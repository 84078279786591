import React from "react";
import { connect } from "react-redux";
import BrandKitSmartText from "views/components/BrandKit/BrandKitSmartText/BrandKitSmartText";
import Subscriptions from "lib/subscriptions";
import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import {
  teamSmartTextOverideSelector,
  teamSmartTextSelector,
  teamSmartTextSearchSelector,
  isFetchingTeamSmartText
} from "state/entities/smartText/smartTextSelectors";
import {
  fetchTeamSmartText,
  deleteTeamSmartText
} from "state/entities/smartText/smartTextActions";

/** Brand kit specific container. This manages state and actions for the BrandKitSmartText list
 */
const BrandKitSmartTextContainer = props => <BrandKitSmartText {...props} />;

const mapStateToProps = state => {
  const currentSubscription = currentSubscriptionPlan(state);
  const currentSubscriptionCode = currentSubscription.code;
  const canAccessBrandKit = Subscriptions.get(
    currentSubscriptionCode
  ).getCanAccessBrandKit();

  return {
    smartText: teamSmartTextSelector(state),
    overrideSmartText: teamSmartTextOverideSelector(state),
    isFetchingSmartText: isFetchingTeamSmartText(state),
    smartTextSearchArr: teamSmartTextSearchSelector(state),
    canAccessBrandKit,
    location: state.router.location
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteSmartText: args => dispatch(deleteTeamSmartText(args)),
    fetchTeamSmartText: args => dispatch(fetchTeamSmartText(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandKitSmartTextContainer);
