import { getPath } from "lib";

export const purchaseCollectionSelector = state =>
  state.ui.purchaseCollectionModal;
export const currentOrderIdSelector = state =>
  purchaseCollectionSelector(state).orderId;
export const purchaseCollectionErrorsSelector = state =>
  purchaseCollectionSelector(state).errors;
export const purchaseCollectionTokenSelector = state =>
  purchaseCollectionSelector(state).token;
export const purchaseCollectionPaymentTypeSelector = state =>
  purchaseCollectionSelector(state).paymentType;
export const purchaseCollectionLoadingSelector = state =>
  purchaseCollectionSelector(state).loading;
export const purchaseCollectionPollingErrorSelector = state =>
  purchaseCollectionSelector(state).pollingError;
export const purchaseCollectionModalOpenSelector = state =>
  purchaseCollectionSelector(state).isOpen;
export const purchaseCollectionCollectionIdSelector = state =>
  purchaseCollectionSelector(state).collectionId;
export const purchaseCollectionRetryPaymentSelector = state => {
  return getPath(purchaseCollectionSelector(state), "retryPayment", false);
};
export const purchaseCollectionCouponAddedSelector = state => {
  return getPath(purchaseCollectionSelector(state), "couponAdded", false);
};
