import React, { useState } from "react";
import style from "./style.module.css";
import TeamRolesPicker from "./TeamRolesPicker";
import { Modal, Avatar } from "views/components";
import TeamRoleInfoMessage from "./TeamRoleInfoMessage";

const EditTeamRolesModal = ({ member, onSubmit, isOpen, onRequestClose }) => {
  const [selectedRoles, setSelectedRoles] = useState(member.roles);

  const handleOnChange = (event, key) => {
    event.preventDefault();
    event.stopPropagation();

    let updatedRoles = [...selectedRoles];

    // if role is already included in array, toggle and remove
    if (selectedRoles.includes(key)) {
      updatedRoles = selectedRoles.filter(role => role !== key);

      setSelectedRoles(updatedRoles);
      return;
    }

    // include new role into update role array
    // and set new state
    updatedRoles.push(key);

    setSelectedRoles(updatedRoles);
  };

  const handleEditSubmit = () => {
    onSubmit({
      id: member.id,
      roles: selectedRoles
    });

    onRequestClose();
  };

  return (
    <Modal
      className={style.memberModal}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      header={`Edit roles`}
      buttons={[
        {
          name: "Cancel",
          theme: "gray",
          onClick: onRequestClose
        },
        {
          name: "Done",
          theme: "blueSolid",
          onClick: handleEditSubmit
        }
      ]}
      buttonsClassName={style.editButtons}
    >
      <div data-testid="EditTeamRolesModal">
        <TeamRoleInfoMessage className={style.teamRoleContentWrapper} />
        <div className={style.memberAvatar}>
          <Avatar
            size="40px"
            theme="dark"
            src={member.avatarUrl}
            name={member.name}
            singleCharacterNameInitial={true}
            charsClassName={style.noAvatarChars}
          />
          <div className={style.userDetails}>
            <div className={style.memberName}>{member.name}</div>
            <div className={style.memberEmail}>{member.email}</div>
          </div>
        </div>
        <div className={style.rolePicker}>
          <div
            className={style.rolePickerTitle}
          >{`Select roles for ${member.name}:`}</div>
          <TeamRolesPicker
            onClick={handleOnChange}
            selectedRoles={selectedRoles}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditTeamRolesModal;
