import React, { Component } from "react";
import style from "./style.module.css";
import CaretWithPopout from "views/components/caretWithPopout";
import { Logger } from "lib";
import { formatFileSize } from "lib/files/fileUtils";
import { formatDateString } from "lib/temporal/dateUtils";
import { isVideo } from "lib/isVideo";
import { isGif } from "lib/isGif";
import PopoutItemMenu from "views/components/popout/PopoutItemMenu/PopoutItemMenu";
import { Loading } from "views/components";
import TrashIcon from "views/components/icons/TrashIcon";
import FolderIcon from "views/components/icons/FolderIcon";
import CheckeredBackgroundIcon from "views/components/icons/CheckeredBackgroundIcon";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import { RemoveBackgroundModalContainer } from "views/containers";
import { getParameterByName } from "lib/queryStringUtils";
import { getImageSize } from "lib/getImageDetails";
import { memoizedGetGifImageSource as getGifImageSource } from "lib/mediaSourceHelpers";
import ScrollingOverflowText from "views/components/ScrollingOverflowText/ScrollingOverflowText";
import PATHS from "routes/paths";
import { playVideo } from "lib/videoControlUtils";
import Checkbox from "views/components/checkbox";
import SmartImageLabel from "views/components/SmartImage/SmartImageLabel";
import SmartImageModalContainer from "views/containers/SmartImageModalContainer";
import DeleteUserSmartImageModalContainer from "views/containers/DeleteUserSmartImageModalContainer/DeleteUserSmartImageModalContainer";
import SmartImageIcon from "views/components/icons/SmartImageIcon";
import EditSmartImageIcon from "views/components/icons/EditSmartImageIcon";
import UnassignSmartImageIcon from "views/components/icons/UnassignSmartImageIcon";
import confirmDeleteSmartImage from "views/components/SmartImage/confirmDeleteSmartImage";

export class ImageListImageRow extends Component {
  constructor(props) {
    super(props);

    this.openPopout = this.openPopout.bind(this);
    this.closePopout = this.closePopout.bind(this);
    this.openSmartImageModal = this.openSmartImageModal.bind(this);
    this.handleDeleteUserImage = this.handleDeleteUserImage.bind(this);
    this.handleCloseDeleteUserSmartImageModal = this.handleCloseDeleteUserSmartImageModal.bind(
      this
    );
    this.closeSmartImageModal = this.closeSmartImageModal.bind(this);
    this.togglePopout = this.togglePopout.bind(this);
    this.toggleFooterOpen = this.toggleFooterOpen.bind(this);
    this.getMenuActionsDetails = this.getMenuActionsDetails.bind(this);
    this.getFooterInfo = this.getFooterInfo.bind(this);
    this.handleDeleteUserTeamImage = this.handleDeleteUserTeamImage.bind(this);
    this.handleRemoveImageFromFolder = this.handleRemoveImageFromFolder.bind(
      this
    );
    this.handleChangeFooterInputValue = this.handleChangeFooterInputValue.bind(
      this
    );

    this.handleOpenRemoveBackgroundModal = this.handleOpenRemoveBackgroundModal.bind(
      this
    );
    this.handleCloseRemoveBackgroundModal = this.handleCloseRemoveBackgroundModal.bind(
      this
    );
    this.useFallbackImage = this.useFallbackImage.bind(this);
    this.handleUpdateUserTeamImageName = this.handleUpdateUserTeamImageName.bind(
      this
    );
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleImageRowClick = this.handleImageRowClick.bind(this);
    this.wrapperRef = React.createRef();
    this.popoutRef = React.createRef();
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
    this.handleDeleteSmartImage = this.handleDeleteSmartImage.bind(this);
    this.onClickDeleteSmartImage = this.onClickDeleteSmartImage.bind(this);
    this.videoRef = React.createRef();

    this.state = {
      useFallback: false,
      popoutOpen: false,
      footerOpen: false,
      footerInputValue: null,
      isRemoveBackgroundModalOpen: false,
      imgStyle: {
        width: "100%",
        height: "100%"
      },
      imageSource: null,
      isHovered: false,
      isSmartImageModalOpen: false,
      smartModalType: "create"
    };
  }

  componentDidMount() {
    this.getImageUrl();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.image !== this.props.image) {
      // try to get the url again and remove the fallback if it was set
      if (this.state.useFallback) {
        this.setState({
          useFallback: false
        });
      }
      this.getImageUrl();
    }

    if (
      this.state.useFallback !== prevState.useFallback ||
      this.state.isHovered !== prevState.isHovered
    ) {
      // just get the url again since we want to preserve the fallback state we had
      this.getImageUrl();
    }

    if (this.state.useFallback !== prevState.useFallback && this.videoRef) {
      this.videoRef.current?.load();
    }

    if (this.videoRef && this.videoRef.current) {
      if (!prevState.isHovered && this.state.isHovered) {
        // hover start
        playVideo(this.videoRef.current);
      }
      if (prevState.isHovered && !this.state.isHovered) {
        // hover end
        this.videoRef.current.currentTime = 0;
        this.videoRef.current.pause();
      }
    }
    if (!prevState.popoutOpen && this.state.popoutOpen) {
      this.props.getFolderForMedia({ mediaId: this.props.image.mediaId });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  onClickDeleteSmartImage() {
    this.props.onDeleteUserTeamSmartImage({
      id: this.props.smartImage.id,
      mediaId: this.props.smartImage.mediaId
    });
  }

  handleDeleteSmartImage() {
    confirmDeleteSmartImage(this.onClickDeleteSmartImage);
  }

  handleDeleteUserImage(image) {
    if (!!this.props.smartImage) {
      this.setState({
        isDeleteUserSmartImageModalOpen: true
      });
      return;
    }

    this.handleDeleteUserTeamImage({
      mediaId: image.mediaId
    });
  }

  handleCloseDeleteUserSmartImageModal() {
    this.setState({
      isDeleteUserSmartImageModalOpen: false
    });
  }

  openSmartImageModal(type) {
    if (this.state.isSmartImageModalOpen) return;
    this.setState({
      isSmartImageModalOpen: true,
      smartModalType: type
    });
  }

  closeSmartImageModal() {
    if (!this.state.isSmartImageModalOpen) return;
    this.setState({
      isSmartImageModalOpen: false,
      smartModalType: null
    });
  }

  handleClickOutside(event) {
    if (
      this.popoutRef.current &&
      !this.popoutRef.current.contains(event.target) &&
      event.target.isConnected
    ) {
      this.closePopout();
    }
  }

  openPopout() {
    if (this.state.popoutOpen === false) {
      this.setState({
        popoutOpen: true
      });
    }
  }

  closePopout() {
    if (this.state.popoutOpen === true) {
      this.setState({
        popoutOpen: false,
        footerOpen: false
      });
    }
  }

  togglePopout(event) {
    if (this.state.popoutOpen === true) {
      return this.closePopout();
    }
    this.openPopout();
  }

  toggleFooterOpen() {
    const { footerOpen } = this.state;
    this.setState({
      footerOpen: !footerOpen
    });
  }

  handleChangeFooterInputValue(value) {
    this.setState({
      footerInputValue: value
    });
  }

  handleRemoveImageFromFolder(contentId) {
    const { removeImageFromFolder, mediaInFolders, image } = this.props;
    const folderId = mediaInFolders[image.mediaId]?.folderId;
    const currentTerm = getParameterByName("term", window.location.search);

    removeImageFromFolder({
      folderId,
      contentId,
      term: currentTerm,
      onSuccess: this.closePopout
    });
    this.closePopout();
  }

  getFolderId() {
    const locationSearch = window.location.search;
    return getParameterByName("folderId", locationSearch);
  }

  handleDeleteUserTeamImage({ mediaId }) {
    const { onDeleteUserTeamImage, isInFolder } = this.props;
    const folderId = isInFolder ? this.getFolderId() : null;
    onDeleteUserTeamImage({
      mediaId,
      folderId,
      isSmartImage: !!this.props.smartImage
    });
  }

  handleUpdateUserTeamImageName(name) {
    const { updateImage, isInFolder, image } = this.props;

    const updateArguments = {
      mediaId: image.mediaId,
      image: {
        ...image,
        media: {
          ...image.media,
          name
        }
      }
    };

    if (isInFolder) {
      updateArguments.folderId = this.getFolderId();
    }

    updateImage(updateArguments);
  }

  getMenuActionsDetails() {
    const { image, mediaInFolders, folderId } = this.props;

    const folderOptionLabel = folderId
      ? "Remove from folder"
      : "Move to folder";

    const getFolderAction = () => {
      const isSearchingMedia = getParameterByName(
        "term",
        window.location.search
      );

      if (this.props.disableFolderFunctions) return null;
      if (mediaInFolders[image.mediaId]?.isFetching && !!isSearchingMedia) {
        return {
          Icon: props => <Loading size="24px" />
        };
      }
      return {
        name: folderOptionLabel,
        Icon: props => <FolderIcon />,
        onClick: this.toggleFooterOpen
      };
    };

    const actions = [
      getFolderAction(),
      {
        name: "Delete",
        Icon: props => <TrashIcon version="2" {...props} />,
        onClick: () => {
          this.handleDeleteUserImage(image);
          this.closePopout();
        }
      }
    ].filter(x => x);

    if (!isGif(image.url) && !isVideo(image.url)) {
      actions.splice(1, 0, {
        name: "Remove background",
        Icon: props => <CheckeredBackgroundIcon {...props} />,
        onClick: () => {
          this.handleOpenRemoveBackgroundModal();
          this.closePopout();
        }
      });
      // This conditional is only here until UPDATE smart image is created
      if (!this.props.smartImage) {
        actions.splice(2, 0, {
          name: "Assign Smart ID",
          Icon: props => <SmartImageIcon {...props} />,
          onClick: () => {
            // open smart image Modal
            this.openSmartImageModal("create");
          }
        });
      } else {
        // UPDATE SMART IMAGE BUTTON
        actions.splice(2, 0, {
          name: "Edit Smart ID",
          Icon: props => <EditSmartImageIcon {...props} />,
          onClick: () => {
            // open smart image Modal
            this.openSmartImageModal("update");
          }
        });
        actions.splice(3, 0, {
          name: "Unassign Smart ID",
          Icon: props => <UnassignSmartImageIcon {...props} />,
          onClick: () => {
            // open smart image Modal
            this.handleDeleteSmartImage();
          }
        });
      }
    }

    return actions;
  }

  getFooterInfo() {
    const { imageFolders, image, onAddMediaToFolder, folderId } = this.props;
    const { footerInputValue } = this.state;

    const removeFromFolderValue = "Remove from folder";

    const selectedImageFolder =
      imageFolders && imageFolders.length
        ? imageFolders.find(folder => folder.name === footerInputValue)
        : {};

    const folders = folderId
      ? imageFolders.filter(folder => folder.id !== folderId)
      : imageFolders;

    const submitButtonLabel =
      folderId && footerInputValue === removeFromFolderValue ? "Remove" : "Add";

    let prependOptions;

    if (folderId) {
      prependOptions = [
        {
          key: removeFromFolderValue,
          label: removeFromFolderValue
        }
      ];
    }

    return {
      type: "Dropdown",
      footerSubmitAction: () => {
        // To move to/Remove from folders
        onAddMediaToFolder({
          folderId:
            folderId && footerInputValue === removeFromFolderValue
              ? "root"
              : selectedImageFolder.id || "",
          mediaIds: [image.mediaId]
        });
        this.closePopout();
      },
      handleFooterFieldChange: this.handleChangeFooterInputValue,
      isFooterSubmitting: false,
      footerInputValue: footerInputValue,
      footerValueOptions: folders.map(folder => ({
        label: folder.name,
        key: folder.name
      })),
      prependOptions,
      submitButtonLabel
    };
  }

  handleOpenRemoveBackgroundModal() {
    if (this.state.isRemoveBackgroundModalOpen) return;
    this.setState({
      isRemoveBackgroundModalOpen: true
    });
  }

  handleCloseRemoveBackgroundModal() {
    if (!this.state.isRemoveBackgroundModalOpen) return;
    this.setState({
      isRemoveBackgroundModalOpen: false
    });
  }

  useFallbackImage() {
    this.setState({ useFallback: true });
  }

  async getImageUrl() {
    let imageSource = null;
    if (this.state.useFallback) {
      imageSource = this.props.image.url;
    } else {
      imageSource = this.props.image.thumbnailUrl;
    }

    if (isGif(this.props.image.url)) {
      imageSource = await getGifImageSource({
        isPlaying: this.state.isHovered,
        gifSource: imageSource
      });
    }

    if (imageSource !== this.state.imageSource) {
      this.setState({
        imageSource
      });
    }
  }

  handleOnMouseOver() {
    if (this.state.isHovered) {
      return;
    }

    this.setState({ isHovered: true });
  }

  handleImageRowClick(event) {
    if (event.shiftKey) {
      event.preventDefault();
      this.props.onMultiSelect([this.props.image.mediaId]);
    }
  }

  async shouldComponentUpdate(nextProps) {
    if (nextProps.image.thumbnailUrl !== this.props.image.thumbnailUrl) {
      // the thumbnail url was updated so we want to ensure we load it
      // wait for the preview to be added to cache so we don't get a flicker on the canvas
      await new Promise(resolve => {
        let attempts = 0;
        const attemptLoad = () => {
          attempts++;
          Logger.info(
            `attempting a load for ${nextProps.image.name}: attempt ${attempts}`
          );
          const imagePreloaded = document.createElement("img");
          imagePreloaded.addEventListener("load", () => {
            Logger.info(
              `load succeeded for ${nextProps.image.name}: attempt ${attempts}`
            );
            resolve();
          });
          imagePreloaded.addEventListener("error", () => {
            if (attempts >= 5) {
              resolve();
            } else {
              Logger.info(
                `a load failed for ${nextProps.image.name}: attempt ${attempts}`
              );
              setTimeout(attemptLoad, 200);
            }
          });
          imagePreloaded.src = nextProps.image.thumbnailUrl;
        };
        attemptLoad();
      });

      return true;
    } else {
      return true;
    }
  }

  render() {
    const {
      image,
      isLast,
      imageOriginPath,
      imageRowClassName,
      isMultiSelect,
      onMultiSelect,
      isSelected,
      smartImage
    } = this.props;
    const {
      popoutOpen,
      footerOpen,
      isRemoveBackgroundModalOpen,
      imageSource
    } = this.state;

    const footerInfo = this.getFooterInfo();
    const updatedDateClean = formatDateString(image.createdAt, "MMM DD, YYYY");
    const imageSize = formatFileSize(getImageSize(image), 1);
    const isVideo =
      (imageSource && imageSource.endsWith(".mp4")) ||
      image.media.asset?.toLowerCase().endsWith(".mp4");
    return (
      <div
        className={`${style.imageRow} ${imageRowClassName}`}
        ref={this.wrapperRef}
        onClick={event => this.handleImageRowClick(event)}
      >
        <div className={style.imageTile}>
          {isMultiSelect && (
            <div className={style.multiSelectCheckbox}>
              <Checkbox
                onClick={onMultiSelect}
                checked={isSelected}
                isDoubleScale={true}
                checkboxClassName={style.checkbox}
              />
            </div>
          )}
          <div
            className={style.imageWrapper}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
            onMouseOver={this.handleOnMouseOver}
          >
            {!!imageSource ? (
              <>
                {isVideo ? (
                  <video
                    // onLoadedData={this.handleVideoLoaded}
                    onError={this.useFallbackImage}
                    // className={style.video}
                    muted
                    loop
                    autoPlay={false}
                    style={this.state.imgStyle}
                    alt=""
                    ref={this.videoRef}
                  >
                    <source
                      src={imageSource}
                      type="video/mp4"
                      // onLoad={this.handleVideoLoaded}
                      onError={this.useFallbackImage}
                    />
                  </video>
                ) : (
                  <img
                    src={imageSource}
                    alt=""
                    onError={this.useFallbackImage}
                    id={this.props.image.mediaId}
                    style={this.state.imgStyle}
                  />
                )}
                {image.isPlaceholder && (
                  <div className={style.imageOverlay}>
                    <div />
                    <Loading
                      size="44px"
                      borderWidth={2}
                      largeEdgeHexOverride="#f5f5f8"
                      hexColor="#8f9297"
                    />
                  </div>
                )}
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
        <div className={style.imageRowRight} data-is-last={isLast}>
          <a
            className={style.imageTitle}
            href={image.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ flexWrap: smartImage ? "wrap" : "nowrap" }}
          >
            {smartImage && <SmartImageLabel label={smartImage.label} />}
            <ScrollingOverflowText
              customStyle={{ paddingLeft: smartImage ? "4px" : "0" }}
            >
              {image.media.name}
            </ScrollingOverflowText>
          </a>
          <div className={style.imageDetails}>
            <div className={style.imageDetailsColumn}>
              <p>{imageSize}</p>
            </div>
            <div className={style.imageDetailsColumn}>
              <p>{updatedDateClean}</p>
            </div>
            <div
              className={style.imageRowPopoutCaretWrapper}
              ref={this.popoutRef}
            >
              {!image.isPlaceholder && (
                <>
                  {/* brand kit animations popout uses EllipsisIcon instead of CaretWithPopout */}
                  {imageOriginPath === PATHS.brandAnimations ? (
                    <div
                      className={style.imageListRowEllipse}
                      onClick={event => {
                        event.stopPropagation();
                        this.togglePopout();
                      }}
                      id={`ellipsis-with-popout-${image.mediaId}`}
                    >
                      <EllipsisIcon color="#999c9e" size="24px" />
                    </div>
                  ) : (
                    <CaretWithPopout
                      className={style.imageRowPopoutCaret}
                      caretArrowColor={"white"}
                      caretFillColor={style.easilBlack}
                      fullFill
                      width={"20px"}
                      height={"20px"}
                      openPopoutClick={this.togglePopout}
                      id={image.mediaId}
                    />
                  )}
                  {popoutOpen && (
                    <PopoutItemMenu
                      onClose={this.closePopout}
                      className={style.popout}
                      itemName={image.media.name}
                      onRename={this.handleUpdateUserTeamImageName}
                      actions={this.getMenuActionsDetails()}
                      footerOpen={footerOpen}
                      footerInfo={footerInfo}
                      nameSectionClassName={style.nameSection}
                      nameBoxClassName={style.nameBox}
                      buttonId={`caret-with-popout-${image.mediaId}`}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <RemoveBackgroundModalContainer
          isOpen={isRemoveBackgroundModalOpen}
          onOpen={this.handleOpenRemoveBackgroundModal}
          onClose={this.handleCloseRemoveBackgroundModal}
          mediaId={image.mediaId}
          mediaType={image.url.split(".").pop()}
        />
        <SmartImageModalContainer
          isOpen={this.state.isSmartImageModalOpen}
          onOpen={this.openSmartImageModal}
          onClose={this.closeSmartImageModal}
          image={image}
          smartImage={this.props.smartImage}
          type={this.state.smartModalType}
        />
        {this.state.isDeleteUserSmartImageModalOpen && (
          <DeleteUserSmartImageModalContainer
            isOpen={this.state.isDeleteUserSmartImageModalOpen}
            onHardDelete={() =>
              this.handleDeleteUserTeamImage({ mediaId: image.mediaId })
            }
            onClose={this.handleCloseDeleteUserSmartImageModal}
          />
        )}
      </div>
    );
  }
}

ImageListImageRow.displayName = "ImageListImageRow";

export default ImageListImageRow;
