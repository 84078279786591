import { sanitizeHTMLForV1 } from "lib/textUtils";
import { decodeHtml, encodeHtmlForRender } from "lib/htmlStrings";
import { getFontByName } from "lib/fonts";

const formatTableDataForV1 = tableData => {
  const columnsMetadataFormatted = columnsMetadataFormattedForV1(
    tableData.columnsMetadata
  );

  const rowsFormatted = rowsFormattedForV1(tableData.rows);

  return {
    ...tableData,
    rows: rowsFormatted,
    columnsMetadata: columnsMetadataFormatted
  };
};

const rowsFormattedForV1 = rows => {
  return rows.map(rowFormattedForV1);
};

const rowFormattedForV1 = row => {
  const cellsFormattedForV1 = row.cells.map(rowsCellFormattedForV1);

  return {
    ...row,
    cells: cellsFormattedForV1
  };
};

const rowsCellFormattedForV1 = cell => {
  const textFieldsFormattedForV1 = cell.textFields.map(
    rowsCellTextFieldFormattedForV1
  );

  return {
    ...cell,
    textFields: textFieldsFormattedForV1
  };
};

const rowsCellTextFieldFormattedForV1 = textField => {
  // perform a sanitize for v1
  let value = sanitizeHTMLForV1(textField.value);
  // decode the html using our custom html decoder
  value = decodeHtml(value);
  // encode the html for the renderer to read
  value = encodeHtmlForRender(value.replace(/<br>/g, "\n"));
  return {
    ...textField,
    value
  };
};

const columnsMetadataFormattedForV1 = columnsMetadata => {
  const columnsMetadataFormatted = {};

  Object.keys(columnsMetadata).forEach(columnMetadataKey => {
    columnsMetadataFormatted[columnMetadataKey] = columnMetadataFormattedForV1(
      columnsMetadata[columnMetadataKey]
    );
  });

  return columnsMetadataFormatted;
};

const columnMetadataFormattedForV1 = columnMetadata => {
  const cellsMetadataFormatted = columnMetadata.cellsMetadata.map(
    cellMetadataFormattedForV1
  );

  return {
    cellsMetadata: cellsMetadataFormatted
  };
};

const cellMetadataFormattedForV1 = cellMetadata => {
  const textFieldsFormattedForV1 = cellMetadata.textFields.map(
    textFieldFormattedForV1
  );

  return {
    ...cellMetadata,
    textFields: textFieldsFormattedForV1
  };
};

const textFieldFormattedForV1 = textField => {
  const bordersFormattedForV1 = formatBordersForV1(textField.borders);

  if (!textField.fontId) {
    const font = getFontByName(textField.fontFamily);
    if (font) {
      textField.fontId = font.fontId;
    }
  }

  return {
    ...textField,
    borders: bordersFormattedForV1
  };
};

const formatBordersForV1 = borders => {
  const bordersFormattedForV1 = [];

  Object.keys(borders).forEach(borderKey => {
    if (borders[borderKey].color) {
      const position = borderKey.match(/border(.*)/)[1].toLowerCase();

      const border = {
        color: borders[borderKey].color,
        edge: position
      };

      bordersFormattedForV1.push(border);
    }
  });

  return bordersFormattedForV1;
};

export default formatTableDataForV1;
