import * as types from "./editorAnimationSearchTypes";
import * as userTeamAnimationTypes from "state/entities/userTeamAnimations/userTeamAnimationsTypes";
import * as teamAnimationTypes from "state/entities/teamAnimations/teamAnimationsTypes";
import * as teamFolderTypes from "state/entities/teamAnimationsFolders/teamAnimationsFoldersTypes";
import { getPath, isEmpty, uniq } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
import { getParameterByName } from "lib/queryStringUtils";
import { ANIMATION_ASSET_TYPE_MAP } from "lib/constants";

export const initState = {
  personal: {
    currentTerm: null,
    folders: {},
    ANIMATION: { terms: {} },
    VIDEO: { terms: {} }
  },
  stock: {
    ANIMATION: { terms: {} },
    VIDEO: { terms: {} }
  },
  brand: {
    folders: {},
    ANIMATION: { terms: {} },
    VIDEO: { terms: {} }
  },
  pageSize: 30
};

const editorAnimationSearchReducers = (state = initState, action) => {
  const paramAssetType = getParameterByName("assetType", action.queryParams);
  const mediaType =
    paramAssetType === "stickers"
      ? ANIMATION_ASSET_TYPE_MAP.ANIMATION
      : ANIMATION_ASSET_TYPE_MAP.VIDEO;

  switch (action.type) {
    case userTeamAnimationTypes.USER_TEAM_ANIMATION_DELETE_REQUEST: {
      if (isEmpty(state.personal[mediaType].terms[state.personal.currentTerm]))
        return state;
      const idsForTerm =
        state.personal[mediaType].terms[state.personal.currentTerm];
      const idIndex = (idsForTerm.ids || []).indexOf(action.request.mediaId);
      return immutableUpdate(state, {
        personal: {
          [mediaType]: {
            $auto: {
              terms: {
                [state.personal.currentTerm]: {
                  animations: {
                    $unset: [action.request.mediaId]
                  },
                  ids: {
                    $splice: [[idIndex, 1]]
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_ANIMATION_SEARCH_REQUEST: {
      const assetType = action.request.params.type || "ALL";

      const folderId = action.request.params.folderId;

      if (folderId) {
        return immutableUpdate(state, {
          personal: {
            currentTerm: {
              $set: action.request.params.term
            },
            folders: {
              $auto: {
                [folderId]: {
                  $auto: {
                    [assetType]: {
                      $auto: {
                        terms: {
                          $auto: {
                            [action.request.params.term]: {
                              $auto: {
                                $merge: {
                                  isFetching: true
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return immutableUpdate(state, {
          personal: {
            currentTerm: {
              $set: action.request.params.term
            },
            [assetType]: {
              $auto: {
                terms: {
                  $auto: {
                    [action.request.params.term]: {
                      $auto: {
                        $merge: {
                          isFetching: true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
    }

    case teamFolderTypes.REMOVE_ANIMATION_FROM_TEAM_FOLDER_REQUEST_SUCCESS: {
      const term = getParameterByName("term", action.queryParams);
      const assetType = "ALL";
      if (!state.brand.folders[action.request.extra.folderId]) {
        return state;
      }
      if (!!term) {
        const ids =
          state.brand.folders[action.request.extra.folderId][assetType]?.terms[
            term
          ].ids;
        const idToRemove = action.request.extra.contentId;
        const newIds = ids.filter(id => id !== idToRemove);
        return immutableUpdate(state, {
          brand: {
            $auto: {
              folders: {
                $auto: {
                  [action.request.extra.folderId]: {
                    $auto: {
                      [assetType]: {
                        $auto: {
                          terms: {
                            $auto: {
                              [term]: {
                                ids: { $set: [...newIds] }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return state;
      }
    }

    case types.EDITOR_ANIMATION_SEARCH_CLEAR_CURRENT_TERM: {
      return immutableUpdate(state, {
        personal: {
          currentTerm: {
            $set: null
          }
        }
      });
    }

    case types.EDITOR_ANIMATION_SEARCH_SUCCESS: {
      const newEntities = getPath(
        action,
        "response.entities.userTeamSearchedAnimation",
        {}
      );
      const assetType = action.request.params.type || "ALL";
      const folderId = action.request.params.folderId;

      if (folderId) {
        const ids = uniq([
          ...(state.personal.folders[folderId][assetType].terms[
            action.request.params.term
          ]?.ids || []),
          ...(action.response.ids || [])
        ]);
        return immutableUpdate(state, {
          personal: {
            folders: {
              [folderId]: {
                [assetType]: {
                  $auto: {
                    terms: {
                      [action.request.params.term]: {
                        isFetching: { $set: false },
                        didInvalidate: { $set: false },
                        ids: { $set: [...ids] },
                        animations: { $auto: { $merge: newEntities } }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        const ids = uniq([
          ...(state.personal[assetType].terms[action.request.params.term]
            ?.ids || []),
          ...(action.response.ids || [])
        ]);
        return immutableUpdate(state, {
          personal: {
            [assetType]: {
              $auto: {
                terms: {
                  [action.request.params.term]: {
                    isFetching: { $set: false },
                    didInvalidate: { $set: false },
                    ids: { $set: [...ids] },
                    animations: { $auto: { $merge: newEntities } }
                  }
                }
              }
            }
          }
        });
      }
    }

    case userTeamAnimationTypes.USER_TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS: {
      // using 'ALL' here as we you can only update media items in the masonry
      const assetType = "ALL";
      const folderId = action.request.body.folderId;
      const term = getParameterByName("term", action.queryParams);

      if (!!folderId && !!term) {
        const currentMediaState =
          state.personal.folders[folderId][assetType].terms[term].animations[
            action.response.mediaId
          ];
        const updatedAnimation = {
          ...currentMediaState,
          name: action.response.media.name
        };
        return immutableUpdate(state, {
          personal: {
            folders: {
              $auto: {
                [folderId]: {
                  $auto: {
                    [assetType]: {
                      $auto: {
                        terms: {
                          [term]: {
                            animations: {
                              $auto: {
                                [action.response.mediaId]: {
                                  $set: { ...updatedAnimation }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
      return state;
    }

    case types.EDITOR_STOCK_ANIMATION_SEARCH_REQUEST: {
      const assetType = action.request.params.type;

      return immutableUpdate(state, {
        personal: {
          currentTerm: {
            $set: action.request.params.term
          }
        },
        stock: {
          [assetType]: {
            $auto: {
              terms: {
                $auto: {
                  [action.request.params.term]: {
                    $auto: {
                      $merge: {
                        isFetching: true
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_BRAND_ANIMATION_SEARCH_REQUEST: {
      const assetType = action.request.params.type || "ALL";
      const folderId = action.request.params.folderId;

      if (folderId) {
        return immutableUpdate(state, {
          personal: {
            currentTerm: {
              $set: action.request.params.term
            }
          },
          brand: {
            folders: {
              $auto: {
                [folderId]: {
                  $auto: {
                    [assetType]: {
                      $auto: {
                        terms: {
                          $auto: {
                            [action.request.params.term]: {
                              $auto: {
                                $merge: {
                                  isFetching: true
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return immutableUpdate(state, {
          personal: {
            currentTerm: {
              $set: action.request.params.term
            }
          },
          brand: {
            [assetType]: {
              $auto: {
                terms: {
                  $auto: {
                    [action.request.params.term]: {
                      $auto: {
                        $merge: {
                          isFetching: true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
    }

    case types.EDITOR_STOCK_ANIMATION_SEARCH_SUCCESS: {
      const ids = new Set([
        ...getPath(
          state,
          `stock.${action.request.params.type}.terms.${action.request.params.term}.ids`,
          []
        ),
        ...(action.response.ids || [])
      ]);

      let newEntities = getPath(
        action,
        "response.entities.stockSearchedAnimation",
        {}
      );

      const nextPage = ids.size ? action.request.page + 1 : action.request.page;

      return immutableUpdate(state, {
        stock: {
          [action.request.params.type]: {
            $auto: {
              terms: {
                [action.request.params.term]: {
                  $auto: {
                    isFetching: { $set: false },
                    didInvalidate: { $set: false },
                    nextPage: { $set: nextPage },
                    ids: { $set: [...ids] },
                    animations: {
                      $auto: {
                        $merge: newEntities
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_BRAND_ANIMATION_SEARCH_SUCCESS: {
      const newEntities = getPath(
        action,
        "response.entities.teamSearchedAnimation",
        {}
      );

      const assetType = action.request.params.type || "ALL";
      const folderId = action.request.params.folderId;

      if (folderId) {
        const ids = uniq([
          ...(state.brand.folders[folderId][assetType].terms[
            action.request.params.term
          ]?.ids || []),
          ...(action.response.ids || [])
        ]);

        return immutableUpdate(state, {
          brand: {
            folders: {
              $auto: {
                [folderId]: {
                  $auto: {
                    [assetType]: {
                      $auto: {
                        terms: {
                          [action.request.params.term]: {
                            isFetching: { $set: false },
                            didInvalidate: { $set: false },
                            ids: { $set: [...ids] },
                            animations: { $auto: { $merge: newEntities } }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        const ids = uniq([
          ...(state.brand[assetType].terms[action.request.params.term]?.ids ||
            []),
          ...(action.response.ids || [])
        ]);
        return immutableUpdate(state, {
          brand: {
            [assetType]: {
              $auto: {
                terms: {
                  [action.request.params.term]: {
                    isFetching: { $set: false },
                    didInvalidate: { $set: false },
                    ids: { $set: [...ids] },
                    animations: { $auto: { $merge: newEntities } }
                  }
                }
              }
            }
          }
        });
      }
    }

    case teamAnimationTypes.TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS: {
      const assetType = "ALL";
      const folderId = action.request.body.folderId;
      const term = getParameterByName("term", action.queryParams);
      if (!!folderId && !!term) {
        const currentMediaState =
          state.brand.folders[action.request.body.folderId][assetType].terms[
            term
          ].animations[action.response.mediaId];
        const updatedAnimation = {
          ...currentMediaState,
          name: action.response.media.name
        };
        if (
          updatedAnimation &&
          updatedAnimation.media &&
          !updatedAnimation.media.priority
        ) {
          updatedAnimation.media.priority = null;
        }
        return immutableUpdate(state, {
          brand: {
            folders: {
              $auto: {
                [folderId]: {
                  $auto: {
                    [assetType]: {
                      $auto: {
                        terms: {
                          [term]: {
                            animations: {
                              $auto: {
                                [action.response.mediaId]: {
                                  $set: { ...updatedAnimation }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
      return state;
    }
    case userTeamAnimationTypes.USER_TEAM_VIDEOS_DELETE_REQUEST_SUCCESS: {
      const { mediaIds, folderId } = action.extra;

      // This reducer should only be updated when searching inside of a folder in Workspace
      if (folderId && state.personal.currentTerm) {
        const ids = [
          ...(state.personal.folders[folderId].ALL.terms[
            state.personal.currentTerm
          ].ids || [])
        ];

        // Filter out ids to be deleted
        const updatedIds = ids.filter(id => !mediaIds.includes(id));

        return immutableUpdate(state, {
          personal: {
            $auto: {
              folders: {
                $auto: {
                  [folderId]: {
                    $auto: {
                      ALL: {
                        $auto: {
                          terms: {
                            $auto: {
                              [state.personal.currentTerm]: {
                                $auto: {
                                  ids: { $set: [...updatedIds] }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
      return state;
    }
    default:
      return state;
  }
};

export default editorAnimationSearchReducers;
