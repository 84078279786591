/**
 * Adapts the properties of a media item from the results of the search media endpoint.
 *
 * @param {Array} mediaItems Required: An array of objects returned by search results.
 * @param {Boolean} hasMissingArguments Optional: Flag used when search results are missing duration, createdAt & updatedAt.
 * @returns {Array} resolved with an array of updated media items.
 */
export default function mediaSearchAdapter(mediaItems, hasMissingArguments) {
  const searchAdapterMedia = mediaItems.map(mediaItem => {
    if (!!mediaItem.media && !hasMissingArguments) {
      return mediaItem;
    } else {
      return {
        media: {
          id: mediaItem.id,
          name: mediaItem.name,
          priority: null,
          createdAt: new Date(),
          updatedAt: new Date(),
          status: "ACTIVE",
          thumbnail: mediaItem.thumbnailUrl,
          asset: mediaItem.url,
          preview: mediaItem.previewUrl,
          type: mediaItem.type,
          height: null,
          width: null,
          duration: mediaItem.duration || null
        },
        mediaId: mediaItem.id,
        createdAt: new Date(),
        updatedAt: new Date(),
        url: mediaItem.url,
        previewUrl: mediaItem.previewUrl,
        thumbnailUrl: mediaItem.thumbnailUrl,
        teamId: mediaItem.teamId,
        origin: mediaItem.origin
      };
    }
  });
  return searchAdapterMedia;
}
