import * as types from "./orderTransactionsTypes";
import { merge } from "lib";

export const initState = {};

const ordersReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_ORDER_TRANSACTIONS_REQUEST_SUCCESS: {
      const { entities } = action.response;

      if (!entities) return state;

      return merge({}, state, entities.orderTransaction);
    }

    default:
      return state;
  }
};

export default ordersReducer;
