import { uuid } from "lib";

const generateDefaults = () => {
  return {
    restrictions: [],
    fonts: [],
    isHidden: false,
    palette: [],
    scaleX: 1,
    scaleY: 1,
    scale: 1,
    textInstructions: [], // TODO
    opacity: 1,
    type: "grid",
    uniqueId: uuid(),
    id: uuid(),
    angle: 0,
    name: "Untitled Grid",
    top: 0,
    left: 0
  };
};

const scaledDimensionByMaxSize = (width, height) => {
  const ratio = 3;
  const maxDimension = Math.max(width, height);

  return maxDimension / ratio;
};

const parseGridToElement = data => {
  const dimension = scaledDimensionByMaxSize(data.width, data.height);

  const gridDefaults = generateDefaults();

  return {
    ...gridDefaults,
    height: dimension,
    imageInstructions: data.imageInstructions,
    left: data.left || gridDefaults.left,
    source: data.source,
    srcHeight: 0,
    srcWidth: 0,
    top: data.top || gridDefaults.top,
    width: dimension
  };
};

export default parseGridToElement;
