import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const EyeIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  isCrossed
}) => {
  if (isCrossed) {
    // return eye icon with cross through it
    return (
      <svg
        className={className}
        style={style}
        data-no-pointer={noPointer}
        width={width || size || 18}
        height={height || size || 18}
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <title>{title}</title>
        <g fill="none" fillRule="evenodd">
          <path d="M-1-1h20v20H-1z" />
          <path
            stroke={color || easilGrayXDark}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1l16 16M7.896 3.095A6.422 6.422 0 0 1 9 3c5.09 0 8 6 8 6s-.398.821-1.158 1.856m-2.499 2.583C12.157 14.331 10.7 15 9 15c-5.09 0-8-6-8-6s1.257-2.593 3.566-4.37"
          />
          <path
            fill={color || easilGrayXDark}
            fillRule="nonzero"
            d="M11.139 11.104a3 3 0 1 1-4.151-4.328M9 7.745A1.263 1.263 0 0 0 7.737 9 1.26 1.26 0 0 0 9 10.255 1.26 1.26 0 0 0 10.263 9"
          />
        </g>
      </svg>
    );
  }
  // return normal eye icon
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 18 14"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M-1-3h20v20H-1z" />
        <g fill={color || easilGrayXDark} fillRule="nonzero">
          <path d="M9.002 2C6.06 2 4.007 3.168 2.7 5.563a3 3 0 0 0 0 2.874C4.007 10.832 6.06 12 9.002 12c2.944 0 4.996-1.169 6.3-3.566a3 3 0 0 0 0-2.868C13.999 3.17 11.947 2 9.003 2zm0-2c3.663 0 6.396 1.557 8.058 4.61a5 5 0 0 1 0 4.78C15.398 12.443 12.665 14 9.002 14 5.342 14 2.61 12.445.944 9.394a5 5 0 0 1 0-4.788C2.61 1.555 5.342 0 9.002 0z" />
          <path d="M9 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
        </g>
      </g>
    </svg>
  );
};
EyeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default EyeIcon;
