import * as types from "./cardsTypes";

export const initState = {};

const cardsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_CARDS_SUCCESS: {
      if (!action.response.entities) return state;

      const {
        entities: { card }
      } = action.response;

      return {
        ...state,
        ...card
      };
    }

    case types.CREATE_CARD_SUCCESS: {
      const {
        entities: { card }
      } = action.response;

      return {
        ...initState,
        ...card
      };
    }

    case types.REMOVE_CARD_SUCCESS: {
      const {
        body: { stripeCardId }
      } = action.request;

      return { ...state, [stripeCardId]: null };
    }

    default:
      return state;
  }
};

export default cardsReducers;
