import * as types from "../../entities/designFolders/designFoldersTypes";
import { merge } from "lib";

export const initState = {};

export const baseState = {
  isFetching: false,
  isSuccess: false,
  errors: []
};

const fetchDesignFolder = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_FOLDER_FOR_DESIGN_REQUEST: {
      const {
        extra: { designId }
      } = action;

      const fetchState = {
        [designId]: { ...baseState, isFetching: true }
      };

      return merge({}, state, fetchState);
    }

    case types.FETCH_FOLDER_FOR_DESIGN_REQUEST_SUCCESS: {
      const {
        extra: { designId }
      } = action;
      const newState = Object.assign({}, state);

      newState[designId] = {
        ...newState[designId],
        isFetching: false,
        isSuccess: true
      };

      return newState;
    }

    default:
      return state;
  }
};

export default fetchDesignFolder;
