import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CropIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="cropIcon"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <path d="M0 0h16v16H0z" />
      <g fill={color || easilGrayXDark} fillRule="nonzero">
        <path d="M15 11.1a.9.9 0 1 1 0 1.8H5A1.9 1.9 0 0 1 3.1 11V1a.9.9 0 0 1 1.8 0v10a.1.1 0 0 0 .1.1h10z" />
        <path d="M12.1 15a.9.9 0 1 0 1.8 0v-2.063a.9.9 0 1 0-1.8 0V15zm0-6.958a.9.9 0 1 0 1.8 0V4A1.9 1.9 0 0 0 12 2.1H8.056a.9.9 0 0 0 0 1.8H12a.1.1 0 0 1 .1.1v4.042zM3.204 3.9a.9.9 0 1 0 0-1.8H1a.9.9 0 0 0 0 1.8h2.204z" />
      </g>
    </g>
  </svg>
);
CropIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CropIcon;
