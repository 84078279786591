import { ensureArguments } from "lib/errors/errors";

/**
 * @desc takes a set of point coordinates, origin point coordinates and an angle and outputs the point rotated angle around the origin
 * @param {Number} pointX - the x coordinate for the point we want to rotate
 * @param {Number} pointY - the y coordinate for the point we want to rotate
 * @param {Number} originX - the x coordinate for the rotation origin
 * @param {Number} originY - the y coordinate for the rotation origin
 * @param {Number} angleInDegrees - the angle in degrees to rotate the point by around the origin
 * @returns {Object{ x: Number, y: Number }} - the rotated point
 */
export function rotatePoint(pointX, pointY, originX, originY, angleInDegrees) {
  ensureArguments(rotatePoint, arguments);

  const angleInRadians = (angleInDegrees * Math.PI) / 180.0;

  const sin = Math.sin(angleInRadians);
  const cos = Math.cos(angleInRadians);

  // translate point back to origin:
  const pointXTranslatedToOrigin = pointX - originX;
  const pointYTranslatedToOrigin = pointY - originY;

  // rotate Point
  let newX = pointXTranslatedToOrigin * cos - pointYTranslatedToOrigin * sin;
  let newY = pointXTranslatedToOrigin * sin + pointYTranslatedToOrigin * cos;

  // translate point back
  newX = newX + originX;
  newY = newY + originY;

  return {
    x: newX,
    y: newY
  };
}

/**
 * converts a trig function taking radians to degrees
 * @param {function} trigFunc - eg. Math.cos, Math.sin, etc.
 * @param {number} angle - in degrees
 * @returns {number}
 */
export const inDegrees = (trigonometryFunction, angle) =>
  trigonometryFunction((angle * Math.PI) / 180);
