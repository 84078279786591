import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CircledArrowIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g
        transform="translate(4 4)"
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <circle cx="8" cy="8" r="8" />
        <path d="m8 12 4-4-4-4M4 8h8" />
      </g>
    </g>
  </svg>
);

CircledArrowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CircledArrowIcon;
