import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { noop } from "lib";
import { sortByUpdatedAt } from "lib/array/sort";
import { currentOrderIdSelector } from "state/ui/cart/cartSelectors";
import { findRateByCountry } from "state/entities/shippingRates/shippingRatesSelectors";
import { asyncAction } from "lib/asyncHelpers";
import * as types from "./shippingDetailsTypes";
import { reject } from "lodash";

const defaultFetchTypes = [
  types.FETCH_SHIPPING_DETAILS_FOR_ORDER_REQUEST,
  types.FETCH_SHIPPING_DETAILS_FOR_ORDER_REQUEST_SUCCESS,
  types.FETCH_SHIPPING_DETAILS_FOR_ORDER_REQUEST_FAILURE
];
const currentOrderFetchTypes = [
  types.FETCH_SHIPPING_DETAILS_FOR_CURRENT_ORDER_REQUEST,
  types.FETCH_SHIPPING_DETAILS_FOR_CURRENT_ORDER_REQUEST_SUCCESS,
  types.FETCH_SHIPPING_DETAILS_FOR_CURRENT_ORDER_REQUEST_FAILURE
];

export const fetchShippingDetailsForOrder = ({
  orderId,
  types,
  onSuccess = noop,
  resolve = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER,
      types: types || defaultFetchTypes,
      endpoint: `/orders/${orderId}/shipping-details`,
      schema: schemas.SHIPPING_DETAILS,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      }
    }
  });
};

export const asyncFetchShippingDetailsForOrder = asyncAction(
  fetchShippingDetailsForOrder
);

export const fetchShippingDetailsForCurrentOrder = ({
  onSuccess = noop,
  resolve = noop
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentOrderId = currentOrderIdSelector(state);

  return dispatch(
    fetchShippingDetailsForOrder({
      orderId: currentOrderId,
      onSuccess,
      resolve,
      types: currentOrderFetchTypes
    })
  );
};

export const asyncFetchShippingDetailsForCurrentOrder = asyncAction(
  fetchShippingDetailsForCurrentOrder
);

export const createNewShippingDetailsForOrder = ({
  orderId,
  shippingDetails,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => (dispatch, getState) => {
  const state = getState();
  const shippingRates = findRateByCountry(state, shippingDetails.country);
  const shippingFilteredByState = shippingRates.filter(
    rate => rate.region === shippingDetails.state
  );
  const shippingRate = sortByUpdatedAt(shippingFilteredByState)[0];

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ORDER,
      types: [
        types.CREATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST,
        types.CREATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_SUCCESS,
        types.CREATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_FAILURE
      ],
      endpoint: `/orders/${orderId}/shipping-details`,
      schema: schemas.SHIPPING_DETAILS,
      request: {
        body: {
          ...shippingDetails,
          amount: shippingRate.price,
          orderId
        }
      },
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

export const createNewShippingDetailsForCurrentOrder = ({
  shippingDetails,
  onSuccess = noop,
  resolve = noop,
  reject = noop
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentOrderId = currentOrderIdSelector(state);

  return dispatch(
    createNewShippingDetailsForOrder({
      orderId: currentOrderId,
      shippingDetails,
      onSuccess,
      resolve,
      reject
    })
  );
};

export const updateShippingDetailsForOrder = ({
  orderId,
  shippingDetails,
  onSuccess = noop,
  resolve = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ORDER,
      types: [
        types.UPDATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST,
        types.UPDATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_SUCCESS,
        types.UPDATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_FAILURE
      ],
      endpoint: `/orders/${orderId}/shipping-details`,
      schema: schemas.SHIPPING_DETAILS,
      request: {
        body: {
          ...shippingDetails,
          orderId
        }
      },
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

export const updateShippingDetailsForCurrentOrder = ({
  shippingDetails,
  onSuccess = noop,
  resolve = noop,
  reject = noop
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentOrderId = currentOrderIdSelector(state);

  return dispatch(
    updateShippingDetailsForOrder({
      orderId: currentOrderId,
      shippingDetails,
      onSuccess,
      resolve,
      reject
    })
  );
};
