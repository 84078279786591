export const EDITOR_IMAGE_SEARCH_REQUEST = "API/MEDIA/IMAGE_SEARCH_REQUEST";
export const EDITOR_IMAGE_SEARCH_SUCCESS = "API/MEDIA/IMAGE_SEARCH_SUCCESS";
export const EDITOR_IMAGE_SEARCH_FAILURE = "API/MEDIA/IMAGE_SEARCH_FAILURE";

export const EDITOR_IMAGE_SEARCH_CLEAR_CURRENT_TERM =
  "API/MEDIA/IMAGE_SEARCH_CLEAR_CURRENT_TERM";

export const EDITOR_BRAND_IMAGE_SEARCH_REQUEST =
  "API/MEDIA/BRAND_IMAGE_SEARCH_REQUEST";
export const EDITOR_BRAND_IMAGE_SEARCH_SUCCESS =
  "API/MEDIA/BRAND_IMAGE_SEARCH_SUCCESS";
export const EDITOR_BRAND_IMAGE_SEARCH_FAILURE =
  "API/MEDIA/BRAND_IMAGE_SEARCH_FAILURE";

export const EDITOR_BRAND_LOGO_SEARCH_REQUEST =
  "API/MEDIA/BRAND_LOGO_SEARCH_REQUEST";
export const EDITOR_BRAND_LOGO_SEARCH_SUCCESS =
  "API/MEDIA/BRAND_LOGO_SEARCH_SUCCESS";
export const EDITOR_BRAND_LOGO_SEARCH_FAILURE =
  "API/MEDIA/BRAND_LOGO_SEARCH_FAILURE";

export const EDITOR_STOCK_IMAGE_SEARCH_REQUEST =
  "API/MEDIA/STOCK_IMAGE_SEARCH_REQUEST";
export const EDITOR_STOCK_IMAGE_SEARCH_SUCCESS =
  "API/MEDIA/STOCK_IMAGE_SEARCH_SUCCESS";
export const EDITOR_STOCK_IMAGE_SEARCH_FAILURE =
  "API/MEDIA/STOCK_IMAGE_SEARCH_FAILURE";
