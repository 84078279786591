import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import { findPage } from "state/api/helpers";
import { omit, isEmpty } from "lib";
import { removeItem } from "lib/array/array";
import PATHS from "routes/paths";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50
};

const pendingApprovalApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_PENDING_APPROVAL_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case designsEntitiesTypes.DESIGNS_PENDING_APPROVAL_REQUEST_SUCCESS: {
      const {
        response: { ids = [] } = {},
        request: { page }
      } = action;

      if (isEmpty(ids) && Number(page) !== 1) {
        return {
          ...state,
          pages: omit(state.pages, action.request.page)
        };
      }

      const updatedPage = {
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false
        }
      };

      return {
        ...state,
        pages: { ...state.pages, ...updatedPage }
      };
    }

    case designsEntitiesTypes.DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS:
    case designsEntitiesTypes.DESIGN_UNAPPROVE_REQUEST: {
      if (action.pathname !== PATHS.workspaceDrafts) {
        return state;
      }

      const {
        request: {
          body: { designId }
        }
      } = action;

      let newIds = [designId].concat(state.pages[1] ? state.pages[1].ids : []);

      const updatedPage = {
        1: {
          isFetching: false,
          ids: newIds,
          didInvalidate: false
        }
      };

      return {
        ...state,
        pages: { ...state.pages, ...updatedPage }
      };
    }

    case designsEntitiesTypes.DESIGN_CANCEL_APPROVAL_REQUEST_SUCCESS:
    case designsEntitiesTypes.DESIGN_DECLINE_REQUEST_SUCCESS:
    case designsEntitiesTypes.DESIGN_APPROVE_REQUEST_SUCCESS: {
      if (action.pathname !== PATHS.workspaceDrafts) {
        return state;
      }

      const {
        request: {
          body: { designId }
        }
      } = action;

      const page = findPage(state.pages, designId);
      if (!page) return state;
      const imageIndex = state.pages[page].ids.indexOf(designId);

      const pageUpdated = {
        ...state.pages[page],
        ids: removeItem(state.pages[page].ids, imageIndex)
      };
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: pageUpdated
        }
      };
    }

    default:
      return state;
  }
};

export default pendingApprovalApiReducers;
