import React from "react";
import { connect } from "react-redux";

import { userIsAdmin } from "state/currentUser/currentUserSelectors";
import {
  fetchCoupon,
  fetchSubscriptionsIfNeeded,
  updateSubscription
} from "../../../state/entities/subscriptions/subscriptionsActions";
import { toggleModal } from "../../../state/ui/subscription/subscriptionActions";
import {
  fetchCards,
  addCard
} from "../../../state/entities/cards/cardsActions";
import { currentSubscriptionSelector } from "../../../state/entities/subscriptions/subscriptionsSelectors";
import { currentSubscriptionPlanSelector } from "../../../state/entities/subscriptionPlans/subscriptionPlansSelectors";
import { currentCardSelector } from "../../../state/entities/cards/cardsSelectors";

import { subscriptionPlansForCurrencySelector } from "state/ui/subscription/subscriptionSelectors";

import Plans from "./Plans";

const PlansContainer = props => <Plans {...props} />;

const mapStateToProps = state => {
  const cards = state.entities.cards;
  const subscriptions = state.entities.subscriptions;
  const currentSubscription = currentSubscriptionSelector(state);
  const currentPlan = currentSubscription
    ? currentSubscriptionPlanSelector(state, currentSubscription.planId)
    : null;

  const plans = subscriptionPlansForCurrencySelector({
    state,
    currency: currentPlan.currency
  });

  return {
    subscriptions,
    currentSubscription,
    currentPlan,
    cards,
    plans,
    isAdmin: userIsAdmin(state),
    coupon: state.entities.subscriptions.coupon,
    currentCard: currentCardSelector(state),
    currentTeam: state.ui.currentTeam,
    upgradeModalVisible: state.ui.subscription.modals.upgrade.visible,
    subscriptionChangeSubmitting:
      state.ui.subscription.modals.upgrade.submitting,
    creditCardModalVisible: state.ui.subscription.modals.creditCard.visible,
    creditCardChangeSubmitting:
      state.ui.subscription.modals.creditCard.submitting,
    subscriptionErrors: state.ui.subscription.modals.upgrade.errors,
    creditCardErrors: state.ui.subscription.modals.creditCard.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSubscriptionsIfNeeded: teamId =>
      dispatch(fetchSubscriptionsIfNeeded({ teamId })),
    onUpdateSubscription: args => dispatch(updateSubscription(args)),
    fetchCards: teamId => dispatch(fetchCards({ teamId })),
    fetchCoupon: (...args) => dispatch(fetchCoupon(...args)),
    onAddCard: args => dispatch(addCard(args)),
    onToggleModal: args => dispatch(toggleModal(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlansContainer);
