import React from "react";
import style from "./style.module.css";
import PropTypes from "prop-types";
import InfoHollowIcon from "views/components/icons/InfoHollowIcon";

const getThemeIconColour = themeName => {
  switch (themeName) {
    case "green":
      return "#3ab38d";
    case "yellow":
      return "#ffc100";
    case "red":
      return "#992a3b";
    case "black":
      return "#2c2e2f";
    case "blue":
      return "#3184fc";
    default:
      return "#1d9bd1";
  }
};

const InfoAlert = ({
  className,
  children,
  theme,
  icon: Icon = InfoHollowIcon,
  iconSize,
  hasIcon = true,
  spanClass
}) => {
  return (
    <div
      className={`${style.alert} ${className} ${style[theme]}`}
      data-testid="InfoAlert"
    >
      {hasIcon && (
        <Icon
          className={style.icon}
          size={iconSize || "20px"}
          color={getThemeIconColour(theme)}
          noPointer
        />
      )}
      <span className={spanClass}>{children}</span>
    </div>
  );
};

InfoAlert.displayName = "InfoAlert";

InfoAlert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default InfoAlert;
