import React, { Component } from "react";
import style from "./style.module.css";

import { calcCount } from "./utils";

import NotificationEvent from "./NotificationEvent";
import EmptyNotificationList from "./EmptyNotificationList";
import LoadingNotificationList from "./LoadingNotificationList";

import { ColoredLine } from "views/components";
import BellIcon from "views/components/icons/BellIcon";

class Notification extends Component {
  static defaultProps = {};
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.myRef = React.createRef();

    this.togglePopopout = this.togglePopopout.bind(this);
    this.renderList = this.renderList.bind(this);
    this.renderCount = this.renderCount.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.myRef.current &&
      !this.myRef.current.contains(event.target) &&
      event.target.isConnected
    ) {
      this.setState({ isOpen: false });
    }
  }

  togglePopopout() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  getCount() {
    if (!this.props.notifications) {
      return null;
    }

    return calcCount(this.props.notifications);
  }

  renderCount(count) {
    if (count) {
      return <div className={style.count}>{count}</div>;
    } else {
      return null;
    }
  }

  renderList(notificationsCount) {
    switch (notificationsCount) {
      case null: {
        return <LoadingNotificationList />;
      }

      case 0: {
        return <EmptyNotificationList />;
      }

      default: {
        return (
          <div className={style.notificationList}>
            {this.props.notifications.map(NotificationEvent)}
          </div>
        );
      }
    }
  }

  render() {
    if (!this.props.isBrandManager) {
      return null;
    }

    const notificationsCount = this.getCount();

    return (
      <div
        className={`${style.iconBox} ${this.props.wrapperClassName}`}
        ref={this.myRef}
      >
        <div onClick={this.togglePopopout} className={style.iconBox}>
          <BellIcon size="24px" color="white" version={2} />
          {this.renderCount(notificationsCount)}
        </div>
        {this.state.isOpen && (
          <div className={style.popout}>
            <div className={style.popoutHeader}>Notifications</div>
            <ColoredLine color="#e2e5e8" width="100%" margin="20px 0px 0px" />
            {this.renderList(notificationsCount)}
          </div>
        )}
      </div>
    );
  }
}

Notification.displayName = "Notification";

Notification.propTypes = {};

export default Notification;
