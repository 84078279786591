import * as types from "./stockAnimationsTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import { noop, isEmpty, getPath } from "lib";

/**
 * @desc refetches all Animation pages currently in the stockAnimations api state
 * this function overwrites the current pages with the new ones and should only be used
 * as a hard refresh of the stockAnimations
 */
export const refetchStockAnimationsPages = () => (dispatch, getState) => {
  const state = getState();

  const stockAnimationsStore = state.api.stockAnimations;

  const animationsPages = getPath(stockAnimationsStore, "animations.pages");
  const videosPages = getPath(stockAnimationsStore, "videos.pages");

  if (animationsPages) {
    Object.keys(animationsPages).forEach(page =>
      dispatch(fetchStockAnimations({ page }))
    );
  }
  if (videosPages) {
    Object.keys(videosPages).forEach(page =>
      dispatch(fetchStockVideos({ page }))
    );
  }
};

const shouldFetchAllStockAnimationsNextPage = (state, page) => {
  const stockAnimationsPages = state.api.stockAnimations.animations.pages;
  if (
    !stockAnimationsPages ||
    !stockAnimationsPages[page] ||
    stockAnimationsPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getAllStockAnimationNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchAllStockAnimationsNextPage(getState(), args.page)) {
    return dispatch(getAllStockAnimationNextPage(args));
  }
};

export const getAllStockAnimationNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();

  const stockAnimationsStore = state.api.stockAnimations;

  const pages = stockAnimationsStore.all.pages;

  if (isEmpty(pages)) {
    dispatch(fetchAllStockAnimations({ page: 1 }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(fetchAllStockAnimations({ page: 1, folderId }));
    return;
  }

  if (pages[lastPage].isFetching) {
    return;
  }

  if (pages[lastPage].ids.length < stockAnimationsStore.pageSize) {
    dispatch(fetchAllStockAnimations({ page: lastPage }));
  } else {
    dispatch(fetchAllStockAnimations({ page: lastPage + 1 }));
  }
};

const shouldFetchStockAnimationsNextPage = (state, page) => {
  const stockAnimationsPages = state.api.stockAnimations.animations.pages;

  const numberOfPages = Object.keys(stockAnimationsPages).length;

  if (
    !stockAnimationsPages[numberOfPages].ids ||
    stockAnimationsPages[numberOfPages]?.ids.length <
      state.api.stockAnimations.pageSize
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  if (
    !stockAnimationsPages ||
    !stockAnimationsPages[page] ||
    stockAnimationsPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getStockAnimationNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchStockAnimationsNextPage(getState(), args.page)) {
    return dispatch(getStockAnimationNextPage(args));
  }
};

export const getStockAnimationNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();

  const stockAnimationsStore = state.api.stockAnimations;

  const pages = stockAnimationsStore.animations.pages;

  if (isEmpty(pages)) {
    dispatch(fetchStockAnimations({ page: 1 }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(fetchStockAnimations({ page: 1, folderId }));
    return;
  }

  if (pages[lastPage].isFetching) {
    return;
  }

  if (pages[lastPage].ids.length < stockAnimationsStore.pageSize) {
    dispatch(fetchStockAnimations({ page: lastPage }));
  } else {
    dispatch(fetchStockAnimations({ page: lastPage + 1 }));
  }
};

const shouldFetchStockVideosNextPage = (state, page) => {
  const stockVideosPages = state.api.stockAnimations.videos.pages;

  const numberOfPages = Object.keys(stockVideosPages).length;

  if (
    !stockVideosPages[numberOfPages].ids ||
    stockVideosPages[numberOfPages]?.ids.length <
      state.api.stockAnimations.pageSize
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  if (
    !stockVideosPages ||
    !stockVideosPages[page] ||
    stockVideosPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getStockVideoNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchStockVideosNextPage(getState(), args.page)) {
    return dispatch(getStockVideoNextPage(args));
  }
};

export const getStockVideoNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();

  const stockAnimationsStore = state.api.stockAnimations;

  const pages = stockAnimationsStore.videos.pages;

  if (isEmpty(pages)) {
    dispatch(fetchStockVideos({ page: 1 }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(fetchStockVideos({ page: 1, folderId }));
    return;
  }

  if (pages[lastPage].isFetching) {
    return;
  }

  if (pages[lastPage].ids.length < stockAnimationsStore.pageSize) {
    dispatch(fetchStockVideos({ page: lastPage }));
  } else {
    dispatch(fetchStockVideos({ page: lastPage + 1 }));
  }
};

const stockAllAnimationTypes = [
  types.ALL_STOCK_ANIMATIONS_REQUEST,
  types.ALL_STOCK_ANIMATIONS_REQUEST_SUCCESS,
  types.ALL_STOCK_ANIMATIONS_REQUEST_FAILURE
];

export const fetchAllStockAnimations = ({
  params,
  page = 1,
  types = stockAllAnimationTypes
} = {}) => (dispatch, getState) => {
  const _params = {
    ...params,
    context: "stock_animation"
  };

  dispatch({
    [CALL_API]: {
      types: types,
      service: SERVICES.ASSET,
      endpoint: `/media`,
      request: {
        params: _params,
        page: page,
        pageSize: getState().api.stockAnimations.pageSize
      },
      schema: schemas.STOCK_ANIMATIONS_ARRAY,
      onSuccess: response => {}
    }
  });
};

const stockAnimationTypes = [
  types.STOCK_ANIMATIONS_REQUEST,
  types.STOCK_ANIMATIONS_REQUEST_SUCCESS,
  types.STOCK_ANIMATIONS_REQUEST_FAILURE
];

export const fetchStockAnimations = ({ params, page = 1 } = {}) => dispatch => {
  const _params = {
    ...params,
    type: "ANIMATION"
  };

  return dispatch(
    fetchAllStockAnimations({
      params: _params,
      page,
      types: stockAnimationTypes
    })
  );
};

const stockVideoTypes = [
  types.STOCK_VIDEOS_REQUEST,
  types.STOCK_VIDEOS_REQUEST_SUCCESS,
  types.STOCK_VIDEOS_REQUEST_FAILURE
];

export const fetchStockVideos = ({ params, page = 1 } = {}) => dispatch => {
  const _params = {
    ...params,
    type: "VIDEO"
  };

  return dispatch(
    fetchAllStockAnimations({ params: _params, page, types: stockVideoTypes })
  );
};

export const deleteStockAnimation = ({
  mediaId,
  folderId,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      types: [
        types.STOCK_ANIMATION_DELETE_REQUEST,
        types.STOCK_ANIMATION_DELETE_REQUEST_SUCCESS,
        types.STOCK_ANIMATION_DELETE_REQUEST_FAILURE
      ],
      request: {
        mediaId,
        extra: {
          folderId
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/animations/${mediaId}`,
      schema: schemas.NONE,
      onSuccess: response => onSuccess(response)
    }
  });
};

export const updateStockAnimation = ({
  mediaId,
  folderId,
  image: animation,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "PUT",
      types: [
        types.STOCK_ANIMATION_UPDATE_REQUEST,
        types.STOCK_ANIMATION_UPDATE_REQUEST_SUCCESS,
        types.STOCK_ANIMATION_UPDATE_REQUEST_FAILURE
      ],
      request: {
        body: {
          mediaId,
          folderId,
          userId,
          teamId,
          ...animation
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/animations/${mediaId}`,
      schema: schemas.NONE,
      onSuccess: response => onSuccess(response)
    }
  });
};

/**
 * @desc take an image file and uploads it to the users team images
 * @param {Object} args
 * @param {Object} args.file - an object that describes the file to upload from the file picker
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which runs when the process succeeds passed the result of the request
 */
export const uploadStockAnimation = ({
  imageFile: file,
  onSuccess,
  duration,
  onPresignedUrlCreated = noop,
  noRefetch,
  placeholderURL
}) => async (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  // synchronously generate a pre signed url for the direct upload
  const preSignedUrl = await generatePreSignedUrlPromise({ file, teamId });
  onPresignedUrlCreated(preSignedUrl.id);

  // synchronously upload the image file to the pre signed url
  await uploadAnimationToPreSignedUrlPromise({
    file,
    preSignedUrl: preSignedUrl.url
  });

  // make update request to the api
  dispatch(
    uploadStockAnimationApiCall({
      duration,
      fileId: preSignedUrl.id,
      filename: preSignedUrl.filename,
      noRefetch,
      onSuccess,
      placeholderURL
    })
  );

  /**
   * @desc taking a file object and makes a request for a pre signed image upload url and returns it on resolution
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @returns {Promise} results in a Promise that resolves when the pre signed url is retrieved containing a 'url' key
   */
  function generatePreSignedUrlPromise({ file }) {
    return new Promise((resolve, reject) => {
      dispatch(
        createStockAnimationUploadUrl({
          filename: file.name,
          responseContentType: file.type,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }

  /**
   * @desc taking a file and a pre signed url processes the upload of the file to the passed url
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
   * @returns {Promise} results in a Promise that resolves when the image has been successfully uploaded
   */
  function uploadAnimationToPreSignedUrlPromise({ file, preSignedUrl }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadAnimationToPreSignedUrl({
          imageFile: file,
          preSignedUrl: preSignedUrl,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }
};

/**
 * @desc adds the details for an uploaded image to the api
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.fileId - the id for the file that is being uploaded
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which is run when the request succeeds and is passed the results of the request
 */
export const uploadStockAnimationApiCall = ({
  duration,
  filename,
  fileId,
  onSuccess = noop,
  noRefetch,
  placeholderURL
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      types: [
        types.STOCK_ANIMATION_UPLOAD_REQUEST,
        types.STOCK_ANIMATION_UPLOAD_REQUEST_SUCCESS,
        types.STOCK_ANIMATION_UPLOAD_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/animations`,
      schema: schemas.NONE,
      request: {
        body: {
          duration,
          id: fileId,
          filename,
          userId,
          teamId
        }
      },
      extra: {
        placeholderURL
      },
      onSuccess: response => {
        if (!noRefetch) {
          dispatch(refetchStockAnimationsPages());
        }
        onSuccess(response);
      }
    }
  });
};

/**
 * @desc makes the api request for a pre signed url
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.responseContentType - the type extension for the file to be uploaded
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const createStockAnimationUploadUrl = ({
  filename,
  responseContentType,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.STOCK_ANIMATION_UPLOAD_URL_REQUEST,
        types.STOCK_ANIMATION_UPLOAD_URL_REQUEST_SUCCESS,
        types.STOCK_ANIMATION_UPLOAD_URL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/animations/url`,
      request: {
        body: {
          userId,
          teamId,
          filename,
          responseContentType
        }
      },
      onSuccess: onSuccess,
      schema: schemas.NONE
    }
  });
};

/**
 * @desc using the pre signed url passed processes the upload of a file
 * @param {Object} args
 * @param {Object} args.imageFile - an object that describes the file selected by the user in file picker
 * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const uploadAnimationToPreSignedUrl = ({
  imageFile,
  preSignedUrl,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const headers = new Headers({
    "Content-Type": imageFile.type
  });

  const requestSpecs = {
    method: "PUT",
    headers,
    body: imageFile
  };

  fetch(preSignedUrl, requestSpecs)
    .then(function(response) {
      if (!response.ok) {
        throw Error(
          `Image could not be upload. Error Message: ${response.statusText}`
        );
      }
      return response;
    })
    .then(onSuccess)
    .catch(onFailure);
};

export const addUploadingAnimationsPlaceholders = ({
  mediaPlaceholders,
  folderId
}) => {
  return {
    type: types.ADD_STOCK_ANIMATIONS_PLACEHOLDERS,
    mediaPlaceholders,
    folderId
  };
};
