import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FavoriteFolderIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill={color || easilGrayXDark} fillRule="nonzero">
      <path d="M12 20v-2h5a1 1 0 0 0 1-1V6.444a1 1 0 0 0-1-1h-4a2 2 0 0 1-1.172-.379L7.85 2.19A1 1 0 0 0 7.264 2H3a1 1 0 0 0-1 1v5H0V3a3 3 0 0 1 3-3h4.264a3 3 0 0 1 1.758.569L13 3.444h4a3 3 0 0 1 3 3V17a3 3 0 0 1-3 3h-5zm0-2h5a1 1 0 0 0 1-1V6.444a1 1 0 0 0-1-1h-4a2 2 0 0 1-1.172-.379L7.85 2.19A1 1 0 0 0 7.264 2H3a1 1 0 0 0-1 1v5H0a1 1 0 1 1 2 0 1 1 0 0 1-2 0V3a3 3 0 0 1 3-3h4.264a3 3 0 0 1 1.758.569L13 3.444h4a3 3 0 0 1 3 3V17a3 3 0 0 1-3 3h-5a1 1 0 0 1 0-2z" />
      <path d="M4.739 18.083zM7 14.785c0-.418-.392-.785-.909-.785a.941.941 0 0 0-.572.208c-.113.085-.2.18-.205.191a1 1 0 0 1-1.628 0A1.022 1.022 0 0 0 2.909 14c-.517 0-.909.367-.909.785 0 .874.944 1.944 2.5 3.12 1.55-1.17 2.5-2.243 2.5-3.12zM4.5 20c-.357 0-.7-.117-.961-.317C1.309 18.046 0 16.62 0 14.785 0 13.231 1.317 12 2.909 12a2.88 2.88 0 0 1 1.612.467A2.872 2.872 0 0 1 6.091 12C7.683 12 9 13.231 9 14.785c0 1.835-1.306 3.258-3.533 4.893A1.606 1.606 0 0 1 4.5 20zm.814-5.601a1 1 0 1 1-1.628-1.161 1 1 0 0 1 1.628 1.16z" />
    </g>
  </svg>
);
FavoriteFolderIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FavoriteFolderIcon;
