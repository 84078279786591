import * as types from "state/entities/teamImages/teamImagesTypes";
import { findPage } from "../helpers";
import { omit, isEmpty } from "lib";
import { removeItem } from "lib/array/array";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 100
};

export const handleTeamImagesRequest = ({ action, state }) => {
  const { page } = action.request;
  return {
    ...state,
    pages: {
      ...state.pages,
      [page]: {
        ...state.pages[page],
        isFetching: true
      }
    }
  };
};

export const handleTeamImagesRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      pages: omit(state.pages, action.request.page)
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    pages: { ...state.pages, ...updatedPage }
  };
};

const teamImagesApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_IMAGES_REQUEST: {
      return handleTeamImagesRequest({ state, action });
    }

    case types.TEAM_IMAGES_REQUEST_SUCCESS: {
      return handleTeamImagesRequestSuccess({ state, action });
    }

    case types.TEAM_DELETE_IMAGE_REQUEST: {
      const { mediaId } = action.extra;

      const page = findPage(state.pages, mediaId);
      const imageIndex = state.pages[page].ids.indexOf(mediaId);

      const pageUpdated = {
        ...state.pages[page],
        ids: removeItem(state.pages[page].ids, imageIndex)
      };
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: pageUpdated
        }
      };
    }

    default:
      return state;
  }
};

export default teamImagesApiReducers;
