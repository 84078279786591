import React from "react";
import GridCellDraggableImage from "./GridCellDraggableImage";
import { filterSettingsToCSS, toStyleString } from "lib/stringToFilter";
import style from "./style.module.css";
import { Image, Video } from "views/components";
import { getPlaceholderSize } from "views/components/Editor/elements/grid/imageInstructionUtils";
import { isVideo } from "lib/isVideo";

const GridCellCropping = ({
  canvasBackground,
  pageOffset,
  gridElement,
  gridCell,
  zoom,
  isCropping,
  startGridCellCropMoving,
  finishGridCellCropMoving,
  gridCellHtmlElement
}) => {
  const cancelClick = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const {
    offsetTop,
    offsetLeft,
    clientWidth,
    clientHeight
  } = gridCellHtmlElement;

  const placeholderSize = getPlaceholderSize(gridCell, gridElement);

  const transformedLeft =
    gridCell.scaleX === 1
      ? gridCell.left
      : placeholderSize.width - (gridCell.width + gridCell.left);
  const transformedTop =
    gridCell.scaleY === 1
      ? gridCell.top
      : placeholderSize.height - (gridCell.height + gridCell.top);

  const hex = filterSettingsToCSS(gridCell.filters);
  const filters = toStyleString(hex, zoom);

  return (
    <div
      onClick={cancelClick}
      style={{
        height: canvasBackground.offsetHeight,
        width: canvasBackground.offsetWidth,
        overflow: "hidden",
        position: "absolute",
        top: 0,
        left: 0
      }}
    >
      <div
        className={style.gridMasking}
        style={{
          height: canvasBackground.offsetHeight,
          width: canvasBackground.offsetWidth,
          overflow: "hidden"
        }}
      />
      <GridCellDraggableImage
        isCropping={isCropping}
        offsetLeft={offsetLeft}
        offsetTop={offsetTop}
        pageOffset={pageOffset}
        gridCell={gridCell}
        gridElement={gridElement}
        zoom={zoom}
        startGridCellCropMoving={startGridCellCropMoving}
        finishGridCellCropMoving={finishGridCellCropMoving}
      />
      <div
        style={{
          position: "absolute",
          top: pageOffset.top + gridElement.top * zoom,
          left: pageOffset.left + gridElement.left * zoom,
          height: gridElement.height * zoom,
          width: gridElement.width * zoom,
          zIndex: 11,
          pointerEvents: "none",
          opacity: isCropping ? 0 : 1
        }}
      />
      <div id={gridElement.uniqueId}>
        <div
          style={{
            position: "absolute",
            top: pageOffset.top + gridElement.top * zoom,
            left: pageOffset.left + gridElement.left * zoom,
            height: gridElement.height * zoom,
            width: gridElement.width * zoom,
            zIndex: 4,
            opacity: isCropping ? 0 : 1,
            transform: `rotate(${gridElement.angle}deg) scaleX(${gridElement.scaleX}) scaleY(${gridElement.scaleY})`,
            pointerEvents: "none"
          }}
        >
          <div
            style={{
              position: "absolute",
              top: offsetTop * zoom,
              left: offsetLeft * zoom,
              height: clientHeight * zoom,
              width: clientWidth * zoom,
              overflow: "hidden",
              zIndex: 4,
              opacity: isCropping ? 0 : 1,
              filter: filters
            }}
          >
            <div
              onClick={cancelClick}
              style={{
                position: "absolute",
                zIndex: 4,
                opacity: isCropping ? 0 : 1,
                transform: `translate(${transformedLeft *
                  zoom}px, ${transformedTop * zoom}px) scaleX(${
                  gridCell.scaleX
                }) scaleY(${gridCell.scaleY})`
              }}
            >
              {isVideo(gridCell.previewSrc) ? (
                <Video
                  src={gridCell.previewSrc}
                  height={gridCell.height * zoom}
                  width={gridCell.width * zoom}
                  isLandscape={true}
                  autoPlay
                />
              ) : (
                <Image
                  src={gridCell.previewSrc}
                  height={gridCell.height * zoom}
                  width={gridCell.width * zoom}
                  isLandscape={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridCellCropping;
