import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const EmptyNotificationIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  secondaryColor,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 45 47"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-89">
        <g id="Group-29">
          <rect
            id="Rectangle"
            fill={secondaryColor || "#FCE068"}
            transform="translate(24.000000, 26.000000) rotate(90.000000) translate(-24.000000, -26.000000) "
            x="3"
            y="5"
            width="42"
            height="42"
            rx="20"
          />
          <path
            d="M21,0 C32.5979797,0 42,9.40202025 42,21 C42,32.5979797 32.5979797,42 21,42 C9.40202025,42 0,32.5979797 0,21 C0,9.40202025 9.40202025,0 21,0 Z M21,2 C10.5065898,2 2,10.5065898 2,21 C2,31.4934102 10.5065898,40 21,40 C31.4934102,40 40,31.4934102 40,21 C40,10.5065898 31.4934102,2 21,2 Z M23.2317719,29.135 C23.7094982,29.4121209 23.8721209,30.0240456 23.595,30.5017719 C23.0583034,31.4269784 22.0696029,31.9964563 21,31.9964563 C19.9303971,31.9964563 18.9416966,31.4269784 18.405,30.5017719 C18.1278791,30.0240456 18.2905018,29.4121209 18.7682281,29.135 C19.2118311,28.8776734 19.7711459,28.9995136 20.0706633,29.4007267 L20.2000436,29.5965394 C20.3874689,29.8464703 20.6830806,29.9964563 21,29.9964563 C21.3169194,29.9964563 21.6125311,29.8464703 21.7999564,29.5965394 L21.865,29.4982281 C22.1421209,29.0205018 22.7540456,28.8578791 23.2317719,29.135 Z M21,10 C24.8659932,10 28,13.1340068 28,17 C28,19.8997539 28.5229652,22.0787755 29.3778956,23.6461479 C29.6641519,24.1709511 29.9644664,24.5813809 30.2572027,24.8903804 C30.3889657,25.0294635 30.4848774,25.11427 30.5322227,25.1513653 L30.5547002,25.1679497 C31.3450079,25.6948215 31.0185515,26.898517 30.1158056,26.9939696 L30,27 L12,27 C11.0501684,27 10.6541064,25.8173794 11.3522887,25.2372047 L11.4452998,25.1679497 C11.478192,25.1460216 11.5846818,25.0572801 11.7427973,24.8903804 C12.0355336,24.5813809 12.3358481,24.1709511 12.6221044,23.6461479 C13.4342883,22.1571441 13.9468736,20.1161269 13.9961016,17.4295712 L14,17 C14,13.1340068 17.1340068,10 21,10 Z M21,12 C18.3112453,12 16.1181819,14.1223067 16.0046195,16.7831104 L16,17 C16,20.2252461 15.3979652,22.7337245 14.3778956,24.6038521 L14.2396793,24.8493814 L14.148,25 L27.851,25 L27.7603207,24.8493814 L27.6221044,24.6038521 C26.6506096,22.8227782 26.0582846,20.4627013 26.0040756,17.4558819 L26,17 L25.9953805,16.7831104 C25.8818181,14.1223067 23.6887547,12 21,12 Z"
            id="Combined-Shape"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
          />
          <g id="Group-26" transform="translate(9.000000, 9.000000)">
            <g id="Rectangle-3">
              <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            </g>
            <g id="bell" transform="translate(1.000000, 1.000000)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
EmptyNotificationIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default EmptyNotificationIcon;
