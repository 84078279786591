import * as types from "./userTeamAnimationsTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import { noop, isEmpty, getPath } from "lib";
import { getAssetTypeFromQueryParams } from "views/components/Editor/sidebar/tabs/animations/animationUtils";

/**
 * @desc refetches all Animation pages currently in the userTeamAnimations api state
 * this function overwrites the current pages with the new ones and should only be used
 * as a hard refresh of the userTeamAnimations
 */
export const refetchUserTeamAnimationsPages = () => (dispatch, getState) => {
  const state = getState();

  const userAnimationsStore = state.api.userTeamAnimations;

  const animationsPages = getPath(userAnimationsStore, "animations.pages");
  const videosPages = getPath(userAnimationsStore, "videos.pages");

  if (animationsPages) {
    Object.keys(animationsPages).forEach(page =>
      dispatch(fetchUserTeamAnimations({ page, type: "ANIMATION" }))
    );
  }
  if (videosPages) {
    Object.keys(videosPages).forEach(page =>
      dispatch(fetchUserTeamVideos({ page, type: "VIDEO" }))
    );
  }
};

export const clearUserTeamAnimationState = () => ({
  type: types.USER_TEAM_ANIMATIONS_CLEAR
});

export const refetchUserTeamAnimations = args => dispatch => {
  dispatch(clearUserTeamAnimationState());
  args.folderId
    ? dispatch(getUserAnimationInFolderNextPage(args))
    : dispatch(fetchAllUserTeamAnimations(args));
};

const shouldFetchAllUserAnimationsNextPage = (state, page) => {
  const userTeamAnimationsPages = state.api.userTeamAnimations.all.pages;
  if (
    !userTeamAnimationsPages ||
    !userTeamAnimationsPages[page] ||
    userTeamAnimationsPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getAllUserAnimationNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchAllUserAnimationsNextPage(getState(), args.page)) {
    return dispatch(getAllUserAnimationNextPage(args));
  }
};

export const getAllUserAnimationNextPage = ({
  page,
  folderId,
  sortBy,
  sortOrder
} = {}) => (dispatch, getState) => {
  const state = getState();

  const userAnimationsStore = state.api.userTeamAnimations;

  const pages = userAnimationsStore.all.pages;

  if (isEmpty(pages)) {
    dispatch(fetchAllUserTeamAnimations({ page: 1 }));
    return;
  }

  if (page) {
    dispatch(fetchAllUserTeamAnimations({ page, folderId, sortBy, sortOrder }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(
      fetchAllUserTeamAnimations({ page: 1, folderId, sortBy, sortOrder })
    );
    return;
  }

  if (pages[lastPage].isFetching) {
    return;
  }

  if (pages[lastPage].ids.length < userAnimationsStore.pageSize) {
    dispatch(fetchAllUserTeamAnimations({ page: lastPage, sortBy, sortOrder }));
  } else {
    dispatch(
      fetchAllUserTeamAnimations({ page: lastPage + 1, sortBy, sortOrder })
    );
  }
};

const shouldFetchUserAnimationsNextPage = (state, page) => {
  const userTeamAnimationsPages = state.api.userTeamAnimations.animations.pages;

  const numberOfPages = Object.keys(userTeamAnimationsPages).length;

  if (
    !userTeamAnimationsPages[numberOfPages].ids ||
    userTeamAnimationsPages[numberOfPages].ids.length <
      state.api.userTeamAnimations.pageSize
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  if (
    !userTeamAnimationsPages ||
    !userTeamAnimationsPages[page] ||
    userTeamAnimationsPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getUserAnimationNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchUserAnimationsNextPage(getState(), args.page)) {
    return dispatch(getUserAnimationNextPage(args));
  }
};

export const getUserAnimationNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();

  const userAnimationsStore = state.api.userTeamAnimations;

  const pages = userAnimationsStore.animations.pages;

  if (isEmpty(pages)) {
    dispatch(fetchUserTeamAnimations({ page: 1 }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(fetchUserTeamAnimations({ page: 1, folderId }));
    return;
  }

  if (pages[lastPage].isFetching) {
    return;
  }

  if (pages[lastPage].ids.length < userAnimationsStore.pageSize) {
    dispatch(fetchUserTeamAnimations({ page: lastPage }));
  } else {
    dispatch(fetchUserTeamAnimations({ page: lastPage + 1 }));
  }
};

export const getUserAnimationInFolderNextPage = ({
  folderId,
  type,
  sortBy,
  sortOrder
}) => (dispatch, getState) => {
  const state = getState();
  const userAnimationsFolderStore = state.api.userTeamAnimations.folders;
  const selectedFolderStore = userAnimationsFolderStore[folderId];
  const assetType = getAssetTypeFromQueryParams(window.location);

  if (
    isEmpty(userAnimationsFolderStore) ||
    !selectedFolderStore ||
    isEmpty(selectedFolderStore) ||
    !selectedFolderStore[assetType] ||
    isEmpty(selectedFolderStore[assetType])
  ) {
    dispatch(
      fetchUserTeamAnimationsInFolder({
        page: 1,
        folderId,
        type,
        sortBy,
        sortOrder
      })
    );
    return;
  }

  const pages = selectedFolderStore[assetType].pages;

  const lastPage = Math.max(...Object.keys(pages));

  if (pages[lastPage].isFetching) {
    return;
  }

  let page = lastPage;

  /* refetch the last page if not complete, or get next page */
  if (pages[lastPage].ids.length >= state.api.userTeamAnimations.pageSize) {
    page = lastPage + 1;
  }

  dispatch(fetchUserTeamAnimationsInFolder({ folderId, page, type }));
};

export const fetchUserTeamAnimationsInFolder = ({
  page = 1,
  folderId,
  type,
  sortBy = "updatedAt",
  sortOrder = "desc"
}) => (dispatch, getState) => {
  const actionsTypes = [
    types.USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST,
    types.USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS,
    types.USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST_FAILURE
  ];

  const params = {
    folderId,
    sortBy,
    sortOrder: sortOrder.toLowerCase()
  };

  if (type) {
    params.type = type;
  }

  dispatch(
    fetchUserTeamAnimations({
      types: actionsTypes,
      params,
      page
    })
  );
};

const shouldFetchUserVideosNextPage = (state, page) => {
  const userTeamVideosPages = state.api.userTeamAnimations.videos.pages;
  const numberOfPages = Object.keys(userTeamVideosPages).length;

  if (
    !userTeamVideosPages[numberOfPages]?.ids ||
    userTeamVideosPages[numberOfPages]?.ids?.length <
      state.api.userTeamAnimations.pageSize
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  if (
    !userTeamVideosPages ||
    !userTeamVideosPages[page] ||
    userTeamVideosPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getUserVideoNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchUserVideosNextPage(getState(), args.page)) {
    return dispatch(getUserVideoNextPage(args));
  }
};

export const getUserVideoNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();

  const userAnimationsStore = state.api.userTeamAnimations;

  const pages = userAnimationsStore.videos.pages;

  if (isEmpty(pages)) {
    dispatch(fetchUserTeamVideos({ page: 1 }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(fetchUserTeamVideos({ page: 1, folderId }));
    return;
  }

  if (pages[lastPage].isFetching) {
    return;
  }

  if (pages[lastPage].ids.length < userAnimationsStore.pageSize) {
    dispatch(fetchUserTeamVideos({ page: lastPage }));
  } else {
    dispatch(fetchUserTeamVideos({ page: lastPage + 1 }));
  }
};

const userAllTeamAnimationTypes = [
  types.ALL_USER_TEAM_ANIMATIONS_REQUEST,
  types.ALL_USER_TEAM_ANIMATIONS_REQUEST_SUCCESS,
  types.ALL_USER_TEAM_ANIMATIONS_REQUEST_FAILURE
];

export const fetchAllUserTeamAnimations = ({
  params,
  page = 1,
  types = userAllTeamAnimationTypes,
  sortBy = "updatedAt",
  sortOrder = "desc"
} = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  const _params = {
    ...params,
    sortBy,
    sortOrder: sortOrder.toLowerCase()
  };

  dispatch({
    [CALL_API]: {
      types: types,
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/animations`,
      request: {
        params: _params,
        page: page,
        pageSize: getState().api.userTeamAnimations.pageSize
      },
      schema: schemas.USER_TEAM_ANIMATIONS_ARRAY,
      onSuccess: response => {}
    }
  });
};

const userTeamAnimationTypes = [
  types.USER_TEAM_ANIMATIONS_REQUEST,
  types.USER_TEAM_ANIMATIONS_REQUEST_SUCCESS,
  types.USER_TEAM_ANIMATIONS_REQUEST_FAILURE
];

export const fetchUserTeamAnimations = ({
  params,
  page = 1,
  types = userTeamAnimationTypes
} = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      types: types,
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/animations`,
      request: {
        params,
        page: page,
        pageSize: getState().api.userTeamAnimations.pageSize
      },
      schema: schemas.USER_TEAM_ANIMATIONS_ARRAY,
      onSuccess: response => {}
    }
  });
};

const userTeamVideoTypes = [
  types.USER_TEAM_VIDEOS_REQUEST,
  types.USER_TEAM_VIDEOS_REQUEST_SUCCESS,
  types.USER_TEAM_VIDEOS_REQUEST_FAILURE
];

export const fetchUserTeamVideos = ({
  params,
  page = 1,
  types = userTeamVideoTypes
} = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  const _params = {
    ...params,
    type: "VIDEO"
  };

  dispatch({
    [CALL_API]: {
      types: types,
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/animations`,
      request: {
        params: _params,
        page: page,
        pageSize: getState().api.userTeamAnimations.pageSize
      },
      schema: schemas.USER_TEAM_ANIMATIONS_ARRAY,
      onSuccess: response => {}
    }
  });
};

export const deleteUserTeamAnimation = ({
  mediaId,
  folderId,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      types: [
        types.USER_TEAM_ANIMATION_DELETE_REQUEST,
        types.USER_TEAM_ANIMATION_DELETE_REQUEST_SUCCESS,
        types.USER_TEAM_ANIMATION_DELETE_REQUEST_FAILURE
      ],
      request: {
        mediaId,
        extra: {
          folderId
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/animations/${mediaId}`,
      schema: schemas.NONE,
      onSuccess: response => onSuccess(response)
    }
  });
};

export const deleteUserTeamVideos = (response, folderId, onSuccess = noop) => (
  dispatch,
  getState
) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  const deletionId = response.id;
  const mediaIds = response.mediaIds;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      types: [
        types.USER_TEAM_VIDEOS_DELETE_REQUEST,
        types.USER_TEAM_VIDEOS_DELETE_REQUEST_SUCCESS,
        types.USER_TEAM_VIDEOS_DELETE_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/videos/deletions/${deletionId}`,
      schema: schemas.NONE,
      extra: {
        mediaIds,
        folderId
      },
      onSuccess: () => onSuccess()
    }
  });
};

export const updateUserTeamAnimation = ({
  mediaId,
  folderId,
  image: animation,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  // prevent error response when sending blob url
  if (animation.thumbnailUrl.startsWith("blob:")) {
    animation.thumbnailUrl = animation.previewUrl;
  }

  dispatch({
    [CALL_API]: {
      method: "PUT",
      types: [
        types.USER_TEAM_ANIMATION_UPDATE_REQUEST,
        types.USER_TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS,
        types.USER_TEAM_ANIMATION_UPDATE_REQUEST_FAILURE
      ],
      request: {
        body: {
          mediaId,
          folderId,
          userId,
          teamId,
          ...animation
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/animations/${mediaId}`,
      schema: schemas.NONE,
      onSuccess: response => onSuccess(response)
    }
  });
};

/**
 * @desc take an image file and uploads it to the users team images
 * @param {Object} args
 * @param {Object} args.file - an object that describes the file to upload from the file picker
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which runs when the process succeeds passed the result of the request
 */
export const uploadUserTeamAnimation = ({
  imageFile: file,
  onSuccess,
  duration,
  onPresignedUrlCreated = noop,
  noRefetch,
  placeholderURL,
  storageKey = "all"
}) => async (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  // synchronously generate a pre signed url for the direct upload
  const preSignedUrl = await generatePreSignedUrlPromise({ file, teamId });
  onPresignedUrlCreated(preSignedUrl.id);

  // synchronously upload the image file to the pre signed url
  await uploadAnimationToPreSignedUrlPromise({
    file,
    preSignedUrl: preSignedUrl.url
  });

  // make update request to the api
  dispatch(
    uploadUserTeamAnimationApiCall({
      duration,
      fileId: preSignedUrl.id,
      filename: preSignedUrl.filename,
      noRefetch,
      onSuccess,
      placeholderURL,
      storageKey
    })
  );

  /**
   * @desc taking a file object and makes a request for a pre signed image upload url and returns it on resolution
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @returns {Promise} results in a Promise that resolves when the pre signed url is retrieved containing a 'url' key
   */
  function generatePreSignedUrlPromise({ file }) {
    return new Promise((resolve, reject) => {
      dispatch(
        createUserAnimationUploadUrl({
          filename: file.name,
          responseContentType: file.type,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }

  /**
   * @desc taking a file and a pre signed url processes the upload of the file to the passed url
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
   * @returns {Promise} results in a Promise that resolves when the image has been successfully uploaded
   */
  function uploadAnimationToPreSignedUrlPromise({ file, preSignedUrl }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadAnimationToPreSignedUrl({
          imageFile: file,
          preSignedUrl: preSignedUrl,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }
};

/**
 * @desc adds the details for an uploaded image to the api
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.fileId - the id for the file that is being uploaded
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which is run when the request succeeds and is passed the results of the request
 */
export const uploadUserTeamAnimationApiCall = ({
  duration,
  filename,
  fileId,
  onSuccess = noop,
  noRefetch,
  placeholderURL,
  storageKey
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      types: [
        types.USER_TEAM_ANIMATION_UPLOAD_REQUEST,
        types.USER_TEAM_ANIMATION_UPLOAD_REQUEST_SUCCESS,
        types.USER_TEAM_ANIMATION_UPLOAD_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/animations`,
      schema: schemas.NONE,
      request: {
        body: {
          duration,
          id: fileId,
          filename,
          userId,
          teamId
        }
      },
      extra: {
        placeholderURL,
        storageKey
      },
      onSuccess
    }
  });
};

/**
 * @desc makes the api request for a pre signed url
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.responseContentType - the type extension for the file to be uploaded
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const createUserAnimationUploadUrl = ({
  filename,
  responseContentType,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.USER_ANIMATION_UPLOAD_URL_REQUEST,
        types.USER_ANIMATION_UPLOAD_URL_REQUEST_SUCCESS,
        types.USER_ANIMATION_UPLOAD_URL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/animations/url`,
      request: {
        body: {
          userId,
          teamId,
          filename,
          responseContentType
        }
      },
      onSuccess: onSuccess,
      schema: schemas.NONE
    }
  });
};

/**
 * @desc using the pre signed url passed processes the upload of a file
 * @param {Object} args
 * @param {Object} args.imageFile - an object that describes the file selected by the user in file picker
 * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const uploadAnimationToPreSignedUrl = ({
  imageFile,
  preSignedUrl,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const headers = new Headers({
    "Content-Type": imageFile.type
  });

  const requestSpecs = {
    method: "PUT",
    headers,
    body: imageFile
  };

  fetch(preSignedUrl, requestSpecs)
    .then(function(response) {
      if (!response.ok) {
        throw Error(
          `Image could not be upload. Error Message: ${response.statusText}`
        );
      }
      return response;
    })
    .then(onSuccess)
    .catch(onFailure);
};

export const addUploadingAnimationsPlaceholders = ({
  mediaPlaceholders,
  folderId,
  mediaType
}) => {
  return {
    type: types.ADD_USER_TEAM_ANIMATIONS_PLACEHOLDERS,
    mediaPlaceholders,
    folderId,
    mediaType
  };
};

export const clearUserTeamAnimationsUploadErrors = () => {
  return {
    type: types.CLEAR_USER_TEAM_ANIMATION_UPLOAD_ERRORS
  };
};
