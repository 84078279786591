import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CircledCheckIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  isDashedCircle,
  noPadding = false
}) => {
  if (isDashedCircle) {
    return (
      <svg
        className={className}
        style={style}
        data-no-pointer={noPointer}
        width={width || size || 24}
        height={height || size || 24}
        viewBox={noPadding ? "2 2 20 20" : "0 0 24 24"}
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <title>{title}</title>
        <g fill="none" fillRule="evenodd">
          <path
            d="M14.491 20.706a1 1 0 01-.819 1.153l-.106.018a9.993 9.993 0 01-3.227-.013 1 1 0 11.332-1.973 7.97 7.97 0 002.21.06l.457-.064a1 1 0 011.153.82zm-8.998-4.049a8.027 8.027 0 001.893 1.879 1 1 0 11-1.153 1.634 10.027 10.027 0 01-2.366-2.348 1 1 0 011.626-1.165zm14.412-.24a1 1 0 01.233 1.394c-.65.91-1.447 1.706-2.36 2.353a1 1 0 01-1.156-1.632 8.026 8.026 0 001.889-1.883 1 1 0 011.394-.233zm-3.808-7.633a1 1 0 01.096 1.32l-.083.094-4.413 4.5a1 1 0 01-1.33.089l-.094-.085-1.985-2a1 1 0 011.326-1.492l.094.084 1.271 1.28 3.704-3.776a1 1 0 011.414-.014zm-12.822.781a1 1 0 01.827 1.148 7.971 7.971 0 00-.053 2.175l.071.493a1 1 0 01-1.97.345l-.026-.159a9.996 9.996 0 01.004-3.175 1 1 0 011.147-.827zm18.568.702l.034.167a9.99 9.99 0 01-.023 3.281 1 1 0 11-1.97-.343 7.968 7.968 0 00.068-2.247l-.058-.42a1 1 0 011.949-.438zM7.53 4.122a1 1 0 01-.222 1.396 8.029 8.029 0 00-1.869 1.904A1 1 0 113.8 6.277 10.029 10.029 0 016.134 3.9a1 1 0 011.396.223zm10.325-.228a10.024 10.024 0 012.34 2.372 1 1 0 01-1.638 1.148 8.024 8.024 0 00-1.873-1.9 1 1 0 011.171-1.62zM13.77 2.155a1 1 0 01-.354 1.969 7.987 7.987 0 00-2.666-.026 1 1 0 11-.313-1.975 9.986 9.986 0 013.333.032z"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox={noPadding ? "2 2 20 20" : "0 0 24 24"}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 100 16 8 8 0 000-16zm4.099 4.786a1 1 0 01.096 1.32l-.083.094-4.413 4.5a1 1 0 01-1.33.089l-.094-.085-1.985-2a1 1 0 011.326-1.492l.094.084 1.271 1.28L14.685 8.8a1 1 0 011.414-.014z"
          fill={color || easilGrayXDark}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
CircledCheckIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CircledCheckIcon;
