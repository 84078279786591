import { throttle, isNil } from "lib";
import { CALL_API } from "state/middleware/api";
import PATHS from "routes/paths";
import {
  FETCH_APPROVAL_NOTIFICATIONS_REQUEST,
  FETCH_APPROVAL_NOTIFICATIONS_FAILURE,
  FETCH_APPROVAL_NOTIFICATIONS_SUCCESS
} from "state/ui/notification/notificationTypes";
import { fetchCurrentUserNotifications } from "state/ui/notification/notificationActions";

const dispatch = store => {
  store.dispatch(fetchCurrentUserNotifications());
};

const throttled = throttle(
  dispatch,
  120000, // 2 minutes
  { leading: true, trailing: false }
);

/* This middleware is resposible for refetching user's notification,
whenever there is user activity, and throttle the fetch by 2 minutes. Be
aware that the fetch is done on the leading of user activity. */
export const notification = store => next => action => {
  function shouldRefetchNotifications(state, action) {
    if (isApiCall(action)) {
      return false;
    }

    if (isFetchApprovalType(action)) {
      return false;
    }

    /* if the router has not initialised or there is not currentUser
    (Eg. signin page), we don't want to fetch notifications */
    if (isNil(state.router.location) || isNil(state.currentUser.token)) {
      return false;
    }

    return !PATHS.isEditorPath(state.router.location.pathname);
  }

  function isApiCall(action) {
    return action.hasOwnProperty(CALL_API);
  }

  function isFetchApprovalType(action) {
    return [
      FETCH_APPROVAL_NOTIFICATIONS_REQUEST,
      FETCH_APPROVAL_NOTIFICATIONS_FAILURE,
      FETCH_APPROVAL_NOTIFICATIONS_SUCCESS
    ].includes(action.type);
  }

  if (shouldRefetchNotifications(store.getState(), action)) {
    throttled(store);
  }

  return next(action);
};

export default notification;
