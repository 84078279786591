import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const LettersIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-4-3h24v24H-4z" />
      <path
        fill={color}
        stroke="none"
        d="M10.903 10L8.009 3.487 5.164 10h5.739zm.889 2H4.29l-1.925 4.41a1 1 0 1 1-1.833-.801L7.076.625A1 1 0 0 1 8.53.182c.17.102.313.256.4.452l6.651 14.969a1 1 0 0 1-1.828.812L11.792 12z"
      />
    </g>
  </svg>
);
LettersIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default LettersIcon;
