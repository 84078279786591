import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const BellIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  version = 1
}) => {
  if (version === 2) {
    return (
      <svg
        className={className}
        style={style}
        data-no-pointer={noPointer}
        width={width || size || 24}
        height={height || size || 24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <path
            d="M14.232 20.135a1 1 0 01.363 1.367 3 3 0 01-5.19 0 1 1 0 011.666-1.101l.064.097a1 1 0 001.665.099l.13-.196a1 1 0 011.302-.266zM12 1a7 7 0 016.996 6.76L19 8c0 2.9.523 5.079 1.378 6.646.286.525.586.935.88 1.244.131.14.227.224.274.261l.023.017c.79.527.464 1.73-.44 1.826L21 18H3c-.95 0-1.346-1.183-.648-1.763l.093-.07c.033-.021.14-.11.298-.277.293-.309.593-.719.88-1.244C4.476 13.08 5 10.9 5 8a7 7 0 017-7zm0 2a5 5 0 00-5 5c0 3.225-.602 5.734-1.622 7.604l-.138.245-.092.151h13.703l-.09-.15-.139-.246c-.971-1.781-1.564-4.141-1.618-7.148l-.009-.673A5 5 0 0012 3z"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
      stroke={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fillRule="evenodd" transform="translate(0 1)">
        <path
          stroke="none"
          fillRule="nonzero"
          d="M14 12.829a1 1 0 0 0-.292-.707l-1.415-1.415A1 1 0 0 1 12 10V7a4 4 0 1 0-8 0v3a1 1 0 0 1-.293.707l-1.414 1.415a1 1 0 0 0-.293.707V13h12v-.171zM2 9.586V7a6 6 0 0 1 12 0v2.586l1.122 1.121a3 3 0 0 1 .879 2.122V13a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-.171a3 3 0 0 1 .879-2.122L2 9.586zM5 16h2a1 1 0 0 0 2 0h2a3 3 0 0 1-6 0z"
        />
        <circle cx="8" cy="1" r="1" strokeWidth="2" />
      </g>
    </svg>
  );
};
BellIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default BellIcon;
