import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const SizesIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 18}
    height={height || size || 18}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd" transform="translate(1, 1)">
      <g
        stroke={color || easilGrayXDark}
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          d="M4 5.856V3a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h.095"
        />
        <path d="M1 10h10v7H1z" />
      </g>
    </g>
  </svg>
);
SizesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SizesIcon;
