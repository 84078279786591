import {
  getCanvasbackgroundContentWrapper,
  getPageHtmlNode as _getPageHtmlNode
} from "views/components/Editor/utils";
import Trigonometry from "lib/Trigonometry";

export default class BackgroundItemDrop {
  constructor({
    dragItem,
    differenceFromInitialOffset,
    getBackgroundWrapper = getCanvasbackgroundContentWrapper,
    getPageHtmlNode = _getPageHtmlNode,
    editor = window.easil.editor,
    isDebugging = false
  }) {
    this.dragItem = dragItem;
    this.getBackgroundWrapper = getBackgroundWrapper;
    this.getPageHtmlNode = getPageHtmlNode;
    this.differenceFromInitialOffset = differenceFromInitialOffset;
    this.editor = editor;
    this.isDebugging = isDebugging;
  }

  /* get how much has been scrolled while dragging */
  getScrollDiff() {
    const contentWrapper = this.getBackgroundWrapper();

    const verticalScroll = contentWrapper.scrollTop;
    const verticalScrollDiff =
      verticalScroll - this.dragItem.initScrollVertical;

    const horizontalScroll = contentWrapper.scrollLeft;
    const horizontalScrollDiff =
      horizontalScroll - this.dragItem.initScrollHorizontal;

    return {
      x: horizontalScrollDiff,
      y: verticalScrollDiff
    };
  }

  getMovementDeltaWithScroll() {
    const movementDelta = this.getMovementDelta();

    const scrollDiff = this.getScrollDiff();

    return {
      x: movementDelta.x + scrollDiff.x,
      y: movementDelta.y + scrollDiff.y
    };
  }

  getMovementDelta() {
    const delta = {
      x: this.differenceFromInitialOffset.x,
      y: this.differenceFromInitialOffset.y
    };

    if (this.dragItem.snapResult) {
      const snapOffset = {
        x: this.dragItem.snapResult.x.elementCoordinateDiffFromPointerPosition,
        y: this.dragItem.snapResult.y.elementCoordinateDiffFromPointerPosition
      };

      delta.x += snapOffset.x;
      delta.y += snapOffset.y;
    }

    return delta;
  }

  getItemElement() {
    const elementPageOffsets = this.getElementPageOffsets();
    const movementDelta = this.getMovementDeltaWithScroll();
    const elementPositionZoomed = {
      x: this.dragItem.left * this.editor.zoom,
      y: this.dragItem.top * this.editor.zoom
    };

    const elementPositionAfterDrop = {
      left: elementPositionZoomed.x + movementDelta.x + elementPageOffsets.x,
      top: elementPositionZoomed.y + movementDelta.y + elementPageOffsets.y
    };

    const elementSize = {
      height: this.dragItem.element.height * this.editor.zoom,
      width: this.dragItem.element.width * this.editor.zoom
    };

    /* we create an element here with the coordinates of the element after dropped */
    return this.editor.createElement({
      ...this.dragItem.element,
      left: elementPositionAfterDrop.left,
      top: elementPositionAfterDrop.top,
      height: elementSize.height,
      width: elementSize.width
    });
  }

  getElementPageOffsets() {
    const pageHtmlNode = this.getPageHtmlNode(this.dragItem.pageId);

    return pageHtmlNode.getBoundingClientRect();
  }

  setElementPolygon() {
    const itemElement = this.getItemElement();
    const corners = itemElement.getCornersPosition();

    const polygon = Object.values(corners);

    this.elementPolygon = polygon;
  }

  getPagePolygon(pageId) {
    const pageHtmlNode = this.getPageHtmlNode(pageId);
    const pageBoundingRect = pageHtmlNode.getBoundingClientRect();

    return [
      { x: pageBoundingRect.left, y: pageBoundingRect.top },
      { x: pageBoundingRect.right, y: pageBoundingRect.top },
      { x: pageBoundingRect.right, y: pageBoundingRect.bottom },
      { x: pageBoundingRect.left, y: pageBoundingRect.bottom }
    ];
  }

  setPagesPolygon() {
    this.pagesPolygon = this.editor.designData.pagesOrder.map(pageId =>
      this.getPagePolygon(pageId)
    );
  }

  getPageIndexOfDrop() {
    this.setElementPolygon();
    this.setPagesPolygon();

    if (this.isDebugging) {
      this.printPolygons();
    }

    const pageIndexOfDrop = this.pagesPolygon.findIndex(pagePolygon =>
      Trigonometry.doPolygonsIntersect(this.elementPolygon, pagePolygon)
    );

    return pageIndexOfDrop === -1 ? null : pageIndexOfDrop;
  }

  printPolygons() {
    this.pagesPolygon.forEach(pagePolygon =>
      pagePolygon.forEach(point => pointPrinter(point))
    );

    this.elementPolygon.forEach(point => pointPrinter(point, "blue"));

    function pointPrinter(point, color = "red", offsetPoint = { x: 0, y: 0 }) {
      const pointEl = document.createElement("div");
      pointEl.setAttribute("class", "test-point");
      pointEl.setAttribute(
        "style",
        `
     position: fixed;
     background: ${color};
     top: ${point.y + offsetPoint.y}px;
     left: ${point.x + offsetPoint.x}px;
     width: 5px;
     height: 5px;
     z-index: 999999;
      `
      );
      document.querySelector("body").appendChild(pointEl);
    }
  }
}
