import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const EyeDropperIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <g transform="translate(1.36 .96)">
        <rect
          stroke={color || easilGrayXDark}
          transform="rotate(45 8.839 8.839)"
          x="6.839"
          y="-.661"
          width="4"
          height="19"
          rx="2"
        />
        <path
          d="M15.556 2.121a1.997 1.997 0 0 1 0 2.829l-3.253 3.253-2.828-2.829 3.253-3.253c.39-.39.902-.585 1.414-.585.512 0 1.024.195 1.414.585z"
          stroke="#57595D"
          fill="#57595D"
        />
        <path
          d="M8.34 3.339a.977.977 0 0 0-.382 1.618l4.764 4.764a.977.977 0 0 0 1.618-.382 2.558 2.558 0 0 0-.618-2.618l-2.764-2.764a2.558 2.558 0 0 0-2.618-.618zm-7 12-.5.5a.707.707 0 1 0 1 1l.5-.5a.707.707 0 0 0-1-1z"
          fill="#57595D"
        />
      </g>
    </g>
  </svg>
);
EyeDropperIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default EyeDropperIcon;
