import React, { Component } from "react";
import { noop, composeClassName } from "lib";
import style from "./style.module.css";
import BreadCrumb from "./BreadCrumb";

export class BreadCrumbList extends Component {
  constructor(props) {
    super(props);

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);

    this.state = {
      openMenuIndex: null
    };
  }

  openMenu(index) {
    this.setState({
      openMenuIndex: index
    });
  }

  closeMenu() {
    this.setState({
      openMenuIndex: null
    });
  }

  render() {
    const { path, isDropdownContainer } = this.props;
    const { openMenuIndex } = this.state;

    if (!path || !Array.isArray(path) || !path.length) return null;

    // convert string inputs to breadcrumb nodes
    const nodePath = path.map((node, index) => {
      let nodeDetails = node;
      const isLast = index === path.length - 1;
      const isFirst = index === 0;
      if (typeof nodeDetails === "string") {
        nodeDetails = {
          text: node,
          onClick: noop
        };
      }
      nodeDetails = Object.assign({}, nodeDetails, {
        isFirst,
        isLast
      });
      return nodeDetails;
    });

    return (
      <div
        className={composeClassName(
          "breadCrumbWrapper",
          style,
          this.props.wrapperClassName
        )}
        id="bread-crumb-list-wrapper"
      >
        {nodePath.map((node, index) => {
          const key = node.text.replace(" ", "_") + "-" + index;
          return (
            <BreadCrumb
              node={node}
              openDropdown={() => this.openMenu(index)}
              closeDropdown={() => this.closeMenu(index)}
              isDropdownOpen={openMenuIndex === index}
              key={key}
              crumbId={key}
              isDropdownContainer={isDropdownContainer}
              activeFolder={this.props.activeFolder}
              folderAllocations={this.props.folderAllocations}
            />
          );
        })}
      </div>
    );
  }
}

BreadCrumbList.displayName = "BreadCrumbList";

export default BreadCrumbList;
