import React from "react";
import { connect } from "react-redux";
import style from "./style.module.css";
import { getPath, range, isEmpty, noop } from "lib";
import { waitForElement } from "lib/DOMNodeUtils";

const pageSizeOptions = [10, 20, 50];
const PAGE_NUMBER_WIDTH = 32;
export class Pagination extends React.Component {
  constructor(props) {
    super(props);

    this.getPageRange = this.getPageRange.bind(this);
    this.wrapperRef = React.createRef();
    this.setWrapperWidth = this.setWrapperWidth.bind(this);

    this.state = {
      availableWidth: 400
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.setWrapperWidth);

    waitForElement("#pagination-wrapper", document).then(() => {
      this.setWrapperWidth();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setWrapperWidth);
  }

  componentDidUpdate() {
    this.setWrapperWidth();
  }

  setWrapperWidth() {
    const availableWidth = getPath(this.wrapperRef, "current.clientWidth", 200);
    if (availableWidth !== this.state.availableWidth) {
      this.setState({
        availableWidth
      });
    }
  }

  getPageRange(pages, currentPage) {
    const { availableWidth } = this.state;
    // allow spacing for prev & next buttons as well as 20% margin both sides
    const unPaddedWidth = availableWidth * 0.6 - 60;

    let availableLength = Math.min(
      Math.floor(unPaddedWidth / PAGE_NUMBER_WIDTH),
      15
    );

    // return all pages if they will fit
    if (pages.length < availableLength) return pages;

    // force odd number of spaces
    if (!(availableLength % 2 === 1)) {
      availableLength--;
    }

    // ensure we only start putting in truncation on the left when more than half of
    // the available space would be taken up otherwise to keep the current page in the middle
    const breakPoint = availableLength / 2;
    if (currentPage <= breakPoint) {
      return [1, ...pages.slice(1, availableLength - 3), "...", pages.pop()];
    }

    if (
      currentPage >= breakPoint &&
      pages.length - currentPage > breakPoint - 1
    ) {
      const spaceForPages = availableLength - 4;
      return [
        1,
        "...",
        ...pages.slice(
          currentPage - spaceForPages / 2,
          currentPage + spaceForPages / 2
        ),
        "...",
        pages.pop()
      ];
    }

    if (pages.length - currentPage < breakPoint) {
      return [
        1,
        "...",
        ...pages.slice(pages.length - (availableLength - 3), pages.length)
      ];
    }

    return pages;
  }

  render() {
    const {
      pageSize,
      currentPage,
      totalCount,
      onPageChange,
      onPageSizeChange,
      detailed
    } = this.props;

    const firstItemIndex = 1 + (currentPage - 1) * pageSize;
    const lastItemIndex = Math.min(...[currentPage * pageSize, totalCount]);

    const pages = range(1, Math.ceil(totalCount / pageSize) + 1);

    if (isEmpty(pages)) return null;

    const pageRange = this.getPageRange(pages, currentPage);

    return (
      <div
        className={style.pagination}
        data-detailed={detailed}
        data-testid="Pagination"
        ref={this.wrapperRef}
        id="pagination-wrapper"
      >
        {detailed && (
          <React.Fragment>
            <div
              aria-label="view options"
              className={style.views}
              data-testid="viewOptions"
            >
              View
              {pageSizeOptions.map(option => (
                <span
                  key={option}
                  className={style.pageSize}
                  data-active={option === pageSize}
                  onClick={() => onPageSizeChange(option, currentPage)}
                  data-testid="pageSize"
                >
                  {option}
                </span>
              ))}
            </div>
            <div
              aria-label="page info"
              className={style.info}
              data-testid="pageInfo"
            >
              {`Showing ${firstItemIndex} - ${lastItemIndex} of ${totalCount}`}
            </div>
          </React.Fragment>
        )}
        <div
          aria-label="pages options"
          className={style.pages}
          data-testid="pagesOptions"
        >
          <div
            className={style.navigation}
            data-not-allowed={pages.indexOf(currentPage - 1) === -1}
            onClick={() => onPageChange(currentPage - 1)}
          >
            Prev
          </div>
          {pageRange.map((page, index) => (
            <span
              key={page === "..." ? `...${index}` : page}
              className={style.pageSize}
              onClick={page === "..." ? noop : () => onPageChange(page)}
              data-active={page === currentPage}
              data-testid="pageNumber"
            >
              {page}
            </span>
          ))}
          <div
            className={style.navigation}
            data-not-allowed={pages.indexOf(currentPage + 1) === -1}
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { currentPageAddress, resource } = ownProps;

  return {
    currentPage: getPath(state, currentPageAddress),
    pageSize: getPath(state, resource).pageSize,
    totalCount: getPath(state, resource).totalCount
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onPageChange: page => dispatch(ownProps.pageChangeAction(page)),
  onPageSizeChange: (pageSize, currentPage) => {
    dispatch(ownProps.pageSizeChangeAction(pageSize));
    dispatch(ownProps.pageChangeAction(1));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
