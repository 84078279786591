const data = {
  1: {
    website: "askyvi.com",
    companyName: "Founder, Ask Yvi",
    citation: "Yvonne Heimann",
    content:
      "Being able to lock layers has been a game\nchanger! No matter if clients want more\naccess to their graphics or my VA is taking\nover graphic creation, I never have to worry\nabout brand consistency getting lost and\nthings getting changed that shouldn’t.\nThere's a reason we've been using Easil for\nover a year now!"
  },
  2: {
    website: "iag.me",
    companyName: "Seriously Social",
    citation: "Ian Anderson Gray",
    content:
      "I'm so glad I switched to Easil. The clean\nuser interface is so easy and is a joy to use.\nThe included designs are professional, fun\nand refreshing. But most of all, I love the\nway Easil works out of the box for teams -\nmeaning my designer and I can efficiently\ncollaborate and create templates for\neverything I need in my business. I love it!"
  },
  3: {
    website: "lightscameralive.com",
    companyName: "Lights, Camera, LIVE!",
    citation: "Stephanie Liu",
    content:
      "I use Easil daily for all of my graphics.\nIt's my go to solution for scroll stopping graphics.\n\nLove how easy it is to use and how beautiful\nthe templates are!"
  }
};

export default data;
