import * as types from "state/currentUser/currentUserTypes";

export const initState = {
  isFetching: false
};

const userPreferencesApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_PREFERENCES_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.CURRENT_USER_PREFERENCES_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }

    default:
      return state;
  }
};

export default userPreferencesApiReducers;
