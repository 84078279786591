import * as types from "./foldersTypes";
import { omit } from "lib";

export const initState = {};

const foldersReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_TEAM_FOLDERS_REQUEST:
    case types.FETCH_PERSONAL_FOLDERS_REQUEST: {
      return {
        ...state
      };
    }

    case types.FETCH_TEAM_FOLDERS_REQUEST_SUCCESS:
    case types.FETCH_PERSONAL_FOLDERS_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;

      const {
        response: {
          entities: { folder }
        }
      } = action;

      const folders = Object.assign({}, state, folder);

      return {
        ...folders
      };
    }

    case types.CREATE_TEAM_FOLDER_REQUEST_SUCCESS:
    case types.UPDATE_PERSONAL_FOLDER_REQUEST_SUCCESS:
    case types.UPDATE_TEAM_FOLDER_REQUEST_SUCCESS:
    case types.CREATE_PERSONAL_FOLDER_REQUEST_SUCCESS: {
      const {
        response: {
          entities: { folder }
        }
      } = action;

      return {
        ...state,
        ...folder
      };
    }

    case types.DELETE_TEAM_FOLDER_REQUEST_SUCCESS:
    case types.DELETE_PERSONAL_FOLDER_REQUEST_SUCCESS: {
      const {
        extra: { folderId }
      } = action;

      return omit(state, folderId);
    }

    default:
      return state;
  }
};

export default foldersReducer;
