import React from "react";
import style from "./style.module.css";
import InfoAlert from "views/components/InfoAlert";
import Subscriptions from "lib/subscriptions";

export const NoCreditsWarning = ({
  renewDate,
  creditsPerMonth,
  userSubscriptionPlan,
  moveToSubscription,
  userCanUpgrade
}) => {
  const isPlusPlan = userSubscriptionPlan.plan.code === "PLUS";
  const edgeCreditsPerMonth =
    Subscriptions.EDGE.removeBackgroundCreditsPerMonth;

  return (
    <InfoAlert className={style.info} theme="yellow" iconSize="20px">
      <span>
        You don't have enough credits. Your next {creditsPerMonth} credits will
        renew on <strong>{renewDate}</strong>.
        {isPlusPlan && (
          <div>
            {userCanUpgrade ? (
              <strong data-is-link={true} onClick={moveToSubscription}>
                {"Upgrade "}
              </strong>
            ) : (
              <span>Upgrade </span>
            )}
            to our Easil Edge plan to receive {edgeCreditsPerMonth} credits per
            month.
          </div>
        )}
      </span>
    </InfoAlert>
  );
};

export default NoCreditsWarning;
