import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const IconsIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color = easilGrayXDark,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 22 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h24v24H-1z" />
      <path
        fill={color}
        stroke="none"
        fillRule="nonzero"
        d="M16.424 2.595A1.99 1.99 0 0 0 15 2H7c-.733 0-1.396.397-1.748 1.027l-.075.115-2.675 3.533a2 2 0 1 0 3.42 1.884 1 1 0 0 1 1.783-.29A3.994 3.994 0 0 0 11 10a3.994 3.994 0 0 0 3.295-1.732 1 1 0 0 1 1.784.29A2.001 2.001 0 0 0 20 8c0-.506-.187-.98-.58-1.419L16.78 3.07l-.06-.09a2.011 2.011 0 0 0-.259-.346l-.037-.039zm1.512-1.318l.025.034c.168.185.319.385.45.598l2.547 3.398a4 4 0 0 1-6.043 5.24A5.986 5.986 0 0 1 11 12a5.986 5.986 0 0 1-3.915-1.453A4 4 0 0 1 0 8c0-.99.362-1.926.956-2.592l2.587-3.42A3.998 3.998 0 0 1 7 0h8c1.014 0 1.969.38 2.699 1.048.086.06.167.136.237.23zM18 15a1 1 0 0 1 2 0v2a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-2a1 1 0 0 1 2 0v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2z"
      />
    </g>
  </svg>
);
IconsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default IconsIcon;
