import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const QrCodeSmallIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 16}
    height={height || size || 16}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path
        stroke={color || easilGrayXDark}
        strokeWidth="1.5"
        d="m6.5.75.25 5.75L1 6.75.75 1.5A.748.748 0 0 1 1.5.75h5zm0 8.5L6.75 15l-5.25.25a.748.748 0 0 1-.75-.75v-5l5.75-.25zm8-8.5a.748.748 0 0 1 .75.75v5l-5.75.25L9.25 1z"
      />
      <path
        fill="#57595D"
        d="M9 8.5h1.2a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3H8.8a.3.3 0 0 1-.3-.3V9a.5.5 0 0 1 .5-.5z"
      />
      <rect width="2" height="4" x="8.5" y="11.25" fill="#57595D" rx=".3" />
      <rect width="2" height="4" x="11.25" y="8.5" fill="#57595D" rx=".3" />
      <rect width="4" height="2" x="11.25" y="11.25" fill="#57595D" rx=".3" />
      <path
        fill="#57595D"
        d="M14.3 8.5h1.2a.5.5 0 0 1 .5.5v1.2a.3.3 0 0 1-.3.3h-1.4a.3.3 0 0 1-.3-.3V8.8a.3.3 0 0 1 .3-.3z"
      />
      <rect width="2" height="2" x="14" y="11.25" fill="#57595D" rx=".3" />
      <path
        fill="#57595D"
        d="M8.8 14h1.4a.3.3 0 0 1 .3.3v1.4a.3.3 0 0 1-.3.3H9a.5.5 0 0 1-.5-.5v-1.2a.3.3 0 0 1 .3-.3z"
      />
      <rect width="4" height="2" x="11.25" y="14" fill="#57595D" rx=".3" />
      <path
        fill="#57595D"
        d="M14.3 14h1.4a.3.3 0 0 1 .3.3v.7a1 1 0 0 1-1 1h-.7a.3.3 0 0 1-.3-.3v-1.4a.3.3 0 0 1 .3-.3z"
      />
      <rect width="2" height="2" x="2.75" y="2.75" fill="#57595D" rx=".3" />
      <rect width="2" height="2" x="2.75" y="11.25" fill="#57595D" rx=".3" />
      <rect width="2" height="2" x="11.25" y="2.75" fill="#57595D" rx=".3" />
      <path
        stroke={color || easilGrayXDark}
        strokeWidth=".5"
        d="m5.93 1.22.337 4.712-4.698.335-.336-4.487 4.698-.56zm.002 8.513.335 4.698-4.487.336-.56-4.698 4.712-.336zm8.288-8.5.56 4.698-4.712.336-.335-4.698 4.487-.336z"
      />
    </g>
  </svg>
);
QrCodeSmallIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default QrCodeSmallIcon;
