export const FETCH_TAG_FOR_DESIGN_REQUEST =
  "API/ENTITIES/TAGS/FETCH_DESIGN_TAG_REQUEST";
export const FETCH_TAG_FOR_DESIGN_REQUEST_SUCCESS =
  "API/ENTITIES/TAGS/FETCH_DESIGN_TAG_REQUEST_SUCCESS";
export const FETCH_TAG_FOR_DESIGN_REQUEST_FAILURE =
  "API/ENTITIES/TAGS/FETCH_DESIGN_TAG_REQUEST_FAILURE";

export const ADD_TAG_TO_DESIGN_REQUEST =
  "API/ENTITIES/TAGS/CREATE_DESIGN_TAG_REQUEST";
export const ADD_TAG_TO_DESIGN_REQUEST_SUCCESS =
  "API/ENTITIES/TAGS/CREATE_DESIGN_TAG_REQUEST_SUCCESS";
export const ADD_TAG_TO_DESIGN_REQUEST_FAILURE =
  "API/ENTITIES/TAGS/CREATE_DESIGN_TAG_REQUEST_FAILURE";

export const REMOVE_TAG_FROM_DESIGN_REQUEST =
  "API/ENTITIES/TAGS/REMOVE_DESIGN_TAG_REQUEST";
export const REMOVE_TAG_FROM_DESIGN_REQUEST_SUCCESS =
  "API/ENTITIES/TAGS/REMOVE_DESIGN_TAG_REQUEST_SUCCESS";
export const REMOVE_TAG_FROM_DESIGN_REQUEST_FAILURE =
  "API/ENTITIES/TAGS/REMOVE_DESIGN_TAG_REQUEST_FAILURE";
