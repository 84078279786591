import React from "react";
import style from "./style.module.css";

import CurrentSubscriptionDescription from "../CurrentSubscriptionDescription";

import { differenceInDays } from "lib/temporal/dateUtils";

class CurrentSubscriptionDescriptionTrial extends CurrentSubscriptionDescription {
  getTiles() {
    const tiles = [
      {
        title: "Plan",
        body: this.planDisplayName,
        className: style.planTile
      },
      {
        title: "Trial end date",
        body: this.renewDate,
        className: style.trialEndDateTile
      },
      {
        title: "Days remaining on trial",
        body: `${differenceInDays(
          this.props.subscription.renewAt,
          Date.now()
        )}`,
        className: style.remainingDaysTile
      }
    ];

    return tiles;
  }

  getInfoMessage() {
    return (
      <span>
        When your trial ends, you'll be automatically downgraded to the{" "}
        <strong>Easil Basic</strong> plan. You won't be charged until you
        upgrade to a paid plan.
      </span>
    );
  }

  get planDisplayName() {
    return `Easil ${this.props.plan.name} Trial (Free)`;
  }

  cancelPlanComponent() {
    return null;
  }

  updateCardComponent() {
    return null;
  }
}

CurrentSubscriptionDescriptionTrial.displayName = "CurrentPlanDescriptionTrial";

export default CurrentSubscriptionDescriptionTrial;
