import * as types from "./teamsTypes";
import {
  teamsApiPageSelector,
  teamsApiDetailedIdSelector,
  teamsApiPageForParentIdSelector
} from "state/api/teams/teamsApiSelector";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { moveTo } from "state/ui/navigation/navigationActions";
import { switchToken } from "state/currentUser/currentUserActions";
import { currentUserIdSelector } from "state/currentUser/currentUserSelectors";
import { noop, Logger, reduxStoreExpiry, getPath, isEmpty } from "lib";

export const fetchTeam = ({ teamId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}`,
      schema: schemas.TEAMS,
      types: [
        types.TEAM_REQUEST,
        types.TEAM_REQUEST_SUCCESS,
        types.TEAM_REQUEST_FAILURE
      ],
      request: {
        teamId: teamId
      },
      onSuccess: response => {
        onSuccess(getState().entities.teams[response.ids]);
      }
    }
  });
};

export const fetchTeamV2 = ({ teamId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.USER_V2,
      endpoint: `/teams/${teamId}`,
      schema: schemas.TEAMS,
      types: [
        types.TEAM_REQUEST,
        types.TEAM_REQUEST_SUCCESS,
        types.TEAM_REQUEST_FAILURE
      ],
      request: {
        teamId: teamId
      },
      onSuccess: response => {
        onSuccess(getState().entities.teams[response.ids]);
      }
    }
  });
};

/**
 * @desc makes an api call to fetch team entities from the user service
 * @param {Object} args - the arguments for the fetch request as an object
 * @param {string} args.userId - the id for the user making the request
 * @param {number} [args.page = 1] - the page to fetch, defaulted to 1 if not provided
 * @param {function} [args.onSuccess = noop] - a function to run after the fetch has completed successfully
 * @param {string} [args.parentId] - the id of the parent team to scope the fetch within
 */
export const fetchTeams = ({
  userId,
  page = 1,
  onSuccess = noop,
  parentId
}) => (dispatch, getState) => {
  const params = {};
  // NOTE: DEPRECATED FOR parentId USE. Please use fetchSubTeams
  if (parentId) params.parentId = parentId;
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.USER_V2,
      types: [
        types.TEAMS_REQUEST,
        types.TEAMS_REQUEST_SUCCESS,
        types.TEAMS_REQUEST_FAILURE
      ],
      endpoint: `/users/${userId}/teams`,
      request: {
        page: page,
        pageSize: getState().api.teams.pageSize,
        params
      },
      extra: {
        currentTeamId: getState().ui.currentTeam.id
      },
      schema: schemas.TEAMS_ARRAY,
      onSuccess: response => onSuccess(response)
    }
  });
};

export const fetchNextPageOfSubTeamsIfNeeded = ({
  onSuccess = noop,
  parentId
}) => (dispatch, getState) => {
  if (!parentId) return;
  const state = getState();
  const parent = getPath(state, ["api", "teams", "parentIds", parentId]);

  if (!parent || isEmpty(parent.pages)) {
    // nothing has been fetched yet, just fetch first page
    return dispatch(fetchSubTeamsIfNeeded({ parentId, onSuccess }));
  }

  // get the last page
  const lastPage = Object.keys(parent.pages).pop();

  if (parent.pages[lastPage].isFetching) {
    // do not make more requests when one is already in progress
    return;
  }

  if (parent.pages[lastPage].ids.length >= state.api.teams.pageSize) {
    // last page was full so lets get another page
    dispatch(
      fetchSubTeamsIfNeeded({
        page: parseInt(lastPage) + 1,
        parentId,
        onSuccess
      })
    );
  }
};

/**
 * @desc makes an api call to fetch sub teams of the team with id parentId.
 * @param {Object} args - the arguments for the fetch request as an object
 * @param {string} args.userId - the id for the user making the request
 * @param {number} [args.page = 1] - the page to fetch, defaulted to 1 if not provided
 * @param {function} [args.onSuccess = noop] - a function to run after the fetch has completed successfully
 * @param {string} [args.parentId] - the id of the parent team to scope the fetch within
 */
export const fetchSubTeams = ({
  userId,
  page = 1,
  onSuccess = noop,
  parentId
}) => (dispatch, getState) => {
  if (!parentId) return;
  const params = { parentId };
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.USER_V2,
      types: [
        types.SUB_TEAMS_REQUEST,
        types.SUB_TEAMS_REQUEST_SUCCESS,
        types.SUB_TEAMS_REQUEST_FAILURE
      ],
      endpoint: `/users/${userId}/teams`,
      request: {
        page: page,
        pageSize: getState().api.teams.pageSize,
        params
      },
      extra: {
        currentTeamId: getState().ui.currentTeam.id
      },
      schema: schemas.TEAMS_ARRAY,
      onSuccess: response => onSuccess(response)
    }
  });
};

/**
 * @desc makes an api call to fetch team entities from the user service in V1
 * @param {Object} args - the arguments for the fetch request as an object
 * @param {string} args.userId - the id for the user making the request
 * @param {number} [args.page = 1] - the page to fetch, defaulted to 1 if not provided
 * @param {function} [args.onSuccess = noop] - a function to run after the fetch has completed successfully
 * @param {string} [args.parentId] - the id of the parent team to scope the fetch within
 */
export const fetchTeamsFromV1 = ({
  userId,
  page = 1,
  onSuccess = NavigationPreloadManager
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.USER,
      types: [
        types.TEAMS_REQUEST,
        types.TEAMS_REQUEST_SUCCESS,
        types.TEAMS_REQUEST_FAILURE
      ],
      endpoint: `/users/${userId}/teams`,
      request: {
        page: page,
        pageSize: getState().api.teams.pageSize
      },
      extra: {
        currentTeamId: getState().ui.currentTeam.id
      },
      schema: schemas.TEAMS_ARRAY,
      onSuccess: response => onSuccess(response)
    }
  });
};

/**
 * @desc checks if the state for a team - parentId, combination needs to be (re)fetched
 * @param {Object} state - a redux state object
 * @param {number} page - the api page number to check
 * @param {string} parentId - the id of the parent team to check
 * @returns {boolean} whether the team-parentId combination needs to be (re)fetched
 * @deprecated Use shouldFetchSubTeams
 */
export const shouldFetchTeamsForParentId = (state, page, parentId) => {
  const teamsApiPage = teamsApiPageForParentIdSelector({
    state,
    page,
    parentId
  });

  if (!teamsApiPage) return true;

  if (teamsApiPage.isFetching) return false;

  return reduxStoreExpiry.isDataExpired(teamsApiPage.lastFetched, 5);
};

/**
 * @desc checks if the state for a team - parentId, combination needs to be (re)fetched
 * @param {Object} state - a redux state object
 * @param {number} page - the api page number to check
 * @param {string} parentId - the id of the parent team to check
 * @returns {boolean} whether the team-parentId combination needs to be (re)fetched
 */
export const shouldFetchSubTeams = (state, page, parentId) => {
  const teamsApiPage = teamsApiPageForParentIdSelector({
    state,
    page,
    parentId
  });

  if (!teamsApiPage) return true;

  if (teamsApiPage.isFetching) return false;

  return reduxStoreExpiry.isDataExpired(teamsApiPage.lastFetched, 5);
};

/**
 *
 * @param {Object} args - the arguments for the fetch request as an object
 * @param {number} [args.page = 1] - the page to fetch, defaulted to 1 if not provided
 * @param {function} [args.onSuccess = noop] - a function to run after the fetch has completed successfully
 * @param {string} [args.parentId] - the id of the parent team to scope the fetch within
 */
export const fetchTeamsForParentIdIfNeeded = ({
  page = 1,
  onSuccess = noop,
  parentId
}) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserIdSelector(state);
  if (shouldFetchTeamsForParentId(state, page, parentId)) {
    dispatch(fetchTeams({ userId, page, onSuccess, parentId }));
  }
};

/**
 *
 * @param {Object} args - the arguments for the fetch request as an object
 * @param {number} [args.page = 1] - the page to fetch, defaulted to 1 if not provided
 * @param {function} [args.onSuccess = noop] - a function to run after the fetch has completed successfully
 * @param {string} [args.parentId] - the id of the parent team to scope the fetch within
 */
export const fetchSubTeamsIfNeeded = ({
  page = 1,
  onSuccess = noop,
  parentId
}) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserIdSelector(state);
  if (shouldFetchSubTeams(state, page, parentId)) {
    dispatch(fetchSubTeams({ userId, page, onSuccess, parentId }));
  }
};

/**
 * @desc recursively calls fetchTeams until less than 20 teams is returned in the response
 * @param {{string}} {}.userId - the id of the user to get all teams for
 */
export const fetchAllTeams = ({ userId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const processResult = (result, page) => {
    if (result.ids.length >= 100) {
      /* dispatch again */
      fetchTeamsFromV1IfNeeded({
        userId,
        page,
        onSuccess: res => processResult(res, page + 1)
      })(dispatch, getState);
    } else {
      onSuccess();
    }
  };
  fetchTeamsFromV1IfNeeded({
    userId,
    page: 1,
    onSuccess: res => processResult(res, 1)
  })(dispatch, getState);
};

export const searchTeams = ({ name, parentId, page = 1 }) => {
  const params = { name };

  if (parentId) {
    params.parentId = parentId;
  }

  return {
    [CALL_API]: {
      method: "GET",
      service: SERVICES.USER,
      types: [
        types.TEAMS_SEARCH_REQUEST,
        types.TEAMS_SEARCH_REQUEST_SUCCESS,
        types.TEAMS_SEARCH_REQUEST_FAILURE
      ],
      endpoint: `/teams`,
      request: { page, params },
      schema: schemas.TEAMS_ARRAY
    }
  };
};

export const fetchTeamsCount = ({ userId, onSuccess = noop }) => ({
  [CALL_API]: {
    types: [
      types.TEAMS_COUNT_REQUEST,
      types.TEAMS_COUNT_REQUEST_SUCCESS,
      types.TEAMS_COUNT_REQUEST_FAILURE
    ],
    service: SERVICES.USER,
    endpoint: `/users/${userId}/teams/count`,
    schema: schemas.NONE,
    onSuccess
  }
});

export const createTeam = (team, currentTeam, onSuccess = noop) => (
  dispatch,
  getState
) => {
  switch (team.type) {
    case "orgTeam": {
      dispatch(
        createOrganisation({
          name: team.name,
          setAsDefault: team.setAsDefault,
          country: currentTeam.country,
          userId: getState().currentUser.id,
          logo: null,
          onSuccess
        })
      );
      break;
    }

    case "subTeam": {
      dispatch(createSubteam(team, currentTeam, onSuccess));
      break;
    }

    default:
      Logger.error("Invalid Team Type");
  }
};

export const createSubteam = (team, currentTeam, onSuccess = noop) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      types: [
        types.TEAMS_CREATE_REQUEST,
        types.TEAMS_CREATE_REQUEST_SUCCESS,
        types.TEAMS_CREATE_REQUEST_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/teams`,
      schema: schemas.TEAMS,
      request: {
        body: {
          name: team.name,
          country: currentTeam.country,
          parentTeamId: currentTeam.id,
          setAsDefault: team.setAsDefault
        }
      },
      onSuccess: response => {
        const { ids: teamId } = response;

        dispatch(switchToken({ teamId }));

        onSuccess();
      }
    }
  });
};

export const createOrganisation = ({
  name,
  country,
  userId,
  setAsDefault,
  logo,
  onSuccess
}) => (dispatch, getState) => {
  const params = {
    name,
    country,
    userId,
    setAsDefault
  };

  if (params.logo) params.logo = logo;

  dispatch({
    [CALL_API]: {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      types: [
        types.TEAMS_CREATE_REQUEST,
        types.TEAMS_CREATE_REQUEST_SUCCESS,
        types.TEAMS_CREATE_REQUEST_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/organisations`,
      schema: schemas.TEAMS,
      request: {
        body: {
          ...params
        }
      },
      onSuccess: response => {
        const { ids: teamId } = response;

        dispatch(switchToken({ teamId }));

        onSuccess();
      }
    }
  });
};

export const inviteToTeam = ({ onSuccess = noop } = {}) => (
  dispatch,
  getState
) => {
  const { entries, status } = getState().ui.inviteToTeamForm;
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  if (status === "PRISTINE") {
    dispatch({
      type: types.TEAMS_INVITE_USERS_REQUEST
    });
    onSuccess();
    return;
  }

  const dataFormattedToApi = entries.map(entry => ({
    teamId: getState().ui.currentTeam.id,
    name: entry.name.value,
    email: entry.email.value,
    roles: entry.roles.value.filter(role => role.selected).map(role => role.key)
  }));

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.USER_V2,
      endpoint: `/teams/${teamId}/invitations/bulk`,
      schema: schemas.USERS_INVITED_ARRAY,
      types: [
        types.TEAMS_INVITE_USERS_REQUEST,
        types.TEAMS_INVITE_USERS_REQUEST_SUCCESS,
        types.TEAMS_INVITE_USERS_REQUEST_FAILURE
      ],
      request: {
        body: {
          inviterId: userId,
          invitations: dataFormattedToApi
        }
      },
      onSuccess: response => onSuccess(response)
    }
  });
};

export const inviteToTeamAndRedirect = ({ redirectTo }) => (
  dispatch,
  getState
) => {
  dispatch(
    inviteToTeam({
      onSuccess: () => {
        const formStatus = getState().ui.inviteToTeamForm.status;

        if (formStatus === "ALL_COMPLETED") {
          dispatch(moveTo(redirectTo));
        }
      }
    })
  );
};

export const updateTeam = ({ teamId, team }) => (dispatch, getState) => {
  // if (team.logo === null) team.logo = 'NULL';

  dispatch({
    [CALL_API]: {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}`,
      schema: schemas.TEAMS,
      request: {
        body: team
      },
      types: [
        types.TEAMS_UPDATE_REQUEST,
        types.TEAMS_UPDATE_REQUEST_SUCCESS,
        types.TEAMS_UPDATE_REQUEST_FAILURE
      ]
    }
  });
};

export const shouldFetchTeam = (state, teamId) => {
  const detailedTeam = teamsApiDetailedIdSelector(state, teamId);

  if (!detailedTeam) return true;

  if (detailedTeam.isFetching) return false;

  /*   return teamsStore.didInvalidate;*/
  return true;
};

export const fetchTeamIfNeeded = ({ teamID, onSuccess = noop } = {}) => (
  dispatch,
  getState
) => {
  const teamId = teamID || getState().ui.currentTeam.id;

  if (shouldFetchTeam(getState(), teamId)) {
    dispatch(fetchTeam({ teamId, onSuccess }));
  } else {
    onSuccess();
  }
};

export const fetchTeamIfNeededV2 = ({ teamID, onSuccess = noop } = {}) => (
  dispatch,
  getState
) => {
  const teamId = teamID || getState().ui.currentTeam.id;

  if (shouldFetchTeam(getState(), teamId)) {
    dispatch(fetchTeamV2({ teamId, onSuccess }));
  } else {
    onSuccess();
  }
};

export const shouldFetchTeams = (state, page) => {
  const teamsApiPage = teamsApiPageSelector(state, page);

  if (!teamsApiPage) return true;

  if (teamsApiPage.isFetching) return false;

  const isExpired = reduxStoreExpiry.isDataExpired(teamsApiPage.lastFetched, 5);

  return isExpired;
};

export const fetchNextPageOfTeamsIfNeeded = ({ onSuccess = noop } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const defaultParent = getPath(state, "api.teams.parentIds.all");

  if (!defaultParent || isEmpty(defaultParent.pages)) {
    // nothing has been fetched yet, just fetch first page
    return dispatch(fetchTeamsIfNeeded({ onSuccess }));
  }

  // get the last page
  const lastPage = Object.keys(defaultParent.pages).pop();

  if (defaultParent.pages[lastPage].isFetching) {
    // do not make more requests when one is already in progress
    return;
  }

  if (defaultParent.pages[lastPage].ids.length >= state.api.teams.pageSize) {
    // last page was full so lets get another page
    dispatch(fetchTeamsIfNeeded({ page: parseInt(lastPage) + 1, onSuccess }));
  } else {
    onSuccess();
  }
};

export const fetchTeamsIfNeeded = ({ page = 1, onSuccess = noop } = {}) => (
  dispatch,
  getState
) => {
  const userId = getState().currentUser.id;

  if (shouldFetchTeams(getState(), page)) {
    fetchTeams({ userId, page, onSuccess })(dispatch, getState);
    dispatch(fetchTeamsCount({ userId }));
  }
};

export const fetchTeamsFromV1IfNeeded = ({
  page = 1,
  onSuccess = noop
} = {}) => (dispatch, getState) => {
  const userId = getState().currentUser.id;

  if (shouldFetchTeams(getState(), page)) {
    fetchTeamsFromV1({ userId, page, onSuccess })(dispatch, getState);
    dispatch(fetchTeamsCount({ userId }));
  }
};
