/**
 * @desc - takes the name of a component base style, its style object and a classname string from the encompassing component
 * and gets a string which represents the combination of these classNames
 * @param {String} core - the name of the base style field which represents the components original className
 * @param {Object} style - an object of styles from the component
 * @param {String} className - a className passed in from a higher component to be applied to this instance of the component
 * @returns {String} returns a string representing the combination of the components ClassName and the className(s) passed in
 */
const composeClassName = (core, style, className) => {
  const classes = [style[core]];

  if (className) {
    classes.push(className);
  }

  return classes.join(" ");
};

export default composeClassName;
