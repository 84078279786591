/**
 * @desc - maps the appropriate content_period from a given intervalPeriod string
 * @param {string} intervalPeriod - the string describing the interval period of the subscription
 * @returns {string} - a string describing the content_period matching the given intervalPeriod
 */
const getContentPeriod = intervalPeriod => {
  switch (intervalPeriod) {
    case "yearly":
      return "annual";
    case "monthly":
    default:
      return "monthly";
  }
};

/**
 * @desc - takes information about a new subscription and tracks it to facebook pixel
 * @param {string} planName - the name of the plan that was subscribed to ('plus' | 'edge')
 * @param {string} intervalPeriod - the subscription interval for the plan ('annual' | 'monthly')
 * @param {number} value - the value for the subscription per intervalPeriod (9.00 | 79.00 | 90.00 | 780.00 | 7.50 | 59.00 | 75.00 | 588.00)
 * @param {string} currency - the currency that the subscription is in ('AUD' | 'USD')
 */
export const trackSubscriptionFB = ({
  planName,
  intervalPeriod,
  value,
  currency
}) => {
  window.fbq("track", "Subscribe", {
    content_plan: planName,
    content_period: getContentPeriod(intervalPeriod),
    value,
    currency
  });
};
