import * as types from "./clipBoardTypes";

export const initState = {
  elementsCopyArray: null
};

const ClipBoardReducers = (state = initState, action) => {
  switch (action.type) {
    // clear the clipboard
    case types.CLIP_BOARD_CLEAR: {
      return initState;
    }

    // add content to the clipboard
    case types.CLIP_BOARD_COPY: {
      return {
        ...state,
        elementsCopyArray: action.elementsCopyArray,
        sourceDesignSpecs: action.sourceDesignSpecs
      };
    }

    default:
      return state;
  }
};

export default ClipBoardReducers;
