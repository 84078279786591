import * as types from "./subscriptionTypes";
import * as subscriptionTypes from "../../entities/subscriptions/subscriptionsTypes";
import * as cardTypes from "../../entities/cards/cardsTypes";

import { formatErrors } from "lib";

const modalInitState = {
  visible: false,
  submitting: false,
  errors: []
};

export const initState = {
  modals: {
    upgrade: modalInitState,
    creditCard: modalInitState
  }
};

const errorsArray = error => formatErrors(error, true);

const subscriptionReducers = (state = initState, action) => {
  switch (action.type) {
    case types.SUBSCRIPTION_TOGGLE_MODAL_SUCCESS: {
      const { name, visible } = action;

      return {
        ...state,
        modals: {
          ...state.modals,
          [name]: {
            ...state.modals[name],
            visible,
            submitting: false,
            errors: []
          }
        }
      };
    }

    case subscriptionTypes.UPDATE_SUBSCRIPTION: {
      const name = "upgrade";

      return {
        ...state,
        modals: {
          ...state.modals,
          [name]: {
            ...state.modals[name],
            submitting: true,
            errors: []
          }
        }
      };
    }

    case subscriptionTypes.UPDATE_SUBSCRIPTION_SUCCESS: {
      const name = "upgrade";

      return {
        ...state,
        modals: {
          ...state.modals,
          [name]: {
            ...modalInitState
          }
        }
      };
    }

    case subscriptionTypes.UPDATE_SUBSCRIPTION_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      const name = "upgrade";

      return {
        ...state,
        modals: {
          ...state.modals,
          [name]: {
            ...state.modals[name],
            submitting: false,
            errors
          }
        }
      };
    }

    case cardTypes.CREATE_CARD: {
      const name = "creditCard";

      return {
        ...state,
        modals: {
          ...state.modals,
          [name]: {
            ...state.modals[name],
            submitting: true,
            errors: []
          }
        }
      };
    }

    case cardTypes.CREATE_CARD_SUCCESS: {
      const name = "creditCard";

      return {
        ...state,
        modals: {
          ...state.modals,
          [name]: {
            ...modalInitState
          }
        }
      };
    }

    case cardTypes.CREATE_CARD_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      const name = "creditCard";

      return {
        ...state,
        modals: {
          ...state.modals,
          [name]: {
            ...state.modals[name],
            submitting: false,
            errors
          }
        }
      };
    }

    default:
      return state;
  }
};

export default subscriptionReducers;
