import { uuid } from "lib/uuid";
import { moveItem } from "lib/array/array";
import { ANIMATED_DESIGN_PAGE_DEFAULT_DURATION } from "lib/constants";
import { cloneDeep } from "lodash";
import { isDesignOpenQuickInput } from "lib/designUtils";

const DesignPageOps = {
  updatePageAttribute({ pageId, attributes }) {
    const page = this.pages[pageId];
    const pagesUpdated = {
      ...this.pages,
      [pageId]: {
        ...page,
        ...attributes
      }
    };

    return new this.constructor({
      ...this,
      pages: pagesUpdated,
      version: this.version + 1
    });
  },

  createGroup({ pageId, name }) {
    const group = this.createElement({ name, type: "group" });

    const page = this.pages[pageId];

    const pagesUpdated = {
      ...this.pages,
      [pageId]: {
        ...page,
        elementsOrder: page.elementsOrder.concat(group.uniqueId)
      }
    };

    const elementsUpdated = {
      ...this.elements,
      [group.uniqueId]: group
    };

    return new this.constructor({
      ...this,
      pages: pagesUpdated,
      elements: elementsUpdated,
      version: this.version + 1
    });
  },

  addNewPage() {
    const newPageId = uuid();

    let newPage = {
      backgroundColor: "#FFFFFF",
      elementsOrder: [],
      animatedElements: {},
      quickInputOrder: [],
      openQuickInput: isDesignOpenQuickInput(this)
    };

    // check to see if other pages in design contain animations (GIFs)
    // assign a default duration value if so
    const areAnimationsInDesign = Object.values(this.pages).some(
      page => !!page.duration
    );

    if (areAnimationsInDesign) {
      newPage = {
        ...newPage,
        duration: ANIMATED_DESIGN_PAGE_DEFAULT_DURATION
      };
    }

    const pagesUpdated = {
      ...this.pages,
      [newPageId]: newPage
    };

    return new this.constructor({
      ...this,
      pages: pagesUpdated,
      pagesOrder: this.pagesOrder.concat(newPageId),
      version: this.version + 1
    });
  },

  changePageOrder(currentIndex, newIndex) {
    return new this.constructor({
      ...this,
      pagesOrder: moveItem(this.pagesOrder, currentIndex, newIndex),
      version: this.version + 1
    });
  },

  toggleOpenQuickInput(isOpen) {
    const updatedPages = cloneDeep(this.pages);
    const pageIds = Object.keys(updatedPages);

    pageIds.forEach(id => (updatedPages[id].openQuickInput = isOpen));

    return new this.constructor({
      ...this,
      pages: updatedPages,
      version: this.version + 1
    });
  }
};

export default DesignPageOps;
