import * as types from "./searchSuggestionsTypes";

import { merge } from "lib";

export const initState = {};

const searchSuggestionsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.SEARCH_SUGGESTIONS_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;
      const {
        params: { term }
      } = action.request;
      let suggestions = [];
      if (entities && entities.searchSuggestions) {
        suggestions = Object.values(entities.searchSuggestions.suggestions);
      }
      return merge({}, state, { [term]: suggestions });
    }

    default:
      return state;
  }
};

export default searchSuggestionsReducers;
