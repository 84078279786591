import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { SubscriptionsPage } from "views/pages";
import { userIsAdmin } from "state/currentUser/currentUserSelectors";
import { getIsCurrentTeamOrg } from "state/entities/teams/teamsSelectors";

const SubscriptionsPageContainer = props => {
  return <SubscriptionsPage {...props} />;
};

SubscriptionsPageContainer.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

SubscriptionsPageContainer.displayName = "SubscriptionsPageContainer";

const mapStateToProps = state => {
  const isAdmin = userIsAdmin(state);
  const isCurrentTeamOrg = getIsCurrentTeamOrg(state);
  return {
    isAdmin,
    isCurrentTeamOrg
  };
};

export default connect(mapStateToProps)(SubscriptionsPageContainer);
