import * as types from "state/entities/teams/teamsTypes";
import { isEmpty } from "lib";

const initState = {};

/**
 * Traverses the trees to add a team into it's rightful position in the hierarchy.
 * @param state Team Hierarchy tree state that is trimmed recursively.
 * @param teamPath The common parent path shared by the sub teams that is also trimmed recursively.
 * @param subTeamIds The array of teamIds that are the fetched sub teams.
 * @returns updated state object.
 */
const insertSubTeam = (state, teamPath, subTeamIds) => {
  let updatedState = Object.assign({}, state);
  if (!teamPath || !teamPath.length) {
    subTeamIds.forEach(subTeamId => {
      updatedState[subTeamId] = {};
    });
    return updatedState;
  }

  const parentId = teamPath[0];
  // If the state is empty and we still have a path to navigate then we've probably loaded
  // sub teams of a team that a user has explicit access too BUT that team is actually a sub team
  // of another root team. This is an old bug that is in the process of being cleaned up and should
  // only affect Easil team members at the moment. To get around this we create the path in the
  // hierarchy as we go
  if (isEmpty(state) && teamPath.length) {
    updatedState = {
      ...updatedState,
      [parentId]: {}
    };
  }
  teamPath.shift();
  return {
    ...state,
    [parentId]: insertSubTeam(updatedState[parentId], teamPath, subTeamIds)
  };
};

/**
 * Handles the subTeams response entities and inserts the children at their correct tier in the team hierarchy.
 */
const updateChildNodes = (state, subTeams) => {
  // All teams have same path as they are children of the same parent
  const subTeamIds = Object.keys(subTeams);
  const parentPath = subTeams[subTeamIds[0]].parentPathArr;

  // Find the root ancestor the user has access to as it's not always the root of the parent path
  let idx = 0;
  let ancestorIdx, rootAncestorId;
  while (!rootAncestorId && idx < parentPath.length) {
    if (state[parentPath[idx]]) {
      ancestorIdx = idx;
      rootAncestorId = parentPath[idx];
    }
    idx++;
  }

  // Navigate the ancestor tree and insert these teams at their correct level
  const ancestorTree = Object.assign({}, state[rootAncestorId]);
  const accessiblePath = parentPath.slice(ancestorIdx + 1);
  const subTeamTree = insertSubTeam(ancestorTree, accessiblePath, subTeamIds);

  return {
    ...state,
    [rootAncestorId]: subTeamTree
  };
};

/**
 * Create any root team nodes required.
 */
const updateRootNodes = (state, teams) => {
  if (!teams) return state;
  const updatedState = Object.assign({}, state);
  const rootTeamIds = Object.keys(teams);

  // Compensate for the bug where users can have explicit access given to sub teams of another team
  // where they also have explicit access. We only root nodes that don't have an ancestor in the
  // list of root nodes. The 'skip' condition can be removed when the db data is cleaned up.
  rootTeamIds.forEach(teamId => {
    const skip = teams[teamId].parentPathArr.some(parentId =>
      rootTeamIds.includes(parentId)
    );
    // If root node not already present then add it, otherwise move on.
    if (!skip && !updatedState[teamId]) {
      updatedState[teamId] = {};
    }
  });

  return updatedState;
};

const teamHierarchyReducers = (state = initState, action) => {
  switch (action.type) {
    case types.SUB_TEAMS_REQUEST_SUCCESS: {
      const { entities } = action.response;
      if (!entities) return state;
      const { teams } = entities;
      return updateChildNodes(state, teams);
    }
    case types.TEAMS_REQUEST_SUCCESS: {
      const { entities } = action.response;
      if (!entities) return state;
      const { teams } = entities;
      return updateRootNodes(state, teams);
    }
    default:
      return state;
  }
};

export default teamHierarchyReducers;
