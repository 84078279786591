import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import SubPageLoading from "views/pages/SubPageLoading";
const UserSettingsPage = lazy(() => import("./UserSettingsPage"));

export const UserSettingsPageLazy = props => (
  <Suspense fallback={<SubPageLoading />}>
    <UserSettingsPage {...props} />
  </Suspense>
);
UserSettingsPageLazy.displayName = "UserSettingsPage";

export default WithHeader(UserSettingsPageLazy);
