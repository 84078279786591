export const formatSearchResultList = searchResults => {
  return searchResults.map(searchResult => ({
    ...searchResult,
    media: {
      id: searchResult.mediaId,
      name: searchResult.name,
      priority: null,
      createdAt: null,
      updatedAt: null,
      status: "ACTIVE",
      thumbnail: searchResult.thumbnailUrl.split("/").pop(),
      asset: searchResult.url.split("/").pop(),
      preview: searchResult.previewUrl.split("/").pop(),
      type: searchResult.type,
      size: 0,
      height: null,
      width: null
    }
  }));
};

export default formatSearchResultList;
