import { rotateRect } from "lib/transform";

const handleCoordinates = ["NW", "NE", "SE", "SW"];

class CalculateCropMaskScale {
  static calc(args = {}) {
    const instance = new CalculateCropMaskScale(args);

    return instance.getNewElementAttributes();
  }

  constructor({
    differenceFromInitialOffset,
    dragItem,
    dragItemType,
    imageElement,
    pageOffset,
    zoom,
    adjustmentOffset
  }) {
    this.differenceFromInitialOffset = differenceFromInitialOffset;
    this.dragItem = dragItem;
    this.dragItemType = dragItemType;
    this.imageElement = imageElement;
    this.pageOffset = pageOffset;
    this.zoom = zoom;
    this.adjustmentOffset = adjustmentOffset;
    this.isFlippedHorizontally = this.imageElement.scaleX === -1;
    this.isFlippedVertically = this.imageElement.scaleY === -1;
  }

  getOppositeHandleIndex() {
    const currentIndex = handleCoordinates.indexOf(this.dragItem.position);
    return (currentIndex + 2) % 4;
  }

  calculateRotatedOppositePoint(height, width, top, left, rotation) {
    // define the center point of the rectangle
    const rectCenter = { x: left + width / 2, y: top + height / 2 };

    // rotate the rectangle
    const rotatedRect = rotateRect(
      rectCenter.x,
      rectCenter.y,
      width,
      height,
      rotation
    );

    // calculate the index for the handle directly opposite the current one
    const oppositeHandleIndex = this.getOppositeHandleIndex();

    const rotatedOppositePoint = rotatedRect[oppositeHandleIndex];

    return rotatedOppositePoint;
  }

  calcTopIncrement(height) {
    return this.imageElement.height - height;
  }

  /* the HeightResizeScaleFactor tell us if the image is growing or shrinking vertically*/
  calcHeightResizeScaleFactor() {
    if (!this.dragItem.position.startsWith("N")) {
      return -1;
    }
    return 1;
  }

  // calculates the difference in height that is a side effect of drag
  getHeightIncrement() {
    const heightIncrement = this.differenceFromInitialOffset.y / this.zoom;
    return heightIncrement * this.calcHeightResizeScaleFactor();
  }

  // checks if handle is on the bottom of the image
  isYGreaterThanAnchor() {
    const { selectionBoxNodeCenter, rotatedHandlerPosition } = this.dragItem;
    return rotatedHandlerPosition.y > selectionBoxNodeCenter.y;
  }

  // determines if moving top value is allowed from this handle
  canHandleMoveTop() {
    return this.isFlippedVertically
      ? this.isYGreaterThanAnchor()
      : !this.isYGreaterThanAnchor();
  }

  // determines if moving the top of the mask is valid from this handle
  canHandleMoveMaskTop() {
    return this.isFlippedVertically
      ? this.dragItem.position.startsWith("S")
      : this.dragItem.position.startsWith("N");
  }

  // calculates the new top position taking into account if the handle allows this
  calcTop(topIncrement) {
    if (this.canHandleMoveTop()) {
      return this.imageElement.top + topIncrement;
    }
    return this.imageElement.top;
  }

  calcVerticalDimensions() {
    const heightIncrement = this.getHeightIncrement();
    let newHeight = this.imageElement.height - heightIncrement;

    if (newHeight < 0) {
      // new height should never be negative
      newHeight = 0;
    }

    let topIncrement = this.calcTopIncrement(newHeight);

    const newMaskTop = this.canHandleMoveMaskTop()
      ? this.imageElement.mask.top + topIncrement / this.imageElement.scale
      : this.imageElement.mask.top;

    /* if the new maskTop is smaller than 0, it means the crop area was dragged above the image's height
     * we need to cap it to the mask image height */
    if (newMaskTop < 0) {
      // remove the excess from the height to for this newHeight
      newHeight = newHeight + newMaskTop * this.imageElement.scale;

      topIncrement = this.calcTopIncrement(newHeight);
    }

    const imageHeight = this.imageElement.scale * this.imageElement.srcHeight;
    const maxHeight = imageHeight - newMaskTop * this.imageElement.scale;

    /* if the new height bigger than the imageHeight, it means the crop area was dragged below the image's height
     * we need to cap it to the mask image height */
    if (newHeight > maxHeight) {
      const diff = newHeight - maxHeight;

      newHeight = newHeight - diff;
    }

    const newTop = this.calcTop(topIncrement);
    const updatedMaskTop = this.canHandleMoveMaskTop()
      ? this.imageElement.mask.top + topIncrement / this.imageElement.scale
      : this.imageElement.mask.top;

    const newBottom = updatedMaskTop * this.imageElement.scale + newHeight;
    const bottomOffset = imageHeight - newBottom;

    return {
      height: newHeight,
      top: newTop,
      maskTop: updatedMaskTop,
      bottom: bottomOffset / this.imageElement.scale
    };
  }

  calcLeftIncrement(width) {
    return this.imageElement.width - width;
  }

  /* the WidthResizeFactor tell us if the image is growing or shrinking horizontally*/
  calcWidthResizeScaleFactor() {
    if (this.dragItem.position.endsWith("E")) {
      return -1;
    }
    return 1;
  }

  // calculates the difference in width that is a side effect of drag
  getWidthIncrement() {
    const widthIncrement = this.differenceFromInitialOffset.x / this.zoom;

    return widthIncrement * this.calcWidthResizeScaleFactor();
  }

  // checks if handle is on the right side of the image
  isXGreaterThanAnchor() {
    const { selectionBoxNodeCenter, rotatedHandlerPosition } = this.dragItem;
    return rotatedHandlerPosition.x > selectionBoxNodeCenter.x;
  }

  // determines if moving left value is allowed from this handle
  canHandleMoveLeft() {
    return this.isFlippedHorizontally
      ? this.isXGreaterThanAnchor()
      : !this.isXGreaterThanAnchor();
  }

  // determines if moving the left of the mask is valid from this handle
  canHandleMoveMaskLeft() {
    return this.isFlippedHorizontally
      ? this.dragItem.position.endsWith("E")
      : this.dragItem.position.endsWith("W");
  }

  // calculates the new left position taking into account if the handle allows this
  calcLeft(leftIncrement) {
    if (this.canHandleMoveLeft()) {
      return this.imageElement.left + leftIncrement;
    }
    return this.imageElement.left;
  }

  calcHorizontalDimensions() {
    const widthIncrement = this.getWidthIncrement();
    let newWidth = this.imageElement.width - widthIncrement;

    if (newWidth < 0) {
      // new width should never be negative
      newWidth = 0;
    }

    let leftIncrement = this.calcLeftIncrement(newWidth);

    const newMaskLeft = this.canHandleMoveMaskLeft()
      ? this.imageElement.mask.left + leftIncrement / this.imageElement.scale
      : this.imageElement.mask.left;

    /* if the new maskTop is smaller than 0, it means the crop area was dragged above the image's height
     * we need to cap it to the mask image height, so we need to recalculate the leftIncrement */
    if (newMaskLeft < 0) {
      // remove the excess from the width to for this newWidth
      newWidth = newWidth + newMaskLeft * this.imageElement.scale;

      leftIncrement = this.calcLeftIncrement(newWidth);
    }

    const imageWidth = this.imageElement.scale * this.imageElement.srcWidth;
    const maxWidth = imageWidth - newMaskLeft * this.imageElement.scale;

    /* if the new width bigger than the imageWidth, it means the crop area was dragged below the image's width
     * we need to cap it to the mask image width */
    if (newWidth > maxWidth) {
      const diff = newWidth - maxWidth;

      newWidth = newWidth - diff;
    }

    const newLeft = this.calcLeft(leftIncrement);
    const updatedMaskLeft = this.canHandleMoveMaskLeft()
      ? this.imageElement.mask.left + leftIncrement / this.imageElement.scale
      : this.imageElement.mask.left;

    const newRight = updatedMaskLeft * this.imageElement.scale + newWidth;
    const rightOffset = imageWidth - newRight;

    return {
      width: newWidth,
      left: newLeft,
      maskLeft: updatedMaskLeft,
      right: rightOffset / this.imageElement.scale
    };
  }

  calcRotatedAdjustments(height, width, top, left) {
    // collect the opposite points from the handle for both old and new masks
    // since the opposite point is never adjusted and therefore the difference
    // is only the rotation offset
    const oldRotatedOppositePoint = this.calculateRotatedOppositePoint(
      this.imageElement.height,
      this.imageElement.width,
      this.imageElement.top,
      this.imageElement.left,
      this.imageElement.angle
    );

    const newRotatedOppositePoint = this.calculateRotatedOppositePoint(
      height,
      width,
      top,
      left,
      this.imageElement.angle
    );

    const rotatedOppositePointDifference = {
      x: oldRotatedOppositePoint.x - newRotatedOppositePoint.x,
      y: oldRotatedOppositePoint.y - newRotatedOppositePoint.y
    };

    let leftAdjustment = rotatedOppositePointDifference.x;
    let topAdjustment = rotatedOppositePointDifference.y;

    return {
      adjustedTop: top + topAdjustment,
      adjustedLeft: left + leftAdjustment
    };
  }

  getNewElementAttributes() {
    const { height, top, maskTop, bottom } = this.calcVerticalDimensions();
    const { width, left, maskLeft, right } = this.calcHorizontalDimensions();

    // handle rotation offsets for the cropping
    const { adjustedTop, adjustedLeft } = this.calcRotatedAdjustments(
      height,
      width,
      top,
      left
    );

    const newImageElementUpdated = {
      ...this.imageElement,
      height,
      width,
      top: adjustedTop,
      left: adjustedLeft,
      mask: {
        top: maskTop,
        left: maskLeft,
        right,
        bottom
      }
    };

    return newImageElementUpdated;
  }
}

export default CalculateCropMaskScale;
