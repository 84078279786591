import React from "react";
import PropTypes from "prop-types";
import BillingCardsModal from "./BillingCardsModal";
import PopoutItemMenu from "views/components/popout/PopoutItemMenu/PopoutItemMenu";
import style from "./style.module.css";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import PlusIcon from "views/components/icons/PlusIcon";
import SmoothPencilIcon from "views/components/icons/SmoothPencilIcon";
import TrashCanIcon from "views/components/icons/TrashCanIcon";

class BillingCards extends React.Component {
  state = {
    isPopoutVisible: false
  };

  componentDidMount = () => {
    this.props.fetchCards({ teamId: this.props.currentTeam.id });
  };

  canRemoveCard = () => {
    const { code } = this.props.currentSubscriptionPlan || {};

    return code === "BASIC";
  };

  getLogoPath = () => {
    const logoFiles = {
      "American Express": "american_express.png",
      "Diners Club": "diners_club.png",
      Discover: "discover.png",
      JCB: "jcb.png",
      MasterCard: "mastercard.png",
      Visa: "visa.png"
    };

    const logoBrand = (this.props.currentCard || {}).brand;
    const logoFile = logoFiles[logoBrand] || "unknown.png";

    return `/credit_card_logos/${logoFile}`;
  };

  hidePopout = () => {
    this.setState({ isPopoutVisible: false });
  };

  removeCard = () => {
    this.setState({ isPopoutVisible: false });
    this.props.removeCard(this.props.currentCard);
  };

  showModal = () => {
    this.setState({ isPopoutVisible: false });
    this.props.toggleModal({ name: "creditCard", visible: true });
  };

  showPopout = event => {
    event.stopPropagation();
    this.setState({ isPopoutVisible: true });
  };

  render = () => {
    return (
      <>
        <h2>Cards</h2>

        {this.props.currentCard ? (
          <div className={style.billingCard}>
            <div
              className="logo"
              style={{
                backgroundImage: `url("${this.getLogoPath()}")`
              }}
            />
            <div className="number">
              {this.props.currentCard.brand}
              <b> **{this.props.currentCard.last4}</b>
            </div>
            <div className={style.ellipsisWrapper}>
              <button
                className={style.ellipsisButton}
                onClick={this.showPopout}
              >
                <EllipsisIcon />
              </button>
              {this.state.isPopoutVisible && (
                <PopoutItemMenu
                  actions={[
                    {
                      Icon: SmoothPencilIcon,
                      name: "Update card",
                      onClick: this.showModal
                    },
                    ...(this.canRemoveCard()
                      ? [
                          {
                            Icon: TrashCanIcon,
                            name: "Remove card",
                            onClick: this.removeCard
                          }
                        ]
                      : [])
                  ]}
                  className={style.ellipsisMenu}
                  onClose={this.hidePopout}
                />
              )}
            </div>
          </div>
        ) : (
          <button className={style.addButton} onClick={this.showModal}>
            <PlusIcon size="16" />
            Add card
          </button>
        )}

        <BillingCardsModal
          addCard={this.props.addCard}
          creditCardModal={this.props.creditCardModal}
          currentCard={this.props.currentCard}
          currentTeam={this.props.currentTeam}
          toggleModal={this.props.toggleModal}
        />
      </>
    );
  };
}

BillingCards.displayName = "BillingCards";

BillingCards.propTypes = {
  addCard: PropTypes.func.isRequired,
  creditCardModal: PropTypes.object.isRequired,
  currentCard: PropTypes.object,
  currentSubscription: PropTypes.object.isRequired,
  currentSubscriptionPlan: PropTypes.object.isRequired,
  currentTeam: PropTypes.object.isRequired,
  removeCard: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default BillingCards;
