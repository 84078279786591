import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const UpgradeSuccessPage = lazy(() => import("./UpgradeSuccessPage"));

export const UpgradeSuccessPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <UpgradeSuccessPage {...props} />
  </Suspense>
);
UpgradeSuccessPageLazy.displayName = "SignupPage";

export default UpgradeSuccessPageLazy;
