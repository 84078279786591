import * as types from "state/entities/userTeamFonts/userTeamFontsTypes";
import { CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS } from "state/currentUser/currentUserTypes";
import { omit, isEmpty } from "lib";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50,
  isProcessing: false
};

export const handleUserTeamFontRequest = ({ action, state }) => {
  const { page } = action.request;
  return {
    ...state,
    pages: {
      ...state.pages,
      [page]: {
        ...state.pages[page],
        isFetching: true
      }
    }
  };
};

export const handleUserTeamFontRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      pages: omit(state.pages, action.request.page)
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    pages: { ...state.pages, ...updatedPage }
  };
};

const userTeamFontsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USER_TEAM_FONTS_REQUEST: {
      return handleUserTeamFontRequest({ state, action });
    }

    case types.USER_TEAM_FONTS_REQUEST_SUCCESS: {
      return handleUserTeamFontRequestSuccess({ state, action });
    }

    case CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS: {
      return initState;
    }

    case types.USER_FONTS_IS_PROCESSING: {
      return {
        ...state,
        isCssProcessing: action.isProcessing
      };
    }

    default:
      return state;
  }
};

export default userTeamFontsApiReducers;
