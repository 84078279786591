import { sixDigitHexColor } from "lib/Colors/colorUtils";
import * as imageColorExtractor from "node-vibrant";
import { EDITOR_ELEMENTS_MAP } from "lib/constants";
import { hasRichText } from "lib/textUtils";

export default function hasMatchingColor(element, colorProp, isRichText) {
  const hexColor = sixDigitHexColor(colorProp);
  const hasObjWithColor = ({ backgroundColor, color, shadow, outline }) => {
    if (backgroundColor && sixDigitHexColor(backgroundColor) === hexColor) {
      return true;
    }

    if (color && sixDigitHexColor(color) === hexColor) {
      return true;
    }

    if (shadow && hasObjWithColor(shadow)) {
      return true;
    }

    if (outline && hasObjWithColor(outline)) {
      return true;
    }

    return false;
  };

  if ((element.fillColors || []).some(hasObjWithColor)) {
    return true;
  }

  if (element.type === EDITOR_ELEMENTS_MAP.TEXTBOX) {
    // Go through element.value and check if span tags have a matching color value

    const parser = new DOMParser();
    const doc = parser.parseFromString(element.value, "text/html");
    const spans = doc.getElementsByTagName("span");
    const spanArray = [...spans];
    let colorMap = [];
    if (spans.length) {
      colorMap = spanArray.map(span => {
        const colorInRGB = span.style["color"]
          .replace("rgb(", "")
          .replace(")", "")
          .split(",")
          .map(color => parseInt(color));
        const colorInHex = imageColorExtractor.Util.rgbToHex(...colorInRGB);
        return colorInHex.toUpperCase();
      });
    }
    if (colorMap.length && colorMap.includes(hexColor)) {
      return true;
    }
  }

  if (element.type === EDITOR_ELEMENTS_MAP.TABLE) {
    const hasTextFieldWithColor = ({ textFields }) =>
      textFields.some(hasObjWithColor);
    const hasCellMetaWithColor = ({ cellsMetadata }) =>
      cellsMetadata.some(hasTextFieldWithColor);
    const rowTypes = Object.values(element.columnsMetadata);

    if (rowTypes.some(hasCellMetaWithColor)) {
      return true;
    }
  }

  if (element.type === EDITOR_ELEMENTS_MAP.TABLE2) {
    const table2Cells = Object.values(element.cells);
    const matchingRichTextCells = [];

    table2Cells.forEach(cell => {
      if (hasRichText(cell.value)) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(cell.value, "text/html");
        const spans = doc.getElementsByTagName("span");
        const spanArray = [...spans];
        let colorMap = [];
        if (spans.length) {
          colorMap = spanArray.map(span => {
            const colorInRGB = span.style["color"]
              .replace("rgb(", "")
              .replace(")", "")
              .split(",")
              .map(color => parseInt(color));
            const colorInHex = imageColorExtractor.Util.rgbToHex(...colorInRGB);
            return colorInHex.toUpperCase();
          });
        }
        if (colorMap.length && colorMap.includes(hexColor)) {
          matchingRichTextCells.push(cell);
        }
      }
    });

    return table2Cells.some(hasObjWithColor) || matchingRichTextCells.length;
  }

  if (element.type === EDITOR_ELEMENTS_MAP.GRID) {
    return element.imageInstructions.some(hasObjWithColor);
  }

  return hasObjWithColor(element);
}
