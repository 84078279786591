import * as entityTypes from "state/entities/orders/ordersTypes";
import { immutableUpdate } from "lib/immutableUpdate";

export const initState = {
  pages: {},
  pageSize: 50
};

export const ordersReducers = (state = initState, action) => {
  switch (action.type) {
    case entityTypes.FETCH_ORDERS_REQUEST: {
      const { page } = action.request;

      return immutableUpdate(state, {
        pages: {
          $auto: {
            [page]: {
              $auto: {
                $merge: {
                  isFetching: true,
                  ids: []
                }
              }
            }
          }
        }
      });
    }

    case entityTypes.FETCH_ORDERS_REQUEST_SUCCESS: {
      const {
        response: { ids = [] },
        request: { page }
      } = action;

      return immutableUpdate(state, {
        pages: {
          $auto: {
            [page]: {
              $auto: {
                $merge: {
                  isFetching: false,
                  ids
                }
              }
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default ordersReducers;
