import { pick, isEmpty } from "lib/lodash";
import { uuid } from "lib/uuid";
import {
  capitalizeFirstLetter,
  sanitizeHTMLForTextUsingDOMNodes
} from "lib/textUtils";
import { decodeHtml } from "lib/htmlStrings";
import deepFreeze from "deep-freeze";
import Borders from "./Borders";
import ElementFactory from "state/ui/editor/elements/ElementFactory";
import { tableHeightsUpdater } from "views/components/Editor/editorOps/EditorTableOps/tableSimulator";

const GENERIC_COLUMN_NAME = "genericColumn";

const tableProcess = element => {
  element = deepFreeze(element);

  /* we wont process tables without rows they
   * will be removed from the design in a next step */
  if (isEmpty(element.rows)) {
    return element;
  }

  let table = { ...element };

  table = standarizeTableData(table);

  table = processTableColumnsMetadata(table);

  table = processTableRows(table);

  table = setTextFieldHeights(table);

  table = standardizeRestrictions(table);

  return table;
};

const standardizeRestrictions = table => {
  const restrictions = [...table.restrictions];

  const bordersIndex = restrictions.indexOf("borders");

  if (bordersIndex !== -1) {
    restrictions[bordersIndex] = "border";
  }

  return {
    ...table,
    restrictions
  };
};

const processTableColumnsMetadata = table => {
  const columnsMetadataProcessed = columnsMetadataProcess(
    table.columnsMetadata
  );

  const tableProcessed = {
    ...table,
    columnsMetadata: columnsMetadataProcessed
  };

  return tableProcessed;
};

const processTableRows = table => {
  const tableRowTypes = Object.keys(table.columnsMetadata);

  const rowsProcessed = table.rows.map(row => rowProcess(row, tableRowTypes));

  const tableProcessed = {
    ...table,
    rows: rowsProcessed
  };

  return tableProcessed;
};

const standarizeTableData = table => {
  if (!Array.isArray(table.columnsMetadata)) return table;

  const columnsMetadataNewFormat = {
    [GENERIC_COLUMN_NAME]: {
      cellsMetadata: table.columnsMetadata
    }
  };

  const tableElement = {
    ...table,
    columnsMetadata: columnsMetadataNewFormat
  };

  return tableElement;
};

const setTextFieldHeights = table => {
  if (textFieldsHaveHeight(table)) return table;

  const element = ElementFactory.create(table);

  return tableHeightsUpdater(element);
};

const textFieldsHaveHeight = table => {
  return Boolean(table.rows[0].cells[0].textFields[0].height);
};

const columnsMetadataProcess = columnsMetadata => {
  const newColumnsMetadata = {};
  Object.keys(columnsMetadata).forEach(columnMetadataKey => {
    newColumnsMetadata[columnMetadataKey] = {
      ...columnsMetadata[columnMetadataKey],
      cellsMetadata: cellsMetadataProcess(
        columnsMetadata[columnMetadataKey].cellsMetadata
      )
    };
  });

  return newColumnsMetadata;
};

const cellsMetadataProcess = cellsMetadata => {
  return cellsMetadata.map(cellMetadata => {
    return {
      width: 1,
      ...cellMetadata,
      textFields: cellMetadaTextFieldsProcess(cellMetadata.textFields)
    };
  });
};

const cellMetadaTextFieldsProcess = cellMetadataTextFields => {
  return cellMetadataTextFields.map(textFieldMetadata => {
    const letterSpacing =
      textFieldMetadata.letterSpacing === "normal"
        ? 0
        : textFieldMetadata.letterSpacing;

    const borders = bordersProcess(textFieldMetadata.borders || []);

    return {
      ...textFieldMetadata,
      letterSpacing,
      borders
    };
  });
};

const bordersProcess = borders => {
  const newBorders = {
    borderBottom: { color: null },
    borderTop: { color: null },
    borderRight: { color: null },
    borderLeft: { color: null }
  };

  borders.forEach(({ edge: position, color }) => {
    newBorders[`border${capitalizeFirstLetter(position)}`] = { color };
  });

  return new Borders(newBorders);
};

const rowProcess = (row, tableRowTypes) => {
  return {
    ...row,
    rowTypeCode: defineRowType(),
    id: row.id ? row.id : uuid(),
    cells: row.cells.map(cellProcess)
  };

  function defineRowType() {
    if (!row.rowTypeCode) {
      return GENERIC_COLUMN_NAME;
    }

    if (tableRowTypes.includes(row.rowTypeCode)) {
      return row.rowTypeCode;
    }

    return tryToMatchRowType({ tableRowTypes, rowTypeCode: row.rowTypeCode });
  }
};

function tryToMatchRowType({ tableRowTypes, rowTypeCode }) {
  const stringMatcher = (a, b) =>
    String(a).toLowerCase() === String(b).toLowerCase();

  const match = tableRowTypes.find(tableRowType =>
    stringMatcher(tableRowType, rowTypeCode)
  );

  if (match) {
    return match;
  } else {
    throw new Error("Table has invalid row type;");
  }
}

const cellProcess = cell => {
  return {
    ...cell,
    textFields: cell.textFields.map(textFieldProcess)
  };
};

const textFieldProcess = textField => {
  const textFieldAttributes = ["height", "value", "color"];

  let value = textField.value.replace(/(\r\n|\n|\r|↵)/g, "<br>");
  value = decodeHtml(value);
  value = sanitizeHTMLForTextUsingDOMNodes(value);
  const newTextField = {
    ...pick(textField, textFieldAttributes),
    id: uuid(),
    value
  };
  return newTextField;
};

export default tableProcess;
