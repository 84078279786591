import * as types from "state/entities/catalogueSizes/catalogueSizesTypes";
import { getPath } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 100
};

const api = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_SIZES_REQUEST: {
      const isArchived = getPath(
        action,
        ["request", "params", "archived"],
        false
      );

      if (isArchived) {
        const currentPages = getPath(state, "archived.pages", {});

        const page = {
          [action.request.page]: {
            ...currentPages[action.request.page],
            isFetching: true,
            lastFetched: Date.now()
          }
        };

        return {
          ...state,
          archived: {
            ...state.archived,
            pages: { ...currentPages, ...page }
          }
        };
      } else {
        const currentPages = getPath(state, "pages", {});

        const page = {
          [action.request.page]: {
            ...currentPages[action.request.page],
            isFetching: true,
            lastFetched: Date.now()
          }
        };

        return {
          ...state,
          pages: { ...currentPages, ...page }
        };
      }
    }

    case types.TEAM_SIZES_REQUEST_SUCCESS: {
      const isArchived = getPath(
        action,
        ["request", "params", "archived"],
        false
      );

      if (isArchived) {
        const currentPages = getPath(state, "archived.pages", {});

        const {
          request: { page },
          response: { ids }
        } = action;

        const pages = {
          ...currentPages,
          [page]: {
            isFetching: false,
            ids: ids,
            didInvalidate: false,
            lastFetched: Date.now()
          }
        };

        return {
          ...state,
          archived: {
            ...state.archived,
            pages: pages
          }
        };
      } else {
        const currentPages = getPath(state, "pages", {});

        const {
          request: { page },
          response: { ids }
        } = action;
        const pages = {
          ...currentPages,
          [page]: {
            isFetching: false,
            ids: ids,
            didInvalidate: false,
            lastFetched: Date.now()
          }
        };

        return {
          ...state,
          pages: pages
        };
      }
    }

    case types.TEAM_SIZES_FILTER_REQUEST: {
      const currentPages = getPath(state, "pages", {});

      const page = {
        [action.request.page]: {
          ...currentPages[action.request.page],
          isFetching: true
        }
      };

      return {
        ...state,
        pages: { ...currentPages, ...page }
      };
    }

    case types.TEAM_SIZES_FILTER_REQUEST_SUCCESS: {
      const currentPages = getPath(state, "pages", {});

      const {
        request: { page },
        response: { ids }
      } = action;
      const pages = {
        ...currentPages,
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false
        }
      };

      return {
        ...state,
        pages: pages
      };
    }

    case types.TEAM_SIZES_FOLDER_REQUEST: {
      const folderId = getPath(action, ["request", "params", "folderId"]);
      const pageId = getPath(action, ["request", "page"]);
      if (!folderId) return state;

      return immutableUpdate(state, {
        folders: {
          $auto: {
            [folderId]: {
              $auto: {
                pages: {
                  $auto: {
                    [pageId]: {
                      $auto: {
                        isFetching: {
                          $set: true
                        },
                        lastFetched: {
                          $set: Date.now()
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.TEAM_SIZES_FOLDER_REQUEST_SUCCESS: {
      const folderId = getPath(action, ["request", "params", "folderId"]);
      const pageId = getPath(action, ["request", "page"]);
      if (!folderId) return state;
      const ids = getPath(action, ["response", "ids"], []);

      return immutableUpdate(state, {
        folders: {
          $auto: {
            [folderId]: {
              $auto: {
                pages: {
                  $auto: {
                    [pageId]: {
                      $auto: {
                        isFetching: {
                          $set: false
                        },
                        ids: {
                          $set: ids
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default api;
