import { omit } from "lib/lodash";
import formatTableDataForV1 from "./formatTableDataForV1";
import formatTextboxDataForV1 from "./formatTextboxDataForV1";
import formatImageDataForV1 from "./formatImageDataForV1";

const groupData = ({ elementsIds, data }) => {
  const elements = elementsIds.map(id => data.elements[id]);
  const groupElements = elements.filter(element => element.type === "group");

  return groupElements.map(group => {
    return {
      id: group.uniqueId,
      name: group.name,
      elements: group.elementsOrder,
      visible: !group.isHidden,
      opacity: group.opacity,
      angle: group.angle
    };
  });
};

const elementsData = ({ elementsIds, data }) => {
  const elements = elementsIds.map(id => data.elements[id]);
  const elementsList = [];

  elements.forEach(element => {
    if (element.type === "group") {
      const ids = element.elementsOrder;
      ids.forEach(id => {
        const nestedElementFormatted = formatElementForV1(data.elements[id]);
        elementsList.push({
          ...nestedElementFormatted,
          groupId: element.uniqueId
        });
      });
    } else {
      const elementFormatted = formatElementForV1(element);
      elementsList.push(elementFormatted);
    }
  });

  return elementsList.map(element => omit(element, ["getElement"]));
};

const formatElementForV1 = element => {
  let elementFormatted = null;

  switch (element.type) {
    case "table":
      elementFormatted = formatTableDataForV1(element);
      break;

    case "textbox":
      elementFormatted = formatTextboxDataForV1(element);
      break;

    case "grid":
    case "vector":
      elementFormatted = formatImageDataForV1(element);
      break;
    default:
      elementFormatted = element;
  }

  return elementFormatted;
};

const pageDataById = ({ id, data }) => {
  const page = data.pages[id];
  const elementsIdList = page.elementsOrder;

  return {
    width: data.width,
    height: data.height,
    elements: elementsData({ elementsIds: elementsIdList, data }),
    backgroundColor: page.backgroundColor,
    groups: groupData({ elementsIds: elementsIdList, data }),
    uniqueId: id,
    guides: page.guides,
    duration: page.duration,
    isDurationManual: page.isDurationManual,
    animatedElements: page.animatedElements || {},
    quickInputOrder: page.quickInputOrder || [],
    openQuickInput: page.openQuickInput || false
  };
};

const pagesData = data => {
  return data.pagesOrder.map(pageId => pageDataById({ id: pageId, data }));
};

const formatDataForV1 = data => {
  const transformedData = {
    bleed: data.bleed,
    createdAt: data.createdAt,
    data: pagesData(data),
    designId: data.designId,
    documentRestrictions: data.restrictions,
    id: data.id,
    ordered: data.ordered,
    updatedAt: data.updatedAt
  };

  return transformedData;
};

export default formatDataForV1;
