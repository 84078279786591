import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import SubPageLoading from "views/pages/SubPageLoading";
const TeamPage = lazy(() => import("./TeamPage"));

export const TeamPageLazy = props => (
  <Suspense fallback={<SubPageLoading />}>
    <TeamPage {...props} />
  </Suspense>
);
TeamPageLazy.displayName = "TeamPage";

export default WithHeader(TeamPageLazy);
