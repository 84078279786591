import { createSelector } from "reselect";

const shippingRatesSelector = state => state.entities.shippingRates;
const countryCodePassThrough = (state, countryCode) => countryCode;

const processFindRateByCountry = (rates, countryCode) => {
  return Object.keys(rates)
    .map(id => rates[id])
    .filter(rate => rate.country === countryCode);
};

export const findRateByCountry = createSelector(
  [shippingRatesSelector, countryCodePassThrough],
  processFindRateByCountry
);

const processAllShippingRates = rates => {
  return Object.keys(rates).map(id => rates[id]);
};

export const allShippingRates = createSelector(
  [shippingRatesSelector],
  processAllShippingRates
);
