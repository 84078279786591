import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const NewPageIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-2h20v20H-3z" />
      <g fill={color || easilGrayXDark}>
        <path d="M4.8 11.1h2.3a.9.9 0 1 1 0 1.8H4.8v2.2a.9.9 0 1 1-1.8 0v-2.2H.9a.9.9 0 1 1 0-1.8H3V8.9a.9.9 0 0 1 1.8 0v2.2z" />
        <path
          fillRule="nonzero"
          d="M1.9 6A.9.9 0 0 1 .1 6V3A2.9 2.9 0 0 1 3 .1h4.298A2.9 2.9 0 0 1 9.11.735l3.702 2.962A2.9 2.9 0 0 1 13.9 5.96V13a2.9 2.9 0 0 1-2.9 2.9h-1a.9.9 0 1 1 0-1.8h1a1.1 1.1 0 0 0 1.1-1.1V5.961a1.1 1.1 0 0 0-.413-.859L7.986 2.141a1.1 1.1 0 0 0-.688-.241H3A1.1 1.1 0 0 0 1.9 3v3z"
        />
      </g>
    </g>
  </svg>
);
NewPageIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default NewPageIcon;
