import { some } from "lib";
import { ordersEntitiesSelector } from "state/entities/orders/ordersSelectors";

export const ordersSelector = ({ state }) => state.api.orders;
export const ordersPageSelector = ({ state, page }) =>
  ordersSelector({ state }).pages[page] || {};

export const isAnyOrdersFetching = ({ state }) => {
  const orders = ordersSelector({ state });
  return some(orders.pages, { isFetching: true });
};

export const allOrderIdsSelector = ({ state }) => {
  const orders = ordersSelector({ state });
  return Object.values(orders.pages).reduce(
    (ids, page) => [...ids, ...(page.ids || [])],
    []
  );
};

export const allOrdersSelector = ({ state }) => {
  const ids = allOrderIdsSelector({ state });
  const ordersEntities = ordersEntitiesSelector(state);

  return ids.map(id => ordersEntities[id]);
};
