import React from "react";
import style from "./style.module.css";
import { Empty } from "views/components";

const EasilTemplatesSettingsLoading = () => (
  <div className={style.section} data-testid="EasilTemplatesSettingsLoading">
    <div className={style.title}>
      <Empty height="31px" width="155.91px" />
    </div>
    <div>
      <Empty height="96px" width="498px" />
    </div>
  </div>
);

export default EasilTemplatesSettingsLoading;
