import PropTypes from "prop-types";
import React from "react";

import { easilGrayXDark, easilGrayXLight } from "./style.module.css";

const TimesCircleIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g
      stroke="none"
      strokeWidth="1"
      fill={color || easilGrayXLight}
      fillRule="evenodd"
    >
      <g>
        <circle fill={color || easilGrayXLight} cx="10" cy="10" r="10" />
        <g transform="translate(4.000000, 4.000000)">
          <rect x="0" y="0" width="12" height="12" />
          <g
            transform="translate(2.000000, 2.000000)"
            fill={easilGrayXDark}
            fillRule="nonzero"
          >
            <path
              d="M7.6576683,6.11894302 C8.0481926,6.50946732 8.0481926,7.14263229 7.6576683,7.53315659 C7.26714401,7.92368088 6.63397903,7.92368088 6.24345474,7.53315659 L0.457021671,1.74672351 C0.0664973788,1.35619922 0.0664973788,0.723034244 0.457021671,0.332509952 C0.847545962,-0.05801434 1.48071094,-0.05801434 1.87123523,0.332509952 L7.6576683,6.11894302 Z"
              id="Path-9"
              transform="translate(4.057345, 3.932833) scale(-1, 1) translate(-4.057345, -3.932833) "
            />
            <path
              d="M7.6576683,6.11894302 C8.0481926,6.50946732 8.0481926,7.14263229 7.6576683,7.53315659 C7.26714401,7.92368088 6.63397903,7.92368088 6.24345474,7.53315659 L0.457021671,1.74672351 C0.0664973788,1.35619922 0.0664973788,0.723034244 0.457021671,0.332509952 C0.847545962,-0.05801434 1.48071094,-0.05801434 1.87123523,0.332509952 L7.6576683,6.11894302 Z"
              id="Path-9"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
TimesCircleIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TimesCircleIcon;
