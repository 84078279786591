import React from "react";
import PropTypes from "prop-types";

const SmoothHashCrossedIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="SmoothHashCrossedIcon"
  >
    <defs>
      <filter colorInterpolationFilters="auto" id="a">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.560784 0 0 0 0 0.572549 0 0 0 0 0.592157 0 0 0 1.000000 0"
        />
      </filter>
      <path id="b" d="M0 0h14.02v14.02H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width="20" height="20" rx="4" />
      <g filter="url(#a)">
        <path
          d="m12.253 8.01 1.757 1.757V8.01h2a1 1 0 1 0 0-2h-2v-2a1 1 0 1 0-2 0v2h-1.757l1.757 1.757.243.243z"
          fill="#0F0"
        />
        <g transform="translate(3 3)">
          <mask id="c" fill="#fff">
            <use xlinkHref="#b" />
          </mask>
          <path
            d="M5.01 6.425 7.596 9.01H5.01V6.425zm8.718 5.888L1.708.293A1 1 0 0 0 .292 1.708L1.596 3.01H1.01a1 1 0 0 0 0 2h2v4h-2a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h4v2a1 1 0 0 0 2 0v-.585l1.303 1.302a1 1 0 1 0 1.415-1.414z"
            fill="#0F0"
            mask="url(#c)"
          />
        </g>
      </g>
    </g>
  </svg>
);
SmoothHashCrossedIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SmoothHashCrossedIcon;
