import * as types from "./downloadsTypes";
import { merge } from "lib";

export const initState = {};

const downloadsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.DOWNLOAD_DESIGN_REQUEST_SUCCESS: {
      const {
        response: {
          ids: id,
          entities: { download: downloads }
        }
      } = action;
      const download = downloads[id];

      if (download.status) {
        const designDownload = { [download.designId]: download };

        return merge({}, initState, designDownload);
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

export default downloadsReducer;
