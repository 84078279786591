import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import {
  currentUserSelector,
  currentUserIdSelector
} from "state/currentUser/currentUserSelectors";
import * as types from "./smartTextTypes";
import { noop } from "lodash";
import { currentTeamSelector } from "../entitiesSelectors";

export const fetchAllSmartText = () => (dispatch, getState) => {
  dispatch(fetchTeamSmartText());
  dispatch(fetchUserTeamSmartText());
};

export const fetchTeamSmartText = () => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const teamName = currentTeamSelector(state).name;

  dispatch({
    [CALL_API]: {
      types: [
        types.FETCH_TEAM_SMART_TEXT_REQUEST,
        types.FETCH_TEAM_SMART_TEXT_SUCCESS,
        types.FETCH_TEAM_SMART_TEXT_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/smart-fields`,
      schema: schemas.SMART_TEXT,
      camelize: false,
      extra: {
        teamName
      }
    }
  });
};

export const fetchUserTeamSmartText = () => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserIdSelector(state);
  const user = currentUserSelector(state);

  dispatch({
    [CALL_API]: {
      types: [
        types.FETCH_USER_TEAM_SMART_TEXT_REQUEST,
        types.FETCH_USER_TEAM_SMART_TEXT_SUCCESS,
        types.FETCH_USER_TEAM_SMART_TEXT_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/users/${userId}/smart-fields`,
      schema: schemas.SMART_TEXT,
      camelize: false,
      extra: {
        user
      }
    }
  });
};

export const fetchDesignSmartText = ({ designId }) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      types: [
        types.FETCH_DESIGN_SMART_TEXT_REQUEST,
        types.FETCH_DESIGN_SMART_TEXT_SUCCESS,
        types.FETCH_DESIGN_SMART_TEXT_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/designs/${designId}/smart-fields`,
      schema: schemas.SMART_TEXT,
      camelize: false
    }
  });
};

export const updateDesignSmartFieldOwner = ({ designId }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = currentUserIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "PUT",
      types: [
        types.UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST,
        types.UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST_SUCCESS,
        types.UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST_FAILURE
      ],
      request: {
        body: {
          userId,
          designId
        }
      },
      service: SERVICES.USER,
      endpoint: `/designs/${designId}/smart-fields`,
      schema: schemas.NONE
    }
  });
};

export const addTeamSmartText = ({ smartText, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      types: [
        types.TEAM_SMART_TEXT_SAVE_REQUEST,
        types.TEAM_SMART_TEXT_SAVE_REQUEST_SUCCESS,
        types.TEAM_SMART_TEXT_SAVE_REQUEST_FAILURE
      ],
      request: {
        body: {
          ...smartText
        }
      },
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/smart-fields`,
      schema: schemas.SMART_TEXT,
      onSuccess: () => onSuccess(),
      camelize: false
    }
  });
};

export const addUserSmartText = ({ smartText, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      types: [
        types.CREATE_USER_TEAM_SMART_TEXT_REQUEST,
        types.CREATE_USER_TEAM_SMART_TEXT_REQUEST_SUCCESS,
        types.CREATE_USER_TEAM_SMART_TEXT_REQUEST_FAILURE
      ],
      request: {
        body: smartText
      },
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/users/${userId}/smart-fields`,
      schema: schemas.SMART_TEXT,
      onSuccess: () => onSuccess()
    }
  });
};

export const updateTeamSmartText = ({
  smartText,
  smartTextKey,
  onSuccess = noop
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const teamName = currentTeamSelector(state).name;

  dispatch({
    [CALL_API]: {
      method: "PUT",
      types: [
        types.UPDATE_TEAM_SMART_TEXT_REQUEST,
        types.UPDATE_TEAM_SMART_TEXT_SUCCESS,
        types.UPDATE_TEAM_SMART_TEXT_FAILURE
      ],
      request: {
        body: {
          ...smartText
        }
      },
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/smart-fields/${smartTextKey}`,
      schema: schemas.SMART_TEXT,
      onSuccess: () => onSuccess(),
      camelize: false,
      extra: {
        teamName
      }
    }
  });
};

export const deleteTeamSmartText = smartTextKey => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const teamName = currentTeamSelector(state).name;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      types: [
        types.DELETE_TEAM_SMART_TEXT_REQUEST,
        types.DELETE_TEAM_SMART_TEXT_SUCCESS,
        types.DELETE_TEAM_SMART_TEXT_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/smart-fields/${smartTextKey}`,
      schema: schemas.SMART_TEXT,
      extra: {
        smartTextKey,
        teamName
      }
    }
  });
};

export const deleteUserTeamSmartText = smartTextKey => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserIdSelector(state);
  const user = currentUserSelector(state);

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      types: [
        types.DELETE_USER_TEAM_SMART_TEXT_REQUEST,
        types.DELETE_USER_TEAM_SMART_TEXT_SUCCESS,
        types.DELETE_USER_TEAM_SMART_TEXT_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/users/${userId}/smart-fields/${smartTextKey}`,
      schema: schemas.SMART_TEXT,
      extra: {
        smartTextKey,
        user
      }
    }
  });
};

export const updateUserTeamSmartText = ({
  smartText,
  smartTextKey,
  onSuccess = noop
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserIdSelector(state);
  const user = currentUserSelector(state);

  dispatch({
    [CALL_API]: {
      method: "PUT",
      types: [
        types.UPDATE_USER_TEAM_SMART_TEXT_REQUEST,
        types.UPDATE_USER_TEAM_SMART_TEXT_SUCCESS,
        types.UPDATE_USER_TEAM_SMART_TEXT_FAILURE
      ],
      request: {
        body: {
          ...smartText
        }
      },
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/users/${userId}/smart-fields/${smartTextKey}`,
      schema: schemas.SMART_TEXT,
      onSuccess: () => onSuccess(),
      extra: {
        user
      }
    }
  });
};

export const setAutoCompleteDropdown = setAutoComplete => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.SET_AUTO_COMPLETE_DROPDOWN,
    payload: { setAutoComplete }
  });
};
