import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const InviteToTeamModal = lazy(() => import("./InviteToTeamModal"));

export const InviteToTeamModalLazy = props => (
  <Suspense fallback={<SubPageLoading height={"40px"} />}>
    <InviteToTeamModal {...props} />
  </Suspense>
);
InviteToTeamModalLazy.displayName = "InviteToTeamModal";

export default InviteToTeamModalLazy;
