import React, { Component } from "react";
import { Loading, Input, Button } from "views/components";
import EasilLogoIcon from "views/components/icons/EasilLogoIcon";
import style from "./style.module.css";
import { userValidators, pick, values } from "lib";
import PATHS from "routes/paths";
import { Link } from "react-router-dom";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isValid: false,
      email: { value: null, error: null }
    };
  }

  isFormValid(state) {
    const availableAttributes = ["email"];

    const attributes = pick(state, availableAttributes);

    return values(attributes).every(
      attribute => attribute.value && !attribute.error
    );
  }

  handleChange(attribute, value) {
    const { error } = userValidators[attribute](value);

    const newState = {
      ...this.state,
      [attribute]: { value: value, error: error }
    };

    this.setState({
      ...newState,
      isValid: this.isFormValid(newState),
      isSubmitted: false
    });
  }

  handleSubmit() {
    const { email } = this.state;

    this.props.onRequestPasswordEmailToken({ email: email.value });
  }

  render() {
    const { isSubmitting: isProcessing, isSuccess, errors } = this.props;
    const { email, isValid } = this.state;

    return (
      <React.Fragment>
        <div className={style.logo}>
          <EasilLogoIcon width="128px" />
        </div>
        <div className={style.title}>
          {isSuccess ? "Email sent" : "Reset your password"}
        </div>
        <div className={style.columns}>
          <div className={style.form}>
            {isSuccess && (
              <div>
                <p>
                  We've sent you an email to reset your password. <br />
                  Please follow the instructions to continue.
                </p>
                <div className={style.actions}>
                  <Link className={style.resetButton} to={PATHS.login}>
                    Back to Login
                  </Link>
                </div>
              </div>
            )}
            {!isSuccess && (
              <React.Fragment>
                <p>We'll email you instructions to reset your password.</p>
                <div>
                  <Input
                    disabled={isProcessing}
                    type="email"
                    placeholder="Email Address"
                    value={email.value || ""}
                    error={email.error}
                    onChange={e => this.handleChange("email", e.target.value)}
                    showErrorMsg={errors.length > 0}
                  />
                </div>
                <div className={style.actions}>
                  <Button
                    theme="greenSolid"
                    onClick={this.handleSubmit}
                    disabled={!isValid || isProcessing}
                  >
                    {isProcessing ? (
                      <Loading size="16px" hexColor="fff" />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                  <div className={style.links}>
                    <Link to={PATHS.login}>Back to Login</Link>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ResetPassword.propTypes = {};

export default ResetPassword;
