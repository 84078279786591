import React from "react";
import { ColorPicker } from "views/components";
import CrossThinIcon from "views/components/icons/CrossThinIcon";
import style from "./style.module.css";

export default function BrandKitColorGroupColor({
  color,
  isRemovable,
  isSelected,
  onClick,
  onPickerChange,
  onPickerClose,
  onRemove
}) {
  return (
    <div className={style.colorsGroupColor}>
      <div
        className={style.colorsGroupColorCircle}
        data-is-white={
          /#F{3}/i.test(color) || /#F{6}/i.test(color) || /^white$/i.test(color)
        }
        onClick={onClick}
        style={{ backgroundColor: color }}
      />

      {isRemovable && (
        <div className={style.colorsGroupColorRemove} onClick={onRemove}>
          <CrossThinIcon size="10px" />
        </div>
      )}

      {isSelected && (
        <ColorPicker
          className={style.colorsGroupColorPicker}
          color={color}
          isDisplayHeader
          isSaveButtonHidden
          onChange={onPickerChange}
          onClose={onPickerClose}
          onSave={onPickerClose}
        />
      )}
    </div>
  );
}
