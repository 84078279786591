export const USER_TEAM_FAVORITES_REQUEST =
  "API/ENTITIES/USER_TEAM_FAVORITES_REQUEST";
export const USER_TEAM_FAVORITES_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_FAVORITES_REQUEST_FAILURE";
export const USER_TEAM_FAVORITES_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_FAVORITES_REQUEST_SUCCESS";

export const USER_TEAM_FAVORITES_CREATE_REQUEST =
  "API/ENTITIES/USER_TEAM_FAVORITES_CREATE_REQUEST";
export const USER_TEAM_FAVORITES_CREATE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_FAVORITES_CREATE_REQUEST_FAILURE";
export const USER_TEAM_FAVORITES_CREATE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_FAVORITES_CREATE_REQUEST_SUCCESS";

export const USER_TEAM_FAVORITES_REMOVE_REQUEST =
  "API/ENTITIES/USER_TEAM_FAVORITES_REMOVE_REQUEST";
export const USER_TEAM_FAVORITES_REMOVE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_FAVORITES_REMOVE_REQUEST_FAILURE";
export const USER_TEAM_FAVORITES_REMOVE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_FAVORITES_REMOVE_REQUEST_SUCCESS";
