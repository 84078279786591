import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const ResetPasswordPage = lazy(() => import("./ResetPasswordPage"));

export const ResetPasswordPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <ResetPasswordPage {...props} />
  </Suspense>
);
ResetPasswordPageLazy.displayName = "ResetPasswordPage";

export default ResetPasswordPageLazy;
