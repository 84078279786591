import React, { Component } from "react";
import style from "./style.module.css";
import { clamp } from "lib";

export class ScrollingOverflowText extends Component {
  constructor(props) {
    super(props);

    this.applyScrollAnimation = this.applyScrollAnimation.bind(this);
    this.setDefaultScrollDuration = this.setDefaultScrollDuration.bind(this);
    this.wrapperRef = React.createRef();
    this.spanRef = React.createRef();
  }

  componentDidMount() {
    this.applyScrollAnimation();
  }

  componentDidUpdate() {
    this.applyScrollAnimation();
  }

  applyScrollAnimation() {
    const textWrapper = this.wrapperRef.current;
    const scrollingTextContent = this.spanRef.current;

    if (textWrapper && scrollingTextContent) {
      const textWrapperWidth = textWrapper.offsetWidth;
      const scollingTextWidth = scrollingTextContent.offsetWidth;

      const deltaWidth =
        textWrapper.offsetWidth - scrollingTextContent.offsetWidth;

      if (scollingTextWidth < textWrapperWidth) {
        scrollingTextContent.style.animation = "";
        // ensure we reset the values for our css variables
        // incase they were set before a content change
        textWrapper.style.setProperty("--delta-scroll-left", `0px`);
        textWrapper.style.setProperty("--animation-duration", `0s`);
        scrollingTextContent.style.paddingRight = "inherit";
      } else {
        textWrapper.style.setProperty(
          "--delta-scroll-left",
          `${clamp(deltaWidth, -Infinity, 0)}px`
        );
        textWrapper.style.setProperty(
          "--animation-duration",
          `${this.props.scrollDuration || this.setDefaultScrollDuration()}s`
        );
        scrollingTextContent.style.paddingRight = "10px";
      }
    }
  }

  setDefaultScrollDuration() {
    if (!this.props.children) return 0;
    return this.props.children.length * 0.07;
  }

  render() {
    const { className, children, customStyle, title } = this.props;

    return (
      <div
        className={`${style.scrollingTextWrapper} ${className}`}
        style={customStyle}
        ref={this.wrapperRef}
        title={title}
      >
        <span ref={this.spanRef} data-testid="scrollingOverflowText">
          {children}
        </span>
      </div>
    );
  }
}

export default ScrollingOverflowText;
