import { Logger } from "lib";
import {
  getCanvasbackgroundContent,
  getCanvasbackgroundContentWrapper
} from "views/components/Editor/utils";

const EditorPagesOps = {
  deletePage(index) {
    Logger.info("Editor.deletePage called");
    const { designData } = this.state;
    const { pagesOrder } = designData;

    if (designData.pagesOrder.length === 1) {
      return;
    }

    /* because arrays are 0 based and we should 1 based in the UI*/
    const correctedIndex = index - 1;

    this.updateStateAndSave({
      selectedItems: [], // when deleting page clear selection
      context: {}, // when deleting page clear context
      currentPageId:
        pagesOrder[correctedIndex - 1] || pagesOrder[correctedIndex + 1],
      designData: designData.deletePage(correctedIndex)
    });
  },

  addPage(newPages) {
    Logger.info("Editor.addPage called");
    const { designData } = this.state;

    designData.addPage(newPages).then(data => {
      this.updateStateAndSave({
        designData: data
      });
    });
  },

  replacePage(newPages, pageId) {
    Logger.info("Editor.replacePage called");
    this.clearSelectItems();
    const { designData } = this.state;
    designData.replacePage(newPages, pageId).then(data => {
      this.updateStateAndSave({
        currentPageId: data.pagesOrder[0],
        designData: data
      });
    });
  },

  copyPage(index) {
    Logger.info("Editor.copyPage called");
    const { designData } = this.state;

    /* because arrays are 0 based */
    const correctedIndex = index - 1;

    const scrollToCopiedPage = () => {
      const backgroundContent = getCanvasbackgroundContent();

      const scrollIndex = this.props.design.isAnimated ? index * 2 : index;

      // index * 2 is required since the pages also have an animationController in front of them
      backgroundContent.children[scrollIndex].scrollIntoView({
        block: "nearest",
        inline: "start"
      });
    };

    this.updateStateAndSave(
      {
        designData: designData.copyPage(correctedIndex)
      },
      scrollToCopiedPage /* callback */
    );
  },

  changePageOrder(currentIndex, newIndex) {
    Logger.info("Editor.changePageOrder called");
    const { designData } = this.state;

    /* Scroll to the moved page after editor reorder pages */
    const scrollToNewPagePosition = () => {
      const backgroundContent = getCanvasbackgroundContent();

      backgroundContent.children[newIndex].scrollIntoView({
        block: "nearest",
        inline: "start"
      });
    };

    this.updateStateAndSave(
      {
        designData: designData.changePageOrder(currentIndex, newIndex)
      },
      scrollToNewPagePosition /* callback */
    );
  },

  addNewPage() {
    Logger.info("Editor.onCreateGroup called");
    const { designData } = this.state;

    const scrollToNewPage = () => {
      const backgroundContent = getCanvasbackgroundContentWrapper();

      backgroundContent.scrollTop = backgroundContent.scrollHeight;
    };

    this.updateStateAndSave(
      {
        designData: designData.addNewPage()
      },
      scrollToNewPage /* callback */
    );
  }
};

export default EditorPagesOps;
