import React, { Component } from "react";
/* import PropTypes from 'prop-types';*/
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import GridCellCropResizeHandlerContainer from "./gridCellCropResizeHandler/GridCellCropResizeHandlerContainer";
import { Image, Video } from "views/components";
import ResizeBox from "views/components/Editor/ResizeBox";
import { getPlaceholderSize } from "views/components/Editor/elements/grid/imageInstructionUtils";
import { isVideo } from "lib/isVideo";

const GridMaskingDraggableImageSource = {
  beginDrag(props, monitor, component) {
    props.startGridCellCropMoving();
    return {};
  },
  endDrag(props, monitor) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

    props.finishGridCellCropMoving({ differenceFromInitialOffset });
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

class GridMaskingDraggableImage extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  cancelClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  render() {
    const {
      connectDragSource,
      pageOffset,
      gridElement,
      gridCell,
      isCropping,
      zoom,
      offsetLeft,
      offsetTop
    } = this.props;

    const placeholderSize = getPlaceholderSize(gridCell, gridElement);

    const transformedLeft =
      gridCell.scaleX === 1
        ? gridCell.left
        : placeholderSize.width - (gridCell.width + gridCell.left);
    const transformedTop =
      gridCell.scaleY === 1
        ? gridCell.top
        : placeholderSize.height - (gridCell.height + gridCell.top);

    const Handler = props => {
      return (
        <GridCellCropResizeHandlerContainer
          gridCell={gridCell}
          {...props}
          gridElement={gridElement}
        />
      );
    };

    return connectDragSource(
      <div
        style={{
          position: "absolute",
          top: pageOffset.top + gridElement.top * zoom,
          left: pageOffset.left + gridElement.left * zoom,
          height: gridElement.height * zoom,
          width: gridElement.width * zoom,
          zIndex: 4,
          opacity: isCropping ? 0 : 1,
          transform: [
            `rotate(${gridElement.angle}deg)`,
            `scaleX(${gridElement.scaleX})`,
            `scaleY(${gridElement.scaleY})`
          ].join(" ")
        }}
      >
        <div
          onClick={this.cancelClick}
          style={{
            position: "absolute",
            top: offsetTop * zoom,
            left: offsetLeft * zoom,
            zIndex: 4,
            opacity: isCropping ? 0 : 1,
            transform: `translate(${transformedLeft *
              zoom}px, ${transformedTop * zoom}px) scaleX(${
              gridCell.scaleX
            }) scaleY(${gridCell.scaleY})`
          }}
        >
          {isVideo(gridCell.previewSrc) ? (
            <Video
              src={gridCell.previewSrc}
              height={gridCell.height * zoom}
              width={gridCell.width * zoom}
              filter="brightness(0.5)"
              isLandscape={true}
              autoPlay
            />
          ) : (
            <Image
              src={gridCell.previewSrc}
              height={gridCell.height * zoom}
              width={gridCell.width * zoom}
              filter="brightness(0.5)"
              isLandscape={true}
            />
          )}
          <ResizeBox isResizing={false} Handler={Handler} isCropping={true} />
        </div>
      </div>
    );
  }
}

/* TextMaskingDraggableImage.propTypes = propTypes;*/

// Export the wrapped component:
export default DragSource(
  "GRID_CELL_IMAGE",
  GridMaskingDraggableImageSource,
  collect
)(GridMaskingDraggableImage);
