import Subscriptions from "lib/subscriptions";

class UserSubscriptionPlan {
  constructor({ subscription, plan }) {
    this.subscription = subscription;
    this.plan = plan;
    this.subscriptionFeatures = Subscriptions.get(plan.code);
  }

  isPlanId(planId) {
    return this.plan.id === planId;
  }

  hasEndDate() {
    return this.isCancelling || this.isTrialling;
  }

  get isCancelling() {
    return this.subscription.status === "CANCELLING";
  }

  get isTrialling() {
    return this.subscription.status === "TRIALLING";
  }

  get planCode() {
    return this.plan.code;
  }

  get isBasicPlan() {
    return this.plan.code === "BASIC";
  }

  get isRemoveBackgroundAvailable() {
    return this.plan.code !== "BASIC" && !this.isTrialling;
  }

  isUpgrade(candidatePlan) {
    if (this.hasEndDate()) {
      return true;
    }

    return Subscriptions.IS_PLAN_UPGRADE(this.plan, candidatePlan);
  }

  canAccessPlanCode(planCode) {
    return this.subscriptionFeatures.canAccess(planCode);
  }
}

export default UserSubscriptionPlan;
