import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { Avatar } from "../";
import TimesCircleIcon from "views/components/icons/TimesCircleIcon";

const Tag = ({
  id,
  className,
  removeIconClassName,
  label,
  onRemove,
  iconSize = "12px",
  displayAvatar = true,
  canAddRemoveTags,
  removeIcon,
  removeIconColor,
  src
}) => {
  const Icon = removeIcon || TimesCircleIcon;

  return (
    <div
      className={`${style.wrapper} ${
        displayAvatar ? style.withAvatar : null
      } ${className}`}
    >
      {label && displayAvatar && (
        <Avatar
          name={label}
          size={"20px"}
          noBorder={true}
          className={style.avatar}
          charsClassName={style.avatarLabel}
          singleCharacterNameInitial={true}
          src={src}
        />
      )}
      {label && <div className={style.label}>{label}</div>}
      {canAddRemoveTags && (
        <div
          className={`${style.remove} ${removeIconClassName}`}
          onClick={event => onRemove(id, event)}
        >
          <Icon color={removeIconColor} width={iconSize} height={iconSize} />
        </div>
      )}
    </div>
  );
};
Tag.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onRemove: PropTypes.func,
  displayAvatar: PropTypes.bool,
  iconSize: PropTypes.string
};

export default Tag;
