import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const BIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 12 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-4-2h20v20H-4z" />
      <g fill={color || easilGrayXDark}>
        <path d="M2 1h1v14H2a1 1 0 1 1-1-1V2a1 1 0 1 1 1-1z" />
        <path d="M1 0h5.004L7 2H1zM1 14h7l-1.003 2H1zM1 6h6v2H1z" />
        <path
          fillRule="nonzero"
          d="M7 7.963l-.382.02c-.124.011-.25.017-.378.017l-.05-1.999.264-.013C7.37 5.882 8 5.098 8 4a2 2 0 0 0-2-2V0a4 4 0 0 1 4 4c0 .959-.288 1.83-.79 2.514A5 5 0 0 1 7 16v-2a3 3 0 0 0 0-6v-.037z"
        />
      </g>
    </g>
  </svg>
);
BIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default BIcon;
