import React from "react";
import DesignRemix from "./DesignRemix";
import bg from "./bg.png";
import style from "./style.module.css";
import { OneCardLayout } from "views/layouts";
import FullPageLoading from "views/pages/fullLoadingPage";

// Wrapper for the DesignRemix handle all the data manipulation and functions keeping the Component free to just render things
export class DesignRemixWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.handleProcessDesignRemix = this.handleProcessDesignRemix.bind(this);
    this.handleTeamCountResponse = this.handleTeamCountResponse.bind(this);
    this.handleSwitchToPicker = this.handleSwitchToPicker.bind(this);
    this.onTeamMenuNavigationDown = this.onTeamMenuNavigationDown.bind(this);
    this.onTeamMenuNavigationUp = this.onTeamMenuNavigationUp.bind(this);
    this.onFetchMoreTeams = this.onFetchMoreTeams.bind(this);

    this.state = {
      teamsLoading: true,
      pageReady: false,
      displayTeamDropdown: false,
      teams: [],
      nestedTeams: {},
      teamMenuLevel: 1,
      teamMenuTeamId: null
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;

    /* in the case that a user only has a single team just apply to that team */
    this.props.fetchTeamsCount({ userId: currentUser.id });
  }

  componentDidUpdate() {
    const { teamCount } = this.props;
    const { pageReady } = this.state;

    if (teamCount && !pageReady) {
      this.handleTeamCountResponse(teamCount);
    }
  }

  handleTeamCountResponse(teamCount) {
    const { currentTeam } = this.props;
    if (teamCount === 1) {
      /* 1 team, remix to this */
      this.handleProcessDesignRemix(currentTeam.id);
    } else {
      /* more than 1 team, show picker */
      this.handleSwitchToPicker();
    }
  }

  handleSwitchToPicker() {
    this.setState({ pageReady: true });

    /* get all the teams for the current user */
    this.props.fetchTeamsIfNeeded({
      onSuccess: () => {
        this.setState({ teamsLoading: false });
      }
    });
  }

  handleProcessDesignRemix(teamId) {
    const { designId, remixToken, processDesignRemix } = this.props;

    // pass both designId and remixToken
    processDesignRemix({ designId, remixToken, teamId });
  }

  // On down nav
  onTeamMenuNavigationDown(team) {
    this.props.navigateDown(team);
  }

  // On up nav
  onTeamMenuNavigationUp(team) {
    this.props.navigateUp(team);
  }

  onFetchMoreTeams() {
    const {
      selectedTeam,
      fetchNextPageOfSubTeamsIfNeeded,
      fetchTeamsIfNeeded
    } = this.props;

    // when we have a selected team we want to fetch next subteam page
    if (selectedTeam) {
      fetchNextPageOfSubTeamsIfNeeded({ parentId: selectedTeam.id });
      return;
    }
    fetchTeamsIfNeeded();
  }

  render() {
    const {
      designId,
      teams,
      currentUser,
      selectedTeam,
      targetName
    } = this.props;

    const { teamsLoading, pageReady } = this.state;

    if (!pageReady) {
      /* the page is not ready, show loading spinner */
      return <FullPageLoading />;
    }

    return (
      <OneCardLayout background={bg} className={style.card}>
        <DesignRemix
          designId={designId}
          handleProcessDesignRemix={this.handleProcessDesignRemix}
          teams={teams}
          currentUser={currentUser}
          teamsLoading={teamsLoading}
          selectedTeam={selectedTeam}
          selectTeam={this.onTeamMenuNavigationDown}
          navigateBack={this.onTeamMenuNavigationUp}
          onFetchMoreTeams={this.onFetchMoreTeams}
          targetName={targetName}
        />
      </OneCardLayout>
    );
  }
}

export default DesignRemixWrapper;
