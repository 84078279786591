import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const SignupPage = lazy(() => import("./SignupPage"));

export const SignupPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <SignupPage {...props} />
  </Suspense>
);
SignupPageLazy.displayName = "SignupPage";

export default SignupPageLazy;
