export const TEAM_BILLING_REQUEST = "API/ENTITIES/TEAM_BILLING/REQUEST";
export const TEAM_BILLING_REQUEST_FAILURE =
  "APP/ENTITIES/TEAM_BILLING/REQUEST_FAILURE";
export const TEAM_BILLING_REQUEST_SUCCESS =
  "APP/ENTITIES/TEAM_BILLING/REQUEST_SUCCESS";

export const TEAM_BILLING_UPDATE_REQUEST =
  "API/ENTITIES/TEAM_BILLING/UPDATE_REQUEST";
export const TEAM_BILLING_UPDATE_REQUEST_FAILURE =
  "APP/ENTITIES/TEAM_BILLING/UPDATE_REQUEST_FAILURE";
export const TEAM_BILLING_UPDATE_REQUEST_SUCCESS =
  "APP/ENTITIES/TEAM_BILLING/UPDATE_REQUEST_SUCCESS";
