import React from "react";

export default function ImageAltIcon({ color = "#2C2E2F", ...props }) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={color}
          fillRule="nonzero"
          d="M18 2c2.21 0 4 1.79 4 4v12c0 2.21-1.79 4-4 4H6c-2.21 0-4-1.79-4-4V6c0-2.21 1.79-4 4-4h12zm0 2H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2zm-2.944 5.437a.9.9 0 011.425.027l2.242 3.024a.9.9 0 11-1.446 1.072l-1.546-2.086-3.274 4.089a.9.9 0 01-1.328.084l-1.37-1.324-2.369 2.92a.9.9 0 11-1.398-1.134l2.986-3.682a.9.9 0 011.325-.08l1.366 1.32zM8.5 6.5a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 018.5 6.5z"
        />
      </g>
    </svg>
  );
}
