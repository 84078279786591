import { clamp } from "lib";
import { DOM_NODE_TYPES } from "lib/textUtils";

/**
 * @desc gets the current window selection and returns the startOffset and endOffset of the current selection
 * the ancestor property can be passed to also get the index of which child the range is in from the target ancestor (if it is a child of the ancestor)
 */
export const getCurrentRangeValues = ancestor => {
  // get current selection range
  const selection = window.getSelection();

  let range = {
    startOffset: 0,
    endOffset: 0
  };
  if (selection && selection.rangeCount > 0) {
    range = selection.getRangeAt(0);
  }

  let rangeTargetIndex = 0;

  if (ancestor) {
    const ancestorChildNodes = Array.from(ancestor.childNodes);
    rangeTargetIndex = Math.max(
      ancestorChildNodes.indexOf(range.endContainer),
      0
    );
  }

  const rangeStart = range.startOffset;
  const rangeEnd = range.endOffset;

  return { rangeStart, rangeEnd, rangeTargetIndex };
};

export const setRangeForTarget = ({ target, startOffset, endOffset }) => {
  let _target = target;

  if (target.nodeName === DOM_NODE_TYPES.span) {
    // for spans we need to target their text component
    _target = target.firstChild;
  }

  // clamp the offsets inside the target
  const _startOffset = clamp(startOffset, 0, _target.textContent.length);

  const _endOffset = clamp(endOffset, 0, _target.textContent.length);

  // build new range
  const newRange = new Range();

  newRange.setStart(_target, _startOffset);
  newRange.setEnd(_target, _endOffset);

  // apply the new range
  const selection = document.getSelection();
  selection.removeAllRanges();
  selection.addRange(newRange);
};

export const clearSelection = ({ domElement }) => {
  if (window.getSelection) {
    const selection = window.getSelection();
    if (domElement && !domElement.contains(selection.baseNode)) {
      // when a domElement is passed in we want to make sure the target is inside it
      return;
    } else if (selection.empty) {
      // Chrome
      selection.empty();
    } else if (selection.removeAllRanges) {
      // Firefox
      selection.removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }
};

export const moveCursorToEndOfContent = ({ target }) => {
  let _target = target;

  if (target.nodeName === DOM_NODE_TYPES.span) {
    // for spans we need to target their text component
    _target = target.firstChild;
  }

  const length = _target.textContent.length;

  setRangeForTarget({
    target: _target,
    startOffset: length,
    endOffset: length
  });
};

export function selectHtmlNodeText(node) {
  if (window.getSelection && document.createRange) {
    const range = document.createRange();
    range.selectNodeContents(node);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  }
}
