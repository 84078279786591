export const BRAND_KIT_UPDATE_SELECTED_COLOR =
  "API/UI/BRAND_KIT/UPDATE_SELECTED_COLOR";

export const BRAND_KIT_IMAGES_CLEAR = "API/UI/BRAND_KIT/IMAGES/CLEAR";
export const BRAND_KIT_LOGOS_CLEAR = "API/UI/BRAND_KIT/LOGOS/CLEAR";

export const BRAND_KIT_REQUEST_COLOR_GROUP =
  "API/UI/BRAND_KIT/REQUEST_COLOR_GROUP";
export const BRAND_KIT_REQUEST_COLOR_GROUP_SUCCESS =
  "API/UI/BRAND_KIT/REQUEST_COLOR_GROUP_SUCCESS";
export const BRAND_KIT_REQUEST_COLOR_GROUP_FAILURE =
  "API/UI/BRAND_KIT/REQUEST_COLOR_GROUP_FAILURE";

export const BRAND_KIT_CREATE_COLOR_GROUP_REQUEST =
  "API/UI/BRAND_KIT/CREATE_COLOR_GROUP_REQUEST";
export const BRAND_KIT_CREATE_COLOR_GROUP_REQUEST_SUCCESS =
  "API/UI/BRAND_KIT/CREATE_COLOR_GROUP_REQUEST_SUCCESS";
export const BRAND_KIT_CREATE_COLOR_GROUP_REQUEST_FAILURE =
  "API/UI/BRAND_KIT/CREATE_COLOR_GROUP_REQUEST_FAILURE";

export const BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST =
  "API/UI/BRAND_KIT/REMOVE_COLOR_GROUP_REQUEST";
export const BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST_SUCCESS =
  "API/UI/BRAND_KIT/REMOVE_COLOR_GROUP_REQUEST_SUCCESS";
export const BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST_FAILURE =
  "API/UI/BRAND_KIT/REMOVE_COLOR_GROUP_REQUEST_FAILURE";

export const BRAND_KIT_UPDATE_COLOR_GROUP_REQUEST =
  "API/UI/BRAND_KIT/UPDATE_COLOR_GROUP_REQUEST";
export const BRAND_KIT_UPDATE_COLOR_GROUP_REQUEST_SUCCESS =
  "API/UI/BRAND_KIT/UPDATE_COLOR_GROUP_REQUEST_SUCCESS";
export const BRAND_KIT_UPDATE_COLOR_GROUP_REQUEST_FAILURE =
  "API/UI/BRAND_KIT/UPDATE_COLOR_GROUP_REQUEST_FAILURE";

export const SEARCH_IMAGES_REQUEST = "API/UI/BRAND_KIT/SEARCH_IMAGES_REQUEST";
export const SEARCH_IMAGES_SUCCESS = "API/UI/BRAND_KIT/SEARCH_IMAGES_SUCCESS";
export const SEARCH_IMAGES_FAILURE = "API/UI/BRAND_KIT/SEARCH_IMAGES_FAILURE";

export const CREATE_IMAGE_REQUEST = "API/UI/BRAND_KIT/CREATE_IMAGE_REQUEST";
export const CREATE_IMAGE_SUCCESS = "API/UI/BRAND_KIT/CREATE_IMAGE_SUCCESS";
export const CREATE_IMAGE_FAILURE = "API/UI/BRAND_KIT/CREATE_IMAGE_FAILURE";

export const UPDATE_IMAGE_REQUEST = "API/UI/BRAND_KIT/UPDATE_IMAGE_REQUEST";
export const UPDATE_IMAGE_SUCCESS = "API/UI/BRAND_KIT/UPDATE_IMAGE_SUCCESS";
export const UPDATE_IMAGE_FAILURE = "API/UI/BRAND_KIT/UPDATE_IMAGE_FAILURE";

export const REMOVE_IMAGE_REQUEST = "API/UI/BRAND_KIT/REMOVE_IMAGE_REQUEST";
export const REMOVE_IMAGE_SUCCESS = "API/UI/BRAND_KIT/REMOVE_IMAGE_SUCCESS";
export const REMOVE_IMAGE_FAILURE = "API/UI/BRAND_KIT/REMOVE_IMAGE_FAILURE";

export const SEARCH_LOGOS_REQUEST = "API/UI/BRAND_KIT/SEARCH_LOGOS_REQUEST";
export const SEARCH_LOGOS_SUCCESS = "API/UI/BRAND_KIT/SEARCH_LOGOS_SUCCESS";
export const SEARCH_LOGOS_FAILURE = "API/UI/BRAND_KIT/SEARCH_LOGOS_FAILURE";

export const CREATE_LOGO_REQUEST = "API/UI/BRAND_KIT/CREATE_LOGO_REQUEST";
export const CREATE_LOGO_SUCCESS = "API/UI/BRAND_KIT/CREATE_LOGO_SUCCESS";
export const CREATE_LOGO_FAILURE = "API/UI/BRAND_KIT/CREATE_LOGO_FAILURE";

export const UPDATE_LOGO_REQUEST = "API/UI/BRAND_KIT/UPDATE_LOGO_REQUEST";
export const UPDATE_LOGO_SUCCESS = "API/UI/BRAND_KIT/UPDATE_LOGO_SUCCESS";
export const UPDATE_LOGO_FAILURE = "API/UI/BRAND_KIT/UPDATE_LOGO_FAILURE";

export const REMOVE_LOGO_REQUEST = "API/UI/BRAND_KIT/REMOVE_LOGO_REQUEST";
export const REMOVE_LOGO_SUCCESS = "API/UI/BRAND_KIT/REMOVE_LOGO_SUCCESS";
export const REMOVE_LOGO_FAILURE = "API/UI/BRAND_KIT/REMOVE_LOGO_FAILURE";

export const BRAND_KIT_REQUEST_FONTS = "API/UI/BRAND_KIT/REQUEST_FONTS";
export const BRAND_KIT_REQUEST_FONTS_SUCCESS =
  "API/UI/BRAND_KIT/REQUEST_FONTS_SUCCESS";
export const BRAND_KIT_REQUEST_FONTS_FAILURE =
  "API/UI/BRAND_KIT/REQUEST_FONTS_FAILURE";

export const BRAND_KIT_CREATE_FONT_REQUEST =
  "API/UI/BRAND_KIT/CREATE_FONT_REQUEST";
export const BRAND_KIT_CREATE_FONT_REQUEST_SUCCESS =
  "API/UI/BRAND_KIT/CREATE_FONT_REQUEST_SUCCESS";
export const BRAND_KIT_CREATE_FONT_REQUEST_FAILURE =
  "API/UI/BRAND_KIT/CREATE_FONT_REQUEST_FAILURE";

export const BRAND_KIT_UPLOAD_FONT_REQUEST =
  "API/UI/BRAND_KIT/UPLOAD_FONT_REQUEST";
export const BRAND_KIT_UPLOAD_FONT_REQUEST_SUCCESS =
  "API/UI/BRAND_KIT/UPLOAD_FONT_REQUEST_SUCCESS";
export const BRAND_KIT_UPLOAD_FONT_REQUEST_FAILURE =
  "API/UI/BRAND_KIT/UPLOAD_FONT_REQUEST_FAILURE";

export const BRAND_KIT_UPDATE_FONT_REQUEST =
  "API/UI/BRAND_KIT/UPDATE_FONT_REQUEST";
export const BRAND_KIT_UPDATE_FONT_REQUEST_SUCCESS =
  "API/UI/BRAND_KIT/UPDATE_FONT_REQUEST_SUCCESS";
export const BRAND_KIT_UPDATE_FONT_REQUEST_FAILURE =
  "API/UI/BRAND_KIT/UPDATE_FONT_REQUEST_FAILURE";

export const BRAND_KIT_PARTIAL_UPDATE_FONT_REQUEST =
  "API/UI/BRAND_KIT/PARTIAL_UPDATE_FONT_REQUEST";
export const BRAND_KIT_PARTIAL_UPDATE_FONT_REQUEST_SUCCESS =
  "API/UI/BRAND_KIT/PARTIAL_UPDATE_FONT_REQUEST_SUCCESS";
export const BRAND_KIT_PARTIAL_UPDATE_FONT_REQUEST_FAILURE =
  "API/UI/BRAND_KIT/PARTIAL_UPDATE_FONT_REQUEST_FAILURE";

export const BRAND_KIT_REMOVE_FONT_REQUEST =
  "API/UI/BRAND_KIT/REMOVE_FONT_REQUEST";
export const BRAND_KIT_REMOVE_FONT_REQUEST_SUCCESS =
  "API/UI/BRAND_KIT/REMOVE_FONT_REQUEST_SUCCESS";
export const BRAND_KIT_REMOVE_FONT_REQUEST_FAILURE =
  "API/UI/BRAND_KIT/REMOVE_FONT_REQUEST_FAILURE";

export const BRAND_KIT_FONT_FACE_LOAD_SUCCESS =
  "API/UI/BRAND_KIT/FONT_FACE_LOAD_SUCCESS";

export const CREATE_TEAM_LOGO_UPLOAD_URL_REQUEST =
  "API/TEAM/CREATE_LOGO_URL_REQUEST";
export const CREATE_TEAM_LOGO_UPLOAD_URL_REQUEST_SUCCESS =
  "API/TEAM/CREATE_LOGO_URL_SUCCESS";
export const CREATE_TEAM_LOGO_UPLOAD_URL_REQUEST_FAILURE =
  "API/TEAM/CREATE_LOGO_URL_FAILURE";

export const CREATE_TEAM_IMAGE_UPLOAD_URL_REQUEST =
  "API/TEAM/CREATE_IMAGE_URL_REQUEST";
export const CREATE_TEAM_IMAGE_UPLOAD_URL_REQUEST_SUCCESS =
  "API/TEAM/CREATE_IMAGE_URL_SUCCESS";
export const CREATE_TEAM_IMAGE_UPLOAD_URL_REQUEST_FAILURE =
  "API/TEAM/CREATE_IMAGE_URL_FAILURE";
