import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const LayoutsIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path fill={color || easilGrayXDark} d="M9 0h2v20H9z" />
      <path fill={color || easilGrayXDark} d="M9 9h11v2H9z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
      />
    </g>
  </svg>
);
LayoutsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default LayoutsIcon;
