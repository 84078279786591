import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ExclaimationMarkCircledIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  ...props
}) => {
  return (
    <svg
      {...props}
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 14}
      height={height || size || 14}
      aria-labelledby="title"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
    >
      <title>{title}</title>
      <path
        fill={color || easilGrayXDark}
        fillRule="evenodd"
        d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zm0-6.25c.414 0 .75-.319.75-.712V3.713C7.75 3.319 7.414 3 7 3s-.75.319-.75.713v3.325c0 .393.336.712.75.712zM7 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </svg>
  );
};
ExclaimationMarkCircledIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ExclaimationMarkCircledIcon;
