import React from "react";
import EasilIcon from "views/components/icons/EasilIcon";
import style from "./style.module.css";
import { TeamSignupDetails as TeamSignupDetailsContainer } from "views/containers";
const SignupTeamDetailsPage = () => (
  <div className={style.wrap}>
    <section className={style.header}>
      <EasilIcon />
    </section>
    <TeamSignupDetailsContainer />
  </div>
);

SignupTeamDetailsPage.propTypes = {};

export default SignupTeamDetailsPage;
