import { createSelector } from "reselect";
import { flatten, isEmpty } from "lib";
import { getParameterByName } from "lib/queryStringUtils";

const folderIdsFromObjectList = list => {
  let pagesReady = true;

  const pages = Object.keys(list)
    .map(id => list[id])
    .map(page => page);

  pages.forEach(page => {
    if (page.isFetching === true) pagesReady = false;
  });

  if (!pagesReady) return [];

  return flatten(pages.map(page => page.ids.map(id => id)));
};

const folderListByPages = (folders, objectList) => {
  const folderIds = folderIdsFromObjectList(objectList);

  return Object.keys(folders)
    .map(id => folders[id])
    .filter(folder => folderIds.includes(folder.id));
};

const personalFoldersPagesSelector = state => state.api.folders.personal.pages;
export const foldersEntitiesSelector = state => state.entities.folders;

const processWorkspaceFoldersSelector = (folders, workspaceFoldersPages) => {
  if (isEmpty(workspaceFoldersPages)) return [];

  return folderListByPages(folders, workspaceFoldersPages);
};

export const workspaceFoldersSelector = createSelector(
  [foldersEntitiesSelector, personalFoldersPagesSelector],
  processWorkspaceFoldersSelector
);

const teamFoldersPagesSelector = state => state.api.folders.team.pages;

export const isTeamFoldersFetched = state => {
  const pages = teamFoldersPagesSelector(state);

  if (!Object.keys(pages).length) return false;

  // check if at least the first page is fetched
  return !pages[1].isFetching;
};

const processCatalogueFoldersSelector = (folders, catalogueFoldersPages) => {
  if (isEmpty(catalogueFoldersPages)) return [];

  return folderListByPages(folders, catalogueFoldersPages);
};

export const catalogueFoldersSelector = createSelector(
  [foldersEntitiesSelector, teamFoldersPagesSelector],
  processCatalogueFoldersSelector
);

const locationSearchSelector = state => state.router.location.search;

const processFolderById = (folders, locationSearch) => {
  const folderId = getParameterByName("folderId", locationSearch);

  if (!folders || !folderId) {
    return null;
  }

  return folders[folderId];
};

export const folderById = createSelector(
  [foldersEntitiesSelector, locationSearchSelector],
  processFolderById
);

export const getFolderById = (state, folderId) => {
  if (!folderId) return null;
  const folders = foldersEntitiesSelector(state);
  return folders[folderId];
};

export const folderAllocationsSelector = state => state.api.folderAllocations;
export const teamEntitiesSelector = state => state.entities.teams;

export const folderTeamAllocationsSelector = (state, folderId) => {
  if (!folderId) return;

  const teamEntities = teamEntitiesSelector(state);
  const allocatedTeamsFolderState = folderAllocationsSelector(state)[folderId];

  if (!allocatedTeamsFolderState) return;

  // return all pages and ids
  const allFolderAllocationIds = Object.values(
    allocatedTeamsFolderState.pages
  ).reduce(
    (previousValues, currentPage) => previousValues.concat(currentPage.ids),
    []
  );

  return allFolderAllocationIds.map(id => teamEntities[id]);
};

export const isFetchingFolderAllocationsSelector = state => {
  const allocatedTeamsFolderState = folderAllocationsSelector(state);

  return allocatedTeamsFolderState && allocatedTeamsFolderState.isFetching;
};
