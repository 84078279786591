import Element from "state/ui/editor/elements/Element";
import { tableHeightsUpdater } from "views/components/Editor/editorOps/EditorTableOps/tableSimulator";
import { uuid } from "lib/uuid";
import { cloneDeep } from "lib/lodash";
import {
  generateTable2MinHeight,
  table2CellDimensionUpdater
} from "views/components/Editor/sidebar/tabs/shapes/Tables2Tab/helper";

class Table2Element extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "textEdit",
    "sizeAndPosition",
    "color",
    "fontFamily",
    "fontSize",
    "textAlign",
    "textDecoration",
    "letterSpacing",
    "lineHeight",
    "cellStyle",
    "editRowLayout",
    "editColumnLayout",
    "table2TextBoundary"
  ];

  get canChangeHeight() {
    return true;
  }

  clone(newElementId = uuid()) {
    const clonedLayout = this.layout.map(row =>
      row.map(cell => ({
        ...cell,
        id: uuid()
      }))
    );
    const clonedCells = this.layout.reduce((newCells, row, rowIndex) => {
      row.forEach((cell, columnIndex) => {
        const originalId = cell.id;
        const newId = clonedLayout[rowIndex][columnIndex].id;
        newCells[newId] = {
          ...this.cells[originalId],
          uniqueId: newId
        };
      });
      return newCells;
    }, {});

    const cloneInstance = new this.constructor({
      ...this,
      uniqueId: newElementId,
      cells: clonedCells,
      layout: clonedLayout
    });

    return [cloneInstance];
  }

  getCellsMetadata(cellId) {
    return this.cells[cellId];
  }

  // cloned from TableElement. Needs updating
  duplicateLastRow() {
    const lastRowIndex = this.rows.length - 1;

    const lastRowClone = this.cloneRow({ rowIndex: lastRowIndex });

    const tableHeightsUpdated = tableHeightsUpdater({
      ...this,
      rows: this.rows.concat(lastRowClone)
    });

    return new this.constructor({
      ...tableHeightsUpdated
    });
  }

  updateTextFieldValue({ cellId, value, displayValue }) {
    const updatedTable2CellsClone = cloneDeep(this.cells);

    // assign new values to selected cell
    updatedTable2CellsClone[cellId] = {
      ...updatedTable2CellsClone[cellId],
      value: value,
      displayValue: displayValue
    };

    const updatedTable = table2CellDimensionUpdater({
      ...this,
      cells: updatedTable2CellsClone
    });

    return new this.constructor({
      ...this,
      cells: updatedTable.cells
    });
  }

  updateTable2CellHeight({ cellId, height }) {
    const table2CellsClone = cloneDeep(this.cells);
    table2CellsClone[cellId] = {
      ...table2CellsClone[cellId],
      height
    };

    return new this.constructor({
      ...this,
      cells: table2CellsClone
    });
  }

  // cloned from TableElement. Needs updating
  cloneRow({ rowIndex, suggestedId = uuid() }) {
    const rowClone = cloneDeep(this.rows[rowIndex]);

    rowClone.id = suggestedId;

    rowClone.cells = rowClone.cells.map(cell => {
      return {
        ...cell,
        id: uuid(),
        textFields: cell.textFields.map(textField => ({
          ...textField,
          id: uuid()
        }))
      };
    });

    return rowClone;
  }

  updateAttributes(attributes) {
    return new this.constructor({
      ...this,
      ...attributes
    });
  }

  get minHeight() {
    return generateTable2MinHeight(this);
  }

  isCellLocked(cellId) {
    return this.lockedCellIds.includes(cellId);
  }
}

export default Table2Element;
