import { Logger } from "lib";

const EditorResizeOps = {
  startResizing({ pageId }) {
    Logger.info("Editor.startResizing called");

    const {
      htmlElements: { canvasBackground }
    } = this.state;
    /* we disable the scrolling while resizing */
    canvasBackground.style.overflow = "hidden";

    const selectedItemsUpdated = this.props.selectedItems
      //we deselected the items from another page
      .filter(item => item.pageId === pageId)
      .map(selectedItem => ({
        ...selectedItem,
        preview: {
          opacity: 0
        }
      }));
    this.props.updateContextState({
      selectedItems: selectedItemsUpdated,
      context: {},
      isResizing: true
    });
    this.props.updateActionBarState({
      ...this.state.actionbar,
      buttonActive: null
    });
  },

  finishResizing({
    anchorPoint,
    offset,
    scale,
    zoom,
    handlerCoordinateName,
    differenceFromInitialOffset,
    dragItem
  }) {
    Logger.info("Editor.finishResizing called");

    const {
      htmlElements: { canvasBackground }
    } = this.state;
    const { designData, selectedItems } = this.props;

    /* because we disable the scrolling while resizing */
    canvasBackground.style.overflow = "scroll";

    const selectedItemsUpdated = selectedItems.map(selectedItem => ({
      ...selectedItem,
      preview: {}
    }));

    const designDataUpdated = designData.resizeElements({
      dragItem,
      selectedItems,
      anchorPoint,
      offset,
      scale,
      zoom,
      handlerInitialPosition: dragItem.handlerInitialPosition,
      scaleDirection: handlerCoordinateName,
      differenceFromInitialOffset
    });
    this.props.updateContextState({
      selectedItems: selectedItemsUpdated,
      context: {},
      isResizing: false
    });
    this.props.onSave(designDataUpdated, {});
  },

  onToggleImageFitToFrame(elementId, containerProps) {
    Logger.info("EditorResizeOps.onToggleImageFitToFrame called");
    const { designData } = containerProps;

    let updatedDesignData;
    updatedDesignData = designData.toggleFitToFrame({
      elementId
    });

    containerProps.updateContextState({ designData: updatedDesignData });
    containerProps.onSave(updatedDesignData, {});
  },

  onSetImageFitToFrame(elementId, isFitToFrame, containerProps) {
    Logger.info("EditorResizeOps.onSetImageFitToFrame called");
    const { designData } = containerProps;

    let updatedDesignData;
    updatedDesignData = designData.setFitToFrame(elementId, isFitToFrame);

    // Return if no update has occured
    if (
      designData.elements[elementId].fitToFrame ===
      updatedDesignData.elements[elementId].fitToFrame
    ) {
      return;
    }

    containerProps.updateContextState({ designData: updatedDesignData });
    containerProps.onSave(updatedDesignData, {});
  }
};

export default EditorResizeOps;
