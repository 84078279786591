import React, { Component } from "react";
import { connect } from "react-redux";
import { createTeamAnimationsFolder } from "state/entities/teamAnimationsFolders/teamAnimationsFoldersActions";
import ImagesNewFolderButton from "./ImagesNewFolderButton";

export class AnimationsNewFolderButtonContainer extends Component {
  render() {
    return <ImagesNewFolderButton {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    createImageFolder: args => dispatch(createTeamAnimationsFolder(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnimationsNewFolderButtonContainer);
