import * as types from "./userTeamSmartImagesTypes";
import { merge, omit } from "lib";

export const initState = {};

const userTeamSmartImagesReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USER_TEAM_SMART_IMAGES_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;
      return merge({}, state, entities.userTeamSmartImage);
    }

    case types.USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST: {
      return {
        ...state
      };
    }

    case types.USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.response.entities.userTeamSmartImage
      };
    }

    case types.USER_TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS: {
      return {
        ...state,
        [action.request.body.mediaId]:
          action.response.entities.userTeamSmartImage[
            action.request.body.mediaId
          ]
      };
    }

    case types.USER_TEAM_SMART_IMAGE_DELETE_REQUEST_SUCCESS: {
      return omit(state, action.extra.mediaId);
    }

    default:
      return state;
  }
};

export default userTeamSmartImagesReducers;
