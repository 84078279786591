import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ClockArrowClockwiseIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 22}
      height={height || size || 22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      ariaLabelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path d="M0 0h20v20H0z" />
        <path d="M0 1h20v20H0z" />
        <path
          d="M17.227 5.818A9 9 0 1 0 19 11.182"
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10 7v5l3 2m4.56-12v4.243h-4.242"
        />
      </g>
    </svg>
  );
};
ClockArrowClockwiseIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ClockArrowClockwiseIcon;
