import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import { boolXor } from "lib";
import { getHtmlNodeCenter } from "lib/htmlElements/dimensions";
import { rotatePoint } from "lib/geometry/rotation";
import style from "views/components/Editor/styleSelectionBox.module.css";
import { getPageHtmlNode } from "views/components/Editor/utils";
import { unRotateKeyPoints } from "views/components/Editor/CropLayer/photoFrameCropping/PhotoFrameCroppingPreviewCalculations";

/**
 * Implements the drag source contract.
 */
const PhotoFrameCropResizeHandlerSource = {
  beginDrag(props, monitor, component) {
    const {
      startCropResizing,
      selectedItems,
      photoFrame,
      position,
      selectedPhotoFrameId,
      vectorElement
    } = props;

    startCropResizing({ pageId: selectedItems[0].pageId });

    const componentNode = findDOMNode(component);
    const selectionBoxNode = componentNode.parentElement;

    const componentNodeCenter = getHtmlNodeCenter(componentNode);
    const selectionBoxNodeCenter = getHtmlNodeCenter(selectionBoxNode);

    const pageHtmlElement = getPageHtmlNode(selectedItems[0].pageId);

    // scale anchor is used for determining the scale change
    const scaleAnchorPoint = rotatePoint(
      componentNodeCenter.x,
      componentNodeCenter.y,
      selectionBoxNodeCenter.x,
      selectionBoxNodeCenter.y,
      180
    );

    // here we only want to flip if there is a single scaleX applied
    // when both are applied we are back at no flip
    const isFlipX = boolXor(
      photoFrame.scaleX === -1,
      vectorElement.scaleX === -1
    );
    const isFlipY = boolXor(
      photoFrame.scaleY === -1,
      vectorElement.scaleY === -1
    );

    // this anchor point will be the one to remain stationary
    const anchorPoint = { ...scaleAnchorPoint };

    if (isFlipX) anchorPoint.x = componentNodeCenter.x;
    if (isFlipY) anchorPoint.y = componentNodeCenter.y;

    return {
      handlerInitialPosition: componentNodeCenter,
      anchorPoint: anchorPoint,
      scaleAnchorPoint,
      pageHtmlElement,
      selectedPhotoFrameId,
      position,
      selectionBoxNodeCenter
    };
  },

  endDrag(props, monitor, component) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();
    const {
      anchorPoint,
      handlerInitialPosition,
      pageHtmlElement,
      selectedPhotoFrameId,
      scaleAnchorPoint,
      position,
      selectionBoxNodeCenter
    } = monitor.getItem();

    const {
      differenceFromInitialOffsetUnrotated,
      originPointUnrotated,
      anchorPointUnrotated
    } = unRotateKeyPoints(
      differenceFromInitialOffset,
      handlerInitialPosition,
      props.vectorElement,
      anchorPoint
    );

    props.finishCropResizing({
      handlerInitialPosition,
      anchorPoint,
      scaleAnchorPoint,
      differenceFromInitialOffset: differenceFromInitialOffsetUnrotated,
      pageHtmlElement,
      selectedPhotoFrameId,
      originPointUnrotated,
      anchorPointUnrotated,
      position,
      selectionBoxNodeCenter
    });
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset()
  };
}

class PhotoFrameCropResizeHandler extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;

    if (connectDragPreview) {
      // Use empty image as a drag preview so browsers don't draw it
      // and we can draw whatever we want on the custom drag layer instead.
      connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true
      });
    }
  }

  render() {
    const { connectDragSource, position } = this.props;

    return connectDragSource(
      <div className={style.dotWrapper} data-position={position}>
        <div className={style.dot} />
      </div>
    );
  }
}

const PhotoFrameCropResizeHandlerDraggable = DragSource(
  "PHOTO_FRAME_CROP_RESIZE_HANDLER",
  PhotoFrameCropResizeHandlerSource,
  collect
)(PhotoFrameCropResizeHandler);

const PhotoFrameCropResizeHandlerDefault = props => {
  return (
    <PhotoFrameCropResizeHandlerDraggable
      {...props}
      canvasBackgroundHtmlElement={props.canvasBackgroundHtmlElement}
      selectedPhotoFrameId={props.selectedPhotoInFrameId}
      startCropResizing={props.startPhotoFrameCropResizing}
      finishCropResizing={props.finishPhotoFrameCropResizing}
      selectedItems={props.selectedItems}
      getElement={props.getElement}
      zoom={props.zoom}
    />
  );
};

export default PhotoFrameCropResizeHandlerDefault;
