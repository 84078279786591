import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const VectorInEditorContext = lazy(() => import("./VectorInEditorContext"));

export const VectorInEditorContextLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <VectorInEditorContext {...props} />
  </Suspense>
);
VectorInEditorContextLazy.displayName = "VectorInEditorContext";

export default VectorInEditorContextLazy;
