import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import SubPageLoading from "views/pages/SubPageLoading";

const BrandKitContainer = lazy(() =>
  import("views/containers/BrandKitContainer")
);

export const BrandPage = props => (
  <Suspense fallback={<SubPageLoading />}>
    <BrandKitContainer {...props} />
  </Suspense>
);

export default WithHeader(BrandPage, {
  bgColor: "#F4F4F5",
  withGutters: false
});
