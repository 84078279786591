class SnapElement {
  static THRESHOLD = 5; /* px */

  static getPoints = props => {
    const instance = new SnapElement(props);

    return instance.getPoints();
  };

  static noSnapPoints = () => {
    return {
      x: {
        coordinate: null,
        elementCoordinate: null,
        elementCoordinateDiffFromPointerPosition: null
      },
      y: {
        coordinate: null,
        elementCoordinate: null,
        elementCoordinateDiffFromPointerPosition: null
      }
    };
  };

  constructor({ zoom, elementSnapPointsOffset, snapPoints, pointerPosition }) {
    this.zoom = zoom;
    this.elementSnapPointsOffset = elementSnapPointsOffset;
    this.snapPoints = snapPoints;
    this.pointerPosition = pointerPosition;
  }

  getPoints() {
    return {
      x: this.getXPoint(),
      y: this.getYPoint()
    };
  }

  getXPoint() {
    const xSnapPoints = this.snapPoints.x;

    const xPosition = {
      done: false,
      xCoordinate: null,
      elementXCoordinate: null,
      delta: Infinity
    };

    const closestPoints = {
      left: { ...xPosition },
      center: { ...xPosition },
      right: { ...xPosition }
    };

    const horizontalOffsets = {
      left: this.elementSnapPointsOffset.x.left * this.zoom,
      center: this.elementSnapPointsOffset.x.center * this.zoom,
      right: this.elementSnapPointsOffset.x.right * this.zoom
    };

    xSnapPoints.forEach(xCoordinate => {
      /* this operation mutates the closesPoints variable */
      this.processXCoordinate({
        closestPoints,
        horizontalOffsets,
        xCoordinate
      });
    });

    let smallestXDelta = Object.values(closestPoints)[0];

    Object.values(closestPoints).forEach(position => {
      if (position.delta < smallestXDelta.delta) {
        smallestXDelta = position;
      }
    });

    let elementXCoordinate = null;
    let snappingXCoordinate = null;
    let elementCoordinateDiffFromPointerPosition = null;

    if (smallestXDelta.delta < SnapElement.THRESHOLD) {
      snappingXCoordinate = smallestXDelta.xCoordinate;
      elementXCoordinate = smallestXDelta.elementXCoordinate;
      elementCoordinateDiffFromPointerPosition =
        smallestXDelta.elementXCoordinate - this.pointerPosition.x;
    }

    return {
      coordinate: snappingXCoordinate,
      elementCoordinate: elementXCoordinate,
      elementCoordinateDiffFromPointerPosition
    };
  }

  processXCoordinate({ closestPoints, horizontalOffsets, xCoordinate }) {
    const delta = offset =>
      Math.abs(this.pointerPosition.x - offset - xCoordinate);

    const deltaLeft = delta(horizontalOffsets.left);

    if (!closestPoints.left.done) {
      if (deltaLeft <= closestPoints.left.delta) {
        closestPoints.left.xCoordinate = xCoordinate;
        closestPoints.left.elementXCoordinate =
          xCoordinate + horizontalOffsets.left;
        closestPoints.left.delta = deltaLeft;
      } else {
        closestPoints.left.done = true;
      }
    }

    const deltaCenter = delta(horizontalOffsets.center);

    if (!closestPoints.center.done) {
      if (deltaCenter <= closestPoints.center.delta) {
        closestPoints.center.xCoordinate = xCoordinate;
        closestPoints.center.elementXCoordinate =
          xCoordinate + horizontalOffsets.center;
        closestPoints.center.delta = deltaCenter;
      } else {
        closestPoints.center.done = true;
      }
    }

    const deltaRight = delta(horizontalOffsets.right);

    if (!closestPoints.right.done) {
      if (deltaRight <= closestPoints.right.delta) {
        closestPoints.right.xCoordinate = xCoordinate;
        closestPoints.right.elementXCoordinate =
          xCoordinate + horizontalOffsets.right;
        closestPoints.right.delta = deltaRight;
      } else {
        closestPoints.right.done = true;
      }
    }
  }

  getYPoint() {
    const ySnapPoints = this.snapPoints.y;

    const yPosition = {
      done: false,
      yCoordinate: null,
      elementYCoordinate: null,
      delta: Infinity
    };

    const closestPoints = {
      top: { ...yPosition },
      center: { ...yPosition },
      bottom: { ...yPosition }
    };

    const verticalOffsets = {
      top: this.elementSnapPointsOffset.y.top * this.zoom,
      center: this.elementSnapPointsOffset.y.center * this.zoom,
      bottom: this.elementSnapPointsOffset.y.bottom * this.zoom
    };

    ySnapPoints.forEach(yCoordinate => {
      /* this operation mutates the closesPoints variable */
      this.processYCoordinate({ closestPoints, verticalOffsets, yCoordinate });
    });

    let smallestYDelta = Object.values(closestPoints)[0];

    Object.values(closestPoints).forEach(position => {
      if (position.delta < smallestYDelta.delta) {
        smallestYDelta = position;
      }
    });

    let elementYCoordinate = null;
    let snappingYCoordinate = null;
    let elementCoordinateDiffFromPointerPosition = null;

    if (smallestYDelta.delta < SnapElement.THRESHOLD) {
      snappingYCoordinate = smallestYDelta.yCoordinate;
      elementYCoordinate = smallestYDelta.elementYCoordinate;
      elementCoordinateDiffFromPointerPosition =
        smallestYDelta.elementYCoordinate - this.pointerPosition.y;
    }

    return {
      coordinate: snappingYCoordinate,
      elementCoordinate: elementYCoordinate,
      elementCoordinateDiffFromPointerPosition
    };
  }

  processYCoordinate({ closestPoints, verticalOffsets, yCoordinate }) {
    const deltaTop = Math.abs(
      this.pointerPosition.y - verticalOffsets.top - yCoordinate
    );

    if (!closestPoints.top.done) {
      if (deltaTop <= closestPoints.top.delta) {
        closestPoints.top.yCoordinate = yCoordinate;
        closestPoints.top.elementYCoordinate =
          yCoordinate + verticalOffsets.top;
        closestPoints.top.delta = deltaTop;
      } else {
        closestPoints.top.done = true;
      }
    }

    const deltaCenter = Math.abs(
      this.pointerPosition.y - verticalOffsets.center - yCoordinate
    );

    if (!closestPoints.center.done) {
      if (deltaCenter <= closestPoints.center.delta) {
        closestPoints.center.yCoordinate = yCoordinate;
        closestPoints.center.elementYCoordinate =
          yCoordinate + verticalOffsets.center;
        closestPoints.center.delta = deltaCenter;
      } else {
        closestPoints.center.done = true;
      }
    }

    const deltaBottom = Math.abs(
      this.pointerPosition.y - verticalOffsets.bottom - yCoordinate
    );

    if (!closestPoints.bottom.done) {
      if (deltaBottom <= closestPoints.bottom.delta) {
        closestPoints.bottom.yCoordinate = yCoordinate;
        closestPoints.bottom.elementYCoordinate =
          yCoordinate + verticalOffsets.bottom;
        closestPoints.bottom.delta = deltaBottom;
      } else {
        closestPoints.bottom.done = true;
      }
    }
  }
}

export default SnapElement;
