import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { isNil, noop } from "lib";

const Textarea = ({
  className,
  label,
  error,
  showErrorMsg,
  clearInput,
  crossThinIconColor = "white",
  inputClassName,
  isEmptySearch,
  labelClassName,
  onBlur = noop,
  onFocus,
  onInputClick = noop,
  onKeyDown,
  onKeyPress,
  ref,
  value,
  ...props
}) => (
  <React.Fragment>
    {label && <div className={style.label}>{label}</div>}
    <div
      className={`${style.div} ${className}`}
      data-error={!isNil(error)}
      data-disabled={props.disabled}
      data-testid="TextArea"
    >
      <textarea
        value={value}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onInputClick}
        {...props}
      />
      {showErrorMsg && error && <span className={style.error}>{error}</span>}
    </div>
  </React.Fragment>
);
Textarea.propTypes = {
  className: PropTypes.string
};

export default Textarea;
