import { rotatePoint } from "lib/geometry/rotation";
import { calculateScalingFactor } from "lib/scalingTools/scalingTools";

const calculateScaledNewDimensions = ({
  imageElement,
  differenceFromInitialOffset,
  zoom,
  dragItem: { anchorPoint, handlerInitialPosition },
  pageOffset
}) => {
  const { newMaskTop, newMaskLeft, scale } = calculateNewDimensions({
    anchorPoint,
    imageElement,
    pageOffset,
    zoom,
    differenceFromInitialOffset,
    handlerInitialPosition
  });

  const { maskTop, maskLeft, maskRight, maskBottom } = ensureImageInsideBox({
    imageElement,
    newMaskTop,
    newMaskLeft,
    scale
  });

  return {
    ...imageElement,
    scale: scale,
    mask: {
      top: maskTop,
      left: maskLeft,
      right: maskRight,
      bottom: maskBottom
    }
  };
};

const ensureImageInsideBox = ({
  imageElement,
  newMaskTop,
  newMaskLeft,
  scale
}) => {
  if (newMaskTop < 0) {
    newMaskTop = 0;
  }

  const newMaskBottom = (imageElement.srcHeight - newMaskTop) * scale;

  if (newMaskBottom < imageElement.height) {
    newMaskTop -= (imageElement.height - newMaskBottom) / scale;
  }

  if (newMaskLeft < 0) {
    newMaskLeft = 0;
  }

  const newMaskRight = (imageElement.srcWidth - newMaskLeft) * scale;

  if (newMaskRight < imageElement.width) {
    newMaskLeft -= (imageElement.width - newMaskRight) / scale;
  }

  const maskBottomOffet = (imageElement.height - newMaskBottom) * scale;
  const maskRightOffet = (imageElement.width - newMaskRight) * scale;

  return {
    maskTop: newMaskTop,
    maskLeft: newMaskLeft,
    maskBottom: maskBottomOffet,
    maskRight: maskRightOffet
  };
};

const calculateNewDimensions = ({
  anchorPoint,
  imageElement,
  pageOffset,
  zoom,
  differenceFromInitialOffset,
  handlerInitialPosition
}) => {
  const imageLeft = imageElement.left * zoom;
  const imageTop = imageElement.top * zoom;
  const imageHeight = imageElement.height * zoom;
  const imageWidth = imageElement.width * zoom;

  const imageCenterX = imageLeft + imageWidth / 2;
  const imageCenterY = imageTop + imageHeight / 2;

  const maskLeft = -imageElement.mask.left * zoom;
  const maskTop = -imageElement.mask.top * zoom;

  const imageCoordinatesRotated = rotatePoint(
    pageOffset.x + imageLeft + maskLeft * imageElement.scale,
    pageOffset.y + imageTop + maskTop * imageElement.scale,
    pageOffset.x + imageCenterX,
    pageOffset.y + imageCenterY,
    imageElement.angle
  );

  const movementScale = calculateScale({
    anchorPoint,
    differenceFromInitialOffset,
    handlerInitialPosition,
    imageElement
  });

  const newImageLeft =
    anchorPoint.x + (imageCoordinatesRotated.x - anchorPoint.x) * movementScale;

  const newImageTop =
    anchorPoint.y + (imageCoordinatesRotated.y - anchorPoint.y) * movementScale;

  const newImageCoordinatesNotRotated = rotatePoint(
    newImageLeft,
    newImageTop,
    pageOffset.x + imageCenterX,
    pageOffset.y + imageCenterY,
    -imageElement.angle
  );

  const newImageLeftNotOffseted =
    (newImageCoordinatesNotRotated.x - pageOffset.x - imageLeft) / zoom;
  const newImageTopNotOffseted =
    (newImageCoordinatesNotRotated.y - pageOffset.y - imageTop) / zoom;

  const newElementScale = movementScale * imageElement.scale;

  const newMaskTop = -newImageTopNotOffseted / newElementScale;
  const newMaskLeft = -newImageLeftNotOffseted / newElementScale;

  return {
    newMaskTop,
    newMaskLeft,
    scale: newElementScale
  };
};

const calculateScale = ({
  anchorPoint,
  differenceFromInitialOffset,
  handlerInitialPosition,
  imageElement
}) => {
  const scaleFactorFromMouseMovement = calculateScalingFactor({
    anchorPoint,
    handlerInitialPosition,
    differenceFromInitialPosition: differenceFromInitialOffset
  });

  const scale = Math.max(
    imageElement.width / (imageElement.scale * imageElement.srcWidth),
    imageElement.height / (imageElement.scale * imageElement.srcHeight),
    scaleFactorFromMouseMovement
  );

  return scale;
};

export default calculateScaledNewDimensions;
