import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FlipIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  direction,
  title
}) => {
  const directionToAngle = {
    horizontal: 90,
    vertical: 0
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction] || 0}deg)`,
    ...style
  };

  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 14 16"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M-3-2h20v20H-3z" />
        <g fill={color || easilGrayXDark} fillRule="nonzero">
          <path d="M1 16c-.935 0-1.359-1.17-.64-1.768l6-5a1 1 0 0 1 1.28 0l6 5C14.36 14.83 13.935 16 13 16H1zM1 0C.065 0-.359 1.17.36 1.768l6 5a1 1 0 0 0 1.28 0l6-5C14.36 1.17 13.935 0 13 0H1zm9.238 2L7 4.698 3.762 2h6.476z" />
        </g>
      </g>
    </svg>
  );
};

FlipIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FlipIcon;
