import { getNextSiblings } from "lib/DOMNodeUtils";
import { getBrowserClientName } from "lib/getBrowserClientName";
import { BROWSER_NAMES } from "lib/constants";

const paragraphBreakStyle = `display: block; height: calc((var(--line-height) * (var(--paragraph-spacing) / 100)) * 1em); content: "";`;
const paragraphBreakStyleFF = `display: block; margin-top: calc((var(--line-height) * (var(--paragraph-spacing) / 100)) * 1em); content: "";`;
const hiddenBreakStyle = "display: none;";

export const createStyleElement = elementId => {
  const styleId = `sheet-${elementId}`;
  if (!!document.querySelector(`style[id="${styleId}"]`)) return;
  // Create a new style element
  const style = document.createElement("style");

  style.setAttribute("id", styleId);

  document.head.appendChild(style);
};

export const applyParagraphStyling = (textboxElement, elementId) => {
  if (!textboxElement) return;
  const browserClientName = getBrowserClientName();

  const paraBreakStyle =
    browserClientName === BROWSER_NAMES.FIREFOX
      ? paragraphBreakStyleFF
      : paragraphBreakStyle;

  const styleId = `sheet-${elementId}`;

  // get the predefined style for our element
  const style = document.querySelector(`style[id="${styleId}"]`);

  const allBreakTags = textboxElement.querySelectorAll("br");

  const paragraphBreakIndexes = [];
  const hiddenBreakIndexes = [];

  // map through and find all breaks that we want to apply styling to
  Array.from(allBreakTags).forEach((breakTag, index) => {
    const nextSiblings = getNextSiblings(breakTag);
    const isEndOfInput =
      nextSiblings.length === 0 ||
      nextSiblings.every(sibling => sibling.nodeName === "BR");

    // get all next siblings to check if there is anything other than br tags before the end of the textbox
    if (isEndOfInput) {
      return;
    }

    // check if next sibling is a break
    if (breakTag.nextSibling && breakTag.nextSibling.nodeName === "BR") {
      // add index to paragraph list (+1 since selector indexes start at 1)
      paragraphBreakIndexes.push(index + 1);
    } else if (
      breakTag.previousSibling &&
      breakTag.previousSibling.nodeName === "BR"
    ) {
      // add index to hidden list (+1 since selector indexes start at 1)
      hiddenBreakIndexes.push(index + 1);
    }
  });

  // define out css selector for all the br elements that are paragraph spaces
  let cssRules = paragraphBreakIndexes.map(
    i => `div[id="${elementId}"] br:nth-of-type(${i}) { ${paraBreakStyle} }`
  );
  cssRules = cssRules.concat(
    hiddenBreakIndexes.map(
      i => `div[id="${elementId}"] br:nth-of-type(${i}) { ${hiddenBreakStyle} }`
    )
  );

  const sheet = style.sheet;

  // iterate over the old rules in reverse order to avoid index shifting
  for (let i = sheet.cssRules.length - 1; i >= 0; i--) {
    const cssRule = sheet.cssRules[i];
    if (cssRule.cssText.indexOf("br:nth-of-type(") >= 0) {
      sheet.deleteRule(i);
    }
  }

  // Insert the new rules
  cssRules.forEach(cssRule => {
    sheet.insertRule(cssRule);
  });
};
