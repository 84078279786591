import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { ANIMATION_ASSET_TYPE_MAP } from "lib/constants";
import * as AnimationSearchActionTypes from "./editorAnimationSearchTypes";
import { getParameterByName } from "lib/queryStringUtils";

export const searchUserAnimations = ({ term, searchType, page = 1 }) => (
  dispatch,
  getState
) => {
  const folderId = getParameterByName("folderId", window.location);
  let params = {
    term,
    context: "MY_ANIMATION"
  };
  if (searchType) {
    params.type = searchType;
  }
  if (!term) {
    delete params.term;
  }
  if (folderId) {
    params.folderId = folderId;
  }
  dispatch({
    [CALL_API]: {
      service: SERVICES.ASSET,
      types: [
        AnimationSearchActionTypes.EDITOR_ANIMATION_SEARCH_REQUEST,
        AnimationSearchActionTypes.EDITOR_ANIMATION_SEARCH_SUCCESS,
        AnimationSearchActionTypes.EDITOR_ANIMATION_SEARCH_FAILURE
      ],
      endpoint: "/media",
      request: {
        page: page,
        pageSize: getState().ui.editorAnimationSearch.pageSize,
        params
      },
      schema: schemas.ANIMATION_SEARCH_RESULT
    }
  });
};

export const searchStockAnimations = ({ term, searchType, page = 1 }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      service: SERVICES.ASSET,
      types: [
        AnimationSearchActionTypes.EDITOR_STOCK_ANIMATION_SEARCH_REQUEST,
        AnimationSearchActionTypes.EDITOR_STOCK_ANIMATION_SEARCH_SUCCESS,
        AnimationSearchActionTypes.EDITOR_STOCK_ANIMATION_SEARCH_FAILURE
      ],
      endpoint: "/media",
      request: {
        page: page,
        pageSize: getState().ui.editorAnimationSearch.pageSize,
        params: {
          term: term,
          type: searchType,
          context: "STOCK_ANIMATION"
        }
      },
      schema: schemas.STOCK_ANIMATION_SEARCH_RESULT
    }
  });
};

export const searchBrandAnimations = ({ term, searchType, page = 1 }) => (
  dispatch,
  getState
) => {
  const folderId = getParameterByName("folderId", window.location);
  let params = {
    term,
    context: "BRAND_ANIMATION"
  };
  if (searchType) {
    params.type = searchType;
  }
  if (folderId) {
    params.folderId = folderId;
  }
  if (!term) {
    delete params.term;
  }
  if (folderId) {
    params.folderId = folderId;
  }
  dispatch({
    [CALL_API]: {
      service: SERVICES.ASSET,
      types: [
        AnimationSearchActionTypes.EDITOR_BRAND_ANIMATION_SEARCH_REQUEST,
        AnimationSearchActionTypes.EDITOR_BRAND_ANIMATION_SEARCH_SUCCESS,
        AnimationSearchActionTypes.EDITOR_BRAND_ANIMATION_SEARCH_FAILURE
      ],
      endpoint: "/media",
      request: {
        page: page,
        pageSize: getState().ui.editorAnimationSearch.pageSize,
        params
      },
      schema: schemas.BRAND_ANIMATION_SEARCH_RESULT
    }
  });
};

export const clearSearchTerm = () => {
  return {
    type: AnimationSearchActionTypes.EDITOR_ANIMATION_SEARCH_CLEAR_CURRENT_TERM
  };
};

const shouldFetchUserVideosSearchNextPage = (state, page) => {
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const userTeamAnimationsSearchResults =
    state.ui.editorAnimationSearch.personal[ANIMATION_ASSET_TYPE_MAP.VIDEO]
      .terms[currentTerm]?.ids;
  const numberOfAnimations = userTeamAnimationsSearchResults?.length;

  if (
    !userTeamAnimationsSearchResults ||
    numberOfAnimations % state.ui.editorAnimationSearch.pageSize !== 0
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  return !state.ui.editorAnimationSearch.personal[
    ANIMATION_ASSET_TYPE_MAP.VIDEO
  ].terms[currentTerm].isFetching;
};

export const getUserVideoSearchNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchUserVideosSearchNextPage(getState(), args.page)) {
    return dispatch(getUserVideoSearchNextPage(args));
  }
};

export const getUserVideoSearchNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const searchType = ANIMATION_ASSET_TYPE_MAP.VIDEO;

  const userVideosStore =
    state.ui.editorAnimationSearch.personal[ANIMATION_ASSET_TYPE_MAP.VIDEO]
      .terms[currentTerm];

  if (!userVideosStore.ids || userVideosStore.ids.length === 0) {
    dispatch(searchUserAnimations({ page: 1, searchType, term: currentTerm }));
    return;
  }

  // TODO: SEARCH FOLDER LOGIC
  // if (!pages || !pages[lastPage]) {
  //   dispatch(searchUserAnimations({ page: 1, folderId }));
  //   return;
  // }

  if (userVideosStore.isFetching) {
    return;
  }
  dispatch(searchUserAnimations({ page, searchType, term: currentTerm }));
};

const shouldFetchUserAnimationsSearchNextPage = (state, page) => {
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const userTeamAnimationsSearchResults =
    state.ui.editorAnimationSearch.personal[ANIMATION_ASSET_TYPE_MAP.ANIMATION]
      .terms[currentTerm]?.ids;
  const numberOfAnimations = userTeamAnimationsSearchResults?.length;

  if (
    !userTeamAnimationsSearchResults ||
    numberOfAnimations % state.ui.editorAnimationSearch.pageSize !== 0
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  return !state.ui.editorAnimationSearch.personal[
    ANIMATION_ASSET_TYPE_MAP.ANIMATION
  ].terms[currentTerm].isFetching;
};

export const getUserAnimationSearchNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchUserAnimationsSearchNextPage(getState(), args.page)) {
    return dispatch(getUserAnimationSearchNextPage(args));
  }
};

export const getUserAnimationSearchNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const searchType = ANIMATION_ASSET_TYPE_MAP.ANIMATION;

  const userAnimationsStore =
    state.ui.editorAnimationSearch.personal[ANIMATION_ASSET_TYPE_MAP.ANIMATION]
      .terms[currentTerm];

  if (!userAnimationsStore.ids || userAnimationsStore.ids.length === 0) {
    dispatch(searchUserAnimations({ page: 1, searchType, term: currentTerm }));
    return;
  }

  // TODO: SEARCH FOLDER LOGIC
  // if (!pages || !pages[lastPage]) {
  //   dispatch(searchUserAnimations({ page: 1, folderId }));
  //   return;
  // }

  if (userAnimationsStore.isFetching) {
    return;
  }
  dispatch(searchUserAnimations({ page, searchType, term: currentTerm }));
};

export const getStockVideoSearchNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const searchType = ANIMATION_ASSET_TYPE_MAP.VIDEO;

  const stockVideosStore =
    state.ui.editorAnimationSearch.stock[ANIMATION_ASSET_TYPE_MAP.VIDEO].terms[
      currentTerm
    ];

  if (!stockVideosStore.ids || stockVideosStore.ids.length === 0) {
    dispatch(searchStockAnimations({ page: 1, searchType, term: currentTerm }));
    return;
  }

  if (stockVideosStore.isFetching) {
    return;
  }
  dispatch(searchStockAnimations({ page, searchType, term: currentTerm }));
};

const shouldFetchStockVideosSearchNextPage = (state, page) => {
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const stockAnimationsSearchResults =
    state.ui.editorAnimationSearch.stock[ANIMATION_ASSET_TYPE_MAP.VIDEO].terms[
      currentTerm
    ]?.ids;
  const numberOfAnimations = stockAnimationsSearchResults?.length;

  if (
    !stockAnimationsSearchResults ||
    numberOfAnimations % state.ui.editorAnimationSearch.pageSize !== 0
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  return !state.ui.editorAnimationSearch.stock[ANIMATION_ASSET_TYPE_MAP.VIDEO]
    .terms[currentTerm].isFetching;
};

export const getStockVideoSearchNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchStockVideosSearchNextPage(getState(), args.page)) {
    return dispatch(getStockVideoSearchNextPage(args));
  }
};

export const getStockAnimationSearchNextPage = ({
  page = 1,
  folderId
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const searchType = ANIMATION_ASSET_TYPE_MAP.ANIMATION;

  const stockAnimationStore =
    state.ui.editorAnimationSearch.stock[ANIMATION_ASSET_TYPE_MAP.ANIMATION]
      .terms[currentTerm];

  if (!stockAnimationStore.ids || stockAnimationStore.ids.length === 0) {
    dispatch(searchStockAnimations({ page: 1, searchType, term: currentTerm }));
    return;
  }

  if (stockAnimationStore.isFetching) {
    return;
  }
  dispatch(searchStockAnimations({ page, searchType, term: currentTerm }));
};

const shouldFetchStockAnimationSearchNextPage = (state, page) => {
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const stockAnimationsSearchResults =
    state.ui.editorAnimationSearch.stock[ANIMATION_ASSET_TYPE_MAP.ANIMATION]
      .terms[currentTerm]?.ids;
  const numberOfAnimations = stockAnimationsSearchResults?.length;

  if (
    !stockAnimationsSearchResults ||
    numberOfAnimations % state.ui.editorAnimationSearch.pageSize !== 0
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  return !state.ui.editorAnimationSearch.stock[
    ANIMATION_ASSET_TYPE_MAP.ANIMATION
  ].terms[currentTerm].isFetching;
};

export const getStockAnimationSearchNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchStockAnimationSearchNextPage(getState(), args.page)) {
    return dispatch(getStockAnimationSearchNextPage(args));
  }
};

const shouldFetchBrandVideosSearchNextPage = (state, page) => {
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const teamAnimationsSearchResults =
    state.ui.editorAnimationSearch.brand[ANIMATION_ASSET_TYPE_MAP.VIDEO].terms[
      currentTerm
    ]?.ids;
  const numberOfAnimations = teamAnimationsSearchResults?.length;

  if (
    !teamAnimationsSearchResults ||
    numberOfAnimations % state.ui.editorAnimationSearch.pageSize !== 0
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  return !state.ui.editorAnimationSearch.brand[ANIMATION_ASSET_TYPE_MAP.VIDEO]
    .terms[currentTerm].isFetching;
};

export const getBrandVideoSearchNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchBrandVideosSearchNextPage(getState(), args.page)) {
    return dispatch(getBrandVideoSearchNextPage(args));
  }
};

export const getBrandVideoSearchNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const searchType = ANIMATION_ASSET_TYPE_MAP.VIDEO;

  const brandVideosStore =
    state.ui.editorAnimationSearch.brand[ANIMATION_ASSET_TYPE_MAP.VIDEO].terms[
      currentTerm
    ];

  if (!brandVideosStore.ids || brandVideosStore.ids.length === 0) {
    dispatch(searchBrandAnimations({ page: 1, searchType, term: currentTerm }));
    return;
  }

  if (brandVideosStore.isFetching) {
    return;
  }
  dispatch(searchBrandAnimations({ page, searchType, term: currentTerm }));
};

const shouldFetchBrandAnimationsSearchNextPage = (state, page) => {
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const teamAnimationsSearchResults =
    state.ui.editorAnimationSearch.brand[ANIMATION_ASSET_TYPE_MAP.ANIMATION]
      .terms[currentTerm]?.ids;
  const numberOfAnimations = teamAnimationsSearchResults?.length;

  if (
    !teamAnimationsSearchResults ||
    numberOfAnimations % state.ui.editorAnimationSearch.pageSize !== 0
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  return !state.ui.editorAnimationSearch.brand[
    ANIMATION_ASSET_TYPE_MAP.ANIMATION
  ].terms[currentTerm].isFetching;
};

export const getBrandAnimationSearchNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchBrandAnimationsSearchNextPage(getState(), args.page)) {
    return dispatch(getBrandAnimationSearchNextPage(args));
  }
};

export const getBrandAnimationSearchNextPage = ({
  page = 1,
  folderId
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentTerm = state.ui.editorAnimationSearch.personal.currentTerm;
  const searchType = ANIMATION_ASSET_TYPE_MAP.ANIMATION;

  const brandAnimationsStore =
    state.ui.editorAnimationSearch.brand[ANIMATION_ASSET_TYPE_MAP.ANIMATION]
      .terms[currentTerm];

  if (!brandAnimationsStore?.ids || brandAnimationsStore?.ids.length === 0) {
    dispatch(searchBrandAnimations({ page: 1, searchType, term: currentTerm }));
    return;
  }

  if (brandAnimationsStore.isFetching) {
    return;
  }
  dispatch(searchBrandAnimations({ page, searchType, term: currentTerm }));
};
