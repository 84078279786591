import React from "react";

import { getHeaderLinks } from "lib";

import style from "./style.module.css";

const getTitle = ({ urls, currentUrl, team }) => {
  if (!currentUrl || !urls) return [null];

  const urlList = Object.values(urls);
  const link = urlList.find(link => link.url === currentUrl);

  if (link) {
    if (link.title === "Team Templates") {
      return ["Templates", team.name];
    }
    return [link.title];
  }

  const matchByStartingWith = urlList.find(link =>
    currentUrl.match(new RegExp(`^${link.url}`, "i"))
  );

  if (matchByStartingWith) return [matchByStartingWith.title];

  return [null];
};

export const SubHeader = ({ children, currentUrl, pathToCatalogue, team }) => {
  const [title, preFix] = getTitle({
    urls: getHeaderLinks({ pathToCatalogue }),
    currentUrl,
    team
  });
  return (
    <div className={style.subHeaderWrapper} data-testid="SubHeader">
      <div className={style.subHeaderTitle} id="sub-header-title">
        {preFix && <div className={style.subHeaderTitlePrefix}>{preFix}</div>}
        <div>{title}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};

SubHeader.displayName = "SubHeader";

export default SubHeader;
