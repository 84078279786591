import React, { useState } from "react";
import SearchInput from "views/components/searchInput/SearchInput";
import style from "./style.module.css";

export default function BrandKitSearchInput({
  onClear,
  value,
  isDisabled,
  onChange,
  onKeyDown,
  placeholder,
  ...props
}) {
  const [isFocussed, setIsFocussed] = useState(false);

  return (
    <SearchInput
      {...props}
      className={style.searchInput}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onFocus={() => setIsFocussed(true)}
      onBlur={() => setIsFocussed(false)}
      isFocussed={isFocussed}
      clearInput={onClear}
    />
  );
}
