import * as types from "./subscriptionsTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { switchToken } from "state/currentUser/currentUserActions";
import { addCard } from "state/entities/cards/cardsActions";
import { subscriptionsApiSelector } from "state/api/subscriptions/subscriptionsApiSelector";

import { noop, reduxStoreExpiry } from "lib";
import { currentTeamIdSelector } from "../teams/teamsSelectors";

export const fetchCoupon = ({ promoCode }) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.SUBSCRIPTION,
      types: [
        types.FETCH_COUPON,
        types.FETCH_COUPON_SUCCESS,
        types.FETCH_COUPON_FAILURE
      ],
      endpoint: `/coupons/${promoCode}`,
      schema: schemas.NONE
    }
  });
};

export const fetchSubscriptions = ({ teamId, page = 1 }) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.SUBSCRIPTION,
      types: [
        types.FETCH_SUBSCRIPTIONS,
        types.FETCH_SUBSCRIPTIONS_SUCCESS,
        types.FETCH_SUBSCRIPTIONS_FAILURE
      ],
      endpoint: `/teams/${teamId}/subscriptions/current`,
      request: { page: page },
      schema: schemas.SUBSCRIPTION
    }
  });
};

export const fetchSubscriptionsForCurrentTeam = ({ page = 1 } = {}) => (
  dispatch,
  getState
) => {
  const teamId = currentTeamIdSelector(getState());
  return dispatch(fetchSubscriptions({ teamId, page }));
};

export const shouldFetchSubscriptions = (state, page = 1) => {
  const subscriptions = subscriptionsApiSelector(state).pages[page];

  if (!subscriptions) return true;
  if (subscriptions && subscriptions.isFetching) return false;

  const isExpired = reduxStoreExpiry.isDataExpired(
    subscriptions.lastFetched,
    5
  );

  return isExpired;
};

export const fetchSubscriptionsIfNeeded = args => (dispatch, getState) => {
  if (shouldFetchSubscriptions(getState(), args.page))
    dispatch(fetchSubscriptions(args));
};

export const updateSubscription = ({
  teamId,
  subscriptionId,
  planId,
  subscriptionCode,
  couponCode,
  token,
  onSuccess,
  onFailure,
  cancellationReason,
  cancellationNotes
}) => dispatch => {
  if (token) {
    addCardThenUpdateSub();
  } else {
    updateSubFn();
  }

  function updateSubFn() {
    updateSub({
      teamId,
      subscriptionId,
      planId,
      subscriptionCode,
      couponCode,
      dispatch,
      onSuccess,
      onFailure,
      cancellationReason,
      cancellationNotes
    });
  }

  function addCardThenUpdateSub() {
    dispatch(
      addCard({
        teamId,
        token,
        onSuccess: updateSubFn,
        onFailure
      })
    );
  }
};

const updateSub = ({
  teamId,
  subscriptionId,
  subscriptionCode,
  planId,
  couponCode,
  dispatch,
  onSuccess = noop,
  onFailure = noop,
  cancellationReason,
  cancellationNotes
}) => {
  const body = {
    subscriptionId,
    planId,
    subscriptionCode,
    teamId,
    couponCode
  };
  if (cancellationReason) {
    body.cancellationReason = cancellationReason;
  }
  if (cancellationNotes && cancellationNotes.length) {
    body.cancellationNotes = cancellationNotes;
  }
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.SUBSCRIPTION,
      types: [
        types.UPDATE_SUBSCRIPTION,
        types.UPDATE_SUBSCRIPTION_SUCCESS,
        types.UPDATE_SUBSCRIPTION_FAILURE
      ],
      endpoint: `/teams/${teamId}/subscriptions/${subscriptionId}`,
      request: {
        body
      },
      schema: schemas.SUBSCRIPTION,
      onSuccess: response => {
        dispatch(switchToken({ onSuccess: () => onSuccess(response) }));
      },
      onFailure
    }
  });
};
