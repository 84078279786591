import { createSelector } from "reselect";
import { getPath } from "lib/lodash";

export const teamAnimationsApiSelector = state => state.api.teamAnimations;
export const processAllTeamAnimationsIsFetchingSelector = teamAnimationsApi => {
  if (!teamAnimationsApi || !teamAnimationsApi.all.pages) return true;
  const {
    all: { pages }
  } = teamAnimationsApi;
  return Object.values(pages).some(page => page.isFetching); // true if at least one page is fetching
};

export const teamAnimationsIsFetchingSelector = createSelector(
  [teamAnimationsApiSelector],
  processAllTeamAnimationsIsFetchingSelector
);

const processTeamAnimationsPageCount = teamAnimationsApi => {
  if (!teamAnimationsApi || !teamAnimationsApi.pages) return 0;
  return Object.keys(teamAnimationsApi.all.pages).length;
};

export const teamAnimationsPageCount = createSelector(
  [teamAnimationsApiSelector],
  processTeamAnimationsPageCount
);

export const teamAnimationsFirstPageIsFetching = state => {
  const isAnimationsMissing = !getPath(
    state,
    "api.teamAnimations.animations.pages[1]"
  );
  const isVideosMissing = !getPath(state, "api.teamAnimations.videos.pages[1]");

  // both states are missing
  if (isAnimationsMissing && isVideosMissing) {
    return true;
  }

  const isAnimationsFirstPageFetching = getPath(
    state,
    "api.teamAnimations.animations.pages[1].isFetching"
  );
  const isVideosFirstPageFetching = getPath(
    state,
    "api.teamAnimations.videos.pages[1].isFetching"
  );

  return (
    (!isAnimationsMissing && isAnimationsFirstPageFetching) ||
    (!isVideosMissing && isVideosFirstPageFetching)
  );
};

export const teamAnimationsAllFirstPageIsFetching = state => {
  const firstPage = getPath(state, "api.teamAnimations.all.pages[1]");
  if (!firstPage) {
    return true;
  }
  return firstPage.isFetching;
};

export const teamAnimationsErrorSelector = state =>
  teamAnimationsApiSelector(state).errors;
