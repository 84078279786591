import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CellLockingIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M7 15H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2"
        stroke={color || easilGrayXDark}
        stroke-width="2"
        stroke-linecap="round"
      />
      <g transform="translate(10 9.75)" stroke={color || easilGrayXDark}>
        <path d="M5.5 3V1.5a1.5 1.5 0 0 0-3 0V3" stroke-width="1.5" />
        <rect stroke-width="2" x="1" y="3.25" width="6" height="4" rx="2" />
      </g>
      <path
        stroke={color || easilGrayXDark}
        stroke-width="2"
        stroke-linecap="round"
        d="M3 9h7.5M9 3v7.5"
      />
    </g>
  </svg>
);
CellLockingIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CellLockingIcon;
