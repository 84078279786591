import * as types from "./userTeamAnimationsTypes";
import * as animationFolderTypes from "state/entities/userTeamAnimationsFolders/userTeamAnimationsFoldersTypes";
import { isEmpty, omit } from "lib";

export const initState = null;

const userTeamAnimationsReducers = (state = initState, action) => {
  switch (action.type) {
    // GET
    case types.USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS:
    case types.USER_TEAM_ANIMATIONS_REQUEST_SUCCESS:
    case types.ALL_USER_TEAM_ANIMATIONS_REQUEST_SUCCESS:
    case types.USER_TEAM_VIDEOS_REQUEST_SUCCESS: {
      let previousState = state || {};

      const { entities = {} } = action.response;

      const userTeamAnimation = { ...entities.userTeamAnimation };

      if (!isEmpty(state)) {
        Object.keys(userTeamAnimation).forEach(id => {
          const animation = userTeamAnimation[id];
          const stateAnimation = state[id];
          const preservedThumbUrl = animation.thumbnailUrl;
          let thumbnailUrl = animation.thumbnailUrl;
          if (
            stateAnimation &&
            stateAnimation.thumbnailUrl &&
            stateAnimation.thumbnailUrl.startsWith("blob:")
          ) {
            thumbnailUrl = stateAnimation.thumbnailUrl;
          }
          userTeamAnimation[id] = {
            ...animation,
            thumbnailUrl,
            preservedThumbUrl
          };
        });
      }

      return { ...previousState, ...userTeamAnimation };
    }

    case types.ADD_USER_TEAM_ANIMATIONS_PLACEHOLDERS: {
      let previousState = state || {};

      // get the media placeholders
      const { mediaPlaceholders = [] } = action;

      // reduce into object and add isPlaceholder boolean flag
      const userTeamAnimations = mediaPlaceholders.reduce(
        (allMedia, currentMedia) => ({
          ...allMedia,
          [currentMedia.mediaId]: {
            ...currentMedia,
            isPlaceholder: true
          }
        }),
        {}
      );

      return { ...previousState, ...userTeamAnimations };
    }

    // DELETE
    case types.USER_TEAM_ANIMATION_DELETE_REQUEST: {
      return omit(state, action.request.mediaId);
    }
    case types.USER_TEAM_VIDEOS_DELETE_REQUEST: {
      return omit(state, action.extra.mediaIds);
    }

    // PUT
    case types.USER_TEAM_ANIMATION_UPLOAD_REQUEST:
    case types.USER_TEAM_ANIMATION_UPDATE_REQUEST: {
      const updatedAnimationId =
        action.request.body.mediaId || action.request.body.id;

      // return existing animation entity if one is already stored in state
      if (state[updatedAnimationId]) {
        return state;
      }

      return {
        ...state,
        [updatedAnimationId]: action.request.body
      };
    }

    // POST
    case types.USER_TEAM_ANIMATION_UPLOAD_REQUEST_SUCCESS:
    case types.USER_TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS: {
      const updatedAnimation = { ...action.response };

      if (
        updatedAnimation &&
        updatedAnimation.media &&
        !updatedAnimation.media.priority
      ) {
        updatedAnimation.media.priority = null;
      }

      const thumbnailUrl =
        action.extra?.placeholderURL || updatedAnimation.thumbnailUrl;

      return {
        ...state,
        [updatedAnimation.mediaId]: {
          ...updatedAnimation,
          thumbnailUrl,
          preservedThumbUrl: updatedAnimation.thumbnailUrl,
          origin: state[updatedAnimation.mediaId].origin
        }
      };
    }

    case animationFolderTypes.UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS: {
      const imageId = action.request.body.id;
      const thumbnailUrl = action.extra.placeholderURL;
      return {
        ...state,
        [imageId]: {
          ...state[imageId],
          isPlaceholder: false,
          thumbnailUrl,
          preservedThumbUrl: state[imageId].thumbnailUrl
        }
      };
    }

    default:
      return state;
  }
};

export default userTeamAnimationsReducers;
