import { Component } from "react";
import TunesIcon from "views/components/icons/TunesIcon";
import TeamSettingsModalContainer from "views/containers/TeamSettingsModalContainer/TeamSettingsModalContainer";
import style from "./style.module.css";

export class TeamCreateButton extends Component {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.state = {
      isModalOpen: false
    };
  }

  handleOpenModal() {
    this.setState({
      isModalOpen: true
    });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false
    });
  }

  render() {
    return (
      <div data-testid="TeamSettingsButton">
        <div
          className={style.teamSettingsButtonWrapper}
          onClick={this.handleOpenModal}
          data-is-divider={this.props.showDivider}
        >
          <TunesIcon size="20px" />
          <div className={style.teamSettingsTitle}>Team Settings</div>
        </div>
        {this.state.isModalOpen && (
          <TeamSettingsModalContainer
            isOpen={this.state.isModalOpen}
            onCloseModal={this.handleCloseModal}
          />
        )}
      </div>
    );
  }
}

export default TeamCreateButton;
