import * as types from "./designActionsTypes";
import { merge } from "lib";
import { getPath } from "lib/lodash";

export const initState = {};

const designActionsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.DESIGN_APPROVAL_ACTIONS_REQUEST_SUCCESS: {
      const designAction = getPath(action, "response.entities.designAction");

      if (!designAction) return state;

      return merge({}, state, designAction);
    }

    default:
      return state;
  }
};

export default designActionsReducer;
