import * as editorActionTypes from "state/ui/editor/editorActionTypes";
import * as designsTypes from "../../entities/designs/designsTypes";
import { formatErrors, omit } from "lib";

export const initState = {
  lastDesignDataIdSaved: null,
  isSaving: false,
  isSuccess: false,
  isSavingDesignDescription: false,
  errors: []
};

const errorsArray = error => formatErrors(error);

const editorDesignReducers = (state = initState, action) => {
  switch (action.type) {
    case designsTypes.SAVE_DESIGN_DATA_REQUEST: {
      const { isUpdatingDataVersion } = action.extra;

      return {
        ...initState,
        isSaving: true,
        lastDesignDataIdSaved: isUpdatingDataVersion
          ? null
          : state.lastDesignDataIdSaved
      };
    }

    case designsTypes.SAVE_DESIGN_DATA_REQUEST_SUCCESS: {
      const { isUpdatingDataVersion } = action.extra;

      return {
        ...initState,
        isSuccess: true,
        lastDesignDataIdSaved: isUpdatingDataVersion
          ? action.response.id
          : state.lastDesignDataIdSaved
      };
    }

    case editorActionTypes.LOAD_DESIGN: {
      return {
        ...initState,
        lastDesignDataIdSaved: action.data.designDataId
      };
    }

    case designsTypes.SAVE_DESIGN_DATA_REQUEST_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...initState,
        errors
      };
    }

    case designsTypes.SET_DESIGN_SAVE_ERROR: {
      const { errors } = state;

      if (!errors) return state;

      return omit(state, "errors");
    }

    case designsTypes.DESIGN_DRAFT_UPDATE_REQUEST: {
      return {
        ...state,
        isSavingDesignDescription: true
      };
    }

    case designsTypes.DESIGN_DRAFT_UPDATE_REQUEST_SUCCESS: {
      return {
        ...state,
        isSavingDesignDescription: false
      };
    }

    default:
      return state;
  }
};

export default editorDesignReducers;
