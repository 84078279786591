import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const TableInEditorContext = lazy(() => import("./TableInEditorContext"));

export const TableInEditorContextLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <TableInEditorContext {...props} />
  </Suspense>
);
TableInEditorContextLazy.displayName = "TableInEditorContext";

export default TableInEditorContextLazy;
