import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const SmoothDiagonalArrowIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    data-testid="SmoothDiagonalArrowIcon"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path
        stroke={color || easilGrayXDark}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M6.727 5H11v4.273M5 11l6-6"
      />
    </g>
  </svg>
);
SmoothDiagonalArrowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SmoothDiagonalArrowIcon;
