import React from "react";
import { connect } from "react-redux";
import { processCollectionRemix } from "state/ui/collectionRemix/collectionRemixActions";
import {
  fetchNextPageOfTeamsIfNeeded,
  fetchNextPageOfSubTeamsIfNeeded,
  fetchTeamsCount
} from "state/entities/teams/teamsActions";
import { teamsTotalCountSelector } from "state/entities/teams/teamsSelectors";
import {
  isFetchingTeamsSelector,
  selectedTeamSelector,
  teamsSelector
} from "state/ui/header/TeamMenu/TeamMenuSelectors";
import {
  navigateDown,
  navigateUp
} from "state/ui/header/TeamMenu/TeamMenuActions";
import DesignRemixWrapper from "views/components/DesignRemix/DesignRemixWrapper";

export const CollectionRemixContainer = props => (
  <DesignRemixWrapper {...props} targetName="collection" />
);

const mapStateToProps = state => {
  const selectedTeam = selectedTeamSelector(state);
  const teams = teamsSelector(state, selectedTeam);
  const teamCount = teamsTotalCountSelector(state);
  const isFetchingTeams = isFetchingTeamsSelector(state);

  return {
    currentUser: state.currentUser,
    currentTeam: state.ui.currentTeam,
    teams,
    teamCount,
    isFetchingTeams,
    selectedTeam
  };
};

const mapDispatchToProps = dispatch => {
  return {
    processDesignRemix: (...args) => dispatch(processCollectionRemix(...args)),
    fetchTeamsIfNeeded: args => dispatch(fetchNextPageOfTeamsIfNeeded(args)),
    fetchNextPageOfSubTeamsIfNeeded: args =>
      dispatch(fetchNextPageOfSubTeamsIfNeeded(args)),
    fetchTeamsCount: args => dispatch(fetchTeamsCount(args)),
    navigateDown: selectedTeam => dispatch(navigateDown(selectedTeam)),
    navigateUp: selectedTeam => dispatch(navigateUp(selectedTeam))
  };
};

CollectionRemixContainer.displayName = "CollectionRemixContainer";
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionRemixContainer);
