import React, { Component } from "react";

import PlanComparisonTop from "./PlanComparisonTop";
import PlanComparisonBottom from "./PlanComparisonBottom";
import Testimonials from "views/containers/plansContainer/Testimonials";
import testimonialsData from "views/containers/plansContainer/testimonialsData";

import Subscriptions from "lib/subscriptions";

import style from "./style.module.css";

export class PlanComparison extends Component {
  constructor(props) {
    super(props);

    this.setPeriod = this.setPeriod.bind(this);
    this.getPlansForPeriod = this.getPlansForPeriod.bind(this);
    this.handleUpgradeClick = this.handleUpgradeClick.bind(this);

    this.state = {
      currentPeriod: Subscriptions.SUBSCRIPTIONS_PERIODS[0]
    };
  }

  setPeriod(period) {
    this.setState({
      currentPeriod: period
    });
  }

  getPlansForPeriod() {
    const { currentPeriod } = this.state;
    const { plans, filterPlanCode } = this.props;

    return plans.filter(
      plan =>
        plan.intervalPeriod === currentPeriod &&
        (!filterPlanCode ||
          plan.code.toLowerCase() === "BASIC".toLowerCase() ||
          plan.code.toLowerCase() === filterPlanCode.toLowerCase())
    );
  }

  handleUpgradeClick(planId) {
    const { openUpgradeModal } = this.props;

    openUpgradeModal(planId);
  }

  render() {
    const { currentSubscription, currentPlan, filterPlanCode } = this.props;
    const { currentPeriod } = this.state;

    const isFiltered = Boolean(filterPlanCode);

    return (
      <div className={style.wrapper}>
        <PlanComparisonTop
          setPeriod={this.setPeriod}
          currentPeriod={currentPeriod}
          isFiltered={isFiltered}
        />

        <PlanComparisonBottom
          isAdmin={this.props.isAdmin}
          plans={this.getPlansForPeriod()}
          handleUpgradeClick={this.handleUpgradeClick}
          currentPlan={currentPlan}
          currentSubscription={currentSubscription}
        />

        <div className={style.testimonials}>
          <Testimonials testimonials={testimonialsData} />
        </div>
      </div>
    );
  }
}

export default PlanComparison;
