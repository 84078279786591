import * as types from "./inviteToTeamFormTypes";
import {
  default as pristineReducer,
  initState as initStatePristine
} from "./inviteToTeamFormPristineReducers";
import { default as validReducer } from "./inviteToTeamFormValidReducers";
import { default as invalidReducer } from "./inviteToTeamFormInvalidReducers";
import { default as loadingReducer } from "./inviteToTeamFormLoadingReducers";
import { default as allCompletedReducer } from "./inviteToTeamFormAllCompletedReducers";

const inviteToTeamFormReducers = (state = initStatePristine, action) => {
  switch (state.status) {
    case "EMPTY":
    case "PRISTINE": {
      return pristineReducer(state, action);
    }

    case "VALID": {
      return validReducer(state, action);
    }

    case "INVALID": {
      return invalidReducer(state, action);
    }

    case "LOADING": {
      return loadingReducer(state, action);
    }

    case "ALL_COMPLETED": {
      return allCompletedReducer(state, action);
    }

    default:
      return state;
  }
};

const inviteToTeamReducersWithReset = (state, action) => {
  if (action.type === types.TEAM_INVITE_FORM_RESET) {
    state = undefined;
  }

  return inviteToTeamFormReducers(state, action);
};

export default inviteToTeamReducersWithReset;
