import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CoIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M3.512 4.556l5-2.857a3 3 0 0 1 2.976 0l5 2.857A3 3 0 0 1 18 7.16v5.678a3 3 0 0 1-1.512 2.605l-5 2.857a3 3 0 0 1-2.976 0l-5-2.857A3 3 0 0 1 2 12.84V7.161a3 3 0 0 1 1.512-2.605zm6.52 6.518H8.667c-.01.323-.099.559-.266.707-.166.149-.407.223-.722.223-.373 0-.635-.13-.787-.39-.153-.261-.229-.695-.229-1.302V9.91c.008-.56.091-.967.25-1.223.159-.255.409-.382.75-.382.336 0 .586.075.75.226.164.151.251.4.262.746h1.367c-.057-.638-.296-1.138-.715-1.502-.42-.363-.974-.545-1.664-.545-.48 0-.901.114-1.266.342-.364.228-.643.551-.837.969-.194.418-.291.906-.291 1.463v.281c0 .888.214 1.576.644 2.063.43.487 1.018.73 1.766.73.453 0 .856-.083 1.209-.25.353-.167.627-.402.822-.705.195-.303.302-.653.32-1.049zm.421-.226l.004.222c.029.618.225 1.106.59 1.467.364.36.848.541 1.45.541.624 0 1.12-.193 1.485-.58.366-.387.55-.91.55-1.568v-.047c0-.675-.183-1.208-.548-1.6-.364-.392-.863-.588-1.496-.588-.414 0-.774.089-1.082.266a1.76 1.76 0 0 0-.707.76c-.164.33-.246.705-.246 1.127zm1.317.082l.003-.239c.037-.653.275-.98.715-.98.485 0 .727.379.727 1.137v.132c-.01.722-.25 1.082-.719 1.082-.24 0-.42-.09-.543-.273-.122-.182-.183-.469-.183-.86z"
        fill={color || easilGrayXDark}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
CoIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CoIcon;
