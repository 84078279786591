import React from "react";
import style from "./style.module.css";
import { formatDateString } from "lib/temporal/dateUtils";
import PersonIcon from "views/components/icons/PersonIcon";
import CalendarIcon from "views/components/icons/CalendarIcon";
import SquareCutInto3PiecesIcon from "views/components/icons/SquareCutInto3PiecesIcon";

const DetailRows = ({ userName, designName, requestDate }) => {
  const rows = [
    {
      icon: <PersonIcon isSmall={true} size="22px" />,
      text: `${userName} has requested design approval`
    },
    {
      icon: (
        <SquareCutInto3PiecesIcon
          size="18px"
          style={{ marginRight: "3px", marginLeft: "3px" }}
        />
      ),
      text: `${designName}`
    },
    {
      icon: <CalendarIcon size="24px" />,
      text: `Approval requested ${formatDateString(
        requestDate,
        "MMM D, YYYY, hh:mmA"
      )}`
    }
  ];

  return (
    <div>
      {rows.map((row, index) => (
        <div className={style.detailRow} key={index}>
          <div className={style.iconBox}>{row.icon}</div>
          <span className={style.detailText}>{row.text}</span>
        </div>
      ))}
    </div>
  );
};

DetailRows.displayName = "DetailRows";

DetailRows.propTypes = {};

export default DetailRows;
