import { push } from "react-router-redux";
import * as types from "./ordersTypes";
import { noop, getPath } from "lib";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { orderByIdSelector } from "./ordersSelectors";
import {
  moveToCart,
  moveToCartComplete
} from "state/ui/navigation/navigationActions";
import { setCurrentOrderId, clearCartState } from "state/ui/cart/cartActions";
import { currentUserIdSelector } from "state/currentUser/currentUserSelectors";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import { designEntitiesSelector } from "state/entities/designs/designsSelectors";
import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";
import {
  currentOrderIdSelector,
  cartTokenSelector,
  cartPaymentTypeSelector
} from "state/ui/cart/cartSelectors";
import {
  currentOrderIdSelector as purchaseCollectionCurrentOrderIdSelector,
  purchaseCollectionTokenSelector,
  purchaseCollectionPaymentTypeSelector
} from "state/ui/purchaseCollectionModal/purchaseCollectionModalSelectors";
import { asyncAction } from "lib/asyncHelpers";
import { isEmpty } from "lib/lodash";
import { asyncFetchDesignIfNeeded } from "state/entities/designs/designsActions";

const pageSize = 100;

/*
  ORDER ACTIONS
*/

/* fetch all orders for the current states user-team */
export const getAllOrders = ({
  status,
  page = 1,
  resolve = noop,
  orders = []
} = {}) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserIdSelector(state);
  const teamId = currentTeamIdSelector(state);

  const params = {};

  if (status) params.status = status;

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER,
      types: [
        types.FETCH_ORDERS_REQUEST,
        types.FETCH_ORDERS_REQUEST_SUCCESS,
        types.FETCH_ORDERS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/orders`,
      request: {
        page,
        pageSize,
        params
      },
      schema: schemas.ORDERS,
      onSuccess: response => {
        const jointOrders = [
          ...orders,
          ...Object.values(getPath(response, "entities.orders", {}))
        ];
        // if there are entities and there is enough for a full page
        if (
          response.entities &&
          Object.keys(response.entities).length === pageSize
        ) {
          dispatch(
            getAllOrders({
              status,
              page: page + 1,
              resolve,
              orders: jointOrders
            })
          );
        } else {
          resolve(jointOrders);
        }
      }
    }
  });
};

/* fetch all orders for the current states user-team */
export const getAllOrdersV2 = ({
  status,
  page = 1,
  type = "PRINT",
  resolve = noop,
  orders = []
} = {}) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserIdSelector(state);
  const teamId = currentTeamIdSelector(state);

  const params = {};

  if (status) params.status = status;
  if (type) params.type = type;

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER_V2,
      types: [
        types.FETCH_ORDERS_REQUEST,
        types.FETCH_ORDERS_REQUEST_SUCCESS,
        types.FETCH_ORDERS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/orders`,
      request: {
        page,
        pageSize,
        params
      },
      schema: schemas.ORDERS,
      onSuccess: response => {
        const jointOrders = [
          ...orders,
          ...Object.values(getPath(response, "entities.orders", {}))
        ];
        // if there are entities and there is enough for a full page
        if (
          response.entities &&
          Object.keys(response.entities).length === pageSize
        ) {
          dispatch(
            getAllOrders({
              status,
              page: page + 1,
              resolve,
              orders: jointOrders
            })
          );
        } else {
          resolve(jointOrders);
        }
      }
    }
  });
};

/**
 * @desc - a wrapper function for the getAllOrders function which allows it to be run async
 * @param {object} args - input parameter object for getAllOrders function
 * @param {string} args.userId - the current users id
 * @param {string} args.teamId - the team that the current design belongs to
 * @param {string} args.status - the status of orders we want to find
 * @param {number} args.page - the current page to get for from pagination
 * @param {function} dispatch - current dispatch action from caller
 * @returns {promise} - results in a promise that will resolve when the getAllOrders process is complete
 */
export const asyncGetAllOrders = asyncAction(getAllOrders);

/**
 * @desc - a wrapper function for the getAllOrders function which allows it to be run async
 * @param {object} args - input parameter object for getAllOrders function
 * @param {string} args.userId - the current users id
 * @param {string} args.teamId - the team that the current design belongs to
 * @param {string} args.status - the status of orders we want to find
 * @param {number} args.page - the current page to get for from pagination
 * @param {function} dispatch - current dispatch action from caller
 * @returns {promise} - results in a promise that will resolve when the getAllOrders process is complete
 */
export const asyncGetAllOrdersV2 = asyncAction(getAllOrdersV2);

/**
 * @desc - fetches data about an order from the api
 * @param {object} args - the params object to be used in the api request
 * @param {string} args.orderId - the id of the order to fetch details for
 * @param {string} args.userId - the current users id
 * @param {string} args.teamId - the team that the order belongs to
 * @param {function} args.onSuccess - a function which is called when the process completes
 * @param {function} args.resolve - a promise resolve function called when the process completes
 * @param {function} dispatch - current dispatch action from caller
 * @returns {promise} - results in a promise that will resolve when the fetchOrder process is complete
 */
export const fetchOrder = ({ orderId, onSuccess = noop, resolve = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = currentUserIdSelector(state);
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER,
      types: [
        types.FETCH_ORDER_REQUEST,
        types.FETCH_ORDER_REQUEST_SUCCESS,
        types.FETCH_ORDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/orders/${orderId}`,
      schema: schemas.ORDER,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      }
    }
  });
};

/**
 * @desc - wrapper for the fetchOrder function which allows it to be called synchronously with await
 * @param {object} args - the params object to be passed to fetchOrder function
 * @param {string} args.orderId - the id of the order to fetch details for
 * @param {string} args.userId - the current users id
 * @param {string} args.teamId - the team that the order belongs to
 * @param {function} dispatch - current dispatch action from caller
 * @returns {promise} - results in a promise that will resolve when the fetchOrder process is complete
 */
export const asyncFetchOrder = asyncAction(fetchOrder);

// gets the "details" for the current in progress order in cart page
export const fetchCurrentOrder = ({ onSuccess } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentOrderId = currentOrderIdSelector(state);

  return dispatch(asyncFetchOrder({ orderId: currentOrderId, onSuccess }));
};

export const fetchCurrentOrderIfNeeded = () => async dispatch => {
  const [inProgressOrderId, orderDetails] = await new Promise(resolve =>
    dispatch(getInProgressOrderId({ resolve }))
  );

  if (inProgressOrderId && isEmpty(orderDetails)) {
    // set the order as the current cart order so we don't have to refetch it
    dispatch(setCurrentOrderId({ orderId: inProgressOrderId }));
    // fetch the order since it isn't already in state
    dispatch(fetchOrder({ orderId: inProgressOrderId }));
  }
};

/**
 * @desc create a new order
 */
export const createOrder = ({
  designId,
  designDataId,
  onSuccess = noop,
  resolve = noop
}) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserIdSelector(state);
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ORDER,
      types: [
        types.CREATE_ORDER_REQUEST,
        types.CREATE_ORDER_REQUEST_SUCCESS,
        types.CREATE_ORDER_REQUEST_FAILURE
      ],
      request: {
        body: {
          designId,
          designDataId,
          userId,
          teamId
        }
      },
      endpoint: `/teams/${teamId}/users/${userId}/orders`,
      schema: schemas.ORDER,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      }
    }
  });
};

export const asyncCreateOrder = asyncAction(createOrder);

/**
 * @desc create a new order
 */
export const createOrderV2 = ({
  designId,
  designDataId,
  onSuccess = noop,
  resolve = noop
}) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserIdSelector(state);
  const teamId = currentTeamIdSelector(state);

  const body = {
    userId,
    teamId
  };

  // to allow for orders created with no designs (allow marketplace orders)
  if (designId && designDataId) {
    body.designId = designId;
    body.designDataId = designDataId;
  } else {
    body.type = "COLLECTION";
  }

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ORDER_V2,
      types: [
        types.CREATE_ORDER_REQUEST,
        types.CREATE_ORDER_REQUEST_SUCCESS,
        types.CREATE_ORDER_REQUEST_FAILURE
      ],
      request: {
        body
      },
      endpoint: `/teams/${teamId}/users/${userId}/orders`,
      schema: schemas.ORDER_V2,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      }
    }
  });
};

export const asyncCreateOrderV2 = asyncAction(createOrderV2);

/*
  ORDER DESIGN ACTIONS
*/

export const addNewDesignToOrder = ({
  orderId,
  designId,
  designDataId,
  onSuccess = noop,
  resolve = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ORDER,
      types: [
        types.ADD_DESIGN_TO_ORDER_REQUEST,
        types.ADD_DESIGN_TO_ORDER_REQUEST_SUCCESS,
        types.ADD_DESIGN_TO_ORDER_REQUEST_FAILURE
      ],
      request: {
        body: {
          orderId,
          designId,
          designDataId
        }
      },
      endpoint: `/orders/${orderId}/designs`,
      schema: schemas.ORDER_DESIGN,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      }
    }
  });
};

export const asyncAddNewDesignToOrder = asyncAction(addNewDesignToOrder);

export const updateDesignInOrder = ({
  orderId,
  designId,
  orderDesignId,
  designDataId,
  onSuccess = noop,
  resolve = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PATCH",
      service: SERVICES.ORDER,
      types: [
        types.UPDATE_DESIGN_IN_ORDER_REQUEST,
        types.UPDATE_DESIGN_IN_ORDER_REQUEST_SUCCESS,
        types.UPDATE_DESIGN_IN_ORDER_REQUEST_FAILURE
      ],
      request: {
        body: {
          orderDesignId,
          designDataId,
          designId,
          orderId
        }
      },
      endpoint: `/orders/${orderId}/designs/${orderDesignId}`,
      schema: schemas.ORDER_DESIGN,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      }
    }
  });
};

export const asyncUpdateDesignInOrder = asyncAction(updateDesignInOrder);

/**
 * @desc Remove a design from an order in progress
 */
export const deleteDesignFromOrder = ({
  orderId,
  orderDesignId,
  onSuccess = noop
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ORDER,
      types: [
        types.DESIGN_ORDER_DELETE_REQUEST,
        types.DESIGN_ORDER_DELETE_REQUEST_SUCCESS,
        types.DESIGN_ORDER_DELETE_REQUEST_FAILURE
      ],
      endpoint: `/orders/${orderId}/designs/${orderDesignId}`,
      schema: schemas.NONE,
      onSuccess: () => onSuccess(),
      extra: {
        orderId,
        orderDesignId
      }
    }
  });
};

/*
  ORDER COLLECTION ACTIONS
*/

export const addNewCollectionToOrder = ({
  orderId,
  collectionId,
  onSuccess = noop,
  resolve = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ORDER,
      types: [
        types.ADD_COLLECTION_TO_ORDER_REQUEST,
        types.ADD_COLLECTION_TO_ORDER_REQUEST_SUCCESS,
        types.ADD_COLLECTION_TO_ORDER_REQUEST_FAILURE
      ],
      request: {
        body: {
          orderId,
          collectionId
        }
      },
      endpoint: `/orders/${orderId}/collections`,
      schema: schemas.ORDER_COLLECTION,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      // TODO: remove this as it is only for testing right now
      onFailure: response => {
        resolve();
      }
    }
  });
};

export const asyncAddNewCollectionToOrder = asyncAction(
  addNewCollectionToOrder
);

/*
  MISC ORDER HANDLING ACTIONS
*/

export const handleAddingDesignToOrder = ({
  designId,
  designDataId,
  onSuccess = noop,
  resolve = noop
}) => async dispatch => {
  const asyncHandleSetUpOrder = asyncAction(handleSetUpOrder);
  const orderId = await dispatch(
    asyncHandleSetUpOrder({ designId, designDataId })
  );

  // set the order as the current cart order so we don't have to refetch it
  dispatch(setCurrentOrderId({ orderId }));

  onSuccess();
  resolve();

  // done everything, lets move to the cart page
  dispatch(moveToCart());
};

export const asyncHandleAddingDesignToOrder = asyncAction(
  handleAddingDesignToOrder
);

/**
 * @desc gets the currently in progress order calling resolve when it finds one
 */
export const getInProgressOrderId = ({ resolve }) => async (
  dispatch,
  getState
) => {
  // check if there is an in progress order in state
  const state = getState();
  const stateOrderId = currentOrderIdSelector(state);
  const stateOrder = stateOrderId
    ? orderByIdSelector({ state, orderId: stateOrderId })
    : null;
  let orderId = null;
  let orderDetailsFetchResult = {};

  if (stateOrder && stateOrder.status === "IN_PROGRESS") {
    // there is an order in state in progress, lets add to it
    orderDetailsFetchResult = await dispatch(
      asyncFetchOrder({ orderId: stateOrderId })
    );
    if (
      getPath(
        orderDetailsFetchResult,
        `entities.order.${orderId}.status`,
        ""
      ) !== "IN_PROGRESS"
    ) {
      return resolve([stateOrderId, orderDetailsFetchResult]);
    }
  }

  // there is no valid order in state, lets try fetching one
  const orders = await dispatch(asyncGetAllOrders({ status: "IN_PROGRESS" }));
  const fetchedOrder = orders.length ? orders[0] : null;
  if (fetchedOrder) {
    // order was found, lets add to it
    return resolve([fetchedOrder.id, {}]);
  }
  return resolve([]);
};

/**
 * @desc add a design to currently in progress order, creating a new order if none exist already
 */
export const handleSetUpOrder = ({
  designId,
  designDataId,
  onSuccess = noop,
  resolve = noop
}) => async dispatch => {
  const endAction = args => {
    onSuccess(args);
    resolve(args);
  };

  let [orderId, orderDetailsFetchResult] = await dispatch(
    asyncAction(getInProgressOrderId)({ designId, designDataId })
  );

  // now we should have the orderId, but if not print an error and return
  if (!orderId) {
    // no order could be found, need to make one
    const newOrderResponse = await dispatch(
      asyncCreateOrder({ designId, designDataId })
    );

    return endAction(newOrderResponse.ids);
  }

  // always try to fetch the updated information about the order if we need to
  if (isEmpty(orderDetailsFetchResult)) {
    orderDetailsFetchResult = await dispatch(asyncFetchOrder({ orderId }));
  }

  // check if any of the designs in the order match the new design information
  const orderDesigns = getPath(
    orderDetailsFetchResult,
    `entities.order.${orderId}.designs`,
    []
  );

  const matchingOrderDesign = orderDesigns.find(
    design => design.designId === designId
  );

  if (matchingOrderDesign) {
    // there is an orderDesign with the same designId
    if (matchingOrderDesign.designDataId === designDataId) {
      // the designDataId match, abort
      return endAction(orderId);
    }
    // at least one of the designs is a match but we need to update the designDataId
    await dispatch(
      asyncUpdateDesignInOrder({
        orderId,
        designId,
        designDataId,
        orderDesignId: matchingOrderDesign.id
      })
    );
  } else {
    // add the design to the order
    await dispatch(
      asyncAddNewDesignToOrder({ orderId, designId, designDataId })
    );
  }

  return endAction(orderId);
};

/*
  ORDER DESIGN PRINT ITEM ACTIONS
*/

/**
 *
 * @desc creates a new print item for the given order design in the given order
 */
export const addNewPrintItemInOrderDesign = ({
  orderId,
  orderDesignId,
  printProviderPrintOptionPricingId,
  quantity,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ORDER,
      types: [
        types.ADD_PRINT_ITEM_TO_ORDER_DESIGN_REQUEST,
        types.ADD_PRINT_ITEM_TO_ORDER_DESIGN_REQUEST_SUCCESS,
        types.ADD_PRINT_ITEM_TO_ORDER_DESIGN_REQUEST_FAILURE
      ],
      request: {
        body: {
          orderId,
          orderDesignId,
          printProviderPrintOptionPricingId,
          quantity
        }
      },
      endpoint: `/orders/${orderId}/designs/${orderDesignId}/print-items`,
      schema: schemas.ORDER_DESIGN_PRINT_ITEM,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

/**
 *
 * @desc updates print item with given id in given order design in the given order
 */
export const updatePrintItemInOrderDesign = ({
  orderId,
  orderDesignId,
  printItemId,
  printItem,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserIdSelector(state);
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ORDER,
      types: [
        types.UPDATE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST,
        types.UPDATE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST_SUCCESS,
        types.UPDATE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST_FAILURE
      ],
      request: {
        body: {
          ...printItem,
          discount: printItem.discount || 0,
          orderId,
          orderDesignId,
          userId,
          teamId
        }
      },
      endpoint: `/orders/${orderId}/designs/${orderDesignId}/print-items/${printItemId}`,
      schema: schemas.ORDER_DESIGN_PRINT_ITEM,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

export const handleAddCouponToOrder = ({
  orderId,
  coupon,
  onSuccess
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PATCH",
      service: SERVICES.ORDER,
      types: [
        types.ADD_COUPON_TO_ORDER_REQUEST,
        types.ADD_COUPON_TO_ORDER_REQUEST_SUCCESS,
        types.ADD_COUPON_TO_ORDER_REQUEST_FAILURE
      ],
      request: {
        body: {
          orderId,
          coupon
        }
      },
      endpoint: `/orders/${orderId}`,
      schema: schemas.COUPON_ORDER,
      onSuccess
    }
  });
};

/**
 *
 * @desc deletes print item with given id in given order design in the given order
 */
export const deletePrintItemInOrderDesign = ({
  orderId,
  orderDesignId,
  printItemId,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ORDER,
      types: [
        types.DELETE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST,
        types.DELETE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST_SUCCESS,
        types.DELETE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST_FAILURE
      ],
      extra: {
        orderId,
        orderDesignId,
        printItemId
      },
      endpoint: `/orders/${orderId}/designs/${orderDesignId}/print-items/${printItemId}`,
      schema: schemas.ORDER_DESIGN_PRINT_ITEM,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

/**
 * @desc create a payment for an order using the given stripe token
 * @returns
 */
export const createOrderPayment = ({
  orderId,
  token,
  provider,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ORDER,
      types: [
        types.CREATE_ORDER_PAYMENT_REQUEST,
        types.CREATE_ORDER_PAYMENT_REQUEST_SUCCESS,
        types.CREATE_ORDER_PAYMENT_REQUEST_FAILURE
      ],
      endpoint: `/orders/${orderId}/payment`,
      request: {
        body: {
          orderId,
          token,
          provider
        }
      },
      schema: schemas.PAYMENT_ORDER,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

export const createOrderPaymentFromState = ({
  onSuccess = noop,
  resolve = noop,
  reject = noop,
  token
}) => (dispatch, getState) => {
  const state = getState();
  const orderId = currentOrderIdSelector(state);

  const paymentType = cartPaymentTypeSelector(state);
  let provider = paymentType === "credit" ? "STRIPE" : "PAYPAL";

  // handle account based payments
  const subscription = currentSubscriptionSelector(state);
  if (subscription.isAccount) {
    token = subscription.processorCustomerId;
    provider = "EASIL";
  } else if (!token) {
    token = cartTokenSelector(state);
  }

  return dispatch(
    createOrderPayment({
      orderId,
      token,
      provider,
      onSuccess: response => {
        onSuccess(response);
        dispatch(moveToCartComplete(orderId));
        dispatch(clearCartState());
      },
      resolve,
      reject
    })
  );
};

export const createCollectionOrderPaymentFromState = ({
  onSuccess = noop,
  resolve = noop,
  reject = noop,
  token,
  orderId: incomingOrderId
}) => (dispatch, getState) => {
  const state = getState();
  const orderId =
    incomingOrderId || purchaseCollectionCurrentOrderIdSelector(state);

  const paymentType = purchaseCollectionPaymentTypeSelector(state);
  let provider = paymentType === "credit" ? "STRIPE" : "PAYPAL";

  // handle account based payments
  const subscription = currentSubscriptionSelector(state);
  if (subscription.isAccount) {
    token = subscription.processorCustomerId;
    provider = "EASIL";
  } else if (!token) {
    token = purchaseCollectionTokenSelector(state);
  }

  return dispatch(
    createOrderPayment({
      orderId,
      token,
      provider,
      onSuccess: response => {
        onSuccess(response);
      },
      resolve,
      reject
    })
  );
};

export const asyncCreateCollectionOrderPaymentFromState = asyncAction(
  createCollectionOrderPaymentFromState
);

export const flagOrderDesignsAsUpdatingVersion = ({
  orderId,
  orderDesignIds
}) => {
  return {
    type: types.FLAG_ORDER_DESIGNS_UPDATING_VERSION,
    orderId,
    orderDesignIds
  };
};

/**
 * @desc create an order in paypal
 */
export const createPaypalOrder = ({
  orderId,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ORDER,
      types: [
        types.CREATE_PAYPAL_ORDER_REQUEST,
        types.CREATE_PAYPAL_ORDER_REQUEST_SUCCESS,
        types.CREATE_PAYPAL_ORDER_REQUEST_FAILURE
      ],
      endpoint: `/paypal/orders`,
      request: {
        body: {
          orderId
        }
      },
      schema: schemas.NONE,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

export const asyncCreatePaypalOrder = asyncAction(createPaypalOrder);

export const pollOrderStatus = ({ orderId, resolve, reject }) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER,
      endpoint: `/orders/${orderId}/status`,
      schema: schemas.NONE,
      types: [
        types.POLL_ORDER_REQUEST,
        types.POLL_ORDER_REQUEST_SUCCESS,
        types.POLL_ORDER_REQUEST_FAILURE
      ],
      onSuccess: response => {
        const order = response;

        if (["COMPLETED"].includes(order.status)) {
          resolve(order);
        } else if (["ERROR"].includes(order.status)) {
          reject(order);
        } else {
          setTimeout(() => {
            dispatch(
              pollOrderStatus({
                orderId,
                resolve,
                reject
              })
            );
          }, 3000);
        }
      }
    }
  });
};

export const asyncPollOrderStatus = asyncAction(pollOrderStatus);

/**
 * @desc Cancel an order given the orderId and status 'CANCELLED'
 * @returns
 */
export const cancelOrder = ({
  orderId,
  status = "CANCELLED",
  resolve
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ORDER,
      types: [
        types.CANCEL_ORDER_REQUEST,
        types.CANCEL_ORDER_REQUEST_SUCCESS,
        types.CANCEL_ORDER_REQUEST_FAILURE
      ],
      endpoint: `/orders/${orderId}/status`,
      request: {
        body: {
          orderId,
          status
        }
      },
      schema: schemas.NONE,
      onSuccess: resolve
    }
  });
};

export const asyncCancelOrder = asyncAction(cancelOrder);

///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @desc takes updated order information and makes an api call to update the order
 * @param {object} args - the arguments used for the updateOrder request
 * @param {object} args.order - the new order object to update to
 * @param {[object]} args.designs - a list of designs included in the order
 * @param {object} args.shipping - the shipping details for the order
 * @param {object} args.billing - the billing details for the order
 * @param {string} args.orderId - the id of the order to update
 * @param {string} args.userId - the id for the user making the update request
 * @param {string} args.teamId - the id for the team the user is currently in to make the update request
 * @param {function} args.onSuccess - function to be called on successful update
 * @param {function} args.resolve - function to be called on successful update (used to resolve an async update)
 */
export const updateOrder = ({
  order,
  designs,
  shipping,
  billing,
  orderId,
  userId,
  teamId,
  onSuccess = noop,
  resolve = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ORDER,
      types: [
        types.UPDATE_ORDER_REQUEST,
        types.UPDATE_ORDER_REQUEST_SUCCESS,
        types.UPDATE_ORDER_REQUEST_FAILURE
      ],
      request: {
        body: {
          order,
          designs,
          shippingDetail: shipping,
          billingDetail: billing
        }
      },
      endpoint: `/teams/${teamId}/users/${userId}/orders/${orderId}`,
      schema: schemas.ORDER,
      onSuccess: response => {
        onSuccess(response);
        // resolve promise if called as an async request
        resolve();
      }
    }
  });
};

/**
 * @desc takes updated order information and passes it on to updateOrder() in an async manner
 * @param {object} args - the arguments used for the updateOrder request
 * @param {object} args.order - the new order object to update to
 * @param {[object]} args.designs - a list of designs included in the order
 * @param {object} args.shipping - the shipping details for the order
 * @param {object} args.billing - the billing details for the order
 * @param {string} args.orderId - the id of the order to update
 * @param {string} args.userId - the id for the user making the update request
 * @param {string} args.teamId - the id for the team the user is currently in to make the update request
 * @param {function} args.onSuccess - function to be called on successful update
 * @param {function} dispatch - a redux dispatch function
 */
export const asyncUpdateOrder = (args, dispatch) =>
  new Promise(resolve => {
    args.resolve = resolve;
    dispatch(updateOrder(args));
  });

export const updateCouponCode = ({
  order,
  designs,
  shipping,
  billing,
  orderId,
  userId,
  teamId
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ORDER,
      types: [
        types.UPDATE_ORDER_COUPON_CODE_REQUEST,
        types.UPDATE_ORDER_COUPON_CODE_REQUEST_SUCCESS,
        types.UPDATE_ORDER_COUPON_CODE_REQUEST_FAILURE
      ],
      request: {
        body: {
          order,
          designs,
          shippingDetail: shipping,
          billingDetail: billing
        }
      },
      endpoint: `/teams/${teamId}/users/${userId}/orders/${orderId}`,
      schema: schemas.ORDER
    }
  });
};

export const completeOrder = ({
  token,
  orderId,
  userId,
  teamId,
  redirectUrl,
  onSuccess = noop,
  onFailure = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PATCH",
      service: SERVICES.ORDER,
      types: [
        types.COMPLETE_ORDER_REQUEST,
        types.COMPLETE_ORDER_REQUEST_SUCCESS,
        types.COMPLETE_ORDER_REQUEST_FAILURE
      ],
      request: {
        body: {
          token,
          userId,
          teamId,
          orderId
        }
      },
      endpoint: `/teams/${teamId}/users/${userId}/orders/${orderId}`,
      schema: schemas.ORDER,
      onSuccess: response =>
        redirectUrl ? dispatch(push(redirectUrl)) : onSuccess(response),
      onFailure
    }
  });
};

// fetch the details for an order by its id
export const getDetailsForOrderById = ({ orderId }) => async (
  dispatch,
  getState
) => {
  const state = getState();

  // get the order
  let order = orderByIdSelector({ state, orderId });

  // if the order is missing or has no designs get more details
  if (!order || !order.designs) {
    const {
      entities: { order: orders }
    } = await dispatch(asyncFetchOrder({ orderId }));
    order = Object.values(orders)[0];
  }

  // get all the ids for designs in entity state already
  const designsState = designEntitiesSelector({ state });
  const designEntityIds = Object.keys(designsState);

  // check if any designs are missing from entities
  const missingDesignIds = [];
  order.designs.forEach(design => {
    if (!designEntityIds.includes(design.designId)) {
      // add to list of missing designs
      missingDesignIds.push(design.designId);
    }
  });

  const designPromises = missingDesignIds.map(designId =>
    dispatch(asyncFetchDesignIfNeeded({ designId }))
  );
  return Promise.all(designPromises);
};
