const tourProvider = {};

const setupUser = user => {
  if (!window.inlineManualTracking) {
    window.inlineManualTracking = {
      uid: user.id,
      email: user.email,
      name: user.name,
      campaignreference: user.cid,
      created: Math.floor(Date.parse(user.createdAt) / 1000),
      updated: Math.floor(Date.parse(user.updatedAt) / 1000)
    };
  }
};

const startPlayer = () => {
  if (!process.env.REACT_APP_INLINE_MANUAL_API_KEY) return;

  // The script below is the script provided by inlineManual
  // https://help.inlinemanual.com/docs/single-page-app-and-people-tracking-angular-react-ember
  /* eslint-disable */
  !(function() {
    var e = document.createElement("script"),
      t = document.getElementsByTagName("script")[0];
    (e.async = 1),
      (e.src = `https://inlinemanual.com/embed/player.${
        process.env.REACT_APP_INLINE_MANUAL_API_KEY
      }.js`),
      (e.charset = "UTF-8"),
      t.parentNode.insertBefore(e, t);
  })();
  /* eslint-enable */
};

tourProvider.trackUser = (user, overwrite = false) => {
  if (window.inlineManualTracking && !overwrite) return;

  setupUser(user);
  startPlayer();
};

export default tourProvider;
