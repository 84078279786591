export const TEAM_IMAGES_REQUEST = "API/ENTITIES/TEAM_IMAGES_REQUEST";
export const TEAM_IMAGES_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_IMAGES_REQUEST_FAILURE";
export const TEAM_IMAGES_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_IMAGES_REQUEST_SUCCESS";

export const TEAM_DELETE_IMAGE_REQUEST =
  "API/ENTITIES/TEAM_DELETE_IMAGE_REQUEST";
export const TEAM_DELETE_IMAGE_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_DELETE_IMAGE_REQUEST_FAILURE";
export const TEAM_DELETE_IMAGE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_DELETE_IMAGE_REQUEST_SUCCESS";

export const TEAM_DELETE_LOGO_REQUEST = "API/ENTITIES/TEAM_DELETE_LOGO_REQUEST";
export const TEAM_DELETE_LOGO_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_DELETE_LOGO_REQUEST_FAILURE";
export const TEAM_DELETE_LOGO_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_DELETE_LOGO_REQUEST_SUCCESS";
