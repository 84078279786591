import * as types from "./billingDetailsTypes";

export const initState = {};

const billingDetailsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_BILLING_DETAILS_FOR_ORDER_REQUEST_SUCCESS:
    case types.FETCH_BILLING_DETAILS_FOR_CURRENT_ORDER_REQUEST_SUCCESS: {
      const { entities } = action.response;

      if (!entities) return state;

      return {
        ...state,
        ...entities.billingDetails
      };
    }

    case types.UPDATE_BILLING_DETAILS_FOR_ORDER_REQUEST_SUCCESS: {
      const orderId = action.request.body.orderId;
      const {
        entities: {
          billingDetails: { [orderId]: billingDetails }
        }
      } = action.response;

      return {
        ...state,
        [orderId]: billingDetails
      };
    }

    case types.CREATE_BILLING_DETAILS_FOR_ORDER_REQUEST_SUCCESS: {
      const orderId = action.request.body.orderId;
      const {
        entities: {
          billingDetails: { [orderId]: billingDetails }
        }
      } = action.response;

      return {
        ...state,
        [orderId]: billingDetails
      };
    }

    default:
      return state;
  }
};

export default billingDetailsReducer;
