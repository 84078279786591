import landscape_tv from "./landscape_tv.png";
import leaderboard_728 from "./leaderboard_728.png";
import large_rectangle_336 from "./large_rectangle_336.png";
import medium_rectangle_300 from "./medium_rectangle_300.png";
import a4_portrait from "./a4_portrait.png";
import a5_portrait from "./a5_portrait.png";
import presentation from "./presentation.png";
import ebooks_covers from "./ebooks_covers.png";
import dl_landscape_flyer from "./dl_landscape_flyer.png";
import a5_landscape from "./a5_landscape.png";
import facebook_post_3 from "./facebook_post_3.jpg";
import facebook_cover_2 from "./facebook_cover_2.png";
import facebook_cover_2021 from "./facebook_cover.png";
import facebook_cover from "./facebook_cover.png";
import facebook_advert from "./facebook_avert.png";
// replaced by mp4 thumbnail
// import instagram_portrait from "./instagram_portrait.jpg";
import twitter_image from "./twitter_image_1024x512.png";
import youtube_thumbnail from "./youtube_thumbnail.png";
import linkedin_cover_company from "./linkedin_cover_company.png";
import facebook_event_cover from "./facebook_event_cover.png";
import pinterest_tall from "./pinterest_tall.png";
import wide_skyscraper from "./wide_skyscraper.jpg";
import custom from "./custom.jpg";
import us_half_letter from "./Half-Letter-5.5-x-8.5in.png";
import twitter_image1200x675 from "./Twitter-Cover-1200-x-675px-.png";
import facebook_post from "./Facebook-Post-940x788px.png";
import twitter_cover from "./Twitter-Cover-1200-x-675px-.png";
import us_letter from "./Letter-8.5-x-11in.png";
import us_letter_landscape from "./us_letter_landscape.png";
import youtube_channel_art from "./Youtube_Channel_Art.png";
import a3_landscape from "./a3_landscape.png";
import a4_landscape from "./a4_landscape.png";
import a6_postcard_landscape from "./a6_postcard_landscape.png";
import a6_postcard_portrait from "./a6_postcard_portrait.png";

// updated design size thumbnails 2022
import banner_96x36in from "./banner_96x36in.png";
import banner_3000x1250mm from "./banner_3000x1250mm.png";
import business_card_landscape_3_5x2in from "./business_card_landscape_3.5x2in.png";
import business_card_landscape_90x55mm from "./business_card_landscape_90x55mm.png";
import business_card_portrait_2x3_5in from "./business_card_portrait_2x3.5in.png";
import business_card_portrait_55x90mm from "./business_card_portrait_55x90mm.png";
import christmas_card_feature from "./christmas_card_feature.mp4";
import australia_day_feature from "./australia_day_feature.png";
import dl_flyer_portrait_99x210mm_ from "./dl_flyer_portrait_99x210mm_.png";
import instagram_post_1080x1080px from "./instagram_post_1080x1080px.png";
// replaced by mp4 thumbnail
// import landscape_nightlife_1920x1080px from "./landscape_nightlife_1920x1080px.png";
import landscape_screen_1920x1080px from "./landscape_screen_1920x1080px.png";
import pinterest_mid_735x1500px from "./pinterest_mid_735x1500px.png";
import pinterest_short_600x900px from "./pinterest_short_600x900px.png";
import postcard_landscape_6x4in from "./postcard_landscape_6x4in.png";
import postcard_portrait_4x6in from "./postcard_portrait_4x6in.png";
import poster_18x24in from "./poster_18x24in.png";
import poster_420x594mm from "./poster_420x594mm.png";
import presentation_1920x1080px from "./presentation_(169)_1920x1080px.png";
import rack_card_portrait_4x9in from "./rack_card_portrait_4x9in.png";
import a6_greeting_card_landscape from "./a6_folding_card_landscape.png";
import a6_greeting_card_portrait from "./a6_folding_card_portrait.png";
import greeting_card_portrait from "lib/templateSizesThumbnails/greeting_card_portrait.png";
import greeting_card_landscape from "lib/templateSizesThumbnails/greeting_card_landscape.png";
// replaced by mp4 thumbnail
// import story_1080x1920px from "./story_1080x1920px.png";
import twitter_header_1500x500px from "./twitter_header_1500x500px.png";
import video_template_landscape_nightlife_hd from "./video_template_landscape_nightlife_hd.mp4";
import video_instagram_portrait from "./video_instagram_portrait.mp4";
import video_instagram_stories from "./video_instagram_stories.mp4";

const templateSizesThumbnailsCommon = {
  a6_greeting_card_landscape: a6_greeting_card_landscape, // Folding card that folds portrait -> landscape
  a6_greeting_card_portrait: a6_greeting_card_portrait, // Folding card that folds landscape -> portrait
  businesscardl: business_card_landscape_90x55mm,
  christmas_card_feature: christmas_card_feature,
  australia_day_feature: australia_day_feature,
  facebook_post_3: facebook_post_3,
  greeting_card_portrait: greeting_card_landscape, // Folding card that folds portrait -> landscape
  greeting_card_landscape: greeting_card_portrait, // Folding card that folds landscape -> portrait
  instagram_portrait: video_instagram_portrait,
  instagram1080: instagram_post_1080x1080px,
  instagram_stories: video_instagram_stories,
  landscape_tv_full_hd: landscape_screen_1920x1080px,
  pinterest_short_2: pinterest_short_600x900px,
  poster: poster_420x594mm,
  us_letter: us_letter,
  us_letter_landscape: us_letter_landscape,
  businesscardp: business_card_portrait_55x90mm,
  landscape_tv: landscape_tv,
  landscape_nightlife_hd: video_template_landscape_nightlife_hd,
  banner3001250_2: banner_3000x1250mm,
  leaderboard_728: leaderboard_728,
  large_rectangle_336: large_rectangle_336,
  medium_rectangle_300: medium_rectangle_300,
  a4_portrait: a4_portrait,
  a4_landscape: a4_landscape,
  a5_portrait: a5_portrait,
  presentation_16_9: presentation_1920x1080px,
  presentation: presentation,
  ebooks_covers: ebooks_covers,
  dl_landscape_flyer: dl_landscape_flyer,
  dl_portrait_flyer: dl_flyer_portrait_99x210mm_,
  a3_landscape: a3_landscape,
  a5_landscape: a5_landscape,
  a6_postcard_landscape: a6_postcard_landscape,
  a6_postcard_portrait: a6_postcard_portrait,
  facebook_cover_2: facebook_cover_2,
  facebook_cover_2021: facebook_cover_2021,
  facebook_cover: facebook_cover,
  facebook_advert: facebook_advert,
  twitter_image: twitter_image,
  youtube_channel_art: youtube_channel_art,
  youtube_thumbnail: youtube_thumbnail,
  linkedin_cover_company: linkedin_cover_company,
  pinterest_short: pinterest_mid_735x1500px,
  twitter_header: twitter_header_1500x500px,
  facebook_event_cover: facebook_event_cover,
  pinterest_tall: pinterest_tall,
  custom: custom,
  wide_skyscraper: wide_skyscraper,

  //TODO: set these to appropriate thumbs
  poster457610: poster_18x24in,
  banner2438914: banner_96x36in,
  businesscard192336: business_card_portrait_2x3_5in,
  businesscard336192: business_card_landscape_3_5x2in,
  rack_card: rack_card_portrait_4x9in,
  us_half_letter: us_half_letter,
  twitter_image1200x675: twitter_image1200x675,
  facebook_post: facebook_post,
  postcard_landscape: postcard_landscape_6x4in,
  postcard_portrait: postcard_portrait_4x6in,
  twitter_cover: twitter_cover
};

const templateSizesThumbnailsUS = {};

const templateSizesThumbnails = {
  AU: {
    ...templateSizesThumbnailsCommon
  },
  US: {
    ...templateSizesThumbnailsCommon,
    ...templateSizesThumbnailsUS
  }
};

export default templateSizesThumbnails;
