import * as types from "../../entities/imageFolders/imageFoldersTypes";

import { parseClientErrorMessages } from "lib";

export const initState = {
  visibleModal: null,
  isSubmitting: false,
  isSuccess: false,
  errors: []
};

const createImageFolderReducers = (state = initState, action) => {
  switch (action.type) {
    case types.CREATE_IMAGE_FOLDER_REQUEST: {
      return {
        ...state,
        errors: [],
        isSuccess: false,
        isSubmitting: true
      };
    }

    case types.CREATE_IMAGE_FOLDER_REQUEST_SUCCESS: {
      return {
        ...state,
        visibleModal: null,
        errors: [],
        isSuccess: true,
        isSubmitting: false
      };
    }

    case types.CREATE_IMAGE_FOLDER_REQUEST_FAILURE: {
      const { error } = action;
      const errors = parseClientErrorMessages(error);

      return {
        ...state,
        errors,
        isSubmitting: false,
        isSuccess: false
      };
    }

    default:
      return state;
  }
};

export default createImageFolderReducers;
