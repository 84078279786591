import PATHS from "routes/paths";

export const tabs = () => ({
  Templates: {
    title: "Templates",
    url: PATHS.catalogueTemplates,
    altUrls: [PATHS.catalogueFolders]
  },
  Archived: {
    title: "Archived",
    url: PATHS.catalogueArchived
  }
});
export const easilTabs = () => ({
  Templates: {
    title: "Templates",
    url: PATHS.catalogueEasil
  },
  Archived: {
    title: "Purchased",
    url: PATHS.cataloguePurchased
  }
});
