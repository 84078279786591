import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CrossThinIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 14}
    height={height || size || 14}
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-5-5h24v24H-5z" />
      <path
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 7l6-6-6 6-6-6 6 6zm0 0l-6 6 6-6 6 6-6-6z"
      />
    </g>
  </svg>
);
CrossThinIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CrossThinIcon;
