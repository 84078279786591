import React, { Component } from "react";
import style from "./style.module.css";
import { Loading } from "views/components";
import SmartTextOverrideRow from "views/components/SmartText/SmartTextOverrideRow";
import SmartTextRow from "views/components/SmartText/SmartTextRow";
import InfoAlert from "views/components/InfoAlert/InfoAlert";
import { renderLearnMoreLink } from "lib/smartTextUtils";
import EmptySearchIcon from "views/components/icons/EmptySearchIcon";
import UpdateTeamSmartTextModalContainer from "./modals/UpdateTeamSmartTextModalContainer";
import BrandKitEmpty from "../BrandKitEmpty/BrandKitEmpty";
import confirmDeleteSmartText from "views/components/SmartText/confirmDeleteSmartText";

/**
 * Brand kit specific smart text list. This componenet displays the smart text content within the brand kit
 */
export default class BrandKitSmartText extends Component {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onCancelDelete = this.onCancelDelete.bind(this);
    this.getSearchArr = this.getSearchArr.bind(this);

    this.state = {
      isUpdateModalOpen: false,
      selectedRow: null
    };
  }

  getSearchArr(term) {
    const { smartTextSearchArr } = this.props;
    const smartTextArray = smartTextSearchArr;

    if ((term || "").trim().length) {
      const regExp = new RegExp(term, "i");
      return smartTextArray.filter(
        smartText => regExp.test(smartText.name) || regExp.test(smartText.value)
      );
    } else {
      return smartTextArray;
    }
  }

  componentDidMount() {
    const { smartText, fetchTeamSmartText } = this.props;
    if (!smartText || !smartText.length) {
      fetchTeamSmartText();
    }
  }

  onClickDelete() {
    const { deleteSmartText } = this.props;

    deleteSmartText(this.state.selectedRow.name);
  }

  onCancelDelete() {
    this.setState({ selectedRow: null });
  }

  handleDelete(smartText) {
    confirmDeleteSmartText(this.onClickDelete, this.onCancelDelete, smartText);
  }

  selectRow(smartText) {
    this.setState({ selectedRow: smartText });
  }

  handleOpenModal() {
    this.setState({
      isUpdateModalOpen: true
    });
  }

  handleCloseModal() {
    this.setState({
      isUpdateModalOpen: false,
      selectedRow: null
    });
  }

  render() {
    const {
      overrideSmartText,
      smartText,
      isFetchingSmartText,
      location
    } = this.props;
    const { isUpdateModalOpen } = this.state;

    const urlParams = new URLSearchParams(location.search);
    const urlTerm = urlParams.get("term");
    const smartTextSearchArray = this.getSearchArr(urlTerm);
    const isSearching = !isFetchingSmartText && urlTerm !== null;

    return (
      <>
        <div
          className={style.brandKitSmartTextWrapper}
          data-testid="brandkitSmartTextWrapper"
        >
          <div className={style.contentTitle}>Smart Text</div>
          <InfoAlert
            className={style.info}
            theme={"blue"}
            hasIcon={true}
            spanClass={style.infoText}
          >
            Smart text saves you time by automatically updating regularly used
            text. {renderLearnMoreLink()}
          </InfoAlert>

          {isSearching ? (
            smartTextSearchArray.map(text => {
              return text.hasOwnProperty("overridden") && !text.overridden ? (
                <SmartTextOverrideRow
                  smartText={text}
                  handleOpenModal={this.handleOpenModal}
                  selectRow={this.selectRow}
                />
              ) : (
                <SmartTextRow
                  smartText={text}
                  handleOpenModal={this.handleOpenModal}
                  handleDelete={this.handleDelete}
                  selectRow={this.selectRow}
                />
              );
            })
          ) : (
            <>
              {overrideSmartText.map(overrideText => (
                <SmartTextOverrideRow
                  smartText={overrideText}
                  handleOpenModal={this.handleOpenModal}
                  selectRow={this.selectRow}
                />
              ))}
              {smartText.map(text => (
                <SmartTextRow
                  smartText={text}
                  handleOpenModal={this.handleOpenModal}
                  handleDelete={this.handleDelete}
                  selectRow={this.selectRow}
                />
              ))}
            </>
          )}
        </div>
        {isFetchingSmartText && (
          <div className={style.loader}>
            <Loading size="24px" />
          </div>
        )}
        {isSearching && !smartTextSearchArray.length && (
          <BrandKitEmpty
            icon={<EmptySearchIcon height="43px" width="56px" />}
            subtitle="Try searching for another Smart Text, or clearing your search."
            title={
              <>
                No Smart Text could be found for '<b>{urlTerm}</b>'.
              </>
            }
          />
        )}
        {isUpdateModalOpen && (
          <UpdateTeamSmartTextModalContainer
            isOpen={isUpdateModalOpen}
            onCloseModal={this.handleCloseModal}
            selectedRow={this.state.selectedRow}
          />
        )}
      </>
    );
  }
}
