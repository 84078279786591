import * as types from "./userTeamFontsTypes";
import { formatErrors } from "lib";

export const initState = {
  isProcessing: false,
  isSuccess: false,
  isRemoving: false,
  errors: []
};

const errorsArray = error => formatErrors(error);

const userTeamFontsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USER_UPLOAD_FONT_REQUEST: {
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
        isRemoving: false,
        errors: []
      };
    }

    case types.USER_UPLOAD_FONT_REQUEST_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        isRemoving: false,
        errors: []
      };
    }

    case types.USER_UPLOAD_FONT_REQUEST_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        isRemoving: false,
        errors
      };
    }

    case types.USER_TEAM_FONT_REMOVE_REQUEST: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        isRemoving: true,
        errors: []
      };
    }

    case types.USER_TEAM_FONT_REMOVE_REQUEST_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        isRemoving: false,
        errors: []
      };
    }

    case types.USER_TEAM_FONT_REMOVE_REQUEST_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        isRemoving: false,
        errors
      };
    }

    default:
      return state;
  }
};

export default userTeamFontsReducers;
