import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const BellIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 12 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-4-2h20v20H-4z" />
      <path d="M-2 0h16v16H-2z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M3.808 14L7.295 2H6.5a1 1 0 1 1 0-2h4a1 1 0 0 1 0 2H9.377L5.891 14H7.7a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2h2.108z"
      />
    </g>
  </svg>
);
BellIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default BellIcon;
