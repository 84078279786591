import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import { getHtmlNodeCenter } from "lib/htmlElements/dimensions";
import { rotatePoint } from "lib/geometry/rotation";
import style from "../styleSelectionBox.module.css";

/**
 * Implements the drag source contract.
 */
const TextMaskResizeHandlerSource = {
  beginDrag(props, monitor, component) {
    props.startCropResizing({ pageId: props.selectedItems[0].pageId });

    const componentNode = findDOMNode(component);
    const selectionBoxNode = componentNode.parentElement;

    const componentNodeCenter = getHtmlNodeCenter(componentNode);
    const selectionBoxNodeCenter = getHtmlNodeCenter(selectionBoxNode);
    const selectionBoxBounding = selectionBoxNode.getBoundingClientRect();

    const anchorPoint = rotatePoint(
      componentNodeCenter.x,
      componentNodeCenter.y,
      selectionBoxNodeCenter.x,
      selectionBoxNodeCenter.y,
      180
    );

    return {
      handlerInitialPosition: componentNodeCenter,
      anchorPoint: anchorPoint,
      selectionBoxNodeCenter,
      selectionBoxBounding
    };
  },

  endDrag(props, monitor, component) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();
    const dragItem = monitor.getItem();

    props.finishCropResizing({
      dragItem,
      differenceFromInitialOffset
    });
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset()
  };
}

class TextMaskResizeHandler extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;

    if (connectDragPreview) {
      // Use empty image as a drag preview so browsers don't draw it
      // and we can draw whatever we want on the custom drag layer instead.
      connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true
      });
    }
  }

  render() {
    const { connectDragSource, position } = this.props;

    return connectDragSource(
      <div className={style.dotWrapper} data-position={position}>
        <div className={style.dot} />
      </div>
    );
  }
}

const TextMaskResizeHandlerDraggable = DragSource(
  "TEXT_MASK_RESIZE_HANDLER",
  TextMaskResizeHandlerSource,
  collect
)(TextMaskResizeHandler);

const TextMaskResizeHandlerDefault = props => {
  return (
    <TextMaskResizeHandlerDraggable
      {...props}
      startCropResizing={props.startTextMaskCropResizing}
      finishCropResizing={props.finishTextMaskCropResizing}
      selectedItems={props.selectedItems}
      getElement={props.designData.getElement.bind(props.designData)}
      zoom={props.zoom}
      designData={props.designData}
    />
  );
};

export default TextMaskResizeHandlerDefault;
