import React, { Component } from "react";
import PropTypes from "prop-types";
import { Empty } from "views/components";
import CaretIcon from "views/components/icons/CaretIcon";
import { isEmpty } from "lib";
import style from "./style.module.css";
import TeamDefaultChooserPopoutContainer from "./TeamDefaultChooserPopout";

class TeamDefaultChooser extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();

    this.state = {
      isOpen: false,
      isSearching: false,
      searchTerm: ""
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.onOptionSelection = this.onOptionSelection.bind(this);
    this.isRefSet = this.isRefSet.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.defaultTeamId !== prevProps.defaultTeamId &&
      !this.props.currentDefaultTeam
    ) {
      this.props.fetchTeam({ teamID: this.props.defaultTeamId });
    }

    if (prevState.isOpen && !this.state.isOpen) {
      // popout was closed so we should clear state
      this.props.clearMenuState();
    }
  }

  isRefSet() {
    return this.wrapperRef && this.wrapperRef.current;
  }

  handleClickOutside(event) {
    if (!this.isRefSet()) {
      return;
    }

    if (
      !this.wrapperRef.current.contains(event.target) &&
      event.target.isConnected
    ) {
      this.setState({ isOpen: false });
    }
  }

  toggleDropdown() {
    this.setState({ isOpen: !this.state.isOpen }, scrollToTop);

    function scrollToTop() {
      if (
        this.state.isOpen &&
        this.isRefSet() &&
        this.wrapperRef.current.scrollIntoView
      ) {
        this.wrapperRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }
    }
  }

  onOptionSelection(teamId) {
    this.setState({ isOpen: false, isSearching: false, searchTerm: "" });
    this.props.onSetDefaultTeam({ defaultTeamId: teamId });
  }

  handleSearchChange(value) {
    const searchString = value;

    this.setState({ isSearching: false, searchTerm: searchString });
  }

  onSearchKeyPress(event) {
    if (event.key !== "Enter") {
      return;
    }

    const searchString = event.target.value.trim();

    if (!isEmpty(searchString)) {
      this.setState({ isSearching: true, searchTerm: searchString });
      this.props.onSearchTeams({ name: searchString, page: 1 });
    }
  }

  render() {
    if (!this.props.currentDefaultTeam) {
      return <Empty width="300px" height="40px" />;
    }

    const { className } = this.props;

    return (
      <div
        ref={this.wrapperRef}
        className={`${style.wrapper} ${className}`}
        data-testid="TeamDefaultChooser"
      >
        <div className={style.dropDownTop} onClick={this.toggleDropdown}>
          <span className={`${style.currentDefaultTeam} notranslate`}>
            {this.props.currentDefaultTeam.name}
          </span>
          <CaretIcon direction="down" size="8px" />
        </div>
        {this.state.isOpen && (
          <TeamDefaultChooserPopoutContainer
            teams={this.props.teams}
            defaultTeam={this.props.currentDefaultTeam}
            onOptionSelection={this.onOptionSelection}
            isSearching={this.state.isSearching}
            searchTerm={this.state.searchTerm}
            teamsSearch={this.props.teamsSearch}
            onSearchKeyPress={this.onSearchKeyPress}
            onSearchChange={this.handleSearchChange}
          />
        )}
      </div>
    );
  }
}

TeamDefaultChooser.propTypes = {
  currentDefaultTeam: PropTypes.object,
  teams: PropTypes.object,
  teamsSearch: PropTypes.array,
  onSearchTeams: PropTypes.func.isRequired,
  onSetDefaultTeam: PropTypes.func.isRequired
};

export default TeamDefaultChooser;
