import * as cartTypes from "./cartTypes";
import * as orderTypes from "state/entities/orders/ordersTypes.js";
import * as billingDetailsTypes from "state/entities/billingDetails/billingDetailsTypes";
import * as shippingDetailsTypes from "state/entities/shippingDetails/shippingDetailsTypes";
import { CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS } from "state/currentUser/currentUserTypes";
import {
  MANDATORY_BILLING_FIELDS,
  MANDATORY_SHIPPING_FIELDS,
  ADDRESS_FIELDS
} from "lib/constants";
import { some, uniq, isEmpty, formatErrors, omit } from "lib";
import { LOCATION_CHANGE } from "react-router-redux";
import PATHS from "routes/paths";

const countryDefault = "AU";
const stateDefault = "QLD";
const addressTypeDefault = "BUSINESS";

export const initState = {
  loading: {
    // all loading states should start as true
    order: true,
    details: true,
    pricing: true,
    billing: true,
    shipping: true,
    shippingRates: true,
    sizes: true
  },
  orderId: null,
  billing: {
    country: "AU",
    state: "QLD"
  },
  shipping: {
    country: "AU",
    state: "QLD",
    addressType: "BUSINESS"
  },
  token: null,
  paymentType: "credit",
  errors: [
    // VV of style VV
    // {
    //   type: "billing",
    //   description: "field must be filled",
    //   fields: ["name"]
    // }
  ]
};

const cartReducers = (state = initState, action) => {
  switch (action.type) {
    // Team Switch Action
    case CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS: {
      // Clear the state to init when changing teams
      return { ...initState };
    }

    case cartTypes.UI_CART_SET_CURRENT_ORDER_ID: {
      return {
        ...state,
        orderId: action.orderId
      };
    }

    case cartTypes.UI_CART_SET_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.loading
        }
      };
    }

    case cartTypes.UI_CART_SET_BILLING: {
      const { errors } = state;
      const {
        billingUpdate: { key, value }
      } = action;

      const updatedRelatedErrors = errors.map(relatedError => ({
        ...relatedError,
        fields: relatedError.fields.filter(field => field !== key)
      }));

      const updatedErrors = [...updatedRelatedErrors].filter(
        error => error.fields.length
      );

      const updatedBilling = {
        ...state.billing,
        [key]: value
      };

      const mandatoryValuesExist = MANDATORY_BILLING_FIELDS.map(
        key => updatedBilling[key] && updatedBilling[key] !== ""
      );

      const isValid = !some(mandatoryValuesExist, value => !value);

      return {
        ...state,
        billing: {
          ...updatedBilling,
          isValid
        },
        errors: updatedErrors
      };
    }

    case cartTypes.UI_CART_SET_SHIPPING: {
      const { errors } = state;
      const {
        shippingUpdate: { key, value: incomingValue }
      } = action;

      const nonEmptyKeys = ["notes", "attention"];
      // notes currently can not be empty string so we need to set it to null if this is the case
      const value =
        nonEmptyKeys.includes(key) && incomingValue === ""
          ? null
          : incomingValue;

      const updatedRelatedErrors = errors.map(relatedError => ({
        ...relatedError,
        fields: relatedError.fields.filter(field => field !== key)
      }));

      const updatedErrors = [...errors, ...updatedRelatedErrors].filter(
        error => error.fields.length
      );

      const updatedShipping = {
        ...state.shipping,
        [key]: value
      };

      const mandatoryValuesExist = MANDATORY_SHIPPING_FIELDS.map(
        key => updatedShipping[key] && updatedShipping[key] !== ""
      );

      const isValid = !some(mandatoryValuesExist, value => !value);

      return {
        ...state,
        shipping: {
          ...updatedShipping,
          isValid
        },
        errors: updatedErrors
      };
    }

    case cartTypes.UI_CART_SHIPPING_SAME_AS_BILLING: {
      const { billing, shipping } = state;

      const updatedShipping = { ...shipping };

      ADDRESS_FIELDS.forEach(key => {
        updatedShipping[key] = billing[key];
      });

      return {
        ...state,
        shipping: updatedShipping
      };
    }

    case billingDetailsTypes.FETCH_BILLING_DETAILS_FOR_CURRENT_ORDER_REQUEST_SUCCESS: {
      if (isEmpty(action.response)) {
        return {
          ...state,
          billing: {
            ...state.billing,
            country: state.billing.country || countryDefault,
            state: state.billing.state || stateDefault
          }
        };
      }

      const orderId = action.response.ids;
      const {
        entities: {
          billingDetails: { [orderId]: billingDetails }
        }
      } = action.response;

      return {
        ...state,
        billing: {
          ...billingDetails,
          isValid: true
        }
      };
    }

    case billingDetailsTypes.UPDATE_BILLING_DETAILS_FOR_ORDER_REQUEST_FAILURE: {
      // update failed, get the error and put it in state
      const { errors } = action.error;

      const errorTypes = uniq(errors.map(error => error.type));

      const newErrors = errorTypes.map(errorType => ({
        type: "billing",
        description: `is ${errorType}`,
        fields: errors
          .filter(error => error.type === errorType)
          .map(error => error.path)
      }));

      return {
        ...state,
        errors: [...state.errors, ...newErrors]
      };
    }

    case shippingDetailsTypes.FETCH_SHIPPING_DETAILS_FOR_CURRENT_ORDER_REQUEST_SUCCESS: {
      if (isEmpty(action.response)) {
        return {
          ...state,
          shipping: {
            ...state.shipping,
            country: state.shipping.country || countryDefault,
            state: state.shipping.state || stateDefault,
            addressType: state.shipping.addressType || addressTypeDefault
          }
        };
      }

      const orderId = action.response.ids;
      const {
        entities: {
          shippingDetails: { [orderId]: shippingDetails }
        }
      } = action.response;

      return {
        ...state,
        shipping: {
          ...shippingDetails,
          isValid: true
        }
      };
    }

    case LOCATION_CHANGE: {
      // When we leave cart page, return loading state
      if (
        action.pathname &&
        PATHS.isCartPage(action.pathname) &&
        !PATHS.isCartPage(action.payload.pathname)
      ) {
        return {
          ...state,
          loading: initState.loading
        };
      }
      return state;
    }

    case cartTypes.UI_CART_SET_TOKEN: {
      const { token } = action;

      return {
        ...state,
        token
      };
    }

    case cartTypes.UI_CART_SET_PAYMENT_TYPE: {
      const { paymentType } = action;

      return {
        ...state,
        paymentType
      };
    }

    case orderTypes.ADD_COUPON_TO_ORDER_REQUEST_FAILURE: {
      const { error } = action;
      const couponErrors = formatErrors(error);

      return {
        ...state,
        couponErrors
      };
    }

    case cartTypes.UI_CART_SET_COUPON_ERROR: {
      const { couponErrors } = state;

      if (!couponErrors) return state;

      return omit(state, "couponErrors");
    }

    case cartTypes.UI_CART_SET_SHIPPING_DEFAULTS: {
      const { defaultShipping } = action;

      return {
        ...state,
        shipping: {
          // allow these values to be overwritten if they are already filled
          ...defaultShipping,
          ...state.shipping
        }
      };
    }

    case cartTypes.UI_CART_CLEAR: {
      return initState;
    }

    case orderTypes.CREATE_PAYPAL_ORDER_REQUEST_SUCCESS: {
      return {
        ...state,
        paypalOrderId: action.response.paypalOrderId
      };
    }

    default:
      return state;
  }
};

export default cartReducers;
