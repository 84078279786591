import * as types from "./designFoldersTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { fetchDesignsIfNeeded } from "../designs/designsActions";
import {
  changeWorkspaceFoldersDesignsFilters,
  changeWorkspaceDesignsFilters,
  changeTeamCatalogueDesignsFilters,
  changeCatalogueFoldersDesignsFilters
} from "state/ui/designsPage/designsPageActions";
import {
  designsSelector,
  designsByCollectionId
} from "state/entities/designs/designsSelectors";
import { isInFolder } from "lib/locationHelpers";
import PATHS from "routes/paths";
import { noop } from "lib";

const emptyFilters = section => {
  return {
    term: "",
    categoryId: "",
    section: section,
    size: ""
  };
};

export const fetchFolderForDesign = ({ designId }) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.FETCH_FOLDER_FOR_DESIGN_REQUEST,
        types.FETCH_FOLDER_FOR_DESIGN_REQUEST_SUCCESS,
        types.FETCH_FOLDER_FOR_DESIGN_REQUEST_FAILURE
      ],
      endpoint: `/designs/${designId}/folder`,
      schema: schemas.DESIGN_FOLDER,
      extra: {
        designId
      }
    }
  });
};

export const addContentToFolder = ({
  contentId,
  teamId,
  type,
  userId,
  folderId,
  onSuccess = noop
}) => dispatch => {
  if (!contentId) {
    throw new Error("Invalid content ID.");
  }

  if (!type) {
    throw new Error("Invalid type.");
  }

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      types: [
        types.ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST,
        types.ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST_SUCCESS,
        types.ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content`,
      request: {
        body: {
          contentId,
          folderId,
          teamId,
          type,
          userId
        }
      },
      schema: schemas.NONE,
      onSuccess
    }
  });
};

export const addBulkCollectionsToFolder = ({
  collectionIds,
  userId,
  teamId,
  folderId,
  onSuccess = noop,
  onFailure = noop
}) => (dispatch, getState) => {
  if (!Array.isArray(collectionIds)) {
    throw new Error("Invalid collectionIds. Must be an array");
  }

  const state = getState();
  const designsInCollection = collectionIds.reduce(
    (designsAccumulator, collectionId) => {
      const designsForCollection = designsByCollectionId({
        state,
        collectionId
      });

      return [...designsAccumulator, ...designsForCollection];
    },
    []
  );

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST,
        types.MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST_SUCCESS,
        types.MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/collections`,
      request: {
        body: {
          collectionIds,
          folderId,
          teamId,
          userId
        }
      },
      extra: {
        designsInCollection
      },
      schema: schemas.NONE,
      onSuccess: () => onSuccess(),
      onFailure: () => onFailure()
    }
  });
};

export const addContentToTeamFolder = ({
  contentId,
  folderId,
  onSuccess,
  teamId,
  type = "DESIGN",
  isFolderUpload
}) => {
  if (!contentId) {
    throw new Error("Invalid content ID.");
  }

  return {
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      types: [
        types.ADD_CONTENT_TO_TEAM_FOLDER_REQUEST,
        types.ADD_CONTENT_TO_TEAM_FOLDER_SUCCESS,
        types.ADD_CONTENT_TO_TEAM_FOLDER_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders/${folderId}/content`,
      onSuccess,
      request: {
        body: {
          contentId,
          teamId,
          type,
          folderId
        }
      },
      extra: {
        isFolderUpload
      },
      schema: schemas.NONE
    }
  };
};

export const removeContentFromFolder = ({
  contentId,
  teamId,
  userId,
  folderId,
  filters,
  onSuccess = noop
}) => (dispatch, getState) => {
  if (!contentId) {
    throw new Error("Invalid content ID.");
  }

  const state = getState();
  const designsInCollection = designsByCollectionId({
    state,
    collectionId: contentId
  });

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST,
        types.REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST_SUCCESS,
        types.REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content/${contentId}`,
      extra: {
        contentId,
        designsInCollection
      },
      schema: schemas.NONE,
      onSuccess: _response => {
        onSuccess();
        dispatch(
          fetchDesignsIfNeeded({
            filters,
            resource: "/designs/workspace/drafts",
            shouldClearPages: true
          })
        );
      }
    }
  });
};

export const removeContentFromTeamFolder = ({
  contentId,
  filters,
  folderId,
  onSuccess = noop,
  resource,
  teamId
}) => (dispatch, getState) => {
  if (!contentId) {
    throw new Error("Invalid collection ID.");
  }

  const state = getState();
  const designsInCollection = designsByCollectionId({
    state,
    collectionId: contentId
  });

  return dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.REMOVE_CONTENT_FROM_TEAM_FOLDER_REQUEST,
        types.REMOVE_CONTENT_FROM_TEAM_FOLDER_SUCCESS,
        types.REMOVE_CONTENT_FROM_TEAM_FOLDER_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders/${folderId}/content/${contentId}`,
      extra: {
        contentId,
        folderId,
        designsInCollection
      },
      schema: schemas.NONE,
      onSuccess: _response => {
        if (filters && resource) {
          dispatch(
            fetchDesignsIfNeeded({
              filters,
              resource,
              shouldClearPages: true
            })
          );
        }

        onSuccess();
      }
    }
  });
};

export const handleEmptyFilter = ({ filteredDesigns }) => (
  dispatch,
  getState
) => {
  const section = PATHS.isWorkspace(window.location.pathname)
    ? "workspace"
    : "catalogue";

  const inFolderFunction =
    section === "workspace"
      ? changeWorkspaceFoldersDesignsFilters
      : changeCatalogueFoldersDesignsFilters;
  const notInFolderFunction =
    section === "workspace"
      ? changeWorkspaceDesignsFilters
      : changeTeamCatalogueDesignsFilters;

  const emptyFiltersForSection = emptyFilters(section);

  if (isInFolder(section)) {
    // check if last design in filter
    if (filteredDesigns.length < 1) {
      return dispatch(inFolderFunction(emptyFiltersForSection));
    } else {
      return;
    }
  } else {
    // check if last design in filter
    if (filteredDesigns.length < 1) {
      return dispatch(notInFolderFunction(emptyFiltersForSection));
    } else {
      return;
    }
  }
};

export const handleEmptyFilterDesign = ({ designId }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const designsState = designsSelector({ state });
  let filteredDesigns = [];
  if (designsState) {
    const { designs } = designsState;
    filteredDesigns = designs.filter(design => design.id !== designId);
  }

  return dispatch(handleEmptyFilter({ filteredDesigns }));
};

export const handleEmptyFilterCollection = ({ collectionId }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const { designs } = designsSelector({ state });
  const filteredDesigns = designs.filter(
    design => design.collectionId !== collectionId
  );

  return dispatch(handleEmptyFilter({ filteredDesigns }));
};
