import vectorProcess from "./vectorProcess";
import vectorTextProcess from "./vectorTextProcess";
import gridProcess from "./gridProcess";
import textboxProcess from "./textboxProcess";
import tableProcess from "./tableProcess";
import table2Process from "./table2Process";
import imageProcess from "./imageProcess";
import videoProcess from "./videoProcess";
import { cloneDeep } from "lib";
import { sixDigitHexColor } from "lib/Colors/colorUtils";
import { isVideo } from "lib/isVideo";
import { EDITOR_ELEMENTS_MAP } from "lib/constants";

const {
  GRID,
  IMAGE,
  VIDEO,
  VECTOR,
  TEXTBOX,
  TABLE,
  TABLE2,
  VECTOR_TEXT,
  BACKGROUND
} = EDITOR_ELEMENTS_MAP;

export const elementProcess = (element, pageGroupIds, page) => {
  const _element = cloneDeep(element);
  if (_element.color) {
    _element.color = sixDigitHexColor(_element.color);
  }

  // If an element references a group that does not exist, then we have to remove the reference from
  // the element. We're unable to add the group as we have no data apart from it's id.
  if (pageGroupIds && !!_element.groupId) {
    if (!pageGroupIds.includes(_element.groupId)) {
      _element.groupId = null;
    }
  }

  if ([IMAGE, BACKGROUND].includes(_element.type) && isVideo(_element.src)) {
    // handle video source _elements that have been incorrectly typed as an image
    _element.type = VIDEO;
  }

  switch (_element.type) {
    case IMAGE:
    case BACKGROUND: {
      return imageProcess(_element, page);
    }

    case VIDEO: {
      return videoProcess(_element, page);
    }

    case VECTOR:
      return vectorProcess(_element, page);

    case GRID:
      return gridProcess(_element, page);

    case TEXTBOX:
      return textboxProcess(_element, page);

    case VECTOR_TEXT:
      return vectorTextProcess(_element, page);

    case TABLE:
      return tableProcess(_element);

    case TABLE2:
      return table2Process(_element);

    default:
      return _element;
  }
};

export default elementProcess;
