export const FETCH_ORDERS_REQUEST = "API/ENTITIES/ORDERS/FETCH_REQUEST";
export const FETCH_ORDERS_REQUEST_SUCCESS =
  "API/ENTITIES/ORDERS/FETCH_REQUEST_SUCCESS";
export const FETCH_ORDERS_REQUEST_FAILURE =
  "API/ENTITIES/ORDERS/FETCH_REQUEST_FAILURE";

export const FETCH_ORDER_REQUEST = "API/ENTITIES/ORDER/FETCH_REQUEST";
export const FETCH_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/FETCH_REQUEST_SUCCESS";
export const FETCH_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/FETCH_REQUEST_FAILURE";

export const CREATE_ORDER_REQUEST = "API/ENTITIES/ORDER/CREATE_REQUEST";
export const CREATE_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/CREATE_REQUEST_SUCCESS";
export const CREATE_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/CREATE_REQUEST_FAILURE";

export const UPDATE_ORDER_REQUEST = "API/ENTITIES/ORDER/UPDATE_REQUEST";
export const UPDATE_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/UPDATE_REQUEST_SUCCESS";
export const UPDATE_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/UPDATE_REQUEST_FAILURE";

export const UPDATE_ORDER_COUPON_CODE_REQUEST =
  "API/ENTITIES/ORDER/UPDATE_COUPON_CODE_REQUEST";
export const UPDATE_ORDER_COUPON_CODE_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/UPDATE_COUPON_CODE_REQUEST_SUCCESS";
export const UPDATE_ORDER_COUPON_CODE_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/UPDATE_COUPON_CODE_REQUEST_FAILURE";

export const COMPLETE_ORDER_REQUEST = "API/ENTITIES/ORDER/COMPLETE_REQUEST";
export const COMPLETE_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/COMPLETE_REQUEST_SUCCESS";
export const COMPLETE_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/COMPLETE_REQUEST_FAILURE";

export const DESIGN_ORDER_DELETE_REQUEST =
  "API/ENTITIES/ORDER/DESIGN_DELETE_REQUEST";
export const DESIGN_ORDER_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/DESIGN_DELETE_REQUEST_SUCCESS";
export const DESIGN_ORDER_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/DESIGN_DELETE_REQUEST_FAILURE";

export const ADD_DESIGN_TO_ORDER_REQUEST =
  "API/ENTITIES/ORDER/ADD_DESIGN_REQUEST";
export const ADD_DESIGN_TO_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/ADD_DESIGN_REQUEST_SUCCESS";
export const ADD_DESIGN_TO_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/ADD_DESIGN_REQUEST_FAILURE";

export const UPDATE_DESIGN_IN_ORDER_REQUEST =
  "API/ENTITIES/ORDER/UPDATE_DESIGN_REQUEST";
export const UPDATE_DESIGN_IN_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/UPDATE_DESIGN_REQUEST_SUCCESS";
export const UPDATE_DESIGN_IN_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/UPDATE_DESIGN_REQUEST_FAILURE";

export const ADD_PRINT_ITEM_TO_ORDER_DESIGN_REQUEST =
  "API/ENTITIES/ORDER/DESIGN/ADD_PRINT_ITEM_REQUEST";
export const ADD_PRINT_ITEM_TO_ORDER_DESIGN_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/DESIGN/ADD_PRINT_ITEM_REQUEST_SUCCESS";
export const ADD_PRINT_ITEM_TO_ORDER_DESIGN_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/DESIGN/ADD_PRINT_ITEM_REQUEST_FAILURE";

export const UPDATE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST =
  "API/ENTITIES/ORDER/DESIGN/UPDATE_PRINT_ITEM_REQUEST";
export const UPDATE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/DESIGN/UPDATE_PRINT_ITEM_REQUEST_SUCCESS";
export const UPDATE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/DESIGN/UPDATE_PRINT_ITEM_REQUEST_FAILURE";

export const DELETE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST =
  "API/ENTITIES/ORDER/DESIGN/DELETE_PRINT_ITEM_REQUEST";
export const DELETE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/DESIGN/DELETE_PRINT_ITEM_REQUEST_SUCCESS";
export const DELETE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/DESIGN/DELETE_PRINT_ITEM_REQUEST_FAILURE";

export const CREATE_ORDER_PAYMENT_REQUEST =
  "API/ENTITIES/ORDER/PAYMENT/CREATE_REQUEST";
export const CREATE_ORDER_PAYMENT_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/PAYMENT/CREATE_REQUEST_SUCCESS";
export const CREATE_ORDER_PAYMENT_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/PAYMENT/CREATE_REQUEST_FAILURE";

export const ADD_COUPON_TO_ORDER_REQUEST =
  "API/ENTITIES/ORDER/ADD_COUPON_TO_ORDER_REQUEST";
export const ADD_COUPON_TO_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/ADD_COUPON_TO_ORDER_REQUEST_SUCCESS";
export const ADD_COUPON_TO_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/ADD_COUPON_TO_ORDER_REQUEST_FAILURE";

export const FLAG_ORDER_DESIGNS_UPDATING_VERSION =
  "API/ENTITIES/ORDER/DESIGNS/FLAG_UPDATING_VERSION";

export const CREATE_PAYPAL_ORDER_REQUEST =
  "API/ENTITIES/ORDER/PAYPAL/CREATE_REQUEST";
export const CREATE_PAYPAL_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/PAYPAL/CREATE_REQUEST_SUCCESS";
export const CREATE_PAYPAL_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/PAYPAL/CREATE_REQUEST_FAILURE";

export const ADD_COLLECTION_TO_ORDER_REQUEST =
  "API/ENTITIES/ORDER/ADD_COLLECTION_REQUEST";
export const ADD_COLLECTION_TO_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/ADD_COLLECTION_REQUEST_SUCCESS";
export const ADD_COLLECTION_TO_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/ADD_COLLECTION_REQUEST_FAILURE";

export const POLL_ORDER_REQUEST = "API/ENTITIES/ORDER/POLL_REQUEST";
export const POLL_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/POLL_REQUEST_SUCCESS";
export const POLL_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/POLL_REQUEST_FAILURE";

export const CANCEL_ORDER_REQUEST = "API/ENTITIES/ORDER/CANCEL_REQUEST";
export const CANCEL_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/ORDER/CANCEL_REQUEST_SUCCESS";
export const CANCEL_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/ORDER/CANCEL_REQUEST_FAILURE";
