import * as types from "./folderAllocationsApiTypes";
import { immutableUpdate } from "lib/immutableUpdate";
import { getPath, chunk } from "lib";

export const initState = {
  pageSize: 100,
  isFetching: false
};

const folderAllocationsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_ALL_FOLDER_ALLOCATIONS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.FETCH_ALL_FOLDER_ALLOCATIONS_SUCCESS: {
      const {
        extra: { folderId },
        response: { ids },
        request: { page }
      } = action;
      // indicate that fetching is no longer occuring and store allocation id array
      return immutableUpdate(state, {
        $auto: {
          [folderId]: {
            $auto: {
              pages: {
                $auto: {
                  [page]: {
                    $auto: {
                      $merge: {
                        ids: ids || [],
                        isFetching: false
                      }
                    }
                  }
                }
              }
            }
          },
          isFetching: { $set: ids?.length >= state.pageSize }
        }
      });
    }

    case types.ADD_FOLDER_ALLOCATIONS_SUCCESS: {
      const {
        extra: { folderId, teamIds }
      } = action;
      const folderPages = getPath(state[folderId], "pages", {});
      // new id with all other ids joined into an array
      const allIds = Object.values(folderPages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        teamIds
      );
      // split ids into chunks of page size
      const idsSplitIntoPages = chunk(allIds, state.pageSize);
      // build the pages and mark all as invalidated for future refetch
      const newPages = idsSplitIntoPages.reduce(
        (previousPages, ids, index) => ({
          ...previousPages,
          [index + 1]: {
            ids,
            didInvalidate: true
          }
        }),
        {}
      );
      // indicate that fetching is no longer occuring and store allocation id array
      return immutableUpdate(state, {
        $auto: {
          [folderId]: {
            $set: {
              pages: newPages
            }
          }
        }
      });
    }

    case types.REMOVE_FOLDER_ALLOCATIONS_REQUEST: {
      const {
        extra: { folderId, teamId }
      } = action;
      const folderPages = getPath(state[folderId], "pages", {});
      // new id with all other ids joined into an array
      const allIds = Object.values(folderPages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        []
      );
      const filteredIds = allIds.filter(id => id !== teamId);
      // split ids into chunks of page size
      const idsSplitIntoPages = chunk(filteredIds, state.pageSize);
      // build the pages and mark all as invalidated for future refetch
      const newPages = idsSplitIntoPages.reduce(
        (previousPages, ids, index) => ({
          ...previousPages,
          [index + 1]: {
            ids,
            didInvalidate: true
          }
        }),
        {}
      );

      return immutableUpdate(state, {
        $auto: {
          [folderId]: {
            $set: {
              pages: newPages
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default folderAllocationsApiReducers;
