import * as types from "./teamsTypes";
import { merge, isNil, omit } from "lib";

export const initState = {};

const teamsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.SUB_TEAMS_REQUEST_SUCCESS:
    case types.TEAMS_REQUEST_SUCCESS: {
      const { entities } = action.response;
      const { currentTeamId } = action.extra;

      if (!entities) return state;

      const { teams } = entities;
      if (teams[currentTeamId] && isNil(teams[currentTeamId].logoUrl)) {
        // when we get back the current team and there is a null logoUrl we want to clear it so that the value is not changed
        teams[currentTeamId] = omit(teams[currentTeamId], "logoUrl");
      }

      return merge({}, state, teams);
    }

    case types.TEAMS_SEARCH_REQUEST_SUCCESS: {
      if (!action.response || !action.response.entities) return state;
      const { entities } = action.response;
      return merge({}, state, entities.teams);
    }

    case types.TEAM_REQUEST_SUCCESS: {
      const {
        request: { teamId },
        response: { entities }
      } = action;

      return {
        ...state,
        [teamId]: {
          ...state[teamId],
          ...entities.teams[teamId]
        }
      };
    }

    case types.TEAMS_UPDATE_REQUEST: {
      return {
        ...state
      };
    }

    case types.TEAMS_UPDATE_REQUEST_SUCCESS: {
      const {
        response: {
          entities: { teams }
        }
      } = action;

      const updatedTeams = Object.assign({}, state, teams);

      return {
        ...updatedTeams
      };
    }

    default:
      return state;
  }
};

export default teamsReducer;
