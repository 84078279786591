import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import * as types from "./BrandKitTypes";
import { noop, cloneDeep } from "lib";
import { fetchFonts } from "../../../lib/fonts";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import { uploadImageToPreSignedUrl } from "state/entities/userTeamImages/userTeamImagesActions";
import { asyncAction } from "lib/asyncHelpers";

/**
 * Dispatch the action required to set the selected colour in state.
 * @param colorId of the selected colour.
 * @returns {(function(*): void)|*}
 */
export const setSelectedColor = ({ colorId }) => dispatch => {
  dispatch({ type: types.BRAND_KIT_UPDATE_SELECTED_COLOR, colorId });
};

export const fetchBrandKitColorGroups = ({ teamId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/colors`,
      request: {
        page: 1,
        pageSize: 100
      },
      types: [
        types.BRAND_KIT_REQUEST_COLOR_GROUP,
        types.BRAND_KIT_REQUEST_COLOR_GROUP_SUCCESS,
        types.BRAND_KIT_REQUEST_COLOR_GROUP_FAILURE
      ],
      schema: schemas.BRAND_KIT_COLORS,
      onSuccess: response => noop
    }
  });
};

export const addBrandKitColorGroup = ({ teamId, onSuccess }) => ({
  [CALL_API]: {
    endpoint: `/teams/${teamId}/colors`,
    method: "POST",
    onSuccess,
    request: {
      body: {
        name: "Brand Color Palette",
        teamId,
        colors: ["#2170BF"]
      }
    },
    service: SERVICES.ASSET,
    schema: schemas.BRAND_KIT_COLOR_GROUP,
    types: [
      types.BRAND_KIT_CREATE_COLOR_GROUP_REQUEST,
      types.BRAND_KIT_CREATE_COLOR_GROUP_REQUEST_SUCCESS,
      types.BRAND_KIT_CREATE_COLOR_GROUP_REQUEST_FAILURE
    ]
  }
});

export const updateBrandKitColorGroup = ({
  groupId,
  name,
  colors,
  teamId,
  onSuccess = noop
}) => (dispatch, getState) =>
  new Promise(resolve => {
    const payload = {
      id: groupId,
      name,
      teamId,
      colors
    };

    dispatch({
      [CALL_API]: {
        method: "PUT",
        service: SERVICES.ASSET,
        endpoint: `/teams/${teamId}/colors/${groupId}`,
        request: {
          body: payload
        },
        types: [
          types.BRAND_KIT_UPDATE_COLOR_GROUP_REQUEST,
          types.BRAND_KIT_UPDATE_COLOR_GROUP_REQUEST_SUCCESS,
          types.BRAND_KIT_UPDATE_COLOR_GROUP_REQUEST_FAILURE
        ],
        schema: schemas.BRAND_KIT_COLOR_GROUP,
        onSuccess: onSuccess || resolve
      }
    });
  });

export const removeBrandKitColorGroup = ({
  groupId,
  teamId,
  onSuccess = noop
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/colors/${groupId}`,
      types: [
        types.BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST,
        types.BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST_SUCCESS,
        types.BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST_FAILURE
      ],
      schema: schemas.BRAND_KIT_COLOR_GROUP,
      onSuccess: response => {
        const group = {
          id: groupId,
          teamId
        };
        dispatch({
          type: types.BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST_SUCCESS,
          response: group
        });
      }
    }
  });
};

export const clearBrandKitImagesState = () => ({
  type: types.BRAND_KIT_IMAGES_CLEAR
});

export const refetchBrandKitImages = args => dispatch => {
  dispatch(clearBrandKitImagesState());
  dispatch(searchBrandKitImages(args));
};

const sortByOptions = ["updatedAt", "name"];
const sortOrderOptions = ["desc", "asc"];

export const searchBrandKitImages = ({
  folderId,
  onSuccess,
  page = 1,
  term,
  sortBy = "updatedAt",
  sortOrder = "desc"
} = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  const params = {
    term,
    context: "BRAND_IMAGE",
    sortBy: [sortBy, ...sortByOptions.filter(option => option !== sortBy)],
    sortOrder: [
      sortOrder,
      ...sortOrderOptions.filter(option => option !== sortOrder.toLowerCase())
    ].map(sortOrderKey => sortOrderKey.toLowerCase())
  };

  if (folderId) {
    params.folderId = folderId;
  }

  return dispatch({
    [CALL_API]: {
      endpoint: term ? "/media" : `/teams/${teamId}/images`,
      extra: {
        folderId,
        term
      },
      method: "GET",
      onSuccess,
      request: {
        page,
        pageSize: state.api.teamImages.pageSize,
        params
      },
      service: SERVICES.ASSET,
      schema: schemas.NONE,
      types: [
        types.SEARCH_IMAGES_REQUEST,
        types.SEARCH_IMAGES_SUCCESS,
        types.SEARCH_IMAGES_FAILURE
      ]
    }
  });
};

/**
 * @desc take an image file and uploads it to the brand kit images for the given team
 * @param {Object} args
 * @param {Object} args.file - an object that describes the file to upload from the file picker
 * @param {string} args.teamId - the id for the team that the image is to be uploaded for
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which runs when the process succeeds passed the result of the request
 */
export const addBrandKitImage = ({
  file,
  teamId,
  onSuccess = noop,
  duration,
  placeholderURL
}) => async dispatch => {
  // synchronously generate a pre signed url for the direct upload
  const preSignedUrl = await generatePreSignedUrlPromise({ file, teamId });
  // synchronously upload the image file to the pre signed url
  await uploadImageToPreSignedUrlPromise({
    file,
    preSignedUrl: preSignedUrl.url
  });

  // make update request to the api
  return dispatch(
    addBrandKitImageApiCall({
      duration,
      filename: preSignedUrl.filename,
      fileId: preSignedUrl.id,
      placeholderURL
    })
  );

  /**
   * @desc taking a file object and makes a request for a pre signed image upload url and returns it on resolution
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @returns {Promise} results in a Promise that resolves when the pre signed url is retrieved containing a 'url' key
   */
  function generatePreSignedUrlPromise({ file }) {
    return new Promise((resolve, reject) => {
      dispatch(
        createTeamImageUploadUrl({
          filename: file.name,
          responseContentType: file.type,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }

  /**
   * @desc taking a file and a pre signed url processes the upload of the file to the passed url
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
   * @returns {Promise} results in a Promise that resolves when the image has been successfully uploaded
   */
  function uploadImageToPreSignedUrlPromise({ file, preSignedUrl }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadImageToPreSignedUrl({
          imageFile: file,
          preSignedUrl: preSignedUrl,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }
};

/**
 * @desc makes the api request for a pre signed url
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.responseContentType - the type extension for the file to be uploaded
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const createTeamImageUploadUrl = ({
  filename,
  responseContentType,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      responseType: "text",
      types: [
        types.CREATE_TEAM_IMAGE_UPLOAD_URL_REQUEST,
        types.CREATE_TEAM_IMAGE_UPLOAD_URL_REQUEST_SUCCESS,
        types.CREATE_TEAM_IMAGE_UPLOAD_URL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/images/url`,
      request: {
        body: {
          teamId,
          filename,
          responseContentType
        }
      },
      onSuccess: onSuccess,
      schema: schemas.NONE
    }
  });
};

/**
 * @desc adds the details for an uploaded image to the api
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which is run when the request succeeds and is passed the results of the request
 */
export const addBrandKitImageApiCall = ({
  duration,
  filename,
  fileId,
  placeholderURL,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getCurrentTeamId(getState());

  return dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      endpoint: `/teams/${teamId}/images`,
      types: [
        types.CREATE_IMAGE_REQUEST,
        types.CREATE_IMAGE_SUCCESS,
        types.CREATE_IMAGE_FAILURE
      ],
      schema: schemas.NONE,
      request: {
        body: {
          teamId,
          filename,
          id: fileId,
          duration
        }
      },
      extra: {
        placeholderURL
      },
      onSuccess
    }
  });
};

export const updateBrandKitImage = image => {
  if (!image.mediaId) {
    throw new Error("Invalid media ID.");
  }

  if (!image.teamId) {
    throw new Error("Invalid team ID.");
  }

  // When updating an image name straight after upload, thumbnail URL is a blob image and will fail validation.
  const _image = cloneDeep(image);

  if (_image.thumbnailUrl.startsWith("blob:") && _image.preservedThumbUrl) {
    _image.thumbnailUrl = _image.preservedThumbUrl;
  }

  return {
    [CALL_API]: {
      endpoint: `/teams/${image.teamId}/images/${image.mediaId}`,
      method: "PUT",
      request: {
        body: _image
      },
      schema: schemas.NONE,
      service: SERVICES.ASSET,
      types: [
        types.UPDATE_IMAGE_REQUEST,
        types.UPDATE_IMAGE_SUCCESS,
        types.UPDATE_IMAGE_FAILURE
      ]
    }
  };
};

export const removeBrandKitImage = ({
  imageId,
  teamId,
  isSmartImage = false
}) => ({
  [CALL_API]: {
    endpoint: `/teams/${teamId}/images/${imageId}?forceDelete=${isSmartImage}`,
    extra: { imageId },
    method: "DELETE",
    schema: schemas.NONE,
    service: SERVICES.ASSET,
    types: [
      types.REMOVE_IMAGE_REQUEST,
      types.REMOVE_IMAGE_SUCCESS,
      types.REMOVE_IMAGE_FAILURE
    ]
  }
});

export const clearBrandKitLogosState = () => ({
  type: types.BRAND_KIT_LOGOS_CLEAR
});

export const refetchBrandKitLogos = args => dispatch => {
  dispatch(clearBrandKitLogosState());
  dispatch(searchBrandKitLogos(args));
};

export const searchBrandKitLogos = ({
  folderId,
  onSuccess,
  page = 1,
  term,
  sortBy = "updatedAt",
  sortOrder = "desc"
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  const params = {
    term,
    context: "BRAND_LOGO",
    sortBy: [sortBy, ...sortByOptions.filter(option => option !== sortBy)],
    sortOrder: [
      sortOrder,
      ...sortOrderOptions.filter(option => option !== sortOrder.toLowerCase())
    ].map(sortOrderKey => sortOrderKey.toLowerCase())
  };

  if (folderId) {
    params.folderId = folderId;
  }

  return dispatch({
    [CALL_API]: {
      endpoint: term ? "/media" : `/teams/${teamId}/logos`,
      extra: {
        folderId,
        term
      },
      method: "GET",
      onSuccess,
      request: {
        page,
        pageSize: state.api.teamLogos.pageSize,
        params
      },
      service: SERVICES.ASSET,
      schema: schemas.NONE,
      types: [
        types.SEARCH_LOGOS_REQUEST,
        types.SEARCH_LOGOS_SUCCESS,
        types.SEARCH_LOGOS_FAILURE
      ]
    }
  });
};

/**
 * @desc makes the api request for a pre signed url
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.responseContentType - the type extension for the file to be uploaded
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const createTeamLogoUploadUrl = ({
  filename,
  responseContentType,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      responseType: "text",
      types: [
        types.CREATE_TEAM_LOGO_UPLOAD_URL_REQUEST,
        types.CREATE_TEAM_LOGO_UPLOAD_URL_REQUEST_SUCCESS,
        types.CREATE_TEAM_LOGO_UPLOAD_URL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/logos/url`,
      request: {
        body: {
          teamId,
          filename,
          responseContentType
        }
      },
      onSuccess: onSuccess,
      schema: schemas.NONE
    }
  });
};

/**
 * @desc take an image file and uploads it to the brand logo for the given team
 * @param {Object} args
 * @param {Object} args.file - an object that describes the file to upload from the file picker
 * @param {string} args.teamId - the id for the team to upload the image for
 * @param {function} args.onSuccess - a function which runs when the process succeeds passed the result of the request
 */
export const addBrandKitLogo = ({ file, teamId, onSuccess = noop }) => async (
  dispatch,
  getState
) => {
  // synchronously generate a pre signed url for the direct upload
  const preSignedUrl = await generatePreSignedUrlPromise({ file, teamId });

  // synchronously upload the image file to the pre signed url
  await uploadImageToPreSignedUrlPromise({
    file,
    preSignedUrl: preSignedUrl.url
  });

  // make update request to the api
  return dispatch(
    addBrandKitLogoApiCall({
      filename: preSignedUrl.filename,
      fileId: preSignedUrl.id
    })
  );

  /**
   * @desc taking a file object and makes a request for a pre signed image upload url and returns it on resolution
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.teamId - the id for the team to upload the image for
   * @returns {Promise} results in a Promise that resolves when the pre signed url is retrieved containing a 'url' key
   */
  function generatePreSignedUrlPromise({ file, teamId }) {
    return new Promise((resolve, reject) => {
      dispatch(
        createTeamLogoUploadUrl({
          filename: file.name,
          responseContentType: file.type,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }

  /**
   * @desc taking a file and a pre signed url processes the upload of the file to the passed url
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
   * @returns {Promise} results in a Promise that resolves when the image has been successfully uploaded
   */
  function uploadImageToPreSignedUrlPromise({ file, preSignedUrl }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadImageToPreSignedUrl({
          imageFile: file,
          preSignedUrl: preSignedUrl,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }
};

/**
 * @desc adds the details for an uploaded image to the api
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.fileId - the id for the file that is being uploaded
 * @param {function} args.onSuccess - a function which is run when the request succeeds and is passed the results of the request
 */
export const addBrandKitLogoApiCall = ({
  filename,
  fileId,
  onSuccess = noop
}) => async (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  return dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      endpoint: `/teams/${teamId}/logos`,
      types: [
        types.CREATE_LOGO_REQUEST,
        types.CREATE_LOGO_SUCCESS,
        types.CREATE_LOGO_FAILURE
      ],
      schema: schemas.NONE,
      request: {
        /* to be confirmed */
        body: {
          filename,
          teamId,
          id: fileId
        }
      },
      onSuccess
    }
  });
};

export const updateBrandKitLogo = logo => {
  if (!logo.mediaId) {
    throw new Error("Invalid media ID.");
  }

  if (!logo.teamId) {
    throw new Error("Invalid team ID.");
  }

  return {
    [CALL_API]: {
      endpoint: `/teams/${logo.teamId}/logos/${logo.mediaId}`,
      method: "PUT",
      request: {
        body: logo
      },
      schema: schemas.NONE,
      service: SERVICES.ASSET,
      types: [
        types.UPDATE_LOGO_REQUEST,
        types.UPDATE_LOGO_SUCCESS,
        types.UPDATE_LOGO_FAILURE
      ]
    }
  };
};

export const removeBrandKitLogo = ({
  logoId,
  teamId,
  isSmartImage = false
}) => ({
  [CALL_API]: {
    endpoint: `/teams/${teamId}/logos/${logoId}?forceDelete=${isSmartImage}`,
    extra: { logoId },
    method: "DELETE",
    service: SERVICES.ASSET,
    schema: schemas.NONE,
    types: [
      types.REMOVE_LOGO_REQUEST,
      types.REMOVE_LOGO_SUCCESS,
      types.REMOVE_LOGO_FAILURE
    ]
  }
});

// TODO: The use of getState().api.teamImages.pageSize is done on purpose here and should be updated
// this has been done as an 'urgent' update.
export const fetchBrandKitFonts = ({ teamId, page = 1, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const pageSize = getState().api.userTeamFonts.pageSize;
  const processResult = (result, pageNumber) => {
    if (result.ids.length === pageSize) {
      /* dispatch again */
      fetchBrandKitFonts({
        teamId,
        page: pageNumber,
        onSuccess: onSuccess
      })(dispatch, getState);
    } else {
      onSuccess();
    }
  };
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/fonts`,
      types: [
        types.BRAND_KIT_REQUEST_FONTS,
        types.BRAND_KIT_REQUEST_FONTS_SUCCESS,
        types.BRAND_KIT_REQUEST_FONTS_FAILURE
      ],
      request: {
        page,
        pageSize: getState().api.userTeamFonts.pageSize
      },
      schema: schemas.BRAND_KIT_FONTS,
      onSuccess: response => {
        if (response.entities) {
          const teamFontMap = response.entities.brandKitFont;
          const fontFamilies = [];
          const urls = [];
          Object.keys(teamFontMap).forEach(id => {
            let font = teamFontMap[id];
            fontFamilies.push(font.fontFamilyName);
            urls.push(font.stylesheetUrl);
          });
          fetchFonts(urls, fontFamilies).then(() => {
            dispatch({
              type: types.BRAND_KIT_FONT_FACE_LOAD_SUCCESS
            });
          });
          processResult(response, page + 1);
        }
      }
    }
  });
};

export const shouldFetchBrandKitFonts = state => {
  const fonts = state.ui.brandKit.fonts;
  if (fonts.entries || fonts.isProcessing) return false;

  return true;
};

export const fetchBrandKitFontsIfNeeded = ({ teamId }) => (
  dispatch,
  getState
) => {
  if (shouldFetchBrandKitFonts(getState())) {
    dispatch(fetchBrandKitFonts({ teamId }));
  }
};

export const uploadBrandKitFont = ({
  fontFile,
  teamFontObject,
  userId,
  teamId,
  onSuccess = noop,
  resolve = noop
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/fonts`,
      request: {
        body: {
          userId,
          teamId,
          fontFile
        }
      },
      types: [
        types.BRAND_KIT_UPLOAD_FONT_REQUEST,
        types.BRAND_KIT_UPLOAD_FONT_REQUEST_SUCCESS,
        types.BRAND_KIT_UPLOAD_FONT_REQUEST_FAILURE
      ],
      schema: schemas.BRAND_KIT_FONT_UPLOAD,
      onSuccess: response => {
        const font = response.entities.brandKitFontUpload[response.ids];
        teamFontObject.fontId = font.fontId;
        teamFontObject.fontFamilyName = font.fontFamilyName;

        const fontFamilies = [font.fontFamilyName];
        const urls = [font.stylesheetUrl];

        fetchFonts(urls, fontFamilies).then(() => {
          dispatch({
            type: types.BRAND_KIT_FONT_FACE_LOAD_SUCCESS
          });
        });

        dispatch(addBrandKitFont({ teamFontObject, teamId, resolve }));
      }
    }
  });
};

export const asyncUploadBrandKitFont = asyncAction(uploadBrandKitFont);

export const addBrandKitFont = ({
  teamFontObject,
  teamId,
  onSuccess = noop,
  resolve = noop
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/fonts`,
      request: {
        body: teamFontObject
      },
      types: [
        types.BRAND_KIT_CREATE_FONT_REQUEST,
        types.BRAND_KIT_CREATE_FONT_REQUEST_SUCCESS,
        types.BRAND_KIT_CREATE_FONT_REQUEST_FAILURE
      ],
      schema: schemas.BRAND_KIT_FONT,
      onSuccess: resolve
    }
  });
};

export const updateBrandKitFont = teamFont => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamFont.teamId}/fonts/${teamFont.id}`,
      types: [
        types.BRAND_KIT_UPDATE_FONT_REQUEST,
        types.BRAND_KIT_UPDATE_FONT_REQUEST_SUCCESS,
        types.BRAND_KIT_UPDATE_FONT_REQUEST_FAILURE
      ],
      schema: schemas.BRAND_KIT_FONT,
      request: {
        body: teamFont
      },
      onSuccess: response => noop
    }
  });
};

const partialUpdateBrandKitFont = (teamFont, resolve = noop, reject = noop) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamFont.teamId}/fonts/${teamFont.id}`,
      types: [
        types.BRAND_KIT_PARTIAL_UPDATE_FONT_REQUEST,
        types.BRAND_KIT_PARTIAL_UPDATE_FONT_REQUEST_SUCCESS,
        types.BRAND_KIT_PARTIAL_UPDATE_FONT_REQUEST_FAILURE
      ],
      schema: schemas.BRAND_KIT_FONT,
      request: {
        body: teamFont
      },
      onSuccess: response => {
        const font = response.entities.brandKitFont[response.ids];
        const fontFamilies = [font.fontFamilyName];
        const urls = [font.stylesheetUrl];

        if (teamFont.fontFile) {
          fetchFonts(urls, fontFamilies).then(() => {
            dispatch({
              type: types.BRAND_KIT_FONT_FACE_LOAD_SUCCESS
            });
          });
        }
        resolve();
      },
      onFailure: reject
    }
  });
};
export const asyncPartialUpdateBrandKitFont = asyncAction(
  partialUpdateBrandKitFont
);

export const removeBrandKitFont = ({ id, teamId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/fonts/${id}`,
      types: [
        types.BRAND_KIT_REMOVE_FONT_REQUEST,
        types.BRAND_KIT_REMOVE_FONT_REQUEST_SUCCESS,
        types.BRAND_KIT_REMOVE_FONT_REQUEST_FAILURE
      ],
      schema: schemas.BRAND_KIT_FONT,
      onSuccess: response => {
        const font = {
          id,
          teamId
        };
        dispatch({
          type: types.BRAND_KIT_REMOVE_FONT_REQUEST_SUCCESS,
          response: font
        });
      }
    }
  });
};
