import React from "react";
import style from "./style.module.css";
import { Button } from "views/components";

export const UpgradeBox = ({
  upgradeBoxDescriptionText,
  moveToSubscription,
  userCanUpgrade
}) => (
  <div className={style.upgradeBoxWrapper}>
    <div className={style.upgradeBoxTitle}>Upgrade your plan</div>
    <div className={style.upgradeBoxDescription}>
      {upgradeBoxDescriptionText}
    </div>
    {userCanUpgrade && (
      <Button theme="blueSolid" onClick={moveToSubscription}>
        Upgrade Plan
      </Button>
    )}
  </div>
);

export default UpgradeBox;
