import * as types from "state/entities/workspaceSizes/workspaceSizesTypes";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 100
};

const api = (state = initState, action) => {
  switch (action.type) {
    case types.WORKSPACE_SIZES_REQUEST: {
      const page = {
        [action.request.page]: {
          ...state.pages[action.request.page],
          isFetching: true,
          lastFetched: Date.now()
        }
      };

      return {
        ...state,
        pages: { ...state.pages, ...page }
      };
    }
    case types.WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_FILTER_REQUEST_SUCCESS:
    case types.WORKSPACE_APPROVED_SIZES_FILTER_REQUEST_SUCCESS:
    case types.WORKSPACE_SHARED_SIZES_FILTER_REQUEST_SUCCESS:
    case types.WORKSPACE_SIZES_FILTER_REQUEST_SUCCESS:
    case types.WORKSPACE_SIZES_REQUEST_SUCCESS: {
      const {
        request: { page },
        response: { ids }
      } = action;
      const pages = {
        ...state.pages,
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false,
          lastFetched: Date.now()
        }
      };

      return {
        ...state,
        pages: pages
      };
    }

    default:
      return state;
  }
};

export default api;
