import { combineReducers } from "redux";

import { archivedApiReducers as archived } from "./archived";
import { templatesApiReducers as templates } from "./templates";
import { easilApiReducers as easil } from "./easil";
import { foldersApiReducers as folders } from "./folders";
import { teamDesignsAllApiReducers as all } from "./all";
import { queryApiReducers as query } from "./query";
import { searchApiReducers as search } from "./search";
import { layoutsReducers as layouts } from "./layouts";
import { approvedApiReducers as approved } from "./approved";
import { pendingApprovalApiReducers as pendingApproval } from "./pendingApproval";

export default combineReducers({
  all,
  templates,
  easil,
  folders,
  archived,
  query,
  search,
  layouts,
  approved,
  pendingApproval
});
