import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

const TopBarN2Cols = ({ children }) => (
  <div className={`${style.outer} translate`}>
    <div className={style.topRow}>{children[0]}</div>
    <div className={style.col1}>{children[1]}</div>
  </div>
);
TopBarN2Cols.propTypes = {
  content: PropTypes.element,
  props: PropTypes.object
};

export default TopBarN2Cols;
