import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TeamPageTopRow from "./TeamPageTopRow";
import { switchToken } from "state/currentUser/currentUserActions";
import {
  fetchPendingUsers,
  fetchUsersWithParentTeamsIfNeeded,
  fetchTeamsIfNeeded,
  inviteToTeam
} from "state/entities/entitiesActions";
import { createTeamAndRedirect } from "state/ui/teamPage/createTeamMenu/createTeamMenuActions";
import {
  usersSelector,
  usersTotalCountSelector,
  teamsSelector,
  teamsTotalCountSelector,
  teamSelector
} from "state/entities/entitiesSelectors";
import { userIsAdmin } from "state/currentUser/currentUserSelectors";
import TeamPageTopRowLoading from "./TeamPageTopRowLoading";
import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";
import { currentSubscriptionPlanSelector } from "state/entities/subscriptionPlans/subscriptionPlansSelectors";
import Subscriptions from "lib/subscriptions";
import { showUpgradeModal } from "state/ui/upgradeModal/upgradeModalActions";
import { getIsCurrentTeamAtDepthLimit } from "state/entities/teams/teamsSelectors";
import { updateTeam } from "state/entities/teams/teamsActions";

export class TeamPageTopRowContainer extends Component {
  static propTypes = {
    currentTeam: PropTypes.object,
    isAdmin: PropTypes.bool,
    users: PropTypes.object,
    teams: PropTypes.object,
    fetchUsersWithParentTeamsIfNeeded: PropTypes.func,
    fetchTeamsIfNeeded: PropTypes.func,
    onSubmiteNewTeam: PropTypes.func,
    teamsTotalCount: PropTypes.number,
    usersTotalCount: PropTypes.number
  };

  componentDidMount() {
    const {
      fetchUsersWithParentTeamsIfNeeded,
      fetchTeamsIfNeeded
    } = this.props;

    fetchUsersWithParentTeamsIfNeeded({ page: 1 });
    fetchTeamsIfNeeded();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      users,
      fetchUsersWithParentTeamsIfNeeded,
      teams,
      fetchTeamsIfNeeded
    } = nextProps;

    if (!users) fetchUsersWithParentTeamsIfNeeded({ page: 1 });
    if (!teams) fetchTeamsIfNeeded();
  }

  render() {
    const {
      currentTeam,
      currentTeamSubscription,
      users,
      usersTotalCount,
      teams,
      teamsTotalCount,
      onSubmitNewTeam,
      onInviteToTeam,
      inviteToTeamForm,
      onSwitchTeam,
      currentPlan,
      isAdmin,
      moveTo,
      currentSubscriptionFeatures,
      showUpgradeModal,
      isCurrentTeamAtDepthLimit,
      updateTeam,
      fetchPendingUsers
    } = this.props;

    if (!users || !teams) return <TeamPageTopRowLoading />;

    return (
      <TeamPageTopRow
        currentTeam={currentTeam}
        currentTeamSubscription={currentTeamSubscription}
        isAdmin={isAdmin}
        users={users}
        usersTotalCount={usersTotalCount}
        teams={teams}
        inviteToTeamForm={inviteToTeamForm}
        teamsTotalCount={teamsTotalCount}
        onInviteToTeam={() =>
          onInviteToTeam({
            onSuccess: () => fetchPendingUsers({ teamId: currentTeam.id })
          })
        }
        onSubmitNewTeam={team => onSubmitNewTeam(team, currentTeam)}
        onSwitchTeam={onSwitchTeam}
        currentPlan={currentPlan}
        moveTo={moveTo}
        currentSubscriptionFeatures={currentSubscriptionFeatures}
        showUpgradeModal={showUpgradeModal}
        isCurrentTeamAtDepthLimit={isCurrentTeamAtDepthLimit}
        updateTeam={updateTeam}
      />
    );
  }
}

const mapStateToProps = state => {
  const currentTeam = teamSelector({ state });
  const teams = teamsSelector({ state, exclude: state.ui.currentTeam.id });
  const currentSubscription = currentSubscriptionSelector(state);
  const currentPlan = currentSubscriptionPlanSelector(
    state,
    currentSubscription.planId
  );
  const currentSubscriptionFeatures = Subscriptions.get(
    currentSubscription.code
  );

  return {
    isAdmin: userIsAdmin(state),
    users: usersSelector(state),
    teams: teams,
    currentTeam: currentTeam,
    teamsTotalCount: teamsTotalCountSelector(state),
    usersTotalCount: usersTotalCountSelector(state),
    inviteToTeamForm: state.ui.inviteToTeamForm,
    currentPlan,
    currentSubscriptionFeatures,
    isCurrentTeamAtDepthLimit: getIsCurrentTeamAtDepthLimit(state),
    currentTeamSubscription: currentSubscription
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmitNewTeam: (team, currentTeam) =>
      dispatch(createTeamAndRedirect(team, currentTeam)),
    fetchUsersWithParentTeamsIfNeeded: args =>
      dispatch(fetchUsersWithParentTeamsIfNeeded(args)),
    fetchTeamsIfNeeded: () => dispatch(fetchTeamsIfNeeded()),
    onInviteToTeam: args => dispatch(inviteToTeam(args)),
    fetchPendingUsers: args => dispatch(fetchPendingUsers(args)),
    onSwitchTeam: args => dispatch(switchToken(args)),
    showUpgradeModal: args => dispatch(showUpgradeModal(args)),
    updateTeam: args => dispatch(updateTeam(args))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamPageTopRowContainer);
