import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const MagnifierRightIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 18}
    height={height || size || 18}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-3h24v24H-3z" />
      <g
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(1 1)"
      >
        <circle cx="7.111" cy="7.111" r="7.111" />
        <path d="M16 16l-3.867-3.867" />
      </g>
    </g>
  </svg>
);
MagnifierRightIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default MagnifierRightIcon;
