import { createSelector } from "reselect";
import { getPath, isEmpty } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
const DIGITAL_DOWNLOAD_OPTION = "Digital Download";
const GIF_OPTION = "GIF";
const DIGITAL_OPTIONS = [DIGITAL_DOWNLOAD_OPTION, GIF_OPTION];

const printPricingSelector = state => state.entities.printPricing;

const processPrintPricingGroupedBySizeSelector = printPricing => {
  const groupedPricing = {};

  if (!printPricing) return groupedPricing;

  Object.keys(printPricing)
    .map(id => printPricing[id])
    .forEach(pricingGroup => {
      pricingGroup.options.forEach(pricing => {
        if (groupedPricing[pricing.designId]) {
          groupedPricing[pricing.designId].push(pricing);
        } else {
          groupedPricing[pricing.designId] = [pricing];
        }
      });
    });

  return groupedPricing;
};

export const printPricingGroupedBySizeSelector = createSelector(
  [printPricingSelector],
  processPrintPricingGroupedBySizeSelector
);

// get all pricing options grouped by the defined key
const groupPrintPricingByKey = (
  printProviders,
  groupingKey,
  excludedProviders
) => {
  if (!printProviders || isEmpty(printProviders)) return {};

  const groupedPricing = Object.values(printProviders)
    .filter(printProvider => !excludedProviders.includes(printProvider.name))
    .reduce((printOptions, printProvider) => {
      const newOptions = printProvider.options.reduce((options, option) => {
        return immutableUpdate(options, {
          $auto: {
            [option[groupingKey]]: {
              $autoArray: {
                $push: [option]
              }
            }
          }
        });
      }, printOptions);

      return newOptions;
    }, {});

  return groupedPricing;
};

export const printPricingGroupedByDesignIdWithDigitalSelector = createSelector(
  [printPricingSelector, () => "designId", () => []],
  groupPrintPricingByKey
);

export const printPricingGroupedByDesignIdSelector = createSelector(
  [printPricingSelector, () => "designId", () => ["Easil"]],
  groupPrintPricingByKey
);

export const printPricingGroupedByPrintOptionIdSelector = createSelector(
  [printPricingSelector, () => "printOptionId", () => []],
  groupPrintPricingByKey
);

export const printPricingForDesignIdSelector = (state, designId) =>
  getPath(printPricingGroupedByDesignIdSelector(state), designId);

const processPrintPricingDigitalOptionsList = printPricing => {
  const options = [];

  if (!printPricing) return options;

  Object.keys(printPricing)
    .map(id => printPricing[id])
    .forEach(pricingGroup => {
      pricingGroup.options.forEach(option => {
        if (!DIGITAL_OPTIONS.includes(option.sizeDescription)) return;

        options.push(option);
      });
    });

  return options;
};

export const printPricingDigitalOptionsList = createSelector(
  [printPricingSelector],
  processPrintPricingDigitalOptionsList
);
