class Subscriptions {
  static BASIC = {
    canUseApprovalsSystem: false,
    canAccessBrandKit: false,
    canAccessFavorites: false,
    canCreateFolder: false,
    canResizeDesigns: false,
    canUploadFonts: false,
    canUseRestrictions: false,
    canUseTextMask: false,
    canPromoteDrafts: false,
    canUseDesignMerge: false,
    canInviteMembers: false,
    code: "BASIC",
    downloadTypes: ["JPG"],
    name: "Basic",
    description:
      "All you need to get started with Easil\nand make it your DIY secret weapon.",
    numberOfDesigns: "",
    popularSizesList: {
      AU: [
        "instagram_stories",
        "instagram1080",
        "instagram_portrait",
        "businesscardl",
        "poster",
        "a6_postcard_landscape",
        "presentation_16_9",
        "a4_portrait"
      ],
      US: [
        "instagram_stories",
        "instagram1080",
        "instagram_portrait",
        "businesscard336192",
        "poster457610",
        "postcard_landscape",
        "presentation_16_9",
        "us_letter"
      ]
    },
    easilCatalogueDefaultSize: {
      AU: "instagram_stories",
      US: "instagram_stories"
    },
    removeBackgroundCreditsPerMonth: 0
  };

  static PLUS = {
    canUseApprovalsSystem: true,
    canAccessBrandKit: true,
    canAccessFavorites: true,
    canCreateFolder: true,
    canResizeDesigns: true,
    canUploadFonts: true,
    canUseRestrictions: true,
    canUseTextMask: true,
    canPromoteDrafts: true,
    canUseDesignMerge: true,
    canInviteMembers: true,
    code: "PLUS",
    downloadTypes: ["JPG", "PNG", "PDF", "GIF", "MP4"],
    name: "Plus",
    description:
      "Design and creative tools for\nindividuals and teams creating and\nmanaging their own design templates.",
    numberOfDesigns: "10,000+",
    popularSizesList: {
      AU: [
        "instagram_stories",
        "instagram1080",
        "instagram_portrait",
        "businesscardl",
        "poster",
        "a6_postcard_landscape",
        "presentation_16_9",
        "a4_portrait"
      ],
      US: [
        "instagram_stories",
        "instagram1080",
        "instagram_portrait",
        "businesscard336192",
        "poster457610",
        "postcard_landscape",
        "presentation_16_9",
        "us_letter"
      ]
    },
    easilCatalogueDefaultSize: {
      AU: "instagram_stories",
      US: "instagram_stories"
    },
    removeBackgroundCreditsPerMonth: 10
  };

  static EDGE = {
    canUseApprovalsSystem: true,
    canAccessBrandKit: true,
    canAccessFavorites: true,
    canCreateFolder: true,
    canUploadFonts: true,
    canResizeDesigns: true,
    canUseRestrictions: true,
    canUseTextMask: true,
    canPromoteDrafts: true,
    canUseDesignMerge: true,
    canInviteMembers: true,
    code: "EDGE",
    downloadTypes: ["JPG", "PNG", "PDF", "GIF", "MP4"],
    name: "Edge",
    description:
      "Unlimited premium template\ncollections access plus\nEasil’s Edge Design Guarantee.",
    numberOfDesigns: "20,000+",
    popularSizesList: {
      AU: [
        "instagram_stories",
        "instagram1080",
        "instagram_portrait",
        "businesscardl",
        "poster",
        "a6_postcard_landscape",
        "presentation_16_9",
        "a4_portrait"
      ],
      US: [
        "instagram_stories",
        "instagram1080",
        "instagram_portrait",
        "businesscard336192",
        "poster457610",
        "postcard_landscape",
        "presentation_16_9",
        "us_letter"
      ]
    },
    easilCatalogueDefaultSize: {
      AU: "poster",
      US: "poster457610"
    },
    removeBackgroundCreditsPerMonth: 50
  };

  static SUBSCRIPTIONS_HIERARCHY = ["BASIC", "PLUS", "EDGE"];

  static SUBSCRIPTIONS_PERIODS = ["MONTHLY", "YEARLY"];

  static SUBSCRIPTIONS_LIST = ["BASIC", "PLUS", "EDGE"];

  static IS_PLAN_UPGRADE(plan, candidatePlan) {
    const upgradesMap = {
      BASIC_MONTHLY: [
        "PLUS_MONTHLY",
        "PLUS_YEARLY",
        "EDGE_MONTHLY",
        "EDGE_YEARLY"
      ],
      BASIC_YEARLY: [
        "PLUS_MONTHLY",
        "PLUS_YEARLY",
        "EDGE_MONTHLY",
        "EDGE_YEARLY"
      ],
      PLUS_MONTHLY: ["PLUS_YEARLY", "EDGE_MONTHLY", "EDGE_YEARLY"],
      PLUS_YEARLY: ["EDGE_MONTHLY", "EDGE_YEARLY"],
      EDGE_MONTHLY: ["EDGE_YEARLY"],
      EDGE_YEARLY: []
    };

    const planKey = `${plan.code}_${plan.intervalPeriod}`;
    const candidatePlanKey = `${candidatePlan.code}_${candidatePlan.intervalPeriod}`;

    return upgradesMap[planKey].includes(candidatePlanKey);
  }

  static get(code) {
    const subscription = this[code] || this.BASIC;

    return new this(subscription);
  }

  static PLAN_DETAILS = {
    // DESIGN TOOLS
    highQualityTemplates: {
      label: "Professionally designed graphic & video templates",
      BASIC: {
        available: true,
        label: "2,500+"
      },
      PLUS: {
        available: true,
        label: "10,000+"
      },
      EDGE: {
        available: true,
        label: "25,000+"
      },
      type: "DESIGN_TOOL"
    },
    freeGraphicElements: {
      label: "Free Graphic Elements",
      BASIC: {
        available: true,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    oneMillionStockImageRange: {
      label: "1 Million+ Free Stock Images",
      BASIC: {
        available: true,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    textEffects: {
      label: "Text Effects: Drop Shadow & Glow",
      BASIC: {
        available: true,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    uploadYourOwnImage: {
      label: "Upload your own Images",
      BASIC: {
        available: true,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    uploadYourOwnVideo: {
      label: "Upload your own Videos",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    removeBackground: {
      label: "Remove Image Background",
      BASIC: {
        available: false
      },
      PLUS: {
        available: true,
        label: `${Subscriptions.PLUS.removeBackgroundCreditsPerMonth} Credits`
      },
      EDGE: {
        available: true,
        label: `${Subscriptions.EDGE.removeBackgroundCreditsPerMonth} Credits`
      },
      type: "DESIGN_TOOL"
    },
    textMask: {
      label: "Text Masking: Add Images to Fonts",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    transparentPng: {
      label: "Download Transparent PNG",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    printQualityPdf: {
      label: "Download Print Quality PDF",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    transparentAnimatedGif: {
      label: "Download Transparent Animated GIF",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    MP4Video: {
      label: "Download MP4 Video",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    designResize: {
      label: "Design Resizing Tool",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    uploadFonts: {
      label: "Upload your own Fonts",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "DESIGN_TOOL"
    },
    printDiscount: {
      label: "15% Off Print Purchases",
      BASIC: {
        available: false
      },
      PLUS: {
        available: false
      },
      EDGE: {
        available: true
      },
      type: "DESIGN_TOOL"
    },
    designGuarantee: {
      label: "Edge Design Guarantee",
      BASIC: {
        available: false
      },
      PLUS: {
        available: false
      },
      EDGE: {
        available: true
      },
      type: "DESIGN_TOOL",
      isGuarantee: true,
      infoToolTip: `<b>Can't find it? We'll design it!</b>\nIf you can't find a design that fits your requirements, our studio will create it for FREE. T&Cs apply.`,
      infoHref: "https://about.easil.com/easil-edge-design-guarantee/"
    },

    // ORGANIZATIONAL_TOOLS

    organiseLayersAndGroups: {
      label: "Organize with Layers and Groups",
      BASIC: {
        available: true,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "ORGANIZATIONAL_TOOL"
    },
    imageFolders: {
      label: "Organize Photos with Folders",
      BASIC: {
        available: true,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "ORGANIZATIONAL_TOOL"
    },
    designOrganisationFoldersTags: {
      label: "Organize Designs with Folders & Tags",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "ORGANIZATIONAL_TOOL"
    },
    favorites: {
      label: "Save Elements as Favorites",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "ORGANIZATIONAL_TOOL"
    },
    updateCollection: {
      label: "Batch update graphics with Update Collection",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      infoToolTip:
        "Single click to update text & images across multiple sizes in your linked design Collections.",
      type: "ORGANIZATIONAL_TOOL"
    },

    // BRAND_MANAGEMENT

    designTeamMasterTemplates: {
      label: "Save Designs as Templates",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "BRAND_MANAGEMENT"
    },
    designBrandColorPalettes: {
      label: "Save Colors, Fonts, Logos & Images in Brand Kits",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "BRAND_MANAGEMENT"
    },
    designTeamRestrictions: {
      label: "Apply Document & Design Restrictions",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "BRAND_MANAGEMENT"
    },
    designTeamShareFontsImages: {
      label: "Share Designs & Assets with your Team",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "BRAND_MANAGEMENT"
    },
    approvalWorkflow: {
      label: "Design Approval Workflow",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "BRAND_MANAGEMENT"
    },
    multipleTeams: {
      label: "Set Up Multiple Teams / Workspaces",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: true,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "BRAND_MANAGEMENT"
    },

    // SUPPORT

    prioritySupport: {
      label: "Priority Support",
      BASIC: {
        available: false,
        label: null
      },
      PLUS: {
        available: false,
        label: null
      },
      EDGE: {
        available: true,
        label: null
      },
      type: "SUPPORT"
    }
  };

  constructor({
    canAccessBrandKit,
    canAccessFavorites,
    canCreateFolder,
    canResizeDesigns,
    canUploadFonts,
    canUseRestrictions,
    canUseTextMask,
    canPromoteDrafts,
    canUseDesignMerge,
    canInviteMembers,
    code,
    downloadTypes,
    name,
    numberOfDesigns,
    popularSizesList,
    easilCatalogueDefaultSize,
    removeBackgroundCreditsPerMonth
  }) {
    this.canAccessBrandKit = canAccessBrandKit;
    this.canAccessFavorites = canAccessFavorites;
    this.canCreateFolder = canCreateFolder;
    this.canResizeDesigns = canResizeDesigns;
    this.canUploadFonts = canUploadFonts;
    this.canUseRestrictions = canUseRestrictions;
    this.canUseTextMask = canUseTextMask;
    this.canPromoteDrafts = canPromoteDrafts;
    this.canUseDesignMerge = canUseDesignMerge;
    this.canInviteMembers = canInviteMembers;
    this.code = code;
    this.downloadTypes = downloadTypes;
    this.name = name;
    this.numberOfDesigns = numberOfDesigns;
    this.popularSizesList = popularSizesList;
    this.easilCatalogueDefaultSize = easilCatalogueDefaultSize;
    this.removeBackgroundCreditsPerMonth = removeBackgroundCreditsPerMonth;
  }

  canAccess(code) {
    const SUBSCRIPTIONS_HIERARCHY = this.constructor.SUBSCRIPTIONS_HIERARCHY;
    const subscriptionCodeIndex = SUBSCRIPTIONS_HIERARCHY.indexOf(code);

    if (subscriptionCodeIndex === -1) {
      return false;
    }

    return subscriptionCodeIndex <= this.indexOnHierarchy;
  }

  get indexOnHierarchy() {
    const SUBSCRIPTIONS_HIERARCHY = this.constructor.SUBSCRIPTIONS_HIERARCHY;

    return SUBSCRIPTIONS_HIERARCHY.indexOf(this.code);
  }

  canDownloadType(type) {
    return this.downloadTypes.includes(type);
  }

  getpopularSizesList({ region = "AU" }) {
    if (region !== "US") region = "AU";
    return this.popularSizesList[region];
  }

  getEasilCatalogueDefaultSize({ region = "AU" }) {
    if (region !== "US") region = "AU";
    return this.easilCatalogueDefaultSize[region];
  }

  getName() {
    return this.name;
  }

  getCode() {
    return this.code;
  }

  getNumberOfDesigns() {
    return this.numberOfDesigns;
  }

  getCanCreateFolder() {
    return this.canCreateFolder;
  }

  getListOfSubscriptionsAbove() {
    const SUBSCRIPTIONS_HIERARCHY = this.constructor.SUBSCRIPTIONS_HIERARCHY;

    return SUBSCRIPTIONS_HIERARCHY.slice(this.indexOnHierarchy + 1);
  }

  getCanResizeDesigns() {
    return this.canResizeDesigns;
  }

  getCanAccessFavorites() {
    return this.canAccessFavorites;
  }

  getCanUseTextMask() {
    return this.canUseTextMask;
  }

  getCanAccessBrandKit() {
    return this.canAccessBrandKit;
  }

  getCanUseRestrictions() {
    return this.canUseRestrictions;
  }

  getCanUploadFonts() {
    return this.canUploadFonts;
  }

  getCanUseApprovalsSystem() {
    return Subscriptions[this.code].canUseApprovalsSystem;
  }
}

export default Subscriptions;
