import React from "react";
import style from "./style.module.css";
import { Button } from "views/components";
import { noop } from "lib";

export default function BrandKitEmpty({ button, icon, subtitle, title }) {
  return (
    <div className={style.empty} data-testid="brandKitEmpty">
      {icon && <div className={style.emptyIcon}>{icon}</div>}

      {title && <div className={style.emptyTitle}>{title}</div>}

      {subtitle && <div className={style.emptySubtitle}>{subtitle}</div>}

      {button && (
        <Button
          className={style.emptyButton}
          theme="blueSolid"
          onClick={button.onClick || noop}
        >
          {button.children}
        </Button>
      )}
    </div>
  );
}
