import * as types from "./designsDataTypes";
import { noop } from "lib";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { asyncAction } from "lib/asyncHelpers";

export const fetchDesignDataByDesignId = ({
  designId,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.FETCH_LATEST_DATA_REQUEST,
        types.FETCH_LATEST_DATA_REQUEST_SUCCESS,
        types.FETCH_LATEST_DATA_REQUEST_FAILURE
      ],
      endpoint: `/designs/${designId}/data`,
      schema: schemas.DESIGN_DATA,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: reject
    }
  });
};

export const asyncFetchDesignDataByDesignId = asyncAction(
  fetchDesignDataByDesignId
);

export const clearAndFetchLatestDesignDataForDesigns = ({
  designIds
}) => dispatch => {
  dispatch({ type: types.CLEAR_ALL_DESIGN_DATA });
  designIds.forEach(designId =>
    dispatch(fetchDesignDataByDesignId({ designId }))
  );
};
