import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const RotateIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 19 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <g fill={color || easilGrayXDark}>
        <path
          fillRule="nonzero"
          d="M16 8a1 1 0 0 1-2 0 6 6 0 1 0-6 6 1 1 0 0 1 0 2 8 8 0 1 1 8-8z"
        />
        <path d="M14.943 8.657l2.121-2.121a1 1 0 0 1 1.414 1.414l-2.828 2.828a1 1 0 0 1-1.414 0L11.407 7.95a1 1 0 1 1 1.414-1.414l2.122 2.12z" />
      </g>
    </g>
  </svg>
);
RotateIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default RotateIcon;
