import * as types from "./inviteToTeamFormTypes";

export const inviteToTeamFormAddNewEntry = newEntry => {
  return {
    type: types.TEAM_INVITE_FORM_ADD_NEW_ENTRY,
    newEntry
  };
};

export const inviteToTeamFormRemoveEntry = entryIndex => {
  return {
    type: types.TEAM_INVITE_FORM_REMOVE_ENTRY,
    payload: { entryIndex }
  };
};

export const inviteToTeamFormReset = () => {
  return {
    type: types.TEAM_INVITE_FORM_RESET
  };
};
