export const USER_UPLOAD_FONT_REQUEST = "API/ENTITIES/UPLOAD_FONT_REQUEST";
export const USER_UPLOAD_FONT_REQUEST_SUCCESS =
  "API/ENTITIES/UPLOAD_FONT_REQUEST_SUCCESS";
export const USER_UPLOAD_FONT_REQUEST_FAILURE =
  "API/ENTITIES/UPLOAD_FONT_REQUEST_FAILURE";

export const USER_TEAM_FONT_REMOVE_REQUEST =
  "API/ENTITIES/USER_TEAM_FONT_REMOVE_REQUEST";
export const USER_TEAM_FONT_REMOVE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_FONT_REMOVE_REQUEST_SUCCESS";
export const USER_TEAM_FONT_REMOVE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_FONT_REMOVE_REQUEST_FAILURE";
