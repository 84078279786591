export const archivedApiSelector = state => state.api.designs.team.archived;
export const isAnyArchivedTemplates = state => {
  const templatePages = archivedApiSelector(state).pages;

  // will assume false until ids are loaded
  return (
    templatePages &&
    Object.values(templatePages).length > 0 &&
    templatePages[1].ids &&
    templatePages[1].ids.length > 0
  );
};

export const teamArchivedDesignApiPageSelector = (state, page) => {
  return state.api.designs.team.archived.pages[page];
};
