import { getParameterByName } from "lib/queryStringUtils";
import { getPath } from "lib";
import {
  SUBTAB_TYPES,
  SUBTAB_TYPES_MAP,
  ANIMATIONS_SIDEBAR_SUBTABS
} from "views/components/Editor/sidebar/tabs/animations/animationsTabConstants";
import { ANIMATION_ASSET_TYPE_MAP } from "lib/constants";
import { formatDesignAsset } from "lib/designUtils";

export const getAssetTypeFromQueryParams = params => {
  let mediaType = SUBTAB_TYPES.ALL;

  const assetType = getParameterByName("assetType", params);

  if (assetType) {
    mediaType =
      assetType === SUBTAB_TYPES.ANIMATIONS
        ? SUBTAB_TYPES_MAP.stickers
        : SUBTAB_TYPES_MAP.videos;
  }

  return mediaType;
};

export const getStatePropertyFromAnimationType = type => {
  return type === ANIMATION_ASSET_TYPE_MAP.ANIMATION
    ? SUBTAB_TYPES_MAP.stickers
    : SUBTAB_TYPES_MAP.videos;
};

/**
 * @desc takes an object containing all animations in a design, and the video subTab the user has navigated to
 * and formats those animations to be passed into the sidebar for render in the bottom section of the subTab
 * subTab is either stickers or videos
 * @returns an arry of subTab specific animated asset objects
 */
export const formatAnimationsInDesign = ({ designAnimations, subTab }) => {
  const returnAssetsInDesign = asset => {
    const designAssets = getPath(designAnimations, asset, {});
    const assetsEntities = Object.values(designAssets);
    return (
      assetsEntities.map(assetAnimation => formatDesignAsset(assetAnimation)) ||
      []
    );
  };

  return returnAssetsInDesign(ANIMATIONS_SIDEBAR_SUBTABS[subTab].assetType);
};

/**
 * @desc takes the current sub tab, provided animations for context, current search term and provided animation search results
 * @returns an array of the animations filtered into the asset type specific to the sub tab
 * e.g. Stickers subtab requires filtering of assets to only return animations (gifs)
 * Videos subtab requires filtering of assets to only return videos
 */
export const getAnimationsForSubTab = ({
  subTab,
  animations,
  searchTerm,
  searchAnimations
}) => {
  const selectedSubTab = ANIMATIONS_SIDEBAR_SUBTABS[subTab];
  let animationsForContext = animations[selectedSubTab.assetType];

  if (searchTerm) {
    const selectedSubTabContentType = selectedSubTab.contentType;
    animationsForContext =
      searchAnimations[selectedSubTabContentType][searchTerm];
  }

  return animationsForContext || [];
};
