import React, { Component } from "react";
/* import PropTypes from 'prop-types';*/
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import TextMaskResizeHandler from "views/components/Editor/textMaskResizeHandler/TextMaskResizeHandlerContainer";
import { Image } from "views/components";
import ResizeBox from "views/components/Editor/ResizeBox";
import { rotatePoint } from "lib/geometry/rotation";

const TextMaskingDraggableImageSource = {
  beginDrag(props, monitor, component) {
    props.startCropMoving();

    return {};
  },
  endDrag(props, monitor) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

    const rotatedOffset = rotatePoint(
      differenceFromInitialOffset.x,
      differenceFromInitialOffset.y,
      0,
      0,
      -props.textElement.angle
    );

    props.finishCropMoving({
      differenceFromInitialOffset: {
        ...differenceFromInitialOffset,
        ...rotatedOffset
      }
    });
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

class TextMaskingDraggableImage extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  cancelClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  render() {
    const { connectDragSource, textElement, isCropping, zoom } = this.props;

    return connectDragSource(
      <div
        onClick={this.cancelClick}
        style={{
          position: "absolute",
          transform: [`rotate(${textElement.angle}deg)`].join(" "),
          top: textElement.top * zoom,
          left: textElement.left * zoom,
          height: `${textElement.height * zoom}px`,
          width: `${textElement.width * zoom}px`
        }}
      >
        <div
          style={{
            position: "absolute",
            top: textElement.maskImage.top * zoom,
            left: textElement.maskImage.left * zoom,
            zIndex: 4,
            opacity: isCropping ? 0 : 1
          }}
        >
          <Image
            src={textElement.maskImage.previewSrc || textElement.maskImage.src}
            height={textElement.maskImage.height * zoom}
            width={textElement.maskImage.width * zoom}
            filter="brightness(0.5)"
          />
          <ResizeBox
            Handler={TextMaskResizeHandler}
            isResizing={false}
            isCropping={true}
          />
        </div>
      </div>
    );
  }
}

/* TextMaskingDraggableImage.propTypes = propTypes;*/

// Export the wrapped component:
export default DragSource(
  "TEXT_MASK_IMAGE",
  TextMaskingDraggableImageSource,
  collect
)(TextMaskingDraggableImage);
