import { confirm } from "lib/ConfirmationModal/ConfirmationModal";

import style from "./style.module.css";

const confirmDeleteMedia = (onClickDelete, onCancelDelete, smartText) => {
  let headerText;
  let bodyText;
  let buttonText;
  if (smartText.overridden) {
    headerText = "Revert to Default Value";
    bodyText =
      "Are you sure you want to revert this Smart Text to its default value?";
    buttonText = "Revert";
  } else {
    headerText = "Delete Smart Text?";
    bodyText = "Are you sure you want to delete this Smart Text?";
    buttonText = "Delete";
  }
  confirm({
    header: headerText,
    text: bodyText,
    onOk: onClickDelete,
    onCancel: onCancelDelete,
    buttonsDetails: {
      ok: {
        theme: "redSolid",
        className: style.button,
        name: buttonText
      },
      cancel: {
        theme: "gray",
        className: style.button,
        name: "Cancel"
      },
      buttonsClassName: style.deleteConfirmationModalButtons
    },
    modalClassName: style.deleteConfirmationModal
  });
};

export default confirmDeleteMedia;
