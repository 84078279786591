import * as designActionsEntitiesTypes from "state/entities/designActions/designActionsTypes";

import { immutableUpdate } from "lib/immutableUpdate";

export const initState = {
  designActions: {}
};

const designActionsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designActionsEntitiesTypes.DESIGN_APPROVAL_ACTIONS_REQUEST: {
      return immutableUpdate(state, {
        [action.extra.designId]: {
          $auto: {
            pages: {
              $auto: {
                [action.request.page]: {
                  $auto: {
                    $merge: {
                      isFetching: true
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case designActionsEntitiesTypes.DESIGN_APPROVAL_ACTIONS_REQUEST_FAILURE: {
      return immutableUpdate(state, {
        [action.extra.designId]: {
          $auto: {
            pages: {
              $auto: {
                [action.request.page]: {
                  $auto: {
                    $merge: {
                      isFetching: false
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case designActionsEntitiesTypes.DESIGN_APPROVAL_ACTIONS_REQUEST_SUCCESS: {
      return immutableUpdate(state, {
        [action.extra.designId]: {
          $auto: {
            pages: {
              $auto: {
                [action.request.page]: {
                  $auto: {
                    $merge: {
                      isFetching: false,
                      ids: action.response.ids || []
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default designActionsApiReducers;
