import { createSelector } from "reselect";
import { isEmpty, isNil } from "lib";

const teamsSearchSelector = state => state.api.teamsSearch;
const teamsEntitiesSelector = state => state.entities.teams;

const processGetCurrentTeamsSearch = (teamsSearch, teams) => {
  const { currentSearchTerm, results } = teamsSearch;

  if (isEmpty(currentSearchTerm)) {
    return null;
  }

  const currentSearchResults = results[currentSearchTerm];

  if (!currentSearchResults) {
    return null;
  }

  if (isNil(currentSearchResults.pages[1].ids)) {
    return null;
  }

  const resultIds = [];

  const pages = currentSearchResults.pages;

  Object.keys(pages)
    .sort()
    .map(pageNumber => resultIds.push(...pages[pageNumber].ids));

  const resultTeams = resultIds.map(teamId => teams[teamId]);

  return resultTeams;
};

export const getCurrentTeamsSearch = createSelector(
  [teamsSearchSelector, teamsEntitiesSelector],
  processGetCurrentTeamsSearch
);

export const teamsApiDetailedIdSelector = (state, id) => {
  return state.api.teams.detailedIds[id];
};
