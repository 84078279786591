import React from "react";
import PropTypes from "prop-types";
import { noop, composeClassName } from "lib";
import style from "./style.module.css";

const ToggleCheckbox = ({
  className,
  label = "",
  checked,
  onChange = noop,
  height,
  width = 48,
  ...props
}) => {
  const dynamicStyle = {
    height: height || `${parseInt(width, 10) / 1.765}px` || "34px",
    width: width || `${parseInt(height, 10) * 1.765}px` || "60px"
  };

  return (
    <label
      className={composeClassName("switch", style, className)}
      style={dynamicStyle}
      data-testid="ToggleCheckbox"
    >
      <input
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        type="checkbox"
      />
      <span className={composeClassName("slider", style, className)} />
      <svg
        height={dynamicStyle.height}
        viewBox="0 0 40 28"
        width={dynamicStyle.width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.337 10.392a.9.9 0 011.403 1.12l-.077.096-5.5 6a.9.9 0 01-1.24.083l-.086-.083-2.5-2.727a.9.9 0 011.238-1.3l.088.084L8.5 15.668l4.838-5.276zM35.036 9.864a.9.9 0 01.08 1.18l-.08.092L32.173 14l2.863 2.864a.9.9 0 01-1.18 1.353l-.092-.08-2.864-2.864-2.864 2.863a.9.9 0 01-1.353-1.18l.08-.092L29.628 14l-2.863-2.864a.9.9 0 011.18-1.353l.092.08 2.864 2.864 2.864-2.863a.9.9 0 011.272 0z" />
      </svg>
    </label>
  );
};
ToggleCheckbox.propTypes = {
  className: PropTypes.string
};

export default ToggleCheckbox;
