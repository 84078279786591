import { combineReducers } from "redux";

import { personalApiReducers as personal } from "./personal";
import { teamApiReducers as team } from "./team";
import { mediaFoldersApiReducers as media } from "./media";

import { CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS } from "state/currentUser/currentUserTypes";
const apiFoldersReducers = combineReducers({ personal, team, media });

const apiFoldersReducersWithReset = (state, action) => {
  if (action.type === CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS) {
    state = undefined;
  }

  return apiFoldersReducers(state, action);
};

export default apiFoldersReducersWithReset;
