import React from "react";
import PropTypes from "prop-types";
import { DropDownLink, Button, Popout } from "views/components";
import PlusIcon from "views/components/icons/PlusIcon";
import CaretIcon from "views/components/icons/CaretIcon";

import style from "./style.module.css";
import { composeClassName } from "lib";

export class ButtonDropDown extends React.Component {
  static defaultProps = {
    topOffset: 8,
    popoutWidth: 200
  };

  constructor(props) {
    super(props);

    this.toggleDropDown = this.toggleDropDown.bind(this);

    this.state = {
      displayDropdown: false,
      dropdownRef: React.createRef()
    };
  }

  toggleDropDown() {
    this.setState({
      displayDropdown: !this.state.displayDropdown
    });
  }

  getPopoutTop() {
    const { topOffset } = this.props;
    const { offsetHeight } = this.state.dropdownRef.current;
    return offsetHeight + topOffset;
  }

  getPopoutLeft() {
    const { popoutWidth, direction } = this.props;
    const {
      offsetWidth
    } = this.state.dropdownRef.current.firstChild.firstChild;

    if (direction === "left") {
      return -Math.abs(popoutWidth - offsetWidth);
    }
    return 0;
  }

  render() {
    const { displayDropdown } = this.state;

    const { label, className, children, popoutWidth, parentWidth } = this.props;

    return (
      <div
        className={style.buttonDropDownWrapper}
        ref={this.state.dropdownRef}
        data-testid="ButtonDropDown"
      >
        <DropDownLink
          className={composeClassName("dropDownLink", style, className)}
          onClick={this.toggleDropDown}
        >
          <Button
            theme="blueSolid"
            className={composeClassName("button", style, className)}
          >
            <PlusIcon color="white" size="12px" />
            <div className={style.buttonLabel}>{label}</div>
            <CaretIcon
              color="white"
              size="12px"
              direction="down"
              strokeWidth="1"
            />
          </Button>
        </DropDownLink>
        {displayDropdown && (
          <Popout
            maxHeight="450px"
            width={popoutWidth}
            top={this.getPopoutTop()}
            parentWidth={parentWidth}
            calculateOffsetFromDirection="top"
            onClose={this.toggleDropDown}
            left={this.getPopoutLeft()}
            className={style.dropDownPopout}
          >
            {children}
          </Popout>
        )}
      </div>
    );
  }
}

ButtonDropDown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  popoutWidth: PropTypes.number,
  parentWidth: PropTypes.number,
  direction: PropTypes.string,
  topOffset: PropTypes.number
};

export default ButtonDropDown;
