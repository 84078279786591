import * as pendingUsersEntitiesTypes from "state/entities/pendingUsers/pendingUsersTypes";
import { SWITCH_TEAM } from "state/ui/uiTypes";
import * as pendingUsersApiTypes from "state/api/pendingUsers/pendingUsersApiTypes";
import * as teamsTypes from "state/entities/teams/teamsTypes";
import { findPage } from "state/api/designs/helpers";
import { omit } from "lib";
import { removeItem } from "lib/array/array";

export const initState = {
  pages: {
    1: {
      isFetching: false,
      ids: [],
      didInvalidate: false
    }
  },
  detailedIds: {},
  totalCount: 0,
  pageSize: 10,
  isProcessing: false
};

const pendingUsersApiReducers = (state = initState, action) => {
  switch (action.type) {
    case pendingUsersEntitiesTypes.PENDING_USERS_REQUEST: {
      const { page } = action.request;
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: {
            ...state.pages[page],
            isFetching: true
          }
        }
      };
    }

    case pendingUsersEntitiesTypes.PENDING_USERS_TOTAL_REQUEST_SUCCESS: {
      return {
        ...state,
        totalCount: action.response.count
      };
    }

    case pendingUsersEntitiesTypes.PENDING_USERS_REQUEST_SUCCESS: {
      const { ids = [] } = action.response;
      const updatedPage = {
        [action.request.page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false
        }
      };

      return {
        ...state,
        pages: { ...state.pages, ...updatedPage }
      };
    }

    case pendingUsersApiTypes.PENDING_USERS_API_PAGE_SIZE_CHANGE: {
      const invalidPages = {};

      Object.keys(state.pages).forEach(pageId => {
        invalidPages[pageId] = { ...state.pages[pageId], didInvalidate: true };
      });

      return {
        ...state,
        pages: invalidPages,
        pageSize: action.payload.pageSize
      };
    }

    case pendingUsersApiTypes.PENDING_USERS_RESEND_REQUEST:
    case pendingUsersApiTypes.PENDING_USERS_RESEND_ALL_REQUEST:
    case pendingUsersApiTypes.PENDING_USERS_CANCEL_ALL_REQUEST:
    case pendingUsersApiTypes.PENDING_USERS_CANCEL_REQUEST: {
      return {
        ...state,
        isProcessing: true
      };
    }

    case pendingUsersApiTypes.PENDING_USERS_RESEND_ALL_REQUEST_SUCCESS:
    case pendingUsersApiTypes.PENDING_USERS_CANCEL_ALL_REQUEST_FAILURE:
    case pendingUsersApiTypes.PENDING_USERS_RESEND_REQUEST_SUCCESS:
    case pendingUsersApiTypes.PENDING_USERS_RESEND_REQUEST_FAILURE:
    case pendingUsersApiTypes.PENDING_USERS_CANCEL_REQUEST_FAILURE: {
      return {
        ...state,
        isProcessing: false
      };
    }

    case pendingUsersApiTypes.PENDING_USERS_CANCEL_REQUEST_SUCCESS: {
      const {
        extra: { invitationId }
      } = action;

      const pageIdx = findPage(state.pages, invitationId);
      if (!pageIdx) return state;

      const invitationIndex = state.pages[pageIdx].ids.indexOf(invitationId);

      const pageUpdated = {
        ...state.pages[pageIdx],
        ids: removeItem(state.pages[pageIdx].ids, invitationIndex)
      };

      let pages = {
        ...state.pages,
        [pageIdx]: pageUpdated
      };

      // if we removed the last invite on a page we should remove the page
      if (!pageUpdated.ids.length) {
        pages = omit(pages, pageIdx);
      }

      return {
        ...state,
        isProcessing: false,
        totalCount: state.totalCount - 1,
        pages
      };
    }

    case teamsTypes.TEAMS_INVITE_USERS_REQUEST_SUCCESS: {
      const { ids } = action.response;
      // Filter out the invitations that failed and are handled elsewhere.
      const successfulIds = ids.filter(id => !id.includes("temp"));

      const pages = state.pages;

      const lastPageNumber = Object.keys(pages).pop();
      const lastPage = pages[lastPageNumber];
      const isLastPageFull = lastPage.ids.length >= state.pageSize;

      let updatedPages = { ...pages };

      if (!isLastPageFull) {
        let idStorage = successfulIds;

        let updatedPage = {
          ...pages[lastPageNumber]
        };

        const availableSpace = state.pageSize - updatedPage.ids.length;
        const numIdsToInsertInPage = Math.min(availableSpace, idStorage.length);
        const newIdsForPage = idStorage.slice(0, numIdsToInsertInPage);

        updatedPage.ids = updatedPage.ids.concat(newIdsForPage);
        updatedPages[lastPageNumber] = updatedPage;
      }

      return {
        ...state,
        pages: updatedPages,
        totalCount: state.totalCount + successfulIds.length
      };
    }

    case pendingUsersApiTypes.PENDING_USERS_CANCEL_ALL_REQUEST_SUCCESS:
    case SWITCH_TEAM: {
      return initState;
    }

    default:
      return state;
  }
};

export default pendingUsersApiReducers;
