import { getGridPlaceholderSize } from "./imageInstructionUtils.js";
import { INHERITED_IMAGE_INSTRUCTION_PROPERTIES } from "lib/constants";
import { pick } from "lib";

export const calculateUpdatedGridImageInstructions = async ({
  imageInstruction,
  element,
  originalImageInstruction
}) => {
  const gridCellSize = getGridPlaceholderSize(imageInstruction, element);
  await imageInstruction.imageElementBuilderPromise;

  const scaleToFit = Math.max(
    gridCellSize.width / imageInstruction.width,
    gridCellSize.height / imageInstruction.height
  );

  const imageScaled = {
    height: imageInstruction.height * scaleToFit,
    width: imageInstruction.width * scaleToFit
  };

  return {
    ...originalImageInstruction,
    ...imageInstruction,
    ...imageScaled,
    price: imageInstruction.price,
    top: (gridCellSize.height - imageScaled.height) / 2,
    left: (gridCellSize.width - imageScaled.width) / 2,
    ...pick(originalImageInstruction, INHERITED_IMAGE_INSTRUCTION_PROPERTIES)
  };
};
