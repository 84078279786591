import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CustomColorIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <path d="M0 0h16v16H0z" />
      <g fill={color || easilGrayXDark}>
        <path
          fillRule="nonzero"
          d="M2.705 13.675a.9.9 0 0 1 1.252-1.293A6.1 6.1 0 0 0 13.62 5.2a.9.9 0 0 1 1.598-.828 7.9 7.9 0 0 1-12.514 9.304z"
        />
        <path d="M1.96 6l.41-2.2H1.6a.9.9 0 0 1 0-1.8h1.107l.22-1.184A1 1 0 0 1 3.912 0h.104a.776.776 0 0 1 .762.918L4.575 2h2.132l.22-1.184A1 1 0 0 1 7.912 0h.104a.776.776 0 0 1 .762.918L8.575 2H9.8a.9.9 0 1 1 0 1.8H8.24L7.828 6H9.1a.9.9 0 0 1 0 1.8H7.491l-.258 1.384A1 1 0 0 1 6.25 10h-.104a.776.776 0 0 1-.762-.918l.24-1.282H3.49l-.258 1.384A1 1 0 0 1 2.25 10h-.104a.776.776 0 0 1-.762-.918l.24-1.282H.9A.9.9 0 0 1 .9 6h1.06zm1.868 0h2.131l.411-2.2H4.24L3.828 6z" />
      </g>
    </g>
  </svg>
);
CustomColorIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CustomColorIcon;
