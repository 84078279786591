import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import Loading from "../loading";

const Button = ({
  children,
  theme = "blue",
  disabledTheme = null,
  className,
  onClick,
  disabled,
  isWide,
  isExtraWide,
  color,
  isFullWidth,
  isHoverable,
  style: customStyle = {},
  isLoading,
  ...props
}) => {
  let dynamicStyle = {
    justifyContent: Array.isArray(children) ? "space-between" : "center",
    ...customStyle
  };

  if (!disabled) {
    dynamicStyle = {
      ...dynamicStyle,
      backgroundColor: color,
      border: `solid 1px ${color}`
    };
  }

  const dynamicTheme = disabled ? disabledTheme : theme;

  return (
    <button
      {...props}
      onClick={onClick}
      className={`${style.button} ${style[dynamicTheme]} ${className}`}
      style={dynamicStyle}
      disabled={disabled}
      data-is-wide={isWide}
      data-is-extra-wide={isExtraWide}
      data-disabled={disabled}
      data-is-full-width={isFullWidth}
      data-is-hoverable={isHoverable}
      data-testid="Button"
    >
      {isLoading ? (
        <Loading hexColor={disabled ? "#000000" : "#D1D4D6"} />
      ) : (
        children
      )}
    </button>
  );
};
Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  theme: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hoverable: PropTypes.bool
};

export default Button;
