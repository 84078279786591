import React, { Component } from "react";
import { connect } from "react-redux";
import {
  uploadUserTeamImage,
  addUploadingImagesPlaceholders
} from "state/entities/userTeamImages/userTeamImagesActions";
import { uploadImageToFolder } from "state/entities/imageFolders/imageFoldersActions";
import ImagesListDragAndDropUpload from "./ImagesListDragAndDropUpload";
import { makeToast } from "state/ui/toaster/ToasterActions";
import {
  handleUploadAnimation,
  handleUploadAnimationToFolder,
  handleUploadingAnimationsPlaceholders
} from "state/ui/workspace/animationActions";
import { getUserSubscriptionPlan } from "state/entities/subscriptionPlans/subscriptionPlansSelectors";
import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import Subscriptions from "lib/subscriptions";

export class ImagesListDragAndDropUploadContainer extends Component {
  render() {
    return <ImagesListDragAndDropUpload {...this.props} />;
  }
}

const mapStateToProps = state => {
  const userSubscriptionPlan = getUserSubscriptionPlan(state);
  const currentSubscription = currentSubscriptionPlan(state);
  const canAccessBrandKit = Subscriptions.get(
    currentSubscription.code
  ).getCanAccessBrandKit();

  return {
    isBasicSubscription: userSubscriptionPlan.isBasicPlan,
    canAccessBrandKit
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { isBrandAnimations } = ownProps;
  return {
    onUploadUserTeamImage: args => dispatch(uploadUserTeamImage(args)),
    addUploadingImagesPlaceholders: args =>
      dispatch(addUploadingImagesPlaceholders(args)),
    addUploadingAnimationsPlaceholders: args =>
      dispatch(
        handleUploadingAnimationsPlaceholders({ isBrandAnimations, ...args })
      ),
    onUploadImageToFolder: args => dispatch(uploadImageToFolder(args)),
    onUploadAnimation: args =>
      dispatch(handleUploadAnimation({ isBrandAnimations, ...args })),
    onUploadAnimationToFolder: args =>
      dispatch(handleUploadAnimationToFolder({ isBrandAnimations, ...args })),
    invalidFileToast: args => dispatch(makeToast(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagesListDragAndDropUploadContainer);
