import { cloneDeep } from "lib/lodash";
import { getAnimationData } from "lib/animatedElementUtils";

const DesignGridCropOps = {
  /* removeTextMask({ elementId }) {
   *   const textElement = this.getElement(elementId).removeTextMask();

   *   return new this.constructor({
   *     ...this,
   *     elements: {
   *       ...this.elements,
   *       [elementId]: textElement,
   *     },
   *     version: this.version + 1,
   *   });
   * },*/

  /* addTextMask({ elementId, imageElement }) {
   *   const textElement = this.getElement(elementId).addTextMask(imageElement);

   *   return new this.constructor({
   *     ...this,
   *     elements: {
   *       ...this.elements,
   *       [elementId]: textElement,
   *     },
   *     version: this.version + 1,
   *   });
   * },*/

  updateImageInstructions({ elementId, imageInstructions }) {
    const element = this.getElement(elementId);
    const updatedElement = element.updateImageInstructions({
      imageInstructions
    });
    const elementPageId = this.getElementPageId(updatedElement.uniqueId);
    let elementPage = cloneDeep(this.pages[elementPageId]);

    // when new image instructions contain animations
    // updated pageAnimatedElements object
    if (imageInstructions.map(instruction => instruction.duration)) {
      const animationData = getAnimationData({
        ...updatedElement,
        pageId: elementPageId
      });
      let updatedPageAnimatedElements = elementPage.animatedElements;
      // map through each animation in an element and append to pageAnimatedElements object
      animationData.forEach(animation => {
        updatedPageAnimatedElements[animation.animationDataKey] = animation;
      });

      elementPage = {
        ...elementPage,
        animatedElements: updatedPageAnimatedElements
      };
    }

    // remove keys from animatedElements object and update page duration
    // when an animation imageInstruction has been replaced by a static image
    const prevImageInstructionsHaveAnimations = element.imageInstructions.some(
      instruction => instruction.duration
    );
    const imageInstructionsHaveAnimations = updatedElement.imageInstructions.every(
      instruction => instruction.duration
    );
    if (
      prevImageInstructionsHaveAnimations &&
      !imageInstructionsHaveAnimations
    ) {
      const prevAnimationDataKeys = getAnimationData({
        ...element,
        pageId: elementPageId
      }).map(animation => animation.animationDataKey);
      const updatedAnimationDataKeys = getAnimationData({
        ...updatedElement,
        pageId: elementPageId
      }).map(animation => animation.animationDataKey);
      const keysToBeRemoved = prevAnimationDataKeys.filter(
        key => !updatedAnimationDataKeys.includes(key)
      );
      keysToBeRemoved.forEach(key => delete elementPage.animatedElements[key]);
    }

    return new this.constructor({
      ...this,
      elements: {
        ...this.elements,
        [elementId]: updatedElement
      },
      pages: {
        ...this.pages,
        [elementPageId]: elementPage
      },
      version: this.version + 1
    });
  }
};

export default DesignGridCropOps;
