import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FolderWideIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  strokeWidth = "2"
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 18}
    height={height || size || 16}
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="FolderWideIcon"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <path
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M17 13.444c0 .86-.716 1.556-1.6 1.556H2.6c-.884 0-1.6-.696-1.6-1.556V2.556C1 1.696 1.716 1 2.6 1h4l1.6 2.333h7.2c.884 0 1.6.697 1.6 1.556v8.555z"
      />
    </g>
  </svg>
);
FolderWideIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FolderWideIcon;
