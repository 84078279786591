import React from "react";
import { InputTransparent, InputNumber } from "views/components";
import style from "./style.module.css";
import { omit, noop } from "lib";

export class LabelFloatInput extends React.Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getInputField = this.getInputField.bind(this);
    this.initInput = this.initInput.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.state = {
      isActive: false,
      isInit: false
    };
  }

  initInput() {
    const currentElement = this.wrapperRef.current;
    if (currentElement) {
      // get the information about the initial state of the input field used to calculate the dynamic css
      const elementType = this.props.type === "textarea" ? "textarea" : "input";
      const currentInput = currentElement.querySelector(elementType);
      const inputComputedStyle = getComputedStyle(currentInput);

      currentElement.style.setProperty(
        "--label-float-height",
        this.props.height
      );
      currentElement.style.setProperty(
        "--label-float-margin-x",
        inputComputedStyle["margin-left"]
      );
      currentElement.style.setProperty(
        "--label-float-margin-y",
        inputComputedStyle["margin-top"]
      );
    }

    if (this.props.value && !this.state.isActive) {
      this.setState({
        isActive: true
      });
    }

    this.setState({
      isInit: true
    });
  }

  componentDidMount() {
    if (this.props.height && this.props.height !== "0px") {
      this.initInput();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && !this.state.isActive) {
      // if there is a value set to active so label doesn't overlap value
      this.setState({
        isActive: true
      });
    }
    if (this.props.height !== prevProps.height && !this.state.isInit) {
      this.initInput();
    }
  }

  handleOnChange(event) {
    // ensure clearing the value sets to inactive and adding a value sets to active
    if (event.target.value && !this.state.isActive) {
      this.setState({
        isActive: true
      });
    } else if (!event.target.value && this.state.isActive) {
      this.setState({
        isActive: false
      });
    }
    this.props.onChange(event);
  }

  handleFocus() {
    const { onFocus = noop } = this.props;

    onFocus();
  }

  getInputField() {
    switch (this.props.type) {
      case "textarea": {
        return (
          <textarea
            {...omit(this.props, ["wrapperClassName", "customWrapperStyle"])}
            onChange={this.handleOnChange}
          />
        );
      }
      case "number": {
        return (
          <InputNumber
            currentValue={this.props.value}
            onBlur={value => {
              this.props.onBlur({ target: { value } });
            }}
            onChange={value => {
              this.handleOnChange({ target: { value } });
            }}
            min={this.props.min || 1}
            max={this.props.max}
            step={1}
            isBlankAllowed={true}
            onFocusIn={this.handleFocus}
          />
        );
      }
      case "text":
      default: {
        return (
          <InputTransparent
            {...this.props}
            type={this.props.type}
            onChange={this.handleOnChange}
            onFocusIn={this.handleFocus}
          />
        );
      }
    }
  }

  render() {
    const {
      label,
      wrapperClassName,
      error,
      customWrapperStyle,
      type
    } = this.props;
    const { isActive } = this.state;

    return (
      <div
        className={[
          style.floatLabel,
          isActive && style.activeText,
          wrapperClassName
        ]
          .filter(x => x)
          .join(" ")}
        ref={this.wrapperRef}
        style={customWrapperStyle}
      >
        <div id={`${type}-${label}`}>
          {this.getInputField()}
          <label htmlFor="floating-input">{label}</label>
        </div>
        <div className={style.error}>{error && error.description}</div>
      </div>
    );
  }
}

LabelFloatInput.displayName = "LabelFloatInput";

export default LabelFloatInput;
