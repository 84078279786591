import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";
import { findPage } from "state/api/helpers";
import { removeItem } from "lib/array/array";
import { immutableUpdate } from "lib/immutableUpdate";
import { getParameterByName } from "lib/queryStringUtils";
import PATHS from "routes/paths";

const sizeInitState = {
  pages: {}
};

export const initState = {
  sizes: {
    all: sizeInitState
    /* other sizes will populate this list, on demand as we don't know
     *  what sizes the user has */
  },
  pageSize: 50
};

const sharedApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_SHARED_REQUEST: {
      const size = action.request.params.size || "all";

      const sizeState = state.sizes[size] || sizeInitState;

      const newSizeState = handleDesignRequest({ state: sizeState, action });

      return {
        ...state,
        sizes: {
          ...state.sizes,
          [size]: newSizeState
        }
      };
    }

    case designsEntitiesTypes.DESIGNS_SHARED_REQUEST_SUCCESS: {
      const size = action.request.params.size || "all";

      const sizeState = state.sizes[size] || sizeInitState;

      const newSizeState = handleDesignRequestSuccess({
        state: sizeState,
        action
      });

      return {
        ...state,
        sizes: {
          ...state.sizes,
          [size]: newSizeState
        }
      };
    }

    case designsEntitiesTypes.DESIGN_UNSHARE_REQUEST_SUCCESS: {
      // when a design is unshared and it is the current user, remove it from the shared api state
      const { isUserRemovingSelf } = action.request.extra;

      if (!isUserRemovingSelf) return state;

      // re init the state when current user was removed
      return initState;
    }

    case designsEntitiesTypes.DESIGN_CANCEL_APPROVAL_REQUEST: {
      if (action.pathname !== PATHS.workspaceShared) {
        return state;
      }

      const { designId } = action.request.body;

      const allSizeState = state.sizes.all;

      let newIds = [designId].concat(
        allSizeState.pages[1] ? allSizeState.pages[1].ids : []
      );

      const updatedPage = {
        isFetching: false,
        ids: newIds,
        didInvalidate: false
      };

      return immutableUpdate(state, {
        sizes: {
          all: {
            pages: {
              1: { $set: updatedPage }
            }
          }
        }
      });
    }

    case designsEntitiesTypes.DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS: {
      if (action.pathname !== PATHS.workspaceShared) {
        return state;
      }
      const size = getParameterByName(action.queryParams) || "all";

      const {
        request: {
          body: { designId }
        }
      } = action;

      const sizeState = state.sizes[size];

      const page = findPage(sizeState.pages, designId);
      if (!page) return state;
      const imageIndex = sizeState.pages[page].ids.indexOf(designId);

      const pageUpdated = {
        ...sizeState.pages[page],
        ids: removeItem(sizeState.pages[page].ids, imageIndex)
      };

      return immutableUpdate(state, {
        sizes: {
          [size]: {
            pages: {
              [page]: { $set: pageUpdated }
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default sharedApiReducers;
