import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const DiagonalBarsIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 16}
    height={height || size || 16}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <rect
        fill={color || easilGrayXDark}
        mask="url(#b)"
        transform="rotate(-45 2.342 2.342)"
        x="-.408"
        y="1.342"
        width="5.5"
        height="2"
        rx="1"
      />
      <rect
        fill={color || easilGrayXDark}
        mask="url(#b)"
        transform="rotate(-45 5.17 5.17)"
        x="-1.58"
        y="4.17"
        width="13.5"
        height="2"
        rx="1"
      />
      <rect
        fill={color || easilGrayXDark}
        mask="url(#b)"
        transform="rotate(-45 7.999 7.999)"
        x="-2.751"
        y="6.999"
        width="21.5"
        height="2"
        rx="1"
      />
      <rect
        fill={color || easilGrayXDark}
        mask="url(#b)"
        transform="rotate(-45 10.827 10.827)"
        x="4.077"
        y="9.827"
        width="13.5"
        height="2"
        rx="1"
      />
      <rect
        fill={color || easilGrayXDark}
        mask="url(#b)"
        transform="rotate(-45 13.655 13.655)"
        x="10.905"
        y="12.655"
        width="5.5"
        height="2"
        rx="1"
      />
    </g>
  </svg>
);
DiagonalBarsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default DiagonalBarsIcon;
