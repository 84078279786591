import Subscriptions from "lib/subscriptions";
import { MEDIA_SORT_OPTIONS } from "lib/constants";
import { clickClosestAddFileInput } from "lib/input/mouse";
const TEAM_FOLDER_TYPE = "BRAND_LOGO";

const BrandKitLogoOps = {
  setQueryString(params) {
    const { modifyQuery } = this.props;
    const encodedFolderId = encodeURIComponent((params || {}).folderId || "");
    const encodedTerm = encodeURIComponent((params || {}).term || "");

    if (encodedFolderId) {
      modifyQuery({ appendTerms: { folderId: encodedFolderId } });
    } else if (encodedTerm) {
      modifyQuery({ appendTerms: { term: encodedTerm } });
    } else {
      modifyQuery({ dropTerms: ["term"] });
    }
  },

  handleSortUpdate(value) {
    this.props.setBrandKitState(
      {
        sortKey: value
      },
      "logos"
    );
  },

  onAddImageButtonClick(event) {
    const subscription = Subscriptions.get(this.props.currentSubscriptionCode);
    event.stopPropagation();
    if (!subscription.getCanAccessBrandKit()) {
      event.preventDefault();

      this.props.showUpgradeModal();
    } else {
      clickClosestAddFileInput(event.target);
    }
  },

  async handleAddLogo(fileObject, onSuccess) {
    const { qsFolderId, qsTerm } = this.props;

    const mediaResp = await this.props.addBrandKitLogo({
      teamId: this.props.currentTeam.id,
      file: fileObject,
      onSuccess
    });
    const sortOption = MEDIA_SORT_OPTIONS[this.props.sortKey];

    if (qsTerm) {
      this.setQueryString(null);
    } else if ((mediaResp || {}).mediaId && qsFolderId) {
      await this.props.setBrandKitState({ isProcessing: true }, "logos");

      await this.props.addContentToTeamFolder({
        contentId: mediaResp.mediaId,
        folderId: qsFolderId,
        teamId: this.props.currentTeam.id,
        type: TEAM_FOLDER_TYPE
      });

      await this.props.searchBrandKitLogos({
        folderId: qsFolderId,
        term: qsTerm,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });

      await this.props.setBrandKitState({ isProcessing: false }, "logos");
    }
  }
};

export default BrandKitLogoOps;
