import React from "react";
import { connect } from "react-redux";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";
import { teamSelector } from "state/entities/entitiesSelectors";
import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import auth from "lib/auth";
import { startUserSession } from "state/currentUser/currentUserActions";
import { FullLoadingPage } from "views/pages";
import {
  fetchTeamIfNeeded,
  fetchTeamSettingsIfNeeded
} from "state/entities/entitiesActions";
import { fetchSubscriptionsIfNeeded } from "../../state/entities/subscriptions/subscriptionsActions";
import { fetchSubscriptionPlansIfNeeded } from "../../state/entities/subscriptionPlans/subscriptionPlansActions";
import { fetchPublicFontsIfNeeded } from "../../state/entities/publicFonts/publicFontsActions";
import { fetchUserTeamFontsIfNeeded } from "../../state/entities/userTeamFonts/userTeamFontsActions";
import { fetchBrandKitFontsIfNeeded } from "../../state/ui/brandKit/BrandKitActions";
import { fetchPersonalTeamIfNeeded } from "state/personalTeam/personalTeamActions";
import ApplicationUpdateNotifier from "lib/ApplicationUpdateNotifier/ApplicationUpdateNotifier";
import { Logger } from "lib";

const WithUser = WrappedComponent => {
  class WithCurrentUserWrapper extends React.Component {
    componentDidMount() {
      this.props.startUserSession();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { currentUser, currentTeam, fetchTeamIfNeeded } = nextProps;

      if (!currentTeam && currentUser.token) {
        fetchTeamIfNeeded();

        const fetchFontsDelay = 5000;
        setTimeout(
          () => nextProps.fetchPublicFontsIfNeeded({ page: 1 }),
          fetchFontsDelay
        );
      }

      if (currentTeam && currentUser.token) {
        nextProps.fetchPersonalTeamIfNeeded({ userId: currentUser.id });
        nextProps.fetchBrandKitFontsIfNeeded(currentTeam.id);
        nextProps.fetchUserTeamFontsIfNeeded({
          teamId: currentTeam.id,
          userId: currentUser.id,
          page: 1
        });
        nextProps.fetchSubscriptionsIfNeeded(currentTeam.id);
        nextProps.fetchSubscriptionPlansIfNeeded();
        nextProps.fetchTeamSettingsIfNeeded({});
      }
    }

    componentDidUpdate() {
      const {
        currentUser,
        currentTeam,
        isUpdateNotificationShown
      } = this.props;

      const isUserPresent = currentTeam && currentUser.token;

      if (isUpdateNotificationShown && isUserPresent) {
        // we have changed pages and there is an update notification to show
        const swUrl = `${window.location.origin}/service-worker.js`;
        ApplicationUpdateNotifier.dispatchNotification(swUrl);
      }
    }

    render() {
      const { currentUser, currentTeam, currentPlan } = this.props;

      if (currentUser.logout) {
        Logger.warn("User Logged out, Push to login");

        window.location = auth.LOGIN_URL;
      }

      if (currentUser.isSwitchingToken) {
        return <FullLoadingPage />;
      }

      if (!currentUser.token || !currentTeam || !currentPlan) {
        return <FullLoadingPage />;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => ({
    currentUser: currentUserSelector(state),
    currentTeam: teamSelector({ state }),
    currentPlan: currentSubscriptionPlan(state),
    isUpdateNotificationShown: state.ui.updateNotification.visible,
    locationPathName: state.router.location.pathName
  });

  const mapDispatchToProps = dispatch => {
    return {
      startUserSession: args => dispatch(startUserSession(args)),
      fetchTeamIfNeeded: args => dispatch(fetchTeamIfNeeded(args)),
      fetchPublicFontsIfNeeded: args =>
        dispatch(fetchPublicFontsIfNeeded(args)),
      fetchBrandKitFontsIfNeeded: teamId =>
        dispatch(fetchBrandKitFontsIfNeeded({ teamId })),
      fetchUserTeamFontsIfNeeded: args =>
        dispatch(fetchUserTeamFontsIfNeeded(args)),
      fetchSubscriptionsIfNeeded: teamId =>
        dispatch(fetchSubscriptionsIfNeeded({ teamId })),
      fetchSubscriptionPlansIfNeeded: args =>
        dispatch(fetchSubscriptionPlansIfNeeded(args)),
      fetchPersonalTeamIfNeeded: args =>
        dispatch(fetchPersonalTeamIfNeeded(args)),
      fetchTeamSettingsIfNeeded: args =>
        dispatch(fetchTeamSettingsIfNeeded(args))
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithCurrentUserWrapper);
};

export default WithUser;
