import { createSelector } from "reselect";
import { isNil, getPath } from "lib";
import { getParameterByName } from "lib/queryStringUtils";
import { ANIMATIONS_SIDEBAR_SUBTABS } from "views/components/Editor/sidebar/tabs/animations/animationsTabConstants";

export const editorAnimationSearchPersonalSelector = state =>
  state.ui.editorAnimationSearch.personal;
const editorAnimationSearchStockSelector = state =>
  state.ui.editorAnimationSearch.stock;

const editorAnimationSearchBrandSelector = state =>
  state.ui.editorAnimationSearch.brand;

const getFolderedAnimationsByterm = ({
  animationsStore,
  folderId,
  canBeDeleted = false
}) => {
  const animationsByTerm = {
    VIDEO: {},
    ANIMATION: {}
  };

  Object.keys(animationsByTerm).forEach(type => {
    const folder = getPath(animationsStore, `folders.${folderId}.${type}`);
    if (!folder) {
      return animationsByTerm;
    }
    const terms = Object.keys(folder.terms);
    terms.forEach(term => {
      const termObject = folder.terms[term];

      const formatAnimation = animationId => {
        return {
          ...termObject.animations[animationId],
          canBeDeleted
        };
      };

      if (isNil(termObject.ids)) {
        animationsByTerm[type][term] = null;
      } else {
        animationsByTerm[type][term] = termObject.ids.map(formatAnimation);
      }
    });
  });

  return animationsByTerm;
};

const getAnimationsByTerm = ({ animationsStore, canBeDeleted = false }) => {
  const animationsByTerm = {
    VIDEO: {},
    ANIMATION: {}
  };

  const folderId = getParameterByName("folderId", window.location);

  if (folderId) {
    return getFolderedAnimationsByterm({
      animationsStore,
      folderId,
      canBeDeleted
    });
  } else {
    Object.keys(animationsByTerm).forEach(type => {
      const terms = Object.keys(animationsStore[type].terms);
      terms.forEach(term => {
        const termObject = animationsStore[type].terms[term];

        const formatAnimation = animationId => {
          return {
            ...termObject.animations[animationId],
            canBeDeleted
          };
        };

        if (isNil(termObject.ids)) {
          animationsByTerm[type][term] = null;
        } else {
          animationsByTerm[type][term] = termObject.ids.map(formatAnimation);
        }
      });
    });

    return animationsByTerm;
  }
};

export const getEditorUserAnimationsSearch = createSelector(
  [editorAnimationSearchPersonalSelector],
  animationsStore =>
    getAnimationsByTerm({ animationsStore, canBeDeleted: true })
);

export const getEditorStockSearch = createSelector(
  [editorAnimationSearchStockSelector],
  animationsStore => getAnimationsByTerm({ animationsStore })
);

export const getEditorBrandAnimationSearch = createSelector(
  [editorAnimationSearchBrandSelector],
  animationsStore => getAnimationsByTerm({ animationsStore })
);

export const isFetchingSearchAnimations = state => {
  const { personal, brand } = state.ui.editorAnimationSearch;
  const { currentTerm } = personal;
  const urlFolderParam = getParameterByName("folderId", window.location);
  const urlAssetType = getParameterByName("assetType", window.location);
  const statePropertyType = urlAssetType
    ? ANIMATIONS_SIDEBAR_SUBTABS[urlAssetType].contentType
    : "ALL";

  const checkIfIsFetching = stateType => {
    if (!stateType || !stateType.terms[currentTerm]) {
      return false;
    }
    return stateType.terms[currentTerm]?.isFetching;
  };

  let brandFolderState;
  if (urlFolderParam) {
    brandFolderState = getPath(
      brand.folders[urlFolderParam],
      statePropertyType
    );
  }

  const isFetchingUserAnimations =
    checkIfIsFetching(personal.ANIMATION) || checkIfIsFetching(personal.VIDEO);

  const isFetchingTeamAnimations =
    checkIfIsFetching(brand.ANIMATION) ||
    checkIfIsFetching(brand.VIDEO) ||
    checkIfIsFetching(brand.ALL) ||
    checkIfIsFetching(brandFolderState);

  return isFetchingUserAnimations || isFetchingTeamAnimations;
};
