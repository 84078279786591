import PubSub from "lib/PubSub";
import UploadingImages from "./UploadingImages";

class ImagesUploadModal {
  static EVENTS = {
    DOWNLOADS_STARTED: "DOWNLOADS_STARTED",
    ALL_DOWNLOADS_FINISHED: "ALL_DOWNLOADS_FINISHED"
  };

  constructor() {
    this.pubsub = new PubSub();
    this.EVENTS = ImagesUploadModal.EVENTS;
  }

  subscribe(name, fn, id) {
    return this.pubsub.subscribe(name, fn, id);
  }

  unsubscribe(name, fn, id) {
    return this.pubsub.unsubscribe(name, fn, id);
  }

  onStateChange(prevState, state) {
    const prevUploadingImages = new UploadingImages({
      uploads: prevState.uploadingImages
    });
    const uploadingImages = new UploadingImages({
      uploads: state.uploadingImages
    });

    if (this.hasDownloadsStarted(prevUploadingImages, uploadingImages)) {
      this.pubsub.publish(this.EVENTS.DOWNLOADS_STARTED, {
        folderId: state.selectedFolderId
      });
    }

    if (this.hasDownloadsFinished(prevUploadingImages, uploadingImages)) {
      this.pubsub.publish(this.EVENTS.ALL_DOWNLOADS_FINISHED);
    }
  }

  hasDownloadsFinished(prevUploadingImages, uploadingImages) {
    return (
      prevUploadingImages.hasOngoingUploads() &&
      !uploadingImages.hasFinishedUploads()
    );
  }

  hasDownloadsStarted(prevUploadingImages, uploadingImages) {
    return !prevUploadingImages.hasUploads() && uploadingImages.hasUploads();
  }
}

const instance = new ImagesUploadModal();

export default instance;
