import { capitalizeFirstLetter } from "lib/textUtils";
import { formatDateString } from "lib/temporal/dateUtils";
export const formatOrderStatus = orderStatus =>
  orderStatus
    .toLowerCase()
    .split("_")
    .map(segment => capitalizeFirstLetter(segment))
    .join(" ");
export const formatOrderDate = date =>
  formatDateString(date, "MMM DD YYYY, HH:mmA");
