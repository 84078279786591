import {
  TEAM_PAGE_USERS_PAGE_CHANGE,
  TEAM_PAGE_USERS_TABLE_CHANGE,
  TEAM_PAGE_PENDING_USERS_PAGE_CHANGE
} from "../uiTypes";
import { CURRENT_USER_SWITCH_TOKEN_REQUEST } from "state/currentUser/currentUserTypes";

export const initState = {
  users: {
    currentPage: 1,
    currentTable: "Team"
  },
  pendingUsers: {
    currentPage: 1,
    currentTable: "Pending"
  }
};

const teamPageReducers = (state = initState, action) => {
  switch (action.type) {
    case TEAM_PAGE_USERS_PAGE_CHANGE: {
      let page = action.payload.page;

      if (page === -1) {
        page = state.users.currentPage - 1 || 1;
      }

      return {
        ...state,
        users: {
          ...state.users,
          currentPage: page
        }
      };
    }

    case TEAM_PAGE_PENDING_USERS_PAGE_CHANGE: {
      let { page } = action.payload;

      if (page === -1) {
        page = state.pendingUsers.currentPage - 1 || 1;
      }

      return {
        ...state,
        pendingUsers: {
          ...state.pendingUsers,
          currentPage: page
        }
      };
    }

    case TEAM_PAGE_USERS_TABLE_CHANGE: {
      let type = action.payload.type;
      return {
        ...state,
        users: {
          ...state.users,
          currentTable: type
        }
      };
    }

    case CURRENT_USER_SWITCH_TOKEN_REQUEST: {
      return initState;
    }

    default:
      return state;
  }
};

export default teamPageReducers;
