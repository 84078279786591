import React, { Component } from "react";
import style from "./style.module.css";
import { getEmptyImage } from "react-dnd-html5-backend";
import { identity } from "lib";
import ImageCroppingResizeHandler from "../ImageCroppingResizeHandler";
import ResizeBox from "views/components/Editor/ResizeBox";
import PATHS from "routes/paths";

class MaskedImage extends Component {
  static defaultProps = {
    connectDragSource: identity
  };

  constructor(props) {
    super(props);

    this.getImageSource = this.getImageSource.bind(this);

    this.state = {
      isRenderer: PATHS.isRenderer(window.location.pathname)
    };
  }

  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  getImageMaskStyle() {
    const { imageElement, isCropping, zoom } = this.props;

    const { mask, scale, srcWidth, srcHeight } = imageElement;

    if (!mask) {
      return {};
    }

    const translateX = -mask.left * scale * zoom;
    const translateY = -mask.top * scale * zoom;

    const height = srcHeight * scale * zoom;
    const width = srcWidth * scale * zoom;

    return {
      opacity: isCropping ? 0 : 1,
      transformOrigin: "top left",
      transform: `translate(${translateX}px, ${translateY}px)`,
      filter: "brightness(0.5)",
      height,
      width
    };
  }

  getWrapperStyle({ imageElement, zoom }) {
    return {
      width: imageElement.width * zoom,
      height: imageElement.height * zoom,
      position: "absolute",
      transform: `translate(${imageElement.left * zoom}px, ${imageElement.top *
        zoom}px)`,
      transformOrigin: "0 0"
    };
  }

  getElementStyle({ imageElement, zoom }) {
    const styleProps = {
      transform: [
        `rotate(${imageElement.angle}deg)`,
        `scaleX(${imageElement.scaleX})`,
        `scaleY(${imageElement.scaleY})`
      ].join(" ")
    };

    const { height, opacity, width } = imageElement;

    const finalStyle = {
      ...styleProps,
      height: height * zoom,
      opacity: opacity,
      position: "relative",
      overflow: "show",
      width: width * zoom
    };

    return finalStyle;
  }

  getImageSource(imageElement) {
    if (this.state.isRenderer) {
      return imageElement.originalSrc;
    }
    return imageElement.previewSrc || imageElement.originalSrc;
  }

  render() {
    const { connectDragSource, imageElement, zoom, maskStyle } = this.props;

    return connectDragSource(
      <div style={this.getWrapperStyle({ imageElement, zoom })}>
        <div style={this.getElementStyle({ imageElement, zoom })}>
          <div style={{ ...this.getImageMaskStyle(), ...maskStyle }}>
            <img
              alt=""
              className={style.image}
              src={this.getImageSource(imageElement)}
            />
            <ResizeBox
              isResizing={false}
              Handler={ImageCroppingResizeHandler}
              isCropping={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MaskedImage;
