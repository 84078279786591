import React, { Component } from "react";
import { identity } from "lib/lodash";
import style from "./style.module.css";

class TableRowDropZone extends Component {
  static defaultProps = {
    connectDropTarget: identity,
    connectDragSource: identity,
    dragItemType: null
  };

  render() {
    const {
      connectDropTarget,
      position,
      dragItemType,
      isDragging
    } = this.props;

    if (
      (dragItemType !== "SIDEBAR_TABLE" && dragItemType !== "TABLE_ROW") ||
      isDragging
    ) {
      return null;
    }

    return connectDropTarget(
      <div className={style.tableRowDropZone} data-position={position} />
    );
  }
}

export default TableRowDropZone;
