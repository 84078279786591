import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const MagnifierIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  direction = "left"
}) => {
  const directionToScaleX = {
    right: 1,
    left: -1
  };

  const finalStyle = {
    ...style,
    transform: `scaleX(${directionToScaleX[direction]})`
  };

  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 2 20 18"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <path
        fill={color || easilGrayXDark}
        d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
      />
    </svg>
  );
};
MagnifierIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default MagnifierIcon;
