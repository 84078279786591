import React from "react";
import Button from "views/components/button/Button";
import userTracker from "lib/userTracker";

import style from "./style.module.css";

const AddNewPageButton = ({ canAddNewPage, onClick }) => {
  if (!canAddNewPage) {
    return null;
  }

  const onAddNewPageClick = () => {
    userTracker.track({
      event: userTracker.events.addPage,
      properties: {
        source: "add_new_page_button"
      }
    });

    onClick();
  };

  return (
    <div className={style.canvasBackgroundButton}>
      <Button isWide theme="canvas" onClick={onAddNewPageClick}>
        Add new page
      </Button>
    </div>
  );
};

export default AddNewPageButton;
