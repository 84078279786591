import { getDetailsForOrderById } from "state/entities/orders/ordersActions";
import { fetchAllPrintPricingForOrderId } from "state/entities/printPricing/printPricingActions";
import { asyncFetchShippingDetailsForOrder } from "state/entities/shippingDetails/shippingDetailsActions";

export const fetchAllDataForOrder = ({ orderId }) => async (
  dispatch,
  getState
) => {
  await dispatch(getDetailsForOrderById({ orderId }));
  await dispatch(fetchAllPrintPricingForOrderId({ orderId }));
  await dispatch(asyncFetchShippingDetailsForOrder({ orderId }));
};
