import React, { Component } from "react";
import { Link } from "react-router-dom";
import Message from "views/components/Message/Message";
import Button from "views/components/button";
import TeamRolesDropdown from "views/components/TeamRolesDropdown/TeamRolesDropdown";
import style from "./style.module.css";
import PropTypes from "prop-types";
import { userValidators, some } from "lib";
import { Input, Table, Scrollable } from "views/components";
import PlusIcon from "views/components/icons/PlusIcon";
import TrashIcon from "views/components/icons/TrashIcon";
import {
  newEntry,
  checkForDuplicatedEmails
} from "state/ui/inviteToTeamForm/helpers";

const commonStyles = {
  color: "#8f9297",
  marginRight: "12px",
  flex: "none"
};
const tableHeaders = [
  {
    name: "Name",
    style: {
      ...commonStyles,
      width: style.cell1Width
    }
  },
  {
    name: "Work Email",
    style: {
      ...commonStyles,
      width: style.cell2Width
    }
  },
  {
    name: "Role",
    style: {
      ...commonStyles,
      width: style.cell3Width
    }
  },
  { name: "", style: { width: style.cell4Width } }
];

export class InviteToTeamForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    handleChange: PropTypes.func,
    handleNewEntry: PropTypes.func,
    handleRemoveEntry: PropTypes.func,
    handleResetForm: PropTypes.func,
    displayCostInformation: PropTypes.bool,
    currentTeamSubscription: PropTypes.object,
    currentTeamSubscriptionPlan: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.pushEntryToList = this.pushEntryToList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateEntry = this.validateEntry.bind(this);
    this.isAnyEmailAlreadyInTeam = this.isAnyEmailAlreadyInTeam.bind(this);
    this.canShowInvitePricingMessage = this.canShowInvitePricingMessage.bind(
      this
    );
    this.getPricingMessage = this.getPricingMessage.bind(this);

    this.state = {
      currentEntry: newEntry()
    };
  }

  // validate an entry and apply errors to the current state, then return boolean validity
  validateEntry() {
    const { currentEntry } = this.state;

    const entryClone = { ...currentEntry };

    let isValid = true;

    Object.keys(currentEntry).forEach(attribute => {
      const { error } = userValidators[attribute](
        currentEntry[attribute].value
      );
      entryClone[attribute].error = error;
      if (error) isValid = false;
    });

    this.setState({
      currentEntry: entryClone
    });

    return isValid;
  }

  pushEntryToList() {
    const { currentEntry } = this.state;

    // check if the current entry is valid
    if (
      some(Object.values(currentEntry), entry => Boolean(entry.error)) ||
      !this.validateEntry()
    )
      return;

    this.props.handleNewEntry(currentEntry);
    this.setState({
      currentEntry: newEntry(),
      inputError: false
    });
  }

  componentWillUnmount() {
    this.props.handleResetForm();
  }

  handleChange(key, value) {
    const { currentEntry } = this.state;

    let updatedEntry = {
      ...currentEntry,
      [key]: {
        value
      }
    };

    this.setState({
      currentEntry: updatedEntry,
      inputError: true
    });
  }

  handleBlur(key, value) {
    const { form: { entries = [] } = {} } = this.props;
    const { currentEntry } = this.state;
    const { error } = userValidators[key](value);

    let updatedEntry = {
      ...currentEntry,
      [key]: {
        value,
        error
      }
    };

    // ensure for email changes we confirm that other entries are not duplicates
    if (key === "email") {
      const updatedEntries = [...entries, updatedEntry];

      const duplicateCheckResult = checkForDuplicatedEmails(updatedEntries);
      updatedEntry = duplicateCheckResult.pop();
    }

    this.setState({
      currentEntry: updatedEntry
    });
  }

  isAnyEmailAlreadyInTeam() {
    const { form: { entries = [] } = {} } = this.props;

    return entries.reduce(
      (isEmailErrorFound, currentEntry) =>
        isEmailErrorFound || currentEntry.email.error !== null,
      false
    );
  }

  isFormComplete() {
    const { email, name, roles } = this.state.currentEntry;

    const hasValidEmail = !!email.value && !email.error;
    const hasValidName = !!name.value && !name.error;
    const hasRole = roles.value.some(role => role.selected);

    /* Return boolean to toggle add team member button disabled state */
    return hasValidEmail && hasValidName && hasRole;
  }

  canShowInvitePricingMessage() {
    const {
      displayCostInformation,
      currentTeamSubscription,
      currentTeamSubscriptionPlan
    } = this.props;

    if (
      !(
        displayCostInformation ||
        currentTeamSubscription ||
        currentTeamSubscriptionPlan
      )
    )
      return false;

    return (
      currentTeamSubscription.status === "TRIALLING" ||
      currentTeamSubscriptionPlan.code === "BASIC" ||
      currentTeamSubscriptionPlan.code === "EDGE" ||
      currentTeamSubscriptionPlan.code === "PLUS"
    );
  }

  getPricingMessage() {
    const { currentTeamSubscription, currentTeamSubscriptionPlan } = this.props;

    if (currentTeamSubscription.status === "TRIALLING") {
      return (
        <>
          You won't be charged for additional team members during your free
          trial. When your trial ends, you'll be automatically downgraded to the
          free <b>Easil Basic</b> plan. To give your team access to Easil's
          premium features after your trial, you'll need to upgrade to a paid
          plan. View our paid plans in the{" "}
          <Link to="/subscription">Subscription</Link> page.
        </>
      );
    }

    if (currentTeamSubscriptionPlan.code === "BASIC") {
      return (
        <>
          You won't be charged for additional team members while you remain on
          the free <b>Easil Basic</b> plan. To give your team access to Easil's
          premium features, you'll need to upgrade to a paid plan. View our paid
          plans on the <Link to="/subscription">Subscription</Link> page.
        </>
      );
    } else {
      return (
        <>
          When a team member accepts their invitation, your organization will
          incur an extra{" "}
          {currentTeamSubscriptionPlan.intervalPeriod.toLowerCase()} recurring
          charge of <b>${currentTeamSubscription.amount}</b>. View more details
          on the <Link to="/subscription">Subscription</Link> page.
        </>
      );
    }
  }

  render() {
    const { form: { entries = [] } = {}, handleRemoveEntry } = this.props;

    const { currentEntry } = this.state;
    const currentSelectedRoles = [];
    currentEntry.roles.value.forEach(role => {
      if (role.selected) currentSelectedRoles.push(role.key);
    });
    const scrollEntries = entries
      .map((user, index) => {
        return { index, user };
      })
      .reverse(); // we reverse here so new additions are at the top of the list giving the user better visual feedback

    const entryHasErrors = some(Object.values(currentEntry), entry =>
      Boolean(entry.error)
    );

    const emailHasErrors = this.isAnyEmailAlreadyInTeam();
    const canShowInvitePricingMessage = this.canShowInvitePricingMessage();
    const invitePricingMessage = this.getPricingMessage();

    return (
      <React.Fragment>
        <Table headers={tableHeaders} className={style.tableWrapper}>
          <div className={style.row}>
            <div className={style.cell}>
              <Input
                value={currentEntry.name.value || ""}
                placeholder="Full Name"
                error={currentEntry.name.error}
                showErrorMsg={
                  Boolean(currentEntry.name.error) && !emailHasErrors
                }
                onChange={e => this.handleChange("name", e.target.value)}
                onBlur={e => this.handleBlur("name", e.target.value)}
                className={style.teamInput}
              />
            </div>
            <div className={style.cell}>
              <Input
                type="email"
                value={currentEntry.email.value || ""}
                error={
                  currentEntry.email.error === "Duplicated"
                    ? "Another invite with this email already exists"
                    : currentEntry.email.error
                }
                placeholder="Email"
                showErrorMsg={
                  Boolean(currentEntry.email.error) && !emailHasErrors
                }
                onChange={e =>
                  this.handleChange("email", e.target.value.trim())
                }
                onBlur={e => this.handleBlur("email", e.target.value.trim())}
                className={style.teamInput}
              />
            </div>
            <div className={style.cell}>
              <TeamRolesDropdown
                error={currentEntry.roles.error}
                showErrorMsg={
                  Boolean(currentEntry.roles.error) && !emailHasErrors
                }
                onSubmit={roles => this.handleBlur("roles", roles)}
                selectedRoles={currentSelectedRoles}
              />
            </div>
            <div
              className={style.cell}
              data-disabled={entryHasErrors || emailHasErrors}
            >
              <Button
                onClick={this.pushEntryToList}
                className={style.addToTeamIcon}
                disabled={!this.isFormComplete()}
              >
                <PlusIcon
                  size="16px"
                  angle="45"
                  color={"#ffffff"}
                  className={style.clickablePlusIcon}
                />
              </Button>
            </div>
          </div>
          <div className={style.emailError} data-has-error={emailHasErrors}>
            The highlighted users have already been invited, or are part of the
            team.
          </div>
          <div className={style.list}>
            <Scrollable
              scrollYClassName={style.scrollbar}
              scrollContentClassName={style.scrollContentClassName}
              className={style.scrollable}
              hasFold={true}
              foldColor={"#ffffff"}
            >
              {scrollEntries.map(({ user, index }) => (
                <div className={style.listrow} key={user.email.value}>
                  <div style={{ width: style.scrollableCell1Width }}>
                    {user.name.value}
                  </div>
                  <div
                    style={{ width: style.scrollableCell2Width }}
                    className={user.email.error ? style.cellError : ""}
                  >
                    {user.email.value}
                  </div>
                  <div style={{ width: style.scrollableCell3Width }}>
                    {user.roles.value
                      .filter(({ selected }) => selected)
                      .map(({ label }) => label)
                      .join(", ")}
                  </div>
                  <div style={{ width: style.scrollableCell4Width }}>
                    <TrashIcon
                      size="20px"
                      onClick={() => handleRemoveEntry(index)}
                      className={style.trashIcon}
                    />
                  </div>
                </div>
              ))}
            </Scrollable>
          </div>
        </Table>
        {canShowInvitePricingMessage && (
          <Message>{invitePricingMessage}</Message>
        )}
      </React.Fragment>
    );
  }
}

export default InviteToTeamForm;
