import { getPath } from "lib/lodash";
import { domParser } from "lib/defaultDomParser";
import { SMART_FIELD_REGEX } from "lib/constants";

const includes = (arr, value) => {
  for (let index = 0; index < arr.length; index++) {
    if (arr[index] === value) {
      return true;
    }
  }

  return false;
};

const nodeWhitelist = [
  "#text",
  "div",
  "span",
  "br",
  "b",
  "i",
  "u",
  "a",
  "li",
  "ul"
];
const styleWhitelist = [
  "marginRight",
  "margin-right",
  "fontWeight",
  "font-weight",
  "fontStyle",
  "font-style",
  "textDecoration",
  "text-decoration",
  "textDecorationLine",
  "WebkitTextDecorationLine",
  "webkitTextDecorationLine",
  "-webkit-text-decoration-line",
  "text-decoration-line",
  "color",
  "font",
  "fontFamily",
  "font-family",
  "font-size",
  "fontSize"
];
const nodeTransforms = {
  b: { fontWeight: "bold" },
  i: { fontStyle: "italic" },
  u: { textDecoration: "underline" }
};

function sanitizeDOM(element) {
  if (element.style.cssText !== "") {
    for (const property in element.style) {
      if (
        property === "cssText" ||
        typeof element.style[property] !== "string" ||
        element.style[property] === ""
      ) {
        continue;
      }

      if (!includes(styleWhitelist, property) && !/^\d/.test(property)) {
        element.style[property] = "";
      }
    }
  }

  [].forEach.call(element.childNodes, child => {
    if (!includes(nodeWhitelist, child.nodeName.toLowerCase())) {
      element.removeChild(child);
      return;
    }

    if (child.nodeType === document.ELEMENT_NODE) {
      const transform = nodeTransforms[child.nodeName.toLowerCase()];
      if (transform) {
        const span = document.createElement("span");
        Object.assign(span.style, transform);
        [].slice.call(child.childNodes).forEach(grandchild => {
          span.appendChild(grandchild);
        });
        element.replaceChild(span, child);

        sanitizeDOM(span);
      } else {
        sanitizeDOM(child);
      }
    }
  });
}

export const DOM_NODE_TYPES = {
  text: "#text",
  div: "DIV",
  br: "BR",
  span: "SPAN",
  li: "LI",
  ul: "UL"
};

/**
 * @desc - takes a span node and builds a string output stripping html data other than breaks
 * @param {object} spanNode - a DOM node of the type "SPAN"
 */
export function parseSpanNode(spanNode) {
  const outputStrings = Array.from(spanNode.node.childNodes).reduce(
    (previousValue, childNode) => {
      const parsedNodeContent = parseDomNode({ node: childNode });
      return previousValue.concat([`${parsedNodeContent}`]);
    },
    []
  );
  const output = outputStrings.join("");
  return output;
}

export function parseLiNode(liNode) {
  const outputStrings = Array.from(liNode.node.childNodes).reduce(
    (previousValue, childNode) => {
      const parsedNodeContent = parseDomNode({ node: childNode });
      return previousValue.concat([`${parsedNodeContent}`]);
    },
    []
  );
  const output = outputStrings.join("");
  return output;
}

export function parseUlNode(ulNode) {
  const outputStrings = Array.from(ulNode.node.childNodes).reduce(
    (previousValue, childNode) => {
      const parsedNodeContent = parseDomNode({ node: childNode });
      return previousValue.concat([`${parsedNodeContent}`]);
    },
    []
  );
  const output = outputStrings.join("");
  return output;
}

/**
 * @desc - takes a text node and builds a string output stripping html data other than breaks
 * @param {object} textNode - a DOM node of the type "#text"
 */
export function parseTextNode(textNode) {
  const outputPrefix = textNode.isChild ? "<br>" : "";
  const nodeValue = textNode.node.data || textNode.node.nodeValue;
  return `${outputPrefix}${nodeValue}`;
}

/**
 * @desc - simply returns a break string
 */
export function parseBrNode() {
  return `<br>`;
}

/**
 * @desc - takes a div node and builds a string output stripping html data other than breaks
 * @param {object} divNode - a DOM node of the type "DIV"
 */
export function parseDivNode(divNode) {
  const outputStrings = Array.from(divNode.node.childNodes).reduce(
    (previousValue, childNode) => {
      const parsedNodeContent = parseDomNode({
        node: childNode,
        isChild: true
      });
      return previousValue.concat([`${parsedNodeContent}`]);
    },
    []
  );
  const output = outputStrings.join("");
  return output;
}

/**
 * @desc - takes an array of DOM nodes and builds a string output stripping html data other than breaks
 * @param {object} domNodes - an array of DOM nodes to parse
 */
export function parseDomNodeList(domNodes) {
  const outputStrings = Array.from(domNodes).reduce(
    (previousValue, childNode) => {
      const parsedNodeContent = parseDomNode({ node: childNode });
      return previousValue.concat([parsedNodeContent]);
    },
    []
  );
  return outputStrings.join("");
}

/**
 * @desc - takes a DOM node and calls the appropriate parse function to get a sanitized string
 * @param {object} domNode - a DOM node
 */
export function parseDomNode(domNode) {
  switch (domNode.node.nodeName) {
    case DOM_NODE_TYPES.text: {
      return parseTextNode(domNode);
    }
    case DOM_NODE_TYPES.div: {
      return parseDivNode(domNode);
    }
    case DOM_NODE_TYPES.br: {
      return parseBrNode(domNode);
    }
    case DOM_NODE_TYPES.span: {
      return parseSpanNode(domNode);
    }
    case DOM_NODE_TYPES.li: {
      return parseLiNode(domNode);
    }
    case DOM_NODE_TYPES.ul: {
      return parseUlNode(domNode);
    }

    default: {
      console.warn(`unhandled DOM node of type ${domNode.node.nodeName} found`);
      return "";
    }
  }
}

// perform HTML sanitization for text entries using DOM Node Parsing which can more accurately
// interpret the displayed structure
export function sanitizeHTMLForTextUsingDOMNodes(htmlString) {
  // Only perform dom operations if we are within the context of a window (not react-renderer)
  // https://stackoverflow.com/questions/35068451/reactjs-document-is-not-defined
  if (typeof window !== "undefined") {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    sanitizeDOM(div);
    return parseDomNodeList(div.childNodes);
  } else {
    return sanitizeHTMLForText(htmlString);
  }
}

export function sanitizeHTMLForText(htmlString) {
  // Only perform dom operations if we are within the context of a window (not react-renderer)
  // https://stackoverflow.com/questions/35068451/reactjs-document-is-not-defined
  const EMPTY = "";
  if (typeof window !== "undefined") {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    sanitizeDOM(div);
    let resultString = div.innerHTML.replace(/^(<div>)/g, EMPTY);
    resultString = reverseReplace(
      resultString,
      /(>vid\/<>rb<>vid<(?!>rb<)(?!>vid\/<>rb<>vid<))/g,
      ">rb<>rb<"
    );
    resultString = resultString
      .replace(/(<div><br><\/div>)/g, "<br>")
      .replace(/(<div>\r\t<br>\r<\/div>)/g, "<br>")
      .replace(/(<div><span style=""><br>)/g, '<span style=""><br>')
      .replace(/(<br><div>(?!<br>))/g, "<br>")
      .replace(/(<br><\/div><div>)/g, "<br>");
    resultString = reverseReplace(resultString, /((?=>rb<)>rb<>vid<)/g, ">rb<");
    resultString = resultString
      .replace(/(<div>)/g, "<br>")
      .replace(/(<\/div>)/g, EMPTY);
    return resultString;
  }
  let resultString = htmlString.replace(/^(<div>)/g, EMPTY);
  resultString = reverseReplace(
    resultString,
    /(>vid\/<>rb<>vid<(?!>rb<)(?!>vid\/<>rb<>vid<))/g,
    ">rb<>rb<"
  );
  resultString = resultString
    .replace(/(<div><br><\/div>)/g, "<br>")
    .replace(/(<div>\r\t<br>\r<\/div>)/g, "<br>")
    .replace(/(<div><span style=""><br>)/g, '<span style=""><br>')
    .replace(/(<br><div>(?!<br>))/g, "<br>")
    .replace(/(<br><\/div><div>)/g, "<br>");
  resultString = reverseReplace(resultString, /((?=>rb<)>rb<>vid<)/g, ">rb<");
  resultString = resultString
    .replace(/(<div>)/g, "<br>")
    .replace(/(<\/div>)/g, EMPTY);
  return resultString;
}

/* convert from v2 editor html to v1 renderer text -_- */
export function sanitizeHTMLForV1(htmlString) {
  // Only perform dom operations if we are within the context of a window (not react-renderer)
  // https://stackoverflow.com/questions/35068451/reactjs-document-is-not-defined
  const EMPTY = "";
  if (typeof window !== "undefined") {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    sanitizeDOM(div);
    return div.innerHTML
      .replace(/^(<div>)/g, EMPTY)
      .replace(/(<div><br><\/div>)/g, "<br>")
      .replace(/(<div><span style=""><br>)/g, '<span style=""><br>')
      .replace(/(<div><span style=".{0,100}"><br>)/g, "<span><br>")
      .replace(/(<br><\/div><div>)/g, "<br>")
      .replace(/(<div>)/g, "<br>")
      .replace(/(<\/div>)/g, EMPTY);
  }
  return htmlString
    .replace(/^(<div>)/g, EMPTY)
    .replace(/(<div><br><\/div>)/g, "<br>")
    .replace(/(<div><span style=""><br>)/g, '<span style=""><br>')
    .replace(/(<div><span style=".{0,100}"><br>)/g, "<span><br>")
    .replace(/(<br><\/div><div>)/g, "<br>")
    .replace(/(<div>)/g, "<br>")
    .replace(/(<\/div>)/g, EMPTY);
}

/**
 * @desc takes a string and applies a reverse regex to it, needed for using lookbehinds in browsers other than chrome
 * @param {string} string - the string to apply the reverse regex to
 * @param {regEx} regEx - the reversed regex to apply
 * @param {string} value - the value to replace any matches with (should be reverse)
 * @return {string} returns a string where the value defined by the regex is replaced with value
 */
export const reverseReplace = (string, regEx, value) => {
  let resultString = string;
  /* flip the string */
  resultString = resultString
    .split("")
    .reverse()
    .join("");
  /* apply the regex */
  resultString.replace(regEx, value);
  /* flip it back */
  resultString = resultString
    .split("")
    .reverse()
    .join("");

  return resultString;
};

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

// splits a string into an array of sections or numeric and non-numeric characters only
export const splitStringByNumericSections = string => {
  const getLeadingNonNumericSection = subString => subString.match(/^\D+/g);
  const getLeadingNumericSection = subString => subString.match(/^\d+/g);

  const stringSections = [];

  let _string = string;

  while (_string.length > 0) {
    const leadingNonNumericSection = getLeadingNonNumericSection(_string);
    const leadingNumericSection = getLeadingNumericSection(_string);

    const leadingSection =
      getPath(leadingNonNumericSection, "0") ||
      getPath(leadingNumericSection, "0");

    // add the section to our array
    stringSections.push(leadingSection);

    // remove the section from the target string
    _string = _string.slice(leadingSection.length);
  }

  return stringSections;
};

// determines the sorting order of 2 given objects by a given attribute using correct numeric comparisons
export const sortObjectsBySplitAttributeString = (
  objectA,
  objectB,
  attributeName
) => {
  const splitStringA = splitStringByNumericSections(objectA[attributeName]);
  const splitStringB = splitStringByNumericSections(objectB[attributeName]);

  // loop through the sections
  for (let i = 0; i < Math.max(splitStringA.length, splitStringB.length); i++) {
    if (!splitStringA[i]) {
      return -1;
    }
    if (!splitStringB[i]) {
      return 1;
    }

    // ensure we check numbers with actual numeric comparisons
    const sectionA = !isNaN(Number(splitStringA[i]))
      ? Number(splitStringA[i])
      : splitStringA[i].toUpperCase();
    const sectionB = !isNaN(Number(splitStringB[i]))
      ? Number(splitStringB[i])
      : splitStringB[i].toUpperCase();

    // check if this section is usable to determine the sort order
    if (sectionA < sectionB) {
      return -1;
    }
    if (sectionA > sectionB) {
      return 1;
    }
    // this section is not capable of determining sort order
  }
};

/**
 * Strips HTML from the provided string value.
 * @param {string} value to parse.
 * @returns {string} value with no HTML.
 */
export const stripHTML = value =>
  domParser.parseFromString(value, "text/html").body.innerText;

/**
 * Replaces smart text placeholders with values defined in editor textField state
 * @param {string} value to parse.
 * @param {object} smartTextFields redux state with smart text placeholders as properties.
 * @returns {string} value smart text placeholders replaced.
 */
export const updateSmartTextPlaceholder = (value, smartTextFields) => {
  if (!value) return "";
  if (!smartTextFields) return value;

  return value.replace(SMART_FIELD_REGEX, (match, key) => {
    const smartTextEntity = smartTextFields[key.trim()];
    if (smartTextEntity && smartTextEntity.value) return smartTextEntity.value;
    return match;
  });
};

export const hasSmartTextPlaceholders = value => {
  const matchedPlaceholders = value.match(SMART_FIELD_REGEX);

  return matchedPlaceholders && matchedPlaceholders.length;
};

/**
 * Returns boolean if argument contains span or a html tags
 * @param {string} htmlString to parse.
 * @returns {boolean} whether span or a tags are present in value
 */
export const hasRichText = htmlString => {
  const spanRegex = /<span\b[^>]*>[\s\S]*?<\/span>/i;
  const aRegex = /<a\b[^>]*>[\s\S]*?<\/a>/i;

  return spanRegex.test(htmlString) || aRegex.test(htmlString);
};

/**
 * Checks if a newly added value to a textbox with exceed text boundary locking
 * @param {string} value new value being added to textbox.
 * @param {string} elementId id of textbox.
 * @param {string} pageId id of the where element is located.
 * @param {object} styleAdditions containing keys of css atrributes and corresponding values
 * @returns {boolean} whether text is exceeding textbox boundary
 */
export const isExceedingTextBoundary = (
  value,
  elementId,
  pageId,
  styleAdditions
) => {
  // Get textbox parent div to clone and page to append to
  const textboxParent = document.getElementById(elementId);
  if (!textboxParent) {
    return false;
  }
  const textboxElement = textboxParent.querySelector(
    `div[class*="EditorTextbox-core"]`
  );
  if (!textboxElement) {
    return false;
  }
  const pageSelector = document.getElementById(`PAGE_CANVAS-${pageId}`);

  const clonedTextboxParent = textboxParent.cloneNode(true);
  // Add new value to cloned element and add to dom
  const clonedElement = clonedTextboxParent.querySelector(
    `div[class*="EditorTextbox-core"]`
  );
  clonedElement.innerHTML = value;

  // include ability to assign new styles to allow for flexibility with function
  if (styleAdditions) {
    Object.keys(styleAdditions).forEach(
      property => (clonedElement.style[property] = styleAdditions[property])
    );
  }

  pageSelector.appendChild(clonedTextboxParent);

  // Get scroll height and compare to elementData height
  const clonedElementScrollHeight = clonedElement.scrollHeight;

  pageSelector.removeChild(clonedTextboxParent);

  return clonedElementScrollHeight > textboxElement.scrollHeight;
};

/**
 * Strips HTML other than <br> tags from the provided string value.
 * @param {string} value to parse.
 * @returns {string} value with no HTML.
 */
export const stripHTMLOtherThanBrTags = value => {
  // Remove HTML tags (except <br>)
  const strippedString = value.replace(/<(?!br\s*\/?)[^>]+>/g, "");
  // Replace &nbsp; with a space
  const updatedValue = strippedString
    .replace(/&nbsp;/g, " ")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&");
  return updatedValue;
};

export const convertBrToLineBreak = value => {
  const strippedString = value.replace(/<br\s*\/?>/g, "\n");
  const updatedValue = strippedString
    .replace(/&nbsp;/g, " ")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&");
  return updatedValue;
};

export const convertLineBreakToBr = value => {
  return value.replace(/\n/g, "<br>");
};

export const hasBrTags = value => {
  return /<br\s*\/?>/.test(value);
};

export const hasRemovedOpeningBracket = (prevValue, currentValue) => {
  let isOpeningBracket = false;
  // return true when missing character is {
  for (let i = 0; i < prevValue.length - 1; i++) {
    if (prevValue[i] !== currentValue[i] && prevValue[i] === "{") {
      isOpeningBracket = true;
      break;
    }
  }
  return isOpeningBracket;
};

/**
 * Conditionally truncate and append ... to a supplied string.
 * Can override the length to truncate and the ... suffix.
 *
 * @param {string} str string to truncate.
 * @param {number} length number of characters allowed before truncating.
 * @param {string} suffix string to append to truncated string.
 * @returns {string} Conditionally truncated string.
 */
export const truncateString = (str, length = 30, suffix = "...") =>
  str.length > length ? str.slice(0, length - suffix.length) + suffix : str;
