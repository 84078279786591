import PATHS from "routes/paths";

const getHeaderLinks = ({
  pathToCatalogue,
  isAdmin,
  isBrandManager,
  allowEasilTemplates,
  isTeamTemplates
}) => ({
  designs: {
    title: "My Workspace",
    url: PATHS.workspace,
    visible: true,
    width: 129,
    noPopout: true
  },
  purchaseCollection: {
    title: "My Workspace",
    url: PATHS.purchaseCollection,
    visible: false,
    Width: 129,
    noPopout: true
  },
  templatesEasil: {
    title: "Easil Templates",
    url: PATHS.catalogueEasil,
    visible: false,
    noPopout: true
  },
  templatesPurchased: {
    title: "Easil Templates",
    url: PATHS.cataloguePurchased,
    visible: false,
    noPopout: true
  },
  templatesTeam: {
    title: "Team Templates",
    url: PATHS.catalogueTemplates,
    visible: false,
    noPopout: true
  },
  templatesArchived: {
    title: "Team Templates",
    url: PATHS.catalogueArchived,
    visible: false,
    noPopout: true
  },
  templatesFolders: {
    title: "Team Templates",
    url: PATHS.catalogueFolders,
    visible: false,
    noPopout: true
  },
  templates: {
    title: "Templates",
    url: pathToCatalogue,
    urlBase: PATHS.catalogue,
    visible: true,
    isDropdown: allowEasilTemplates && isTeamTemplates, // allowing easil templates and there are team templates
    dropdownOptions: [
      {
        label: "Easil Templates",
        path: PATHS.catalogueEasil
      },
      {
        label: "Team Templates",
        path: PATHS.catalogueTemplates
      }
    ],
    width: 114,
    noPopout: true
  },
  brand: {
    title: "Brand",
    url: PATHS.brand,
    visible: isBrandManager,
    width: 65
  },
  team: { title: "Team", url: PATHS.team, visible: isAdmin, width: 59 },
  orders: {
    title: "My Orders",
    url: PATHS.orders,
    visible: false,
    width: 80,
    isRightPanel: true
  },
  cart: { title: "Cart", url: PATHS.cart, visible: false },
  subscription: {
    title: "Subscription",
    url: PATHS.subscription,
    visible: false,
    width: 109,
    isRightPanel: true
  },
  teamSettings: {
    title: "Team Settings",
    url: PATHS.teamSettings,
    visible: false
  },
  userSettings: { title: "Profile", url: PATHS.settings, visible: false }
});

export default getHeaderLinks;
