import * as types from "./userTeamFavoritesTypes";

import { merge } from "lib";

export const initState = {};

const userTeamFavoritesReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USER_TEAM_FAVORITES_CREATE_REQUEST_SUCCESS:
    case types.USER_TEAM_FAVORITES_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;

      return merge({}, state, entities.userTeamFavorite);
    }

    case types.USER_TEAM_FAVORITES_REMOVE_REQUEST_SUCCESS: {
      const {
        extra: { mediaId }
      } = action;

      const newState = Object.assign({}, state);
      delete newState[mediaId];

      return newState;
    }

    default:
      return state;
  }
};

export default userTeamFavoritesReducers;
