import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const PurchaseCollectionPage = lazy(() => import("./PurchaseCollectionPage"));

export const PurchaseCollectionPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <PurchaseCollectionPage {...props} />
  </Suspense>
);
PurchaseCollectionPageLazy.displayName = "PurchaseCollectionPage";

export default PurchaseCollectionPageLazy;
