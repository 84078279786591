import React from "react";
import { easilGrayXDark } from "./style.module.css";
import PropTypes from "prop-types";

const FolderIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M2.003 9.992A1.014 1.014 0 0 1 2 9.917V5.778C2 4.241 3.256 3 4.8 3h3.61a3 3 0 0 1 2.23.993L11.544 5H19.2C20.745 5 22 6.454 22 8v1.995a2.989 2.989 0 0 1 .75 2.333l-.66 6A3 3 0 0 1 19.108 21H4.892a3 3 0 0 1-2.982-2.672l-.66-6c-.097-.885.2-1.722.753-2.336zM20 9.01V8c0-.501-.43-1-.8-1h-7.655a2 2 0 0 1-1.486-.662L9.153 5.33A1 1 0 0 0 8.409 5H4.8a.79.79 0 0 0-.8.778v3.231A3 3 0 0 1 4.232 9h15.536c.078 0 .155.003.232.009zM4.123 11.006a1 1 0 0 0-.885 1.103l.66 6a1 1 0 0 0 .994.891h14.216a1 1 0 0 0 .994-.89l.66-6a1 1 0 0 0-.994-1.11H4.232a1 1 0 0 0-.11.006z"
        fill={color || easilGrayXDark}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
FolderIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FolderIcon;
