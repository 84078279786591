export const TEAM_SMART_IMAGES_REQUEST =
  "API/ENTITIES/TEAM_SMART_IMAGES_REQUEST";
export const TEAM_SMART_IMAGES_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SMART_IMAGES_REQUEST_FAILURE";
export const TEAM_SMART_IMAGES_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SMART_IMAGES_REQUEST_SUCCESS";

export const TEAM_SMART_IMAGE_UPLOAD_REQUEST =
  "API/ENTITIES/TEAM_SMART_IMAGE_UPLOAD_REQUEST";
export const TEAM_SMART_IMAGE_UPLOAD_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SMART_IMAGE_UPLOAD_REQUEST_FAILURE";
export const TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS";

export const TEAM_SMART_IMAGE_UPDATE_REQUEST =
  "API/ENTITIES/TEAM_SMART_IMAGE_UPDATE_REQUEST";
export const TEAM_SMART_IMAGE_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SMART_IMAGE_UPDATE_REQUEST_FAILURE";
export const TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS";

export const TEAM_SMART_IMAGE_DELETE_REQUEST =
  "API/ENTITIES/TEAM_SMART_IMAGE_DELETE_REQUEST";
export const TEAM_SMART_IMAGE_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SMART_IMAGE_DELETE_REQUEST_FAILURE";
export const TEAM_SMART_IMAGE_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SMART_IMAGE_DELETE_REQUEST_SUCCESS";
