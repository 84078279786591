import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

import { ColoredLine, Modal, Loading } from "views/components";
import SlidingToggle from "./SlidingToggle";
import DetailRows from "./DetailRows";
import CommentSection from "./CommentSection";

class ApprovalModal extends Component {
  static defaultState = {
    approve: true,
    comment: "",
    approvalRequest: {
      requester: "",
      designName: "",
      date: "2019-11-24T10:12:56.702Z",
      comment: ""
    }
  };
  constructor(props) {
    super(props);

    this.state = ApprovalModal.defaultState;

    this.getButtons = this.getButtons.bind(this);
    this.setApproveState = this.setApproveState.bind(this);
    this.setCommentState = this.setCommentState.bind(this);
    this.returnToDefaultState = this.returnToDefaultState.bind(this);
    this.getBody = this.getBody.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.isActionable = this.isActionable.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const { designAction, design, requester } = this.props;
    if (designAction && design && requester) {
      this.updateApprovalRequest();
    }
  }

  componentDidUpdate(prevProps) {
    const { designAction, design, requester } = this.props;
    if (
      designAction &&
      design &&
      requester &&
      (prevProps.designAction !== designAction ||
        prevProps.design !== design ||
        prevProps.requester !== requester)
    ) {
      this.updateApprovalRequest();
    }
  }

  returnToDefaultState() {
    this.setState(ApprovalModal.defaultState);
  }

  updateApprovalRequest() {
    const { designAction, design, requester } = this.props;

    this.setState({
      approvalRequest: {
        ...this.state.approvalRequest,
        ...{
          designName: design.title,
          date: designAction.createdAt,
          comment: designAction.comment,
          requester: requester.name
        }
      }
    });
  }

  isActionable() {
    return ["REQUESTED", "UNAPPROVED"].includes(this.props.designAction.action);
  }

  closeModal() {
    if (!this.isActionable()) {
      this.props.refetchAllPendingApprovalDesignsPages();
    }

    this.props.onCloseModal();
  }

  getButtons() {
    if (this.isLoading() || !this.isActionable()) {
      return [];
    }

    const cancelButton = {
      name: "Cancel",
      theme: "gray",
      onClick: this.props.onCloseModal
    };

    const declineButton = {
      name: "Decline",
      theme: "redSolid",
      onClick: () => {
        this.props.approveAction({
          approve: false,
          comment: this.state.comment,
          designId: this.props.designId
        });
        this.returnToDefaultState();
      },
      disabled: this.props.isLoading,
      isLoading: this.props.isLoading
    };

    const approveButton = {
      name: "Approve",
      theme: "greenSolid",
      onClick: () => {
        this.props.approveAction({
          approve: true,
          comment: this.state.comment,
          designId: this.props.designId
        });
        this.returnToDefaultState();
      },
      disabled: this.props.isLoading,
      isLoading: this.props.isLoading
    };

    const buttonsList = [
      cancelButton,
      this.state.approve ? approveButton : declineButton
    ];

    return buttonsList;
  }

  renderComment() {
    if (
      !this.state.approvalRequest.comment ||
      this.state.approvalRequest.comment === ""
    )
      return null;
    return (
      <div className={style.commentSection}>
        <div className={style.commentLabel}>Comment:</div>
        <div className={style.comment}>
          “{this.state.approvalRequest.comment}”
        </div>
      </div>
    );
  }

  setApproveState({ approve }) {
    this.setState({ approve });
  }

  setCommentState({ comment }) {
    this.setState({ comment });
  }

  isLoading() {
    const { designAction, design, requester } = this.props;

    return !designAction || !design || !requester;
  }

  getBody() {
    const { approvalRequest } = this.state;

    if (this.isLoading()) {
      return <Loading />;
    }

    if (!this.isActionable()) {
      return "This approval request has been cancelled and is no longer required.";
    }

    return (
      <div>
        <div>
          <DetailRows
            userName={approvalRequest.requester}
            designName={approvalRequest.designName}
            requestDate={approvalRequest.date}
          />
        </div>
        <div>{this.renderComment()}</div>
        <ColoredLine margin="24px auto" />
        <SlidingToggle onToggle={this.setApproveState} />
        <CommentSection
          isApproving={this.state.approve}
          comment={this.state.comment}
          onChange={this.setCommentState}
        />
        <div className={style.notification}>
          <div>
            <span>{approvalRequest.requester}</span> will be notified
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        className={style.wrapper}
        isOpen={this.props.isOpen}
        contentLabel="Action approval request"
        onRequestClose={this.closeModal.bind(this)}
        header={"Action approval request"}
        buttons={this.getButtons()}
      >
        {this.getBody()}
      </Modal>
    );
  }
}

ApprovalModal.displayName = "ApprovalModal";
ApprovalModal.propTypes = {
  approveAction: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

export default ApprovalModal;
