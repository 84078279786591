import { createSelector } from "reselect";
import { pick, isEmpty, isNil, flatten } from "lib";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";

export const usersStoreSelector = state => state.entities.users;

const usersPagesSelector = state => state.api.users.pages;
const usersEntitiesSelector = state => state.entities.users;

const processSelectAllUsers = (pages, usersEntities) => {
  if (isEmpty(pages)) {
    return null;
  }

  if (isNil(processUsersSelector(pages, 1, usersEntities))) {
    return null;
  }

  const users = [];

  Object.keys(pages).forEach(pageNumber => {
    const usersFromPage = processUsersSelector(
      pages,
      pageNumber,
      usersEntities
    );

    if (!isNil(usersFromPage)) {
      users.push(Object.values(usersFromPage));
    }
  });

  return flatten(users);
};

export const selectAllUsers = createSelector(
  [usersPagesSelector, usersEntitiesSelector],
  processSelectAllUsers
);

export const usersTotalCountSelector = state => state.api.users.totalCount;

const processSelectAllUsersButCurrentUser = (allUsers, currentUser) => {
  if (isNil(allUsers)) {
    return null;
  }

  return allUsers.filter(user => user.id !== currentUser.id);
};

export const selectAllUsersButCurrentUser = createSelector(
  [selectAllUsers, currentUserSelector],
  processSelectAllUsersButCurrentUser
);

const pageNumberPassThrough = (state, pageNumber = 1) => pageNumber;

const processUsersSelector = (pages, pageNumber, users) => {
  const page = pages[pageNumber];

  if (!page) return null;

  if (!page.ids) return null;

  return pick(users, page.ids);
};

export const usersSelector = createSelector(
  [usersPagesSelector, pageNumberPassThrough, usersEntitiesSelector],
  processUsersSelector
);

export const userById = (state, userId) => {
  return state.entities.users[userId];
};
