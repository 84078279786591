import React from "react";
import TextMaskingCropping from "./TextMaskingCropping";
import { connect } from "react-redux";
import {
  isCroppingSelector,
  contextSelector,
  selectedItemsSelector,
  zoomSelector,
  designDataSelector,
  elementPreviewsSelector
} from "state/ui/editorContext/editorContextSelectors";
import { getPath } from "lib/lodash";
import { getPageOffsetInCanvasbackground } from "views/components/Editor/utils";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { Logger } from "lib";

class TextMaskingCroppingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.getElementWithPreview = this.getElementWithPreview.bind(this);
    this.getPageOffsetInCanvasbackground = this.getPageOffsetInCanvasbackground.bind(
      this
    );
    this.getElementWithPreview = this.getElementWithPreview.bind(this);
    this.getElementPreview = this.getElementPreview.bind(this);
    this.startTextMaskImageCropMoving = this.startTextMaskImageCropMoving.bind(
      this
    );
    this.finishTextMaskImageCropMoving = this.finishTextMaskImageCropMoving.bind(
      this
    );
  }

  getElementPreview(elementId) {
    if (!elementId) return {};
    const { elementPreviews, selectedItems } = this.props;

    const elementSelection =
      selectedItems.find(item => item.itemId === elementId) || {};

    return {
      ...elementPreviews[elementId],
      ...elementSelection.preview
    };
  }

  getElementWithPreview(elementId) {
    if (!elementId) return {};
    const { designData } = this.props;

    const element = designData.getElement(elementId);

    const elementPreview = this.getElementPreview(elementId);

    // when the preview is image instructions we want to replace without removing unchanged ones
    if (Object.keys(elementPreview).includes("imageInstructions")) {
      const previewInstructions = elementPreview.imageInstructions;
      const elementInstructions = element.imageInstructions;

      const originalInstructionsByDomId = elementInstructions.reduce(
        (combinedInstructions, currentInstruction) => ({
          ...combinedInstructions,
          [currentInstruction.domId]: currentInstruction
        }),
        {}
      );

      previewInstructions.forEach(previewInstruction => {
        originalInstructionsByDomId[
          previewInstruction.domId
        ] = previewInstruction;
      });

      elementPreview.imageInstructions = Object.values(
        originalInstructionsByDomId
      );
    }

    return {
      ...element,
      ...elementPreview
    };
  }

  getPageOffsetInCanvasbackground(pageId) {
    if (!pageId) return 0;
    return getPageOffsetInCanvasbackground(pageId);
  }

  startTextMaskImageCropMoving() {
    Logger.info(
      "TextMaskingCroppingContainer.startTextMaskImageCropMoving called"
    );

    this.props.updateContext({
      isCropping: true
    });
  }

  finishTextMaskImageCropMoving({ differenceFromInitialOffset }) {
    Logger.info(
      "TextMaskingCroppingContainer.finishTextMaskImageCropMoving called"
    );

    const { zoom, designData, selectedItems } = this.props;
    const selectedTextMaskImage = getPath(this.props, "selectedItems.0");

    let textElement = designData.getElement(selectedItems[0].itemId);

    textElement = {
      ...textElement,
      ...selectedItems[0].preview
    };

    const maskImage = {
      ...textElement.maskImage,
      left: textElement.maskImage.left + differenceFromInitialOffset.x / zoom,
      top: textElement.maskImage.top + differenceFromInitialOffset.y / zoom
    };

    if (maskImage.top > 0) {
      maskImage.top = 0;
    }

    const maskImageBottom = maskImage.top + maskImage.height;
    if (maskImageBottom < textElement.height) {
      maskImage.top += textElement.height - maskImageBottom;
    }

    if (maskImage.left > 0) {
      maskImage.left = 0;
    }

    const maskImageRight = maskImage.left + maskImage.width;
    if (maskImageRight < textElement.width) {
      maskImage.left += textElement.width - maskImageRight;
    }

    const selectedItemUpdated = [
      {
        ...selectedTextMaskImage,
        preview: {
          ...selectedTextMaskImage.preview,
          maskImage
        }
      }
    ];

    this.props.updateContext({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  }

  render() {
    if (
      !this.props.canvasBackground ||
      !this.props.context ||
      !this.props.context.isCroppingTextMaskImage
    ) {
      return null;
    }

    const selectedTextMaskImage = getPath(this.props, "selectedItems.0");

    const textElement = this.getElementWithPreview(
      selectedTextMaskImage?.itemId
    );

    const pageId = selectedTextMaskImage?.pageId || "";
    const pageOffset = this.getPageOffsetInCanvasbackground(pageId);

    return (
      <TextMaskingCropping
        {...this.props}
        textElement={textElement}
        pageId={pageId}
        pageOffset={pageOffset}
        startCropMoving={this.startTextMaskImageCropMoving}
        finishCropMoving={this.finishTextMaskImageCropMoving}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isCropping: isCroppingSelector(state),
    context: contextSelector(state),
    selectedItems: selectedItemsSelector(state),
    zoom: zoomSelector(state),
    elementPreviews: elementPreviewsSelector(state),
    designData: designDataSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContext: args => dispatch(updateEditorContextState(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextMaskingCroppingContainer);
