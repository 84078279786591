import React from "react";
import PropTypes from "prop-types";

const ShapesAltIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 21 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h24v24H-2z" />
      <path d="M0 0h20v20H0z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M9.799 18h7.6l-3.802-6.17L9.8 18zm4.649-8.601l5.594 9.076A1 1 0 0 1 19.191 20H8.009a1 1 0 0 1-.852-1.524l5.588-9.077a1 1 0 0 1 1.703 0zM5.5 11a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0-2a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
      />
    </g>
  </svg>
);
ShapesAltIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ShapesAltIcon;
