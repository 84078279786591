import ReduxUndo from "redux-undo";
import * as editorActionsTypes from "state/ui/editor/editorActionTypes";
import { LOCATION_CHANGE } from "react-router-redux";
import PATHS from "routes/paths";

export const initState = {
  designData: null,
  editorState: null
};

const editorReducers = (state = initState, action) => {
  switch (action.type) {
    case editorActionsTypes.LOAD_DESIGN:
    case editorActionsTypes.SAVE_DESIGN: {
      return {
        ...state,
        designData: action.data,
        editorState: action.editorState
      };
    }

    case LOCATION_CHANGE: {
      if (
        action.pathname &&
        PATHS.isEditorPath(action.pathname) &&
        !PATHS.isEditorPath(action.payload.pathname)
      ) {
        return initState;
      }
      return state;
    }

    default:
      return state;
  }
};

const ReduxUndoOptions = {
  initTypes: editorActionsTypes.RESET_EDITOR_HISTORY
};

export default ReduxUndo(editorReducers, ReduxUndoOptions);
