import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import SubPageLoading from "views/pages/SubPageLoading";
const CartPage = lazy(() => import("./CartPage"));

export const CartPageLazy = props => (
  <Suspense fallback={<SubPageLoading />}>
    <CartPage {...props} />
  </Suspense>
);
CartPageLazy.displayName = "CartPage";

export default WithHeader(CartPageLazy);
