import * as types from "./editorLayoutsTypes";
import * as designsTypes from "../../entities/designs/designsTypes";
import { formatErrors } from "lib";

export const initState = {
  visibleModal: null,
  isFetching: false,
  isSuccess: false,
  errors: []
};

const errorsArray = error => formatErrors(error);

const editorLayoutsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.EDITOR_LAYOUTS_DISPLAY_MODAL_SUCCESS: {
      const visibleModal = action.name;

      return {
        ...state,
        errors: [],
        isSuccess: false,
        isFetching: false,
        visibleModal
      };
    }

    case types.EDITOR_LAYOUTS_FETCH_DATA_REQUEST: {
      return {
        ...state,
        errors: [],
        isSuccess: false,
        isFetching: true
      };
    }

    case types.EDITOR_LAYOUTS_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        visibleModal: null,
        errors: [],
        isSuccess: true,
        isFetching: false
      };
    }

    case designsTypes.DESIGNS_DATA_REQUEST_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...state,
        errors,
        isFetching: false,
        isSuccess: false
      };
    }

    default:
      return state;
  }
};

export default editorLayoutsReducers;
