import { createSelector } from "reselect";
import { isEmpty, isNil, getPath } from "lib";
import { SUBTAB_TYPES_MAP } from "views/components/Editor/sidebar/tabs/animations/animationsTabConstants";
import { formatSearchResultList } from "state/ui/userTeamImagesPage/userTeamImagesPageSelectors";

const teamAnimationsFoldersEntitiesSelector = state =>
  state.entities.teamAnimationsFolders;
const teamAnimationsFoldersApiSelector = state =>
  state.api.teamAnimationsFolders;
const teamAnimationsSelector = state => state.api.teamAnimations.folders;
const teamAnimationsEntitiesSelector = state => state.entities.teamAnimations;
const teamAnimationSearchSelector = state =>
  state.ui.editorAnimationSearch.brand;

const getFolderAnimations = (
  folderStore,
  animationFolderId,
  teamAnimations
) => {
  const selectedFolderStore = folderStore[animationFolderId];
  const assetTypes = Object.values(SUBTAB_TYPES_MAP);
  const assets = {};

  if (isEmpty(folderStore)) {
    return null;
  }

  if (isEmpty(selectedFolderStore)) {
    return null;
  }

  assetTypes.forEach(asset => {
    if (
      !selectedFolderStore[asset] ||
      isEmpty(selectedFolderStore[asset].pages) ||
      isNil(selectedFolderStore[asset].pages[1].ids)
    ) {
      assets[asset] = null;
    } else {
      let animationIds = [];

      Object.values(folderStore[animationFolderId][asset].pages).forEach(
        page => {
          if (page.ids) {
            animationIds = animationIds.concat(page.ids);
          }
        }
      );

      const uniqueImagesIds = [...new Set(animationIds)];

      assets[asset] = uniqueImagesIds
        .filter(animationId => teamAnimations[animationId])
        .map(animationId =>
          Object.assign({ canBeDeleted: true }, teamAnimations[animationId])
        );
    }
  });

  return assets;
};

const processGetAllTeamAnimationsFolders = (
  animationFolders,
  animationFoldersApi,
  teamAnimationsFolders,
  teamAnimations
) => {
  const pages = animationFoldersApi.pages;
  if (isEmpty(pages) || (pages[1].isFetching && isNil(pages[1].ids))) {
    return [];
  }

  const pageNumbers = Object.keys(pages).sort();

  let allAnimationFoldersIds = [];

  pageNumbers.forEach(
    pageNumber =>
      (allAnimationFoldersIds = allAnimationFoldersIds.concat(
        pages[pageNumber].ids || []
      ))
  );

  return allAnimationFoldersIds.map(animationFolderId => {
    const entity = Object.assign({}, animationFolders[animationFolderId]);

    // using images as property to match selector in ImagesList component
    entity.images = getFolderAnimations(
      teamAnimationsFolders,
      animationFolderId,
      teamAnimations
    );

    return entity;
  });
};

export const getAllTeamAnimationsFolders = createSelector(
  [
    teamAnimationsFoldersEntitiesSelector,
    teamAnimationsFoldersApiSelector,
    teamAnimationsSelector,
    teamAnimationsEntitiesSelector
  ],
  processGetAllTeamAnimationsFolders
);

export const getTeamAnimationsInFolders = ({ state, term, folderId }) => {
  const searchState = teamAnimationSearchSelector(state);
  const folderSearchState = getPath(searchState.folders, folderId);
  if (
    folderSearchState &&
    !isEmpty(folderSearchState.ALL) &&
    !isEmpty(folderSearchState.ALL.terms)
  ) {
    const termSearchState = getPath(folderSearchState.ALL.terms, term);

    if (!termSearchState || termSearchState.isFetching) return [];

    const ids = termSearchState.ids || [];
    const uniqueIds = [...new Set(ids)];
    const animationEntities = uniqueIds.map(
      teamAnimationId => termSearchState.animations[teamAnimationId]
    );

    return formatSearchResultList(animationEntities);
  }
  return [];
};

export const getTeamAnimationFoldersAll = ({ state, term, folderId }) => {
  const teamAnimationFolders = getAllTeamAnimationsFolders(state);
  return teamAnimationFolders.map(animationFolder => {
    let images = animationFolder.images?.all || null;
    if (animationFolder.id === folderId && term && term !== "") {
      images = getTeamAnimationsInFolders({ state, term, folderId });
    }
    return {
      ...animationFolder,
      images
    };
  });
};
