import React from "react";
import PropTypes from "prop-types";
import BillingContactModal from "./BillingContactModal";
import style from "./style.module.css";
import MailIcon from "views/components/icons/MailIcon";
import SmoothPencilIcon from "views/components/icons/SmoothPencilIcon";
import PlusIcon from "views/components/icons/PlusIcon";
class BillingContact extends React.Component {
  state = {
    isModalVisible: false
  };

  hideModal = () => {
    this.setState({ isModalVisible: false });
    this.props.clearBillingErrors();
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  render = () => (
    <>
      <h3>Contacts</h3>

      <p>Invoices will be sent to these people.</p>

      <ul className={style.contacts}>
        <li>
          <MailIcon />
          <div className="email">
            {this.props.teamBilling.invoiceEmail || "None."}
          </div>
        </li>
      </ul>
      {this.props.teamBilling.invoiceEmail ? (
        <button className={style.addButton} onClick={this.showModal}>
          <SmoothPencilIcon color="#2d70cf" size="18" />
          Edit billing contact
        </button>
      ) : (
        <button className={style.addButton} onClick={this.showModal}>
          <PlusIcon size="16" />
          Add billing contact
        </button>
      )}

      {this.state.isModalVisible && (
        <BillingContactModal
          teamBilling={this.props.teamBilling}
          teamBillingSettings={this.props.teamBillingSettings}
          updateBilling={this.props.updateBilling}
          onClose={this.hideModal}
        />
      )}
    </>
  );
}

BillingContact.displayName = "BillingContact";

BillingContact.propTypes = {
  updateBilling: PropTypes.func.isRequired,
  teamBilling: PropTypes.object,
  teamBillingSettings: PropTypes.object.isRequired
};

export default BillingContact;
