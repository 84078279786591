import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";

export const initState = {
  pages: {},
  totalCount: null,
  pageSize: 50
};

const teamDesignsAllApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.TEAM_DESIGNS_REQUEST: {
      return handleDesignRequest({ state, action });
    }

    case designsEntitiesTypes.TEAM_DESIGNS_REQUEST_SUCCESS: {
      return handleDesignRequestSuccess({
        state:
          action.extra && action.extra.shouldClearPages ? initState : state,
        action
      });
    }

    default:
      return state;
  }
};

export default teamDesignsAllApiReducers;
