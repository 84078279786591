const eventNotificationCountValue = event => {
  switch (event.type) {
    case "PENDING_APPROVAL": {
      return event.count;
    }

    default: {
      return 1;
    }
  }
};

export const calcCount = eventsList => {
  let countTotal = 0;

  eventsList.forEach(event => {
    countTotal += eventNotificationCountValue(event);
  });

  return countTotal;
};
