import { Logger } from "lib";
import {
  calculateScaledNewDimensions,
  calculateMovedNewDimensions,
  calculateCropMaskScale
} from "views/components/Editor/CropLayer/ImageCropping/ImageCroppingPreview/ImageCroppingPreviewCalculations";
import { getPageHtmlNode } from "views/components/Editor/utils";

const EditorVideoCropOps = {
  startCropMaskResize() {
    Logger.info("Editor.startCropMaskResize called");

    this.setState({
      isCropping: true
    });
  },

  finishCropMaskResize({
    differenceFromInitialOffset,
    dragItem,
    adjustmentOffset
  }) {
    Logger.info("Editor.finishVideoCropMoving called");

    const { zoom, selectedItems } = this.state;

    let videoElement = this.getElementWithPreview(selectedItems[0].itemId);

    videoElement = {
      ...videoElement
      // angle: 0
    };

    const pageId = selectedItems[0].pageId;

    const pageDiv = getPageHtmlNode(pageId);
    const pageOffset = pageDiv.getBoundingClientRect();

    const { mask, height, width, top, left } = calculateCropMaskScale.calc({
      videoElement,
      dragItem,
      differenceFromInitialOffset,
      pageOffset,
      zoom,
      adjustmentOffset
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          ...selectedItems[0].preview,
          mask,
          height,
          width,
          top,
          left
        }
      }
    ];

    this.setState({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  },

  startVideoCropMode() {
    Logger.info("Editor.startVideoCropMode called");

    const { actionbar, context } = this.props;
    const updatedActionbarState = {
      ...actionbar,
      buttonActive: "crop"
    };
    const updatedContextState = {
      context: {
        ...context,
        isCroppingVideo: true
      }
    };
    this.props.updateActionBarState(updatedActionbarState);
    this.props.updateContextState(updatedContextState);
  },

  cancelVideoCropMode() {
    Logger.info("Editor.cancelVideoCropMode called");

    const { context, actionbar, selectedItems } = this.state;

    const selectedItemsUpdated = selectedItems.map(item => ({
      ...item,
      preview: {}
    }));

    this.setState({
      actionbar: {
        ...actionbar,
        buttonActive: null
      },
      context: {
        ...context,
        isCroppingVideo: false
      },
      selectedItems: selectedItemsUpdated
    });
  },

  startVideoCropMoving() {
    Logger.info("Editor.startVideoCropMoving called");

    this.setState({
      isCropping: true
    });
  },

  finishVideoCropMoving({ differenceFromInitialOffset }) {
    Logger.info("Editor.finishVideoCropMoving called");

    const { zoom, selectedItems } = this.state;

    let videoElement = this.getElementWithPreview(selectedItems[0].itemId);

    const { mask } = calculateMovedNewDimensions({
      imageElement: videoElement,
      differenceFromInitialOffset,
      zoom
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          ...selectedItems[0].preview,
          mask
        }
      }
    ];
    this.setState({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  },

  startVideoCropResizing({ pageId }) {
    Logger.info("Editor.startVideoCropResizing called");

    this.setState({
      isCropping: true
    });
  },

  finishVideoCropResizing({
    handlerInitialPosition,
    anchorPoint,
    differenceFromInitialOffset
  }) {
    Logger.info("Editor.finishVideoCropResizing called");

    const { zoom, selectedItems } = this.state;

    let videoElement = this.getElementWithPreview(selectedItems[0].itemId);

    videoElement = {
      ...videoElement
      // angle: 0
    };

    const pageId = selectedItems[0].pageId;
    const pageDiv = getPageHtmlNode(pageId);

    const pageOffset = pageDiv.getBoundingClientRect();

    const {
      scale,
      mask,
      top,
      left,
      height,
      width
    } = calculateScaledNewDimensions({
      imageElement: videoElement,
      differenceFromInitialOffset,
      zoom,
      dragItem: { anchorPoint, handlerInitialPosition },
      pageOffset
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          scale,
          mask,
          top,
          left,
          height,
          width
        }
      }
    ];

    this.setState({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  },

  applyCrop() {
    Logger.info("Editor.EditoVideoCropOps.applyCrop  called");

    const { actionbar, designData, selectedItems, context } = this.state;

    const selectedItem = selectedItems[0];

    if (!selectedItem.preview) {
      Logger.info(
        "Editor.EditorVideoCropOps - No changes to be applied, cancelling it"
      );
      this.state.cancelVideoCropMode();
      return;
    }

    const currentItem = designData.elements[selectedItem.itemId];
    const scale = currentItem.scale;

    this.updateStateAndSave({
      actionbar: {
        ...actionbar,
        buttonActive: null
      },
      designData: designData.updateElementsAttribute({
        elementsId: [selectedItem.itemId],
        attributes: { scale, ...selectedItems[0].preview } // always give a scale but prefer preview scale
      }),
      selectedItems: selectedItems.map(item => ({
        ...item,
        preview: {}
      })),
      context: {
        ...context,
        isCroppingVideo: false
      }
    });
  }
};

export default EditorVideoCropOps;
