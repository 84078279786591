import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const BorderIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 12"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1378.000000, -631.000000)">
        <g transform="translate(1376.000000, 627.000000)">
          <rect x="0" y="0" width="20" height="20" />
          <g transform="translate(2.000000, 2.000000)">
            <rect x="0" y="0" width="16" height="16" />
            <g transform="translate(0.000000, 2.000000)" fill="#BDC0C2">
              <path d="M0.9,0 L15.1,-3.33066907e-16 C15.5970563,-4.24374664e-16 16,0.402943725 16,0.9 L16,0.9 C16,1.39705627 15.5970563,1.8 15.1,1.8 L0.9,1.8 C0.402943725,1.8 6.08718376e-17,1.39705627 0,0.9 L0,0.9 C-6.08718376e-17,0.402943725 0.402943725,9.13077564e-17 0.9,0 Z" />
              <g id="Group-88" transform="translate(0.000000, 5.000000)">
                <rect x="0" y="0" width="4" height="1.8" rx="0.9" />
                <rect x="6" y="0" width="4" height="1.8" rx="0.9" />
                <rect x="12" y="0" width="4" height="1.8" rx="0.9" />
              </g>
              <circle cx="1" cy="11" r="1" />
              <circle cx="8" cy="11" r="1" />
              <circle cx="4.5" cy="11" r="1" />
              <circle cx="11.5" cy="11" r="1" />
              <circle cx="15" cy="11" r="1" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
BorderIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default BorderIcon;
