import React, { Component } from "react";
import PropTypes from "prop-types";
import FontOption from "../fontOption/FontOption";
import { DividerLabel } from "../";
import style from "./style.module.css";
import SearchInput from "../searchInput";
import { noop } from "lib";

/**
 * Renders options of structure:
 * {
 *   teamFonts: {},
 *   userFonts: {},
 *   publicFonts: {},
 * }
 */
class FontOptionsRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleBrandFontClick = this.handleBrandFontClick.bind(this);

    this.state = {
      searchFilter: null,
      publicFonts: [],
      userTeamFonts: [],
      teamFonts: []
    };
  }

  UNSAFE_componentWillMount(props) {
    const { publicFonts, userTeamFonts, teamFonts } = this.props.options;

    this.setState({
      publicFonts: this.convertFontsFromObjectToArray(publicFonts),
      userTeamFonts: this.convertFontsFromObjectToArray(userTeamFonts),
      teamFonts: this.convertFontsFromObjectToArray(teamFonts)
    });
  }

  handleSearchChange(filter) {
    const filterValue = filter !== "" ? filter : null;
    const { publicFonts, userTeamFonts, teamFonts } = this.props.options;

    const publicFontsList = this.convertFontsFromObjectToArray(publicFonts);
    const filteredPublicFontsList = this.filterFontsListBySearchTerm(
      publicFontsList,
      "name",
      filterValue
    );

    const userTeamFontsList = this.convertFontsFromObjectToArray(userTeamFonts);
    const filteredUserTeamFontsList = this.filterFontsListBySearchTerm(
      userTeamFontsList,
      "fontName",
      filterValue
    );

    const teamFontsList = this.convertFontsFromObjectToArray(teamFonts);
    const filteredTeamFontsList = this.filterFontsListBySearchTerm(
      teamFontsList,
      "fontName",
      filterValue
    );

    this.setState({
      searchFilter: filterValue,
      publicFonts: filteredPublicFontsList,
      userTeamFonts: filteredUserTeamFontsList,
      teamFonts: filteredTeamFontsList
    });
  }

  convertFontsFromObjectToArray(list) {
    if (!list) return null;
    return Object.keys(list).map(id => list[id]);
  }

  filterFontsListBySearchTerm(list, field, term) {
    if (!list) return null;
    return list.filter(font =>
      term ? font[field].match(new RegExp(term, "i")) : font
    );
  }

  handleBrandFontClick(args) {
    const { showUpgradeModal, subscription } = this.props;

    if (subscription.canAccessBrandKit) {
      this.props.onChange(args);
      return;
    }
    // when not available for the plan prompt to upgrade plan
    showUpgradeModal();
  }

  render() {
    const {
      onChange,
      selected,
      setInputRef = noop,
      onInputClick = noop,
      onFocus = noop
    } = this.props;
    const { publicFonts, userTeamFonts, teamFonts } = this.state;

    return (
      <React.Fragment>
        <SearchInput
          className={style.search}
          placeholder="Search Fonts"
          onChange={value => this.handleSearchChange(value)}
          setInputRef={setInputRef}
          onInputClick={onInputClick}
          onFocus={onFocus}
        />
        {/*Brand Fonts*/}
        {teamFonts && teamFonts.length > 0 && (
          <React.Fragment>
            <DividerLabel label="Brand Fonts" />
            {teamFonts.map(teamFont => (
              <FontOption
                key={`teamFont-${teamFont.id}`}
                fontId={teamFont.fontId}
                fontName={teamFont.teamFontName}
                fontFamily={teamFont.fontFamilyName}
                onClick={this.handleBrandFontClick}
                selected={selected === teamFont.id}
              />
            ))}
          </React.Fragment>
        )}
        {/*My Fonts*/}
        {userTeamFonts && userTeamFonts.length > 0 && (
          <React.Fragment>
            <DividerLabel label="My Fonts" />
            {userTeamFonts.map(userTeamFont => (
              <FontOption
                key={`userTeamFont-${userTeamFont.fontId}`}
                fontId={userTeamFont.fontId}
                fontName={userTeamFont.fontName}
                fontFamily={userTeamFont.fontFamilyName}
                onClick={onChange}
                selected={selected === userTeamFont.fontId}
              />
            ))}
          </React.Fragment>
        )}
        {/*Public Fonts*/}
        {publicFonts && publicFonts.length > 0 && (
          <React.Fragment>
            <DividerLabel label="Public Fonts" />
            {publicFonts.map(publicFont => (
              <FontOption
                key={`publicFont-${publicFont.id}`}
                fontId={publicFont.id}
                fontName={publicFont.name}
                fontFamily={publicFont.fontFamilyName}
                onClick={onChange}
                selected={selected === publicFont.id}
              />
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

FontOptionsRenderer.propTypes = {
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default FontOptionsRenderer;
