import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const SquareCutInto3PiecesIcon = ({
  width,
  size,
  height,
  style = {},
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    style={style}
  >
    <title>{title}</title>
    <g
      id="DESIGNS-+-Proto"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M-2-2h24v24H-2z" />
      <g fillRule="nonzero">
        <path
          fill={color || easilGrayXDark}
          d="M3 2a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1H3zm0-2h14a3 3 0 013 3v14a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3z"
        />
        <path
          fill="#D8D8D8"
          stroke={color || easilGrayXDark}
          d="M10.5 1.5h-1v17h1v-8h7v-1h-7z"
        />
      </g>
    </g>
  </svg>
);
SquareCutInto3PiecesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SquareCutInto3PiecesIcon;
