import React, { Component } from "react";
import DesignsEmptyState from "views/containers/designsPageMasonry/DesignsEmptyState";
import style from "./style.module.css";
import ImageListImageRow from "./ImageListImageRow";
import ImageListFolderRow from "./ImageListFolderRow";
import { Loading, MoreButton } from "views/components";
import TrashIcon from "views/components/icons/TrashIcon";
import Menu from "views/components/popout/Menu";
import BreadCrumbList from "views/components/BreadCrumb";
import PATHS from "routes/paths";
import ImagesUploadModalContainer from "views/containers/DesignsPageContainer/Workspace/ImagesButtons/ImagesUploadModalContainer";
import AnimationsUploadModalContainer from "views/containers/DesignsPageContainer/Workspace/ImagesButtons/AnimationsUploadModalContainer";
import TeamAnimationsUploadModalContainer from "views/containers/DesignsPageContainer/Workspace/ImagesButtons/TeamAnimationsUploadModalContainer";
import confirmDeleteFolder from "./confirmDeleteFolder";
import ImagesListDragAndDropUploadContainer from "views/containers/ImageListContainer/ImagesListDragAndDropUpload/ImagesListDragAndDropUploadContainer";
import { IMAGE_LIST_FOLDER_TYPES, MEDIA_SORT_OPTIONS } from "lib/constants";
import mediaSearchAdapter from "lib/mediaSearchAdapter";

import { isEmpty, noop, getPath } from "lib";

const imagesPerPage = 50;
export class ImagesList extends Component {
  constructor(props) {
    super(props);

    this.getCrumbDetails = this.getCrumbDetails.bind(this);
    this.renderList = this.renderList.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.getUserTeamImages = this.getUserTeamImages.bind(this);
    this.getImagesFromFolder = this.getImagesFromFolder.bind(this);
    this.handleOpenUploadModal = this.handleOpenUploadModal.bind(this);
    this.handleCloseUploadModal = this.handleCloseUploadModal.bind(this);
    this.onDeleteUserImage = this.onDeleteUserImage.bind(this);
    this.getUploadModalContainer = this.getUploadModalContainer.bind(this);
    this.getEmptyStateButtonOnClick = this.getEmptyStateButtonOnClick.bind(
      this
    );
    this.getIsSearchFetching = this.getIsSearchFetching.bind(this);
    this.getIsFetchingImages = this.getIsFetchingImages.bind(this);

    this.state = {
      isUploadModalOpen: false,
      canShowMoreButton: true
    };
  }

  componentDidMount() {
    const { filters } = this.props;
    const { folderId } = filters;

    const sortOption = MEDIA_SORT_OPTIONS[this.props.sortKey];

    this.props.onFetchUsersImageFolders();

    const userTeamImages = this.getUserTeamImages();

    if (!userTeamImages || !userTeamImages.length) {
      this.handleFetch();
    }

    if (folderId) {
      this.props.onFetchUsersImagesInFolder({
        folderId,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { filters, sortKey, refetchUserTeamImages } = this.props;
    const { filters: prevFilters } = prevProps;
    const { term, folderId } = filters;

    const sortOption = MEDIA_SORT_OPTIONS[this.props.sortKey];

    if (folderId !== prevFilters.folderId || term !== prevFilters.term) {
      this.handleFetch({ isLeavingFolder: true });
    }

    if (sortKey !== prevProps.sortKey) {
      refetchUserTeamImages({
        term,
        folderId,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });
    }
  }

  handleFetch({ isLeavingFolder } = {}) {
    const {
      filters: { folderId, term },
      isUserTeamAnimations,
      isBrandAnimations,
      onFetchUsersImages,
      onFetchUsersTeamSmartImages,
      onFetchUsersImagesInFolder,
      onSearchUsersImages,
      sortKey,
      smartImages
    } = this.props;

    const sortOption = MEDIA_SORT_OPTIONS[sortKey];

    if (term) {
      onSearchUsersImages({ term, folderId });
    } else if (folderId) {
      const imagesFromFolder = this.getImagesFromFolder(folderId);

      if (!(imagesFromFolder || []).length) {
        onFetchUsersImagesInFolder({
          folderId,
          sortBy: sortOption.sortKey,
          sortOrder: sortOption.direction
        });
        if (!smartImages && !isUserTeamAnimations && !isBrandAnimations) {
          onFetchUsersTeamSmartImages();
        }
      }
    } else if (isLeavingFolder) {
      onFetchUsersImages({
        page: 1,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });
    } else {
      if (!isUserTeamAnimations && !isBrandAnimations)
        onFetchUsersTeamSmartImages();

      onFetchUsersImages({
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });
    }
  }

  onDeleteUserImage(args) {
    this.props.onDeleteUserTeamImage(args);

    /* After deleting a image, the process of re-fetching the pages would lead
     * to the show more button flicking, the code below hides the bottom for a
     * few seconds while the whole pagination is refreshed  */
    this.setState({ canShowMoreButton: false });

    setTimeout(() => {
      this.setState({ canShowMoreButton: true });
    }, 2000);
  }

  handleOpenUploadModal() {
    const { isUploadModalOpen } = this.state;

    if (!isUploadModalOpen) {
      this.setState({
        isUploadModalOpen: true
      });
    }
  }

  handleCloseUploadModal({ onSuccess = noop }) {
    const { isUploadModalOpen } = this.state;

    if (isUploadModalOpen) {
      this.setState({
        isUploadModalOpen: false
      });
      onSuccess();
    }
  }

  /**
   * @desc determines if a breadcrumb is needed on the page based on whether we are in a folder
   */
  isCrumbNeeded() {
    const {
      filters: { folderId },
      imageFolders
    } = this.props;

    if (!folderId) {
      return false;
    }

    // if there are no imageFolders then we do not need a crumb
    if (isEmpty(imageFolders)) {
      return false;
    }

    const folder = Object.values(imageFolders).find(
      imageFolder => imageFolder.id === folderId
    );

    // if we dont find the folder then we dont need a crumb
    // if all cases pass then we want to show a crumb
    return Boolean(folder);
  }

  getCrumbDetails() {
    const {
      filters: { folderId },
      changePage,
      imageFolders,
      imageOriginPath
    } = this.props;
    const folder = Object.values(imageFolders).find(
      imageFolder => imageFolder.id === folderId
    );

    const getFolderType = selectedFolder => {
      switch (selectedFolder.type) {
        case IMAGE_LIST_FOLDER_TYPES.ANIMATION: {
          return "Videos";
        }
        case IMAGE_LIST_FOLDER_TYPES.BRAND_ANIMATION: {
          return "Brand Videos";
        }
        default: {
          return "Images";
        }
      }
    };

    return [
      {
        text: `My ${getFolderType(folder)}`,
        onClick: () => changePage(imageOriginPath)
      },
      {
        text: folder.name,
        MenuComponent: () => (
          <Menu
            itemName={folder.name}
            submitNameChange={name =>
              this.props.onUpdateImageFolder({ folder: { ...folder, name } })
            }
            actions={[
              {
                name: "Delete",
                Icon: props => <TrashIcon version="2" {...props} />,
                onClick: confirmDeleteFolder({
                  onOk: () =>
                    this.props.onDeleteFolderAndGoToMyImages({
                      folderId: folder.id
                    })
                })
              }
            ]}
          />
        )
      }
    ];
  }

  getImagesFromFolder(id) {
    const { imageFolders, isBrandAnimations } = this.props;
    if (!imageFolders) return [];
    const imageFolder = imageFolders.find(folder => folder.id === id);
    if (!imageFolder) return [];
    if (this.props.filters.term && !isBrandAnimations) {
      const searchResults = getPath(
        imageFolder,
        `terms.${this.props.filters.term}`,
        {}
      );
      const searchResultIds = searchResults?.ids || [];
      const results = searchResultIds.map(
        imageId =>
          searchResults.images[imageId] || searchResults.animations[imageId]
      );

      const hasMissingArguments = true;
      return mediaSearchAdapter(results, hasMissingArguments);
    }
    if (isBrandAnimations && this.props.filters.term) {
      const hasMissingArguments = true;
      return mediaSearchAdapter(imageFolder.images, hasMissingArguments);
    }
    return imageFolder.images;
  }

  getIsSearchFetching() {
    const {
      imageFolders,
      filters: { folderId }
    } = this.props;
    const imageFolder = (imageFolders || []).find(
      folder => folder.id === folderId
    );
    if (!this.props.filters.term || !imageFolders || !imageFolder) {
      return false;
    }
    const termState = getPath(
      imageFolder,
      `terms.${this.props.filters.term}`,
      {}
    );
    return termState.isFetching;
  }

  getIsFetchingImages() {
    const isSearchFetching = this.getIsSearchFetching();
    return this.props.isFetchingImages || isSearchFetching;
  }

  getUserTeamImages() {
    const {
      filters: { folderId },
      userTeamImagesNotInFolders
    } = this.props;

    return folderId
      ? this.getImagesFromFolder(folderId)
      : userTeamImagesNotInFolders;
  }

  renderList({ numberOfImages, userTeamImages, isCrumbNeeded }) {
    let {
      changePage,
      filters: { folderId, term },
      imageFolders,
      onFetchUsersImages,
      onFetchUsersImagesInFolder,
      isFetchingImageFolders,
      imagesPageCount,
      onAddImageToFolder,
      onDeleteImageFolder,
      onUpdateImageFolder,
      removeImageFromFolder,
      updateImage,
      disableFolderFunctions,
      imageOriginPath,
      wrapperClassName,
      imageRowClassName,
      popoutClassName,
      folderRowClassName,
      sortKey,
      getFolderForMedia,
      mediaInFolders,
      isBrandAnimations,
      isUserTeamAnimations,
      isMultiSelect,
      onMultiSelect = noop,
      onAddMediaToFolder,
      selectedIds = []
    } = this.props;

    const sortOption = MEDIA_SORT_OPTIONS[sortKey];

    const loadMore = () => {
      if (this.getIsFetchingImages() || isFetchingImageFolders) {
        return;
      }

      if (folderId) {
        onFetchUsersImagesInFolder({
          folderId,
          sortBy: sortOption.sortKey,
          sortOrder: sortOption.direction
        });
      } else {
        onFetchUsersImages({
          sortBy: sortOption.sortKey,
          sortOrder: sortOption.direction
        });
      }
    };

    const isLoadMoreDisplayed =
      this.state.canShowMoreButton &&
      (numberOfImages === imagesPageCount * imagesPerPage ||
        this.getIsFetchingImages());

    if (!userTeamImages) {
      userTeamImages = [];
    }

    if (!imageFolders) {
      imageFolders = [];
    }

    return (
      <div className={`${style.imageList} ${wrapperClassName}`}>
        <div className={style.titleRow}>
          <div className={style.titleRowLeft}>Title</div>
          <div className={style.titleRowRight}>
            <div className={style.titleRowRightColumn}>Size</div>
            <div className={style.titleRowRightColumn}>Uploaded</div>
          </div>
        </div>
        {!term &&
          !isCrumbNeeded &&
          imageFolders.map((folder, index) => (
            <ImageListFolderRow
              key={`folder-${folder.id}`}
              changePage={changePage}
              folder={folder}
              onDeleteImageFolder={onDeleteImageFolder}
              onUpdateImageFolder={onUpdateImageFolder}
              isLast={index === Object.values(imageFolders).length - 1}
              hasImages={userTeamImages.length !== 0}
              imageOriginPath={imageOriginPath}
              className={folderRowClassName}
            />
          ))}
        {userTeamImages.map((image, index) => (
          <ImageListImageRow
            key={`media-${image.mediaId}`}
            image={image}
            isLast={index === Object.values(userTeamImages).length - 1}
            onAddImageToFolder={onAddImageToFolder}
            imageFolders={imageFolders}
            onDeleteUserTeamImage={this.onDeleteUserImage}
            isInFolder={isCrumbNeeded}
            removeImageFromFolder={removeImageFromFolder}
            disableFolderFunctions={!!disableFolderFunctions}
            updateImage={updateImage}
            imageOriginPath={imageOriginPath}
            imageRowClassName={imageRowClassName}
            popoutClassName={popoutClassName}
            getFolderForMedia={getFolderForMedia}
            mediaInFolders={mediaInFolders}
            isMultiSelect={isMultiSelect}
            onMultiSelect={() => onMultiSelect(image.mediaId)}
            isSelected={selectedIds.includes(image.mediaId)}
            onAddMediaToFolder={onAddMediaToFolder}
            folderId={folderId}
            smartImage={
              isUserTeamAnimations || isBrandAnimations
                ? null
                : this.props.smartImages[image.mediaId]
            }
            onDeleteUserTeamSmartImage={this.props.onDeleteUserTeamSmartImage}
          />
        ))}
        {isLoadMoreDisplayed && (
          <MoreButton
            isLoading={this.getIsFetchingImages() || isFetchingImageFolders}
            loadMore={loadMore}
            type={
              isUserTeamAnimations || isBrandAnimations ? "Videos" : "Images"
            }
          />
        )}
      </div>
    );
  }

  isLoading({ userTeamImages }) {
    const {
      imageFolders,
      isFetchingImageFolders,
      isSearching,
      isImagesFirstPageFetching
    } = this.props;

    const hasNotFetchedImageFoldersYet =
      imageFolders === null || isFetchingImageFolders;
    const hasNotFetchedImagesYet =
      userTeamImages === null ||
      (isImagesFirstPageFetching && this.getIsFetchingImages());
    return (
      hasNotFetchedImagesYet || hasNotFetchedImageFoldersYet || isSearching
    );
  }

  isEmpty({ numberOfImages, isCrumbNeeded }) {
    const {
      filters: { term },
      imageFolders
    } = this.props;

    if (this.getIsFetchingImages()) return false;

    return (
      (!numberOfImages && // no images
        (isEmpty(imageFolders) || // no folders
          !!term)) || // in a search
      (!numberOfImages && // no images
        isCrumbNeeded) // in a folder
    );
  }

  getUploadModalContainer() {
    switch (window.location.pathname) {
      case PATHS.workspaceUploadsAnimations: {
        return AnimationsUploadModalContainer;
      }

      case PATHS.brandAnimations: {
        return TeamAnimationsUploadModalContainer;
      }

      default: {
        return ImagesUploadModalContainer;
      }
    }
  }

  getEmptyStateButtonOnClick() {
    const {
      filters: { term },
      canAccessBrandKit,
      changePage,
      imageOriginPath = PATHS.workspaceUploadsImages,
      isBrandAnimations,
      showUpgradeModal
    } = this.props;

    if (isBrandAnimations && !canAccessBrandKit) {
      return showUpgradeModal;
    } else if (term) {
      return () => changePage(imageOriginPath);
    } else {
      return this.handleOpenUploadModal;
    }
  }

  render() {
    const {
      filters: { folderId, term },
      teamSettings,
      changePage,
      changeQuery,
      errors,
      isDesigner,
      clearUploadErrors,
      breadCrumbClassName,
      searchWrapperClassName,
      sortOptions
    } = this.props;

    let userTeamImages = this.getUserTeamImages();

    const numberOfImages = userTeamImages ? userTeamImages.length : 0;

    const isCrumbNeeded = this.isCrumbNeeded();

    const UploadModalContainer = this.getUploadModalContainer();

    const emptyStateButtonOnClick = this.getEmptyStateButtonOnClick();

    const getContents = () => {
      switch (true) {
        case this.isLoading({ userTeamImages }): {
          return (
            <div className={style.loader}>
              <Loading size="24px" />
            </div>
          );
        }

        case this.isEmpty({ numberOfImages, isCrumbNeeded }): {
          return (
            <DesignsEmptyState
              changePage={changePage}
              changeQuery={changeQuery}
              resource={window.location.pathname}
              isDesigner={isDesigner}
              filters={{}}
              teamAllowEasilTemplatesSetting={teamSettings.allowEasilTemplates}
              buttonOnClick={emptyStateButtonOnClick}
              isImageFolder={!!folderId}
              isImageSearch={!!term}
              imageSearchTerm={term}
            />
          );
        }

        default:
          return this.renderList({
            numberOfImages,
            userTeamImages,
            isCrumbNeeded
          });
      }
    };

    const currentFolder = (this.props.imageFolders || []).find(
      folder => folder.id === folderId
    );

    return (
      <>
        {Boolean(term) && Boolean(numberOfImages) && (
          <div
            className={`${style.resultsTitleGutters} ${searchWrapperClassName}`}
          >
            <div className={style.resultsTitle}>
              Showing {numberOfImages} results for <span>{term}</span>
              {currentFolder ? (
                <>
                  {" "}
                  in <span>{currentFolder?.name}</span>
                </>
              ) : null}
            </div>
          </div>
        )}
        <ImagesListDragAndDropUploadContainer
          folderId={folderId}
          isUserTeamAnimations={this.props.isUserTeamAnimations}
          isBrandAnimations={this.props.isBrandAnimations}
          sortOptions={sortOptions}
        />
        {isCrumbNeeded &&
          !this.isLoading({ userTeamImages }) &&
          !Boolean(term) && (
            <div className={`${style.crumbWrapper} ${breadCrumbClassName}`}>
              <BreadCrumbList path={this.getCrumbDetails()} />
            </div>
          )}
        {getContents()}
        <UploadModalContainer
          isOpen={this.state.isUploadModalOpen}
          onClose={this.handleCloseUploadModal}
          folderId={folderId}
          errors={errors}
          clearUploadErrors={clearUploadErrors}
        />
      </>
    );
  }
}

export default ImagesList;
