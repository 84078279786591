import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

const H1 = ({ children, className }) => {
  return <div className={`${style.h1} ${className}`}>{children}</div>;
};
H1.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default H1;
