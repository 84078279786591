export const FETCH_USER_TEAM_ANIMATION_FOLDERS_REQUEST =
  "API/ENTITIES/FOLDERS/USER_TEAM_ANIMATION_FOLDER_REQUEST";
export const FETCH_USER_TEAM_ANIMATION_FOLDERS_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS";
export const FETCH_USER_TEAM_ANIMATION_FOLDERS_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE";

export const CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST";
export const CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS";
export const CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE";

export const ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST";
export const ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS";
export const ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_FAILURE";

export const BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST";
export const BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS";
export const BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST_FAILURE";

export const DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST";
export const DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS";
export const DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE";

export const UPDATE_USER_TEAM_ANIMATION_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/UPDATE_USER_TEAM_ANIMATION_FOLDER_REQUEST";
export const UPDATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/UPDATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS";
export const UPDATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/UPDATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE";

export const REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST";
export const REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST_SUCCESS";
export const REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST_FAILURE";

export const UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST";
export const UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS";
export const UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_FAILURE";

export const USER_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST =
  "API/ENTITIES/FOLDERS/USER_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST";
export const USER_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/USER_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST_SUCCESS";
export const USER_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/USER_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST_FAILURE";
