import React from "react";
import style from "./style.module.css";

export const ClipboardFailToastContent = (
  <div
    className={style.pasteFailToastContent}
  >{`Please note: Copy and paste functionality\n is not permitted between teams, or on\n restricted designs.`}</div>
);

export default ClipboardFailToastContent;
