import React, { Component } from "react";
import style from "./style.module.css";
import PropTypes from "prop-types";
import { Input } from "views/components";
import PencilIcon from "views/components/icons/PencilIcon";
import PlusIcon from "views/components/icons/PlusIcon";
import CheckIcon from "views/components/icons/CheckIcon";

class NameSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditingName: false,
      itemNewName: null
    };

    this.setIsEditingName = this.setIsEditingName.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.cancelNameChange = this.cancelNameChange.bind(this);
    this.submitItemNewName = this.submitItemNewName.bind(this);
  }

  onNameChange(event) {
    this.setState({ itemNewName: event.target.value });
  }

  setIsEditingName(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isEditingName: true,
      itemNewName: this.props.itemName
    });
  }

  cancelNameChange(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isEditingName: false,
      itemNewName: null
    });
  }

  submitItemNewName() {
    this.setState({ isEditingName: false });

    if (this.state.itemNewName !== this.props.itemName) {
      this.props.submitNameChange(this.state.itemNewName);
    } else {
      /* names are the same */
      /* ignore it */
    }
  }
  render() {
    return (
      <div data-testid="NameSection">
        {!this.state.isEditingName ? (
          <div
            className={`${style.nameSection} ${this.props.nameSectionClassName}`}
          >
            <div
              className={`${style.nameBox} ${this.props.nameBoxClassName}`}
              data-is-name-editable={this.props.isNameEditable}
            >
              <div>{this.state.itemNewName || this.props.itemName}</div>
              {this.props.isNameEditable && (
                <div className={style.icon} onClick={this.setIsEditingName}>
                  <PencilIcon size={20} version="2" />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={style.nameSectionEditing}>
            <Input
              className={style.nameInput}
              value={this.state.itemNewName}
              onChange={this.onNameChange}
            />
            <div className={style.cancelIcon}>
              <PlusIcon
                rotate="45"
                size="16.5px"
                onClick={this.cancelNameChange}
              />
            </div>
            <div
              className={style.saveIcon}
              data-disabled={!this.state.itemNewName}
            >
              <CheckIcon size="16px" onClick={this.submitItemNewName} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

NameSection.displayName = "NameSection";

NameSection.propTypes = {
  itemName: PropTypes.string,
  submitNameChange: PropTypes.func
};

export default NameSection;
