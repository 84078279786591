import { values } from "lib";
import { SWITCH_TEAM } from "state/ui/uiTypes";
import {
  PERSONAL_ORGANISATION_CREATE_REQUEST_SUCCESS,
  ORGANISATION_CREATE_REQUEST_SUCCESS
} from "state/ui/teamSignup/teamSignupTypes";
import * as types from "state/currentUser/currentUserTypes";
import auth from "lib/auth";

export const initState = {
  id: null
};

const currentTeamReducers = (state = initState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_TOKEN_REQUEST: {
      const { body } = action.request;

      return {
        ...state,
        id: body.teamId
      };
    }

    case types.CURRENT_USER_TOKEN_REQUEST_SUCCESS: {
      const { accessToken } = action.response;
      const { teamId } = auth.jwtDecoder(accessToken);

      return {
        ...state,
        id: teamId
      };
    }
    case types.CURRENT_USER_SET_TOKEN_DATA: {
      return {
        ...state,
        id: action.payload.teamId
      };
    }

    case PERSONAL_ORGANISATION_CREATE_REQUEST_SUCCESS: {
      const { organisations } = action.response.entities;

      return {
        ...state,
        id: values(organisations)[0].id
      };
    }

    case ORGANISATION_CREATE_REQUEST_SUCCESS: {
      return {
        ...state,
        id: values(action.response.entities.organisations)[0].id
      };
    }

    case SWITCH_TEAM: {
      const { teamId } = action.payload;

      return {
        ...state,
        id: teamId
      };
    }

    default:
      return state;
  }
};

export default currentTeamReducers;
