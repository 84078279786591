const vectorTextProcess = (element, page) => {
  // Default the name if required.
  element.name = element.name || "Text Field";

  // assign displayValue to curved text value if not present
  if (!element.displayValue) {
    element.displayValue = element.value;
  }

  // update cruved textbox name property when it has previously been assigned the value
  if (element.name === element.value) {
    element.name = "Text Field";
  }

  return element;
};

export default vectorTextProcess;
