import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const SmartImageIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="TrashCanShortIcon"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        stroke={color || easilGrayXDark}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m10.5 10-1 1.5-1.722-2L6 12.5"
      />
      <path
        stroke={color || easilGrayXDark}
        stroke-linecap="round"
        stroke-width="2"
        d="M16 4.5a2 2 0 0 0-2-2H4.5a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2H10"
      />
      <circle cx="6.75" cy="6.25" r="1.25" fill="#57595D" fill-rule="nonzero" />
      <path
        stroke={color || easilGrayXDark}
        stroke-width="2"
        d="M15.5 9v7m-3-8h1a2 2 0 0 1 2 2 2 2 0 0 1 2-2h1m0 9.5h-1a2 2 0 0 1-2-2 2 2 0 0 1-2 2h-1"
      />
    </g>
  </svg>
);

SmartImageIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SmartImageIcon;
