import React from "react";
import style from "./style.module.css";

import CurrentSubscriptionDescription from "../CurrentSubscriptionDescription";

class CurrentSubscriptionDescriptionPaid extends CurrentSubscriptionDescription {
  getTiles() {
    const tiles = [
      {
        title: "Plan",
        body: this.planDisplayName,
        className: style.planTile
      },
      {
        title: "Active members",
        tooltip:
          "An active member is someone who has accepted their Easil invitation",
        body: this.props.subscription.quantity,
        className: style.membersCountTile
      },
      {
        title: `${this.planIntervalPeriod} plan price per member`,
        body: `$${this.props.subscription.amount.toFixed(2)} ${
          this.props.plan.currency
        }`,
        className: style.pricePerMemberTile
      },
      {
        title: `${this.planIntervalPeriod} charge`,
        tooltip: `The total ${this.planIntervalPeriod.toLowerCase()} charge for all members on your plan`,
        body: `$${(
          this.props.subscription.amount * this.props.subscription.quantity
        ).toFixed(2)} ${this.props.plan.currency}`,
        className: style.totalChargeTile
      }
    ];

    if (
      !["CANCELLING", "DOWNGRADING"].includes(this.props.subscription.status)
    ) {
      tiles.push({
        title: "Subscription renewal date",
        body: `${this.renewDate}`,
        className: style.renewDateTile
      });
    }

    return tiles;
  }

  cancellingMessage() {
    return (
      <span>
        {"Your plan has been cancelled. You'll be downgraded to the "}
        <strong>Easil Basic</strong>
        {` plan when your billing expires on ${this.renewDate}`}
      </span>
    );
  }

  downgradingMessage() {
    if (!shouldShowIntervalPeriod(this.props)) {
      return (
        <span>
          Your <strong>{this.planDisplayName}</strong> plan has been cancelled.
          Your <strong>{getNextSubscriptionDisplayName(this.props)}</strong>
          {`  plan will begin when your current billing expires on  ${
            this.renewDate
          }`}
        </span>
      );
    }

    const currentSubscriptionDescription = (
      <span>
        {this.props.plan.intervalPeriod.toLowerCase()}{" "}
        <strong>{this.planDisplayName}</strong>
      </span>
    );

    const nextSubscriptionDescription = (
      <span>
        {this.props.subscription.nextSubscription.intervalPeriod.toLowerCase()}{" "}
        <strong> {getNextSubscriptionDisplayName(this.props)}</strong>
      </span>
    );

    return (
      <span>
        Your {currentSubscriptionDescription} plan has been cancelled. Your{" "}
        {nextSubscriptionDescription} plan will begin when your current billing
        expires on {this.renewDate}
      </span>
    );

    function getNextSubscriptionDisplayName(props) {
      return `Easil ${props.subscription.nextSubscription.name}`;
    }

    function shouldShowIntervalPeriod(props) {
      return props.plan.code === props.subscription.nextSubscription.code;
    }
  }

  accountBasedMessage() {
    return (
      <span>
        Your Easil Subscription is Account based. Please click on the Help (?){" "}
        Button and notify our team to Cancel your Subscription
      </span>
    );
  }

  getInfoMessage() {
    if (this.props.subscription.status === "CANCELLING") {
      return this.cancellingMessage();
    }

    if (this.props.subscription.status === "DOWNGRADING") {
      return this.downgradingMessage();
    }

    if (this.props.subscription.isAccount) {
      return this.accountBasedMessage();
    }

    let text = ` plan. Your ${this.planIntervalPeriod.toLowerCase()} subscription will renew on ${
      this.renewDate
    }.`;

    if (this.props.card) {
      text =
        text +
        ` Your ${this.planIntervalPeriod.toLowerCase()} total will be charged to ${
          this.props.card.brand
        } ****${this.props.card.last4}.`;
    }

    return (
      <span>
        You're on the <strong>{this.planDisplayName}</strong>
        {text}
      </span>
    );
  }
}

CurrentSubscriptionDescriptionPaid.displayName =
  "CurrentSubscriptionDescriptionPaid";

export default CurrentSubscriptionDescriptionPaid;
