import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Header";
import { userChat } from "lib";
import {
  clearSearch,
  navigateDown,
  navigateUp,
  searchTeams,
  switchTeams,
  clearAll
} from "state/ui/header/TeamMenu/TeamMenuActions";
import {
  isFetchingTeamsSelector,
  searchModeSelector,
  selectedTeamSelector,
  teamsSelector
} from "state/ui/header/TeamMenu/TeamMenuSelectors";
import { fetchTeamsIfNeeded } from "state/entities/teams/teamsActions";
import {
  currentSubscriptionPlan,
  currentSubscriptionSelector
} from "state/entities/subscriptions/subscriptionsSelectors";
import {
  currentTeamSelector,
  teamsTotalCountSelector
} from "state/entities/teams/teamsSelectors";
import Subscriptions from "lib/subscriptions";
import { getPathToCatalogueBasedOnTeamSettings } from "lib/pathing";
import { moveTo } from "state/ui/navigation/navigationActions";
import PATHS from "routes/paths";
import {
  userIsAdmin,
  userIsBrandManager,
  userIsDesigner
} from "state/currentUser/currentUserSelectors";
import { teamAllowEasilTemplatesSettings } from "state/entities/teamSettings/teamSettingsSelectors";

import {
  fetchTeamDesignsIfNeeded,
  fetchPurchasedDesignsForCurrentUserTeam,
  promiseFetchTemplateDesignsFromFolder,
  fetchTeamArchivedDesignsIfNeeded
} from "state/entities/designs/designsActions";
import {
  catalogueFoldersSelector,
  isTeamFoldersFetched
} from "state/entities/folders/foldersSelectors";
import { isAnyTeamTemplates } from "state/api/designs/team/templates/templatesApiSelectors";
// import { isAnyTeamTemplatesInFolders } from "state/api/designs/team/folders/foldersApiSelectors";
import { hasTeamFoldersSelector } from "state/api/folders/team/teamApiSelectors";
import { fetchAllTeamFoldersIfNeeded } from "state/entities/folders/foldersActions";
import { getCurrentOrderByIdSelector } from "state/entities/orders/ordersSelectors";
import { fetchCurrentOrderIfNeeded } from "state/entities/orders/ordersActions";

class HeaderContainer extends Component {
  constructor(props) {
    super(props);

    this.toggleTeamMenu = this.toggleTeamMenu.bind(this);
    this.filterTeamListByTeamId = this.filterTeamListByTeamId.bind(this);
    this.onCreateDesign = this.onCreateDesign.bind(this);
    this.onTeamMenuNavigationUp = this.onTeamMenuNavigationUp.bind(this);
    this.pollUserChat = this.pollUserChat.bind(this);

    this.state = {
      displayTeamDropdown: false,
      teams: [],
      nestedTeams: {},
      teamMenuLevel: 1,
      teamMenuTeamId: null,
      teamMenuSelectedTeam: props.selectedTeam,
      isUserChatReady: false
    };
  }

  pollUserChat() {
    this.userChatPoll = setInterval(() => {
      const isUserChatReady = userChat.isReady();
      if (isUserChatReady) {
        this.setState({
          isUserChatReady
        });
        clearInterval(this.userChatPoll);
      }
    }, 1000);
    const isUserChatReady = userChat.isReady();
    if (isUserChatReady) {
      this.setState({
        isUserChatReady
      });
      clearInterval(this.userChatPoll);
    }
  }

  componentDidMount() {
    this.props.fetchTeamsIfNeeded();
    this.props.fetchPurchasedTemplates();
    this.props.fetchTeamTemplates();
    this.props.fetchTeamFolders({});
    this.props.fetchTeamArchivedDesigns({});
    this.props.fetchCurrentOrderIfNeeded();
    this.pollUserChat();
  }

  componentWillUnmount() {
    clearInterval(this.userChatPoll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.displayTeamDropdown && !this.state.displayTeamDropdown) {
      // menu was closed, should clear menu state
      this.props.clearMenuState();
    }
  }

  // On down nav
  filterTeamListByTeamId(team) {
    this.props.navigateDown(team);
  }

  onTeamMenuNavigationUp(team) {
    this.props.navigateUp(team);
  }

  toggleTeamMenu() {
    const { displayTeamDropdown } = this.state;

    this.setState({
      displayTeamDropdown: !displayTeamDropdown
    });
  }

  static handleHelpWindowToggle() {
    userChat.toggle();
  }

  onCreateDesign() {
    const { currentUserRoles, allowEasilTemplates, moveTo } = this.props;

    const isDesigner = currentUserRoles.includes("DESIGNER");

    let moveLocation = PATHS.catalogueEasil;

    if (!isDesigner && !allowEasilTemplates) {
      moveLocation = PATHS.catalogueTemplates;
    }

    moveTo(moveLocation);
  }

  render() {
    const {
      clearSearch,
      isTrialling,
      team,
      teams,
      match,
      onSwitchTeam,
      pathToCatalogue,
      isBasicPlan,
      isAccount,
      isAdmin,
      isBrandManager,
      isFetchingTeams,
      isSearchMode,
      onSearchTeams,
      selectedTeam,
      totalTeamCount,
      allowEasilTemplates,
      isTeamTemplates,
      currentOrder
    } = this.props;

    const {
      nestedTeams,
      displayTeamDropdown,
      teamMenuLevel,
      teamMenuTeamId,
      isUserChatReady
    } = this.state;

    return (
      <Header
        allowEasilTemplates={allowEasilTemplates}
        displayTeamDropdown={displayTeamDropdown}
        isAccount={isAccount}
        isAdmin={isAdmin}
        isBasicPlan={isBasicPlan}
        isBrandManager={isBrandManager}
        isFetchingTeams={isFetchingTeams}
        isSearchMode={isSearchMode}
        isTeamTemplates={isTeamTemplates}
        isTrialling={isTrialling}
        isUserChatReady={isUserChatReady}
        nestedTeams={nestedTeams}
        onClearSearch={clearSearch}
        onCreateDesign={this.onCreateDesign}
        onSearchTeams={onSearchTeams}
        onSwitchTeam={onSwitchTeam}
        onTeamMenuNavigationDown={this.filterTeamListByTeamId}
        onTeamMenuNavigationUp={this.onTeamMenuNavigationUp}
        onToggleHelpWindow={HeaderContainer.handleHelpWindowToggle}
        pathToCatalogue={pathToCatalogue}
        team={team}
        teamMenuLevel={teamMenuLevel}
        teamMenuSelectedTeam={selectedTeam}
        teamMenuTeamId={teamMenuTeamId}
        teams={teams}
        toggleTeamMenu={this.toggleTeamMenu}
        totalTeamCount={totalTeamCount}
        url={match.url}
        currentOrder={currentOrder}
      />
    );
  }
}

HeaderContainer.propTypes = {
  team: PropTypes.object.isRequired,
  teams: PropTypes.array,
  match: PropTypes.object.isRequired,
  onSwitchTeam: PropTypes.func
};

const mapStateToProps = state => {
  const selectedTeam = selectedTeamSelector(state);
  const subscriptionPlan = currentSubscriptionPlan(state);
  const currentSubscription = currentSubscriptionSelector(state);
  const pathToCatalogue = getPathToCatalogueBasedOnTeamSettings(state);
  const isTrialling = currentSubscription.status === "TRIALLING";
  const isBasicPlan = subscriptionPlan.code === Subscriptions.BASIC.code;
  const isBrandManager = userIsBrandManager(state);
  const isAdmin = userIsAdmin(state);
  const isAccount = currentSubscription.isAccount;
  const isFetchingTeams = isFetchingTeamsSelector(state);
  const isSearchMode = searchModeSelector(state);
  const team = currentTeamSelector(state);
  const teams = teamsSelector(state, selectedTeam);
  const totalTeamCount = teamsTotalCountSelector(state);

  const allowEasilTemplates = teamAllowEasilTemplatesSettings(state);
  const isDesigner = userIsDesigner(state);

  const isEasilTemplatesAvailable = allowEasilTemplates || isDesigner;

  const isTeamTemplates =
    isAnyTeamTemplates(state) || hasTeamFoldersSelector(state); //|| isAnyTeamTemplatesInFolders(state);

  return {
    selectedTeam,
    team,
    teams,
    currentUserRoles: state.currentUser.roles,
    allowEasilTemplates: isEasilTemplatesAvailable,
    isBasicPlan,
    isTrialling,
    isAccount: isAccount,
    isAdmin: isAdmin,
    isBrandManager: isBrandManager,
    isFetchingTeams,
    isSearchMode,
    pathToCatalogue,
    totalTeamCount,
    isTeamTemplates,
    teamFolders: catalogueFoldersSelector(state),
    isTeamFoldersFetched: isTeamFoldersFetched(state),
    currentOrder: getCurrentOrderByIdSelector({ state })
  };
};

const mapDispatchToProps = dispatch => ({
  clearSearch: () => dispatch(clearSearch()),
  clearMenuState: () => dispatch(clearAll()),
  onSwitchTeam: args => dispatch(switchTeams(args)),
  fetchTeamsIfNeeded: args => dispatch(fetchTeamsIfNeeded(args)),
  onSearchTeams: args => dispatch(searchTeams(args)),
  moveTo: args => dispatch(moveTo(args)),
  navigateDown: selectedTeam => dispatch(navigateDown(selectedTeam)),
  navigateUp: selectedTeam => dispatch(navigateUp(selectedTeam)),
  fetchTeamTemplates: args => dispatch(fetchTeamDesignsIfNeeded(args)),
  fetchPurchasedTemplates: args =>
    dispatch(fetchPurchasedDesignsForCurrentUserTeam(args)),
  fetchTeamFolders: args => dispatch(fetchAllTeamFoldersIfNeeded(args)),
  fetchTemplateDesignsFromFolder: args =>
    dispatch(promiseFetchTemplateDesignsFromFolder(args)),
  fetchTeamArchivedDesigns: args =>
    dispatch(fetchTeamArchivedDesignsIfNeeded(args)),
  fetchCurrentOrderIfNeeded: args => dispatch(fetchCurrentOrderIfNeeded(args))
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(HeaderContainer);
