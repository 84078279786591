import { Logger, chunk } from "lib";

export const findPage = (pages, invitationId) => {
  let pageNumberFound;

  for (let pageNum in pages) {
    const pageIds = pages[pageNum].ids;
    if (!pageIds) continue;

    if (pageIds.includes(invitationId)) {
      pageNumberFound = pageNum;
      break;
    }
  }

  if (!pageNumberFound) Logger.error("Reducer page not found.");

  return pageNumberFound;
};

export const fixPagesIds = ({ state, ids }) => {
  const idsInPages = chunk(ids, state.pageSize);

  const updatePagesIds = pagesIds =>
    pagesIds.reduce((acc, pageIds, idx) => {
      acc[1 + idx] = {
        ...state.pages[1 + idx],
        ids: pageIds
      };
      return acc;
    }, {});

  return updatePagesIds(idsInPages);
};

export const getTermKey = ({ term, size, searchType }) => {
  let type;

  if (searchType && searchType === "all") {
    type = searchType;
  } else {
    type = size || "all";
  }

  return `${term}-${type}`;
};

export const removeItemsFromArray = (itemsToRemove, array) => {
  if (!itemsToRemove.length || !array.length) return [];

  const itemsToBeRemoved = new Set(itemsToRemove);

  // filter only those elements that need not to be deleted from the array
  const updatedArray = array.filter(item => {
    return !itemsToBeRemoved.has(item);
  });

  return updatedArray;
};
