import * as types from "./workspaceSizesTypes";
import { immutableUpdate } from "lib/immutableUpdate";

export const initState = {
  brandManagerApproved: null,
  approved: null,
  shared: null,
  workspace: null,
  folders: null
};

const workspaceSizesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.WORKSPACE_APPROVED_SIZES_FILTER_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return {};
      }
      const {
        response: {
          entities: { workspaceSize }
        }
      } = action;

      return {
        ...state,
        approved: workspaceSize
      };
    }
    case types.WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_FILTER_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return {};
      }
      const {
        response: {
          entities: { workspaceSize }
        }
      } = action;

      return {
        ...state,
        brandManagerApproved: workspaceSize
      };
    }
    case types.WORKSPACE_SHARED_SIZES_FILTER_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return {};
      }
      const {
        response: {
          entities: { workspaceSize }
        }
      } = action;
      return {
        ...state,
        shared: workspaceSize
      };
    }
    case types.WORKSPACE_SIZES_FILTER_REQUEST_SUCCESS:
    case types.WORKSPACE_SIZES_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return {};
      } else {
        const {
          response: {
            entities: { workspaceSize }
          }
        } = action;
        return {
          ...state,
          workspace: workspaceSize
        };
      }
    }

    case types.WORKSPACE_SIZES_FOLDER_REQUEST: {
      // ensure that the sizes are cleared if present in case we are removing a size
      return immutableUpdate(state, {
        folders: {
          $auto: {
            [action.extra.folderId]: {
              $set: {}
            }
          }
        }
      });
    }

    case types.WORKSPACE_SIZES_FOLDER_REQUEST_SUCCESS: {
      if (!action.response.entities || !action.extra.folderId) return state;
      return immutableUpdate(state, {
        folders: {
          [action.extra.folderId]: {
            $auto: {
              $merge: {
                ...action.response.entities.workspaceSize
              }
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default workspaceSizesReducer;
