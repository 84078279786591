import * as types from "state/entities/userTeamAnimations/userTeamAnimationsTypes";
import * as animationFoldersEntitiesTypes from "state/entities/userTeamAnimationsFolders/userTeamAnimationsFoldersTypes";
import { findPage } from "../helpers";
import { formatErrors, getPath, isEmpty, omit, chunk } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
import {
  getAssetTypeFromQueryParams,
  getStatePropertyFromAnimationType
} from "views/components/Editor/sidebar/tabs/animations/animationUtils";
import { isGif } from "lib/isGif";
import { getParameterByName } from "lib/queryStringUtils";
import { EDITOR_ANIMATION_SUBTABS_OPTIONS } from "views/components/Editor/sidebar/tabs/animations/animationsTabConstants";

export const initState = {
  animations: {
    pages: {
      /* isFetching: true|false
       * ids:[]
       * didInvalidate: true|false */
    },
    detailedIds: {},
    totalCount: null
  },
  videos: {
    pages: {
      /* isFetching: true|false
       * ids:[]
       * didInvalidate: true|false */
    },
    detailedIds: {},
    totalCount: null
  },
  all: {
    pages: {
      /* isFetching: true|false
       * ids:[]
       * didInvalidate: true|false */
    },
    detailedIds: {},
    totalCount: null
  },
  folders: {
    //  folderId: {
    //    animations: {
    //     pages: {
    //       isFetching: true|false
    //       ids:[]
    //       didInvalidate: true|false
    //     }
    //    },
    //    videos: {
    //     pages: {
    //       isFetching: true|false
    //       ids:[]
    //       didInvalidate: true|false
    //     }
    //    },
    //    all: {
    //     pages: {
    //       isFetching: true|false
    //       ids:[]
    //       didInvalidate: true|false
    //     }
    //   }
    // }
  },
  totalCount: null,
  pageSize: 50,
  errors: null
};

export const handleUserTeamAnimationInFolderRequest = ({ action, state }) => {
  const {
    page,
    params: { folderId }
  } = action.request;
  const assetType = getAssetTypeFromQueryParams(action.queryParams);

  return immutableUpdate(state, {
    folders: {
      [folderId]: {
        $auto: {
          [assetType]: {
            $auto: {
              pages: {
                $auto: {
                  [page]: {
                    $auto: {
                      $merge: {
                        isFetching: true
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  });
};

export const handleUserTeamAnimationInFolderRequestSuccess = ({
  action,
  state
}) => {
  const {
    page,
    params: { folderId }
  } = action.request;
  const assetType = getAssetTypeFromQueryParams(action.queryParams);

  return immutableUpdate(state, {
    folders: {
      [folderId]: {
        $auto: {
          [assetType]: {
            $auto: {
              pages: {
                $auto: {
                  [page]: {
                    $merge: {
                      isFetching: false,
                      didInvalidate: false,
                      ids: action.response.ids || []
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  });
};

export const handleAllUserTeamAnimationRequest = ({ action, state }) => {
  const { page } = action.request;

  return {
    ...state,
    all: {
      ...state.all,
      pages: {
        ...state.all.pages,
        [page]: {
          ...state.all.pages[page],
          isFetching: true
        }
      }
    }
  };
};

export const handleUserTeamAnimationRequest = ({ action, state }) => {
  const { page } = action.request;

  return {
    ...state,
    animations: {
      ...state.animations,
      pages: {
        ...state.animations.pages,
        [page]: {
          ...state.animations.pages[page],
          isFetching: true
        }
      }
    }
  };
};

export const removeFromFolder = (action, state) => {
  const {
    request: {
      extra: { folderId, contentId }
    }
  } = action;
  const stateProperty = getAssetTypeFromQueryParams(window.location);

  if (!state.folders[folderId]) {
    return state;
  }

  const folder = state.folders[folderId][stateProperty];
  const pages = folder.pages;
  const pageId = findPage(folder.pages, contentId);

  // get an array of contentId without the target
  const updatedPageIds = pages[pageId].ids.filter(
    imageId => imageId !== contentId
  );

  const updatedPage = Object.assign({}, pages[pageId], {
    ids: updatedPageIds
  });

  // replace the page in a clone of the current pages
  const updatedPages = Object.assign({}, pages, { [pageId]: updatedPage });

  const folders = Object.assign({}, state.folders, {
    [folderId]: Object.assign({}, folder[folderId], {
      [stateProperty]: Object.assign({}, folder, { pages: updatedPages })
    })
  });

  // replace the pages with the new updated page list in state
  return Object.assign({}, state, { folders });
};

export const handleUserTeamVideoRequest = ({ action, state }) => {
  const { page } = action.request;

  return {
    ...state,
    videos: {
      ...state.videos,
      pages: {
        ...state.videos.pages,
        [page]: {
          ...state.videos.pages[page],
          isFetching: true
        }
      }
    }
  };
};

export const handleAllUserTeamAnimationRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      all: {
        pages: omit(state.all.pages, action.request.page)
      }
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    all: {
      pages: { ...state.all.pages, ...updatedPage }
    }
  };
};

export const handleUserTeamAnimationRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      animations: {
        pages: omit(state.animations.pages, action.request.page)
      }
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    animations: {
      pages: { ...state.animations.pages, ...updatedPage }
    }
  };
};

export const handleUserTeamVideoRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      videos: {
        pages: omit(state.videos.pages, action.request.page)
      }
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    videos: {
      pages: { ...state.videos.pages, ...updatedPage }
    }
  };
};

export const handleUserTeamAnimationDeleteRequestSuccess = ({
  state,
  stateProperty,
  pageIndex,
  mediaId
}) => {
  const updatedIds = state[stateProperty].pages[pageIndex].ids.filter(
    id => id !== mediaId
  );

  const pageUpdated = {
    ...state[stateProperty].pages[pageIndex],
    ids: updatedIds
  };
  return {
    ...state,
    [stateProperty]: {
      ...state[stateProperty],
      pages: {
        ...state[stateProperty].pages,
        [pageIndex]: pageUpdated
      }
    }
  };
};

export const handleUploadUserTeamAnimationRequestSuccess = ({
  state,
  stateProperty,
  urlAssetType,
  pageIndex,
  mediaId
}) => {
  if (EDITOR_ANIMATION_SUBTABS_OPTIONS.includes(urlAssetType)) {
    if (urlAssetType === stateProperty) {
      return immutableUpdate(state, {
        [stateProperty]: {
          $auto: {
            pages: {
              $auto: {
                [pageIndex]: {
                  $auto: {
                    isFetching: { $set: false },
                    didInvalidate: { $set: true },
                    ids: {
                      $autoArray: {
                        $unshift: [mediaId]
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    return {
      ...state,
      [stateProperty]: initState[stateProperty]
    };
  }

  // if no urlAsset type then we should attach the mediaId to all folder state
  const allPages = getPath(state, `all.pages`);
  const allPageNumber = allPages ? Object.keys(allPages).length : 1;

  return immutableUpdate(state, {
    all: {
      $auto: {
        pages: {
          $auto: {
            [allPageNumber]: {
              $auto: {
                isFetching: { $set: false },
                didInvalidate: { $set: true },
                ids: {
                  $autoArray: {
                    $unshift: [mediaId]
                  }
                }
              }
            }
          }
        }
      }
    }
  });
};

export const handleUploadToUserTeamFolderRequestSuccess = ({
  state,
  stateProperty,
  urlAssetType,
  mediaId,
  folderId
}) => {
  if (EDITOR_ANIMATION_SUBTABS_OPTIONS.includes(urlAssetType)) {
    if (urlAssetType === stateProperty) {
      const folderAssetPages = getPath(
        state,
        `folders.${folderId}[${stateProperty}].pages`
      );
      const pageIndex = folderAssetPages
        ? Object.keys(folderAssetPages).length
        : 1;
      return immutableUpdate(state, {
        folders: {
          $auto: {
            [folderId]: {
              [stateProperty]: {
                $auto: {
                  pages: {
                    $auto: {
                      [pageIndex]: {
                        $auto: {
                          isFetching: { $set: false },
                          didInvalidate: { $set: true },
                          ids: {
                            $autoArray: {
                              $unshift: [mediaId]
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    return {
      ...state,
      folders: {
        [folderId]: omit(state.folders[folderId], stateProperty)
      }
    };
  }

  // if no urlAsset type then we should attach the mediaId to all folder state
  const allPages = getPath(state.folders[folderId], `all.pages`);
  const allPageNumber = allPages ? Object.keys(allPages).length : 1;

  return immutableUpdate(state, {
    folders: {
      $auto: {
        [folderId]: {
          $auto: {
            all: {
              $auto: {
                pages: {
                  $auto: {
                    [allPageNumber]: {
                      $auto: {
                        isFetching: { $set: false },
                        didInvalidate: { $set: true },
                        ids: {
                          $autoArray: {
                            $unshift: [mediaId]
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  });
};

export const moveFromTeamVideoFolder = (action, state) => {
  const { mediaIds } = action.request.body;
  const folderId = getParameterByName("folderId", action.queryParams);
  const currentPages = getPath(state, `folders[${folderId}].all.pages`, {});

  const allIds = Object.values(currentPages).reduce(
    (previousValues, currentPage) => previousValues.concat(currentPage.ids),
    []
  );

  const updatedAllIds = allIds.filter(id => !mediaIds.includes(id));

  const idsSplitIntoPages = chunk(updatedAllIds, state.pageSize);

  let newPages = currentPages;
  // when all videos have been removed from a folder
  // we need to return a single page with no ids
  if (!idsSplitIntoPages.length) {
    newPages = {
      1: {
        isFetching: false,
        ids: [],
        didInvalidate: true,
        lastFetched: Date.now()
      }
    };
  } else {
    newPages = idsSplitIntoPages.reduce(
      (previousPages, ids, index) => ({
        ...previousPages,
        [index + 1]: {
          isFetching: false,
          ids,
          didInvalidate: true,
          lastFetched: Date.now()
        }
      }),
      {}
    );
  }

  return immutableUpdate(initState, {
    folders: {
      $auto: {
        [folderId]: {
          $auto: {
            all: {
              $auto: {
                $set: {
                  pages: newPages
                }
              }
            }
          }
        }
      }
    }
  });
};

const userTeamAnimationsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.ALL_USER_TEAM_ANIMATIONS_REQUEST: {
      return handleAllUserTeamAnimationRequest({ state, action });
    }

    case types.USER_TEAM_ANIMATIONS_REQUEST: {
      return handleUserTeamAnimationRequest({ state, action });
    }

    case types.USER_TEAM_VIDEOS_REQUEST: {
      return handleUserTeamVideoRequest({ state, action });
    }

    case types.ALL_USER_TEAM_ANIMATIONS_REQUEST_SUCCESS: {
      return handleAllUserTeamAnimationRequestSuccess({ state, action });
    }

    case types.USER_TEAM_ANIMATIONS_REQUEST_SUCCESS: {
      return handleUserTeamAnimationRequestSuccess({ state, action });
    }

    case types.USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST: {
      return handleUserTeamAnimationInFolderRequest({ state, action });
    }

    case types.USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS: {
      return handleUserTeamAnimationInFolderRequestSuccess({ state, action });
    }

    case types.USER_TEAM_VIDEOS_REQUEST_SUCCESS: {
      return handleUserTeamVideoRequestSuccess({ state, action });
    }

    case types.ADD_USER_TEAM_ANIMATIONS_PLACEHOLDERS: {
      const { mediaPlaceholders, folderId, mediaType } = action;
      const mediaIds = mediaPlaceholders.map(media => media.mediaId);
      // handle update of state for each mediaPlaceholder being passed in
      // check type for placeholder, then update the corresponding state
      // only return updated state if uploaded assetType is the same as the editor
      const urlAssetType = getAssetTypeFromQueryParams(window.location);
      const mediaAssetTypes = mediaPlaceholders.map(asset => asset.media.type);
      // only one placeholder is passed into the array each time
      const animationType = mediaAssetTypes[0];
      const mediaId = mediaIds[0];
      const assetType = mediaType
        ? mediaType
        : getStatePropertyFromAnimationType(animationType);

      if (folderId) {
        return handleUploadToUserTeamFolderRequestSuccess({
          state,
          stateProperty: assetType,
          urlAssetType,
          mediaId,
          folderId
        });
      }

      const assetPages = getPath(state, `${assetType}.pages`);
      const assetPageNumber = assetPages ? Object.keys(assetPages).length : 1;

      return handleUploadUserTeamAnimationRequestSuccess({
        state,
        stateProperty: assetType,
        urlAssetType,
        pageIndex: assetPageNumber,
        mediaId
      });
    }

    case types.USER_TEAM_ANIMATION_UPLOAD_REQUEST_FAILURE: {
      const mediaPlaceholderId = action.request.body.id;
      const {
        error,
        request: {
          body: { filename }
        }
      } = action;
      const errors = formatErrors(error, true);

      if (
        error.errors[0].path === "duration" &&
        action.request.body.duration > 0
      ) {
        errors[0].value = "Videos must be less than 60 seconds in duration";
      }

      const assetType = getAssetTypeFromQueryParams(window.location);
      const assetPages = getPath(state, `${assetType}.pages`);
      const pageIndex = assetPages ? Object.keys(assetPages).length : 1;
      const placeholderIdIndex = state[assetType].pages[pageIndex]?.ids.indexOf(
        mediaPlaceholderId
      );
      return immutableUpdate(state, {
        [assetType]: {
          $auto: {
            pages: {
              $auto: {
                [pageIndex]: {
                  $auto: {
                    isFetching: { $set: false },
                    didInvalidate: { $set: true },
                    ids: {
                      $splice: [[placeholderIdIndex, 1]]
                    }
                  }
                }
              }
            }
          }
        },
        errors: {
          $auto: {
            [filename]: { $set: errors }
          }
        }
      });
    }

    case types.USER_TEAM_VIDEOS_DELETE_REQUEST: {
      const { mediaIds, folderId } = action.extra;
      const size = "all";
      // Get all current pages
      const currentPages = folderId
        ? getPath(state, `folders.${folderId}.${size}.pages`, {})
        : getPath(state, `${size}.pages`, {});

      // Get all ids from all pages and concat them
      const allIds = Object.values(currentPages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        []
      );

      // Filter out ids to be deleted
      const updatedAllIds = allIds.filter(id => !mediaIds.includes(id));

      // Split ids back into their own pages
      const idsSplitIntoPages = chunk(updatedAllIds, state.pageSize);

      // rebuild new pages with updated ids
      let newPages = currentPages;
      if (!idsSplitIntoPages.length) {
        newPages = {
          1: {
            ids: []
          }
        };
      } else {
        newPages = idsSplitIntoPages.reduce(
          (previousPages, ids, index) => ({
            ...previousPages,
            [index + 1]: {
              ids
            }
          }),
          {}
        );
      }

      if (folderId) {
        return immutableUpdate(state, {
          folders: {
            $auto: {
              [folderId]: {
                $auto: {
                  all: {
                    $auto: {
                      $set: {
                        pages: newPages
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }

      return immutableUpdate(state, {
        all: {
          $auto: {
            $set: {
              pages: newPages
            }
          }
        }
      });
    }

    case types.USER_TEAM_ANIMATION_DELETE_REQUEST: {
      const {
        mediaId,
        extra: { folderId }
      } = action.request;

      const allPage = findPage(state.all.pages, action.request.mediaId);
      const animationsPage = findPage(
        state.animations.pages,
        action.request.mediaId
      );
      const videosPage = findPage(state.videos.pages, action.request.mediaId);

      if (folderId) {
        const updatedAction = {
          request: {
            extra: {
              folderId,
              contentId: mediaId
            }
          }
        };
        return removeFromFolder(updatedAction, state);
      }
      if (allPage) {
        return handleUserTeamAnimationDeleteRequestSuccess({
          state,
          stateProperty: "all",
          pageIndex: allPage,
          mediaId
        });
      }
      if (animationsPage) {
        return handleUserTeamAnimationDeleteRequestSuccess({
          state,
          stateProperty: "animations",
          pageIndex: animationsPage,
          mediaId
        });
      }
      if (videosPage) {
        return handleUserTeamAnimationDeleteRequestSuccess({
          state,
          stateProperty: "videos",
          pageIndex: videosPage,
          mediaId
        });
      }
      return state;
    }

    case animationFoldersEntitiesTypes.ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS: {
      const {
        request: {
          body: { contentId: mediaId, folderId }
        }
      } = action;
      // get the current root pages
      const rootPages = state.all.pages;

      // get the id of the page that contains our add target
      const pageId = findPage(rootPages, mediaId);

      // get an array of mediaIds without the target for the root
      const updatedPageIds = rootPages[pageId]?.ids?.filter(
        imageId => imageId !== mediaId
      );

      // compile mediaIds into a clone of the page
      const updatedPage = Object.assign({}, rootPages[pageId], {
        ids: updatedPageIds
      });

      // replace the page in a clone of the current pages
      const updatedPages = Object.assign({}, rootPages, {
        [pageId]: updatedPage
      });

      // push mediaId into 'folders' state after it has been removed from the 'all' state
      let updatedFolders = state.folders;
      const targetFolder = state.folders[folderId];
      // if folder state is not empty empty, create clone
      // push media id into updatedFolder page ids
      // replace the target folder with the updated folder object
      if (targetFolder && targetFolder.all) {
        const targetFolderAll = targetFolder.all;
        const targetFolderPages = getPath(targetFolderAll, "pages");
        const targetFolderPageId = targetFolderPages
          ? Object.keys(targetFolderPages).length
          : 1;

        updatedFolders = {
          ...state.folders,
          [folderId]: {
            ...targetFolderAll,
            pages: {
              ...targetFolderAll.pages,
              [targetFolderPageId]: {
                ...targetFolderAll.pages[targetFolderPageId],
                ids: [...targetFolderAll.pages[targetFolderPageId].ids, mediaId]
              }
            }
          }
        };
      }

      // replace the pages with the new updated page list in state
      return {
        ...state,
        all: {
          ...state.all,
          pages: updatedPages
        },
        folders: updatedFolders
      };
    }

    case animationFoldersEntitiesTypes.BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS: {
      const { mediaIds } = action.request.body;
      const currentFolderId = getParameterByName(
        "folderId",
        action.queryParams
      );

      // removing from a folder
      if (currentFolderId) {
        return moveFromTeamVideoFolder(action, state);
      }

      const currentPages = getPath(state, `all.pages`, {});

      const allIds = Object.values(currentPages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        []
      );

      const updatedAllIds = allIds.filter(id => !mediaIds.includes(id));
      const idsSplitIntoPages = chunk(updatedAllIds, state.pageSize);

      const newPages = idsSplitIntoPages.reduce(
        (previousPages, ids, index) => ({
          ...previousPages,
          [index + 1]: {
            isFetching: false,
            ids,
            didInvalidate: true,
            lastFetched: Date.now()
          }
        }),
        {}
      );

      return immutableUpdate(initState, {
        all: {
          $auto: {
            $set: {
              pages: newPages
            }
          }
        }
      });
    }

    case types.USER_TEAM_ANIMATION_UPLOAD_REQUEST_SUCCESS: {
      const { mediaId } = action.response;
      const mediaType = action.response.media.type;
      const assetType = getStatePropertyFromAnimationType(mediaType);
      const urlAssetType = getAssetTypeFromQueryParams(action.queryParams);

      if (!mediaId) return state;

      const assetPages = getPath(state, `${assetType}.pages`);
      const assetPageNumber = assetPages ? Object.keys(assetPages).length : 1;

      return handleUploadUserTeamAnimationRequestSuccess({
        state,
        stateProperty: assetType,
        urlAssetType,
        pageIndex: assetPageNumber,
        mediaId
      });
    }

    case animationFoldersEntitiesTypes.UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS: {
      const {
        body: { folderId, id: mediaId, filename }
      } = action.request;
      const urlAssetType = getParameterByName("assetType", action.queryParams);
      const assetType = isGif(filename) ? "animations" : "videos";

      if (!mediaId) return state;

      return handleUploadToUserTeamFolderRequestSuccess({
        state,
        stateProperty: assetType,
        urlAssetType,
        mediaId,
        folderId
      });
    }

    case types.CLEAR_USER_TEAM_ANIMATION_UPLOAD_ERRORS: {
      const { errors } = state;

      if (!errors) return state;

      return omit(state, "errors");
    }

    case types.USER_TEAM_ANIMATIONS_CLEAR: {
      return initState;
    }

    default:
      return state;
  }
};

export default userTeamAnimationsApiReducers;
