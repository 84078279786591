import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const UnassignSmartImageIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="TrashCanShortIcon"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        stroke={color || easilGrayXDark}
        stroke-linecap="round"
        stroke-width="2"
        d="M16 9.5v-5a2 2 0 0 0-2-2H4.5a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h5"
      />
      <path
        stroke={color || easilGrayXDark}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12.5 10 11 7.5l-1.75 4L7.5 9.75l-1.5 3"
      />
      <circle
        cx="6.75"
        cy="6.25"
        r="1.25"
        fill={color || easilGrayXDark}
        fill-rule="nonzero"
      />
      <path
        stroke={color || easilGrayXDark}
        stroke-linecap="round"
        stroke-width="2"
        d="m17 13-4 4m4 0-4-4"
      />
    </g>
  </svg>
);

UnassignSmartImageIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default UnassignSmartImageIcon;
