import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ExitStandardisedIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  direction
}) => {
  const directionToAngle = {
    up: -90,
    right: 0,
    down: 90,
    left: 180
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction]}deg)`,
    ...style
  };
  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 20}
      height={height || size || 20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill={color || easilGrayXDark} fillRule="nonzero">
        <path d="M7 18a1 1 0 0 1 0 2H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h4a1 1 0 1 1 0 2H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h4z" />
        <path d="M16.586 11H7a1 1 0 0 1 0-2h9.586l-3.293-3.293a1 1 0 1 1 1.414-1.414l5 5a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414-1.414L16.586 11z" />
      </g>
    </svg>
  );
};
ExitStandardisedIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ExitStandardisedIcon;
