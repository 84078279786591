import React from "react";
import style from "./style.module.css";
import { Loading } from "views/components";

const PrintItem = ({ item, orderPrintOptions }) => {
  if (!item || !orderPrintOptions) {
    return (
      <div
        style={{
          height: "112px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loading />
      </div>
    );
  }

  const orderPrintOption =
    orderPrintOptions.find(
      i => i.id === item.printProviderPrintOptionPricingId
    ) || {};

  const { finishing, sizeDescription, stock } = orderPrintOption;
  const { quantity, total } = item;

  return (
    <div className={style.printItem}>
      <div className={style.col1}>
        <div className={style.subtotal}>Subtotal:</div>
        <div>
          <strong>Quantity:</strong>
        </div>
        <div>
          <strong>Size:</strong>
        </div>
        <div>
          <strong>Material:</strong>
        </div>
        {finishing && <div>Finish:</div>}
      </div>
      <div className={style.col2}>
        <div className={style.subtotal}>
          ${total ? total.toFixed(2) : "0.00"}
        </div>
        <div>{quantity}</div>
        <div>{sizeDescription}</div>
        <div>{stock}</div>
        <div>{finishing}</div>
      </div>
    </div>
  );
};

export default PrintItem;
