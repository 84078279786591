import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PadlockCurvedIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  isFilled
}) => {
  if (isFilled) {
    return (
      <svg
        className={className}
        style={style}
        data-no-pointer={noPointer}
        width={width || size || 16}
        height={height || size || 22}
        viewBox="0 0 16 22"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <title>{title}</title>
        <g fill="none" fillRule="evenodd">
          <path d="M-4-.5h24v24H-4z" />
          <path
            d="M8 .5c2.21 0 4 1.79 4 4v3h.922a3 3 0 013 3c0 .058 0 .058-.003.115l-.17 4.428a7.756 7.756 0 01-15.499 0l-.17-4.428a3.002 3.002 0 012.884-3.113l.043-.001.992-.001.001-3c0-2.21 1.79-4 4-4zm0 11a1.9 1.9 0 00-.901 3.573L7.1 16.6a.9.9 0 001.8 0v-1.527A1.9 1.9 0 008 11.5zm0-9a2 2 0 00-2 2v3h4v-3a2 2 0 00-2-2z"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 16}
      height={height || size || 22}
      viewBox="0 0 16 22"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M-4-1h24v24H-4z" />
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M8 0a4 4 0 0 1 4 4v3h.922a3 3 0 0 1 3 3c0 .058 0 .058-.003.115l-.17 4.428a7.755 7.755 0 0 1-15.498 0l-.17-4.428a3 3 0 0 1 2.882-3.113l.043-.001L3.999 7 4 4a4 4 0 0 1 4-4zm4.922 9H3.04a1 1 0 0 0-.96 1.038l.17 4.428a5.755 5.755 0 0 0 11.5 0l.17-4.428.002-.038a1 1 0 0 0-1-1zM8 11a1.9 1.9 0 0 1 .9 3.573V16.1a.9.9 0 1 1-1.8 0l-.001-1.527A1.9 1.9 0 0 1 8 11zm0-9a2 2 0 0 0-2 2v3h4V4a2 2 0 0 0-2-2z"
        />
      </g>
    </svg>
  );
};
PadlockCurvedIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PadlockCurvedIcon;
