import {
  UI_CART_SET_CURRENT_ORDER_ID,
  UI_CART_SET_LOADING,
  UI_CART_SET_COUPON_ERROR,
  UI_CART_SET_BILLING,
  UI_CART_SET_SHIPPING,
  UI_CART_SHIPPING_SAME_AS_BILLING,
  UI_CART_SET_TOKEN,
  UI_CART_SET_PAYMENT_TYPE,
  UI_CART_SET_SHIPPING_DEFAULTS,
  UI_CART_CLEAR
} from "./cartTypes";

import { currentTeamSelector } from "state/entities/teams/teamsSelectors";

export const setCurrentOrderId = ({ orderId }) => {
  return {
    type: UI_CART_SET_CURRENT_ORDER_ID,
    orderId
  };
};

export const setLoading = loading => {
  return {
    type: UI_CART_SET_LOADING,
    loading
  };
};

export const setCouponError = () => {
  return {
    type: UI_CART_SET_COUPON_ERROR
  };
};

export const setCurrentBilling = billingUpdate => {
  return {
    type: UI_CART_SET_BILLING,
    billingUpdate
  };
};

export const setCurrentShipping = shippingUpdate => {
  return {
    type: UI_CART_SET_SHIPPING,
    shippingUpdate
  };
};

export const setShippingSameAsBilling = () => {
  return {
    type: UI_CART_SHIPPING_SAME_AS_BILLING
  };
};

export const setToken = token => {
  return {
    type: UI_CART_SET_TOKEN,
    token: token ? token.id : ""
  };
};

export const setPaymentType = paymentType => {
  return {
    type: UI_CART_SET_PAYMENT_TYPE,
    paymentType
  };
};

export const getShippingFromAccountInfo = () => (dispatch, getState) => {
  const state = getState();
  const currentTeam = currentTeamSelector(state);
  const currentUser = state.currentUser;

  const defaultShipping = {
    attention: currentUser.name,
    name: currentTeam.name,
    addressType: "BUSINESS",
    country: currentTeam.country
  };

  dispatch({
    type: UI_CART_SET_SHIPPING_DEFAULTS,
    defaultShipping
  });

  return defaultShipping;
};

export const clearCartState = () => {
  return {
    type: UI_CART_CLEAR
  };
};
