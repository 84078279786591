import React from "react";
import style from "./style.module.css";
import InputRow from "./InputRow";
import { composeClassName } from "../../../lib";

export const Form = ({
  title,
  rows,
  wrapperClassName,
  children,
  showValidation,
  showTitle,
  rowSpacingX = "16px",
  rowSpacingY = "16px",
  titleStyle,
  activeLabel,
  selectActiveLabel
}) => (
  <div className={wrapperClassName}>
    {showTitle && (
      <div className={composeClassName("title", style, titleStyle)}>
        {title}
      </div>
    )}
    {children}
    {rows.map((row, index) => (
      <InputRow
        row={row}
        showValidation={showValidation}
        key={`${row[0].label}-${index}`}
        rowSpacingX={rowSpacingX}
        rowSpacingY={rowSpacingY}
        activeLabel={activeLabel}
        selectActiveLabel={selectActiveLabel}
      />
    ))}
  </div>
);

Form.displayName = "Form";

export default Form;
