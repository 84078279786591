import React from "react";
import { Button, Tooltip } from "views/components";
import EyeDropperIcon from "views/components/icons/EyeDropperIcon";
import { isValidHex } from "react-color/lib/helpers/color";
import { rgbToHex } from "lib/Colors/colorUtils";

const ColorEyeDropperButton = ({ onChange, className }) => {
  if (!window.EyeDropper) return null;

  const handleEyeDropperChange = e => {
    let eyeDropper = new window.EyeDropper();
    const abortController = new window.AbortController();

    if (eyeDropper) {
      eyeDropper
        .open()
        .then(result => {
          let hexValue = result.sRGBHex;
          if (!isValidHex(hexValue) && hexValue.startsWith("rgb")) {
            // attempt to convert to hex from rgb
            hexValue = rgbToHex(hexValue);
          }
          isValidHex(hexValue) &&
            onChange(
              {
                hex: hexValue,
                source: "hex"
              },
              e
            );
        })
        .catch(error => {
          console.error("Error accessing eye dropper:", error);
        });

      setTimeout(() => {
        abortController.abort();
      }, 2000);
    }
  };

  return (
    <>
      <div
        data-tip="Eye Dropper"
        data-for="color-eye-dropper"
        className={className}
      >
        <Button theme="actionbar" onClick={handleEyeDropperChange}>
          <EyeDropperIcon size="20px" />
        </Button>
      </div>
      <Tooltip id="color-eye-dropper" place="top" />
    </>
  );
};

export default ColorEyeDropperButton;
