import React from "react";
import style from "./style.module.css";
import FolderWideIcon from "views/components/icons/FolderWideIcon";
import TickIcon from "views/components/icons/TickIcon";

const FolderPicker = ({
  onClick,
  destinationName,
  selectedFolderId,
  folders,
  className
}) => {
  const isRootSelected = !selectedFolderId || selectedFolderId === "root";

  return (
    <div className={`${style.folderSelectionContent} ${className}`}>
      <div
        className={style.folderSelectionContentItem}
        data-is-selected={isRootSelected}
        onClick={() => onClick(null)}
      >
        <div className={style.uploadFolderIconWrapper}>
          <FolderWideIcon size="22px" color="#9a9b9d" />
        </div>
        <div className={style.folderSelectionFolderTitle}>
          {destinationName}
        </div>
        {isRootSelected && (
          <div className={style.folderSelectionContentTickWrapper}>
            <TickIcon hasCircle={false} color={style.easilBlueDeep} />
          </div>
        )}
      </div>
      {folders.map(folder => {
        const isSelected = selectedFolderId === folder.id;
        return (
          <div
            key={folder.id}
            className={style.folderSelectionContentItem}
            data-is-sub={true}
            data-is-selected={isSelected}
            onClick={() => onClick(folder.id)}
          >
            <div className={style.uploadFolderIconWrapper}>
              <FolderWideIcon size="22px" color="#9a9b9d" />
            </div>
            <div className={style.uploadFolderName}>{folder.name}</div>
            {isSelected && (
              <div className={style.folderSelectionContentTickWrapper}>
                <TickIcon hasCircle={false} color={style.easilBlueDeep} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FolderPicker;
