import { createSelector } from "reselect";

export const currentUserSelector = state => state.currentUser;

export const currentUserIdSelector = state => state.currentUser.id;

export const currentUserRolesSelector = state => state.currentUser.roles;

export const logoutLocationSelector = state => state.currentUser.logoutLocation;

export const onPersonalTeam = state =>
  state.personalTeam.id === state.ui.currentTeam.id;

const processUserIsAdmin = currentUserRoles => {
  return currentUserRoles.includes("ADMIN");
};

export const userIsAdmin = createSelector(
  [currentUserRolesSelector],
  processUserIsAdmin
);

const processUserIsBrandManager = currentUserRoles => {
  return currentUserRoles.includes("BRAND_MANAGER");
};

export const userIsBrandManager = createSelector(
  [currentUserRolesSelector],
  processUserIsBrandManager
);

const processUserIsDesigner = currentUserRoles => {
  return currentUserRoles.includes("DESIGNER");
};

export const userIsDesigner = createSelector(
  [currentUserRolesSelector],
  processUserIsDesigner
);
