import {
  UI_OPEN_COLLECTION_PREVIEW_MODAL,
  UI_CLOSE_COLLECTION_PREVIEW_MODAL
} from "./collectionPreviewModalTypes";

export const initState = {
  isOpen: false
};

const collectionPreviewModal = (state = initState, action) => {
  switch (action.type) {
    case UI_OPEN_COLLECTION_PREVIEW_MODAL: {
      return {
        isOpen: true,
        selectedDesignId: action.selectedDesignId
      };
    }

    case UI_CLOSE_COLLECTION_PREVIEW_MODAL: {
      return initState;
    }

    default:
      return state;
  }
};

export default collectionPreviewModal;
