import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const LinesIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 14"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd" transform="translate(-2 -5)">
      <path d="M0 0h24v24H0z" />
      <rect
        width="20"
        height="2"
        x="2"
        y="5"
        stroke="none"
        fill={color}
        rx="1"
      />
      <path
        fill={color}
        stroke="none"
        d="M3 17h2a1 1 0 1 1 0 2H3a1 1 0 1 1 0-2zm5.5 0h2a1 1 0 1 1 0 2h-2a1 1 0 1 1 0-2zm5 0h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2zm5.5 0h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2zM3 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm3 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
      />
    </g>
  </svg>
);
LinesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default LinesIcon;
