import React from "react";
import { pick } from "lib/lodash";
import { selectHtmlNodeText } from "lib/selectionUtils";
import UncontrolledContentEditable from "views/components/Editor/elements/UncontrolledContentEditable";
import style from "./style.module.css";

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
  }

  componentDidMount() {
    /* ensure that new rows that are automatically selected are have their content selected */
    if (this.props.isSelected) {
      const div = this.divRef.current.ref.current;
      selectHtmlNodeText(div);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isSelected && this.props.isSelected) {
      const div = this.divRef.current.ref.current;
      selectHtmlNodeText(div);
    }
  }

  render() {
    const {
      value,
      metadata,
      height,
      index,
      id,
      rowIndex,
      cellIndex,
      onSelectTableTextField,
      isSelected,
      onTableTextFieldPreview,
      onTableTextFieldChange,
      isTextEditRestricted,
      onSelectNextTableTextField,
      onSelectPreviousTableTextField,
      rowType
    } = this.props;

    const styleAttributes = [
      "color",
      "fontFamily",
      "fontSize",
      "letterSpacing",
      "lineHeight",
      "textAlign",
      "textDecoration"
    ];

    const metadataStyle = pick(metadata, styleAttributes);

    const dynamicStyle = {
      ...metadataStyle,
      lineHeight: `${metadataStyle.lineHeight}em`,
      letterSpacing: `${metadataStyle.letterSpacing / 1000}em`,
      fontSize: `${metadataStyle.fontSize}px`,
      height,
      cursor: isSelected ? "text" : "move",
      ...metadata.borders.getAsCss()
    };

    const selectTextField = () => {
      onSelectTableTextField({
        rowIndex,
        cellIndex,
        textFieldIndex: index,
        textFieldId: id,
        rowType
      });
    };

    const onBlur = (_value, event) => {
      onTableTextFieldChange();
    };

    const onChange = (newValue, event) => {
      onTableTextFieldPreview({
        height: event.target.scrollHeight,
        value: newValue
      });
    };

    const onKeyDown = event => {
      if (event.key === "Tab") {
        event.stopPropagation();
        event.preventDefault();

        /* jump to next/previous textField */
        event.shiftKey
          ? onSelectPreviousTableTextField()
          : onSelectNextTableTextField();
      }
    };

    const uncontrolledContentRef =
      this.divRef && this.divRef.current
        ? this.divRef.current.ref.current
        : null;

    const selectionBoxStyles = {
      border: !isTextEditRestricted ? "1px solid rgb(49,132,252)" : "none",
      position: "absolute",
      width: "0px",
      height: "0px",
      top: "inherit",
      left: "inherit",
      zIndex: 5,
      pointerEvents: "none"
    };

    if (uncontrolledContentRef) {
      Object.assign(selectionBoxStyles, {
        width: `${uncontrolledContentRef.offsetWidth}px`,
        height: `${uncontrolledContentRef.offsetHeight}px`,
        top: `${uncontrolledContentRef.offsetTop}px`,
        left: `${uncontrolledContentRef.offsetLeft}px`
      });
    }

    return (
      <div>
        <UncontrolledContentEditable
          onClick={selectTextField}
          style={dynamicStyle}
          onKeyDown={onKeyDown}
          className={style.tableTextField}
          onBlur={onBlur}
          text={value || "&nbsp;"}
          editable={!isTextEditRestricted}
          onChange={onChange}
          ref={this.divRef}
          disableInlineStyling
        />
        <div style={selectionBoxStyles} />
      </div>
    );
  }
}

export default TextField;
