const VIDEO_FILE_EXTENSIONS = ["mp4", "MP4"];

export const isVideo = url => {
  if (!url) return false;
  const fileExtension = url.split(".").pop();
  if (
    VIDEO_FILE_EXTENSIONS.some(
      extension => fileExtension && fileExtension.toLowerCase() === extension
    )
  ) {
    return true;
  }
  return false;
};
