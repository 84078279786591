import React from "react";
import style from "./style.module.css";
import { pick } from "lib/lodash";
import { MaskedVideoDraggable } from "./MaskedVideo";
import VideoCroppingHandler from "./VideoCroppingHandler";
import { getElementComponent } from "views/components/Editor/elements/elementsComponentsMap";
import ResizeBox from "views/components/Editor/ResizeBox";

const VideoCropping = ({
  canvasBackground,
  finishVideoCropMoving,
  videoElement,
  isCropping,
  pageId,
  pageOffset,
  startVideoCropMoving,
  zoom,
  startCropMaskResize,
  finishCropMaskResize
}) => {
  const cancelClick = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const getWrapperStyle = () => {
    return {
      height: canvasBackground.offsetHeight,
      width: canvasBackground.offsetWidth,
      overflow: "hidden",
      position: "absolute",
      top: 0,
      left: 0
    };
  };

  const getGrayOverlayStyle = () => {
    return {
      height: canvasBackground.offsetHeight,
      width: canvasBackground.offsetWidth,
      overflow: "hidden",
      opacity: isCropping ? 0 : 0.5
    };
  };

  const getPageStyle = () => {
    return {
      position: "absolute",
      top: pageOffset.top,
      left: pageOffset.left,
      zIndex: 4
      // opacity: isCropping ? 0 : 1
    };
  };

  const getElementStyle = () => {
    const translateX = videoElement.left * zoom;
    const translateY = videoElement.top * zoom;

    const transform = [
      `translate(${translateX}px, ${translateY}px)`,
      `scale(${zoom})`
    ].join(" ");

    return {
      pointerEvents: "none",
      position: "absolute",
      width: 0,
      height: 0,
      zIndex: 4,
      opacity: isCropping ? 0 : 1,
      transform: transform,
      transformOrigin: "0 0"
    };
  };

  const getRotationStyle = () => {
    return {
      transform: [
        `rotate(${videoElement.angle}deg)`,
        `scaleX(${videoElement.scaleX})`,
        `scaleY(${videoElement.scaleY})`
      ].join(" ")
    };
  };

  const { ElementComponent, componentProps } = getElementComponent("video");

  const elementData = pick(videoElement, componentProps);

  const getElementCropStyle = () => {
    const translateX = videoElement.left * zoom;
    const translateY = videoElement.top * zoom;

    const transform = `translate(${translateX}px, ${translateY}px) rotate(${videoElement.angle}deg)`;

    return {
      position: "absolute",
      width: `${videoElement.width * zoom}px`,
      height: `${videoElement.height * zoom}px`,
      transform,
      pointerEvents: "none",
      zIndex: 4,
      opacity: isCropping ? 0 : 1
    };
  };

  const Handler = props => {
    return (
      <VideoCroppingHandler
        videoElement={videoElement}
        startCropMaskResize={startCropMaskResize}
        finishCropMaskResize={finishCropMaskResize}
        pageId={pageId}
        {...props}
      />
    );
  };

  return (
    <div onClick={cancelClick} id="VideoCroppingDiv" style={getWrapperStyle()}>
      <div className={style.videoCropping} style={getGrayOverlayStyle()} />
      <div style={getPageStyle()}>
        <MaskedVideoDraggable
          finishVideoCropMoving={finishVideoCropMoving}
          videoElement={videoElement}
          isCropping={isCropping}
          pageOffset={pageOffset}
          startVideoCropMoving={startVideoCropMoving}
          zoom={zoom}
        />
        <div style={getElementStyle()} id="VideoCroppingElementDiv">
          <div>
            <ElementComponent
              zoom={zoom}
              style={getRotationStyle()}
              key={videoElement.uniqueId}
              elementId={videoElement.uniqueId}
              elementData={elementData}
              autoPlay
            />
          </div>
        </div>
        <div style={getElementCropStyle()} id="VideoCroppingMaskResizeBox">
          <ResizeBox Handler={Handler} pageId={pageId} isCropping={true} />
        </div>
      </div>
    </div>
  );
};

export default VideoCropping;
