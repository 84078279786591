import React from "react";
import style from "./style.module.css";
import { Tag } from "views/components";

export default class TagInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "" };
  }

  componentDidUpdate(prevProps) {
    const { tags } = this.props;
    const { tags: tagsPrev } = prevProps;

    if ((tags || []).length !== (tagsPrev || []).length) {
      this.setState({ name: "" });
    }
  }

  handleChange = event => {
    this.setState({ name: event.target.value });
  };

  handleKeyDown = event => {
    const key = event.key.toUpperCase();
    const { name } = this.state;
    const { onAdd, onRemove, tags } = this.props;

    if (key === "BACKSPACE" && name === "" && tags && tags.length) {
      onRemove(tags[tags.length - 1].id);
    } else if (key === "ENTER" && name) {
      onAdd(event, name);
    }
  };

  render() {
    const { disableAdd, disableRemove, onRemove, tags } = this.props;

    return (
      <>
        <div className={style.tagInput}>
          {(tags || []).map(tag => (
            <Tag
              canAddRemoveTags={!disableRemove}
              className={style.tagInputTag}
              displayAvatar={false}
              id={tag.id}
              key={tag.id}
              label={tag.name}
              onRemove={onRemove}
            />
          ))}
          {!disableAdd && (
            <input
              autoFocus
              className={style.tagInputTextfield}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              value={this.state.name}
            />
          )}
        </div>
        {!disableAdd && (
          <label className={style.tagInputHelper}>Enter / Return to add.</label>
        )}
      </>
    );
  }
}
