import React from "react";
import { connect } from "react-redux";

import { RemoveBackgroundModal } from "views/components";
import { noop } from "lib";

import { currentSubscriptionSelector } from "../../../state/entities/subscriptions/subscriptionsSelectors";
import { currentSubscriptionPlanSelector } from "../../../state/entities/subscriptionPlans/subscriptionPlansSelectors";
import { moveToSubscription } from "state/ui/navigation/navigationActions";
import UserSubscriptionPlan from "views/containers/plansContainer/PricingGrid/UserSubscriptionPlan";
import { fetchCreditsIfNeeded } from "state/entities/credits/creditsActions";
import { removeBg as removeBackground } from "state/ui/workspace/myImages/removeBgActions";
import { removeBackgroundSelector } from "state/entities/credits/creditsSelectors";
import { canUpgradeSubscription } from "state/ui/uiSelectors";
import { removeBgUiSelector } from "state/ui/workspace/myImages/removeBgSelector.js";
import { resetModal } from "state/ui/workspace/myImages/removeBgActions";

export class RemoveBackgroundModalContainer extends React.Component {
  render() {
    const {
      userSubscriptionPlan,
      isOpen,
      onOpen = noop,
      onClose = noop,
      moveToSubscription,
      fetchCredits,
      removeBackgroundCredits,
      removeBackground: removeBackgroundAction,
      mediaId,
      mediaType,
      isProcessing,
      errors,
      resetModal,
      userCanUpgrade
    } = this.props;

    return (
      <RemoveBackgroundModal
        userCanUpgrade={userCanUpgrade}
        userSubscriptionPlan={userSubscriptionPlan}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        moveToSubscription={moveToSubscription}
        fetchCredits={fetchCredits}
        removeBackgroundCredits={removeBackgroundCredits}
        removeBackground={removeBackgroundAction}
        mediaId={mediaId}
        mediaType={mediaType}
        isProcessing={isProcessing}
        errors={errors}
        resetModal={resetModal}
      />
    );
  }
}

function mapStateToProps(state) {
  const subscription = currentSubscriptionSelector(state);
  const plan = subscription
    ? currentSubscriptionPlanSelector(state, subscription.planId)
    : null;
  const userSubscriptionPlan = new UserSubscriptionPlan({
    subscription,
    plan
  });
  const removeBackgroundCredits = removeBackgroundSelector(state);
  const removeBgUiState = removeBgUiSelector(state);

  return {
    userCanUpgrade: canUpgradeSubscription(state),
    userSubscriptionPlan,
    removeBackgroundCredits,
    isProcessing: removeBgUiState.isProcessing,
    errors: removeBgUiState.errors
  };
}

const mapDispatchToProps = dispatch => {
  return {
    moveToSubscription: (...args) => dispatch(moveToSubscription()),
    fetchCredits: (...args) => dispatch(fetchCreditsIfNeeded(args)),
    removeBackground: (...args) => dispatch(removeBackground(...args)),
    resetModal: (...args) => dispatch(resetModal())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveBackgroundModalContainer);
