import * as types from "./teamBillingTypes";
import { merge } from "lib";

export const initState = {};

const teamBillingReducers = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_BILLING_REQUEST_SUCCESS: {
      const { entities } = action.response;

      if (!entities) return state;

      return merge({}, state, entities.teamBilling);
    }

    case types.TEAM_BILLING_UPDATE_REQUEST_SUCCESS: {
      const { entities } = action.response;

      return merge({}, state, entities.teamBilling);
    }

    default:
      return state;
  }
};

export default teamBillingReducers;
