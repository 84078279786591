import { createTeam } from "state/entities/teams/teamsActions";
import { moveTo } from "state/ui/navigation/navigationActions";
import PATHS from "routes/paths";
import Subscriptions from "lib/subscriptions";
import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";
import { teamSelector } from "state/entities/entitiesSelectors";
import { currentSubscriptionPlanSelector } from "state/entities/subscriptionPlans/subscriptionPlansSelectors";
import { noop } from "lib";

const getRedirectLocation = (teamType, currentPlan) => {
  if (teamType === "subTeam" && currentPlan.code === Subscriptions.EDGE.code) {
    return PATHS.catalogueAllPosterSearch;
  }
  return PATHS.catalogueAll;
};

export const createTeamAndRedirect = (team, onSuccess = noop) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentTeam = teamSelector({ state });

  const currentSubscription = currentSubscriptionSelector(state);
  const currentPlan = currentSubscriptionPlanSelector(
    state,
    currentSubscription.planId
  );
  const redirectLocation = getRedirectLocation(team.type, currentPlan);

  dispatch(
    createTeam(team, currentTeam, () => {
      dispatch(moveTo(redirectLocation));
      onSuccess();
    })
  );
};
