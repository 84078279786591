/**
 * @desc calculates the vector between two given points
 * @param {Object} pointA - a point object of form { x: <Number>, y: <Number> }
 * @param {Object} pointB - a point object of form { x: <Number>, y: <Number> }
 * @returns {Object} a vector object of form { x: <Number>, y: <Number> }
 */
export const vectorBetweenPoints = (pointA, pointB) => ({
  x: pointA.x - pointB.x,
  y: pointA.y - pointB.y
});
