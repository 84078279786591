import React, { Component } from "react";
import { noop, composeClassName, keyCodes } from "lib";
import style from "./style.module.css";

class InputTransparent extends Component {
  constructor(props) {
    super(props);

    this.defaultKeyPress = this.defaultKeyPress.bind(this);
    this.defaultOnKeyDown = this.defaultOnKeyDown.bind(this);
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.nameInput.focus();
    }

    if (this.props.onSelectAll) {
      this.nameInput.select();
    }

    const { onFocusIn = noop } = this.props;
    this.nameInput.addEventListener("focusin", onFocusIn);
  }

  componentWillUnmount() {
    const { onFocusIn = noop } = this.props;
    this.nameInput.removeEventListener("focusin", onFocusIn);
  }

  defaultKeyPress(event) {
    if (event.key === "Enter") {
      this.nameInput.blur();
    } else {
      noop();
    }
  }

  defaultOnKeyDown(event) {
    /* We stop propagation to prevent elements to be deleted from canvas */
    if (keyCodes.isBlackListed(event.keyCode)) {
      event.stopPropagation();
    }
  }

  render() {
    const {
      className,
      dataPrivate,
      dataTestId,
      disabled = false,
      onBlur,
      onChange = noop,
      onClick = noop,
      onFocus = noop,
      onKeyDown = this.defaultOnKeyDown,
      onKeyPress = this.defaultKeyPress,
      placeholder = "",
      type = "",
      value
    } = this.props;

    return (
      <input
        className={`${composeClassName("inputTransparent", style, className)}${
          disabled ? ` ${style.disabled}` : ""
        }`}
        data-private={dataPrivate}
        data-testid={dataTestId}
        disabled={disabled}
        ref={input => {
          this.nameInput = input;
        }}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        type={type}
        value={value || ""}
      />
    );
  }
}

export default InputTransparent;
