import {
  getAllFontFamilyContent,
  getComputedFontFamilyForNode,
  removeFontFamilyFromSelection,
  getNodesInRange
} from "lib/DOMNodeUtils";
import { uniq } from "lib/lodash";
import { createDOMElementFromHTMLString } from "lib/tests/DOMNodeUtils/testSetupFunctions";
import { updateSmartTextPlaceholder } from "lib/textUtils";

export const getValueUpdateSideEffects = ({ sideEffects = {}, value }) => {
  const _sideEffects = { ...sideEffects };
  const textboxElement = createDOMElementFromHTMLString(value);

  textboxElement.style["position"] = "absolute";
  textboxElement.contentEditable = "true";

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(textboxElement);

  selection.removeAllRanges();
  selection.addRange(range);

  const nodesInRange = getNodesInRange(textboxElement);

  const fontFamilyNodes = getAllFontFamilyContent(nodesInRange.allNodes);

  if (fontFamilyNodes.length > 0) {
    // there is some font family nodes, need to check if all nodes are of the same font family
    const filteredNodes = nodesInRange.allNodes.filter(
      node => node.nodeName !== "BR" && node !== textboxElement
    );
    const fontFamilies = filteredNodes.map(node =>
      getComputedFontFamilyForNode(node, textboxElement)
    );
    const uniqueFontFamilies = uniq(fontFamilies);
    if (uniqueFontFamilies.length === 1) {
      // strip all font family data
      removeFontFamilyFromSelection({
        styledNodes: fontFamilyNodes,
        nodesInRange
      });
      _sideEffects.value = textboxElement.innerHTML;
      _sideEffects.fontFamily = uniqueFontFamilies[0].replaceAll('"', "");
    }
  }
  textboxElement.remove();
  return _sideEffects;
};

export const getFontFamilyUpdateSideEffects = ({
  sideEffects = {},
  value,
  smartTextState,
  attributes,
  getHeight
}) => {
  const _sideEffects = { ...sideEffects };

  const textboxElement = createDOMElementFromHTMLString(
    attributes.value || value
  );

  textboxElement.style["position"] = "absolute";
  textboxElement.contentEditable = "true";

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(textboxElement);

  selection.removeAllRanges();
  selection.addRange(range);

  const nodesInRange = getNodesInRange(textboxElement);

  const fontFamilyNodes = getAllFontFamilyContent(nodesInRange.allNodes);

  // only strip color styling if fontFamilyNodes are present
  if (fontFamilyNodes.length) {
    removeFontFamilyFromSelection({
      textboxElement,
      styledNodes: fontFamilyNodes,
      nodesInRange
    });
    // reassign the new stripped innerHTML value to the element preview
    _sideEffects.value = textboxElement.innerHTML;
    _sideEffects.displayValue = updateSmartTextPlaceholder(
      textboxElement.innerHTML,
      smartTextState
    );
  }

  _sideEffects.height =
    attributes.height || getHeight({ ...attributes, ..._sideEffects });

  textboxElement.remove();
  return _sideEffects;
};
