import React from "react";
import PropTypes from "prop-types";

import { Button } from "views/components";
import ArrowCircleIcon from "views/components/icons/ArrowCircleIcon";

import style from "./designUpdateNotice.module.css";

const DesignUpdateNotice = ({ onUpdate, className, iconColor = "#1d9bd1" }) => {
  return (
    <div className={[style.wrapper, className].filter(x => x).join(" ")}>
      <div>
        <ArrowCircleIcon color={iconColor} size="26px" />
      </div>
      <div className={style.label}>
        These designs have been updated since being added to your cart.
        <br />
        Click 'Update' button to proceed with the latest design version.
      </div>
      <div className={style.updateButton}>
        <Button theme="blueSolid" onClick={onUpdate}>
          Update
        </Button>
      </div>
    </div>
  );
};

DesignUpdateNotice.propTypes = {
  onUpdate: PropTypes.func.isRequired
};

export default DesignUpdateNotice;
