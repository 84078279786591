import React, { Component } from "react";
import { Button } from "views/components";
import InfoArrowIcon from "views/components/icons/InfoArrowIcon";
import { jsonStringEqual } from "lib/equalityUtils";
import style from "./style.module.css";

class PrintOptionsButton extends Component {
  constructor(props) {
    super(props);

    this.handlePrintOptionClick = this.handlePrintOptionClick.bind(this);
  }

  componentDidMount() {
    const { selectedDesign, getPrintOptions } = this.props;
    if (selectedDesign && selectedDesign.templateCode) {
      getPrintOptions({ templateCode: selectedDesign.templateCode });
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedDesign, getPrintOptions } = this.props;

    if (!jsonStringEqual(selectedDesign, prevProps.selectedDesign)) {
      getPrintOptions({ templateCode: selectedDesign.templateCode });
    }
  }

  handlePrintOptionClick() {
    const { printOptionsURL } = this.props;

    if (!printOptionsURL) return;

    window.open(printOptionsURL, "_blank", "noopener,noreferrer");
  }

  render() {
    const { printOptionsURL, isFetching } = this.props;

    if (isFetching || !printOptionsURL) return null;

    return (
      <Button
        theme="grayBackgroundXDarkGrayFont"
        className={style.printingOptions}
        onClick={this.handlePrintOptionClick}
      >
        <>
          <span>View Printing Options</span>
          <InfoArrowIcon color="#57595d" />
        </>
      </Button>
    );
  }
}

export default PrintOptionsButton;
