import React from "react";
import VideoCropping from "./VideoCropping";
import { connect } from "react-redux";
import {
  isCroppingSelector,
  contextSelector,
  selectedItemsSelector,
  zoomSelector,
  designDataSelector,
  elementPreviewsSelector
} from "state/ui/editorContext/editorContextSelectors";
import { getPath } from "lib/lodash";
import { getPageOffsetInCanvasbackground } from "views/components/Editor/utils";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { getPageHtmlNode } from "views/components/Editor/utils";
import {
  calculateMovedNewDimensions,
  calculateCropMaskScale
} from "views/components/Editor/CropLayer/ImageCropping/ImageCroppingPreview/ImageCroppingPreviewCalculations";
import { Logger } from "lib";

class VideoCroppingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.getElementWithPreview = this.getElementWithPreview.bind(this);
    this.getPageOffsetInCanvasbackground = this.getPageOffsetInCanvasbackground.bind(
      this
    );
    this.getElementWithPreview = this.getElementWithPreview.bind(this);
    this.getElementPreview = this.getElementPreview.bind(this);
    this.startCropMaskResize = this.startCropMaskResize.bind(this);
    this.finishCropMaskResize = this.finishCropMaskResize.bind(this);
    this.startVideoCropMoving = this.startVideoCropMoving.bind(this);
    this.finishVideoCropMoving = this.finishVideoCropMoving.bind(this);
  }

  getElementPreview(elementId) {
    if (!elementId) return {};
    const { elementPreviews, selectedItems } = this.props;

    const elementSelection =
      selectedItems.find(item => item.itemId === elementId) || {};

    return {
      ...elementPreviews[elementId],
      ...elementSelection.preview
    };
  }

  getElementWithPreview(elementId) {
    if (!elementId) return {};
    const { designData } = this.props;

    const element = designData.getElement(elementId);

    const elementPreview = this.getElementPreview(elementId);

    // when the preview is image instructions we want to replace without removing unchanged ones
    if (Object.keys(elementPreview).includes("imageInstructions")) {
      const previewInstructions = elementPreview.imageInstructions;
      const elementInstructions = element.imageInstructions;

      const originalInstructionsByDomId = elementInstructions.reduce(
        (combinedInstructions, currentInstruction) => ({
          ...combinedInstructions,
          [currentInstruction.domId]: currentInstruction
        }),
        {}
      );

      previewInstructions.forEach(previewInstruction => {
        originalInstructionsByDomId[
          previewInstruction.domId
        ] = previewInstruction;
      });

      elementPreview.imageInstructions = Object.values(
        originalInstructionsByDomId
      );
    }

    return {
      ...element,
      ...elementPreview
    };
  }

  getPageOffsetInCanvasbackground(pageId) {
    if (!pageId) return 0;
    return getPageOffsetInCanvasbackground(pageId);
  }

  startCropMaskResize() {
    Logger.info("VideoCroppingContainer.startCropMaskResize called");
    this.props.updateContext({
      isCropping: true
    });
  }

  finishCropMaskResize({
    differenceFromInitialOffset,
    dragItem,
    adjustmentOffset
  }) {
    Logger.info("VideoCroppingContainer.finishCropMaskResize called");
    const { zoom, selectedItems } = this.props;
    const selectedVideo = getPath(this.props, "selectedItems.0");
    let imageElement = this.getElementWithPreview(selectedVideo?.itemId);

    imageElement = {
      ...imageElement
      // angle: 0
    };

    const pageId = selectedVideo?.pageId;

    const pageDiv = getPageHtmlNode(pageId);
    const pageOffset = pageDiv.getBoundingClientRect();

    const { mask, height, width, top, left } = calculateCropMaskScale.calc({
      imageElement,
      dragItem,
      differenceFromInitialOffset,
      pageOffset,
      zoom,
      adjustmentOffset
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          ...selectedItems[0].preview,
          mask,
          height,
          width,
          top,
          left
        }
      }
    ];

    this.props.updateContext({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  }

  startVideoCropMoving() {
    Logger.info("VideoCroppingContainer.startVideoCropMoving called");

    this.props.updateContext({
      isCropping: true
    });
  }

  finishVideoCropMoving({ differenceFromInitialOffset }) {
    Logger.info("VideoCroppingContainer.finishVideoCropMoving called");

    const { zoom } = this.props;
    const selectedVideo = getPath(this.props, "selectedItems.0");
    let imageElement = this.getElementWithPreview(selectedVideo.itemId);

    const { mask } = calculateMovedNewDimensions({
      imageElement,
      differenceFromInitialOffset,
      zoom
    });

    const selectedItemUpdated = [
      {
        ...selectedVideo,
        preview: {
          ...selectedVideo.preview,
          mask
        }
      }
    ];

    this.props.updateContext({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  }

  render() {
    if (
      !this.props.canvasBackground ||
      !this.props.context ||
      !this.props.context.isCroppingVideo
    ) {
      return null;
    }

    const selectedVideo = getPath(this.props, "selectedItems.0");

    const videoElement = this.getElementWithPreview(selectedVideo?.itemId);

    const pageId = selectedVideo?.pageId || "";
    const pageOffset = this.getPageOffsetInCanvasbackground(pageId);

    return (
      <VideoCropping
        {...this.props}
        videoElement={videoElement}
        pageId={pageId}
        pageOffset={pageOffset}
        startCropMaskResize={this.startCropMaskResize}
        finishCropMaskResize={this.finishCropMaskResize}
        startVideoCropMoving={this.startVideoCropMoving}
        finishVideoCropMoving={this.finishVideoCropMoving}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isCropping: isCroppingSelector(state),
    context: contextSelector(state),
    selectedItems: selectedItemsSelector(state),
    zoom: zoomSelector(state),
    elementPreviews: elementPreviewsSelector(state),
    designData: designDataSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContext: args => dispatch(updateEditorContextState(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoCroppingContainer);
