import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const InfoArrowIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  viewbox = "0 0 24 24"
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox={viewbox}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M4 4h16v16H4z" />
      <path
        d="M15 8.25a.765.765 0 0 1 .42.129.748.748 0 0 1 .11.09l-.078-.068A.754.754 0 0 1 15.75 9l-.004-.077.004.06v4.29a.75.75 0 1 1-1.5 0l-.001-2.462-4.719 4.72a.75.75 0 0 1-.976.072l-.084-.073a.75.75 0 0 1 0-1.06l4.718-4.72h-2.46a.75.75 0 0 1-.744-.648L9.977 9a.75.75 0 0 1 .75-.75z"
        fill={color || easilGrayXDark}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
InfoArrowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default InfoArrowIcon;
