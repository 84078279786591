import React from "react";
import style from "./style.module.css";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Avatar, DropDownLink, Button } from "../../components";
import EasilIcon from "views/components/icons/EasilIcon";
import { UserMenuContainer } from "../../containers";
import NotificationContainer from "views/containers/NotificationContainer/NotificationContainer";

import { HeaderBottomPanel } from "./HeaderBottomPanel";

import TeamMenu from "./TeamMenuLazy";

export const Header = ({
  team,
  teams = [],
  teamMenuLevel,
  teamMenuSelectedTeam,
  onClearSearch,
  onFilterTeamListByTeamId,
  onTeamMenuNavigationDown,
  onTeamMenuNavigationUp,
  url,
  displayTeamDropdown,
  onSwitchTeam,
  toggleTeamMenu,
  onToggleHelpWindow,
  totalTeamCount,
  isBasicPlan,
  onSearchTeams,
  onCreateDesign,
  isTrialling,
  isAccount,
  isAdmin,
  isBrandManager,
  isFetchingTeams,
  isSearchMode,
  pathToCatalogue,
  isUserChatReady,
  allowEasilTemplates,
  isTeamTemplates,
  currentOrder
}) => {
  const isOrganisation =
    team && team.parentPathArr && team.parentPathArr.length <= 1;
  const shouldShowUpgrade =
    (isBasicPlan || isTrialling) && isOrganisation && isAdmin && !isAccount;
  return (
    <React.Fragment>
      <div className={style.navRow}>
        <div className={style.logo}>
          <NavLink to="#">
            <EasilIcon color="white" size="32" />
          </NavLink>
        </div>
        <div className={style.navRowRight}>
          <div>
            {shouldShowUpgrade && (
              <NavLink
                to="/subscription"
                key="upgrade"
                className={style.upgradeLink}
              >
                <Button
                  theme="blueSolid"
                  className={style.upgradeButton}
                  id="upgrade-now-button"
                >
                  Upgrade Now
                </Button>
              </NavLink>
            )}
          </div>
          <NotificationContainer wrapperClassName={style.notification} />
          {teams && totalTeamCount > 1 && (
            <div className={style.teamMenuDropdown}>
              <DropDownLink
                className={`${style.icon} ${style.iconWithDropDown}`}
                onClick={toggleTeamMenu}
                hasCaret={false}
              >
                <div className={style.teamDropDownButton}>
                  <div className={`${style.teamDropdownTitle} notranslate`}>
                    {team.name}
                  </div>
                  <Avatar
                    size="40px"
                    theme="light"
                    src={team.logoUrl}
                    name={team.name}
                    singleCharacterNameInitial={true}
                    className={`${
                      team.logoUrl ? style.avatarWithImage : null
                    } ${style.avatar}`}
                    doubleBorder
                  />
                </div>
              </DropDownLink>
              {displayTeamDropdown && teams && (
                <TeamMenu
                  isFetchingTeams={isFetchingTeams}
                  toggleMenu={toggleTeamMenu}
                  team={team}
                  teams={teams}
                  menuLevel={teamMenuLevel}
                  isSearchMode={isSearchMode}
                  selectedTeam={teamMenuSelectedTeam}
                  onClearSearch={onClearSearch}
                  onSwitchTeam={onSwitchTeam}
                  onFilterTeamListByTeamId={onFilterTeamListByTeamId}
                  onTeamNavigationDown={onTeamMenuNavigationDown}
                  onTeamNavigationUp={onTeamMenuNavigationUp}
                  onSearchTeams={onSearchTeams}
                />
              )}
            </div>
          )}
          <UserMenuContainer hasCaret={false} avatarClassName={style.avatar} />
        </div>
      </div>
      <HeaderBottomPanel
        onToggleHelpWindow={onToggleHelpWindow}
        url={url}
        onCreateDesign={onCreateDesign}
        isOrganisation={isOrganisation}
        pathToCatalogue={pathToCatalogue}
        isAdmin={isAdmin}
        isBrandManager={isBrandManager}
        isUserChatReady={isUserChatReady}
        allowEasilTemplates={allowEasilTemplates}
        isTeamTemplates={isTeamTemplates}
        currentOrder={currentOrder}
      />
    </React.Fragment>
  );
};

Header.propTypes = {
  isTrialling: PropTypes.bool.isRequired,
  team: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  displayTeamDropdown: PropTypes.bool,
  onSwitchTeam: PropTypes.func,
  toggleTeamMenu: PropTypes.func,
  teams: PropTypes.array,
  teamMenuLevel: PropTypes.number,
  teamMenuSelectedTeam: PropTypes.object,
  onToggleHelpWindow: PropTypes.func,
  moveToWorkSpace: PropTypes.func
};

export default Header;
