import React, { Component } from "react";
import { connect } from "react-redux";
import { DeleteSmartImageModal } from "views/components/SmartImage/DeleteSmartImageModal";
import { userTeamSmartImagesEntitiesSelector } from "state/entities/userTeamSmartImages/userTeamSmartImagesSelectors";
import { userTeamSmartImagesApiSavingSelector } from "state/api/userTeamSmartImages/userTeamSmartImagesApiSelectors";
import { teamSmartImagesApiSavingSelector } from "state/api/teamSmartImages/teamSmartImagesApiSelectors";

export class DeleteUserSmartImageModalContainer extends Component {
  render() {
    return <DeleteSmartImageModal {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    smartImages: userTeamSmartImagesEntitiesSelector(state),
    isSaving: userTeamSmartImagesApiSavingSelector(state),
    isSavingTeam: teamSmartImagesApiSavingSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteUserSmartImageModalContainer);
