import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { EditableTitle, AddImageButton, Image, Button } from "../../components";
import Dropdown2 from "views/components/dropdown2/Dropdown2";
import CountryOptionsRenderer from "views/components/countrySelect/CountryOptionsRenderer";
import {
  allCountries,
  priorityCountries,
  secondaryCountries
} from "lib/countriesList";
import { getUserDefaultCountry } from "lib/billingUtils";

class Team extends React.Component {
  constructor(props) {
    super(props);

    this.handleTeamUpdate = this.handleTeamUpdate.bind(this);
    this.handleAddTeamImage = this.handleAddTeamImage.bind(this);
    this.handleRemoveTeamImage = this.handleRemoveTeamImage.bind(this);
    this.toggleTeamCountryDropdown = this.toggleTeamCountryDropdown.bind(this);
    this.handleTeamSave = this.handleTeamSave.bind(this);

    this.state = {
      displayTeamCountryDropdown: false,
      team: props.team
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;

    const { team } = this.props;

    this.setState({ team });
  }

  handleTeamUpdate(attribute, value) {
    const { team } = this.state;
    team[attribute] = value;

    this.setState({ team });
  }

  handleAddTeamImage(fileObject) {
    const { team } = this.state;
    const teamObject = Object.assign({}, team, { logo: fileObject });
    this.props.updateTeam({ teamId: team.id, team: teamObject });
  }

  handleRemoveTeamImage() {
    const { team } = this.state;
    const teamObject = Object.assign({}, team, { logo: "NULL" });
    this.props.updateTeam({ teamId: team.id, team: teamObject });
    team.logo = null;
    this.setState({ team });
  }

  toggleTeamCountryDropdown() {
    const { displayTeamCountryDropdown } = this.state;

    this.setState({ displayTeamCountryDropdown: !displayTeamCountryDropdown });
  }

  async handleTeamSave() {
    const { team } = this.state;
    team.logo = team.logoUrl;

    if (team.logoUrl) {
      const filename = team.logoUrl.split("/").pop();
      team.logo = filename;
    } else {
      team.logo = "NULL";
    }

    delete team.logoUrl;
    this.props.updateTeam({ teamId: team.id, team });
    this.setState({ displayTeamCountryDropdown: false });
  }

  render() {
    const { roles, teamSettings } = this.props;

    const { team, displayTeamCountryDropdown } = this.state;

    const teamLogoUploadStyles = {
      backgroundColor: "#efeff1",
      noBorder: true,
      width: "88px",
      height: "88px"
    };

    const teamCountry = getUserDefaultCountry(team.country);

    if (!roles.includes("ADMIN"))
      return (
        <div className={style.wrapper}>Unauthorized to access settings</div>
      );

    return (
      <div className={style.wrapper}>
        <h1 className={style.pageHeader}>{team.name} </h1>
        <div className={style.teamTab}>
          <div className={style.teamTabCol}>
            <div className={style.sectionHeading}>
              <h2>Team Settings</h2>
            </div>
            <div className={style.formGroup}>
              <div className={style.formGroupLabel}>Team Name</div>
              <EditableTitle
                title={team.name}
                onTitleUpdate={value => this.handleTeamUpdate("name", value)}
                allowEdit={true}
                displayTrashIcon={false}
                className={style.editableInput}
                disabled={teamSettings.isProcessing}
              />
              {teamSettings.errors.name && (
                <div className={style.fieldError}>
                  {teamSettings.errors.name}
                </div>
              )}
            </div>
            <div className={style.formGroup}>
              <div className={style.formGroupLabel}>Country</div>
              {!displayTeamCountryDropdown && (
                <EditableTitle
                  title={
                    allCountries.find(country => country.key === team.country)
                      .label
                  }
                  onEditClick={() => this.toggleTeamCountryDropdown()}
                  allowEdit={true}
                  displayTrashIcon={false}
                  className={style.editableInput}
                  disabled={teamSettings.isProcessing}
                />
              )}
              {displayTeamCountryDropdown && (
                <Dropdown2
                  dropdownHeight="300px"
                  value={teamCountry.label}
                  selected={teamCountry}
                  options={{
                    countries: secondaryCountries,
                    commonCountries: priorityCountries
                  }}
                  optionsRenderer={CountryOptionsRenderer}
                  onChange={selectedOption =>
                    this.handleTeamUpdate("country", selectedOption)
                  }
                />
              )}
              {teamSettings.errors.country && (
                <div className={style.fieldError}>
                  {teamSettings.errors.country}
                </div>
              )}
            </div>
            <div className={style.formGroup}>
              <Button
                theme="blueSolid"
                onClick={() => this.handleTeamSave()}
                disabled={teamSettings.isProcessing}
              >
                {teamSettings.isProcessing ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>
          <div className={style.teamTabCol}>
            <div className={style.sectionHeading}>
              <h2>Team Logo</h2>
            </div>
            <div className={style.teamLogoUpload}>
              {!team.logoUrl && (
                <AddImageButton
                  acceptedFileTypes={["png", "jpg", "jpeg"]}
                  className={style.addImageButton}
                  buttonStyles={teamLogoUploadStyles}
                  buttonBackgroundColor="#efeff1"
                  buttonNoBorder={true}
                  buttonWidth="88px"
                  buttonHeight="88px"
                  onChange={({ file }) => this.handleAddTeamImage(file)}
                />
              )}
              {team.logoUrl && (
                <AddImageButton
                  acceptedFileTypes={["png", "jpg", "jpeg"]}
                  className={style.addImageButton}
                  buttonStyles={teamLogoUploadStyles}
                  buttonBackgroundColor="#efeff1"
                  buttonNoBorder={true}
                  buttonWidth="88px"
                  buttonHeight="88px"
                  onChange={({ file }) => this.handleAddTeamImage(file)}
                  image={
                    <Image
                      src={team.logoUrl}
                      fillContainer={true}
                      width="88px"
                      height="88px"
                      borderRadius="50%"
                    />
                  }
                />
              )}
              <div>
                {!team.logoUrl && (
                  <React.Fragment>
                    <strong>Upload</strong> a team logo.
                    <br />
                    Must be at least 64 x 64px.
                  </React.Fragment>
                )}
                {team.logoUrl && (
                  <React.Fragment>
                    <strong>Change</strong> your team logo.
                    <br />
                    <span
                      className={style.removeLogoLink}
                      onClick={() => this.handleRemoveTeamImage()}
                    >
                      Remove
                    </span>
                  </React.Fragment>
                )}
              </div>
            </div>
            {teamSettings.errors.logo && (
              <div className={style.fieldError}>{teamSettings.errors.logo}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Team.propTypes = {
  team: PropTypes.object,
  countriesList: PropTypes.array,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
  saving: PropTypes.bool
};

export default Team;
