import * as types from "./stockAnimationsTypes";
import { isEmpty, omit } from "lib";

export const initState = null;

const stockAnimationsReducers = (state = initState, action) => {
  switch (action.type) {
    // GET
    case types.STOCK_ANIMATIONS_REQUEST_SUCCESS:
    case types.ALL_STOCK_ANIMATIONS_REQUEST_SUCCESS:
    case types.STOCK_VIDEOS_REQUEST_SUCCESS: {
      let previousState = state || {};

      const { entities = {} } = action.response;

      const stockAnimation = { ...entities.stockAnimation };

      if (!isEmpty(state)) {
        Object.keys(stockAnimation).forEach(id => {
          const animation = stockAnimation[id];
          const stateAnimation = state[id];
          const preservedThumbUrl = animation.thumbnailUrl;
          let thumbnailUrl = animation.thumbnailUrl;
          if (
            stateAnimation &&
            stateAnimation.thumbnailUrl &&
            stateAnimation.thumbnailUrl.startsWith("blob:")
          ) {
            thumbnailUrl = stateAnimation.thumbnailUrl;
          }
          stockAnimation[id] = {
            ...animation,
            thumbnailUrl,
            preservedThumbUrl
          };
        });
      }

      return { ...previousState, ...stockAnimation };
    }

    case types.ADD_STOCK_ANIMATIONS_PLACEHOLDERS: {
      let previousState = state || {};

      // get the media placeholders
      const { mediaPlaceholders = [] } = action;

      // reduce into object and add isPlaceholder boolean flag
      const stockAnimations = mediaPlaceholders.reduce(
        (allMedia, currentMedia) => ({
          ...allMedia,
          [currentMedia.mediaId]: {
            ...currentMedia,
            isPlaceholder: true
          }
        }),
        {}
      );

      return { ...previousState, ...stockAnimations };
    }

    // DELETE
    case types.STOCK_ANIMATION_DELETE_REQUEST: {
      return omit(state, action.request.mediaId);
    }

    // PUT
    case types.STOCK_ANIMATION_UPDATE_REQUEST: {
      const updatedAnimation = action.request.body;
      return {
        ...state,
        [updatedAnimation.mediaId]: updatedAnimation
      };
    }

    // POST
    case types.STOCK_ANIMATION_UPLOAD_REQUEST_SUCCESS:
    case types.STOCK_ANIMATION_UPDATE_REQUEST_SUCCESS: {
      const updatedAnimation = { ...action.response };

      if (
        updatedAnimation &&
        updatedAnimation.media &&
        !updatedAnimation.media.priority
      ) {
        updatedAnimation.media.priority = null;
      }

      const thumbnailUrl = action.extra
        ? action.extra.placeholderURL
        : updatedAnimation.thumbnailUrl;

      return {
        ...state,
        [updatedAnimation.mediaId]: {
          ...updatedAnimation,
          preservedThumbUrl: updatedAnimation.thumbnailUrl,
          thumbnailUrl
        }
      };
    }

    default:
      return state;
  }
};

export default stockAnimationsReducers;
