import React from "react";

import SubHeaderTabs from "views/components/SubHeaderTabs";

import { tabs as tabList } from "./tabs";

import style from "../style.module.css";

import { omit } from "lib";

export const DesignsPageWorkspaceSubHeader = ({
  userHasApprovedDrafts,
  userHasDesignSharedWith,
  canSeeBrandManagerApprovedDesigns
}) => {
  let hiddenTabs = [];
  let tabs = tabList();

  if (!canSeeBrandManagerApprovedDesigns) {
    hiddenTabs.push("BrandManagerApprovedDesigns");
  }

  if (!userHasDesignSharedWith) {
    hiddenTabs.push("SharedWithMe");
  }
  if (!userHasApprovedDrafts) {
    hiddenTabs.push("ApprovedDesigns");
  }
  tabs = omit(tabs, hiddenTabs);

  return (
    <React.Fragment>
      <div className={style.headerPadding} />
      <SubHeaderTabs tabs={tabs} />
    </React.Fragment>
  );
};
