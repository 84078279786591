import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../modal";
import style from "./style.module.css";
import { Input, Button } from "views/components";

class BillingContactModal extends Component {
  constructor(props) {
    super(props);

    this.handleSave = this.handleSave.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);

    this.state = props.teamBilling;
  }

  componentDidUpdate(prevProps) {
    // Close modal if processed with no errors:
    if (
      prevProps.teamBillingSettings.isProcessing &&
      !this.props.teamBillingSettings.isProcessing &&
      !Object.keys(this.props.teamBillingSettings.errors).length
    ) {
      this.props.onClose();
    }
  }

  handleSave() {
    this.props.updateBilling({
      billingDetails: this.state,
      teamId: this.state.teamId
    });
  }

  handleUpdate(field, value) {
    this.setState({ [field]: value });
  }

  render() {
    const {
      teamBillingSettings: { errors, isProcessing }
    } = this.props;

    return (
      <Modal
        className={style.modalContainer}
        header="Edit billing contact"
        isOpen
        onRequestClose={this.props.onClose}
      >
        <div className={style.formGroup}>
          <div className={style.formGroupLabel}>Email</div>
          <Input
            disabled={isProcessing}
            value={this.state.invoiceEmail || ""}
            onChange={e => this.handleUpdate("invoiceEmail", e.target.value)}
          />
          {errors.invoiceEmail && (
            <div className={style.fieldError}>{errors.invoiceEmail}</div>
          )}
        </div>
        <div className={style.formGroupButtons}>
          <Button onClick={this.props.onClose} theme="gray">
            Cancel
          </Button>
          <Button
            disabled={isProcessing}
            onClick={this.handleSave}
            theme="blueSolid"
          >
            {isProcessing ? "Saving..." : "Save"}
          </Button>
        </div>
      </Modal>
    );
  }
}

BillingContactModal.displayName = "BillingContactModal";

BillingContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  teamBilling: PropTypes.object.isRequired,
  teamBillingSettings: PropTypes.object.isRequired,
  updateBilling: PropTypes.func.isRequired
};

export default BillingContactModal;
