import React, { Component } from "react";
import { connect } from "react-redux";
import {
  handleAddCouponToOrder,
  fetchCurrentOrder
} from "state/entities/orders/ordersActions";
import { setCouponError } from "state/ui/cart/cartActions";
import { currentOrderLoadingStateSelector } from "state/ui/cart/cartSelectors";
import { getCurrentOrderByIdSelector } from "state/entities/orders/ordersSelectors";
import { fetchShippingDetailsForCurrentOrder } from "state/entities/shippingDetails/shippingDetailsActions";
import OrderSummary from "./OrderSummary";

class OrderSummaryContainer extends Component {
  render() {
    return (
      <OrderSummary
        couponError={this.props.couponError}
        handleAddCouponToOrder={this.props.handleAddCouponToOrder}
        handleResetCouponError={this.props.handleResetCouponError}
        loading={this.props.loading}
        order={this.props.order}
      />
    );
  }
}

const mapStateToProps = state => {
  const loading = currentOrderLoadingStateSelector(state);
  const order = getCurrentOrderByIdSelector({ state });
  const couponError = state.ui.cart.couponErrors;
  return {
    loading,
    order,
    couponError
  };
};

const mapDispatchToProps = dispatch => ({
  handleAddCouponToOrder: args =>
    dispatch(
      handleAddCouponToOrder({
        ...args,
        onSuccess: () => {
          dispatch(fetchShippingDetailsForCurrentOrder());
          dispatch(fetchCurrentOrder());
        }
      })
    ),
  handleResetCouponError: () => dispatch(setCouponError())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummaryContainer);
