import React from "react";
import { connect } from "react-redux";
import UpgradeModal from "views/components/UpgradeModal";
import {
  hideUpgradeModal,
  redirectToSubscription
} from "state/ui/upgradeModal/upgradeModalActions";

const UpgradeModalContainer = ({
  upgradeModalSubscriptionCode,
  onCloseModal,
  subscriptionCode,
  redirectToSubscription
}) => {
  if (upgradeModalSubscriptionCode === null) {
    return null;
  }

  return (
    <UpgradeModal
      isOpen={true}
      onCloseModal={onCloseModal}
      subscriptionCode={upgradeModalSubscriptionCode}
      redirectToSubscription={redirectToSubscription}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    upgradeModalSubscriptionCode: state.ui.upgradeModal.subscriptionCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseModal: () => dispatch(hideUpgradeModal()),
    redirectToSubscription: () => dispatch(redirectToSubscription())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeModalContainer);
