import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const VideoIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="2 2 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="m10.21 9.327 4.806 2.219a.5.5 0 0 1 0 .908l-4.806 2.219a.5.5 0 0 1-.71-.454V9.78a.5.5 0 0 1 .71-.454z"
        fill={color || easilGrayXDark}
        fillRule="nonzero"
      />
      <path
        d="M12 5c2.14 0 4.208.286 6.204.86l.554.168a2.997 2.997 0 0 1 2.076 2.568 35.15 35.15 0 0 1 .06 6.118l-.06.69a2.997 2.997 0 0 1-2.081 2.57A22.275 22.275 0 0 1 12 19c-2.14 0-4.208-.286-6.204-.86l-.554-.168a2.997 2.997 0 0 1-2.076-2.568 35.15 35.15 0 0 1-.06-6.118l.06-.69a2.997 2.997 0 0 1 2.081-2.57A22.275 22.275 0 0 1 12 5z"
        stroke={color || easilGrayXDark}
        strokeWidth="2"
      />
    </g>
  </svg>
);
VideoIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default VideoIcon;
