import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { isNil, keyCodes, noop } from "lib";
import CrossThinIcon from "views/components/icons/CrossThinIcon";

const defaultOnKeyDown = event => {
  /* We stop propagation to prevent elements to be deleted from canvas */
  if (keyCodes.isBlackListed(event.keyCode)) {
    event.stopPropagation();
  }
};

const Input = ({
  className,
  clearInput,
  crossThinIconColor = "white",
  error,
  inputClassName,
  isEmptySearch,
  label,
  labelClassName,
  onBlur = noop,
  onFocus,
  onInputClick = noop,
  onKeyDown = defaultOnKeyDown,
  onKeyPress,
  ref,
  showErrorMsg,
  style: propsStyle,
  value,
  ...props
}) => (
  <React.Fragment>
    {label && <div className={`${style.label} ${labelClassName}`}>{label}</div>}
    <div
      className={`${style.div} ${className}`}
      data-error={!isNil(error)}
      data-disabled={props.disabled}
      data-testid="Input"
      style={propsStyle}
    >
      <input
        className={inputClassName}
        value={value}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onInputClick}
        ref={ref}
        {...props}
      />
      {Boolean(clearInput) && !isEmptySearch && (
        <div
          className={style.clearCross}
          onClick={clearInput}
          data-testid="CrossThinIcon"
        >
          <CrossThinIcon size="11" color={crossThinIconColor} />
        </div>
      )}
      {showErrorMsg && error && <span className={style.error}>{error}</span>}
    </div>
  </React.Fragment>
);

Input.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string
};

export default Input;
