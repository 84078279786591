import * as types from "state/entities/graphics/graphicsTypes";
import { omit, isEmpty } from "lib";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50
};

export const handleClearGraphicRequest = () => {
  return initState;
};

export const handleGraphicRequest = ({ action, state }) => {
  const { page } = action.request;
  return {
    ...state,
    pages: {
      ...state.pages,
      [page]: {
        ...state.pages[page],
        isFetching: true
      }
    }
  };
};

export const handleGraphicRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      pages: omit(state.pages, action.request.page)
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    pages: { ...state.pages, ...updatedPage }
  };
};

const graphicsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.CLEAR_GRAPHICS_REQUEST: {
      return handleClearGraphicRequest();
    }

    case types.FETCH_GRAPHICS_REQUEST: {
      return handleGraphicRequest({ state, action });
    }

    case types.FETCH_GRAPHICS_REQUEST_SUCCESS: {
      return handleGraphicRequestSuccess({ state, action });
    }

    default:
      return state;
  }
};

export default graphicsApiReducers;
