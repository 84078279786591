import { createSelector } from "reselect";
import { isNil, getPath } from "lib";

export const userTeamImagesEntitiesSelector = state =>
  state.entities.userTeamImages;
const userTeamImagesApiSelector = state => state.api.userTeamImages;

export const processGetUserTeamImagesNotInFolders = (
  userTeamImagesApi,
  userTeamImagesEntities
) => {
  let userImages = [];
  if (userTeamImagesApi.pages) {
    const pagesOrder = Object.keys(userTeamImagesApi.pages).sort();

    pagesOrder.forEach(page => {
      const ids = userTeamImagesApi.pages[page].ids || [];

      ids.forEach(userTeamImageId => {
        const entity = userTeamImagesEntities[userTeamImageId];

        userImages.push(entity);
      });
    });
  }
  return userImages;
};

export const getUserTeamImagesNotInFolders = createSelector(
  [userTeamImagesApiSelector, userTeamImagesEntitiesSelector],
  processGetUserTeamImagesNotInFolders
);

const processUserTeamImagesSelector = (
  userTeamImagesApi,
  userTeamImagesEntities
) => {
  if (isNil(userTeamImagesApi.pages)) {
    return null;
  }

  let hasBeenFetched = false;
  let userImagesIds = [];

  const pagesOrder = Object.keys(userTeamImagesApi.pages).sort();

  pagesOrder.forEach(page => {
    const ids = getPath(userTeamImagesApi, `pages[${page}].ids`);

    if (!isNil(ids)) {
      hasBeenFetched = true;
      userImagesIds = userImagesIds.concat(ids);
    }
  });

  if (hasBeenFetched) {
    return userImagesIds.map(imageId => userTeamImagesEntities[imageId]);
  } else {
    return null;
  }
};

export const userTeamImagesSelector = createSelector(
  [userTeamImagesApiSelector, userTeamImagesEntitiesSelector],
  processUserTeamImagesSelector
);

export const userTeamImageSelector = ({ state, imageId }) =>
  state.entities.userTeamImages[imageId];
