import React, { Component } from "react";

import style from "./style.module.css";

import { Button } from "views/components";
import PlusIcon from "views/components/icons/PlusIcon";

import { default as AddFolderModal } from "views/components/DesignsPageSubHeader/AddFolderModalContainer";

import PATHS from "routes/paths";

export class ImagesNewFolderButton extends Component {
  constructor(props) {
    super(props);

    this.handleCreateImageFolder = this.handleCreateImageFolder.bind(this);
    this.handleCloseFolderModal = this.handleCloseFolderModal.bind(this);
    this.handleOpenFolderModal = this.handleOpenFolderModal.bind(this);
    this.isInFolder = this.isInFolder.bind(this);

    this.state = {
      isFolderModalOpen: false
    };
  }

  handleCloseFolderModal(e) {
    if (e && e.stopPropagation && e.preventDefault) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({ isFolderModalOpen: false });
  }

  handleOpenFolderModal() {
    const isInFolder = this.isInFolder();
    if (isInFolder || this.props.isInSearchContext) return;
    this.setState({ isFolderModalOpen: true });
  }

  handleCreateImageFolder({ name }) {
    const { createImageFolder } = this.props;

    createImageFolder({
      name,
      onSuccess: this.handleCloseFolderModal
    });
  }

  isInFolder() {
    const pathname = window.location.pathname;
    const splitPath = pathname.split(PATHS.images);

    // if there is no path past images then we are not in a folder
    if (splitPath.length === 1) {
      return false;
    }

    // if the path is not a folder then we are not in a folder
    if (!splitPath[1].startsWith(PATHS.folder)) {
      return false;
    }

    // if all cases pass then we are in a folder
    return true;
  }

  render() {
    const { createImageFolderState = {}, isInSearchContext } = this.props;
    const { isFolderModalOpen } = this.state;

    const isInFolder = this.isInFolder();

    return (
      <Button
        onClick={this.handleOpenFolderModal}
        theme="blueSolidNoBackgroundNoBorder"
        className={
          isInFolder || isInSearchContext
            ? style.headerButtonDisabled
            : style.headerButton
        }
        id="folder-create-button"
      >
        <div className={style.iconBoundingBox}>
          <PlusIcon
            color={isInFolder || isInSearchContext ? "#2C6FCF80" : "#3184fc"}
            size="18"
            title="Add Folder"
          />
        </div>
        <span>New Folder</span>
        <AddFolderModal
          errors={createImageFolderState.errors}
          submitting={createImageFolderState.isSubmitting}
          isOpen={isFolderModalOpen}
          onClose={this.handleCloseFolderModal}
          onCreate={attributes => this.handleCreateImageFolder(attributes)}
        />
      </Button>
    );
  }
}

ImagesNewFolderButton.displayName = "ImagesNewFolderButton";

export default ImagesNewFolderButton;
