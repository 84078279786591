export const DESIGN_REQUEST = "API/ENTITIES/DESIGN_REQUEST";
export const DESIGN_REQUEST_FAILURE = "API/ENTITIES/DESIGN_REQUEST_FAILURE";
export const DESIGN_REQUEST_SUCCESS = "API/ENTITIES/DESIGN_REQUEST_SUCCESS";

export const DESIGNS_DRAFTS_REQUEST = "API/ENTITIES/DESIGNS_DRAFTS_REQUEST";
export const DESIGNS_DRAFTS_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_DRAFTS_REQUEST_FAILURE";
export const DESIGNS_DRAFTS_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_DRAFTS_REQUEST_SUCCESS";

export const TEAM_DESIGNS_APPROVED_REQUEST =
  "API/ENTITIES/TEAM_DESIGNS_APPROVED_REQUEST";
export const TEAM_DESIGNS_APPROVED_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_DESIGNS_APPROVED_REQUEST_FAILURE";
export const TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS";

export const SEARCH_TEAM_DESIGNS_APPROVED_REQUEST =
  "API/ENTITIES/SEARCH_TEAM_DESIGNS_APPROVED_REQUEST";
export const SEARCH_TEAM_DESIGNS_APPROVED_REQUEST_FAILURE =
  "API/ENTITIES/SEARCH_TEAM_DESIGNS_APPROVED_REQUEST_FAILURE";
export const SEARCH_TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS =
  "API/ENTITIES/SEARCH_TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS";

export const DESIGNS_APPROVED_REQUEST = "API/ENTITIES/DESIGNS_APPROVED_REQUEST";
export const DESIGNS_APPROVED_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_APPROVED_REQUEST_FAILURE";
export const DESIGNS_APPROVED_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_APPROVED_REQUEST_SUCCESS";

export const DESIGNS_APPROVED_SHARED_REQUEST =
  "API/ENTITIES/DESIGNS_APPROVED_SHARED_REQUEST";
export const DESIGNS_APPROVED_SHARED_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_APPROVED_SHARED_REQUEST_FAILURE";
export const DESIGNS_APPROVED_SHARED_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_APPROVED_SHARED_REQUEST_SUCCESS";

export const DESIGNS_ARCHIVED_REQUEST = "API/ENTITIES/DESIGNS_ARCHIVED_REQUEST";
export const DESIGNS_ARCHIVED_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_ARCHIVED_REQUEST_FAILURE";
export const DESIGNS_ARCHIVED_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_ARCHIVED_REQUEST_SUCCESS";

export const DESIGNS_SHARED_REQUEST = "API/ENTITIES/DESIGNS_SHARED_REQUEST";
export const DESIGNS_SHARED_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_SHARED_REQUEST_FAILURE";
export const DESIGNS_SHARED_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_SHARED_REQUEST_SUCCESS";

export const TEAM_DESIGNS_REQUEST = "API/ENTITIES/TEAM_DESIGNS_REQUEST";
export const TEAM_DESIGNS_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_DESIGNS_REQUEST_FAILURE";
export const TEAM_DESIGNS_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_DESIGNS_REQUEST_SUCCESS";

export const TEAM_ARCHIVED_DESIGNS_REQUEST =
  "API/ENTITIES/TEAM_ARCHIVED_DESIGNS_REQUEST";
export const TEAM_ARCHIVED_DESIGNS_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_ARCHIVED_DESIGNS_REQUEST_FAILURE";
export const TEAM_ARCHIVED_DESIGNS_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_ARCHIVED_DESIGNS_REQUEST_SUCCESS";

export const TEAM_TEMPLATES_DESIGNS_REQUEST =
  "API/ENTITIES/TEAM_TEMPLATES_DESIGNS_REQUEST";
export const TEAM_TEMPLATES_DESIGNS_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_TEMPLATES_DESIGNS_REQUEST_FAILURE";
export const TEAM_TEMPLATES_DESIGNS_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_TEMPLATES_DESIGNS_REQUEST_SUCCESS";

export const TEAM_TEMPLATES_STATUS_UPDATE_REQUEST =
  "API/ENTITIES/TEAM_TEMPLATES_STATUS_UPDATE_REQUEST";
export const TEAM_TEMPLATES_STATUS_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_TEMPLATES_STATUS_UPDATE_REQUEST_FAILURE";
export const TEAM_TEMPLATES_STATUS_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_TEMPLATES_STATUS_UPDATE_REQUEST_SUCCESS";

export const EASIL_TEMPLATES_DESIGNS_REQUEST =
  "API/ENTITIES/EASIL_TEMPLATES_DESIGNS_REQUEST";
export const EASIL_TEMPLATES_DESIGNS_REQUEST_FAILURE =
  "API/ENTITIES/EASIL_TEMPLATES_DESIGNS_REQUEST_FAILURE";
export const EASIL_TEMPLATES_DESIGNS_REQUEST_SUCCESS =
  "API/ENTITIES/EASIL_TEMPLATES_DESIGNS_REQUEST_SUCCESS";

export const DESIGN_DRAFT_UPDATE_REQUEST =
  "API/ENTITIES/DESIGN_DRAFT_UPDATE_REQUEST";
export const DESIGN_DRAFT_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_DRAFT_UPDATE_REQUEST_FAILURE";
export const DESIGN_DRAFT_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_DRAFT_UPDATE_REQUEST_SUCCESS";

export const DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST =
  "API/ENTITIES/DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST";
export const DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_FAILURE";
export const DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_SUCCESS";

export const DESIGNS_DRAFTS_CREATE_REQUEST =
  "API/ENTITIES/DESIGNS_DRAFTS_CREATE_REQUEST";
export const DESIGNS_DRAFTS_CREATE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_DRAFTS_CREATE_REQUEST_FAILURE";
export const DESIGNS_DRAFTS_CREATE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_DRAFTS_CREATE_REQUEST_SUCCESS";

export const DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST =
  "API/ENTITIES/DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST";
export const DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST_FAILURE";
export const DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST_SUCCESS";

export const DESIGNS_DRAFTS_COPY_REQUEST =
  "API/ENTITIES/DESIGNS_DRAFTS_COPY_REQUEST";
export const DESIGNS_DRAFTS_COPY_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_DRAFTS_COPY_REQUEST_FAILURE";
export const DESIGNS_DRAFTS_COPY_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_DRAFTS_COPY_REQUEST_SUCCESS";

export const DESIGNS_DATA_REQUEST = "API/ENTITIES/DESIGNS_DATA_REQUEST";
export const DESIGNS_DATA_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_DATA_REQUEST_FAILURE";
export const DESIGNS_DATA_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_DATA_REQUEST_SUCCESS";

export const DESIGN_RESIZE_REQUEST = "API/ENTITIES/DESIGN_RESIZE_REQUEST";
export const DESIGN_RESIZE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_RESIZE_REQUEST_SUCCESS";
export const DESIGN_RESIZE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_RESIZE_REQUEST_FAILURE";

export const COLLECTION_DESIGNS_REQUEST = "APP/ENTITIES/COLLECTIONS/FETCH";
export const COLLECTION_DESIGNS_REQUEST_SUCCESS =
  "APP/ENTITIES/COLLECTIONS/FETCH_SUCCESS";
export const COLLECTION_DESIGNS_REQUEST_FAILURE =
  "APP/ENTITIES/COLLECTIONS/FETCH_FAILURE";

export const COLLECTION_PRICE_REQUEST = "APP/ENTITIES/COLLECTION_PRICE_REQUEST";
export const COLLECTION_PRICE_REQUEST_SUCCESS =
  "APP/ENTITIES/COLLECTION_PRICE_REQUEST_SUCCESS";
export const COLLECTION_PRICE_REQUEST_FAILURE =
  "APP/ENTITIES/COLLECTION_PRICE_REQUEST_FAILURE";

export const DESIGNS_BY_FOLDER_REQUEST =
  "API/ENTITIES/DESIGNS_BY_FOLDER_REQUEST";
export const DESIGNS_BY_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_BY_FOLDER_REQUEST_FAILURE";
export const DESIGNS_BY_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_BY_FOLDER_REQUEST_SUCCESS";

export const DESIGNS_BY_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/DESIGNS_BY_TEAM_FOLDER_REQUEST";
export const DESIGNS_BY_TEAM_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_BY_TEAM_FOLDER_REQUEST_FAILURE";
export const DESIGNS_BY_TEAM_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_BY_TEAM_FOLDER_REQUEST_SUCCESS";

export const SAVE_DESIGN_DATA_REQUEST = "API/ENTITIES/SAVE_DESIGN_DATA_REQUEST";
export const SAVE_DESIGN_DATA_REQUEST_SUCCESS =
  "API/ENTITIES/SAVE_DESIGN_DATA_REQUEST_SUCCESS";
export const SAVE_DESIGN_DATA_REQUEST_FAILURE =
  "API/ENTITIES/SAVE_DESIGN_DATA_REQUEST_FAILURE";

export const DESIGNS_SEARCH_REQUEST = "API/ENTITIES/DESIGNS/SEARCH_REQUEST";
export const DESIGNS_SEARCH_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS/SEARCH_REQUEST_SUCCESS";
export const DESIGNS_SEARCH_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS/SEARCH_REQUEST_FAILURE";

export const TEAM_DESIGNS_EASIL_REQUEST =
  "API/ENTITIES/DESIGNS/TEAM_DESIGNS_EASIL_REQUEST";
export const TEAM_DESIGNS_EASIL_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS/TEAM_DESIGNS_EASIL_REQUEST_SUCCESS";
export const TEAM_DESIGNS_EASIL_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS/TEAM_DESIGNS_EASIL_REQUEST_FAILURE";

export const TEAM_DESIGNS_TEAM_REQUEST =
  "API/ENTITIES/DESIGNS/TEAM_DESIGNS_TEAM_REQUEST";
export const TEAM_DESIGNS_TEAM_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS/TEAM_DESIGNS_TEAM_REQUEST_SUCCESS";
export const TEAM_DESIGNS_TEAM_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS/TEAM_DESIGNS_TEAM_REQUEST_FAILURE";

export const DESIGN_SHARE_REQUEST = "API/ENTITIES/DESIGN_SHARE_REQUEST";
export const DESIGN_SHARE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_SHARE_REQUEST_FAILURE";
export const DESIGN_SHARE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_SHARE_REQUEST_SUCCESS";

export const DESIGNS_SHARE_REQUEST = "API/ENTITIES/DESIGNS_SHARE_REQUEST";
export const DESIGNS_SHARE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_SHARE_REQUEST_FAILURE";
export const DESIGNS_SHARE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_SHARE_REQUEST_SUCCESS";

export const DESIGN_UNSHARE_REQUEST = "API/ENTITIES/DESIGN_UNSHARE_REQUEST";
export const DESIGN_UNSHARE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_UNSHARE_REQUEST_FAILURE";
export const DESIGN_UNSHARE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_UNSHARE_REQUEST_SUCCESS";

export const DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST =
  "API/ENTITIES/DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST";
export const DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST_FAILURE";
export const DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST_SUCCESS";

/* dispatched when all the allocations for a designs is removed */
export const DESIGN_NO_LONGER_SHARED = "APP/ENTITIES/DESIGN/NO_LONGER_SHARED";

export const DESIGN_UNAPPROVE_REQUEST = "API/ENTITIES/DESIGN_UNAPPROVE_REQUEST";
export const DESIGN_UNAPPROVE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_UNAPPROVE_REQUEST_FAILURE";
export const DESIGN_UNAPPROVE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_UNAPPROVE_REQUEST_SUCCESS";

export const DESIGN_DECLINE_REQUEST = "API/ENTITIES/DESIGN_DECLINE_REQUEST";
export const DESIGN_DECLINE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_DECLINE_REQUEST_FAILURE";
export const DESIGN_DECLINE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_DECLINE_REQUEST_SUCCESS";

export const DESIGN_APPROVE_REQUEST = "API/ENTITIES/DESIGN_APPROVE_REQUEST";
export const DESIGN_APPROVE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_APPROVE_REQUEST_FAILURE";
export const DESIGN_APPROVE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_APPROVE_REQUEST_SUCCESS";

export const DESIGN_CANCEL_APPROVAL_REQUEST =
  "API/ENTITIES/DESIGN_CANCEL_APPROVAL_REQUEST";
export const DESIGN_CANCEL_APPROVAL_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_CANCEL_APPROVAL_REQUEST_FAILURE";
export const DESIGN_CANCEL_APPROVAL_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_CANCEL_APPROVAL_REQUEST_SUCCESS";

export const DESIGN_REQUEST_APPROVAL_REQUEST =
  "API/ENTITIES/DESIGN_REQUEST_APPROVAL_REQUEST";
export const DESIGN_REQUEST_APPROVAL_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_REQUEST_APPROVAL_REQUEST_FAILURE";
export const DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS";

export const DESIGNS_PENDING_APPROVAL_REQUEST =
  "API/ENTITIES/DESIGNS_PENDING_APPROVAL_REQUEST";
export const DESIGNS_PENDING_APPROVAL_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_PENDING_APPROVAL_REQUEST_FAILURE";
export const DESIGNS_PENDING_APPROVAL_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_PENDING_APPROVAL_REQUEST_SUCCESS";

export const DESIGNS_PENDING_APPROVAL_TEAM_REQUEST =
  "API/ENTITIES/DESIGNS_PENDING_APPROVAL_TEAM_REQUEST";
export const DESIGNS_PENDING_APPROVAL_TEAM_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_PENDING_APPROVAL_TEAM_REQUEST_FAILURE";
export const DESIGNS_PENDING_APPROVAL_TEAM_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_PENDING_APPROVAL_TEAM_REQUEST_SUCCESS";

export const DESIGNS_DECLINED_APPROVAL_REQUEST =
  "API/ENTITIES/DESIGNS_DECLINED_APPROVAL_REQUEST";
export const DESIGNS_DECLINED_APPROVAL_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_DECLINED_APPROVAL_REQUEST_FAILURE";
export const DESIGNS_DECLINED_APPROVAL_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_DECLINED_APPROVAL_REQUEST_SUCCESS";

export const DESIGNS_PENDING_APPROVAL_SHARED_REQUEST =
  "API/ENTITIES/DESIGNS_PENDING_APPROVAL_SHARED_REQUEST";
export const DESIGNS_PENDING_APPROVAL_SHARED_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_PENDING_APPROVAL_SHARED_REQUEST_FAILURE";
export const DESIGNS_PENDING_APPROVAL_SHARED_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_PENDING_APPROVAL_SHARED_REQUEST_SUCCESS";

export const DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST =
  "API/ENTITIES/DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST";
export const DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST_FAILURE";
export const DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST_SUCCESS";

export const DESIGN_REVERT_APPROVAL_REQUEST =
  "API/ENTITIES/DESIGN_REVERT_APPROVAL_REQUEST";
export const DESIGN_REVERT_APPROVAL_REQUEST_FAILURE =
  "API/ENTITIES/DESIGN_REVERT_APPROVAL_REQUEST_FAILURE";
export const DESIGN_REVERT_APPROVAL_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGN_REVERT_APPROVAL_REQUEST_SUCCESS";

export const EASIL_TUTORIAL_DESIGNS_REQUEST =
  "API/ENTITIES/EASIL_TUTORIAL_DESIGNS_REQUEST";
export const EASIL_TUTORIAL_DESIGNS_REQUEST_SUCCESS =
  "API/ENTITIES/EASIL_TUTORIAL_DESIGNS_REQUEST_SUCCESS";
export const EASIL_TUTORIAL_DESIGNS_REQUEST_FAILURE =
  "API/ENTITIES/EASIL_TUTORIAL_DESIGNS_REQUEST_FAILURE";

export const DESIGNS_DELETE_REQUEST = "API/ENTITIES/DESIGNS_DELETE_REQUEST";
export const DESIGNS_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/DESIGNS_DELETE_REQUEST_SUCCESS";
export const DESIGNS_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/DESIGNS_DELETE_REQUEST_FAILURE";

export const SET_DESIGN_SAVE_ERROR = "API/ENTITIES/SET_DESIGN_SAVE_ERROR";

export const PURCHASED_TEMPLATES_DESIGNS_REQUEST =
  "API/ENTITIES/PURCHASED_TEMPLATES_DESIGNS_REQUEST";
export const PURCHASED_TEMPLATES_DESIGNS_REQUEST_FAILURE =
  "API/ENTITIES/PURCHASED_TEMPLATES_DESIGNS_REQUEST_FAILURE";
export const PURCHASED_TEMPLATES_DESIGNS_REQUEST_SUCCESS =
  "API/ENTITIES/PURCHASED_TEMPLATES_DESIGNS_REQUEST_SUCCESS";
