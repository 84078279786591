import React from "react";

import Grid from "./grid/GridContainerLazy";
import Image from "./Image";
import Video from "./Video";
import Table from "./Table";
import Table2 from "./Table2";
import { default as Textbox } from "./TextboxContainerLazy";
import Vector from "./vector";
import VectorText from "./VectorText";
import QrCode from "./QrCode";

import Logger from "lib/logger";

const commonComponentProps = ["groupId", "id", "uniqueId"];

const elementsTypeComponentMap = {
  grid: {
    ElementComponent: Grid,
    componentProps: [
      "height",
      "imageInstructions",
      "left",
      "opacity",
      "restrictions",
      "scaleX",
      "scaleY",
      "source",
      "top",
      "type",
      "width",
      ...commonComponentProps
    ]
  },
  image: {
    ElementComponent: Image,
    componentProps: [
      "originalSrc",
      "fillColors",
      "fitToFrame",
      "filters",
      "height",
      "left",
      "mask",
      "opacity",
      "restrictions",
      "scale",
      "scaleX",
      "scaleY",
      "src",
      "previewSrc",
      "srcHeight",
      "srcWidth",
      "top",
      "width",
      "type",
      "link",
      "outline",
      "size",
      "mediaId",
      "name",
      "label",
      ...commonComponentProps
    ]
  },
  video: {
    ElementComponent: Video,
    componentProps: [
      "originalSrc",
      "fillColors",
      "filters",
      "height",
      "left",
      "mask",
      "opacity",
      "restrictions",
      "scale",
      "scaleX",
      "scaleY",
      "src",
      "previewSrc",
      "srcHeight",
      "srcWidth",
      "top",
      "width",
      "type",
      "link",
      "outline",
      "size",
      "mediaId",
      "name",
      "startOffset",
      "trimDuration",
      ...commonComponentProps
    ]
  },
  vector: {
    ElementComponent: Vector,
    componentProps: [
      "angle",
      "fillColors",
      "height",
      "imageInstructions",
      "left",
      "opacity",
      "restrictions",
      "resizableX",
      "resizableY",
      "scale",
      "src",
      "srcHeight",
      "srcWidth",
      "top",
      "width",
      "link",
      "outline",
      "mask",
      ...commonComponentProps
    ]
  },
  textbox: {
    ElementComponent: Textbox,
    componentProps: [
      "angle",
      "bold",
      "color",
      "filters",
      "fontFamily",
      "fontSize",
      "paragraphSpacing",
      "fontWeight",
      "height",
      "letterSpacing",
      "lineHeight",
      "maskImage",
      "opacity",
      "restrictions",
      "shadow",
      "textAlign",
      "textDecoration",
      "value",
      "displayValue",
      "width",
      "radius",
      "link",
      "top",
      "textboxAnchor",
      "textCase",
      ...commonComponentProps
    ]
  },
  table: {
    ElementComponent: Table,
    componentProps: [
      "columnsMetadata",
      "height",
      "opacity",
      "rows",
      "width",
      ...commonComponentProps
    ]
  },
  table2: {
    ElementComponent: Table2,
    componentProps: [
      "angle",
      "cells",
      "height",
      "isHidden",
      "layout",
      "left",
      "locked",
      "lockedCellIds",
      "opacity",
      "resizableX",
      "resizableY",
      "restrictions",
      "scale",
      "scaleX",
      "scaleY",
      "top",
      "type",
      "visible",
      "width",
      ...commonComponentProps
    ]
  },
  vectortext: {
    ElementComponent: VectorText,
    componentProps: [
      "angle",
      "fillColors",
      "height",
      "imageInstructions",
      "opacity",
      "restrictions",
      "resizableX",
      "resizableY",
      "scale",
      "srcHeight",
      "srcWidth",
      "width",
      "svgInstructions",
      "svgInstructions.degrees",
      "value",
      "displayValue",
      "fontFamily",
      "bold",
      "letterSpacing",
      "shadow",
      "textDecoration",
      "link",
      ...commonComponentProps
    ]
  },
  qrcode: {
    ElementComponent: QrCode,
    componentProps: [
      "angle",
      "fillColors",
      "height",
      "left",
      "opacity",
      "restrictions",
      "scale",
      "src",
      "srcHeight",
      "srcWidth",
      "top",
      "width",
      "link",
      "outline",
      "mask",
      "value",
      "color",
      "backgroundColor",
      "ecl",
      "padding",
      ...commonComponentProps
    ]
  }
};

elementsTypeComponentMap.background = elementsTypeComponentMap.image;

const ElementNotImplemented = {
  ElementComponent: () => <div>Element Not Supported</div>,
  componentProps: []
};

export const getElementComponent = elementType => {
  const elementComponentDetails = elementsTypeComponentMap[elementType];

  if (!elementComponentDetails) {
    Logger.error(`${elementType} Element Not Supported`);
    return ElementNotImplemented;
  }

  return elementComponentDetails;
};
