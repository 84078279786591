import * as designTagsTypes from "../designTags/designTagsTypes";
import * as tagsTypes from "./tagsTypes";

export const initState = {};

const tagsReducer = (state = initState, action) => {
  switch (action.type) {
    case designTagsTypes.ADD_TAG_TO_DESIGN_REQUEST: {
      return {
        ...state
      };
    }

    case designTagsTypes.FETCH_TAG_FOR_DESIGN_REQUEST_SUCCESS:
    case designTagsTypes.ADD_TAG_TO_DESIGN_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;
      const {
        response: {
          entities: { designTag }
        }
      } = action;

      return {
        ...state,
        ...designTag
      };
    }

    case tagsTypes.FETCH_PERSONAL_TAGS_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;
      const {
        response: {
          entities: { tag: tags }
        }
      } = action;

      return {
        ...state,
        ...tags,
        personalTagsCollected: true
      };
    }

    case tagsTypes.FETCH_TEAM_TAGS_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;
      const {
        response: {
          entities: { tag: tags }
        }
      } = action;
      return {
        ...state,
        ...tags
      };
    }

    default:
      return state;
  }
};

export default tagsReducer;
