import * as types from "./uiTypes";
import { fetchUsersWithParentTeamsIfNeeded } from "state/entities/users/usersActions";
import { fetchPendingUsersIfNeeded } from "state/entities/pendingUsers/pendingUsersActions";

export const teamPageUsersPageChange = page => (dispatch, getState) => {
  dispatch({
    type: types.TEAM_PAGE_USERS_PAGE_CHANGE,
    payload: { page }
  });

  const teamId = getState().ui.currentTeam.id;

  dispatch(fetchUsersWithParentTeamsIfNeeded({ page, teamId }));
};

export const teamPagePendingUsersPageChange = page => (dispatch, getState) => {
  dispatch({
    type: types.TEAM_PAGE_PENDING_USERS_PAGE_CHANGE,
    payload: { page }
  });

  dispatch(fetchPendingUsersIfNeeded({ page }));
};

export const switchTeam = teamId => ({
  type: types.SWITCH_TEAM,
  payload: { teamId }
});

export const teamPageUsersTableChange = type => (dispatch, getState) => {
  dispatch({
    type: types.TEAM_PAGE_USERS_TABLE_CHANGE,
    payload: { type }
  });
};
