import React from "react";
import { Image } from "views/components";

const LogoImage = ({ src, width = "88px", height = "88px" }) => {
  return (
    <Image
      src={src}
      key={src}
      fillContainer={true}
      width={width}
      height={height}
      borderRadius="50%"
    />
  );
};

export default LogoImage;
