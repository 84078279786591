import React from "react";
import PropTypes from "prop-types";
import { easilBlack, easilGrayXLight } from "./style.module.css";

const FileWithTextIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color = easilGrayXLight,
  backGroundColor,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 46}
    height={height || size || 56}
    viewBox="0 0 46 56"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <rect
        fill={backGroundColor || easilGrayXLight}
        transform="rotate(90 26 30.5)"
        x="1"
        y="11"
        width="50"
        height="39"
        rx="2"
      />
      <path
        d="M32.494 0L42 10.71V48a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h28.494zm-2.093 1.999L4 2a2 2 0 00-2 2v44a2 2 0 002 2h34a2 2 0 002-2V12.138h-6.598a3 3 0 01-2.995-2.823l-.005-.176-.001-7.14zM25 32a1 1 0 01.117 1.993L25 34H15a1 1 0 01-.117-1.993L15 32h10zm-13.99 0a1 1 0 01.117 1.993L11.01 34a1 1 0 01-.127-1.993L11.01 32zM25 26a1 1 0 01.117 1.993L25 28H15a1 1 0 01-.117-1.993L15 26h10zm-13.99 0a1 1 0 01.117 1.993L11.01 28a1 1 0 01-.127-1.993L11.01 26zM32 20a1 1 0 01.117 1.993L32 22H15a1 1 0 01-.117-1.993L15 20h17zm-20.99 0a1 1 0 01.117 1.993L11.01 22a1 1 0 01-.127-1.993L11.01 20zM32.401 2.907v6.232a1 1 0 00.884.993l.117.007 5.416-.001-6.417-7.231z"
        fill={color || easilBlack}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
FileWithTextIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FileWithTextIcon;
