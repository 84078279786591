import React from "react";
import { easilGrayXDark } from "./style.module.css";

const CrossCircledIcon = ({
  size = 24,
  color = easilGrayXDark,
  version = 1,
  className
}) => {
  switch (version) {
    case 2:
      // unfilled circle with cross of same color inside
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="2 2 20 20"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 100 16 8 8 0 000-16zM9.613 8.21l.094.083L12 10.585l2.293-2.292a1 1 0 011.497 1.32l-.083.094L13.415 12l2.292 2.293a1 1 0 01-1.32 1.497l-.094-.083L12 13.415l-2.293 2.292a1 1 0 01-1.497-1.32l.083-.094L10.585 12 8.293 9.707a1 1 0 011.32-1.497z"
              fill={color}
              fillRule="nonzero"
            />
          </g>
        </svg>
      );
    case 1:
    default:
      // circle filled, cross cut out
      return (
        <svg
          className={className}
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 24 24"
        >
          <path
            fill={color}
            d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM8.613 7.21a1 1 0 00-1.32 1.497L10.585 12l-3.292 3.293-.083.094a1 1 0 001.497 1.32L12 13.415l3.293 3.292.094.083a1 1 0 001.32-1.497L13.415 12l3.292-3.293.083-.094a1 1 0 00-1.497-1.32L12 10.585 8.707 7.293z"
          />
        </svg>
      );
  }
};

export default CrossCircledIcon;
