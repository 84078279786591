import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ShapesSquareIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <path
      d="M17.263 7H12.71l2.24-3.984L17.264 7zM14.065.51l-3.937 7A1 1 0 0 0 11 9h8a1 1 0 0 0 .865-1.502l-4.063-7a1 1 0 0 0-1.737.012zM0 3.112c0 .825.327 1.616.909 2.2l3.383 3.394a1 1 0 0 0 1.416 0l3.384-3.394a3.117 3.117 0 0 0 0-4.4A3.101 3.101 0 0 0 5 .647 3.1 3.1 0 0 0 .909.913 3.115 3.115 0 0 0 0 3.113zm2.73 1.195L2.326 3.9a1.115 1.115 0 0 1 0-1.575 1.102 1.102 0 0 1 1.561 0l.406.407a1 1 0 0 0 1.416 0l.406-.407a1.1 1.1 0 0 1 1.561 0c.433.434.433 1.14 0 1.575L5 6.584 2.73 4.307zM15.5 20a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm-13-5v5h5v-5h-5zm0-2h5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2z"
      fill={color || easilGrayXDark}
    />
  </svg>
);
ShapesSquareIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ShapesSquareIcon;
