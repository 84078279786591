import React from "react";
import { connect } from "react-redux";
import PaymentDetails from "./PaymentDetails";
import {
  setToken,
  setPaymentType,
  createPaypalCollectionOrder as createPaypalOrder,
  addPaymentForCollectionOrder as createOrderPayment,
  clearPurchaseCollectionPaymentError
} from "state/ui/purchaseCollectionModal/purchaseCollectionModalActions";
import {
  purchaseCollectionPaymentTypeSelector,
  purchaseCollectionTokenSelector,
  purchaseCollectionErrorsSelector
} from "state/ui/purchaseCollectionModal/purchaseCollectionModalSelectors";
import { currentSubscriptionPlan as currentSubscriptionPlanSelector } from "state/entities/subscriptions/subscriptionsSelectors";

export const PaymentDetailsContainer = props => <PaymentDetails {...props} />;

const mapStateToProps = state => {
  const currentSubscriptionPlan = currentSubscriptionPlanSelector(state);
  const currencyCode = currentSubscriptionPlan.currency;
  const paymentErrors = purchaseCollectionErrorsSelector(state);
  return {
    paymentType: purchaseCollectionPaymentTypeSelector(state),
    token: purchaseCollectionTokenSelector(state),
    currencyCode,
    paymentErrors
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setToken: args => dispatch(setToken(args)),
    setPaymentType: args => dispatch(setPaymentType(args)),
    createPaypalOrder: () =>
      dispatch(createPaypalOrder({ collectionId: ownProps.collectionId })),
    createOrderPayment: args => dispatch(createOrderPayment(args)),
    clearPaymentError: () => dispatch(clearPurchaseCollectionPaymentError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetailsContainer);
