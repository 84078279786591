import { keyCodes } from "lib";

const BrandKitAnimationOps = {
  setQueryString(event) {
    const { modifyQuery } = this.props;
    const term = event.target.value;

    if (term) {
      if (event.keyCode === keyCodes.enterKey) {
        modifyQuery({ appendTerms: { term } });
        return;
      }
    } else {
      this.props.setBrandKitState({ qsTerm: null }, "animations");
      modifyQuery({ dropTerms: ["term"] });
    }
  },

  handleOnChange(term) {
    const { modifyQuery, setBrandKitState } = this.props;
    setBrandKitState({ qsTerm: term }, "animations");

    if (!term) {
      modifyQuery({ dropTerms: ["term"] });
    }
  },

  handleSortUpdate(value) {
    this.props.setBrandKitState(
      {
        sortKey: value
      },
      "animations"
    );
  }
};

export default BrandKitAnimationOps;
