import dateFns from "date-fns";

/**
 * @desc - takes a lastFetched date and an expiryInterval in minutes and returns a Boolean indicating if the data is expired
 * @param {Date} lastFetched - a Date object representing the last time a data set was fetched
 * @param {number} expiryInterval - a number which represents the age in minutes when a data set is considered expired
 * @example isDataExpired(<DATE>, 1440)
 */
export const isDataExpired = (lastFetched, expiryInterval) => {
  if (!lastFetched) return true;

  const currentTime = Date.now();
  const dataAgeMinutes =
    dateFns.differenceInSeconds(currentTime, lastFetched) / 60;

  // if the data is older than its expiry time then it is expired
  return Boolean(dataAgeMinutes > expiryInterval);
};

const reduxStoreExpiry = { isDataExpired };

export default reduxStoreExpiry;
