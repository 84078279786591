export const TEAM_SETTINGS_REQUEST = "API/ENTITIES/TEAM_SETTINGS_REQUEST";
export const TEAM_SETTINGS_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SETTINGS_REQUEST_SUCCESS";
export const TEAM_SETTINGS_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SETTINGS_REQUEST_FAILURE";

export const TEAM_SETTINGS_UPDATE_REQUEST =
  "API/ENTITIES/TEAM_SETTINGS_UPDATE_REQUEST";
export const TEAM_SETTINGS_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SETTINGS_UPDATE_REQUEST_SUCCESS";
export const TEAM_SETTINGS_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SETTINGS_UPDATE_REQUEST_FAILURE";
