import CopyMaker from "lib/Copymaker/Copymaker";
const sessionRecorder = {};

const {
  REACT_APP_SESSION_REWIND_KEY: apiKey,
  REACT_APP_SESSION_REWIND_APP: userApplication
} = process.env;

const cmConfig = { apiKey, iframe: true };
const easilConfig = {
  apiKey,
  startRecording: true,
  userInfo: { userApplication }
};

sessionRecorder.init = () => {
  window.SessionRewindConfig = CopyMaker.isCmModeOn() ? cmConfig : easilConfig;
  const f = document.createElement("script");
  f.async = 1;
  f.crossOrigin = "anonymous";
  f.src = "https://rec.sessionrewind.com/srloader.js";
  const g = document.getElementsByTagName("head")[0];
  g.insertBefore(f, g.firstChild);
};

sessionRecorder.identify = data => {
  if (CopyMaker.isCmModeOn()) {
    return;
  }
  const { country, email, id, name, roles, teamId, teamPlan } = data;
  const userInfo = {
    userId: email, // NOTE: portal is identifying session by user email
    id,
    userName: name,
    email,
    roles: `${roles}`,
    teamId,
    teamPlan,
    country,
    userApplication
  };
  if (window.sessionRewind) {
    window.sessionRewind.identifyUser(userInfo);
  } else {
    window.SessionRewindConfig = {
      apiKey,
      startRecording: true,
      userInfo
    };
  }
};

export default sessionRecorder;
