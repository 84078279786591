import React from "react";
import { downloadDesign as downloadFromPath } from "lib";
import { formatDateString } from "lib/temporal/dateUtils";
import ArrowIntoBoxIcon from "views/components/icons/ArrowIntoBoxIcon";

import style from "./style.module.css";

export class InvoiceMonthList extends React.Component {
  constructor(props) {
    super(props);

    this.listRef = React.createRef();
    this.updateStyleMaxHeight = this.updateStyleMaxHeight.bind(this);
  }

  updateStyleMaxHeight() {
    const listRef = this.listRef.current;
    const height = listRef.scrollHeight;
    listRef.style.setProperty(
      "--invoice-month-list-selected-max-height",
      `${height}px`
    );
  }

  componentDidUpdate(prevProps) {
    // on a new selection, assign the max-height variable
    if (
      !prevProps.invoiceMonth.isSelected &&
      this.props.invoiceMonth.isSelected
    ) {
      this.updateStyleMaxHeight();
    }
  }

  render() {
    const { invoiceMonth } = this.props;

    const { invoices } = invoiceMonth;

    return (
      <div
        className={style.invoiceMonthInvoiceList}
        data-is-selected={invoiceMonth.isSelected}
        ref={this.listRef}
      >
        {/* hidden list of invoices until selected */}
        <div className={style.invoiceListHeaders}>
          <div className={style.invoiceListTotalHeader}>Total</div>
          <div className={style.invoiceListInvoiceIdHeader}>Invoice No.</div>
          <div className={style.invoiceListDateHeader}>Date</div>
        </div>
        {invoices.map(invoice => (
          <div className={style.invoiceListValueRow} key={invoice.id}>
            <div className={style.invoiceListTotalValue}>
              {invoice.amount ? `$${invoice.amount}` : "-"}
            </div>
            <div className={style.invoiceListInvoiceIdValue}>{invoice.id}</div>
            <div className={style.invoiceListDateValue}>
              {formatDateString(new Date(invoice.createdAt), "D MMM, h:m a")}
            </div>
            <div
              className={style.invoiceListDownloadButton}
              onClick={() =>
                downloadFromPath({
                  path: invoice.invoicePdf,
                  isTargetBlank: true
                })
              }
            >
              <ArrowIntoBoxIcon size="24px" />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

InvoiceMonthList.displayName = "InvoiceMonthList";

export default InvoiceMonthList;
