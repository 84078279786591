import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark, easilGrayXXXLight } from "./style.module.css";

const EllipsisIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  withBackground,
  backgroundColor,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="EllipsisIcon"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <g>
        {withBackground && (
          <rect
            fill={backgroundColor || easilGrayXXXLight}
            width="24"
            height="24"
            rx="4"
          />
        )}
        <circle fill={color || easilGrayXDark} cx="12" cy="12" r="1.5" />
        <circle fill={color || easilGrayXDark} cx="18" cy="12" r="1.5" />
        <circle fill={color || easilGrayXDark} cx="6" cy="12" r="1.5" />
      </g>
    </g>
  </svg>
);

EllipsisIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  withBackground: PropTypes.bool,
  backgroundColor: PropTypes.string
};

export default EllipsisIcon;
