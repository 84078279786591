export const USER_TEAM_SMART_IMAGES_REQUEST =
  "API/ENTITIES/USER_TEAM_SMART_IMAGES_REQUEST";
export const USER_TEAM_SMART_IMAGES_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_SMART_IMAGES_REQUEST_FAILURE";
export const USER_TEAM_SMART_IMAGES_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_SMART_IMAGES_REQUEST_SUCCESS";

export const USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST =
  "API/ENTITIES/USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST";
export const USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST_FAILURE";
export const USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS";

export const USER_TEAM_SMART_IMAGE_UPDATE_REQUEST =
  "API/ENTITIES/USER_TEAM_SMART_IMAGE_UPDATE_REQUEST";
export const USER_TEAM_SMART_IMAGE_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_SMART_IMAGE_UPDATE_REQUEST_FAILURE";
export const USER_TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS";

export const USER_TEAM_SMART_IMAGE_DELETE_REQUEST =
  "API/ENTITIES/USER_TEAM_SMART_IMAGE_DELETE_REQUEST";
export const USER_TEAM_SMART_IMAGE_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_SMART_IMAGE_DELETE_REQUEST_FAILURE";
export const USER_TEAM_SMART_IMAGE_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_SMART_IMAGE_DELETE_REQUEST_SUCCESS";
