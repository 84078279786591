import React, { Component } from "react";
import { connect } from "react-redux";
import { imageFoldersSelector } from "state/entities/imageFolders/imageFoldersSelectors";
import {
  fetchAllImageFoldersIfNeeded,
  uploadImageToFolder
} from "state/entities/imageFolders/imageFoldersActions";
import ImagesUploadModal from "./ImagesUploadModal";
import {
  uploadUserTeamImage,
  addUploadingImagesPlaceholders
} from "state/entities/userTeamImages/userTeamImagesActions";
import { STATIC_ASSET_FILE_TYPES } from "lib/constants";
import { noop } from "lib";

export class ImagesUploadModalContainer extends Component {
  render() {
    return (
      <ImagesUploadModal
        {...this.props}
        acceptedFileTypes={STATIC_ASSET_FILE_TYPES}
      />
    );
  }
}

const mapStateToProps = state => {
  const imageFolders = imageFoldersSelector(state);
  return {
    imageFolders
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUsersImageFolders: args =>
      dispatch(fetchAllImageFoldersIfNeeded(args)),
    onUploadUserTeamImage: args => dispatch(uploadUserTeamImage(args)),
    onUploadImageToFolder: args => dispatch(uploadImageToFolder(args)),
    clearUploadErrors: () => noop,
    addUploadingImagesPlaceholders: args =>
      dispatch(addUploadingImagesPlaceholders(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImagesUploadModalContainer);
