export const REMOVE_BACKGROUND_REQUEST =
  "API/ENTITIES/REMOVE_BACKGROUND_REQUEST";
export const REMOVE_BACKGROUND_REQUEST_FAILURE =
  "API/ENTITIES/REMOVE_BACKGROUND_REQUEST_FAILURE";
export const REMOVE_BACKGROUND_REQUEST_SUCCESS =
  "API/ENTITIES/REMOVE_BACKGROUND_REQUEST_SUCCESS";

export const REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST =
  "API/ENTITIES/REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST";
export const REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST_FAILURE";
export const REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST_SUCCESS";
