import React from "react";
import Table2CellContainer from "./Table2Cell/Table2Cell/Table2CellContainer";
import Table2EditorOps from "au.com.easil.table2editorops";

const Table2Row = ({
  row,
  rowIndex,
  elementData,
  isPreview,
  zoom,
  lockedRowResizeCellIds,
  lockedColumnResizeCellIds
}) => {
  const table2EditorOps = new Table2EditorOps(elementData.layout);
  return (
    <tr key={rowIndex}>
      {row.map(({ coordinates, id }, columnIndex) => {
        const isReferenceCell =
          rowIndex !== coordinates[0] || columnIndex !== coordinates[1];
        if (!isReferenceCell) {
          const rowSpan = table2EditorOps.computeRowSpan(rowIndex, columnIndex);
          const colSpan = table2EditorOps.computeColSpan(rowIndex, columnIndex);
          return (
            <Table2CellContainer
              key={columnIndex}
              colSpan={colSpan}
              rowSpan={rowSpan}
              rowIndex={rowIndex}
              columnIndex={columnIndex}
              table2EditorOps={table2EditorOps}
              cellId={id}
              elementData={elementData}
              isPreview={isPreview}
              zoom={zoom}
              lockedRowResizeCellIds={lockedRowResizeCellIds}
              lockedColumnResizeCellIds={lockedColumnResizeCellIds}
            />
          );
        } else {
          return null;
        }
      })}
    </tr>
  );
};

export default Table2Row;
