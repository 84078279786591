export const PENDING_USERS_REQUEST = "API/ENTITIES/PENDING_USERS_REQUEST";
export const PENDING_USERS_REQUEST_FAILURE =
  "API/ENTITIES/PENDING_USERS_REQUEST_FAILURE";
export const PENDING_USERS_REQUEST_SUCCESS =
  "API/ENTITIES/PENDING_USERS_REQUEST_SUCCESS";
export const PENDING_USERS_TOTAL_REQUEST =
  "API/ENTITIES/PENDING_USERS_TOTAL_REQUEST";
export const PENDING_USERS_TOTAL_REQUEST_FAILURE =
  "API/ENTITIES/PENDING_USERS_TOTAL_REQUEST_FAILURE";
export const PENDING_USERS_TOTAL_REQUEST_SUCCESS =
  "API/ENTITIES/PENDING_USERS_TOTAL_REQUEST_SUCCESS";
