import WebFontLoader from "webfontloader";
import Logger from "lib/logger";
import { getPath } from "lib/lodash";

export const fetchFonts = (urls, fontNames) => {
  const fontFilesPromises = urls.map(url => {
    return new Promise((resolve, reject) => {
      const link = document.createElement("link");
      link.type = "text/css";
      link.as = "stylesheet";
      link.rel = "prefetch stylesheet";

      link.addEventListener(
        "load",
        event => {
          event.target.rel = "stylesheet";
          resolve(`FontFile ${url} Ready`);
        },
        { once: true }
      );
      link.href = url;

      const headScript = document.querySelector("script");
      headScript.parentNode.insertBefore(link, headScript);
    });
  });

  return new Promise((resolve, reject) => {
    Promise.all(fontFilesPromises).then(() => {
      WebFontLoader.load({
        custom: {
          families: fontNames
        },
        classes: false,
        loading: () => {
          Logger.info("Loading fonts...");
        },
        fontloading: font => {
          /*           Logger.info('Loaded font: ' + font);*/
        },
        active: () => resolve(`FontNames Ready`),
        fontinactive: (font, fvd) => {
          /*           Logger.info("Couldn't load " + font + ' font');*/
        },
        inactive: () => {
          Logger.info(
            "All fonts failed loading..\n\rTrying to load site anyway.."
          );
          resolve(`FontNames failed`);
        }
      });
    });
  });
};

/**
 * @desc searches through the current browser storage for font matching fontName
 * @param {string} fontName - the name of the font to match
 * @returns {object} - returns an object containing font information
 */
export const getFontByName = fontName => {
  // escape early if no fontName
  if (!fontName) return;

  const publicFonts = getPath(window, "easil.editor.props.publicFonts");
  const userFonts = getPath(window, "easil.editor.props.userFonts");

  // escape early if no fonts in browser yet
  if (!publicFonts && !userFonts) {
    return;
  }

  const fontList = Object.values({ ...publicFonts, ...userFonts });

  // Both Bold & Italic
  if (fontName.includes("BoldItalic")) {
    const font = fontList.find(
      font => font.boldItalicFontFamilyName === fontName
    );
    if (font) {
      return font;
    }
  }

  //Just Bold
  if (fontName.includes("Bold")) {
    const font = fontList.find(font => font.boldFontFamilyName === fontName);
    if (font) {
      return font;
    }
  }

  //Just Italic
  if (fontName.includes("Italic")) {
    const font = fontList.find(font => font.italicFontFamilyName === fontName);
    if (font) {
      return font;
    }
  }

  // Normal
  const font = fontList.find(font => font.fontFamilyName === fontName);
  if (font) {
    return font;
  }

  // Note: do not set fontId if none found as this will prevent getting real id in future
  return;
};
