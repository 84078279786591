import * as types from "./shareModalTypes";
import * as teamApiTypes from "state/entities/teams/teamsTypes";
import * as collectionAllocationTypes from "state/api/collectionAllocations/collectionAllocationsApiTypes";
import { isEmpty, uniqWith } from "lib";

export const initState = {
  collectionAllocations: {},
  collectionId: null,
  isFetchingSubTeams: false,
  isOpen: false,
  teams: null,
  isFetchingAllocations: false
};

const shareModalReducers = (state = initState, action) => {
  switch (action.type) {
    case collectionAllocationTypes.LIST_TEAMS_REQUEST: {
      return {
        ...state,
        isFetchingAllocations: true
      };
    }

    case collectionAllocationTypes.ADD_TEAMS_FAILURE:
    case collectionAllocationTypes.REMOVE_TEAM_FAILURE:
    case collectionAllocationTypes.LIST_TEAMS_FAILURE: {
      const {
        error,
        extra: { collectionId },
        type
      } = action;

      return {
        ...state,
        collectionAllocations: {
          [collectionId]: { error, type }
        },
        isFetchingAllocations: false
      };
    }

    case collectionAllocationTypes.REMOVE_TEAM_REQUEST: {
      const {
        extra: { collectionId, teamId, isAllSelected }
      } = action;

      const filteredCollectionAllocations = state.collectionAllocations[
        collectionId
      ].filter(allocation => !isAllSelected && allocation.teamId !== teamId);

      return {
        ...state,
        collectionAllocations: {
          [collectionId]: filteredCollectionAllocations
        }
      };
    }

    case collectionAllocationTypes.LIST_TEAMS_SUCCESS: {
      const {
        extra: { collectionId }
      } = action;

      if (!Array.isArray(action.response)) {
        const updatedState = {
          ...state,
          isFetchingAllocations: false
        };

        if (!isEmpty(action.response)) {
          updatedState.collectionAllocations[collectionId] =
            action.response || null;
        }

        return updatedState;
      }

      const currentCollectionAllocation =
        state.collectionAllocations[collectionId];

      const joinedAllocations = uniqWith(
        [
          ...(currentCollectionAllocation &&
          Array.isArray(currentCollectionAllocation)
            ? currentCollectionAllocation
            : []),
          ...(Array.isArray(action.response) ? action.response : [])
        ],
        (a, b) => a.teamId === b.teamId && a.designId === b.designId
      );

      return {
        ...state,
        collectionAllocations: {
          [collectionId]:
            joinedAllocations.length > 0 ? joinedAllocations : null
        },
        isFetchingAllocations: false
      };
    }

    case types.SHOW_SHARE_MODAL: {
      return {
        ...state,
        collectionId: action.collectionId,
        isOpen: true
      };
    }

    case types.HIDE_SHARE_MODAL: {
      return initState;
    }

    case teamApiTypes.SUB_TEAMS_REQUEST: {
      return {
        ...state,
        isFetchingSubTeams: true
      };
    }
    case teamApiTypes.SUB_TEAMS_REQUEST_FAILURE:
    case teamApiTypes.SUB_TEAMS_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetchingSubTeams: false
      };
    }

    default:
      return state;
  }
};

export default shareModalReducers;
