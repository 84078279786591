import { isNil } from "lib";

export const collectionAllocationsSelector = ({ state, collectionId }) => {
  const collectionAllocation = state.api.collectionAllocations[collectionId];
  if (!collectionAllocation) return [];
  const ids = collectionAllocation.ids;
  return ids
    .map(id => state.entities.collectionAllocations[id])
    .filter(allocation => !isNil(allocation));
};

export const collectionAllocationsSelectorById = ({ state, allocationId }) =>
  state.entities.collectionAllocations[allocationId];
