import * as types from "./teamAnimationsFoldersTypes";
import { push } from "react-router-redux";
import { isEmpty, noop } from "lib/lodash";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import {
  uploadTeamAnimationToPreSignedUrl,
  uploadTeamAnimationApiCall
} from "state/entities/teamAnimations/teamAnimationsActions";
import PATHS from "routes/paths";
import { getParameterByName } from "lib/queryStringUtils";
import { ANIMATION_ASSET_TYPE_MAP } from "lib/constants";
import { SUBTAB_TYPES } from "views/components/Editor/sidebar/tabs/animations/animationsTabConstants";

const shouldFetchAllTeamAnimationFolders = state => {
  return isEmpty(state.api.teamAnimationsFolders.pages);
};

export const fetchAllTeamAnimationFoldersIfNeeded = (...args) => (
  dispatch,
  getState
) => {
  if (shouldFetchAllTeamAnimationFolders(getState())) {
    dispatch(fetchAllTeamAnimationFolders(...args));
  }
};

export const fetchAllTeamAnimationFolders = ({ page = 1 } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.FETCH_TEAM_ANIMATION_FOLDERS_REQUEST,
        types.FETCH_TEAM_ANIMATION_FOLDERS_REQUEST_SUCCESS,
        types.FETCH_TEAM_ANIMATION_FOLDERS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders`,
      request: {
        page: page,
        params: {
          type: "BRAND_ANIMATION"
        }
      },
      schema: schemas.FOLDERS,
      onSuccess: response => {
        if (response.entities) {
          dispatch(fetchAllTeamAnimationFolders({ teamId, page: page + 1 }));
        }
      }
    }
  });
};

export const createTeamAnimationsFolder = ({ name, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.CREATE_TEAM_ANIMATION_FOLDER_REQUEST,
        types.CREATE_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS,
        types.CREATE_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders`,
      request: {
        body: {
          teamId,
          name,
          type: "BRAND_ANIMATION"
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const addAnimationToTeamFolder = ({
  folderId,
  contentId,
  onSuccess
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      types: [
        types.ADD_ANIMATION_TO_TEAM_FOLDER_REQUEST,
        types.ADD_ANIMATION_TO_TEAM_FOLDER_REQUEST_SUCCESS,
        types.ADD_ANIMATION_TO_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders/${folderId}/content`,
      request: {
        body: {
          teamId,
          contentId,
          folderId,
          type: "BRAND_ANIMATION"
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const deleteTeamAnimationFolder = ({ folderId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.DELETE_TEAM_ANIMATION_FOLDER_REQUEST,
        types.DELETE_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS,
        types.DELETE_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders/${folderId}`,
      schema: schemas.FOLDER,
      extra: {
        folderId
      },
      onSuccess
    }
  });
};

export const updateTeamAnimationFolder = ({ folder, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ASSET,
      types: [
        types.UPDATE_TEAM_ANIMATION_FOLDER_REQUEST,
        types.UPDATE_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS,
        types.UPDATE_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders/${folder.id}`,
      schema: schemas.FOLDER,
      request: {
        body: folder,
        type: "BRAND_ANIMATION"
      },
      onSuccess
    }
  });
};

export const removeTeamAnimationFromFolder = ({
  folderId,
  contentId,
  onSuccess
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.REMOVE_ANIMATION_FROM_TEAM_FOLDER_REQUEST,
        types.REMOVE_ANIMATION_FROM_TEAM_FOLDER_REQUEST_SUCCESS,
        types.REMOVE_ANIMATION_FROM_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders/${folderId}/content/${contentId}`,
      request: {
        extra: {
          contentId,
          folderId,
          type: "BRAND_ANIMATION"
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const uploadTeamAnimationToFolder = ({
  folderId,
  imageFile: file,
  onSuccess,
  duration,
  onPresignedUrlCreated = noop,
  noRefetch,
  placeholderURL
}) => async (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const assetType = getParameterByName("assetType", window.location);
  const getAnimationFetchType = () => {
    if (assetType) {
      return assetType === SUBTAB_TYPES.ANIMATIONS
        ? ANIMATION_ASSET_TYPE_MAP.ANIMATION
        : ANIMATION_ASSET_TYPE_MAP.VIDEO;
    }
  };

  // synchronously generate a pre signed url for the direct upload
  const preSignedUrl = await generatePreSignedUrlPromise({ file, teamId });
  onPresignedUrlCreated(preSignedUrl.id);

  // synchronously upload the image file to the pre signed url
  await uploadTeamAnimationToPreSignedUrlPromise({
    file,
    preSignedUrl: preSignedUrl.url
  });

  const { mediaId } = await generateAnimationUploadApiPromise({
    duration,
    filename: preSignedUrl.filename,
    fileId: preSignedUrl.id,
    placeholderURL
  });

  // make update request to the api
  dispatch(
    uploadTeamAnimationToFolderApiCall({
      folderId,
      contentId: mediaId,
      onSuccess,
      noRefetch,
      placeholderURL,
      type: getAnimationFetchType()
    })
  );

  function generateAnimationUploadApiPromise({
    duration,
    filename,
    fileId,
    placeholderURL
  }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadTeamAnimationApiCall({
          duration,
          filename,
          fileId,
          placeholderURL,
          onSuccess: resolve,
          onFailure: reject,
          folderId
        })
      );
    });
  }

  /**
   * @desc taking a file object and a teamId makes a request for a pre signed image upload url and returns it on resolution
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.teamId - the id string for the current team to upload the image for
   * @returns {Promise} results in a Promise that resolves when the pre signed url is retrieved containing a 'url' key
   */
  function generatePreSignedUrlPromise({ file, teamId }) {
    return new Promise((resolve, reject) => {
      dispatch(
        createTeamAnimationInFolderUploadUrl({
          folderId,
          filename: file.name,
          responseContentType: file.type,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }

  /**
   * @desc taking a file and a pre signed url processes the upload of the file to the passed url
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
   * @returns {Promise} results in a Promise that resolves when the image has been successfully uploaded
   */
  function uploadTeamAnimationToPreSignedUrlPromise({ file, preSignedUrl }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadTeamAnimationToPreSignedUrl({
          imageFile: file,
          preSignedUrl: preSignedUrl,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }
};

/**
 * @desc makes the api request for a pre signed url
 * @param {Object} args
 * @param {string} folderId - the id for the folder to upload the file into
 * @param {string} filename - the name of the file that will be uploaded
 * @param {string} responseContentType - the type extension for the file to be uploaded
 * @param {function} onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} onFailure - a function that runs when the request fails passed the results of the request
 */
export const createTeamAnimationInFolderUploadUrl = ({
  folderId,
  filename,
  responseContentType,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.TEAM_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST,
        types.TEAM_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST_SUCCESS,
        types.TEAM_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/animations/url`,
      request: {
        body: {
          teamId,
          folderId,
          filename,
          responseContentType
        }
      },
      onSuccess: onSuccess,
      schema: schemas.NONE
    }
  });
};

/**
 * @desc adds the details for an uploaded image to the api
 * @param {Object} args
 * @param {string} args.folderId - the id for the folder that the image is to be contained in
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which is run when the request succeeds and is passed the results of the request
 */
export const uploadTeamAnimationToFolderApiCall = ({
  folderId,
  contentId,
  onSuccess = noop,
  noRefetch,
  placeholderURL,
  type
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      types: [
        types.UPLOAD_ANIMATION_TO_TEAM_FOLDER_REQUEST,
        types.UPLOAD_ANIMATION_TO_TEAM_FOLDER_REQUEST_SUCCESS,
        types.UPLOAD_ANIMATION_TO_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders/${folderId}/content`,
      request: {
        body: {
          teamId,
          folderId,
          contentId,
          type: "BRAND_ANIMATION"
        }
      },
      extra: {
        placeholderURL
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const addTeamAnimationToFolder = ({
  folderId,
  contentId,
  onSuccess
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      types: [
        types.ADD_ANIMATION_TO_TEAM_FOLDER_REQUEST,
        types.ADD_ANIMATION_TO_TEAM_FOLDER_REQUEST_SUCCESS,
        types.ADD_ANIMATION_TO_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders/${folderId}/content`,
      request: {
        body: {
          teamId,
          contentId,
          folderId,
          type: "BRAND_ANIMATION"
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const deleteTeamAnimationFolderAndGoToBrandAnimations = ({
  folderId
}) => dispatch => {
  dispatch(deleteTeamAnimationFolder({ folderId }));
  dispatch(push(PATHS.brandAnimations));
};
