import React from "react";
import GridCellCropResizeHandler from "./GridCellCropResizeHandler";
import { connect } from "react-redux";
import { Logger } from "lib";
import {
  isCroppingSelector,
  contextSelector,
  selectedItemsSelector,
  zoomSelector,
  designDataSelector,
  elementPreviewsSelector
} from "state/ui/editorContext/editorContextSelectors";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { getCanvasbackground } from "views/components/Editor/utils";
import { EditorGridOps } from "views/components/Editor/editorOps";

class GridCellCropResizeHandlerContainer extends React.Component {
  constructor(props) {
    super(props);

    this.startGridCellCropResizing = this.startGridCellCropResizing.bind(this);
    this.finishGridCellCropResizing = this.finishGridCellCropResizing.bind(
      this
    );
  }

  startGridCellCropResizing({ pageId }) {
    Logger.info(
      "GridCellCropResizeHandlerContainer.startGridCellCropResizing called"
    );

    const canvasBackground = getCanvasbackground();
    /* we disable the scrolling while resizing */
    canvasBackground.style.overflowX = "hidden";

    this.props.updateContext({
      isCropping: true
    });
  }

  finishGridCellCropResizing({
    handlerInitialPosition,
    anchorPoint,
    differenceFromInitialOffset,
    pageHtmlElement,
    scaleAnchorPoint,
    position,
    selectionBoxNodeCenter
  }) {
    Logger.info(
      "GridCellCropResizeHandlerContainer.finishGridCellCropResizing called"
    );

    const { zoom, selectedItems, designData, context } = this.props;

    const updatedContext = EditorGridOps.finishGridCellCropResizing({
      handlerInitialPosition,
      anchorPoint,
      differenceFromInitialOffset,
      pageHtmlElement,
      scaleAnchorPoint,
      position,
      selectionBoxNodeCenter,
      zoom,
      selectedItems,
      designData,
      context
    });

    this.props.updateContext(updatedContext);
  }

  render() {
    const {
      context: { isCroppingGridCell, selectedGridCellId },
      designData,
      selectedItems,
      zoom
    } = this.props;

    if (!isCroppingGridCell) {
      return null;
    }

    let gridElement = designData.elements[selectedItems[0].itemId];

    gridElement = {
      ...gridElement,
      ...selectedItems[0].preview
    };

    const gridCellIndex = gridElement.imageInstructions.findIndex(
      imageInstruction => imageInstruction.domId === selectedGridCellId
    );

    const gridCell = gridElement.imageInstructions[gridCellIndex];

    return (
      <GridCellCropResizeHandler
        {...this.props}
        selectedGridCellId={selectedGridCellId}
        startCropResizing={this.startGridCellCropResizing}
        finishCropResizing={this.finishGridCellCropResizing}
        selectedItems={selectedItems}
        getElement={id => designData.elements[id]}
        zoom={zoom}
        gridCell={gridCell}
        gridElement={gridElement}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isCropping: isCroppingSelector(state),
    context: contextSelector(state),
    selectedItems: selectedItemsSelector(state),
    zoom: zoomSelector(state),
    elementPreviews: elementPreviewsSelector(state),
    designData: designDataSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContext: args => dispatch(updateEditorContextState(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridCellCropResizeHandlerContainer);
