/**
 * Map coupon api validation errors to user friendly (eventually localised) error messages.
 * @return error object of { field: '', message: '' }
 */
const couponMessageMapper = () => {
  // Current decision is to just say it's invalid rather than Not Found/Expired/Invalid etc.
  return {
    field: "coupon",
    message: "The provided coupon is invalid"
  };
};

const nameMessageMapper = error => {
  if (!error) {
    return null;
  }
  // TODO: This is currently folder.name specific. More work needs to be done to shape this error
  // handling.
  const baseErrorMessage = {
    field: "name",
    message: null
  };
  switch (error.type) {
    case "notFound":
      return {
        ...baseErrorMessage,
        message: "Folder name must be unique"
      };
    case "match":
      return {
        ...baseErrorMessage,
        message:
          "Your folder name can contain letters & numbers only! Please remove any special characters, and click 'Create'."
      };
    default:
      return {
        ...baseErrorMessage,
        message: "An error has occurred creating your folder"
      };
  }
};

// See https://stripe.com/docs/error-codes
const stripeMessageMapper = error => {
  if (!error) {
    return null;
  }

  const baseErrorMessage = {
    field: "card",
    message: null
  };
  switch (error.code) {
    case "card_declined":
      return {
        ...baseErrorMessage,
        message: error.message
      };
    case "expired_card":
      return {
        ...baseErrorMessage,
        message: "The card has expired"
      };
    case "invalid_cvc":
    case "invalid_expiry_month":
    case "invalid_expiry_year":
    case "invalid_number":
      return {
        ...baseErrorMessage,
        message: "The card details are incorrect"
      };
    case "processing_error":
      return {
        ...baseErrorMessage,
        message:
          "An error occurred while processing the card. Please try another card"
      };
    case "resource_missing":
      return {
        field: "coupon",
        message: "There was an error processing your upgrade"
      };
    default:
      return {
        ...baseErrorMessage,
        message: "Please check your card"
      };
  }
};

const stripeTokenMessageMapper = error => {
  if (!error) {
    return null;
  }

  return {
    field: "card",
    message:
      "There was an error processing your card. Please check your card details"
  };
};

const imageSizeMessageMapper = error => {
  if (!error) {
    return null;
  }

  switch (error.type) {
    case "between":
      return {
        field: "imageSize",
        message: "The file size of your image is too large."
      };

    case "unknown_foreground":
      return {
        field: "unknown_foreground",
        message: "Could not find a well defined foreground object"
      };

    default:
      return {
        field: "Error",
        message: "An error has occurred when removing background"
      };
  }
};

const userTeamFolderMessageMapper = error => {
  if (!error) {
    return null;
  }

  const baseErrorMessage = {
    field: "userTeamFolder",
    message: null
  };
  switch (error.type) {
    case "notFound":
      return {
        ...baseErrorMessage,
        message: "Folder name must be unique"
      };
    case "match":
      return {
        ...baseErrorMessage,
        message:
          "Your folder name can contain letters & numbers only! Please remove any special characters, and click 'Create'."
      };
    default:
      return {
        ...baseErrorMessage,
        message: "An error has occurred creating your folder"
      };
  }
};

/**
 * An object the maps the 'path' of an error to the relevant message mapper implementation.
 *
 * The message mapper implementation must return a standard error object of
 * {
 *   field: '',
 *   message: ''
 * }
 *
 * @type {{coupon: couponMessageMapper}}
 */
const messageMappers = {
  coupon: couponMessageMapper,
  name: nameMessageMapper,
  StripeCardError: stripeMessageMapper,
  StripeInvalidRequestError: stripeMessageMapper,
  teamFolder: nameMessageMapper,
  token: stripeTokenMessageMapper,
  userTeamFolder: userTeamFolderMessageMapper,
  "image size": imageSizeMessageMapper
};

/**
 * Process the errors array and map to an array of UI friendly error objects based on the path
 * value of each error.
 *
 * The path indicates the field that has failed validation.
 *
 * If no mapper is found, return empty array and leave it up to the component to decide what to show
 * (as it currently does)
 *
 * @param errors Array of errors returned from the API.
 * @return Array of error objects or empty array if no mapper found.
 */
export const parseClientErrorMessages = ({ errors }) => {
  if (Array.isArray(errors)) {
    return errors.map(error => {
      // error key can be a path for validation or a name for others
      const errorKey = error.path || error.name;
      return messageMappers[errorKey] ? messageMappers[errorKey](error) : [];
    });
  } else if (errors) {
    // This is the current format returned by Stripe payment failure errors.
    // Unfortunately it does not match the standard error format yet.
    let errorMessages = [];
    const mapper = messageMappers[errors.type];
    if (mapper) {
      errorMessages = mapper(errors);
    }
    return errorMessages;
  } else {
    return [];
  }
};

/**
 * Starting point for handling API error messages.
 * From here we can translate to user friendly messages, raise actions, redirect etc. Whatever is
 * required to correctly inform the user of the issue.
 *
 * @param body response body from the API
 */
const handleError = ({ error: body }) => {
  if (body.status > 499) {
    // Server error, to be addressed during UI error update ticket
    return [];
  } else if (body.status === 403) {
    // Role/Current authentication does not permit this access.
    // To be address during UI error update ticket
    return [];
  } else if (body.status === 400) {
    // Client/User error. Map to friendly user message.
    return parseClientErrorMessages({ errors: body.error.errors });
  }
};

export default handleError;
