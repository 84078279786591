import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import { getFilters } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";
import {
  handleUnapproveSearch,
  handleUnapprove
} from "./approvedApiReducersHandlers";

const sizeInitState = {
  pages: {}
};

export const initState = {
  sizes: {
    all: sizeInitState
    /* other sizes will populate this list, on demand as we don't know
     *  what sizes the user has */
  },
  search: {
    isSearching: false,
    results: {}
  },
  pageSize: 50
};

const approvedApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.SEARCH_TEAM_DESIGNS_APPROVED_REQUEST: {
      const termKey = `${action.request.params.term}-${action.request.params
        .size || "all"}`;

      const getPreviousPages = () => {
        if (state.search[termKey]) {
          return state.search.results[termKey].pages;
        } else {
          return {};
        }
      };

      return {
        ...state,
        search: {
          ...state.search,
          isSearching: true,
          results: {
            ...state.search.results,
            [termKey]: {
              pages: {
                ...getPreviousPages(),
                [action.request.page]: {
                  ids: null
                }
              }
            }
          }
        }
      };
    }

    case designsEntitiesTypes.SEARCH_TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS: {
      const { ids } = action.response;

      const termKey = `${action.request.params.term}-${action.request.params
        .size || "all"}`;

      const getPreviousPages = () => {
        if (state.search[termKey]) {
          return state.search.results[termKey].pages;
        } else {
          return {};
        }
      };

      return {
        ...state,
        search: {
          ...state.search,
          isSearching: true,
          results: {
            ...state.search.results,
            [termKey]: {
              pages: {
                ...getPreviousPages(),
                [action.request.page]: {
                  ids: ids || []
                }
              }
            }
          }
        }
      };
    }

    case designsEntitiesTypes.DESIGN_UNAPPROVE_REQUEST: {
      const filters = getFilters(window.location.search);

      if (filters.term) {
        return handleUnapproveSearch({ state, action, filters });
      } else {
        return handleUnapprove({ state, action, filters });
      }
    }

    case designsEntitiesTypes.TEAM_DESIGNS_APPROVED_REQUEST: {
      const size = action.request.params.size || "all";

      const sizeState = state.sizes[size] || sizeInitState;

      const newSizeState = handleDesignRequest({ state: sizeState, action });

      return {
        ...state,
        sizes: {
          ...state.sizes,
          [size]: newSizeState
        }
      };
    }

    case designsEntitiesTypes.TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS: {
      const size = action.request.params.size || "all";

      const sizeState = state.sizes[size] || sizeInitState;

      const newSizeState = handleDesignRequestSuccess({
        state: sizeState,
        action
      });

      return {
        ...state,
        sizes: {
          ...state.sizes,
          [size]: newSizeState
        }
      };
    }

    // when approving a design add it to the teams approved designs
    case designsEntitiesTypes.DESIGN_APPROVE_REQUEST_SUCCESS: {
      const {
        request: {
          body: { designId }
        }
      } = action;

      let newIds = [designId].concat(
        state.sizes.all.pages[1] ? state.sizes.all.pages[1].ids : []
      );

      const updatedPage = {
        1: {
          isFetching: false,
          ids: newIds,
          didInvalidate: false
        }
      };

      return immutableUpdate(state, {
        sizes: {
          all: {
            pages: {
              $auto: {
                $merge: {
                  ...updatedPage
                }
              }
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default approvedApiReducers;
