export const handleTableRowHover = ({ monitor, props }) => {
  const dragIndex = monitor.getItem().index;
  const hoverIndex = props.index;

  // Don't replace items with themselves
  if (dragIndex === hoverIndex) {
    return;
  }

  props.onRowMove({ dragIndex, hoverIndex });

  // Note: we're mutating the monitor item here!
  // Generally it's better to avoid mutations,
  // but it's good here for the sake of performance
  // to avoid expensive index searches.

  monitor.getItem().index = hoverIndex;
};

export const handleTableHover = ({ monitor, props }) => {
  const hoverIndex = props.index;

  props.onTableOver({ hoverIndex, table: monitor.getItem().tableElement });
};
