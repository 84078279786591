import * as types from "state/entities/userTeamAnimationsFolders/userTeamAnimationsFoldersTypes";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";
import { findPage } from "../helpers";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50
};

const userTeamAnimationsFoldersApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_USER_TEAM_ANIMATION_FOLDERS_REQUEST: {
      return handleDesignRequest({ state, action });
    }

    case types.FETCH_USER_TEAM_ANIMATION_FOLDERS_REQUEST_SUCCESS: {
      return handleDesignRequestSuccess({ state, action });
    }

    case types.CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST: {
      const pageNumber = Object.keys(state.pages).map(id => state.pages[id])
        .length;
      const updatedPages = Object.assign({}, state.pages);

      const updatedPage = updatedPages[pageNumber];

      updatedPage.isFetching = true;

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageNumber]: {
            ...updatedPage
          }
        }
      };
    }

    case types.CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS: {
      const { ids: newId } = action.response;
      const pageNumber = Object.keys(state.pages).map(id => state.pages[id])
        .length;
      const updatedPages = Object.assign({}, state.pages);
      const updatedPage = updatedPages[pageNumber];

      updatedPage.ids = updatedPage.ids.concat(newId);
      updatedPage.isFetching = false;

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageNumber]: {
            ...updatedPage
          }
        }
      };
    }

    case types.DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS: {
      const {
        extra: { folderId }
      } = action;

      const pages = state.pages;

      const pageId = findPage(pages, folderId);

      const updatedPage = Object.assign({}, pages[pageId], {
        ids: pages[pageId].ids.filter(id => id !== folderId)
      });

      const updatedPages = Object.assign({}, pages, { [pageId]: updatedPage });

      return Object.assign({}, state, { pages: updatedPages });
    }

    default:
      return state;
  }
};

export default userTeamAnimationsFoldersApiReducers;
