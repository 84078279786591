import { fetchCreditsApiCall } from "state/api/credits/creditsApiActions";
import { creditsApiSelector } from "state/api/credits/creditsApiSelectors";
import { reduxStoreExpiry } from "lib";

const shouldFetchCredits = state => {
  const creditsState = creditsApiSelector(state);

  if (!creditsState) return true;

  if (creditsState.isFetching) return false;

  const isExpired = reduxStoreExpiry.isDataExpired(creditsState.lastFetched, 5);

  return isExpired;
};

export const fetchCreditsIfNeeded = ({ onSuccess } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  if (shouldFetchCredits(state)) {
    dispatch(fetchCreditsApiCall({ onSuccess }));
  }
};
