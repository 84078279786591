import { Logger } from "lib";
import { getCSSComputedBoundingClientRect } from "lib/htmlElements/dimensions";
import { getHtmlNodeCenter } from "lib/htmlElements/dimensions";
import { rotatePoint } from "lib/geometry/rotation";
import { calculateScalingFactor } from "lib/scalingTools/scalingTools";

export const calculateNewDimensions = ({
  dragItemType,
  gridElement,
  gridCell,
  differenceFromInitialOffset,
  gridCellDivHeight,
  gridCellDivWidth,
  zoom,
  dragItem,
  pageOffset,
  gridCellOffsetTop,
  gridCellOffsetLeft
}) => {
  switch (dragItemType) {
    case "GRID_CELL_IMAGE":
      return calculateMovedNewDimensions({
        gridElement,
        gridCell,
        differenceFromInitialOffset,
        gridCellDivHeight,
        gridCellDivWidth,
        zoom
      });

    case "GRID_CELL_CROP_RESIZE_HANDLER":
      return calculateScaledNewDimensions({
        gridElement,
        gridCell,
        differenceFromInitialOffset,
        gridCellDivHeight,
        gridCellDivWidth,
        zoom,
        dragItem,
        pageOffset,
        gridCellOffsetTop,
        gridCellOffsetLeft
      });

    default:
      Logger.error("GridCell Cropping Preview DragType Handler");
  }
};

export const calculateMovedNewDimensions = ({
  gridElement,
  gridCell,
  differenceFromInitialOffset,
  gridCellDivHeight,
  gridCellDivWidth,
  zoom
}) => {
  const diffRotated = rotatePoint(
    differenceFromInitialOffset.x,
    differenceFromInitialOffset.y,
    0,
    0,
    -gridElement.angle
  );

  const gridCellHeight = gridCell.height;
  const gridCellWidth = gridCell.width;

  const gridCellLeft = gridCell.left;
  const gridCellTop = gridCell.top;

  const newGridCellLeft = calculateGridCellMaskLeft({
    horizontalDiff:
      (diffRotated.x / zoom) * gridCell.scaleX * gridElement.scaleX,
    gridCell,
    elementWidth: gridCellDivWidth
  });

  const newGridCellTop = calculateGridCellMaskTop({
    verticalDiff: (diffRotated.y / zoom) * gridCell.scaleY * gridElement.scaleY,
    gridCell,
    elementHeight: gridCellDivHeight
  });

  const gridCellNewDimensions = {
    height: gridCellHeight,
    width: gridCellWidth,
    left: newGridCellLeft,
    top: newGridCellTop,
    right: newGridCellLeft + gridCellWidth,
    bottom: newGridCellTop + gridCellHeight,
    leftDiff: newGridCellLeft - gridCellLeft,
    topDiff: newGridCellTop - gridCellTop,
    scale: 1
  };

  return gridCellNewDimensions;
};

const calculateGridCellMaskTop = ({
  verticalDiff,
  gridCell,
  elementHeight
}) => {
  let imageMaskTop = gridCell.top + verticalDiff;

  const imageMaskTopMinValue = elementHeight - gridCell.height;

  if (imageMaskTop < imageMaskTopMinValue) {
    imageMaskTop = imageMaskTopMinValue;
  }

  if (imageMaskTop > 0) {
    imageMaskTop = 0;
  }

  return imageMaskTop;
};

const calculateGridCellMaskLeft = ({
  horizontalDiff,
  gridCell,
  elementWidth
}) => {
  let imageMaskLeft = gridCell.left + horizontalDiff;

  const imageMaskLeftMinValue = elementWidth - gridCell.width;

  if (imageMaskLeft < imageMaskLeftMinValue) {
    imageMaskLeft = imageMaskLeftMinValue;
  }

  if (imageMaskLeft > 0) {
    imageMaskLeft = 0;
  }

  return imageMaskLeft;
};

export const getDesignRelativeDimensions = ({
  gridElement,
  pageOffset,
  gridCell,
  zoom,
  dragItem,
  gridCellDivHeight,
  gridCellDivWidth,
  gridCellOffsetTop,
  gridCellOffsetLeft
}) => {
  const elementLeftZoomed = gridElement.left * zoom; // window
  const elementTopZoomed = gridElement.top * zoom; // window
  const elementWidthZoomed = gridElement.width * zoom;
  const elementHeightZoomed = gridElement.height * zoom;

  // gridCell bounding rect in window coordinates
  const gridCellBoundingClientRect = {
    x: pageOffset.left + elementLeftZoomed + gridCellOffsetLeft,
    y: pageOffset.top + elementTopZoomed + gridCellOffsetTop
  };

  const gridCellWidth = gridCellDivWidth;
  const gridCellHeight = gridCellDivHeight;

  // image dimensions
  const imageHeight = gridCell.height; // design
  const imageWidth = gridCell.width; // design
  const imageLeft = gridCell.left;
  const imageTop = gridCell.top;

  // define the center of the element in screen coordinates for initial anchor rotation
  const elementCenter = {
    x: pageOffset.left + elementLeftZoomed + elementWidthZoomed / 2,
    y: pageOffset.top + elementTopZoomed + elementHeightZoomed / 2
  };

  // unrotate the anchor around the element
  const unRotatedAnchorPosition = rotatePoint(
    dragItem.anchorPoint.x,
    dragItem.anchorPoint.y,
    elementCenter.x,
    elementCenter.y,
    -parseInt(gridElement.angle)
  );

  // offset the anchor so that it is in design coordinates
  const anchorPoint = {
    x: (unRotatedAnchorPosition.x - gridCellBoundingClientRect.x) / zoom,
    y: (unRotatedAnchorPosition.y - gridCellBoundingClientRect.y) / zoom
  };

  return {
    imageHeight,
    imageWidth,
    imageLeft,
    imageTop,
    anchorPoint,
    gridCellWidth,
    gridCellHeight
  };
};

/*
  NOTE: this function does not consider image flipping,
  the drag handler needs to handle this before passing in.
*/
export const calculateScaledNewDimensions = ({
  gridElement,
  gridCell,
  differenceFromInitialOffset,
  gridCellDivHeight,
  gridCellDivWidth,
  zoom,
  dragItem,
  pageOffset,
  gridCellOffsetTop,
  gridCellOffsetLeft
}) => {
  const {
    handlerInitialPosition,
    scaleAnchorPoint,
    selectionBoxNodeCenter
  } = dragItem;

  const {
    imageHeight,
    imageWidth,
    imageLeft,
    imageTop,
    anchorPoint,
    gridCellHeight,
    gridCellWidth
  } = getDesignRelativeDimensions({
    gridElement,
    pageOffset,
    gridCell,
    dragItem,
    gridCellOffsetLeft,
    gridCellOffsetTop,
    zoom,
    selectionBoxNodeCenter,
    differenceFromInitialOffset,
    gridCellDivHeight, // window
    gridCellDivWidth // window
  });

  // calculate the scale change
  const scale = calculateScale({
    // use the scaleAnchor here as it will not be flipped
    anchorPoint: scaleAnchorPoint,
    differenceFromInitialOffset,
    handlerInitialPosition,
    gridCell,
    gridCellDivWidth,
    gridCellDivHeight
  });

  const newImageHeight = imageHeight * scale; // element
  const newImageWidth = imageWidth * scale; // element

  // calculate the new left and top values
  const newImageLeft = anchorPoint.x + (imageLeft - anchorPoint.x) * scale;
  const newImageTop = anchorPoint.y + (imageTop - anchorPoint.y) * scale;

  // should all be converted back to design space
  const imageNewDimensions = {
    height: newImageHeight,
    width: newImageWidth,
    left: newImageLeft,
    top: newImageTop,
    right: newImageLeft + newImageWidth,
    bottom: newImageTop + newImageHeight
  };

  if (imageNewDimensions.top > 0) {
    imageNewDimensions.top = 0;
  }
  if (imageNewDimensions.bottom < gridCellHeight) {
    imageNewDimensions.top += gridCellHeight - imageNewDimensions.bottom;
  }
  if (imageNewDimensions.left > 0) {
    imageNewDimensions.left = 0;
  }
  if (imageNewDimensions.right < gridCellWidth) {
    imageNewDimensions.left += gridCellWidth - imageNewDimensions.right;
  }

  return imageNewDimensions;
};

const calculateScale = ({
  anchorPoint,
  differenceFromInitialOffset,
  handlerInitialPosition,
  gridCell,
  gridCellDivWidth,
  gridCellDivHeight
}) => {
  const scaleFactorFromMouseMovement = calculateScalingFactor({
    anchorPoint,
    handlerInitialPosition,
    differenceFromInitialPosition: differenceFromInitialOffset
  });
  // ensure that we scale proportionately
  const scale = Math.max(
    gridCellDivWidth / gridCell.width,
    gridCellDivHeight / gridCell.height,
    scaleFactorFromMouseMovement
  );

  return scale;
};

export const calculateUnRotatedCell = ({
  gridElement,
  zoom,
  gridCellHtmlElement,
  gridHtmlElement
}) => {
  const cellClientRect = getCSSComputedBoundingClientRect({
    element: gridCellHtmlElement,
    cssValueMultiplier: zoom
  });

  const cellWindowHeight = cellClientRect.height;
  const cellWindowWidth = cellClientRect.width;

  const cellCenter = getHtmlNodeCenter(gridCellHtmlElement);

  // note that the gridHtmlElement has no width or height so we need to use first child
  const gridCenter = getHtmlNodeCenter(gridHtmlElement.firstChild);

  // rotate the cell center backwards from element center
  const cellCenterUnrotated = rotatePoint(
    cellCenter.x,
    cellCenter.y,
    gridCenter.x,
    gridCenter.y,
    -gridElement.angle
  );

  // calculate the top for cell as center minus half height
  const cellTopUnrotated = cellCenterUnrotated.y - cellWindowHeight / 2;

  // calculate the top for cell as center minus half width
  const cellLeftUnrotated = cellCenterUnrotated.x - cellWindowWidth / 2;

  const gridTopUnrotated = gridCenter.y - (gridElement.height * zoom) / 2;
  const gridLeftUnrotated = gridCenter.x - (gridElement.width * zoom) / 2;

  // calculate the difference between the cell and element positions
  const cellOffsetTop = cellTopUnrotated - gridTopUnrotated;
  const cellOffsetLeft = cellLeftUnrotated - gridLeftUnrotated;

  return {
    gridCellOffsetTop: cellOffsetTop,
    gridCellOffsetLeft: cellOffsetLeft,
    gridCellWindowHeight: cellWindowHeight,
    gridCellWindowWidth: cellWindowWidth
  };
};
