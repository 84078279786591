import {
  UI_OPEN_CONFIRMATION_MODAL,
  UI_CLOSE_CONFIRMATION_MODAL
} from "./confirmationModalTypes";

export const openConfirmationModal = ({
  header,
  text,
  onOk,
  onCancel,
  modalClassName,
  buttonsDetails
}) => {
  return {
    type: UI_OPEN_CONFIRMATION_MODAL,
    header,
    text,
    onOk,
    onCancel,
    modalClassName,
    buttonsDetails
  };
};

export const closeConfirmationModal = () => {
  return {
    type: UI_CLOSE_CONFIRMATION_MODAL
  };
};
