import React from "react";
import { uuid } from "lib";
import { rotatePoint } from "lib/geometry/rotation";
import { Knockout, Offset, Fade } from "./FilterTemplates";
import { getBrowserClientName } from "lib/getBrowserClientName";
import { BROWSER_NAMES } from "lib/constants";
import { getPlaceholderSize } from "views/components/Editor/elements/grid/imageInstructionUtils";
import { getFrameDimensions } from "views/components/Editor/elements/vector/vectorUtils";
import ImageInstruction from "views/components/Editor/elements/ImageInstruction/ImageInstruction";

export class OutlineFilter extends React.Component {
  constructor(props) {
    super(props);

    this.getElementDiv = this.getElementDiv.bind(this);
  }

  getElementDiv({
    filterId,
    outlinePadding = 0,
    outlineOffset = 0,
    outlineSmoothing = 0
  }) {
    const { leftMaskOffset = 0, topMaskOffset = 0 } = this.props;
    const scale = this.props.instructionScale || this.props.elementData.scale;

    const adjustedOutlinePadding =
      parseInt(outlinePadding) +
      parseInt(outlineOffset) +
      parseInt(outlineSmoothing);

    switch (this.props.elementData.type) {
      case "image": {
        const { elementStyle, imageMaskStyle, imageUrl } = this.props;
        return (
          <div
            style={{
              position: "absolute",
              overflow: "hidden",
              filter: `url(#${filterId})`,
              width: `calc(100% + ${2 * adjustedOutlinePadding}px)`,
              height: `calc(100% + ${2 * adjustedOutlinePadding}px)`,
              marginTop: `-${adjustedOutlinePadding}px`,
              marginLeft: `-${adjustedOutlinePadding}px`
            }}
          >
            <img
              alt=""
              className={elementStyle}
              src={imageUrl}
              style={{
                ...imageMaskStyle,
                marginTop: `${adjustedOutlinePadding}px`,
                marginLeft: `${adjustedOutlinePadding}px`
              }}
            />
          </div>
        );
      }
      case "vector": {
        const { svg } = this.props;

        if (!svg) return;

        return (
          <div
            style={{
              position: "absolute",
              overflow: "hidden",
              filter: `url(#${filterId})`,
              width: `calc(${this.props.elementData.width}px + ${2 *
                adjustedOutlinePadding}px)`,
              height: `calc(${this.props.elementData.height}px + ${2 *
                adjustedOutlinePadding}px)`,
              marginTop: `-${adjustedOutlinePadding}px`,
              marginLeft: `-${adjustedOutlinePadding}px`
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: svg.outerHTML }}
              style={{
                position: "absolute",
                marginTop: `${adjustedOutlinePadding + topMaskOffset}px`,
                marginLeft: `${adjustedOutlinePadding + leftMaskOffset}px`
              }}
            />
            {this.props.imageInstructions.map(imageInstruction => {
              const placeholder = getPlaceholderSize(
                imageInstruction,
                this.props.elementData
              );
              const frameDimensions = getFrameDimensions({
                vectorId: this.props.elementData.uniqueId,
                frameId: imageInstruction.domId,
                vectorElement: this.props.elementData,
                zoom: this.props.zoom
              });
              if (!frameDimensions) return null;
              return (
                <ImageInstruction
                  imageDoesExist={imageInstruction.type === "image"}
                  restrictionsForDocument={[""]}
                  restrictionsForElement={[]}
                  domId={imageInstruction.domId}
                  height={frameDimensions.height / this.props.zoom}
                  key={imageInstruction.domId}
                  left={placeholder.left * scale}
                  scale={scale}
                  top={placeholder.top * scale}
                  width={frameDimensions.width / this.props.zoom}
                  zoom={this.props.zoom}
                  imageInstruction={{ ...imageInstruction, opacity: 1 }}
                  element={this.props.elementData}
                  customStyles={{
                    marginTop: `${adjustedOutlinePadding}px`,
                    marginLeft: `${adjustedOutlinePadding}px`
                  }}
                  isDropzoneHidden={true}
                  isForcedFullOpacity={true}
                  leftMaskOffset={leftMaskOffset}
                  topMaskOffset={topMaskOffset}
                />
              );
            })}
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }

  render() {
    const {
      elementData: {
        uniqueId,
        width,
        height,
        srcHeight,
        srcWidth,
        outline = {}
      },
      isModifying
    } = this.props;

    const elementScaleFactor =
      Math.max(height / srcHeight, width / srcWidth) * 0.5;

    const outlineStrokeFade = (outline.fade || 0) * elementScaleFactor;
    let outlineStrokeWidth = (outline.width || 0) * elementScaleFactor;
    const outlinePadding = Math.max(outlineStrokeWidth, outlineStrokeFade) * 4;

    const outlineSmoothing = parseInt(outline.smoothness || 0);

    if (outlineStrokeFade) {
      outlineStrokeWidth = Math.max(outlineStrokeWidth - outlineStrokeFade, 0);
    }

    const outlineOffset = parseInt(outline.offset || 0);
    const outlineAngle = outline.angle || 0;

    const computedOutlineOffset = rotatePoint(
      0,
      outlineOffset,
      0,
      0,
      outlineAngle
    );

    const outlineStrokeColor = outline.color || "#000000";
    const isKnockout = outline.knockout;
    const isFill = outline.fill;

    const filterId = `feId-${uniqueId}${isModifying ? "-modifying" : ""}`;
    const filterRectId = `feId-rect-${uniqueId}${
      isModifying ? "-modifying" : ""
    }${uuid()}`;

    const filterSvgStyles = {
      width: `calc(100% + ${2 * outlinePadding}px)`,
      height: `calc(100% + ${2 * outlinePadding}px)`,
      position: "absolute",
      marginTop: `-${outlinePadding}px`,
      marginLeft: `-${outlinePadding}px`,
      left: "0px",
      shapeRendering: "geometricPrecision",
      strokeLinejoin: "round"
      // VV Debugging border enable to see where the filter border is VV
      // border: "5px dotted red"
    };

    // define the dimensions for the viewport
    const viewportWidth =
      width + 2 * outlinePadding + 2 * outlineOffset + 2 * outlineSmoothing;
    const viewportHeight =
      height + 2 * outlinePadding + 2 * outlineOffset + 2 * outlineSmoothing;

    const browserClientName = getBrowserClientName();

    return (
      <>
        <svg
          viewBox={`${0} ${0} ${viewportWidth} ${viewportHeight}`}
          style={filterSvgStyles}
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin"
        >
          <defs>
            <rect
              width={width}
              height={height}
              id={filterRectId}
              fill="#FF0000"
            />
            <filter
              id={filterId}
              colorInterpolationFilters="sRGB"
              width={viewportWidth}
              height={viewportHeight}
              filterUnits="objectBoundingBox"
            >
              {/* get the cropping rect we added to defs and pull the red and alpha channels from it as an alpha mask */}
              {// safari needs different source to support this
              browserClientName === BROWSER_NAMES.SAFARI ? (
                <feImage
                  x={outlinePadding + outlineOffset + outlineSmoothing}
                  y={outlinePadding + outlineOffset + outlineSmoothing}
                  preserveAspectRatio="none"
                  xlinkHref={`#${filterRectId}`}
                  result="cropZone"
                />
              ) : (
                <feImage
                  x={outlinePadding + outlineOffset + outlineSmoothing}
                  y={outlinePadding + outlineOffset + outlineSmoothing}
                  width={width}
                  height={height}
                  preserveAspectRatio="none"
                  xlinkHref={`/rectBase/redSquare.png`}
                  result="cropZone"
                />
              )}

              <feColorMatrix
                in="cropZone"
                type="matrix"
                result="crop-alpha-mask"
                values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0"
              />

              {/* crop the image down to its cropping zone */}
              <feComposite
                in2="crop-alpha-mask"
                in="SourceGraphic"
                operator="in"
                result="croppedImage"
              />

              {/* double the alpha channel so we can minimise semi-transparent pixel space */}
              <feColorMatrix
                in="croppedImage"
                type="matrix"
                result="alpha-mask"
                values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0"
              />

              {/* Create a blank colored rectangle */}
              <feFlood
                in="SourceGraphic"
                floodColor={outlineStrokeColor}
                color={outlineStrokeColor}
                result="color-flood"
              />

              {/* Mask the blank color fill with the alpha mask we created earlier */}
              <feComposite
                in="color-flood"
                in2="alpha-mask"
                operator="in"
                result="masked-white"
              />

              {/* Morphology filter to "dilate" (grow) the colored areas */}
              <feMorphology
                operator="dilate"
                radius={outlineStrokeWidth}
                result={"dilated-result"}
              />

              {/* CREATE SMOOTHING EFFECT */}
              {/* Apply a dilate for 1.1 the value */}
              <feMorphology
                in="dilated-result"
                operator="dilate"
                radius={outlineStrokeWidth * 1.1}
                result="smoothing-dilation"
              />

              {/* blur the extra dilated section */}
              <feGaussianBlur
                in="smoothing-dilation"
                stdDeviation={outlineSmoothing}
                result="blur"
              />

              {/* increase the alpha channel */}
              <feColorMatrix
                in="blur"
                type="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                result="smoothed-dilated-result"
              />

              {/* blend the expected dilate section with the new smoothing section */}
              <feBlend in="dilated-result" in2="smoothed-dilated-result" />

              <Fade fade={outlineStrokeFade} isDisabled={!outlineStrokeFade} />

              <Knockout cutterName="alpha-mask" isDisabled={isFill} />

              {/* offset the outline */}
              <Offset
                offset={computedOutlineOffset}
                isDisabled={!outlineOffset || outlineOffset === 0}
              />
              {/* Perform the knockout again on the offset to get a cut out */}

              <Knockout
                cutterName="alpha-mask"
                isDisabled={
                  !outlineOffset || outlineOffset === 0 || !isKnockout
                }
              />

              {/* <feDropShadow dx={0} dy={10} stdDeviation="0.5" /> */}
            </filter>
          </defs>
        </svg>
        {this.getElementDiv({
          filterId,
          outlinePadding,
          outlineOffset,
          outlineSmoothing
        })}
      </>
    );
  }
}

export default OutlineFilter;
