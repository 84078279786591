import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TextDecorationIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 19 17"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0-2h20v20H0z" />
      <path
        fill={color || easilGrayXDark}
        d="M8.8 1.8v12.6h1.368a.832.832 0 0 1 0 1.663H5.832a.832.832 0 0 1 0-1.663H7V1.8H1.8v1.3a.9.9 0 0 1-1.8 0V.9A.9.9 0 0 1 .9 0h14.2a.9.9 0 0 1 .9.9v2.2a.9.9 0 1 1-1.8 0V1.8H8.8z"
      />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M15.9 9h1.2a.9.9 0 1 1 0 1.8h-1.2V13a1.1 1.1 0 0 0 1.1 1.1h1a.9.9 0 1 1 0 1.8h-1a2.9 2.9 0 0 1-2.9-2.9v-2.2h-1.2a.9.9 0 1 1 0-1.8h1.2V7a.9.9 0 1 1 1.8 0v2z"
      />
    </g>
  </svg>
);
TextDecorationIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TextDecorationIcon;
