export const PURCHASED_CATALOGUE_SIZES_REQUEST =
  "API/ENTITIES/PURCHASED_CATALOGUE_SIZES_REQUEST";
export const PURCHASED_CATALOGUE_SIZES_REQUEST_SUCCESS =
  "API/ENTITIES/PURCHASED_CATALOGUE_SIZES_REQUEST_SUCCESS";
export const PURCHASED_CATALOGUE_SIZES_REQUEST_FAILURE =
  "API/ENTITIES/PURCHASED_CATALOGUE_SIZES_REQUEST_FAILURE";
export const PURCHASED_CATALOGUE_SIZES_FILTER_REQUEST =
  "API/ENTITIES/PURCHASED_CATALOGUE_SIZES_FILTER_REQUEST";
export const PURCHASED_CATALOGUE_SIZES_FILTER_REQUEST_SUCCESS =
  "API/ENTITIES/PURCHASED_CATALOGUE_SIZES_FILTER_REQUEST_SUCCESS";
export const PURCHASED_CATALOGUE_SIZES_FILTER_REQUEST_FAILURE =
  "API/ENTITIES/PURCHASED_CATALOGUE_SIZES_FILTER_REQUEST_FAILURE";

export const PURCHASED_CATALOGUE_SIZES_FOLDER_REQUEST =
  "API/ENTITIES/PURCHASED_CATALOGUE_SIZES_FOLDERS_REQUEST";
export const PURCHASED_CATALOGUE_SIZES_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/PURCHASED_CATALOGUE_SIZES_FOLDERS_REQUEST_SUCCESS";
export const PURCHASED_CATALOGUE_SIZES_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/PURCHASED_CATALOGUE_SIZES_FOLDERS_REQUEST_FAILURE";
