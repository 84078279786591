import React from "react";
import PrintItem from "./PrintItem";
import style from "./style.module.css";
import { getDefaultPrintOption } from "views/containers/cartContainer/CartPreviewSection/printItemUtils";
import { Button, Loading } from "views/components";
import PlusIcon from "views/components/icons/PlusIcon";
import InfoAlert from "views/components/InfoAlert";
import { noop } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";

const handleCreatePrintItemClick = ({
  handleCreatePrintItem,
  filteredPrintPricing
}) => {
  const defaultPrintOption = getDefaultPrintOption({
    printPricing: filteredPrintPricing.filter(option => option.minQuantity <= 1)
  });

  return handleCreatePrintItem({
    printProviderPrintOptionPricingId: defaultPrintOption.id,
    quantity: 1
  });
};

export const PrintItems = ({
  printItems = [],
  printPricing = [],
  handleUpdatePrintItem,
  handleCreatePrintItem,
  handleDeletePrintItem,
  handleRemoveDesignFromOrder,
  isUpdating,
  isPrintableOption,
  isLoading,
  selectPrintItem,
  activePrintItemId,
  selectActiveLabel,
  activeLabel
}) => {
  if (isLoading) {
    return (
      <div className={style.loading}>
        <Loading />
      </div>
    );
  }
  const usedPrintOptionIds = [];
  const selectedPrintOptions = {};
  let droppedPrintOptionsByPrintOptionId = {};

  printItems.forEach(printItem => {
    const selectedPrintOption =
      printPricing.find(
        option => option.id === printItem.printProviderPrintOptionPricingId
      ) || {};
    // add the printOptionId to list of user options
    usedPrintOptionIds.push(selectedPrintOption.printOptionId);
    selectedPrintOptions[printItem.id] = selectedPrintOption;
  });
  // filter out any print pricing options that are already being used by printItems
  const filteredPrintPricing = printPricing.filter(printPricingOption => {
    const isAvailable = !usedPrintOptionIds.includes(
      printPricingOption.printOptionId
    );
    if (!isAvailable) {
      // add to dropped
      droppedPrintOptionsByPrintOptionId = immutableUpdate(
        droppedPrintOptionsByPrintOptionId,
        {
          [printPricingOption.printOptionId]: {
            $autoArray: {
              $push: [printPricingOption]
            }
          }
        }
      );
    }
    return isAvailable;
  });

  if (!isPrintableOption) {
    return (
      <InfoAlert
        className={style.noPrintWarning}
        theme="yellow"
        iconSize="20px"
      >
        <span>
          This is a Digital Design and can be downloaded for free from the
          editor. Remove this design to continue with the order.
          <br />
          <strong onClick={handleRemoveDesignFromOrder}>Remove Design</strong>
        </span>
      </InfoAlert>
    );
  }

  return (
    <div className={style.printItemsWrapper}>
      {printItems.map(printItem => {
        const selectedPrintOption = selectedPrintOptions[printItem.id];
        const droppedOptions =
          droppedPrintOptionsByPrintOptionId[
            selectedPrintOption.printOptionId
          ] || [];
        return (
          <PrintItem
            printItem={printItem}
            printPricing={[...filteredPrintPricing, ...droppedOptions]}
            selectedPrintOption={selectedPrintOptions[printItem.id]}
            key={printItem.id}
            handleUpdatePrintItem={handleUpdatePrintItem}
            handleDeletePrintItem={() =>
              handleDeletePrintItem({ printItemId: printItem.id })
            }
            onClick={() => selectPrintItem(printItem.id)}
            isActive={activePrintItemId === printItem.id}
            selectActiveLabel={optionLabel =>
              selectActiveLabel({ optionLabel, printItemId: printItem.id })
            }
            activeLabel={activeLabel}
          />
        );
      })}
      {filteredPrintPricing.filter(option => option.minQuantity <= 1).length >
        0 && (
        <Button
          className={style.addButton}
          onClick={
            isUpdating
              ? noop
              : () =>
                  handleCreatePrintItemClick({
                    handleCreatePrintItem,
                    filteredPrintPricing
                  })
          }
          theme="grayBackgroundXDarkGrayFont"
        >
          {isUpdating ? (
            <Loading hexColor="#000000" size="20px" />
          ) : printItems && printItems.length > 0 ? (
            <>
              <PlusIcon size="14px" />
              Add another size
            </>
          ) : (
            <>Select your Print Options</>
          )}
        </Button>
      )}
    </div>
  );
};

export default PrintItems;
