import React from "react";
import style from "./style.module.css";
import { Modal } from "views/components";
import TickCircledIcon from "views/components/icons/TickCircledIcon";
import PlanConfig from "./PlanConfig";
import Subscriptions from "lib/subscriptions";

const UpgradeModal = ({
  isOpen,
  onCloseModal,
  redirectToSubscription,
  subscriptionCode
}) => {
  const subscription = Subscriptions.get(subscriptionCode);
  const isEdge = subscription.getCode() === Subscriptions.EDGE.code;
  const modalData = isEdge ? PlanConfig.EDGE : PlanConfig.PLUS;

  return (
    <Modal
      className={style.modal}
      isOpen={isOpen}
      contentLabel="Upgrade to Plan"
      onRequestClose={onCloseModal}
      header={modalData.header}
      headerTitleClassName={style.header}
      buttons={[
        {
          name: "Upgrade Now",
          onClick: redirectToSubscription
        }
      ]}
      buttonsClassName={style.buttons}
      style={{ content: { backgroundImage: `url("${modalData.imageUrl}")` } }}
    >
      <div className={style.container}>
        <p className={style.intro}>{modalData.intro}</p>
        <ul>
          {modalData.points.map(point => (
            <li key={point}>
              <TickCircledIcon color="#11b992" size="20px" />
              {point}
            </li>
          ))}
        </ul>
        <p>
          <b>Upgrade now to access all these amazing features, and more!</b>
        </p>
      </div>
    </Modal>
  );
};

export default UpgradeModal;
