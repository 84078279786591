import React, { Component } from "react";
import SmoothPencilIcon from "views/components/icons/SmoothPencilIcon";
import ExitStandardisedIcon from "views/components/icons/ExitStandardisedIcon";
import CrossedCircleIcon from "views/components/icons/CrossedCircleIcon";
import PopoutItemMenu from "views/components/popout/PopoutItemMenu/PopoutItemMenu";
import Logger from "lib/logger";
import style from "./style.module.css";
import { PlusIcon } from "../icons";

export default class UpdateTeamLogoButton extends Component {
  constructor(props) {
    super(props);
    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getTeamLogoIcon = this.getTeamLogoIcon.bind(this);
    this.getLogoMenuActions = this.getLogoMenuActions.bind(this);
    this.handleRemoveTeamLogo = this.handleRemoveTeamLogo.bind(this);
    this.handleAddTeamLogo = this.handleAddTeamLogo.bind(this);
    this.handleTeamLogoSelect = this.handleTeamLogoSelect.bind(this);
    this.handleTeamLogoClick = this.handleTeamLogoClick.bind(this);
    this.processImage = this.processImage.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.inputRef = React.createRef();

    this.state = {
      open: false,
      acceptedFileTypes: ["png", "jpg", "jpeg"],
      file: null,
      imageError: null,
      imagesUploading: false
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedRoles !== prevProps.selectedRoles) {
      this.setState({ selectedRoles: this.props.selectedRoles });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.isConnected
    ) {
      this.setState({ open: false });
    }
  }

  onDropdownClick() {
    if (!this.props.hasLogo) {
      this.handleTeamLogoClick();
      return;
    }

    this.setState({ open: !this.state.open });
  }

  getTeamLogoIcon() {
    if (this.props.hasLogo) {
      return <SmoothPencilIcon size="16px" />;
    }

    return <PlusIcon size="12px" color="white" />;
  }

  handleRemoveTeamLogo() {
    const { team, updateTeam } = this.props;

    // prevent api call when no logo is present to begin with
    if (team.logoUrl) {
      const teamObject = Object.assign({}, team, { logo: "NULL" });
      updateTeam({ teamId: team.id, team: teamObject });
    }

    this.setState({ open: false });
  }

  handleAddTeamLogo({ file }) {
    const { team, updateTeam } = this.props;

    const teamObject = Object.assign({}, team, { logo: file });
    updateTeam({ teamId: team.id, team: teamObject });

    this.setState({ open: false });
  }

  async handleTeamLogoSelect(event) {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files[0];
    this.setState({
      file,
      imageError: null,
      imagesUploading: true
    });

    /* define function for creating promise containers for all uploads */
    const createImageProcessPromise = () => {
      return new Promise(resolve => {
        this.processImage(file).then(
          result => {
            this.setState({ fileSrc: result });
            this.handleAddTeamLogo({ file, onSuccess: resolve });
          },
          ({ error }) => {
            this.setState({ imageError: error });
          }
        );
      });
    };

    await createImageProcessPromise(file);

    this.setState({ imagesUploading: false });

    // overwrite event value to trigger onChange event in case of same image being selected again
    event.target.value = "";
  }

  handleTeamLogoClick() {
    this.inputRef.current.click();
  }

  processImage(file) {
    const imageFilesTypesAllowed = this.state.acceptedFileTypes;
    const fileExtension = file.name
      .split(".")
      .pop()
      .toLowerCase();

    if (!imageFilesTypesAllowed.includes(fileExtension)) {
      return Promise.reject({
        error: "Images must be in PNG, JPG or SVG format."
      });
    }

    const promise = new Promise((resolve, reject) => {
      const errorCallback = msg => () => {
        Logger.warn(msg);
        return reject({ error: "We were not able to process your image." });
      };

      const validateFileAsImage = fileSrc => {
        const image = new Image();
        image.onerror = errorCallback("Image onload error");
        image.onload = () => {
          Logger.info({ app: "Image processed successfully" });
          /* validate image size here*/
          resolve(fileSrc);
        };
        image.src = fileSrc;
      };

      const reader = new FileReader();
      reader.onerror = errorCallback("File Reader onload error");

      reader.onload = ({ target }) => validateFileAsImage(target.result);
      reader.readAsDataURL(file);
    });

    return promise;
  }

  getLogoMenuActions() {
    return [
      {
        name: "Update Team Logo",
        Icon: () => <ExitStandardisedIcon size="18px" direction="up" />,
        onClick: this.handleTeamLogoClick
      },
      {
        name: "Remove Team Logo",
        Icon: () => <CrossedCircleIcon size="20px" />,
        onClick: this.handleRemoveTeamLogo
      }
    ];
  }

  render() {
    const { open, acceptedFileTypes, imageError } = this.state;
    const TeamLogoIcon = this.getTeamLogoIcon();

    return (
      <React.Fragment>
        <div
          ref={this.setWrapperRef}
          className={style.teamLogoButtonWrapper}
          data-testid="UpdateTeamLogoButton"
          onClick={this.onDropdownClick}
          data-has-logo={this.props.hasLogo}
        >
          {TeamLogoIcon}
          {open && (
            <PopoutItemMenu
              onClose={() => {}}
              className={style.popout}
              actions={this.getLogoMenuActions()}
              menuClassName={style.popoutMenu}
              actionsClassName={style.popoutActions}
            />
          )}
        </div>
        <input
          ref={this.inputRef}
          type="file"
          id={this.state.inputId}
          accept={acceptedFileTypes
            .map(extension => `.${extension}`)
            .join(", ")}
          style={{ display: "none" }}
          onChange={this.handleTeamLogoSelect}
          data-testid="TeamLogoInput"
        />
        {imageError && <div className={style.error}>{imageError}</div>}
      </React.Fragment>
    );
  }
}
