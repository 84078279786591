export const FETCH_PERSONAL_FOLDERS_REQUEST =
  "API/ENTITIES/FOLDERS/PERSONAL_REQUEST";
export const FETCH_PERSONAL_FOLDERS_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/PERSONAL_REQUEST_SUCCESS";
export const FETCH_PERSONAL_FOLDERS_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/PERSONAL_REQUEST_FAILURE";

export const CREATE_PERSONAL_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/CREATE_PERSONAL_REQUEST";
export const CREATE_PERSONAL_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/CREATE_PERSONAL_REQUEST_SUCCESS";
export const CREATE_PERSONAL_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/CREATE_PERSONAL_REQUEST_FAILURE";

export const FETCH_TEAM_FOLDERS_REQUEST = "API/ENTITIES/FOLDERS/TEAM_REQUEST";
export const FETCH_TEAM_FOLDERS_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/TEAM_REQUEST_SUCCESS";
export const FETCH_TEAM_FOLDERS_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/TEAM_REQUEST_FAILURE";

export const CREATE_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/CREATE_TEAM_REQUEST";
export const CREATE_TEAM_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/CREATE_TEAM_REQUEST_SUCCESS";
export const CREATE_TEAM_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/CREATE_TEAM_REQUEST_FAILURE";

export const DELETE_PERSONAL_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/DELETE_PERSONAL_REQUEST";
export const DELETE_PERSONAL_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/DELETE_PERSONAL_REQUEST_SUCCESS";
export const DELETE_PERSONAL_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/DELETE_PERSONAL_REQUEST_FAILURE";

export const DELETE_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/DELETE_TEAM_REQUEST";
export const DELETE_TEAM_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/DELETE_TEAM_REQUEST_SUCCESS";
export const DELETE_TEAM_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/DELETE_TEAM_REQUEST_FAILURE";

export const UPDATE_PERSONAL_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/UPDATE_PERSONAL_REQUEST";
export const UPDATE_PERSONAL_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/UPDATE_PERSONAL_REQUEST_SUCCESS";
export const UPDATE_PERSONAL_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/UPDATE_PERSONAL_REQUEST_FAILURE";

export const UPDATE_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/UPDATE_TEAM_REQUEST";
export const UPDATE_TEAM_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/UPDATE_TEAM_REQUEST_SUCCESS";
export const UPDATE_TEAM_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/UPDATE_TEAM_REQUEST_FAILURE";
