import { createSelector } from "reselect";
import { getPath, isEmpty, isNil } from "lib";
import { SUBTAB_TYPES_MAP } from "views/components/Editor/sidebar/tabs/animations/animationsTabConstants";
import { getParameterByName } from "lib/queryStringUtils";
import { getAssetTypeFromQueryParams } from "views/components/Editor/sidebar/tabs/animations/animationUtils";
import formatSearchResultList from "lib/formatSearchResultList";

const userTeamAnimationsFoldersEntitiesSelector = state =>
  state.entities.userTeamAnimationsFolders;
const userTeamAnimationsFoldersApiSelector = state =>
  state.api.userTeamAnimationsFolders;
const userTeamAnimationsSelector = state =>
  state.api.userTeamAnimations.folders;
const userTeamAnimationsEntitiesSelector = state =>
  state.entities.userTeamAnimations;
const userTeamAnimationsFoldersSearchSelector = state =>
  getPath(state, "ui.myAnimations.personal.folders", {});

const getFolderAnimations = (
  folderStore,
  animationFolderId,
  userTeamAnimations
) => {
  const selectedFolderStore = folderStore[animationFolderId];
  const assetTypes = Object.values(SUBTAB_TYPES_MAP);
  const assets = {};

  if (isEmpty(folderStore)) {
    return null;
  }

  if (isEmpty(selectedFolderStore)) {
    return null;
  }

  assetTypes.forEach(asset => {
    if (
      !selectedFolderStore[asset] ||
      isEmpty(selectedFolderStore[asset].pages) ||
      isNil(selectedFolderStore[asset].pages[1].ids)
    ) {
      assets[asset] = null;
    } else {
      let imagesIds = [];

      Object.values(folderStore[animationFolderId][asset].pages).forEach(
        page => {
          if (page.ids) {
            imagesIds = imagesIds.concat(page.ids);
          }
        }
      );

      const uniqueImagesIds = [...new Set(imagesIds)];

      assets[asset] = uniqueImagesIds
        .filter(imageId => userTeamAnimations[imageId])
        .map(imageId =>
          Object.assign({ canBeDeleted: true }, userTeamAnimations[imageId])
        );
    }
  });

  return assets;
};

const processGetAllAnimationsFolders = (
  animationFolders,
  animationFoldersApi,
  teamAnimationsFolders,
  userTeamAnimations,
  userTeamAnimationsFoldersSearch
) => {
  const pages = animationFoldersApi.pages;

  if (isEmpty(pages) || (pages[1].isFetching && isNil(pages[1].ids))) {
    return [];
  }

  const pageNumbers = Object.keys(pages).sort();

  let allAnimationFoldersIds = [];

  pageNumbers.forEach(
    pageNumber =>
      (allAnimationFoldersIds = allAnimationFoldersIds.concat(
        pages[pageNumber].ids || []
      ))
  );

  return allAnimationFoldersIds.map(animationFolderId => {
    const entity = Object.assign({}, animationFolders[animationFolderId]);

    // using images as property to match selector in ImagesList component
    entity.images = getFolderAnimations(
      teamAnimationsFolders,
      animationFolderId,
      userTeamAnimations
    );

    if (userTeamAnimationsFoldersSearch[animationFolderId]) {
      entity.terms = Object.keys(
        userTeamAnimationsFoldersSearch[animationFolderId].terms
      ).reduce((formattedTerms, currentTerm) => {
        const termState =
          userTeamAnimationsFoldersSearch[animationFolderId].terms[currentTerm];
        const termEntities = {};
        formatSearchResultList(
          Object.values(termState.animations || {})
        ).forEach(searchResultEntity => {
          termEntities[searchResultEntity.mediaId] = searchResultEntity;
        });
        return {
          ...formattedTerms,
          [currentTerm]: {
            ...termState,
            images: termEntities
          }
        };
      }, {});
    }

    return entity;
  });
};

export const getAllAnimationsFolders = createSelector(
  [
    userTeamAnimationsFoldersEntitiesSelector,
    userTeamAnimationsFoldersApiSelector,
    userTeamAnimationsSelector,
    userTeamAnimationsEntitiesSelector,
    userTeamAnimationsFoldersSearchSelector
  ],
  processGetAllAnimationsFolders
);

export const getAnimationsFoldersAll = state => {
  const animationsFolders = getAllAnimationsFolders(state);
  return animationsFolders.map(imageFolder => ({
    ...imageFolder,
    images: imageFolder.images?.all || null
  }));
};

export const animationsInUserTeamFolderFetchingSelector = state => {
  const userTeamAnimationFoldersState = userTeamAnimationsSelector(state);
  const currentSelectedFolder = getParameterByName("folderId", window.location);
  const currentAssetType = getAssetTypeFromQueryParams(window.location);

  if (!currentSelectedFolder) return false;

  const currentFolderState =
    userTeamAnimationFoldersState[currentSelectedFolder];

  if (!currentFolderState) return false;

  const currentFolderAssetType = currentFolderState[currentAssetType];

  if (!currentFolderAssetType) return false;

  return currentFolderAssetType.pages.some(page => page.isFetching);
};
