import React from "react";
import style from "./style.module.css";

const DividerLabel = ({ label, className }) => (
  <div className={`${style.wrapper} ${className}`}>
    <div className={style.label}>{label}</div>
    <div className={style.divider} />
  </div>
);

export default DividerLabel;
