import * as types from "./teamSettingsTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { teamSettingsApiSelector } from "state/api/teamSettings/teamSettingsApiSelector";
import { teamSettingsSelector } from "state/entities/teamSettings/teamSettingsSelectors";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import { noop, reduxStoreExpiry } from "lib";

export const fetchTeamSettings = ({ teamId, onSuccess = noop }) => {
  return {
    [CALL_API]: {
      method: "GET",
      service: SERVICES.USER,
      types: [
        types.TEAM_SETTINGS_REQUEST,
        types.TEAM_SETTINGS_REQUEST_SUCCESS,
        types.TEAM_SETTINGS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/settings`,
      request: {},
      schema: schemas.TEAM_SETTINGS,
      onSuccess
    }
  };
};

export const shouldFetchTeamSettings = state => {
  const teamSettingsApi = teamSettingsApiSelector(state);

  if (!teamSettingsApi) return true;
  if (teamSettingsApi.isFetching) return false;

  const isExpired = reduxStoreExpiry.isDataExpired(
    teamSettingsApi.lastFetched,
    5
  );

  return isExpired;
};

export const fetchTeamSettingsIfNeeded = ({ onSuccess } = {}) => (
  dispatch,
  getState
) => {
  const teamId = getState().ui.currentTeam.id;

  if (shouldFetchTeamSettings(getState())) {
    dispatch(fetchTeamSettings({ teamId, onSuccess }));
  }
};

export const updateTeamSettings = ({ teamSettings, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();

  const currentTeamSettings = teamSettingsSelector(state);
  const newTeamSettings = {
    ...currentTeamSettings,
    ...teamSettings
  };

  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.USER,
      types: [
        types.TEAM_SETTINGS_UPDATE_REQUEST,
        types.TEAM_SETTINGS_UPDATE_REQUEST_SUCCESS,
        types.TEAM_SETTINGS_UPDATE_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/settings`,
      request: {
        body: {
          ...newTeamSettings
        }
      },
      schema: schemas.TEAM_SETTINGS,
      onSuccess
    }
  });
};
