import * as ImageSearchActionTypes from "./editorImageSearchTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { getParameterByName } from "lib/queryStringUtils";

const PAGE_SIZE = 30;

export const fetchSearchUserImagesIfNeeded = ({ term, page = 1 }) => (
  dispatch,
  getState
) => {
  const state = getState();

  const imagesStore = state.ui.editorImageSearch.personal;
  const termStore = imagesStore.terms[term];

  if (!termStore.isFetching) {
    dispatch(searchUserImages({ term, page }));
  }
};

export const fetchStockSearchIfNeeded = ({ term, page = 1 }) => (
  dispatch,
  getState
) => {
  const state = getState();

  const imagesStore = state.ui.editorImageSearch.stock;
  const termStore = imagesStore.terms[term];

  if (!termStore.isFetching) {
    dispatch(searchStockImages({ term, page }));
  }
};

export const fetchBrandImagesIfNeeded = ({ term, page = 1 }) => (
  dispatch,
  getState
) => {
  const state = getState();

  const imagesStore = state.ui.editorImageSearch.brand.image;
  const termStore = imagesStore.terms[term];

  if (!termStore.isFetching) {
    dispatch(searchBrandImages({ term, page }));
  }
};

export const fetchBrandLogosIfNeeded = ({ term, page = 1 }) => (
  dispatch,
  getState
) => {
  const state = getState();

  const imagesStore = state.ui.editorImageSearch.brand.logo;
  const termStore = imagesStore.terms[term];

  if (!termStore.isFetching) {
    dispatch(searchBrandLogos({ term, page }));
  }
};

export const searchStockImages = ({ term, page = 1 }) => dispatch => {
  dispatch({
    [CALL_API]: {
      service: SERVICES.ASSET,
      types: [
        ImageSearchActionTypes.EDITOR_STOCK_IMAGE_SEARCH_REQUEST,
        ImageSearchActionTypes.EDITOR_STOCK_IMAGE_SEARCH_SUCCESS,
        ImageSearchActionTypes.EDITOR_STOCK_IMAGE_SEARCH_FAILURE
      ],
      endpoint: "/media",
      request: {
        page: page,
        pageSize: PAGE_SIZE,
        params: {
          term: term,
          type: "image",
          context: "stock"
        }
      },
      schema: schemas.STOCK_IMAGE_SEARCH_RESULT
    }
  });
};

export const searchUserImages = ({ term, page = 1 }) => dispatch => {
  const folderId = getParameterByName("folderId", window.location);

  const params = {
    term,
    context: "uploaded"
  };

  if (folderId) {
    params.folderId = folderId;
  }

  dispatch({
    [CALL_API]: {
      service: SERVICES.ASSET,
      types: [
        ImageSearchActionTypes.EDITOR_IMAGE_SEARCH_REQUEST,
        ImageSearchActionTypes.EDITOR_IMAGE_SEARCH_SUCCESS,
        ImageSearchActionTypes.EDITOR_IMAGE_SEARCH_FAILURE
      ],
      endpoint: "/media",
      request: {
        page: page,
        pageSize: PAGE_SIZE,
        params
      },
      schema: schemas.IMAGE_SEARCH_RESULT
    }
  });
};

export const searchBrandImages = ({
  term,
  page = 1,
  type = "IMAGE"
}) => dispatch => {
  const types = [
    ImageSearchActionTypes[`EDITOR_BRAND_${type}_SEARCH_REQUEST`],
    ImageSearchActionTypes[`EDITOR_BRAND_${type}_SEARCH_SUCCESS`],
    ImageSearchActionTypes[`EDITOR_BRAND_${type}_SEARCH_FAILURE`]
  ];

  dispatch({
    [CALL_API]: {
      service: SERVICES.ASSET,
      types,
      endpoint: "/media",
      request: {
        page: page,
        pageSize: PAGE_SIZE,
        params: {
          term: term,
          context: `BRAND_${type}`
        }
      },
      schema: schemas[`BRAND_${type}_SEARCH_RESULT`]
    }
  });
};

export const searchBrandLogos = ({ term, page = 1 }) => dispatch => {
  dispatch(searchBrandImages({ term, page, type: "LOGO" }));
};

export const getStockSearchNextPage = term => (dispatch, getState) => {
  const state = getState();
  const termState = state.ui.editorImageSearch.stock.terms[term];
  const nextPage = Math.floor(termState.ids.length / PAGE_SIZE) + 1;

  // if the number of images and the state next page do not line up then don't make more calls
  if (termState.nextPage && nextPage !== termState.nextPage) return;

  dispatch(
    fetchStockSearchIfNeeded({
      term: term,
      page: nextPage
    })
  );
};

export const getUserImageSearchNextPage = term => (dispatch, getState) => {
  const state = getState();
  const termState = state.ui.editorImageSearch.personal.terms[term];

  if (!termState) return;

  const nextPage = Math.floor(termState.ids.length / PAGE_SIZE) + 1;

  dispatch(
    fetchSearchUserImagesIfNeeded({
      term: term,
      page: nextPage
    })
  );
};

export const getBrandImageSearchNextPage = term => (dispatch, getState) => {
  const state = getState();
  const termState = state.ui.editorImageSearch.brand.image.terms[term];

  if (!termState) return;

  const nextPage = Math.floor(termState.ids.length / PAGE_SIZE) + 1;

  dispatch(
    fetchBrandImagesIfNeeded({
      term: term,
      page: nextPage
    })
  );
};

export const getBrandLogoSearchNextPage = term => (dispatch, getState) => {
  const state = getState();
  const termState = state.ui.editorImageSearch.brand.logo.terms[term];

  if (!termState) return;

  const nextPage = Math.floor(termState.ids.length / PAGE_SIZE) + 1;

  dispatch(
    fetchBrandLogosIfNeeded({
      term: term,
      page: nextPage
    })
  );
};

export const clearSearchTerm = () => {
  return {
    type: ImageSearchActionTypes.EDITOR_IMAGE_SEARCH_CLEAR_CURRENT_TERM
  };
};
