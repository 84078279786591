import React from "react";

const ColoredLine = ({
  opacity = "1",
  color = "#d1d4d6",
  height = "1px",
  border = "none",
  width = "100%",
  margin = "auto"
}) => (
  <hr
    style={{
      color,
      backgroundColor: color,
      height,
      border,
      width,
      margin,
      opacity
    }}
  />
);

export default ColoredLine;
