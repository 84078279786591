import React, { Component } from "react";
import PropTypes from "prop-types";
import { default as imagesUploadModalPubSub } from "lib/PubSub/ImagesUploadModal/ImagesUploadModal";
import FolderPicker from "views/components/FolderPicker/FolderPicker";
import style from "./style.module.css";
import { noop, Logger, chainPromises, isEmpty } from "lib";
import { formatFileSize } from "lib/files/fileUtils";
import { getGifUploadData } from "lib/imageHelpers";
import { isVideo } from "lib/isVideo";
import { isGif } from "lib/isGif";
import getFileExtension from "lib/getFileExtension";
import {
  ANIMATION_ASSET_TYPE_MAP,
  IMAGE_LIST_FOLDER_TYPES
} from "lib/constants";
import { Modal, Loading } from "views/components";
import ImageJaggedMountainIcon from "views/components/icons/ImageJaggedMountainIcon";
import TickCircledIcon from "views/components/icons/TickCircledIcon";
import CrossCircledIcon from "views/components/icons/CrossCircledIcon";
import PATHS from "routes/paths";

const acceptedFileTypes = ["png", "jpg", "jpeg", "svg", "gif"];

const initialState = (pages, folderId) => ({
  name: null,
  currentPage: pages.FOLDER_SELECTION,
  selectedFolderId: folderId || null,
  uploadingImages: {}
});

class ImagesUploadModal extends Component {
  static PAGES = {
    FOLDER_SELECTION: "FOLDER_SELECTION",
    UPLOAD: "UPLOAD"
  };

  constructor(props) {
    super(props);

    this.getFolderSelectionContent = this.getFolderSelectionContent.bind(this);
    this.getUploadContent = this.getUploadContent.bind(this);
    this.handleChangeSelectedFolder = this.handleChangeSelectedFolder.bind(
      this
    );
    this.handleChangeCurrentPage = this.handleChangeCurrentPage.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
    this.getPageContents = this.getPageContents.bind(this);
    this.setFolderContext = this.setFolderContext.bind(this);
    this.processImage = this.processImage.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.getSubTextLabel = this.getSubTextLabel.bind(this);
    this.handleTabChangeOnUpload = this.handleTabChangeOnUpload.bind(this);

    const fileTypes = this.props.acceptedFileTypes || acceptedFileTypes;

    this.state = {
      ...initialState(ImagesUploadModal.PAGES),
      acceptedFileTypes: fileTypes,
      acceptedFileExtensionList: `.${fileTypes.join(", .")}`,
      errors: null
    };
  }

  setFolderContext() {
    if (this.props.folderId) {
      this.handleChangeSelectedFolder(this.props.folderId);
    }
  }

  componentDidMount() {
    this.setFolderContext();
    this.props.onFetchUsersImageFolders();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      errors: propErrors,
      uploadErrors,
      isOpen,
      clearUploadErrors
    } = this.props;
    const { uploadingImages } = this.state;
    imagesUploadModalPubSub.onStateChange(prevState, this.state);

    if (isOpen !== prevProps.isOpen) {
      // when opening or closing the modal
      const isAtLeastOneUploading = Object.values(uploadingImages).some(
        upload => !upload.isFinished
      );
      // in the case that no uploads are still happening
      if (!isAtLeastOneUploading) {
        // return to the original state
        this.setState(
          initialState(ImagesUploadModal.PAGES, this.props.folderId)
        );
        this.setFolderContext();
      }
    }

    if (isOpen === false && prevProps.isOpen === true) {
      clearUploadErrors();
      this.setState({ errors: null });
    }

    if (propErrors !== prevProps.errors) {
      this.setState({
        errors: propErrors
      });
    }

    if (uploadErrors !== prevProps.uploadErrors && isOpen === true) {
      const errorKeys = Object.keys(uploadErrors);
      const uploadingAnimationKeys = Object.keys(uploadingImages);

      let uploadingMedia = {};
      let errors = {};

      uploadingAnimationKeys.forEach(animation => {
        const animationKey = animation.replaceAll(" ", "-").toLowerCase();
        if (errorKeys.includes(animationKey)) {
          uploadingMedia[animation] = Object.assign(
            {},
            uploadingImages[animation],
            {
              isFinished: true,
              isError: true
            }
          );
          errors[animation] = {
            value: uploadErrors[animationKey][0].value
          };
        }
      });

      this.setState({
        uploadingImages: {
          ...this.state.uploadingImages,
          ...uploadingMedia
        },
        errors: {
          ...this.state.errors,
          ...errors
        }
      });
    }
  }

  handleChangeCurrentPage(page) {
    this.setState({
      currentPage: page
    });
  }

  getPageContents(type) {
    const { onClose } = this.props;

    const folderSelectionContent = {
      header: "Upload to...",
      content: this.getFolderSelectionContent(),
      buttons: [
        {
          name: "Cancel",
          theme: "gray",
          onClick: onClose
        },
        {
          name: "Upload",
          theme: "blueSolid",
          disabled: false,
          onClick: () => {
            this.triggerImageSelector();
          }
        }
      ],
      buttonsClassName: style.selectButtons,
      modalStyle: style.uploadModalTo
    };

    const uploadContent = {
      header: "Upload details",
      content: this.getUploadContent(),
      buttons: [
        {
          name: "Hide",
          theme: "blueSolid",
          disabled: false,
          onClick: () => {
            onClose({ onSuccess: this.clearErrors });
          }
        }
      ],
      buttonsClassName: style.uploadButtons,
      modalStyle: style.uploadModalDetails
    };

    switch (type) {
      case ImagesUploadModal.PAGES.FOLDER_SELECTION: {
        return folderSelectionContent;
      }
      case ImagesUploadModal.PAGES.UPLOAD: {
        return uploadContent;
      }
      default: {
        return folderSelectionContent;
      }
    }
  }

  handleChangeSelectedFolder(folderId) {
    const { selectedFolderId } = this.state;
    if (selectedFolderId !== folderId) {
      this.setState({
        selectedFolderId: folderId
      });
    }
  }

  stopBrowserDefaultBehaviour(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  handleFlagFileAsFinishedUploading(fileName, resolve) {
    const { uploadingImages } = this.state;

    if (
      uploadingImages[fileName] &&
      uploadingImages[fileName].isFinished === false
    ) {
      this.setState({
        uploadingImages: Object.assign({}, uploadingImages, {
          [fileName]: Object.assign({}, uploadingImages[fileName], {
            isFinished: true
          })
        })
      });
      resolve();
    }
  }

  async handleFileSelect({ event }) {
    this.stopBrowserDefaultBehaviour(event);

    this.handleChangeCurrentPage(ImagesUploadModal.PAGES.UPLOAD);

    if (this.props.disabled) return;

    const files = event.target.files;

    const uploadingImages = Array.from(files).reduce(
      (previous, current, index) => {
        return Object.assign({}, previous, {
          [current.name]: {
            size: formatFileSize(files[index].size, 1),
            isFinished: false
          }
        });
      },
      {}
    );

    this.setState({
      file: files[0],
      uploadingImages,
      imageError: null
    });

    /* define function for creating promise containers for all uploads */
    const createImageProcessPromise = file => {
      return () =>
        new Promise(resolve => {
          this.processImage(file).then(
            result => {
              this.setState({ fileSrc: result });
              this.onImageUpload(file, () =>
                this.handleFlagFileAsFinishedUploading(file.name, resolve)
              );
            },
            ({ error }) => {
              this.setState({ imageError: error });
            }
          );
        });
    };

    /* set up the promise containers for the upload */
    const uploadPromises = Object.values(files).map(file =>
      createImageProcessPromise(file)
    );

    this.handleTabChangeOnUpload({ files });

    /* run the promises in chain */
    await chainPromises(uploadPromises);
  }

  async onImageUpload(file, onSuccess) {
    const { selectedFolderId } = this.state;
    const {
      onUploadUserTeamImage,
      onUploadImageToFolder,
      uploadOrigin
    } = this.props;
    this.setState({
      uploadingFileName: file.name,
      uploadingError: false
    });

    const uploadImage = !selectedFolderId
      ? onUploadUserTeamImage
      : onUploadImageToFolder;

    let uploadParams = {
      imageFile: file,
      folderId: selectedFolderId,
      onSuccess
    };

    // define what we should do when the presignedUrl is created
    const onPresignedUrlCreated = async id => {
      const isAnimation = isGif(file.name) || isVideo(file.name);
      const fileURL = window.URL.createObjectURL(file);
      const getMediaType = () => {
        switch (true) {
          case isGif(file.name): {
            return ANIMATION_ASSET_TYPE_MAP.ANIMATION;
          }
          case isVideo(file.name): {
            return ANIMATION_ASSET_TYPE_MAP.VIDEO;
          }
          default: {
            return IMAGE_LIST_FOLDER_TYPES.IMAGE;
          }
        }
      };

      // build a placeholder media entry
      const media = {
        asset: file.name.toLowerCase(),
        createdAt: Date.now(),
        height: null,
        id,
        name: file.name.toLowerCase(),
        preview: "preview_placeholder.png",
        size: 75780,
        status: "ACTIVE",
        thumbnail: "thumb_placeholder.png",
        type: getMediaType(),
        updatedAt: Date.now(),
        width: null,
        duration: null
      };

      // build placeholder media entity
      const mediaEntity = {
        createdAt: Date.now(),
        media,
        mediaId: id,
        previewUrl: fileURL,
        thumbnailUrl: fileURL,
        updatedAt: Date.now(),
        url: fileURL,
        origin: uploadOrigin || "userTeamImage"
      };

      // add placeholder media entity to the store
      isAnimation
        ? this.props.addUploadingAnimationsPlaceholders({
            mediaPlaceholders: [mediaEntity],
            folderId: selectedFolderId
          })
        : this.props.addUploadingImagesPlaceholders({
            mediaPlaceholders: [mediaEntity],
            folderId: selectedFolderId
          });
    };

    if (isGif(file.name)) {
      const gifData = await getGifUploadData(file);
      uploadParams = {
        ...uploadParams,
        duration: gifData.duration,
        placeholderURL: window.URL.createObjectURL(file),
        uploadOrigin,
        onPresignedUrlCreated,
        noRefetch: selectedFolderId ? false : true,
        sortOptions: this.props.sortOptions
      };
    } else if (isVideo(file.name)) {
      const getVideoDuration = () =>
        new Promise(resolve => {
          let video = document.createElement("video");
          video.preload = "metadata";
          video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            // foor the duration to prevent fractional values sent in request
            resolve(Math.floor(video.duration * 1000));
          };
          video.src = window.URL.createObjectURL(file);
        });

      const duration = await getVideoDuration();

      uploadParams = {
        ...uploadParams,
        duration,
        placeholderURL: window.URL.createObjectURL(file),
        uploadOrigin,
        onPresignedUrlCreated,
        noRefetch: selectedFolderId ? false : true,
        sortOptions: this.props.sortOptions
      };
    }

    uploadImage(uploadParams);
  }

  processImage(file) {
    const fileExtension = getFileExtension(file.name);

    if (!this.state.acceptedFileTypes.includes(fileExtension))
      return Promise.reject({
        error: "Images must be in PNG, GIF or JPG format."
      });

    const promise = new Promise((resolve, reject) => {
      const errorCallback = msg => () => {
        Logger.warn(msg);
        return reject({ error: "We were not able to process your image." });
      };

      const validateFileAsImage = fileSrc => {
        const image = new Image();
        image.onerror = errorCallback("Image onload error");
        image.onload = () => {
          Logger.info({ app: "Image processed successfully" });
          /* validate image size here*/
          resolve(fileSrc);
        };
        image.src = fileSrc;
      };

      const reader = new FileReader();
      reader.onerror = errorCallback("File Reader onload error");

      reader.onload = ({ target }) => {
        if (target.result.includes("video/mp4")) {
          resolve(target.result);
        }
        validateFileAsImage(target.result);
      };
      reader.readAsDataURL(file);
    });

    return promise;
  }

  handleTabChangeOnUpload(files) {
    const { uploadOrigin } = this.props;
    const isAnimationUpload = ["teamAnimation", "userTeamAnimation"].includes(
      uploadOrigin
    );

    // return safely if not an animation upload or
    // not located in the editor
    if (!isAnimationUpload || !PATHS.isEditorPath(window.location.pathname))
      return;

    const { files: _files } = files;
    const subTab =
      uploadOrigin === "teamAnimation" ? "TEAM_ANIMATIONS" : "MY_ANIMATIONS";

    this.props.handleUploadTabChange({
      files: _files,
      subTab,
      folderId: this.state.selectedFolderId
    });
  }

  triggerImageSelector() {
    document.getElementById("ImageUpload").click();
  }

  clearErrors() {
    const { clearUploadErrors = noop } = this.props;

    this.setState({
      errors: null
    });
    clearUploadErrors();
  }

  getSubTextLabel() {
    const { destinationName = "My Images" } = this.props;
    const uploadType = destinationName === "My Videos" ? "videos" : "images";
    return `Your ${uploadType} are now uploading and will appear as they are completed`;
  }

  getUploadContent() {
    const { imageFolders, destinationName = "My Images" } = this.props;
    const { uploadingImages, selectedFolderId, errors } = this.state;

    let selectedFolderName = destinationName;
    if (selectedFolderId !== null && !isEmpty(imageFolders)) {
      const selectedFolder = imageFolders.find(
        folder => folder.id === selectedFolderId
      );
      if (selectedFolder) {
        selectedFolderName = selectedFolder.name;
      }
    }

    const getUploadSuccessIcon = image => {
      if (!image.isFinished && !image.isError) {
        return <Loading size="14px" />;
      }
      if (image.isFinished && image.isError) {
        return <CrossCircledIcon color="#e64826" size="14px" />;
      }
      if (image.isFinished && !image.isError) {
        return <TickCircledIcon size="14px" color="#00bd62" />;
      }
    };

    return (
      <div className={style.uploadContent}>
        <div className={style.uploadContentSubText}>
          {this.getSubTextLabel()}
        </div>
        <div className={style.uploadUploadingTo}>
          Uploading to:{" "}
          <span className={style.uploadLocation}>{selectedFolderName}</span>
        </div>
        <div className={style.uploadTable}>
          {Object.keys(uploadingImages).map(key => (
            <>
              <div className={style.uploadTableRow}>
                <div className={style.uploadRowLeft}>
                  <div className={style.uploadImageIconWrapper}>
                    <ImageJaggedMountainIcon
                      isTabVersion={false}
                      size="24px"
                      color="#9a9b9d"
                    />
                  </div>
                  <div className={style.uploadRowText}>{key}</div>
                </div>
                <div className={style.uploadRowRight}>
                  <div>{uploadingImages[key].size}</div>
                  <div>{getUploadSuccessIcon(uploadingImages[key])}</div>
                </div>
              </div>
              {errors && errors[key] && (
                <div className={style.uploadError}>{errors[key].value}</div>
              )}
            </>
          ))}
        </div>
      </div>
    );
  }

  getFolderSelectionContent() {
    const { imageFolders, destinationName = "My Images" } = this.props;
    const { selectedFolderId } = this.state;

    return (
      <FolderPicker
        folders={imageFolders}
        onClick={this.handleChangeSelectedFolder}
        destinationName={destinationName}
        selectedFolderId={selectedFolderId}
      />
    );
    // return (
    //   <div className={style.folderSelectionContent}>
    //     <div
    //       className={style.folderSelectionContentItem}
    //       data-is-selected={!selectedFolderId}
    //       onClick={() => this.handleChangeSelectedFolder(null)}
    //     >
    //       <div className={style.uploadFolderIconWrapper}>
    //         <FolderWideIcon size="22px" color="#9a9b9d" />
    //       </div>
    //       <div className={style.folderSelectionFolderTitle}>
    //         {destinationName}
    //       </div>
    //       {!selectedFolderId && (
    //         <div className={style.folderSelectionContentTickWrapper}>
    //           <TickIcon hasCircle={false} color={style.easilBlueDeep} />
    //         </div>
    //       )}
    //     </div>
    //     {imageFolders.map(folder => {
    //       const isSelected = selectedFolderId === folder.id;
    //       return (
    //         <div
    //           key={folder.id}
    //           className={style.folderSelectionContentItem}
    //           data-is-sub={true}
    //           data-is-selected={isSelected}
    //           onClick={() => this.handleChangeSelectedFolder(folder.id)}
    //         >
    //           <div className={style.uploadFolderIconWrapper}>
    //             <FolderWideIcon size="22px" color="#9a9b9d" />
    //           </div>
    //           <div className={style.uploadFolderName}>{folder.name}</div>
    //           {isSelected && (
    //             <div className={style.folderSelectionContentTickWrapper}>
    //               <TickIcon hasCircle={false} color={style.easilBlueDeep} />
    //             </div>
    //           )}
    //         </div>
    //       );
    //     })}
    //   </div>
    // );
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { currentPage } = this.state;

    const pageContent = this.getPageContents(currentPage);

    return (
      <Modal
        isOpen={isOpen}
        contentLabel="Preview"
        onRequestClose={onClose}
        header={pageContent.header}
        className={pageContent.modalStyle}
        buttons={pageContent.buttons}
        headerTitleClassName={style.uploadModalHeader}
        crossColor="#afb3b6"
        buttonsClassName={pageContent.buttonsClassName}
      >
        {pageContent.content}
        <input
          type="file"
          accept={this.state.acceptedFileExtensionList}
          id={"ImageUpload"}
          className={style.inputfile}
          onChange={e => this.handleFileSelect({ event: e })}
          multiple
        />
      </Modal>
    );
  }
}

ImagesUploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUploadUserTeamImage: PropTypes.func,
  imageFolders: PropTypes.array
};

export default ImagesUploadModal;
