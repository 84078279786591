import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import Loading from "../loading";
import { playVideo } from "lib/videoControlUtils";
import dateFns from "date-fns";

const defaultLoader = () => (
  <div className={style.loader}>
    <Loading />
  </div>
);

class Video extends Component {
  static defaultProps = {
    fallbackVideoSrc: ""
  };
  constructor(props) {
    super(props);

    this.handleVideoLoaded = this.handleVideoLoaded.bind(this);
    this.getVideoUrl = this.getVideoUrl.bind(this);
    this.useFallbackVideo = this.useFallbackVideo.bind(this);

    this.videoRef = React.createRef();

    this.state = {
      loaded: false,
      useFallback: false,
      videoReference: null,
      isLandscape: false,
      isFirstRender: true,
      mountTime: Date.now()
    };
  }

  componentDidMount() {
    const video = document.createElement("video");

    video.src = this.getVideoUrl();

    this.setState({
      videoReference: video
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { videoReference } = this.state;
    if (
      !prevState.loaded &&
      this.state.loaded &&
      videoReference &&
      videoReference.naturalWidth
    ) {
      this.setState({
        isLandscape:
          videoReference.naturalWidth > videoReference.naturalHeight ||
          this.props.isLandscape
      });
    }

    if (prevState.useFallback !== this.state.useFallback) {
      this.videoRef.current.load();
    }

    if (!this.props.autoPlay) {
      if (!prevProps.isHovered && this.props.isHovered) {
        // hover start
        playVideo(this.videoRef.current);
      }
      if (prevProps.isHovered && !this.props.isHovered) {
        // hover end
        this.videoRef.current.pause();
        this.videoRef.current.currentTime = 0;
      }
    }
    if (
      this.state.isFirstRender &&
      dateFns.differenceInMilliseconds(Date.now(), this.state.mountTime) > 500
    ) {
      this.setState({
        isFirstRender: false
      });
    }
  }

  handleVideoLoaded() {
    const { onVideoLoaded } = this.props;

    if (onVideoLoaded) onVideoLoaded();

    this.setState({
      loaded: true
    });
  }

  useFallbackVideo() {
    if (this.props.src === "loading") {
      return;
    }
    this.setState({
      loaded: true,
      useFallback: true
    });
  }

  getVideoUrl() {
    if (this.state.useFallback) {
      return this.props.fallbackVideoSrc;
    }

    return this.props.src;
  }

  render() {
    const {
      alt = "",
      backgroundColor,
      borderColor,
      borderRadius,
      className,
      fillContainer,
      filter,
      height,
      isWantingCheckeredBackground = false,
      left,
      onClick,
      top,
      width,
      noBorders
    } = this.props;

    const { loaded, isLandscape } = this.state;

    const Loader = this.props.loader || defaultLoader;

    const isLoading = !loaded || this.props.src === "loading";

    const styles = {
      backgroundColor,
      borderColor,
      borderRadius,
      height: height,
      width: width
    };

    if (noBorders) {
      styles.border = "none";
    }

    if (this.state.isFirstRender && !loaded) {
      styles.opacity = 0;
    }

    const imageStyles = {
      borderRadius,
      display: !isLoading ? "block" : "none",
      filter,
      height: "auto",
      left: left || 0,
      objectFit: fillContainer ? "cover" : "contain",
      top: top || 0,
      width: isLandscape ? "100%" : "auto"
    };

    if (isWantingCheckeredBackground) {
      imageStyles.backgroundImage =
        "linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF), linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF)";
    }

    if (
      !this.state.loaded &&
      this.videoRef &&
      this.videoRef.current &&
      this.videoRef.current.readyState === 4
    ) {
      this.handleVideoLoaded();
    }

    return (
      <div
        className={`${style.wrapper} ${className}`}
        onClick={onClick}
        style={styles}
      >
        {isLoading && <Loader />}
        <video
          onLoadedData={this.handleVideoLoaded}
          onError={this.useFallbackVideo}
          className={style.video}
          muted
          loop
          autoPlay={this.props.autoPlay}
          style={imageStyles}
          alt={alt}
          ref={this.videoRef}
          src={this.getVideoUrl()}
        />
      </div>
    );
  }
}

Video.propTypes = {
  src: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  className: PropTypes.string,
  fillContainer: PropTypes.bool,
  onClick: PropTypes.func,
  alt: PropTypes.string
};

export default Video;
