import React from "react";
import PropTypes from "prop-types";
import TickCircledIcon from "views/components/icons/TickCircledIcon";
import ExclaimationMarkCircledIcon from "views/components/icons/ExclaimationMarkCircledIcon";
import CrossCircledIcon from "views/components/icons/CrossCircledIcon";

import Button from "views/components/button";

import style from "./style.module.css";

import ToastWrapper from "./ToastWrapper";

const getIcon = icon => {
  switch (icon) {
    case "failure": {
      return <CrossCircledIcon color="#ffff" size={"24"} />;
    }
    case "warning": {
      return <ExclaimationMarkCircledIcon color="#ffff" size={"24"} />;
    }
    case "success":
    default: {
      return <TickCircledIcon color="#ffff" size={"24"} />;
    }
  }
};

const ToastWithButton = ({
  closeToast,
  icon = "success",
  textComponent,
  buttonLabel,
  action
}) => {
  let _textComponent = textComponent;

  if (typeof textComponent === "string") {
    _textComponent = <p>{textComponent}</p>;
  }

  return (
    <ToastWrapper closeToast={closeToast}>
      <div className={style.toastContentWrapper}>
        <div className={style.toastContentLeft}>{getIcon(icon)}</div>
        <div className={style.toastContentRight}>
          {_textComponent}
          <Button theme="grayBackgroundXDarkGrayFont" onClick={action}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </ToastWrapper>
  );
};

ToastWithButton.propTypes = {
  closeToast: PropTypes.func,
  icon: PropTypes.string,
  textComponent: PropTypes.element.isRequired,
  buttonLabel: PropTypes.string,
  action: PropTypes.func
};

export default ToastWithButton;
