import * as types from "./currentUserTypes";
import { push } from "react-router-redux";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import {
  noop,
  userTracker,
  userChat,
  tourProvider,
  Referrer,
  sessionRecorder,
  Logger
} from "lib";
import auth from "lib/auth";
import PATHS from "routes/paths";
import { fetchTeamIfNeeded } from "state/entities/entitiesActions";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";
import { teamSelector } from "state/entities/teams/teamsSelectors";
import { makeToast } from "state/ui/toaster/ToasterActions";
import { getParameterByName } from "lib/queryStringUtils";
import { clearClipboardAndTriggerToast } from "state/ui/clipBoard/clipBoardActions";

export const fetchCurrentUser = ({ onSuccess = noop }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      types: [
        types.CURRENT_USER_REQUEST,
        types.CURRENT_USER_REQUEST_SUCCESS,
        types.CURRENT_USER_REQUEST_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/users/me`,
      schema: schemas.USERS,
      onSuccess: response => {
        onSuccess(getState().currentUser);
      }
    }
  });
};

export const changeCurrentUserPassword = ({
  onSuccess = noop,
  newPassword
}) => (dispatch, getState) => {
  const currentUser = currentUserSelector(getState());
  dispatch({
    [CALL_API]: {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      types: [
        types.CHANGE_CURRENT_USER_PASSWORD_REQUEST,
        types.CHANGE_CURRENT_USER_PASSWORD_REQUEST_SUCCESS,
        types.CHANGE_CURRENT_USER_PASSWORD_REQUEST_FAILURE
      ],
      request: {
        body: {
          id: currentUser.id,
          name: currentUser.name,
          email: currentUser.email,
          avatar: currentUser.avatarUrl
            ? currentUser.avatarUrl.split("/").pop()
            : "NULL",
          password: newPassword
        }
      },
      schema: schemas.NONE,
      service: SERVICES.USER,
      endpoint: `/users/me`,
      onSuccess: () => {
        onSuccess();
        dispatch(
          makeToast({
            textComponent: `Your password has been successfully\n changed. Use it next time you log in.`,
            type: "noButton",
            icon: "success"
          })
        );
      }
    }
  });
};

export const changeCurrentUserPasswordV2 = ({
  onSuccess = noop,
  newPassword,
  password
}) => (dispatch, getState) => {
  const currentUser = currentUserSelector(getState());
  dispatch({
    [CALL_API]: {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      types: [
        types.CHANGE_CURRENT_USER_PASSWORD_REQUEST,
        types.CHANGE_CURRENT_USER_PASSWORD_REQUEST_SUCCESS,
        types.CHANGE_CURRENT_USER_PASSWORD_REQUEST_FAILURE
      ],
      request: {
        body: {
          id: currentUser.id,
          name: currentUser.name,
          email: currentUser.email,
          avatar: currentUser.avatarUrl
            ? currentUser.avatarUrl.split("/").pop()
            : "NULL",
          password: password,
          newPassword: newPassword
        }
      },
      schema: schemas.NONE,
      service: SERVICES.USER_V2,
      endpoint: `/users/me`,
      onSuccess: () => {
        onSuccess();
        dispatch(
          makeToast({
            textComponent: `Your password has been successfully\n changed. Use it next time you log in.`,
            type: "noButton",
            icon: "success"
          })
        );
      }
    }
  });
};

export const setCurrentUserTokenData = ({
  token,
  tokenOrigin,
  onSuccess = noop
}) => (dispatch, getState) => {
  const { teamId, userId, roles } = auth.jwtDecoder(token);

  auth.setTokenInLocalStorage(token);

  dispatch({
    type: types.CURRENT_USER_SET_TOKEN_DATA,
    payload: {
      tokenOrigin,
      token,
      teamId,
      userId,
      roles
    }
  });

  onSuccess();
};

const currentUserTokenTypes = [
  types.CURRENT_USER_TOKEN_REQUEST,
  types.CURRENT_USER_TOKEN_REQUEST_SUCCESS,
  types.CURRENT_USER_TOKEN_REQUEST_FAILURE
];

export const fetchUserToken = ({
  email,
  password,
  types = currentUserTokenTypes,
  socialId,
  strategy = "password",
  onSuccess,
  retries = 0
}) => (dispatch, getState) => {
  const maxRetryCount = 2;
  const retryDelay = 3000;

  dispatch({
    [CALL_API]: {
      method: "POST",
      headers: {
        accept: "application/x-www-form-urlencoded",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      types,
      request: {
        body: {
          email: email,
          password: password,
          client_id: "easil",
          grant_type: strategy === "password" ? "password" : "social",
          scope: "openid profile email",
          strategy,
          socialId
        }
      },
      service: SERVICES.AUTH,
      endpoint: `/token`,
      schema: schemas.NONE,
      onSuccess: response =>
        dispatch(
          setCurrentUserTokenData({
            tokenOrigin: "state",
            token: response.accessToken,
            onSuccess: onSuccess
          })
        ),
      onFailure: error => {
        if (
          retries < maxRetryCount &&
          error.error_description === "Team does not have a subscription"
        ) {
          setTimeout(() => {
            dispatch(
              fetchUserToken({
                email,
                password,
                types,
                socialId,
                strategy,
                onSuccess,
                retries: retries + 1
              })
            );
          }, retryDelay);
        } else {
          Logger.error(
            `Retrieving Token has failed. Error details: ${JSON.stringify(
              error
            )}`
          );
        }
      }
    }
  });
};

export const switchToken = ({ teamId, onSuccess = noop, token }) => (
  dispatch,
  getState
) => {
  let retryCounts = 0;
  const maxRetryCount = 6;
  const retryDelay = 3000;

  const _token = token || getState().currentUser.token;

  dispatch({
    [CALL_API]: {
      method: "POST",
      headers: {
        accept: "application/x-www-form-urlencoded",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      types: [
        types.CURRENT_USER_SWITCH_TOKEN_REQUEST,
        types.CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS,
        types.CURRENT_USER_SWITCH_TOKEN_REQUEST_FAILURE
      ],
      request: {
        body: {
          team_id: teamId || getState().ui.currentTeam.id,
          token: _token,
          client_id: "easil",
          grant_type: "switch",
          scope: "openid profile email"
        }
      },
      service: SERVICES.AUTH,
      endpoint: `/token`,
      schema: schemas.NONE,
      onSuccess: response => {
        dispatch(
          setCurrentUserTokenData({
            tokenOrigin: "state",
            token: response.accessToken,
            onSuccess: onSuccess
          })
        );
        // if the element clipboard has content then we need to clear it and notify the user
        dispatch(clearClipboardAndTriggerToast());
      },
      onFailure: error => {
        if (retryCounts < maxRetryCount) {
          ++retryCounts;

          setTimeout(() => {
            dispatch(
              switchToken({
                teamId,
                onSuccess,
                token: _token
              })
            );
          }, retryDelay);
        } else {
          Logger.error(
            `Switching Token has failed. Error details: ${JSON.stringify(
              error
            )}`
          );
        }
      }
    }
  });
};

export const switchToPersonalTeam = () => (dispatch, getState) => {
  const teamId = getState().personalTeam.id;
  dispatch(switchToken({ teamId }));
};

export const updateUser = ({ user }) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      service: SERVICES.USER,
      endpoint: `/users/me`,
      schema: schemas.USERS,
      request: {
        body: {
          id: user.id,
          name: user.name,
          email: user.email,
          password: null,
          avatar:
            user.avatar ||
            (user.avatarUrl ? user.avatarUrl.split("/").pop() : "NULL")
        }
      },
      types: [
        types.CURRENT_USER_UPDATE_REQUEST,
        types.CURRENT_USER_UPDATE_REQUEST_SUCCESS,
        types.CURRENT_USER_UPDATE_REQUEST_FAILURE
      ]
    }
  });
};

export const updateUserV2 = ({ user, onSuccess }) => (dispatch, getState) => {
  const isRemovingAvatar = user.avatarUrl === null && user.avatar === null;
  dispatch({
    [CALL_API]: {
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      service: SERVICES.USER_V2,
      endpoint: `/users/me`,
      schema: schemas.USERS,
      request: {
        body: {
          id: user.id,
          name: user.name,
          email: user.email,
          password: user.password || null,
          newPassword: null,
          avatar:
            user.avatar ||
            (user.avatarUrl ? user.avatarUrl.split("/").pop() : "NULL")
        }
      },
      extra: {
        isRemovingAvatar
      },
      types: [
        types.CURRENT_USER_UPDATE_REQUEST,
        types.CURRENT_USER_UPDATE_REQUEST_SUCCESS,
        types.CURRENT_USER_UPDATE_REQUEST_FAILURE
      ],
      onSuccess
    }
  });
};

export const logout = () => dispatch => {
  dispatch({
    [CALL_API]: {
      endpoint: `/logout`,
      method: "POST",
      schema: schemas.NONE,
      service: SERVICES.AUTH,
      types: [
        types.CURRENT_USER_LOGOUT_REQUEST,
        types.CURRENT_USER_LOGOUT_REQUEST_SUCCESS,
        types.CURRENT_USER_LOGOUT_REQUEST_FAILURE
      ]
    }
  });
};

export const fetchUserInitialData = () => (dispatch, getState) => {
  const currentUserPromise = new Promise((resolve, reject) => {
    dispatch(fetchCurrentUserIfNeeded({ onSuccess: resolve }));
  });

  const teamPromise = new Promise((resolve, reject) => {
    dispatch(fetchTeamIfNeeded({ onSuccess: resolve }));
  });

  Promise.all([currentUserPromise, teamPromise]).then(_ => {
    identifyUserToOthersServices({ getState });
  });
};

const identifyUserToOthersServices = ({ getState }) => {
  const state = getState();

  const team = teamSelector({ state });
  const user = currentUserSelector(state);

  const cid = getParameterByName("cid", window.location.search);

  if (!user.token) {
    return;
  }

  const { teamPlan } = auth.jwtDecoder(user.token);

  tourProvider.trackUser(
    {
      id: user.id,
      cid,
      email: user.email,
      name: user.name,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt
    },
    true
  );

  const startUserChatDelay = 5000;
  setTimeout(
    () =>
      userChat.initialize({
        userId: user.id,
        email: user.email
      }),
    startUserChatDelay
  );

  sessionRecorder.identify({
    country: team.country,
    email: user.email,
    id: user.id,
    name: user.name,
    roles: user.roles,
    teamId: team.id,
    teamPlan
  });

  userTracker.start({
    id: user.id,
    email: user.email,
    name: user.name,
    roles: user.roles,
    country: team.country,
    teamId: team.id,
    teamName: team.name,
    teamPlan: teamPlan,
    createdAt: user.createdAt
  });
};

const pushToReferrerPath = () => (dispatch, getState) => {
  const referrerPath = Referrer.pop();

  if (referrerPath) {
    dispatch(push(referrerPath));
  }
};

export const startUserSession = history => (dispatch, getState) => {
  const redirectToLogin = () => {
    const warnMsg =
      "starting user session failed: Unable to authenticate user. Pushing to login page";
    Logger.warn(warnMsg);

    window.location = PATHS.login;
  };

  const initialiseUser = () => {
    dispatch(fetchUserInitialData());
    dispatch(pushToReferrerPath());
  };

  dispatch(
    authenticateUser({
      history,
      onFail: redirectToLogin,
      onSuccess: initialiseUser
    })
  );
};

export const authenticateUser = ({ history, onSuccess, onFail }) => (
  dispatch,
  getState
) => {
  const { userId, teamId, roles, token, tokenOrigin } = auth.getToken({
    state: getState()
  });

  if (!token && !getState().currentUser.isSwitchingToken) {
    Referrer.set(window.location.pathname);

    onFail();
  }

  if (tokenOrigin !== "state") {
    dispatch(
      setCurrentUserTokenData({
        tokenOrigin,
        token,
        teamId,
        userId,
        roles
      })
    );
  }

  onSuccess();
};

const shouldFetchUser = (state, userId) => {
  return true;
};

export const fetchCurrentUserIfNeeded = ({ userId, onSuccess }) => (
  dispatch,
  getState
) => {
  if (!shouldFetchUser(getState(), userId)) {
    onSuccess();
    return;
  }

  dispatch(fetchCurrentUser({ onSuccess }));
};

const shouldFetchCurrentUserPreferences = state => {
  const { api } = state;

  return !api.userPreferences.isFetching;
};

export const fetchCurrentUserPreferencesIfNeeded = ({
  onSuccess = noop
} = {}) => (dispatch, getState) => {
  if (shouldFetchCurrentUserPreferences(getState())) {
    dispatch(fetchCurrentUserPreferences({ onSuccess }));
  } else {
    onSuccess();
  }
};

const fetchCurrentUserPreferences = ({ onSuccess }) => (dispatch, getState) => {
  const { currentUser } = getState();
  dispatch({
    [CALL_API]: {
      types: [
        types.CURRENT_USER_PREFERENCES_REQUEST,
        types.CURRENT_USER_PREFERENCES_REQUEST_SUCCESS,
        types.CURRENT_USER_PREFERENCES_REQUEST_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/users/${currentUser.id}/preferences`,
      schema: schemas.USER_PREFERENCES,
      onSuccess: onSuccess
    }
  });
};

export const updateUserDefaultTeam = ({ defaultTeamId, onSuccess }) => (
  dispatch,
  getState
) => {
  const { ui } = getState();

  const { userId, updatedAt, createdAt } = ui.userPreferences;

  const userPreferences = {
    userId,
    updatedAt,
    createdAt,
    defaultTeamId
  };

  dispatch(updateUserPreferences({ userPreferences, onSuccess }));
};

export const updateUserPreferences = ({ userPreferences, onSuccess }) => (
  dispatch,
  getState
) => {
  const { currentUser } = getState();

  dispatch({
    [CALL_API]: {
      method: "PUT",
      types: [
        types.CURRENT_USER_UPDATE_PREFERENCES_REQUEST,
        types.CURRENT_USER_UPDATE_PREFERENCES_REQUEST_SUCCESS,
        types.CURRENT_USER_UPDATE_PREFERENCES_REQUEST_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/users/${currentUser.id}/preferences`,
      schema: schemas.USER_PREFERENCES,
      request: {
        body: {
          userId: userPreferences.userId,
          defaultTeamId: userPreferences.defaultTeamId,
          updatedAt: userPreferences.updatedAt,
          createdAt: userPreferences.createdAt
        }
      },
      onSuccess: onSuccess
    }
  });
};
