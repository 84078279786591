import ScalingCalculation from "lib/scalingTools/ScalingCalculation/ScalingCalculation";
import ScalingCalculationHorizontal from "lib/scalingTools/ScalingCalculation/ScalingCalculationHorizontal";
import ScalingCalculationVertical from "lib/scalingTools/ScalingCalculation/ScalingCalculationVertical";

import { ResizeHandler } from "views/components/Editor/resizeHandler/ResizeHandler";
const { POSITIONS } = ResizeHandler;

const ScalingCalculationFactory = ({
  dragItem,
  differenceFromInitialOffset
}) => {
  switch (dragItem.handlerCoordinateName) {
    case POSITIONS.W:
    case POSITIONS.E:
      return new ScalingCalculationHorizontal({
        dragItem,
        differenceFromInitialOffset
      });

    case POSITIONS.N:
    case POSITIONS.S:
      return new ScalingCalculationVertical({
        dragItem,
        differenceFromInitialOffset
      });

    default:
      return new ScalingCalculation({
        dragItem,
        differenceFromInitialOffset
      });
  }
};

export default ScalingCalculationFactory;
