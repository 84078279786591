import { rotateRect } from "lib/transform";

const getSideAngles = (pointOne, pointTwo) => {
  // This math returns the angle from the given X axis to another point(Y)
  // The closer the angle the two points are to 180 degres, the closer the returned number is to 0
  // We can use this on all four corner points of the selected element(s) to determine which is closest to 0
  return (
    (Math.atan2(pointTwo.y - pointOne.y, pointTwo.x - pointOne.x) * 180) /
    Math.PI
  );
};

const getElementHighestSide = element => {
  if (!element) {
    return;
  }

  // get bounding points of each side of the rotated elements exact top left bot right using rotateRect helper function
  const { top, left, width, height, angle } = element;
  const rotatedElement = rotateRect(left, top, width, height, angle);

  // assign corners of newly rotated element
  const topLeft = rotatedElement[0];
  const topRight = rotatedElement[1];
  const bottomRight = rotatedElement[2];
  const bottomLeft = rotatedElement[3];

  // get angle of each side by passing through the corner points of each side through getSideAnglesFunction
  const topSide = getSideAngles(topLeft, topRight);
  const rightSide = getSideAngles(topRight, bottomRight);
  const botSide = getSideAngles(bottomRight, bottomLeft);
  const leftSide = getSideAngles(bottomLeft, topLeft);

  // Once we have the angle of each side, which ever side is closest to 0 is the highest side
  const sideArray = [topSide, rightSide, botSide, leftSide];
  const highestSide = sideArray.reduce((a, b) => {
    return Math.abs(b) < Math.abs(a) ? b : a;
  });

  return {
    highestSide,
    sideArray: sideArray
  };
};

export default getElementHighestSide;
