import { getAnimatedElementData } from "lib/designUtils";

// need to use camelCase conversion here as these keys can be converted to camelCase
// and starting them as camelCase just keeps it consistent
export const getAnimationDataKey = ({ uniqueId, domId = "" }) =>
  `${uniqueId}${domId ? `-${domId}` : ""}`;

/**
 * @desc Takes in any element and returns extracted animation data for it
 * @param {object} element - the element to gather animation data for
 * @returns an array of animation data including the duration for the animated content
 */
export const getAnimationData = element => {
  let animationData = [];
  const animatedElementData = getAnimatedElementData(element);

  if (animatedElementData) {
    animationData = [animatedElementData];
  }

  // get imageInstruction data for every indiividual image in the element
  // pass each imageInstruction to be checked for animated duration property
  if (element.hasOwnProperty("imageInstructions")) {
    const animatedImageInstructionData = element.imageInstructions.map(
      imageInstruction => {
        const animatedImageInstructions = getAnimatedElementData(
          imageInstruction
        );

        if (animatedImageInstructions) {
          return animatedImageInstructions;
        }
        return null;
      }
    );
    if (animatedImageInstructionData.filter(x => x).length > 0) {
      animationData = animatedImageInstructionData.filter(x => x);
    }
  }

  // ensure that the uniqueId and animationDataKey are added to our dataPoints
  return animationData.map(dataPoint => ({
    // ...dataPoint,
    duration: dataPoint.duration,
    domId: dataPoint.domId,
    uniqueId: element.uniqueId,
    pageId: element.pageId,
    groupId: element.groupId,
    animationDataKey: getAnimationDataKey({
      uniqueId: element.uniqueId,
      domId: dataPoint.domId
    })
  }));
};
