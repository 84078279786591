import {
  boundingRectOfEllipse,
  boundingRectOfPolygon,
  getPathBounds
} from "lib/svgBoundingBoxUtils";

const heightKeys = [
  "height",
  "paddingTop",
  "paddingBottom",
  "borderTopWidth",
  "borderBottomWidth"
];
const widthKeys = [
  "width",
  "paddingLeft",
  "paddingRight",
  "borderLeftWidth",
  "borderRightWidth"
];

export const getCSSComputedBoundingClientRect = ({
  element,
  cssValueMultiplier
}) => {
  const clientBoundingRect = element.getBoundingClientRect();

  // get the css computed values
  const computedCss = window.getComputedStyle(element);

  const bounds = {
    width: 0,
    height: 0
  };

  const a = {};

  // join the results of these keys together to get the client values
  // which would be returned from getClientBoundingRect() usually
  heightKeys.forEach(key => {
    bounds.height += parseFloat(computedCss[key]);
    a[key] = parseFloat(computedCss[key]);
  });
  widthKeys.forEach(key => {
    bounds.width += parseFloat(computedCss[key]);
    a[key] = parseFloat(computedCss[key]);
  });

  // apply scaling since these are in css values
  bounds.height = bounds.height * cssValueMultiplier;
  bounds.width = bounds.width * cssValueMultiplier;

  if (element.nodeName === "circle") {
    // circles are a special case in svg where no style computation is made so we need to do this manually
    const circleRadius = element.getAttribute("r");
    const computedDimension = circleRadius * 2 * cssValueMultiplier;
    bounds.height = computedDimension;
    bounds.width = computedDimension;
  }

  if (element.nodeName === "path") {
    // paths also have special requirements for determining their dimensions when getClientBoundingRect() can not be used
    const pathBounds = getPathBounds(element);
    bounds.height = pathBounds.height * cssValueMultiplier;
    bounds.width = pathBounds.width * cssValueMultiplier;
  }

  if (element.nodeName === "ellipse") {
    const ellipseBoundingRect = boundingRectOfEllipse(element);

    bounds.height = ellipseBoundingRect.height * cssValueMultiplier;
    bounds.width = ellipseBoundingRect.width * cssValueMultiplier;
  }

  if (element.nodeName === "polygon") {
    const polygonBoundingRect = boundingRectOfPolygon(element);

    bounds.height = polygonBoundingRect.height * cssValueMultiplier;
    bounds.width = polygonBoundingRect.width * cssValueMultiplier;
  }

  // remove any empty keys
  const finalBounds = Object.keys(bounds)
    .filter(key => bounds[key] != null)
    .reduce((value, key) => ({ ...value, [key]: bounds[key] }), {});

  // return client bounds but add the newly computed width and height values
  return {
    ...clientBoundingRect,
    ...finalBounds
  };
};

export const getDimensionSource = (clipPath, defaultElement) => {
  const rect = clipPath.querySelector("rect");
  const circle = clipPath.querySelector("circle");
  const path = clipPath.querySelector("path");
  const ellipse = clipPath.querySelector("ellipse");
  const polygon = clipPath.querySelector("polygon");
  return (
    rect || circle || path || ellipse || polygon || defaultElement || clipPath
  );
};

export const getHtmlNodeCenter = node => {
  const nodeRect = node.getBoundingClientRect();

  return {
    y: nodeRect.top + nodeRect.height / 2,
    x: nodeRect.left + nodeRect.width / 2
  };
};
