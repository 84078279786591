import React from "react";
import style from "./style.module.css";
import { FontDropZone, Checkbox } from "views/components";

const FontModalUploadSection = ({
  handleUploadForm,
  deleteUploadedFile,
  onUsageRightsCheckboxClick,
  confirmUsageRights,
  hasFileUploaded,
  uploadingFonts
}) => {
  const onFontDropzoneChange = files => {
    if (files) {
      handleUploadForm(files);
    } else {
      deleteUploadedFile();
    }
  };

  const checkboxLabel =
    "I confirm that I have the right to use this for my intended purposes.";
  return (
    <div className={style.upload}>
      <div className={style.fontDropZone}>
        <FontDropZone
          onChange={onFontDropzoneChange}
          uploadingFonts={uploadingFonts}
        />
      </div>
      <Checkbox
        onClick={onUsageRightsCheckboxClick}
        checked={confirmUsageRights}
        label={checkboxLabel}
        disabled={!hasFileUploaded}
        checkboxClassName={style.checkbox}
      />
    </div>
  );
};
export default FontModalUploadSection;
