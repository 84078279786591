export const ALL_TEAM_ANIMATIONS_REQUEST =
  "API/ENTITIES/ALL_TEAM_ANIMATIONS_REQUEST";
export const ALL_TEAM_ANIMATIONS_REQUEST_FAILURE =
  "API/ENTITIES/ALL_TEAM_ANIMATIONS_REQUEST_FAILURE";
export const ALL_TEAM_ANIMATIONS_REQUEST_SUCCESS =
  "API/ENTITIES/ALL_TEAM_ANIMATIONS_REQUEST_SUCCESS";

export const TEAM_ANIMATIONS_REQUEST = "API/ENTITIES/TEAM_ANIMATIONS_REQUEST";
export const TEAM_ANIMATIONS_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_ANIMATIONS_REQUEST_FAILURE";
export const TEAM_ANIMATIONS_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_ANIMATIONS_REQUEST_SUCCESS";

export const TEAM_ANIMATIONS_CLEAR = "API/ENTITIES/TEAM_ANIMATIONS_CLEAR";

export const TEAM_VIDEOS_REQUEST = "API/ENTITIES/TEAM_VIDEOS_REQUEST";
export const TEAM_VIDEOS_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_VIDEOS_REQUEST_FAILURE";
export const TEAM_VIDEOS_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_VIDEOS_REQUEST_SUCCESS";

export const TEAM_ANIMATIONS_IN_FOLDER_REQUEST =
  "API/ENTITIES/TEAM_ANIMATIONS_IN_FOLDER_REQUEST";
export const TEAM_ANIMATIONS_IN_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_ANIMATIONS_IN_FOLDER_REQUEST_FAILURE";
export const TEAM_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS";

export const TEAM_ANIMATIONS_SEARCH_REQUEST =
  "API/ENTITIES/TEAM_ANIMATIONS_SEARCH_REQUEST";
export const TEAM_ANIMATIONS_SEARCH_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_ANIMATIONS_SEARCH_REQUEST_FAILURE";
export const TEAM_ANIMATIONS_SEARCH_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_ANIMATIONS_SEARCH_REQUEST_SUCCESS";

export const CLEAR_TEAM_ANIMATION_SEARCH_TERM =
  "API/ENTITIES/CLEAR_TEAM_ANIMATION_SEARCH_TERM";

export const TEAM_ANIMATION_DELETE_REQUEST =
  "API/ENTITIES/TEAM_ANIMATION_DELETE_REQUEST";
export const TEAM_ANIMATION_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_ANIMATION_DELETE_REQUEST_FAILURE";
export const TEAM_ANIMATION_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_ANIMATION_DELETE_REQUEST_SUCCESS";

export const TEAM_ANIMATION_UPDATE_REQUEST =
  "API/ENTITIES/TEAM_ANIMATION_UPDATE_REQUEST";
export const TEAM_ANIMATION_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_ANIMATION_UPDATE_REQUEST_FAILURE";
export const TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS";

export const TEAM_ANIMATION_UPLOAD_REQUEST =
  "API/ENTITIES/TEAM_ANIMATION_UPLOAD_REQUEST";
export const TEAM_ANIMATION_UPLOAD_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_ANIMATION_UPLOAD_REQUEST_FAILURE";
export const TEAM_ANIMATION_UPLOAD_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_ANIMATION_UPLOAD_REQUEST_SUCCESS";

export const TEAM_ANIMATION_UPLOAD_URL_REQUEST =
  "API/ENTITIES/TEAM_ANIMATION_UPLOAD_URL_REQUEST";
export const TEAM_ANIMATION_UPLOAD_URL_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_ANIMATION_UPLOAD_URL_REQUEST_FAILURE";
export const TEAM_ANIMATION_UPLOAD_URL_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_ANIMATION_UPLOAD_URL_REQUEST_SUCCESS";

export const ADD_TEAM_ANIMATIONS_PLACEHOLDERS =
  "API/ENTITIES/ADD_TEAM_ANIMATIONS_PLACEHOLDERS";

export const CLEAR_TEAM_ANIMATION_UPLOAD_ERRORS =
  "API/CLEAR_TEAM_ANIMATION_UPLOAD_ERRORS";
