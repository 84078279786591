import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

/* components */
import EasilTemplatesSettings from "./EasilTemplatesSettings";

/* selectors */
import { getIsCurrentTeamOrg as isCurrentTeamOrg } from "state/entities/teams/teamsSelectors";
import { userIsBrandManager } from "state/currentUser/currentUserSelectors";
import { teamSettingsSelector } from "state/entities/teamSettings/teamSettingsSelectors";

/* actions */
import {
  fetchTeamSettingsIfNeeded,
  updateTeamSettings
} from "state/entities/teamSettings/teamSettingsActions";

export const BrandSettingsContainer = ({
  isCurrentTeamOrg,
  isBrandManager,
  teamSettings = {},
  fetchTeamSettingsIfNeeded,
  updateTeamSettings
}) => {
  return (
    <div>
      <EasilTemplatesSettings
        isBrandManager={isBrandManager}
        isCurrentTeamOrg={isCurrentTeamOrg}
        teamAllowEasilTemplatesSetting={teamSettings.allowEasilTemplates}
        fetchTeamSettingsIfNeeded={fetchTeamSettingsIfNeeded}
        updateTeamSettings={updateTeamSettings}
      />
    </div>
  );
};
BrandSettingsContainer.displayName = "BrandSettingsContainer";
BrandSettingsContainer.propTypes = {
  isCurrentTeamOrg: PropTypes.bool,
  isBrandManager: PropTypes.bool,
  teamSettings: PropTypes.object,
  fetchTeamSettingsIfNeeded: PropTypes.func,
  updateTeamSettings: PropTypes.func
};

const mapStateToProps = state => {
  return {
    isCurrentTeamOrg: isCurrentTeamOrg(state),
    isBrandManager: userIsBrandManager(state),
    teamSettings: teamSettingsSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTeamSettingsIfNeeded: (...args) =>
      dispatch(fetchTeamSettingsIfNeeded(...args)),
    updateTeamSettings: (...args) => dispatch(updateTeamSettings(...args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandSettingsContainer);
