import { connect } from "react-redux";
import FontModal from "./FontModal";
import {
  publicFontsSelector,
  publicFontsIsLoadingSelector
} from "state/entities/publicFonts/publicFontsSelectors";
import {
  addBrandKitFont,
  asyncUploadBrandKitFont as uploadBrandKitFont,
  asyncPartialUpdateBrandKitFont as partialUpdateBrandKitFont
} from "state/ui/brandKit/BrandKitActions";

const mapStateToProps = state => {
  return {
    currentTeam: state.ui.currentTeam,
    currentUser: state.currentUser,
    publicFonts: publicFontsSelector(state),
    isLoadingPublicFonts: publicFontsIsLoadingSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    partialUpdateFont: teamFont =>
      dispatch(partialUpdateBrandKitFont(teamFont)),
    addFont: ({ teamId, teamFontObject }) =>
      dispatch(addBrandKitFont({ teamId, teamFontObject })),
    uploadFont: ({ fontFile, userId, teamId, teamFontObject }) =>
      dispatch(uploadBrandKitFont({ fontFile, userId, teamId, teamFontObject }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FontModal);
