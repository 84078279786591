import store from "state/store";
import { makeToast } from "state/ui/toaster/ToasterActions";
import {
  showUpdateNotification,
  hideUpdateNotification
} from "state/ui/updateNotification/updateNotificationActions";
import React from "react";

class ApplicationUpdateNotifier {
  static dispatchNotification(swUrl) {
    store.dispatch(
      makeToast({
        action: () => {
          const data = { type: "SKIP_WAITING" };
          // register the service worker again to get access to registration
          navigator.serviceWorker.register(swUrl).then(registration => {
            // tell the new service worker version to take control
            registration.waiting.postMessage(data);
          });
        },
        buttonLabel: "Refresh",
        icon: "success",
        textComponent: (
          <div>A new update is available. Please refresh to update Easil.</div>
        ),
        type: "button"
      })
    );
    // after making the toast we want to stop the notification happening more than once
    store.dispatch(hideUpdateNotification());
  }

  static flagNotificationForDispatch() {
    store.dispatch(showUpdateNotification());
  }
}

export default ApplicationUpdateNotifier;
