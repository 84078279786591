import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ProhibitedIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2zm0-2a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM2.398 12.188l9.893-9.893 1.43 1.399-9.975 9.974-1.348-1.48z"
      />
    </g>
  </svg>
);
ProhibitedIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ProhibitedIcon;
