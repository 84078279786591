import React from "react";
import { connect } from "react-redux";
import PurchaseCollectionModalContainer from "./PurchaseCollectionModalContainer";
import {
  purchaseCollectionModalOpenSelector,
  purchaseCollectionCollectionIdSelector
} from "state/ui/purchaseCollectionModal/purchaseCollectionModalSelectors";
import { closePurchaseCollectionModal } from "state/ui/purchaseCollectionModal/purchaseCollectionModalActions";

const PurchaseCollectionModalContainerGlobal = props => {
  if (props.isOpen) {
    return <PurchaseCollectionModalContainer {...props} />;
  }
  return null;
};

const mapStateToProps = state => {
  const isOpen = purchaseCollectionModalOpenSelector(state);
  const collectionId = purchaseCollectionCollectionIdSelector(state);
  return {
    isOpen,
    collectionId,
    isPurchaseCollectionModal: true
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseModal: () => dispatch(closePurchaseCollectionModal())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseCollectionModalContainerGlobal);
