import { memoize } from "lib/lodash";

function integerToHex(integer) {
  const parsed = (integer + 100).toString(16).toUpperCase();
  return ("0" + parsed).slice(-2);
}

// Hue can store values up to 360 and takes 3 places
function integerToHexHue(integer) {
  const parsed = (integer + 100).toString(16).toUpperCase();
  return ("00" + parsed).slice(-3);
}

function toPercentage(integer) {
  return integer + 100;
}

export function filterSettings(initialHex = "64646464646464064") {
  const hex = String(initialHex).split("");
  function hexToInteger(hex: string): number {
    return parseInt(hex, 16) - 100;
  }

  return {
    brightness: hexToInteger(hex[0] + hex[1]),
    contrast: hexToInteger(hex[2] + hex[3]),
    saturation: hexToInteger(hex[4] + hex[5]),
    grayscale: hexToInteger(hex[6] + hex[7]),
    invert: hexToInteger(hex[8] + hex[9]),
    sepia: hexToInteger(hex[10] + hex[11]),
    blur: hexToInteger(hex[12] + hex[13]),
    hue: hexToInteger(hex[14] + hex[15] + hex[16])
  };
}

export const filterSettingsToCSS = memoize(
  processFilterSettingsToCSS,
  initialHex => `${initialHex}`
);

export function processFilterSettingsToCSS(initialHex) {
  const filter = filterSettings(initialHex);

  function toDecimal(integer: number): number {
    return integer / 100.0 + 1;
  }

  return {
    brightness: toDecimal(filter.brightness),
    contrast: toPercentage(filter.contrast),
    saturation: toPercentage(filter.saturation),
    grayscale: filter.grayscale,
    invert: filter.invert,
    sepia: filter.sepia,
    blur: filter.blur,
    hue: filter.hue
  };
}

export function toHex(settings) {
  return `${integerToHex(settings.brightness)}${integerToHex(
    settings.contrast
  )}${integerToHex(settings.saturation)}${integerToHex(
    settings.grayscale
  )}${integerToHex(settings.invert)}${integerToHex(
    settings.sepia
  )}${integerToHex(settings.blur)}${integerToHexHue(settings.hue)}`;
}

export function validateHexFilter(hexFilter) {
  return hexFilter.toUpperCase() === toHex(filterSettings(hexFilter));
}

export const toStyleString = memoize(
  processToStyleString,
  (settings, zoom = 1.0) => `${JSON.stringify(settings)}-${zoom}`
);

export function processToStyleString(settings, zoom = 1.0) {
  const filters = [];

  if (settings.brightness !== 1) {
    filters.push(`brightness(${settings.brightness})`);
  }

  if (settings.contrast !== 100) {
    filters.push(`contrast(${settings.contrast}%)`);
  }

  if (settings.saturation !== 100) {
    filters.push(`saturate(${settings.saturation}%)`);
  }

  if (settings.grayscale !== 0) {
    filters.push(`grayscale(${settings.grayscale}%)`);
  }

  if (settings.invert !== 0) {
    filters.push(`invert(${settings.invert}%)`);
  }

  if (settings.hue !== 0) {
    filters.push(`hue-rotate(${settings.hue}deg)`);
  }

  if (settings.sepia !== 0) {
    filters.push(`sepia(${settings.sepia}%)`);
  }

  if (settings.blur !== 0) {
    filters.push(`blur(${settings.blur * zoom}px)`);
  }

  return filters.join(" ");
}

function svgBlurFilter(val) {
  // https://docs.webplatform.org/wiki/css/functions/blur
  const amount = parseInt(val, 10);

  return `<feGaussianBlur stdDeviation="${amount}"></feGaussianBlur>`;
}

function svgBrightnessFilter(val) {
  // https://docs.webplatform.org/wiki/css/functions/brightness
  const amount = parseFloat(val);

  return `
    <feComponentTransfer color-interpolation-filters="sRGB">
      <feFuncR type="linear" slope="${amount}"></feFuncR>
      <feFuncG type="linear" slope="${amount}"></feFuncG>
      <feFuncB type="linear" slope="${amount}"></feFuncB>
    </feComponentTransfer>
  `;
}

function svgContrastFilter(val) {
  // https://docs.webplatform.org/wiki/css/functions/contrast
  const amount = (parseFloat(val) - 100) / 100 + 1;

  return `
    <feComponentTransfer color-interpolation-filters="sRGB">
      <feFuncR type="linear" slope="${amount}" intercept="${-(0.5 * amount) +
    0.5}"></feFuncR>
      <feFuncG type="linear" slope="${amount}" intercept="${-(0.5 * amount) +
    0.5}"></feFuncG>
      <feFuncB type="linear" slope="${amount}" intercept="${-(0.5 * amount) +
    0.5}"></feFuncB>
    </feComponentTransfer>
  `;
}

function svgSaturateFilter(val) {
  // https://docs.webplatform.org/wiki/css/functions/saturate
  const amount = parseFloat(val) / 100;

  return `
    <feColorMatrix type="matrix" color-interpolation-filters="sRGB"
     values="
       ${0.213 + 0.787 * amount} ${0.715 - 0.715 * amount} ${0.072 -
    0.072 * amount} 0 0
       ${0.213 - 0.213 * amount} ${0.715 + 0.295 * amount} ${0.072 -
    0.072 * amount} 0 0
       ${0.213 - 0.213 * amount} ${0.715 - 0.715 * amount} ${0.072 +
    0.928 * amount} 0 0
       0 0 0 1 0"
    ></feColorMatrix>
  `;
}

function svgGrayscaleFilter(val) {
  // https://docs.webplatform.org/wiki/css/functions/grayscale
  const amount = parseFloat(val) / 100;

  return `
    <feColorMatrix type="matrix"
     values="
       ${0.2126 + 0.7874 * (1 - amount)} ${0.7152 -
    0.7152 * (1 - amount)} ${0.0722 - 0.0722 * (1 - amount)} 0 0
       ${0.2126 - 0.2126 * (1 - amount)} ${0.7152 +
    0.2848 * (1 - amount)} ${0.0722 - 0.0722 * (1 - amount)} 0 0
       ${0.2126 - 0.2126 * (1 - amount)} ${0.7152 -
    0.7152 * (1 - amount)} ${0.0722 + 0.9278 * (1 - amount)} 0 0
       0 0 0 1 0"
    ></feColorMatrix>
  `;
}

function svgHueFilter(val) {
  const amount = parseFloat(val);

  return `<feColorMatrix type="hueRotate" color-interpolation-filters="sRGB" values="${amount}" ></feColorMatrix>`;
}

function svgSepiaFilter(val) {
  // https://docs.webplatform.org/wiki/css/functions/sepia
  const amount = parseFloat(val) / 100;

  return `
    <feColorMatrix type="matrix" color-interpolation-filters="sRGB"
      values="${0.393 + 0.607 * (1 - amount)} ${0.769 -
    0.769 * (1 - amount)} ${0.189 - 0.189 * (1 - amount)} 0 0
              ${0.349 - 0.349 * (1 - amount)} ${0.686 +
    0.314 * (1 - amount)} ${0.168 - 0.168 * (1 - amount)} 0 0
              ${0.272 - 0.272 * (1 - amount)} ${0.534 -
    0.534 * (1 - amount)} ${0.131 + 0.869 * (1 - amount)} 0 0
               0 0 0 1 0"
    ></feColorMatrix>
  `;
}

function svgInvertFilter(val) {
  // https://docs.webplatform.org/wiki/css/functions/invert
  const amount = parseInt(val, 10) / 100;

  return `
    <feComponentTransfer color-interpolation-filters="sRGB">
      <feFuncR type="table" tableValues="${amount} ${1 - amount}"></feFuncR>
      <feFuncG type="table" tableValues="${amount} ${1 - amount}"></feFuncG>
      <feFuncB type="table" tableValues="${amount} ${1 - amount}"></feFuncB>
    </feComponentTransfer>
  `;
}

export function filterSettingsToSVGElements(initialHex = "64646464646464000") {
  // https://github.com/iamvdo/pleeease-filters
  const filter = filterSettingsToCSS(initialHex);

  return [
    svgBlurFilter(filter.blur),
    svgBrightnessFilter(filter.brightness),
    svgContrastFilter(filter.contrast),
    svgSaturateFilter(filter.saturation),
    svgGrayscaleFilter(filter.grayscale),
    svgHueFilter(filter.hue),
    svgSepiaFilter(filter.sepia),
    svgInvertFilter(filter.invert)
  ];
}
