import React from "react";
import { easilGrayXDark } from "./style.module.css";

const ArrowCircleIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => {
  const finalStyle = {
    ...style
  };

  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0-.002h24v24H0z" />
        <path
          stroke={color || easilGrayXDark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4.282 9.331c.937-2.59 3.167-4.527 5.907-5.134 2.74-.606 5.607.205 7.593 2.148L22 10.22M2 13.776l4.218 3.875c1.986 1.943 4.853 2.754 7.593 2.148 2.74-.606 4.97-2.545 5.907-5.134"
        />
        <g
          stroke={color || easilGrayXDark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M22 4.887v5.333h-5.455M2 19.109v-5.333h5.455" />
        </g>
      </g>
    </svg>
  );
};

export default ArrowCircleIcon;
