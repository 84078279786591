import { Logger } from "lib";

import calculateMovedNewDimensions from "./calculateMovedNewDimensions";
import calculateScaledNewDimensions from "./calculateScaledNewDimensions";
import CalculateCropMaskScale from "./calculateCropMaskScale";

export const calculateNewDimensions = ({
  dragItemType,
  dragItem,
  imageElement,
  differenceFromInitialOffset,
  zoom,
  pageOffset,
  adjustmentOffset
}) => {
  switch (dragItemType) {
    case "IMAGE_CROP":
    case "VECTOR_CROP":
    case "VIDEO_CROP":
      return calculateMovedNewDimensions({
        imageElement,
        differenceFromInitialOffset,
        zoom
      });

    case "IMAGE_CROP_RESIZE_HANDLER":
    case "VECTOR_CROP_RESIZE_HANDLER":
    case "VIDEO_CROP_RESIZE_HANDLER":
      return calculateScaledNewDimensions({
        imageElement,
        differenceFromInitialOffset,
        zoom,
        dragItem,
        pageOffset
      });

    case "IMAGE_CROP_HANDLER":
    case "VECTOR_CROP_HANDLER":
    case "VIDEO_CROP_HANDLER":
      return CalculateCropMaskScale.calc({
        imageElement,
        differenceFromInitialOffset,
        zoom,
        dragItem,
        pageOffset,
        adjustmentOffset
      });

    default:
      Logger.error("Invalid Image Cropping Preview DragType Handler");
  }
};
