import React from "react";
import InfoHollowIcon from "views/components/icons/InfoHollowIcon";
import style from "./style.module.css";

const Message = ({ children, icon: Icon = InfoHollowIcon }) => (
  <div className={style.messageContainer}>
    <Icon color={style.easilBlue} size="20px" className={style.messageIcon} />
    <div className={style.messageContent}>{children}</div>
  </div>
);

export default Message;
