import React from "react";
import style from "./style.module.css";
import { NavLink } from "react-router-dom";
import { Button } from "../../components";
import HeaderDropdownLink from "./HeaderDropdownLink";

export const HeaderBottomPanelLeft = ({ links, onCreateDesign }) => {
  const visibleLinks = Object.values(links).filter(
    link => link.visible === true
  );
  return (
    <div data-testid="HeaderBottomPanelLeft">
      <Button
        theme="whiteBorderWhiteFontWithTransition"
        className={style.createDesignButton}
        onClick={onCreateDesign}
      >
        Create Design
      </Button>
      {visibleLinks.map(link => {
        const sharedLinks = Object.values(links).filter(
          comparisonLink => comparisonLink.title === link.title
        );
        let isOnSharedUrl = false;
        if (sharedLinks.length > 1) {
          const matchingLink = sharedLinks.find(sharedLink =>
            window.location.pathname.startsWith(sharedLink.url)
          );
          isOnSharedUrl = Boolean(matchingLink);
        }
        if (link.isDropdown) {
          return (
            <HeaderDropdownLink
              label={link.title}
              options={link.dropdownOptions}
              urlBase={link.urlBase}
              key={link.title}
            />
          );
        }
        return (
          <NavLink
            key={link.title}
            className={`${style.navlink} ${
              isOnSharedUrl ? style.navlinkActive : ""
            }`}
            activeClassName={style.navlinkActive}
            to={link.url || "/"}
          >
            {link.title}
          </NavLink>
        );
      })}
    </div>
  );
};

HeaderBottomPanelLeft.displayName = "HeaderBottomPanelLeft";

export default HeaderBottomPanelLeft;
