import * as creditsApiTypes from "state/api/credits/creditsApiTypes";

export const dummyCredit = {
  removeBackground: {
    endAt: "n/a",
    total: 0,
    consumed: 0,
    id: "n/a",
    teamId: "n/a",
    updatedAt: "n/a",
    createdAt: "n/a",
    userId: "n/a",
    startAt: "n/a"
  }
};

export const initState = {};

const creditsReducers = (state = initState, action) => {
  switch (action.type) {
    case creditsApiTypes.CREDITS_REQUEST_SUCCESS: {
      return action.response.entity || dummyCredit;
    }

    default:
      return state;
  }
};

export default creditsReducers;
