import React from "react";
import { connect } from "react-redux";
import TeamDefaultChooser from "./TeamDefaultChooser";
import { fetchTeamIfNeededV2 } from "state/entities/teams/teamsActions";
import { clearAll } from "state/ui/header/TeamMenu/TeamMenuActions";

export const TeamDefaultChooserContainer = props => {
  return <TeamDefaultChooser {...props} />;
};

const mapStateToProps = (state, ownProps) => {
  const defaultTeamId = state.ui.userPreferences.defaultTeamId;
  return {
    currentDefaultTeam: state.entities.teams[defaultTeamId],
    defaultTeamId
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchTeam: args => dispatch(fetchTeamIfNeededV2(args)),
    clearMenuState: args => dispatch(clearAll(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamDefaultChooserContainer);
