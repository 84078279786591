class SSO {
  static init() {
    SSO.setAuthToken();
  }

  static getSSOToken() {
    // Borrowed from https://openid.net/specs/openid-connect-core-1_0.html#FragmentNotes
    const params = {};
    const postBody = window.location.hash.substring(1);
    const regex = /([^&=]+)=([^&]*)/g;
    let match;
    while ((match = regex.exec(postBody))) {
      params[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
    }

    return params["access_token"];
  }

  static setAuthToken() {
    if (SSO.getSSOToken()) {
      localStorage.setItem("auth_token", SSO.getSSOToken());
    }
  }
}

export default SSO;
