import * as removeBackgroundApiTypes from "state/api/removeBackground/removeBackgroundApiTypes.js";
import { APP_UI_WORKSPACE_REMOVE_BG_RESET_MODAL } from "./removeBgTypes";
import { parseClientErrorMessages } from "lib";

const initState = {
  isProcessing: false,
  /* errors attribute also represents the state of the ui,
   * null - means no request has been made
   * [] -  request was success
   * [somethingHere] - request has fail with somethingHere error */
  errors: null // when populated it will be an array
};

const removeBgReducers = (state = initState, action) => {
  switch (action.type) {
    // Remove Background Action
    case APP_UI_WORKSPACE_REMOVE_BG_RESET_MODAL: {
      return initState;
    }
    case removeBackgroundApiTypes.REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST:
    case removeBackgroundApiTypes.REMOVE_BACKGROUND_REQUEST: {
      // indicate is processing
      return {
        ...state,
        isProcessing: true
      };
    }

    case removeBackgroundApiTypes.REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST_FAILURE:
    case removeBackgroundApiTypes.REMOVE_BACKGROUND_REQUEST_FAILURE: {
      if (!action.error) {
        action.error = {
          errors: [
            {
              path: "Unknown",
              message: "Unknown",
              type: "Unknown",
              friendlyMessage:
                "Something went wrong. Please try again, if the problem persist, please contact support"
            }
          ]
        };
      }

      /* while backend doesn't handle this properly */
      if (action.error && action.error[0] && action.error[0].title) {
        action.error = {
          errors: [
            {
              path: "image size",
              message: action.error[0].title,
              type: action.error[0].code,
              friendlyMessage: "Could not find a well defined foreground object"
            }
          ]
        };
      }

      return {
        ...state,
        isProcessing: false,
        errors: parseClientErrorMessages(action.error)
      };
    }

    case removeBackgroundApiTypes.REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST_SUCCESS:
    case removeBackgroundApiTypes.REMOVE_BACKGROUND_REQUEST_SUCCESS: {
      // indicate has finished processing
      return {
        ...state,
        isProcessing: false,
        errors: []
      };
    }

    default:
      return state;
  }
};

export default removeBgReducers;
