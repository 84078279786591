import React from "react";
import PropTypes from "prop-types";

const FacebookRoundIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color = "#FFFFFF",
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path
      d="M18 9.055C18 4.055 13.97 0 9 0S0 4.054 0 9.055c0 4.52 3.291 8.266 7.594 8.945v-6.328H5.309V9.055h2.285V7.06c0-2.27 1.343-3.523 3.4-3.523.984 0 2.014.177 2.014.177v2.228h-1.135c-1.118 0-1.467.698-1.467 1.414v1.699h2.496l-.399 2.617h-2.097V18C14.71 17.32 18 13.575 18 9.055"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
FacebookRoundIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FacebookRoundIcon;
