import React from "react";
import BrandKitLayout from "views/components/BrandKit/BrandKitLayout/BrandKitLayout";
import BrandKitSearch from "views/components/BrandKit/BrandKitSearch/BrandKitSearch";
import BrandKitContentSection from "./BrandKitContentSection";
class BrandKit extends React.Component {
  constructor(props) {
    super(props);

    this.updateState = this.updateState.bind(this);

    this.state = {
      fontModal: {
        fontId: null,
        font: null,
        visible: false
      },
      popoutFontId: null,
      logos: {
        sortKey: "UPDATEDAT_DESC",
        isAddingFolder: false,
        qsFolderId: null,
        qsTerm: null,
        isProcessing: false
      },
      images: {
        sortKey: "UPDATEDAT_DESC",
        isAddingFolder: false,
        qsFolderId: null,
        qsTerm: null,
        isProcessing: false
      },
      animations: {
        sortKey: "UPDATEDAT_DESC",
        qsTerm: null
      }
    };
  }

  updateState(stateUpdate = {}, updateKey) {
    if (updateKey) {
      return this.setState({
        [updateKey]: {
          ...this.state[updateKey],
          ...stateUpdate
        }
      });
    }

    this.setState(stateUpdate);
  }

  render() {
    const {
      colors: { entries }
    } = this.props;

    return (
      <BrandKitLayout>
        <BrandKitSearch
          {...this.props}
          colors={entries}
          setBrandKitState={this.updateState}
          logosState={this.state.logos}
          imagesState={this.state.images}
          animationsState={this.state.animations}
        />
        <BrandKitContentSection
          {...this.props}
          setBrandKitState={this.updateState}
          fontModal={this.state.fontModal}
          popoutFontId={this.state.popoutFontId}
          logosState={this.state.logos}
          imagesState={this.state.images}
          animationsState={this.state.animations}
        />
      </BrandKitLayout>
    );
  }
}

export default BrandKit;
