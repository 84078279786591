import React, { Component } from "react";
import style from "./style.module.css";
import ScrollingOverflowText from "views/components/ScrollingOverflowText/ScrollingOverflowText";
import { Tooltip } from "views/components";
import { SmoothPencilIcon, TrashCanIcon, RevertIcon } from "../icons";

/**
 * Smart text row displays the name and value of a smart text enitity. The row allows for editing and deletion of the entity
 */
export default class SmartTextRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { smartText, handleOpenModal, selectRow, handleDelete } = this.props;

    return (
      <div
        className={`${style.smartTextRow}`}
        key={smartText.name}
        data-testid="smartTextRow"
      >
        <div className={style.smartTextRowTextTop}>
          <ScrollingOverflowText className={style.smartTextValue}>
            {smartText.value}
          </ScrollingOverflowText>
          <div className={style.rowIcons}>
            <div
              className={style.pencilIconWrapper}
              onClick={() => {
                handleOpenModal();
                selectRow(smartText);
              }}
            >
              <SmoothPencilIcon
                size="24px"
                tipText={"Edit"}
                tipFor={"edit-icon"}
              />
              <Tooltip id="edit-icon" place="bottom" />
            </div>
            <div
              className={style.trashCanIconWrapper}
              onClick={() => {
                handleDelete(smartText);
                selectRow(smartText);
              }}
              data-testid="deleteSmartTextIcon"
            >
              {smartText.overridden ? (
                <>
                  <RevertIcon
                    tipText={"Revert to Default"}
                    tipFor={"revert-icon"}
                  />
                  <Tooltip id="revert-icon" place="bottom" />
                </>
              ) : (
                <>
                  <TrashCanIcon
                    size="23px"
                    tipText={"Delete"}
                    tipFor={"delete-icon"}
                  />
                  <Tooltip id="delete-icon" place="bottom" />
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <ScrollingOverflowText className={style.smartTextName}>
            {`{${smartText.name}}`}
          </ScrollingOverflowText>
        </div>
      </div>
    );
  }
}
