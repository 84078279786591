import { confirm } from "lib/ConfirmationModal/ConfirmationModal";

const confirmDeleteFolder = ({ onOk, onCancel }) => {
  const header = "Permanently delete folder";
  const text = `Are you sure you want to permanently delete your folder?
                Once deleted, you won't be able to recover the folder or it's contents.`;
  const buttonsDetails = {
    ok: {
      theme: "redSolid",
      name: "Delete"
    },
    cancel: {
      theme: "gray"
    }
  };

  return () => {
    return confirm({
      header,
      text,
      onOk,
      onCancel,
      buttonsDetails
    });
  };
};

export default confirmDeleteFolder;
