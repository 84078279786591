import * as types from "state/entities/designs/designsTypes";
import { getPath } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";

export const initState = {
  isFetching: false,
  pageSize: 100
};

const collectionApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.COLLECTION_DESIGNS_REQUEST: {
      return immutableUpdate(state, {
        isFetching: { $set: true },
        $auto: {
          [action.extra.collectionId]: {
            $auto: {
              ids: {
                $set: []
              }
            }
          }
        }
      });
    }

    case types.COLLECTION_DESIGNS_REQUEST_SUCCESS: {
      const {
        response: { ids },
        extra: { collectionId }
      } = action;

      return immutableUpdate(state, {
        isFetching: { $set: false },
        $auto: {
          [collectionId]: {
            $auto: {
              ids: {
                $push: ids || []
              }
            }
          }
        }
      });
    }

    case types.COLLECTION_PRICE_REQUEST: {
      return immutableUpdate(state, {
        isFetching: { $set: true },
        $auto: {
          [action.extra.collectionId]: {
            $set: {
              collectionPrice: {},
              ids: []
            }
          }
        }
      });
    }

    case types.COLLECTION_PRICE_REQUEST_SUCCESS: {
      const {
        response: {
          entities: { collection }
        },
        extra: { collectionId }
      } = action;

      const { ids, priceAud, priceUsd } = collection[collectionId];

      // take subscriptionCode from first design
      const subscriptionCode = getPath(
        collection,
        `${collectionId}.designs.0.subscriptionCode`,
        ""
      );

      return immutableUpdate(state, {
        isFetching: { $set: false },
        $auto: {
          [collectionId]: {
            $set: {
              ids,
              collectionPrice: {
                priceAud,
                priceUsd
              },
              subscriptionCode
            }
          }
        }
      });
    }

    case types.COLLECTION_PRICE_REQUEST_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isFetching: false,
        error
      };
    }

    default:
      return state;
  }
};

export default collectionApiReducers;
