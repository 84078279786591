import React, { Component } from "react";
import style from "./style.module.css";
import { Modal, Input } from "views/components";
import { stripHTML } from "lib/textUtils";
import QuickInput from "views/components/QuickInput/QuickInput";

class AddTeamSmartTextModal extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.canSave = this.canSave.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      newName: null,
      newValue: null,
      error: null
    };
  }

  onChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  canSave() {
    const { newName, newValue, error } = this.state;

    if (newName === null || newValue === null) {
      return false;
    }

    if (
      newName !== null &&
      newName === "" &&
      newValue !== null &&
      newValue === ""
    ) {
      return false;
    }

    if (error && error !== null) {
      return false;
    }

    return true;
  }

  handleSave() {
    const { newName, newValue } = this.state;

    const cleanValue = stripHTML(newValue);

    const smartText = {
      [newName]: { value: cleanValue, type: "string" }
    };

    this.props.addTeamSmartText({
      smartText,
      onSuccess: () => this.props.onCloseModal()
    });
  }

  handleBlur() {
    const { newName } = this.state;
    const { smartText } = this.props;

    let error;

    const isSmartTextInUse = () => {
      let smartTextInUse = false;
      smartTextInUse = smartText.find(text => text.name === newName);
      return !!smartTextInUse;
    };

    switch (true) {
      case !newName:
        error = <span>Enter a smart text ID.</span>;
        this.setState({ error: error });
        break;
      case newName && newName.length <= 1:
        error = <span>Must contain at least 2 characters.</span>;
        this.setState({ error: error });
        break;
      case isSmartTextInUse():
        error = <span>Already in use.</span>;
        this.setState({ error: error });
        break;
      case newName && !newName.match(/^[-a-zA-Z0-9._]+$/):
        error = <span>Cannot contain invalid characters.</span>;
        this.setState({ error: error });
        break;
      case newName &&
        !newName.match(/^(?!.*[._-]{2})[a-zA-Z0-9]+[._-]?[a-zA-Z0-9]+$/):
        error = <span>Cannot contain more than one separator.</span>;
        this.setState({ error: error });
        break;
      case newName && newName.startsWith("user."):
        error = (
          <span>
            User Smart Text can be added in{" "}
            <span
              onClick={() => this.props.moveToWorkspaceSmartText()}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              My Uploads.
            </span>
          </span>
        );
        this.setState({ error: error });
        break;
      default:
        this.setState({ error: null });
        return;
    }
  }

  handleKeyDown(event) {
    if (event.code === "Space") event.preventDefault();
  }

  render() {
    const { isOpen, onCloseModal } = this.props;
    const { newName, newValue, error } = this.state;

    const getInputFieldError = () => error;

    const labelErrorColor = {
      color: "#c01818",
      marginBottom: "8px"
    };

    const labelColor = {
      color: "#7a7d81",
      marginBottom: "8px"
    };

    return (
      <Modal
        className={style.addSmartTextModal}
        isOpen={isOpen}
        contentLabel="Add Smart Text"
        onRequestClose={onCloseModal}
        header="Add Smart Text"
        buttonsClassName={style.modalButtons}
        buttons={[
          {
            name: "Cancel",
            theme: "gray",
            onClick: onCloseModal
          },
          {
            name: "Save",
            theme: "blueSolid",
            onClick: this.handleSave,
            disabled: !this.canSave()
          }
        ]}
      >
        <div className={style.modalFields}>
          <div className={style.modalInputRow}>
            <div style={error ? labelErrorColor : labelColor}>
              <span
                style={
                  error
                    ? { fontWeight: 500, paddingRight: "4px" }
                    : { fontWeight: "normal", paddingRight: "4px" }
                }
              >
                Smart Text ID
              </span>
              {getInputFieldError()}
            </div>
            <QuickInput
              name="newName"
              onChange={this.onChange}
              value={newName}
              placeholder="Enter Smart Text ID"
              className={style.modalInputSplit}
              inputClassName={style.modalInput}
              onBlur={this.handleBlur}
              hasInputError={getInputFieldError()}
              errorColor={"#c01818"}
              onKeyDown={this.handleKeyDown}
            />
          </div>
          <div className={style.modalInputRow}>
            <div style={labelColor}>Smart Text Content</div>
            <Input
              name="newValue"
              onChange={this.onChange}
              value={newValue}
              placeholder="Type your Smart Text content here"
              className={style.modalInputWrapper}
              inputClassName={style.modalInput}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddTeamSmartTextModal;
