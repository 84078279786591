import { noop } from "lib/lodash";
import { fetchFonts } from "lib/fonts";
import { CanvasBackground } from "views/components/Editor/CanvasBackground/CanvasBackground";
import ScrollableStyle from "views/components/Scrollable/style.module.css";
import { fetchSvgs } from "lib/svg/svg";
import { getElementsBox } from "lib/elements";
import { EDITOR_ELEMENTS_MAP } from "lib/constants";

window.easil.svgs = {};

export const loadDesignAssets = ({ elements, fonts, fontsUrls = [] }) => {
  const assets = {
    images: new Set(),
    vectors: new Set(),
    fontFiles: new Set(fontsUrls),
    fontNames: new Set(fonts)
  };
  Object.keys(elements).forEach(elementId => {
    const element = elements[elementId];

    switch (element.type) {
      case "image":
        assets.images.add(element.src);
        break;
      case "vector":
        assets.vectors.add(element.src);
        break;
      case "textbox":
        if (element.maskImage) {
          assets.images.add(element.maskImage.src);
        }
        break;
      default:
        noop();
    }
  });

  return Promise.all([
    ...fetchImages(Array.from(assets.images)),
    ...fetchSvgs([...assets.vectors]),
    fetchFonts([...assets.fontFiles], [...assets.fontNames])
  ]);
};

export const loadDesignFontsAndVectors = ({
  elements,
  fonts,
  fontsUrls = []
}) => {
  const assets = {
    vectors: new Set(),
    fontFiles: new Set(fontsUrls),
    fontNames: new Set(fonts)
  };

  Object.keys(elements).forEach(elementId => {
    const element = elements[elementId];

    switch (element.type) {
      case "vector":
        assets.vectors.add(element.src);
        break;
      case "textbox":
        if (element.maskImage) {
          assets.images.add(element.maskImage.src);
        }
        break;
      default:
        noop();
    }
  });

  return Promise.all([
    ...fetchSvgs([...assets.vectors]),
    fetchFonts([...assets.fontFiles], [...assets.fontNames])
  ]);
};

const fetchImages = urls => {
  return urls.map(src => {
    return new Promise(resolve => {
      const image = new Image();
      // on load we want to resolve that the image is ready
      image.onload = () => resolve(`Image ${src} Ready`);
      // on error we want to resolve the image so that the editor is not held back by failing image sources
      image.onerror = () => resolve(`Image ${src} Failed to Load`);
      image.src = src;
    });
  });
};

export const getCanvasbackground = () => {
  return document.getElementById(CanvasBackground.htmlId);
};

export const getPageHtmlNode = pageId => {
  return document.getElementById(`PAGE-${pageId}`);
};

export const getCanvasbackgroundContent = () => {
  const canvasbackgroundHtml = getCanvasbackground();

  return canvasbackgroundHtml.querySelector(`.${ScrollableStyle.content}`);
};

export const getCanvasbackgroundContentWrapper = () => {
  const canvasbackgroundHtml = getCanvasbackground();

  // guard clause to return if nothing is collected
  if (!canvasbackgroundHtml) return;

  return canvasbackgroundHtml.querySelector(
    `.${ScrollableStyle.contentWrapper}`
  );
};

export const getPageOffsetInCanvasbackground = pageId => {
  const backgroundContentWrapper = getCanvasbackgroundContentWrapper();

  const pageHtmlElement = getPageHtmlNode(pageId);

  return {
    top: pageHtmlElement.offsetTop - backgroundContentWrapper.scrollTop,
    left: pageHtmlElement.offsetLeft - backgroundContentWrapper.scrollLeft
  };
};

export function areHeadersButtonsLocked(design, currentUser) {
  if (currentUser.roles.includes("BRAND_MANAGER")) {
    return false;
  }

  if (!design) {
    return true;
  }

  switch (design.status) {
    case "PENDING_APPROVAL":
    case "DECLINED": {
      return true;
    }

    case "DRAFT": {
      return design.requiresApproval;
    }

    default: {
      return false;
    }
  }
}

export function areFunctionalitiesLocked(
  design,
  currentUser,
  isEditorReady = true,
  isSharedDialogOpen
) {
  // when the editor is not ready always consider functionalities locked
  if (!isEditorReady || isSharedDialogOpen) return true;

  if (currentUser.roles.includes("BRAND_MANAGER")) {
    return false;
  }

  const lockedStatus = ["APPROVED", "DECLINED", "PENDING_APPROVAL"];

  return !!design && lockedStatus.includes(design.status);
}

export const getActionBarButtonTipText = (
  isRichText,
  propsIsDisabled,
  elementTypes,
  button,
  areCellsLocked
) => {
  // rich text disabled state
  if (isRichText && propsIsDisabled)
    return "Not available with highlighted text";
  // table2 cell is locked
  if (areCellsLocked) return "Locked for this cell";
  // disabled prop passed in from higher order element restriction
  if (propsIsDisabled) return "Locked for this element";
  // curved text is active for selected element
  if (elementTypes && elementTypes.includes(EDITOR_ELEMENTS_MAP.VECTOR_TEXT))
    return "Not available with curved text";
  // default
  return button;
};

export const calculatePageVerticalExtents = pageIds => {
  const firstPage = document.querySelector(`div[id="PAGE-${pageIds[0]}"]`);
  if (!firstPage) {
    return [];
  }
  const firstPageBounding = firstPage.getBoundingClientRect();
  const editorContentDiv = document.querySelector(
    "#CANVAS-BACKGROUND-CONTENT-WRAPPER"
  );
  const editorContentBounding = editorContentDiv.getBoundingClientRect();

  const firstPageOffset = firstPageBounding.top - editorContentBounding.top;

  const pageMargin = Number(
    window
      .getComputedStyle(firstPage)
      .marginBottom.split("px")
      .shift()
  );

  const pageHeight = firstPageBounding.height;

  return pageIds.reduce((pageAccumulator, pageId) => {
    const lastPage = pageAccumulator[pageAccumulator.length - 1];
    let newPage = {};
    if (!lastPage) {
      newPage = {
        id: pageId,
        top: 0 + editorContentDiv.scrollTop,
        bottom: firstPageOffset + editorContentDiv.scrollTop + pageHeight
      };
    } else {
      const newPageTop = lastPage.bottom + pageMargin;
      newPage = {
        id: pageId,
        top: newPageTop,
        bottom: newPageTop + pageHeight
      };
    }

    return [...pageAccumulator, newPage];
  }, []);
};

export const flipSelectionOnAxis = (axis, selectedElements) => {
  const selectionBoxBounds = getElementsBox(selectedElements);
  selectionBoxBounds.right = selectionBoxBounds.left + selectionBoxBounds.width;
  selectionBoxBounds.bottom =
    selectionBoxBounds.top + selectionBoxBounds.height;

  const elementUpdates = {};

  switch (axis) {
    case "x": {
      selectedElements.forEach(selectedElement => {
        const newRight =
          selectionBoxBounds.width -
          (selectedElement.left - selectionBoxBounds.left);
        const newLeft =
          newRight - selectedElement.width + selectionBoxBounds.left;
        elementUpdates[selectedElement.uniqueId] = {
          left: newLeft,
          scaleX: selectedElement.scaleX * -1
        };
      });
      break;
    }
    case "y": {
      selectedElements.forEach(selectedElement => {
        const newBottom =
          selectionBoxBounds.height -
          (selectedElement.top - selectionBoxBounds.top);
        const newTop =
          newBottom - selectedElement.height + selectionBoxBounds.top;
        elementUpdates[selectedElement.uniqueId] = {
          top: newTop,
          scaleY: selectedElement.scaleY * -1
        };
      });
      break;
    }
    default: {
      break;
    }
  }

  return elementUpdates;
};

export const isEditorInIframe = () =>
  window.location !== window.parent.location;

export const calculateZoom = design =>
  Math.min(
    Math.round((window.innerWidth / (2 * design.width)) * 10) / 10, // estimates the zoom level for the width
    Math.ceil((window.innerHeight / (1.5 * design.height)) * 10) / 10 // estimates the zoom level for the height
  );
