import * as types from "./teamBillingTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";

export const fetchBilling = ({ teamId }) => ({
  [CALL_API]: {
    method: "GET",
    service: SERVICES.USER,
    endpoint: `/teams/${teamId}/billing/details`,
    schema: schemas.TEAM_BILLING,
    types: [
      types.TEAM_BILLING_REQUEST,
      types.TEAM_BILLING_REQUEST_SUCCESS,
      types.TEAM_BILLING_REQUEST_FAILURE
    ]
  }
});

export const updateBilling = ({ teamId, billingDetails }) => ({
  [CALL_API]: {
    method: "PUT",
    service: SERVICES.USER,
    endpoint: `/teams/${teamId}/billing/details`,
    schema: schemas.TEAM_BILLING,
    types: [
      types.TEAM_BILLING_UPDATE_REQUEST,
      types.TEAM_BILLING_UPDATE_REQUEST_SUCCESS,
      types.TEAM_BILLING_UPDATE_REQUEST_FAILURE
    ],
    request: {
      body: billingDetails
    }
  }
});
