import React, { Component } from "react";

export class Table2CellDragHandle extends Component {
  render() {
    const {
      position,
      handleResizeMouseDown,
      zoom,
      cellHeight,
      cellWidth
    } = this.props;
    const isColumnResizeHandle = ["left", "right"].includes(position);

    // handle width should be calculated as 10% of cells width/height
    // 20px max handle width zoom adjusted
    const handleWidth = 0.1 * (isColumnResizeHandle ? cellWidth : cellHeight);
    const scaledWidth = Math.min(handleWidth, 20) * zoom;

    return (
      <div
        style={{
          [position]: 0,
          height: isColumnResizeHandle ? "100%" : `${scaledWidth}px`,
          width: isColumnResizeHandle ? `${scaledWidth}px` : "100%",
          position: "absolute",
          zIndex: 2147483647,
          cursor: isColumnResizeHandle ? "ew-resize" : "ns-resize"
        }}
        data-border={position}
        onMouseDown={handleResizeMouseDown}
      />
    );
  }
}

export default Table2CellDragHandle;
