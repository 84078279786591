export const FETCH_ALL_FOLDER_ALLOCATIONS_REQUEST =
  "API/FOLDER_ALLOCATIONS/FETCH_ALL_TEAMS_REQUEST";
export const FETCH_ALL_FOLDER_ALLOCATIONS_SUCCESS =
  "API/FOLDER_ALLOCATIONS/FETCH_ALL_TEAMS_SUCCESS";
export const FETCH_ALL_FOLDER_ALLOCATIONS_FAILURE =
  "API/FOLDER_ALLOCATIONS/FETCH_ALL_TEAMS_FAILURE";

export const ADD_FOLDER_ALLOCATIONS_REQUEST =
  "API/FOLDER_ALLOCATIONS/ADD_TEAMS_REQUEST";
export const ADD_FOLDER_ALLOCATIONS_SUCCESS =
  "API/FOLDER_ALLOCATIONS/ADD_TEAMS_SUCCESS";
export const ADD_FOLDER_ALLOCATIONS_FAILURE =
  "API/FOLDER_ALLOCATIONS/ADD_TEAMS_FAILURE";

export const REMOVE_FOLDER_ALLOCATIONS_REQUEST =
  "API/FOLDER_ALLOCATIONS/REMOVE_TEAMS_REQUEST";
export const REMOVE_FOLDER_ALLOCATIONS_SUCCESS =
  "API/FOLDER_ALLOCATIONS/REMOVE_TEAMS_SUCCESS";
export const REMOVE_FOLDER_ALLOCATIONS_FAILURE =
  "API/FOLDER_ALLOCATIONS/REMOVE_TEAMS_FAILURE";
