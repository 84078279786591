import PATHS from "routes/paths";
import { isNil } from "lib";

class InputMode {
  static startModeIfNeeded() {
    if (isNil(InputMode.getImQueryParam())) {
      InputMode.checkIfEditorIsLoadingImDesign();
    } else {
      InputMode.init();
    }
  }

  static exit() {
    localStorage.removeItem("isImMode");
    localStorage.removeItem("lastImDesignId");
  }

  static init(designId) {
    InputMode.setImMode(true);
    InputMode.setLastImDesignId();
  }

  static isImModeOn() {
    return Boolean(localStorage.getItem("isImMode"));
  }

  static getImQueryParam() {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get("inputMode");
  }

  static checkIfEditorIsLoadingImDesign() {
    if (!PATHS.isEditorPath(window.location.pathname)) return;

    const lastImDesignId = InputMode.getLastImDesignId();
    const designId = InputMode.getEditorDesignId();

    if (lastImDesignId === designId) {
      InputMode.setImMode(true);
    }
  }
  static getEditorDesignId() {
    const [, , designId] = window.location.pathname.split("/");

    return designId;
  }

  static setImMode(value) {
    localStorage.setItem("isImMode", value);
  }

  static getLastImDesignId() {
    return localStorage.getItem("lastImDesignId");
  }

  static setLastImDesignId() {
    return localStorage.setItem(
      "lastImDesignId",
      InputMode.getEditorDesignId()
    );
  }
}

export default InputMode;
