import { default as Logger } from "./logger";
import templateSizesThumbnails from "./templateSizesThumbnails";

/**
 * Determines if the template should display in "in" format.
 *
 * @param templateCode String templateCode of the templateSize
 * @return {boolean}
 */
export const shouldDisplayInInches = templateCode => {
  const usaTemplateTypes = [
    "banner2438914",
    "businesscard336192",
    "businesscard192336",
    "postcard_landscape",
    "postcard_portrait",
    "poster457610",
    "rack_card",
    "us_half_letter",
    "us_letter",
    "us_letter_landscape",
    "greeting_card_portrait",
    "greeting_card_landscape"
  ];
  return usaTemplateTypes.includes(templateCode);
};

/**
 * Determines if the template should display in px based on the templateCode and Type.
 * Some templates are of templateType 'print', which is normally px based, however there are
 * exceptions we need to cater for.
 *
 * @param templateCode String templateCode of the templateSize
 * @param templateType String templateType of the templateSize
 * @return {boolean}
 */
export const shouldDisplayInPx = (templateCode, templateType) => {
  const isImageTemplateType = templateType === "image";
  const printMeasurementOverrideTypes = [
    "presentation_16_9",
    "presentation",
    "landscape_tv_full_hd",
    "landscape_tv",
    "landscape_nightlife_hd"
  ];
  return (
    isImageTemplateType || printMeasurementOverrideTypes.includes(templateCode)
  );
};

/**
 * Determine which dimensions to use when displaying the dimensions of a templateSize.
 * @param templateSize Entity object containing the side code (id), type and dimensions
 * @return {{unit: string, width, height}|{unit: string, width: *, height: *}}
 */
export const calculateTemplateSizeDisplayMeasurements = templateSize => {
  // Note: Unfortunately the UI maps templateSize.templateCode to templateSize.id in some cases and
  // leaves it as templateCode in others.
  const templateCode = templateSize.templateCode || templateSize.id;

  // It's been requested that custom template size does not show measurement (currently 0x0mm)
  if (templateCode === "custom") {
    return null;
  }

  const { templateType } = templateSize;

  if (shouldDisplayInInches(templateCode)) {
    return {
      width: templateSize.widthInInches,
      height: templateSize.heightInInches,
      unit: "in"
    };
  } else if (shouldDisplayInPx(templateCode, templateType)) {
    return {
      width: templateSize.width,
      height: templateSize.height,
      unit: "px"
    };
  } else {
    return {
      width: templateSize.widthInMm,
      height: templateSize.heightInMm,
      unit: "mm"
    };
  }
};

/**
 * Determine which dimensions to display for the given design.
 * @param design The design to determine the dimensions for.
 * @return {{unit: string, width, height}|{unit: string, width: *, height: *}}
 */
export const calculateDisplayMeasurements = design => {
  switch (design.measurementUnits) {
    case "in": {
      return {
        width: design.widthInInches,
        height: design.heightInInches,
        unit: "in"
      };
    }

    case "px": {
      return { width: design.width, height: design.height, unit: "px" };
    }

    case "mm": {
      return { width: design.widthInMm, height: design.heightInMm, unit: "mm" };
    }

    default:
      // Default rather than breaking
      Logger.error("Measurement unit not supported");
      return { width: 0, height: 0, unit: "px" };
  }
};

export const getTemplateThumbnail = (templateCode, region = "AU") => {
  if (region !== "US") region = "AU";
  return (
    templateSizesThumbnails[region][templateCode] ||
    templateSizesThumbnails.AU.instagram1080 // default australian instagram thumbnail
  );
};
