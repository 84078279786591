import React from "react";
import style from "./style.module.css";
import { Loading } from "../../components";
import easilLoader from "./easil-loader.gif";

const FullPageLoading = ({ type = "spinner" }) => {
  const vectorsAnimation = (
    <img
      src={easilLoader}
      style={{ width: "100px", height: "100px" }}
      alt="vectors animation"
    />
  );

  const types = {
    spinner: <Loading />,
    vectors: vectorsAnimation
  };
  const animation = types[type];

  return (
    <div className={style.center} data-testid="FullPageLoading">
      {animation}
    </div>
  );
};

export default FullPageLoading;
