import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import CrossThinIcon from "views/components/icons/CrossThinIcon";

const ToastWrapper = ({ children, closeToast, className }) => {
  return (
    <div className={`${style.wrapper} ${className}`}>
      <div className={style.leftPanel}>{children}</div>
      <div className={style.rightPanel} onClick={closeToast}>
        <CrossThinIcon color="#ffff" size="14" />
      </div>
    </div>
  );
};

ToastWrapper.propTypes = {
  children: PropTypes.element,
  closeToast: PropTypes.func.isRequired
};

export default ToastWrapper;
