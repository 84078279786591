import { createSelector } from "reselect";
import { designById } from "state/entities/designs/designsSelectors";
import { currentOrderIdSelector } from "state/ui/cart/cartSelectors";
import { sortBy, omit, getPath } from "lib";

const IN_PROGRESS = "IN_PROGRESS";
const ERRORED = "ERROR";

export const ordersEntitiesSelector = state => state.entities.orders;

export const orderByIdSelector = ({ state, orderId }) =>
  ordersEntitiesSelector(state)[orderId];

export const orderByIdWithFullDesignSelector = ({ state, orderId }) => {
  const order = orderByIdSelector({ state, orderId });
  if (!order || !order.designs) return null;

  // map design entities to the order designs
  const orderDesigns = order.designs.map(design => {
    const designEntity = designById({ state, designId: design.designId });
    return {
      ...(designEntity
        ? {
            ...omit(designEntity, [
              "updatedAt",
              "thumbnailUrl",
              "thumbnailGenerating"
            ]),
            designUpdatedAt: designEntity.updatedAt
          }
        : {}),
      ...design
    };
  });
  return {
    ...order,
    designs: orderDesigns
  };
};

export const getCurrentOrderByIdSelector = ({ state }) => {
  const orderId = currentOrderIdSelector(state);
  if (!orderId) return undefined;
  return orderByIdSelector({ state, orderId });
};

export const getCurrentOrderByIdWithFullDesignSelector = ({ state }) => {
  const orderId = currentOrderIdSelector(state);
  return orderByIdWithFullDesignSelector({ state, orderId });
};

const processOrdersList = orders => {
  if (!orders) return [];

  return Object.keys(orders).map(id => orders[id]);
};

const ordersList = createSelector([ordersEntitiesSelector], processOrdersList);

const processPlacedOrders = availableOrders => {
  const orders = availableOrders.filter(order => order.status !== IN_PROGRESS);

  return sortBy(orders, order => {
    return order.updatedAt;
  }).reverse();
};

export const placedOrders = createSelector([ordersList], processPlacedOrders);

const processCurrentInProgressOrder = ordersEntities => {
  if (!ordersEntities) return null;

  const orders = Object.keys(ordersEntities).map(id => ordersEntities[id]);

  const inProgressOrder = orders.find(order => order.status === IN_PROGRESS);

  if (inProgressOrder) return inProgressOrder;

  const erroredOrders = orders.filter(order => order.status === ERRORED);

  return sortBy(erroredOrders, ["createdAt"])
    .slice(-1)
    .pop();
};

export const currentInProgressOrder = createSelector(
  [ordersEntitiesSelector],
  processCurrentInProgressOrder
);

const designsEntitiesSelector = ({ state }) => state.entities.designs;
const designIdsPassThrough = ({ designIds }) => designIds;

const processOrderDesignsMeta = (designs, designIds) => {
  if (!designs) return [];

  return Object.keys(designs)
    .map(id => designs[id])
    .filter(design => designIds.includes(design.id));
};

export const orderDesignsMeta = createSelector(
  [designsEntitiesSelector, designIdsPassThrough],
  processOrderDesignsMeta
);

export const getCouponByOrderIdSelector = ({ state, orderId }) => {
  return getPath(orderByIdSelector({ state, orderId }), "couponCode", null);
};
