import { mapErrorsToEntities } from "./helpers";
import { TEAMS_INVITE_USERS_REQUEST_SUCCESS } from "state/entities/teams/teamsTypes";

export const initState = {};

const inviteToTeamFormLoadingReducers = (state = initState, action) => {
  switch (action.type) {
    case TEAMS_INVITE_USERS_REQUEST_SUCCESS: {
      const { ids, entities } = action.response;
      const { entries } = state;

      const responseErrors = ids.map(id => entities.users[id].error);

      const entriesWithErrors = mapErrorsToEntities({
        responseErrors,
        entries
      });

      if (entriesWithErrors.length === 0) {
        return {
          entries: [],
          status: "ALL_COMPLETED"
        };
      }

      return {
        entries: entriesWithErrors,
        status: "INVALID"
      };
    }

    default:
      return state;
  }
};

export default inviteToTeamFormLoadingReducers;
