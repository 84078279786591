import React from "react";
import style from "./style.module.css";

const TeamRoleInfoMessage = ({ className }) => {
  return (
    <div className={`${style.teamRoleContent} ${className}`}>
      <div>
        Roles in Easil are cumulative, meaning that more than one role can be
        assigned to each team member.
      </div>
      <a
        href="https://support.easil.com/updating-roles-and-permissions-of-team-members/"
        rel="noopener noreferrer"
        target="_blank"
        className={style.learnMore}
      >
        Learn more.
      </a>
    </div>
  );
};

export default TeamRoleInfoMessage;
