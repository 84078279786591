import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const LogosIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-4-2h24v24H-4z" />
      <path
        fill={color}
        stroke="none"
        fillRule="nonzero"
        d="M6.45 12.568H1a1 1 0 0 1-.775-1.631L8.83.369c.668-.82 1.985-.178 1.75.853L9.263 7.02H15a1 1 0 0 1 .784 1.621l-8.708 10.98c-.646.816-1.954.224-1.767-.8l1.14-6.253zm1.198-2a1 1 0 0 1 .984 1.18l-.638 3.497 4.937-6.225H8.009a1 1 0 0 1-.975-1.222l.649-2.853-4.58 5.623h4.545z"
      />
    </g>
  </svg>
);
LogosIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default LogosIcon;
