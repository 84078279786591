import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

const Bubble = ({ className, size, theme, noBorder, display }) => {
  const divStyle = {
    border: noBorder && "none",
    width: size,
    height: size
  };

  return (
    <div
      className={`${style.avatar} ${style[theme]} ${className}`}
      style={divStyle}
      data-testid="Bubble"
    >
      <div className={`${style.chars}`}>{display}</div>
    </div>
  );
};
Bubble.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.string,
  name: PropTypes.string,
  display: PropTypes.string,
  noBorder: PropTypes.bool,
  src: PropTypes.string
};

export default Bubble;
