import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CalendarIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-24">
        <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        <g
          id="calendar"
          transform="translate(3.000000, 2.000000)"
          fill={color || easilGrayXDark}
          fillRule="nonzero"
        >
          <path
            d="M13,0 C13.5128358,0 13.9355072,0.38604019 13.9932723,0.883378875 L14,1 L14,2 L15,2 C16.5976809,2 17.9036609,3.24891996 17.9949073,4.82372721 L18,5 L18,17 C18,18.6568542 16.6568542,20 15,20 L15,20 L3,20 C1.34314575,20 0,18.6568542 0,17 L0,17 L0,5 C0,3.34314575 1.34314575,2 3,2 L3,2 L4,2 L4,1 C4,0.44771525 4.44771525,0 5,0 C5.51283584,0 5.93550716,0.38604019 5.99327227,0.883378875 L6,1 L6,2 L12,2 L12,1 C12,0.44771525 12.4477153,0 13,0 Z M16,9 L2,9 L2,17 C2,17.5128358 2.38604019,17.9355072 2.88337887,17.9932723 L3,18 L15,18 C15.5522847,18 16,17.5522847 16,17 L16,17 L16,9 Z M4,4 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,5 L2,7 L16,7 L16,5 C16,4.48716416 15.6139598,4.06449284 15.1166211,4.00672773 L15,4 L14,4 L14,5 C14,5.55228475 13.5522847,6 13,6 C12.4871642,6 12.0644928,5.61395981 12.0067277,5.11662113 L12,5 L12,4 L6,4 L6,5 C6,5.55228475 5.55228475,6 5,6 C4.48716416,6 4.06449284,5.61395981 4.00672773,5.11662113 L4,5 L4,4 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);
CalendarIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CalendarIcon;
