import * as types from "./teamSettingsTypes";

export const initState = {
  allowEasilTemplates: false
};

const teamSettingsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_SETTINGS_REQUEST_SUCCESS: {
      const { ids: teamId, entities } = action.response;

      return entities.teamSettings[teamId];
    }

    case types.TEAM_SETTINGS_UPDATE_REQUEST: {
      const { body: teamSettings } = action.request;

      return teamSettings;
    }

    case types.TEAM_SETTINGS_REQUEST_FAILURE: {
      return state;
    }

    default:
      return state;
  }
};

export default teamSettingsReducer;
