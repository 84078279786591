import React from "react";
import style from "./style.module.css";
import PaymentOptions from "./PaymentOptions";

export const PaymentDetails = ({
  setToken,
  paymentType,
  setPaymentType,
  token,
  createOrderPayment,
  createPaypalOrder,
  currencyCode,
  clearPaymentError,
  paymentErrors
}) => {
  return (
    <div className={style.wrapper}>
      <div className={style.title}>Payment details</div>
      <div className={style.paymentOptions}>
        <PaymentOptions
          handleSelection={setPaymentType}
          type={paymentType}
          setToken={setToken}
          token={token}
          availableTypes={["credit", "paypal"]} // can add paypal to this list when it is available
          createOrderPayment={createOrderPayment}
          createPaypalOrder={createPaypalOrder}
          currencyCode={currencyCode}
          clearPaymentError={clearPaymentError}
          paymentErrors={paymentErrors}
        />
      </div>
    </div>
  );
};

PaymentDetails.displayName = "PaymentDetails";

export default PaymentDetails;
