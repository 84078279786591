import React from "react";
import PropTypes from "prop-types";
import { Modal, Input } from "views/components";
import style from "./style.module.css";
import { passwordErrorParse } from "lib/errorHandling";
import { userValidators, getPath } from "lib";
import { CHANGE_PASSWORD_INPUT_FIELDS } from "lib/constants";

const {
  NEW_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  CURRENT_PASSWORD
} = CHANGE_PASSWORD_INPUT_FIELDS;

const initState = {
  newPasswordInput: {},
  confirmNewPasswordInput: {},
  currentPasswordInput: {},
  error: {}
};

export class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);

    this.getIsReadyToSubmit = this.getIsReadyToSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.getButtons = this.getButtons.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.doPasswordsMatch = this.doPasswordsMatch.bind(this);

    this.state = initState;
  }

  getIsReadyToSubmit() {
    // if both newPasswordInput and confirmNewPasswordInput are the same then we can submit
    const {
      newPasswordInput,
      confirmNewPasswordInput,
      currentPasswordInput
    } = this.state;
    const { updatePasswordState } = this.props;
    const passwordStates = [
      newPasswordInput,
      confirmNewPasswordInput,
      currentPasswordInput
    ];
    const arePasswordFieldsValid = passwordStates.every(input => !!input.value);
    const hasErrorsInPasswordFields = passwordStates.some(
      input => !!input.error
    );

    if (!arePasswordFieldsValid || hasErrorsInPasswordFields) return false;

    return this.doPasswordsMatch() && !updatePasswordState.isProcessing;
  }

  handleUpdate(field, value) {
    const { [field]: currentValue } = this.state;
    const { error } = userValidators["password"](value);

    if (currentValue === value) return;

    this.setState({
      [field]: {
        value: value,
        error: error
      }
    });
  }

  handleClose() {
    this.props.onClose();
    this.setState(initState);
  }

  handleChangePassword() {
    const { onChangeCurrentUserPassword } = this.props;
    const { newPasswordInput: newPassword, currentPasswordInput } = this.state;

    onChangeCurrentUserPassword({
      newPassword: newPassword.value,
      password: currentPasswordInput.value,
      onSuccess: this.handleClose
    });
  }

  getButtons() {
    const {
      updatePasswordState: { isProcessing }
    } = this.props;
    const isReadyToSubmit = this.getIsReadyToSubmit();
    return [
      {
        name: "Cancel",
        theme: "gray",
        onClick: this.handleClose
      },
      {
        name: isProcessing ? "Saving..." : "Save Password",
        theme: "blueSolid",
        onClick: () => {
          if (isReadyToSubmit) {
            this.handleChangePassword();
          }
          return;
        },
        disabled: !isReadyToSubmit
      }
    ];
  }

  doPasswordsMatch() {
    const { newPasswordInput, confirmNewPasswordInput } = this.state;

    return newPasswordInput.value === confirmNewPasswordInput.value;
  }

  handleBlur() {
    const { newPasswordInput, confirmNewPasswordInput } = this.state;

    if (
      !newPasswordInput.value ||
      newPasswordInput.value === "" ||
      !confirmNewPasswordInput.value ||
      confirmNewPasswordInput.value === ""
    ) {
      return;
    }

    let error;

    if (!this.doPasswordsMatch()) {
      error = "Passwords don't match";
    }

    this.setState({
      [NEW_PASSWORD]: {
        ...this.state.newPasswordInput,
        error
      },
      [CONFIRM_NEW_PASSWORD]: {
        ...this.state.confirmNewPasswordInput,
        error
      }
    });
  }

  render() {
    const {
      isOpen,
      updatePasswordState: { error }
    } = this.props;
    const buttons = this.getButtons();
    const getPasswordFieldError = field => getPath(this.state[field], "error");

    return (
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={this.handleClose}
          className={style.modal}
          crossColor="#afb3b6"
          header="Change Password"
          buttons={buttons}
          buttonsClassName={style.buttons}
        >
          <div>
            <div className={style.inputContainer}>
              <div className={style.inputTitle}>New Password</div>
              <Input
                className={style.input}
                onChange={e => this.handleUpdate(NEW_PASSWORD, e.target.value)}
                type="password"
                onBlur={this.handleBlur}
                showErrorMsg
                error={getPasswordFieldError(NEW_PASSWORD)}
              />
            </div>
            <div className={style.inputContainer}>
              <div className={style.inputTitle}>Confirm New Password</div>
              <Input
                className={style.input}
                onChange={e =>
                  this.handleUpdate(CONFIRM_NEW_PASSWORD, e.target.value)
                }
                type="password"
                onBlur={this.handleBlur}
                showErrorMsg
                error={getPasswordFieldError(CONFIRM_NEW_PASSWORD)}
              />
            </div>
            <div className={style.inputContainer}>
              <div className={style.inputTitle}>Current Password</div>
              <Input
                className={style.input}
                onChange={e =>
                  this.handleUpdate(CURRENT_PASSWORD, e.target.value)
                }
                type="password"
              />
            </div>
            {error && (
              <div className={style.error}>
                {passwordErrorParse(error.message, error)}
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeCurrentUserPassword: PropTypes.func,
  updatePasswordState: PropTypes.object
};

ChangePasswordModal.displayName = "ChangePasswordModal";

export default ChangePasswordModal;
