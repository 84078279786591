import React from "react";
import style from "./style.module.css";
import { Dropdown } from "views/components";
import LabelFloatInput from "views/components/LabelFloatInput/LabelFloatInput";
import { noop } from "lib";

/**
 * @desc gets the component to show for this input mapping in its props
 * @param {object} input - object describing the input field wanted
 * @param {boolean} showWarning - if the input box should show a yellow warning outline
 * @returns JSX Component fragment
 */
const getInputComponent = (
  input,
  showWarning,
  activeLabel,
  selectActiveLabel = noop
) => {
  const onChange = input.onChange || noop;
  const onBlur = input.onBlur || noop;
  const customWrapperStyle = input.width ? { width: input.width } : {};
  switch (input.type) {
    case "dropdown": {
      const joinedOptions = Array.isArray(input.options[0])
        ? input.options.reduce(
            (allOptions, optionList) => [...allOptions, ...optionList],
            []
          )
        : input.options;
      const selectedOption = input.value
        ? joinedOptions.find(option => option.key === input.value)
        : joinedOptions.find(option => option.key === input.default);
      const selected = selectedOption ? selectedOption.label : input.default;
      return (
        <Dropdown
          inputTitle={`${input.label}${input.optional ? " (optional)" : ""}`}
          selected={selected}
          onChange={value => {
            onChange({ key: input.key, value });
            onBlur({ key: input.key, value });
          }}
          options={input.options}
          className={style.input}
          inputWrapperClassName={style.dropdownWrapper}
          popoutClassName={style.dropdownPopout}
          caretColor="#afb3b6"
          caretSize="10px"
          error={input.error}
          customWrapperStyle={customWrapperStyle}
          disabled={!input.options.length}
          isSearchable={input.isSearchable}
          searchPlaceholder={input.searchPlaceholder}
          isActive={input.label === activeLabel}
          onClick={() => selectActiveLabel(input.label)}
        />
      );
    }
    case "number":
    case "text":
    case "textarea":
    default: {
      const inputClasses = [style.input];
      if (input.error) {
        inputClasses.push(style.error);
      } else if (showWarning) {
        inputClasses.push(style.warn);
      }
      return (
        <LabelFloatInput
          value={input.value || ""}
          label={`${input.label}${input.optional ? " (optional)" : ""}`}
          onChange={event => {
            onChange({ key: input.key, value: event.target.value });
          }}
          onBlur={event => {
            onBlur({ key: input.key, value: event.target.value });
          }}
          className={style.input}
          wrapperClassName={inputClasses.join(" ")}
          customWrapperStyle={customWrapperStyle}
          type={input.type}
          height={input.height || "44px"}
          error={input.error}
          min={input.min}
          max={input.max}
          dataPrivate={input.dataPrivate || null}
          isActive={input.label === activeLabel}
          onFocus={() => selectActiveLabel(input.label)}
        />
      );
    }
  }
};

export class InputItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showWarning: false
    };
  }

  componentDidUpdate(prevProps) {
    // value change and input is not optional
    if (
      !this.props.input.value &&
      prevProps.input.value &&
      !this.props.input.optional
    ) {
      this.setState({
        showWarning: true
      });
    }

    if (
      this.props.input.value &&
      !prevProps.input.value &&
      this.state.showWarning
    ) {
      this.setState({
        showWarning: false
      });
    }
  }

  render() {
    const { input, activeLabel, selectActiveLabel } = this.props;
    const { showWarning } = this.state;

    return getInputComponent(
      input,
      showWarning,
      activeLabel,
      selectActiveLabel
    );
  }
}

export class InputRow extends React.Component {
  constructor(props) {
    super(props);

    this.rowRef = React.createRef();
  }

  componentDidMount() {
    const currentElement = this.rowRef.current;
    if (currentElement) {
      currentElement.style.setProperty(
        "--row-spacing-x",
        this.props.rowSpacingX
      );
      currentElement.style.setProperty(
        "--row-spacing-y",
        this.props.rowSpacingY
      );
    }
  }

  render() {
    const { row, activeLabel, selectActiveLabel } = this.props;

    const rowHeights = row.map(
      input =>
        Number((input.height || "44px").split("px").shift()) +
        (input.error ? 20 : 0)
    );
    const rowHeight = Math.max(...rowHeights);
    const styles = {
      height: `${rowHeight}px`
    };
    if (!rowHeight) {
      styles.opacity = 0;
      styles.margin = 0;
      styles.transition =
        "height 0.5s ease, margin 0.3s ease, opacity 0.3s ease";
      styles.overflow = "hidden";
    }
    return (
      <div className={style.inputRow} style={styles} ref={this.rowRef}>
        {row.map((input, index) => (
          <InputItem
            input={input}
            key={`${input.label}-${index}`}
            activeLabel={activeLabel}
            selectActiveLabel={selectActiveLabel}
          />
        ))}
      </div>
    );
  }
}

InputRow.displayName = "InputRow";

export default InputRow;
