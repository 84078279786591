import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const EditSmartImageIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="TrashCanShortIcon"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        stroke={color || easilGrayXDark}
        stroke-linecap="round"
        stroke-width="2"
        d="M16 6.5v-2a2 2 0 0 0-2-2H4.5a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2H7"
      />
      <path
        stroke={color || easilGrayXDark}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 8.5 11 7l-1.75 4L7.5 9.25l-1.5 3"
      />
      <circle cx="6.75" cy="6.25" r="1.25" fill="#57595D" fill-rule="nonzero" />
      <g
        stroke={color || easilGrayXDark}
        stroke-linecap="round"
        stroke-width="2"
      >
        <path
          stroke-linejoin="round"
          d="M17 12.5 12.5 17h0l-2.5.5.5-2.5 4.5-4.5a1.414 1.414 0 1 1 2 2z"
        />
        <path d="m14 12 1.5 1.5" />
      </g>
    </g>
  </svg>
);

EditSmartImageIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default EditSmartImageIcon;
