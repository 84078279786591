import React from "react";
import { Link } from "react-router-dom";
import TIcon from "views/components/icons/TIcon";
import PATHS from "routes/paths";
import style from "./style.module.css";
import common from "../common.module.css";
import ColorIcon from "views/components/icons/ColorIcon";
import ImageAltIcon from "views/components/icons/ImageAltIcon";
import LogosAltIcon from "views/components/icons/LogosAltIcon";
import CogAltIcon from "views/components/icons/CogAltIcon";
import VideoIcon from "views/components/icons/VideoIcon";
import TextCaretIcon from "views/components/icons/TextCaretIcon";

export default function BrandKitNav({ location }) {
  const getIconColor = pathMatch =>
    location.pathname === pathMatch ? "#2c2e2f" : "#57595d";

  return (
    <>
      <div className={common.contentTitle}>Brand Kit</div>

      <div className={style.brandKitMenuOptions}>
        <Link
          className={style.brandKitMenuOption}
          data-active={location.pathname === PATHS.brandColors}
          to={PATHS.brandColors}
        >
          <span className={style.brandKitMenuOptionIcon}>
            <ColorIcon
              color={getIconColor(PATHS.brandColors)}
              height="24px"
              width="24px"
            />
          </span>
          <span>Brand Colors</span>
        </Link>

        <Link
          className={style.brandKitMenuOption}
          data-active={location.pathname === PATHS.brandFonts}
          to={PATHS.brandFonts}
        >
          <span
            className={style.brandKitMenuOptionIcon}
            style={{ padding: "2px 0 0 2px" }}
          >
            <TIcon color={getIconColor(PATHS.brandFonts)} size="20px" />
          </span>
          <span>Brand Fonts</span>
        </Link>

        <Link
          className={style.brandKitMenuOption}
          data-active={location.pathname === PATHS.brandLogos}
          to={PATHS.brandLogos}
        >
          <span className={style.brandKitMenuOptionIcon}>
            <LogosAltIcon
              color={getIconColor(PATHS.brandLogos)}
              height="24px"
              width="24px"
            />
          </span>
          <span>Brand Logos</span>
        </Link>

        <Link
          className={style.brandKitMenuOption}
          data-active={location.pathname === PATHS.brandImages}
          to={PATHS.brandImages}
        >
          <span className={style.brandKitMenuOptionIcon}>
            <ImageAltIcon
              color={getIconColor(PATHS.brandImages)}
              height="24px"
              width="24px"
            />
          </span>
          <span>Brand Images</span>
        </Link>

        <Link
          className={style.brandKitMenuOption}
          data-active={location.pathname === PATHS.brandAnimations}
          to={PATHS.brandAnimations}
        >
          <span className={style.brandKitMenuOptionIcon}>
            <VideoIcon
              color={getIconColor(PATHS.brandAnimations)}
              height="20px"
              width="20px"
              style={{ marginLeft: "2px" }}
            />
          </span>
          <span>Brand Videos</span>
        </Link>

        <Link
          className={style.brandKitMenuOption}
          data-active={location.pathname === PATHS.brandSmartText}
          to={PATHS.brandSmartText}
        >
          <span
            className={style.brandKitMenuOptionIcon}
            data-testid="brandKitSmartTextNav"
          >
            <TextCaretIcon
              color={getIconColor(PATHS.brandSmartText)}
              height="20px"
              width="20px"
              style={{ marginLeft: "2px" }}
            />
          </span>
          <span>Smart Text</span>
        </Link>

        <Link
          className={style.brandKitMenuOption}
          data-active={location.pathname === PATHS.brandSettings}
          to={PATHS.brandSettings}
        >
          <span className={style.brandKitMenuOptionIcon}>
            <CogAltIcon
              color={getIconColor(PATHS.brandSettings)}
              height="24px"
              width="24px"
            />
          </span>
          <span>Brand Settings</span>
        </Link>
      </div>
    </>
  );
}
