import React from "react";
import { connect } from "react-redux";
import {
  fetchNextPageOfTeamsIfNeeded,
  fetchNextPageOfSubTeamsIfNeeded,
  fetchTeamsCount
} from "state/entities/teams/teamsActions";
import { teamsTotalCountSelector } from "state/entities/teams/teamsSelectors";
import {
  isFetchingTeamsSelector,
  selectedTeamSelector,
  teamsSelector
} from "state/ui/header/TeamMenu/TeamMenuSelectors";
import {
  navigateDown,
  navigateUp,
  searchTeams,
  clearSearch
} from "state/ui/header/TeamMenu/TeamMenuActions";
import TeamDefaultChooserPopout from "./TeamDefaultChooserPopout";

export const TeamDefaultChooserPopoutContainer = props => (
  <TeamDefaultChooserPopout {...props} />
);

const mapStateToProps = state => {
  const selectedTeam = selectedTeamSelector(state);
  const teams = teamsSelector(state, selectedTeam);
  const teamCount = teamsTotalCountSelector(state);
  const isFetchingTeams = isFetchingTeamsSelector(state);

  return {
    currentUser: state.currentUser,
    currentTeam: state.ui.currentTeam,
    teams,
    teamCount,
    isFetchingTeams,
    selectedTeam
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTeamsIfNeeded: args => dispatch(fetchNextPageOfTeamsIfNeeded(args)),
    fetchNextPageOfSubTeamsIfNeeded: args =>
      dispatch(fetchNextPageOfSubTeamsIfNeeded(args)),
    fetchTeamsCount: args => dispatch(fetchTeamsCount(args)),
    navigateDown: selectedTeam => dispatch(navigateDown(selectedTeam)),
    navigateUp: selectedTeam => dispatch(navigateUp(selectedTeam)),
    onSearchTeams: args => dispatch(searchTeams(args)),
    clearSearch: () => dispatch(clearSearch())
  };
};

TeamDefaultChooserPopoutContainer.displayName =
  "TeamDefaultChooserPopoutContainer";
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamDefaultChooserPopoutContainer);
