export const FETCH_TEAMS = "APP/ENTITIES/TEAMS/FETCH";
export const FETCH_TEAMS_FAILURE = "APP/ENTITIES/TEAMS/FETCH_FAILURE";
export const FETCH_TEAMS_SUCCESS = "APP/ENTITIES/TEAMS/FETCH_SUCCESS";

export const SUB_TEAMS_REQUEST = "API/ENTITIES/SUB_TEAMS_REQUEST";
export const SUB_TEAMS_REQUEST_FAILURE =
  "API/ENTITIES/SUB_TEAMS_REQUEST_FAILURE";
export const SUB_TEAMS_REQUEST_SUCCESS =
  "API/ENTITIES/SUB_TEAMS_REQUEST_SUCCESS";

export const TEAM_REQUEST = "API/ENTITIES/TEAM_REQUEST";
export const TEAM_REQUEST_FAILURE = "API/ENTITIES/TEAM_REQUEST_FAILURE";
export const TEAM_REQUEST_SUCCESS = "API/ENTITIES/TEAM_REQUEST_SUCCESS";

export const TEAMS_CREATE_REQUEST = "API/ENTITIES/TEAMS_CREATE_REQUEST";
export const TEAMS_CREATE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAMS_CREATE_REQUEST_SUCCESS";
export const TEAMS_CREATE_REQUEST_FAILURE =
  "API/ENTITIES/TEAMS_CREATE_REQUEST_FAILURE";

export const TEAMS_INVITE_USERS_REQUEST =
  "API/ENTITIES/TEAMS_INVITE_USERS_REQUEST";
export const TEAMS_INVITE_USERS_REQUEST_SUCCESS =
  "API/ENTITIES/TEAMS_INVITE_USERS_REQUEST_SUCCESS";
export const TEAMS_INVITE_USERS_REQUEST_FAILURE =
  "API/ENTITIES/TEAMS_INVITE_USERS_REQUEST_FAILURE";

export const TEAMS_REQUEST = "API/ENTITIES/TEAMS_REQUEST";
export const TEAMS_REQUEST_FAILURE = "API/ENTITIES/TEAMS_REQUEST_FAILURE";
export const TEAMS_REQUEST_SUCCESS = "API/ENTITIES/TEAMS_REQUEST_SUCCESS";

export const TEAMS_SEARCH_REQUEST = "API/ENTITIES/TEAMS_SEARCH_REQUEST";
export const TEAMS_SEARCH_REQUEST_FAILURE =
  "API/ENTITIES/TEAMS_SEARCH_REQUEST_FAILURE";
export const TEAMS_SEARCH_REQUEST_SUCCESS =
  "API/ENTITIES/TEAMS_SEARCH_REQUEST_SUCCESS";

export const TEAMS_COUNT_REQUEST = "API/ENTITIES/TEAMS_COUNT_REQUEST";
export const TEAMS_COUNT_REQUEST_FAILURE =
  "API/ENTITIES/TEAMS_COUNT_REQUEST_FAILURE";
export const TEAMS_COUNT_REQUEST_SUCCESS =
  "API/ENTITIES/TEAMS_COUNT_REQUEST_SUCCESS";

export const TEAMS_UPDATE_REQUEST = "API/ENTITIES/TEAMS_UPDATE_REQUEST";
export const TEAMS_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAMS_UPDATE_REQUEST_SUCCESS";
export const TEAMS_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/TEAMS_UPDATE_REQUEST_FAILURE";
