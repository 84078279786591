import * as types from "./invitationTypes";
import auth from "lib/auth";
import { push } from "react-router-redux";
import { Logger, Referrer, getPath } from "lib";
import PATHS from "routes/paths";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import {
  switchToken,
  setCurrentUserTokenData
} from "state/currentUser/currentUserActions";
import { moveTo } from "state/ui/navigation/navigationActions";

export const acceptInvitation = ({ invitation, token, onSuccess }) => (
  dispatch,
  setState
) => {
  dispatch({
    [CALL_API]: {
      types: [
        types.INVITATION_ACCEPT_REQUEST,
        types.INVITATION_ACCEPT_REQUEST_SUCCESS,
        types.INVITATION_ACCEPT_REQUEST_FAILURE
      ],
      method: "PUT",
      service: SERVICES.USER,
      schema: schemas.INVITATIONS,
      endpoint: `/invitations/${token}`,
      onSuccess: response => {
        dispatch(
          switchToken({
            teamId: invitation.teamId,
            onSuccess
          })
        );
      }
    }
  });
};

export const acceptInvitationAndRedirect = ({
  invitation,
  token,
  redirectTo,
  acceptInvitationFn = acceptInvitation
}) => (dispatch, setState) => {
  dispatch(
    acceptInvitationFn({
      invitation,
      token,
      onSuccess: () => dispatch(moveTo(redirectTo))
    })
  );
};

export const fetchInvitation = ({ token, onSuccess }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      types: [
        types.INVITATION_REQUEST,
        types.INVITATION_REQUEST_SUCCESS,
        types.INVITATION_REQUEST_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/invitations/${token}`,
      schema: schemas.INVITATIONS,
      onSuccess: response => {
        const invitation = response.entities.invitations[response.ids];
        return onSuccess(invitation);
      }
    }
  });
};

export const fetchInvitationAndAcceptIt = ({
  token,
  fetchInvitationFn = fetchInvitation,
  acceptInvitationAndRedirectFn = acceptInvitationAndRedirect
}) => (dispatch, getState) => {
  /* remove the referrer to prevent user authorization from overwriting our redirect */
  Referrer.remove();
  dispatch(
    fetchInvitationFn({
      token,
      onSuccess: invitation =>
        dispatch(
          acceptInvitationAndRedirectFn({
            invitation,
            token,
            redirectTo: PATHS.catalogueTemplates
          })
        )
    })
  );
};

export const fetchInvitationAndRedirectUser = ({
  token,
  fetchInvitationFn = fetchInvitation
}) => (dispatch, getState) => {
  dispatch(
    fetchInvitationFn({
      token,
      onSuccess: invitation => {
        const userHasAccount = invitation.userId;

        const redirectTo = userHasAccount
          ? `/user/invitations/${token}`
          : `/signup/${token}?name=${invitation.name}&email=${
              invitation.email
            }`;
        /* remove the referrer to prevent user authorization from overwriting our redirect */
        Referrer.remove();
        dispatch(push(encodeURI(redirectTo)));
      }
    })
  );
};

export const setCurrentUserTokenandAcceptInvitationWithRedirect = ({
  token,
  invitation,
  authToken,
  setCurrentUserTokenDataFn = setCurrentUserTokenData,
  acceptInvitationAndRedirectFn = acceptInvitationAndRedirect
}) => (dispatch, getState) => {
  const redirectTo = PATHS.catalogueTemplates;
  /* remove the referrer to prevent user authorization from overwriting our redirect */
  Referrer.remove();

  dispatch(
    setCurrentUserTokenDataFn({
      token: authToken,
      tokenOrigin: "localstorage",
      onSuccess: () => {
        dispatch(
          acceptInvitationAndRedirectFn({ invitation, token, redirectTo })
        );
      }
    })
  );
};

export const processInvitation = ({
  token,
  fetchInvitationFn = fetchInvitation,
  setCurrentUserTokenandAcceptInvitationWithRedirectFn = setCurrentUserTokenandAcceptInvitationWithRedirect
}) => (dispatch, getState) => {
  if (invitationHasBeenFetched()) {
    handleInvitation(getState().ui.invitation);
  } else {
    dispatch(
      fetchInvitationFn({
        token,
        onSuccess: handleInvitation
      })
    );
  }

  function handleInvitation(invitation) {
    if (
      (isInvitationForExistingUser() && isInvitationForLoggedInUser()) ||
      isInvitationForUserThatSignUp()
    ) {
      dispatch(
        setCurrentUserTokenandAcceptInvitationWithRedirectFn({
          token,
          invitation,
          authToken: localStorage.getItem("auth_token")
        })
      );

      return;
    }

    if (
      isThereUserLoggedin() &&
      isInvitationForExistingUser() &&
      !isInvitationForLoggedInUser()
    ) {
      dispatch(push(`${PATHS.invitations}/${token}?wrongUser=true`));
      return;
    }

    if (isInvitationForExistingUser()) {
      const warnMsg =
        "Invitation Fecthed Successfully, but user is not Authenticated. Push to login";
      Logger.info(warnMsg);

      dispatch(push(encodeURI(PATHS.login) + `?invitationToken=${token}`));
      return;
    } else {
      const signupPagePreFilled = [
        "/signup",
        `/${token}`,
        `?name=${invitation.name}&email=${invitation.email}`
      ].join("");

      dispatch(push(encodeURI(signupPagePreFilled)));
      return;
    }

    function isInvitationForUserThatSignUp() {
      return (
        invitation.email === getPath(getState(), "ui.teamSignup.user.email")
      );
    }

    function isInvitationForExistingUser() {
      return Boolean(invitation.userId);
    }

    function isInvitationForLoggedInUser() {
      const authToken = localStorage.getItem("auth_token");

      if (!authToken) {
        return false;
      }

      const userIdLoggedin = auth.jwtDecoder(authToken).userId;

      return invitation.userId === userIdLoggedin;
    }

    function isThereUserLoggedin() {
      const authToken = localStorage.getItem("auth_token");

      return Boolean(authToken);
    }
  }

  function invitationHasBeenFetched() {
    const invitationState = getState().ui.invitation;

    return Boolean(invitationState.id);
  }
};
