import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import * as types from "./resetPasswordTypes";

export const requestPasswordEmailToken = ({ email }) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.USER,
      endpoint: "/account/password/token",
      types: [
        types.RESET_PASSWORD_EMAIL_TOKEN_REQUEST,
        types.RESET_PASSWORD_EMAIL_TOKEN_REQUEST_SUCCESS,
        types.RESET_PASSWORD_EMAIL_TOKEN_REQUEST_FAILURE
      ],
      request: {
        body: {
          email
        }
      },
      schema: schemas.NONE
    }
  });
};

export const validateResetPasswordToken = ({ token }) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.USER,
      endpoint: `/account/password/${token}`,
      types: [
        types.VALIDATE_RESET_PASSWORD_EMAIL_TOKEN_REQUEST,
        types.VALIDATE_RESET_PASSWORD_EMAIL_TOKEN_REQUEST_SUCCESS,
        types.VALIDATE_RESET_PASSWORD_EMAIL_TOKEN_REQUEST_FAILURE
      ],
      schema: schemas.RESET_PASSWORD_TOKEN
    }
  });
};

export const updatePassword = ({ token, password }) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.USER,
      endpoint: `/account/password/${token}`,
      types: [
        types.RESET_PASSWORD_REQUEST,
        types.RESET_PASSWORD_REQUEST_SUCCESS,
        types.RESET_PASSWORD_REQUEST_FAILURE
      ],
      request: {
        body: {
          password
        }
      },
      schema: schemas.RESET_PASSWORD_TOKEN
    }
  });
};
