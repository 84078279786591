import React, { Component } from "react";

import style from "./style.module.css";

import CaretIcon from "views/components/icons/CaretIcon";

import CrumbDropdown from "./CrumbDropdown";

import { noop, composeClassName } from "lib";
import { isSharedFolder } from "lib/sharedFoldersUtils";
import { TwoPeopleIcon } from "../icons";

const initialDropdownComponent = () => <div />;
export class BreadCrumb extends Component {
  constructor(props) {
    super(props);

    this.getOnClick = this.getOnClick.bind(this);

    this.crumbReference = React.createRef();

    this.state = {
      hasAction: false,
      onClick: noop,
      DropdownComponent: initialDropdownComponent
    };
  }

  componentDidMount() {
    this.getOnClick();
  }

  componentDidUpdate(prevProps) {
    const { isDropdownOpen } = this.props;
    const { isDropdownOpen: prevIsDropdownOpen } = prevProps;
    if (isDropdownOpen !== prevIsDropdownOpen) {
      this.getOnClick();
    }
  }

  getOnClick() {
    const { node, closeDropdown, openDropdown, isDropdownOpen } = this.props;

    if (node.MenuComponent && node.isLast) {
      // make the onclick open the dropdown or close it in the case that it is already open
      this.setState({
        onClick: () => (isDropdownOpen ? closeDropdown() : openDropdown()),
        hasAction: true,
        DropdownComponent: node.MenuComponent
      });
    } else if (node.onClick !== noop && node.onClick) {
      this.setState({ onClick: node.onClick, hasAction: true });
    }
  }

  render() {
    const {
      node,
      crumbId,
      isDropdownOpen,
      closeDropdown,
      isDropdownContainer,
      activeFolder,
      folderAllocations
    } = this.props;

    const { hasAction, onClick, DropdownComponent } = this.state;

    const showDropdown = isDropdownOpen && node.isLast;

    return (
      <div className={style.crumbAndDropdownWrapper} data-testid="BreadCrumb">
        <div
          onClick={onClick}
          ref={this.crumbReference}
          className={style.crumb}
          data-has-action={hasAction}
          id={`bread-crumb-wrapper-${crumbId}`}
        >
          {node.isLast && isSharedFolder(activeFolder, folderAllocations) && (
            <div className={style.sharedFolderIcon}>
              <TwoPeopleIcon />
            </div>
          )}
          <div
            className={composeClassName("crumbText", style, node.className)}
            data-is-last={node.isLast}
            data-is-first={node.isFirst}
            id={`bread-crumb-text-${crumbId}`}
          >
            {node.text}
          </div>
          {!node.isLast && (
            <div
              className={style.crumbCaret}
              id={`bread-crumb-right-caret-${crumbId}`}
              data-testid={`bread-crumb-right-caret`}
            >
              <CaretIcon
                color="#afb3b6"
                size="12px"
                direction="right"
                strokeWidth="1"
              />
            </div>
          )}
          {node.isLast && node.MenuComponent && (
            <div
              className={style.crumbCaret}
              id={`bread-crumb-down-caret-${crumbId}`}
              data-testid={`bread-crumb-down-caret`}
            >
              <CaretIcon
                color={style.easilBlack}
                size="12px"
                direction="down"
                strokeWidth="1"
              />
            </div>
          )}
        </div>
        <CrumbDropdown
          DropdownComponent={DropdownComponent}
          isDropdownOpen={showDropdown}
          isDropdownContainer={isDropdownContainer}
          onClose={closeDropdown}
        />
      </div>
    );
  }
}

BreadCrumb.displayName = "BreadCrumb";

export default BreadCrumb;
