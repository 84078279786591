import {
  updateDesignForMasonry,
  fetchTeamDesigns,
  fetchDraftsDesigns,
  declineDesignApiCall,
  approveDesignApiCall,
  requestDesignApprovalApiCall,
  refetchDesignsPages,
  cancelDesignApprovalRequestApiCall,
  searchDesigns
} from "state/entities/designs/designsActions";
import * as designsActionsType from "state/entities/designs/designsTypes";
import {
  moveToWorkspaceApprovedFiltered,
  moveToWorkspaceBrandManagerApprovedFiltered,
  moveToCatalogueTemplates
} from "state/ui/navigation/navigationActions";
import { makeToast } from "state/ui/toaster/ToasterActions";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import PATHS from "routes/paths";
import { getFilters, Logger, noop } from "lib";
import {
  moveToEasilCatalogueFiltered,
  moveToPurchasedCatalogueFiltered,
  moveToWorkspaceDraftsFiltered,
  moveToWorkspaceArchivedFiltered,
  moveToWorkspaceSharedFiltered,
  moveToTeamCatalogueFiltered,
  moveToCatalogueArchivedFiltered,
  moveToCatalogueFoldersFiltered,
  moveToWorkspaceFoldersFiltered,
  moveToWorkspace,
  moveToEditorDesignId
} from "state/ui/navigation/navigationActions";
import { getParameterByName } from "lib/queryStringUtils";
import DesignConversion from "views/components/toast/DesignConversion";
import {
  shareDesignApiCall,
  shareDesignsApiCall,
  fetchDesignsShared,
  unshareDesignApiCall,
  createDesign,
  createDesignCopy,
  refetchAllPendingApprovalDesignsPages
} from "state/entities/designs/designsActions";
import {
  collectionAllocationsSelectorById,
  collectionAllocationsSelector
} from "state/entities/collectionAllocations/collectionAllocationsSelectors";
import { closeApprovalModal } from "state/ui/approvalModal/approvalModalActions";
import { fetchCurrentUserNotifications } from "state/ui/notification/notificationActions";
import { updateCollection } from "state/api/collection/collectionActions";
import { getSearchPath } from "../location/locationSelectors";
import { templateSizeSelector } from "state/entities/templateSizes/templateSizeSelectors";
import { sendPortalMessage } from "lib/portalUtil";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";
import { isEditorInIframe } from "views/components/Editor/utils";
import { designById } from "state/entities/designs/designsSelectors";
import { getLatestDesignAction } from "state/entities/designActions/designActionsSelectors";
import { userById } from "state/entities/users/usersSelectors";

/**
 * @desc - converts a design in the workspace to a template and displays a toast for the action completion
 * @param {string} designId - the id for the design in workspace to be converted to a template
 */
export const convertDesignToMaster = ({ designId }) => (dispatch, getState) =>
  dispatch(
    updateDesignForMasonry({
      design: {
        id: designId,
        status: "MASTER"
      },
      onSuccess: () => {
        const location = getSearchPath(getState());
        const filters = getFilters(location);
        dispatch(makeToastForConvertWorkspaceDesignToTemplateDesign());
        dispatch(refetchDesignsPages({ filters, shouldClearPages: true }));
      },
      isStatus: true
    })
  );

/**
 * @desc - converts a designs' collection in the workspace to a template and displays a toast for the action completion
 * @param {string} collectionId - the id for the designs' collection in workspace to be converted to a template
 */
export const convertCollectionToMaster = ({ collectionId }) => (
  dispatch,
  getState
) =>
  dispatch(
    updateCollection({
      collectionId,
      onSuccess: () => {
        const location = getSearchPath(getState());
        const filters = getFilters(location);
        dispatch(makeToastForConvertWorkspaceCollectionToTemplateCollection());
        dispatch(refetchDesignsPages({ filters, shouldClearPages: true }));
      },
      status: "MASTER"
    })
  );

export const shareDesign = ({ usersId, collectionId, designId }) => (
  dispatch,
  getState
) => {
  dispatch(
    shareDesignApiCall({
      usersId,
      collectionId,
      onSuccess: showToaster
    })
  );

  function showToaster(response) {
    const requestedCount = response.length;
    const successfulResponseCount = response.filter(
      httpResponse => httpResponse.status === 201
    ).length;

    let toasterText = "";
    let iconType = "success";

    if (requestedCount === successfulResponseCount) {
      toasterText = `Your design has successfully been shared with ${requestedCount} team member${
        requestedCount > 1 ? "s" : ""
      }.`;
      iconType = "success";
    } else if (successfulResponseCount === 0) {
      toasterText = `Your design has failed to share. Please try again.`;
      iconType = "failure";
    } else {
      toasterText = `Your design was only successfully shared with ${successfulResponseCount} team member${
        successfulResponseCount > 1 ? "s" : ""
      }. Please try again.`;
      iconType = "warning";
    }

    dispatch(makeToastSharedDesign({ designId, toasterText, iconType }));
  }
};

export const shareDesigns = ({
  userIds,
  collectionIds,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  dispatch(
    shareDesignsApiCall({
      userIds,
      collectionIds,
      onSuccess: response => {
        showToaster(response);
        onSuccess();
      },
      onFailure: () => {
        dispatch(
          makeToast({
            textComponent: `Some selected designs could not be shared.\nPlease contact support if the issue\npersists`,
            type: "noButton",
            icon: "warning"
          })
        );
        onFailure();
      }
    })
  );

  function showToaster(response) {
    const requestedCount = response.length;
    const successfulResponseCount = response.filter(res => res.status === 201)
      .length;

    let toasterText = "";
    let iconType = "success";

    if (requestedCount === successfulResponseCount) {
      toasterText = `Your designs have successfully been shared`;
      iconType = "success";
    }

    dispatch(makeToastSharedDesign({ bulkShare: true, toasterText, iconType }));
  }
};

export const makeToastSharedDesign = ({
  designId,
  toasterText,
  iconType,
  bulkShare
}) => (dispatch, getState) => {
  const toastAction = () => {
    dispatch(moveToEditorDesignId({ designId }));
  };

  dispatch(
    makeToast({
      textComponent: toasterText,
      type: iconType === "success" && !bulkShare ? "button" : "noButton",
      buttonLabel: "Open Design",
      icon: iconType,
      action: bulkShare ? noop : toastAction
    })
  );
};

/**
 * @desc - demotes a collection in team templates to workspace drafts and displays a toast for the action completion
 * @param {string} collectionId - the id for the collection in templates to be converted to workspace drafts
 * @param {func} onSuccess - function to call if update was a success
 */
export const demoteCollectionFromMaster = ({ collectionId, onSuccess }) => (
  dispatch,
  getState
) =>
  dispatch(
    updateCollection({
      collectionId,
      onSuccess: (...args) => {
        const location = getSearchPath(getState());
        const filters = getFilters(location);
        dispatch(makeToastForConvertTemplateToWorkspace());
        dispatch(refetchDesignsPages({ filters, shouldClearPages: true }));

        if (onSuccess) {
          onSuccess(...args);
        }
      },
      status: "DRAFT"
    })
  );

/**
 * @desc - demotes a design in team templates to a workspace draft and displays a toast for the action completion
 * @param {string} designId - the id for the design in templates to be converted to a workspace draft
 */
export const demoteDesignFromMaster = ({ designId }) => (
  dispatch,
  getState
) => {
  dispatch(
    updateDesignForMasonry({
      design: {
        id: designId,
        status: "DRAFT"
      },
      designType: "template",
      isStatus: true,
      onSuccess: () => dispatch(makeToastForConvertTemplateToWorkspace())
    })
  );
};

/**
 * @desc creates a toast for after the conversion of a template design to a workspace draft
 */
export const makeToastForConvertTemplateToWorkspace = () => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentUserId = state.currentUser.id;
  const currentTeamId = currentTeamIdSelector(state);
  const toastAction = () => {
    dispatch(
      fetchDraftsDesigns({ userId: currentUserId, teamId: currentTeamId })
    );
    dispatch(moveToWorkspace());
  };

  const textComponent = DesignConversion({
    message:
      "Your Template Collection has been reverted to a Draft. View in your",
    linkText: "Workspace",
    onLinkClick: toastAction
  });

  dispatch(
    makeToast({
      textComponent,
      type: "button",
      buttonLabel: "My Workspace",
      icon: "success",
      action: toastAction
    })
  );
};

/**
 * @desc creates a copy of an approved design and adds it to workspace drafts before creating a toast
 */
export const copyApprovedDesignAndMakeToast = ({ designId }) => dispatch => {
  dispatch(
    createDesign({
      design: {
        id: designId,
        status: "DRAFT"
      },
      onSuccess: () => dispatch(makeToastForCopyApproved())
    })
  );
};

/**
 * @desc creates a copy of an approved design and adds it to workspace drafts before creating a toast without redirect
 */
export const copyApprovedDesignAndMakeToastWithoutRedirect = ({
  designId
}) => dispatch => {
  const toastMessage = "This design has been copied into your";
  dispatch(
    createDesignCopy({
      design: {
        id: designId,
        status: "DRAFT"
      },
      onSuccess: () => dispatch(makeToastForCopyApproved(toastMessage))
    })
  );
};

/**
 * @desc creates a toast for after copying a approved design to draft
 */
export const makeToastForCopyApproved = toastMessage => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentUserId = state.currentUser.id;
  const currentTeamId = currentTeamIdSelector(state);
  const toastAction = () => {
    dispatch(
      fetchDraftsDesigns({ userId: currentUserId, teamId: currentTeamId })
    );
    dispatch(moveToWorkspace());
  };
  const message =
    toastMessage || "Your design has been copied and moved to your";

  const textComponent = DesignConversion({
    message,
    linkText: "Workspace",
    onLinkClick: toastAction
  });

  dispatch(
    makeToast({
      textComponent,
      type: "button",
      buttonLabel: "View My Designs",
      icon: "success",
      action: toastAction
    })
  );
};

/**
 * @desc creates a toast for converting a draft design to a template
 */
export const makeToastForConvertWorkspaceDesignToTemplateDesign = () => dispatch => {
  dispatch(
    makeToastForConvertWorkspaceToTemplate({
      message: "Your Design has been converted to a Template. View it in your"
    })
  );
};

/**
 * @desc creates a toast for converting a collection of drafts to templates
 */
export const makeToastForConvertWorkspaceCollectionToTemplateCollection = () => dispatch => {
  dispatch(
    makeToastForConvertWorkspaceToTemplate({
      message:
        "Your Draft Collection has been converted to a Template. View in your"
    })
  );
};

/**
 * @desc creates a toast for after the conversion of a workspace design to a template
 */
export const makeToastForConvertWorkspaceToTemplate = ({ message }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentTeamId = currentTeamIdSelector(state);
  const toastAction = () => {
    dispatch(fetchTeamDesigns({ teamId: currentTeamId }));
    dispatch(moveToCatalogueTemplates());
  };

  const textComponent = DesignConversion({
    message,
    linkText: "Team Templates",
    onLinkClick: toastAction
  });

  dispatch(
    makeToast({
      textComponent,
      type: "button",
      buttonLabel: "Team Templates",
      icon: "success",
      action: toastAction
    })
  );
};

export const changeWorkspaceDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  if (filters.term) {
    dispatch(searchDesignsTerm(filters));
  } else {
    dispatch(moveToWorkspaceDraftsFiltered(filters));
  }
};

export const changeWorkspaceArchivedDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  if (filters.term) {
    dispatch(searchDesignsTerm(filters));
  } else {
    dispatch(moveToWorkspaceArchivedFiltered(filters));
  }
};

export const searchDesignsTerm = (filters = {}) => {
  return searchDesigns({
    term: filters.term,
    categoryId: filters.categoryId,
    section: "workspace",
    size: filters.size
  });
};

export const changeWorkspaceApprovedDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  if (filters.term) {
    dispatch(searchDesignsTerm(filters));
  } else {
    dispatch(moveToWorkspaceApprovedFiltered(filters));
  }
};

export const changeWorkspaceBrandManagerApprovedDesignsFilters = (
  filters = {}
) => (dispatch, getState) => {
  if (filters.term) {
    dispatch(searchDesignsTerm(filters));
  } else {
    dispatch(moveToWorkspaceBrandManagerApprovedFiltered(filters));
  }
};

export const changeWorkspaceSharedDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  if (filters.term) {
    dispatch(searchDesignsTerm(filters));
  } else {
    dispatch(moveToWorkspaceSharedFiltered(filters));
  }
};

export const changeWorkspaceFoldersDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  if (filters.term) {
    dispatch(searchDesignsTerm(filters));
  } else {
    const state = getState();

    const folderId = getParameterByName(
      "folderId",
      state.router.location.search
    );

    const filtersWithFolder = {
      ...filters,
      folderId: folderId
    };

    dispatch(moveToWorkspaceFoldersFiltered(filtersWithFolder));
  }
};

export const changeTeamCatalogueDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  let passThroughFilters = { ...filters };

  if (filters.size && filters.categoryId === "All") {
    const sizeFromState = templateSizeSelector(getState(), filters.size);
    if (sizeFromState && sizeFromState.category) {
      passThroughFilters.categoryId = sizeFromState.category;
      passThroughFilters.highlightedId = sizeFromState.category;
    }
  }

  if (filters.term) {
    const params = {
      term: passThroughFilters.term,
      categoryId: passThroughFilters.categoryId,
      section: "catalogue",
      highlightedId:
        passThroughFilters.highlightedId || passThroughFilters.categoryId,
      searchType: "size",
      size: passThroughFilters.size
    };

    dispatch(searchDesigns(params));
  } else {
    dispatch(moveToTeamCatalogueFiltered(passThroughFilters));
  }
};

export const changeCatalogueArchivedDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  if (filters.term) {
    dispatch(
      searchDesigns({
        term: filters.term,
        categoryId: filters.categoryId,
        section: "catalogue",
        size: filters.size
      })
    );
  } else {
    dispatch(moveToCatalogueArchivedFiltered(filters));
  }
};

export const changeCatalogueFoldersDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  if (filters.term) {
    dispatch(
      searchDesigns({
        term: filters.term,
        categoryId: filters.categoryId,
        section: "catalogue",
        size: filters.size
      })
    );
  } else {
    const state = getState();

    const folderId = getParameterByName(
      "folderId",
      state.router.location.search
    );

    const filtersWithFolder = {
      ...filters,
      folderId: folderId
    };

    dispatch(moveToCatalogueFoldersFiltered(filtersWithFolder));
  }
};

export const changeCatalogueDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  if (filters.term) {
    dispatch(
      searchDesigns({
        term: filters.term,
        categoryId: filters.categoryId,
        section: "catalogue",
        size: filters.size,
        searchType: filters.searchType
      })
    );
  } else {
    dispatch(moveToEasilCatalogueFiltered(filters));
  }
};

export const changePurchasedCatalogueDesignsFilters = (filters = {}) => (
  dispatch,
  getState
) => {
  if (filters.term) {
    const params = {
      term: filters.term,
      categoryId: filters.categoryId,
      section: "purchased",
      searchType: filters.searchType,
      highlightedId: filters.highlightedId || filters.categoryId
    };

    /* when the user has selected "All" we want to remove the size filter */
    if (filters.categoryId !== "All") {
      params.size = filters.size;
    }

    dispatch(searchDesigns(params));
  } else {
    dispatch(moveToPurchasedCatalogueFiltered(filters));
  }
};

export const changeDesignsFilters = (filters = {}) => (dispatch, getState) => {
  const state = getState();
  const { pathname } = state.router.location;

  switch (true) {
    case pathname.includes(PATHS.workspace): {
      dispatch(changeWorkspaceDesignsFilters(filters));
      break;
    }
    case pathname.includes(PATHS.catalogue): {
      dispatch(changeCatalogueDesignsFilters(filters));
      break;
    }
    default:
      Logger.error("Invalid Designs Filter Change");
  }
};

export const unshareDesign = ({
  teamId,
  collectionId,
  allocationId,
  onSuccess = noop
}) => (dispatch, getState) => {
  const state = getState();
  const allocation = collectionAllocationsSelectorById({ state, allocationId });
  const userId = state.currentUser.id;
  const isUserRemovingSelf = allocation.user.id === userId;

  const runSuccessAndRefetchIfNeeded = response => {
    if (isUserRemovingSelf) {
      dispatch(fetchDesignsShared({ page: 1 }));
    }

    onSuccess(response);
  };

  const allocations = collectionAllocationsSelector({
    state: getState(),
    collectionId
  });
  const wasLastAllocation = allocations.length === 1;

  if (wasLastAllocation) {
    dispatch({
      type: designsActionsType.DESIGN_NO_LONGER_SHARED,
      collectionId
    });
  }

  dispatch(
    unshareDesignApiCall({
      teamId,
      collectionId,
      allocationId,
      onSuccess: runSuccessAndRefetchIfNeeded,
      isUserRemovingSelf
    })
  );
};

export const declineDesign = ({ designId, comment, onSuccess }) => dispatch => {
  dispatch(declineDesignApiCall({ designId, comment, onSuccess }));
};

export const approveDesign = ({ designId, comment, onSuccess }) => dispatch => {
  dispatch(approveDesignApiCall({ designId, comment, onSuccess }));
};

export const approvalAction = ({ designId, comment, approve }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const currentUser = state.currentUser;

  const onSuccessFunctions = [
    () => dispatch(fetchCurrentUserNotifications()),
    () => dispatch(refetchAllPendingApprovalDesignsPages()),
    () => dispatch(closeApprovalModal())
  ];

  // when in iframe we need to send a message to the portal
  if (isEditorInIframe()) {
    // get the design action
    const designAction = getLatestDesignAction({ state, designId });
    // get the design to send to portal
    const design = designById({ state, designId });
    // get the current requester
    let requester;
    if (designAction && designAction.actionedById) {
      requester = userById(state, designAction.actionedById);
    }
    // approver should always be the current user
    const approver = {
      name: currentUser.name,
      email: currentUser.email
    };
    // construct the portal message we want to send
    const portalMessage = {
      design,
      comment,
      approver,
      requester
    };

    if (approve) {
      // push the approved function
      onSuccessFunctions.push(() => {
        sendPortalMessage({
          ...portalMessage,
          event: "portal.approvalRequestApproved"
        });
      });
    } else {
      // push the declined function
      onSuccessFunctions.push(() => {
        sendPortalMessage({
          ...portalMessage,
          event: "portal.approvalRequestDeclined"
        });
      });
    }
  }

  const args = {
    designId,
    comment,
    onSuccess: () => {
      // map the on success functions and fire them off
      onSuccessFunctions.forEach(successFunction => {
        successFunction();
      });
    }
  };

  if (approve) return dispatch(approveDesign(args));

  return dispatch(declineDesign(args));
};

export const requestDesignApproval = ({ designId, comment }) => (
  dispatch,
  getState
) => {
  let onSuccess = noop;
  // onSuccess for approval request to send message to portal
  if (isEditorInIframe()) {
    const state = getState();
    const { name, email } = currentUserSelector(state);
    onSuccess = () => {
      const design = designById({ state, designId });
      const message = {
        event: "portal.approvalRequestSubmitted",
        design,
        requester: {
          name,
          email
        },
        comment
      };
      sendPortalMessage(message);
    };
  }

  dispatch(
    requestDesignApprovalApiCall({
      designId,
      comment,
      onSuccess
    })
  );
};

export const cancelDesignApprovalRequest = ({
  designId,
  comment = null,
  onSuccess
}) => (dispatch, getState) => {
  dispatch(
    cancelDesignApprovalRequestApiCall({
      designId,
      comment,
      onSuccess: callback
    })
  );

  function callback(...args) {
    onSuccess(...args);
    dispatch(refetchDesignsPages({}));
  }
};
