import React, { Component } from "react";
import style from "./style.module.css";
import DuplicatePageIcon from "views/components/icons/DuplicatePageIcon";
import PasteIcon from "views/components/icons/PasteIcon";
import PositionFilledIcon from "views/components/icons/PositionFilledIcon";
import PositionIcon from "views/components/icons/PositionIcon";
import ReloadIcon from "views/components/icons/ReloadIcon";
import DiagonalBarsIcon from "views/components/icons/DiagonalBarsIcon";
import GarbageCanIcon from "views/components/icons/GarbageCanIcon";
import PadlockIcon from "views/components/icons/PadlockIcon";
import PadlockOpenSmoothIcon from "views/components/icons/PadlockOpenSmoothIcon";
import { FillFrameIcon, FitFrameIcon } from "../icons";
import CellLockingIcon from "views/components/icons/CellLockingIcon";

export default class ContextMenu extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.selectedItemsWithRestrictions = this.selectedItemsWithRestrictions.bind(
      this
    );
    this.getSelectedItemsRestrictions = this.getSelectedItemsRestrictions.bind(
      this
    );
    this.onLockClick = this.onLockClick.bind(this);
    this.state = {
      hasRestriction: false
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
    this.handleReposition();
    this.getSelectedItemsRestrictions();
  }

  componentDidUpdate(prevProps) {
    this.handleReposition();
    if (prevProps.designData !== this.props.designData) {
      this.getSelectedItemsRestrictions();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick(event) {
    let { target } = event;
    let isOutside = true;

    while (target && this.ref) {
      if (target === this.ref.current) {
        isOutside = false;
        break;
      }
      target = target.parentNode;
    }

    if (isOutside) {
      this.props.onClose(event);
    }
  }

  handleReposition() {
    const { current: ref } = this.ref || {};

    if (ref) {
      ref.style.transform = "";

      const rect = ref.getBoundingClientRect();
      let offsetX = 0;
      let offsetY = 0;

      if (rect.left < 0) {
        offsetX += 0 - rect.left;
      }

      if (rect.top + rect.height > window.innerHeight) {
        offsetY += window.innerHeight - (rect.top + rect.height);
      }

      if (rect.left + rect.width > window.innerWidth) {
        offsetX += window.innerWidth - (rect.left + rect.width);
      }

      ref.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
    }
  }

  selectedItemsWithRestrictions = () =>
    this.props.selectedItems.map(item => {
      return this.props.designData.getElementWithRestrictions(
        Object.assign({}, item, { uniqueId: item.itemId })
      );
    });

  getSelectedItemsRestrictions = () => {
    const restrictedSelectedItems = this.selectedItemsWithRestrictions();

    const restrictions = restrictedSelectedItems.map(item => item.restrictions);
    if (
      restrictions.some(
        element => element.sizeAndPosition === true || element.position === true
      )
    ) {
      this.setState({ hasRestriction: true });
    }
  };

  onLockClick() {
    this.props.onElementsRestrictionChange({
      attribute: "sizeAndPosition",
      value: !this.state.hasRestriction
    });

    this.setState({ hasRestriction: !this.state.hasRestriction });
  }

  render() {
    const {
      clientX,
      clientY,
      enable,
      offsetX = 0 - 10,
      offsetY = 0 - 10 - 60,
      onClick
    } = this.props;

    const getColor = isEnabled =>
      isEnabled ? style.easilGrayXDark : style.easilGray;

    const isImageElement =
      this.props.selectedItems.length === 1 &&
      this.props.designData.elements[this.props.selectedItems[0].itemId]
        .type === "image";

    const isFitToFrame =
      this.props.selectedItems.length === 1 &&
      this.props.designData.elements[this.props.selectedItems[0].itemId]
        .fitToFrame;

    const Icon = !isFitToFrame ? FitFrameIcon : FillFrameIcon;
    const label = !isFitToFrame ? "Fit to Frame" : "Fill Frame";

    let isFitToFrameDisabled, showTableCellLocking;
    if (this.props.selectedItems.length === 1) {
      const element = this.props.designData.elements[
        this.props.selectedItems[0].itemId
      ];
      isFitToFrameDisabled = element.restrictions.includes("cropping");
      showTableCellLocking = element.type === "table2" && this.props.isDesigner;
    }

    return (
      <ul
        className={style.contextMenuContainer}
        ref={this.ref}
        style={{
          left: `${clientX + offsetX}px`,
          top: `${clientY + offsetY}px`
        }}
      >
        <li disabled={!enable.copy} onClick={e => onClick(e, "copy")}>
          <div className={style.icon}>
            <DuplicatePageIcon color={getColor(enable.copy)} size="20px" />
          </div>
          Copy
        </li>
        <li disabled={!enable.paste} onClick={e => onClick(e, "paste")}>
          <PasteIcon color={getColor(enable.paste)} size="16px" />
          Paste
        </li>
        <li disabled={!enable.delete} onClick={e => onClick(e, "delete")}>
          <div className={style.icon}>
            <GarbageCanIcon color={getColor(enable.delete)} size="20px" />
          </div>
          Delete
        </li>
        <hr />
        <li
          disabled={!enable.bringToFront}
          onClick={e => onClick(e, "moveToTop")}
        >
          <PositionFilledIcon
            color={getColor(enable.bringToFront)}
            size="16px"
          />
          Bring to Front
        </li>
        <li disabled={!enable.bringForward} onClick={e => onClick(e, "moveUp")}>
          <PositionFilledIcon
            color={getColor(enable.bringForward)}
            size="16px"
          />
          Bring Forward
        </li>
        <li
          disabled={!enable.sendBackward}
          onClick={e => onClick(e, "moveDown")}
        >
          <PositionIcon color={getColor(enable.sendBackward)} size="16px" />
          Send Backward
        </li>
        <li
          disabled={!enable.sendToBack}
          onClick={e => onClick(e, "moveToBottom")}
        >
          <PositionIcon color={getColor(enable.sendToBack)} size="16px" />
          Send to Back
        </li>
        {enable.setBackground && (
          <>
            <hr />
            {isImageElement && (
              <li
                onClick={e => onClick(e, "toggleFitToFrame")}
                data-testid="fitFrameContextMenu"
                disabled={isFitToFrameDisabled}
              >
                <Icon
                  size="16px"
                  viewbox="0 0 18 18"
                  color={getColor(!isFitToFrameDisabled)}
                />
                {label}
              </li>
            )}
            <li onClick={e => onClick(e, "setAsBackground")}>
              <DiagonalBarsIcon color={getColor(enable.setBackground)} />
              Set as Background
            </li>
          </>
        )}
        {enable.updateCollection && (
          <>
            <hr />
            <li onClick={e => onClick(e, "updateCollection")}>
              <ReloadIcon
                color={getColor(enable.updateCollection)}
                size="16px"
              />
              Update Collection
            </li>
          </>
        )}
        {!this.props.isBasicPlan &&
          this.props.isDesigner &&
          !this.props.selectedItems.length <= 0 && (
            <>
              <hr />
              <li onClick={this.onLockClick}>
                {this.state.hasRestriction ? (
                  <>
                    <PadlockOpenSmoothIcon size="16px" color={"#57595D"} />
                    Unlock Position
                  </>
                ) : (
                  <>
                    <PadlockIcon size="16px" color={"#57595D"} />
                    Lock Position
                  </>
                )}
              </li>
            </>
          )}
        {showTableCellLocking && (
          <li onClick={e => onClick(e, "cellLockingMode")}>
            <CellLockingIcon color={"#57595D"} size="18px" />
            Cell Locking Mode
          </li>
        )}
      </ul>
    );
  }
}
