import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ArrowIntoBoxIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  direction = "down",
  title
}) => {
  const directionToAngle = {
    right: -90,
    down: 0,
    left: 90,
    up: 180
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction]}deg)`,
    ...style
  };

  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 20}
      height={height || size || 20}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M21 14a1 1 0 01.993.883L22 15v4a3 3 0 01-2.824 2.995L19 22H5a3 3 0 01-2.995-2.824L2 19v-4a1 1 0 011.993-.117L4 15v4a1 1 0 00.883.993L5 20h14a1 1 0 00.993-.883L20 19v-4a1 1 0 011-1zM12 2a1 1 0 01.993.883L13 3v9.585l3.293-3.292a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-5 5a1.006 1.006 0 01-.09.08l.09-.08a1.008 1.008 0 01-.674.292L12 16h-.032l-.054-.004L12 16a1.008 1.008 0 01-.613-.21 1.037 1.037 0 01-.094-.083l-5-5a1 1 0 011.32-1.497l.094.083L11 12.585V3a1 1 0 011-1z"
          fill={color || easilGrayXDark}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
ArrowIntoBoxIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ArrowIntoBoxIcon;
