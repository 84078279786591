import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ImageReplaceIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 19"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2 0h20v20H-2z" />
      <g fill={color || easilGrayXDark} transform="translate(1 4)">
        <path
          fillRule="nonzero"
          d="M13.1 4.928a.9.9 0 1 1 1.8 0V12a2.9 2.9 0 0 1-2.9 2.9H2A2.9 2.9 0 0 1-.9 12V2A2.9 2.9 0 0 1 2-.9h.324a.9.9 0 0 1 0 1.8H2A1.1 1.1 0 0 0 .9 2v10A1.1 1.1 0 0 0 2 13.1h10a1.1 1.1 0 0 0 1.1-1.1V4.928z"
        />
        <circle cx="4.5" cy="4.5" r="1.5" />
      </g>
      <path
        stroke="#BDC0C2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M10 4h3a2 2 0 0 1 2 2v3"
      />
      <path
        fill={color || easilGrayXDark}
        d="M9.303 4l2.333 2.333a.9.9 0 0 1-1.272 1.273l-2.97-2.97a.9.9 0 0 1 0-1.272l2.97-2.97a.9.9 0 1 1 1.272 1.273L9.303 4z"
      />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M13.072 13.024a3.1 3.1 0 0 0-4.047.558L5.972 17.1H13a1.1 1.1 0 0 0 1.1-1.1v-2.299l-1.028-.677zm.99-1.503l1.433.944a.9.9 0 0 1 .405.751V16a2.9 2.9 0 0 1-2.9 2.9H4a.9.9 0 0 1-.68-1.49l4.346-5.008a4.9 4.9 0 0 1 6.396-.881z"
      />
    </g>
  </svg>
);
ImageReplaceIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ImageReplaceIcon;
