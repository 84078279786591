import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../modal";
import style from "./style.module.css";
import { Input, Button } from "views/components";
import { getUserDefaultCountry } from "lib/billingUtils";
import CountryFlagsDropdown from "views/components/CountryFlagsDropdown/CountryFlagsDropdown";

class BillingAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = props.teamBilling;
  }

  componentDidUpdate = prevProps => {
    // Close modal if processed with no errors:
    if (
      prevProps.teamBillingSettings.isProcessing &&
      !this.props.teamBillingSettings.isProcessing &&
      !Object.keys(this.props.teamBillingSettings.errors).length
    ) {
      this.props.onClose();
    }
  };

  handleSave = () => {
    this.props.updateBilling({
      billingDetails: this.state,
      teamId: this.state.teamId
    });
  };

  handleUpdate = (field, value) => {
    this.setState({ [field]: value });
  };

  render = () => {
    const { teamBilling, teamBillingSettings } = this.props;
    const { errors, isProcessing } = teamBillingSettings;
    const userCountry = getUserDefaultCountry(this.state.country);

    return (
      <Modal
        className={style.modalContainer}
        header={
          teamBilling.street ? "Edit billing address" : "Add a billing address"
        }
        isOpen={true}
        onRequestClose={this.props.onClose}
      >
        <div className={style.formGroup}>
          <div className={style.formGroupLabel}>Company Name</div>
          <Input disabled value={this.props.currentTeam.name} />
        </div>
        <div className={style.formGroupInline}>
          <div className={style.formGroup}>
            <div className={style.formGroupLabel}>Street</div>
            <Input
              disabled={isProcessing}
              value={this.state.street || ""}
              onChange={e => this.handleUpdate("street", e.target.value)}
            />
            {errors.street && (
              <div className={style.fieldError}>{errors.street}</div>
            )}
          </div>
          <div className={style.formGroup}>
            <div className={style.formGroupLabel}>Suburb</div>
            <Input
              disabled={isProcessing}
              value={this.state.suburb || ""}
              onChange={e => this.handleUpdate("suburb", e.target.value)}
            />
            {errors.suburb && (
              <div className={style.fieldError}>{errors.suburb}</div>
            )}
          </div>
        </div>
        <div className={style.formGroupInline}>
          <div className={style.formGroup}>
            <div className={style.formGroupLabel}>State</div>
            <Input
              disabled={isProcessing}
              value={this.state.state || ""}
              onChange={e => this.handleUpdate("state", e.target.value)}
            />
            {errors.state && (
              <div className={style.fieldError}>{errors.state}</div>
            )}
          </div>
          <div className={style.formGroup}>
            <div className={style.formGroupLabel}>Post Code</div>
            <Input
              disabled={isProcessing}
              value={this.state.postcode || ""}
              onChange={e => this.handleUpdate("postcode", e.target.value)}
            />
            {errors.postcode && (
              <div className={style.fieldError}>{errors.postcode}</div>
            )}
          </div>
          <div className={style.formGroup} data-is-country={true}>
            <div className={style.formGroupLabel}>Country</div>
            <CountryFlagsDropdown
              selectedCountry={userCountry.country}
              onChange={selectedOption =>
                this.handleUpdate("country", selectedOption)
              }
              disabled={isProcessing}
            />
            {errors.country && (
              <div className={style.fieldError}>{errors.country}</div>
            )}
          </div>
        </div>
        <div className={style.formGroup}>
          <div className={style.formGroupLabel}>VAT ID</div>
          <Input
            disabled={isProcessing}
            value={this.state.taxId || ""}
            onChange={e => this.handleUpdate("taxId", e.target.value)}
          />
          {errors.taxId && (
            <div className={style.fieldError}>{errors.taxId}</div>
          )}
        </div>
        <div className={style.formGroupButtons}>
          <Button onClick={this.props.onClose} theme="gray">
            Cancel
          </Button>
          <Button
            disabled={isProcessing}
            onClick={this.handleSave}
            theme="blueSolid"
          >
            {isProcessing ? "Saving..." : "Save"}
          </Button>
        </div>
      </Modal>
    );
  };
}

BillingAddressModal.displayName = "BillingAddressModal";

BillingAddressModal.propTypes = {
  currentTeam: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  teamBilling: PropTypes.object.isRequired,
  teamBillingSettings: PropTypes.object.isRequired,
  updateBilling: PropTypes.func.isRequired
};

export default BillingAddressModal;
