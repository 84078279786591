import { noop, getPath } from "lib/lodash";
import { secondsInMs, msInSeconds } from "lib/temporal/time";

// handle the video element play promise synchronously to prevent unhandled rejects
export const playVideo = (videoElement, onSuccess = noop, onError = noop) => {
  videoElement
    .play()
    .then(onSuccess)
    .catch(onError);
};

export const loopTrimmedVideo = (
  videoElement,
  elementData,
  endCallback = noop
) => {
  const { startOffset = 0, trimDuration, duration } = elementData;
  let _trimDuration = trimDuration;
  if (!trimDuration) {
    _trimDuration = duration;
  }
  const endTime = startOffset + _trimDuration;
  const currentTimeInMs = secondsInMs(videoElement?.currentTime);

  if (currentTimeInMs > endTime || currentTimeInMs < startOffset) {
    videoElement.currentTime = msInSeconds(startOffset);
    endCallback();
  }
};

export const handleTrimmingVideoComponent = ({
  props,
  prevProps,
  trimSource,
  videoRef,
  trimInterval,
  setTrimInterval,
  endCallback,
  forceStartOffset
}) => {
  const { isPlaying } = props;
  const { isPlaying: wasPlaying } = prevProps;
  const startOffset = msInSeconds(trimSource.startOffset || 0);
  const videoElement = getPath(videoRef, "current");

  if (!videoElement) {
    return;
  }

  if (trimSource.startOffset || trimSource.trimDuration) {
    if (isPlaying && !wasPlaying) {
      setTrimInterval(
        setInterval(() => {
          loopTrimmedVideo(videoElement, trimSource, endCallback);
        }, 1)
      );
    }

    if (!isPlaying && wasPlaying) {
      clearInterval(trimInterval);
      if (forceStartOffset) {
        videoElement.currentTime = startOffset;
      }
    }
  }

  if (
    forceStartOffset &&
    !isPlaying &&
    videoElement.currentTime !== startOffset
  ) {
    videoElement.currentTime = startOffset;
  }

  if (
    !forceStartOffset &&
    !isPlaying &&
    videoElement.currentTime < startOffset
  ) {
    videoElement.currentTime = startOffset;
  }
};
