import * as foldersEntitiesTypes from "state/entities/folders/foldersTypes";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";
import { findPage } from "state/api/helpers";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50
};

const foldersApiReducers = (state = initState, action) => {
  switch (action.type) {
    case foldersEntitiesTypes.FETCH_TEAM_FOLDERS_REQUEST: {
      return handleDesignRequest({ state, action });
    }

    case foldersEntitiesTypes.FETCH_TEAM_FOLDERS_REQUEST_SUCCESS: {
      return handleDesignRequestSuccess({ state, action });
    }

    case foldersEntitiesTypes.CREATE_TEAM_FOLDER_REQUEST: {
      const pageNumber =
        Object.keys(state.pages).map(id => state.pages[id]).length || 1;
      const updatedPages = Object.assign({}, state.pages);
      const updatedPage = updatedPages[pageNumber] || {
        isFetching: false,
        ids: [],
        didInvalidate: true
      };

      updatedPage.isFetching = true;

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageNumber]: {
            ...updatedPage
          }
        }
      };
    }

    case foldersEntitiesTypes.CREATE_TEAM_FOLDER_REQUEST_SUCCESS: {
      const { ids: newId } = action.response;
      const pageNumber =
        Object.keys(state.pages).map(id => state.pages[id]).length || 1;
      const updatedPages = Object.assign({}, state.pages);
      const updatedPage = updatedPages[pageNumber] || {
        isFetching: false,
        ids: [],
        didInvalidate: true
      };

      updatedPage.ids.push(newId);
      updatedPage.isFetching = false;

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageNumber]: {
            ...updatedPage
          }
        }
      };
    }

    case foldersEntitiesTypes.DELETE_TEAM_FOLDER_REQUEST_SUCCESS: {
      const { folderId } = action.extra;
      const pageNumber = findPage(state.pages, folderId);
      const page = state.pages[pageNumber];
      const pageIds = page.ids;

      function removeIdFromPage(array, id) {
        const index = array.indexOf(id);
        if (index !== -1) {
          array.splice(index, 1);
        }
        return array;
      }

      const updatedPage = {
        ...page,
        ids: removeIdFromPage(pageIds, folderId)
      };

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageNumber]: updatedPage
        }
      };
    }

    default:
      return state;
  }
};

export default foldersApiReducers;
