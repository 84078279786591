import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const UpAndDownArrowIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 20}
      height={height || size || 20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      ariaLabelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <g
          stroke={color || easilGrayXDark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M14 3v14m3-3-3 3-3-3M6 17V3m3 3L6 3 3 6" />
        </g>
      </g>
    </svg>
  );
};
UpAndDownArrowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default UpAndDownArrowIcon;
