import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import { omit, isEmpty } from "lib";

export const sizeInitState = {
  pages: {},
  totalCount: null,
  pageSize: 50
};

export const initState = {};

const templatesApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.EASIL_TUTORIAL_DESIGNS_REQUEST:
    case designsEntitiesTypes.EASIL_TEMPLATES_DESIGNS_REQUEST: {
      const sizeName = action.request.params.size;
      const currentState = state[sizeName] || sizeInitState;

      const { page } = action.request;
      return {
        ...state,
        [sizeName]: {
          pages: {
            ...currentState.pages,
            [page]: {
              ...currentState.pages[page],
              isFetching: true
            }
          },
          totalCount: null,
          pageSize: 50
        }
      };
    }

    case designsEntitiesTypes.EASIL_TUTORIAL_DESIGNS_REQUEST_SUCCESS:
    case designsEntitiesTypes.EASIL_TEMPLATES_DESIGNS_REQUEST_SUCCESS: {
      const {
        response: { ids = [] } = {},
        request: { page, params }
      } = action;

      if (isEmpty(ids) && Number(page) !== 1) {
        return {
          ...state,
          [params.size]: {
            ...state[params.size],
            pages: omit(state.pages, action.request.page)
          }
        };
      }

      const updatedPage = {
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false
        }
      };

      return {
        ...state,
        [params.size]: {
          ...state[params.size],
          pages: { ...state[params.size].pages, ...updatedPage }
        }
      };
    }

    default:
      return state;
  }
};

export default templatesApiReducers;
