import * as types from "../../entities/designs/designsTypes";
import { formatErrors } from "lib";

export const initState = {
  isFetching: false,
  isSuccess: false,
  errors: [],
  // store an indication of the currently fetching design
  designId: null
};

const errorsArray = error => formatErrors(error);

const fetchDesign = (state = initState, action) => {
  switch (action.type) {
    case types.DESIGN_REQUEST: {
      return {
        ...initState,
        isFetching: true,
        designId: action.request.extra.designId
      };
    }

    case types.DESIGN_REQUEST_SUCCESS: {
      return {
        ...initState,
        isSuccess: true,
        designId: null
      };
    }

    case types.DESIGN_REQUEST_FAILURE: {
      const { error } = action;
      const errors = errorsArray(error);

      return {
        ...initState,
        errors,
        designId: null
      };
    }

    default:
      return state;
  }
};

export default fetchDesign;
