import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PadlockOpenIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    aria-labelledby="title"
    className={className}
    data-no-pointer={noPointer}
    data-testid="PadlockOpenIcon"
    height={height || size || 24}
    style={style}
    viewBox="0 0 15 16"
    width={width || size || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <g>
        <path d="M0 0h16v16H0z" />
        <path d="M2 4h12v12H2z" />
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M4 7.9A1.1 1.1 0 0 0 2.9 9v4A1.1 1.1 0 0 0 4 14.1h8a1.1 1.1 0 0 0 1.1-1.1V9A1.1 1.1 0 0 0 12 7.9H4zm2.1-1.8V4a2.1 2.1 0 0 0-4.073-.721.9.9 0 0 1-1.69-.618A3.9 3.9 0 0 1 7.9 4v2.1H12A2.9 2.9 0 0 1 14.9 9v4a2.9 2.9 0 0 1-2.9 2.9H4A2.9 2.9 0 0 1 1.1 13V9A2.9 2.9 0 0 1 4 6.1h2.1z"
        />
        <circle cx="8" cy="11" r="1" fill="#FFF" />
      </g>
    </g>
  </svg>
);
PadlockOpenIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PadlockOpenIcon;
