import { omit, isEmpty } from "lib";

export const handleDesignRequest = ({ action, state }) => {
  const { page } = action.request;
  return {
    ...state,
    pages: {
      ...state.pages,
      [page]: {
        ...state.pages[page],
        isFetching: true
      }
    }
  };
};

export const handleDesignRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      pages: omit(state.pages, action.request.page)
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false,
      lastFetched: Date.now()
    }
  };

  return {
    ...state,
    pages: { ...state.pages, ...updatedPage }
  };
};
