import React from "react";
import PropTypes from "prop-types";

const IOMessageIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color = "#2C2E2F",
  secondaryColor = "#FCE068",
  title
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 57 60"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <g fill="none" fillRule="evenodd">
        <rect
          width="50"
          height="37"
          x="7"
          y="23"
          fill={secondaryColor}
          rx="2"
        />
        <path
          fill={color}
          fillRule="nonzero"
          d="M11.006 16.5c.553 0 1 .448 1 1 0 .513-.386.936-.883.993l-.117.007h-5.66c-.5 0-.97.127-1.378.349L26.5 35.75l22.533-16.9c-.36-.197-.77-.32-1.205-.345l-.174-.005h-6.122c-.552 0-1-.448-1-1 0-.513.387-.936.884-.993l.116-.007h6.122c2.601 0 4.728 2.03 4.841 4.581l.005.214v29.41c0 2.58-2.057 4.679-4.63 4.79l-.216.005H5.346c-2.601 0-4.728-2.03-4.841-4.581L.5 50.705v-29.41c0-2.58 2.057-4.679 4.63-4.79l.216-.005h5.66zm-8.5 4.625l-.006.17v29.41c0 1.484 1.179 2.702 2.672 2.79l.174.005h42.308c1.516 0 2.751-1.163 2.84-2.625l.006-.17v-29.41c0-.324-.056-.635-.16-.925L27.1 37.8c-.32.24-.748.264-1.09.072l-.11-.072L2.66 20.37c-.085.238-.139.492-.155.755zM31.612 8.21l.094.083 3 3c.029.028.055.059.08.09l-.08-.09c.036.035.068.073.097.112.018.024.034.049.05.074l.021.037c.011.02.022.04.031.06l.023.053.021.06.014.045.016.065.009.053.007.06.003.056L35 12v.033l-.004.052L35 12c0 .05-.004.1-.011.149l-.01.052c-.004.022-.009.043-.015.065l-.014.046-.021.06-.023.051-.03.061-.022.037c-.016.025-.032.05-.05.074l-.014.018c-.005.007-.007.01-.01.012l-.073.082-3 3c-.39.39-1.024.39-1.414 0-.36-.36-.388-.928-.083-1.32l.083-.094L31.585 13H20c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L20 11h11.585l-1.292-1.293c-.36-.36-.388-.928-.083-1.32l.083-.094c.36-.36.928-.388 1.32-.083zm-9.226-8c.392-.305.96-.278 1.32.083l.083.094c.305.392.278.96-.083 1.32L22.415 3H34l.117.007c.497.057.883.48.883.993 0 .552-.448 1-1 1H22.415l1.292 1.293.083.094c.305.392.278.96-.083 1.32-.39.39-1.024.39-1.414 0l-3-3-.073-.082-.007-.008-.017-.022c-.018-.024-.034-.049-.05-.074l-.021-.037c-.011-.02-.022-.04-.031-.06l-.023-.053-.021-.06-.014-.045-.016-.065-.009-.053C19.004 4.1 19 4.051 19 4l.004.09L19 4.02V4v-.02c0-.022.002-.046.004-.07l.007-.059.01-.052c.004-.022.009-.043.015-.065l.014-.046.021-.06.023-.051.03-.061.022-.037c.016-.025.032-.05.05-.074.03-.04.061-.077.097-.112l-.08.09c.025-.031.051-.062.08-.09l3-3z"
        />
      </g>
    </svg>
  );
};

IOMessageIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default IOMessageIcon;
