import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { Tooltip } from "views/components";
import InfoIcon from "views/components/icons/InfoIcon";

export const DropDownOptions = ({
  handleOpenModal,
  isCurrentTeamAtDepthLimit
}) => {
  return (
    <div className={style.dropDownWrapper} data-testid="DropDownOptions">
      {!isCurrentTeamAtDepthLimit && (
        <div>
          <span onClick={() => handleOpenModal("createSubTeamModal")}>
            New Sub-Team
          </span>
          <div
            data-tip="Create a new Team under this team"
            data-for="teamCreateToolTip"
          >
            <InfoIcon size="16px" color={style.easilGrayXLight} />
          </div>
        </div>
      )}
      <div>
        <span onClick={() => handleOpenModal("createTeamModal")}>New Team</span>
        <div
          data-tip="Create a new top-level Team"
          data-for="teamCreateToolTip"
        >
          <InfoIcon size="16px" color={style.easilGrayXLight} />
        </div>
      </div>
      <Tooltip id="teamCreateToolTip" place="bottom" />
    </div>
  );
};

DropDownOptions.propTypes = {
  handleOpenModal: PropTypes.func
};

export default DropDownOptions;
