import React from "react";
import style from "./style.module.css";
import Dropdown2 from "views/components/dropdown2/Dropdown2";
import FontOptionsRenderer from "views/components/fontSelect/FontOptionsRenderer";

const FontModalDropDownSection = ({
  teamFont,
  handleSelectChange,
  fontOptions,
  disableDropdown
}) => {
  const onDropdownChange = value => {
    handleSelectChange(value);
  };

  return (
    <div className={style.settings}>
      <div className={style.settingsFontFamily}>
        <label className={style.heading}>Font-family</label>
        <Dropdown2
          dropdownHeight="300px"
          value={teamFont.fontName}
          selected={teamFont.fontId}
          options={fontOptions}
          optionsRenderer={FontOptionsRenderer}
          onChange={onDropdownChange}
          disabled={disableDropdown}
          className={style.dropdown}
        />
      </div>
    </div>
  );
};

export default FontModalDropDownSection;
