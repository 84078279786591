import React from "react";
import { NavLink } from "react-router-dom";
import CaretIcon from "views/components/icons/CaretIcon";
import style from "./style.module.css";

export class HeaderDropdownLink extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setPopoutRef = this.setPopoutRef.bind(this);
    this.setCaretRef = this.setCaretRef.bind(this);
    this.handleClosePopout = this.handleClosePopout.bind(this);

    this.popoutRef = null;
    this.caretRef = null;

    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    document
      .getElementById("root")
      .addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document
      .getElementById("root")
      .removeEventListener("click", this.handleClickOutside);
  }

  setPopoutRef(node) {
    this.popoutRef = node;
  }

  setCaretRef(node) {
    this.caretRef = node;
  }

  handleClickOutside(event) {
    const { popoutRef, caretRef } = this;

    if (
      this.state.isOpen &&
      popoutRef &&
      !popoutRef.contains(event.target) &&
      caretRef &&
      !caretRef.contains(event.target) &&
      event.target.isConnected
    ) {
      this.handleClosePopout();
    }
    return event;
  }

  handleClosePopout() {
    this.setState({
      isOpen: false
    });
  }

  handleToggleDropdown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { options = [], label, urlBase } = this.props;

    const isActive = window.location.pathname.includes(urlBase);

    return (
      <div className={style.dropdownLinkWrapper}>
        <div
          className={style.dropdownLink}
          data-is-active={isActive}
          onClick={this.handleToggleDropdown}
          ref={this.setCaretRef}
        >
          {label}
          <CaretIcon direction="down" color="#ffffff" size="10px" />
        </div>
        {this.state.isOpen && (
          <div className={style.dropdown} ref={this.setPopoutRef}>
            {options.map(option => (
              <div
                className={style.dropdownOption}
                key={option.label}
                onClick={this.handleClosePopout}
              >
                <NavLink to={option.path}>{option.label}</NavLink>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default HeaderDropdownLink;
