const teamUsersStoreSelector = state => state.entities.users;
const teamUsersSearchSelector = state => state.api.teamUsersSearch;

export const allSearchedTeamUsers = (state, page = 1) => {
  const usersStore = teamUsersStoreSelector(state);
  const teamSearchState = teamUsersSearchSelector(state);

  if (!teamSearchState || !teamSearchState.currentSearchTerm) return null;

  const currentTerm = teamSearchState.currentSearchTerm;
  const searchResultsPages = teamSearchState.results[currentTerm].pages;
  const searchResultsIds = searchResultsPages[page].ids;

  if (
    searchResultsPages[page].isFetching ||
    !searchResultsIds ||
    !Array.isArray(searchResultsIds)
  )
    return null;

  const searchedUsers = {};

  searchResultsIds.forEach(id => {
    searchedUsers[id] = usersStore[id];
  });

  return searchedUsers;
};

export const isSearchingTeamMembers = (state, page = 1) => {
  const teamSearchState = teamUsersSearchSelector(state);

  if (!teamSearchState || !teamSearchState.currentSearchTerm) return null;

  const currentTerm = teamSearchState.currentSearchTerm;
  const searchResultsPages = teamSearchState.results[currentTerm].pages;

  return searchResultsPages[page].isFetching;
};
