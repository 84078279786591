export const findTableNextTextFieldAddress = ({
  table,
  currentRowIndex,
  currentCellIndex,
  currentTextFieldIndex
}) => {
  /* try the next cell */
  let nextTextField = table.getTextFieldByAddress(
    currentRowIndex,
    currentCellIndex + 1,
    currentTextFieldIndex
  );

  if (nextTextField) {
    return {
      nextRowIndex: currentRowIndex,
      nextCellIndex: currentCellIndex + 1,
      nextTextFieldIndex: currentTextFieldIndex
    };
  }

  /* try the next textField */
  nextTextField = table.getTextFieldByAddress(
    currentRowIndex,
    0,
    currentTextFieldIndex + 1
  );

  if (nextTextField) {
    return {
      nextRowIndex: currentRowIndex,
      nextCellIndex: 0,
      nextTextFieldIndex: currentTextFieldIndex + 1
    };
  }

  /* try the next row */
  nextTextField = table.getTextFieldByAddress(currentRowIndex + 1, 0, 0);

  if (nextTextField) {
    return {
      nextRowIndex: currentRowIndex + 1,
      nextCellIndex: 0,
      nextTextFieldIndex: 0
    };
  }

  return null;
};

export const findTablePreviousTextFieldAddress = ({
  table,
  currentRowIndex,
  currentCellIndex,
  currentTextFieldIndex
}) => {
  /* try the previous cell */
  let previousTextField = table.getTextFieldByAddress(
    currentRowIndex,
    currentCellIndex - 1,
    currentTextFieldIndex
  );

  if (previousTextField) {
    return {
      previousRowIndex: currentRowIndex,
      previousCellIndex: currentCellIndex - 1,
      previousTextFieldIndex: currentTextFieldIndex
    };
  }

  /* try the previous textField */
  const lastCellIndex = table.rows[currentRowIndex].cells.length - 1;
  previousTextField = table.getTextFieldByAddress(
    currentRowIndex,
    lastCellIndex,
    currentTextFieldIndex - 1
  );

  if (previousTextField) {
    return {
      previousRowIndex: currentRowIndex,
      previousCellIndex: lastCellIndex,
      previousTextFieldIndex: currentTextFieldIndex - 1
    };
  }

  /* if it is the first/zeroIndex row, there is no previous, return null */
  if (currentRowIndex === 0) {
    return null;
  }

  const previousRow = currentRowIndex - 1;
  const previousRowLastCellIndex = table.rows[previousRow].cells.length - 1;
  const previousRowLastTextFieldIndex =
    table.rows[previousRow].cells[previousRowLastCellIndex].textFields.length -
    1;

  return {
    previousRowIndex: previousRow,
    previousCellIndex: previousRowLastCellIndex,
    previousTextFieldIndex: previousRowLastTextFieldIndex
  };
};
