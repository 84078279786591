export const parseLinkUrl = linkUrl => {
  try {
    new URL(linkUrl);
  } catch (error) {
    // assume https
    return `https://${linkUrl}`;
  }

  // link is valid
  return linkUrl;
};
