import * as types from "./printOptionsTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { teamSelector } from "state/entities/teams/teamsSelectors";
import { templatePrintOptionsSelector } from "state/entities/printOptions/printOptionsSelectors";

export const getPrintOptionsByTemplateCode = ({ templateCode }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const country = teamSelector({ state }).country;

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER,
      types: [
        types.FETCH_PRINT_OPTIONS_BY_TEMPLATE_CODE_REQUEST,
        types.FETCH_PRINT_OPTIONS_BY_TEMPLATE_CODE_REQUEST_SUCCESS,
        types.FETCH_PRINT_OPTIONS_BY_TEMPLATE_CODE_REQUEST_FAILURE
      ],
      endpoint: `/templates/${templateCode}/country/${country}/description-url`,
      schema: schemas.NONE,
      request: {
        body: {
          templateCode,
          country: country
        }
      }
    }
  });
};

export const getPrintOptions = ({ templateCode }) => (dispatch, getState) => {
  const state = getState();
  const templatePrintOptions = templatePrintOptionsSelector(
    state,
    templateCode
  );

  // prevent fetch of print options url if a templateCode already exists in state
  if (!templatePrintOptions) {
    dispatch(getPrintOptionsByTemplateCode({ templateCode }));
  }
};
