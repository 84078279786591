import Element from "state/ui/editor/elements/Element";
import TextBoxElement from "state/ui/editor/elements/TextBoxElement";
import TableElement from "state/ui/editor/elements/TableElement";
import Table2Element from "state/ui/editor/elements/Table2Element";
import GridElement from "state/ui/editor/elements/GridElement";
import VectorElement from "state/ui/editor/elements/VectorElement";
import VectorTextElement from "state/ui/editor/elements/VectorTextElement";
import QrCodeElement from "state/ui/editor/elements/QrCodeElement";
import ImageElement from "state/ui/editor/elements/ImageElement";
import VideoElement from "state/ui/editor/elements/VideoElement";
import BackgroundElement from "state/ui/editor/elements/BackgroundElement";
import Group from "state/ui/editor/elements/Group";
import { EDITOR_ELEMENTS_MAP } from "lib/constants";

class ElementFactory {
  static create(element) {
    if (element instanceof Element) {
      return element;
    }

    switch (element.type) {
      case EDITOR_ELEMENTS_MAP.TABLE: {
        return new TableElement(element);
      }

      case EDITOR_ELEMENTS_MAP.TABLE2: {
        return new Table2Element(element);
      }

      case EDITOR_ELEMENTS_MAP.TEXTBOX: {
        return new TextBoxElement(element);
      }

      case EDITOR_ELEMENTS_MAP.GRID: {
        return new GridElement(element);
      }

      case EDITOR_ELEMENTS_MAP.VECTOR: {
        return new VectorElement(element);
      }

      case EDITOR_ELEMENTS_MAP.VECTOR_TEXT: {
        return new VectorTextElement(element);
      }

      case EDITOR_ELEMENTS_MAP.QR_CODE: {
        return new QrCodeElement(element);
      }

      case EDITOR_ELEMENTS_MAP.GROUP: {
        return new Group(element);
      }

      case EDITOR_ELEMENTS_MAP.IMAGE: {
        return new ImageElement(element);
      }

      case EDITOR_ELEMENTS_MAP.BACKGROUND: {
        return new BackgroundElement(element);
      }

      case EDITOR_ELEMENTS_MAP.VIDEO: {
        return new VideoElement(element);
      }

      default: {
        return new Element(element);
      }
    }
  }
}

export default ElementFactory;
