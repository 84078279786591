import React, { Component } from "react";
import { connect } from "react-redux";
import {
  contextSelector,
  elementPreviewsSelector,
  selectedItemsSelector
} from "state/ui/editorContext/editorContextSelectors";
import {
  findTableNextTextFieldAddress,
  findTablePreviousTextFieldAddress
} from "views/components/Editor/editorOps/EditorTableOps/helpers";
import { getCurrentDesignData } from "state/ui/editor/editorSelectors";
import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";
import { currentSubscriptionPlanSelector } from "state/entities/subscriptionPlans/subscriptionPlansSelectors";
import { isEmpty } from "lib/lodash";
import { decodeHtmlTable } from "lib/htmlStrings";
import { sanitizeHTMLForTextUsingDOMNodes } from "lib/textUtils";
import Logger from "lib/logger";
import { addAction as addActionToPropagationQueue } from "state/ui/editorActionPropagation/editorActionPropagationActions";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { saveDesign } from "state/ui/editor/editorActions";
import {
  designById,
  designsByCollectionId
} from "state/entities/designs/designsSelectors";
import { sidePanelSelector } from "state/ui/sidePanel/sidePanelSelectors";
import { update as updateSidePanelState } from "state/ui/sidePanel/sidePanelActions";
import { update as updateActionBarState } from "state/ui/actionBar/actionBarActions";
import { actionBarStateSelector } from "state/ui/actionBar/actionBarSelectors";
import { showUpgradeModal } from "state/ui/upgradeModal/upgradeModalActions";
import { jsonStringEqual } from "lib/equalityUtils";
import { EditorTableOps } from "views/components/Editor/editorOps";
import TextField from "views/components/Editor/elements/Table/TableRow/TableCell/TextField/TextField";
import { tableHeightsUpdater } from "views/components/Editor/editorOps/EditorTableOps/tableSimulator";
import { EditorElementsOps } from "views/components/Editor/editorOps";
import { TEXTBOX_COLLECTION_PROPAGATION_ACTIONS } from "lib/constants";
import { hasEditorTableContextUpdated } from "lib/tableUtils";

class TextFieldContainer extends Component {
  constructor(props) {
    super(props);
    this.onSelectNextTableTextField = this.onSelectNextTableTextField.bind(
      this
    );
    this.selectTableTextField = this.selectTableTextField.bind(this);
    this.onTableTextFieldChange = this.onTableTextFieldChange.bind(this);
    this.onTableTextFieldPreview = this.onTableTextFieldPreview.bind(this);
    this.onSelectPreviousTableTextField = this.onSelectPreviousTableTextField.bind(
      this
    );
    this.updateTextFieldInContext = this.updateTextFieldInContext.bind(this);
    this.addToUpdateCollectionQueue = this.addToUpdateCollectionQueue.bind(
      this
    );
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!jsonStringEqual(this.props.selectedItems, nextProps.selectedItems)) {
      return true;
    } else if (
      hasEditorTableContextUpdated(this.props.context, nextProps.context)
    ) {
      return true;
    } else if (
      !jsonStringEqual(this.props.elementPreviews, nextProps.elementPreviews)
    ) {
      return true;
    } else if (
      !jsonStringEqual(
        this.props.documentRestrictions,
        nextProps.documentRestrictions
      )
    ) {
      return true;
    } else if (!jsonStringEqual(this.props.designData, nextProps.designData)) {
      return true;
    }
    return false;
  }

  selectTableTextField({
    rowIndex,
    cellIndex,
    textFieldIndex,
    textFieldId,
    rowType
  }) {
    Logger.info("TextFieldContainer.selectTableTextField called");
    const { actionbar } = this.props;
    const { updateActionBarState, updateContextState } = this.props;

    updateContextState({
      context: {
        selectedTableFieldId: textFieldId,
        rowIndex,
        cellIndex,
        textFieldIndex,
        rowType
      }
    });

    updateActionBarState({
      ...actionbar,
      buttonActive: "tableTextFieldCell"
    });
  }

  onSelectNextTableTextField() {
    const { context, designData, selectedItems } = this.props;
    const { rowIndex, cellIndex, textFieldIndex } = context;

    const tableId = selectedItems[0].itemId;
    const tableElement = designData.getElement(tableId);

    EditorTableOps.applyOnGoingChanges(tableElement, selectedItems);

    const nextTextFieldAddress = findTableNextTextFieldAddress({
      table: tableElement,
      currentRowIndex: rowIndex,
      currentCellIndex: cellIndex,
      currentTextFieldIndex: textFieldIndex
    });

    if (nextTextFieldAddress) {
      const rowType =
        tableElement.rows[nextTextFieldAddress.nextRowIndex].rowTypeCode;

      this.updateTextFieldInContext({
        rowIndex: nextTextFieldAddress.nextRowIndex,
        cellIndex: nextTextFieldAddress.nextCellIndex,
        textFieldIndex: nextTextFieldAddress.nextTextFieldIndex,
        table: tableElement,
        context,
        rowType
      });

      return;
    }

    /* if there is no next Field  we duplicate the last row and select it*/
    const newTable = tableElement.duplicateLastRow();
    const newRowIndex = rowIndex + 1;
    const nextTextField = newTable.getTextFieldByAddress(newRowIndex, 0, 0);

    const elementsId = [newTable.uniqueId];
    const attributes = { rows: newTable.rows, height: newTable.height };
    const newDesignData = designData.updateElementsAttribute({
      elementsId,
      attributes
    });

    const updatedContextState = {
      context: {
        ...context,
        rowIndex: newRowIndex,
        cellIndex: 0,
        textFieldIndex: 0,
        selectedTableFieldId: nextTextField.id
      },
      designData: newDesignData
    };

    this.props.updateContextState(updatedContextState);
    this.props.onSave(newDesignData, updatedContextState);
  }

  /**
   * Add the update to the update collection queue if the update qualifies.
   */
  addToUpdateCollectionQueue(designData, selectedItems, attributes) {
    const selectedElementsData = selectedItems.map(({ itemId, pageId }) => {
      return {
        ...designData.elements[itemId],
        pageId
      };
    });

    const attributeAction = Object.keys(attributes)[0];
    const isAttributeActionPermitted = TEXTBOX_COLLECTION_PROPAGATION_ACTIONS.includes(
      attributeAction
    );
    // if design is part of a collection and permitted text box attributes were updated
    // add action to propagation queue
    if (
      this.props.collectionDesigns.length > 1 &&
      selectedElementsData.every(element => element.type === "textbox") &&
      isAttributeActionPermitted
    ) {
      this.props.addActionToPropagationQueue({
        selectedElementsData,
        actionType: "textboxAttributeChange",
        attributes
      });
    }
  }

  /**
   * Update the attributes of the selected items.
   *
   * @param attributes - The attributes of the text that are to be changed.
   * @param selectedItems - The selected text items
   * @param callback - Callback function to execute on completion.
   */
  onSelectedElementsAttributesChange(
    attributes,
    selectedItems = this.props.selectedItems,
    callback
  ) {
    Logger.info("TextFieldContainer.onSelectedElementsAttributesChange called");

    const updatedSelectedPreviews = EditorElementsOps.updatePreviews(
      selectedItems,
      this.props.elementPreviews,
      attributes
    );

    // persist the current elementPreviews as well as updated
    const updatedPreviews = {
      ...this.props.elementPreviews,
      ...updatedSelectedPreviews
    };

    const { designData } = this.props;
    const updatedDesignData = designData.updateElementsAttribute(
      {
        elementsId: selectedItems.map(item => item.itemId),
        attributes
      },
      callback
    );
    const updatedContextState = {
      designData: updatedDesignData,
      lastAttributeChanges: selectedItems.map(({ itemId, pageId }) => ({
        attributes,
        uniqueId: itemId,
        pageId,
        originalElement: designData.elements[itemId]
      })),
      elementPreviews: updatedPreviews,
      selectedItems: this.props.selectedItems.map(item => ({
        ...item,
        preview: {}
      }))
    };
    this.props.onSave(updatedDesignData, updatedContextState);
    this.props.updateContextState(updatedContextState);
    this.addToUpdateCollectionQueue(designData, selectedItems, attributes);
  }

  onTableTextFieldChange() {
    Logger.info("TextFieldContainer.onTableTextFieldChange called");
    const { selectedItems } = this.props;

    /* if there is no preview then nothing is changed, escape */
    if (
      !selectedItems ||
      !selectedItems[0] ||
      !selectedItems[0].preview ||
      isEmpty(selectedItems[0].preview)
    ) {
      return;
    }

    const tablePreview = selectedItems[0].preview;

    tablePreview.rows = decodeHtmlTable(Object.assign({}, tablePreview))[
      tablePreview.uniqueId
    ].rows;

    /* if the preview contains no changes escape */
    if (isEmpty(tablePreview)) {
      return;
    }

    this.onSelectedElementsAttributesChange(tablePreview, selectedItems);
  }

  updateTextFieldInContext({
    rowIndex,
    cellIndex,
    textFieldIndex,
    rowType,
    table,
    context
  }) {
    const textField = table.getTextFieldByAddress(
      rowIndex,
      cellIndex,
      textFieldIndex
    );

    this.props.updateContextState({
      context: {
        ...context,
        rowIndex,
        cellIndex,
        textFieldIndex,
        selectedTableFieldId: textField.id,
        rowType
      }
    });
  }

  /**
   * Handle the update of attributes on the selected item.
   * @param {object} attributes - The attributes and the new values they should be set to.
   */
  onElementPreview(attributes) {
    Logger.info("TextFieldContainer.onElementPreview called");
    if (attributes.isColorChange) {
      this.handleColorChange(attributes);
      return;
    }

    const updatedSelectedItems = EditorElementsOps.updateSelectedItemAttributes(
      this.props.designData,
      this.props.selectedItems,
      this.props.context,
      attributes
    );

    this.props.updateContextState({ selectedItems: updatedSelectedItems });
  }

  onTableTextFieldPreview({
    value,
    rowIndex,
    cellIndex,
    textFieldIndex,
    tableId
  }) {
    Logger.info("TextFieldContainer.onTableTextFieldPreview called");
    const { designData } = this.props;

    const tableElement = designData.getElement(tableId);

    // sanitize the value so that the preview has clean input
    const sanitizedValue = sanitizeHTMLForTextUsingDOMNodes(value);

    const tableElementWithTextFieldUpdated = tableElement.updateTextFieldValue({
      rowIndex,
      cellIndex,
      textFieldIndex,
      value: sanitizedValue
    });

    const tableWithHeightsUpdated = tableHeightsUpdater(
      tableElementWithTextFieldUpdated
    );
    this.onElementPreview({
      rows: tableWithHeightsUpdated.rows,
      height: tableWithHeightsUpdated.height
    });
  }

  onSelectPreviousTableTextField() {
    const {
      context,
      designData,
      selectedItems,
      rowIndex,
      cellIndex,
      textFieldIndex
    } = this.props;

    const tableId = selectedItems[0].itemId;
    const tableElement = designData.getElement(tableId);

    const previousTextFieldAddress = findTablePreviousTextFieldAddress({
      table: tableElement,
      currentRowIndex: rowIndex,
      currentCellIndex: cellIndex,
      currentTextFieldIndex: textFieldIndex
    });

    if (previousTextFieldAddress) {
      const rowType =
        tableElement.rows[previousTextFieldAddress.previousRowIndex]
          .rowTypeCode;

      this.updateTextFieldInContext({
        rowIndex: previousTextFieldAddress.previousRowIndex,
        cellIndex: previousTextFieldAddress.previousCellIndex,
        textFieldIndex: previousTextFieldAddress.previousTextFieldIndex,
        table: tableElement,
        context,
        editor: this,
        rowType
      });

      return;
    }
  }

  render() {
    const { designData, rowIndex, cellIndex } = this.props;

    const isSelected =
      this.props.id === this.props.context.selectedTableFieldId;

    const getFields = () => {
      if (!this.props.selectedItems || !this.props.selectedItems.length) {
        return {
          isTextEditRestricted: true,
          rowType: ""
        };
      }

      const tableElement = designData.getElement(
        this.props.selectedItems[0].itemId
      );
      const rowType =
        !!tableElement && tableElement.rows && tableElement.rows[rowIndex]
          ? tableElement.rows[rowIndex].rowTypeCode
          : "";

      if (!isSelected) {
        return {
          isTextEditRestricted: true,
          rowType
        };
      }
      return {
        isTextEditRestricted: tableElement.isAttributeRestricted("textEdit"),
        rowType
      };
    };

    const { isTextEditRestricted, rowType } = getFields();

    return (
      <TextField
        {...this.props}
        onSelectNextTableTextField={this.onSelectNextTableTextField}
        onSelectPreviousTableTextField={this.onSelectPreviousTableTextField}
        onTableTextFieldPreview={args =>
          this.onTableTextFieldPreview({
            ...args,
            rowIndex: rowIndex,
            cellIndex: cellIndex,
            textFieldIndex: this.props.index,
            tableId: this.props.tableId
          })
        }
        onTableTextFieldChange={this.onTableTextFieldChange}
        onSelectTableTextField={this.selectTableTextField}
        isSelected={isSelected}
        isTextEditRestricted={isTextEditRestricted}
        rowType={rowType}
        selectedItem={this.props.selectedItems[0]}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addActionToPropagationQueue: args =>
      dispatch(addActionToPropagationQueue(args)),
    onSave: (...args) => dispatch(saveDesign(...args)),
    showUpgradeModal: args => dispatch(showUpgradeModal(args)),
    updateActionBarState: args => dispatch(updateActionBarState(args)),
    updateContextState: args => dispatch(updateEditorContextState(args)),
    updateSidePanelState: args => dispatch(updateSidePanelState(args))
  };
};

const mapStateToProps = state => {
  const currentDesignData = getCurrentDesignData(state);
  const currentSubscription = currentSubscriptionSelector(state);
  const currentPlan = currentSubscription
    ? currentSubscriptionPlanSelector(state, currentSubscription.planId)
    : null;

  let collectionDesigns = [];
  let documentRestrictions = [];
  if (currentDesignData) {
    const designId = currentDesignData.id;
    const design = designById({ state, designId });
    documentRestrictions = currentDesignData.getRestrictionsMap();

    if (design) {
      collectionDesigns = designsByCollectionId({
        state,
        collectionId: design.collectionId
      });
    }
  }

  return {
    actionbar: actionBarStateSelector(state),
    collectionDesigns,
    context: contextSelector(state),
    designData: currentDesignData,
    documentRestrictions,
    elementPreviews: elementPreviewsSelector(state),
    selectedItems: selectedItemsSelector(state),
    sidePanel: sidePanelSelector(state),
    userPlan: currentPlan
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextFieldContainer);
