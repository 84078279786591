import Logger from "lib/logger";
import { EditorElementsOps as EditorElementOps } from "views/components/Editor/editorOps";

/**
 * A file containing the functions that are used across multiple, if not all, action bar containers.
 */
const ActionBarOps = {
  onElementsAttributesChange(elementUpdates, containerProps, callback) {
    Logger.info("ActionBarOps.onElementsAttributesChange called");
    const updatedDesignData = containerProps.designData.updateElementsAttributes(
      {
        elementUpdates
      }
    );

    containerProps.updateContextState(updatedDesignData);
    containerProps.onSave(updatedDesignData, {});
    // TODO: What to do with the callback?
  },
  /**
   * Take the current selection and select grouped elements instead of groups where applicable
   * @param {object} containerProps - Props of the calling container.
   */
  onFlattenSelection(containerProps) {
    Logger.info("ActionBarOps.onFlattenSelection called");
    const newSelection = EditorElementOps.flattenSelection(
      containerProps.selectedItems,
      containerProps.designData
    );
    containerProps.updateContextState({ selectedItems: newSelection });
  },

  /**
   * Update the supplied attributes of the selected items.
   *
   * @param attributes - The attributes of the text that are to be changed.
   * @param selectedItems - The selected text items
   * @param containerProps - The container props containing additional data required to process
   * the action.
   * @param callback - Callback function to execute on completion.
   * @returns {object} updated design data object.
   */
  onSelectedElementsAttributesChange(
    attributes,
    selectedItems,
    containerProps,
    callback
  ) {
    Logger.info("ActionbarOps.onSelectedElementsAttributesChange called");

    const updatedSelectedPreviews = EditorElementOps.updatePreviews(
      selectedItems,
      containerProps.elementPreviews,
      attributes
    );

    // persist the current elementPreviews as well as updated
    const updatedPreviews = {
      ...containerProps.elementPreviews,
      ...updatedSelectedPreviews
    };

    const { designData } = containerProps;
    const updatedDesignData = designData.updateElementsAttribute(
      {
        elementsId: selectedItems.map(item => item.itemId),
        attributes
      },
      callback
    );
    const updatedContextState = {
      designData: updatedDesignData,
      lastAttributeChanges: selectedItems.map(({ itemId, pageId }) => ({
        attributes,
        uniqueId: itemId,
        pageId,
        originalElement: designData.elements[itemId]
      })),
      elementPreviews: updatedPreviews,
      selectedItems: containerProps.selectedItems.map(item => ({
        ...item,
        preview: {}
      }))
    };
    containerProps.onSave(updatedDesignData, updatedContextState);
    containerProps.updateContextState(updatedContextState);

    // Some callers require this to add to update collection queue
    return updatedDesignData;
  }
};

export default ActionBarOps;
