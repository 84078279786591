import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TunesIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    stroke={color || easilGrayXDark}
    data-testid="TunesIcon"
  >
    <title>{title}</title>
    <g fill="none" stroke="none" fillRule="evenodd">
      <path d="M15 0v16H-1V0z" />
      <path
        fill={color || easilGrayXDark}
        d="M4 8v7a1 1 0 0 1-2 0V8H1a1 1 0 0 1 0-2h4a1 1 0 1 1 0 2H4zm0-4V1a1 1 0 0 0-2 0v3h2zm8 8v3a1 1 0 0 1-2 0v-3H9a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2h-1zm0-4V1a1 1 0 1 0-2 0v7h2z"
      />
    </g>
  </svg>
);
TunesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TunesIcon;
