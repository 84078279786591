import React from "react";
import { easilGrayXDark } from "./style.module.css";

const TickIcon = ({ size = 24, color = easilGrayXDark, hasCircle = true }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-2-2h24v24H-2z" />
        <g fill={color} fillRule="nonzero">
          {hasCircle && (
            <path d="M13.357.684a.9.9 0 0 1-.61 1.693A8.1 8.1 0 1 0 18.1 10a.9.9 0 1 1 1.8 0c0 5.468-4.432 9.9-9.9 9.9S.1 15.468.1 10 4.532.1 10 .1c1.158 0 2.29.2 3.357.584z" />
          )}
          <path d="M10.148 10.713l7.21-7.343a.9.9 0 1 1 1.284 1.26l-7.854 8a.9.9 0 0 1-1.286-.002L6.356 9.403a.9.9 0 0 1 1.288-1.257l2.504 2.567z" />
        </g>
      </g>
    </svg>
  );
};

export default TickIcon;
