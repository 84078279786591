import Element from "state/ui/editor/elements/Element";
import { scaleGrid } from "lib/scalingTools/scalingTools";
import { getGridPlaceholderSize } from "views/components/Editor/elements/grid/imageInstructionUtils";

class GridElement extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "sizeAndPosition",
    "imageUpload",
    "filters",
    "flipping",
    "cropping"
  ];

  stretch({ dragItem, zoom, differenceFromInitialOffset }) {
    const scaledDimensions = scaleGrid({
      element: this,
      dragItem,
      zoom,
      differenceFromInitialOffset
    })[0][0];

    return new this.constructor({
      ...this,
      top: this.top + scaledDimensions.topIncrement / zoom,
      left: this.left + scaledDimensions.leftIncrement / zoom,
      height: scaledDimensions.height / zoom,
      width: scaledDimensions.width / zoom,
      imageInstructions: scaledDimensions.imageInstructions
    });
  }

  updateImageInstructions({ imageInstructions }) {
    return new this.constructor({
      ...this,
      imageInstructions
    });
  }

  getImageColors() {
    const colors = {};

    this.imageInstructions.forEach(imageInstruction => {
      this.getImageInstructionColors(imageInstruction, colors);
    });

    return colors;
  }

  getImageInstructionColors(imageInstruction, colors = {}) {
    if (!imageInstruction.id) {
      return;
    }

    return (colors[imageInstruction.id] = {
      previewUrl: imageInstruction.previewSrc || this.src,
      colors: imageInstruction.palette
    });
  }

  adjustImageInstructionsForScale(scale, attributeChanged) {
    return this.imageInstructions.map(imageInstruction => {
      if (!imageInstruction.id) return imageInstruction;

      const size = getGridPlaceholderSize(imageInstruction, {
        ...this,
        [attributeChanged]: this[attributeChanged] * scale
      });

      const scaleToFit = Math.max(
        size.width / imageInstruction.srcWidth,
        size.height / imageInstruction.srcHeight
      );

      const imageScaled = {
        height: imageInstruction.srcHeight * scaleToFit,
        width: imageInstruction.srcWidth * scaleToFit
      };

      return {
        ...imageInstruction,
        top: (size.height - imageScaled.height) / 2,
        left: (size.width - imageScaled.width) / 2,
        height: imageScaled.height,
        width: imageScaled.width,
        scale: 1
      };
    });
  }

  updateAttributes(attributes) {
    const sideEffects = {};

    const _attributes = super.ensureMinimalValues(attributes);

    if (_attributes.hasOwnProperty("width")) {
      const scale = _attributes.width / this.width;

      sideEffects.imageInstructions = this.adjustImageInstructionsForScale(
        scale,
        "width"
      );
    }

    if (_attributes.hasOwnProperty("height")) {
      const scale = _attributes.height / this.height;

      sideEffects.imageInstructions = this.adjustImageInstructionsForScale(
        scale,
        "height"
      );
    }

    return new this.constructor({
      ...this,
      ...attributes,
      ...sideEffects
    });
  }
}

export default GridElement;
