import { getPath, chunk } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import * as designsTeamFoldersTypes from "state/entities/designFolders/designFoldersTypes";
import * as foldersEntitiesTypes from "state/entities/folders/foldersTypes";
import { getParameterByName } from "lib/queryStringUtils";
import { removeItemsFromArray } from "state/api/designs/helpers";

export const initState = {
  pageSize: 50
};

const foldersApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_BY_TEAM_FOLDER_REQUEST: {
      const {
        request: {
          page,
          params: { folderId }
        }
      } = action;

      const size = getPath(action, "request.params.size") || "all";

      return immutableUpdate(state, {
        [folderId]: {
          $auto: {
            sizes: {
              $auto: {
                [size]: {
                  $auto: {
                    pages: {
                      $auto: {
                        [page]: {
                          $auto: {
                            $merge: {
                              isFetching: true
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case designsEntitiesTypes.DESIGNS_BY_TEAM_FOLDER_REQUEST_SUCCESS: {
      const {
        response: { ids = [] } = {},
        request: {
          page,
          params: { folderId }
        }
      } = action;

      const size = getPath(action, "request.params.size") || "all";

      return immutableUpdate(state, {
        [folderId]: {
          $auto: {
            sizes: {
              $auto: {
                [size]: {
                  $auto: {
                    pages: {
                      $auto: {
                        [page]: {
                          $auto: {
                            $merge: {
                              isFetching: false,
                              ids: ids || [],
                              lastFetched: Date.now()
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case designsTeamFoldersTypes.ADD_CONTENT_TO_TEAM_FOLDER_SUCCESS: {
      if (!Array.isArray(action.response)) {
        return state;
      }

      const ids = action.response.map(design => design.designId);

      const {
        request: {
          body: { folderId }
        }
      } = action;

      if (!folderId) {
        return state;
      }

      const size = getPath(action, "request.body.size", "all");

      const currentPages = getPath(
        state,
        `${folderId}.sizes.${size}.pages`,
        {}
      );

      // new id with all other ids joined into an array
      const allIds = Object.values(currentPages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        ids
      );
      // split ids into chunks of page size
      const idsSplitIntoPages = chunk(allIds, state.pageSize);

      // build the pages and mark all as invalidated for future refetch
      const newPages = idsSplitIntoPages.reduce(
        (previousPages, ids, index) => ({
          ...previousPages,
          [index + 1]: {
            isFetching: false,
            ids,
            didInvalidate: true,
            folderId
          }
        }),
        {}
      );

      return immutableUpdate(state, {
        [folderId]: {
          $auto: {
            sizes: {
              $auto: {
                [size]: {
                  $auto: {
                    $set: {
                      pages: newPages
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case foldersEntitiesTypes.DELETE_TEAM_FOLDER_REQUEST: {
      return immutableUpdate(state, {
        [action.extra.folderId]: {
          $auto: {
            $set: {
              sizes: {}
            }
          }
        }
      });
    }

    case designsTeamFoldersTypes.REMOVE_CONTENT_FROM_TEAM_FOLDER_SUCCESS: {
      const folderId = getParameterByName("folderId", action.queryParams);
      const size = getPath(action, "request.params.size", "all");
      const page = getPath(action, "request.page", 1);

      // current design ids in folder
      const folderDesignIds = getPath(
        state,
        `${folderId}.sizes.${size}.pages.${page}.ids`,
        []
      );

      // collection designs passed in through the dispatch action
      const designsInCollection = getPath(
        action,
        "extra.designsInCollection",
        []
      );

      // return early if no folderId or designs are present
      if (!folderId || !designsInCollection.length) {
        return initState;
      }

      const designIdsToRemove = designsInCollection.map(design => design.id);

      const updatedFolderIds = removeItemsFromArray(
        designIdsToRemove,
        folderDesignIds
      );

      return immutableUpdate(state, {
        [folderId]: {
          $auto: {
            sizes: {
              $auto: {
                [size]: {
                  $auto: {
                    pages: {
                      $auto: {
                        [page]: {
                          $auto: {
                            $merge: {
                              ids: updatedFolderIds || []
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case designsEntitiesTypes.TEAM_TEMPLATES_STATUS_UPDATE_REQUEST_SUCCESS: {
      const folderId = getParameterByName("folderId", action.queryParams);
      const size = getPath(action, "request.params.size", "all");
      const page = getPath(action, "request.page", 1);

      // current design ids in folder
      const folderDesignIds = getPath(
        state,
        `${folderId}.sizes.${size}.pages.${page}.ids`,
        []
      );

      const designIdsToRemove = getPath(action, "response.ids", []);

      // return early if no folderId or designs are present
      if (!folderId || !designIdsToRemove.length) {
        return initState;
      }

      const updatedFolderIds = removeItemsFromArray(
        designIdsToRemove,
        folderDesignIds
      );

      return immutableUpdate(state, {
        [folderId]: {
          $auto: {
            sizes: {
              $auto: {
                [size]: {
                  $auto: {
                    pages: {
                      $auto: {
                        [page]: {
                          $auto: {
                            $merge: {
                              ids: updatedFolderIds || []
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default foldersApiReducers;
