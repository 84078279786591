import { allCountries } from "lib/countriesList";
import { allCountriesWithFlags } from "lib/CountryFlags";

export const getUserDefaultCountry = country => {
  return country
    ? allCountries.find(countryPreset => countryPreset.key === country)
    : allCountries.find(countryPreset => countryPreset.key === "US");
};

export const getUserDefaultCountryWithFlag = country => {
  return country
    ? allCountriesWithFlags.find(countryPreset => countryPreset.key === country)
    : allCountriesWithFlags.find(countryPreset => countryPreset.key === "US");
};
