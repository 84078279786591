import { normalize } from "normalizr";
import { noop, isEmpty } from "lib/lodash";
import Referrer from "lib/Referrer/Referrer";
import Logger from "lib/logger";
import { camelizeKeys } from "humps";
import auth from "lib/auth";
import { httpBodyEncoder } from "./httpHelpers";

export const DEFAULT_PAGE_SIZE = 10;

const handleArrayParams = (paramKey, paramArray) =>
  paramArray.map(param => encodeURIComponent(paramKey) + "=" + param).join("&");

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
export const callApi = ({
  camelize = true,
  headers,
  endpoint,
  schema,
  method,
  body,
  token,
  page,
  service,
  params,
  pageSize = DEFAULT_PAGE_SIZE
}) => {
  let fullUrl =
    endpoint.indexOf(service) === -1 ? service + endpoint : endpoint;

  if (page) {
    fullUrl += `?count=${pageSize}&offset=${pageSize * (page - 1)}`;
  }

  if (!isEmpty(params)) {
    const queryString = Object.keys(params)
      .map(paramKey => {
        const paramValue = params[paramKey];
        return Array.isArray(paramValue)
          ? handleArrayParams(paramKey, paramValue)
          : encodeURIComponent(paramKey) + "=" + encodeURIComponent(paramValue);
      })
      .join("&");

    fullUrl += fullUrl.includes("?") ? `&${queryString}` : `?${queryString}`;
  }

  const myInit = {
    method: method || "GET",
    headers: headers || {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  };

  if (
    ["POST", "PUT", "PATCH"].includes(method) ||
    (method === "DELETE" && body)
  ) {
    myInit.body = httpBodyEncoder({
      body,
      headers: myInit.headers
    });
  }

  return fetch(fullUrl, myInit).then(response => {
    if (response.status === 401) {
      Referrer.set(window.location.pathname);

      Logger.warn(
        `Api Call could not be authenticated. URL calledCa: ${response.url}`
      );

      window.location = auth.LOGIN_URL;
    }

    if (response.status === 204) {
      return {};
    }

    // Redirect portal users to portal password reset
    if (response.status === 409) {
      Referrer.set(window.location.pathname);

      const location = response.headers.get("location");
      if (location) {
        window.location = location;
        return;
      }
    }

    return response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      const reponseJson = camelize ? camelizeKeys(json) : json;

      if (schema._key === "none") {
        return reponseJson;
      }

      const { result: ids, entities } = normalize(reponseJson, schema);

      if (ids === "singleEntry") {
        return {
          entity: Object.values(entities)[0].singleEntry
        };
      } else {
        return {
          ids,
          entities
        };
      }
    });
  });
};

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// GitHub's API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "Call API";

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
const api = store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  let {
    endpoint,
    onSuccess = noop,
    onFailure = noop,
    request,
    headers,
    camelize = true
  } = callAPI;
  const { schema, types, method, service } = callAPI;

  const { body, page, pageSize, params } = request || {};

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!schema) {
    throw new Error("Specify one of the exported Schemas.");
  }
  if (!service) {
    throw new Error("Specify one of the exported Services");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = data => {
    const { request, onSuccess, onFailure, extra, camelize } = action[CALL_API];
    const finalAction = {
      ...action,
      ...data,
      request,
      onSuccess,
      onFailure,
      extra,
      camelize
    };

    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  const token = store.getState().currentUser.token;

  if (store.getState().currentUser.isSwitchingToken && endpoint !== "/token") {
    return;
  }

  return callApi({
    headers,
    endpoint,
    schema,
    method,
    body,
    token,
    page,
    pageSize,
    params,
    service,
    camelize
  }).then(
    response => {
      next(
        actionWith({
          response,
          type: successType
        })
      );

      onSuccess(response);
      return response;
    },
    response => {
      if (response.message === "Failed to fetch") {
        Logger.error("Api Service is unresponsive.");
        // returning here would cause silent failures
        // we should be handling failures not hiding them
      }

      let error;
      if (typeof response.error === "string") {
        error = response;
      } else {
        // the error is nested, need to add a status
        error = {
          ...response.error,
          status: response.status
        };
      }

      next(
        actionWith({
          type: failureType,
          error: error || "Something bad happened with the Api Service"
        })
      );

      onFailure(response);
      return response;
    }
  );
};

export default api;
