import * as types from "state/entities/collectionAllocations/collectionAllocationsTypes";
import { immutableUpdate } from "lib/immutableUpdate";

export const initState = {
  isFetching: false,
  pageSize: 100
};

const collectionAllocationsApiReducers = (state = initState, action) => {
  switch (action.type) {
    // Remove Background Credit Action
    case types.COLLECTION_ALLOCATIONS_REQUEST: {
      // indicate that fetching is happening
      return immutableUpdate(state, {
        isFetching: { $set: true },
        $auto: {
          [action.extra.collectionId]: {
            $auto: {
              ids: {
                $set: []
              }
            }
          }
        }
      });
    }
    case types.COLLECTION_ALLOCATIONS_REQUEST_FAILURE:
    case types.COLLECTION_ALLOCATIONS_REQUEST_SUCCESS: {
      const {
        response: { ids },
        extra: { collectionId }
      } = action;
      // indicate that fetching is no longer occuring and store allocation id array
      return immutableUpdate(state, {
        isFetching: { $set: false },
        $auto: {
          [collectionId]: {
            $auto: {
              ids: {
                $push: ids || []
              }
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default collectionAllocationsApiReducers;
