import { openRequestApprovalModal } from "state/ui/requestApprovalModal/requestApprovalModalActions";
import { openApprovalModal } from "state/ui/approvalModal/approvalModalActions";
import {
  showDownloadProof,
  hidePDFOption
} from "state/ui/portal/portalActions";
import { modifyQuery } from "state/ui/navigation/navigationActions";
import { isEditorInIframe } from "views/components/Editor/utils";
import { PORTAL_EVENTS } from "lib/constants";
import { openCancelApprovalModal } from "state/ui/requestApprovalModal/requestApprovalModalActions";
import { copyToClipBoard } from "state/ui/clipBoard/clipBoardActions";
import designAdapter from "state/ui/editor/designAdapter";
import formatDataForV1 from "state/ui/editor/formatDataForV1";
import { buildDesignObject } from "state/entities/designs/designsActions";

/**
 * Listener function that will handle messages in between the portal and editor within the iFrame
 *
 * @param {object} event Required: Message event sent from the portal
 * @param {function} modifyQuery Optional: Allows changes the url within the editor iFrame.
 * @param {function} handleEditTitleModal Optional: Handles opening edit title modal.
 * @returns
 */
export const iframeListener = ({
  event,
  handleEditTitleModal,
  design
}) => async dispatch => {
  switch (event.data.event) {
    case PORTAL_EVENTS.ORDER_CHECK: {
      dispatch(modifyQuery({ appendTerms: { tab: "orderCheck" } }));
      dispatch(showDownloadProof(event.data.allowDownloadProof));
      break;
    }
    case PORTAL_EVENTS.EDIT_DESIGN_DESCRIPTION: {
      handleEditTitleModal();
      break;
    }
    case PORTAL_EVENTS.REQUEST_APPROVAL: {
      // prevent opening of modal when design is already pending approval
      if (!design.requiresApproval) return;

      dispatch(openRequestApprovalModal({ designId: design.id }));
      break;
    }
    case PORTAL_EVENTS.ACTION_APPROVAL: {
      dispatch(openApprovalModal({ designId: design.id }));
      break;
    }
    case PORTAL_EVENTS.CANCEL_APPROVAL: {
      // prevent opening of modal when design is already pending approval
      if (design.status !== "PENDING_APPROVAL") return;

      dispatch(openCancelApprovalModal());
      break;
    }
    case PORTAL_EVENTS.OPEN_DOWNLOAD: {
      dispatch(modifyQuery({ appendTerms: { tab: "download" } }));

      if (event.data[PORTAL_EVENTS.PREVENT_PDF_DOWNLOAD]) {
        dispatch(hidePDFOption(event.data[PORTAL_EVENTS.PREVENT_PDF_DOWNLOAD]));
      }
      break;
    }
    case PORTAL_EVENTS.CLOSE_DOWNLOAD: {
      dispatch(
        modifyQuery({
          appendTerms: { tab: "resize" },
          dropTerms: [
            "subtab",
            "assetType",
            "categoryLabel",
            "folderId",
            "download"
          ]
        })
      );
      break;
    }
    case PORTAL_EVENTS.CLIPBOARD_CONTENTS: {
      const { elements, sourceDesign } = event.data;

      if (
        !(elements && elements.length) ||
        !(sourceDesign && sourceDesign.design && sourceDesign.designData)
      )
        return;

      // create new instance of elements to provide class methods
      const formattedDesign = formatDataForV1(sourceDesign.designData);
      const designObject = buildDesignObject({
        design: sourceDesign.design,
        designData: formattedDesign
      });
      const designData = await designAdapter(designObject);
      const clipboardElements = elements.map(element =>
        designData.createElement(element)
      );

      dispatch(
        copyToClipBoard({
          elementsCopyArray: clipboardElements,
          sourceDesignSpecs: sourceDesign
        })
      );
      break;
    }
    default:
      return;
  }
};

/**
 * Sends message to portal if the editor is within an iframe
 *
 * @param {*} message Required: Message event to send to the portal
 */
export const sendPortalMessage = message => {
  if (isEditorInIframe()) {
    window.top.postMessage(message, "*");
  }
};
