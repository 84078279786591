import * as types from "./personalTeamTypes";
import { personalTeamSelector } from "./personalTeamSelector";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { noop, isEmpty, reduxStoreExpiry } from "lib";
import { ONE_DAY_IN_MINUTES } from "lib/constants";

export const fetchPersonalTeam = ({ userId, onSuccess = noop }) => ({
  [CALL_API]: {
    method: "GET",
    service: SERVICES.USER,
    endpoint: `/users/${userId}/teams/personal`,
    schema: schemas.TEAMS,
    types: [
      types.PERSONAL_TEAM_REQUEST,
      types.PERSONAL_TEAM_REQUEST_SUCCESS,
      types.PERSONAL_TEAM_REQUEST_FAILURE
    ],
    onSuccess: response => onSuccess(response)
  }
});

// only fetch the personal team if it isn't already present
export const shouldFetchPersonalTeam = state => {
  const personalTeam = personalTeamSelector(state);

  if (isEmpty(personalTeam)) return true;

  const isExpired = reduxStoreExpiry.isDataExpired(
    personalTeam.lastFetched,
    ONE_DAY_IN_MINUTES
  );

  return isExpired;
};

export const fetchPersonalTeamIfNeeded = args => (dispatch, getState) => {
  if (shouldFetchPersonalTeam(getState())) {
    dispatch(fetchPersonalTeam(args));
  }
};
