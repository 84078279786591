import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const HeartHollowIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 22 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h24v24H-1z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M11.429 17.85C16.774 13.732 20 10.044 20 6.356 20 3.954 18.01 2 15.546 2c-1.011 0-1.924.373-2.73 1.01a5.713 5.713 0 0 0-.755.716c-.124.141-.21.255-.234.29a1 1 0 0 1-1.654 0 3.493 3.493 0 0 0-.231-.287 5.712 5.712 0 0 0-.755-.717C8.38 2.375 7.467 2 6.454 2 3.99 2 2 3.954 2 6.355c0 3.688 3.226 7.376 8.571 11.496.12.096.268.149.429.149.16 0 .31-.053.429-.15zM11 20c-.62 0-1.21-.211-1.666-.578C3.488 14.917 0 10.93 0 6.355 0 2.841 2.893 0 6.454 0c1.507 0 2.838.546 3.973 1.443.211.167.402.334.573.497.172-.164.365-.333.578-.501C12.712.544 14.04 0 15.546 0 19.106 0 22 2.84 22 6.355c0 4.572-3.485 8.558-9.327 13.062A2.678 2.678 0 0 1 11 20z"
      />
    </g>
  </svg>
);
HeartHollowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default HeartHollowIcon;
