import { MEDIA_SORT_OPTIONS } from "lib/constants";
import Dropdown2 from "views/components/dropdown2/Dropdown2";
import style from "./common.module.css";
import AZDownIcon from "views/components/icons/AZDownIcon";
import AZUpIcon from "views/components/icons/AZUpIcon";
import ClockArrowClockwiseIcon from "views/components/icons/ClockArrowClockwiseIcon";
import ClockArrowAntiClockwiseIcon from "views/components/icons/ClockArrowAntiClockwiseIcon";
import UpAndDownArrowIcon from "views/components/icons/UpAndDownArrowIcon";
import React from "react";

const getOptionIcon = optionKey => {
  switch (optionKey) {
    case "UPDATEDAT_ASC": {
      return <ClockArrowAntiClockwiseIcon color={style.easilBlack} />;
    }
    case "NAME_ASC": {
      return <AZDownIcon color={style.easilBlack} />;
    }
    case "NAME_DESC": {
      return <AZUpIcon color={style.easilBlack} />;
    }
    case "UPDATEDAT_DESC":
    default: {
      return <ClockArrowClockwiseIcon color={style.easilBlack} />;
    }
  }
};

const optionsRenderer = ({ option, onChange }) => {
  return (
    <div className={style.sortDropdownOptions}>
      {Object.values(MEDIA_SORT_OPTIONS).map(option => {
        return (
          <div
            className={style.sortDropdownOption}
            onClick={() => onChange(option.key)}
          >
            {getOptionIcon(option.key)}
            {option.label}
          </div>
        );
      })}
    </div>
  );
};

const Label = ({ sortKey }) => {
  return (
    <div className={style.sortDropdownLabel}>
      <UpAndDownArrowIcon color={style.easilBlack} />
      {MEDIA_SORT_OPTIONS[sortKey].label}
    </div>
  );
};

export class BrandKitSortButton extends React.Component {
  constructor(props) {
    super(props);

    this.getLabel = this.getLabel.bind(this);
  }

  getLabel() {
    const { sortKey } = this.props;

    return <Label sortKey={sortKey} />;
  }

  render() {
    const { onSortUpdate } = this.props;

    const sortDropdownOptions = Object.values(MEDIA_SORT_OPTIONS);

    return (
      <Dropdown2
        className={style.sortDropdown}
        disabled={false}
        value={this.getLabel}
        isCustomValue={true}
        selected={null}
        onChange={onSortUpdate}
        optionsRenderer={optionsRenderer}
        options={sortDropdownOptions}
        dropdownClassName={style.sortDropdownPopout}
        wrapperClassName={style.sortDropdownWrapper}
        isNewCaret
      />
    );
  }
}

export default BrandKitSortButton;
