import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { isNil } from "lib";

export class Popout extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      dynamicStyle: null
    };
  }

  componentDidMount() {
    this.ensureInsideWrapper();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  horizontalOffset(elementWidth, parentWidth, additionalOffsetHorizontal) {
    const elementMargin = 12;
    return (
      elementWidth / 2 -
      (parentWidth + elementMargin) / 2 +
      additionalOffsetHorizontal
    );
  }

  ensureInsideWrapper() {
    const styles = {};
    const parentWidth = this.props.parentWidth;
    const offsetDirection = this.props.calculateOffsetFromDirection || "left";
    const windowWidth = window.innerWidth;
    const elementPosition = this.element.getBoundingClientRect();
    const additionalOffsetHorizontal =
      this.props.additionalOffsetHorizontal || 0;

    const offset = this.horizontalOffset(
      this.props.width,
      parentWidth,
      additionalOffsetHorizontal
    );
    styles[offsetDirection] = `-${offset}px`;

    if (elementPosition.width + elementPosition.x - offset > windowWidth) {
      delete styles.left;
      styles.right = 0;
    }

    if (elementPosition.width + elementPosition.x - offset < 0) {
      styles.left = 0;
    }

    this.setState({ dynamicStyle: styles });
  }

  handleClickOutside(event) {
    const { element } = this;

    if (!event.target.isConnected) return;

    let isOutsideButton = true;

    if (this.props.buttonRef && this.props.buttonRef.contains(event.target)) {
      isOutsideButton = false;
    }

    if (
      element &&
      !element.contains(event.target) &&
      !element.parentElement.contains(event.target) &&
      event.target.isConnected &&
      isOutsideButton
    ) {
      event.stopPropagation();
      this.props.onClose();
    }
  }

  render() {
    const { dynamicStyle } = this.state;
    const {
      children,
      height,
      width,
      top,
      className,
      maxHeight,
      left
    } = this.props;

    const propStyles = {
      height: height ? `${height}px` : null,
      top: `${top}px`,
      width: `${width}px`,
      maxHeight,
      overflowY: maxHeight ? "scroll" : null
    };

    if (!isNil(left)) {
      propStyles.left = left;
    }

    const styles = Object.assign({}, dynamicStyle, propStyles);

    return (
      <div
        ref={element => (this.element = element)}
        className={`${style.wrapper} ${className}`}
        style={styles}
        data-testid="Popout"
      >
        {children}
      </div>
    );
  }
}

Popout.displayName = "Popout";
Popout.propTypes = {
  calculateOffsetFromDirection: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number,
  maxHeight: PropTypes.string,
  onClose: PropTypes.func, // I feel like this should be required??
  top: PropTypes.number,
  width: PropTypes.number
};

export default Popout;
