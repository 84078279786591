import React from "react";
import { BrandSettingsContainer } from "views/containers";
import common from "./common.module.css";

export default function BrandKitSettings() {
  return (
    <>
      <div className={common.contentTitle}>Brand Settings</div>

      <BrandSettingsContainer />
    </>
  );
}
