import * as types from "./cardsTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";

import { noop } from "lib";

export const fetchCards = ({ teamId, page = 1 }) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.USER,
      types: [
        types.FETCH_CARDS,
        types.FETCH_CARDS_SUCCESS,
        types.FETCH_CARDS_FAILURE
      ],
      request: {
        page
      },
      endpoint: `/teams/${teamId}/card/details`,
      schema: schemas.CARD
    }
  });
};

export const addCard = ({
  teamId,
  token,
  onSuccess = noop,
  onFailure = noop
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.USER,
      types: [
        types.CREATE_CARD,
        types.CREATE_CARD_SUCCESS,
        types.CREATE_CARD_FAILURE
      ],
      endpoint: `/teams/${teamId}/card/details`,
      request: {
        body: {
          teamId,
          token
        }
      },
      schema: schemas.CARD,
      onSuccess,
      onFailure
    }
  });
};

export const removeCard = ({
  stripeCardId,
  teamId,
  onSuccess = noop,
  onFailure = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.USER,
      types: [
        types.REMOVE_CARD,
        types.REMOVE_CARD_SUCCESS,
        types.REMOVE_CARD_FAILURE
      ],
      endpoint: `/teams/${teamId}/card/details`,
      request: {
        body: {
          stripeCardId,
          teamId
        }
      },
      schema: schemas.CARD,
      onSuccess,
      onFailure
    }
  });
};
