export const createDOMElementFromHTMLString = htmlString => {
  const DOMElement = document.createElement("div");
  DOMElement.innerHTML = htmlString;

  // append element to dom so we can use ranges on it
  document.body.appendChild(DOMElement);

  return DOMElement;
};

export const setTestRange = ({
  DOMElement,
  startNodeIndex,
  startNodeOffset,
  endNodeIndex,
  endNodeOffset
}) => {
  const DOMNodes = Array.from(DOMElement.childNodes);

  let startNode = DOMNodes[startNodeIndex];
  let endNode = DOMNodes[endNodeIndex];

  const getStartNode = node => {
    if (node.nodeName !== "#text") {
      return getStartNode(node.firstChild);
    }
    startNode = node;
  };
  // drill down to the text node
  getStartNode(startNode);

  const getEndNode = node => {
    if (node.nodeName !== "#text") {
      return getEndNode(node.firstChild);
    }
    endNode = node;
  };
  // drill down to the text node
  getEndNode(endNode);

  const range = document.createRange();

  range.setStart(startNode, startNodeOffset);
  range.setEnd(endNode, endNodeOffset);

  const selection = window.getSelection();

  selection.removeAllRanges();
  selection.addRange(range);
};
