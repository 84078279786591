import React, { Component } from "react";
import style from "./style.module.css";
import { DropTarget } from "react-dnd";
import { debounce } from "lib/lodash";
import { EditorContext } from "views/components/Editor/EditorContext";
import CropLayer from "../CropLayer";
import { Scrollable } from "views/components";
import BackgroundItemDrop from "./BackgroundItemDrop";

import AddNewPageButton from "./AddNewPageButton";

const canvaTarget = {
  drop(props, monitor, component) {
    const isOverBackgroundOnly = monitor.isOver({ shallow: true });
    /*  */
    if (!isOverBackgroundOnly) {
      return;
    }

    return {
      backgroundItemDrop: new BackgroundItemDrop({
        dragItem: monitor.getItem(),
        differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset()
      })
    };
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true })
  };
}

export class CanvasBackground extends Component {
  static htmlId = "Canvasbackground";

  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleOnScroll = debounce(this.handleOnScroll.bind(this), 300);
    this.handleClicks = this.handleClicks.bind(this);

    this.setElementRef = element => {
      this.element = element;
    };
  }

  componentDidMount() {
    this.props.setElementRef(this.element);
  }

  handleScroll(event) {
    this.props.onGetPagesInView();

    this.handleOnScroll(event);
  }

  handleOnScroll(event) {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    const scrollValue = scrollTop / (scrollHeight - clientHeight);
    const scrollInPercentage = Math.floor(scrollValue * 100) || 1;

    this.props.onScroll({ scrollInPercentage });
  }

  handleClicks(event) {
    if (this.props.areFunctionalitiesLocked) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      /* noop */
    }
  }

  render() {
    const { connectDropTarget, onAddNewPage, canAddNewPage } = this.props;

    return connectDropTarget(
      <div
        onClickCapture={this.handleClicks}
        onDoubleClickCapture={this.handleClicks}
        onClick={this.props.onClick}
        onContextMenu={this.props.onContextMenu}
        ref={this.setElementRef}
        className={style.canvasBackground}
        id={CanvasBackground.htmlId}
        style={{
          width: "100%",
          backgroundColor: "#EDEDEE"
        }}
        data-is-locked={this.props.areFunctionalitiesLocked}
      >
        <Scrollable
          onScroll={this.handleScroll}
          contentWrapperId="CANVAS-BACKGROUND-CONTENT-WRAPPER"
        >
          {this.props.children}
          <AddNewPageButton
            onClick={onAddNewPage}
            canAddNewPage={canAddNewPage}
          />
        </Scrollable>
        <CropLayer canvasBackground={this.element} />
      </div>
    );
  }
}

const CanvasBackgroundDropTarget = DropTarget(
  "ITEM",
  canvaTarget,
  collect
)(CanvasBackground);

const CanvasBackgroundDefault = props => (
  <EditorContext.Consumer>
    {({
      addNewPage,
      areFunctionalitiesLocked,
      clearSelectItems,
      context: { isCroppingTextMaskImage },
      designData,
      handleScroll,
      onGetPagesInView,
      sidePanel: { width },
      zoom
    }) => {
      return (
        <CanvasBackgroundDropTarget
          {...props}
          areFunctionalitiesLocked={areFunctionalitiesLocked}
          sidebarWidth={width}
          canAddNewPage={!designData.getRestrictionsMap().addNewPage}
          onAddNewPage={addNewPage}
          onClick={clearSelectItems}
          onScroll={handleScroll}
          pageWidth={designData.width * zoom}
          isCroppingTextMaskImage={isCroppingTextMaskImage}
          zoom={zoom}
          onGetPagesInView={onGetPagesInView}
        />
      );
    }}
  </EditorContext.Consumer>
);

export default CanvasBackgroundDefault;
