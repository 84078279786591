import React, { Component } from "react";
import { connect } from "react-redux";
import AddTeamSmartTextModal from "./AddTeamSmartTextModal";
import { addTeamSmartText } from "state/entities/smartText/smartTextActions";
import {
  teamSmartTextOverideSelector,
  teamSmartTextSelector
} from "state/entities/smartText/smartTextSelectors";
import { moveToWorkspaceSmartText } from "state/ui/navigation/navigationActions";

export class AddTeamSmartTextModalContainer extends Component {
  render() {
    return <AddTeamSmartTextModal {...this.props} />;
  }
}

const mapStateToProps = state => {
  const standardSmartText = teamSmartTextSelector(state);
  const overrideSmartText = teamSmartTextOverideSelector(state);

  const smartText = [...standardSmartText, ...overrideSmartText];
  return {
    smartText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addTeamSmartText: args => dispatch(addTeamSmartText(args)),
    moveToWorkspaceSmartText: args => dispatch(moveToWorkspaceSmartText(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeamSmartTextModalContainer);
