import React from "react";
import { connect } from "react-redux";

import { closeRequestApprovalModal } from "state/ui/requestApprovalModal/requestApprovalModalActions";
import { requestDesignApproval } from "state/ui/designsPage/designsPageActions";

import RequestApprovalModal from "views/components/RequestApprovalModal/RequestApprovalModal";
import { showUpgradeModal } from "state/ui/upgradeModal/upgradeModalActions";

const RequestApprovalModalContainer = props => {
  return <RequestApprovalModal {...props} />;
};

RequestApprovalModalContainer.propTypes = {};

RequestApprovalModalContainer.displayName = "RequestApprovalModalContainer";

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.ui.requestApprovalModal.isOpen,
    designId: state.ui.requestApprovalModal.designId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRequestDesignApproval: (...args) =>
      dispatch(requestDesignApproval(...args)),
    onCloseModal: () => dispatch(closeRequestApprovalModal()),
    openUpgradeModal: () => dispatch(showUpgradeModal())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestApprovalModalContainer);
