import * as types from "./designTagsTypes";

export const initState = {};

const tagsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_TAG_FOR_DESIGN_REQUEST: {
      return {
        ...state
      };
    }

    case types.FETCH_TAG_FOR_DESIGN_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;
      const {
        extra: { designId },
        response: {
          ids,
          entities: { designTag: designTags }
        }
      } = action;

      ids.forEach(id => {
        designTags[id].designId = designId;
      });

      return {
        ...state,
        ...designTags
      };
    }

    case types.ADD_TAG_TO_DESIGN_REQUEST: {
      return {
        ...state
      };
    }

    case types.ADD_TAG_TO_DESIGN_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;
      const {
        request: {
          body: { designId }
        },
        response: {
          ids: id,
          entities: { designTag }
        }
      } = action;

      designTag[id].designId = designId;

      return {
        ...state,
        ...designTag
      };
    }

    case types.REMOVE_TAG_FROM_DESIGN_REQUEST_SUCCESS: {
      const {
        extra: { tagId }
      } = action;

      const newState = Object.assign({}, state);

      delete newState[tagId];

      return newState;
    }

    default:
      return state;
  }
};

export default tagsReducer;
