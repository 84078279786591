/**
 * @desc converts a number of seconds to its equivalent milliseconds value
 * @param {Number} seconds - the number of seconds to convert to milliseconds
 * @returns {Number}
 */
export const secondsInMs = seconds => seconds * 1000;

/**
 * @desc converts a number of milliseconds to its equivalent seconds value
 * @param {Number} ms - the number of milliseconds to convert to seconds
 * @returns {Number}
 */
export const msInSeconds = ms => ms / 1000;
