import * as types from "./inviteToTeamFormTypes";
import {
  removeEntryFromForm,
  addEntryToForm
} from "./inviteToTeamFormReducersCommonCases";

const inviteToTeamFormAllCompletedReducers = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_INVITE_FORM_REMOVE_ENTRY: {
      return removeEntryFromForm({ state, action });
    }

    case types.TEAM_INVITE_FORM_ADD_NEW_ENTRY: {
      return addEntryToForm({ state, newEntry: action.newEntry });
    }

    default:
      return state;
  }
};

export default inviteToTeamFormAllCompletedReducers;
