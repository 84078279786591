import * as types from "./pendingUsersTypes";

import { merge } from "lib";

export const initState = {};

const pendingUsersReducers = (state = initState, action) => {
  switch (action.type) {
    case types.PENDING_USERS_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;

      return merge({}, state, entities.users);
    }

    default:
      return state;
  }
};

export default pendingUsersReducers;
