import * as types from "./updateNotificationTypes";

export const initState = {
  visible: false
};

const updateNotificationReducers = (state = initState, action) => {
  switch (action.type) {
    case types.SHOW_UPDATE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        visible: true
      };
    }

    case types.HIDE_UPDATE_NOTIFICATION_SUCCESS: {
      return {
        ...initState
      };
    }

    default:
      return state;
  }
};

export default updateNotificationReducers;
