import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const QrCodeInEditorContext = lazy(() => import("./QrCodeInEditorContext"));

export const QrCodeInEditorContextLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <QrCodeInEditorContext {...props} />
  </Suspense>
);
QrCodeInEditorContextLazy.displayName = "QrCodeInEditorContext";

export default QrCodeInEditorContextLazy;
