import React from "react";
import PropTypes from "prop-types";
import { Modal, Input } from "views/components";
import style from "./style.module.css";
import { passwordErrorParse, emailErrorParse } from "lib/errorHandling";

export class UpdateUserDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.getIsReadyToSubmit = this.getIsReadyToSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateUserDetails = this.handleUpdateUserDetails.bind(this);
    this.getButtons = this.getButtons.bind(this);
    this.setInitState = this.setInitState.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      newNameInput: props.currentUserState.name,
      newEmailInput: props.currentUserState.email,
      confirmPasswordInput: null
    };
  }

  setInitState() {
    this.setState({
      newNameInput: this.props.currentUserState.name,
      newEmailInput: this.props.currentUserState.email,
      confirmPasswordInput: null
    });
  }

  handleClose() {
    this.props.onClose();
    this.setInitState();
  }

  getIsReadyToSubmit() {
    // if all fields are passed we can submit the request
    const { newNameInput, newEmailInput, confirmPasswordInput } = this.state;

    const { currentUserState } = this.props;

    // if there is no input then we can not submit
    if (
      newNameInput === null ||
      newNameInput === "" ||
      newEmailInput === null ||
      newEmailInput === ""
    )
      return false;

    if (
      this.isPasswordRequired() &&
      (confirmPasswordInput === null || confirmPasswordInput === "")
    )
      return false;

    return !currentUserState.isProcessing;
  }

  handleUpdate(field, value) {
    const { [field]: currentValue } = this.state;

    if (currentValue === value) return;

    this.setState({
      [field]: value
    });
  }

  handleUpdateUserDetails() {
    const { onUpdateUserDetails, currentUserState } = this.props;
    const { newNameInput, newEmailInput, confirmPasswordInput } = this.state;

    const user = {
      ...currentUserState,
      name: newNameInput,
      email: newEmailInput,
      password: this.isPasswordRequired() ? confirmPasswordInput : null
    };

    onUpdateUserDetails({ user, onSuccess: this.handleClose });
  }

  getButtons() {
    const {
      currentUserState: { isProcessing }
    } = this.props;
    const isReadyToSubmit = this.getIsReadyToSubmit();
    return [
      {
        name: "Cancel",
        theme: "gray",
        onClick: this.handleClose
      },
      {
        name: isProcessing ? "Updating..." : "Update",
        theme: "blueSolid",
        onClick: () => {
          if (isReadyToSubmit) {
            this.handleUpdateUserDetails();
          }
          return;
        },
        disabled: !isReadyToSubmit
      }
    ];
  }

  isPasswordRequired() {
    return this.state.newEmailInput !== this.props.currentUserState.email;
  }

  render() {
    const {
      isOpen,
      settings: { errors: settingsErrors }
    } = this.props;

    const buttons = this.getButtons();

    return (
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={this.handleClose}
          className={`${style.modal2Field} ${this.isPasswordRequired() &&
            style.modal3Field}`}
          crossColor="#afb3b6"
          header="Edit User Details"
          buttons={buttons}
          buttonsClassName={style.buttons}
        >
          <div>
            <div className={style.inputContainer}>
              <div className={style.inputTitle}>Full Name</div>
              <Input
                className={style.input}
                value={this.state.newNameInput}
                onChange={e =>
                  this.handleUpdate("newNameInput", e.target.value)
                }
                showErrorMsg
                error={settingsErrors.name}
              />
            </div>
            <div className={style.inputContainer}>
              <div className={style.inputTitle}>Email</div>
              <Input
                className={style.input}
                value={this.state.newEmailInput}
                onChange={e =>
                  this.handleUpdate("newEmailInput", e.target.value)
                }
                showErrorMsg
                error={emailErrorParse(settingsErrors.email)}
              />
            </div>
            <div
              className={style.inputContainer}
              data-is-password-required={this.isPasswordRequired()}
            >
              <div className={style.inputTitle}>Confirm Current Password</div>
              <Input
                className={style.input}
                onChange={e =>
                  this.handleUpdate("confirmPasswordInput", e.target.value)
                }
                type="password"
                showErrorMsg
                error={passwordErrorParse(settingsErrors.password)}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

UpdateUserDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeCurrentUserPassword: PropTypes.func,
  updatePasswordState: PropTypes.object
};

UpdateUserDetailsModal.displayName = "UpdateUserDetailsModal";

export default UpdateUserDetailsModal;
