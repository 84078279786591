import * as types from "./designsDataTypes";
import * as designsTypes from "state/entities/designs/designsTypes";

export const initState = {};

const designsDataReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CLEAR_ALL_DESIGN_DATA: {
      return initState;
    }

    case designsTypes.DESIGNS_DATA_REQUEST_SUCCESS:
    case types.FETCH_LATEST_DATA_REQUEST_SUCCESS: {
      const { entities } = action.response;

      const newEntities = {};

      // apply lastFetched to all designData entities
      Object.values(entities.designData).forEach(designData => {
        newEntities[designData.designId] = {
          ...designData,
          lastFetched: Date.now()
        };
      });

      return Object.assign({}, state, newEntities);
    }

    case designsTypes.SAVE_DESIGN_DATA_REQUEST_SUCCESS: {
      const { designData } = action.extra;

      return Object.assign({}, state, { [designData.designId]: designData });
    }

    default:
      return state;
  }
};

export default designsDataReducer;
