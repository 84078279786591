import React from "react";
import style from "./PromoCode.module.css";
import InfoAlert from "views/components/InfoAlert";
import { Button } from "../../components";
import CheckIcon from "views/components/icons/CheckIcon";
import keyCodes from "lib/keyCodes";
import LabelFloatInput from "views/components/LabelFloatInput/LabelFloatInput";

export class PromoCode extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpenPromoCodeSection = this.handleOpenPromoCodeSection.bind(
      this
    );
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCouponApply = this.handleCouponApply.bind(this);
    this.wrapperRef = React.createRef();

    this.state = {
      isPromoSectionExpanded: false,
      couponCode: null
    };
  }

  handleOpenPromoCodeSection() {
    this.setState({
      isPromoSectionExpanded: true
    });

    if (!this.wrapperRef || !this.wrapperRef.current) return;

    setTimeout(() => {
      this.wrapperRef.current.scrollIntoViewIfNeeded();
      const input = this.wrapperRef.current.querySelector("input");
      if (input) {
        input.focus();
      }
    }, 800);
  }

  handleCouponChange(event) {
    const {
      target: { value }
    } = event;
    const { couponErrors, handleResetCouponError } = this.props;

    if (couponErrors && couponErrors.length) {
      handleResetCouponError();
    }

    this.setState({ couponCode: value });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleCouponApply();
    } else if (
      event.keyCode === keyCodes.backspaceKey ||
      event.keyCode === keyCodes.deleteKey
    ) {
      if (this.props.couponErrors && this.props.couponErrors.length) {
        this.props.handleResetCouponError();
      }
    }
  }

  async handleCouponApply() {
    const { createCouponCollectionOrder, collectionId } = this.props;
    // create order when coupon is applied
    await createCouponCollectionOrder({
      collectionId,
      coupon: this.state.couponCode
    });
  }

  render() {
    const { isPromoSectionExpanded, couponCode } = this.state;
    const { couponErrors, isLoading } = this.props;
    let errorMessage = {};

    if (couponErrors && couponErrors.length) {
      couponErrors.map(error => {
        const errorType = error.error.split(" ");
        return (errorMessage.description = errorType.includes("date")
          ? "This promo code is expired"
          : "This promo code is invalid");
      });
    }

    return (
      <div className={style.promoCode} ref={this.wrapperRef}>
        <div
          className={style.promoOpen}
          data-is-active={isPromoSectionExpanded}
        >
          {!this.props.couponCode ? (
            <div
              className={style.couponInputSection}
              data-has-error={!!couponErrors}
            >
              <LabelFloatInput
                label="Promo Code"
                onChange={e => this.handleCouponChange(e)}
                onKeyDown={e => this.handleKeyDown(e)}
                className={style.couponInput}
                value={couponCode || ""}
                error={errorMessage}
              />
              <Button
                className={style.couponApply}
                theme="grayBackgroundXDarkGrayFont"
                onClick={() => this.handleCouponApply()}
                isWide={true}
                disabled={!couponCode || !!couponErrors}
                isLoading={isLoading}
              >
                Apply
              </Button>
            </div>
          ) : (
            <InfoAlert theme="green" icon={CheckIcon}>
              {`The promo code ${this.props.couponCode} has been successfully applied.`}
            </InfoAlert>
          )}
        </div>
        {!isPromoSectionExpanded && (
          <div
            onClick={this.handleOpenPromoCodeSection}
            className={style.promoClosed}
          >
            Promo Code?
          </div>
        )}
      </div>
    );
  }
}

PromoCode.displayName = "PromoCode";

export default PromoCode;
