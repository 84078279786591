const ANIMATION_FILE_EXTENSIONS = ["mp4", "MP4", "gif", "GIF"];

export const isAnimation = url => {
  if (!url) return false;
  const fileExtension = url.split(".").pop();
  if (
    ANIMATION_FILE_EXTENSIONS.some(
      extension => fileExtension && fileExtension.toLowerCase() === extension
    )
  ) {
    return true;
  }
  return false;
};
