import Logger from "lib/logger";

/**
 * Determines if we need to work around a cached object or not.
 * @param src The original src url of the SVG.
 * @returns {string}
 */
export const determineSvgFetchUrl = src => {
  let fetchUrl = src;
  if (isFetchingFailedSvg(src)) {
    // Likely failed due to CORS/Cache issue so try going around the browser cache.
    fetchUrl = `${src}?random=${Date.now()}`;
  }
  return fetchUrl;
};

export const fetchSvgAsync = async src => {
  const fetchUrl = determineSvgFetchUrl(src);
  setWindowSvg({ src, content: { isFetching: true, data: null } });
  try {
    const response = await fetch(fetchUrl);
    if (response.ok) {
      const svgAsText = await response.text();
      setWindowSvg({ src, content: { isFetching: false, data: svgAsText } });
      return svgAsText;
    } else {
      Logger.error(
        `Failed to fetch SVG. HTTP Code: ${response.status}. SVG: ${fetchUrl}`
      );
      setWindowSvg({ src, content: { isFetching: false, data: null } });
    }
  } catch (error) {
    setWindowSvg({ src, content: { isFetching: false, data: null } });
    if (fetchUrl === src) {
      // isRetry ensures we only retry once, ensure the original url is passed
      return fetchSvgAsync(src);
    } else {
      throw new Error(`Failed to fetch SVG ${src}`);
    }
  }
};

/**
 * Fetch the SVG data from each of the given URLs.
 * The local SVG data will be used if the SVG data is already stored locally.
 *
 * @param urls Array of URLs to return SVG data for.
 */
export const fetchSvgs = urls => {
  return urls.map(url => {
    const cachedData = getWindowSvg(url);
    if (Boolean(cachedData) && Boolean(cachedData.data)) {
      // No point fetching again.
      return cachedData.data;
    }
    return fetchSvgAsync(url);
  });
};

/**
 * Determine if there has been a request for an SVG and we did not succeed in either fetching the
 * SVG or extracting its data.
 *
 * @param src The src url of the SVG to check.
 * @returns {*|boolean}
 */
export const isFetchingFailedSvg = src => {
  const windowSvgData = getWindowSvg(src);
  return (
    Boolean(windowSvgData) &&
    !windowSvgData.isFetching &&
    !Boolean(windowSvgData.data)
  );
};

export const setWindowSvg = ({ src, content }) => {
  if (!window.easil) window.easil = {};
  if (!window.easil.svgs) window.easil.svgs = {};
  window.easil.svgs[src] = content;
};

export const getWindowSvg = src => {
  if (!window.easil) window.easil = {};
  if (!window.easil.svgs) window.easil.svgs = {};
  return window.easil.svgs[src];
};
