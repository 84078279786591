import React from "react";

import SubHeaderTabs from "views/components/SubHeaderTabs";

import PATHS from "routes/paths";

import { tabs, easilTabs } from "./tabs";

import style from "../style.module.css";

const getLowerSection = tabs => {
  //tabs needed so return them
  return (
    <>
      <div className={style.headerPadding} />
      {Object.keys(tabs).length > 0 && <SubHeaderTabs tabs={tabs} />}
    </>
  );
};

const getTabs = ({
  isEasil,
  isPurchased,
  isAnyPurchasedTemplates,
  isAnyArchivedTemplates
}) => {
  if (isEasil || isPurchased) {
    if (!isAnyPurchasedTemplates) {
      return {};
    }
    return easilTabs();
  } else {
    if (!isAnyArchivedTemplates) {
      return {};
    }
    return tabs();
  }
};

export const DesignsPageTemplatesSubHeader = ({
  isAnyPurchasedTemplates,
  isAnyArchivedTemplates
}) => {
  const isEasil = window.location.pathname.startsWith(PATHS.catalogueEasil);
  const isPurchased = window.location.pathname.startsWith(
    PATHS.cataloguePurchased
  );
  const _tabs = getTabs({
    isEasil,
    isPurchased,
    isAnyPurchasedTemplates,
    isAnyArchivedTemplates
  });

  return <React.Fragment>{getLowerSection(_tabs)}</React.Fragment>;
};
