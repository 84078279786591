import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CircledQuestionMarkIcon = ({
  className,
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>

    <g fill="none" fillRule="evenodd">
      <path d="M-1-1h24v24H-1z" />
      <path
        d="M11 22C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11zm0-2a9 9 0 1 0 0-18 9 9 0 0 0 0 18zM9.033 8.332a1 1 0 1 1-1.886-.664A4 4 0 0 1 14.92 9c0 1.28-.758 2.29-1.945 3.082a7.575 7.575 0 0 1-1.739.867 1 1 0 1 1-.632-1.898 5.988 5.988 0 0 0 1.262-.633c.687-.459 1.054-.948 1.054-1.42a2 2 0 0 0-3.887-.666zM11 16a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        fill={color || easilGrayXDark}
        fillRule="nonzero"
      />
      <path
        d="M11 16"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
CircledQuestionMarkIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CircledQuestionMarkIcon;
