import React, { Component } from "react";
import style from "./style.module.css";
import CaretIcon from "views/components/icons/CaretIcon";
import { isNil } from "lib";

export default class TeamRolesDropdown extends Component {
  constructor(props) {
    super(props);
    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.isConnected &&
      this.props.isOpen
    ) {
      this.props.setIsOpen(false);
    }
  }

  onDropdownClick() {
    this.props.setIsOpen(!this.props.isOpen);
  }

  render() {
    const {
      error,
      showErrorMsg,
      label,
      popoutContent,
      placeholder,
      value,
      className,
      inputClassName,
      isOpen,
      setIsOpen
    } = this.props;

    return (
      <React.Fragment>
        {label && <div className={style.label}>{label}</div>}
        <div
          ref={this.setWrapperRef}
          className={`${style.dropdownCustom} ${className}`}
        >
          <div
            aria-label="DropdownCustom"
            onClick={() => setIsOpen(!isOpen)}
            className={`${style.inputDiv} ${inputClassName}`}
            data-error={!isNil(error)}
            data-testid="DropdownCustom"
          >
            <input placeholder={placeholder} value={value} readOnly />
            <CaretIcon
              size="10px"
              color={style.easilGrayXLight}
              direction="down"
            />
          </div>
          {showErrorMsg && error && (
            <span aria-label="dropdownError" className={style.error}>
              {error}
            </span>
          )}
          {isOpen && popoutContent}
        </div>
      </React.Fragment>
    );
  }
}
