export const FETCH_BILLING_DETAILS_FOR_ORDER_REQUEST =
  "API/ENTITIES/BILLING_DETAILS/FETCH_REQUEST";
export const FETCH_BILLING_DETAILS_FOR_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/BILLING_DETAILS/FETCH_REQUEST_SUCCESS";
export const FETCH_BILLING_DETAILS_FOR_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/BILLING_DETAILS/FETCH_REQUEST_FAILURE";

export const FETCH_BILLING_DETAILS_FOR_CURRENT_ORDER_REQUEST =
  "API/ENTITIES/BILLING_DETAILS/FETCH_CURRENT_REQUEST";
export const FETCH_BILLING_DETAILS_FOR_CURRENT_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/BILLING_DETAILS/FETCH_CURRENT_REQUEST_SUCCESS";
export const FETCH_BILLING_DETAILS_FOR_CURRENT_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/BILLING_DETAILS/FETCH_CURRENT_REQUEST_FAILURE";

export const CREATE_BILLING_DETAILS_FOR_ORDER_REQUEST =
  "API/ENTITIES/BILLING_DETAILS/CREATE_REQUEST";
export const CREATE_BILLING_DETAILS_FOR_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/BILLING_DETAILS/CREATE_REQUEST_SUCCESS";
export const CREATE_BILLING_DETAILS_FOR_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/BILLING_DETAILS/CREATE_REQUEST_FAILURE";

export const UPDATE_BILLING_DETAILS_FOR_ORDER_REQUEST =
  "API/ENTITIES/BILLING_DETAILS/UPDATE_REQUEST";
export const UPDATE_BILLING_DETAILS_FOR_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/BILLING_DETAILS/UPDATE_REQUEST_SUCCESS";
export const UPDATE_BILLING_DETAILS_FOR_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/BILLING_DETAILS/UPDATE_REQUEST_FAILURE";
