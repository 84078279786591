import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const IllustrationsIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd" transform="translate(-2 -1)">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        stroke="none"
        fillRule="nonzero"
        d="M19.64 6.205c-5.117-3.024-10.163-3.024-15.28 0L12 19.045l2.266-3.809a3 3 0 0 1 2.516-4.228l2.857-4.803zm-2.47 6.81a1 1 0 0 0-.956 1.604 1 1 0 0 1 .074 1.13L12.86 21.51a1 1 0 0 1-1.718 0l-9-15.126a1 1 0 0 1 .312-1.348c6.333-4.133 12.761-4.133 19.094 0a1 1 0 0 1 .312 1.348l-3.661 6.155a1 1 0 0 1-1.028.474z"
      />
      <path
        fill={color}
        stroke="none"
        fillRule="nonzero"
        d="M5.537 9.94L4.39 8.3c5.039-3.52 10.191-3.52 15.23 0l-1.145 1.64c-4.351-3.04-8.589-3.04-12.94 0z"
      />
      <circle cx="12" cy="14" r="1" fill={color} />
      <circle cx="10.5" cy="10.5" r="1.5" fill={color} />
    </g>
  </svg>
);
IllustrationsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default IllustrationsIcon;
