import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PositionFilledIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 18 14"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-3h20v20H-1z" />
      <g
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path
          fill={color || easilGrayXDark}
          d="M10.185 1.518l5.768 2.524a.5.5 0 0 1 0 .916l-5.768 2.524c-.49.214-.669.27-.877.306a1.747 1.747 0 0 1-.616 0c-.208-.037-.387-.092-.877-.306L2.047 4.958a.5.5 0 0 1 0-.916l5.768-2.524c.49-.214.669-.27.877-.306.209-.036.407-.036.616 0 .208.037.387.092.877.306z"
        />
        <path d="M16 9.5l-6.167 2.753c-.356.159-.48.2-.623.227a1.097 1.097 0 0 1-.42 0c-.143-.027-.267-.068-.623-.227L2 9.5" />
      </g>
    </g>
  </svg>
);
PositionFilledIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PositionFilledIcon;
