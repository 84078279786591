import { getPath } from "./lodash";
import { immutableUpdate } from "lib/immutableUpdate";
import FontToolBox from "lib/FontToolBox";

export const adjustTableFontSizes = ({ columnsMetadata, scale }) => {
  if (!columnsMetadata) {
    // with no column metadata (not a table) return null so we don't add a key to other elements
    return null;
  }
  const newColumnsMetadata = Object.keys(columnsMetadata).reduce(
    (columnTypes, columnTypeKey) => {
      const columnType = columnsMetadata[columnTypeKey];
      return {
        ...columnTypes,
        [columnTypeKey]: {
          ...columnType,
          cellsMetadata: columnType.cellsMetadata.map(cell => ({
            ...cell,
            textFields: cell.textFields.map(textField => ({
              ...textField,
              fontSize: textField.fontSize * scale
            }))
          }))
        }
      };
    },
    {}
  );
  return newColumnsMetadata;
};

export const adjustTableRowHeights = ({ rows, scale }) => {
  if (!rows) {
    // with no rows it is not a table, do not give it rows
    return null;
  }
  const newRows = rows.map(row => {
    return {
      ...row,
      cells: row.cells.map(cell => ({
        ...cell,
        textFields: cell.textFields.map(textField => ({
          ...textField,
          height: textField.height * scale
        }))
      }))
    };
  });
  return newRows;
};

/**
 * @desc takes a table element and extracts colors from textFields and borders
 * @param {object} table - an element object of type 'table'
 */
export const getTableColors = table => {
  if (!table.type === "table") return [];
  const colors = [];
  // get all columns regardless of type
  const tableColumns = Object.values(table.columnsMetadata);
  // collate all cell meta data into one array
  const cellsMetaData = tableColumns.reduce(
    (cells, column) => cells.concat(column.cellsMetadata),
    []
  );
  // get all the textFields from the cell meta data
  const textFields = cellsMetaData.reduce(
    (textFieldList, cell) => textFieldList.concat(cell.textFields),
    []
  );
  // get the colors from text fields
  textFields.forEach(textField => {
    if (textField.color) {
      // add colors from the text field text
      colors.push(textField.color);
    }
    const borders = Object.values(textField.borders);
    borders.forEach(border => {
      if (border.color) {
        // add colors from the textField borders
        colors.push(border.color);
      }
    });
  });
  return colors;
};

export const isFontInTable = (table, font) => {
  const cellsWithFont = getCellsWithFont(table, font);
  return cellsWithFont.length >= 1;
};

export const getCellsWithFont = (table, font) => {
  const cells = getTableCells(table);

  const fontFamily = FontToolBox.getFontFamilyFor({
    fontName: font,
    isItalicVersion: font.includes("Italic"),
    isBoldVersion: font.includes("Bold")
  });
  const fontData = FontToolBox.getFontData(fontFamily);
  const fontsToCheck = [
    fontData.fontFamilyName,
    fontData.boldFontFamilyName,
    fontData.italicFontFamilyName,
    fontData.boldItalicFontFamilyName
  ].filter(x => x);

  const matchingCells = cells.filter(cell =>
    fontsToCheck.includes(cell.fontFamily)
  );

  return matchingCells;
};

export const getTableFonts = table => {
  const cells = getTableCells(table);

  const fonts = new Set(cells.map(cell => cell.fontFamily));

  return fonts;
};

/**
 * @desc takes a table element and extracts all of the cells in it
 * @param {object} table - an element object of type 'table'
 */
export const getTableCells = table => {
  if (!table.type === "table") return [];

  const cells = [];

  table.rows.forEach((row, rowIndex) => {
    const rowType = row.rowTypeCode;
    row.cells.forEach((cell, cellIndex) => {
      cell.textFields.forEach((textField, textFieldIndex) => {
        const textFieldMetaData = getPath(table, [
          "columnsMetadata",
          rowType,
          "cellsMetadata",
          cellIndex,
          "textFields",
          textFieldIndex
        ]);
        cells.push({
          ...textFieldMetaData,
          value: textField.value,
          textFieldId: textField.id,
          rowIndex,
          rowType,
          cellIndex,
          textFieldIndex
        });
      });
    });
  });

  return cells;
};

export const columnsMetadataTextFieldUpdater = ({
  attributes,
  tableElement,
  cellIndex,
  rowIndex,
  textFieldIndex
}) => {
  const rowType = tableElement.rows[rowIndex].rowTypeCode;

  const columnsMetadataUpdated = immutableUpdate(tableElement.columnsMetadata, {
    [rowType]: {
      cellsMetadata: {
        [cellIndex]: {
          textFields: {
            [textFieldIndex]: {
              $merge: attributes
            }
          }
        }
      }
    }
  });

  return columnsMetadataUpdated;
};

export const hasEditorTableContextUpdated = (context, nextContext) => {
  if (context.selectedTableFieldId !== nextContext.selectedTableFieldId) {
    return true;
  } else if (context.rowIndex !== nextContext.rowIndex) {
    return true;
  } else if (context.cellIndex !== nextContext.cellIndex) {
    return true;
  } else if (context.textFieldIndex !== nextContext.textFieldIndex) {
    return true;
  } else if (context.rowType !== nextContext.rowType) {
    return true;
  }
  return false;
};
