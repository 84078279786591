import * as types from "./usersTypes";
import * as userSearchTypes from "state/entities/teamUsersSearch/teamUsersSearchTypes";
import { usersApiPageSelector } from "state/api/users/usersApiSelector";
import { currentTeamIdSelector } from "state/entities/entitiesSelectors";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { noop, reduxStoreExpiry } from "lib";
import { uniq } from "lib/lodash";

export const fetchUsers = ({
  page = 1,
  teamId,
  includeParentTeams = false,
  onSuccess
}) => ({
  [CALL_API]: {
    types: [
      types.USERS_REQUEST,
      types.USERS_REQUEST_SUCCESS,
      types.USERS_REQUEST_FAILURE
    ],
    service: SERVICES.USER,
    endpoint: `/teams/${teamId}/users`,
    schema: schemas.USERS_ARRAY,
    request: {
      page: page,
      params: {
        includeParentTeams
      }
    },
    onSuccess
  }
});

// wrapper for calling fetchUsers with parent teams default
export const fetchUsersWithParentTeamsIfNeeded = ({ page = 1, teamId }) => (
  dispatch,
  getState
) => {
  const _teamId = teamId || getState().ui.currentTeam.id;
  if (shouldFetchUsers(getState(), page)) {
    dispatch(
      fetchUsers({
        page,
        teamId: _teamId,
        includeParentTeams: true
      })
    );
    dispatch(
      fetchUsersTotalCount({ teamId: _teamId, includeParentTeams: true })
    );
  }
};

export const fetchAllUsersWithParentTeams = ({
  page = 1,
  teamId,
  onSuccess = noop
}) => (dispatch, getState) => {
  const _teamId = teamId || getState().ui.currentTeam.id;

  const checkForMoreUsers = response => {
    if (response.entities) {
      dispatch(
        fetchAllUsersWithParentTeams({
          page: page + 1,
          teamId: _teamId,
          onSuccess
        })
      );
    } else {
      onSuccess(response);
    }
  };

  dispatch(
    fetchUsers({
      page,
      teamId: _teamId,
      includeParentTeams: true,
      onSuccess: checkForMoreUsers
    })
  );
};

export const fetchUsersTotalCount = ({
  teamId,
  includeParentTeams = false
}) => ({
  [CALL_API]: {
    types: [
      types.USERS_TOTAL_REQUEST,
      types.USERS_TOTAL_REQUEST_SUCCESS,
      types.USERS_TOTAL_REQUEST_FAILURE
    ],
    request: {
      params: {
        includeParentTeams
      }
    },
    service: SERVICES.USER,
    endpoint: `/teams/${teamId}/users/count`,
    schema: schemas.NONE
  }
});

export const updateUserRoles = ({ id: userId, roles }) => (
  dispatch,
  getState
) => {
  const teamId = getState().ui.currentTeam.id;
  // ensure roles array is always unique
  const _roles = uniq(roles);
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/users/${userId}/roles`,
      schema: schemas.NONE,
      types: [
        types.USER_ROLES_UPDATE_REQUEST,
        types.USER_ROLES_UPDATE_REQUEST_SUCCESS,
        types.USER_ROLES_UPDATE_REQUEST_FAILURE
      ],
      request: {
        body: { userId, roles: _roles, teamId }
      }
    }
  });
};

export const deleteUser = ({ user, teamId }) => ({
  [CALL_API]: {
    method: "DELETE",
    service: SERVICES.USER,
    endpoint: `/teams/${teamId}/users/${user.id}`,
    schema: schemas.NONE,
    types: [
      types.USER_DELETE_REQUEST,
      types.USER_DELETE_REQUEST_SUCCESS,
      types.USER_DELETE_REQUEST_FAILURE
    ],
    request: {
      user
    }
  }
});

export const onDeleteUser = ({
  user,
  currentPageAddress,
  isLastUserInPage
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch(
    deleteUser({
      user,
      teamId
    })
  );
};

const shouldFetchUsers = (state, page = 1) => {
  const usersApiPage = usersApiPageSelector(state, page);

  if (!usersApiPage) return true;

  if (usersApiPage.isFetching) return false;

  const isExpired = reduxStoreExpiry.isDataExpired(usersApiPage.lastFetched, 5);

  return isExpired;
};

export const fetchUsersIfNeeded = ({ page }) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;

  if (shouldFetchUsers(getState(), page)) {
    dispatch(fetchUsers({ page, teamId }));
    dispatch(fetchUsersTotalCount({ teamId }));
  }
};

export const searchUsersInTeam = ({
  page = 1,
  term,
  includeParentTeams = false,
  onSuccess
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;

  dispatch({
    [CALL_API]: {
      types: [
        userSearchTypes.SEARCH_USERS_IN_TEAM_REQUEST,
        userSearchTypes.SEARCH_USERS_IN_TEAM_REQUEST_SUCCESS,
        userSearchTypes.SEARCH_USERS_IN_TEAM_REQUEST_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/users`,
      schema: schemas.USERS_ARRAY,
      request: {
        page: page,
        params: {
          term,
          includeParentTeams
        }
      },
      onSuccess
    }
  });
};

export const searchTeamUsersByRoles = ({
  term,
  onSuccess,
  roles,
  includeParentTeams = false
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;

  const params = {
    term,
    includeParentTeams
  };

  if (!term) delete params.term;

  let paramsString = "";

  if (roles && roles.length) {
    const roleParams = roles.map(role => `role=${role}`).join("&");
    paramsString = `?${roleParams}`;
  }

  dispatch({
    [CALL_API]: {
      types: [
        userSearchTypes.SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST,
        userSearchTypes.SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST_SUCCESS,
        userSearchTypes.SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/users${paramsString}`,
      schema: schemas.USERS_ARRAY,
      request: {
        params
      },
      onSuccess
    }
  });
};
