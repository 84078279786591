import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import Fields from "./Fields";
import Pointer from "./Pointer";
import SaturationPointer from "./SaturationPointer";
import SliderSwatches from "./SliderSwatches";
import Button from "../button";

import { ColorWrap, Saturation, Hue } from "react-color/lib/components/common";

export class ColorPicker extends Component {
  static defaultProps = {
    onClose: () => {},
    width: 300
  };

  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { element } = this;
    const isClickOutside =
      element &&
      !element.contains(event.target) &&
      !element.parentElement.parentElement.contains(event.target) &&
      // ignore targets that are not in the dom at the time of trigger
      event.target.isConnected;
    if (isClickOutside) {
      this.props.onClose();
    }
  }

  render() {
    const {
      rgb,
      hex,
      hsv,
      hsl,
      onChange,
      onSave,
      isSaveButtonHidden = false,
      className = "",
      isDisplayHeader = false,
      isRichText
    } = this.props;

    const styles = {
      saturation: {
        radius: "3px",
        shadow: "inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)"
      },
      hue: {
        radius: "2px",
        shadow: "inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)"
      }
    };

    return (
      <div
        className={`easil-picker ${style.wrapper} ${className}`}
        ref={element => (this.element = element)}
      >
        {isDisplayHeader && (
          <div className={style.pickerHeader}>
            <div>Select a color</div>
            <Button theme="blueSolidNoBackgroundNoBorder" onClick={onSave}>
              Close
            </Button>
          </div>
        )}
        <Fields
          rgb={rgb}
          hsl={hsl}
          hex={hex}
          onChange={onChange}
          disableAlpha={true}
          isRichText={isRichText}
        />
        <div className={style.colorsWrapper}>
          <SliderSwatches
            className={style.swatchesWrapper}
            hsl={hsl}
            onClick={onChange}
          />
          <div className={style.gradientWrapper}>
            <Saturation
              style={styles.saturation}
              pointer={SaturationPointer}
              hsl={hsl}
              hsv={hsv}
              onChange={onChange}
            />
          </div>
          <div className={style.hue}>
            <Hue
              direction={"vertical"}
              style={styles.hue}
              hsl={hsl}
              pointer={Pointer}
              onChange={onChange}
            />
          </div>
        </div>
        {!isSaveButtonHidden && (
          <Button onClick={onSave} theme="blueSolid" isExtraWide>
            Save
          </Button>
        )}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ColorWrap(ColorPicker);
