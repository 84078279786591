import React from "react";
import PropTypes from "prop-types";

const QRIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <rect
        stroke={color}
        strokeWidth="2"
        x="1"
        y="1"
        width="7"
        height="7"
        rx="1"
      />
      <rect
        stroke={color}
        strokeWidth="2"
        x="1"
        y="12"
        width="7"
        height="7"
        rx="1"
      />
      <rect
        stroke={color}
        strokeWidth="2"
        x="12"
        y="1"
        width="7"
        height="7"
        rx="1"
      />
      <rect fill={color} x="3.25" y="3.25" width="2.5" height="2.5" rx=".5" />
      <rect fill={color} x="3.25" y="14.25" width="2.5" height="2.5" rx=".5" />
      <rect fill={color} x="14.25" y="3.25" width="2.5" height="2.5" rx=".5" />
      <rect fill={color} x="11" y="11" width="2" height="2" rx=".5" />
      <rect fill={color} x="11" y="18" width="2" height="2" rx=".5" />
      <rect fill={color} x="11" y="14.5" width="2" height="5" rx=".5" />
      <rect fill={color} x="14.5" y="11" width="2" height="2" rx=".5" />
      <rect fill={color} x="14.5" y="18" width="5" height="2" rx=".5" />
      <rect fill={color} x="14.5" y="11.5" width="2" height="5" rx=".5" />
      <rect fill={color} x="18" y="11" width="2" height="2" rx=".5" />
      <rect fill={color} x="18" y="18" width="2" height="2" rx=".5" />
      <rect fill={color} x="15" y="14.5" width="5" height="2" rx=".5" />
    </g>
  </svg>
);
QRIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default QRIcon;
