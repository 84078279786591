import React from "react";

import style from "./style.module.css";

export const InvoiceYears = ({ invoices, selectedYear, setSelectedYear }) => {
  const invoiceYears = Object.keys(invoices);

  // on only one year return we don't need to show the year selector
  if (invoiceYears.length <= 1) return null;

  return (
    <div className={style.invoiceYears}>
      {invoiceYears.reverse().map(invoiceYear => (
        <div
          className={style.invoiceYear}
          data-is-selected={selectedYear === invoiceYear}
          onClick={() => setSelectedYear(invoiceYear)}
          key={invoiceYear}
        >
          {invoiceYear}
        </div>
      ))}
    </div>
  );
};
InvoiceYears.displayName = "InvoiceYears";

export default InvoiceYears;
