import React, { Component } from "react";

import style from "./style.module.css";

export class SlidingToggle extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();

    this.state = {
      selectedOption: props.options ? props.options[0].id : ""
    };
  }

  toggleSwitch(option) {
    this.setState({
      selectedOption: option.id
    });
    if (option.onToggle) {
      option.onToggle();
    }
  }

  componentDidMount() {
    this.defineStyleSheetVariableColours();
    if (this.props.selectedOption) {
      this.setState({
        selectedOption: this.props.selectedOption
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.selectedOption &&
      this.props.selectedOption !== oldProps.selectedOption &&
      this.props.selectedOption !== this.state.selectedOption
    ) {
      this.setState({
        selectedOption: this.props.selectedOption
      });
    }
  }

  defineStyleSheetVariableColours() {
    const { customStyles = {} } = this.props;

    Object.keys(customStyles).forEach(key => {
      const variableKey = `--switch-${key}`;
      this.wrapperRef.current.style.setProperty(variableKey, customStyles[key]);
    });
  }

  renderLabel(label) {
    if (typeof label === "string") {
      return label;
    } else {
      const LabelComponent = label;
      return <LabelComponent />;
    }
  }

  render() {
    const { options } = this.props;

    if (!options || !options.length) return null;

    const { selectedOption } = this.state;

    const isLeftSelected = selectedOption === options[0].id;

    return (
      <div
        className={`${style.toggleWrapper} ${style.toggleVariables}`}
        ref={this.wrapperRef}
      >
        <div
          className={`${style.toggleSwitch} ${
            isLeftSelected ? style.toggleSwitchLeft : style.toggleSwitchRight
          }`}
        />
        <div
          className={style.toggle}
          onClick={() => this.toggleSwitch(options[0])}
          data-toggle-left="true"
          data-is-active={isLeftSelected}
        >
          <div>{this.renderLabel(options[0].label)}</div>
        </div>
        <div
          className={style.toggle}
          onClick={() => this.toggleSwitch(options[1])}
          data-toggle-right="true"
          data-is-active={!isLeftSelected}
        >
          {this.renderLabel(options[1].label)}
        </div>
      </div>
    );
  }
}

export default SlidingToggle;
