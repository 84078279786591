import * as usersEntitiesTypes from "state/entities/users/usersTypes";
import { SWITCH_TEAM } from "state/ui/uiTypes";
import * as usersApiTypes from "state/api/users/usersApiTypes";
import { Logger } from "lib";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 10
};

const findUserPage = (pages, userId) => {
  let userPage;

  for (let page in pages) {
    const pageIds = pages[page].ids;
    if (!pageIds) continue;

    if (pageIds.includes(userId)) {
      userPage = page;
      break;
    }
  }

  if (!userPage) Logger.error("User page not found.");

  return userPage;
};

const usersApiReducers = (state = initState, action) => {
  switch (action.type) {
    case usersEntitiesTypes.USERS_REQUEST: {
      const { page } = action.request;
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: {
            ...state.pages[page],
            isFetching: true,
            lastFetched: Date.now()
          }
        }
      };
    }

    case usersEntitiesTypes.USERS_TOTAL_REQUEST_SUCCESS: {
      return {
        ...state,
        totalCount: action.response.count
      };
    }

    case usersEntitiesTypes.USERS_REQUEST_SUCCESS: {
      const { ids = [] } = action.response;
      const updatedPage = {
        [action.request.page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false,
          lastFetched: Date.now()
        }
      };

      return {
        ...state,
        pages: { ...state.pages, ...updatedPage }
      };
    }

    case usersApiTypes.USERS_API_PAGE_SIZE_CHANGE: {
      const invalidPages = {};

      Object.keys(state.pages).forEach(pageId => {
        invalidPages[pageId] = { ...state.pages[pageId], didInvalidate: true };
      });

      return {
        ...state,
        pages: invalidPages,
        pageSize: action.payload.pageSize
      };
    }

    case usersEntitiesTypes.USER_DELETE_REQUEST_SUCCESS: {
      const invalidPages = {};
      const userPage = findUserPage(state.pages, action.request.user.id);

      for (let pageNumber in state.pages) {
        if (pageNumber < userPage) continue;

        invalidPages[pageNumber] = {
          ...state.pages[pageNumber],
          didInvalidate: true
        };
      }

      return {
        ...state,
        pages: { ...state.pages, ...invalidPages },
        totalCount: state.totalCount - 1
      };
    }
    case SWITCH_TEAM: {
      return initState;
    }

    default:
      return state;
  }
};

export default usersApiReducers;
