import * as types from "./invitationTypes";
import { merge } from "lib";

export const initState = {
  errors: []
};

const invitationsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.INVITATION_REQUEST_SUCCESS: {
      const { entities } = action.response;

      return merge({}, state, entities.invitations[action.response.ids]);
    }

    case types.INVITATION_REQUEST_FAILURE: {
      const { error } = action;

      localStorage.removeItem("invitation_token");

      const errors = error.errors.map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });

      return {
        ...state,
        errors
      };
    }

    case types.INVITATION_ACCEPT_REQUEST_SUCCESS: {
      localStorage.removeItem("invitation_token");

      return initState;
    }

    default:
      return state;
  }
};

export default invitationsReducer;
