import React from "react";

import CurrentSubscriptionDescriptionTrial from "./CurrentSubscriptionDescriptionTrial";
import CurrentSubscriptionDescriptionFree from "./CurrentSubscriptionDescriptionFree";
import CurrentSubscriptionDescriptionPaid from "./CurrentSubscriptionDescriptionPaid";

const CurrentSubscriptionDescriptionSelector = props => {
  if (props.plan.code === "BASIC") {
    return <CurrentSubscriptionDescriptionFree {...props} />;
  }

  if (props.subscription.status === "TRIALLING") {
    return <CurrentSubscriptionDescriptionTrial {...props} />;
  }

  return <CurrentSubscriptionDescriptionPaid {...props} />;
};

export default CurrentSubscriptionDescriptionSelector;
