import gifFrames from "gif-frames";

export const getImageSourceWithFallback = ({
  optimalSource,
  fallbackSource,
  callback
}) => {
  // try to get the optimal image
  cacheImage(optimalSource).then(
    () => {
      // if the optimal image can be cached then use it
      return callback(optimalSource);
    },
    () => {
      // if the optimal image can not be cached then use the original source as a fallback
      return callback(fallbackSource);
    }
  );
};

export const getGifUploadData = async file => {
  const imageUrl = window.URL.createObjectURL(file);
  const frameData = await gifFrames({ url: imageUrl, frames: "all" });
  let duration = 0;
  let frames = 0;
  frameData.forEach(frame => {
    // GIF frame delay is represented in 1/100 of a second. Multiplying this by 10 converts it to milliseconds
    duration += frame.frameInfo.delay * 10;
    frames++;
  });

  // When an uploaded gif has 0 frame delay it will cause the duration to return 0
  // Browsers play GIFs back at a default frame delay of 100ms if no frame delay is provided.
  if (duration === 0 && frames > 1) {
    duration = frames * 100;
  }

  return {
    duration
  };
};

export const getImageSizeFromSrc = async src => {
  const getImageSize = src => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        // when an image has no intrinsic dimensions it is treated differently in each browser in terms of
        // base dimensions, and firefox for example will set width and height to 0 by default
        // we will set default for these to 150 to avoid having issues inserting these elements
        resolve({ height: img.height || 150, width: img.width || 150 });
      };
      img.onerror = reject;
      img.src = src;
    });
  };

  return await getImageSize(src);
};

/**
 *
 * @param {Object} imageElement an object representing the image element to get dimensions for
 * @returns {Object} imageDimensions - contains width and height dimensions for an image
 */
export const getImageDimensionsFromElement = async imageElement => {
  let imageDimensions;
  // check for dimensions in the media object first
  if (
    imageElement.media &&
    imageElement.media.width &&
    imageElement.media.height
  ) {
    imageDimensions = {
      width: imageElement.media.width,
      height: imageElement.media.height
    };
  } else {
    // fallback to using the main url, the original url and then finally the preview url
    try {
      imageDimensions = await getImageSizeFromSrc(
        imageElement.url || imageElement.src
      );
    } catch {
      try {
        imageDimensions = await getImageSizeFromSrc(
          imageElement.originalUrl || imageElement.originalSrc
        );
      } catch {
        imageDimensions = await getImageSizeFromSrc(
          imageElement.previewUrl || imageElement.previewSrc
        );
      }
    }
  }
  return imageDimensions;
};

export const cacheImage = src => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.src = src;
    image.onload = () => {
      resolve(true);
    };

    image.onerror = () => {
      reject(false);
    };
  });
};

export const isImageCached = src => {
  const image = new Image();
  image.src = src;

  return image.complete;
};
