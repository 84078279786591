import * as types from "./subscriptionPlansTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { subscriptionPlansApiPageSelector } from "../../api/subscriptionPlans/subscriptionPlansApiSelector";

export const fetchSubscriptionPlans = ({ page = 1 }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.SUBSCRIPTION,
      types: [
        types.FETCH_SUBSCRIPTION_PLANS,
        types.FETCH_SUBSCRIPTION_PLANS_SUCCESS,
        types.FETCH_SUBSCRIPTION_PLANS_FAILURE
      ],
      endpoint: `/subscriptions`,
      request: {
        page: page,
        pageSize: getState().api.subscriptionPlans.pageSize
      },
      schema: schemas.SUBSCRIPTION_PLANS
    }
  });
};

export const shouldFetchPlans = (state, page) => {
  const plansApiPage = subscriptionPlansApiPageSelector(state, page);

  if (plansApiPage) return false;

  return true;
};

export const fetchSubscriptionPlansIfNeeded = (page = 1) => (
  dispatch,
  getState
) => {
  if (shouldFetchPlans(getState(), page)) {
    dispatch(fetchSubscriptionPlans({ page }));
  }
};
