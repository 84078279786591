import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PatternsIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd" transform="translate(-3 -3)">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        stroke="none"
        d="M4 3a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V4a1 1 0 0 1 1-1zm5 0a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V4a1 1 0 0 1 1-1z"
      />
      <rect
        width="2"
        height="8"
        x="14"
        y="13"
        stroke="none"
        fill={color}
        rx="1"
      />
      <rect
        width="2"
        height="8"
        x="19"
        y="13"
        stroke="none"
        fill={color}
        rx="1"
      />
      <path
        fill={color}
        stroke="none"
        d="M14 3h6a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2zm0 5h6a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2zM4 14h6a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 5h6a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
      />
    </g>
  </svg>
);
PatternsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PatternsIcon;
