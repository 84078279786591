import * as types from "./inviteToTeamFormTypes";
import {
  removeEntryFromForm,
  addEntryToForm
} from "./inviteToTeamFormReducersCommonCases";
import { TEAMS_INVITE_USERS_REQUEST } from "state/entities/teams/teamsTypes";

export const initState = {
  status: "PRISTINE",
  entries: []
};

const inviteToTeamFormPristineReducers = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_INVITE_FORM_REMOVE_ENTRY: {
      return removeEntryFromForm({ state, action });
    }

    case types.TEAM_INVITE_FORM_ADD_NEW_ENTRY: {
      return addEntryToForm({ state, newEntry: action.newEntry });
    }

    case TEAMS_INVITE_USERS_REQUEST: {
      return {
        ...state,
        status: "ALL_COMPLETED"
      };
    }

    default:
      return state;
  }
};

export default inviteToTeamFormPristineReducers;
