import React from "react";
import style from "./style.module.css";
import { noop } from "lib";

const ImageSample = ({
  imageUrl,
  onClick = noop,
  highlight = false,
  cursor = "pointer"
}) => (
  <div
    onClick={onClick}
    className={style.imageSample}
    style={{
      backgroundImage: `url("${imageUrl}")`,
      cursor
    }}
  />
);

export default ImageSample;
