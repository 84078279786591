import * as collectionTypes from "../../api/collection/collectionTypes";
import * as designTypes from "../designs/designsTypes";
import * as types from "./designFoldersTypes";
import { getPath, omit, cloneDeep } from "lib";

export const initState = {};

const designFoldersReducer = (state = initState, action) => {
  switch (action.type) {
    case collectionTypes.UPDATE_REQUEST_SUCCESS:
    case designTypes.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_SUCCESS:
    case designTypes.TEAM_TEMPLATES_STATUS_UPDATE_REQUEST_SUCCESS:
    case types.ADD_CONTENT_TO_TEAM_FOLDER_SUCCESS:
    case types.MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST_SUCCESS:
    case types.ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST_SUCCESS: {
      return initState;
    }

    case types.FETCH_FOLDER_FOR_DESIGN_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;
      const {
        extra: { designId },
        response: {
          ids: id,
          entities: { designFolder }
        }
      } = action;

      const rawFolderData = designFolder[id];
      const folder = {
        [designId]: rawFolderData
      };

      return {
        ...state,
        ...folder
      };
    }

    case types.REMOVE_CONTENT_FROM_TEAM_FOLDER_SUCCESS:
    case types.REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST_SUCCESS: {
      let updatedState = cloneDeep(state);
      // collection designs passed in through the dispatch action
      const designsInCollection = getPath(
        action,
        "extra.designsInCollection",
        null
      );

      if (designsInCollection) {
        const designIdsInCollection = designsInCollection.map(
          design => design.id
        );
        updatedState = omit(state, designIdsInCollection);
      }

      return updatedState;
    }

    default:
      return state;
  }
};

export default designFoldersReducer;
