import React from "react";
import TableRowDropZone from "./TableRowDropZone";

const TableRowDropZones = ({
  rowIndex,
  onRowMove,
  onTableOver,
  onPersistRowMove,
  isDragging
}) => {
  const topRowIndex = Math.max(rowIndex - 1, 0);
  // ensure the top row doesn't have both index 0
  const bottomRowIndex = rowIndex === 0 ? 1 : rowIndex;

  return (
    <React.Fragment>
      <TableRowDropZone
        position="top"
        index={topRowIndex}
        onRowMove={onRowMove}
        onPersistRowMove={onPersistRowMove}
        onTableOver={onTableOver}
        isDragging={isDragging}
      />
      <TableRowDropZone
        position="bottom"
        index={bottomRowIndex}
        onRowMove={onRowMove}
        onPersistRowMove={onPersistRowMove}
        onTableOver={onTableOver}
        isDragging={isDragging}
      />
    </React.Fragment>
  );
};

export default TableRowDropZones;
