import React from "react";
import style from "./style.module.css";

const PriceSummary = ({ order, shippingDetails, shippingCanBeHidden }) => {
  if (!order) return null;

  const shippingAmount = shippingDetails.amount || null;

  const shouldShowShipping = !shippingCanBeHidden || shippingAmount;

  // If order is empty then shipping should not be deducted from 0
  const orderSubtotal = () => {
    const orderMinusShippingAmount = order.amount - shippingAmount;
    return orderMinusShippingAmount < 0 ? 0 : orderMinusShippingAmount;
  };

  // If no order amount then shipping is 0
  const shippingTotal = () => {
    return order.amount ? shippingAmount : 0;
  };

  return (
    <div className={style.priceSummary}>
      <div className={style.subtotal}>
        <div className={style.col1}>
          <div>Subtotal:</div>
          {order.couponCode && <div>Promo code:</div>}
          {shouldShowShipping && <div>Shipping:</div>}
        </div>
        <div className={style.col2}>
          <div>${orderSubtotal().toFixed(2)}</div>
          {order.couponCode && (
            <div className={style.couponCode}>{order.couponCode}</div>
          )}
          {shouldShowShipping && (
            <div className={style.shippingCost}>
              {shippingAmount
                ? "$" + shippingTotal().toFixed(2)
                : "Calculated at next step"}
            </div>
          )}
        </div>
      </div>
      <div className={style.grandTotal}>
        <div className={style.col1}>Grand Total:</div>
        <div className={style.col2}>
          <span className={style.total}>${order.amount.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

export default PriceSummary;
