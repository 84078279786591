import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const StarIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  isSmall,
  ...props
}) => {
  if (isSmall)
    return (
      <svg
        {...props}
        data-no-pointer={noPointer}
        width={width || size || 20}
        height={height || size || 20}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h20v20H0z" />
          <path
            d="M10 1.5l2.781 5.634L19 8.043l-4.5 4.383 1.062 6.192L10 15.693l-5.562 2.925L5.5 12.426 1 8.043l6.219-.909z"
            stroke={color || easilGrayXDark}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.8"
          />
          <path d="M0 0h20v20H0z" />
        </g>
      </svg>
    );
  return (
    <svg
      {...props}
      data-no-pointer={noPointer}
      width={width || size || 32}
      height={height || size || 30}
      viewBox="0 0 32 30"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g id="ROLES-+-Proto" stroke="none" fill="none" fillRule="evenodd">
        <path d="M0-1h32v32H0z" />
        <path
          stroke={color || easilGrayXDark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 1l4.635 9.216L31 11.702l-7.5 7.17L25.27 29 16 24.216 6.73 29 8.5 18.872 1 11.702l10.365-1.486z"
        />
      </g>
    </svg>
  );
};
StarIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default StarIcon;
