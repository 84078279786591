import * as types from "./inviteToTeamFormTypes";
import { removeEntryFromForm } from "./inviteToTeamFormReducersCommonCases";

export const initState = {};

const inviteToTeamFormInvalidReducers = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_INVITE_FORM_REMOVE_ENTRY: {
      return removeEntryFromForm({ state, action });
    }

    default:
      return state;
  }
};

export default inviteToTeamFormInvalidReducers;
