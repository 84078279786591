import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const BackgroundFillIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 17 17 "
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-1h20v20H-1z" />
      <path d="M1 1h16v16H1z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M9.556 3.05A1.1 1.1 0 0 0 8 3.05L3.05 8a1.1 1.1 0 0 0 0 1.556l5.304 5.303a1.1 1.1 0 0 0 1.556 0l4.95-4.95a1.1 1.1 0 0 0 0-1.555L9.555 3.05zm1.273-1.272l5.303 5.303a2.9 2.9 0 0 1 0 4.101l-4.95 4.95a2.9 2.9 0 0 1-4.1 0L1.777 10.83a2.9 2.9 0 0 1 0-4.101l4.95-4.95a2.9 2.9 0 0 1 4.1 0z"
      />
      <path fill={color || easilGrayXDark} d="M1 8.5h16L9 16z" />
    </g>
  </svg>
);
BackgroundFillIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default BackgroundFillIcon;
