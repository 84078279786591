import { fetchFonts } from "lib/fonts";
import { isEmpty, getPath } from "lib/lodash";
import * as types from "./publicFontsTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";

const { REACT_APP_PUBLIC_FONTS_PATH: PUBLIC_FONTS_PATH } = process.env;

const setCssProcessing = ({ isProcessing }) => ({
  type: types.PUBLIC_FONTS_CSS_PROCESSING,
  isProcessing
});

const shouldFetchPublicFonts = state => {
  return (
    !state.api.publicFonts.isCssProcessing &&
    isEmpty(state.api.publicFonts.pages)
  );
};

export const fetchPublicFontsIfNeeded = (...args) => (dispatch, getState) => {
  const state = getState();
  if (shouldFetchPublicFonts(state)) {
    dispatch(fetchAllPublicFonts(...args));
  }
};

export const fetchAllPublicFonts = (...args) => (dispatch, getState) => {
  dispatch(
    insertPublicFontsCss({
      onSuccess: () => dispatch(fetchPublicFonts(...args))
    })
  );
};

export const fetchPublicFonts = ({ page = 1 }) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      types: [
        types.PUBLIC_FONTS_REQUEST,
        types.PUBLIC_FONTS_REQUEST_SUCCESS,
        types.PUBLIC_FONTS_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/fonts`,
      request: {
        page: page,
        pageSize: getState().api.publicFonts.pageSize
      },
      schema: schemas.PUBLIC_FONTS_ARRAY,
      onSuccess: response => {
        if (response.entities) {
          const publicFontMap = response.entities.publicFont;
          const fontFamilies = [];
          const urls = [];
          Object.keys(publicFontMap).forEach(id => {
            let font = publicFontMap[id];
            fontFamilies.push(font.fontFamilyName);
            urls.push(font.stylesheetUrl);
          });
          fetchFonts([], fontFamilies);
          if (
            Object.keys(getPath(response, ["entities", "publicFont"], {}))
              .length === getState().api.publicFonts.pageSize
          ) {
            dispatch(fetchPublicFonts({ page: page + 1 }));
          }
        }
      }
    }
  });
};

export const insertPublicFontsCss = ({ onSuccess }) => (dispatch, getState) => {
  dispatch(setCssProcessing({ isProcessing: true }));
  const link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";

  link.addEventListener("load", () => {
    dispatch(setCssProcessing({ isProcessing: false }));
    onSuccess();
  });
  link.href = SERVICES.FONTS + PUBLIC_FONTS_PATH;

  const headScript = document.querySelector("script");
  headScript.parentNode.insertBefore(link, headScript);
};
