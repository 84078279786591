import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FileCurvedArrowIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="m16.388 11.526.001.03-.002-.054.001.024.001.03v.03l-.003.045c-.001.02-.004.038-.007.057l-.008.046a.339.339 0 0 1-.014.058l-.012.04-.018.054-.021.045-.027.055-.02.032a1.684 1.684 0 0 1-.043.066l-.023.027c.003-.002.002 0 0 0l-.01.013-.009.012-.045.048-2.667 2.667a.89.89 0 0 1-1.33-1.174l.073-.083 1.148-1.15h-1.408c-1.42 0-2.58 1.11-2.663 2.51l-.004.157a.889.889 0 1 1-1.778 0c0-2.39 1.886-4.34 4.252-4.44l.193-.005h1.41l-1.15-1.149a.89.89 0 0 1-.074-1.173l.074-.084a.89.89 0 0 1 1.173-.074l.084.074 2.667 2.667a.75.75 0 0 1 .07.08l.017.02c.015.02.03.043.043.065l.02.033.027.053.021.048.018.053.012.04.014.058.008.047.008.065v.013l.001.024z"
        fill={color || easilGrayXDark}
      />
      <path
        d="M7 4h7.172a2 2 0 0 1 1.414.586L18.12 7.12A3 3 0 0 1 19 9.243V18a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z"
        stroke={color || easilGrayXDark}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

FileCurvedArrowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FileCurvedArrowIcon;
