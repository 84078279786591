import Element from "state/ui/editor/elements/Element";

class VideoElement extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "sizeAndPosition",
    "filters",
    "flipping",
    "cropping",
    "setAsBackground",
    "imageUpload",
    "outline"
  ];

  updateAttributes(attributes) {
    if (!window.easil.context.isCroppingVideo) {
      return super.updateAttributes(attributes);
    }

    return new this.constructor({
      ...this,
      ...attributes
    });
  }
}

export default VideoElement;
