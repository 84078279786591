import { createSelector } from "reselect";

export const getCurrentTeamId = state => {
  return state.ui.currentTeam.id;
};

const teamsEntitiesSelector = ({ state }) => state.entities.teams;

const processCurrentTeamsListSelector = (currentTeamId, teamsEntities) => {
  const currentTeam = teamsEntities[currentTeamId];
  if (
    !currentTeam ||
    !currentTeam.parentPathArr ||
    !currentTeam.parentPathArr.length
  )
    return [];

  const teamsList = [...currentTeam.parentPathArr]; // clone to avoid mutating

  teamsList.push(currentTeamId);

  return teamsList;
};

export const currentTeamsListSelector = createSelector(
  [({ state }) => getCurrentTeamId(state), teamsEntitiesSelector],
  processCurrentTeamsListSelector
);
