import React, { Component } from "react";
import { Checkbox, Button } from "views/components";
import CaretIcon from "views/components/icons/CaretIcon";
import style from "./style.module.css";
import { isNil } from "lib";

class DropdownWithCheckBox extends Component {
  constructor(props) {
    super(props);

    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      open: false,
      options: this.props.options || []
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.options });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.isConnected
    ) {
      this.setState({ open: false, options: this.props.options });
    }
  }

  onDropdownClick() {
    this.setState({ open: !this.state.open });
  }

  onSubmit() {
    this.props.onSubmit(this.state.options);
    this.setState({ open: false });
  }

  handleChange(option, optionIndex, checked) {
    const { options } = this.state;

    const optionChanged = { ...options[optionIndex], selected: checked };

    const newOptions = options.map((item, index) => {
      return index !== optionIndex ? item : optionChanged;
    });

    this.setState({
      options: newOptions
    });
  }

  render() {
    const {
      placeholder,
      error,
      showErrorMsg,
      label,
      contentClassName
    } = this.props;
    const { options, open } = this.state;

    return (
      <React.Fragment>
        {label && <div className={style.label}>{label}</div>}
        <div
          ref={this.setWrapperRef}
          className={style.dropdown}
          data-testid="DropdownWithCheckbox"
        >
          <div
            aria-label="dropdown"
            onClick={this.onDropdownClick}
            className={style.inputDiv}
            data-error={!isNil(error)}
            data-testid="dropdown"
          >
            <input
              placeholder={placeholder}
              value={options
                .filter(option => option.selected)
                .map(option => option.label)
                .join(", ")}
              disabled
            />
            <CaretIcon
              size="8px"
              color={style.easilGrayXDark}
              direction="down"
            />
          </div>
          {showErrorMsg && error && (
            <span aria-label="dropdownError" className={style.error}>
              {error}
            </span>
          )}
          {open && (
            <div
              className={`${style.content} ${contentClassName}`}
              data-testid="dropdownContent"
            >
              {options.map((option, index) => (
                <Checkbox
                  key={option.key}
                  checked={option.selected}
                  label={option.label}
                  onClick={e =>
                    this.handleChange(option, index, e.target.checked)
                  }
                />
              ))}
              <Button
                onClick={this.onSubmit}
                theme="grayBackgroundXDarkGrayFont"
                isExtraWide
              >
                Done
              </Button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DropdownWithCheckBox;
