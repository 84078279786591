import auth from "lib/auth";
import { push } from "react-router-redux";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import PATHS from "routes/paths";
import * as types from "./designRemixTypes";
import { noop, Referrer, Logger } from "lib";
import { switchToken } from "state/currentUser/currentUserActions";

const remixAndMoveToEditor = ({ designId, teamId, onSuccess, onFailure }) => (
  dispatch,
  getState
) => {
  const state = getState();
  // Fetch the currently logged in users auth_token
  const { token } = auth.getToken({ state });

  if (!token) {
    Referrer.set(`/mix/${designId}`);

    Logger.warn(
      "Processing Design Remix. User not authenticated. Push to login"
    );

    dispatch(push(PATHS.login));
    return;
  } else {
    Referrer.remove();

    dispatch(createRemix({ designId, teamId, token, onSuccess, onFailure }));
  }
};

export const processDesignRemix = ({ designId, teamId }) => dispatch => {
  dispatch(
    switchToken({
      teamId,
      onSuccess: () => {
        dispatch(
          remixAndMoveToEditor({
            designId,
            teamId,
            onSuccess: response =>
              dispatch(push(`/editor/${response.design.id}`)),
            onFailure: () => dispatch(push("/designs"))
          })
        );
      }
    })
  );
};

export const createRemix = ({
  designId,
  teamId,
  token,
  onSuccess = noop,
  onFailure = noop
}) => dispatch => {
  const { userId } = auth.jwtDecoder(token);

  dispatch({
    [CALL_API]: {
      types: [
        types.DESIGNS_CREATE_REMIX_REQUEST,
        types.DESIGNS_CREATE_REMIX_REQUEST_SUCCESS,
        types.DESIGNS_CREATE_REMIX_REQUEST_FAILURE
      ],
      method: "POST",
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/remix`,
      schema: schemas.NONE,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      request: {
        body: {
          designId,
          teamId,
          userId
        }
      },
      onSuccess,
      onFailure
    }
  });
};
