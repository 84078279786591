import { UPDATE_EDITOR_CONTEXT_STATE } from "state/ui/editorContext/editorContextActionTypes";
import { LOAD_DESIGN } from "state/ui/editor/editorActionTypes";

export const initialState = {
  canvasBackground: null,
  collectionDesignsUpdating: {},
  context: {
    isInTextEditMode: false
  },
  contextMenu: null,
  currentPageId: null,
  designData: null,
  displayThumbnail: true,
  elementPreviews: {},
  elementsToPlayAudio: {},
  highlightedElementIds: null,
  highlightedSection: null,
  // htmlElements: {},
  isCropping: false,
  isEditTitleModalOpen: false,
  isGuideDragging: false,
  isImMode: false,
  isPlaying: {},
  isResizing: false,
  isSavingErrorModalOpen: false,
  isShowingRulersAndGuides: false,
  loadingThumbnail: { complete: false },
  measurementBreakdown: 100,
  measurementUnits: "px",
  pageExtents: [],
  pagesInView: [],
  ready: false,
  selectedItems: [],
  showBleed: false,
  snapToCorners: true,
  unitHeight: 0,
  unitWidth: 0,
  zoom: 1,
  useDesignSmartFields: false,
  isSharedDialogOpen: false
};

const editorContextReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EDITOR_CONTEXT_STATE:
      return {
        ...state,
        ...action.payload
      };
    case LOAD_DESIGN: {
      return {
        ...state,
        designData: action.data,
        selectedItems: [],
        useDesignSmartFields: false,
        isSharedDialogOpen: false
      };
    }
    default:
      return state;
  }
};

export default editorContextReducers;
