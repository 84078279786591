import React, { Component } from "react";
import HoverOverlay from "../HoverOverlay";
import ImageInstruction from "views/components/Editor/elements/ImageInstruction/ImageInstruction";
import {
  generateSvgDomObject,
  getSelectedImageInstructionBorder,
  getFrameDimensions,
  calculateUpdatedVectorImageInstructions,
  validateImageInstructions
} from "./vectorUtils";
import { identity, pick, getPath, uuid } from "lib";
import { jsonStringEqual, sortedJsonStringEqual } from "lib/equalityUtils";
import { domParser as defaultParser } from "lib/defaultDomParser";
import { fetchSvgs } from "lib/svg/svg";
import { getPaletteFromSrc } from "lib/Colors/colorUtils";
import { isImportantPropsChanged } from "lib/elements";
import { getPlaceholderSize } from "views/components/Editor/elements/grid/imageInstructionUtils";
import { OutlineFilter } from "views/components/OutlineFilter/OutlineFilter";
import svgStyles from "./style.module.css";
import { memoizedGetGifImageSource as getGifImageSource } from "lib/mediaSourceHelpers";
import { isGif } from "lib/isGif";
import { playVideo } from "lib/videoControlUtils";
import { getBrowserClientName } from "lib/getBrowserClientName";
import { BROWSER_NAMES, DEFAULT_VECTOR_MASK } from "lib/constants";

const importantElementProps = [
  "zoom",
  "style",
  "elementId",
  "elementData",
  "isSelected",
  "restrictions",
  "pageId",
  "groupId",
  "isDesignPartOfACollection",
  "isAllRestrictionsLocked",
  "isPhotoSelected",
  "restrictionsForDocument",
  "restrictionsForElement",
  "isPlaying",
  "isPreview",
  "imageInstruction",
  "mask",
  "scale"
];

export class Vector extends Component {
  static defaultProps = {
    connectDragSource: identity
  };

  constructor(props) {
    super(props);
    let svgDomElement = document.createElement("svg");

    if (
      !window.easil ||
      !window.easil.svgs ||
      !window.easil.svgs[props.elementData.src] ||
      !window.easil.svgs[props.elementData.src].data
    ) {
      const svgData = fetchSvgs([props.elementData.src]);
      svgData.pop().then(data => {
        this.setState({
          svgDomElement: (props.domParser || defaultParser)
            .parseFromString(data, "image/svg+xml")
            .querySelector("svg")
        });
      });
    } else {
      svgDomElement = (props.domParser || defaultParser)
        .parseFromString(
          window.easil.svgs[props.elementData.src].data,
          "image/svg+xml"
        )
        .querySelector("svg");
    }

    this.handleImageDrop = this.handleImageDrop.bind(this);
    this.handleImageDragHover = this.handleImageDragHover.bind(this);
    this.handleImageDragLeave = this.handleImageDragLeave.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.photoFrameHasImage = this.photoFrameHasImage.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.renderSvgBorder = this.renderSvgBorder.bind(this);
    this.selectPhotoFrame = this.selectPhotoFrame.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.buildSvgRenderObject = this.buildSvgRenderObject.bind(this);
    this.buildImageInstructionList = this.buildImageInstructionList.bind(this);
    this.forceRedraw = this.forceRedraw.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);

    this.wrapperRef = React.createRef();

    this.state = {
      isHovered: false,
      svgDomElement: svgDomElement,
      imageInstructionPreview: null,
      svgRenderObject: null,
      forceUpdateInterval: null,
      imageInstructionList: [],
      renderVersion: ""
    };
  }

  componentDidMount() {
    this.buildSvgRenderObject();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !sortedJsonStringEqual(nextState, this.state) ||
      isImportantPropsChanged(nextProps, this.props, importantElementProps)
    );
  }

  forceRedraw() {
    if (!this.wrapperRef || !this.wrapperRef.current) {
      return;
    }
    // a hacky solution to foreignObject rendering of video in firefox
    // DO NOT USE IN OTHER CASES
    if (this.wrapperRef.current.style["line-height"] === "1") {
      this.wrapperRef.current.style["line-height"] = "0";
    } else {
      this.wrapperRef.current.style["line-height"] = "1";
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !jsonStringEqual(prevProps.elementData, this.props.elementData) ||
      prevProps.zoom !== this.props.zoom ||
      !jsonStringEqual(
        prevState.imageInstructionPreview,
        this.state.imageInstructionPreview
      )
    ) {
      this.buildSvgRenderObject();
    }

    if (prevProps.isPlaying !== this.props.isPlaying) {
      this.buildImageInstructionList();

      const videos = [
        ...(this.wrapperRef.current?.querySelectorAll("video") || [])
      ];

      const browserClientName = getBrowserClientName();

      if (videos.length) {
        if (this.props.isPlaying) {
          // start playing
          videos.forEach(video => {
            playVideo(video);
          });

          // check if firefox hack fix is needed
          if (browserClientName === BROWSER_NAMES.FIREFOX) {
            // hacky fix for a firefox bug where video elements inside foreignObject elements
            // would not redraw while playing and so remained paused or jumped around
            const forceUpdateInterval = setInterval(this.forceRedraw, 0);
            this.setState({
              forceUpdateInterval
            });
          }
        } else {
          videos.forEach(video => {
            video.pause();
            video.currentTime = 0;
          });
        }
      }
      // check if firefox hack fix needs to be removed
      if (
        browserClientName === BROWSER_NAMES.FIREFOX &&
        this.state.forceUpdateInterval
      ) {
        clearInterval(this.state.forceUpdateInterval);
        this.setState({
          forceUpdateInterval: null
        });
      }
    }

    if (
      !jsonStringEqual(
        this.state.imageInstructionList,
        prevState.imageInstructionList
      )
    ) {
      this.buildSvgRenderObject();
    }

    if (
      !jsonStringEqual(
        this.props.imageInstructions,
        prevProps.imageInstructions
      )
    ) {
      this.buildImageInstructionList();
    }

    if (this.state.svgRenderObject !== prevState.svgRenderObject) {
      if (this.wrapperRef && this.wrapperRef.current) {
        const { imageInstructionList = [] } = this.state;
        imageInstructionList.forEach(instruction => {
          if (isGif(instruction.previewSrc)) {
            const imageDomId = `${instruction.domId}-${this.props.elementData.uniqueId}`;
            const svgImage = this.wrapperRef.current.querySelector(
              `*[id^="${imageDomId}"]`
            );

            svgImage.setAttribute("href", "");
            setTimeout(() => {
              svgImage.setAttribute("href", instruction.previewSrc);
            }, 0);
          }
        });
      }
    }
  }

  async buildImageInstructionList() {
    const { imageInstructionPreview } = this.state;

    let imageInstructionList = [...this.props.elementData.imageInstructions];

    // when we have a preview we need to replace its original and keep the others so there is none missing on render
    if (imageInstructionPreview) {
      const previewInstructionIndex = imageInstructionList
        .map(instruction => instruction.domId)
        .indexOf(imageInstructionPreview.domId);
      imageInstructionList[previewInstructionIndex] = imageInstructionPreview;
    }

    imageInstructionList = await Promise.all(
      imageInstructionList.map(async instruction => {
        if (isGif(instruction.previewSrc)) {
          const instructionSrc = await getGifImageSource({
            isPlaying: this.props.isPlaying,
            gifSource: instruction.previewSrc
          });
          return {
            ...instruction,
            previewSrc: instructionSrc
          };
        }
        return instruction;
      })
    );

    this.setState({
      imageInstructionList
    });

    return imageInstructionList;
  }

  buildSvgRenderObject() {
    const { autoPlay } = this.props;
    const {
      fillColors,
      srcWidth,
      srcHeight,
      scale,
      width,
      height,
      resizableX,
      resizableY
    } = this.props.elementData;

    this.buildImageInstructionList();

    const { zoom } = this.props;
    const { imageInstructionList } = this.state;

    const svgRenderObject = generateSvgDomObject({
      svgDomElement: this.state.svgDomElement.cloneNode(true),
      fillColors,
      width,
      height,
      srcWidth,
      srcHeight,
      scale,
      resizableX,
      resizableY,
      imageInstructions: imageInstructionList,
      zoom,
      element: this.props.elementData,
      elementId: `${this.props.elementData.uniqueId}${
        this.props.isPreview ? "-preview" : ""
      }`,
      autoPlay
    });

    this.setState({
      svgRenderObject,
      renderVersion: uuid()
    });

    // need to force an update here since state checks don't see changes
    // in element objects in state
    this.forceUpdate();

    return svgRenderObject;
  }

  onSelect(e) {
    e.stopPropagation();
    e.preventDefault();

    /* if user pressed shift or meta/command(mac), we append the to the selection */
    const append = e.shiftKey || e.metaKey;
    this.props.onSelectItem({ append });
  }

  async handleImageDragHover({ domId, imageInstruction }) {
    const { imageInstructions } = this.props.elementData;
    const { imageInstructionPreview } = this.state;

    // initial check to see if imageInstructions contain essesntial properties
    // prevent setting of preview when asset image hasn't been built
    const areImageInstructionsValid = validateImageInstructions(
      imageInstruction
    );

    if (!areImageInstructionsValid) {
      this.setState({
        imageInstructionPreview: null
      });
      return;
    }

    // do not try to adjust preview when nothing has changed
    if (
      imageInstructionPreview &&
      imageInstructionPreview.dragId === imageInstruction.dragId
    ) {
      return;
    }

    const svgImageTag = this.state.svgDomElement.querySelector(
      `#${domId} > image`
    );

    const maskSize = {
      width: svgImageTag.getAttribute("width"),
      height: svgImageTag.getAttribute("height")
    };

    // ensure we get the actual original and not just the first one
    const originalImageInstructionIndex = imageInstructions
      .map(instruction => instruction.domId)
      .indexOf(domId);
    const originalImageInstruction =
      imageInstructions[originalImageInstructionIndex];

    const imageInstructionPreviewUpdated = await calculateUpdatedVectorImageInstructions(
      {
        imageInstruction,
        originalImageInstruction,
        maskSize
      }
    );

    this.setState({
      imageInstructionPreview: {
        ...imageInstructionPreviewUpdated,
        filter: originalImageInstruction.filter,
        filters: originalImageInstruction.filters,
        opacity: originalImageInstruction.opacity
      }
    });
  }

  handleImageDragLeave() {
    if (!this.state.imageInstructionPreview) return;

    this.setState({
      imageInstructionPreview: null
    });
  }

  stopPropagationAndPreventDefault(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  async handleImageDrop({
    assetElement,
    domId,
    imageInstruction: dropImageInstruction
  }) {
    const {
      imageInstruction,
      addActionToPropagationQueue,
      elementData
    } = this.props;
    const { imageInstructionPreview } = this.state;

    if (!imageInstructionPreview) {
      this.setState({
        imageInstructionPreview: null
      });
      return;
    }

    const palette = await getPaletteFromSrc(imageInstructionPreview.previewUrl);

    const svgImageTag = this.state.svgDomElement.querySelector(
      `#${domId} > image`
    );

    const maskSize = {
      width: svgImageTag.getAttribute("width"),
      height: svgImageTag.getAttribute("height")
    };

    addActionToPropagationQueue({
      assetElement,
      uniqueId: elementData.uniqueId,
      domId,
      actionType: "vectorImageReplace",
      imageInstruction: dropImageInstruction,
      maskSize,
      originalElement: {
        ...pick(elementData, ["value", "src", "id"]),
        imageInstructions: (
          elementData.imageInstructions || []
        ).map(instruction => pick(instruction, ["src", "domId"])),
        groupId: this.props.groupId,
        pageId: this.props.pageId,
        domId
      }
    });

    this.setState({
      imageInstructionPreview: null
    });

    const imageInstructionUpdated = {
      scaleX: 1,
      scaleY: 1,
      ...imageInstruction,
      ...imageInstructionPreview,
      palette: palette
    };

    this.props.updateGridImageInstructions({
      elementId: this.props.elementId,
      imageInstructions: [imageInstructionUpdated]
    });
  }

  photoFrameHasImage(domId) {
    const { elementData } = this.props;
    const imageInstructions = elementData.imageInstructions.filter(
      imageInstruction => imageInstruction.domId === domId
    );

    const matchingImageInstructionFound =
      Boolean(imageInstructions) && Boolean(imageInstructions[0]);
    if (matchingImageInstructionFound) {
      return Boolean(
        imageInstructions[0].id &&
          (imageInstructions[0].mediaId || imageInstructions[0].src)
      );
    }
    return false;
  }

  selectPhotoFrame(_event, domId) {
    const { elementData } = this.props;

    if (!elementData.imageInstructions) {
      return;
    }

    this.props.selectPhotoInFrame({ domId });
  }

  handleClick = (event, domId) => {
    this.stopPropagationAndPreventDefault(event);

    if (this.props.isSelected) {
      this.selectPhotoFrame(event, domId);
      return;
    }
    this.onSelect(event);
  };

  handleDoubleClick = (_event, domId) => {
    if (this.props.restrictions.includes("cropping")) {
      return;
    }
    if (this.photoFrameHasImage(domId)) {
      this.props.startPhotoFrameCropMode();
    } else {
      const { resizableX, resizableY } = this.props.elementData || {};
      // do not enter crop mode for a vector that is resizable
      const isResizableVector = [resizableX, resizableY].some(x => !!x);
      if (!isResizableVector) {
        this.props.startVectorCropMode();
      }
    }
  };

  renderSvgBorder(_svg, selectedDomId) {
    const svgCloned = _svg.cloneNode(true);

    const svg = getSelectedImageInstructionBorder(svgCloned, selectedDomId);
    const mask = this.props.elementData.mask || DEFAULT_VECTOR_MASK;
    const leftMaskOffset = (-mask.left || 0) * this.props.elementData.scale;
    const topMaskOffset = (-mask.top || 0) * this.props.elementData.scale;

    return (
      <div
        style={{
          position: "absolute",
          top: `${topMaskOffset}px`,
          left: `${leftMaskOffset}px`,
          width: this.props.elementData.width,
          height: this.props.elementData.height,
          pointerEvents: "none",
          zIndex: -1
        }}
        dangerouslySetInnerHTML={{ __html: svg.outerHTML }}
      />
    );
  }

  handleMouseEnter() {
    if (this.state.isHovered) return;
    this.setState({
      isHovered: true
    });
  }

  handleMouseLeave() {
    this.setState({
      isHovered: false
    });
  }

  render() {
    const {
      scale,
      width,
      height,
      opacity,
      instructionScale,
      resizableX,
      resizableY
    } = this.props.elementData;

    const {
      restrictionsForDocument,
      restrictionsForElement,
      isSelected,
      zoom,
      isAllRestrictionsLocked,
      audioPlayableElementsForPage = [],
      isCropMask
    } = this.props;

    let svg = this.state.svgRenderObject;

    if (!svg) {
      return null;
    }

    const isResizableVector = [resizableX, resizableY].some(x => !!x);

    let style = {
      width,
      height,
      lineHeight: 0,
      opacity: opacity,
      overflow: "visible"
    };

    const finalStyle = {
      ...this.props.style,
      ...style
    };

    if (isCropMask) {
      finalStyle.pointerEvents = "none";
    }

    const isHovered = this.state.isHovered;

    const element = {
      ...this.props.elementData,
      svg,
      type: "vector"
    };

    const isOutlined =
      !!element.outline &&
      (!!element.outline.width || !!element.outline.fade) &&
      !(element.outline.width === "0" && element.outline.fade === "0");

    // Turns out this.props.isPhotoSelected is really the domId of the selected imageInstruction
    // renaming here to make code in this component easier to understand
    const selectedDomId = this.props.isPhotoSelected;
    const isPhotoSelected = Boolean(this.props.isPhotoSelected);

    const mask = this.props.elementData.mask || DEFAULT_VECTOR_MASK;

    // define default masking variables
    let unmaskedVector = this.props.elementData,
      leftMaskOffset = 0,
      topMaskOffset = 0,
      maskedVectorStyles = {};

    // conditional assignments to masking variables
    if (!isResizableVector) {
      unmaskedVector = {
        ...this.props.elementData,
        top:
          this.props.elementData.top -
          this.props.elementData.mask.top * this.props.elementData.scale,
        left:
          this.props.elementData.left -
          this.props.elementData.mask.left * this.props.elementData.scale,
        width:
          this.props.elementData.srcWidth * this.props.elementData.scale * zoom,
        height:
          this.props.elementData.srcHeight *
          this.props.elementData.scale *
          zoom,
        mask: DEFAULT_VECTOR_MASK,
        srcWidth: this.props.elementData.srcWidth * zoom,
        srcHeight: this.props.elementData.srcHeight * zoom,
        instructionScale: this.props.elementData.scale * zoom
      };

      leftMaskOffset = (-mask.left || 0) * scale;
      topMaskOffset = (-mask.top || 0) * scale;

      maskedVectorStyles = {
        height: `${this.props.elementData.srcHeight *
          this.props.elementData.scale}px`,
        width: `${this.props.elementData.srcWidth *
          this.props.elementData.scale}px`,
        transform: `translate(${-(mask.left * scale)}px, ${-(
          mask.top * scale
        )}px)`
      };
    }

    return this.props.connectDragSource(
      <div style={finalStyle} ref={this.wrapperRef}>
        {isOutlined && !isCropMask && (
          <>
            <OutlineFilter
              elementData={element}
              svg={svg}
              zoom={zoom}
              imageInstructions={this.state.imageInstructionList}
              scale={scale}
              instructionScale={instructionScale}
              leftMaskOffset={leftMaskOffset}
              topMaskOffset={topMaskOffset}
              isModifying={this.props.isModifying}
            />
          </>
        )}
        <div
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.onSelect}
          style={{
            overflow: "hidden",
            width: `${this.props.elementData.width}px`,
            height: `${this.props.elementData.height}px`,
            position: "absolute"
          }}
        >
          <div
            ref={this.setElementRef}
            dangerouslySetInnerHTML={{ __html: svg.outerHTML }}
            className={svgStyles.originSvgWrapper}
            onDoubleClick={this.handleDoubleClick}
            style={{
              opacity: getPath(
                this.props.elementData,
                "outline.knockout",
                false
              )
                ? 0
                : 1,
              ...maskedVectorStyles
            }}
          />

          {isPhotoSelected && this.renderSvgBorder(svg, selectedDomId)}
          {this.state.imageInstructionList.map(imageInstruction => {
            const isMuted = !audioPlayableElementsForPage.find(
              audioPlayableElement =>
                audioPlayableElement.elementId ===
                  this.props.elementData.uniqueId &&
                audioPlayableElement.instructionId === imageInstruction.uniqueId
            );
            const placeholder = getPlaceholderSize(imageInstruction, element);
            const frameDimensions = getFrameDimensions({
              vectorId: this.props.elementId,
              frameId: imageInstruction.domId,
              vectorElement: isCropMask
                ? this.props.elementData
                : unmaskedVector,
              zoom
            });
            if (!frameDimensions) return null;
            return (
              <ImageInstruction
                imageDoesExist={imageInstruction.type === "image"}
                restrictionsForDocument={restrictionsForDocument}
                restrictionsForElement={restrictionsForElement}
                domId={imageInstruction.domId}
                height={frameDimensions.height / zoom}
                key={imageInstruction.domId}
                left={placeholder.left * scale}
                onClick={this.handleClick}
                onDoubleClick={this.handleDoubleClick}
                onImageDragHover={this.handleImageDragHover}
                onImageDragLeave={this.handleImageDragLeave}
                onImageDrop={this.handleImageDrop}
                scale={instructionScale || scale}
                instructionScale={instructionScale}
                top={placeholder.top * scale}
                width={frameDimensions.width / zoom}
                zoom={zoom}
                imageInstruction={imageInstruction}
                element={this.props.elementData}
                isPlaying={this.props.isPlaying}
                autoPlay={this.props.autoPlay}
                isContentHidden={getPath(
                  this.props.elementData,
                  "outline.knockout",
                  false
                )}
                isMuted={isMuted}
                leftMaskOffset={leftMaskOffset}
                topMaskOffset={topMaskOffset}
                renderVersion={this.state.renderVersion}
              />
            );
          })}
          {isHovered && !isAllRestrictionsLocked && !isSelected && (
            <HoverOverlay height={height} width={width} zoom={zoom} />
          )}
        </div>
      </div>
    );
  }
}

export default Vector;
