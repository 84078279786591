import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ImageFileIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color = easilGrayXDark,
  backgroundColor = "#DCDCE0",
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 56}
    height={height || size || 43}
    viewBox="0 0 56 43"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <rect
          fill={backgroundColor}
          opacity=".85"
          x="6"
          y="6"
          width="50"
          height="37"
          rx="2"
        />
        <path
          d="M52.5 4.5v31a4 4 0 0 1-4 4h-44a4 4 0 0 1-4-4v-31a4 4 0 0 1 4-4h44a4 4 0 0 1 4 4zm-2 0a2 2 0 0 0-2-2h-44a2 2 0 0 0-2 2v31a2 2 0 0 0 2 2h44a2 2 0 0 0 2-2v-31z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M21 20a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
      <path
        d="M35.403 12.056C36.039 23.4 30.058 28.926 17 28.926a1 1 0 0 0 0 2c14.15 0 21.104-6.425 20.4-18.982a1 1 0 1 0-1.997.112z"
        fill={color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
ImageFileIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ImageFileIcon;
