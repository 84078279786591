import React, { Component } from "react";
import PropTypes from "prop-types";
import DesignSizeCard from "views/containers/DesignsSizeFilterContainer/DesignsSizeFilter/ViewAll/ModalContent/DesignsCategory/DesignSizeCard/DesignSizeCard";
import CaretSlimIcon from "views/components/icons/CaretSlimIcon";
import style from "./style.module.css";
import { noop, getPath } from "lib";
import { jsonStringEqual } from "lib/equalityUtils";

class Carousel extends Component {
  static defaultProps = {
    onSizeClick: noop
  };

  constructor(props) {
    super(props);

    this.slideTimer = null;

    this.row = React.createRef();
    this.leftButton = React.createRef();
    this.rightButton = React.createRef();

    this.sideScroll = this.sideScroll.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.setScrolling = this.setScrolling.bind(this);
    this.updateButtonsDisplay = this.updateButtonsDisplay.bind(this);
  }

  componentDidMount() {
    this.updateButtonsDisplay(this.row.current);
  }

  componentDidUpdate(prevProps) {
    if (this.hasOptionsChanges(this.props.sizeOptions, prevProps.sizeOptions)) {
      this.row.current.scrollLeft = 0;
    }

    this.updateButtonsDisplay(this.row.current);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.hasOptionsChanges(this.props.sizeOptions, nextProps.sizeOptions)) {
      return true;
    }

    if (
      this.hasSelectedChanged(this.props.sizeOptions, nextProps.sizeOptions)
    ) {
      return true;
    }

    return false;
  }

  hasSelectedChanged(sizeOptions, newSizeOptions) {
    const sizeOptionSelected = sizeOptions.filter(
      option => option.isSelected
    ) || { name: null };
    const newSizeOptionsSelected = newSizeOptions.filter(
      option => option.isSelected
    ) || { name: null };

    return !jsonStringEqual(sizeOptionSelected, newSizeOptionsSelected);
  }

  hasOptionsChanges(sizeOptions, newSizeOptions) {
    if (newSizeOptions.length !== sizeOptions.length) {
      return true;
    }

    if (optionsToHash(newSizeOptions) !== optionsToHash(sizeOptions)) {
      return true;
    }

    return false;

    function optionsToHash(sizeOptions) {
      return sizeOptions.map(sizeOption => sizeOption.name).join();
    }
  }

  componentWillUnmount() {
    clearInterval(this.slideTimer);
  }

  sideScroll({ element, direction, animationInterval, distance, step }) {
    if (this.isScrolling) {
      return;
    }

    this.setScrolling(true);

    let scrollAmount = 0;
    const that = this;

    this.slideTimer = setInterval(function() {
      const minStep = Math.min(step, distance - scrollAmount);

      if (direction === "left") {
        element.scrollLeft -= minStep;
      } else {
        element.scrollLeft += minStep;
      }

      scrollAmount += minStep;

      if (
        scrollAmount >= distance ||
        element.scrollLeft === element.scrollWidth - element.clientWidth ||
        element.scrollLeft === 0
      ) {
        that.setScrolling(false);
        that.updateButtonsDisplay(element);
        clearInterval(that.slideTimer);
      }
    }, animationInterval);
  }

  updateButtonsDisplay(row) {
    /* if there is no scrolling, hide right button */
    if (row.scrollWidth > row.clientWidth) {
      this.rightButton.current.dataset.hidden = false;
    } else {
      this.rightButton.current.dataset.hidden = true;
    }

    /* if has scrolled to the end, disable the right button */
    if (row.scrollLeft === row.scrollWidth - row.clientWidth) {
      this.rightButton.current.dataset.hidden = true;
    } else {
      this.rightButton.current.dataset.hidden = false;
    }

    /* if it is at the initial position(0) or has no scroll */
    if (row.scrollLeft === 0 || row.scrollWidth === row.clientWidth) {
      this.leftButton.current.dataset.hidden = true;
    } else {
      this.leftButton.current.dataset.hidden = false;
    }
  }

  setScrolling(value) {
    this.isScrolling = value;
    this.leftButton.current.disabled = value;
    this.rightButton.current.disabled = value;
  }

  onButtonClick(event) {
    const direction = event.currentTarget.dataset.direction;
    const container = this.row.current;

    this.sideScroll({
      element: container,
      direction,
      animationInterval: 0.01,
      distance: container.clientWidth + 25,
      step: 30
    });
  }

  render() {
    const { sizeOptions, onSizeClick } = this.props;

    return (
      <div data-testid="Carousel">
        <div className={style.viewBox}>
          <div className={style.row} ref={this.row}>
            {sizeOptions.map(designSize => {
              let onClick = () => onSizeClick(designSize.id);
              if (designSize.type === "search") {
                onClick = () =>
                  this.props.onSearch(
                    getPath(designSize, "searchParams.term"),
                    getPath(designSize, "searchParams.searchSize")
                  );
              }

              return (
                <DesignSizeCard
                  key={designSize.id}
                  designSize={designSize}
                  wrapperClassName={style.designSizeCard}
                  onClick={onClick}
                />
              );
            })}
          </div>
          <button
            className={style.buttons}
            onClick={this.onButtonClick}
            data-direction="left"
            data-hidden="true"
            ref={this.leftButton}
          >
            <CaretSlimIcon width={14} direction="left" />
          </button>
          <button
            className={style.buttons}
            onClick={this.onButtonClick}
            data-direction="right"
            data-hidden="true"
            ref={this.rightButton}
          >
            <CaretSlimIcon width={14} direction="right" />
          </button>
        </div>
      </div>
    );
  }
}
Carousel.propTypes = {
  sizeOptions: PropTypes.array.isRequired,
  onSizeClick: PropTypes.func
};

export default Carousel;
