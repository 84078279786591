import { rotatePoint } from "lib/geometry/rotation";
import {
  MINIMUM_CROP_RESIZE_HANDLE_PROXIMITY,
  MINIMUM_CROP_EUCLIDEAN_DISTANCE
} from "lib/constants";
import {
  distanceBetweenPoints,
  calculateScalingFactor
} from "lib/scalingTools/scalingTools";

export const calculateTextMaskImageScaledNewDimensions = ({
  dragItem,
  textElement,
  zoom,
  differenceFromInitialOffset
}) => {
  const {
    anchorPoint,
    handlerInitialPosition,
    selectionBoxNodeCenter
  } = dragItem;

  const scaleFactor = calculateScalingFactor({
    anchorPoint,
    handlerInitialPosition,
    differenceFromInitialPosition: differenceFromInitialOffset
  });

  const scale = Math.max(
    textElement.width / textElement.maskImage.width,
    textElement.height / textElement.maskImage.height,
    scaleFactor
  );

  const imageHeight = textElement.maskImage.height * zoom;
  const imageWidth = textElement.maskImage.width * zoom;

  const newImageHeight = imageHeight * scale;
  const newImageWidth = imageWidth * scale;

  const imageCenterX = selectionBoxNodeCenter.x;
  const imageCenterY = selectionBoxNodeCenter.y;

  const imageLeft = imageCenterX - imageWidth / 2;
  const imageTop = imageCenterY - imageHeight / 2;

  const rotatedImagePoint = rotatePoint(
    imageLeft,
    imageTop,
    imageCenterX,
    imageCenterY,
    parseInt(textElement.angle)
  );

  const rotatedImageLeft =
    anchorPoint.x + (rotatedImagePoint.x - anchorPoint.x) * scale;
  const rotatedImageTop =
    anchorPoint.y + (rotatedImagePoint.y - anchorPoint.y) * scale;

  const unRotatedImagePoint = rotatePoint(
    rotatedImageLeft,
    rotatedImageTop,
    imageCenterX,
    imageCenterY,
    -parseInt(textElement.angle)
  );

  const newImageLeft = unRotatedImagePoint.x;
  const newImageTop = unRotatedImagePoint.y;

  return {
    height: newImageHeight,
    width: newImageWidth,
    left: newImageLeft,
    top: newImageTop,
    right: newImageLeft + newImageWidth,
    bottom: newImageTop + newImageHeight,
    leftDiff: newImageLeft - imageLeft,
    topDiff: newImageTop - imageTop,
    scale: scale
  };
};

/**
 * @desc returns an object containing values for top, left, right and bottom offset of cropping
 * mask from the original image element
 * @param {object} element - element data for cropping image/vector
 * @param {object} croppingImage - object from editor selectedItems
 * contains top, left, right, bottom values for offset of mask from image element
 */
const getPreviewCropCoordinates = ({ element, croppingImage }) => {
  const defaultCropCoordinates = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  };

  let cropCoordinates;
  if (croppingImage.preview && croppingImage.preview.mask) {
    cropCoordinates = croppingImage.preview.mask;
  } else {
    // no preview mask has been applied
    // assign element mask data
    cropCoordinates = element.mask;
  }

  // spread default values with assigned values to assign any missing values
  return {
    ...defaultCropCoordinates,
    ...cropCoordinates
  };
};

/**
 * @desc returns an object containing boolean values for the x and y axis in regards to resize
 * handle position proximity to crop handle position
 * @param {boolean} isCropping - true/false whether element is in cropping mode
 * @param {Array} selectedItems - array from editor state. Contains elements uniqueId and preview data
 * @param {string} position - position of resize handle to check ("NW", "NE", "SW", "SE")
 * @param {object} designData - object containing current design data
 * @param {Number} zoom - zoom value for current design
 */
export const checkCropHandleProximity = ({
  isCropping,
  selectedItems,
  position: resizeDotPosition,
  designData,
  zoom
} = {}) => {
  if (!isCropping || !(selectedItems && selectedItems.length)) return;

  // only one selectedItem when cropping
  const croppingImage = selectedItems[0];
  const element = designData.getElement(croppingImage.itemId);

  const { top, bottom, left, right } = getPreviewCropCoordinates({
    element,
    croppingImage
  });

  // returns boolean for each axis dependant on whether crop handle coordianates are too
  // close to resize box sides
  const checkCropProximity = ({ x, y }) => {
    const originPosition = { x: 0, y: 0 };
    // euclidean distance from resize box corner to crop mask corner
    const distanceToCropHandle =
      distanceBetweenPoints(originPosition, {
        x,
        y
      }) * element.scale;

    // return early when crop mask is spaced away from resize box regardless of side proximity
    if (distanceToCropHandle > MINIMUM_CROP_EUCLIDEAN_DISTANCE / zoom)
      return { x: false, y: false };

    return {
      x: x * element.scale < MINIMUM_CROP_RESIZE_HANDLE_PROXIMITY / zoom,
      y: y * element.scale < MINIMUM_CROP_RESIZE_HANDLE_PROXIMITY / zoom
    };
  };

  switch (resizeDotPosition) {
    case "NW": {
      const positionalValues = { x: left, y: top };
      return checkCropProximity(positionalValues);
    }
    case "NE": {
      const positionalValues = { x: right, y: top };
      return checkCropProximity(positionalValues);
    }
    case "SE": {
      const positionalValues = { x: right, y: bottom };
      return checkCropProximity(positionalValues);
    }
    case "SW": {
      const positionalValues = { x: left, y: bottom };
      return checkCropProximity(positionalValues);
    }
    default:
      return { x: true, y: true };
  }
};
