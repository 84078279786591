import React from "react";
import style from "./style.module.css";
import PaymentOption from "./PaymentOption";

export class PaymentSection extends React.Component {
  render() {
    const {
      setToken,
      paymentType,
      setPaymentType,
      token,
      createOrderPayment,
      createPaypalOrder,
      orderId,
      currencyCode
    } = this.props;

    return (
      <div className={style.wrapper}>
        <div className={style.title}>Payment</div>
        <div className={style.subTitle}>
          All transactions are 100% secure and encrypted.
        </div>
        <div className={style.paymentOptions}>
          <PaymentOption
            handleSelection={() => setPaymentType("credit")}
            type="credit"
            isActive={paymentType === "credit"}
            setToken={setToken}
            token={token}
          />
          <PaymentOption
            handleSelection={() => setPaymentType("paypal")}
            type="paypal"
            isActive={paymentType === "paypal"}
            token={token}
            createOrderPayment={createOrderPayment}
            createPaypalOrder={createPaypalOrder}
            orderId={orderId}
            currencyCode={currencyCode}
          />
        </div>
      </div>
    );
  }
}

PaymentSection.displayName = "PaymentSection";

export default PaymentSection;
