import { jwt } from "lib/jsonWebToken";
import PATHS from "routes/paths";
const {
  REACT_APP_AUTH_SERVICE_URL: AUTH_SERVICE_URL,
  REACT_APP_URL
} = process.env;

const auth = {};

auth.jwtDecoder = token => {
  if (!token) return {};

  const {
    team_id: teamId,
    sub: userId,
    roles,
    name,
    email,
    team_plan: teamPlan
  } = jwt.decode(token);

  return {
    teamId,
    userId,
    roles,
    name,
    email,
    teamPlan
  };
};

auth.setTokenInLocalStorage = value =>
  localStorage.setItem("auth_token", value);

auth.LOGIN_URL = [REACT_APP_URL, PATHS.login].join("");

auth.LOGOUT_URL = [
  AUTH_SERVICE_URL,
  "/session/end?",
  `post_logout_redirect_uri=${encodeURIComponent(REACT_APP_URL)}`
].join("");

auth.purgeLocalStorage = () => {
  localStorage.removeItem("auth_token");
  localStorage.removeItem("accessToken");
};

auth.findAToken = ({ state, history }) => {
  const storeToken = state.currentUser.token;
  if (storeToken) {
    return {
      tokenOrigin: "state",
      token: storeToken
    };
  }

  const localStorageToken = localStorage.getItem("auth_token");
  if (localStorageToken) {
    return {
      tokenOrigin: "localStorage",
      token: localStorageToken
    };
  }

  return {};
};

auth.getToken = ({ state, history }) => {
  const { token, tokenOrigin } = auth.findAToken({ state, history });

  if (!token) return {};

  auth.setTokenInLocalStorage(token);

  // TODO: CM start: See Git history for what to revert to
  let tokenClaims = {
    userId: null,
    teamId: null,
    roles: null
  };
  try {
    tokenClaims = auth.jwtDecoder(token);

    return {
      ...tokenClaims,
      tokenOrigin,
      token
    };
  } catch (err) {
    console.error("Failed to decode the provided auth token");
    return {
      ...tokenClaims,
      tokenOrigin,
      token: null
    };
  }
  // TODO: CM end
};

export default auth;
