import React from "react";
import style from "./style.module.css";

import CurrentSubscriptionDescription from "../CurrentSubscriptionDescription";

class CurrentSubscriptionDescriptionFree extends CurrentSubscriptionDescription {
  getTiles() {
    const tiles = [
      {
        title: "Plan",
        body: this.planDisplayName,
        className: style.planTile
      }
    ];

    return tiles;
  }

  getInfoMessage() {
    return (
      <span>
        You're on the <strong>{this.planDisplayName}</strong> plan. To unlock
        access to more amazing features, upgrade your plan.
      </span>
    );
  }

  cancelPlanComponent() {
    return null;
  }
}

CurrentSubscriptionDescriptionFree.displayName =
  "CurrentSubscriptionDescriptionFree";

export default CurrentSubscriptionDescriptionFree;
