import { asyncFetchDesignIfNeeded } from "state/entities/designs/designsActions";
import {
  fetchCurrentOrder,
  asyncGetAllOrders,
  flagOrderDesignsAsUpdatingVersion,
  asyncUpdateDesignInOrder
} from "state/entities/orders/ordersActions";
import { getCurrentOrderByIdSelector } from "state/entities/orders/ordersSelectors";
import { setCurrentOrderId, setLoading } from "state/ui/cart/cartActions";
import {
  asyncFetchBillingDetailsForCurrentOrder,
  createNewBillingDetailsForCurrentOrder,
  updateBillingDetailsForCurrentOrder
} from "state/entities/billingDetails/billingDetailsActions";
import { reduxStoreExpiry } from "lib";
import {
  asyncFetchShippingDetailsForCurrentOrder,
  createNewShippingDetailsForCurrentOrder,
  updateShippingDetailsForCurrentOrder
} from "state/entities/shippingDetails/shippingDetailsActions";
import { billingDetailsForCurrentOrderSelector } from "state/entities/billingDetails/billingDetailsSelectors";
import { shippingDetailsForCurrentOrderSelector } from "state/entities/shippingDetails/shippingDetailsSelectors";
import { asyncFetchAllShippingRates } from "state/entities/shippingRates/shippingRatesActions";

import { asyncFetchAllPrintPricingForCurrentOrder } from "state/entities/printPricing/printPricingActions";
import {
  currentShippingSelector,
  currentBillingSelector
} from "state/ui/cart/cartSelectors";
import {
  MANDATORY_SHIPPING_FIELDS,
  MANDATORY_BILLING_FIELDS
} from "lib/constants";
import { isEmpty, some, omit } from "lib/lodash";
import { jsonStringEqual } from "lib/equalityUtils";
import { asyncAction } from "lib/asyncHelpers";
import { asyncFetchAllSizesForCurrentTeam as fetchWorkspaceSizes } from "state/entities/workspaceSizes/workspaceSizesActions";
import { asyncFetchAllSizesForCurrentTeam as fetchCatalogueSizes } from "state/entities/catalogueSizes/catalogueSizesActions";
import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";
import { asyncFetchDesignDataByDesignId } from "state/entities/designsData/designsDataActions";
import { removeFalsey } from "lib/object/object";

/**
 * @desc  fetch the details for the current order, if supplied with a preFetchedOrder
 *        then it will skip details request and only fetch the design details
 * @param {object} preFetchedOrder - order object to fetch the designs for (optional)
 * @returns
 */
export const getDetailsForCurrentOrder = (
  preFetchedOrder = {}
) => async dispatch => {
  let order = preFetchedOrder;
  if (isEmpty(order)) {
    const {
      entities: { order: orders }
    } = await dispatch(fetchCurrentOrder());
    order = Object.values(orders)[0];
  }

  const designPromises = order.designs.map(design =>
    dispatch(asyncFetchDesignIfNeeded({ designId: design.designId }))
  );
  return Promise.all(designPromises);
};

/**
 * @desc collect the data for the card page including order, pricing and required additional design information
 */
export const fetchCartPageData = () => async (dispatch, getState) => {
  const state = getState();
  // check for an order in cart state
  const currentOrderInState = getCurrentOrderByIdSelector({ state });

  if (
    !currentOrderInState ||
    currentOrderInState.status !== "IN_PROGRESS" ||
    reduxStoreExpiry.isDataExpired(currentOrderInState.fetchedAt, 5)
  ) {
    // no valid order in state, fetch or refetch
    const orders = await dispatch(asyncGetAllOrders({ status: "IN_PROGRESS" }));

    dispatch(setLoading({ order: false }));

    if (!orders.length) {
      dispatch(
        setLoading({
          details: false,
          pricing: false
        })
      );
      return;
    }

    dispatch(setCurrentOrderId({ orderId: orders[0].id }));

    // get details for current order
    await dispatch(getDetailsForCurrentOrder());
    dispatch(setLoading({ details: false }));
  } else {
    dispatch(setLoading({ order: false }));
    // get details for current order
    await dispatch(getDetailsForCurrentOrder(currentOrderInState));
    dispatch(setLoading({ details: false }));
  }

  // fetch order print pricing
  await dispatch(asyncFetchAllPrintPricingForCurrentOrder());
  dispatch(setLoading({ pricing: false }));

  // fetch catalogue sizes which we use to determine if a design is actually printable
  await Promise.all([
    dispatch(fetchWorkspaceSizes()),
    dispatch(fetchCatalogueSizes())
  ]).catch(() => {
    dispatch(setLoading({ sizes: false }));
  });
  dispatch(setLoading({ sizes: false }));
};

/**
 * @desc updates or creates billing details for the currently in progress order
 */
export const updateBillingDetails = ({ resolve, reject }) => (
  dispatch,
  getState
) => {
  const state = getState();
  // if shipping has been fetched and there is no shipping
  const apiBillingDetails = billingDetailsForCurrentOrderSelector({ state });
  const localBillingDetails = currentBillingSelector(state);

  if (
    // we have details from the api and no local details
    (apiBillingDetails && isEmpty(localBillingDetails)) ||
    // nothing has changed about the details so no need to send request
    jsonStringEqual(apiBillingDetails, localBillingDetails)
  ) {
    resolve();
  }

  const mandatoryValuesExist = MANDATORY_BILLING_FIELDS.map(
    key => localBillingDetails[key] && localBillingDetails[key] !== ""
  );
  const isAnyMandatoryValueMissing = some(
    mandatoryValuesExist,
    value => !value
  );
  if (isAnyMandatoryValueMissing) {
    // set an error state for each missing value for the cart to check
    console.error(
      "Mandatory field missing, this is currently a noop but should be handled with an error in redux"
    );
    return resolve();
  } else {
    // nothing is missing, we should proceed
    // run update if there is details already, run create if there is none already
    const billingUpdateFunction = !isEmpty(apiBillingDetails)
      ? updateBillingDetailsForCurrentOrder
      : createNewBillingDetailsForCurrentOrder;

    const localBillingKeys = Object.keys(localBillingDetails);

    // remove any empty strings from billing request
    const updatedBillingDetails = removeFalsey({
      ...localBillingDetails,
      ...omit(apiBillingDetails || {}, localBillingKeys)
    });

    dispatch(
      billingUpdateFunction({
        billingDetails: updatedBillingDetails,
        resolve,
        reject
      })
    );
  }
};

/**
 * @desc updates or creates shipping details for the currently in progress order
 */
export const updateShippingDetails = ({ resolve, reject }) => (
  dispatch,
  getState
) => {
  const state = getState();
  // if shipping has been fetched and there is no shipping
  const apiShippingDetails = shippingDetailsForCurrentOrderSelector({ state });
  const localShippingDetails = currentShippingSelector(state);

  if (
    // we have details from the api and no local details
    (apiShippingDetails && isEmpty(localShippingDetails)) ||
    // nothing has changed about the details so no need to send request
    jsonStringEqual(apiShippingDetails, localShippingDetails)
  ) {
    resolve();
  }

  const mandatoryValuesExist = MANDATORY_SHIPPING_FIELDS.map(
    key => localShippingDetails[key] && localShippingDetails[key] !== ""
  );
  const isAnyMandatoryValueMissing = some(
    mandatoryValuesExist,
    value => !value
  );
  if (isAnyMandatoryValueMissing) {
    // set an error state for each missing value for the cart to check
    console.error(
      "Mandatory field missing, this is currently a noop but should be handled with an error in redux"
    );
    resolve();
  } else {
    // nothing is missing, we should proceed
    // run update if there is details already, run create if there is none already
    const shippingUpdateFunction = !isEmpty(apiShippingDetails)
      ? updateShippingDetailsForCurrentOrder
      : createNewShippingDetailsForCurrentOrder;

    const localShippingKeys = Object.keys(localShippingDetails);

    // remove any empty strings from shipping request
    const updatedShippingDetails = removeFalsey({
      ...localShippingDetails,
      ...omit(apiShippingDetails || {}, localShippingKeys)
    });

    dispatch(
      shippingUpdateFunction({
        shippingDetails: updatedShippingDetails,
        resolve,
        reject,
        onSuccess: () => {
          dispatch(fetchCurrentOrder());
        }
      })
    );
  }
};

export const updateShippingAndBillingData = () => (dispatch, getState) => {
  const subscription = currentSubscriptionSelector(getState());
  if (subscription.isAccount) {
    return [dispatch(asyncAction(updateShippingDetails)())];
  }
  return [
    dispatch(asyncAction(updateBillingDetails)()),
    dispatch(asyncAction(updateShippingDetails)())
  ];
};

/**
 * @desc fetches billing details, shipping details and shipping rates for the current in progress order
 */
export const fetchBillingAndShippingDetails = () => async dispatch => {
  await dispatch(asyncFetchBillingDetailsForCurrentOrder());
  dispatch(setLoading({ billing: false }));
  await dispatch(asyncFetchShippingDetailsForCurrentOrder());
  dispatch(setLoading({ shipping: false }));
  await dispatch(asyncFetchAllShippingRates());
  dispatch(setLoading({ shippingRates: false }));
};

/**
 * @desc updates the given orderDesigns designDataId to the latest for their designId
 */
export const updateOrderDesigns = orderDesignUpdates => async dispatch => {
  if (!orderDesignUpdates || !orderDesignUpdates.length) return;

  const orderId = orderDesignUpdates[0].orderId;

  // set flags for orderDesigns being updated
  dispatch(
    flagOrderDesignsAsUpdatingVersion({
      orderId: orderId,
      orderDesignIds: orderDesignUpdates.map(
        orderDesignUpdate => orderDesignUpdate.orderDesignId
      )
    })
  );

  // map through the designs
  orderDesignUpdates.forEach(async orderDesignUpdate => {
    dispatch(asyncUpdateDesignInOrder(orderDesignUpdate));
  });
};

export const getUpdatedDesignDataIdsForOrderDesigns = orderDesigns => async dispatch => {
  if (!orderDesigns || !orderDesigns.length) return [];

  // map through the designs
  const orderDesignUpdatePromises = orderDesigns.map(async orderDesign => {
    // get the latest design version
    const designDataResponse = await dispatch(
      asyncFetchDesignDataByDesignId({ designId: orderDesign.designId })
    );

    // get the designDataId and call to update the orderDesign
    const designDataId =
      designDataResponse.entities.designData[designDataResponse.ids].id;

    if (designDataId === orderDesign.designDataId) return null;

    return {
      orderDesignId: orderDesign.id,
      designDataId,
      orderId: orderDesign.orderId,
      designId: orderDesign.designId
    };
  });

  const orderDesignUpdates = await Promise.all(orderDesignUpdatePromises);

  return orderDesignUpdates.filter(x => x);
};
