import * as types from "./imageFoldersTypes";
import { isEmpty, noop } from "lib";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import { refetchUserTeamImagesPagesInFolder } from "state/entities/userTeamImages/userTeamImagesActions";
import { uploadImageToPreSignedUrl } from "state/entities/userTeamImages/userTeamImagesActions";
import { getParameterByName } from "lib/queryStringUtils";

const shouldFetchAllImageFolders = state => {
  return isEmpty(state.api.imageFolders.pages);
};

export const fetchAllImageFoldersIfNeeded = (...args) => (
  dispatch,
  getState
) => {
  if (shouldFetchAllImageFolders(getState())) {
    dispatch(fetchAllImageFolders(...args));
  }
};

export const fetchAllImageFolders = ({ page = 1 } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.FETCH_IMAGE_FOLDERS_REQUEST,
        types.FETCH_IMAGE_FOLDERS_REQUEST_SUCCESS,
        types.FETCH_IMAGE_FOLDERS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders`,
      request: {
        page: page,
        params: {
          type: "IMAGE"
        }
      },
      schema: schemas.FOLDERS,
      onSuccess: response => {
        if (response.entities) {
          dispatch(fetchAllImageFolders({ teamId, userId, page: page + 1 }));
        }
      }
    }
  });
};

export const createImageFolder = ({ name, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.CREATE_IMAGE_FOLDER_REQUEST,
        types.CREATE_IMAGE_FOLDER_REQUEST_SUCCESS,
        types.CREATE_IMAGE_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders`,
      request: {
        body: {
          teamId,
          userId,
          name,
          type: "IMAGE" //only IMAGE type for image folder creation
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const addImageToFolder = ({ folderId, contentId, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = currentTeamIdSelector(state);
  dispatch({
    [CALL_API]: {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      service: SERVICES.ASSET,
      types: [
        types.ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST,
        types.ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST_SUCCESS,
        types.ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content`,
      request: {
        body: {
          userId,
          teamId,
          contentId,
          folderId,
          type: "IMAGE"
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const addBulkImagesToFolder = ({ folderId, mediaIds, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = currentTeamIdSelector(state);
  const term = getParameterByName("term", window.location.search);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST,
        types.BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS,
        types.BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/images`,
      request: {
        body: {
          userId,
          teamId,
          mediaIds,
          folderId
        },
        extra: {
          term
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const uploadImageToFolder = ({
  folderId,
  imageFile: file,
  onSuccess,
  duration,
  onPresignedUrlCreated = noop,
  noRefetch,
  placeholderURL,
  sortOptions
}) => async (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  // synchronously generate a pre signed url for the direct upload
  const preSignedUrl = await generatePreSignedUrlPromise({ file, teamId });
  onPresignedUrlCreated(preSignedUrl.id);

  // synchronously upload the image file to the pre signed url
  await uploadImageToPreSignedUrlPromise({
    file,
    preSignedUrl: preSignedUrl.url
  });

  // make update request to the api
  dispatch(
    uploadImageToFolderApiCall({
      folderId,
      fileId: preSignedUrl.id,
      filename: preSignedUrl.filename,
      onSuccess,
      duration,
      noRefetch,
      placeholderURL,
      sortOptions
    })
  );

  /**
   * @desc taking a file object and a teamId makes a request for a pre signed image upload url and returns it on resolution
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.teamId - the id string for the current team to upload the image for
   * @returns {Promise} results in a Promise that resolves when the pre signed url is retrieved containing a 'url' key
   */
  function generatePreSignedUrlPromise({ file, teamId }) {
    return new Promise((resolve, reject) => {
      dispatch(
        createUserImageInFolderUploadUrl({
          folderId,
          filename: file.name,
          responseContentType: file.type,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }

  /**
   * @desc taking a file and a pre signed url processes the upload of the file to the passed url
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
   * @returns {Promise} results in a Promise that resolves when the image has been successfully uploaded
   */
  function uploadImageToPreSignedUrlPromise({ file, preSignedUrl }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadImageToPreSignedUrl({
          imageFile: file,
          preSignedUrl: preSignedUrl,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }
};

/**
 * @desc makes the api request for a pre signed url
 * @param {Object} args
 * @param {string} folderId - the id for the folder to upload the file into
 * @param {string} filename - the name of the file that will be uploaded
 * @param {string} responseContentType - the type extension for the file to be uploaded
 * @param {function} onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} onFailure - a function that runs when the request fails passed the results of the request
 */
export const createUserImageInFolderUploadUrl = ({
  folderId,
  filename,
  responseContentType,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.USER_IMAGE_IN_FOLDER_UPLOAD_URL_REQUEST,
        types.USER_IMAGE_IN_FOLDER_UPLOAD_URL_REQUEST_SUCCESS,
        types.USER_IMAGE_IN_FOLDER_UPLOAD_URL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content/url`,
      request: {
        body: {
          userId,
          teamId,
          folderId,
          filename,
          responseContentType
        }
      },
      onSuccess: onSuccess,
      schema: schemas.NONE
    }
  });
};

/**
 * @desc adds the details for an uploaded image to the api
 * @param {Object} args
 * @param {string} args.folderId - the id for the folder that the image is to be contained in
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which is run when the request succeeds and is passed the results of the request
 */
export const uploadImageToFolderApiCall = ({
  folderId,
  duration,
  filename,
  fileId,
  onSuccess = noop,
  noRefetch,
  placeholderURL,
  sortOptions = {
    sortBy: "updatedAt",
    sortOrder: "desc"
  }
}) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserSelector(state).id;
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      types: [
        types.UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST,
        types.UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST_SUCCESS,
        types.UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content`,
      request: {
        body: {
          userId,
          teamId,
          filename,
          folderId,
          duration,
          id: fileId
        }
      },
      extra: {
        placeholderURL
      },
      schema: schemas.FOLDER,
      onSuccess: response => {
        if (!noRefetch) {
          dispatch(
            refetchUserTeamImagesPagesInFolder(
              folderId,
              sortOptions.sortBy,
              sortOptions.sortOrder
            )
          );
        }
        onSuccess(response);
      }
    }
  });
};

export const removeImageFromFolder = ({
  folderId,
  contentId,
  term,
  onSuccess
}) => (dispatch, getState) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = currentTeamIdSelector(state);
  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST,
        types.REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST_SUCCESS,
        types.REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content/${contentId}`,
      request: {
        extra: {
          contentId,
          folderId,
          term
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const deleteImageFolder = ({ folderId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.DELETE_IMAGE_FOLDER_REQUEST,
        types.DELETE_IMAGE_FOLDER_REQUEST_SUCCESS,
        types.DELETE_IMAGE_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}`,
      schema: schemas.FOLDER,
      extra: {
        folderId
      },
      onSuccess
    }
  });
};

export const updateImageFolder = ({ folder, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ASSET,
      types: [
        types.UPDATE_IMAGE_FOLDER_REQUEST,
        types.UPDATE_IMAGE_FOLDER_REQUEST_SUCCESS,
        types.UPDATE_IMAGE_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folder.id}`,
      schema: schemas.FOLDER,
      request: {
        body: folder
      },
      onSuccess
    }
  });
};
