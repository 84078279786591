import React from "react";
import { connect } from "react-redux";
import ResetPassword from "./ResetPassword";
import { push } from "react-router-redux";
import { requestPasswordEmailToken } from "state/ui/resetPassword/resetPasswordActions";

export const ResetPasswordContainer = ({
  onRequestPasswordEmailToken,
  onRedirectTo,
  isSubmitting,
  isSuccess,
  errors
}) => {
  return (
    <ResetPassword
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      errors={errors}
      onRequestPasswordEmailToken={onRequestPasswordEmailToken}
      onRedirectTo={onRedirectTo}
    />
  );
};

const mapStateToProps = state => ({
  isSubmitting: state.ui.resetPassword.isSubmitting,
  isSuccess: state.ui.resetPassword.isSuccess,
  errors: state.ui.resetPassword.errors
});

const mapDispatchToProps = dispatch => ({
  onRequestPasswordEmailToken: args =>
    dispatch(requestPasswordEmailToken(args)),
  onRedirectTo: path => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
