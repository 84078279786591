import update, { extend } from "immutability-helper";

/* extend the immutability-helper to do autovivification
 * the extension helps to create neste paths that doesn't exist as per example below,
 *  where the foo.bar property initially doesn't exist on the state, but gets "auto"
 * create by the helper, making use of $auto to create an empty object if it doesnt
 * exist and $autoArray to create an array if the property doesnt exists and we want,
 * it to be an array
 * var state = {}
 * var desiredState = {
 *   foo: [
 *     {
 *       bar: ['x', 'y', 'z']
 *     },
 *   ],
 * };
 * var state2 = update(state, {
 *   foo: {$autoArray: {
 *     0: {$auto: {
 *       bar: {$autoArray: {$push: ['x', 'y', 'z']}}
 *     }}
 *   }}
 * });
 *
 * console.log(JSON.stringify(state2) === JSON.stringify(desiredState)) // true */

extend("$auto", function(value, object) {
  return object ? update(object, value) : update({}, value);
});
extend("$autoArray", function(value, object) {
  return object ? update(object, value) : update([], value);
});

export default update;
