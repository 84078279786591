export const USERS_REQUEST = "API/ENTITIES/USERS_REQUEST";
export const USERS_REQUEST_FAILURE = "API/ENTITIES/USERS_REQUEST_FAILURE";
export const USERS_REQUEST_SUCCESS = "API/ENTITIES/USERS_REQUEST_SUCCESS";
export const USERS_TOTAL_REQUEST = "API/ENTITIES/USERS_TOTAL_REQUEST";
export const USERS_TOTAL_REQUEST_FAILURE =
  "API/ENTITIES/USERS_TOTAL_REQUEST_FAILURE";
export const USERS_TOTAL_REQUEST_SUCCESS =
  "API/ENTITIES/USERS_TOTAL_REQUEST_SUCCESS";
export const UPDATE_USER = "API/ENTITIES/USERS/UPDATE";
export const USER_DELETE_REQUEST = "API/ENTITIES/USER_DELETE_REQUEST";
export const USER_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_DELETE_REQUEST_SUCCESS";
export const USER_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/USER_DELETE_REQUEST_FAILURE";

export const USER_ROLES_UPDATE_REQUEST =
  "API/ENTITIES/USER_ROLES_UPDATE_REQUEST";
export const USER_ROLES_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_ROLES_UPDATE_REQUEST_SUCCESS";
export const USER_ROLES_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/USER_ROLES_UPDATE_REQUEST_FAILURE";
