import * as types from "./userTeamImagesTypes";
import { isEmpty, omit } from "lib";
import * as imageFoldersEntitiesTypes from "state/entities/imageFolders/imageFoldersTypes";

export const initState = null;

const userTeamImagesReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USER_TEAM_IMAGES_IN_FOLDER_REQUEST_SUCCESS:
    case types.USER_TEAM_IMAGES_REQUEST_SUCCESS: {
      let previousState = state || {};

      const { entities = {} } = action.response;

      const userTeamImage = { ...entities.userTeamImage };

      if (!isEmpty(state)) {
        Object.keys(userTeamImage).forEach(id => {
          const image = userTeamImage[id];
          const stateImage = state[id];
          const preservedThumbUrl = image.thumbnailUrl;
          let thumbnailUrl = image.thumbnailUrl;
          if (
            stateImage &&
            stateImage.thumbnailUrl &&
            stateImage.thumbnailUrl.startsWith("blob:")
          ) {
            thumbnailUrl = stateImage.thumbnailUrl;
          }
          userTeamImage[id] = {
            ...image,
            thumbnailUrl,
            preservedThumbUrl
          };
        });
      }

      return { ...previousState, ...userTeamImage };
    }

    case types.ADD_USER_TEAM_IMAGES_PLACEHOLDERS: {
      let previousState = state || {};

      // get the media placeholders
      const { mediaPlaceholders = [] } = action;

      // reduce into object and add isPlaceholder boolean flag
      const userTeamImages = mediaPlaceholders.reduce(
        (allMedia, currentMedia) => ({
          ...allMedia,
          [currentMedia.mediaId]: {
            ...currentMedia,
            isPlaceholder: true
          }
        }),
        {}
      );

      return { ...previousState, ...userTeamImages };
    }

    case types.USER_TEAM_IMAGE_DELETE_REQUEST: {
      return omit(state, action.request.mediaId);
    }

    case types.USER_TEAM_IMAGES_DELETE_REQUEST: {
      return omit(state, action.extra.mediaIds);
    }

    case types.USER_TEAM_IMAGE_UPDATE_REQUEST: {
      const updatedImage = action.request.body;
      return {
        ...state,
        [updatedImage.mediaId]: updatedImage
      };
    }

    case imageFoldersEntitiesTypes.UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST_SUCCESS: {
      const imageId = action.request.body.id;
      const thumbnailUrl = action.extra.placeholderURL;
      return {
        ...state,
        [imageId]: {
          ...state[imageId],
          isPlaceholder: false,
          thumbnailUrl
        }
      };
    }

    case types.USER_TEAM_IMAGE_UPLOAD_REQUEST_SUCCESS:
    case types.USER_TEAM_IMAGE_UPDATE_REQUEST_SUCCESS: {
      const updatedImage = { ...action.response };

      if (updatedImage && updatedImage.media && !updatedImage.media.priority) {
        updatedImage.media.priority = null;
      }

      const thumbnailUrl =
        action.extra?.placeholderURL || updatedImage.thumbnailUrl;

      return {
        ...state,
        [updatedImage.mediaId]: {
          ...updatedImage,
          thumbnailUrl,
          preservedThumbUrl: updatedImage.thumbnailUrl
        }
      };
    }

    case imageFoldersEntitiesTypes.ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST_SUCCESS: {
      return omit(state, action.request.body.contentId);
    }

    default:
      return state;
  }
};

export default userTeamImagesReducers;
