import React from "react";

import SubHeaderTab from "./SubHeaderTab";

import style from "./style.module.css";

/**
 * @desc - takes a tabs object and adds isSelected and lastWasSelected based on the current window location
 * @param {object} tabs - the reference objects for the tabs to process
 * @param {string} tabs.url - the url link for the tab, if necessary (some tabs may not be links)
 * @returns {[object]} - returns the tabs object converted to an array with the lastWasSelected and isSelected keys appended to each
 */
const processTabSelection = tabs => {
  const pathName = window.location.pathname;

  const processedTabs = Object.values(tabs).reduce((previous, current) => {
    // tabs can pass an array of alternate Urls for them to show as active for
    const matchesAltUrl = current.altUrls
      ? current.altUrls.reduce(
          (previousValue, altUrl) => previousValue || pathName.includes(altUrl),
          false
        )
      : false;
    const isSelected = pathName.includes(current.url) || matchesAltUrl;
    current.isSelected = isSelected;
    if (!isSelected && previous.length) {
      current.lastWasSelected = previous[previous.length - 1].isSelected;
    }
    return previous.concat([current]);
  }, []);

  return processedTabs;
};

export const SubHeaderTabs = ({ tabs }) => {
  /* note: processTabSelection converts tabs object into an array of tabs */
  const processedTabs = processTabSelection(tabs);

  return (
    <div className={style.subHeaderTabsWrapper} data-testid="SubHeaderTabs">
      {processedTabs.map(tab => (
        <SubHeaderTab tab={tab} key={tab.title} />
      ))}
    </div>
  );
};

SubHeaderTabs.displayName = "SubHeaderTabs";

export default SubHeaderTabs;
