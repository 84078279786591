import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import Loading from "../loading";
import dateFns from "date-fns";
import { listenForImageSrc } from "lib/images/images";

const defaultLoader = () => (
  <div className={style.loader}>
    <Loading />
  </div>
);

class Image extends Component {
  static defaultProps = {
    fallbackImageSrc: ""
  };
  constructor(props) {
    super(props);

    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this);
    this.useFallbackImage = this.useFallbackImage.bind(this);
    this.imageRef = React.createRef();

    this.state = {
      loaded: false,
      useFallback: false,
      imageReference: null,
      isLandscape: false,
      isFirstRender: true,
      mountTime: Date.now(),
      loadRetries: 0
    };
  }

  componentDidMount() {
    const img = document.createElement("img");

    img.src = this.getImageUrl();

    this.setState({
      imageReference: img
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { imageReference } = this.state;
    if (
      !prevState.loaded &&
      this.state.loaded &&
      imageReference &&
      imageReference.naturalWidth
    ) {
      this.setState({
        isLandscape:
          imageReference.naturalWidth > imageReference.naturalHeight ||
          this.props.isLandscape
      });
    }
    if (
      this.state.isFirstRender &&
      dateFns.differenceInMilliseconds(Date.now(), this.state.mountTime) > 500
    ) {
      this.setState({
        isFirstRender: false
      });
    }
    if (!this.props.src && !this.state.useFallback) {
      this.useFallbackImage();
    }
  }

  handleImageLoaded() {
    const { onImageLoaded } = this.props;

    if (onImageLoaded) onImageLoaded();

    this.setState({
      loaded: true
    });
  }

  async useFallbackImage() {
    const { retries = 0, fallbackImageSrc } = this.props;
    if (this.props.src === "loading") {
      return;
    }

    // apply an image src listener so we can retry this image src
    await listenForImageSrc(
      this.getImageUrl(),
      retries,
      fallbackImageSrc
    ).catch(err => {
      if (err) {
        this.setState({
          loaded: true,
          useFallback: true
        });
      }
    });
  }

  getImageUrl() {
    if (this.state.useFallback && this.props.fallbackImageSrc) {
      return this.props.fallbackImageSrc;
    }

    return this.props.src;
  }

  render() {
    const {
      alt = "",
      backgroundColor,
      borderColor,
      borderRadius,
      className,
      fillContainer,
      filter,
      height,
      isWantingCheckeredBackground = false,
      left,
      onClick,
      top,
      width,
      noBorders
    } = this.props;

    const { loaded, isLandscape } = this.state;

    const Loader = this.props.loader || defaultLoader;

    const isLoading =
      !this.state.isFirstRender && (!loaded || this.props.src === "loading");

    const styles = {
      backgroundColor,
      borderColor,
      borderRadius,
      height: height,
      width: width
    };

    if (noBorders) {
      styles.border = "none";
    }

    if (this.state.isFirstRender && !loaded) {
      styles.opacity = 0;
    }

    const imageStyles = {
      borderRadius,
      display: !isLoading ? "block" : "none",
      filter,
      height: isLandscape ? "auto" : "100%",
      left: left || 0,
      objectFit: fillContainer ? "cover" : "contain",
      top: top || 0,
      width: isLandscape ? "100%" : "auto"
    };

    if (isWantingCheckeredBackground) {
      imageStyles.backgroundImage =
        "linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF), linear-gradient(45deg, #DFDFDFBF 25%, #DFDFDF00 25%, #DFDFDF00 75%, #DFDFDFBF 75%, #DFDFDFBF)";
    }

    return (
      <div
        className={`${style.wrapper} ${className}`}
        onClick={onClick}
        style={styles}
        data-is-landscape={isLandscape}
      >
        {isLoading && <Loader />}
        <img
          src={this.getImageUrl()}
          style={imageStyles}
          alt={alt}
          className={style.image}
          onLoad={this.handleImageLoaded}
          onError={this.useFallbackImage}
          ref={this.imageRef}
        />
      </div>
    );
  }
}

Image.propTypes = {
  src: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  className: PropTypes.string,
  fillContainer: PropTypes.bool,
  onClick: PropTypes.func,
  alt: PropTypes.string
};

export default Image;
