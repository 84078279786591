import * as types from "./teamImagesTypes";

import { merge } from "lib";

export const initState = {};

const teamImagesReducers = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_IMAGES_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;
      return merge({}, state, entities.teamImage);
    }

    default:
      return state;
  }
};

export default teamImagesReducers;
