import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

import { RoundCancelButton } from "../../components";

const CircleButton = ({
  backgroundColor,
  borderColor,
  children,
  className,
  disabled = false,
  height,
  noBorder,
  onClick,
  onCancelClick,
  displayCancelButton = false,
  width
}) => {
  const styles = {
    backgroundColor,
    borderColor: noBorder ? "transparent" : borderColor,
    height: height,
    width: width
  };

  return (
    <div
      className={`${style.wrapper} ${className}`}
      onClick={onClick}
      disabled={disabled}
      style={styles}
    >
      {displayCancelButton && (
        <RoundCancelButton
          className={style.cancelIcon}
          onClick={onCancelClick}
        />
      )}
      {children}
    </div>
  );
};

CircleButton.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  noBorder: PropTypes.bool,
  onClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  displayCancelButton: PropTypes.bool,
  width: PropTypes.string
};

export default CircleButton;
