export const PERSONAL_ORGANISATION_CREATE_REQUEST =
  "API/ENTITIES/PERSONAL_ORGANISATION/CREATE_REQUEST";
export const PERSONAL_ORGANISATION_CREATE_REQUEST_SUCCESS =
  "API/ENTITIES/PERSONAL_ORGANISATION/CREATE_REQUEST_SUCCESS";
export const PERSONAL_ORGANISATION_CREATE_REQUEST_FAILURE =
  "API/ENTITIES/PERSONAL_ORGANISATION/CREATE_REQUEST_FAILURE";
export const ORGANISATION_CREATE_REQUEST =
  "API/ENTITIES/ORGANISATION/CREATE_REQUEST";
export const ORGANISATION_CREATE_REQUEST_SUCCESS =
  "API/ENTITIES/ORGANISATION/CREATE_REQUEST_SUCCESS";
export const ORGANISATION_CREATE_REQUEST_FAILURE =
  "API/ENTITIES/ORGANISATION/CREATE_REQUEST_FAILURE";
