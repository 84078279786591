import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const MoveIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color || easilGrayXDark}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.7"
    >
      <path d="M8.2 11.2l-1.5 1.4-1.5-1.4M11 8.4l1.4-1.5L11 5.4M2.4 8.4L1 6.9l1.4-1.5M8 2.4L6.5 1 5 2.4" />
    </g>
  </svg>
);
MoveIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default MoveIcon;
