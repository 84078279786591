export const UPDATE_REQUEST = "API/COLLECTION/UPDATE_REQUEST";
export const UPDATE_REQUEST_FAILURE = "API/COLLECTION/UPDATE_REQUEST_FAILURE";
export const UPDATE_REQUEST_SUCCESS = "API/COLLECTION/UPDATE_REQUEST_SUCCESS";

// APPROVAL REQUIRED
export const REQUIRES_APPROVAL_UPDATE_REQUEST =
  "API/COLLECTION/REQUIRES_APPROVAL_UPDATE_REQUEST";
export const REQUIRES_APPROVAL_UPDATE_REQUEST_FAILURE =
  "API/COLLECTION/REQUIRES_APPROVAL_UPDATE_REQUEST_FAILURE";
export const REQUIRES_APPROVAL_UPDATE_REQUEST_SUCCESS =
  "API/COLLECTION/REQUIRES_APPROVAL_UPDATE_REQUEST_SUCCESS";

// COPY TO DRAFT
export const COPY_TO_DRAFT_REQUEST = "API/COLLECTION/COPY_TO_DRAFT_REQUEST";
export const COPY_TO_DRAFT_REQUEST_FAILURE =
  "API/COLLECTION/COPY_TO_DRAFT_REQUEST_FAILURE";
export const COPY_TO_DRAFT_REQUEST_SUCCESS =
  "API/COLLECTION/COPY_TO_DRAFT_REQUEST_SUCCESS";
