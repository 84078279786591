import {
  getPreviousSiblings,
  getNextSiblings,
  isNodeWithinSelectionRange
} from "lib/DOMNodeUtils";

/**
 * @desc copies the classes from one UL node to another
 * @param {HTMLElement} sourceUl - the UL node to copy the classes from
 * @param {HTMLElement} targetUl - the UL node to apply the classes to
 */
const cloneUlClasses = (sourceUl, targetUl) => {
  const ulClasses = sourceUl
    .getAttribute("class")
    ?.split(" ")
    ?.filter(className => className.length > 0);
  if (ulClasses && ulClasses.length > 0) {
    targetUl.classList.add(...ulClasses);
  }
};

const getClosestListNode = node => {
  const parentNode = node.parentNode;

  if (parentNode && parentNode.nodeName === "UL") {
    // the parent is the closest ul
    return parentNode;
  } else if (parentNode && ["LI", "SPAN"].includes(parentNode.nodeName)) {
    // we can get the closest list from here
    return parentNode.closest("UL");
  }
};

export const indentNodes = ({
  nodes,
  targetContainer,
  isReplace,
  markerType
}) => {
  const items = [];

  // map through the child nodes and apply indentation wrapper
  nodes.forEach(node => {
    let newItems = [];
    switch (node.nodeName) {
      case "SPAN": {
        const ulNode = document.createElement("ul");
        const liNode = document.createElement("li");

        if (node.nextSibling && node.nextSibling.nodeName === "BR") {
          node.nextSibling.remove();
        }

        liNode.appendChild(node.cloneNode(true));
        ulNode.appendChild(liNode);

        // take the classes from the span and add them to the ul
        cloneUlClasses(node, ulNode);
        // add our marker class
        ulNode.classList.add(markerType);

        newItems.push(ulNode);
        break;
      }
      case "#text": {
        const closestUlNode = getClosestListNode(node);
        const ulNode = document.createElement("ul");
        const liNode = document.createElement("li");

        if (node.nextSibling && node.nextSibling.nodeName === "BR") {
          node.nextSibling.remove();
        }

        liNode.appendChild(node.cloneNode(true));
        ulNode.appendChild(liNode);

        if (!!closestUlNode && !markerType) {
          // copy classes when in a list with no marker to override with
          cloneUlClasses(closestUlNode, ulNode);
        } else if (!!markerType) {
          // add markerType class if it is provided
          ulNode.classList.add(markerType);
        }

        newItems.push(ulNode);
        break;
      }
      case "LI": {
        const closestUlNode = getClosestListNode(node);
        const ulNode = document.createElement("ul");
        ulNode.appendChild(node.cloneNode(true));

        if (!!closestUlNode && !markerType) {
          // copy classes when in a list with no marker to override with
          cloneUlClasses(closestUlNode, ulNode);
        } else if (!!markerType) {
          // add markerType class if it is provided
          ulNode.classList.add(markerType);
        }

        newItems.push(ulNode);
        break;
      }
      default: {
        // anything other than text and spans should just be returned as we cannot indent it
        newItems.push(node.cloneNode(true));
      }
    }

    // add the item to our item list
    items.concat(newItems);

    if (isReplace && node.isConnected) {
      const nodeParent = node.parentNode;
      // insert the new elements
      newItems.forEach(newItem => {
        nodeParent.insertBefore(newItem, node);
      });
      // remove the original node
      node.remove();
    }

    if (targetContainer) {
      // when provided a target container we should append to this
      newItems.forEach(newItem => {
        targetContainer.appendChild(newItem);
      });
    }
  });

  // return the list of items regardless of if they were applied to a target container or not
  return items;
};

export const findFullySelectedULs = (selectedNodes, rootNode = document) => {
  const treeWalker = document.createTreeWalker(
    rootNode,
    NodeFilter.SHOW_ELEMENT
  );

  const isFullySelected = element => {
    const range = document.createRange();
    range.selectNodeContents(element);
    return (
      selectedNodes.some(node => range.intersectsNode(node)) &&
      range.toString() === element.innerText
    );
  };

  const selectedULs = [];

  let node = treeWalker.nextNode();
  while (node) {
    if (node.nodeName === "UL" && isFullySelected(node)) {
      selectedULs.push(node);
    }
    node = treeWalker.nextNode();
  }

  return selectedULs;
};

/**
 * @desc finds common UL ancestors in an array of nodes
 * @param {[HTMLElement]} nodes
 * @returns {Set<HTMLElement>}
 */
export const findCommonULAncestors = nodes => {
  const commonAncestors = new Set();
  for (let i = 0; i < nodes.length; i++) {
    let ancestor = nodes[i].parentNode;
    while (ancestor) {
      if (nodes.includes(ancestor)) {
        break;
      }
      ancestor = ancestor.parentNode;
    }
    if (ancestor && ancestor.nodeName === "UL") {
      commonAncestors.add(ancestor);
    }
  }
  return commonAncestors;
};

/**
 * @desc gets all nodes in the direct path between a descendent node and its given ancestor
 * @param {HTMLNode} descendent - the descendent node to start at
 * @param {HTMLNode} ancestor - the ancestor node to path towards and stop at
 * @returns {[HTMLNode]} an array of HTML nodes between the descendent and ancestor
 */
export const getNodesToAncestor = (descendent, ancestor) => {
  const middleNodes = [];
  let currentNode = descendent;
  while (!currentNode.isSameNode(ancestor) && currentNode.parentNode) {
    middleNodes.push(currentNode);
    currentNode = currentNode.parentNode;
  }
  return middleNodes;
};

/**
 * @desc removes one level of indentation on the given text node element
 * @param {HTMLElement} node - The node that we are wanting to unindent
 * @param {HTMLElement} rootNode - The root node to use as our boundary
 * @returns {null}
 */
export const unIndentNode = (node, rootNode) => {
  // just unindent the one node
  const nodesToAncestor = getNodesToAncestor(node, rootNode);
  const ancestorLIs = nodesToAncestor.filter(
    ancestor => ancestor.nodeName === "LI"
  );
  const ancestorULs = nodesToAncestor.filter(
    ancestor => ancestor.nodeName === "UL"
  );

  if (!ancestorLIs.length || !ancestorULs.length) return;

  const firstLI = ancestorLIs[0];
  const firstUL = ancestorULs[0];

  const previousSiblings = getPreviousSiblings(firstLI).filter(
    node =>
      node.nodeName !== "#text" ||
      node.nodeValue.replace(/\u00a0/g, "x").trim().length !== 0
  );
  const nextSiblings = getNextSiblings(firstLI).filter(
    node =>
      node.nodeName !== "#text" ||
      node.nodeValue.replace(/\u00a0/g, "x").trim().length !== 0
  );

  const ulParent = firstUL.parentNode;
  const firstUlNextSibling = firstUL.nextSibling;
  if (previousSiblings.length && nextSiblings.length) {
    // we have siblings on both sides
    const newUL = document.createElement("UL");
    cloneUlClasses(firstUL, newUL);
    nextSiblings.forEach(sibling => {
      newUL.appendChild(sibling);
    });

    if (firstUlNextSibling) {
      // there is a node to use insertBefore on
      ulParent.insertBefore(firstLI, firstUlNextSibling);
      ulParent.insertBefore(newUL, firstUlNextSibling);
    } else {
      ulParent.appendChild(firstLI);
      ulParent.appendChild(newUL);
    }
  } else if (previousSiblings.length && !nextSiblings.length) {
    // only siblings before
    if (firstUlNextSibling) {
      // there is a node to use insertBefore on
      ulParent.insertBefore(firstLI, firstUlNextSibling);
    } else {
      ulParent.appendChild(firstLI);
    }
  } else {
    const newUL = document.createElement("UL");
    cloneUlClasses(firstUL, newUL);
    nextSiblings.forEach(sibling => {
      newUL.appendChild(sibling);
    });

    firstLI.appendChild(newUL);

    // only siblings after
    // ulGrandparent.insertBefore(firstLI, ulParent);
    if (firstUlNextSibling) {
      // there is a node to use insertBefore on
      ulParent.insertBefore(firstLI, firstUlNextSibling);
    } else {
      ulParent.appendChild(firstLI);
    }
  }

  const firstULChildren = Array.from(firstUL.childNodes).filter(
    node =>
      node.nodeName !== "#text" ||
      node.nodeValue.replace(/\u00a0/g, "x").trim().length !== 0
  );
  if (firstULChildren.length === 0) {
    firstUL.remove();
  }

  // check if there is more than one UL ancestor
  if (ancestorULs.length === 1) {
    // we need to remove the content of the LI as well
    const children = Array.from(firstLI.childNodes);
    children.forEach(child => {
      firstLI.parentNode.insertBefore(child, firstLI);
    });
    firstLI.remove();
  }
};

export const unIndentNodes = ({ nodes, rootNode }) => {
  // remove any divs since we don't want to handle those
  let _nodes = nodes.filter(node => node.nodeName !== "DIV");

  if (_nodes.length === 1) {
    return unIndentNode(_nodes[0], rootNode);
  } else {
    return;
  }
  // MULTI-LINE unindent not currently supported
  // // Collect all unique common ancestor elements of selected nodes
  // let commonAncestors = findCommonULAncestors(_nodes);

  // // when we have no commonAncestors found
  // if (commonAncestors.size === 0) {
  //   let nodesToRoot = [];
  //   nodes.forEach(node => {
  //     nodesToRoot = nodesToRoot.concat(getNodesToAncestor(node, rootNode));
  //   });
  //   commonAncestors = findCommonULAncestors(nodesToRoot);
  //   _nodes = _nodes.concat(nodesToRoot);
  // }

  // // Remove any common ancestors that are descendants of other common ancestors
  // commonAncestors.forEach(commonAncestor => {
  //   const isDescendentOfAnother = commonAncestors.some(
  //     ancestor =>
  //       ancestor.contains(commonAncestor) &&
  //       !ancestor.isSameNode(commonAncestor)
  //   );

  //   if (
  //     commonAncestors.has(commonAncestor.parentNode) ||
  //     commonAncestor.nodeName === "DIV" ||
  //     isDescendentOfAnother
  //   ) {
  //     commonAncestors.delete(commonAncestor);
  //   }
  // });

  // // Traverse each common ancestor subtree and adjust nesting of selected nodes
  // commonAncestors.forEach(commonAncestor => {
  //   const treeWalker = document.createTreeWalker(
  //     commonAncestor,
  //     NodeFilter.SHOW_ELEMENT
  //   );
  //   let node = treeWalker.nextNode();
  //   let isLoopBreaking = false;
  //   while (node && !isLoopBreaking) {
  //     if (_nodes.includes(node)) {
  //       // Move node to the next higher level in the list hierarchy
  //       const parent = node.parentNode;
  //       if (
  //         parent &&
  //         (parent.nodeName === "UL" || parent.nodeName === "OL") &&
  //         commonAncestors.some(ancestor => ancestor.isSameNode(parent))
  //       ) {
  //         const grandparent = parent.parentNode;
  //         // loop through all the children of the parent and move them
  //         const children = Array.from(parent.children);
  //         for (let i = 0; i < children.length; i++) {
  //           const child = children[i];
  //           grandparent.insertBefore(child, parent);
  //           if (
  //             grandparent &&
  //             grandparent.nodeName !== "UL" &&
  //             grandparent.nodeName !== "LI" &&
  //             child.nodeName === "LI"
  //           ) {
  //             // grandparent is not a list and this child is a LI
  //             // extract children
  //             const liChildren = Array.from(child.childNodes);
  //             let previousChild;
  //             liChildren.forEach(currentChild => {
  //               // add a new break in front of the item when there is no previous child or both are text
  //               if (
  //                 !previousChild ||
  //                 (previousChild.nodeName === "#text" &&
  //                   currentChild.nodeName === "#text")
  //               ) {
  //                 // add a break
  //                 grandparent.insertBefore(document.createElement("BR"), child);
  //               }
  //               // add the node to the grandparent
  //               grandparent.insertBefore(currentChild, child);
  //               previousChild = currentChild;
  //             });

  //             // we need a break afterwards if the next sibling is a text node and our last child was not a UL node
  //             if (
  //               parent.nextSibling &&
  //               parent.nextSibling.nodeName === "#text" &&
  //               liChildren[liChildren.length - 1].nodeName !== "UL"
  //             ) {
  //               // add a break
  //               grandparent.insertBefore(document.createElement("BR"), child);
  //             }
  //             // remove LI
  //             child.remove();
  //           } else if (grandparent && grandparent.nodeName === "LI") {
  //             // grandparent is a LI so we need to insert as a sibling of it instead
  //             grandparent.parentNode.insertBefore(child, grandparent);
  //           }
  //         }
  //         // Remove empty list elements
  //         if (parent.childNodes.length === 0) {
  //           parent.parentNode.removeChild(parent);
  //           isLoopBreaking = true;
  //           break;
  //         }
  //       }
  //     }
  //     node = treeWalker.nextNode();
  //   }
  // });
};

/**
 * @desc moves a textNode from a list and adds it as a child of its previous sibling
 * @param {HTMLNode} textNode - the text node
 * @param {HTMLNode} liNode - the list item our node belongs to
 * @returns null
 */
export const nestListInPreviousSibling = (textNode, liNode) => {
  const previousLiNode = liNode.previousSibling;

  // need to check if the previous list item already has a ul in it
  const ulInPreviousNode = previousLiNode.querySelector("ul");

  if (ulInPreviousNode) {
    // just add the li to this
    ulInPreviousNode.appendChild(liNode);
    return;
  }
  const ulNode = document.createElement("ul");
  const closestUlNode = liNode.closest("ul");
  cloneUlClasses(closestUlNode, ulNode);
  ulNode.appendChild(liNode);

  previousLiNode.appendChild(ulNode);
  return;
};

/**
 * @desc completely removes all indentation from the selection regardless of level
 * @param {HTMLNode} nodes - The HTML nodes in the selection
 */
export const removeIndentation = (nodes, rootNode) => {
  // const nodesInSelectionRange = getNodesInSelectionRange();
  const liNodes = [];
  const ulNodes = [];

  let allAncestorNodes = [];

  nodes.forEach(node => {
    const ancestorNodes = getNodesToAncestor(node, rootNode);
    allAncestorNodes = allAncestorNodes.concat(ancestorNodes);
    if (node.nodeName === "LI") {
      liNodes.push(node);
    }
  });

  const selectedAncestors = [];

  allAncestorNodes.forEach(ancestor => {
    if (
      // all content selected
      Array.from(ancestor.childNodes).every(child => nodes.includes(child))
    ) {
      selectedAncestors.push(ancestor);
      if (
        // is an LI
        ancestor.nodeName === "LI" &&
        // not already included
        !nodes.includes(ancestor)
      ) {
        liNodes.push(ancestor);
      }
    }
  });

  // find UL parents for our li nodes
  liNodes.forEach(liNode => {
    const parent = liNode.parentNode;
    if (
      parent &&
      parent.nodeName === "UL" &&
      !ulNodes.some(node => node.isSameNode(parent))
    ) {
      ulNodes.push(parent);
    }
  });

  // find parent UL nodes that have all of their children in the selection
  ulNodes.forEach(parent => {
    const children = Array.from(parent.childNodes);
    const selectedChildren = children.filter(
      child =>
        nodes.includes(child) ||
        selectedAncestors.includes(child) ||
        isNodeWithinSelectionRange(child)
    );
    const grandparent = parent.parentNode;

    const previousSiblings = getPreviousSiblings(selectedChildren[0]).filter(
      node =>
        node.nodeName !== "#text" ||
        node.nodeValue.replace(/\u00a0/g, "x").trim().length !== 0
    );
    const nextSiblings = getNextSiblings(
      selectedChildren[selectedChildren.length - 1]
    ).filter(
      node =>
        node.nodeName !== "#text" ||
        node.nodeValue.replace(/\u00a0/g, "x").trim().length !== 0
    );

    const isMiddle = nextSiblings.length && previousSiblings.length;
    const isAfter = !nextSiblings.length && previousSiblings.length;
    const isBefore = nextSiblings.length && !previousSiblings.length;
    const isOnly = !isMiddle && !isAfter && !isBefore;

    if (isOnly) {
      selectedChildren.forEach(child => {
        const grandchildren = Array.from(child.childNodes);
        const selectedGrandchildren = grandchildren.filter(
          grandchild =>
            nodes.includes(grandchild) ||
            selectedAncestors.includes(grandchild) ||
            isNodeWithinSelectionRange(grandchild)
        );
        selectedGrandchildren.forEach(grandchild => {
          if (
            parent.previousSibling &&
            parent.previousSibling.nodeName === "#text"
          ) {
            grandparent.insertBefore(document.createElement("BR"), parent);
          }
          grandparent.insertBefore(grandchild, parent);
          if (
            parent.nextSibling &&
            ["#text", "BR"].includes(parent.nextSibling.nodeName)
          ) {
            grandparent.insertBefore(document.createElement("BR"), parent);
          }
        });

        if (child.childNodes.length === 0) {
          child.remove();
        }
      });
    } else if (isBefore) {
      selectedChildren.forEach(child => {
        const grandchildren = Array.from(child.childNodes);
        const selectedGrandchildren = grandchildren.filter(
          grandchild =>
            nodes.includes(grandchild) ||
            selectedAncestors.includes(grandchild) ||
            isNodeWithinSelectionRange(grandchild)
        );
        selectedGrandchildren.forEach(grandchild => {
          if (
            parent.previousSibling &&
            parent.previousSibling.nodeName === "#text"
          ) {
            grandparent.insertBefore(document.createElement("BR"), parent);
          }
          grandparent.insertBefore(grandchild, parent);
        });

        if (child.childNodes.length === 0) {
          child.remove();
        }
      });
    } else if (isAfter) {
      const parentNextSibling = parent.nextSibling;
      selectedChildren.forEach(child => {
        const grandchildren = Array.from(child.childNodes);
        const selectedGrandchildren = grandchildren.filter(
          grandchild =>
            nodes.includes(grandchild) ||
            selectedAncestors.includes(grandchild) ||
            isNodeWithinSelectionRange(grandchild)
        );
        selectedGrandchildren.forEach(grandchild => {
          if (parentNextSibling) {
            grandparent.insertBefore(grandchild, parentNextSibling);
            if (parentNextSibling.nodeName === "#text") {
              grandparent.insertBefore(
                document.createElement("BR"),
                parentNextSibling
              );
            }
          } else {
            if (
              parent.previousSibling &&
              parent.previousSibling.nodeName === "#text"
            ) {
              grandparent.appendChild(document.createElement("BR"));
            }
            grandparent.appendChild(grandchild);
          }
        });

        if (child.childNodes.length === 0) {
          child.remove();
        }
      });
    } else if (isMiddle) {
      const parentNextSibling = parent.nextSibling;
      const newUL = document.createElement("UL");
      cloneUlClasses(parent, newUL);
      nextSiblings.forEach(sibling => {
        newUL.appendChild(sibling);
      });
      selectedChildren.forEach(child => {
        const grandchildren = Array.from(child.childNodes);
        const selectedGrandchildren = grandchildren.filter(
          grandchild =>
            nodes.includes(grandchild) ||
            selectedAncestors.includes(grandchild) ||
            isNodeWithinSelectionRange(grandchild)
        );
        selectedGrandchildren.forEach(grandchild => {
          if (parentNextSibling) {
            grandparent.insertBefore(grandchild, parentNextSibling);
            if (parentNextSibling.nodeName === "#text") {
              grandparent.insertBefore(
                document.createElement("BR"),
                parentNextSibling
              );
            }
          } else {
            if (
              parent.previousSibling &&
              parent.previousSibling.nodeName === "#text"
            ) {
              grandparent.appendChild(document.createElement("BR"));
            }
            grandparent.appendChild(grandchild);
          }
        });

        if (child.childNodes.length === 0) {
          child.remove();
        }
      });
      if (parentNextSibling) {
        grandparent.insertBefore(newUL, parentNextSibling);
      } else {
        grandparent.appendChild(newUL);
      }
    }

    if (parent.childNodes.length === 0) {
      // this ul node is empty now so we can remove it
      parent.remove();
    }
  });
};

export const getNodesInSelectionRange = () => {
  const selection = window.getSelection(); // Get the current selection object
  if (selection.rangeCount === 0) {
    return []; // No selection range
  }

  const range = selection.getRangeAt(0); // Get the first range of the selection
  const nodes = []; // Array to store the selected nodes

  // Traverse all child nodes within the range's common ancestor container
  const traverseNodes = node => {
    if (isNodeWithinSelectionRange(node)) {
      nodes.push(node);
    }
    if (node.childNodes && node.childNodes.length > 0) {
      node.childNodes.forEach(childNode => traverseNodes(childNode));
    }
  };
  traverseNodes(range.commonAncestorContainer);

  return nodes;
};
