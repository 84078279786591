import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const InfoIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  tipText,
  tipFor,
  isTipHtml,
  href
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-tip={tipText}
    data-for={tipFor}
    data-html={isTipHtml}
    data-testid="InfoIcon"
    onClick={() => {
      if (Boolean(href)) {
        window.open(href, "_blank").focus();
      }
    }}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <path
        fill={color || easilGrayXDark}
        d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm0-9a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
      />
    </g>
  </svg>
);
InfoIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default InfoIcon;
