import { first, map } from "lib/lodash";
var esprima = require("esprima");

export const ensure = (key, value) => {
  if (value === undefined || value === null) {
    throw new Error(`argument '${key}' is required and was missing`);
  }
};

/**
 * @see {@link https://stackoverflow.com/questions/1007981/how-to-get-function-parameter-names-values-dynamically}
 * @param {function} func - the function to parse argument keys for
 * @returns {String[]} an array of argument key strings
 */
export const parseFunctionArguments = func => {
  // allows us to access properties that may or may not exist without throwing
  // TypeError: Cannot set property 'x' of undefined
  const maybe = x => x || {};

  // handle conversion to string and then to JSON AST
  const functionAsString = func.toString();
  const tree = esprima.parse(functionAsString);
  // We need to figure out where the main params are. Stupid arrow functions 👊
  const isArrowExpression =
    maybe(first(tree.body)).type === "ExpressionStatement";
  const params = isArrowExpression
    ? maybe(maybe(first(tree.body)).expression).params
    : maybe(first(tree.body)).params;

  // extract out the param names from the JSON AST
  return map(params, "name");
};

/**
 * @desc ensures values for the given function arguments are not undefined or null
 * @param {function} func - the function the arguments are for
 * @param {[]} args - arguments provided
 * @param {function} [filter] - an optional filter for argument keys
 */
export const ensureArguments = (func, args, filter = x => x) => {
  // get the argument keys for the function
  const argumentKeys = parseFunctionArguments(func);

  // map key-value for arguments
  const argsMap = {};
  argumentKeys.forEach((argKey, index) => {
    argsMap[argKey] = args[index];
  });

  // apply filter function to keys
  const filteredArgKeys = argumentKeys.filter(filter);

  // ensure all expected arguments have values
  filteredArgKeys.forEach(argKey => ensure(argKey, argsMap[argKey]));
};
