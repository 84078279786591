import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const TeamSignupPage = lazy(() => import("./TeamSignupPage"));

export const TeamSignupPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <TeamSignupPage {...props} />
  </Suspense>
);
TeamSignupPageLazy.displayName = "SignupPage";

export default TeamSignupPageLazy;
