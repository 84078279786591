import { moveItem } from "lib/array/array";

const DesignLayerOps = {
  changeElementPosition({ elementId, groupId, pageId, moveAction }) {
    let updatedDesignData = this;

    if (groupId) {
      updatedDesignData = this.changeElementPositionInGroup({
        elementId,
        groupId,
        moveAction
      });
    } else {
      updatedDesignData = this.changeElementPositionInPage({
        elementId,
        pageId,
        moveAction
      });
    }

    // check if the element is a background element
    const element = this.getElement(elementId);
    if (element.type === "background") {
      // Background element should be set to foreground
      const designWithRemovedBackground = this.setElementAsForeground(
        elementId
      );

      // we should keep the updated background element but use page and group ordering from the move function
      updatedDesignData = new this.constructor({
        ...updatedDesignData,
        elements: {
          ...updatedDesignData.elements,
          [elementId]: designWithRemovedBackground.elements[elementId]
        },
        version: this.version + 1
      });
    }

    return updatedDesignData;
  },

  changeElementPositionInGroup({ elementId, groupId, moveAction }) {
    const group = this.getElement(groupId);

    const elementIndex = group.elementsOrder.indexOf(elementId);

    const newIndex = (() => {
      switch (moveAction) {
        case "moveToBottom":
          return 0;
        case "moveDown":
          return elementIndex - 1;
        case "moveToTop":
          return group.elementsOrder.length - 1;
        case "moveUp":
          return elementIndex + 1;
        default:
          throw new Error("INVALID MOVE");
      }
    })();

    const groupElementsOrderUpdated = moveItem(
      group.elementsOrder,
      elementIndex,
      newIndex
    );

    const elementsUpdated = {
      ...this.elements,
      [groupId]: {
        ...group,
        elementsOrder: groupElementsOrderUpdated
      }
    };

    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  },

  changeElementPositionInPage({ elementId, pageId, moveAction }) {
    const page = this.pages[pageId];

    const elementIndex = page.elementsOrder.indexOf(elementId);

    const newIndex = (() => {
      switch (moveAction) {
        case "moveToBottom":
          return 0;
        case "moveDown":
          return elementIndex - 1;
        case "moveToTop":
          return page.elementsOrder.length - 1;
        case "moveUp":
          return elementIndex + 1;
        default:
          throw new Error("INVALID MOVE");
      }
    })();

    const pageElementsOrderUpdated = moveItem(
      page.elementsOrder,
      elementIndex,
      newIndex
    );

    const pagesUpdated = {
      ...this.pages,
      [pageId]: {
        ...page,
        elementsOrder: pageElementsOrderUpdated
      }
    };

    return new this.constructor({
      ...this,
      pages: pagesUpdated,
      version: this.version + 1
    });
  }
};

export default DesignLayerOps;
