import * as types from "state/ui/MyAnimations/MyAnimationsTypes";
import { LOCATION_CHANGE } from "react-router-redux";
import * as editorTypes from "state/ui/editorAnimationSearch/editorAnimationSearchTypes";
import * as userAnimationTypes from "state/entities/userTeamAnimations/userTeamAnimationsTypes";
import * as userAnimationFolderTypes from "state/entities/userTeamAnimationsFolders/userTeamAnimationsFoldersTypes";
import { getPath } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
import { getParameterByName } from "lib/queryStringUtils";

export const initState = {
  personal: {
    // This is a hack until editor and workspace search are completely separated
    isWorkspaceSearch: false,
    currentTerm: null,
    terms: {}
  }
};

const myAnimationsReducers = (state = initState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
    case types.CLEAR_SEARCH_TERM: {
      return immutableUpdate(state, {
        personal: {
          isWorkspaceSearch: {
            $set: false
          },
          currentTerm: {
            $set: null
          }
        }
      });
    }
    case types.ANIMATION_SEARCH_REQUEST: {
      const term = action.payload.term;
      const folderId = action.payload.folderId;

      if (folderId) {
        return immutableUpdate(state, {
          personal: {
            isWorkspaceSearch: {
              $set: true
            },
            currentTerm: {
              $set: term
            },
            folders: {
              $auto: {
                [folderId]: {
                  $auto: {
                    terms: {
                      $auto: {
                        [term]: {
                          $auto: {
                            $merge: {
                              isFetching: true
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
      return immutableUpdate(state, {
        personal: {
          isWorkspaceSearch: {
            $set: true
          },
          currentTerm: {
            $set: term
          },
          terms: {
            [term]: {
              $auto: {
                $merge: {
                  isFetching: true
                }
              }
            }
          }
        }
      });
    }

    case editorTypes.EDITOR_ANIMATION_SEARCH_SUCCESS: {
      // TODO: Editor and workspace searches are tightly coupled.
      //  We don't want editor search results here so ignore them
      if (!state.personal.isWorkspaceSearch) {
        return state;
      }
      const ids = new Set([...(action.response.ids || [])]);

      const newEntities = getPath(
        action,
        "response.entities.userTeamSearchedAnimation",
        {}
      );

      if (action.request.params.folderId) {
        return immutableUpdate(state, {
          personal: {
            $auto: {
              folders: {
                $auto: {
                  [action.request.params.folderId]: {
                    $auto: {
                      terms: {
                        $auto: {
                          [action.request.params.term]: {
                            isFetching: { $set: false },
                            didInvalidate: { $set: false },
                            ids: { $set: [...ids] },
                            animations: {
                              $auto: {
                                $set: newEntities
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }

      return immutableUpdate(state, {
        personal: {
          terms: {
            [action.request.params.term]: {
              isFetching: { $set: false },
              didInvalidate: { $set: false },
              ids: { $set: [...ids] },
              animations: {
                $auto: {
                  $set: newEntities
                }
              }
            }
          }
        }
      });
    }

    case userAnimationFolderTypes.REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST_SUCCESS: {
      if (!state.personal.folders) {
        return state;
      }
      if (action.request.extra.term) {
        const ids =
          state.personal.folders[action.request.extra.folderId].terms[
            action.request.extra.term
          ].ids;
        const idToRemove = action.request.extra.contentId;
        const newIds = ids.filter(id => id !== idToRemove);
        return immutableUpdate(state, {
          personal: {
            $auto: {
              folders: {
                $auto: {
                  [action.request.extra.folderId]: {
                    $auto: {
                      terms: {
                        $auto: {
                          [action.request.extra.term]: {
                            isFetching: { $set: false },
                            didInvalidate: { $set: false },
                            ids: { $set: [...newIds] }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return state;
      }
    }

    case userAnimationFolderTypes.BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS: {
      if (!state.personal.folders) {
        return state;
      }

      const searchTerm = getPath(action.request, "extra.term");
      const currentFolderId = getParameterByName(
        "folderId",
        action.queryParams
      );

      if (searchTerm && currentFolderId) {
        const stateIds =
          state.personal.folders[currentFolderId].terms[searchTerm].ids;
        const idsToRemove = action.request.body.mediaIds;
        const newIds = stateIds.filter(id => !idsToRemove.includes(id));

        return immutableUpdate(state, {
          personal: {
            $auto: {
              folders: {
                $auto: {
                  [currentFolderId]: {
                    $auto: {
                      terms: {
                        $auto: {
                          [searchTerm]: {
                            isFetching: { $set: false },
                            didInvalidate: { $set: false },
                            ids: { $set: [...newIds] }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return state;
      }
    }

    case userAnimationTypes.USER_TEAM_VIDEOS_DELETE_REQUEST_SUCCESS: {
      const { mediaIds, folderId } = action.extra;

      let ids;
      let updatedIds;

      if (folderId && !!state.personal.currentTerm) {
        ids = [
          ...(state.personal.folders[folderId].terms[state.personal.currentTerm]
            ?.ids || [])
        ];

        // Filter out ids to be deleted
        updatedIds = ids.filter(id => !mediaIds.includes(id));

        return immutableUpdate(state, {
          personal: {
            $auto: {
              folders: {
                $auto: {
                  [folderId]: {
                    $auto: {
                      terms: {
                        $auto: {
                          [state.personal.currentTerm]: {
                            $auto: {
                              ids: { $set: [...updatedIds] }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }

      ids = [...(state.personal.terms[state.personal.currentTerm]?.ids || [])];

      // Filter out ids to be deleted
      updatedIds = ids.filter(id => !mediaIds.includes(id));

      return immutableUpdate(state, {
        personal: {
          terms: {
            [state.personal.currentTerm]: {
              $auto: {
                ids: { $set: [...updatedIds] }
              }
            }
          }
        }
      });
    }

    case userAnimationTypes.USER_TEAM_ANIMATION_DELETE_REQUEST_SUCCESS: {
      const ids = [
        ...(state.personal.terms[state.personal.currentTerm]?.ids || [])
      ];
      const updatedIds = ids.filter(id => id !== action.request.mediaId);

      return immutableUpdate(state, {
        personal: {
          terms: {
            [state.personal.currentTerm]: {
              $auto: {
                ids: { $set: [...updatedIds] }
              }
            }
          }
        }
      });
    }

    case userAnimationTypes.USER_TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS: {
      // using 'ALL' here as we you can only update media items in the masonry
      const folderId = action.request.body.folderId;
      const term = getParameterByName("term", action.queryParams);

      if (!!folderId && !!term) {
        const currentMediaState =
          state.personal.folders[folderId].terms[term].animations[
            action.response.mediaId
          ];
        const updatedAnimation = {
          ...currentMediaState,
          name: action.response.media.name
        };
        return immutableUpdate(state, {
          personal: {
            folders: {
              $auto: {
                [folderId]: {
                  $auto: {
                    terms: {
                      [term]: {
                        animations: {
                          $auto: {
                            [action.response.mediaId]: {
                              $set: { ...updatedAnimation }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
      return state;
    }

    default:
      return state;
  }
};

export default myAnimationsReducers;
