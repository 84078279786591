import { domParser } from "lib/defaultDomParser";
import { fetchSvgs } from "lib/svg/svg";
import { isHexString } from "lib/parseColor";

import { FILL_VALUE_REGEX, SVG_FILL_SELECTOR } from "lib/constants";

export const fillColors = svg => {
  const fillList = [];
  const elements = svg.querySelectorAll("[id^='change']");

  if (elements.length === 0) return [];

  elements.forEach(element => {
    const fills = element.querySelectorAll("[fill]");

    // if at least one fill colour is found set as default, otherwise set to black
    fillList.push({
      color: fills.length ? fills[0].attributes.fill.value : "#000",
      domId: element.id
    });
  });

  return fillList;
};

export const getClassDefinitionsFromStyleString = styleString => {
  // split by } to get the end of style blocks
  const styleBlocks = styleString.split("}");

  const classDefinitions = {};

  styleBlocks
    .filter(x => x)
    .forEach(styleBlock => {
      const classNameSections = styleBlock.split("{")[0];

      const classNames = classNameSections
        .split(",")
        .map(className => className.replace(".", "").trim());

      const bracketSplitBlock = styleBlock.split("{");
      const fillColorMatch =
        bracketSplitBlock.length > 1
          ? bracketSplitBlock[1].match(FILL_VALUE_REGEX)
          : [];
      const classFillColor =
        fillColorMatch && fillColorMatch[1] ? fillColorMatch[1].trim() : "none";

      if (!isHexString(classFillColor)) {
        return;
      }

      // set the class definition in our definitions object so it is present in the return
      // we can add other attributes to this later for handling of stroke colors
      classNames.forEach(className => {
        classDefinitions[className] = {
          fill: classFillColor
        };
      });
    });

  return classDefinitions;
};

export const fillColorsFromUpload = svg => {
  const fillList = [];
  let fillSections = svg.querySelectorAll(SVG_FILL_SELECTOR);
  const elements = svg.querySelectorAll("[id^='change']");
  if (elements.length > 0) {
    // if the svg already has change sections we should just use those
    return fillColors(svg);
  }

  // set up a generalised index to prevent overlapping color ids between different fill
  // definition methods
  let currentIdIndex = 1;

  const svgStyleElement = svg.querySelector("style");

  if (svgStyleElement) {
    const styleString = svgStyleElement.textContent;

    const classDefinitions = getClassDefinitionsFromStyleString(styleString);

    // go through the class definitions and set the colors for those that match
    if (Object.keys(classDefinitions).length) {
      Object.keys(classDefinitions).forEach(className => {
        const classFillSections = svg.querySelectorAll(
          `*[class="${className}"]`
        );
        classFillSections.forEach(() => {
          if (isHexString(classDefinitions[className].fill)) {
            fillList.push({
              color: classDefinitions[className].fill,
              domId: `change_${currentIdIndex}`
            });
            currentIdIndex++;
          }
        });
      });
    }
  }

  if ((!fillSections || fillSections.length === 0) && !fillList.length) {
    // there is no fillSections from inline style and nothing already in the fillList
    return [];
  }

  // set up automatic fill sections
  fillSections.forEach((fillSection, index) => {
    const styleAttribute = fillSection.getAttribute("style");
    const styleFillMatch = styleAttribute
      ? styleAttribute.match(FILL_VALUE_REGEX)
      : [];
    const styleFillValue =
      styleFillMatch && styleFillMatch.length > 0 ? styleFillMatch[1] : "";
    const fillAttributeValue = fillSection.getAttribute("fill");
    const fillValue = fillAttributeValue || styleFillValue;
    if (isHexString(fillValue)) {
      fillList.push({
        color: fillValue,
        domId: `change_${currentIdIndex}`
      });
      currentIdIndex++;
    }
  });

  return fillList;
};

export const resizableOptions = svg => {
  return {
    resizableX:
      (svg.querySelectorAll("g[id=L]").length === 1 &&
        svg.querySelectorAll("g[id=C]").length === 1 &&
        svg.querySelectorAll("g[id=R]").length === 1) ||
      svg.querySelectorAll("[id=REPEAT_X]").length,
    resizableY:
      (svg.querySelectorAll("g[id=T]").length === 1 &&
        svg.querySelectorAll("g[id=C]").length === 1 &&
        svg.querySelectorAll("g[id=B]").length === 1) ||
      svg.querySelectorAll("[id=REPEAT_Y]").length
  };
};

export const fetchSvgDataFromUrl = async url => {
  const [dataPromise] = await fetchSvgs([url]);

  return await dataPromise;
};

export const formattedDimension = dimension => {
  dimension.replace("px", "");
  return parseFloat(dimension);
};

const parseSvgToElement = async data => {
  const file = await fetchSvgDataFromUrl(data.src);

  const svg = domParser
    .parseFromString(file, "image/svg+xml")
    .querySelector("svg");

  return {
    ...resizableOptions(svg),
    type: "vector",
    id: data.id || data.mediaId,
    fillColors: fillColors(svg),
    name: data.name || "untitled",
    top: data.top || 0,
    left: data.left || 0,
    src: data.src,
    thumbSrc: data.thumbnailUrl || data.src,
    srcHeight: formattedDimension(svg.height.baseVal.valueAsString),
    srcWidth: formattedDimension(svg.width.baseVal.valueAsString),
    height: formattedDimension(svg.height.baseVal.valueAsString),
    width: formattedDimension(svg.width.baseVal.valueAsString)
  };
};

export default parseSvgToElement;
