import React, { Component } from "react";
import style from "./style.module.css";
import { H2, Input, Button, Loading } from "views/components";
import OrderDesignsContainer from "../OrderDesign/OrderDesignsContainer";
import PriceSummaryContainer from "../PriceSummary/PriceSummaryContainer";
import keyCodes from "lib/keyCodes";

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.handleCouponApply = this.handleCouponApply.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      promoCode: null
    };
  }

  handleCouponApply() {
    const { handleAddCouponToOrder, order } = this.props;
    const { promoCode } = this.state;

    handleAddCouponToOrder({ orderId: order.id, coupon: promoCode });
  }

  handleKeyDown(event) {
    if (event.key === "Enter") {
      this.handleCouponApply(event);
    } else if (
      event.keyCode === keyCodes.backspaceKey ||
      event.keyCode === keyCodes.deleteKey
    ) {
      // remove coupon error message when deleting
      this.props.handleResetCouponError();
    }
  }

  handlePromoFormChange(event) {
    const {
      target: { value }
    } = event;

    this.setState({ promoCode: value });
  }

  handleClearInput() {
    this.setState({ promoCode: null });
    this.props.handleResetCouponError();
  }

  getPromoSection() {
    const { promoCode } = this.state;
    const {
      order: { couponCode },
      couponError
    } = this.props;

    return (
      <>
        <div className={style.coupon}>
          <Input
            className={style.couponInput}
            disabled={couponCode}
            value={promoCode || ""}
            onChange={e => this.handlePromoFormChange(e)}
            onKeyDown={e => this.handleKeyDown(e)}
            placeholder="Enter Promo Code"
            isEmptySearch={!promoCode}
            clearInput={() => this.handleClearInput()}
            crossThinIconColor="#afb3b6"
          />
          <Button
            className={style.promoCodeButton}
            theme="grayBackgroundXDarkGrayFont"
            disabled={!promoCode || couponCode || !!couponError}
            onClick={e => this.handleCouponApply(e)}
          >
            Apply
          </Button>
        </div>
        <div
          className={style.couponPromoCodeAlert}
          data-has-error={!!couponError}
        >
          The coupon you entered is invalid
        </div>
      </>
    );
  }

  render() {
    const { loading, order } = this.props;

    if (!order) return null;

    if (loading.order || loading.details || loading.pricing) {
      return (
        <div className={style.loading}>
          <Loading />
        </div>
      );
    }

    return (
      <div className={style.orderSummary}>
        <H2 className={style.orderHeading}>Your Order</H2>
        <div className={style.orderDesigns}>
          <OrderDesignsContainer />
        </div>
        {this.getPromoSection()}
        <PriceSummaryContainer />
      </div>
    );
  }
}

export default OrderSummary;
