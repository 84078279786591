const {
  REACT_APP_ASSET_SERVICE_URL: ASSET_SERVICE_URL,
  REACT_APP_ASSET_SERVICE_URL_V2: ASSET_SERVICE_URL_V2,
  REACT_APP_USER_SERVICE_URL: USER_SERVICE_URL,
  REACT_APP_USER_SERVICE_URL_V2: USER_SERVICE_URL_V2,
  REACT_APP_AUTH_SERVICE_URL: AUTH_SERVICE_URL,
  REACT_APP_SUBSCRIPTION_SERVICE_URL: SUBSCRIPTION_SERVICE_URL,
  REACT_APP_ORDER_SERVICE_URL: ORDER_SERVICE_URL,
  REACT_APP_ORDER_SERVICE_URL_V2: ORDER_SERVICE_URL_V2,
  REACT_APP_RENDER_SERVICE_URL: RENDER_SERVICE_URL,
  REACT_APP_RENDER_SERVICE_URL_V2: RENDER_SERVICE_URL_V2,
  REACT_APP_MEDIA_SERVICE_URL: MEDIA_SERVICE_URL,
  REACT_APP_FONT_HOST_URL: FONT_HOST_URL
} = process.env;

const SERVICES = {
  // V1 SERVICES
  AUTH: AUTH_SERVICE_URL,
  ASSET: ASSET_SERVICE_URL,
  USER: USER_SERVICE_URL,
  SUBSCRIPTION: SUBSCRIPTION_SERVICE_URL,
  ORDER: ORDER_SERVICE_URL,
  RENDER: RENDER_SERVICE_URL,
  MEDIA: MEDIA_SERVICE_URL,
  FONTS: FONT_HOST_URL,
  // V2 SERVICES
  ASSET_V2: ASSET_SERVICE_URL_V2,
  USER_V2: USER_SERVICE_URL_V2,
  ORDER_V2: ORDER_SERVICE_URL_V2,
  RENDER_V2: RENDER_SERVICE_URL_V2
};

export default SERVICES;
