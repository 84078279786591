import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import { getHtmlNodeCenter } from "lib/htmlElements/dimensions";
import { rotatePoint } from "lib/geometry/rotation";
import { getCanvasbackground } from "views/components/Editor/utils";
import style from "views/components/Editor/styleSelectionBox.module.css";

const VideoCroppingHandlerSource = {
  beginDrag(props, monitor, component) {
    props.startCropMaskResize();

    const componentNode = findDOMNode(component);
    const selectionBoxNode = componentNode.parentElement;

    const componentNodeCenter = getHtmlNodeCenter(componentNode);
    const selectionBoxNodeCenter = getHtmlNodeCenter(selectionBoxNode);

    const originalHandlerPosition = rotatePoint(
      componentNodeCenter.x,
      componentNodeCenter.y,
      selectionBoxNodeCenter.x,
      selectionBoxNodeCenter.y,
      -props.videoElement.angle
    );

    const canvasBackground = getCanvasbackground();

    const pageDOMNode = canvasBackground.querySelector(
      `[id="PAGE-${props.pageId}"]`
    );
    const elementDOMNode = pageDOMNode.querySelector(
      `[id="${props.videoElement.uniqueId}"]`
    ).firstChild;
    const elementRect = elementDOMNode.getBoundingClientRect();

    return {
      rotatedHandlerPosition: componentNodeCenter,
      selectionBoxNodeCenter,
      originalHandlerPosition: originalHandlerPosition,
      position: props.position,
      component,
      originalElementRect: elementRect
    };
  },

  endDrag(props, monitor, component) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

    const dragItem = monitor.getItem();

    const { originalHandlerPosition, originalElementRect } = dragItem;

    const updatedElementDOMNode = document.querySelector(
      `#preview-${props.videoElement.uniqueId} > div > div`
    );
    const updatedElementRect = updatedElementDOMNode.getBoundingClientRect();

    const componentNode = findDOMNode(component);
    const selectionBoxNode = componentNode.parentElement;

    const componentNodeCenter = getHtmlNodeCenter(componentNode);
    const selectionBoxNodeCenter = getHtmlNodeCenter(selectionBoxNode);

    const adjustmentOffset = {
      x: originalElementRect.top - updatedElementRect.top,
      y: originalElementRect.left - updatedElementRect.left
    };

    // can get the point the same distance away with the given negative rotation
    const newPoint = rotatePoint(
      componentNodeCenter.x + differenceFromInitialOffset.x, // new point is initial point plus difference
      componentNodeCenter.y + differenceFromInitialOffset.y,
      selectionBoxNodeCenter.x,
      selectionBoxNodeCenter.y,
      -props.videoElement.angle
    );

    // the offset after rotating is the rotated offset minus the original point
    const rotatedOffset = {
      x: newPoint.x - originalHandlerPosition.x,
      y: newPoint.y - originalHandlerPosition.y
    };

    props.finishCropMaskResize({
      differenceFromInitialOffset: rotatedOffset,
      dragItem: monitor.getItem(),
      adjustmentOffset
    });

    return {};
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset()
  };
}

class VideoCroppingHandler extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;

    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  render() {
    const { connectDragSource, position } = this.props;

    return connectDragSource(
      <div
        className={style.croppingDot}
        data-position={position}
        data-is-crop={true}
      >
        <div className={style.lowerHandles}>
          <div className={style.verticalHandle} />
          <div className={style.horizontalHandle} />
        </div>
        <div className={style.upperHandles}>
          <div className={style.verticalHandle} />
          <div className={style.horizontalHandle} />
        </div>
      </div>
    );
  }
}

const VideoCroppingHandlerDraggable = DragSource(
  "VIDEO_CROP_HANDLER",
  VideoCroppingHandlerSource,
  collect
)(VideoCroppingHandler);

const VideoCroppingHandlerDefault = props => {
  return (
    <VideoCroppingHandlerDraggable
      {...props}
      canvasBackgroundHtmlElement={props.canvasBackground}
      startCropResizing={props.startVideoCropResizing}
      finishCropResizing={props.finishVideoCropResizing}
      selectedItems={props.selectedItems}
      getElement={props.designData.getElement.bind(props.designData)}
      zoom={props.zoom}
    />
  );
};

export default VideoCroppingHandlerDefault;
