import React from "react";

import BrandKitColorGroup from "./BrandKitColorGroup";
import BrandKitEmpty from "../BrandKitEmpty/BrandKitEmpty";
import common from "../common.module.css";
import EmptyColorsIcon from "views/components/icons/EmptyColorsIcon";
import EmptySearchIcon from "views/components/icons/EmptySearchIcon";
import Loading from "views/components/loading";
import BrandKitColorOps from "views/components/BrandKit/BrandKitColors/BrandKitColorOps";

export default class BrandKitColors extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddBrandColorGroup = BrandKitColorOps.handleAddBrandColorGroup.bind(
      this
    );
    this.handleSearchInput = BrandKitColorOps.handleSearchInput.bind(this);
  }

  componentDidMount() {
    if (this.props.currentTeam.id) {
      const teamId = this.props.currentTeam.id;
      this.props.fetchBrandKitColorGroups(teamId);
    }
  }

  render() {
    const {
      colors: { isProcessing },
      currentSubscriptionCode,
      currentTeam,
      isBrandManager,
      removeBrandKitColorGroup,
      selectedColor,
      location,
      setSelectedBrandColor,
      updateBrandKitColorGroup,
      colors: { entries }
    } = this.props;

    const urlParams = new URLSearchParams(location.search);
    const urlTerm = urlParams.get("term");
    const colorArr = BrandKitColorOps.getColorArr(urlTerm, entries);

    return (
      <>
        {(isProcessing || colorArr.length > 0) &&
          (urlTerm === null ? (
            <div className={common.contentTitle}>Brand Colors</div>
          ) : (
            <div className={common.contentTitleForSearch}>
              Showing all results for <b>{urlTerm}</b>
            </div>
          ))}

        {colorArr.map(({ colors, id, name }) => (
          <BrandKitColorGroup
            colors={colors}
            currentSubscriptionCode={currentSubscriptionCode}
            currentTeam={currentTeam}
            id={id}
            isBrandManager={isBrandManager}
            key={id}
            name={name}
            removeBrandKitColorGroup={removeBrandKitColorGroup}
            selectedColor={selectedColor}
            setSelectedBrandColor={setSelectedBrandColor}
            updateBrandKitColorGroup={updateBrandKitColorGroup}
          />
        ))}

        {isProcessing && colorArr.length <= 0 && <Loading />}

        {!isProcessing &&
          colorArr.length === 0 &&
          (urlTerm === null ? (
            <BrandKitEmpty
              button={
                isBrandManager && {
                  children: <>Add Palette</>,
                  onClick: () => this.handleAddBrandColorGroup()
                }
              }
              icon={<EmptyColorsIcon height="44px" width="56px" />}
              subtitle={
                isBrandManager &&
                `Start by clicking on 'Add Palette' and then choose your colors.\nThese will be easily accessible in every design you create - to always stay on brand!`
              }
              title="Let's load your Brand Colors!"
            />
          ) : (
            <BrandKitEmpty
              icon={<EmptySearchIcon height="43px" width="56px" />}
              subtitle="Try searching for another Palette, or clearing your search."
              title={
                <>
                  No Color Palette could be found for '<b>{urlTerm}</b>'.
                </>
              }
            />
          ))}
      </>
    );
  }
}
