import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TrashIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  title,
  version = "1",
  ...props
}) => {
  switch (version) {
    case "1": {
      return (
        <svg
          {...props}
          style={style}
          data-no-pointer={noPointer}
          width={width || size || 24}
          height={height || size || 24}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title"
          fill={color || easilGrayXDark}
          stroke={color || easilGrayXDark}
          data-testid="TrashIcon"
        >
          <title>{title}</title>
          <g
            id="PROTO"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Brand-Kit---Colors-Initial"
              transform="translate(-341.000000, -513.000000)"
            >
              <g id="Group-28" transform="translate(206.000000, 513.000000)">
                <g id="Group-31" transform="translate(103.000000, 0.000000)">
                  <g id="Group-6">
                    <g id="Group-14" transform="translate(32.000000, 0.000000)">
                      <rect
                        id="Rectangle-27"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      />
                      <g id="Group-7" transform="translate(4.000000, 5.000000)">
                        <g id="Group-28">
                          <path
                            d="M5,3 L5,1 C5,0.44771525 5.33578644,0 5.75,0 L10.25,0 C10.6642136,0 11,0.44771525 11,1 L11,3"
                            id="Rectangle-13"
                            stroke={color || easilGrayXDark}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.8000031,4 L13,12.2000122 L13,12 C12.9467791,13.0644187 12.1632499,14 11.0975016,14 L4.90249844,14 C3.83675006,14 3.05322093,13.2644309 3,12.2000122 L2.19999695,4"
                            id="Rectangle-33"
                            stroke={color || easilGrayXDark}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <rect
                            id="Rectangle-24"
                            fill={color || easilGrayXDark}
                            x="0"
                            y="2"
                            width="16"
                            height="2"
                            rx="1"
                          />
                          <rect
                            id="Rectangle-28"
                            fill={color || easilGrayXDark}
                            x="5.5"
                            y="5.5"
                            width="1.79999995"
                            height="6"
                            rx="0.899999976"
                          />
                          <rect
                            id="Rectangle-28"
                            fill={color || easilGrayXDark}
                            x="8.69999695"
                            y="5.5"
                            width="1.79999995"
                            height="6"
                            rx="0.899999976"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    }
    case "2": {
      return (
        <svg
          {...props}
          style={style}
          data-no-pointer={noPointer}
          width={width || size || 24}
          height={height || size || 24}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title"
          data-testid="TrashIcon"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <g fill={color || easilGrayXDark} fillRule="nonzero">
              <path d="M7 5V4a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1h4a1 1 0 0 1 0 2h-1v13a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V7H3a1 1 0 1 1 0-2h4zm2 0h6V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1zm9 2H6v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7z" />
              <path d="M9 11a1 1 0 1 1 2 0v6a1 1 0 0 1-2 0v-6zm4 0a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0v-6z" />
            </g>
          </g>
        </svg>
      );
    }
    default:
      return <TrashIcon {...this.arguments} version="1" />;
  }
};

TrashIcon.propTypes = {
  version: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TrashIcon;
