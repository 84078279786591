import PropTypes from "prop-types";
import React from "react";

import style from "./style.module.css";

const Avatar = ({
  className,
  charsClassName,
  size,
  theme,
  noBorder,
  doubleBorder,
  doubleBorderColor = "white",
  name = "",
  src,
  display,
  onClick,
  singleCharacterNameInitial
}) => {
  /* Get initials from first name and last name. Eg: 'ABC DEF GHI' will return 'AG'*/
  const nameInitials = () => {
    let initials = name.match(/\b\w/g) || [];
    const label = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
    return singleCharacterNameInitial === true ? label[0] : label;
  };

  const divStyle = {
    backgroundImage: src ? `url("${src}")` : null,
    border: noBorder && "0px",
    width: size,
    height: size,
    minWidth: size,
    boxShadow: doubleBorder ? `0 0 0 2px ${doubleBorderColor}` : "none"
  };

  return (
    <div
      className={`${style.avatar} ${style[theme]} ${className}`}
      data-has-double-border={doubleBorder}
      style={divStyle}
      onClick={onClick}
      data-testid="Avatar"
    >
      {!src && (
        <div className={`${style.chars} ${charsClassName}`}>
          {nameInitials()}
        </div>
      )}
    </div>
  );
};
Avatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.string,
  name: PropTypes.string,
  display: PropTypes.string,
  noBorder: PropTypes.bool,
  src: PropTypes.string,
  charsClassName: PropTypes.string,
  singleCharacterNameInitial: PropTypes.bool
};

export default Avatar;
