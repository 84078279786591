import { escapeRegExp, isEmpty } from "lib";
import { TEAM_ROLES } from "lib/constants";

export const MAX_TEAM_LEVEL = 4; // normal 3 tier and master if required
export const MIN_TEAM_LEVEL = 1;

export const teamParentsReducer = (previous, current) =>
  previous.concat(current.parentPathArr);

export const teamsFilter = (team, term) => {
  /* return early if no term provided */
  if (!term) return team;

  /* apply regex escape on the term */
  let escapedTerm = escapeRegExp(term);

  /* convert term to a regExp */
  escapedTerm = new RegExp(escapedTerm, "i");

  /* return if the team name matched the escaped term */
  return team.name.match(escapedTerm);
};

/**
 * Search the local list of teams based on the search term and return the id of the matching teams.
 * @param term String search term to match on.
 * @param teams Array of teams to match against.
 * @returns an array of team ids.
 */
export const filterBySearchTerm = (term, teams) =>
  teams.filter(team => teamsFilter(team, term)).map(team => team.id);

export const teamListByLevelAndTeamId = (level, teamId, teamList) => {
  if (level === 1) {
    return Object.values(teamList[level])[0] || [];
  }

  return teamList[level][teamId] || [];
};

/**
 * @desc takes an array of teams and sorts them into their group level while maintaining connections to parent teams
 * @note the standard team tiers are only 3 levels deep, though in the event that a user is a member of the Easil master team we can have a 4th tier
 * @param {[object]} teams - an array of team objects to group by parent and level
 * @returns {object} - returns an object of the form { 1: { parentId: [ childTeam ] } } where the outer key is the group level
 */
export const groupTeamListByParentId = teams => {
  const groups = {
    1: {},
    2: {},
    3: {},
    4: {} // potential 4th level when in Easil master team
  };

  const abandonedTeamIds = [];
  let teamLevelOffset = 0;

  // sort the team list so that higher level teams should come first, meaning we know that ancestors will have already been processed if present
  const teamsSorted = teams.sort(
    (a, b) => a.parentPathArr.length - b.parentPathArr.length
  );

  teamsSorted.forEach(team => {
    if (!team.parentPathArr.length) {
      // teams with no parents are master teams like the Easil master team
      groups[MIN_TEAM_LEVEL][team.name] = [{ ...team }];
      teamLevelOffset = 1;
    }
    const parentsArray = team.parentPathArr;
    const parentId = parentsArray[parentsArray.length - 1];
    const ancestorId = parentsArray[0];
    const ancestorWasAbandoned = abandonedTeamIds.reduce(
      (previousValue, abandonedTeamId) =>
        previousValue || parentsArray.includes(abandonedTeamId),
      false
    );

    // check for abandoned child
    let isAbandoned = false;

    // when the teamLevelOffset is not 0 then the easil team is present and no teams will be abandoned as the easil
    // team is omnipresent
    if (teamLevelOffset === 0 && parentsArray.length > MIN_TEAM_LEVEL) {
      // since we have more than one parent this team could be abandoned
      // try to find its parent in the teams list
      const parent = teams.find(teamOption => teamOption.id === parentId);

      // if no parent is found then the team is abandoned
      isAbandoned = isEmpty(parent);
    }

    // when a team has an abandoned ancestor then their level should be reduced by 1 in the same way that the
    // ancestor was
    // NOTE: this method only works due to the current 3 tier team structure, if team structure changes this will need
    // to be adjusted
    // NOTE: teams are pushed 1 level down when the easil team is present, this is what the teamLevelOffset represents
    const level =
      parentsArray.length + (ancestorWasAbandoned ? -1 : 0) + teamLevelOffset;

    if (isAbandoned) {
      abandonedTeamIds.push(team.id);
      // abandoned child teams should be added to top level
      groups[MIN_TEAM_LEVEL][ancestorId] = groups[MIN_TEAM_LEVEL][ancestorId]
        ? groups[MIN_TEAM_LEVEL][ancestorId].concat([team])
        : [team];
    } else if (groups[parentsArray.length + teamLevelOffset][parentId]) {
      // if the parent is already listed in this level since a sibling was already processed then add to it
      groups[level][parentId].push(team);
    } else {
      // no siblings have been here yet so create the parent key
      groups[level][parentId] = [team];
    }
  });

  return groups;
};

export const convertObjectListToNestedArray = list => {
  if (!list) return [];

  const teamList = Object.values(list);
  return groupTeamListByParentId(teamList);
};

export const convertObjectToNestedAndThenOrganiseByLevel = (
  teamsObj,
  teamMenuLevel,
  teamMenuTeamId
) => {
  const nestedTeams = convertObjectListToNestedArray(teamsObj);
  const level =
    teamMenuLevel > MAX_TEAM_LEVEL || teamMenuLevel === 0
      ? MIN_TEAM_LEVEL
      : teamMenuLevel;
  const teams = teamListByLevelAndTeamId(level, teamMenuTeamId, nestedTeams);
  return { teams, nestedTeams };
};

// returns string containing array of roles sepparated by commas
// with last index preceded with 'and'
export const getFilteredRolesTitle = filteredRoles => {
  if (!(filteredRoles && filteredRoles.length)) return "";

  const roles = [];
  TEAM_ROLES.forEach(role => {
    if (filteredRoles.includes(role.key)) roles.push(role.label + "s");
  });
  return roles.join(", ").replace(/, ([^,]*)$/, " and $1");
};

/**
 * @desc takes a search term if provided, and an array of filtered roles and returns a search results title
 * @param term String - user search term for team search results
 * @param {[object]} filteredRoles - an array of roles the user has selected to filter their team with
 * @returns JSX - returns JSX rendering a search results message
 */
export const getTeamSearchResultsTitle = (
  searchTerm = "",
  filteredRoles = []
) => {
  if (!(searchTerm || filteredRoles.length)) return null;

  // only a term is searched
  if (searchTerm) {
    return (
      <>
        Showing all results for <span>{searchTerm}</span>
      </>
    );
  }

  return (
    <>
      Showing <span>{getFilteredRolesTitle(filteredRoles)}</span>
    </>
  );
};

/**
 * @desc takes a search term if provided, and an array of filtered roles and returns a search results title
 * @param term String - user search term for team search results
 * @param {[object]} filteredRoles - an array of roles the user has selected to filter their team with
 * @returns JSX - returns JSX rendering a search results message
 */
export const getEmptyTeamSearchResultsTitle = (
  searchTerm = "",
  filteredRoles = []
) => {
  // only a term is searched
  if (searchTerm) {
    return (
      <>
        No results for <span>{searchTerm}</span>
      </>
    );
  }

  // only roles searched
  if (filteredRoles.length) {
    return (
      <>
        No <span>{getFilteredRolesTitle(filteredRoles)}</span> found
      </>
    );
  }

  return <>No results found</>;
};
