import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FitFrameIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  viewbox = "",
  color = easilGrayXDark
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 20}
      height={height || size || 20}
      viewBox={viewbox}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      data-testid="FitFrameIcon"
      fill={color || easilGrayXDark}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          stroke={color || easilGrayXDark}
          stroke-width="2"
          d="m12 8 4-4m-8 8-4 4m9-13h4v4m-6 10h4a2 2 0 0 0 2-2v-4M7 17H3v-4M9 3H5a2 2 0 0 0-2 2v4"
        />
      </g>
    </svg>
  );
};
FitFrameIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.string
};

export default FitFrameIcon;
