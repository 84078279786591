import React from "react";
import style from "./style.module.css";
import PropTypes from "prop-types";
import NameSection from "./NameSection";
import { noop } from "lib";

import { Dropdown, Loading, Button } from "views/components";

const Menu = ({
  actionClassName,
  actionsClassName,
  actionNameClassName,
  itemName,
  iconSize,
  submitNameChange,
  actions,
  footerOpen,
  footerInfo,
  isNameEditable = true,
  nameSectionClassName,
  nameBoxClassName,
  menuClassName
}) => {
  const {
    footerSubmitAction = noop,
    handleFooterFieldChange = noop,
    isFooterSubmitting = false,
    footerInputValue = null,
    footerValueOptions = [],
    prependOptions = [],
    submitButtonLabel
  } = footerInfo || {};

  const hasActions = actions && Boolean(actions.length);

  const _footerOpen = footerOpen && hasActions;

  const actionTestId = actionName => `${actionName.replaceAll(" ", "")}-Action`;

  return (
    <div className={`${style.menu} ${menuClassName}`}>
      {itemName && (
        <NameSection
          itemName={itemName}
          submitNameChange={submitNameChange}
          isNameEditable={isNameEditable}
          nameSectionClassName={`${nameSectionClassName}${
            !hasActions ? ` ${style.noBottomBorder}` : ""
          }`}
          nameBoxClassName={nameBoxClassName}
        />
      )}
      {hasActions && (
        <div
          className={`${style.actionsSection} ${actionsClassName}`}
          data-testid="actionSection"
        >
          {actions.map((action, index) => (
            <div
              key={action.name + index}
              id={action.name}
              className={`${actionClassName} ${style.action}`}
              data-testid={actionTestId(action.name || "")}
              onClick={action.onClick}
            >
              <div className={style.icon}>
                <action.Icon size="24px" />
              </div>
              <div
                className={`${style.actionName} ${actionNameClassName}`}
                data-testid={action.name}
              >
                {action.name}
              </div>
            </div>
          ))}
        </div>
      )}

      {footerInfo && (
        <div
          className={`${_footerOpen ? style.footerOpen : style.footerClosed} ${
            style.menuFooterWrapper
          }`}
        >
          <Dropdown
            className={style.menuFooterPopoutDropdown}
            placeholder="select a folder"
            onChange={value => handleFooterFieldChange(value)}
            selected={footerInputValue}
            disabled={
              isFooterSubmitting ||
              (footerValueOptions.length === 0 && prependOptions.length === 0)
            }
            options={footerValueOptions}
            prependOptions={prependOptions}
          />
          <Button
            className={style.menuFooterPopoutButton}
            theme="blueSolid"
            onClick={() => footerSubmitAction()}
            disabled={
              isFooterSubmitting ||
              footerInputValue === null ||
              footerInputValue === ""
            }
            disabledTheme={isFooterSubmitting ? "disabledBlue" : null}
          >
            {isFooterSubmitting && <Loading hexColor="#ffffff" size="16px" />}
            {!isFooterSubmitting && submitButtonLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export const MenuActionType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
});

Menu.actionType = MenuActionType;

Menu.displayName = "Menu";

Menu.propTypes = {
  itemName: PropTypes.string,
  submitNameChange: PropTypes.func,
  actions: PropTypes.arrayOf(MenuActionType)
};

export default Menu;
