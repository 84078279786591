import React from "react";
import { connect } from "react-redux";
import PaymentSection from "./PaymentSection";
import { setToken, setPaymentType } from "state/ui/cart/cartActions";
import {
  cartPaymentTypeSelector,
  cartTokenSelector
} from "state/ui/cart/cartSelectors";
import {
  createOrderPaymentFromState,
  asyncCreatePaypalOrder
} from "state/entities/orders/ordersActions";
import { currentOrderIdSelector } from "state/ui/cart/cartSelectors";
import { currentSubscriptionPlan as currentSubscriptionPlanSelector } from "state/entities/subscriptions/subscriptionsSelectors";

export const PaymentSectionContainer = props => <PaymentSection {...props} />;

const mapStateToProps = state => {
  const orderId = currentOrderIdSelector(state);
  const currentSubscriptionPlan = currentSubscriptionPlanSelector(state);
  const currencyCode = currentSubscriptionPlan.currency;
  return {
    paymentType: cartPaymentTypeSelector(state),
    token: cartTokenSelector(state),
    orderId,
    currencyCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setToken: args => dispatch(setToken(args)),
    setPaymentType: args => dispatch(setPaymentType(args)),
    createOrderPayment: args => dispatch(createOrderPaymentFromState(args)),
    createPaypalOrder: args => dispatch(asyncCreatePaypalOrder(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSectionContainer);
