import React from "react";
import { connect } from "react-redux";
import ConfirmModal from "views/components/ConfirmModal";
import { closeConfirmationModal } from "state/ui/confirmationModal/confirmationModalActions";
import PropTypes from "prop-types";

const ConfirmationModalContainer = props => {
  return <ConfirmModal {...props} />;
};

ConfirmationModalContainer.displayName = "ConfirmationModalContainer";

ConfirmationModalContainer.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  header: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  modalClassName: PropTypes.string,
  buttonsDetails: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.ui.confirmationModal.isOpen,
    text: state.ui.confirmationModal.text,
    header: state.ui.confirmationModal.header,
    onOk: state.ui.confirmationModal.onOk,
    onCancel: state.ui.confirmationModal.onCancel,
    modalClassName: state.ui.confirmationModal.modalClassName,
    buttonsDetails: state.ui.confirmationModal.buttonsDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseModal: () => dispatch(closeConfirmationModal())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationModalContainer);
