import { values, userValidators } from "lib";
import { replaceItem } from "lib/array/array";
import { messages } from "lib";

export const checkForDuplicatedEmails = entries => {
  const emails = entries.map(entry => entry.email.value);

  const isDuplicated = (email, index) => {
    return emails.some(
      (item, idx) =>
        (item || "").toLowerCase() === (email || "").toLowerCase() &&
        index !== idx
    );
  };

  const error = (emailValue, index) => {
    if (isDuplicated(emailValue, index)) {
      return "Duplicated";
    }

    return userValidators["email"](emailValue).error;
  };

  return entries.map((entry, index) => {
    const emailValue = entry.email.value;

    return {
      ...entry,
      email: { error: error(emailValue, index), value: emailValue }
    };
  });
};

// validate the entry using userValidators
export const isEntryValid = entry => {
  return (
    Object.keys(entry)
      .map(attribute => {
        const { error } = userValidators[attribute](entry[attribute].value);
        return error;
      })
      .filter(x => x).length === 0
  );
};

export const isFormValid = entries => {
  return entries
    .map(user => values(user))
    .reduce((acc, arr) => [...acc, ...arr], [])
    .every(attribute => !attribute.pristine && !attribute.error);
};

export const updateEntries = ({ attribute, value, entryIndex, entries }) => {
  const { error } = userValidators[attribute](value);

  const changedEntry = {
    ...entries[entryIndex],
    [attribute]: { value: value, error: error }
  };

  let entriesUpdated = replaceItem(entries, entryIndex, changedEntry);

  if (attribute === "email") {
    entriesUpdated = checkForDuplicatedEmails(entriesUpdated);
  }

  return entriesUpdated;
};

export const newEntry = () => ({
  name: { value: null, error: null, pristine: true },
  email: { value: null, error: null, pristine: true },
  roles: {
    value: [
      { key: "ADMIN", label: "Admin", selected: false },
      { key: "BRAND_MANAGER", label: "Brand Manager", selected: false },
      { key: "DESIGNER", label: "Designer", selected: false },
      { key: "MEMBER", label: "Member", selected: false }
    ],
    error: null,
    pristine: true
  }
});

export const mapErrorsToEntities = ({ responseErrors, entries }) => {
  return responseErrors
    .map((error, index) => {
      if (!error) return null;

      const entry = entries[index];

      let fieldErrors = {};
      error.errors.forEach(
        ({ path, type }) =>
          (fieldErrors[path] = {
            error: messages.errors.inviteToTeamForm[path][type] || type,
            value: entry[path].value
          })
      );

      return {
        ...entry,
        ...fieldErrors
      };
    })
    .filter(entry => entry !== null);
};
