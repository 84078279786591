import React, { Component } from "react";
import { connect } from "react-redux";
import SubscriptionsPageSubHeader from "views/components/SubscriptionsPageSubHeader";
import {
  moveToCatalogueTemplates,
  moveToCatalogueEasil
} from "state/ui/navigation/navigationActions";

export class SubscriptionsPageSubHeaderContainer extends Component {
  render() {
    return <SubscriptionsPageSubHeader {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    toggle: {
      easil: () => dispatch(moveToCatalogueEasil()),
      templates: () => dispatch(moveToCatalogueTemplates())
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsPageSubHeaderContainer);
