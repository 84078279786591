import { createSelector } from "reselect";
import { getPath, isEmpty } from "lib/lodash";

export const userTeamAnimationsApiSelector = state =>
  state.api.userTeamAnimations;
export const processUserTeamAnimationsIsFetchingSelector = userTeamAnimationsApi => {
  if (!userTeamAnimationsApi || !userTeamAnimationsApi.all.pages) return true;
  const { pages } = userTeamAnimationsApi.all;
  if (pages && !isEmpty(pages)) {
    return Object.values(pages).some(page => page.isFetching); // true if at least one page is fetching
  }
  return false;
};

export const userTeamAnimationsIsFetchingSelector = createSelector(
  [userTeamAnimationsApiSelector],
  processUserTeamAnimationsIsFetchingSelector
);

const processUserTeamAnimationsPageCount = userTeamAnimationsApi => {
  if (!userTeamAnimationsApi || !userTeamAnimationsApi.all.pages) return 0;
  return Object.keys(userTeamAnimationsApi.all.pages).length;
};

export const userTeamAnimationsPageCount = createSelector(
  [userTeamAnimationsApiSelector],
  processUserTeamAnimationsPageCount
);

export const userTeamAnimationsFirstPageIsFetching = state => {
  const isAnimationsMissing = !getPath(
    state,
    "api.userTeamAnimations.animations.pages[1]"
  );
  const isVideosMissing = !getPath(
    state,
    "api.userTeamAnimations.videos.pages[1]"
  );

  // both states are missing
  if (isAnimationsMissing && isVideosMissing) {
    return true;
  }

  const isAnimationsFirstPageFetching = getPath(
    state,
    "api.userTeamAnimations.animations.pages[1].isFetching"
  );
  const isVideosFirstPageFetching = getPath(
    state,
    "api.userTeamAnimations.videos.pages[1].isFetching"
  );

  return (
    (!isAnimationsMissing && isAnimationsFirstPageFetching) ||
    (!isVideosMissing && isVideosFirstPageFetching)
  );
};

export const userTeamAnimationsAllFirstPageIsFetching = state => {
  const firstPage = getPath(state, "api.userTeamAnimations.all.pages[1]");
  if (!firstPage) {
    return true;
  }
  return firstPage.isFetching;
};

export const userTeamAnimationsErrorSelector = state =>
  userTeamAnimationsApiSelector(state).errors;
