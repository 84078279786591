import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import * as designsTeamFoldersTypes from "state/entities/designFolders/designFoldersTypes";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";
import { fixPagesIds } from "state/api/designs/helpers";
import { flatten } from "lib";

export const initState = {
  pages: {},
  totalCount: null,
  pageSize: 50
};

const templatesApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.TEAM_TEMPLATES_DESIGNS_REQUEST: {
      return handleDesignRequest({ state, action });
    }

    case designsEntitiesTypes.TEAM_TEMPLATES_DESIGNS_REQUEST_SUCCESS: {
      return handleDesignRequestSuccess({ state, action });
    }

    case designsEntitiesTypes.TEAM_TEMPLATES_STATUS_UPDATE_REQUEST: {
      const { body: templatesChanged } = action.request;
      const allTemplateIds = flatten(
        Object.keys(state.pages)
          .sort()
          .map(i => state.pages[i].ids)
      );

      const templatesChangedIds = templatesChanged.map(draft => draft.id);

      const allTemplatesIdsWithoutUpdateds = allTemplateIds.filter(
        templateId => !templatesChangedIds.includes(templateId)
      );

      return {
        ...state,
        pages: fixPagesIds({ ids: allTemplatesIdsWithoutUpdateds, state })
      };
    }

    case designsTeamFoldersTypes.ADD_CONTENT_TO_TEAM_FOLDER_SUCCESS: {
      const designIdMap = {};
      const designs = action.response;
      const stateNew = { ...state, pages: [] };

      if (!Array.isArray(designs)) {
        return state;
      }

      designs.forEach(({ designId }) => {
        designIdMap[designId] = true;
      });

      Object.entries(state.pages).forEach(([page, { ids, ...other }]) => {
        stateNew.pages[page] = {
          ...other,
          ids: ids.filter(id => !designIdMap[id])
        };
      });

      return stateNew;
    }

    default:
      return state;
  }
};

export default templatesApiReducers;
