import * as types from "./shippingRatesTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { asyncAction } from "lib/asyncHelpers";
import { noop } from "lib";

export const fetchShippingRatesByCountry = ({
  countryCode,
  page = 1
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER,
      types: [
        types.FETCH_SHIPPING_RATES_REQUEST,
        types.FETCH_SHIPPING_RATES_REQUEST_SUCCESS,
        types.FETCH_SHIPPING_RATES_REQUEST_FAILURE
      ],
      endpoint: "/shipping-rates",
      request: {
        page,
        pageSize: 100,
        params: {
          countryCode
        }
      },
      schema: schemas.SHIPPING_RATES
    }
  });
};

export const fetchAllShippingRates = ({
  page = 1,
  onSuccess = noop,
  resolve = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER,
      types: [
        types.FETCH_SHIPPING_RATES_REQUEST,
        types.FETCH_SHIPPING_RATES_REQUEST_SUCCESS,
        types.FETCH_SHIPPING_RATES_REQUEST_FAILURE
      ],
      endpoint: "/shipping-rates",
      request: {
        page,
        pageSize: 100
      },
      schema: schemas.SHIPPING_RATES,
      onSuccess: response => {
        if (response.entities.length === 100) {
          dispatch(fetchAllShippingRates({ page: page + 1 }));
        } else {
          onSuccess(response);
          resolve(response);
        }
      }
    }
  });
};

export const asyncFetchAllShippingRates = asyncAction(fetchAllShippingRates);
