import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Cart from "./Cart";

import {
  currentTeamIdSelector,
  teamSelector
} from "state/entities/teams/teamsSelectors";

import {
  currentOrderLoadingStateSelector,
  isBillingAndShippingValid,
  cartTokenSelector,
  cartPaymentTypeSelector,
  currentBillingSelector,
  currentShippingSelector
} from "state/ui/cart/cartSelectors";

import { fetchCartPageData } from "state/pageActions/cartPageActions";

import { getCurrentOrderByIdWithFullDesignSelector } from "state/entities/orders/ordersSelectors";

import { updateShippingAndBillingData } from "state/pageActions/cartPageActions";
import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";

import {
  createOrderPaymentFromState,
  createOrderPayment
} from "state/entities/orders/ordersActions";
import { moveTo, moveToWorkspace } from "state/ui/navigation/navigationActions";

export const CartContainer = props => <Cart {...props} />;

function mapStateToProps(state) {
  const teamId = currentTeamIdSelector(state);
  const currentTeam = teamSelector({ state, teamId });
  const currentOrderLoadingState = currentOrderLoadingStateSelector(state);
  //TODO: REMOVE SELECTOR FOR ORDER HERE, IT WILL BE USED IN CHILD CONTAINERS INSTEAD
  const order = getCurrentOrderByIdWithFullDesignSelector({ state });
  const subscription = currentSubscriptionSelector(state);
  const currentBilling = currentBillingSelector(state);
  const currentShipping = currentShippingSelector(state);

  return {
    user: state.currentUser,
    team: currentTeam,
    loading: currentOrderLoadingState,
    order,
    isBillingAndShippingValid: isBillingAndShippingValid(state),
    token: cartTokenSelector(state),
    paymentType: cartPaymentTypeSelector(state),
    isAccount: subscription.isAccount,
    currentBilling,
    currentShipping
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCartPageData: args => dispatch(fetchCartPageData(args)),
    updateShippingAndBillingData: args =>
      dispatch(updateShippingAndBillingData(args)),
    createOrderPayment: args => dispatch(createOrderPaymentFromState(args)),
    createOrderPaymentForAccount: args => dispatch(createOrderPayment(args)),
    changePage: path => dispatch(moveTo(path)),
    moveToWorkspace: args => dispatch(moveToWorkspace(args))
  };
};

CartContainer.propTypes = {
  user: PropTypes.object,
  team: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);
