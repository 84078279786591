import * as tagsEntitiesTypes from "state/entities/tags/tagsTypes";
import {
  handleTagRequest,
  handleTagRequestSuccess
} from "state/api/tags/apiTagsReducersCommonCases";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50,
  teamTagsCollected: false
};

const tagsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case tagsEntitiesTypes.FETCH_TEAM_TAGS_REQUEST: {
      return handleTagRequest({ state, action });
    }

    case tagsEntitiesTypes.FETCH_TEAM_TAGS_REQUEST_SUCCESS: {
      return handleTagRequestSuccess({ state, action });
    }

    default:
      return state;
  }
};

export default tagsApiReducers;
