import React from "react";
import { pick } from "lib/lodash";
import { getElementComponent } from "views/components/Editor/elements/elementsComponentsMap";
import TextMaskingDraggableImage from "./TextMaskingDraggableImage";
import style from "./textMaskingCroppingStyle.module.css";

const TextMaskingCropping = ({
  canvasBackground,
  pageOffset,
  textElement,
  zoom,
  pageId,
  isCropping,
  startCropMoving,
  finishCropMoving
}) => {
  const { ElementComponent, componentProps } = getElementComponent("textbox");

  const elementData = pick(textElement, componentProps);

  let styleWrapper = {
    width: 0,
    height: 0,
    cursor: "move",
    top: pageOffset.top,
    left: pageOffset.left,
    position: "absolute",
    transform: `translate3d(${textElement.left * zoom}px, ${textElement.top *
      zoom}px, 0) scale(${zoom})`,
    transformOrigin: "0 0",
    opacity: isCropping ? 0 : 1,
    zIndex: 11,
    pointerEvents: "none"
  };

  const cancelClick = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const getRotationStyle = () => {
    return {
      transform: [
        `rotate(${textElement.angle}deg)`,
        `scaleX(${textElement.scaleX})`,
        `scaleY(${textElement.scaleY})`
      ].join(" ")
    };
  };

  return (
    <div
      onClick={cancelClick}
      style={{
        height: canvasBackground.offsetHeight,
        width: canvasBackground.offsetWidth,
        overflow: "hidden",
        position: "absolute",
        top: 0,
        left: 0
      }}
    >
      <div
        className={style.textMasking}
        style={{
          height: canvasBackground.offsetHeight,
          width: canvasBackground.offsetWidth,
          overflow: "hidden"
        }}
      />
      <div
        style={{
          position: "absolute",
          top: pageOffset.top,
          left: pageOffset.left,
          zIndex: 4
        }}
      >
        <TextMaskingDraggableImage
          pageOffset={pageOffset}
          textElement={textElement}
          isCropping={isCropping}
          zoom={zoom}
          startCropMoving={startCropMoving}
          finishCropMoving={finishCropMoving}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: pageOffset.top + textElement.top * zoom,
          left: pageOffset.left + textElement.left * zoom,
          height: textElement.height * zoom,
          width: textElement.width * zoom,
          zIndex: 11,
          pointerEvents: "none"
        }}
      />
      <div style={styleWrapper} id={textElement.uniqueId}>
        <ElementComponent
          zoom={zoom}
          style={getRotationStyle()}
          key={textElement.uniqueId}
          elementId={textElement.uniqueId}
          elementData={elementData}
          isCropping={true}
        />
      </div>
    </div>
  );
};

export default TextMaskingCropping;
