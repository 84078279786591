import { DragSource } from "react-dnd";
import MaskedImage from "./MaskedImage";

const MaskedImageDraggableSource = {
  beginDrag(props, monitor, component) {
    props.startImageCropMoving();

    return {};
  },
  endDrag(props, monitor) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

    props.finishImageCropMoving({ differenceFromInitialOffset });
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource("IMAGE_CROP", MaskedImageDraggableSource, collect)(
  MaskedImage
);
