import React from "react";

import style from "./style.module.css";

import { Popout } from "views/components";

import { noop } from "lib";

export const CrumbDropdown = ({
  DropdownComponent,
  isDropdownOpen,
  onClose = noop,
  width = 350,
  top = 32,
  isDropdownContainer
}) => {
  if (!isDropdownOpen) return null;
  // if we just want the dropdown to be a component placeholder we can use this to just render what is passed
  if (isDropdownContainer) {
    return <DropdownComponent onClose={onClose} />;
  }
  return (
    <Popout
      className={style.crumbDropdown}
      onClose={onClose}
      maxHeight="500px"
      width={width}
      top={top}
      offsetDirection="left"
    >
      <DropdownComponent onClose={onClose} />
    </Popout>
  );
};

export default CrumbDropdown;
