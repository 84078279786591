export const WORKSPACE_SIZES_REQUEST = "API/ENTITIES/WORKSPACE_SIZES_REQUEST";
export const WORKSPACE_SIZES_REQUEST_SUCCESS =
  "API/ENTITIES/WORKSPACE_SIZES_REQUEST_SUCCESS";
export const WORKSPACE_SIZES_REQUEST_FAILURE =
  "API/ENTITIES/WORKSPACE_SIZES_REQUEST_FAILURE";

export const WORKSPACE_SIZES_FOLDER_REQUEST =
  "API/ENTITIES/WORKSPACE_SIZES_FOLDER_REQUEST";
export const WORKSPACE_SIZES_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/WORKSPACE_SIZES_FOLDER_REQUEST_SUCCESS";
export const WORKSPACE_SIZES_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/WORKSPACE_SIZES_FOLDER_REQUEST_FAILURE";

export const WORKSPACE_APPROVED_SIZES_FILTER_REQUEST =
  "API/ENTITIES/WORKSPACE_APPROVED_SIZES_REQUEST";
export const WORKSPACE_APPROVED_SIZES_FILTER_REQUEST_SUCCESS =
  "API/ENTITIES/WORKSPACE_APPROVED_SIZES_REQUEST_SUCCESS";
export const WORKSPACE_APPROVED_SIZES_FILTER_REQUEST_FAILURE =
  "API/ENTITIES/WORKSPACE_APPROVED_SIZES_REQUEST_FAILURE";

export const WORKSPACE_SIZES_FILTER_REQUEST =
  "API/ENTITIES/WORKSPACE_SIZES_FILTER_REQUEST";
export const WORKSPACE_SIZES_FILTER_REQUEST_SUCCESS =
  "API/ENTITIES/WORKSPACE_SIZES_FILTER_REQUEST_SUCCESS";
export const WORKSPACE_SIZES_FILTER_REQUEST_FAILURE =
  "API/ENTITIES/WORKSPACE_SIZES_FILTER_REQUEST_FAILURE";

export const WORKSPACE_SHARED_SIZES_FILTER_REQUEST =
  "API/ENTITIES/WORKSPACE_SHARED_SIZES_REQUEST";
export const WORKSPACE_SHARED_SIZES_FILTER_REQUEST_SUCCESS =
  "API/ENTITIES/WORKSPACE_SHARED_SIZES_REQUEST_SUCCESS";
export const WORKSPACE_SHARED_SIZES_FILTER_REQUEST_FAILURE =
  "API/ENTITIES/WORKSPACE_SHARED_SIZES_REQUEST_FAILURE";

export const WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_FILTER_REQUEST =
  "API/ENTITIES/WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_REQUEST";
export const WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_FILTER_REQUEST_SUCCESS =
  "API/ENTITIES/WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_REQUEST_SUCCESS";
export const WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_FILTER_REQUEST_FAILURE =
  "API/ENTITIES/WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_REQUEST_FAILURE";
