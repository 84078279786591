import React from "react";
import style from "./style.module.css";

import SliderSwatch from "./SliderSwatch";

export const SliderSwatches = ({ className, onClick, hsl }) => {
  return (
    <div className={`${style.swatches} ${className}`}>
      <div className={style.swatch}>
        <SliderSwatch
          borderRadius={"4px 4px 0 0"}
          hsl={hsl}
          offset=".80"
          active={
            Math.round(hsl.l * 100) / 100 === 0.8 &&
            Math.round(hsl.s * 100) / 100 === 0.5
          }
          onClick={onClick}
          first
        />
      </div>
      <div className={style.swatch}>
        <SliderSwatch
          hsl={hsl}
          offset=".65"
          active={
            Math.round(hsl.l * 100) / 100 === 0.65 &&
            Math.round(hsl.s * 100) / 100 === 0.5
          }
          onClick={onClick}
        />
      </div>
      <div className={style.swatch}>
        <SliderSwatch
          hsl={hsl}
          offset=".50"
          active={
            Math.round(hsl.l * 100) / 100 === 0.5 &&
            Math.round(hsl.s * 100) / 100 === 0.5
          }
          onClick={onClick}
        />
      </div>
      <div className={style.swatch}>
        <SliderSwatch
          hsl={hsl}
          offset=".35"
          active={
            Math.round(hsl.l * 100) / 100 === 0.35 &&
            Math.round(hsl.s * 100) / 100 === 0.5
          }
          onClick={onClick}
        />
      </div>
      <div className={style.swatch}>
        <SliderSwatch
          borderRadius={"0 0 4px 4px"}
          hsl={hsl}
          offset=".20"
          active={
            Math.round(hsl.l * 100) / 100 === 0.2 &&
            Math.round(hsl.s * 100) / 100 === 0.5
          }
          onClick={onClick}
          last
        />
      </div>
    </div>
  );
};

export default SliderSwatches;
