import * as types from "./foldersTypes";
import * as apiFolderTypes from "state/api/folders/apiFolderTypes";
import { isEmpty, reduxStoreExpiry } from "lib";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import { teamFoldersApiPageSelector } from "state/api/folders/team/teamApiSelectors";

const shouldFetchAllPersonalFolders = state => {
  return isEmpty(state.api.folders.personal.pages);
};

export const fetchAllPersonalFoldersIfNeeded = (...args) => (
  dispatch,
  getState
) => {
  if (shouldFetchAllPersonalFolders(getState())) {
    dispatch(fetchAllPersonalFolders(...args));
  }
};

export const fetchAllPersonalFolders = ({
  teamId,
  userId,
  page = 1
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.FETCH_PERSONAL_FOLDERS_REQUEST,
        types.FETCH_PERSONAL_FOLDERS_REQUEST_SUCCESS,
        types.FETCH_PERSONAL_FOLDERS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders`,
      request: {
        page: page,
        params: {
          type: "DESIGN"
        }
      },
      schema: schemas.FOLDERS,
      onSuccess: response => {
        if (response.entities) {
          dispatch(fetchAllPersonalFolders({ teamId, userId, page: page + 1 }));
        }
      }
    }
  });
};

export const createWorkspaceFolder = ({ name, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.CREATE_PERSONAL_FOLDER_REQUEST,
        types.CREATE_PERSONAL_FOLDER_REQUEST_SUCCESS,
        types.CREATE_PERSONAL_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders`,
      request: {
        body: {
          teamId,
          userId,
          name
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const deleteWorkspaceFolder = ({ folderId, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.DELETE_PERSONAL_FOLDER_REQUEST,
        types.DELETE_PERSONAL_FOLDER_REQUEST_SUCCESS,
        types.DELETE_PERSONAL_FOLDER_REQUEST_FAILURE
      ],
      extra: {
        folderId
      },
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}`,
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const updateWorkspaceFolder = ({ folder, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;
  const { id: folderId } = folder;

  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ASSET,
      types: [
        types.UPDATE_PERSONAL_FOLDER_REQUEST,
        types.UPDATE_PERSONAL_FOLDER_REQUEST_SUCCESS,
        types.UPDATE_PERSONAL_FOLDER_REQUEST_FAILURE
      ],
      extra: {
        folderId
      },
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}`,
      request: {
        body: {
          ...folder
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

const shouldFetchAllTeamFolders = (state, page = 1) => {
  const teamFoldersApiPage = teamFoldersApiPageSelector(state, page);

  if (!teamFoldersApiPage) return true;

  if (teamFoldersApiPage.isFetching) return false;

  const isExpired = reduxStoreExpiry.isDataExpired(
    teamFoldersApiPage.lastFetched,
    5
  );

  return isExpired;
};

export const fetchAllTeamFoldersIfNeeded = (...args) => (
  dispatch,
  getState
) => {
  // default this to the current teamId when it isn't provided
  if (!args.teamId) {
    args.teamId = currentTeamIdSelector(getState());
  }

  if (shouldFetchAllTeamFolders(getState())) {
    dispatch(fetchAllTeamFolders(...args));
  }
};

export const fetchAllTeamFolders = ({ page = 1, type }) => (
  dispatch,
  getState
) => {
  const teamId = currentTeamIdSelector(getState());

  return dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.FETCH_TEAM_FOLDERS_REQUEST,
        types.FETCH_TEAM_FOLDERS_REQUEST_SUCCESS,
        types.FETCH_TEAM_FOLDERS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders`,
      request: {
        page: page,
        params: type ? { type } : undefined
      },
      schema: schemas.FOLDERS,
      onSuccess: response => {
        if (response.entities) {
          dispatch(fetchAllTeamFolders({ teamId, type, page: page + 1 }));
        }
      }
    }
  });
};

export const createTeamFolder = ({ name, onSuccess, type }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  return dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.CREATE_TEAM_FOLDER_REQUEST,
        types.CREATE_TEAM_FOLDER_REQUEST_SUCCESS,
        types.CREATE_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders`,
      request: {
        body: {
          teamId,
          name,
          type
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const updateTeamFolder = ({ folder, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const { id: folderId } = folder;

  return dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ASSET,
      types: [
        types.UPDATE_TEAM_FOLDER_REQUEST,
        types.UPDATE_TEAM_FOLDER_REQUEST_SUCCESS,
        types.UPDATE_TEAM_FOLDER_REQUEST_FAILURE
      ],
      extra: {
        folderId
      },
      endpoint: `/teams/${teamId}/folders/${folderId}`,
      request: {
        body: {
          ...folder
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const deleteTeamFolder = ({ folderId, onSuccess, type }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  return dispatch({
    [CALL_API]: {
      endpoint: `/teams/${teamId}/folders/${folderId}`,
      extra: { folderId },
      method: "DELETE",
      onSuccess,
      request: {
        body: {
          folderId,
          teamId,
          type
        }
      },
      schema: schemas.FOLDER,
      service: SERVICES.ASSET,
      types: [
        types.DELETE_TEAM_FOLDER_REQUEST,
        types.DELETE_TEAM_FOLDER_REQUEST_SUCCESS,
        types.DELETE_TEAM_FOLDER_REQUEST_FAILURE
      ]
    }
  });
};

export const getFolderForMedia = ({ mediaId }) => dispatch => {
  return dispatch({
    [CALL_API]: {
      endpoint: `/media/${mediaId}/folder`,
      method: "GET",
      schema: schemas.FOLDER,
      service: SERVICES.ASSET,
      request: {
        mediaId
      },
      types: [
        apiFolderTypes.FETCH_FOLDER_FOR_MEDIA_REQUEST,
        apiFolderTypes.FETCH_FOLDER_FOR_MEDIA_REQUEST_SUCCES,
        apiFolderTypes.FETCH_FOLDER_FOR_MEDIA_REQUEST_FAILURE
      ]
    }
  });
};
