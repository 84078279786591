import React from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import style from "./style.module.css";

export const SubHeaderTab = ({ tab }) => {
  const { url, title, isSelected, lastWasSelected } = tab;

  if (url) {
    let Title = title;

    if (typeof title === "string") {
      Title = () => <span>{title}</span>;
    }

    return (
      <NavLink
        className={style.subHeaderTabWrapper + " " + tab.className}
        activeClassName={style.selectedTab}
        to={url}
        id="tab-wrapper"
        isActive={() => isSelected}
      >
        {isSelected && (
          <div
            className={style.leftTabBumper}
            id="tab-bumper"
            data-testid="tab-bumper"
          />
        )}
        <div
          className={style.tabText}
          id="tab-title"
          data-testid="SubHeaderTab"
          data-isselected={isSelected}
        >
          <Title />
        </div>
        {isSelected && (
          <div
            className={style.rightTabBumper}
            id="tab-bumper"
            data-testid="tab-bumper"
          />
        )}
        {!isSelected && !lastWasSelected && !tab.hasNoBorder && (
          <span id="tab-left-border" data-testid="tab-left-border" />
        )}
      </NavLink>
    );
  }
  return (
    <div
      className={style.subHeaderTabWrapper}
      id="non-nav-tab"
      data-testid="SubHeaderTab"
      data-isselected={isSelected}
    >
      <div
        className={style.tabText}
        id="tab-component"
        data-testid="tab-component"
      >
        <tab.title />
      </div>
      {!lastWasSelected && (
        <span id="tab-left-border" data-testid="tab-left-border" />
      )}
    </div>
  );
};

SubHeaderTab.displayName = "SubHeaderTab";

SubHeaderTab.propTypes = {
  tab: PropTypes.object.isRequired
};

export default SubHeaderTab;
