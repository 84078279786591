import { cloneDeep } from "lodash";
import { getAnimationData } from "lib/animatedElementUtils";

const DesignTextMaskingOps = {
  removeTextMask({ elementId }) {
    const textElement = this.getElement(elementId);
    const removedMaskElement = textElement.removeTextMask();
    const elementPageId = this.getElementPageId(textElement.uniqueId);

    let elementPage = cloneDeep(this.pages[elementPageId]);

    if (!!textElement.maskImage.duration) {
      const animationData = getAnimationData({
        ...textElement,
        pageId: elementPageId
      })[0];
      const updatedPageAnimatedElements = {
        ...elementPage.animatedElements
      };

      delete updatedPageAnimatedElements[animationData.animationDataKey];

      elementPage = {
        ...elementPage,
        animatedElements: updatedPageAnimatedElements
      };
    }

    return new this.constructor({
      ...this,
      elements: {
        ...this.elements,
        [elementId]: removedMaskElement
      },
      pages: {
        ...this.pages,
        [elementPageId]: elementPage
      },
      version: this.version + 1
    });
  },

  addTextMask({ elementId, imageElement }) {
    const textElement = this.getElement(elementId);
    const updatedtextElement = textElement.addTextMask(imageElement);
    const elementPageId = this.getElementPageId(textElement.uniqueId);

    let elementPage = cloneDeep(this.pages[elementPageId]);
    // only item in animationData for maskImage in textbox
    if (!!updatedtextElement.maskImage.duration) {
      const animationData = getAnimationData({
        ...updatedtextElement,
        pageId: elementPageId
      })[0];
      const updatedPageAnimatedElements = {
        ...elementPage.animatedElements,
        [animationData.animationDataKey]: animationData
      };

      elementPage = {
        ...elementPage,
        animatedElements: updatedPageAnimatedElements
      };
    }

    if (imageElement?.duration && textElement.maskImage?.duration) {
      const animationData = getAnimationData({
        ...textElement,
        pageId: elementPageId
      })[0];
      const updatedPageAnimatedElements = {
        ...elementPage.animatedElements
      };

      delete updatedPageAnimatedElements[animationData.animationDataKey];

      elementPage = {
        ...elementPage,
        animatedElements: updatedPageAnimatedElements
      };
    }

    return new this.constructor({
      ...this,
      elements: {
        ...this.elements,
        [elementId]: updatedtextElement
      },
      pages: {
        ...this.pages,
        [elementPageId]: elementPage
      },
      version: this.version + 1
    });
  },

  updateTextMask({ elementId, maskImage }) {
    const textElement = this.getElement(elementId);

    return new this.constructor({
      ...this,
      elements: {
        ...this.elements,
        [elementId]: textElement.updateTextMask({ maskImage })
      },
      version: this.version + 1
    });
  }
};

export default DesignTextMaskingOps;
