import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

const H5 = ({ className, children }) => {
  return <div className={`${style.h5} ${className}`}>{children}</div>;
};
H5.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default H5;
