import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FolderPlusIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h24v24H-2z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M12 20v-2h5a1 1 0 0 0 1-1V6.444a1 1 0 0 0-1-1h-4a2 2 0 0 1-1.172-.379L7.85 2.19A1 1 0 0 0 7.264 2H3a1 1 0 0 0-1 1v6H0V3a3 3 0 0 1 3-3h4.264a3 3 0 0 1 1.758.569L13 3.444h4a3 3 0 0 1 3 3V17a3 3 0 0 1-3 3h-5zm0-2h5a1 1 0 0 0 1-1V6.444a1 1 0 0 0-1-1h-4a2 2 0 0 1-1.172-.379L7.85 2.19A1 1 0 0 0 7.264 2H3a1 1 0 0 0-1 1v6H0a1 1 0 1 1 2 0 1 1 0 0 1-2 0V3a3 3 0 0 1 3-3h4.264a3 3 0 0 1 1.758.569L13 3.444h4a3 3 0 0 1 3 3V17a3 3 0 0 1-3 3h-5a1 1 0 0 1 0-2z"
      />
      <path
        fill={color || easilGrayXDark}
        d="M3 15v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 0 2H5v2a1 1 0 0 1-2 0v-2H1a1 1 0 0 1 0-2h2z"
      />
    </g>
  </svg>
);
FolderPlusIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FolderPlusIcon;
