import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ShapesSmallIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <path d="M0 0h16v16H0z" />
      <g fill={color || easilGrayXDark} fillRule="nonzero">
        <path d="M7.353 14h5.292l-2.648-3.437L7.353 14zm8.117.39a1 1 0 0 1-.792 1.61H5.32a1 1 0 0 1-.792-1.61l4.676-6.076a1 1 0 0 1 1.584 0l4.681 6.076zM4 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
      </g>
    </g>
  </svg>
);
ShapesSmallIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ShapesSmallIcon;
