const formatImageDataForV1 = elementData => {
  if (checkForImageInstructionsBuilderPromise(elementData)) {
    elementData.imageInstructions.forEach(element => {
      delete element.imageElementBuilderPromise;
    });
  }
  return elementData;
};

const checkForImageInstructionsBuilderPromise = data => {
  return data.imageInstructions.some(
    instruction => instruction.imageElementBuilderPromise
  );
};

export default formatImageDataForV1;
