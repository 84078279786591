import { capitalizeFirstLetter } from "lib/textUtils";

export const PERCENT_SYMBOL = "%";

export const getCouponErrorMessage = (
  couponMeta,
  intervalPeriod,
  newPlanName
) => {
  if (!couponMeta) return "";
  const couponIntervalIneligible =
    couponMeta.message &&
    (couponMeta.message === "eligibleIntervals must be in the defined set" ||
      couponMeta.message === "Coupon eligible intervals invalid.");
  const couponPlanIneligible =
    couponMeta.message &&
    (couponMeta.message === "eligiblePlans must be in the defined set" ||
      couponMeta.message === "Coupon eligible plans invalid.");

  if (couponIntervalIneligible) {
    return `The promo code provided is not valid on a ${capitalizeFirstLetter(
      intervalPeriod.toLowerCase()
    )} plan.`;
  }

  if (couponPlanIneligible) {
    return `The promo code provided is not valid on a ${newPlanName} plan.`;
  }

  return "The promo code provided is invalid.";
};

export const getCouponMeta = ({ coupon, newPlan }) => {
  if (!coupon || !newPlan) {
    return null;
  }

  if (coupon.errors) {
    return new Error(coupon.errors[0].message);
  }

  if (!coupon.isValid) {
    return new Error("Coupon is simply invalid.");
  }

  if (
    new Date(coupon.startAt) > Date.now() ||
    new Date(coupon.endAt) < Date.now()
  ) {
    return new Error("Coupon start/end date invalid.");
  }

  if (coupon.eligiblePlans && !coupon.eligiblePlans.includes(newPlan.code)) {
    return new Error("Coupon eligible plans invalid.");
  }

  if (
    coupon.eligibleIntervals &&
    !coupon.eligibleIntervals.includes(newPlan.intervalPeriod)
  ) {
    return new Error("Coupon eligible intervals invalid.");
  }

  if (coupon.valueType !== PERCENT_SYMBOL) {
    return new Error("Coupon value type invalid.");
  }

  const discountPerMember = Math.max(
    0,
    Math.min(newPlan.amount, (newPlan.amount * coupon.value) / 100)
  );

  return { ...coupon, discountPerMember };
};
