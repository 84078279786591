import * as types from "state/entities/searchSuggestions/searchSuggestionsTypes";
import { omit } from "lib";

export const initState = {
  terms: {}
};

export const searchSuggestionsRequest = ({ action, state }) => {
  const {
    params: { term }
  } = action.request;
  return {
    ...state,
    terms: {
      ...state.terms,
      [term]: {
        ...state.terms[term],
        isFetching: true
      }
    }
  };
};

export const searchSuggestionsRequestSuccess = ({ state, action }) => {
  const {
    request: {
      params: { term }
    }
  } = action;

  if (typeof term !== "string") {
    return {
      ...state,
      terms: omit(state.terms, action.request.term)
    };
  }

  const updatedTerm = {
    [term]: {
      isFetching: false
    }
  };

  return {
    ...state,
    terms: { ...state.terms, ...updatedTerm }
  };
};

const searchSuggestionsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.SEARCH_SUGGESTIONS_REQUEST: {
      return searchSuggestionsRequest({ state, action });
    }

    case types.SEARCH_SUGGESTIONS_REQUEST_SUCCESS: {
      return searchSuggestionsRequestSuccess({ state, action });
    }

    default:
      return state;
  }
};

export default searchSuggestionsApiReducers;
