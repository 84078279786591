import * as types from "./teamAnimationsTypes";
import * as teamAnimationFolderTypes from "state/entities/teamAnimationsFolders/teamAnimationsFoldersTypes";
import { isEmpty, omit } from "lib";

export const initState = null;

const teamAnimationsReducers = (state = initState, action) => {
  switch (action.type) {
    // GET
    case types.TEAM_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS:
    case types.TEAM_ANIMATIONS_REQUEST_SUCCESS:
    case types.ALL_TEAM_ANIMATIONS_REQUEST_SUCCESS:
    case types.TEAM_VIDEOS_REQUEST_SUCCESS: {
      let previousState = state || {};

      const { entities = {} } = action.response;

      const teamAnimation = { ...entities.teamAnimation };

      if (!isEmpty(state)) {
        Object.keys(teamAnimation).forEach(id => {
          const animation = teamAnimation[id];
          const stateAnimation = state[id];
          const preservedThumbUrl = animation.thumbnailUrl;
          let thumbnailUrl = animation.thumbnailUrl;
          if (
            stateAnimation &&
            stateAnimation.thumbnailUrl &&
            stateAnimation.thumbnailUrl.startsWith("blob:")
          ) {
            thumbnailUrl = stateAnimation.thumbnailUrl;
          }
          teamAnimation[id] = {
            ...animation,
            thumbnailUrl,
            preservedThumbUrl
          };
        });
      }

      return { ...previousState, ...teamAnimation };
    }

    case types.ADD_TEAM_ANIMATIONS_PLACEHOLDERS: {
      let previousState = state || {};

      // get the media placeholders
      const { mediaPlaceholders = [] } = action;

      // reduce into object and add isPlaceholder boolean flag
      const teamAnimations = mediaPlaceholders.reduce(
        (allMedia, currentMedia) => ({
          ...allMedia,
          [currentMedia.mediaId]: {
            ...currentMedia,
            isPlaceholder: true
          }
        }),
        {}
      );

      return { ...previousState, ...teamAnimations };
    }

    // DELETE
    case types.TEAM_ANIMATION_DELETE_REQUEST: {
      return omit(state, action.request.mediaId);
    }

    // PUT
    case types.TEAM_ANIMATION_UPDATE_REQUEST: {
      const updatedAnimation = action.request.body;
      return {
        ...state,
        [updatedAnimation.mediaId]: updatedAnimation
      };
    }

    // POST
    case types.TEAM_ANIMATION_UPLOAD_REQUEST_SUCCESS:
    case types.TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS: {
      const updatedAnimation = { ...action.response };

      if (
        updatedAnimation &&
        updatedAnimation.media &&
        !updatedAnimation.media.priority
      ) {
        updatedAnimation.media.priority = null;
      }

      const thumbnailUrl = action.extra
        ? action.extra.placeholderURL
        : updatedAnimation.thumbnailUrl;

      return {
        ...state,
        [updatedAnimation.mediaId]: {
          ...updatedAnimation,
          thumbnailUrl,
          origin: state[updatedAnimation.mediaId].origin,
          preservedThumbUrl: updatedAnimation.thumbnailUrl
        }
      };
    }

    case teamAnimationFolderTypes.UPLOAD_ANIMATION_TO_TEAM_FOLDER_REQUEST_SUCCESS: {
      const imageId = action.request.body.contentId;
      const thumbnailUrl = action.extra.placeholderURL;
      return {
        ...state,
        [imageId]: {
          ...state[imageId],
          isPlaceholder: false,
          thumbnailUrl,
          preservedThumbUrl: state[imageId].thumbnailUrl
        }
      };
    }

    case types.TEAM_ANIMATIONS_CLEAR: {
      return initState;
    }

    default:
      return state;
  }
};

export default teamAnimationsReducers;
