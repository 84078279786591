import * as types from "./currentUserTypes";

export const initState = {
  id: null,
  isFetching: false,
  logout: false,
  logoutLocation: null,
  token: null,
  tokenOrigin: null,
  roles: null,
  isSwitchingToken: false,
  changePassword: {
    isProcessing: false,
    errors: []
  },
  isProcessing: false
};

const user = (state = initState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_SET_TOKEN_DATA: {
      const { userId, roles, token, tokenOrigin } = action.payload;

      return {
        ...state,
        id: userId,
        token,
        roles,
        tokenOrigin
      };
    }
    case types.CURRENT_USER_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.CURRENT_USER_REQUEST_SUCCESS: {
      const { entities, ids: id } = action.response;

      return {
        ...state,
        isFetching: false,
        initFetchFailed: false,
        ...entities.users[id]
      };
    }

    case types.CURRENT_USER_SWITCH_TOKEN_REQUEST: {
      return {
        ...state,
        isSwitchingToken: true,
        token: null
      };
    }

    case types.CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS: {
      return {
        ...state,
        isSwitchingToken: false
      };
    }

    case types.CURRENT_USER_LOGOUT_REQUEST_SUCCESS: {
      // Current user is no longer.
      return {
        ...state,
        logoutLocation: action.response.location
      };
    }

    case types.CURRENT_USER_UPDATE_REQUEST: {
      return {
        ...state,
        avatarUrl: action.extra.isRemovingAvatar ? null : state.avatarUrl,
        isProcessing: true
      };
    }

    case types.CURRENT_USER_UPDATE_REQUEST_FAILURE: {
      return {
        ...state,
        isProcessing: false
      };
    }

    case types.CURRENT_USER_UPDATE_REQUEST_SUCCESS: {
      const {
        ids: id,
        entities: { users }
      } = action.response;
      const user = users[id];

      return {
        ...state,
        ...user,
        isProcessing: false
      };
    }

    case types.CHANGE_CURRENT_USER_PASSWORD_REQUEST: {
      return {
        ...state,
        changePassword: {
          isProcessing: true,
          errors: []
        }
      };
    }

    case types.CHANGE_CURRENT_USER_PASSWORD_REQUEST_SUCCESS: {
      return {
        ...state,
        changePassword: {
          isProcessing: false,
          errors: []
        }
      };
    }

    case types.CHANGE_CURRENT_USER_PASSWORD_REQUEST_FAILURE: {
      return {
        ...state,
        changePassword: {
          isProcessing: false,
          errors: []
        }
      };
    }

    default:
      return state;
  }
};

export default user;
