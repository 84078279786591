import React, { Component } from "react";
import PropTypes from "prop-types";
import FontModalHeader from "./FontModalHeader";
import FontModalDescriptionSection from "./FontModalDescriptionSection";
import FontModalDropDownSection from "./FontModalDropDownSection";
import FontModalUploadSection from "./FontModalUploadSection";
import FontModalDivider from "./FontModalDivider";
import { Logger } from "lib";
import { jsonStringEqual } from "lib/equalityUtils";
import { getFileNameWithoutExtension } from "lib/files/fileUtils";
import style from "./style.module.css";
import { chainPromisesWithResults } from "lib/promiseHandlers";

import { Modal, Loading } from "views/components";

class FontModalWrapper extends Component {
  static UPDATE_MODE = "update";

  static CREATE_MODE = "create";

  static defaultProps = {
    isUpdate: false
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTeamFont = this.handleChangeTeamFont.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.findFont = this.findFont.bind(this);
    this.handleCloseFontModal = this.handleCloseFontModal.bind(this);
    this.handleToggleUseFontName = this.handleToggleUseFontName.bind(this);
    this.handleSaveFont = this.handleSaveFont.bind(this);
    this.handleUploadForm = this.handleUploadForm.bind(this);
    this.deleteUploadedFile = this.deleteUploadedFile.bind(this);
    this.getModalButtons = this.getModalButtons.bind(this);
    this.isSaveButtonDisabled = this.isSaveButtonDisabled.bind(this);
    this.onUsageRightsCheckboxClick = this.onUsageRightsCheckboxClick.bind(
      this
    );
    this.handleIsLoading = this.handleIsLoading.bind(this);
    this.handleTeamFont = this.handleTeamFont.bind(this);
    this.handleUploadFont = this.handleUploadFont.bind(this);
    this.disableFontDropdown = this.disableFontDropdown.bind(this);

    const mode = props.isUpdate
      ? FontModalWrapper.UPDATE_MODE
      : FontModalWrapper.CREATE_MODE;

    const useFontName = props.teamFont
      ? props.teamFont.teamFontName === props.teamFont.fontName
      : false;

    this.state = {
      isOpen: false,
      fileUploads: null,
      mode: mode,
      teamFont: props.teamFont,
      useFontName: useFontName,
      confirmUsageRights: false,
      uploadingFonts: null,
      disableDropdown: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ isOpen: true });
    }

    if (prevProps.isUpdate !== this.props.isUpdate) {
      this.setState({
        mode: this.props.isUpdate
          ? FontModalWrapper.UPDATE_MODE
          : FontModalWrapper.CREATE_MODE
      });
    }

    if (this.state.fileUploads !== prevState.fileUploads) {
      this.disableFontDropdown();
    }

    if (
      !prevProps.isOpen &&
      this.props.isOpen &&
      !this.props.teamFont &&
      this.props.publicFonts
    ) {
      this.setState({
        teamFont: this.buildDefaultTeamFont(this.props.publicFonts)
      });
    }

    if (
      (!prevProps.teamFont && this.props.teamFont) ||
      !jsonStringEqual(prevProps.publicFonts, this.props.publicFonts)
    ) {
      this.handleTeamFont();
    }
  }

  handleTeamFont() {
    if (this.props.teamFont) {
      this.setState({ teamFont: this.props.teamFont });
      return;
    }
    this.setState({
      teamFont: this.buildDefaultTeamFont(this.props.publicFonts)
    });
  }

  buildDefaultTeamFont(publicFonts) {
    /* we try the roboto font first, otherwise fallback to first font */
    const font =
      publicFonts["c9b5a19b-3b18-4bf2-87de-fb7de2ca8214"] ||
      Object.values(publicFonts)[0];

    if (!font) return;

    return {
      fontId: font.id,
      teamFontName: "Heading",
      bold: false,
      italic: false,
      fontFamilyName: font.fontFamilyName,
      fontName: font.name
    };
  }

  handleChangeTeamFont(field, value) {
    const teamFont = this.state.teamFont;

    const teamFontUpdated = {
      ...teamFont,
      [field]: value
    };

    this.setState({ teamFont: teamFontUpdated });
  }

  onUsageRightsCheckboxClick() {
    const { confirmUsageRights } = this.state;

    this.setState({ confirmUsageRights: !confirmUsageRights });
  }

  handleChange(field, value) {
    this.setState({ [field]: value });
  }

  findFont(fontId) {
    const { publicFonts, userTeamFonts } = this.props;

    const font = publicFonts[fontId] || userTeamFonts[fontId];

    return font;
  }

  handleSelectChange(fontId) {
    const font = this.findFont(fontId);

    const teamFont = this.state.teamFont;

    const teamFontStateUpdated = {
      ...teamFont,
      fontFamilyName: font.fontFamilyName,
      fontName: font.name || font.fontName,
      fontId: fontId
    };

    this.setState({ teamFont: teamFontStateUpdated });
  }

  handleCloseFontModal() {
    this.setState({
      isOpen: false,
      fileUploads: null,
      confirmUsageRights: false
    });
    this.props.handleCloseFontModal();
  }

  createFont(teamFont) {
    const { fileUploads } = this.state;
    const { currentTeam, addFont } = this.props;

    if (fileUploads) {
      this.handleUploadFont(fileUploads, teamFont);
    } else {
      addFont({
        teamFontObject: teamFont,
        teamId: currentTeam.id
      });
    }
  }

  handleUploadFont(fileUploads, teamFont) {
    this.setState({
      uploadingFonts: fileUploads.map(() => false)
    });

    const fontUploadPromiseFunctions = fileUploads.map((file, index) => () => {
      return this.props
        .uploadFont({
          fontFile: file,
          teamFontObject: teamFont,
          teamId: this.props.currentTeam.id,
          userId: this.props.currentUser.id
        })
        .then(() => {
          const uploadingFonts = [...this.state.uploadingFonts];
          uploadingFonts[index] = true;

          this.setState({
            uploadingFonts
          });
        });
    });

    chainPromisesWithResults(fontUploadPromiseFunctions).then(() => {
      this.setState({
        uploadingFonts: null
      });
      this.setState({
        isOpen: false,
        fileUploads: null,
        confirmUsageRights: false
      });
    });
  }

  handleSaveFont() {
    const { teamFont, mode, useFontName, fileUploads } = this.state;
    const { partialUpdateFont, currentTeam } = this.props;

    const teamFontWithTeamId = { ...teamFont, teamId: currentTeam.id };

    if (useFontName) {
      teamFontWithTeamId.teamFontName = teamFontWithTeamId.fontName;
    }
    if (Array.isArray(fileUploads) && fileUploads.length) {
      teamFontWithTeamId.fontFile = fileUploads[0];
    }

    switch (mode) {
      case FontModalWrapper.UPDATE_MODE:
        partialUpdateFont(teamFontWithTeamId);
        return;

      case FontModalWrapper.CREATE_MODE:
        this.createFont(teamFontWithTeamId);
        return;

      default:
        Logger.error("FontModal invalid mode");
    }
  }

  deleteUploadedFile() {
    const teamFontStateUpdated = {
      ...this.state.teamFont,
      fontName: "Roboto-Regular"
    };

    this.setState({
      fileUploads: null,
      teamFont: teamFontStateUpdated,
      confirmUsageRights: false
    });
  }

  handleUploadForm(files) {
    const fileName = getFileNameWithoutExtension(files[0].name);

    const teamFontStateUpdated = {
      ...this.state.teamFont,
      fontFamilyName: fileName,
      fontName: fileName
    };
    if (this.state.mode === FontModalWrapper.CREATE_MODE) {
      teamFontStateUpdated.fontId = null;
    }

    const useFontName = files.length > 1 ? true : this.state.useFontName;

    this.setState({
      fileUploads: files,
      teamFont: teamFontStateUpdated,
      confirmUsageRights: false,
      useFontName
    });
  }

  handleToggleUseFontName(value) {
    this.setState({ useFontName: value });
  }

  isSaveButtonDisabled() {
    const {
      fileUploads,
      confirmUsageRights,
      teamFont,
      useFontName
    } = this.state;

    if (fileUploads && !confirmUsageRights) return true;

    if (!useFontName && (!teamFont || !teamFont.teamFontName)) return true;

    return false;
  }

  getModalButtons() {
    const { isProcessing } = this.props;

    return [
      {
        name: "Close",
        theme: "gray",
        onClick: this.handleCloseFontModal
      },
      {
        name: isProcessing ? "Saving..." : "Save Font",
        disabled: this.isSaveButtonDisabled(),
        theme: "blueSolid",
        onClick: this.handleSaveFont
      }
    ];
  }

  handleIsLoading() {
    const { isLoadingPublicFonts } = this.props;
    const { teamFont } = this.state;

    return !teamFont || isLoadingPublicFonts;
  }

  disableFontDropdown() {
    const { fileUploads } = this.state;
    const { publicFonts } = this.props;

    // When a user uploads a custom font diable font dropdown menu
    return fileUploads && publicFonts && Object.keys(publicFonts).length !== 0
      ? this.setState({ disableDropdown: true })
      : this.setState({ disableDropdown: false });
  }

  render() {
    const {
      teamFont,
      fileUploads: fontFiles,
      useFontName,
      confirmUsageRights,
      uploadingFonts,
      disableDropdown
    } = this.state;

    const { publicFonts, userTeamFonts } = this.props;

    const fontOptions = {
      publicFonts: publicFonts,
      userTeamFonts: userTeamFonts
    };

    const isLoading = this.handleIsLoading();

    const isTopSectionsHidden =
      // create mode
      this.state.mode === FontModalWrapper.CREATE_MODE &&
      // more than one font file added for uploading
      fontFiles?.length > 1;

    const header =
      this.state.mode === FontModalWrapper.CREATE_MODE
        ? "Upload Fonts"
        : "Font Preview";

    return (
      <Modal
        isOpen={this.props.isOpen}
        contentLabel={header}
        onRequestClose={this.handleCloseFontModal}
        header={header}
        className={style.fontModal}
        headerTitleClassName={style.fontModalHeader}
        buttons={this.getModalButtons()}
      >
        {isLoading ? (
          <div className={style.wrapper}>
            <Loading />
          </div>
        ) : (
          <div className={style.wrapper}>
            {!isTopSectionsHidden && (
              <>
                <FontModalHeader
                  teamFont={teamFont}
                  useFontName={useFontName}
                />
                <FontModalDescriptionSection
                  teamFont={teamFont}
                  handleChangeTeamFont={this.handleChangeTeamFont}
                  useFontName={useFontName}
                  handleToggleUseFontName={this.handleToggleUseFontName}
                />
                <div className={style.fontModalHeader}>SELECT A FONT</div>
                <FontModalDropDownSection
                  teamFont={teamFont}
                  handleSelectChange={this.handleSelectChange}
                  fontOptions={fontOptions}
                  disableDropdown={disableDropdown}
                />
                <FontModalDivider />
              </>
            )}
            <FontModalUploadSection
              handleUploadForm={this.handleUploadForm}
              confirmUsageRights={confirmUsageRights}
              deleteUploadedFile={this.deleteUploadedFile}
              hasFileUploaded={Boolean(fontFiles)}
              onUsageRightsCheckboxClick={this.onUsageRightsCheckboxClick}
              uploadingFonts={uploadingFonts}
            />
          </div>
        )}
      </Modal>
    );
  }
}

FontModalWrapper.propTypes = {
  handleCloseFontModal: PropTypes.func,
  teamFont: PropTypes.object
};

export default FontModalWrapper;
