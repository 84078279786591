import BrandKitColors from "./BrandKitColors/BrandKitColors";
import BrandKitFonts from "./BrandKitFonts/BrandKitFonts";
import BrandKitImages from "./BrandKitImages/BrandKitImages";
import BrandKitLogos from "./BrandKitLogos/BrandKitLogos";
import BrandKitAnimations from "./BrandKitAnimations/BrandKitAnimations";
import BrandKitSettings from "./BrandKitSettings";
import BrandKitSmartTextContainer from "views/components/BrandKit/BrandKitSmartText/BrandKitSmartTextContainer";
import { Route, Redirect, Switch } from "react-router-dom";
import PATHS from "routes/paths";

export const BrandKitContentSection = props => {
  const {
    addBrandKitColorGroup,
    addBrandKitFont,
    addBrandKitImage,
    addBrandKitLogo,
    addContentToTeamFolder,
    animationsState,
    brandKitAnimations,
    brandKitImages,
    brandKitLogos,
    colors,
    createTeamFolder,
    createTeamSmartImage,
    canAccessBrandKit,
    deleteTeamFolder,
    currentSubscriptionCode,
    currentTeam,
    fetchAllTeamFolders,
    fetchBrandKitColorGroups,
    fetchBrandKitFontsIfNeeded,
    fetchTeamSmartImages,
    fontModal,
    fonts,
    getFolderForMedia,
    imagesState,
    isBrandManager,
    isFetchingBrandAnimations,
    logosState,
    mediaInFolders,
    modifyQuery,
    partialUpdateBrandKitFont,
    popoutFontId,
    refetchBrandKitImages,
    refetchBrandKitLogos,
    removeBrandKitColorGroup,
    removeBrandKitFont,
    removeBrandKitImage,
    removeBrandKitLogo,
    removeContentFromTeamFolder,
    searchBrandKitImages,
    searchBrandKitLogos,
    selectedColor,
    setBrandKitState,
    setSelectedBrandColor,
    showUpgradeModal,
    smartImages,
    teamFolders,
    updateBrandKitColorGroup,
    updateBrandKitImage,
    updateBrandKitLogo,
    updateTeamFolder,
    userFonts,
    onDeleteTeamSmartImage
  } = props;

  return (
    <Switch>
      <Route
        path={PATHS.brandColors}
        render={routerProps => (
          <BrandKitColors
            addBrandKitColorGroup={addBrandKitColorGroup}
            colors={colors}
            currentSubscriptionCode={currentSubscriptionCode}
            currentTeam={currentTeam}
            fetchBrandKitColorGroups={fetchBrandKitColorGroups}
            isBrandManager={isBrandManager}
            removeBrandKitColorGroup={removeBrandKitColorGroup}
            selectedColor={selectedColor}
            setSelectedBrandColor={setSelectedBrandColor}
            showUpgradeModal={showUpgradeModal}
            updateBrandKitColorGroup={updateBrandKitColorGroup}
            {...routerProps}
          />
        )}
      />

      <Route
        path={PATHS.brandFonts}
        render={routerProps => (
          <BrandKitFonts
            addBrandKitFont={addBrandKitFont}
            currentSubscriptionCode={currentSubscriptionCode}
            currentTeam={currentTeam}
            fetchBrandKitFontsIfNeeded={fetchBrandKitFontsIfNeeded}
            fonts={fonts}
            isBrandManager={isBrandManager}
            removeBrandKitFont={removeBrandKitFont}
            showUpgradeModal={showUpgradeModal}
            partialUpdateBrandKitFont={partialUpdateBrandKitFont}
            userFonts={userFonts}
            fontModal={fontModal}
            popoutFontId={popoutFontId}
            setBrandKitState={setBrandKitState}
            {...routerProps}
          />
        )}
      />

      <Route
        path={PATHS.brandImages}
        render={routerProps => (
          <BrandKitImages
            addBrandKitImage={addBrandKitImage}
            addContentToTeamFolder={addContentToTeamFolder}
            brandKitImages={brandKitImages}
            smartImages={smartImages}
            createTeamFolder={createTeamFolder}
            currentSubscriptionCode={currentSubscriptionCode}
            currentTeam={currentTeam}
            canAccessBrandKit={canAccessBrandKit}
            deleteTeamFolder={deleteTeamFolder}
            fetchAllTeamFolders={fetchAllTeamFolders}
            isBrandManager={isBrandManager}
            removeBrandKitImage={removeBrandKitImage}
            removeContentFromTeamFolder={removeContentFromTeamFolder}
            searchBrandKitImages={searchBrandKitImages}
            showUpgradeModal={showUpgradeModal}
            teamFolders={teamFolders}
            updateBrandKitImage={updateBrandKitImage}
            updateTeamFolder={updateTeamFolder}
            refetchBrandKitImages={refetchBrandKitImages}
            modifyQuery={modifyQuery}
            mediaInFolders={mediaInFolders}
            getFolderForMedia={getFolderForMedia}
            setBrandKitState={setBrandKitState}
            fetchTeamSmartImages={fetchTeamSmartImages}
            onDeleteTeamSmartImage={onDeleteTeamSmartImage}
            createTeamSmartImage={createTeamSmartImage}
            {...routerProps}
            {...imagesState}
          />
        )}
      />

      <Route
        path={PATHS.brandLogos}
        render={routerProps => (
          <BrandKitLogos
            addBrandKitLogo={addBrandKitLogo}
            addContentToTeamFolder={addContentToTeamFolder}
            brandKitLogos={brandKitLogos}
            smartImages={smartImages}
            createTeamFolder={createTeamFolder}
            currentSubscriptionCode={currentSubscriptionCode}
            currentTeam={currentTeam}
            canAccessBrandKit={canAccessBrandKit}
            deleteTeamFolder={deleteTeamFolder}
            fetchAllTeamFolders={fetchAllTeamFolders}
            isBrandManager={isBrandManager}
            removeBrandKitLogo={removeBrandKitLogo}
            removeContentFromTeamFolder={removeContentFromTeamFolder}
            searchBrandKitLogos={searchBrandKitLogos}
            showUpgradeModal={showUpgradeModal}
            teamFolders={teamFolders}
            updateBrandKitLogo={updateBrandKitLogo}
            updateTeamFolder={updateTeamFolder}
            refetchBrandKitLogos={refetchBrandKitLogos}
            modifyQuery={modifyQuery}
            mediaInFolders={mediaInFolders}
            getFolderForMedia={getFolderForMedia}
            setBrandKitState={setBrandKitState}
            createTeamSmartImage={createTeamSmartImage}
            fetchTeamSmartImages={fetchTeamSmartImages}
            onDeleteTeamSmartImage={onDeleteTeamSmartImage}
            {...routerProps}
            {...logosState}
          />
        )}
      />

      <Route
        path={PATHS.brandAnimations}
        render={routerProps => (
          <BrandKitAnimations
            brandKitAnimations={brandKitAnimations}
            isFetchingBrandAnimations={isFetchingBrandAnimations}
            currentTeam={currentTeam}
            isBrandManager={isBrandManager}
            showUpgradeModal={showUpgradeModal}
            modifyQuery={modifyQuery}
            canAccessBrandKit={canAccessBrandKit}
            setBrandKitState={setBrandKitState}
            {...routerProps}
            {...animationsState}
          />
        )}
      />

      <Route
        path={PATHS.brandSmartText}
        render={routerProps => (
          <BrandKitSmartTextContainer
            currentTeam={currentTeam}
            modifyQuery={modifyQuery}
            canAccessBrandKit={canAccessBrandKit}
            setBrandKitState={setBrandKitState}
            {...routerProps}
          />
        )}
      />

      <Route path={PATHS.brandSettings}>
        <BrandKitSettings />
      </Route>

      <Redirect from={PATHS.brand} to={PATHS.brandColors} />
    </Switch>
  );
};

export default BrandKitContentSection;
