import { createSelector } from "reselect";
import { orderBy } from "lib";

const currencyPassThrough = ({ currency }) => currency;

const processSubscriptionPlansForCurrencySelector = (
  subscriptionPlans,
  currency
) => {
  const sameCurrencyPlans = subscriptionPlans.filter(
    plan => plan.currency === currency
  );

  return orderBy(sameCurrencyPlans, ["amount"], ["asc"]);
};

export const subscriptionPlansForCurrencySelector = createSelector(
  [({ state }) => subscriptionPlansSelector(state), currencyPassThrough],
  processSubscriptionPlansForCurrencySelector
);

const subscriptionPlansEntitiesSelector = state =>
  state.entities.subscriptionPlans;

export const processSubscriptionPlansSelector = subscriptionPlans => {
  const subscriptionsEnhanced = Object.values(subscriptionPlans).map(
    subscriptionPlan => {
      return enhanceWithSaveValue(subscriptionPlan);
    }
  );

  return subscriptionsEnhanced;

  /* helpers */

  function enhanceWithSaveValue(plan) {
    const planWithSaving = Object.assign({ save: 0 }, plan);

    if (plan.intervalPeriod === "YEARLY") {
      planWithSaving.save = calcSavingAmount(plan);
    }

    return planWithSaving;
  }

  function calcSavingAmount(plan) {
    const monthlyEqPlan = Object.values(subscriptionPlans).find(
      templatesPlan => {
        return (
          templatesPlan.code === plan.code &&
          templatesPlan.currency === plan.currency &&
          templatesPlan.intervalPeriod === "MONTHLY"
        );
      }
    );

    return monthlyEqPlan.amount * 12 - plan.amount;
  }
};

export const subscriptionPlansSelector = createSelector(
  [subscriptionPlansEntitiesSelector],
  processSubscriptionPlansSelector
);
