import React from "react";
import { connect } from "react-redux";
import TeamCreateModal from "views/components/TeamCreateModal";
import { currentTeamSelector } from "state/entities/teams/teamsSelectors";
import { createTeamAndRedirect } from "state/ui/teamPage/createTeamMenu/createTeamMenuActions";

const TeamCreateModalContainer = ({
  isOpen,
  onClose,
  currentTeam,
  onSubmitNewTeam
}) => {
  return (
    <TeamCreateModal
      isOpen={isOpen}
      onClose={onClose}
      currentTeam={currentTeam}
      onSubmitNewTeam={onSubmitNewTeam}
    />
  );
};

const mapDispatchToProps = dispatch => ({
  onSubmitNewTeam: (team, currentTeam) =>
    dispatch(createTeamAndRedirect(team, currentTeam))
});

const mapStateToProps = state => ({
  currentTeam: currentTeamSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamCreateModalContainer);
