import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ArrowOutOfBoxIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  direction = "down",
  title
}) => {
  const directionToAngle = {
    right: -90,
    down: 0,
    left: 90,
    up: 180
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction]}deg)`,
    ...style
  };

  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M20 14.667v3.555c0 .982-.796 1.778-1.778 1.778H5.778A1.778 1.778 0 0 1 4 18.222v-3.555m12.444-6.223L12 4 7.556 8.444M12 4v10.667"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
ArrowOutOfBoxIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ArrowOutOfBoxIcon;
