import React from "react";
import Form from "views/components/Form";
import { getMappedShippingRows } from "../paymentSectionUtils";
import CreditCardForm from "views/components/creditCardForm/CreditCardForm";
import { noop, getPath, some } from "lib/lodash";
import { jsonStringEqual } from "lib/equalityUtils";

export class MinimalCardInput extends CreditCardForm {
  static BASE_CONTENT_HEIGHT = 136;
  getDisplayFormatFunction(field) {
    switch (field) {
      case "number": {
        return this.formatCardNumberForDisplay;
      }
      case "expMonth": {
        return this.formatExpiryMonthForDisplay;
      }
      default: {
        return args => args;
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      // we already had a token
      prevProps.token &&
      // card in state has changed
      !jsonStringEqual(this.state.card, prevState.card)
    ) {
      this.props.onTokenClear();
    }

    if (
      // there is a wrapper ref and card just changed
      this.wrapperRef.current &&
      !jsonStringEqual(
        Object.values(this.state.card).map(field => field.error),
        Object.values(prevState.card).map(field => field.error)
      )
    ) {
      const rows = getMappedShippingRows({
        cardDetails: this.state.card,
        onBlur: noop,
        onChange: noop,
        getDisplayFormatFunction: () => noop
      });
      const offsetHeight =
        (getPath(rows, "0.0").error ? 20 : 0) +
        (some(getPath(rows, "1"), field => !!field.error) ? 20 : 0);
      const element = this.wrapperRef.current;
      element.parentElement.parentElement.style.setProperty(
        "--payment-option-height-offset-difference",
        `${offsetHeight}px`
      );
    }
  }

  render() {
    const rows = getMappedShippingRows({
      cardDetails: this.state.card,
      onBlur: this.handleFieldBlur,
      onChange: this.formChange,
      getDisplayFormatFunction: this.getDisplayFormatFunction.bind(this)
    });
    return (
      <div ref={this.wrapperRef}>
        <Form rows={rows} />
      </div>
    );
  }
}

export default MinimalCardInput;
