import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CubeIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  isSmall,
  ...props
}) => {
  if (isSmall)
    return (
      <svg
        {...props}
        data-no-pointer={noPointer}
        width={width || size || 18}
        height={height || size || 20}
        viewBox="0 0 18 20"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M-1 0h20v20H-1z" />
          <path d="M-1 0h20v20H-1z" />
          <g
            stroke={color || easilGrayXDark}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.8"
          >
            <path d="M9.712 1.175l6.4 3.344c.544.282.888.862.888 1.496v7.968c0 .634-.344 1.214-.888 1.496l-6.4 3.344c-.45.236-.981.236-1.432 0l-6.4-3.344A1.682 1.682 0 0 1 1 13.974V6.015c0-.634.344-1.214.888-1.496l6.4-3.344a1.538 1.538 0 0 1 1.424 0z" />
            <path d="M2 6l7 4 7-4M9 18.79v-9" />
          </g>
        </g>
      </svg>
    );
  return (
    <svg
      {...props}
      data-no-pointer={noPointer}
      width={width || size || 28}
      height={height || size || 30}
      viewBox="0 0 28 30"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g id="ROLES-+-Proto" stroke="none" fill="none" fillRule="evenodd">
        <path d="M-2-1h32v32H-2z" />
        <g
          stroke={color || easilGrayXDark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M15.157 1.272l10.4 5.202A2.601 2.601 0 0 1 27 8.802v12.393c0 .987-.56 1.889-1.443 2.328l-10.4 5.202a2.6 2.6 0 0 1-2.327 0l-10.4-5.202A2.601 2.601 0 0 1 1 21.183V8.801a2.6 2.6 0 0 1 1.443-2.328l10.4-5.202a2.6 2.6 0 0 1 2.314 0z" />
          <path d="M2 8l12 6 12-6M14 28.673v-14" />
        </g>
      </g>
    </svg>
  );
};
CubeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CubeIcon;
