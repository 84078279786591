import * as types from "state/entities/smartText/smartTextTypes";
import { SMART_INPUT_FIELDS } from "lib/constants";

export const initState = {
  team: {},
  user: {},
  design: {},
  isFetchingTeamSmartText: false,
  isFetchingUserSmartText: false
};

const smartFieldsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_TEAM_SMART_TEXT_REQUEST: {
      return {
        ...state,
        isFetchingTeamSmartText: true
      };
    }

    case types.FETCH_USER_TEAM_SMART_TEXT_REQUEST: {
      return {
        ...state,
        isFetchingUserSmartText: true
      };
    }

    case types.UPDATE_TEAM_SMART_TEXT_SUCCESS:
    case types.FETCH_TEAM_SMART_TEXT_SUCCESS: {
      const {
        response: { entities, ids }
      } = action;

      const responseSmartText = entities.smartText[ids].smartFields;
      const smartText = {};

      Object.keys(responseSmartText).forEach(fieldName => {
        smartText[fieldName] = {
          ...responseSmartText[fieldName],
          name: fieldName
        };
      });

      return {
        ...state,
        team: {
          ...smartText
        },
        isFetchingTeamSmartText: false
      };
    }

    case types.UPDATE_USER_TEAM_SMART_TEXT_SUCCESS:
    case types.FETCH_USER_TEAM_SMART_TEXT_SUCCESS: {
      const {
        response: { entities, ids }
      } = action;

      const responseSmartText = entities.smartText[ids].smartFields;
      const smartText = {};

      Object.keys(responseSmartText).forEach(fieldName => {
        smartText[fieldName] = {
          ...responseSmartText[fieldName],
          name: fieldName
        };
      });

      return {
        ...state,
        user: {
          ...smartText
        },
        isFetchingUserSmartText: false
      };
    }

    case types.TEAM_SMART_TEXT_SAVE_REQUEST_SUCCESS: {
      const {
        response: { entities, ids }
      } = action;

      const responseSmartText = entities.smartText[ids].smartFields;
      const smartText = {};

      Object.keys(responseSmartText).forEach(fieldName => {
        smartText[fieldName] = {
          ...responseSmartText[fieldName],
          name: fieldName
        };
      });

      return {
        ...state,
        team: {
          ...smartText
        }
      };
    }

    case types.CREATE_USER_TEAM_SMART_TEXT_REQUEST_SUCCESS: {
      const {
        response: { entities, ids }
      } = action;

      const responseSmartText = entities.smartText[ids].smartFields;
      const smartText = {};

      Object.keys(responseSmartText).forEach(fieldName => {
        smartText[fieldName] = {
          ...responseSmartText[fieldName],
          name: fieldName
        };
      });

      return {
        ...state,
        user: {
          ...smartText
        }
      };
    }

    case types.DELETE_TEAM_SMART_TEXT_REQUEST: {
      const {
        extra: { smartTextKey, teamName }
      } = action;

      const smartText = { ...state.team };

      if (smartTextKey === SMART_INPUT_FIELDS.TEAM_NAME) {
        smartText[smartTextKey].overridden = false;
        smartText[smartTextKey].value = teamName;
        smartText[smartTextKey].name = smartTextKey;
      } else {
        delete smartText[smartTextKey];
      }

      return {
        ...state,
        team: {
          ...smartText
        }
      };
    }

    case types.DELETE_USER_TEAM_SMART_TEXT_REQUEST: {
      const {
        extra: { smartTextKey, user }
      } = action;
      const smartText = { ...state.user };

      if (smartTextKey === SMART_INPUT_FIELDS.USER_NAME) {
        smartText[smartTextKey].overridden = false;
        smartText[smartTextKey].value = user.name;
        smartText[smartTextKey].name = smartTextKey;
      } else if (smartTextKey === SMART_INPUT_FIELDS.USER_EMAIL) {
        smartText[smartTextKey].overridden = false;
        smartText[smartTextKey].value = user.email;
        smartText[smartTextKey].name = smartTextKey;
      } else {
        delete smartText[smartTextKey];
      }

      return {
        ...state,
        user: {
          ...smartText
        }
      };
    }

    case types.FETCH_DESIGN_SMART_TEXT_SUCCESS: {
      const {
        response: { entities, ids }
      } = action;
      const responseSmartText = entities.smartText[ids].smartFields;
      const designSmartText = Object.keys(responseSmartText).reduce(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...responseSmartText[key],
            teamId: entities.smartText[ids].teamId
          }
        }),
        {}
      );

      return {
        ...state,
        design: {
          userId: entities.smartText[ids].userId,
          smartText: designSmartText
        }
      };
    }

    case types.UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST_SUCCESS: {
      const { userId } = action.response;
      return {
        ...state,
        design: {
          ...state.design,
          userId
        }
      };
    }

    default:
      return state;
  }
};

export default smartFieldsReducers;
