import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { CreditCardForm } from "..";
import { Button } from "views/components";
import Modal from "../modal";

class BillingCardsModal extends Component {
  state = {
    token: null
  };

  handleClose = () => {
    this.props.toggleModal({ name: "creditCard", visible: false });
  };

  handleToken = token => {
    this.setState({ token });
  };

  handleTokenFailure = () => {
    this.setState({ token: null });
  };

  handleUpdate = () => {
    this.props.addCard({
      teamId: this.props.currentTeam.id,
      token: this.state.token
    });
  };

  render() {
    const { creditCardModal, currentCard } = this.props;

    return (
      <Modal
        className={style.modalContainer}
        header={currentCard ? "Update card details" : "Add a new card"}
        isOpen={creditCardModal.visible}
        onRequestClose={this.handleClose}
      >
        <div className={style.upgradeModalWrapper}>
          <div className={style.upgradeModalPaymentDetails}>
            <CreditCardForm
              disabled={creditCardModal.submitting}
              onTokenSuccess={this.handleToken}
              onTokenFailure={this.handleTokenFailure}
            />
          </div>
          {creditCardModal.errors && (
            <div className={style.errors}>
              {creditCardModal.errors.map((error, index) => (
                <div key={`credit-card-error-${index}`} className={style.error}>
                  {error.error}: {error.value}
                </div>
              ))}
            </div>
          )}
          <div className={style.upgradeModalFooter}>
            <Button
              className={style.upgradeModalCancelButton}
              onClick={this.handleClose}
              disabled={creditCardModal.submitting}
              theme="gray"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleUpdate}
              disabled={!this.state.token}
              theme="blueSolid"
              isLoading={creditCardModal.submitting}
            >
              {currentCard ? "Update Card" : "Add Card"}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

BillingCardsModal.displayName = "BillingCardsModal";

BillingCardsModal.propTypes = {
  addCard: PropTypes.func.isRequired,
  creditCardModal: PropTypes.object.isRequired,
  currentCard: PropTypes.object,
  currentTeam: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default BillingCardsModal;
