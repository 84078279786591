import Element from "state/ui/editor/elements/Element";

class ImageElement extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "sizeAndPosition",
    "filters",
    "flipping",
    "cropping",
    "setAsBackground",
    "imageUpload",
    "outline"
  ];

  updateAttributes(attributes) {
    if (!window.easil.context.isCroppingImage) {
      return super.updateAttributes(attributes);
    }

    return new this.constructor({
      ...this,
      ...attributes
    });
  }

  canBeSetAsBackground() {
    return (
      !this.restrictions.includes("setAsBackground") &&
      !this.restrictions.includes("sizeAndPosition")
    );
  }

  getImageColors() {
    /* We use the id instead of UUID because we want to reference to the asset not the element */
    return {
      [this.id]: {
        previewUrl: this.previewSrc || this.src,
        colors: this.palette
      }
    };
  }

  convertToBackgroundType({ pageWidth, pageHeight }) {
    const scaleFactor = Math.max(
      pageHeight / this.srcHeight,
      pageWidth / this.srcWidth
    );

    const newWidth = this.srcWidth * scaleFactor;
    const newHeight = this.srcHeight * scaleFactor;

    const newTop = (pageHeight - newHeight) / 2;
    const newLeft = (pageWidth - newWidth) / 2;

    return new this.constructor({
      ...this,
      type: "background",
      angle: 0,
      width: newWidth,
      height: newHeight,
      top: newTop,
      left: newLeft,
      scale: scaleFactor,
      // backgrounds should always have no mask to begin with
      mask: {
        top: 0,
        left: 0
      }
    });
  }
}

export default ImageElement;
