import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TrashCanShortIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="TrashCanShortIcon"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M4 8h16m-2 0v10a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8m2 0V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2m-6 4v4m4-4v4"
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

TrashCanShortIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TrashCanShortIcon;
