import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Avatar, Popout, DropDownLink } from "../../components";
import PersonIcon from "views/components/icons/PersonIcon";
import ExitStandardisedIcon from "views/components/icons/ExitStandardisedIcon";

import PATHS from "routes/paths";

import style from "./style.module.css";

const UserMenu = ({
  toggleMenu,
  user,
  logout,
  displayDropdown,
  hasCaret,
  avatarClassName
}) => {
  return (
    <div className={style.container}>
      <DropDownLink
        className={`${style.iconWithDropDown}`}
        onClick={toggleMenu}
        hasCaret={hasCaret}
      >
        <Avatar
          size="40px"
          theme="light"
          src={user.avatarUrl}
          name={user.name}
          className={`${
            user.avatarUrl ? style.avatarWithImage : null
          } ${avatarClassName}`}
          doubleBorder
        />
      </DropDownLink>
      {displayDropdown && (
        <Popout
          width={337}
          top={50}
          parentWidth={32}
          onClose={toggleMenu}
          additionalOffsetHorizontal={149}
          className={style.popout}
        >
          <div id="popout-contents" data-testid="popout-contents">
            <Link to={PATHS.settings || "/"} className={style.wrapper}>
              <div className={style.linkContainer}>
                <div className={style.details} id="user-details">
                  <Avatar
                    size="40px"
                    src={user.avatarUrl}
                    name={user.name}
                    noBorder
                  />
                  <div className={style.meta}>
                    <div
                      className={style.name}
                      id="user-name"
                      data-testid="user-name"
                    >
                      {user.name}
                    </div>
                    <div
                      className={style.email}
                      id="user-email"
                      data-testid="user-email"
                    >
                      {user.email}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            <div className={style.nav}>
              <div className={style.linkSectionWrapper}>
                <Link
                  to={PATHS.settings || "/"}
                  className={style.linkContainer}
                  id="profile-link"
                  data-testid="profile-link"
                >
                  <PersonIcon
                    width="16"
                    height="16"
                    className={style.icon}
                    strokeWidth="3"
                  />
                  Profile
                </Link>
              </div>
              <div className={style.linkSectionWrapper}>
                <div
                  onClick={logout}
                  className={style.linkContainer}
                  id="logout-link"
                  data-testid="logout-link"
                >
                  <ExitStandardisedIcon
                    width="16"
                    height="16"
                    className={style.icon}
                  />
                  Logout
                </div>
              </div>
            </div>
          </div>
        </Popout>
      )}
    </div>
  );
};

UserMenu.propTypes = {
  toggleMenu: PropTypes.func,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  displayDropdown: PropTypes.bool,
  teamName: PropTypes.string,
  currentTeam: PropTypes.object
};

export default UserMenu;
