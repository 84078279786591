import { Logger, noop } from "lib";
import { sendPortalMessage } from "lib/portalUtil";

const EditorDocumentOps = {
  /**
   * Update the document attributes like title or subtitle.
   * @param {object} attributes - Document attributes to update.
   * @param {object} containerProps - Calling container properties.
   */
  updateDesignAttributes(attributes, containerProps) {
    Logger.info("EditorDocumentOps.updateDesignAttributes called");
    const { design, designData } = containerProps;

    const designUpdated = {
      ...design,
      ...attributes
    };

    let onSuccess = noop;
    if (
      attributes.hasOwnProperty("title") ||
      attributes.hasOwnProperty("subtitle")
    ) {
      onSuccess = response => {
        const design = response.entities.designs[response.ids];
        const message = {
          event: "portal.design.titleUpdate",
          design
        };
        sendPortalMessage(message);
        containerProps.updateContextState({ isEditorTitleModalOpen: false });
      };
    }

    containerProps.onUpdateDesign({ design: designUpdated, onSuccess });

    const updatedDesignState = {
      designData: designData.updateAttributes(attributes)
    };
    containerProps.updateContextState(updatedDesignState);
  }
};

export default EditorDocumentOps;
