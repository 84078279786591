import { connect } from "react-redux";
import { updateTeam } from "state/entities/teams/teamsActions";
import TeamSettingsModal from "./TeamSettingsModal";

const TeamSettingsModalContainer = ({
  isOpen,
  onCloseModal,
  team,
  roles,
  teamSettings,
  updateTeam
}) => {
  return (
    <TeamSettingsModal
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      team={team}
      roles={roles}
      teamSettings={teamSettings}
      updateTeam={updateTeam}
    />
  );
};

function mapStateToProps(state) {
  const currentTeamId = state.ui.currentTeam.id;
  const teamSettings = state.ui.teamSettings;

  return {
    team: state.entities.teams[currentTeamId],
    roles: state.currentUser.roles,
    teamSettings: teamSettings.team
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateTeam: args => dispatch(updateTeam(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSettingsModalContainer);
