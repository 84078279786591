import React, { Component } from "react";

import { Route, Switch } from "react-router";

import PATHS from "routes/paths";

import style from "./style.module.css";

import { DesignsPageWorkspaceSubHeader } from "./DesignsPageWorkspaceSubHeader/DesignsPageWorkspaceSubHeader";
import { DesignsPageTemplatesSubHeader } from "./DesignsPageTemplatesSubHeader/DesignsPageTemplatesSubHeader";

export class DesignsPageSubHeader extends Component {
  constructor(props) {
    super(props);

    this.WorkspaceSubHeaderComponent = this.WorkspaceSubHeaderComponent.bind(
      this
    );
    this.TemplatesSubHeaderComponent = this.TemplatesSubHeaderComponent.bind(
      this
    );
    this.subHeaderRef = React.createRef();
  }
  componentDidMount() {
    const {
      currentUser,
      currentTeam,
      fetchAllPersonalFolders,
      fetchAllTeamFolders
    } = this.props;

    if (currentUser && currentTeam) {
      fetchAllPersonalFolders({
        userId: currentUser.id,
        teamId: currentTeam.id
      });
      fetchAllTeamFolders({
        teamId: currentTeam.id
      });
      this.props.onFetchDesignsApproved();
      this.props.onFetchDesignsShared();
      // not fetching these at the moment since there is no place to display them
      // this.props.onFetchDesignsSharedApproved();
      this.props.onFetchDesignsSharedPendingApproval();
      // when a brand manager check for approved team designs
      if (this.props.userIsBrandManager) {
        this.props.onFetchTeamDesignsApproved();
      }
    }
  }

  WorkspaceSubHeaderComponent() {
    return (
      <DesignsPageWorkspaceSubHeader
        userHasDesignSharedWith={this.props.userHasDesignSharedWith}
        userHasApprovedDrafts={this.props.userHasApprovedDrafts}
        getPopoutTop={this.getPopoutTop}
        canSeeBrandManagerApprovedDesigns={
          this.props.canSeeBrandManagerApprovedDesigns
        }
      />
    );
  }

  TemplatesSubHeaderComponent() {
    const {
      isDesigner,
      isPurchasedTemplates: isAnyPurchasedTemplates,
      isArchivedTemplates: isAnyArchivedTemplates
    } = this.props;

    return (
      <DesignsPageTemplatesSubHeader
        getPopoutTop={this.getPopoutTop}
        isDesigner={isDesigner}
        isAnyPurchasedTemplates={isAnyPurchasedTemplates}
        isAnyArchivedTemplates={isAnyArchivedTemplates}
      />
    );
  }

  render() {
    return (
      <div className={style.subHeaderWrapper} ref={this.subHeaderRef}>
        <Switch>
          <Route
            path={PATHS.catalogue}
            render={this.TemplatesSubHeaderComponent}
          />
          <Route
            path={PATHS.workspace}
            component={this.WorkspaceSubHeaderComponent}
          />
          <Route
            path={PATHS.purchaseCollectionWithCollectionId}
            component={this.WorkspaceSubHeaderComponent}
          />
        </Switch>
      </div>
    );
  }
}

DesignsPageSubHeader.displayName = "DesignsPageSubHeader";

export default DesignsPageSubHeader;
