import { Component } from "react";
import { Modal, NoComponent } from "views/components";
import SelectionSection from "./SelectionSection";
import SubTeamSection from "./SubTeamSection";
import OrgTeamSection from "./OrgTeamSection";
import style from "./style.module.css";
import { SECTION_TYPES, TEAM_TYPE_MAP } from "./teamCreateModalUtils";

const DEFAULT_STATE = {
  currentSection: SECTION_TYPES.SELECTION,
  selectedType: SECTION_TYPES.SUBTEAM,
  newTeamName: null,
  isNewTeamDefault: false,
  isProcessing: false
};

export class TeamCreateModal extends Component {
  constructor(props) {
    super(props);

    this.getSectionDetails = this.getSectionDetails.bind(this);
    this.handleSectionTransition = this.handleSectionTransition.bind(this);
    this.setSelectedType = this.setSelectedType.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.setNewTeamName = this.setNewTeamName.bind(this);
    this.setMakeDefaultTeam = this.setMakeDefaultTeam.bind(this);
    this.handleCreateTeam = this.handleCreateTeam.bind(this);

    this.state = DEFAULT_STATE;
  }

  setSelectedType(selectedType) {
    this.setState({
      selectedType
    });
  }

  setNewTeamName(event) {
    this.setState({
      newTeamName: event.target.value
    });
  }

  setMakeDefaultTeam(value) {
    this.setState({
      isNewTeamDefault: value
    });
  }

  getSectionDetails() {
    const cancelButton = {
      name: "Cancel",
      theme: "gray",
      onClick: this.handleCloseModal
    };

    switch (this.state.currentSection) {
      case SECTION_TYPES.SUBTEAM: {
        return {
          Component: SubTeamSection,
          props: {
            newTeamName: this.state.newTeamName,
            setNewTeamName: this.setNewTeamName,
            onSectionTransition: this.handleSectionTransition,
            setMakeDefaultTeam: this.setMakeDefaultTeam
          },
          buttons: [
            cancelButton,
            {
              name: "Add Team",
              theme: "blueSolid",
              onClick: this.handleCreateTeam,
              isLoading: this.state.isProcessing
            }
          ]
        };
      }
      case SECTION_TYPES.ORGANISATION: {
        return {
          Component: OrgTeamSection,
          props: {
            newTeamName: this.state.newTeamName,
            setNewTeamName: this.setNewTeamName,
            onSectionTransition: this.handleSectionTransition,
            setMakeDefaultTeam: this.setMakeDefaultTeam
          },
          buttons: [
            cancelButton,
            {
              name: "Add Team",
              theme: "blueSolid",
              onClick: this.handleCreateTeam,
              isLoading: this.state.isProcessing
            }
          ]
        };
      }
      case SECTION_TYPES.SELECTION:
      default: {
        return {
          Component: SelectionSection,
          props: {
            setSelectedType: this.setSelectedType,
            selectedType: this.state.selectedType
          },
          buttons: [
            cancelButton,
            {
              name: "Create",
              theme: "blueSolid",
              onClick: () =>
                this.handleSectionTransition(this.state.selectedType)
            }
          ]
        };
      }
    }
  }

  handleSectionTransition(sectionKey) {
    this.setState({
      currentSection: sectionKey
    });
  }

  handleCloseModal() {
    this.setState(DEFAULT_STATE);
    this.props.onClose();
  }

  handleCreateTeam() {
    if (this.state.isProcessing) return;
    this.setState({
      isProcessing: true
    });
    this.props.onSubmitNewTeam(
      {
        type: TEAM_TYPE_MAP[this.state.selectedType],
        name: this.state.newTeamName,
        setAsDefault: this.state.isNewTeamDefault
      },
      this.handleCloseModal
    );
  }

  render() {
    const {
      Component = NoComponent,
      buttons = [],
      props
    } = this.getSectionDetails();

    return (
      <Modal
        className={style.wrapper}
        isOpen={this.props.isOpen}
        onRequestClose={this.handleCloseModal}
        buttons={buttons}
        crossClassName={style.cross}
        hasHeader={false}
        buttonsClassName={style.buttons}
      >
        <Component {...props} currentTeam={this.props.currentTeam} />
      </Modal>
    );
  }
}

export default TeamCreateModal;
