import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const LayersIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill={color || easilGrayXDark} fillRule="nonzero">
      <path d="M10.699 2.304c-.362-.126-.473-.158-.586-.175a.725.725 0 0 0-.226 0c-.113.017-.224.049-.586.175L3.027 4.5l6.274 2.196c.362.126.473.158.586.175.08.011.146.011.226 0 .113-.017.224-.049.586-.175L16.973 4.5l-6.274-2.196zm.66-1.888l7.623 2.668a1.5 1.5 0 0 1 0 2.832l-7.623 2.668c-.47.165-.688.226-.953.265-.274.04-.538.04-.812 0-.265-.04-.482-.1-.953-.265L1.018 5.916a1.5 1.5 0 0 1 0-2.832L8.641.416c.47-.165.688-.226.953-.265.274-.04.538-.04.812 0 .265.04.482.1.953.265zM18.672 15.055a1 1 0 0 1 .656 1.89l-7.93 2.753c-.487.169-.71.231-.983.271-.28.041-.55.041-.83 0-.272-.04-.496-.102-.983-.271l-7.93-2.753a1 1 0 0 1 .656-1.89l7.93 2.753c.378.132.496.165.617.182a.81.81 0 0 0 .25 0c.12-.017.24-.05.617-.182l7.93-2.753zM18.672 9.555a1 1 0 1 1 .656 1.89l-7.93 2.753c-.487.169-.71.231-.983.271-.28.041-.55.041-.83 0-.272-.04-.496-.102-.983-.271l-7.93-2.753a1 1 0 0 1 .656-1.89l7.93 2.753c.378.132.496.165.617.182a.81.81 0 0 0 .25 0c.12-.017.24-.05.617-.182l7.93-2.753z" />
    </g>
  </svg>
);
LayersIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default LayersIcon;
