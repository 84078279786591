/**
 * @desc takes a url and extracts the file extension from it
 * @param {String} url - the url to extract the extension from
 * @returns {String} the file extension for the url
 */
export const getFileExtensionFromUrl = url => {
  if (!url || typeof url !== "string") return "";
  const matches = url.match(/\.([^./(?|#)]+)($|\?|#)/);
  if (!matches || !matches.length) return "";
  return matches[1];
};

/**
 * @desc takes a file name and returns the file name without the file extension
 * @param {String} fileName - the file name to remove the extension from
 * @returns {String} the file name after the extension is removed
 */
export const getFileNameWithoutExtension = fileName => {
  if (!fileName || typeof fileName !== "string") return "";
  let lastIndex = fileName.lastIndexOf(".");
  if (lastIndex === -1) lastIndex = fileName.length;
  return fileName.substring(0, lastIndex);
};

/**
 * @desc takes a file size in bytes and returns a formatted string for displaying its size
 * @param {number} size - the file size to be formatted in bytes
 * @param {number} [decimals=2] - the number of decimals to show
 * @returns {string} formatted file size string
 */
export const formatFileSize = (size, decimals = 2) => {
  if (size === null || size === undefined || isNaN(size)) {
    return "-";
  }

  if (size < 1000) {
    return `${size} B`;
  }

  if (size >= 1000 && size < Math.pow(1000, 2)) {
    return `${(size / 1000).toFixed(0)} KB`;
  }

  if (size >= Math.pow(1000, 2) && size < Math.pow(1000, 3)) {
    return `${(size / Math.pow(1000, 2)).toFixed(decimals)} MB`;
  }

  if (size >= Math.pow(1000, 3)) {
    return `${(size / Math.pow(1000, 3)).toFixed(decimals)} GB`;
  }

  return null;
};
