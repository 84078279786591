import * as downloadTypes from "state/entities/downloads/downloadsTypes";
import * as types from "state/entities/userTeamImages/userTeamImagesTypes";
import { findPage } from "../helpers";
import { omit, isEmpty, chunk, getPath, cloneDeep } from "lib";
import { removeItem } from "lib/array/array";
import { immutableUpdate } from "lib/immutableUpdate";
import * as imageFoldersEntitiesTypes from "state/entities/imageFolders/imageFoldersTypes";
import { getParameterByName } from "lib/queryStringUtils";

export const initState = {
  pages: {
    /* isFetching: true|false
     * ids:[]
     * didInvalidate: true|false */
  },
  detailedIds: {},
  search: {
    /* searchTerm: {
     *   pages: {
     *     isFetching: true|false
     *     ids:[]
     *     didInvalidate: true|false
     *   },
     * } */
  },
  folders: {
    /* folderId: {
     *   pages: {
     *     isFetching: true|false
     *     ids:[]
     *     didInvalidate: true|false
     *   },
     * } */
  },
  totalCount: null,
  pageSize: 50
};

export const handleUserTeamImageRequest = ({ action, state }) => {
  const { page } = action.request;

  return {
    ...state,
    pages: {
      ...state.pages,
      [page]: {
        ...state.pages[page],
        isFetching: true
      }
    }
  };
};

export const handleUserTeamImageInFolderRequest = ({ action, state }) => {
  return immutableUpdate(state, {
    folders: {
      [action.request.params.folderId]: {
        $auto: {
          pages: {
            $auto: {
              [action.request.page]: {
                $auto: {
                  $merge: {
                    isFetching: true
                  }
                }
              }
            }
          }
        }
      }
    }
  });
};

export const handleUserTeamImageInFolderRequestSuccess = ({
  action,
  state
}) => {
  return immutableUpdate(state, {
    folders: {
      [action.request.params.folderId]: {
        pages: {
          [action.request.page]: {
            $merge: {
              isFetching: false,
              didInvalidate: false,
              ids: action.response.ids || []
            }
          }
        }
      }
    }
  });
};

export const handleUserTeamImageUploadRequestSuccess = ({ state, action }) => {
  const { body } = action.request;

  if (state.pages[1].ids.includes(body.id)) {
    return state;
  }

  return immutableUpdate(state, {
    pages: {
      1: {
        $auto: {
          ids: {
            $unshift: [body.id]
          }
        }
      }
    }
  });
};

const removeFromFolder = (action, state) => {
  const {
    request: {
      extra: { folderId, contentId }
    }
  } = action;
  const folder = state.folders[folderId];
  if (!folder) {
    return state;
  }
  const pages = folder.pages;

  const pageId = findPage(folder.pages, contentId);

  // get an array of contentId without the target
  const updatedPageIds = pages[pageId].ids.filter(
    imageId => imageId !== contentId
  );

  const updatedPage = Object.assign({}, pages[pageId], {
    ids: updatedPageIds
  });

  // replace the page in a clone of the current pages
  const updatedPages = Object.assign({}, pages, { [pageId]: updatedPage });

  const folders = Object.assign({}, state.folders, {
    [folderId]: Object.assign({}, folder, { pages: updatedPages })
  });

  // replace the pages with the new updated page list in state
  return Object.assign({}, state, { folders });
};

export const handleUserTeamImageRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      pages: omit(state.pages, action.request.page)
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    pages: { ...state.pages, ...updatedPage }
  };
};

export const handleUserTeamImageSearchRequest = ({ action, state }) => {
  return immutableUpdate(state, {
    search: {
      [action.request.params.searchTerm]: {
        $auto: {
          pages: {
            $auto: {
              [action.request.page]: {
                $auto: {
                  $merge: {
                    isFetching: true
                  }
                }
              }
            }
          }
        }
      }
    }
  });
};

export const handleUserTeamImageSearchRequestSuccess = ({ action, state }) => {
  return immutableUpdate(state, {
    search: {
      [action.request.params.searchTerm]: {
        pages: {
          [action.request.page]: {
            $merge: {
              isFetching: false,
              didInvalidate: false,
              ids: action.response.ids || []
            }
          }
        }
      }
    }
  });
};

export const moveFromTeamImageFolder = (action, state) => {
  const { mediaIds } = action.request.body;
  const folderId = getParameterByName("folderId", action.queryParams);
  const currentPages = getPath(state, `folders[${folderId}].pages`, {});

  const allIds = Object.values(currentPages).reduce(
    (previousValues, currentPage) => previousValues.concat(currentPage.ids),
    []
  );

  const updatedAllIds = allIds.filter(id => !mediaIds.includes(id));

  const idsSplitIntoPages = chunk(updatedAllIds, state.pageSize);

  let updatedFolderPages = currentPages;
  // when all videos have been removed from a folder
  // we need to return a single page with no ids
  if (!idsSplitIntoPages.length) {
    updatedFolderPages = {
      1: {
        isFetching: false,
        ids: [],
        didInvalidate: false,
        lastFetched: Date.now()
      }
    };
  } else {
    updatedFolderPages = idsSplitIntoPages.reduce(
      (previousPages, ids, index) => ({
        ...previousPages,
        [index + 1]: {
          isFetching: false,
          ids,
          didInvalidate: false,
          lastFetched: Date.now()
        }
      }),
      {}
    );
  }

  return immutableUpdate(initState, {
    folders: {
      $auto: {
        [folderId]: {
          $auto: {
            $set: {
              pages: updatedFolderPages
            }
          }
        }
      }
    }
  });
};

const userTeamImagesApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USER_TEAM_IMAGES_IN_FOLDER_REQUEST: {
      return handleUserTeamImageInFolderRequest({ state, action });
    }

    case types.USER_TEAM_IMAGES_IN_FOLDER_REQUEST_SUCCESS: {
      return handleUserTeamImageInFolderRequestSuccess({ state, action });
    }

    case types.USER_TEAM_IMAGES_SEARCH_REQUEST: {
      return handleUserTeamImageSearchRequest({ state, action });
    }

    case types.USER_TEAM_IMAGES_SEARCH_REQUEST_SUCCESS: {
      return handleUserTeamImageSearchRequestSuccess({ state, action });
    }

    case types.USER_TEAM_IMAGES_REQUEST: {
      return handleUserTeamImageRequest({ state, action });
    }

    case types.USER_TEAM_IMAGES_REQUEST_SUCCESS: {
      return handleUserTeamImageRequestSuccess({ state, action });
    }

    case types.USER_TEAM_IMAGE_UPLOAD_REQUEST_SUCCESS: {
      return handleUserTeamImageUploadRequestSuccess({ state, action });
    }

    case types.USER_TEAM_IMAGES_CLEAR: {
      return initState;
    }

    case types.ADD_USER_TEAM_IMAGES_PLACEHOLDERS: {
      const { mediaPlaceholders, folderId } = action;

      const mediaIds = mediaPlaceholders.map(media => media.mediaId);

      if (folderId) {
        const allFolderImageIds = Object.values(
          getPath(state, `folders.${folderId}.pages`)
        ).reduce(
          (previousValues, currentPage) =>
            previousValues.concat(currentPage.ids),
          mediaIds
        );

        // split ids into chunks of page size
        const folderImageIdsSplitIntoPages = chunk(
          allFolderImageIds,
          state.pageSize
        );

        const newPages = folderImageIdsSplitIntoPages.reduce(
          (previousPages, ids, index) => ({
            ...previousPages,
            [index + 1]: {
              isFetching: false,
              ids,
              didInvalidate: true
            }
          }),
          {}
        );

        return {
          ...state,
          folders: {
            ...state.folders,
            [folderId]: {
              ...state.folders[folderId],
              pages: newPages
            }
          }
        };
      }

      const allIds = Object.values(state.pages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        mediaIds
      );

      // split ids into chunks of page size
      const idsSplitIntoPages = chunk(allIds, state.pageSize);

      const newPages = idsSplitIntoPages.reduce(
        (previousPages, ids, index) => ({
          ...previousPages,
          [index + 1]: {
            isFetching: false,
            ids,
            didInvalidate: true
          }
        }),
        {}
      );

      return {
        ...state,
        pages: newPages
      };
    }

    case types.USER_TEAM_IMAGE_DELETE_REQUEST: {
      const {
        mediaId,
        extra: { folderId }
      } = action.request;

      const page = findPage(state.pages, action.request.mediaId);

      if (folderId) {
        const updatedAction = {
          request: {
            extra: {
              folderId,
              contentId: mediaId
            }
          }
        };
        return removeFromFolder(updatedAction, state);
      }

      if (!page) return state;

      const imageIndex = state.pages[page].ids.indexOf(mediaId);

      const pageUpdated = {
        ...state.pages[page],
        ids: removeItem(state.pages[page].ids, imageIndex)
      };
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: pageUpdated
        }
      };
    }

    case types.USER_TEAM_IMAGES_DELETE_REQUEST: {
      const { mediaIds, folderId } = action.extra;

      //Get all current pages
      const currentPages = folderId
        ? getPath(state, `folders.${folderId}.pages`, {})
        : getPath(state, `pages`, {});

      // Get all ids from all pages and concat them
      const allIds = Object.values(currentPages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        []
      );

      // Filter out ids to be deleted
      const updatedAllIds = allIds.filter(id => !mediaIds.includes(id));

      // Split ids back into their own pages
      const idsSplitIntoPages = chunk(updatedAllIds, state.pageSize);

      // rebuild new pages with updated ids
      let newPages = currentPages;
      if (!idsSplitIntoPages.length) {
        newPages = {
          1: {
            ids: []
          }
        };
      } else {
        newPages = idsSplitIntoPages.reduce(
          (previousPages, ids, index) => ({
            ...previousPages,
            [index + 1]: {
              isFetching: false,
              ids,
              didInvalidate: false
            }
          }),
          {}
        );
      }

      if (folderId) {
        return immutableUpdate(state, {
          folders: {
            $auto: {
              [folderId]: {
                $auto: {
                  $set: {
                    pages: newPages
                  }
                }
              }
            }
          }
        });
      }

      return {
        ...state,
        pages: {
          ...newPages
        }
      };
    }

    case imageFoldersEntitiesTypes.ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST_SUCCESS: {
      const {
        request: {
          body: { contentId: mediaId }
        }
      } = action;
      // get the current pages
      const pages = state.pages;

      // get the id of the page that contains our add target
      const pageId = findPage(pages, mediaId);

      // get an array of mediaIds without the target
      const updatedPageIds = pages[pageId].ids.filter(
        imageId => imageId !== mediaId
      );

      // compile mediaIds into a clone of the page
      const updatedPage = Object.assign({}, pages[pageId], {
        ids: updatedPageIds
      });

      // replace the page in a clone of the current pages
      const updatedPages = Object.assign({}, pages, { [pageId]: updatedPage });

      // replace the pages with the new updated page list in state
      return Object.assign({}, state, { pages: updatedPages });
    }

    case imageFoldersEntitiesTypes.BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS: {
      const { mediaIds } = action.request.body;

      const currentFolderId = getParameterByName(
        "folderId",
        action.queryParams
      );
      // removing from a folder
      if (currentFolderId) {
        return moveFromTeamImageFolder(action, state);
      }

      const currentRootPages = cloneDeep(state.pages);

      const allRootIds = Object.values(currentRootPages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        []
      );

      const updatedAllRootIds = allRootIds.filter(id => !mediaIds.includes(id));
      const rootIdsSplitIntoPages = chunk(updatedAllRootIds, state.pageSize);

      let updatedRootFolderPages = currentRootPages;
      // when all videos have been removed from a folder
      // we need to return a single page with no ids
      if (!rootIdsSplitIntoPages.length) {
        updatedRootFolderPages = {
          1: {
            isFetching: false,
            ids: [],
            didInvalidate: false,
            lastFetched: Date.now()
          }
        };
      } else {
        updatedRootFolderPages = rootIdsSplitIntoPages.reduce(
          (previousPages, ids, index) => ({
            ...previousPages,
            [index + 1]: {
              isFetching: false,
              ids,
              didInvalidate: false,
              lastFetched: Date.now()
            }
          }),
          {}
        );
      }

      return {
        ...initState,
        pages: updatedRootFolderPages
      };
    }

    case imageFoldersEntitiesTypes.REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST_SUCCESS: {
      return removeFromFolder(action, state);
    }

    case downloadTypes.DOWNLOAD_DESIGN_REQUEST_SUCCESS: {
      const pages = cloneDeep(state.pages);

      Object.keys(pages).forEach(pageNumber => {
        pages[pageNumber].didInvalidate = true;
      });
      return {
        ...state,
        pages
      };
    }

    default:
      return state;
  }
};

export default userTeamImagesApiReducers;
