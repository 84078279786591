import * as types from "./teamAnimationsTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import { noop, isEmpty, getPath } from "lib";
import { getAssetTypeFromQueryParams } from "views/components/Editor/sidebar/tabs/animations/animationUtils";
import { searchBrandAnimations } from "state/ui/editorAnimationSearch/editorAnimationSearchActions";
import { clearSearchTerm as editorSearchClear } from "state/ui/editorAnimationSearch/editorAnimationSearchActions";

/**
 * @desc refetches all brand animation pages currently in the teamAnimations api state
 * this function overwrites the current pages with the new ones and should only be used
 * as a hard refresh of the teamAnimations
 */
export const refetchTeamAnimationsPages = () => (dispatch, getState) => {
  const state = getState();

  const teamAnimationsStore = state.api.userTeamAnimations;

  const animationsPages = getPath(teamAnimationsStore, "animations.pages");
  const videosPages = getPath(teamAnimationsStore, "videos.pages");

  if (animationsPages) {
    Object.keys(animationsPages).forEach(page =>
      dispatch(fetchTeamAnimations({ page }))
    );
  }
  if (videosPages) {
    Object.keys(videosPages).forEach(page =>
      dispatch(fetchTeamVideos({ page }))
    );
  }
};

const shouldFetchAllTeamAnimationsNextPage = (state, page) => {
  const teamAnimationsPages = state.api.teamAnimations.animations.pages;
  if (
    !teamAnimationsPages ||
    !teamAnimationsPages[page] ||
    teamAnimationsPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getAllTeamAnimationNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchAllTeamAnimationsNextPage(getState(), args.page)) {
    return dispatch(getAllTeamAnimationNextPage(args));
  }
};

export const getAllTeamAnimationNextPage = ({
  page = 1,
  folderId,
  sortBy,
  sortOrder
} = {}) => (dispatch, getState) => {
  const state = getState();

  const teamAnimationsStore = state.api.teamAnimations;

  const pages = teamAnimationsStore.all.pages;

  if (isEmpty(pages)) {
    dispatch(fetchAllTeamAnimations({ page: 1, sortBy, sortOrder }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(fetchAllTeamAnimations({ page: 1, folderId, sortBy, sortOrder }));
    return;
  }

  if (pages[lastPage].isFetching) {
    return;
  }

  if (pages[lastPage].ids.length < teamAnimationsStore.pageSize) {
    dispatch(fetchAllTeamAnimations({ page: lastPage, sortBy, sortOrder }));
  } else {
    dispatch(fetchAllTeamAnimations({ page: lastPage + 1, sortBy, sortOrder }));
  }
};

const shouldFetchTeamAnimationsNextPage = (state, page) => {
  const teamAnimationsPages = state.api.userTeamAnimations.animations.pages;

  const numberOfPages = Object.keys(teamAnimationsPages).length;

  if (
    !teamAnimationsPages[numberOfPages]?.ids ||
    teamAnimationsPages[numberOfPages]?.ids.length <
      state.api.teamAnimations.pageSize
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  if (
    !teamAnimationsPages ||
    !teamAnimationsPages[page] ||
    teamAnimationsPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getTeamAnimationNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchTeamAnimationsNextPage(getState(), args.page)) {
    return dispatch(getTeamAnimationNextPage(args));
  }
};

export const getTeamAnimationNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();

  const teamAnimationsStore = state.api.teamAnimations;

  const pages = teamAnimationsStore.animations.pages;

  if (isEmpty(pages)) {
    dispatch(fetchTeamAnimations({ page: 1 }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(fetchTeamAnimations({ page: 1, folderId }));
    return;
  }

  if (pages[lastPage].isFetching) {
    return;
  }

  if (pages[lastPage].ids.length < teamAnimationsStore.pageSize) {
    dispatch(fetchTeamAnimations({ page: lastPage }));
  } else {
    dispatch(fetchTeamAnimations({ page: lastPage + 1 }));
  }
};

const shouldFetchTeamVideosNextPage = (state, page) => {
  const teamVideosPages = state.api.teamAnimations.videos.pages;

  const numberOfPages = Object.keys(teamVideosPages).length;

  if (
    !teamVideosPages[numberOfPages].ids ||
    teamVideosPages[numberOfPages].ids.length <
      state.api.teamAnimations.pageSize
  ) {
    // do not fetch next page if the current page had less than the pagination size
    return false;
  }

  if (
    !teamVideosPages ||
    !teamVideosPages[page] ||
    teamVideosPages[page].didInvalidate
  ) {
    return true;
  }
  return false;
};

export const getTeamVideoNextPageIfNeeded = (args = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchTeamVideosNextPage(getState(), args.page)) {
    return dispatch(getTeamVideoNextPage(args));
  }
};

export const getTeamVideoNextPage = ({ page = 1, folderId } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();

  const teamAnimationsStore = state.api.teamAnimations;

  const pages = teamAnimationsStore.videos.pages;

  if (isEmpty(pages)) {
    dispatch(fetchTeamVideos({ page: 1 }));
    return;
  }

  const lastPage = Math.max(...Object.keys(pages));

  if (!pages || !pages[lastPage]) {
    dispatch(fetchTeamVideos({ page: 1, folderId }));
    return;
  }

  if (pages[lastPage].isFetching) {
    return;
  }

  if (pages[lastPage].ids.length < teamAnimationsStore.pageSize) {
    dispatch(fetchTeamVideos({ page: lastPage }));
  } else {
    dispatch(fetchTeamVideos({ page: lastPage + 1 }));
  }
};

const userAllTeamAnimationTypes = [
  types.ALL_TEAM_ANIMATIONS_REQUEST,
  types.ALL_TEAM_ANIMATIONS_REQUEST_SUCCESS,
  types.ALL_TEAM_ANIMATIONS_REQUEST_FAILURE
];

export const fetchAllTeamAnimations = ({
  params,
  page = 1,
  types = userAllTeamAnimationTypes,
  sortBy,
  sortOrder
} = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  const _params = {
    ...params,
    sortBy,
    sortOrder: sortOrder.toLowerCase()
  };

  dispatch({
    [CALL_API]: {
      types: types,
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/animations`,
      request: {
        params: _params,
        page: page,
        pageSize: getState().api.teamAnimations.pageSize
      },
      schema: schemas.TEAM_ANIMATIONS_ARRAY,
      onSuccess: response => {}
    }
  });
};

const teamAnimationTypes = [
  types.TEAM_ANIMATIONS_REQUEST,
  types.TEAM_ANIMATIONS_REQUEST_SUCCESS,
  types.TEAM_ANIMATIONS_REQUEST_FAILURE
];

export const fetchTeamAnimations = ({
  params = {},
  page = 1,
  types = teamAnimationTypes,
  type
} = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  if (type) {
    params.type = type;
  }

  dispatch({
    [CALL_API]: {
      types: types,
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/animations`,
      request: {
        params,
        page: page,
        pageSize: getState().api.teamAnimations.pageSize
      },
      schema: schemas.TEAM_ANIMATIONS_ARRAY,
      onSuccess: response => {}
    }
  });
};

const teamVideoTypes = [
  types.TEAM_VIDEOS_REQUEST,
  types.TEAM_VIDEOS_REQUEST_SUCCESS,
  types.TEAM_VIDEOS_REQUEST_FAILURE
];

export const fetchTeamVideos = ({
  params,
  page = 1,
  types = teamVideoTypes
} = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  const _params = {
    ...params,
    type: "VIDEO"
  };

  dispatch({
    [CALL_API]: {
      types: types,
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/animations`,
      request: {
        params: _params,
        page: page,
        pageSize: getState().api.teamAnimations.pageSize
      },
      schema: schemas.TEAM_ANIMATIONS_ARRAY,
      onSuccess: response => {}
    }
  });
};

export const deleteTeamAnimation = ({
  mediaId,
  folderId,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      types: [
        types.TEAM_ANIMATION_DELETE_REQUEST,
        types.TEAM_ANIMATION_DELETE_REQUEST_SUCCESS,
        types.TEAM_ANIMATION_DELETE_REQUEST_FAILURE
      ],
      request: {
        mediaId,
        extra: {
          folderId
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/animations/${mediaId}`,
      schema: schemas.NONE,
      onSuccess: response => onSuccess(response)
    }
  });
};

export const updateTeamAnimation = ({
  mediaId,
  folderId,
  image: animation,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  // prevent error response when sending blob url
  if (animation.thumbnailUrl.startsWith("blob:")) {
    animation.thumbnailUrl = animation.previewUrl;
  }

  dispatch({
    [CALL_API]: {
      method: "PUT",
      types: [
        types.TEAM_ANIMATION_UPDATE_REQUEST,
        types.TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS,
        types.TEAM_ANIMATION_UPDATE_REQUEST_FAILURE
      ],
      request: {
        body: {
          mediaId,
          folderId,
          userId,
          teamId,
          ...animation
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/animations/${mediaId}`,
      schema: schemas.NONE,
      onSuccess: response => onSuccess(response)
    }
  });
};

/**
 * @desc take an image file and uploads it to team images
 * @param {Object} args
 * @param {Object} args.file - an object that describes the file to upload from the file picker
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which runs when the process succeeds passed the result of the request
 */
export const uploadTeamAnimation = ({
  imageFile: file,
  onSuccess,
  duration,
  onPresignedUrlCreated = noop,
  noRefetch,
  placeholderURL
}) => async (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  // synchronously generate a pre signed url for the direct upload
  const preSignedUrl = await generatePreSignedUrlPromise({ file, teamId });
  onPresignedUrlCreated(preSignedUrl.id);

  // synchronously upload the image file to the pre signed url
  await uploadTeamAnimationToPreSignedUrlPromise({
    file,
    preSignedUrl: preSignedUrl.url
  });

  // make update request to the api
  dispatch(
    uploadTeamAnimationApiCall({
      duration,
      fileId: preSignedUrl.id,
      filename: preSignedUrl.filename,
      noRefetch,
      onSuccess,
      placeholderURL
    })
  );

  /**
   * @desc taking a file object and makes a request for a pre signed image upload url and returns it on resolution
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @returns {Promise} results in a Promise that resolves when the pre signed url is retrieved containing a 'url' key
   */
  function generatePreSignedUrlPromise({ file }) {
    return new Promise((resolve, reject) => {
      dispatch(
        createTeamAnimationUploadUrl({
          filename: file.name,
          responseContentType: file.type,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }

  /**
   * @desc taking a file and a pre signed url processes the upload of the file to the passed url
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
   * @returns {Promise} results in a Promise that resolves when the image has been successfully uploaded
   */
  function uploadTeamAnimationToPreSignedUrlPromise({ file, preSignedUrl }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadTeamAnimationToPreSignedUrl({
          imageFile: file,
          preSignedUrl: preSignedUrl,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }
};

/**
 * @desc adds the details for an uploaded animation to the api
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.fileId - the id for the file that is being uploaded
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which is run when the request succeeds and is passed the results of the request
 */
export const uploadTeamAnimationApiCall = ({
  duration,
  filename,
  fileId,
  onSuccess = noop,
  noRefetch,
  placeholderURL,
  folderId
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      types: [
        types.TEAM_ANIMATION_UPLOAD_REQUEST,
        types.TEAM_ANIMATION_UPLOAD_REQUEST_SUCCESS,
        types.TEAM_ANIMATION_UPLOAD_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/animations`,
      schema: schemas.NONE,
      request: {
        body: {
          duration,
          id: fileId,
          filename,
          userId,
          teamId
        }
      },
      extra: {
        placeholderURL,
        folderId
      },
      onSuccess: response => {
        if (!noRefetch) {
          dispatch(refetchTeamAnimationsPages());
        }
        onSuccess(response);
      }
    }
  });
};

/**
 * @desc makes the api request for a pre signed url
 * @param {Object} args
 * @param {string} args.filename - the name of the file that will be uploaded
 * @param {string} args.responseContentType - the type extension for the file to be uploaded
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const createTeamAnimationUploadUrl = ({
  filename,
  responseContentType,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.TEAM_ANIMATION_UPLOAD_URL_REQUEST,
        types.TEAM_ANIMATION_UPLOAD_URL_REQUEST_SUCCESS,
        types.TEAM_ANIMATION_UPLOAD_URL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/animations/url`,
      request: {
        body: {
          teamId,
          filename,
          responseContentType
        }
      },
      onSuccess: onSuccess,
      schema: schemas.NONE
    }
  });
};

/**
 * @desc using the pre signed url passed processes the upload of a file
 * @param {Object} args
 * @param {Object} args.imageFile - an object that describes the file selected by the user in file picker
 * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
 * @param {function} args.onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} args.onFailure - a function that runs when the request fails passed the results of the request
 */
export const uploadTeamAnimationToPreSignedUrl = ({
  imageFile,
  preSignedUrl,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const headers = new Headers({
    "Content-Type": imageFile.type
  });

  const requestSpecs = {
    method: "PUT",
    headers,
    body: imageFile
  };

  fetch(preSignedUrl, requestSpecs)
    .then(function(response) {
      if (!response.ok) {
        throw Error(
          `Animation could not be upload. Error Message: ${response.statusText}`
        );
      }
      return response;
    })
    .then(onSuccess)
    .catch(onFailure);
};

export const addUploadingTeamAnimationsPlaceholders = ({
  mediaPlaceholders,
  folderId
}) => {
  return {
    type: types.ADD_TEAM_ANIMATIONS_PLACEHOLDERS,
    mediaPlaceholders,
    folderId
  };
};

export const getTeamAnimationInFolderNextPage = ({
  folderId,
  type,
  sortBy = "updatedAt",
  sortOrder = "desc"
}) => (dispatch, getState) => {
  const state = getState();
  const teamAnimationsFolderStore = state.api.teamAnimations.folders;
  const selectedFolderStore = state.api.teamAnimations.folders[folderId];
  const assetType = getAssetTypeFromQueryParams(window.location);

  if (
    isEmpty(teamAnimationsFolderStore) ||
    !selectedFolderStore ||
    isEmpty(selectedFolderStore) ||
    !selectedFolderStore[assetType] ||
    isEmpty(selectedFolderStore[assetType])
  ) {
    dispatch(
      fetchTeamAnimationsInFolder({
        page: 1,
        folderId,
        type,
        sortBy,
        sortOrder
      })
    );
    return;
  }

  const pages = selectedFolderStore[assetType].pages;

  const lastPage = Math.max(...Object.keys(pages));

  if (pages[lastPage].isFetching) {
    return;
  }

  let page = lastPage;

  /* refetch the last page if not complete, or get next page */
  if (pages[lastPage].ids.length >= state.api.teamAnimations.pageSize) {
    page = lastPage + 1;
  }

  dispatch(
    fetchTeamAnimationsInFolder({ folderId, page, type, sortBy, sortOrder })
  );
};

export const fetchTeamAnimationsInFolder = ({
  page = 1,
  folderId,
  type,
  sortBy,
  sortOrder
}) => (dispatch, getState) => {
  const actionsTypes = [
    types.TEAM_ANIMATIONS_IN_FOLDER_REQUEST,
    types.TEAM_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS,
    types.TEAM_ANIMATIONS_IN_FOLDER_REQUEST_FAILURE
  ];
  const params = { folderId, sortBy, sortOrder: sortOrder.toLowerCase() };
  if (type) {
    params.type = type;
  }

  dispatch(
    fetchTeamAnimations({
      types: actionsTypes,
      params,
      page: page
    })
  );
};

export const clearTeamAnimationsUploadErrors = () => {
  return {
    type: types.CLEAR_TEAM_ANIMATION_UPLOAD_ERRORS
  };
};

export const clearTeamAnimationsState = () => ({
  type: types.TEAM_ANIMATIONS_CLEAR
});

export const refetchTeamAnimations = args => dispatch => {
  dispatch(clearTeamAnimationsState());

  // If the args have a folderId then refetch within folders
  args.folderId
    ? dispatch(fetchTeamAnimationsInFolder(args))
    : dispatch(fetchAllTeamAnimations(args));
};

export const searchTeamAnimations = ({
  term,
  folderId,
  page = 1
}) => dispatch => {
  if (term && term !== "") {
    dispatch(searchBrandAnimations({ term, folderId, page }));
  } else {
    dispatch({ type: types.CLEAR_TEAM_ANIMATION_SEARCH_TERM });
    dispatch(editorSearchClear());
  }
};
