import { noop } from "lib";
import { BRAND_KIT_REQUEST_FONTS_SUCCESS } from "state/ui/brandKit/BrandKitTypes";
import { PUBLIC_FONTS_REQUEST_SUCCESS } from "state/entities/publicFonts/publicFontsTypes";
import { USER_TEAM_FONTS_REQUEST_SUCCESS } from "state/entities/userTeamFonts/userTeamFontsTypes";
import FontToolBox from "lib/FontToolBox";

const fontsToolbox = store => next => action => {
  const { response = {} } = action;
  const entities = response.entities || {};

  switch (action.type) {
    case PUBLIC_FONTS_REQUEST_SUCCESS: {
      FontToolBox.addFonts(entities.publicFont);
      break;
    }
    case BRAND_KIT_REQUEST_FONTS_SUCCESS: {
      FontToolBox.addFonts(entities.brandKitFont);
      break;
    }

    case USER_TEAM_FONTS_REQUEST_SUCCESS: {
      FontToolBox.addFonts(entities.userTeamFont);
      break;
    }

    default:
      noop();
  }

  next(action);
};

export default fontsToolbox;
