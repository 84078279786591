import { SmoothDiagonalArrowIcon } from "views/components/icons";

export const renderLearnMoreLink = () => {
  return (
    <a
      href="https://support.easil.com/how-to-use-smart-text/"
      style={{
        display: "flex",
        paddingLeft: "4px",
        alignItems: "center",
        fontWeight: "500"
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn More
      <div style={{ height: "16px" }}>
        <SmoothDiagonalArrowIcon size="24px" color="#2c2e2f" />
      </div>
    </a>
  );
};
