import React from "react";
import style from "./style.module.css";
import CaretIcon from "views/components/icons/CaretIcon";

const DefaultTeamOption = ({ onOptionSelection, onFilterByOption, team }) => {
  const teamCount = team.subTeamCount;
  return (
    <div className={style.dropdownItem} key={team.id}>
      <div className={style.optionContents}>
        <div className={style.optionLeft}>
          <p className={`${style.option} notranslate`}>{team.name}</p>
          {teamCount > 0 && (
            <div className={style.meta} onClick={() => onFilterByOption(team)}>
              {/* allow team drill down here */}
              <small>
                <strong>{teamCount}</strong>{" "}
                {teamCount === 1 ? "Team" : "Teams"}
              </small>
              <CaretIcon color="#d1d4d6" size="10px" />
            </div>
          )}
        </div>
        <div className={style.optionRight} onClick={onOptionSelection}>
          <p>Set as default team</p>
          <CaretIcon size={"10"} direction="right" color="#bdc0c2" />
        </div>
      </div>
    </div>
  );
};

export default DefaultTeamOption;
