import { uniq, sortBy } from "lib";

/**
 * Sort print options by the width when the option includes the 'x' character.
 */
const sortByWidth = (first, second) => {
  if (first.includes("x")) {
    const firstWidth = parseFloat(first.split('" x ')[0]);
    const secondWidth = parseFloat(second.split('" x ')[0]);
    if (firstWidth < secondWidth) {
      return -1;
    } else if (firstWidth > secondWidth) {
      return 1;
    }
    return 0;
  }
  return 0;
};

export const getSizeOptions = ({ printPricing }) => {
  return uniq(
    printPricing
      .filter(option => option.minQuantity <= 1)
      // just get the size as a string
      .map(priceOption => priceOption.sizeDescription)
      // remove any empty values (sometimes fields can be "")
      .filter(option => option)
      // manually sort if required
      .sort(sortByWidth)
  ).map(option => ({ key: option, label: option }));
};

export const getStockOptions = ({ printPricing, sizeDescription }) => {
  return uniq(
    printPricing
      .filter(option => option.minQuantity <= 1)
      .filter(
        option => !sizeDescription || option.sizeDescription === sizeDescription
      )
      // just get the stock as a string
      .map(option => option.stock)
      // remove any empty values (sometimes fields can be "")
      .filter(option => option)
  ).map(option => ({ key: option, label: option }));
};

export const getFinishingOptions = ({
  printPricing,
  sizeDescription,
  stock
}) => {
  return uniq(
    printPricing
      .filter(option => option.minQuantity <= 1)
      .filter(
        option =>
          (!sizeDescription || option.sizeDescription === sizeDescription) &&
          (!stock || option.stock === stock)
      )
      // just get the finishings as a string
      .map(option => option.finishing)
      // remove any empty values (sometimes fields can be "")
      .filter(option => option)
  ).map(option => ({ key: option, label: option }));
};

export const getClosestMatchingPrintPricingOption = ({
  printPricing,
  fields
}) => {
  // filter down to size
  const printPricingForSize = sortBy(
    printPricing.filter(
      pricingOption =>
        pricingOption.sizeDescription === fields.sizeDescription.value
    ),
    "minQuantity"
  );

  if (printPricingForSize.length === 1) {
    // only one option, needs to be this one
    return printPricingForSize[0];
  }

  // filter down to stock
  const printPricingForStock = sortBy(
    printPricingForSize.filter(
      pricingOption => pricingOption.stock === fields.stock.value
    ),
    "minQuantity"
  );

  if (!printPricingForStock.length) {
    // nothing for this stock, we need to pick the item with the nearest minQuantity from size
    return printPricingForSize
      .filter(option => option.minQuantity <= fields.quantity.value)
      .reverse()[0];
  }

  if (printPricingForStock.length === 1) {
    // only one option, needs to be this one
    return printPricingForStock[0];
  }

  // filter down to finish
  const printPricingForFinishing = sortBy(
    printPricingForStock.filter(
      pricingOption => pricingOption.finishing === fields.finishing.value
    ),
    "minQuantity"
  );

  if (!printPricingForFinishing.length) {
    // nothing for this finishing, we need to pick the item with the nearest minQuantity from stock
    return printPricingForStock
      .filter(option => option.minQuantity <= fields.quantity.value)
      .reverse()[0];
  }

  // there is at least one matching finishing, get the closest minQuantity
  return printPricingForFinishing
    .filter(option => option.minQuantity <= fields.quantity.value)
    .reverse()[0];
};

export const getDefaultPrintOption = ({ printPricing }) => {
  const fields = {
    quantity: { value: 1 },
    sizeDescription: {},
    stock: {},
    finishing: {}
  };

  const sizeOptions = getSizeOptions({ printPricing });
  fields.sizeDescription.value = sizeOptions.length ? sizeOptions[0].key : "";

  const stockOptions = getStockOptions({
    printPricing,
    sizeDescription: fields.sizeDescription.value
  });
  fields.stock.value = stockOptions.length ? stockOptions[0].key : "";

  const finishingOptions = getFinishingOptions({
    printPricing,
    sizeDescription: fields.sizeDescription.value,
    stock: fields.stock.value
  });
  fields.finishing.value = finishingOptions.length
    ? finishingOptions[0].key
    : "";

  return getClosestMatchingPrintPricingOption({ printPricing, fields });
};
