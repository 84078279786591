import React from "react";
import {
  getPathBounds,
  boundingRectOfPolygon,
  boundingRectOfCircle,
  boundingRectOfRect,
  boundingRectOfEllipse,
  getBBoxAsObject
} from "lib/svgBoundingBoxUtils";
import { isVideo } from "lib/isVideo";
import { isGif } from "lib/isGif";
import { memoizedGetGifImageSource as getGifImageSource } from "lib/mediaSourceHelpers";
import { filterSettingsToCSS, toStyleString } from "lib";
import { jsonStringEqual } from "lib/equalityUtils";
import { msInSeconds } from "lib/temporal/time";
import { handleTrimmingVideoComponent } from "lib/videoControlUtils";
import PATHS from "routes/paths";

const FULL_OPACITY_PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAH0CAMAAAD8CC+4AAAAA3NCSVQICAjb4U/gAAAABlBMVEX/AAAAAABBoxIDAAAAAnRSTlP/AOW3MEoAAABfelRYdFJhdyBwcm9maWxlIHR5cGUgQVBQMQAACJnjSk/NSy3KTFYoKMpPy8xJ5VIAA2MTLhNLE0ujRAMDAwsDCDA0MDA2BJJGQLY5VCjRAAWYGphZmhmbGZoDMYjPBQBIthTJOtRDMgAAAQlJREFUeJztwQEBAAAAgiD/r25IQAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwJcB0rEAAfHnEZcAAAAASUVORK5CYII=";
const OPAQUE_PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNg+M9QDwADgQF/e5IkGQAAAABJRU5ErkJggg==";

const getIdFromUrlString = urlString =>
  urlString
    .split("url(#")
    .join("")
    .split(")")
    .join("");
export class ImageInstructionContent extends React.Component {
  constructor(props) {
    super(props);

    this.getContentForAsset = this.getContentForAsset.bind(this);
    this.getComputedStyles = this.getComputedStyles.bind(this);
    this.useFallbackAsset = this.useFallbackAsset.bind(this);
    this.trimInterval = null;

    this.videoRef = React.createRef();
    this.state = {
      clipMaskSource: null,
      useFallback: false,
      isRenderer: PATHS.isRenderer(window.location.pathname)
    };

    this.clipTarget = React.createRef();
  }

  componentDidMount() {
    this.getAssetUrl();
  }

  componentDidUpdate(prevProps, prevState) {
    // force the svg clip path ids to be reapplied the next draw after svg version is updated
    if (prevProps.renderVersion !== this.props.renderVersion)
      setTimeout(this.getComputedStyles, 0);

    handleTrimmingVideoComponent({
      trimInterval: this.trimInterval,
      setTrimInterval: interval => {
        this.trimInterval = interval;
      },
      props: this.props,
      prevProps,
      trimSource: this.props.imageInstruction,
      videoRef: this.videoRef,
      forceStartOffset: true
    });

    if (
      !jsonStringEqual(
        this.props.imageInstruction,
        prevProps.imageInstruction
      ) ||
      this.props.isPlaying !== prevProps.isPlaying ||
      this.state.useFallback !== prevState.useFallback
    ) {
      this.getAssetUrl();
    }

    if (
      this.videoRef?.current &&
      !this.props.isPlaying &&
      prevProps.isPlaying
    ) {
      this.videoRef.current.currentTime = msInSeconds(
        this.props.imageInstruction.startOffset || 0
      );
    }
  }

  useFallbackAsset() {
    this.setState({
      useFallback: true
    });
  }

  getAssetUrlWithFallback() {
    const { src, previewSrc } = this.props.imageInstruction;

    if (this.state.isRenderer || this.state.useFallback) {
      return src;
    }

    return previewSrc || src;
  }

  async getAssetUrl() {
    let assetUrl = this.getAssetUrlWithFallback();
    if (isGif(assetUrl) || isGif(this.props.imageInstruction.previewSrc)) {
      assetUrl = await getGifImageSource({
        isPlaying: this.props.isPlaying,
        gifSource: assetUrl
      });
    }
    this.setState({ assetSource: assetUrl });
  }

  getContentForAsset(contentStyles) {
    const { imageInstruction } = this.props;
    const { assetSource } = this.state;

    if (!imageInstruction.src || this.props.isForcedFullOpacity) {
      return (
        <>
          <img
            alt=""
            src={
              this.props.isForcedFullOpacity
                ? FULL_OPACITY_PLACEHOLDER
                : OPAQUE_PLACEHOLDER
            }
            style={{
              ...(contentStyles || {}),
              width: "100vw",
              height: "100vh",
              top: 0,
              left: 0
            }}
          />
        </>
      );
    }

    const contentId = `${imageInstruction.domId}-${this.props.element.uniqueId}`;

    if (isVideo(this.props.imageInstruction.previewSrc)) {
      return (
        <>
          <video
            id={contentId}
            src={assetSource}
            loop
            style={contentStyles || {}}
            onError={this.useFallbackAsset}
            autoPlay={this.props.autoPlay}
            ref={this.videoRef}
            muted={this.props.isMuted}
          />
        </>
      );
    }

    return (
      <>
        <img
          alt=""
          id={contentId}
          src={assetSource}
          style={contentStyles || {}}
          onError={this.useFallbackAsset}
        />
      </>
    );
  }

  getComputedStyles() {
    const {
      scale,
      element,
      imageInstruction,
      isForcedFullOpacity,
      leftMaskOffset,
      topMaskOffset
    } = this.props;

    let wrapperStyles = {};
    let contentStyles = {
      height: imageInstruction.height,
      width: imageInstruction.width,
      top: imageInstruction.top,
      left: imageInstruction.left,
      position: "absolute",
      opacity: isForcedFullOpacity ? 1 : imageInstruction.opacity
    };
    let contentTransforms = "";
    if (imageInstruction.scaleX) {
      contentTransforms += `scaleX(${imageInstruction.scaleX})`;
    }
    if (imageInstruction.scaleY) {
      contentTransforms += ` scaleY(${imageInstruction.scaleY})`;
    }
    if (contentTransforms) {
      contentStyles.transform = contentTransforms;
    }

    // confirm that there is a domId
    if (this.props.imageInstruction.domId) {
      // TODO: if adding this to grid we need to ensure the instruction also has an option for grid sources here too
      // check for the svg source on the page
      const svgOnPage = document.querySelector(
        `svg[id="${element.uniqueId}-svg"]`
      );

      if (svgOnPage) {
        // with the svg source we can get the imageInstruction clip
        const clipMaskTargetOnPage = svgOnPage.querySelector(
          `g[id="${this.props.imageInstruction.domId}"]`
        );
        const clipPathLink = clipMaskTargetOnPage.getAttribute("clip-path");
        const expectedClipPathId = getIdFromUrlString(clipPathLink);

        const clipPath = svgOnPage.querySelector(
          `*[id^="${expectedClipPathId}"]`
        );
        if (!clipPath) return [wrapperStyles, contentStyles];

        const clipPathId = clipPath.getAttribute("id");

        const isClipPathIdScoped = clipPathId.includes(element.uniqueId);
        let scopedClipPathId = clipPathId;
        if (!isClipPathIdScoped) {
          scopedClipPathId = `${clipPathId}${element.uniqueId}`;

          // we need to apply the scoped id so that this clip path can be found uniquely
          clipPath.setAttribute("id", scopedClipPathId);
        }

        const clipPathSource = [...clipPath.children][0];

        let clipPathBounding = getBBoxAsObject(clipPathSource);

        if (
          !Object.values(clipPathBounding).some(
            measurement => measurement !== 0
          ) ||
          // ellipse are not computed correctly using getBBox currently
          clipPathSource.nodeName === "ellipse"
        ) {
          // all of the measurements in bounding were 0, try to get them manually
          // in case this is a browser issue
          switch (clipPathSource.nodeName) {
            case "path": {
              clipPathBounding = getPathBounds(clipPathSource);
              break;
            }
            case "rect": {
              clipPathBounding = boundingRectOfRect(clipPathSource);
              break;
            }
            case "polygon": {
              clipPathBounding = boundingRectOfPolygon(clipPathSource);
              break;
            }
            case "circle": {
              clipPathBounding = boundingRectOfCircle(clipPathSource);
              break;
            }
            case "ellipse": {
              clipPathBounding = boundingRectOfEllipse(clipPathSource);
              break;
            }
            default: {
              break;
            }
          }
        }

        const filterHex = filterSettingsToCSS(imageInstruction.filters);
        const filtersObj = toStyleString(filterHex, 1);
        const wrapperDimensionScale = this.props.instructionScale || scale || 1;
        wrapperStyles = Object.assign({}, wrapperStyles, {
          clipPath: `url(#${scopedClipPathId})`,
          width: `${(this.props.element.width - leftMaskOffset) /
            wrapperDimensionScale}px`,
          height: `${(this.props.element.height - topMaskOffset) /
            wrapperDimensionScale}px`,
          position: "absolute",
          top: topMaskOffset,
          left: leftMaskOffset,
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          filter: filtersObj,
          border: "0px",
          overflow: "hidden"
        });

        const contentTop =
          clipPathBounding.y + imageInstruction.top * imageInstruction.scaleY;
        const contentLeft =
          clipPathBounding.x + imageInstruction.left * imageInstruction.scaleX;

        contentStyles = Object.assign({}, contentStyles, {
          top:
            imageInstruction.scaleY === -1
              ? contentTop + clipPathBounding.height - imageInstruction.height
              : contentTop,
          left:
            imageInstruction.scaleX === -1
              ? contentLeft + clipPathBounding.width - imageInstruction.width
              : contentLeft
        });
      }
    }

    return [wrapperStyles, contentStyles];
  }

  render() {
    const { customStyles = {} } = this.props;
    const [wrapperStyles, contentStyles] = this.getComputedStyles();

    return (
      <div ref={this.clipTarget} style={{ ...wrapperStyles, ...customStyles }}>
        {/* ^^ the wrapper for content so that we can apply clip-path */}
        {this.getContentForAsset(contentStyles)}
      </div>
    );
  }
}

export default ImageInstructionContent;
