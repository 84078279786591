import React from "react";
import style from "./style.module.css";

import PlanComparisonFeatureSection from "./PlanComparisonFeatureSection";
import Subscriptions from "lib/subscriptions";

const featureSections = [
  {
    type: "DESIGN_TOOL",
    showHeader: true,
    header: "Design Tools"
  },
  {
    type: "ORGANIZATIONAL_TOOL",
    showHeader: true,
    header: "Organizational Tools"
  },
  {
    type: "BRAND_MANAGEMENT",
    showHeader: true,
    header: "Brand & Team Management"
  },
  {
    type: "SUPPORT",
    showHeader: false,
    header: "Priority Support"
  }
];

export const PlanComparisonFeatureGrid = ({ plans }) => {
  const planCodes = plans.map(plan => plan.code);

  const featureSectionsWithFeatures = featureSections.map(featureSection => ({
    ...featureSection,
    features: Object.values(Subscriptions.PLAN_DETAILS).filter(
      planDetail => planDetail.type === featureSection.type
    )
  }));

  return (
    <div className={style.planDescriptionGrid}>
      <div className={style.planGridHeaders}>
        <div className={style.planGridHeader}>Compare the features</div>
        {plans.map(plan => (
          <div className={style.planGridHeader}>{plan.code}</div>
        ))}
      </div>
      {featureSectionsWithFeatures.map(featureSection => (
        <PlanComparisonFeatureSection
          featureSection={featureSection}
          planCodes={planCodes}
        />
      ))}
    </div>
  );
};

export default PlanComparisonFeatureGrid;
