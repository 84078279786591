import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const AnchorEndsIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  position = "top",
  rotate,
  ...props
}) => {
  let scaleY = 1;

  if (position === "bottom") {
    scaleY = -1;
  }

  const finalStyle = {
    transform: `rotate(${rotate || 0}deg) scaleY(${scaleY})`,
    ...style
  };

  if (position === "both") {
    return (
      <svg
        {...props}
        style={finalStyle}
        data-no-pointer={noPointer}
        width={width || size || 20}
        height={height || size || 20}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
        fill={color || easilGrayXDark}
      >
        <title>{title}</title>
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h20v20H0z" />
          <g transform="translate(2 3)">
            <rect
              fill={color || easilGrayXDark}
              y="4"
              width="16"
              height="2"
              rx="1"
            />
            <path
              stroke={color || easilGrayXDark}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 1.5 8 0l2 1.5"
            />
            <rect
              fill={color || easilGrayXDark}
              y="8"
              width="16"
              height="2"
              rx="1"
            />
            <path
              stroke="#57595D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12.5 8 14l2-1.5"
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      {...props}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 20}
      height={height || size || 20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <g transform="translate(2 3)">
          <rect
            fill={color || easilGrayXDark}
            y="4"
            width="16"
            height="2"
            rx="1"
          />
          <path
            stroke={color || easilGrayXDark}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 1.5 8 0l2 1.5"
          />
          <rect
            fill={color || easilGrayXDark}
            y="8"
            width="16"
            height="2"
            rx="1"
          />
        </g>
      </g>
    </svg>
  );
};
AnchorEndsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.string
};

export default AnchorEndsIcon;
