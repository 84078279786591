import React from "react";
import style from "./style.module.css";
import { pick } from "lib/lodash";
import { MaskedVectorDraggable } from "./MaskedVector";
import VectorCroppingHandler from "./VectorCroppingHandler";
import { getElementComponent } from "views/components/Editor/elements/elementsComponentsMap";
import ResizeBox from "views/components/Editor/ResizeBox";

const VectorCropping = ({
  canvasBackground,
  finishVectorCropMoving,
  vectorElement,
  isCropping,
  pageId,
  pageOffset,
  startVectorCropMoving,
  zoom,
  startCropMaskResize,
  finishCropMaskResize
}) => {
  const cancelClick = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const getWrapperStyle = () => {
    return {
      height: canvasBackground.offsetHeight,
      width: canvasBackground.offsetWidth,
      overflow: "hidden",
      position: "absolute",
      top: 0,
      left: 0
    };
  };

  const getGrayOverlayStyle = () => {
    return {
      height: canvasBackground.offsetHeight,
      width: canvasBackground.offsetWidth,
      overflow: "hidden",
      opacity: isCropping ? 0 : 0.5
    };
  };

  const getPageStyle = () => {
    return {
      position: "absolute",
      top: pageOffset.top,
      left: pageOffset.left,
      zIndex: 4
      // opacity: isCropping ? 0 : 1
    };
  };

  const getElementStyle = () => {
    const translateX = vectorElement.left * zoom;
    const translateY = vectorElement.top * zoom;

    const transform = [
      `translate(${translateX}px, ${translateY}px)`,
      `scale(${zoom})`
    ].join(" ");

    return {
      pointerEvents: "none",
      position: "absolute",
      width: 0,
      height: 0,
      zIndex: 4,
      opacity: isCropping ? 0 : 1,
      transform: transform,
      transformOrigin: "0 0"
    };
  };

  const getRotationStyle = () => {
    return {
      transform: [
        `rotate(${vectorElement.angle}deg)`,
        `scaleX(${vectorElement.scaleX})`,
        `scaleY(${vectorElement.scaleY})`
      ].join(" ")
    };
  };

  const { ElementComponent, componentProps } = getElementComponent("vector");

  const elementData = pick(vectorElement, componentProps);

  const getElementCropStyle = () => {
    const translateX = vectorElement.left * zoom;
    const translateY = vectorElement.top * zoom;

    const transform = `translate(${translateX}px, ${translateY}px) rotate(${vectorElement.angle}deg)`;

    return {
      position: "absolute",
      width: `${vectorElement.width * zoom}px`,
      height: `${vectorElement.height * zoom}px`,
      transform,
      pointerEvents: "none",
      zIndex: 4,
      opacity: isCropping ? 0 : 1
    };
  };

  const Handler = props => {
    return (
      <VectorCroppingHandler
        vectorElement={vectorElement}
        startCropMaskResize={startCropMaskResize}
        finishCropMaskResize={finishCropMaskResize}
        pageId={pageId}
        {...props}
      />
    );
  };

  return (
    <div onClick={cancelClick} id="VectorCroppingDiv" style={getWrapperStyle()}>
      <div className={style.VectorCropping} style={getGrayOverlayStyle()} />
      <div style={getPageStyle()}>
        <MaskedVectorDraggable
          finishVectorCropMoving={finishVectorCropMoving}
          vectorElement={vectorElement}
          isCropping={isCropping}
          pageOffset={pageOffset}
          startVectorCropMoving={startVectorCropMoving}
          zoom={zoom}
        />
        <div style={getElementStyle()} id="VectorCroppingElementDiv">
          <div>
            <ElementComponent
              zoom={zoom}
              style={getRotationStyle()}
              key={vectorElement.uniqueId}
              elementId={vectorElement.uniqueId}
              elementData={elementData}
            />
          </div>
        </div>
        <div style={getElementCropStyle()} id="VectorCroppingMaskResizeBox">
          <ResizeBox Handler={Handler} pageId={pageId} isCropping={true} />
        </div>
      </div>
    </div>
  );
};

export default VectorCropping;
