import PATHS from "routes/paths";
import { showEditorErrorModal } from "state/ui/editorErrorModal/editorErrorModalActions";
import { sendPortalMessage } from "lib/portalUtil";

export const errorTypes = {
  LockedError: {
    path: PATHS.workspaceDrafts
  },
  AuthorizationError: {
    path: PATHS.workspaceDrafts
  }
};

export const handleDesignRequestFailure = (response, dispatch, location) => {
  if (!PATHS.isEditorPath(location.pathname)) {
    return;
  }

  if (Object.keys(errorTypes).includes(response.error?.name)) {
    sendPortalMessage("error.portal.design_not_allowed");

    // make a call to get the editor error modal up
    dispatch(
      showEditorErrorModal({
        user: response.error.lastEditedBy,
        errorType: response.error.name,
        path: errorTypes[response.error.name].path
      })
    );
  }
};
