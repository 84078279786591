import React from "react";
import style from "./style.module.css";

const ColorSample = ({
  color,
  onClick,
  highlight = false,
  isSquare = false
}) => (
  <div
    onClick={onClick}
    className={[
      style.colorSample,
      color === "transparent" ? style.colorSampleTransparent : "",
      isSquare ? style.colorSampleSquare : ""
    ].join(" ")}
    style={{
      backgroundColor: color === "transparent" ? "#ffffff" : color,
      boxShadow: highlight ? "0 0 0 2px white, 0 0 0 3px #1c1d1e" : "none"
    }}
  />
);

export default ColorSample;
