import { getPath } from "lib";

export const collectionApiSelector = state => state.api.collections;

export const collectionByIdSelector = (state, collectionId) =>
  collectionApiSelector(state)[collectionId];

export const collectionSubscriptionCodeSelector = (state, collectionId) => {
  return getPath(
    collectionApiSelector(state)[collectionId],
    "subscriptionCode",
    ""
  );
};

export const collectionPricingSelector = (state, collectionId) => {
  return getPath(
    collectionApiSelector(state)[collectionId],
    "collectionPrice",
    {}
  );
};
