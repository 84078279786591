import React, { Component } from "react";
import { connect } from "react-redux";
import ImagesUploadModal from "./ImagesUploadModal";
import {
  uploadUserTeamAnimation,
  clearUserTeamAnimationsUploadErrors,
  addUploadingAnimationsPlaceholders
} from "state/entities/userTeamAnimations/userTeamAnimationsActions";
import { getAllAnimationsFolders } from "state/entities/userTeamAnimationsFolders/userTeamAnimationsFoldersSelectors";
import {
  fetchAllAnimationFoldersIfNeeded,
  uploadAnimationToFolder
} from "state/entities/userTeamAnimationsFolders/userTeamAnimationsFoldersActions";
import { getParameterByName } from "lib/queryStringUtils";
import {
  ANIMATED_ASSET_FILE_TYPES,
  BASIC_SUBSCRIPTION_ANIMATED_ASSET_FILE_TYPES
} from "lib/constants";
import { userTeamAnimationsErrorSelector } from "state/api/userTeamAnimations/userTeamAnimationsApiSelector.js";
import { getUserSubscriptionPlan } from "state/entities/subscriptionPlans/subscriptionPlansSelectors";
import {
  modifyQuery,
  navigateToAssetPrioritySubtab
} from "state/ui/navigation/navigationActions";
export class AnimationsUploadModalContainer extends Component {
  render() {
    return <ImagesUploadModal {...this.props} destinationName="My Videos" />;
  }
}

const mapStateToProps = state => {
  const userSubscriptionPlan = getUserSubscriptionPlan(state);
  const acceptedFileTypes = userSubscriptionPlan.isBasicPlan
    ? BASIC_SUBSCRIPTION_ANIMATED_ASSET_FILE_TYPES
    : ANIMATED_ASSET_FILE_TYPES;

  return {
    acceptedFileTypes,
    imageFolders: getAllAnimationsFolders(state),
    folderId: getParameterByName("folderId", window.location.search),
    uploadOrigin: "userTeamAnimation",
    uploadErrors: userTeamAnimationsErrorSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUsersImageFolders: args =>
      dispatch(fetchAllAnimationFoldersIfNeeded(args)),
    onUploadUserTeamImage: args => dispatch(uploadUserTeamAnimation(args)),
    onUploadImageToFolder: args => dispatch(uploadAnimationToFolder(args)),
    clearUploadErrors: () => dispatch(clearUserTeamAnimationsUploadErrors()),
    addUploadingAnimationsPlaceholders: args =>
      dispatch(addUploadingAnimationsPlaceholders(args)),
    modifyQuery: args => dispatch(modifyQuery(args)),
    handleUploadTabChange: args => dispatch(navigateToAssetPrioritySubtab(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnimationsUploadModalContainer);
