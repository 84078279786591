import {
  calculateScalingFactor,
  resizeElementProportionally
} from "../scalingTools";
import SnapElement from "lib/SnapSystems/SnapElement/SnapElement";

class ScalingCalculation {
  static getClosestPoint(pointsList, point) {
    var closestPoint = null;
    var distanceToClosestPoint = Infinity;

    pointsList.some((Coord, index) => {
      const distanceToSnapPoint = Coord - point;
      const moduleOfDistanceToSnapPoint = Math.abs(distanceToSnapPoint);

      const nextPoint = pointsList[index + 1] || Infinity;
      const distanceToNextSnapPoint = Math.abs(nextPoint - point);

      if (
        moduleOfDistanceToSnapPoint > SnapElement.THRESHOLD ||
        moduleOfDistanceToSnapPoint > distanceToNextSnapPoint
      ) {
        return false;
      }

      distanceToClosestPoint = distanceToSnapPoint;
      closestPoint = Coord;

      return true;
    });

    return [closestPoint, distanceToClosestPoint];
  }

  static MIN_SCALE = 0.1;

  constructor({ dragItem, differenceFromInitialOffset }) {
    this.snapPoints = dragItem.snapSystem.snapPointsCorrected;
    this.handlerInitialPosition = dragItem.handlerInitialPosition;
    this.handlerCoordinateName = dragItem.handlerCoordinateName;
    this.anchorPoint = dragItem.anchorPoint;
    this.canvasPositionAndDimensions = dragItem.canvasPositionAndDimensions;
    this.differenceFromInitialOffset = differenceFromInitialOffset;
    this.dragItem = dragItem;
  }

  scaleElements({ elements, zoom, scale }) {
    return elements.map(element =>
      resizeElementProportionally({
        element,
        anchorPoint: this.anchorPoint,
        dragItem: this.dragItem,
        differenceFromInitialOffset: this.differenceFromInitialOffset,
        scale,
        zoom,
        offset: this.canvasPositionAndDimensions
      })
    );
  }

  get scaleBeforeSnap() {
    if (this._scaleBeforeSnap) {
      return this._scaleBeforeSnap;
    }

    return (this._scaleBeforeSnap =
      calculateScalingFactor({
        anchorPoint: this.anchorPoint,
        handlerInitialPosition: this.handlerInitialPosition,
        differenceFromInitialPosition: this.differenceFromInitialOffset
      }) || ScalingCalculation.MIN_SCALE);
  }

  get boxAxis() {
    if (this._boxAxis) {
      return this._boxAxis;
    }

    return (this._boxAxis = {
      x: this.handlerInitialPosition.x - this.anchorPoint.x,
      y: this.handlerInitialPosition.y - this.anchorPoint.y
    });
  }

  get currentPosition() {
    if (this._currentPosition) {
      return this._currentPosition;
    }

    const xIncrement = this.boxAxis.x * this.scaleBeforeSnap;
    const yIcrement = this.boxAxis.y * this.scaleBeforeSnap;

    return (this._currentPosition = {
      x: this.anchorPoint.x + xIncrement,
      y: this.anchorPoint.y + yIcrement
    });
  }

  snapXPoint(point) {
    const scale = (point - this.anchorPoint.x) / this.boxAxis.x;

    return {
      scale,
      snapPointX: point,
      snapPointY: null
    };
  }

  snapYPoint(point) {
    const scale = (point - this.anchorPoint.y) / this.boxAxis.y;

    return {
      scale,
      snapPointX: null,
      snapPointY: point
    };
  }

  noSnapPoint() {
    return {
      scale: this.scaleBeforeSnap,
      snapPointX: null,
      snapPointY: null
    };
  }

  calculateScale() {
    const [closestXPoint, distanceToClosestXPoint] = this.getClosestXInfo();
    const [closestYPoint, distanceToClosestYPoint] = this.getClosestYInfo();

    if (closestXPoint && closestYPoint) {
      if (distanceToClosestXPoint < distanceToClosestYPoint) {
        return this.snapXPoint(closestXPoint);
      } else {
        return this.snapYPoint(closestYPoint);
      }
    }

    if (closestXPoint) {
      return this.snapXPoint(closestXPoint);
    }

    if (closestYPoint) {
      return this.snapYPoint(closestYPoint);
    }

    return this.noSnapPoint();
  }

  getClosestYInfo() {
    return ScalingCalculation.getClosestPoint(
      this.snapPoints.y,
      this.currentPosition.y
    );
  }

  getClosestXInfo() {
    return ScalingCalculation.getClosestPoint(
      this.snapPoints.x,
      this.currentPosition.x
    );
  }
}

export default ScalingCalculation;
