import React from "react";
import style from "./style.module.css";

import CaretSlimIcon from "views/components/icons/CaretSlimIcon";

const PendingApproval = ({ event, onRedirectToTeamApproval }) => {
  const designWording = event.count === 1 ? "design" : "designs";

  return (
    <div
      className={style.wrapper}
      onClick={() => onRedirectToTeamApproval({ teamId: event.teamId })}
    >
      <div className={style.text}>
        <span className={style.count}>{event.count}</span> {designWording}{" "}
        requesting approval in
        <br />
        <span className={style.teamName}>{event.teamName}.</span>
      </div>
      <div>
        <CaretSlimIcon direction="right" size={10} color="#abd0e8" />
      </div>
    </div>
  );
};

PendingApproval.displayName = "PendingApproval";

PendingApproval.propTypes = {};

export default PendingApproval;
