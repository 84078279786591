import { createSelector } from "reselect";
import { isNil } from "lib";

export const editorImageSearchPersonalSelector = state =>
  state.ui.editorImageSearch.personal;

const editorImageSearchStockSelector = state =>
  state.ui.editorImageSearch.stock;

const getImagesByTerm = ({ imagesStore, canBeDeleted = false }) => {
  const terms = Object.keys(imagesStore.terms);

  const imagesByTerm = {};

  terms.forEach(term => {
    const termObject = imagesStore.terms[term];

    const formatImage = imageId => {
      return {
        ...termObject.images[imageId],
        canBeDeleted
      };
    };

    if (isNil(termObject.ids)) {
      imagesByTerm[term] = null;
    } else {
      imagesByTerm[term] = termObject.ids.map(formatImage);
    }
  });

  return imagesByTerm;
};

export const getEditorUserImagesSearch = createSelector(
  [editorImageSearchPersonalSelector],
  imagesStore => getImagesByTerm({ imagesStore, canBeDeleted: true })
);

export const getEditorStockSearch = createSelector(
  [editorImageSearchStockSelector],
  imagesStore => getImagesByTerm({ imagesStore })
);
