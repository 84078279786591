import React from "react";
import { connect } from "react-redux";
import InviteToTeamForm from "./InviteToTeamForm";
import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import {
  inviteToTeamFormAddNewEntry,
  inviteToTeamFormRemoveEntry,
  inviteToTeamFormReset
} from "state/ui/inviteToTeamForm/inviteToTeamFormActions";

const InviteToTeamFormContainer = props => {
  return <InviteToTeamForm {...props} />;
};

InviteToTeamFormContainer.displayName = "InviteToTeamFormContainer";

const mapStateToProps = state => ({
  currentTeamSubscriptionPlan: currentSubscriptionPlan(state),
  form: state.ui.inviteToTeamForm
});

const mapDispatchToProps = dispatch => {
  return {
    handleNewEntry: entry => dispatch(inviteToTeamFormAddNewEntry(entry)),
    handleRemoveEntry: entryIndex =>
      dispatch(inviteToTeamFormRemoveEntry(entryIndex)),
    handleResetForm: () => dispatch(inviteToTeamFormReset())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteToTeamFormContainer);
