import React from "react";
import style from "./style.module.css";
import { IconButton } from "views/components";
import DesignSizeCategories from "./DesignSizeCategories";
import Tooltip from "views/components/Tooltip";

export const InfoPanelBottom = ({
  isCollection,
  collectionDesignsCategorised,
  handleMouseEnter,
  handleMouseLeave,
  isHighlighted,
  handleChangePreview,
  buttons,
  isPurchaseCollectionModal,
  ButtonDivider = () => null,
  ButtonBefore = () => null,
  ButtonAfter = () => null
}) => {
  return (
    <div className={style.infoPanelBottom}>
      {isCollection && (
        <DesignSizeCategories
          collectionDesignsCategorised={collectionDesignsCategorised}
          handleChangePreview={handleChangePreview}
          isHighlighted={isHighlighted}
        />
      )}
      <div className={style.infoPanelButtons} data-testid="infoPanelButtons">
        {isPurchaseCollectionModal && <ButtonBefore />}
        {buttons
          .filter(button => button.visible)
          .map(button => (
            <>
              <div
                onMouseEnter={() => handleMouseEnter(button.id)}
                onMouseLeave={handleMouseLeave}
                key={button.label}
                data-tip={`Admin role required. \nMust be in Parent Team`}
                data-for={`user-button-tip-${button.label}`}
              >
                <IconButton
                  Icon={button.icon}
                  iconSize={button.iconSize}
                  iconBoxSize={button.iconBoxSize || "20"}
                  iconColor="#ffffff"
                  title={button.label}
                  titleClassName={`${style.editButtonTitle} ${button.titleClassName}`}
                  className={style.editButton}
                  onClick={button.action}
                  isDisabled={button.isDisabled}
                  buttonTheme="blueSolid"
                />
                {button.isDisabled && (
                  <Tooltip
                    id={`user-button-tip-${button.label}`}
                    place="bottom"
                    multiline
                  />
                )}
              </div>
              {isPurchaseCollectionModal && <ButtonDivider />}
            </>
          ))}
        {isPurchaseCollectionModal && <ButtonAfter />}
      </div>
    </div>
  );
};

InfoPanelBottom.displayName = "InfoPanelBottom";

export default InfoPanelBottom;
