import React, { Component } from "react";
import PropTypes from "prop-types";
import { DropTarget } from "react-dnd";
import { SIDEBAR_IMAGE_ASSET_TYPES } from "lib/constants";

const spec = {
  drop(props, monitor) {
    const { domId, onImageDrop } = props;
    const item = monitor.getItem();
    const assetElement = item.imageElement || item.videoElement;
    if (item) {
      onImageDrop({
        domId,
        imageInstruction: Object.assign({}, assetElement, { domId }),
        assetElement: item.element
      });
    }

    return {
      wasImageDropHandled: true
    };
  },

  hover(props, monitor) {
    const { domId, onImageDragHover } = props;
    const item = monitor.getItem();

    const assetElement = item.imageElement || item.videoElement;

    if (assetElement) {
      onImageDragHover({
        domId,
        imageInstruction: Object.assign({}, assetElement, { domId })
      });
    }
  },

  canDrop(props, monitor) {
    const monitorItem = monitor.getItem();
    const assetElement = monitorItem.imageElement || monitorItem.videoElement;
    return assetElement;
  }
};

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  hoverItem:
    monitor.getItem() &&
    (monitor.getItem().imageElement || monitor.getItem().videoElement)
      ? monitor.getItem().imageElement || monitor.getItem().videoElement
      : null
});

class ImageInstructionDropZone extends Component {
  static propTypes = {
    domId: PropTypes.string,
    top: PropTypes.number,
    left: PropTypes.number,
    scale: PropTypes.number,
    zoom: PropTypes.number,
    fillSpace: PropTypes.bool,
    connectDropTarget: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isOver && !nextProps.isOver) {
      this.props.onImageDragLeave({ domId: this.props.domId });
    }
  }

  render() {
    const {
      domId,
      width,
      height,
      top = 0,
      left = 0,
      connectDropTarget,
      fillSpace = false,
      topMaskOffset = 0,
      leftMaskOffset = 0
    } = this.props;

    const style = {
      height: fillSpace ? "100%" : height,
      width: fillSpace ? "100%" : width,
      top: fillSpace ? 0 : top + topMaskOffset,
      left: fillSpace ? 0 : left + leftMaskOffset,
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 99999
    };

    const Component = (
      <div
        className="imageInstructionDropZone"
        onClick={event => this.props.onClick(event, domId)}
        onDoubleClick={event =>
          this.props.onDoubleClick && this.props.onDoubleClick(event, domId)
        }
        style={style}
      />
    );

    return connectDropTarget ? connectDropTarget(Component) : Component;
  }
}

const ImageInstructionDropZoneDropTarget = DropTarget(
  SIDEBAR_IMAGE_ASSET_TYPES,
  spec,
  collect
)(ImageInstructionDropZone);

const getCanDrop = ({
  imageDoesExist,
  canAddImageElement,
  canReplaceImage
}) => {
  // image replace is not allowed
  if (!canReplaceImage) {
    return false;
  }
  // we are not performing a replace
  // we are not allowed to add an image
  if (!imageDoesExist && !canAddImageElement) {
    return false;
  }
  return true;
};

const ImageInstructionDropZoneDefault = ({
  // if there is already an image (indicates are replace operation on drop)
  imageDoesExist,
  restrictionsForDocument,
  restrictionsForElement,
  ...props
}) => {
  // we can only use brand images
  const brandImagesOnly = (restrictionsForDocument || []).includes(
    "addPhotoElement"
  );
  // we can add image elements to the canvas
  const canAddImageElement = !(restrictionsForDocument || []).includes(
    "addImageElement"
  );
  // we can replace the image on this target
  const canReplaceImage = !(restrictionsForElement || []).includes(
    "imageUpload"
  );
  const canDrop = getCanDrop({
    imageDoesExist,
    brandImagesOnly,
    canAddImageElement,
    canReplaceImage
  });
  return canDrop ? (
    // have the dropzone wrapped in a target to drop onto (intercepts drop)
    <ImageInstructionDropZoneDropTarget {...props} />
  ) : (
    // just the dropzone, no drop intercept
    <ImageInstructionDropZone {...props} />
  );
};

export default ImageInstructionDropZoneDefault;
