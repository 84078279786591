import React from "react";

import TextMaskingCropping from "./TextMaskingCropping";
import GridCellCropping from "./gridCellCropping";
import PhotoFrameCropping from "./photoFrameCropping";
import ImageCropping from "./ImageCropping";
import VideoCropping from "./VideoCropping";
import VectorCropping from "./VectorCropping";

const CropLayer = React.memo(({ canvasBackground }) => {
  /* TODO: Add logic here to render only one, and remove logic from each */
  return (
    <div>
      <GridCellCropping canvasBackground={canvasBackground} />
      <TextMaskingCropping canvasBackground={canvasBackground} />
      <PhotoFrameCropping canvasBackground={canvasBackground} />
      <ImageCropping canvasBackground={canvasBackground} />
      <VideoCropping canvasBackground={canvasBackground} />
      <VectorCropping canvasBackground={canvasBackground} />
    </div>
  );
});

export default CropLayer;
