import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PositionIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 18 14"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-3h20v20H-1z" />
      <g fill={color || easilGrayXDark} fillRule="nonzero">
        <path d="M14.505 4.5L9.784 2.434c-.392-.171-.516-.213-.648-.237a.748.748 0 0 0-.272 0c-.132.024-.256.066-.648.237L3.495 4.5l4.721 2.066c.392.171.516.213.648.237a.748.748 0 0 0 .272 0c.132-.024.256-.066.648-.237L14.505 4.5zM10.585.602l5.769 2.524a1.5 1.5 0 0 1 0 2.748l-5.769 2.524c-.525.23-.785.319-1.105.375a2.746 2.746 0 0 1-.96 0c-.32-.056-.58-.145-1.105-.375L1.646 5.874a1.5 1.5 0 0 1 0-2.748L7.415.602C7.94.372 8.2.283 8.52.227c.323-.056.637-.056.96 0 .32.056.58.145 1.105.375z" />
        <path d="M15.592 8.587a1 1 0 1 1 .816 1.826l-6.168 2.753c-.402.18-.598.25-.845.296-.265.05-.525.05-.79 0-.247-.046-.443-.117-.845-.296l-6.168-2.753a1 1 0 0 1 .816-1.826l6.167 2.753c.271.12.341.146.4.157.021.004.029.004.05 0 .059-.011.129-.036.4-.157l6.167-2.753z" />
      </g>
    </g>
  </svg>
);
PositionIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PositionIcon;
