import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import * as types from "./collectionTypes";
import { designById } from "state/entities/designs/designsSelectors";
import { noop } from "lib";
import { currentUserIdSelector } from "state/currentUser/currentUserSelectors";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";

export const updateCollection = ({ collectionId, onSuccess, status }) => {
  if (!collectionId) {
    throw new Error("Invalid collectionId.");
  }

  if (!status) {
    throw new Error("Invalid status.");
  }

  return {
    [CALL_API]: {
      endpoint: `/collections/${collectionId}`,
      method: "PUT",
      onSuccess,
      request: {
        body: {
          collectionId,
          status
        }
      },
      service: SERVICES.ASSET,
      schema: schemas.NONE,
      types: [
        types.UPDATE_REQUEST,
        types.UPDATE_REQUEST_SUCCESS,
        types.UPDATE_REQUEST_FAILURE
      ]
    }
  };
};

export const copyCollectionToDrafts = ({ collectionId, onSuccess }) => (
  dispatch,
  getState
) => {
  if (!collectionId) {
    throw new Error("Invalid collectionId.");
  }

  const state = getState();
  const userId = currentUserIdSelector(state);
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      endpoint: `/teams/${teamId}/users/${userId}/collections`,
      method: "POST",
      onSuccess,
      request: {
        body: {
          parentId: collectionId,
          userId,
          teamId
        }
      },
      service: SERVICES.ASSET,
      schema: schemas.NONE,
      types: [
        types.COPY_TO_DRAFT_REQUEST,
        types.COPY_TO_DRAFT_REQUEST_SUCCESS,
        types.COPY_TO_DRAFT_REQUEST_FAILURE
      ]
    }
  });
};

/**
 * @desc Set the requiresApproval property of all designs for a collection
 * @param {string} collectionId - the identifier for the collection to update requiresApproval for
 */
export const updateCollectionRequiresApprovalStatus = ({
  collectionId,
  requiresApproval,
  onSuccess = noop
}) => {
  if (!collectionId) {
    throw new Error("Invalid collectionId.");
  }

  return {
    [CALL_API]: {
      endpoint: `/collections/${collectionId}/requires-approval`,
      method: "PUT",
      onSuccess,
      request: {
        body: {
          collectionId,
          requiresApproval
        }
      },
      service: SERVICES.ASSET,
      schema: schemas.NONE,
      types: [
        types.REQUIRES_APPROVAL_UPDATE_REQUEST,
        types.REQUIRES_APPROVAL_UPDATE_REQUEST_SUCCESS,
        types.REQUIRES_APPROVAL_UPDATE_REQUEST_FAILURE
      ]
    }
  };
};

export const toggleCollectionRequiresApprovalStatus = ({
  designId,
  onSuccess
}) => (dispatch, getState) => {
  const state = getState();
  const design = designById({ state, designId });

  dispatch(
    updateCollectionRequiresApprovalStatus({
      collectionId: design.collectionId,
      requiresApproval: !design.requiresApproval,
      onSuccess
    })
  );
};
