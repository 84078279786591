import * as types from "./takeoverSharedDesignModalTypes";
import { LOCATION_CHANGE } from "react-router-redux";
import PATHS from "routes/paths";

export const initState = {
  isVisible: false,
  designId: null
};

const takeoverSharedDesignModalReducers = (state = initState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (
        // came from the reset-password page and went somewhere else
        action.pathname &&
        PATHS.isWorkspaceDraftsPath(action.pathname) &&
        !PATHS.isWorkspaceDraftsPath(action.payload.pathname)
      ) {
        return initState;
      }
      return state;
    }

    case types.SHOW_TAKEOVER_MODAL: {
      // make the modal visible
      return {
        ...state,
        isVisible: true,
        designId: action.designId
      };
    }

    case types.HIDE_TAKEOVER_MODAL: {
      // hide the modal
      return {
        ...state,
        isVisible: false,
        designId: null
      };
    }

    default:
      return state;
  }
};

export default takeoverSharedDesignModalReducers;
