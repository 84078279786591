import * as types from "./usersTypes";
import * as teamUserSearchTypes from "state/entities/teamUsersSearch/teamUsersSearchTypes";

import { merge, omit } from "lib";

export const initState = {};

const usersReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USERS_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;

      return merge({}, state, entities.users);
    }

    case types.USER_ROLES_UPDATE_REQUEST: {
      const { userId, roles } = action.request.body;

      return {
        ...state,
        [userId]: { ...state[userId], roles }
      };
    }

    case types.USER_DELETE_REQUEST: {
      const { id } = action.request.user;

      return {
        ...omit(state, id)
      };
    }

    case teamUserSearchTypes.SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST_SUCCESS:
    case teamUserSearchTypes.SEARCH_USERS_IN_TEAM_REQUEST_SUCCESS: {
      const { entities } = action.response;

      if (!action.response || !entities) return state;

      return merge({}, state, entities.users);
    }

    default:
      return state;
  }
};

export default usersReducers;
