export const isSvg = file => {
  if (!file || !file.type) return false;

  return file.type.toLowerCase().includes("svg");
};

export const isVector = url => {
  if (!url) return false;
  const fileExtension = url.split(".").pop();
  if (fileExtension && fileExtension.toLowerCase() === "svg") {
    return true;
  }
  return false;
};
