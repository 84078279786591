import React from "react";
import VideoCroppingResizeHandler from "./VideoCroppingResizeHandler";
import { connect } from "react-redux";
import {
  contextSelector,
  selectedItemsSelector,
  zoomSelector,
  designDataSelector,
  elementPreviewsSelector
} from "state/ui/editorContext/editorContextSelectors";
import { getPath } from "lib/lodash";
import { getPageOffsetInCanvasbackground } from "views/components/Editor/utils";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { getPageHtmlNode } from "views/components/Editor/utils";
import { calculateScaledNewDimensions } from "views/components/Editor/CropLayer/ImageCropping/ImageCroppingPreview/ImageCroppingPreviewCalculations";
import { getCanvasbackground } from "views/components/Editor/utils";
import { Logger } from "lib";

class VideoCroppingResizeHandlerContainer extends React.Component {
  constructor(props) {
    super(props);

    this.getElementWithPreview = this.getElementWithPreview.bind(this);
    this.getPageOffsetInCanvasbackground = this.getPageOffsetInCanvasbackground.bind(
      this
    );
    this.getElementWithPreview = this.getElementWithPreview.bind(this);
    this.getElementPreview = this.getElementPreview.bind(this);
    this.startVideoCropResizing = this.startVideoCropResizing.bind(this);
    this.finishVideoCropResizing = this.finishVideoCropResizing.bind(this);
  }

  getElementPreview(elementId) {
    if (!elementId) return {};
    const { elementPreviews, selectedItems } = this.props;

    const elementSelection =
      selectedItems.find(item => item.itemId === elementId) || {};

    return {
      ...elementPreviews[elementId],
      ...elementSelection.preview
    };
  }

  getElementWithPreview(elementId) {
    if (!elementId) return {};
    const { designData } = this.props;

    const element = designData.getElement(elementId);

    const elementPreview = this.getElementPreview(elementId);

    // when the preview is image instructions we want to replace without removing unchanged ones
    if (Object.keys(elementPreview).includes("imageInstructions")) {
      const previewInstructions = elementPreview.imageInstructions;
      const elementInstructions = element.imageInstructions;

      const originalInstructionsByDomId = elementInstructions.reduce(
        (combinedInstructions, currentInstruction) => ({
          ...combinedInstructions,
          [currentInstruction.domId]: currentInstruction
        }),
        {}
      );

      previewInstructions.forEach(previewInstruction => {
        originalInstructionsByDomId[
          previewInstruction.domId
        ] = previewInstruction;
      });

      elementPreview.imageInstructions = Object.values(
        originalInstructionsByDomId
      );
    }

    return {
      ...element,
      ...elementPreview
    };
  }

  getPageOffsetInCanvasbackground(pageId) {
    if (!pageId) return 0;
    return getPageOffsetInCanvasbackground(pageId);
  }

  startVideoCropResizing() {
    Logger.info(
      "VideoCroppingResizeHandlerContainer.startVideoCropResizing called"
    );
    this.props.updateContext({
      isCropping: true
    });
  }

  finishVideoCropResizing({
    handlerInitialPosition,
    anchorPoint,
    differenceFromInitialOffset
  }) {
    Logger.info(
      "VideoCroppingResizeHandlerContainer.finishVideoCropResizing called"
    );
    const { zoom, selectedItems } = this.props;
    const selectedVideo = getPath(this.props, "selectedItems.0");
    let imageElement = this.getElementWithPreview(selectedVideo?.itemId);

    imageElement = {
      ...imageElement
      // angle: 0
    };

    const pageId = selectedVideo?.pageId;

    const pageDiv = getPageHtmlNode(pageId);
    const pageOffset = pageDiv.getBoundingClientRect();

    const {
      scale,
      mask,
      top,
      left,
      height,
      width
    } = calculateScaledNewDimensions({
      imageElement,
      differenceFromInitialOffset,
      zoom,
      dragItem: { anchorPoint, handlerInitialPosition },
      pageOffset
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          scale,
          mask,
          top,
          left,
          height,
          width
        }
      }
    ];

    this.props.updateContext({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  }

  render() {
    if (!this.props.context || !this.props.context.isCroppingVideo) {
      return null;
    }

    const selectedVideo = getPath(this.props, "selectedItems.0");

    const videoElement = this.getElementWithPreview(selectedVideo?.itemId);

    const pageId = selectedVideo?.pageId || "";
    const pageOffset = this.getPageOffsetInCanvasbackground(pageId);

    const canvasBackground = getCanvasbackground();

    return (
      <VideoCroppingResizeHandler
        {...this.props}
        zoom={this.props.zoom}
        designData={this.props.designData}
        selectedItems={this.props.selectedItems}
        videoElement={videoElement}
        pageId={pageId}
        pageOffset={pageOffset}
        startVideoCropResizing={this.startVideoCropResizing}
        finishVideoCropResizing={this.finishVideoCropResizing}
        canvasBackground={canvasBackground}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    context: contextSelector(state),
    selectedItems: selectedItemsSelector(state),
    zoom: zoomSelector(state),
    designData: designDataSelector(state),
    elementPreviews: elementPreviewsSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContext: args => dispatch(updateEditorContextState(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoCroppingResizeHandlerContainer);
