import ScalingCalculation from "./ScalingCalculation";
import { resizeElementVertically } from "../scalingTools";

class ScalingCalculationVertical extends ScalingCalculation {
  getClosestXInfo() {
    return [null, null];
  }

  scaleElements({ elements, zoom, canvasOffsets, scale }) {
    return elements.map(element =>
      resizeElementVertically({
        element,
        anchorPoint: this.anchorPoint,
        handlerCoordinateName: this.handlerCoordinateName,
        scale,
        zoom,
        offset: this.canvasPositionAndDimensions
      })
    );
  }
}

export default ScalingCalculationVertical;
