import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const ResetPasswordWithTokenPage = lazy(() =>
  import("./ResetPasswordWithTokenPage")
);

export const ResetPasswordWithTokenPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <ResetPasswordWithTokenPage {...props} />
  </Suspense>
);
ResetPasswordWithTokenPageLazy.displayName = "ResetPasswordWithTokenPage";

export default ResetPasswordWithTokenPageLazy;
