export const SEARCH_USERS_IN_TEAM_REQUEST =
  "API/ENTITIES/SEARCH_USERS_IN_TEAM_REQUEST";
export const SEARCH_USERS_IN_TEAM_REQUEST_SUCCESS =
  "API/ENTITIES/SEARCH_USERS_IN_TEAM_REQUEST_SUCCESS";
export const SEARCH_USERS_IN_TEAM_REQUEST_FAILURE =
  "API/ENTITIES/SEARCH_USERS_IN_TEAM_REQUEST_FAILURE";

export const SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST =
  "API/ENTITIES/SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST";
export const SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST_SUCCESS =
  "API/ENTITIES/SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST_SUCCESS";
export const SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST_FAILURE =
  "API/ENTITIES/SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST_FAILURE";

export const CLEAR_TEAM_USERS_SEARCH = "CLEAR_TEAM_USERS_SEARCH";
