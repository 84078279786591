import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FolderFlatIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M3 1.9A1.1 1.1 0 0 0 1.9 3v10A1.1 1.1 0 0 0 3 14.1h10a1.1 1.1 0 0 0 1.1-1.1V6A1.1 1.1 0 0 0 13 4.9h-2.54a1.9 1.9 0 0 1-1.336-.55L6.676 1.93a.1.1 0 0 0-.07-.029H3zM3 .1h3.606c.5 0 .98.197 1.336.55L10.39 3.07a.1.1 0 0 0 .07.029H13A2.9 2.9 0 0 1 15.9 6v7a2.9 2.9 0 0 1-2.9 2.9H3A2.9 2.9 0 0 1 .1 13V3A2.9 2.9 0 0 1 3 .1z"
      />
    </g>
  </svg>
);
FolderFlatIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FolderFlatIcon;
