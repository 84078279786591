import React from "react";
import { MainLayout } from "../layouts";
import { HeaderContainer, SubHeaderContainer } from "../containers";

const WithHeader = (Component, options = {}, SubHeaderChildren) => props => (
  <MainLayout bgColor={options.bgColor} withGutters={options.withGutters}>
    <HeaderContainer />
    <Component {...props} />
    <SubHeaderContainer>
      {SubHeaderChildren && <SubHeaderChildren {...props} />}
    </SubHeaderContainer>
  </MainLayout>
);

export default WithHeader;
