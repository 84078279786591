import { times, getPath, pick } from "lib";
import { domParser as defaultDomParser } from "lib/defaultDomParser";
import { getCSSComputedBoundingClientRect } from "lib/htmlElements/dimensions";
import { getClassDefinitionsFromStyleString } from "lib/parseSvgToElement";
import { isHexString } from "lib/parseColor";
import {
  FILL_VALUE_REGEX,
  SVG_FILL_SELECTOR,
  INHERITED_IMAGE_INSTRUCTION_PROPERTIES
} from "lib/constants";

class SVGContainer extends HTMLElement {
  constructor() {
    super();
    const shadowRoot = this.attachShadow({ mode: "closed" });
    shadowRoot.append(...this.childNodes);
  }
}

customElements.define("svg-container", SVGContainer);

export const getSelectedImageInstructionBorder = (svg, domId) => {
  const pathId = svg
    .querySelector("#" + domId)
    .getAttribute("clip-path")
    .match(/#(.+)\)/)[1];

  svg.querySelectorAll("g").forEach(gNode => {
    if (!gNode.querySelectorAll(`#${domId}`).length) {
      gNode.parentNode.removeChild(gNode);
    }
  });

  const clipPath = svg.querySelector(`#${pathId}`);

  [...clipPath.children].forEach(clipPathChild => {
    clipPathChild.setAttribute("stroke", "black");
    clipPathChild.setAttribute("stroke-width", 1);
    clipPathChild.setAttribute("fill", "transparent");
    clipPathChild.setAttribute("vector-effect", "non-scaling-stroke");
    clipPath.parentNode.append(clipPathChild);

    const whiteBorderChild = clipPathChild.cloneNode(true);
    whiteBorderChild.setAttribute("stroke", "white");
    whiteBorderChild.setAttribute("stroke-dasharray", "2.5,2.5");
    clipPath.parentNode.append(whiteBorderChild);
  });

  clipPath.parentNode.removeChild(clipPath);

  return svg;
};

export const generateSvgDomObject = ({
  fillColors,
  width,
  height,
  srcWidth,
  srcHeight,
  scale,
  resizableX,
  resizableY,
  svgDomElement,
  elementId
}) => {
  svgDomElement.setAttribute("id", `${elementId}-svg`);

  const elements = svgDomElement.querySelectorAll("[id^='change']");
  if (elements.length) {
    // when there is already change ids just use these instead
    applyColors({ svg: svgDomElement, colors: fillColors });
  } else {
    // set up fill section color ids for this element
    setFillColorIds({ svg: svgDomElement });
    applyColorsForUploaded({ svg: svgDomElement, colors: fillColors });
  }

  const isResizableVector = [resizableX, resizableY].some(x => !!x);

  const resizeWidth = isResizableVector ? width : srcWidth * scale;
  const resizeHeight = isResizableVector ? height : srcHeight * scale;

  resizeAndReposition({
    svg: svgDomElement,
    width: resizeWidth,
    height: resizeHeight,
    srcWidth,
    srcHeight,
    scale,
    resizableX,
    resizableY,
    elementId
  });

  applyRepeatablePattern({ svg: svgDomElement, width, height, scale });

  setShapeRendering({ svg: svgDomElement });

  const svgStyleElement = svgDomElement.querySelector("style");

  if (svgStyleElement) {
    const svgWrapper = document.createElement("svg-container");

    svgWrapper.appendChild(svgDomElement);

    return svgWrapper;
  }

  return svgDomElement;
};

const setFillColorIds = ({ svg }) => {
  const svgStyleElement = svg.querySelector("style");

  // generalized index to avoid overlapping fill color ids
  let currentIdIndex = 1;

  if (svgStyleElement) {
    const styleString = svgStyleElement.textContent;

    const classDefinitions = getClassDefinitionsFromStyleString(styleString);

    if (Object.keys(classDefinitions).length) {
      Object.keys(classDefinitions).forEach(className => {
        const fillSections = svg.querySelectorAll(`*[class="${className}"]`);
        fillSections.forEach(fillSection => {
          fillSection.setAttribute("id", `change_${currentIdIndex}`);
          currentIdIndex++;
        });
      });
    }
  }

  let fillSections = svg.querySelectorAll(SVG_FILL_SELECTOR);
  if (!fillSections || fillSections.length === 0) {
    return;
  }
  fillSections = Array.from(fillSections).filter(fillSection => {
    const styleAttribute = fillSection.getAttribute("style");
    const styleFillMatch = styleAttribute
      ? styleAttribute.match(FILL_VALUE_REGEX)
      : [];
    const styleFillValue =
      styleFillMatch && styleFillMatch.length > 0 ? styleFillMatch[1] : "";
    const fillAttributeValue = fillSection.getAttribute("fill");
    // we prefer the fill attribute but will use the style value if it is not present
    const fillValue = fillAttributeValue || styleFillValue;
    return isHexString(fillValue);
  });
  fillSections.forEach(fillSection => {
    fillSection.setAttribute("id", `change_${currentIdIndex}`);
    currentIdIndex++;
  });
};

const setShapeRendering = ({ svg }) => {
  if (!svg.getAttribute("shape-rendering")) {
    svg.setAttribute("shape-rendering", "auto");
  }
};

const applyColors = ({ svg, colors = [] }) => {
  colors.forEach(({ domId, color }) => {
    const domElements = svg.querySelectorAll(`#${domId} *`);

    if (!domElements) return;

    domElements.forEach(domElement => (domElement.style.fill = color));
  });
};

const applyColorsForUploaded = ({ svg, colors = [] }) => {
  colors.forEach(({ domId, color }) => {
    const domElement = svg.querySelector(`#${domId}`);

    if (!domElement) return;

    domElement.style.fill = color;
  });
};

const applyRepeatablePattern = ({
  svg,
  width,
  height,
  scale,
  domParser = defaultDomParser
}) => {
  const pattern = svg.querySelector("#PATTERN");
  if (!pattern) return svg;

  // Step #1 make pattern id uniq to avoid conflicts with other SVGs
  const salt = Math.random()
    .toString()
    .substring(3);

  pattern.setAttribute("id", pattern.getAttribute("id") + salt);

  // Step #2 rename pattern tag
  // <svg><pattern id="pat-1">XYZ</pattern></svg>
  // TO
  // <svg><g id="pat-1">XYZ</g></svg>

  const patternId = pattern.getAttribute("id");
  const patternWidth = parseFloat(pattern.getAttribute("width"));
  const patternHeight = parseFloat(pattern.getAttribute("height"));

  const gElSrc = pattern.outerHTML
    .replace(/^<pattern/, "<g")
    .replace(/<\/pattern>$/, "</g>");

  const g = domParser
    .parseFromString(gElSrc, "image/svg+xml")
    .querySelector("g");

  pattern.replaceWith(g);

  // Step #3
  // create <use /> element
  const use = domParser
    .parseFromString("<use/>", "image/svg+xml")
    .querySelector("use");

  use.setAttribute("xlink:href", `#${patternId}`);
  use.setAttribute("height", patternHeight);
  use.setAttribute("width", patternWidth);

  const repeatEl = svg.querySelector("#REPEAT_X");

  repeatEl.setAttribute("transform", "translate(0, 0)");

  const elementsCount = Math.ceil(width / scale / patternWidth);

  times(elementsCount, index => {
    const tempUseEl = use.cloneNode();
    tempUseEl.setAttribute("transform", `translate(${patternWidth * index},0)`);
    tempUseEl.setAttribute("x", 0);
    tempUseEl.setAttribute("y", 0);
    repeatEl.append(tempUseEl);
  });

  // Step #5
  // adjust viewBoxWidth
  const viewBoxWidth = width / scale;
  const viewBoxHeight = height / scale;

  // some svg has viewbox instead of viewBox, so here is the clear to keep only one
  svg.removeAttribute("viewbox");
  svg.setAttribute("viewBox", `0 0 ${viewBoxWidth} ${viewBoxHeight}`);

  // remove unnecessary elements (fix for Safari)
  svg.querySelector("#REPEAT_X rect").remove();

  return svg;
};

const stretchSvg = ({
  svg,
  width,
  height,
  srcWidth,
  scale,
  srcHeight,
  resizableX,
  resizableY,
  elementId
}) => {
  /* Some Vectors have been created using the structure below,
   * the idea behind it is to be able to skew the vector keeping its
   * corners unchanged(only central elements are stretched), in summary
   * it makes frames scales without distorting its borders */

  /**********
   * TL T TR *
   * L  C  R *
   * BL B BR *
   ***********/

  // Horizontal Transform variables
  const viewBoxWidth = width / scale; // apply scale factor to the width
  const originOffsetX = srcWidth / 3; // svg should be split into 3 columns
  const originMiddleColumnWidth = srcWidth - originOffsetX * 2; // width for middle column in the original svg scale
  const expectedMiddleColumnWidth = viewBoxWidth - originOffsetX * 2 + 2; // width for the middle column in the new svg
  const resizeScaleX = resizableX
    ? expectedMiddleColumnWidth / originMiddleColumnWidth
    : 1; // difference in width from original to new
  const offsetX = originOffsetX * (resizeScaleX - 1); // the original offset multiplied by scale factor to find new svg offsetX

  // Vertical Transform variables
  const viewBoxHeight = height / scale; // apply scale factor to the height
  const originOffsetY = srcHeight / 3; // svg should be split into 3 rows
  const originMiddleRowHeight = srcHeight - originOffsetY * 2; // height for middle row in original svg scale
  const expectedMiddleRowHeight = viewBoxHeight - originOffsetY * 2 + 2; // height for the middle row in the new svg
  const resizeScaleY = resizableY
    ? expectedMiddleRowHeight / originMiddleRowHeight
    : 1; // difference in height from original to new
  const offsetY = originOffsetY * (resizeScaleY - 1); // the original offset multiplied by scale factor to find new svg offsetY

  // define if we only need to resize X or Y, otherwise we need to offset both
  const onlyXResize = resizableX && !resizableY;
  const onlyYResize = !resizableX && resizableY;

  const transformMap = {
    /* TOP  */ T: `translate(${
      onlyYResize ? 0 : -offsetX - 1
    } 0) scale(${resizeScaleX} 1)`,
    /* TOP RIGHT  */ TR: `translate(${offsetX - 2} 0)`,
    /* LEFT */ L: `translate(0 ${
      onlyXResize ? 0 : -offsetY - 1
    }) scale(1 ${resizeScaleY})`,
    /* CENTER  */ C: `translate(${onlyYResize ? 0 : -offsetX - 1} ${
      onlyXResize ? 0 : -offsetY - 1
    }) scale(${resizeScaleX} ${resizeScaleY})`,
    /* RIGHT  */ R: `translate(${offsetX - 2} ${
      onlyXResize ? 0 : -offsetY - 1
    }) scale(1 ${resizeScaleY})`,
    /* BOTTOM LEFT  */ BL: `translate(0 ${offsetY - 2})`,
    /* BOTTOM  */ B: `translate(${onlyYResize ? 0 : -offsetX - 1} ${offsetY -
      2})  scale(${resizeScaleX} 1)`,
    /* BOTTOM RIGHT  */ BR: `translate(${offsetX - 2} ${offsetY - 2})`,
    /* TOP LEFT */ TL: ""
  };

  const overlayOffsetX = onlyXResize ? 0 : 3;
  const overlayOffsetY = onlyYResize ? 0 : 3;

  const overlapMap = {
    TL: { x: `${overlayOffsetX}`, y: `${overlayOffsetY}` },
    T: { x: "0", y: `${overlayOffsetY}` },
    TR: { x: `-${overlayOffsetX}`, y: `${overlayOffsetY}` },
    L: { x: `${overlayOffsetX}`, y: "0" },
    C: { x: "0", y: "0" },
    R: { x: `-${overlayOffsetX}`, y: "0" },
    BL: { x: `${overlayOffsetX}`, y: `-${overlayOffsetY}` },
    B: { x: "0", y: `-${overlayOffsetY}` },
    BR: { x: `-${overlayOffsetX}`, y: `-${overlayOffsetY}` }
  };

  // need to check if the center section has no fill value
  const centerSection = svg.querySelector(`#C`);
  const centerChild = getPath(centerSection, "firstChild");
  const centerChildT2 = getPath(centerChild, "firstChild");

  const isCenterEmpty =
    !centerSection ||
    (centerChildT2 &&
      ["g", "rect"].includes(centerChildT2.type) &&
      centerChildT2.getAttribute("fill") === "none") ||
    (centerChild &&
      ["g", "rect"].includes(centerChild.type) &&
      centerChild.getAttribute("fill") === "none");

  // apply the transforms for the stretching
  Object.keys(transformMap).forEach(transformKey => {
    const currentSection = svg.querySelector(`#${transformKey}`);

    // make sure the section exists before trying to modify it
    if (currentSection) {
      const newSectionId = `${transformKey}-${elementId}`;

      // apply a new unique id to this section
      currentSection.setAttribute("id", newSectionId);

      // apply transform to section
      currentSection.setAttribute("transform", transformMap[transformKey]);

      // clone the section with use tags
      const useElem = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "use"
      );
      useElem.setAttributeNS(
        "http://www.w3.org/1999/xlink",
        "xlink:href",
        `#${newSectionId}`
      );

      const isCurrentSectionEmpty =
        getPath(currentSection, "firstChild.fill", "") !== "none" &&
        getPath(currentSection, "firstChild.firstChild.fill", "") !== "none";

      const isOffsetRequired = !isCenterEmpty && !isCurrentSectionEmpty;

      // do not apply offset to backing if the element has no fill (transparent inner or background)
      if (isOffsetRequired) {
        useElem.setAttribute("x", overlapMap[transformKey].x);
        useElem.setAttribute("y", overlapMap[transformKey].y);
      }

      if (currentSection.parentNode === svg) {
        svg.insertBefore(useElem, currentSection);
      }
    }
  });

  // set the viewbox for the new transformed svg
  svg.setAttribute("viewBox", `0 0 ${viewBoxWidth} ${viewBoxHeight}`);
};

const resizeAndReposition = ({
  svg,
  width,
  height,
  srcWidth,
  srcHeight,
  scale,
  resizableX,
  resizableY,
  elementId
}) => {
  svg.setAttribute("width", width);
  svg.setAttribute("height", height);

  if (svg.querySelectorAll("#T, #TR, #L, #C, #R").length) {
    svg.setAttribute("viewBox", `0 0  ${width / scale} ${height / scale}`);

    return stretchSvg({
      svg,
      width,
      height,
      srcWidth,
      scale,
      srcHeight,
      resizableX,
      resizableY,
      elementId
    });
  }
};

export const getSize = el => {
  if (!el) return { top: 0, left: 0, width: 0, height: 0 };

  const left = parseFloat(el.getAttribute("x"));
  const top = parseFloat(el.getAttribute("y"));
  const width = parseFloat(el.getAttribute("width"));
  const height = parseFloat(el.getAttribute("height"));

  return { top, left, width, height };
};

export const getFrameDimensions = ({
  vectorId,
  frameId,
  vectorElement,
  zoom
}) => {
  // ratio is scale factor from original size to current size
  const vectorRatio = vectorElement.width / vectorElement.srcWidth; //<design space>
  const vectorRatioZoomed = vectorRatio * zoom; //<window space>

  // get the DOM node for the element
  const DOMElement = document.querySelector(`[id="${vectorId}"]`);
  if (!DOMElement)
    return {
      width: 0,
      height: 0
    };

  const clipPathGTag = DOMElement.querySelector(`[id="${frameId}"]`);

  if (!clipPathGTag) {
    return;
  }

  // back track to closest clip-path tag
  const clipPath = clipPathGTag.parentElement.querySelector("clipPath");

  const getDimensionSource = () => {
    const rect = clipPath.querySelector("rect");
    const circle = clipPath.querySelector("circle");
    const path = clipPath.querySelector("path");
    const ellipse = clipPath.querySelector("ellipse");
    const polygon = clipPath.querySelector("polygon");
    return rect || circle || path || ellipse || polygon;
  };

  // define the object to get dimensions for dropzone from
  const dimensionSource = getDimensionSource();

  return getCSSComputedBoundingClientRect({
    element: dimensionSource,
    cssValueMultiplier: vectorRatioZoomed
  });
};

export const calculateUpdatedVectorImageInstructions = async ({
  imageInstruction,
  originalImageInstruction,
  maskSize
}) => {
  await imageInstruction.imageElementBuilderPromise;

  const scaleToFit = Math.max(
    maskSize.width / imageInstruction.width,
    maskSize.height / imageInstruction.height
  );

  const imageScaled = {
    height: imageInstruction.height * scaleToFit,
    width: imageInstruction.width * scaleToFit
  };

  return {
    ...originalImageInstruction,
    ...imageInstruction,
    ...imageScaled,
    top: (maskSize.height - imageScaled.height) / 2,
    left: (maskSize.width - imageScaled.width) / 2,
    ...pick(originalImageInstruction, INHERITED_IMAGE_INSTRUCTION_PROPERTIES)
  };
};

export const getMaskSizeForVector = (elementData, domId) => {
  const svgDOMElement = defaultDomParser
    .parseFromString(window.easil.svgs[elementData.src].data, "image/svg+xml")
    .querySelector("svg");

  if (!svgDOMElement) {
    return;
  }

  const svgImageTag = svgDOMElement.querySelector(`#${domId} > image`);

  if (!svgImageTag) {
    return;
  }

  return {
    width: svgImageTag.getAttribute("width"),
    height: svgImageTag.getAttribute("height")
  };
};

export const validateImageInstructions = imageInstruction => {
  const mandatoryFields = ["srcHeight", "srcWidth", "media", "scale"];

  return mandatoryFields.every(field => imageInstruction.hasOwnProperty(field));
};
