import React from "react";
import Loading from "views/components/loading/Loading";

export const SubPageLoading = ({ height = "300px", width = "100%" } = {}) => (
  <div
    style={{
      display: "flex",
      height,
      width,
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <Loading />
  </div>
);

export default SubPageLoading;
