import React, { Component } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { identity } from "lib/lodash";
import VectorCroppingResizeHandler from "../VectorCroppingResizeHandler";
import ResizeBox from "views/components/Editor/ResizeBox";
import PATHS from "routes/paths";
import { DEFAULT_VECTOR_MASK } from "lib/constants";
import { Vector } from "views/components/Editor/elements/vector/Vector";

class MaskedVector extends Component {
  static defaultProps = {
    connectDragSource: identity
  };

  constructor(props) {
    super(props);

    this.state = {
      isRenderer: PATHS.isRenderer(window.location.pathname)
    };
  }

  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  getVideoMaskStyle() {
    const { vectorElement, isCropping, zoom } = this.props;

    const { mask, scale, srcWidth, srcHeight } = vectorElement;

    if (!mask) {
      return {};
    }

    const translateX = -mask.left * scale * zoom;
    const translateY = -mask.top * scale * zoom;

    const height = srcHeight * scale * zoom;
    const width = srcWidth * scale * zoom;

    return {
      opacity: isCropping ? 0 : 1,
      transformOrigin: "top left",
      transform: `translate(${translateX}px, ${translateY}px)`,
      filter: "brightness(0.5)",
      height,
      width
    };
  }

  getWrapperStyle({ vectorElement, zoom }) {
    return {
      width: vectorElement.width * zoom,
      height: vectorElement.height * zoom,
      position: "absolute",
      transform: `translate(${vectorElement.left *
        zoom}px, ${vectorElement.top * zoom}px)`,
      transformOrigin: "0 0"
    };
  }

  getElementStyle({ vectorElement, zoom }) {
    const styleProps = {
      transform: [
        `rotate(${vectorElement.angle}deg)`,
        `scaleX(${vectorElement.scaleX})`,
        `scaleY(${vectorElement.scaleY})`
      ].join(" ")
    };

    const { height, opacity, width } = vectorElement;

    const finalStyle = {
      ...styleProps,
      height: height * zoom,
      opacity: opacity,
      position: "relative",
      overflow: "show",
      width: width * zoom
    };

    return finalStyle;
  }

  render() {
    const { connectDragSource, vectorElement, zoom, maskStyle } = this.props;

    const unmaskedVector = {
      ...vectorElement,
      top: vectorElement.top - vectorElement.mask.top * vectorElement.scale,
      left: vectorElement.left - vectorElement.mask.left * vectorElement.scale,
      width: vectorElement.srcWidth * vectorElement.scale * zoom,
      height: vectorElement.srcHeight * vectorElement.scale * zoom,
      mask: DEFAULT_VECTOR_MASK,
      srcWidth: vectorElement.srcWidth * zoom,
      srcHeight: vectorElement.srcHeight * zoom,
      instructionScale: vectorElement.scale * zoom
    };

    return connectDragSource(
      <div style={this.getWrapperStyle({ vectorElement, zoom })}>
        <div style={this.getElementStyle({ vectorElement, zoom })}>
          <div style={{ ...this.getVideoMaskStyle(), ...maskStyle }}>
            <Vector elementData={unmaskedVector} zoom={zoom} isCropMask />
            <ResizeBox
              isResizing={false}
              Handler={VectorCroppingResizeHandler}
              isCropping={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MaskedVector;
