import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ImageJaggedMountainIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  isTabVersion = true
}) => {
  if (isTabVersion) {
    return (
      <svg
        className={className}
        style={style}
        data-no-pointer={noPointer}
        width={width || size || 20}
        height={height || size || 20}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
        fill={color || easilGrayXDark}
      >
        <title>{title}</title>
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <g fill="none" fillRule="evenodd">
            <path d="M-2-2h24v24H-2z" />
            <path
              fill={color || easilGrayXDark}
              fillRule="nonzero"
              d="M16 0a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h12zm0 2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-2.944 5.437a.9.9 0 0 1 1.425.027l2.242 3.024a.9.9 0 0 1-1.446 1.072l-1.546-2.086-3.274 4.089a.9.9 0 0 1-1.328.084l-1.37-1.324-2.369 2.92a.9.9 0 0 1-1.398-1.134l2.986-3.682a.9.9 0 0 1 1.325-.08l1.366 1.32zM6.5 4.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
            />
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(2 2)" fill={color || easilGrayXDark}>
          <path
            d="M4 0h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4zm0 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4z"
            fillRule="nonzero"
          />
          <circle cx="6.5" cy="6.5" r="1.5" />
          <path
            d="M7.767 12.47l1.293 1.25a1 1 0 0 0 1.475-.095l3.193-3.987 1.469 1.982a1 1 0 0 0 1.606-1.191l-2.242-3.025a1 1 0 0 0-1.584-.029L9.66 11.518l-1.288-1.244a1 1 0 0 0-1.471.09l-2.986 3.682a1 1 0 1 0 1.553 1.26l2.3-2.835z"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
};
ImageJaggedMountainIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ImageJaggedMountainIcon;
