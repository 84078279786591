import React from "react";
import { Modal } from "views/components";
import style from "./style.module.css";

export class DeleteSmartImageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        contentLabel={"Delete Image?"}
        header={"Delete Image?"}
        className={style.deleteSmartImageModal}
        headerTitleClassName={style.smartImageModalHeader}
        buttonsClassName={style.modalButtons}
        buttons={[
          {
            name: "Cancel",
            theme: "gray",
            onClick: this.props.onClose
          },
          // below button and flow TBI in next milestone
          // {
          //   name: "Transfer ID",
          //   theme: "grayBackgroundXDarkGrayFont",
          //   onClick: this.props.onClose
          // },
          {
            name: "Delete Anyway",
            theme: "redSolid",
            onClick: this.props.onHardDelete
          }
        ]}
        crossClassName={style.modalCross}
      >
        <div className={style.deleteContentWrapper}>
          Are you sure you want to delete this Image? There is a Smart ID
          currently assigned to this image. Any designs using this Smart ID will
          no longer be linked to this image.
        </div>
      </Modal>
    );
  }
}

export default DeleteSmartImageModal;
