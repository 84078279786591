import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PenAndPaperIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  isPointer,
  title,
  ...props
}) => {
  return (
    <svg
      {...props}
      data-is-pointer={isPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M12 3a1 1 0 01.117 1.993L12 5H5a1 1 0 00-.993.883L4 6v14a1 1 0 00.883.993L5 21h12a1 1 0 00.993-.883L18 20v-6a1 1 0 011.993-.117L20 14v6a3 3 0 01-2.824 2.995L17 23H5a3 3 0 01-2.995-2.824L2 20V6a3 3 0 012.824-2.995L5 3h7zm5.793-.207a3.121 3.121 0 014.414 4.414l-8.5 8.5a1 1 0 01-.464.263l-4 1a1 1 0 01-1.213-1.213l1-4a1 1 0 01.263-.464zm3 1.414a1.121 1.121 0 00-1.586 0l-8.304 8.304-.529 2.114 2.114-.529 8.305-8.303a1.122 1.122 0 00.087-1.488z"
          fill={color || easilGrayXDark}
          fillRule="nonzero"
        />
        <path
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          d="M20 7.912l-2.83-2.83"
        />
      </g>
    </svg>
  );
};

PenAndPaperIcon.propTypes = {
  version: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PenAndPaperIcon;
