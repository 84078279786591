import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import { getHtmlNodeCenter } from "lib/htmlElements/dimensions";
import { rotatePoint } from "lib/geometry/rotation";
import { checkCropHandleProximity } from "lib/croppingUtils";
import style from "views/components/Editor/styleSelectionBox.module.css";

const VectorCroppingResizeHandlerSource = {
  beginDrag(props, monitor, component) {
    props.startCropResizing({ pageId: props.selectedItems[0].pageId });

    const componentNode = findDOMNode(component);
    const selectionBoxNode = componentNode.parentElement;

    const componentNodeCenter = getHtmlNodeCenter(componentNode);
    const selectionBoxNodeCenter = getHtmlNodeCenter(selectionBoxNode);

    const anchorPoint = rotatePoint(
      componentNodeCenter.x,
      componentNodeCenter.y,
      selectionBoxNodeCenter.x,
      selectionBoxNodeCenter.y,
      180
    );

    return {
      handlerInitialPosition: componentNodeCenter,
      anchorPoint: anchorPoint
    };
  },

  endDrag(props, monitor, component) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();
    const { handlerInitialPosition, anchorPoint } = monitor.getItem();

    props.finishCropResizing({
      handlerInitialPosition,
      anchorPoint,
      differenceFromInitialOffset
    });
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset()
  };
}

class VectorCroppingResizeHandler extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;

    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  render() {
    const {
      connectDragSource,
      position,
      designData,
      selectedItems,
      isCropping,
      zoom
    } = this.props;
    const {
      x: isXMinimumProximity,
      y: isYMinimumProximity
    } = checkCropHandleProximity({
      isCropping,
      selectedItems,
      position,
      designData,
      zoom
    });

    return connectDragSource(
      <div
        className={style.dotWrapper}
        data-position={position}
        data-x-proximity={isXMinimumProximity}
        data-y-proximity={isYMinimumProximity}
      >
        <div className={style.dot} />
      </div>
    );
  }
}

const VectorCroppingResizeHandlerDraggable = DragSource(
  "VECTOR_CROP_RESIZE_HANDLER",
  VectorCroppingResizeHandlerSource,
  collect
)(VectorCroppingResizeHandler);

const VectorCrippingResizeHandlerDefault = props => (
  <VectorCroppingResizeHandlerDraggable
    {...props}
    canvasBackgroundHtmlElement={props.canvasBackground}
    startCropResizing={props.startVectorCropResizing}
    finishCropResizing={props.finishVectorCropResizing}
    selectedItems={props.selectedItems}
    getElement={props.designData.getElement.bind(props.designData)}
    zoom={props.zoom}
    designData={props.designData}
  />
);

export default VectorCrippingResizeHandlerDefault;
