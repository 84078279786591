import * as types from "./pendingUsersTypes";
import { pendingUsersApiPageSelector } from "state/api/pendingUsers/pendingUsersApiSelector";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";

export const fetchPendingUsers = ({ page = 1, teamId }) => ({
  [CALL_API]: {
    types: [
      types.PENDING_USERS_REQUEST,
      types.PENDING_USERS_REQUEST_SUCCESS,
      types.PENDING_USERS_REQUEST_FAILURE
    ],
    service: SERVICES.USER,
    endpoint: `/teams/${teamId}/invitations/pending`,
    schema: schemas.USERS_ARRAY,
    request: {
      page: page
    }
  }
});

export const fetchPendingUsersTotalCount = ({ teamId }) => ({
  [CALL_API]: {
    types: [
      types.PENDING_USERS_TOTAL_REQUEST,
      types.PENDING_USERS_TOTAL_REQUEST_SUCCESS,
      types.PENDING_USERS_TOTAL_REQUEST_FAILURE
    ],
    service: SERVICES.USER,
    endpoint: `/teams/${teamId}/invitations/pending/count`,
    schema: schemas.NONE
  }
});

const shouldFetchPendingUsers = (state, page = 1) => {
  const pendingUsersApiPage = pendingUsersApiPageSelector(state, page);

  if (!pendingUsersApiPage) return true;

  if (pendingUsersApiPage.isFetching) return false;

  /*   return usersStore.didInvalidate;*/
  return true;
};

export const fetchPendingUsersIfNeeded = ({ page }) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;

  if (shouldFetchPendingUsers(getState(), page)) {
    dispatch(fetchPendingUsers({ page, teamId }));
    dispatch(fetchPendingUsersTotalCount({ teamId }));
  }
};
