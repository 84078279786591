import auth from "lib/auth";
import { push } from "react-router-redux";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import PATHS from "routes/paths";
import * as types from "./collectionRemixTypes";
import { noop, Referrer, Logger, getPath } from "lib";
import { switchToken } from "state/currentUser/currentUserActions";

const remixAndMoveToEditor = ({ remixToken, teamId, onSuccess, onFailure }) => (
  dispatch,
  getState
) => {
  const state = getState();
  // Fetch the currently logged in users auth_token
  const { token } = auth.getToken({ state });

  if (!token) {
    Referrer.set(`remix/collection/${remixToken}`);

    Logger.warn(
      "Processing Collection Remix. User not authenticated. Push to login"
    );

    dispatch(push(PATHS.login));
    return;
  } else {
    Referrer.remove();

    // if there is a token we want to use the token remix call
    dispatch(
      createRemixFromToken({
        remixToken,
        teamId,
        token,
        onSuccess,
        onFailure
      })
    );
  }
};

export const processCollectionRemix = ({ remixToken, teamId }) => dispatch => {
  dispatch(
    switchToken({
      teamId,
      onSuccess: () => {
        dispatch(
          remixAndMoveToEditor({
            remixToken,
            teamId,
            onSuccess: response => {
              dispatch(push(`/editor/${getPath(response, "designs.0.id")}`));
            },
            onFailure: () => dispatch(push("/designs"))
          })
        );
      }
    })
  );
};

export const createRemixFromToken = ({
  remixToken,
  teamId,
  token,
  onSuccess = noop,
  onFailure = noop
}) => dispatch => {
  const { userId } = auth.jwtDecoder(token);

  dispatch({
    [CALL_API]: {
      types: [
        types.COLLECTIONS_CREATE_REMIX_REQUEST,
        types.COLLECTIONS_CREATE_REMIX_REQUEST_SUCCESS,
        types.COLLECTIONS_CREATE_REMIX_REQUEST_FAILURE
      ],
      method: "POST",
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/collections`,
      schema: schemas.NONE,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      request: {
        body: {
          remixToken,
          teamId,
          userId
        }
      },
      onSuccess,
      onFailure
    }
  });
};
