import React from "react";
import { Modal, ScrollFold, Button, Loading } from "views/components";
import style from "./style.module.css";

import CreditSection from "./CreditSection";
import UpgradeBox from "./UpgradeBox";
import NoCreditsWarning from "./NoCreditsWarning";
import video from "./video.mp4";
import InfoAlert from "views/components/InfoAlert";

export class RemoveBackgroundModal extends React.Component {
  static FEATURE_DESCRIPTION_TEXT =
    "Automatically remove the background from an image in seconds. When the image is ready, a new copy with a transparent background will be made in the same location as your original image. This feature works best on images with a well defined foreground.";
  static UPGRADE_BOX_DESCRIPTION_TEXT =
    "Upgrade your plan to unlock this amazing feature. It's a great way to get creative with images and achieve stunning results—all without needing a designer.";

  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleRemoveBackground = this.handleRemoveBackground.bind(this);
    this.getModalBody = this.getModalBody.bind(this);
    this.getNextCreditsTipText = this.getNextCreditsTipText.bind(this);
  }

  handleCloseModal() {
    // do not make close call if currently processing (user should wait till completed)
    if (!this.props.isProcessing) {
      this.props.resetModal();
      this.props.onClose();
    }
  }

  componentDidMount() {
    this.props.fetchCredits();
  }

  handleRemoveBackground() {
    const { mediaId, removeBackground } = this.props;

    if (mediaId) {
      removeBackground({ mediaId, onSuccess: this.handleCloseModal });
    }
  }

  getNextCreditsTipText() {
    const {
      removeBackgroundCreditsPerMonth: creditsPerMonth
    } = this.props.userSubscriptionPlan.subscriptionFeatures;

    return `Your next ${creditsPerMonth} credits will become available on the date below`;
  }

  getWarning() {
    const {
      removeBackgroundCredits,
      userSubscriptionPlan,
      moveToSubscription
    } = this.props;

    if (this.props.removeBackgroundCredits.isDummy) {
      return (
        <InfoAlert className={style.info} theme="red" iconSize="20px">
          Unable to retrieve credits. Please contact support
        </InfoAlert>
      );
    }

    if (this.props.errors && this.props.errors[0]) {
      switch (this.props.errors[0].field) {
        case "imageSize": {
          const sizesLimitMap = {
            png: "10",
            jpg: "25"
          };

          return (
            <InfoAlert className={style.info} theme="yellow" iconSize="20px">
              <span>
                {this.props.errors[0].message}
                <br />
                Try using an image below{" "}
                <strong>
                  {sizesLimitMap[this.props.mediaType]} Mega Pixels
                </strong>{" "}
                in size.
              </span>
            </InfoAlert>
          );
        }
        case "unknown_foreground": {
          return (
            <InfoAlert className={style.info} theme="yellow" iconSize="20px">
              <span>{this.props.errors[0].message}</span>
            </InfoAlert>
          );
        }
        default: {
          return (
            <InfoAlert className={style.info} theme="red" iconSize="20px">
              Unable to remove background. Please contact support
            </InfoAlert>
          );
        }
      }
    }

    if (removeBackgroundCredits.hasNoCredit) {
      return (
        <NoCreditsWarning
          userCanUpgrade={this.props.userCanUpgrade}
          renewDate={removeBackgroundCredits.renewDateFriendly}
          creditsPerMonth={
            userSubscriptionPlan.subscriptionFeatures
              .removeBackgroundCreditsPerMonth
          }
          userSubscriptionPlan={userSubscriptionPlan}
          moveToSubscription={moveToSubscription}
        />
      );
    }
  }

  getModalBody() {
    const { removeBackgroundCredits } = this.props;

    if (!removeBackgroundCredits) {
      return <Loading className={style.loadingData} size="24px" />;
    }

    const {
      userSubscriptionPlan,
      moveToSubscription,
      isProcessing
    } = this.props;

    if (
      !userSubscriptionPlan.isRemoveBackgroundAvailable &&
      (!removeBackgroundCredits || !removeBackgroundCredits.creditsLeft)
    ) {
      return (
        <UpgradeBox
          upgradeBoxDescriptionText={
            RemoveBackgroundModal.UPGRADE_BOX_DESCRIPTION_TEXT
          }
          moveToSubscription={moveToSubscription}
          userCanUpgrade={this.props.userCanUpgrade}
        />
      );
    }

    const {
      removeBackgroundCreditsPerMonth
    } = userSubscriptionPlan.subscriptionFeatures;

    return (
      <div>
        <CreditSection
          currentCredits={removeBackgroundCredits.creditsLeft}
          removeBackgroundCreditsPerMonth={removeBackgroundCreditsPerMonth}
          nextCreditsTipText={this.getNextCreditsTipText()}
          renewDate={removeBackgroundCredits.renewDateFriendly}
          userSubscriptionPlan={userSubscriptionPlan}
        />
        {this.getWarning()}
        <div className={style.buttons}>
          <Button
            className={style.cancelButton}
            theme="gray"
            onClick={this.handleCloseModal}
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            className={style.applyButton}
            theme="blueSolid"
            onClick={() => {
              this.handleRemoveBackground();
            }}
            disabled={removeBackgroundCredits.hasNoCredit}
          >
            {isProcessing ? (
              <Loading hexColor="#FFFFFF" size="16px" />
            ) : (
              "Remove Background"
            )}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        className={style.removeBGModalWrapper}
        isOpen={isOpen}
        contentLabel="Upgrade to Plan"
        onRequestClose={this.handleCloseModal}
        header="Remove background"
        crossColor="#afb3b6"
      >
        <div className={style.contentWrapper}>
          <div className={style.gifAnimationWrapper}>
            <video autoPlay loop muted>
              <source type="video/mp4" src={video} />
            </video>
          </div>
          <div className={style.featureDescription}>
            {RemoveBackgroundModal.FEATURE_DESCRIPTION_TEXT}
          </div>
          {this.getModalBody()}
        </div>
        <ScrollFold color="#FFFFFF" size="40" />
      </Modal>
    );
  }
}

export default RemoveBackgroundModal;
