import React from "react";
import PropTypes from "prop-types";
import { easilBlack, easilGrayXLight } from "./style.module.css";

const FileMagnifierIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color = easilGrayXLight,
  backGroundColor,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 46}
    height={height || size || 56}
    viewBox="0 0 46 56"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <rect
        width="50"
        height="39"
        x="1"
        y="11"
        fill={backGroundColor || easilGrayXLight}
        opacity=".85"
        rx="2"
        transform="rotate(90 26 30.5)"
      />
      <path
        fill={color || easilBlack}
        fillRule="nonzero"
        d="M32.402 2.91v6.229a1 1 0 0 0 1 1h5.416l-6.416-7.23zM40 12.138h-6.598a3 3 0 0 1-3-3V2H4a2 2 0 0 0-2 2v44a2 2 0 0 0 2 2h34a2 2 0 0 0 2-2V12.139zM4 0h28.494L42 10.71V48a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
      />
      <path
        fill={color || easilBlack}
        fillRule="nonzero"
        d="M25.618 33.032a9 9 0 1 1 1.414-1.414l3.675 3.675a1 1 0 0 1-1.414 1.414l-3.675-3.675zm-.577-2.176a7 7 0 1 0-.185.185 1.01 1.01 0 0 1 .185-.185z"
      />
    </g>
  </svg>
);
FileMagnifierIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FileMagnifierIcon;
