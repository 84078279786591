window.easil.htmlElements = {};

const easilHtmlElements = {};

easilHtmlElements.add = ({ id, htmlElement }) => {
  window.easil.htmlElements[id] = {
    ...window.easil.htmlElements[id],
    innerHtml: htmlElement,
    updatedAt: Date.now()
  };
};

easilHtmlElements.delete = id => {
  delete window.easil.htmlElements[id];
};

export default easilHtmlElements;
