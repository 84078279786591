import { isEmpty } from "lib";

class UploadingImages {
  constructor({ uploads = {} }) {
    this.uploads = uploads;
  }

  get uploadsArray() {
    return Object.values(this.uploads);
  }

  hasUploads() {
    return !isEmpty(this.uploads);
  }

  hasOngoingUploads() {
    if (!this.hasUploads()) {
      return false;
    } else {
      return this.uploadsArray.some(upload => !upload.isFinished);
    }
  }

  hasFinishedUploads() {
    if (!this.hasUploads()) {
      return false;
    } else {
      return this.uploadsArray.every(upload => upload.isFinished);
    }
  }
}

export default UploadingImages;
