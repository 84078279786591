import React from "react";
import { connect } from "react-redux";
import {
  convertDesignToMaster,
  convertCollectionToMaster
} from "state/ui/designsPage/designsPageActions";

import DesignPromotionModal from "./DesignPromotionModal";

export const DesignPromotionModalContainer = props => {
  return <DesignPromotionModal {...props} />;
};

DesignPromotionModalContainer.displayName = "DesignPromotionModalContainer";

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  convertDesignToMaster: args => dispatch(convertDesignToMaster(args)),
  convertCollectionToMaster: args => dispatch(convertCollectionToMaster(args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignPromotionModalContainer);
