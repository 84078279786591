export const TEAM_LOGOS_REQUEST = "API/ENTITIES/TEAM_LOGOS_REQUEST";
export const TEAM_LOGOS_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_LOGOS_REQUEST_FAILURE";
export const TEAM_LOGOS_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_LOGOS_REQUEST_SUCCESS";

export const TEAM_DELETE_LOGO_REQUEST = "API/ENTITIES/TEAM_DELETE_LOGO_REQUEST";
export const TEAM_DELETE_LOGO_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_DELETE_LOGO_REQUEST_FAILURE";
export const TEAM_DELETE_LOGO_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_DELETE_LOGO_REQUEST_SUCCESS";
