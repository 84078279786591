const imageInstructionDefaults = {
  opacity: 1,
  scaleX: 1,
  scaleY: 1,
  top: 0,
  left: 0,
  filters: "64646464646464064"
};

export default imageInstructionDefaults;
