import { isGif } from "lib/isGif";
import { isMp4 } from "lib/isMp4";
const ANIMATION_LAYER = "ANIMATION_LAYER";
const STATIC_LAYER = "STATIC_LAYER";

/**
 * Determine whether an element is animated.
 * Layers are split by animated elements.
 *
 * @param {object} element A page element from the designData.
 * @returns {boolean} Whether element is an animated GIF.
 */
const isAnimated = element => {
  const isAnimatedSrc = isGif(element.src) || isMp4(element.src);
  return (
    element.type === "video" ||
    // animated GIF within photo grid
    (element.type === "image" && isAnimatedSrc) ||
    // animated GIF as background image
    (element.type === "background" && isAnimatedSrc)
  );
};

/**
 * Determine whether element is a photo grid with an animated GIF.
 *
 * @param {object} element A page element from the designData.
 * @returns {boolean} Whether element is a photo grid with an animated GIF.
 */
const isAnimatedGrid = element =>
  element.type === "grid" &&
  Boolean(element.imageInstructions.filter(isAnimated).length);

/**
 * Determine whether element is a shape mask with an animated GIF.
 *
 * @param {object} element A page element from the designData.
 * @returns {boolean} Whether element is a shape mask with an animated GIF.
 */
const isAnimatedShapeMask = element =>
  element.type === "vector" &&
  Array.isArray(element.imageInstructions) &&
  Boolean(element.imageInstructions.filter(isAnimated).length);

/**
 * Determine whether element is a text mask with an animated GIF.
 *
 * @param {object} element A page element from the designData.
 * @returns {boolean} Whether element is a text mask with an animated GIF.
 */
const isAnimatedTextMask = element =>
  element.type === "textbox" &&
  element.maskImage &&
  isAnimated(element.maskImage);

const getShapeMaskId = (element, imageInstruction) =>
  `${imageInstruction.domId}-${element.uniqueId}`;

/**
 * Create new layer triggered by the animated element.
 *
 * @param {Array} layers The layers array for the design page.
 * @param {Array} staticElements The current stack of static elements.
 * @returns {undefined}
 */
const createNewStaticLayer = (layers, staticElements) => {
  // Encountered an animated element and signifies the end of static layer
  layers.push({ elements: [...staticElements], type: STATIC_LAYER });
  // NOTE: this needs to mutate the staticElements array, so it is cleared
  // within the exported getLayers function.
  staticElements.length = 0; // eslint-disable-line
};

const createNewAnimatedLayer = (layers, animatedElement) => {
  layers.push({ elements: [animatedElement], type: ANIMATION_LAYER });
};

/**
 * Create layers for the photo grid.
 *
 * @param {object} element The grid type page element.
 * @param {Array} layers The layers array for the design page.
 * @param {Array} staticElements The current stack of static elements.
 * @returns {undefined}
 */
const createTileLayers = (element, layers, staticElements) => {
  const animatedTiles = element.imageInstructions.filter(isAnimated);
  if (animatedTiles.length) {
    const maskedImageIds = animatedTiles.map(animatedTile => [
      element.uniqueId,
      animatedTile.domId
    ]);
    staticElements.push({
      ...element,
      maskedImageIds,
      isAnimatedPhotoGrid: true
    });
    createNewStaticLayer(layers, staticElements);
    // Start creating animated layers for each animated tile.
    animatedTiles.forEach(animatedTile => {
      let { scaleX, scaleY } = element;
      let opacity = Number(element.opacity);
      // grids can be flipped and so can there tiles (both flipped negate each other)
      if (Number(animatedTile.scaleX) === -1) {
        scaleX = Number(scaleX) === -1 ? 1 : -1;
      }
      if (Number(animatedTile.scaleY) === -1) {
        scaleY = Number(scaleY) === -1 ? 1 : -1;
      }
      // grids, as well as their images within them can have opacity
      if (Number(animatedTile.opacity) !== 1) {
        opacity *= Number(animatedTile.opacity);
      }
      const animatedElement = {
        ...animatedTile,
        isAnimatedTile: true,
        // normalise opacity
        opacity,
        // normalise rotate
        angle: Number(element.angle),
        // normalise flip filter
        scaleX,
        scaleY,
        // normalise scale filter (resizing image)
        scale: element.scale,
        srcWidth: animatedTile.width,
        srcHeight: animatedTile.height,
        // normalise crop
        width: element.width,
        height: element.height,
        mask: {
          left: Math.abs(animatedTile.left),
          top: Math.abs(animatedTile.top)
        }
      };
      createNewAnimatedLayer(layers, animatedElement);
    });
  }
};

/**
 * Create layers for the shape mask.
 *
 * @param {object} element The texbox type page element.
 * @param {Array} layers The layers array for the design page.
 * @param {Array} staticElements The current stack of static elements.
 * @returns {undefined}
 */
const createShapeMaskLayers = (element, layers, staticElements) => {
  const animatedShapes = element.imageInstructions.filter(isAnimated);
  const maskedImageIds = animatedShapes.map(animatedShape =>
    getShapeMaskId(element, animatedShape)
  );
  staticElements.push({
    ...element,
    maskedImageIds,
    isAnimatedShapeMask: true
  });
  createNewStaticLayer(layers, staticElements);
  if (animatedShapes.length) {
    animatedShapes.forEach(animatedShape => {
      const animatedElement = {
        ...animatedShape,
        // normalise opacity
        opacity: element.opacity,
        // normalise rotate
        angle: Number(element.angle),
        width: Math.ceil(element.scale * animatedShape.width),
        height: Math.ceil(element.scale * animatedShape.height),
        // normalise flip filter
        scaleX: element.scaleX,
        scaleY: element.scaleY,
        // normalise scale filter (resizing image)
        scale: element.scale,
        srcWidth: animatedShape.width,
        srcHeight: animatedShape.height,
        isMaskedImage: true
      };
      createNewAnimatedLayer(layers, animatedElement);
      staticElements.push({
        ...element,
        maskedImageId: getShapeMaskId(element, animatedShape),
        isAnimatedShape: true
      });
      createNewStaticLayer(layers, staticElements);
    });
  }
};

/**
 * Create layers for the text mask.
 *
 * @param {object} element The texbox type page element.
 * @param {Array} layers The layers array for the design page.
 * @param {Array} staticElements The current stack of static elements.
 * @returns {undefined}
 */
const createTextMaskLayers = (element, layers, staticElements) => {
  // clear static layer stack
  createNewStaticLayer(layers, staticElements);
  // single static layer for text with transparent background
  staticElements.push({
    ...element,
    // opacity: element.maskImage.opacity,
    maskedImageId: element.uniqueId,
    isAnimatedTextMask: true
  });
  createNewStaticLayer(layers, staticElements);
  // Create layer for animated image.
  const animatedElement = {
    ...element,
    isAnimatedText: true,
    // normalise src/originalSrc
    src: element.maskImage.src,
    originalSrc: element.maskImage.originalSrc,
    // normalise mediaId
    mediaId: element.maskImage.id,
    // normalise opacity
    opacity: 1,
    // normalise rotate
    angle: Number(element.angle),
    // normalise scale filter (resizing image)
    scale: element.maskImage.scale,
    srcWidth: element.maskImage.width,
    srcHeight: element.maskImage.height,
    // normalise crop
    width: element.width,
    height: element.height,
    mask: {
      left: Math.abs(element.maskImage.left),
      top: Math.abs(element.maskImage.top)
    }
  };
  createNewAnimatedLayer(layers, animatedElement);
  // remove image and have black text
  staticElements.push({
    ...element,
    maskedImageId: element.uniqueId,
    isAnimatedTextMask: true,
    isGreenScreen: true
  });
  createNewStaticLayer(layers, staticElements);
};

/**
 * Get layers from page.
 *
 * @param {object} pageData Page data extracted from designData.
 * @returns {Array} List of layers { layer: [element], type }
 */
export const getLayers = pageData => {
  const { elements } = pageData;
  const layers = [];
  const staticElements = [];
  elements.forEach(element => {
    if (element.isHidden) {
      return;
    }
    if (isAnimated(element)) {
      // A new layer is created whenever an animated element is encountered.
      if (staticElements.length) {
        createNewStaticLayer(layers, staticElements);
      }
      createNewAnimatedLayer(layers, element);
    } else if (isAnimatedGrid(element)) {
      // A photo grid is composed of:
      // 1. static layer (for any static photos)
      // 2. animated layer(s) for each animated tile.
      createTileLayers(element, layers, staticElements);
    } else if (isAnimatedShapeMask(element)) {
      // A shape mask is composed of:
      // 1. static layer for shape mask border/background
      // For each shape within the shape mask:
      // 2. animated layer for image
      // 3. static layer for green-screen
      createShapeMaskLayers(element, layers, staticElements);
    } else if (isAnimatedTextMask(element)) {
      // A text mask is composed of:
      // 1. static layer for text mask decoration i.e. border
      // 2. animated layer for image
      // 3. static layer for green-screen
      createTextMaskLayers(element, layers, staticElements);
    } else {
      // Keep stacking static elements for next layer.
      staticElements.push(element);
    }
  });
  // Add top static layer if there was one
  if (staticElements.length) {
    layers.push({ elements: staticElements, type: STATIC_LAYER });
  }
  return layers;
};
