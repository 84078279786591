import * as types from "./printPricingTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentOrderIdSelector } from "state/ui/cart/cartSelectors";
import { orderByIdSelector } from "state/entities/orders/ordersSelectors";
import { asyncAction } from "lib/asyncHelpers";
import { noop } from "lib";

export const fetchPrintPricingByDesignDataId = ({
  designId,
  dataId,
  resolve = noop,
  page = 1
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER,
      types: [
        types.FETCH_PRINT_PRICING_FOR_DESIGN_REQUEST,
        types.FETCH_PRINT_PRICING_FOR_DESIGN_REQUEST_SUCCESS,
        types.FETCH_PRINT_PRICING_FOR_DESIGN_REQUEST_FAILURE
      ],
      request: {
        page,
        pageSize: 100
      },
      endpoint: `/designs/${designId}/data/${dataId}/print-options`,
      schema: schemas.PRINT_PRICINGS,
      extra: { dataId, designId },
      onSuccess: response => {
        if (
          response.entities &&
          Object.keys(response.entities.printPricing).length === 100
        ) {
          dispatch(
            fetchPrintPricingByDesignDataId({
              dataId,
              page: page + 1
            })
          );
        } else {
          // don't need to return the data here since it should just be stored in redux
          resolve();
        }
      },
      onFailure: resolve
    }
  });
};

/**
 * @desc async wrapper for fetchPrintPricingByDesignDataId
 * @param {object} args argument object to run fetchPrintPricingByDesignDataId with
 * @returns {Promise}
 */
export const asyncFetchPrintPricingByDesignDataId = args => dispatch =>
  new Promise(resolve => {
    args.resolve = resolve;
    dispatch(fetchPrintPricingByDesignDataId(args));
  });

/**
 * @desc fetches the print pricing for the current order in the redux cart state
 */
export const fetchAllPrintPricingForCurrentOrder = ({
  resolve = noop,
  page = 1
}) => (dispatch, getState) => {
  const state = getState();
  const orderId = currentOrderIdSelector(state);

  return dispatch(fetchAllPrintPricingForOrderId({ resolve, page, orderId }));
};

/**
 * @desc async wrapper for fetchAllPrintPricingForCurrentOrder
 */
export const asyncFetchAllPrintPricingForCurrentOrder = (
  args = {}
) => dispatch =>
  new Promise(resolve => {
    args.resolve = resolve;
    dispatch(fetchAllPrintPricingForCurrentOrder(args));
  });

export const fetchAllPrintPricingForOrderId = ({
  resolve = noop,
  page = 1,
  orderId
}) => (dispatch, getState) => {
  const state = getState();
  const order = orderByIdSelector({ state, orderId });

  // get the print pricing for each design
  const priceFetchPromises = order.designs.map(design =>
    dispatch(
      asyncFetchPrintPricingByDesignDataId({
        designId: design.designId,
        dataId: design.designDataId,
        page
      })
    )
  );

  // if there is an async resolve then we should wait for all the promises to resolve and call it
  Promise.all(priceFetchPromises).then(priceResults => {
    resolve(priceResults);
  });
};

export const asyncFetchAllPrintPricingForOrderId = asyncAction(
  fetchAllPrintPricingForOrderId
);
