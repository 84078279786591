export const FETCH_TEAM_SMART_TEXT_REQUEST =
  "API/ENTITIES/TEAM_SMART_TEXT_REQUEST";
export const FETCH_TEAM_SMART_TEXT_SUCCESS =
  "API/ENTITIES/TEAM_SMART_TEXT_SUCCESS";
export const FETCH_TEAM_SMART_TEXT_FAILURE =
  "API/ENTITIES/TEAM_SMART_TEXT_FAILURE";

export const SET_AUTO_COMPLETE_DROPDOWN = "APP/UI/SET_AUTO_COMPLETE_DROPDOWN";

export const FETCH_USER_TEAM_SMART_TEXT_REQUEST =
  "API/ENTITIES/USER_TEAM_SMART_TEXT_REQUEST";
export const FETCH_USER_TEAM_SMART_TEXT_SUCCESS =
  "API/ENTITIES/USER_TEAM_SMART_TEXT_SUCCESS";
export const FETCH_USER_TEAM_SMART_TEXT_FAILURE =
  "API/ENTITIES/USER_TEAM_SMART_TEXT_FAILURE";

export const FETCH_DESIGN_SMART_TEXT_REQUEST =
  "API/ENTITIES/DESIGN_SMART_TEXT_REQUEST";
export const FETCH_DESIGN_SMART_TEXT_SUCCESS =
  "API/ENTITIES/DESIGN_SMART_TEXT_SUCCESS";
export const FETCH_DESIGN_SMART_TEXT_FAILURE =
  "API/ENTITIES/DESIGN_SMART_TEXT_FAILURE";

export const TEAM_SMART_TEXT_SAVE_REQUEST =
  "API/ENTITIES/TEAM_SMART_TEXT_SAVE_REQUEST";
export const TEAM_SMART_TEXT_SAVE_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SMART_TEXT_SAVE_REQUEST_FAILURE";
export const TEAM_SMART_TEXT_SAVE_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SMART_TEXT_SAVE_REQUEST_SUCCESS";

export const CREATE_USER_TEAM_SMART_TEXT_REQUEST =
  "API/ENTITIES/CREATE_USER_TEAM_SMART_TEXT_REQUEST";
export const CREATE_USER_TEAM_SMART_TEXT_REQUEST_SUCCESS =
  "API/ENTITIES/CREATE_USER_TEAM_SMART_TEXT_REQUEST_SUCCESS";
export const CREATE_USER_TEAM_SMART_TEXT_REQUEST_FAILURE =
  "API/ENTITIES/CREATE_USER_TEAM_SMART_TEXT_REQUEST_FAILURE";

export const UPDATE_TEAM_SMART_TEXT_REQUEST =
  "API/ENTITIES/UPDATE_TEAM_SMART_TEXT_REQUEST";
export const UPDATE_TEAM_SMART_TEXT_SUCCESS =
  "API/ENTITIES/UPDATE_TEAM_SMART_TEXT_SUCCESS";
export const UPDATE_TEAM_SMART_TEXT_FAILURE =
  "API/ENTITIES/UPDATE_TEAM_SMART_TEXT_FAILURE";

export const UPDATE_USER_TEAM_SMART_TEXT_REQUEST =
  "API/ENTITIES/UPDATE_USER_TEAM_SMART_TEXT_REQUEST";
export const UPDATE_USER_TEAM_SMART_TEXT_SUCCESS =
  "API/ENTITIES/UPDATE_USER_TEAM_SMART_TEXT_SUCCESS";
export const UPDATE_USER_TEAM_SMART_TEXT_FAILURE =
  "API/ENTITIES/UPDATE_USER_TEAM_SMART_TEXT_FAILURE";

export const DELETE_TEAM_SMART_TEXT_REQUEST =
  "API/ENTITIES/DELETE_TEAM_SMART_TEXT_REQUEST";
export const DELETE_TEAM_SMART_TEXT_SUCCESS =
  "API/ENTITIES/DELETE_TEAM_SMART_TEXT_SUCCESS";
export const DELETE_TEAM_SMART_TEXT_FAILURE =
  "API/ENTITIES/DELETE_TEAM_SMART_TEXT_FAILURE";

export const DELETE_USER_TEAM_SMART_TEXT_REQUEST =
  "API/ENTITIES/DELETE_USER_TEAM_SMART_TEXT_REQUEST";
export const DELETE_USER_TEAM_SMART_TEXT_SUCCESS =
  "API/ENTITIES/DELETE_USER_TEAM_SMART_TEXT_SUCCESS";
export const DELETE_USER_TEAM_SMART_TEXT_FAILURE =
  "API/ENTITIES/DELETE_USER_TEAM_SMART_TEXT_FAILURE";

export const UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST =
  "API/ENTITIES/UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST";
export const UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST_SUCCESS =
  "API/ENTITIES/UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST_SUCCESS";
export const UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST_FAILURE =
  "API/ENTITIES/UPDATE_DESIGN_SMART_FIELD_OWNER_REQUEST_FAILURE";
