import { clamp, pick } from "lib";
import { getImageSize } from "lib/getImageDetails";
import { isVideo } from "lib/isVideo";
import { getPaletteFromSrc } from "lib/Colors/colorUtils";

const V2_MEDIA_URLS = process.env.REACT_APP_V2_MEDIA_URLS
  ? process.env.REACT_APP_V2_MEDIA_URLS.split(",")
  : ["dev-media.easil.com", "asset.easil.com", "https://media.easil.com"];

/**
 * This uses a defined set of URLS to determine if the image source is a V2 image. We only require
 * multiple urls for V2 due to the image urls being embedded in the designs and dev using
 * production data... which has a different url to dev for it's hosted media.
 * @param url The element src url.
 * @returns {boolean}
 */
const isV2ImageAsset = url =>
  V2_MEDIA_URLS.some(bucket => url.includes(bucket));

/**
 * Remove the preview_ prefix from the image elements in the design data.
 *
 * This is because the editor works with previews for speed.
 * The renderer needs to render the high quality assets so must remove the prefix to use the actual
 * asset.
 *
 * @param element Required: The element containing the src value for the image.
 */
export const determineImageUrl = element => {
  if (!element.src) return null;
  const isSvg = element.type.toLowerCase() === "vector";

  if (element.src.includes("gettyimages")) {
    // Getty image, do nothing.
    return element.src.replace("preview_", "");
  }

  // If it's not an SVG then replace the preview with the HQ asset
  if (!isSvg) {
    if (isV2ImageAsset(element.src)) {
      // V2, just replace the prefix
      return element.src.replace("preview_", "");
    }

    // Legacy support: If element is a V1 element we need to map to an entirely different url
    // Note: Yes there is 'vector' and 'svg' in the path, in v1 this does not mean it's
    // actually a vector or svg.
    const regex = /(https:\/\/s3\.amazonaws\.com\/media\.easil\.com)\/vector\/svg\/([a-z0-9-]*)\/([\w.-]+)$/;
    const matches = element.src.match(regex);
    if (matches && matches.length === 4) {
      const baseUrl = matches[1]; // 0 is the full string
      const mediaId = matches[2];
      const filename = matches[3].replace("preview_", "");
      return `${baseUrl}/raster/66feb788d/${mediaId}/${filename}`;
    }
  }
  return element.src;
};

export const fitToContain = (container, image) => {
  const containerRatio = container.width / container.height;
  const imageRatio = image.width / image.height;

  // container and image have same aspect ratio
  let newWidth = container.width;
  let newHeight = container.height;
  let left = 0;
  let top = 0;
  if (containerRatio > imageRatio) {
    // Container is wider than the image
    newWidth = container.height * imageRatio;
    left = (container.width - newWidth) / 2;
  } else if (containerRatio < imageRatio) {
    // Container is taller than the image
    newHeight = container.width / imageRatio;
    top = (container.height - newHeight) / 2;
  }
  return {
    newWidth,
    newHeight,
    left,
    top
  };
};

export const calculateImageReplacePreview = async ({
  imageInstruction,
  elementData,
  pageDimensions
}) => {
  const builtImageElement = await imageInstruction.imageElementBuilderPromise;
  /* calculate the relative dimension scaling to get image to fill element */
  const scaleToFit = Math.max(
    elementData.height / builtImageElement.srcHeight,
    elementData.width / builtImageElement.srcWidth
  );

  /* calculate src dimensions using image scaling factor */
  let sourceDimensions = {};

  if (elementData.type === "background" && pageDimensions) {
    // backgrounds should be fit to the page
    const scaleFactor = Math.max(
      pageDimensions.height / builtImageElement.srcHeight,
      pageDimensions.width / builtImageElement.srcWidth
    );

    const newWidth = builtImageElement.srcWidth * scaleFactor;
    const newHeight = builtImageElement.srcHeight * scaleFactor;

    const newTop = (pageDimensions.height - newHeight) / 2;
    const newLeft = (pageDimensions.width - newWidth) / 2;

    sourceDimensions = {
      width: newWidth,
      height: newHeight,
      top: newTop,
      left: newLeft,
      scale: scaleFactor
    };
  } else {
    // we only need a mask when the element is not a background
    sourceDimensions = {
      srcWidth: builtImageElement.srcWidth,
      srcHeight: builtImageElement.srcHeight,
      scale: scaleToFit,
      mask: Object.assign({}, elementData.mask, {
        top: clamp(
          elementData.mask.top,
          0,
          (builtImageElement.height * scaleToFit - elementData.height) /
            scaleToFit
        ),
        left: clamp(
          elementData.mask.left,
          0,
          (builtImageElement.width * scaleToFit - elementData.width) /
            scaleToFit
        )
      })
    };
  }

  if (
    (builtImageElement.url && builtImageElement.url.endsWith(".svg")) ||
    (builtImageElement.src && builtImageElement.src.endsWith(".svg"))
  ) {
    // vector images should use original source
    builtImageElement.url =
      builtImageElement.originalUrl || builtImageElement.url;
    builtImageElement.previewSrc =
      builtImageElement.originalUrl || builtImageElement.url;
  }

  /* define the updated attributes for the preview element */
  const previewSrc =
    builtImageElement.previewSrc || builtImageElement.previewUrl;

  // calculate the palette again since the src was changed
  const palette = await getPaletteFromSrc(previewSrc);

  const previewElementUpdates = {
    src: previewSrc,
    previewSrc: previewSrc,
    thumbSrc: builtImageElement.thumbSrc || builtImageElement.thumbnailUrl,
    thumbnailUrl: builtImageElement.thumbSrc || builtImageElement.thumbnailUrl,
    originalSrc:
      builtImageElement.originalSrc ||
      builtImageElement.src ||
      builtImageElement.url,
    ...sourceDimensions,
    id: builtImageElement.mediaId || builtImageElement.id,
    size: getImageSize(builtImageElement),
    mediaId: builtImageElement.mediaId || builtImageElement.id,
    hasAudio: builtImageElement.hasAudio,
    palette,
    label: builtImageElement.label
  };

  /* combine current element attributes with preview updates */
  return Object.assign(
    {},
    builtImageElement,
    previewElementUpdates,
    // include the filters from the old asset since they will carry over on apply
    {
      filters: elementData.filters
    }
  );
};

/**
 * @desc Picks the appropriate data from an element preview for updating an image
 * @param {Object} elementData - the data for the current element
 * @param {Object} previewImageData - the preview data for the image element
 */
export const getUpdatedImageFromPreview = ({
  elementData,
  previewImageData
}) => {
  let elementUpdated = {};
  // when not a background process normally
  if (elementData.type !== "background") {
    elementUpdated = Object.assign(
      elementData,
      pick(previewImageData, [
        "src",
        "previewSrc",
        "originalSrc",
        "thumbSrc",
        "srcWidth",
        "srcHeight",
        "scale",
        "mask",
        "id",
        "size",
        "mediaId",
        "duration",
        "hasAudio",
        "name",
        "duration",
        "palette",
        "label"
      ])
    );
  } else {
    elementUpdated = Object.assign(
      pick(previewImageData, [
        "src",
        "previewSrc",
        "originalSrc",
        "thumbSrc",
        "srcWidth",
        "srcHeight",
        "scale",
        "mask",
        "id",
        "top",
        "left",
        "width",
        "height",
        "size",
        "mediaId",
        "hasAudio",
        "duration",
        "palette",
        "label"
      ])
    );
  }

  // when applying an update from preview ensure we change the type if asset type changes
  if (
    elementUpdated &&
    elementUpdated.type !== "video" &&
    elementUpdated.src &&
    isVideo(elementUpdated.src)
  ) {
    elementUpdated.type = "video";
  }
  if (
    elementUpdated &&
    elementUpdated.type === "video" &&
    elementUpdated.src &&
    !isVideo(elementUpdated.src)
  ) {
    elementUpdated.type = "image";
  }

  if (
    previewImageData &&
    previewImageData.media &&
    previewImageData.media.type === "ANIMATION"
  ) {
    elementUpdated.duration = previewImageData.media.duration;
  }

  // if no thumbSrc is provided by the incoming asset image
  // we should assign it to the previewSrc
  if (!previewImageData || !previewImageData.thumbSrc) {
    elementUpdated.thumbSrc = previewImageData.previewSrc;
  }

  return elementUpdated;
};

// get the updated preview (alias for getUpdatedImageFromPreview for the moment)
export const getUpdatedVideoFromPreview = args =>
  getUpdatedImageFromPreview({
    ...args,
    previewImageData: args.previewVideoData
  });
