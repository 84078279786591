/* ROOT PATHS */
const PATHS = {
  designs: "/designs",
  brand: "/brand",
  subscription: "/subscription",
  settings: "/settings",
  login: "/login",
  subscriptionSuccess: "/subscription-success",
  remix: "/mix/:designId",
  remixCollection: "/remix/collection/:remixToken"
};

PATHS.folder = "/folder";
PATHS.folders = "/folders";
PATHS.uploads = "/uploads";
PATHS.shared = "/shared";

PATHS.render = "/render";

PATHS.nestedPaths = {
  completed: "/completed",
  settings: "/settings",
  details: "/details",
  teams: "/teams",
  team: "/team"
};

PATHS.getImageFolderId = path => {
  const regex = new RegExp(
    `${PATHS.workspaceUploadsImages}${PATHS.folder}/([^?]*)`
  );

  const match = regex.exec(path);

  return match ? match[1] : null;
};

/* SUBSCRIPTION PATHS */
PATHS.subscriptionPlans = PATHS.subscription + "/plans";
PATHS.subscriptionBilling = PATHS.subscription + "/billing";

/* BRAND PATHS */
PATHS.brandColors = PATHS.brand + "/colors";
PATHS.brandFonts = PATHS.brand + "/fonts";
PATHS.brandImages = PATHS.brand + "/images";
PATHS.brandLogos = PATHS.brand + "/logos";
PATHS.brandAnimations = PATHS.brand + "/videos";
PATHS.brandSmartText = PATHS.brand + "/smart-text";
PATHS.brandSettings = PATHS.brand + "/settings";

/* CART PATHS */
PATHS.cart = "/cart";
PATHS.cartComplete = PATHS.cart + "/:orderId" + PATHS.nestedPaths.completed;
PATHS.isCartPage = path => path.startsWith(PATHS.cart);

/* CATALOGUE PATHS */
PATHS.catalogue = PATHS.designs + "/catalogue";
PATHS.catalogueAll = PATHS.catalogue + "/all";
PATHS.catalogueAllPosterSearch = PATHS.catalogueAll + "?size=poster";
PATHS.catalogueArchived = PATHS.catalogue + "/archived";
PATHS.catalogueFolders = PATHS.catalogue + PATHS.folders;
PATHS.catalogueEasil = PATHS.catalogue + "/easil";
PATHS.cataloguePurchased = PATHS.catalogue + "/purchased";
PATHS.cataloguePurchasedSearch = PATHS.cataloguePurchased + "/search";
PATHS.catalogueEasilSearch = PATHS.catalogueEasil + "/search";
PATHS.catalogueTemplates = PATHS.catalogue + "/templates";
PATHS.catalogueTemplatesSearch = PATHS.catalogueTemplates + "/search";
PATHS.buildCatalogueSearchPath = subSection => {
  return `${PATHS.catalogue}/${subSection}/search`;
};

PATHS.isCatalogueTemplates = path => path.startsWith(PATHS.catalogueTemplates);

PATHS.isCatalogueArchived = path => path.startsWith(PATHS.catalogueArchived);

PATHS.isPurchasedCatalogue = path => path.startsWith(PATHS.cataloguePurchased);

/* EDITOR PATHS */
PATHS.editor = "/editor";
PATHS.editorDesign = PATHS.editor + "/:designId";
PATHS.buildEditorDesignPath = designId => {
  return PATHS.editor + `/${designId}`;
};

/* check if on the editor page */
PATHS.isEditorPath = path => path.startsWith(PATHS.editor);

/* INVITATION PATHS */
PATHS.invitations = "/invitations";
PATHS.invitationsWithToken = PATHS.invitations + "/:token";
PATHS.isInvitationPath = path => path.startsWith(PATHS.invitations);

/* ORDERS PATHS */
PATHS.orders = "/orders";
PATHS.ordersId = PATHS.orders + "/:orderId";
PATHS.isOrderPath = path => path.startsWith(PATHS.orders);

/* PASSWORD RESET PATHS */
PATHS.resetPassword = "/reset-password";
PATHS.resetPasswordWithToken = PATHS.resetPassword + "/:token";

/* SIGNUP PATHS */
PATHS.signup = "/signup";
PATHS.signupWithToken = PATHS.signup + "/:token?";
PATHS.signupTeams = PATHS.signup + PATHS.nestedPaths.teams;
PATHS.signupTeam = PATHS.signup + PATHS.nestedPaths.team;
PATHS.signupTeamDetails = PATHS.signupTeam + PATHS.nestedPaths.details;

/* TEAM PATHS */
PATHS.team = "/team";

/* WORKSPACE PATHS */
PATHS.workspace = PATHS.designs + "/workspace";
PATHS.workspaceAll = PATHS.workspace + "/all";
PATHS.workspaceBrandManagerApproved =
  PATHS.workspace + "/brand-manager-approved";
PATHS.workspaceBrandManagerApprovedSearch =
  PATHS.workspaceBrandManagerApproved + "/search";
PATHS.workspaceApproved = PATHS.workspace + "/approved";
PATHS.workspaceArchived = PATHS.workspace + "/archived";
PATHS.workspaceUploads = PATHS.workspace + PATHS.uploads;
PATHS.workspaceUploadsImages = PATHS.workspaceUploads + "/images";
PATHS.workspaceUploadsFonts = PATHS.workspaceUploads + "/fonts";
PATHS.workspaceUploadsAnimations = PATHS.workspaceUploads + "/videos";
PATHS.workspaceDrafts = PATHS.workspace + "/drafts";
PATHS.workspaceApprovedSearch = PATHS.workspaceApproved + "/search";
PATHS.workspaceDraftsSearch = PATHS.workspaceDrafts + "/search";
PATHS.workspaceFolders = PATHS.workspace + PATHS.folders;
PATHS.workspaceShared = PATHS.workspace + PATHS.shared;
PATHS.workspaceUploadsSmartText = PATHS.workspaceUploads + "/smart-text";

/* RENDERER PATHS */
PATHS.renderer = PATHS.render + "/design/:designId/version/:designDataId";
PATHS.isRenderer = path => {
  return path.startsWith(PATHS.render);
};

PATHS.isCatalogueFolder = path => {
  return path.startsWith(PATHS.catalogueFolders);
};

PATHS.isCatalogue = path => {
  return path.includes(PATHS.catalogue);
};

/* check if a path is a workspace drafts path */
PATHS.isWorkspaceDraftsPath = path => {
  return path.startsWith(PATHS.workspaceDrafts);
};

PATHS.isCatalogueEasilPath = path => {
  return path.startsWith(PATHS.catalogueEasil);
};

/* check if on a design search page */
PATHS.isDesignSearch = path => {
  const designSearchPaths = [
    PATHS.catalogueEasilSearch,
    PATHS.catalogueTemplatesSearch,
    PATHS.workspaceApprovedSearch,
    PATHS.workspaceDraftsSearch,
    PATHS.workspaceBrandManagerApprovedSearch,
    PATHS.cataloguePurchasedSearch
  ];

  return designSearchPaths.includes(path);
};

/* check if on the draft search page */
PATHS.isWorkspaceDraftsSearch = path => {
  return path.startsWith(PATHS.workspaceDraftsSearch);
};

/* check if on the workspace page */
PATHS.isWorkspace = path => {
  return path.startsWith(PATHS.workspace);
};

PATHS.isCatalogue = path => {
  return path.startsWith(PATHS.catalogue);
};

/* check if on the workspace approved page */
PATHS.isWorkspaceApproved = path => {
  return path.startsWith(PATHS.workspaceApproved);
};

/* check if on the brand manager approved page */
PATHS.isWorkspaceBrandManagerApproved = path => {
  return path.startsWith(PATHS.workspaceBrandManagerApproved);
};

PATHS.isWorkspaceFolder = path => {
  return path.startsWith(PATHS.workspaceFolders);
};

/* check if on the workspace uploads page */
PATHS.isWorkspaceUploads = path => {
  return path && path.startsWith(PATHS.workspaceUploads);
};

/* check if on the workspace uploads images page */
PATHS.isWorkspaceUploadsImages = path => {
  return path && path.startsWith(PATHS.workspaceUploadsImages);
};

/* check if on the workspace uploads animations page */
PATHS.isWorkspaceUploadsAnimations = path => {
  return path && path.startsWith(PATHS.workspaceUploadsAnimations);
};

/* check if on the Brand Kit Videos page */
PATHS.isBrandKitVideos = path => {
  return path && path.startsWith(PATHS.brandAnimations);
};

/* check if on the workspace uploads fonts page */
PATHS.isWorkspaceUploadsFonts = path => {
  return path && path.startsWith(PATHS.workspaceUploadsFonts);
};

/* check if on the shared drafts page */
PATHS.isWorkspaceShared = path => {
  return path.startsWith(PATHS.workspaceShared);
};

/* check if on a design archive page */
PATHS.isDesignArchive = path => {
  const designArchivePaths = [PATHS.catalogueArchived, PATHS.workspaceArchived];

  return designArchivePaths.includes(path);
};

/* check if on the team templates page */
PATHS.isTeamTemplates = path => {
  return path && path.includes(PATHS.catalogueTemplates);
};

PATHS.userService = "https://easil.user.com";

PATHS.knowledgeBasePath = `${PATHS.userService}/${process.env.REACT_APP_USER_ENGAGE_API_KEY}/knowledge-base`;

PATHS.knowledgeBaseCreatingMultipleTeams = `${PATHS.knowledgeBasePath}/creating-multiple-teams`;

PATHS.purchase = "/purchase";

PATHS.purchaseCollection = `${PATHS.purchase}/collection`;
PATHS.purchaseCollectionWithCollectionId = `${PATHS.purchaseCollection}/:collectionId`;

PATHS.isPurchaseCollection = path => {
  return path.startsWith(PATHS.purchaseCollection);
};

export default PATHS;
