import React from "react";
import PhotoFrameCropResizeHandler from "./PhotoFrameCropResizeHandler";
import { connect } from "react-redux";
import { Logger } from "lib";
import {
  isCroppingSelector,
  contextSelector,
  selectedItemsSelector,
  zoomSelector,
  designDataSelector,
  elementPreviewsSelector
} from "state/ui/editorContext/editorContextSelectors";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { getCanvasbackground } from "views/components/Editor/utils";
import { EditorPhotoFrameOps } from "views/components/Editor/editorOps";

class PhotoFrameCropResizeHandlerContainer extends React.Component {
  constructor(props) {
    super(props);

    this.startPhotoFrameCropResizing = this.startPhotoFrameCropResizing.bind(
      this
    );
    this.finishPhotoFrameCropResizing = this.finishPhotoFrameCropResizing.bind(
      this
    );
  }

  startPhotoFrameCropResizing() {
    Logger.info(
      "PhotoFrameCropResizeHandlerContainer.startPhotoInFrameCropResizing called"
    );
    const {
      selectedItems,
      context,
      zoom,
      designData,
      updateContext
    } = this.props;
    const canvasBackground = getCanvasbackground();

    const updatedContext = EditorPhotoFrameOps.startPhotoFrameCropResizing({
      selectedItems,
      context,
      zoom,
      designData,
      canvasBackground
    });

    updateContext(updatedContext);
  }

  finishPhotoFrameCropResizing({
    handlerInitialPosition,
    anchorPoint,
    differenceFromInitialOffset,
    pageHtmlElement,
    originPointUnrotated,
    anchorPointUnrotated,
    scaleAnchorPoint,
    position,
    selectionBoxNodeCenter
  }) {
    Logger.info(
      "PhotoFrameCropResizeHandlerContainer.finishPhotoInFrameCropResizing called"
    );
    const {
      zoom,
      selectedItems,
      designData,
      context: { selectedPhotoInFrameId },
      updateContext
    } = this.props;

    const {
      vectorElement,
      photoFrame
    } = EditorPhotoFrameOps.getVectorElementWithPreview({
      selectedItems,
      designData,
      selectedPhotoInFrameId
    });
    const pageOffset = pageHtmlElement.getBoundingClientRect();

    const containerProps = {
      zoom,
      selectedItems,
      vectorElement,
      photoFrame
    };
    const newImageArguments = {
      pageOffset,
      handlerInitialPosition,
      anchorPoint,
      differenceFromInitialOffset,
      originPointUnrotated,
      anchorPointUnrotated,
      scaleAnchorPoint,
      position,
      selectionBoxNodeCenter
    };

    const updatedContext = EditorPhotoFrameOps.finishPhotoFrameCropResizing({
      containerProps,
      newImageArguments
    });

    updateContext(updatedContext);
  }

  render() {
    const {
      context: { isCroppingPhotoFrame, selectedPhotoInFrameId },
      designData,
      selectedItems,
      zoom,
      canvasBackground
    } = this.props;

    if (!isCroppingPhotoFrame) {
      return null;
    }

    const {
      vectorElement,
      photoFrame
    } = EditorPhotoFrameOps.getVectorElementWithPreview({
      selectedItems,
      designData,
      selectedPhotoInFrameId
    });

    return (
      <PhotoFrameCropResizeHandler
        {...this.props}
        canvasBackgroundHtmlElement={canvasBackground}
        selectedPhotoFrameId={selectedPhotoInFrameId}
        startPhotoFrameCropResizing={this.startPhotoFrameCropResizing}
        finishPhotoFrameCropResizing={this.finishPhotoFrameCropResizing}
        selectedItems={selectedItems}
        getElement={id => designData.elements[id]}
        zoom={zoom}
        photoFrame={photoFrame}
        vectorElement={vectorElement}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isCropping: isCroppingSelector(state),
    context: contextSelector(state),
    selectedItems: selectedItemsSelector(state),
    zoom: zoomSelector(state),
    elementPreviews: elementPreviewsSelector(state),
    designData: designDataSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContext: args => dispatch(updateEditorContextState(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoFrameCropResizeHandlerContainer);
