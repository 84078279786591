import {
  Logger,
  isEmpty,
  decodeHtmlTable,
  sanitizeHTMLForTextUsingDOMNodes
} from "lib";
import { tableHeightsUpdater } from "./tableSimulator";
import {
  findTableNextTextFieldAddress,
  findTablePreviousTextFieldAddress
} from "./helpers";

const EditorTableOps = {
  updateTextFieldInContext({
    rowIndex,
    cellIndex,
    textFieldIndex,
    rowType,
    table,
    context,
    updateContextState
  }) {
    const textField = table.getTextFieldByAddress(
      rowIndex,
      cellIndex,
      textFieldIndex
    );

    this.props.updateContextState({
      context: {
        ...context,
        rowIndex,
        cellIndex,
        textFieldIndex,
        selectedTableFieldId: textField.id,
        rowType
      }
    });
  },

  /* This function currently mutates the tableElement,
   * a fix for it will come later as it will be a bit more
   * time consuming to make this step async and then proceed
   * to the next table opration */
  applyOnGoingChanges(tableElement, selectedItems) {
    if (
      selectedItems &&
      selectedItems[0] &&
      selectedItems[0].preview &&
      !isEmpty(selectedItems[0].preview)
    ) {
      const tablePreview = selectedItems[0].preview;

      const rows = decodeHtmlTable(
        Object.assign({}, tablePreview, { uniqueId: tableElement.uniqueId })
      )[tableElement.uniqueId].rows;

      tableElement.rows = rows;
    }
  },

  onSelectNextTableTextField() {
    const { context, designData, selectedItems } = this.state;
    const { rowIndex, cellIndex, textFieldIndex } = context;

    const tableId = selectedItems[0].itemId;
    const tableElement = designData.getElement(tableId);

    EditorTableOps.applyOnGoingChanges(tableElement, selectedItems);

    const nextTextFieldAddress = findTableNextTextFieldAddress({
      table: tableElement,
      currentRowIndex: rowIndex,
      currentCellIndex: cellIndex,
      currentTextFieldIndex: textFieldIndex
    });

    if (nextTextFieldAddress) {
      const rowType =
        tableElement.rows[nextTextFieldAddress.nextRowIndex].rowTypeCode;

      EditorTableOps.updateTextFieldInContext({
        rowIndex: nextTextFieldAddress.nextRowIndex,
        cellIndex: nextTextFieldAddress.nextCellIndex,
        textFieldIndex: nextTextFieldAddress.nextTextFieldIndex,
        table: tableElement,
        context,
        editor: this,
        rowType
      });

      return;
    }

    /* if there is no next Field  we duplicate the last row and select it*/
    const newTable = tableElement.duplicateLastRow();
    const newRowIndex = rowIndex + 1;
    const nextTextField = newTable.getTextFieldByAddress(newRowIndex, 0, 0);

    const elementsId = [newTable.uniqueId];
    const attributes = { rows: newTable.rows, height: newTable.height };
    const newDesignData = designData.updateElementsAttribute({
      elementsId,
      attributes
    });

    this.updateStateAndSave({
      context: {
        ...context,
        rowIndex: newRowIndex,
        cellIndex: 0,
        textFieldIndex: 0,
        selectedTableFieldId: nextTextField.id
      },
      designData: newDesignData
    });
  },

  onSelectPreviousTableTextField() {
    const { context, designData, selectedItems } = this.props;
    const { rowIndex, cellIndex, textFieldIndex } = context;

    const tableId = selectedItems[0].itemId;
    const tableElement = designData.getElement(tableId);

    const previousTextFieldAddress = findTablePreviousTextFieldAddress({
      table: tableElement,
      currentRowIndex: rowIndex,
      currentCellIndex: cellIndex,
      currentTextFieldIndex: textFieldIndex
    });

    if (previousTextFieldAddress) {
      const rowType =
        tableElement.rows[previousTextFieldAddress.previousRowIndex]
          .rowTypeCode;

      EditorTableOps.updateTextFieldInContext({
        rowIndex: previousTextFieldAddress.previousRowIndex,
        cellIndex: previousTextFieldAddress.previousCellIndex,
        textFieldIndex: previousTextFieldAddress.previousTextFieldIndex,
        table: tableElement,
        context,
        editor: this,
        rowType
      });

      return;
    }
  },

  onSelectedTableAttributeChange(attributes) {
    Logger.info("Editor.onSelectedTableAttributeChange called");

    const { designData, selectedItems } = this.state;

    const tableId = selectedItems[0].itemId;

    const table = designData.getElement(tableId);

    /* Apply to table the changed attributes*/
    const tableWithUpdatedAttributes = {
      ...table,
      ...attributes
    };

    const tableWithHeightUpdated = tableHeightsUpdater(
      tableWithUpdatedAttributes
    );

    const newDesignData = designData.updateElementsAttribute({
      elementsId: selectedItems.map(item => item.itemId),
      attributes: { ...tableWithHeightUpdated }
    });

    this.updateStateAndSave({
      designData: newDesignData
    });
  },

  duplicateLastTableRow({ tableId }) {
    const { designData, selectedItems } = this.state;

    const tableElement = designData.getElement(tableId);

    EditorTableOps.applyOnGoingChanges(tableElement, selectedItems);

    const newDesignData = designData.duplicateLastTableRow({ tableId });

    this.updateStateAndSave({
      designData: newDesignData
    });
  },

  setIsDraggingTable() {
    Logger.info("EditorTableOps.setIsDraggingTable called");
    const { context } = this.state;

    this.setState({
      context: {
        ...context,
        isDraggingSidebarTable: true
      }
    });
  },

  unsetIsDraggingTable() {
    Logger.info("EditorTableOps.unsetIsDraggingTable called");
    const { context } = this.state;

    this.setState({
      context: {
        ...context,
        isDraggingSidebarTable: true
      }
    });
  },

  deleteTableRow({ rowIndex, rowHeight }) {
    Logger.info("EditorTableOps.deleteRow called");
    const { designData, selectedItems, context, actionbar } = this.state;

    const elementId = selectedItems[0].itemId;

    const table = designData.getElement(elementId);

    EditorTableOps.applyOnGoingChanges(table, selectedItems);

    if (table.rows.length === 1) {
      return;
    }

    this.updateStateAndSave({
      designData: designData.deleteTableRow({
        rowIndex,
        rowHeight,
        elementId
      }),
      context: {
        ...context,
        rowIndex: null,
        cellIndex: null,
        textFieldIndex: null,
        selectedTableFieldId: null
      },
      actionbar: {
        ...actionbar,
        buttonActive: null
      }
    });
  },

  onTableTextFieldChange() {
    Logger.info("EditorTableOps.onTableTextFieldChange called");
    const { selectedItems, onElementAttributeChange } = this.props;

    /* if there is no preview then nothing is changed, escape */
    if (
      !selectedItems ||
      !selectedItems[0] ||
      !selectedItems[0].preview ||
      isEmpty(selectedItems[0].preview)
    ) {
      return;
    }

    const tablePreview = selectedItems[0].preview;

    tablePreview.rows = decodeHtmlTable(Object.assign({}, tablePreview))[
      tablePreview.uniqueId
    ].rows;

    /* if the preview contains no changes escape */
    if (isEmpty(tablePreview)) {
      return;
    }

    onElementAttributeChange(tablePreview);
  },

  onTableTextFieldPreview({
    value,
    rowIndex,
    cellIndex,
    textFieldIndex,
    tableId
  }) {
    Logger.info("EditorTableOps.onTableTextFieldPreview called");
    const { designData } = this.props;

    const tableElement = designData.getElement(tableId);

    // sanitize the value so that the preview has clean input
    const sanitizedValue = sanitizeHTMLForTextUsingDOMNodes(value);

    const tableElementWithTextFieldUpdated = tableElement.updateTextFieldValue({
      rowIndex,
      cellIndex,
      textFieldIndex,
      value: sanitizedValue
    });

    const tableWithHeightsUpdated = tableHeightsUpdater(
      tableElementWithTextFieldUpdated
    );

    this.props.onElementPreview({
      rows: tableWithHeightsUpdated.rows,
      height: tableWithHeightsUpdated.height
    });
  },

  selectTableTextField({
    rowIndex,
    cellIndex,
    textFieldIndex,
    textFieldId,
    rowType
  }) {
    Logger.info("Editor.selectTableTextField called");
    const { actionbar } = this.props;
    const { updateActionBarState, updateContextState } = this.props;

    updateContextState({
      context: {
        selectedTableFieldId: textFieldId,
        rowIndex,
        cellIndex,
        textFieldIndex,
        rowType
      }
    });

    updateActionBarState({
      ...actionbar,
      buttonActive: "tableTextFieldCell"
    });

    // this.setState({
    //   actionbar: {
    //     ...actionbar,
    //     buttonActive: "tableTextFieldCell"
    //   },
    //   context: {
    //     selectedTableFieldId: textFieldId,
    //     rowIndex,
    //     cellIndex,
    //     textFieldIndex,
    //     rowType
    //   }
    // });
  }
};

export default EditorTableOps;
