import React, { Component } from "react";
import style from "./style.module.css";
import { H2 } from "views/components";
import CaretSlimIcon from "views/components/icons/CaretSlimIcon";
import {
  formatOrderStatus,
  formatOrderDate
} from "../orderContainer/ordersHelpers";
import PATHS from "routes/paths";

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // need to fetch information for the orders page here
    this.props.getAllOrders();
  }

  getOrderListColumns() {
    return {
      orderNumber: {
        key: "id",
        label: "Order Number",
        width: "20%"
      },
      status: {
        key: "status",
        label: "Status",
        width: "20%",
        format: formatOrderStatus
      },
      datePaid: {
        key: "paidAt",
        label: "Date Paid",
        width: "30%",
        format: formatOrderDate
      },
      viewDetails: {
        key: "viewDetails",
        label: "",
        width: "30%", //"107px",
        default: order => (
          <div
            onClick={() => {
              if (order.status === "IN_PROGRESS") {
                return this.props.changePage(PATHS.cart);
              }
              this.props.moveToOrderDetails(order.id);
            }}
          >
            View Details
            <CaretSlimIcon color="#2c6fcf" size={10} direction="right" />
          </div>
        )
      }
    };
  }

  renderOrdersRows() {
    const { orders } = this.props;

    return orders.map(order => (
      <div key={order.id} className={style.ordersListRow}>
        {Object.values(this.getOrderListColumns()).map(column => {
          let value = order[column.key];
          if (column.format && value) value = column.format(value);
          if (!value && column.default) {
            value = column.default(order);
          } else if (!value && !column.default) {
            value = "";
          }
          return (
            <div
              key={`${order.id}-${column.key}`}
              style={{ width: column.width }}
              data-column-key={column.key}
            >
              {value}
            </div>
          );
        })}
      </div>
    ));
  }

  render() {
    return (
      <div className={style.wrapper}>
        <H2>Orders</H2>
        <div className={style.ordersList}>
          <div className={style.ordersListRow}>
            {Object.values(this.getOrderListColumns()).map(column => (
              <div key={column.key} style={{ width: column.width }}>
                {column.label}
              </div>
            ))}
          </div>
          {this.renderOrdersRows()}
        </div>
      </div>
    );
  }
}

export default Orders;
