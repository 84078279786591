import * as types from "state/entities/cards/cardsTypes";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null
};

const api = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_CARDS: {
      const page = {
        [action.request.page]: {
          ...state.pages[action.request.page],
          isFetching: true
        }
      };

      return {
        ...state,
        pages: { ...state.pages, ...page }
      };
    }

    case types.FETCH_CARDS_SUCCESS: {
      const {
        request: { page },
        response: { ids }
      } = action;
      const pages = {
        ...state.pages,
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false
        }
      };

      return {
        ...state,
        pages: pages
      };
    }

    default:
      return state;
  }
};

export default api;
