import { createSelector } from "reselect";

const cardsEntitiesSelector = state => state.entities.cards;

const processCurrentCardSelector = cards =>
  Object.keys(cards).map(id => cards[id])[0];

export const currentCardSelector = createSelector(
  [cardsEntitiesSelector],
  processCurrentCardSelector
);
