import React from "react";
import style from "./style.module.css";
import PropTypes from "prop-types";

const Table = ({ headers = [], children, className = "" }) => {
  return (
    <div className={`${style.table} ${className}`} data-testid="Table">
      <div className={style.header} aria-label="Table Headers">
        {headers.map((header, index) => (
          <div key={index} className={style.cell} style={header.style}>
            {header.name}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};
Table.propTypes = {
  headers: PropTypes.array,
  children: PropTypes.node.isRequired
};
export default Table;
