import { createSelector } from "reselect";
import { isNil } from "lib";

export const workspaceSizesSelector = ({ state }) =>
  state.entities.workspaceSizes;

export const getBrandManagerApprovedSizes = ({ state }) => {
  return state.entities.workspaceSizes.brandManagerApproved;
};

const typePassThrough = ({ type }) => type;

const processWorkspaceSizesByType = (workspaceSizes, type) => {
  if (!workspaceSizes) return [];

  const sizes = Object.values(workspaceSizes).reduce(
    (previousSizes, currentSizes) => {
      if (isNil(currentSizes)) {
        return previousSizes;
      }
      return [...previousSizes, ...Object.values(currentSizes)];
    },
    []
  );

  return sizes.filter(size => size.templateType === type);
};

export const workspaceSizesByType = createSelector(
  [workspaceSizesSelector, typePassThrough],
  processWorkspaceSizesByType
);
