export const FETCH_IMAGE_FOLDERS_REQUEST = "API/ENTITIES/FOLDERS/IMAGE_REQUEST";
export const FETCH_IMAGE_FOLDERS_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/IMAGE_REQUEST_SUCCESS";
export const FETCH_IMAGE_FOLDERS_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/IMAGE_REQUEST_FAILURE";

export const CREATE_IMAGE_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/CREATE_IMAGE_REQUEST";
export const CREATE_IMAGE_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/CREATE_IMAGE_REQUEST_SUCCESS";
export const CREATE_IMAGE_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/CREATE_IMAGE_REQUEST_FAILURE";

export const UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST";
export const UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST_SUCCESS";
export const UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/UPLOAD_IMAGE_TO_IMAGE_FOLDER_REQUEST_FAILURE";

export const ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST";
export const ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST_SUCCESS";
export const ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/ADD_IMAGE_TO_IMAGE_FOLDER_REQUEST_FAILURE";

export const BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST";
export const BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS";
export const BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST_FAILURE";

export const REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST";
export const REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST_SUCCESS";
export const REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST_FAILURE";

export const DELETE_IMAGE_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/DELETE_IMAGE_REQUEST_SUCCESS";
export const DELETE_IMAGE_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/DELETE_IMAGE_REQUEST_FAILURE";

export const UPDATE_IMAGE_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/UPDATE_IMAGE_REQUEST";
export const UPDATE_IMAGE_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/UPDATE_IMAGE_REQUEST_SUCCESS";
export const UPDATE_IMAGE_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/UPDATE_IMAGE_REQUEST_FAILURE";

export const USER_IMAGE_IN_FOLDER_UPLOAD_URL_REQUEST =
  "API/USER_IMAGE_IN_FOLDER_UPLOAD_URL_REQUEST";
export const USER_IMAGE_IN_FOLDER_UPLOAD_URL_REQUEST_SUCCESS =
  "API/USER_IMAGE_IN_FOLDER_UPLOAD_URL_REQUEST_SUCCESS";
export const USER_IMAGE_IN_FOLDER_UPLOAD_URL_REQUEST_FAILURE =
  "API/USER_IMAGE_IN_FOLDER_UPLOAD_URL_REQUEST_FAILURE";
