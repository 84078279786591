import React, { Component } from "react";
import style from "./style.module.css";
import ScrollingOverflowText from "views/components/ScrollingOverflowText/ScrollingOverflowText";
import Button from "views/components/button/Button";

/**
 * Smart text override row displays the name and value of a defaulted smart text enitity. The row allows for overriding of the default values
 */
export default class SmartTextOverideRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { smartText, handleOpenModal, selectRow } = this.props;

    return (
      <div
        className={`${style.overrideRow} notranslate`}
        key={smartText.name}
        data-testid="smartTextOverideRow"
      >
        <div className={style.overrideRowText}>
          <div>
            <ScrollingOverflowText className={style.smartTextValue}>
              {smartText.value}
            </ScrollingOverflowText>

            <ScrollingOverflowText className={style.smartTextName}>
              {`{${smartText.name}}`}
            </ScrollingOverflowText>
          </div>
        </div>
        <Button
          onClick={() => {
            handleOpenModal();
            selectRow(smartText);
          }}
        >
          Override
        </Button>
      </div>
    );
  }
}
