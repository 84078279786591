import { combineReducers } from "redux";

import { default as actionBar } from "./actionBar/actionBarReducers";
import { default as smartTextAutoComplete } from "./smartTextAutoComplete/smartTextAutoCompleteReducers";
import { default as sidePanel } from "./sidePanel/sidePanelReducers";
import { default as invitation } from "./invitation/invitationReducers";
import { default as teamPage } from "./teamPage/teamPageReducers";
import { default as currentTeam } from "./currentTeam/currentTeamReducers";
import { default as inviteToTeamForm } from "./inviteToTeamForm/inviteToTeamFormReducers";
import { default as teamSignup } from "./teamSignup/TeamSignupReducers";
import { default as loginPage } from "./loginPage/LoginPageReducers";
import { default as brandKit } from "./brandKit/BrandKitReducers";
import { default as editor } from "./editor/editorReducers";
import { default as editorContext } from "./editorContext/editorContextReducers";
import { default as teamSettings } from "./teamSettings/teamSettingsReducers";
import { default as userSettings } from "./userSettings/userSettingsReducers";
import { default as editorLayouts } from "./editorLayouts/editorLayoutsReducers";
import { default as userTeamFonts } from "./userTeamFonts/userTeamFontsReducers";
import { default as downloads } from "./downloads/downloadsReducers";
import { default as teamFolders } from "./teamFolders/teamFoldersReducers";
import { default as createImageFolder } from "./createImageFolder/createImageFolderReducers";
import { default as addContentToFolder } from "./addContentToFolder/addContentToFolderReducers";
import { default as addTagToDesign } from "./addTagToDesign/addTagToDesignReducers";
import { default as editorDesign } from "./editorDesign/editorDesignReducers";
import { default as fetchDesignFolder } from "./fetchDesignFolder/fetchDesignFolderReducers";
import { default as fetchDesign } from "./fetchDesign/fetchDesignReducers";
import { default as subscription } from "./subscription/subscriptionReducers";
import { default as editorImageSearch } from "./editorImageSearch/editorImageSearchReducers";
import { default as resetPassword } from "./resetPassword/resetPasswordReducers";
import { default as cart } from "./cart/cartReducers";
import { default as upgradeModal } from "./upgradeModal/upgradeModalReducers";
import { default as designsPage } from "./designsPage/designsPageReducers";
import { default as toaster } from "./toaster/ToasterReducers";
import { userPreferencesReducers as userPreferences } from "./userPreferences";
import { default as confirmationModal } from "./confirmationModal/confirmationModalReducer";
import { default as removeBg } from "./workspace/myImages/removeBgReducer";
import { default as editorErrorModal } from "./editorErrorModal/editorErrorModalReducers";
import { default as takeoverSharedDesignModal } from "./takeoverSharedDesignModal/takeoverSharedDesignModalReducers";
import { default as shareModal } from "./shareModal/shareModalReducers";
import { default as requestApprovalModal } from "./requestApprovalModal/requestApprovalModalReducer";
import { default as approvalModal } from "./approvalModal/approvalModalReducer";
import { default as notification } from "./notification/notificationReducers";
import { default as clipBoard } from "./clipBoard";
import { default as teamMenu } from "./header/TeamMenu/TeamMenuReducers";
import { default as myImages } from "./MyImages/MyImagesReducers";
import { default as myAnimations } from "./MyAnimations/MyAnimationsReducers";
import { default as updateNotification } from "./updateNotification/updateNotificationReducers";
import { default as collectionPreviewModal } from "./collectionPreviewModal/collectionPreviewModalReducers";
import { default as editorActionPropagation } from "./editorActionPropagation/editorActionPropagationReducers";
import { default as purchaseCollectionModal } from "./purchaseCollectionModal/purchaseCollectionModalReducers";
import { default as editorAnimationSearch } from "./editorAnimationSearch/editorAnimationSearchReducers";
import { default as portal } from "./portal/portalReducers";

export default combineReducers({
  actionBar,
  addContentToFolder,
  addTagToDesign,
  approvalModal,
  brandKit,
  cart,
  clipBoard,
  collectionPreviewModal,
  confirmationModal,
  createImageFolder,
  currentTeam,
  designsPage,
  downloads,
  editor,
  editorActionPropagation,
  editorAnimationSearch,
  editorContext,
  editorDesign,
  editorErrorModal,
  editorImageSearch,
  editorLayouts,
  fetchDesign,
  fetchDesignFolder,
  invitation,
  inviteToTeamForm,
  loginPage,
  myAnimations,
  myImages,
  notification,
  portal,
  purchaseCollectionModal,
  requestApprovalModal,
  resetPassword,
  shareModal,
  sidePanel,
  smartTextAutoComplete,
  subscription,
  takeoverSharedDesignModal,
  teamFolders,
  teamMenu,
  teamPage,
  teamSettings,
  teamSignup,
  toaster,
  updateNotification,
  upgradeModal,
  userPreferences,
  userSettings,
  userTeamFonts,
  workspace: combineReducers({
    removeBg
  })
});
