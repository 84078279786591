import React, { Component } from "react";
import { Loading, Input, Button } from "views/components";
import EasilIcon from "views/components/icons/EasilIcon";
import style from "./style.module.css";
import { userValidators, pick, values } from "lib";
import PATHS from "routes/paths";
import { Link } from "react-router-dom";

class ResetPasswordWithToken extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isValid: false,
      password: { value: null, error: null }
    };
  }

  UNSAFE_componentWillMount() {
    const { onValidateResetPasswordToken, token } = this.props;

    onValidateResetPasswordToken({ token });
  }

  isFormValid(state) {
    const availableAttributes = ["password"];

    const attributes = pick(state, availableAttributes);

    return values(attributes).every(
      attribute => attribute.value && !attribute.error
    );
  }

  handleChange(attribute, value) {
    const { error } = userValidators[attribute](value);

    const newState = {
      ...this.state,
      [attribute]: { value: value, error: error }
    };

    this.setState({
      ...newState,
      isValid: this.isFormValid(newState),
      isSubmitted: false
    });
  }

  handleSubmit() {
    const { userId, token } = this.props;
    const { password } = this.state;

    this.props.onUpdatePassword({ userId, token, password: password.value });
  }

  render() {
    const {
      isSubmitting,
      isSuccess,
      isFetching,
      errors,
      isValidToken
    } = this.props;
    const { password, isValid } = this.state;

    return (
      <React.Fragment>
        <div className={style.logo}>
          <EasilIcon size="38px" />
        </div>
        <div className={style.title}>
          {isSuccess ? "Password successfully reset" : "Enter a new password"}
        </div>
        <div className={style.columns}>
          <div className={style.form}>
            {errors && (
              <div className={style.errors}>
                {errors.map((error, index) => (
                  <div
                    key={`reset-password-error-${index}`}
                    className={`${style.error} ${style.textCenter}`}
                  >
                    {error.error}: {error.value}
                  </div>
                ))}
              </div>
            )}
            {isSuccess && (
              <div>
                <p>
                  Thanks for resetting your password. Please login to continue
                </p>
                <div className={style.actions}>
                  <Link to={PATHS.login}>
                    <Button theme="blueSolid">Login</Button>
                  </Link>
                </div>
              </div>
            )}
            {isFetching && <Loading />}
            {!isFetching && isValidToken && !isSuccess && (
              <React.Fragment>
                <div>
                  <Input
                    disabled={isSubmitting}
                    type="password"
                    placeholder="Password"
                    value={password.value || ""}
                    error={password.error}
                    onChange={e =>
                      this.handleChange("password", e.target.value)
                    }
                    showErrorMsg={errors.length > 0}
                  />
                </div>
                <div className={style.actions}>
                  <Button
                    theme="greenSolid"
                    onClick={this.handleSubmit}
                    disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting ? (
                      <Loading size="16px" hexColor="fff" />
                    ) : (
                      "Change Password"
                    )}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ResetPasswordWithToken.propTypes = {};

export default ResetPasswordWithToken;
