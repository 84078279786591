import { DropTarget } from "react-dnd";
import TableRowDropZone from "../TableRowDropZone";
import {
  handleTableRowHover,
  handleTableHover
} from "./TableRowDropZoneDragTargetHandlers";

const LayerTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }

    const dragItemType = monitor.getItemType();

    if (dragItemType === "TABLE_ROW") {
      handleTableRowHover({ monitor, props });
      return;
    }

    if (dragItemType === "SIDEBAR_TABLE") {
      handleTableHover({ monitor, props });
      return;
    }
  },
  drop(props, monitor, component) {
    return {
      hoverIndex: props.index
    };
  }
};

const collectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    dragItemType: monitor.getItemType()
  };
};

export default DropTarget(
  ["TABLE_ROW", "SIDEBAR_TABLE"],
  LayerTarget,
  collectTarget
)(TableRowDropZone);
