import { ResizeHandler } from "./ResizeHandler";
import { getHtmlNodeCenter } from "lib/htmlElements/dimensions";

export const getHandlerContactPoint = handlerNode => {
  switch (handlerNode.dataset.position) {
    case ResizeHandler.POSITIONS.N: {
      const boundingBox = handlerNode.getBoundingClientRect();

      return {
        x: boundingBox.left + boundingBox.width / 2,
        y: boundingBox.bottom
      };
    }

    case ResizeHandler.POSITIONS.S: {
      const boundingBox = handlerNode.getBoundingClientRect();

      return {
        x: boundingBox.left + boundingBox.width / 2,
        y: boundingBox.top
      };
    }

    case ResizeHandler.POSITIONS.W: {
      const boundingBox = handlerNode.getBoundingClientRect();

      return {
        x: boundingBox.left,
        y: boundingBox.top + boundingBox.height / 2
      };
    }

    case ResizeHandler.POSITIONS.E: {
      const boundingBox = handlerNode.getBoundingClientRect();

      return {
        x: boundingBox.right,
        y: boundingBox.top + boundingBox.height / 2
      };
    }
    default: {
      return getHtmlNodeCenter(handlerNode);
    }
  }
};
