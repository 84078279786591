import * as collectionAllocationsTypes from "./collectionAllocationsTypes";
import * as designsTypes from "state/entities/designs/designsTypes";
import { omit } from "lib";

export const initState = {};

const collectionAllocationsReducers = (state = initState, action) => {
  switch (action.type) {
    case collectionAllocationsTypes.COLLECTION_ALLOCATIONS_REQUEST: {
      return initState;
    }

    case collectionAllocationsTypes.COLLECTION_ALLOCATIONS_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;

      return entities.allocations || {};
    }

    case designsTypes.DESIGN_UNSHARE_REQUEST: {
      return omit(state, action.request.extra.allocationId);
    }

    default:
      return state;
  }
};

export default collectionAllocationsReducers;
