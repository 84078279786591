import { emailValidation, messages } from "lib";
import { allCountries } from "lib/countriesList";

const {
  presence,
  selectAtLeastOne,
  invalidEmail,
  invalidCountry,
  invalidPasswordLength,
  invalidPasswordCombination,
  fortyPlusChars
} = messages.errors;

const PASSWORD_BLACKLIST = [
  "password",
  "12345678",
  "123456789",
  "1234567890",
  "qwertyuiop",
  "asdfghjkl",
  "Password",
  "football",
  "iloveyou",
  "starwars",
  "passw0rd"
];

const email = (email = "") => {
  /* default replace for null since null does not trigger defaults */
  if (email === null) email = "";

  const emailTrimmed = email.trim();

  if (!emailTrimmed) return { error: presence };

  if (!emailValidation(emailTrimmed)) return { error: invalidEmail };

  return { error: null };
};

const password = (password = "") => {
  if (!password) return { error: presence };

  if (password.length < 8) return { error: invalidPasswordLength };

  // TODO: I would probably remove this as the list will be updated semi regularly so easier to manage on the server.
  if (PASSWORD_BLACKLIST.includes(password))
    return { error: invalidPasswordCombination };

  return { error: null };
};

const name = (name = "", errors) => {
  const nameTrimmed = name.trim();

  if (!nameTrimmed) return { error: presence };

  if (nameTrimmed.length > 40) return { error: fortyPlusChars };

  return { error: null };
};

const country = (country = "", errors) => {
  if (!country) return { error: presence };

  if (!allCountries.find(countryPreset => countryPreset.key === country)) {
    return { error: invalidCountry };
  }

  return { error: null };
};

const roles = (roles, errors) => {
  if (roles.every(role => !role.selected)) {
    return { error: selectAtLeastOne };
  }

  return { error: null };
};

export const validate = user => {
  let validations = {};

  for (let field in user) {
    if (!Object.keys(userValidators).includes(field)) return;

    validations[field] = userValidators[field](user[field]);
  }

  return validations;
};
const userValidators = {
  name,
  email,
  roles,
  password,
  country
};

export default userValidators;
