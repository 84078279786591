import React, { Component } from "react";
import PropType from "prop-types";
import style from "./style.module.css";
import CheckIcon from "views/components/icons/CheckIcon";

class Option extends Component {
  render() {
    const {
      label,
      value,
      onClick,
      selected,
      className,
      iconColor
    } = this.props;
    return (
      <div
        className={`${style.option} ${className}`}
        key={value}
        onClick={() => onClick(value)}
      >
        <div className={style.name}>{label}</div>
        {selected && (
          <div className={style.selected}>
            <CheckIcon width={"11"} height={"11"} color={iconColor} />
          </div>
        )}
      </div>
    );
  }
}

Option.propTypes = {
  label: PropType.string,
  value: PropType.string,
  onClick: PropType.func,
  selected: PropType.bool,
  className: PropType.string,
  iconColor: PropType.string
};

export default Option;
