import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const VectorTextContainer = lazy(() => import("./VectorTextContainer"));

export const VectorTextContainerLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <VectorTextContainer {...props} />
  </Suspense>
);
VectorTextContainerLazy.displayName = "VectorTextContainer";

export default VectorTextContainerLazy;
