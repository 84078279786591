import React, { Component } from "react";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import PadlockIcon from "views/components/icons/PadlockIcon";
import EditPadlockIcon from "views/components/icons/EditPadlockIcon";
import style from "./styleElementContextMenu.module.css";
import { noop } from "lodash";
import { getIsImageReplaceAvailableForElement } from "lib/elements";

class ElementContextMenu extends Component {
  constructor(props) {
    super(props);

    this.handlePopout = this.handlePopout.bind(this);
    this.handleLockClick = this.handleLockClick.bind(this);
    this.getMenuContent = this.getMenuContent.bind(this);
    this.checkIsImageReplaceAvailable = this.checkIsImageReplaceAvailable.bind(
      this
    );
    this.getMenuAttributes = this.getMenuAttributes.bind(this);
    this.handleReplaceClick = this.handleReplaceClick.bind(this);
    this.getReplaceOffset = this.getReplaceOffset.bind(this);
    this.updateEdgeOffset = this.updateEdgeOffset.bind(this);

    this.popoutRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.transformRef = React.createRef();
    this.state = {
      isOpen: false,
      isImageReplaceAvailable: false,
      edgeOffset: 0
    };
  }

  componentDidMount() {
    this.checkIsImageReplaceAvailable();
    this.updateEdgeOffset();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.contextMenu && prevProps.contextMenu) {
      this.setState({ isOpen: false });
    }
    if (
      this.props.element !== prevProps.element ||
      this.props.elements !== prevProps.elements
    ) {
      this.checkIsImageReplaceAvailable();
      this.updateEdgeOffset();
    }
  }

  updateEdgeOffset() {
    // reset the edgeOffset
    this.setState(
      {
        edgeOffset: 0
      },
      () => {
        // recalculate the edge offset
        const actionbar = document.querySelector("#editor-actionbar");
        if (!actionbar) return;
        const actionbarBoundingBox = actionbar.getBoundingClientRect();
        if (!this.transformRef || !this.transformRef.current) return;
        const transformBoundingBox = this.transformRef.current.getBoundingClientRect();
        const offsetAdjustment =
          transformBoundingBox.top - actionbarBoundingBox.bottom;
        if (offsetAdjustment < 8) {
          this.setState({
            edgeOffset: offsetAdjustment - 8
          });
        }
      }
    );
  }

  checkIsImageReplaceAvailable() {
    const elements =
      this.props.elements || (this.props.element ? [this.props.element] : []);
    if (!elements || !elements.length) {
      this.setState({
        isImageReplaceAvailable: false
      });
      return;
    }

    const isImageReplaceAvailable = elements.every(element =>
      getIsImageReplaceAvailableForElement(element)
    );
    this.setState({
      isImageReplaceAvailable
    });
  }

  handlePopout(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.state.isOpen) {
      this.props.closeContextMenu();
    } else {
      const refBounds = e.target
        .closest(`div[id^="popout-"]`)
        .getBoundingClientRect();

      this.props.onContextMenu({
        clientX: refBounds.left + 8,
        clientY: refBounds.bottom + 15,
        target: e.target.closest(`div[id^="popout-"]`),
        stopPropagation: noop,
        preventDefault: noop,
        isEllipsisMenu: true,
        elementContextMenuClick: true
      });
    }
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleLockClick(e) {
    e.stopPropagation();
    e.preventDefault();

    this.props.modifyQuery({
      appendTerms: { tab: "restrictions" },
      dropTerms: ["subtab", "assetType", "categoryLabel", "folderId"]
    });
  }

  handleReplaceClick(e) {
    e.stopPropagation();
    e.preventDefault();

    this.props.modifyQuery({
      appendTerms: { tab: "images" },
      dropTerms: ["subtab", "assetType", "categoryLabel", "folderId"]
    });
  }

  getMenuAttributes() {
    const { isLocked, isSemiLocked } = this.props;

    const transform = `rotate(-${this.props.angle}deg)`;

    if (isLocked) {
      return {
        ellipsisSize: "16px",
        LockIcon: PadlockIcon,
        lockIconSize: "16px",
        idClassifier: "",
        wrapperClassName: style.ellipsisLockBox,
        transform
      };
    } else if (isSemiLocked) {
      return {
        ellipsisSize: "16px",
        LockIcon: EditPadlockIcon,
        lockIconSize: "20px",
        idClassifier: "semi-",
        wrapperClassName: style.ellipsisLockBox,
        transform
      };
    } else {
      return {
        ellipsisSize: "20px",
        LockIcon: null,
        lockIconSize: null,
        idClassifier: "un",
        wrapperClassName: style.ellipsisBox,
        transform
      };
    }
  }

  getMenuContent() {
    const { isSemiLocked } = this.props;

    const attributes = this.getMenuAttributes();

    const ellipsisWrapperId = `popout-${attributes.idClassifier}locked`;

    return (
      <div
        className={attributes.wrapperClassName}
        style={{
          transform: attributes.transform
        }}
        data-image-replace={this.state.isImageReplaceAvailable}
        ref={this.transformRef}
      >
        {isSemiLocked && (
          <div
            onClick={this.handleLockClick}
            className={style.padlockEnabled}
            onContextMenu={this.props.onContextMenu}
            ref={this.popoutRef}
          >
            <attributes.LockIcon
              size={attributes.lockIconSize}
              color={"#2C2E2F"}
            />
          </div>
        )}
        {this.state.isImageReplaceAvailable && (
          <div
            className={style.replaceButton}
            onClick={this.handleReplaceClick}
          >
            Replace
          </div>
        )}
        <div
          className={style.ellipsislockEnabled}
          onClick={this.handlePopout}
          onContextMenu={this.props.onContextMenu}
          id={ellipsisWrapperId}
          ref={this.popoutRef}
        >
          <EllipsisIcon size={attributes.ellipsisSize} />
        </div>
      </div>
    );
  }

  getReplaceOffset() {
    let replaceOffset = 0;
    if (this.state.isImageReplaceAvailable) {
      const angle = Number(this.props.angle);
      switch (this.props.highestSide) {
        case "top": {
          // 315 - 0 - 44
          if (angle < 315) {
            replaceOffset = -angle / 2;
          } else {
            replaceOffset = (angle - 360) / 2;
          }
          break;
        }
        case "left": {
          // 45 - 134
          if (angle > 90) {
            replaceOffset = (90 - angle) / 2;
          } else {
            replaceOffset = (angle - 90) / 2;
          }
          break;
        }
        case "bottom": {
          // 135 - 224
          if (angle > 180) {
            replaceOffset = (180 - angle) / 2;
          } else {
            replaceOffset = (angle - 180) / 2;
          }
          break;
        }
        case "right": {
          // 225 - 314
          if (angle > 270) {
            replaceOffset = (270 - angle) / 2;
          } else {
            replaceOffset = (angle - 270) / 2;
          }
          break;
        }
        default: {
          replaceOffset = 0;
        }
      }
    }
    return replaceOffset;
  }

  render() {
    let replaceOffset = this.getReplaceOffset();
    if (this.state.edgeOffset) {
      replaceOffset -= this.state.edgeOffset;
    }
    return (
      <div
        className={this.props.menuStyling()}
        ref={this.wrapperRef}
        data-testid="ElementContextMenu"
        style={{ "--replace-offset": `${replaceOffset}px` }}
      >
        {this.getMenuContent()}
      </div>
    );
  }
}

export default ElementContextMenu;
