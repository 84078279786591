import Element from "state/ui/editor/elements/Element";
import { tableHeightsUpdater } from "views/components/Editor/editorOps/EditorTableOps/tableSimulator";
import { uuid } from "lib/uuid";
import { cloneDeep, getPath } from "lib/lodash";
import { immutableUpdate } from "lib/immutableUpdate";

class TableElement extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "textEdit",
    "sizeAndPosition",
    "color",
    "fontFamily",
    "fontSize",
    "textAlign",
    "textDecoration",
    "letterSpacing",
    "lineHeight",
    "border"
  ];

  get canChangeHeight() {
    return false;
  }

  getTextFieldByAddress(rowIndex, cellIndex, textFieldIndex) {
    return getPath(this.rows, [
      rowIndex,
      "cells",
      cellIndex,
      "textFields",
      textFieldIndex
    ]);
  }

  clone(newElementId = uuid()) {
    const clonedRows = this.rows.map((row, rowIndex) =>
      this.cloneRow({ rowIndex })
    );

    const cloneInstance = new this.constructor({
      ...this,
      uniqueId: newElementId,
      rows: clonedRows
    });

    return [cloneInstance];
  }

  getCellsMetadata({ rowIndex }) {
    const rowType = this.rows[rowIndex].rowTypeCode;

    const cellsMetadata = this.columnsMetadata[rowType].cellsMetadata;

    return cellsMetadata;
  }

  getTextFieldMetadata({ rowIndex, cellIndex, textFieldIndex }) {
    const cellsMetadata = this.getCellsMetadata({ rowIndex });
    const textFieldMetadata =
      cellsMetadata[cellIndex].textFields[textFieldIndex];

    return textFieldMetadata;
  }

  duplicateLastRow() {
    const lastRowIndex = this.rows.length - 1;

    const lastRowClone = this.cloneRow({ rowIndex: lastRowIndex });

    const tableHeightsUpdated = tableHeightsUpdater({
      ...this,
      rows: this.rows.concat(lastRowClone)
    });

    return new this.constructor({
      ...tableHeightsUpdated
    });
  }

  updateTextFieldValue({ rowIndex, cellIndex, textFieldIndex, value }) {
    const rowsUpdated = immutableUpdate(this.rows, {
      [rowIndex]: {
        cells: {
          [cellIndex]: {
            textFields: {
              [textFieldIndex]: {
                $merge: {
                  value: value
                }
              }
            }
          }
        }
      }
    });

    return new this.constructor({
      ...this,
      rows: rowsUpdated
    });
  }

  cloneRow({ rowIndex, suggestedId = uuid() }) {
    const rowClone = cloneDeep(this.rows[rowIndex]);

    rowClone.id = suggestedId;

    rowClone.cells = rowClone.cells.map(cell => {
      return {
        ...cell,
        id: uuid(),
        textFields: cell.textFields.map(textField => ({
          ...textField,
          id: uuid()
        }))
      };
    });

    return rowClone;
  }

  // set up a basic updateAttributes for tables since updating a fontSize should not modify the tables width
  updateAttributes(attributes) {
    const _attributes = this.ensureMinimalValues(attributes);
    return new this.constructor({
      ...this,
      ..._attributes
    });
  }
}

export default TableElement;
