import * as types from "./printOptionsTypes";

export const initState = {
  // [templateCode]: {
  //   url: "www.printOptions.com"
  // },
  currentTemplateCode: null,
  isFetching: false
};

const printOptionsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_PRINT_OPTIONS_BY_TEMPLATE_CODE_REQUEST: {
      return {
        ...state,
        currentTemplateCode: action.request.body.templateCode,
        isFetching: true
      };
    }

    case types.FETCH_PRINT_OPTIONS_BY_TEMPLATE_CODE_REQUEST_SUCCESS: {
      const { templateCode } = action.request.body;

      return {
        ...state,
        isFetching: false,
        [templateCode]: action.response
      };
    }

    default:
      return state;
  }
};

export default printOptionsReducer;
