import React from "react";
import { Loading, IconButton } from "views/components";
import FileWithTextIcon from "views/components/icons/FileWithTextIcon";
import ArrowIntoBoxIcon from "views/components/icons/ArrowIntoBoxIcon";
import InvoiceYears from "./InvoiceYears";
import InvoiceMonths from "./InvoiceMonths";
import { isEmpty } from "lib";

import style from "./style.module.css";

export class Invoices extends React.Component {
  constructor(props) {
    super(props);

    this.setSelectedYear = this.setSelectedYear.bind(this);
    this.setSelectedMonth = this.setSelectedMonth.bind(this);
    this.handleDownloadAllInvoices = this.handleDownloadAllInvoices.bind(this);

    this.state = {
      selectedYear: null,
      selectedMonth: null,
      isDownloadingAll: false
    };
  }

  // sets the selected year
  setSelectedYear(year) {
    if (this.state.selectedYear === year) return;
    this.setState({
      selectedYear: year,
      selectedMonth: null // clear month when changing years
    });
  }

  // sets the selected month, if passed the same value as the current selection it clears the value
  setSelectedMonth(month) {
    let selectedMonth = month;
    if (this.state.selectedMonth === month) selectedMonth = null;
    this.setState({
      selectedMonth
    });
  }

  componentDidMount() {
    this.props.fetchInvoicesIfNeeded();
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(prevProps.invoices) && !isEmpty(this.props.invoices)) {
      this.setSelectedYear(Object.keys(this.props.invoices).reverse()[0]);
    }
  }

  getInvoicesList({ isInvoicesEmpty }) {
    const { invoices, isLoading } = this.props;

    const { selectedYear, selectedMonth } = this.state;

    if (isLoading) {
      return (
        <div className={style.loadingWrapper}>
          <Loading />
        </div>
      );
    }
    if (isInvoicesEmpty) {
      return (
        <div className={style.emptySectionWrapper}>
          <FileWithTextIcon color="#2c2e2f" backGroundColor="#FCE068" />
          <div className={style.emptySectionText}>
            You don't have any invoices.
          </div>
        </div>
      );
    }

    return (
      <div className={style.invoicesListWrapper}>
        <InvoiceYears
          invoices={invoices}
          selectedYear={selectedYear}
          setSelectedYear={this.setSelectedYear}
        />
        <InvoiceMonths
          invoiceMonths={invoices[selectedYear]}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          setSelectedMonth={this.setSelectedMonth}
        />
      </div>
    );
  }

  async handleDownloadAllInvoices() {
    this.setState({
      isDownloadingAll: true
    });

    await this.props.downloadAllInvoiceFiles();

    this.setState({
      isDownloadingAll: false
    });
  }

  render() {
    const { invoices } = this.props;

    const isInvoicesEmpty = isEmpty(invoices);

    return (
      <div className={style.columnWrapper}>
        <div className={style.column}>
          <div className={style.sectionTitle}>
            Invoices
            {/* TODO: DOWNLOAD ALL BUTTON NOT YET IMPLEMENTED, WHEN BACKEND IS COMPLETE ADD ONCLICK */}
            {!isInvoicesEmpty && (
              <>
                {this.state.isDownloadingAll ? (
                  <div className={style.downloadAllLoading}>
                    <Loading size="24px" />
                  </div>
                ) : (
                  <IconButton
                    onClick={this.handleDownloadAllInvoices}
                    Icon={ArrowIntoBoxIcon}
                    title={"Download all"}
                    titleClassName={style.titleButton}
                    className={style.downloadButton}
                    iconColor="#57595d"
                    iconSize="24"
                  />
                )}
              </>
            )}
          </div>
          {this.getInvoicesList({ isInvoicesEmpty })}
        </div>
      </div>
    );
  }
}

Invoices.displayName = "Invoices";

export default Invoices;
