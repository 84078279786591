export const ALL_STOCK_ANIMATIONS_REQUEST =
  "API/ENTITIES/ALL_STOCK_ANIMATIONS_REQUEST";
export const ALL_STOCK_ANIMATIONS_REQUEST_FAILURE =
  "API/ENTITIES/ALL_STOCK_ANIMATIONS_REQUEST_FAILURE";
export const ALL_STOCK_ANIMATIONS_REQUEST_SUCCESS =
  "API/ENTITIES/ALL_STOCK_ANIMATIONS_REQUEST_SUCCESS";

export const STOCK_ANIMATIONS_REQUEST = "API/ENTITIES/STOCK_ANIMATIONS_REQUEST";
export const STOCK_ANIMATIONS_REQUEST_FAILURE =
  "API/ENTITIES/STOCK_ANIMATIONS_REQUEST_FAILURE";
export const STOCK_ANIMATIONS_REQUEST_SUCCESS =
  "API/ENTITIES/STOCK_ANIMATIONS_REQUEST_SUCCESS";

export const STOCK_VIDEOS_REQUEST = "API/ENTITIES/STOCK_VIDEOS_REQUEST";
export const STOCK_VIDEOS_REQUEST_FAILURE =
  "API/ENTITIES/STOCK_VIDEOS_REQUEST_FAILURE";
export const STOCK_VIDEOS_REQUEST_SUCCESS =
  "API/ENTITIES/STOCK_VIDEOS_REQUEST_SUCCESS";

export const STOCK_ANIMATIONS_IN_FOLDER_REQUEST =
  "API/ENTITIES/STOCK_ANIMATIONS_IN_FOLDER_REQUEST";
export const STOCK_ANIMATIONS_IN_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/STOCK_ANIMATIONS_IN_FOLDER_REQUEST_FAILURE";
export const STOCK_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/STOCK_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS";

export const STOCK_ANIMATIONS_SEARCH_REQUEST =
  "API/ENTITIES/STOCK_ANIMATIONS_SEARCH_REQUEST";
export const STOCK_ANIMATIONS_SEARCH_REQUEST_FAILURE =
  "API/ENTITIES/STOCK_ANIMATIONS_SEARCH_REQUEST_FAILURE";
export const STOCK_ANIMATIONS_SEARCH_REQUEST_SUCCESS =
  "API/ENTITIES/STOCK_ANIMATIONS_SEARCH_REQUEST_SUCCESS";

export const STOCK_ANIMATION_DELETE_REQUEST =
  "API/ENTITIES/STOCK_ANIMATION_DELETE_REQUEST";
export const STOCK_ANIMATION_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/STOCK_ANIMATION_DELETE_REQUEST_FAILURE";
export const STOCK_ANIMATION_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/STOCK_ANIMATION_DELETE_REQUEST_SUCCESS";

export const STOCK_ANIMATION_UPDATE_REQUEST =
  "API/ENTITIES/STOCK_ANIMATION_UPDATE_REQUEST";
export const STOCK_ANIMATION_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/STOCK_ANIMATION_UPDATE_REQUEST_FAILURE";
export const STOCK_ANIMATION_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/STOCK_ANIMATION_UPDATE_REQUEST_SUCCESS";

export const STOCK_ANIMATION_UPLOAD_REQUEST =
  "API/ENTITIES/STOCK_ANIMATION_UPLOAD_REQUEST";
export const STOCK_ANIMATION_UPLOAD_REQUEST_FAILURE =
  "API/ENTITIES/STOCK_ANIMATION_UPLOAD_REQUEST_FAILURE";
export const STOCK_ANIMATION_UPLOAD_REQUEST_SUCCESS =
  "API/ENTITIES/STOCK_ANIMATION_UPLOAD_REQUEST_SUCCESS";

export const STOCK_ANIMATION_UPLOAD_URL_REQUEST =
  "API/ENTITIES/STOCK_ANIMATION_UPLOAD_URL_REQUEST";
export const STOCK_ANIMATION_UPLOAD_URL_REQUEST_FAILURE =
  "API/ENTITIES/STOCK_ANIMATION_UPLOAD_URL_REQUEST_FAILURE";
export const STOCK_ANIMATION_UPLOAD_URL_REQUEST_SUCCESS =
  "API/ENTITIES/STOCK_ANIMATION_UPLOAD_URL_REQUEST_SUCCESS";

export const ADD_STOCK_ANIMATIONS_PLACEHOLDERS =
  "API/ENTITIES/ADD_STOCK_ANIMATIONS_PLACEHOLDERS";
