import * as types from "state/entities/teamSmartImages/teamSmartImagesTypes";
import { formatErrors } from "lib";
export const initState = {
  isSaving: false,
  errors: []
};

const errorsArray = error => formatErrors(error);

const teamSmartImagesApiReducers = (state = initState, action) => {
  switch (action.type) {
    // Update request
    case types.TEAM_SMART_IMAGE_UPDATE_REQUEST:
    case types.TEAM_SMART_IMAGE_UPLOAD_REQUEST: {
      return {
        ...state,
        isSaving: true
      };
    }
    case types.TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS:
    case types.TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS: {
      return {
        ...state,
        isSaving: false
      };
    }

    case types.TEAM_SMART_IMAGE_UPLOAD_REQUEST_FAILURE: {
      const { error } = action;
      const errors = errorsArray(error);

      return {
        ...state,
        isSaving: false,
        errors
      };
    }

    default:
      return state;
  }
};
export default teamSmartImagesApiReducers;
