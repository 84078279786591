import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const AZUpIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 20}
      height={height || size || 20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path d="M0 0h20v20H0z" />
        <path
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m19 4-3-3-3 3M1 9l3.417-8L8 9"
        />
        <path fill={color || easilGrayXDark} d="M2 6h5v2H2z" />
        <path
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 19H1l6.922-7H1M16 1v18"
        />
      </g>
    </svg>
  );
};
AZUpIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default AZUpIcon;
