import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PaletteIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <g fill={color || easilGrayXDark} transform="rotate(-10 13.215 1.785)">
        <path
          fillRule="nonzero"
          d="M13.1 7a6.1 6.1 0 1 0-6.967 6.039 4.4 4.4 0 0 1 6.706-4.267A6.102 6.102 0 0 0 13.1 7zm-.599 3.84a2.6 2.6 0 0 0-4.354 2.768.9.9 0 0 1-.772 1.283 7.9 7.9 0 1 1 6.614-4.206.9.9 0 0 1-1.488.155z"
        />
        <circle cx="4.003" cy="6.419" r="1.25" />
        <circle cx="6.494" cy="3.812" r="1.25" />
        <circle cx="9.767" cy="5.404" r="1.25" />
      </g>
    </g>
  </svg>
);
PaletteIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PaletteIcon;
