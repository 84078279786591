import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PadlockOpenIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 15 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <g transform="translate(1 1)">
        <path d="M1 3h12v12H1z" />
        <path
          d="M3 6.9A1.1 1.1 0 0 0 1.9 8v4A1.1 1.1 0 0 0 3 13.1h8a1.1 1.1 0 0 0 1.1-1.1V8A1.1 1.1 0 0 0 11 6.9H3zm0-1.8h8A2.9 2.9 0 0 1 13.9 8v4a2.9 2.9 0 0 1-2.9 2.9H3A2.9 2.9 0 0 1 .1 12V8A2.9 2.9 0 0 1 3 5.1z"
          fill={color || easilGrayXDark}
        />
        <path
          d="M0 3.5V3a3 3 0 1 1 6 0v2.5h0"
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle fill={color || easilGrayXDark} cx="7" cy="10" r="1" />
      </g>
    </g>
  </svg>
);
PadlockOpenIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PadlockOpenIcon;
