import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const UnderLineIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-2h20v20H-3z" />
      <g fill={color || easilGrayXDark}>
        <circle cx="11" cy="1" r="1" />
        <circle cx="3" cy="1" r="1" />
        <rect width="14" height="2" y="14" rx="1" />
        <path
          fillRule="nonzero"
          d="M4 1v5a3 3 0 1 0 6 0V1h2v5A5 5 0 1 1 2 6V1h2z"
        />
      </g>
    </g>
  </svg>
);
UnderLineIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default UnderLineIcon;
