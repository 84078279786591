import React from "react";
import style from "./style.module.css";
import { CircleButton, Button } from "views/components";
import PlusIcon from "views/components/icons/PlusIcon";
import { Logger, chainPromises } from "lib";
import { clickClosestAddFileInput } from "lib/input/mouse";

class AddImageButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputId: props.inputId || `file${Date.now() + Math.random()}`,
      file: null,
      imageError: null,
      imagesUploading: false,
      showOverlay: false
    };
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.processImage = this.processImage.bind(this);
    this.stopBrowserDefaultBehaviour = this.stopBrowserDefaultBehaviour.bind(
      this
    );
    this.defaultOnClick = this.defaultOnClick.bind(this);
  }

  async handleFileSelect({ event, origin }) {
    this.stopBrowserDefaultBehaviour(event);

    if (this.props.disabled) return;

    let files;

    switch (origin) {
      case "manualInput":
        files = event.target.files;
        break;
      default:
        Logger.warn({ error: "Invalid Event Origin" });
        this.setState({ imageError: "Something went wrong" });
        return;
    }

    this.setState({
      file: files[0],
      imageError: null,
      imagesUploading: true
    });

    /* define function for creating promise containers for all uploads */
    const createImageProcessPromise = file => {
      return () =>
        new Promise(resolve => {
          this.processImage(file).then(
            result => {
              this.setState({ fileSrc: result });
              this.props.onChange({ file, onSuccess: resolve });
            },
            ({ error }) => {
              this.setState({ imageError: error });
            }
          );
        });
    };

    /* set up the promise containers for the upload */
    const uploadPromises = Object.values(files).map(file =>
      createImageProcessPromise(file)
    );

    /* run the promises in chain */
    await chainPromises(uploadPromises);

    /* when all promises complete update state */
    this.setState({ imagesUploading: false });

    // overwrite event value to trigger onChange event in case of same image being selected again
    event.target.value = "";
  }

  processImage(file) {
    const imageFilesTypesAllowed = this.props.acceptedFileTypes;
    const fileExtension = file.name
      .split(".")
      .pop()
      .toLowerCase();

    if (!imageFilesTypesAllowed.includes(fileExtension)) {
      return Promise.reject({
        error: "Images must be in PNG, JPG or SVG format."
      });
    }

    const promise = new Promise((resolve, reject) => {
      const errorCallback = msg => () => {
        Logger.warn(msg);
        return reject({ error: "We were not able to process your image." });
      };

      const validateFileAsImage = fileSrc => {
        const image = new Image();
        image.onerror = errorCallback("Image onload error");
        image.onload = () => {
          Logger.info({ app: "Image processed successfully" });
          /* validate image size here*/
          resolve(fileSrc);
        };
        image.src = fileSrc;
      };

      const reader = new FileReader();
      reader.onerror = errorCallback("File Reader onload error");

      reader.onload = ({ target }) => validateFileAsImage(target.result);
      reader.readAsDataURL(file);
    });

    return promise;
  }

  stopBrowserDefaultBehaviour(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  defaultOnClick(event) {
    event.stopPropagation();
    event.preventDefault();
    clickClosestAddFileInput(event.target);
  }

  render() {
    const {
      acceptedFileTypes,
      isMulti = false,
      onClick = this.defaultOnClick
    } = this.props;
    return (
      <div
        className={this.props.className}
        onMouseOver={() => this.setState({ showOverlay: true })}
        onMouseLeave={() => this.setState({ showOverlay: false })}
        id="add-image-button"
      >
        <input
          disabled={this.props.disabled}
          type="file"
          id={this.state.inputId}
          accept={acceptedFileTypes
            .map(extension => `.${extension}`)
            .join(", ")}
          className={style.inputfile}
          onChange={e => {
            this.handleFileSelect({ event: e, origin: "manualInput" });
          }}
          multiple={isMulti}
        />
        <label onClick={onClick}>
          <div className={style.imageWrapper}>
            {this.props.image}
            {!this.props.image && (
              <CircleButton {...this.props.buttonStyles}>
                <PlusIcon width="16" height="16" />
              </CircleButton>
            )}
            {this.props.image &&
              this.state.showOverlay &&
              this.props.isUserAvatar && (
                <CircleButton
                  {...this.props.buttonStyles}
                  className={style.profilePictureHover}
                >
                  <Button className={style.updateProfileButton} theme="white">
                    {" "}
                    Update
                  </Button>
                </CircleButton>
              )}
          </div>
        </label>
        {this.state.imageError && (
          <div className={style.error}>{this.state.imageError}</div>
        )}
      </div>
    );
  }
}
export default AddImageButton;
