import React, { Component } from "react";
import { connect } from "react-redux";
import CartPreviewSection from "./CartPreviewSection";
import { getCurrentOrderByIdWithFullDesignSelector } from "state/entities/orders/ordersSelectors";
import {
  fetchCurrentOrder,
  deleteDesignFromOrder,
  updatePrintItemInOrderDesign,
  addNewPrintItemInOrderDesign,
  deletePrintItemInOrderDesign
} from "state/entities/orders/ordersActions";
import { fetchDesignDataAndRedirect } from "state/entities/designs/designsActions";
import {
  printPricingGroupedByPrintOptionIdSelector,
  printPricingGroupedByDesignIdSelector
} from "state/entities/printPricing/printPricingSelectors";
import { currentOrderLoadingStateSelector } from "state/ui/cart/cartSelectors";
import { catalogueSizesByType } from "state/entities/catalogueSizes/catalogueSizesSelectors";
import { workspaceSizesByType } from "state/entities/workspaceSizes/workspaceSizesSelector";
import {
  updateOrderDesigns,
  getUpdatedDesignDataIdsForOrderDesigns
} from "state/pageActions/cartPageActions";
import { fetchDesign } from "state/entities/designs/designsActions";
class CartPreviewSectionContainer extends Component {
  render() {
    return <CartPreviewSection {...this.props} />;
  }
}

const mapStateToProps = state => {
  const order = getCurrentOrderByIdWithFullDesignSelector({ state });
  const printPricingGroupedByPrintOptionId = printPricingGroupedByPrintOptionIdSelector(
    state
  );
  const printPricingGroupedByDesignId = printPricingGroupedByDesignIdSelector(
    state
  );
  const loading = currentOrderLoadingStateSelector(state);
  const catalogueImageTemplateTypes =
    catalogueSizesByType({ state, type: "image" }) || [];
  const workspaceImageTemplateTypes =
    workspaceSizesByType({ state, type: "image" }) || [];
  const imageTemplateTypes = [
    ...catalogueImageTemplateTypes,
    ...workspaceImageTemplateTypes
  ];
  return {
    order,
    printPricingGroupedByPrintOptionId,
    printPricingGroupedByDesignId,
    loading,
    imageTemplateTypes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDesignPreview: args => dispatch(fetchDesignDataAndRedirect(args)),
    onDeleteDesignFromOrder: args =>
      dispatch(
        deleteDesignFromOrder({
          ...args,
          onSuccess: () => dispatch(fetchCurrentOrder())
        })
      ),
    handleUpdatePrintItem: args =>
      dispatch(
        updatePrintItemInOrderDesign({
          ...args,
          onSuccess: () => dispatch(fetchCurrentOrder())
        })
      ),
    handleCreatePrintItem: args =>
      dispatch(
        addNewPrintItemInOrderDesign({
          ...args,
          onSuccess: () => dispatch(fetchCurrentOrder())
        })
      ),
    handleDeletePrintItem: args =>
      dispatch(
        deletePrintItemInOrderDesign({
          ...args,
          onSuccess: () => dispatch(fetchCurrentOrder())
        })
      ),
    updateOrderDesigns: args => dispatch(updateOrderDesigns(args)),
    fetchDesign: args => dispatch(fetchDesign(args)),
    getUpdatedDesignDataIds: args =>
      dispatch(getUpdatedDesignDataIdsForOrderDesigns(args)),
    fetchCurrentOrder: args => dispatch(fetchCurrentOrder(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartPreviewSectionContainer);
