import * as types from "./toasterTypes";
import { toaster, noop, uuid } from "lib";

/**
 * @desc append a new toast to the toasts in state
 * @param {string} toastId - a unique identifier for the toast, used to reference the toast after creation
 * @param {component} textComponent - a jsx component to be rendered as the toasts text section
 * @param {string} buttonLabel - the label to be placed on the toasts button
 * @param {string} type - the type of toast, @example "button" will build a toast with a button
 * @param {string} icon - the type of icon to be used, @example "success" displays a tick icon
 * @param {function} onOpen - a function to run when the toast has completed opening
 * @param {function} onClose - a function to run when the toast is closed using its internal close, does not get triggered with removeToast or removeAllToast
 * @param {number} duration - the timer for the toast to auto-close, if not provided toast will not auto-close
 * @param {function} action - the action that will be triggered when clicking on the toasts button
 */
export const makeToast = ({
  textComponent,
  buttonLabel,
  type,
  icon,
  action,
  duration,
  onClose = noop,
  onOpen = noop,
  toastId = uuid(),
  className
} = {}) => dispatch => {
  toaster.makeToast({
    textComponent,
    buttonLabel,
    type,
    icon,
    action,
    onOpen: () => onOpen({ toastId }),
    onClose: () => {
      dispatch(removeToast({ toastId }));
      onClose({ toastId });
    },
    toastId,
    duration,
    className
  });
  dispatch({
    type: types.MAKE_TOAST,
    toastId
  });
};

/**
 * @desc remove a toast from the toastIds in state
 */
export const removeToast = ({ toastId } = {}) => dispatch => {
  toaster.removeToast({ toastId });
  dispatch({
    type: types.REMOVE_TOAST,
    toastId
  });
};

/**
 * @desc dismisses all toasts and clears the toastIds in state
 */
export const removeAllToast = () => dispatch => {
  toaster.removeAllToast();
  dispatch({
    type: types.REMOVE_ALL_TOAST
  });
};

/**
 * @desc updates a toast
 * @param {string} toastId - the id for the toast to update
 * @param {object} args - the arguments to update the toast with
 */
export const updateToast = (toastId, args) => {
  toaster.updateToast(toastId, args);
};
