const HEX_PATTERN = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"; // hex with 3 or 6 char
const HEX_TEST = RegExp(HEX_PATTERN);
const HEX_PATTERN_WITH_ALPHA = "^#([A-Fa-f0-9]{8})$";
const HEX_WITH_ALPHA_TEST = RegExp(HEX_PATTERN_WITH_ALPHA);
const RGB_PATTERN =
  "^rgb\\((25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9])\\)$";
const RGB_TEST = RegExp(RGB_PATTERN);
const RGBA_PATTERN =
  "^rgba\\((25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(25[0-5]|2[0-4][0-9]|1[0-9]?[0-9]?|[1-9][0-9]?|[0-9]), ?(1|0|0.[0-9]+)\\)$";
const RGBA_TEST = RegExp(RGBA_PATTERN);

/**
 * @desc - takes in a rgba alpha value and returns a hex equivalent
 * @param {number} percentage - the alpha between 0-1 to be converted into a hex alpha
 * @returns {string} - returns the hex string equivalent of the provided 0-1 alpha value
 */
const rgbaAlphaToHexAlpha = percentage => {
  const percent = Math.max(0, Math.min(100, percentage * 100)); // convert from 0-1 to 0-100 and bind percent from 0 to 100
  const intValue = Math.round((percent / 100) * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation
  return hexValue.padStart(2, "0").toUpperCase(); // format with leading 0 and upper case characters
};

/**
 * @desc takes a string representation of a colour and applies the given alpha channel to it
 * @param {string} string - the color string to apply an alpha value to
 * @param {number} alpha - the alpha value in scale 0-1
 * @returns {string} - when supplied with an rgb/rgba value returns an rgba string with the given alpha applied,
 * given a hex string returns an 8 character hex colour with the alpha given applied
 */
export const addAlphaToColorString = (string, alpha) => {
  let colorStringWithTransparency = string;
  if (HEX_TEST.test(string) || HEX_WITH_ALPHA_TEST.test(string)) {
    const hexAlpha = rgbaAlphaToHexAlpha(alpha);
    // slice to get the first 7 chars so we don't append to a hex with transparency
    colorStringWithTransparency = `${string.slice(0, 7)}${hexAlpha}`;
  }
  if (RGB_TEST.test(string)) {
    // slice the bracket from the end and add an opacity
    // slice rgb from the front and replace with rgba
    colorStringWithTransparency = `rgba${string.slice(
      3,
      string.length - 1
    )},${alpha})`;
  } else if (RGBA_TEST.test(string)) {
    const splitColor = string.split(",");
    // remove the last piece
    splitColor.pop();
    // add 0 alpha channel
    splitColor.push(`${alpha})`);

    // join the color back together and set it as the transparent color
    colorStringWithTransparency = splitColor.join(",");
  }
  return colorStringWithTransparency;
};

// takes a colour string and appends an alpha value to it if none is present (used to set up alpha interpolation)
export const addAlphaIfNeeded = (string, alpha = 1) => {
  const hasAlpha = HEX_WITH_ALPHA_TEST.test(string) || RGBA_TEST.test(string);
  if (hasAlpha) return string;
  return addAlphaToColorString(string, alpha);
};

export const isHexString = colorString =>
  HEX_TEST.test(colorString) || HEX_WITH_ALPHA_TEST.test(colorString);
