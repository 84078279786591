import * as types from "./loginPageTypes";

export const initState = {
  isProcessing: false,
  isSuccess: false,
  errors: {}
};

const LoginPageReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USER_LOGIN_REQUEST: {
      return {
        ...state,
        errors: {},
        isProcessing: true
      };
    }

    case types.USER_LOGIN_REQUEST_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true
      };
    }

    case types.USER_LOGIN_REQUEST_FAILURE: {
      const { error } = action;

      return {
        ...state,
        isProcessing: false,
        errors: {
          email: error.error_description,
          password: error.error_description
        }
      };
    }

    default:
      return state;
  }
};

export default LoginPageReducers;
