export const draftDesignsApiPageSelector = (state, page, size = "all") => {
  return state.api.designs.personal.drafts.sizes[size].pages[page];
};

export const draftDesignsApiSelector = (state, size = "all") => {
  return state.api.designs.personal.drafts.sizes[size].pages;
};

export const draftDesignsApiLocalSelector = (state, size = "all") => {
  return state.sizes[size].pages;
};
