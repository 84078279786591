import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import PlusIcon from "views/components/icons/PlusIcon";

const RoundCancelButton = ({ className, disabled = false, onClick }) => {
  return (
    <div
      className={`${style.wrapper} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <PlusIcon width="10" height="10" rotate="45" />
    </div>
  );
};

RoundCancelButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
  onClick: PropTypes.func
};

export default RoundCancelButton;
