import { createSelector } from "reselect";
import { isNil, getPath } from "lib";

export const stockAnimationsEntitiesSelector = state =>
  state.entities.stockAnimations;
const stockAnimationsApiSelector = state => state.api.stockAnimations;

export const processGetStockAnimationsNotInFolders = (
  stockAnimationsApi,
  stockAnimationsEntities
) => {
  let stockAnimations = [];
  if (stockAnimationsApi.pages) {
    const pagesOrder = Object.keys(stockAnimationsApi.pages).sort();

    pagesOrder.forEach(page => {
      const ids = stockAnimationsApi.pages[page].ids || [];

      ids.forEach(stockAnimationId => {
        const entity = stockAnimationsEntities[stockAnimationId];

        stockAnimations.push(entity);
      });
    });
  }
  return stockAnimations;
};

export const getStockAnimationsNotInFolders = createSelector(
  [stockAnimationsApiSelector, stockAnimationsEntitiesSelector],
  processGetStockAnimationsNotInFolders
);

const processStockAnimationsSelector = (
  stockAnimationsApi,
  stockAnimationsEntities
) => {
  if (isNil(stockAnimationsApi.all.pages)) {
    return null;
  }

  let hasBeenFetched = false;
  let stockAnimationsIds = [];

  const pagesOrder = Object.keys(stockAnimationsApi.all.pages).sort();

  pagesOrder.forEach(page => {
    const ids = getPath(stockAnimationsApi.all, `pages[${page}].ids`);

    if (!isNil(ids)) {
      hasBeenFetched = true;
      stockAnimationsIds = stockAnimationsIds.concat(ids);
    }
  });

  if (hasBeenFetched) {
    return stockAnimationsIds.map(
      animationId => stockAnimationsEntities[animationId]
    );
  } else {
    return null;
  }
};

export const stockAnimationsSelector = createSelector(
  [stockAnimationsApiSelector, stockAnimationsEntitiesSelector],
  processStockAnimationsSelector
);

export const stockAnimationSelector = ({ state, animationId }) =>
  state.entities.stockAnimations[animationId];
