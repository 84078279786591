import React from "react";
import { easilGrayXDark } from "./style.module.css";

const RowsIcon = ({
  noPointer,
  className,
  color,
  width,
  height,
  size,
  title,
  style
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="2 2 16 16" // Adjust the viewBox to fit the actual content
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fill-rule="evenodd">
        <path d="M20 0v20H0V0z" />
        <path
          d="M17 5v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2zM17 13v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1z"
          stroke={color || easilGrayXDark}
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default RowsIcon;
