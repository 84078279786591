import React, { Component } from "react";
import CaretIcon from "views/components/icons/CaretIcon";
import CrossIcon from "views/components/icons/CrossIcon";
import style from "./style.module.css";
import Tag from "../tag";
import { isNil } from "lib";
import { ScrollFold, SearchInput, Button } from "views/components";

class DropdownTags extends Component {
  constructor(props) {
    super(props);

    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemoveSelected = this.handleRemoveSelected.bind(this);
    this.handleClosePopout = this.handleClosePopout.bind(this);

    this.state = {
      optionsOpened: false,
      options: this.props.options || [],
      selected: this.props.selected || [],
      isSearchFocussed: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      options: nextProps.options,
      selected: nextProps.selected
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.optionsOpened !== prevState.optionsOpened) {
      const { handleToggle } = this.props;
      handleToggle();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onDropdownClick() {
    if (this.props.disabled) return;
    this.setState({
      optionsOpened: !this.state.optionsOpened
    });
  }

  handleChange(optionKey) {
    const { shouldCloseOnChange = true } = this.props;
    if (shouldCloseOnChange) {
      this.setState({
        optionsOpened: !this.state.optionsOpened
      });
    }
    this.props.onChange(optionKey);
  }

  handleRemoveSelected(id, event) {
    this.props.removeTag(id, event);
  }

  handleClosePopout() {
    this.setState({
      optionsOpened: false
    });
  }

  render() {
    const {
      optionsRenderer,
      className,
      selectedTagClassName,
      placeholder,
      error,
      showErrorMsg,
      label,
      selected,
      disabled,
      options,
      dropdownHeight = "auto",
      caretColor = style.easilGrayXDark,
      caretSize = "8px",
      isAvatarOption,
      isSearchable,
      searchClassName,
      searchInputClassName,
      searchButton,
      searchWrapperClassName,
      isFooterSubmitShown,
      dropdownMaxHeight = "initial",
      dropdownOptionsClassName
    } = this.props;
    const { optionsOpened } = this.state;
    // TODO: Make a default KeyValueOptionsRenderer to use if no custom options renderer is provided
    const OptionsRenderer = optionsRenderer;

    const dropdownStyle = {
      height: dropdownHeight,
      maxHeight: dropdownMaxHeight
    };

    // TODO: Increase dropdown open/close click area
    return (
      <React.Fragment>
        {label && <div className={style.label}>{label}</div>}
        <div
          ref={this.setWrapperRef}
          className={`${style.dropdown} ${className}`}
          // onClick={this.onDropdownClick}
        >
          <div
            aria-label="dropdown"
            className={style.inputDiv}
            data-error={!isNil(error)}
            data-disabled={disabled}
            onClick={this.onDropdownClick}
          >
            {selected.length === 0 && (
              <div className={style.placeholder} onClick={this.onDropdownClick}>
                {placeholder}
              </div>
            )}
            {selected && selected.length > 0 && (
              <div className={style.tagList}>
                {selected.map(selected => (
                  <Tag
                    key={`selected-tag-${selected.id}`}
                    id={selected.id}
                    label={selected.label}
                    canAddRemoveTags={true}
                    onRemove={(id, event) =>
                      this.handleRemoveSelected(id, event)
                    }
                    className={selectedTagClassName}
                    iconSize="16px"
                    removeIconClassName={style.tagRemoveIcon}
                    noRemoveCircle={true}
                    removeIcon={CrossIcon}
                    removeIconColor="#afb3b6"
                    src={selected.src}
                  />
                ))}
                <ScrollFold color="#ffffff" size="25" />
              </div>
            )}
            <CaretIcon
              size={caretSize}
              color={caretColor}
              direction="down"
              onClick={this.onDropdownClick}
            />
          </div>
          {showErrorMsg && error && (
            <span aria-label="dropdownError" className={style.error}>
              {error}
            </span>
          )}
          {options && (
            <div
              className={`${style.options} ${
                optionsOpened ? style.optionsOpened : null
              } ${dropdownOptionsClassName}`}
              style={dropdownStyle}
              aria-label="dropdownContent"
            >
              {isSearchable && (
                <div className={searchWrapperClassName}>
                  <SearchInput
                    className={searchClassName}
                    inputClassName={searchInputClassName}
                    onChange={this.props.onSearchInputChange}
                    clearInput={() => this.props.onSearchInputChange("")}
                    onKeyDown={this.props.onSearchInputKeyDown}
                    value={this.props.searchTerm}
                    placeholder={this.props.searchPlaceholder}
                    onFocus={() => this.setState({ isSearchFocussed: true })}
                    onBlur={() => this.setState({ isSearchFocussed: false })}
                    isFocussed={this.state.isSearchFocussed}
                  />
                  {!!searchButton && (
                    <Button
                      theme={searchButton.theme || "gray"}
                      onClick={searchButton.action}
                      className={searchButton.className}
                    >
                      {searchButton.label}
                    </Button>
                  )}
                </div>
              )}
              <div className={style.optionList}>
                <OptionsRenderer
                  multiSelect={true}
                  options={options}
                  selected={selected}
                  onChange={this.handleChange}
                  isAvatarOption={isAvatarOption}
                />
                {options.length <= 0 && (
                  <div
                    style={{
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    No options could be found
                  </div>
                )}
              </div>
              {isFooterSubmitShown && (
                <div className={style.footer}>
                  <Button theme="blueSolid" onClick={this.handleClosePopout}>
                    Done
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DropdownTags;
