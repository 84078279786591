import { isEmpty } from "lib/lodash";

export const smartTextSelector = state => {
  return {
    ...state.entities.smartText.team,
    ...state.entities.smartText.user
  };
};

export const designSmartTextSelector = state =>
  state.entities.smartText.design?.smartText;

export const teamSmartTextSearchSelector = state => {
  const teamSmartTextEntities = state.entities.smartText.team;
  const smartText = [];

  Object.keys(teamSmartTextEntities).forEach(text => {
    smartText.push(teamSmartTextEntities[text]);
  });

  return smartText;
};

export const allTeamSmartTextSelector = state => state.entities.smartText.team;

export const teamSmartTextSelector = state => {
  const teamSmartTextEntities = allTeamSmartTextSelector(state);
  const teamSmartText = [];

  Object.keys(teamSmartTextEntities).forEach(smartText => {
    if (
      !teamSmartTextEntities[smartText].hasOwnProperty("overridden") ||
      (teamSmartTextEntities[smartText].hasOwnProperty("overridden") &&
        teamSmartTextEntities[smartText].overridden)
    ) {
      teamSmartText.unshift(teamSmartTextEntities[smartText]);
    }
  });
  return teamSmartText;
};

export const teamSmartTextOverideSelector = state => {
  const teamSmartTextEntities = state.entities.smartText.team;
  const overrideSmartText = [];

  Object.keys(teamSmartTextEntities).forEach(smartText => {
    if (
      teamSmartTextEntities[smartText].hasOwnProperty("overridden") &&
      !teamSmartTextEntities[smartText].overridden
    ) {
      overrideSmartText.push(teamSmartTextEntities[smartText]);
    }
  });

  return overrideSmartText;
};

export const allUserTeamSmartTextSelector = state =>
  state.entities.smartText.user;

export const userTeamSmartTextSelector = state => {
  const smartTextEntities = allUserTeamSmartTextSelector(state);
  const userSmartText = [];

  Object.keys(smartTextEntities).forEach(smartText => {
    if (
      !smartTextEntities[smartText].hasOwnProperty("overridden") ||
      (smartTextEntities[smartText].hasOwnProperty("overridden") &&
        smartTextEntities[smartText].overridden)
    ) {
      userSmartText.unshift(smartTextEntities[smartText]);
    }
  });
  return userSmartText;
};

export const userTeamSmartTextOverideSelector = state => {
  const smartTextEntities = state.entities.smartText.user;
  const overrideSmartText = [];

  Object.keys(smartTextEntities).forEach(smartText => {
    if (
      smartTextEntities[smartText].hasOwnProperty("overridden") &&
      !smartTextEntities[smartText].overridden
    ) {
      overrideSmartText.push(smartTextEntities[smartText]);
    }
  });

  return overrideSmartText;
};

export const smartTextValueSelector = (state, placeholder) => {
  const smartTextEntities = smartTextSelector(state);
  const placeholderEntity = smartTextEntities[placeholder];

  if (!placeholderEntity) return null;

  return placeholderEntity.value;
};

export const isFetchingTeamSmartText = state => {
  return state.entities.smartText.isFetchingTeamSmartText;
};

export const isFetchingUserSmartText = state => {
  return state.entities.smartText.isFetchingUserSmartText;
};

export const isFetchingSmartText = state => {
  return isFetchingTeamSmartText(state) || isFetchingUserSmartText(state);
};

export const userTeamSmartTextSearchSelector = state => {
  const smartTextEntities = state.entities.smartText.user;
  const smartText = [];
  Object.keys(smartTextEntities).forEach(text => {
    smartText.push(smartTextEntities[text]);
  });
  return smartText;
};

export const designSmartFieldOwnerSelector = state =>
  state.entities.smartText.design?.userId;

export const isCurrentDesignSmartFieldOwner = state => {
  if (isEmpty(designSmartTextSelector(state))) return false;

  return designSmartFieldOwnerSelector(state) === state.currentUser.id;
};
