import React from "react";
import style from "./style.module.css";
import {
  getMappedBillingRows,
  parseBillingUpdate,
  getMappedShippingRows,
  parseShippingUpdate,
  getDefaultStateForCountry
} from "./billingSectionUtils";
import { Checkbox, Loading } from "views/components";
import SadFileIcon from "views/components/icons/SadFileIcon";
import InfoAlert from "views/components/InfoAlert";
import Form from "views/components/Form";
import { isEmpty } from "lib";
import { ADDRESS_FIELDS, SHIPPING_COUNTRY_CODES } from "lib/constants";

export class BillingSection extends React.Component {
  constructor(props) {
    super(props);
    this.updateBillingKey = this.updateBillingKey.bind(this);
    this.updateShippingKey = this.updateShippingKey.bind(this);
    this.setShippingSameAsBilling = this.setShippingSameAsBilling.bind(this);
    this.updateCurrentBilling = this.updateCurrentBilling.bind(this);
    this.updateCurrentShipping = this.updateCurrentShipping.bind(this);

    this.state = {
      billing: {
        country: "AU",
        state: "QLD"
      },
      shipping: {
        country: "AU",
        state: "QLD",
        addressType: "BUSINESS"
      },
      shouldShowValidation: true,
      isShippingSameAsBilling: false
    };
  }

  // update a billing key in local component state
  updateBillingKey({ key, value }) {
    const { billing, shipping, isShippingSameAsBilling } = this.state;

    const { updateKey, updateValue } = parseBillingUpdate({
      key,
      value,
      billing
    });

    const stateUpdate = {
      billing: {
        ...billing,
        [updateKey]: updateValue
      }
    };

    if (
      key === "country" &&
      !SHIPPING_COUNTRY_CODES.includes(value) &&
      isShippingSameAsBilling
    ) {
      // if we set country to anything other than AU or US stop shipping same as billing
      // no shipping for countries other than AU or US
      this.setState({
        isShippingSameAsBilling: false
      });
    } else if (isShippingSameAsBilling && ADDRESS_FIELDS.includes(updateKey)) {
      stateUpdate.shipping = {
        ...shipping,
        [updateKey]: updateValue
      };
    }
    if (key === "country") {
      const defaultState = getDefaultStateForCountry(value);
      this.updateBillingKey({ key: "state", value: defaultState });
    }

    this.setState(stateUpdate);
  }

  // update a shipping key in local component state
  updateShippingKey({ key, value }) {
    const { shipping } = this.state;

    const { updateKey, updateValue } = parseShippingUpdate({
      key,
      value,
      shipping
    });

    if (key === "country") {
      const defaultState = getDefaultStateForCountry(value);
      this.updateShippingKey({ key: "state", value: defaultState });
    }

    this.setState({
      shipping: {
        ...shipping,
        [updateKey]: updateValue
      }
    });
  }

  // update the current billing details in redux state
  updateCurrentBilling({ key, value }) {
    this.props.setCurrentBilling({ key, value });
    if (this.state.isShippingSameAsBilling && ADDRESS_FIELDS.includes(key)) {
      this.props.setCurrentShipping({ key, value });
    }
    if (key === "country") {
      const defaultState = getDefaultStateForCountry(value);
      this.updateCurrentBilling({ key: "state", value: defaultState });
    }
  }

  // update the current shipping details in redux state
  updateCurrentShipping({ key, value }) {
    this.props.setCurrentShipping({ key, value });
    if (key === "country") {
      const defaultState = getDefaultStateForCountry(value);
      this.updateCurrentShipping({ key: "state", value: defaultState });
    }
  }

  // set the shipping the same as billing in local component state
  setShippingSameAsBilling() {
    const { billing, shipping } = this.state;

    // copy the address fields in state from billing to shipping
    this.setState({
      isShippingSameAsBilling: !this.state.isShippingSameAsBilling,
      shipping: {
        ...shipping,
        street: billing.street,
        suburb: billing.suburb,
        country: billing.country,
        state: billing.state,
        postcode: billing.postcode
      }
    });

    // call to copy billing address details to redux shipping state
    if (!this.state.isShippingSameAsBilling) {
      this.props.setShippingSameAsBilling();
    }
  }

  componentDidMount() {
    // if we already have billingDetails from the api, set them in state
    if (!isEmpty(this.props.billingDetails)) {
      this.setState({
        billing: this.props.billingDetails
      });
    }

    // if we already have shippingDetails from the api, set them in state
    if (!isEmpty(this.props.shippingDetails)) {
      this.setState({
        shipping: this.props.shippingDetails
      });
    }

    if (this.props.currentOrderId) {
      // if we start with an orderId then we should try to fetch the billing and shipping
      this.props.fetchBillingAndShippingDetails();
    }

    if (this.props.isAccount) {
      // call to set the shipping detail default info from account
      const defaultShipping = this.props.getShippingFromAccountInfo();
      this.setState({
        shipping: {
          ...defaultShipping,
          ...this.state.shipping
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    // in case currentOrderId is not set before the section is loaded
    if (
      // just got an order id
      (!prevProps.currentOrderId && this.props.currentOrderId) ||
      // order loading finished
      (prevProps.loading.order && !this.props.loading.order)
    ) {
      this.props.fetchBillingAndShippingDetails();
    }

    // when billing details come in from the api set in state
    if (
      isEmpty(prevProps.billingDetails) &&
      !isEmpty(this.props.billingDetails)
    ) {
      this.setState({
        billing: this.props.billingDetails
      });
    }

    // when shipping details come in from the api set state
    if (
      isEmpty(prevProps.shippingDetails) &&
      !isEmpty(this.props.shippingDetails)
    ) {
      this.setState({
        shipping: this.props.shippingDetails
      });
    }
  }

  render() {
    if (
      !this.props.loading.order &&
      !this.props.loading.details &&
      !this.props.currentOrderId
    ) {
      // not loading order, order details, and still no order id (there is no order)
      return (
        <div className={style.empty}>
          <SadFileIcon />
          An error occurred and your order could not be found
        </div>
      );
    }

    if (this.props.loading.billing || this.props.loading.shipping) {
      // still loading the billing or shipping so show load spinner
      return (
        <div className={style.loading}>
          <Loading />
        </div>
      );
    }

    // when the country in billing is not AU or US we can't set shipping to billing since we only ship to AU and US
    const isShippingSameAsBillingDisabled = !SHIPPING_COUNTRY_CODES.includes(
      this.state.billing.country
    );

    const { isShippingSameAsBilling } = this.state;

    // get the billing and shipping rows with functions, errors and values applied to them
    const billingRows = getMappedBillingRows({
      billingDetails: this.state.billing,
      onChange: this.updateBillingKey,
      onBlur: this.updateCurrentBilling,
      errors: this.props.errors.filter(error => error.type === "billing")
    });
    const shippingRows = getMappedShippingRows({
      shippingDetails: this.state.shipping,
      onChange: this.updateShippingKey,
      onBlur: this.updateCurrentShipping,
      isShippingSameAsBilling,
      errors: this.props.errors.filter(error => error.type === "shipping")
    });

    return (
      <div className={style.billingSectionWrapper}>
        {this.props.isAccount && (
          <InfoAlert className={style.info} theme="yellow" iconSize="20px">
            <span>Order will be charged to your Account.</span>
          </InfoAlert>
        )}
        {!this.props.isAccount && (
          <div className={style.billingSubSection}>
            <Form
              title="Billing"
              rows={billingRows}
              showValidation={this.state.shouldShowValidation}
              showTitle
              titleStyle={style.billingTitle}
            />
          </div>
        )}
        <div className={style.billingSubSection}>
          <Form
            title="Shipping"
            rows={shippingRows}
            showTitle
            titleStyle={style.shippingTitle}
          >
            {// no need for shippingSameAsBilling checkbox for accounts
            !this.props.isAccount && (
              <Checkbox
                checked={this.state.isShippingSameAsBilling}
                label="Shipping address is same as Billing"
                onClick={this.setShippingSameAsBilling}
                className={style.checkbox}
                disabled={isShippingSameAsBillingDisabled}
              />
            )}
          </Form>
        </div>
      </div>
    );
  }
}

BillingSection.displayName = "BillingSection";

export default BillingSection;
