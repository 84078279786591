/**
 * @desc takes any number of arrays and finds the common elements among them
 * @param {[any]} array1 - an array to intersect
 * @param {[any]} array2 - an array to intersect
 * @param  {...any} rest - any number of arrays to intersect
 * @returns {[any]} the intersecting values between all given arrays
 */
export const intersect = (array1, array2, ...rest) => {
  if (rest.length === 0) {
    return [...new Set(array1)].filter(x => new Set(array2).has(x));
  }

  return intersect(array1, intersect(array2, ...rest));
};

/**
 * @desc creates a clone of an array with the item at the given index replaced
 * @param {Array} array - the original array
 * @param {Number} index - the index to replace at
 * @param {any} item - the item to replace with
 * @returns {Array} - a clone of the input array with the item at index replaced
 */
export const replaceItem = (array, index, item) => {
  const arrayCopy = array.slice(0);
  arrayCopy[index] = item;
  return arrayCopy;
};

/**
 * @desc creates a clone of an array with the item at the given index removed
 * @param {Array} array - the original array
 * @param {Number} index - the index to remove at
 * @returns {Array} - a clone of the input array with the item at index removed
 */
export const removeItem = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

/**
 * @desc creates a clone of an array inserting an item at the given index
 * @param {Array} array - the original array
 * @param {Number} index - the index to insert at
 * @param {any} item - the item to insert with
 * @returns {Array} - a clone of the input array with the item at index inserted
 */
export const insertItem = (array, index, value) => {
  return [...array.slice(0, index), value, ...array.slice(index)];
};

/**
 * @desc creates a clone of an array moving the item at fromIndex to toIndex
 * @param {Array} array - the original array
 * @param {Number} fromIndex - the index to move from
 * @param {Number} toIndex - the index to move to
 * @param {Number} on - the number of items starting at fromIndex to move
 * @returns {Array} - a clone of the input array with the item at index inserted
 */
export const moveItem = (array, fromIndex, toIndex, on = 1) => {
  const newArray = [...array];

  newArray.splice(toIndex, 0, ...newArray.splice(fromIndex, on));

  return newArray;
};

/**
 * @desc parses an array into an object using the given key from each object as the keys
 * @param {Array} array - the array to convert to an object
 * @param {string} key - the key which will be used to identify each array element in the output object
 * @returns {object} - an object of key values representing the array given in object format
 */
export const arrayOfObjectsToObject = (array, key) => {
  return array.reduce(
    (acc, value) => Object.assign(acc, { [value[key]]: value }),
    {}
  );
};
