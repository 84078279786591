import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FontSizeIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-1h20v20H-1z" />
      <g>
        <path d="M1 2h16v16H1z" />
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M2.87 13l-.91 2.282a1 1 0 1 1-1.85-.74L4.05 4.69a.993.993 0 0 1 .047-.12l.024-.06.006.003a1 1 0 0 1 1.851.279l3.886 9.704a.994.994 0 0 1 .11.273l.002.006a1 1 0 0 1-1.932.518L7.129 13H2.87zm.799-2H6.33L5 7.664 3.668 11z"
        />
        <path
          fill={color || easilGrayXDark}
          d="M14.8 3.2h2.3a.9.9 0 1 1 0 1.8h-2.3v2.1a.9.9 0 1 1-1.8 0V5h-2.1a.9.9 0 1 1 0-1.8H13V.9a.9.9 0 1 1 1.8 0v2.3z"
        />
        <rect width="6" height="1.8" x="11" y="10" fill="#BDC0C2" rx=".9" />
      </g>
    </g>
  </svg>
);
FontSizeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FontSizeIcon;
