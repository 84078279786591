import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { keyCodes, noop } from "lib";
import { ExclaimationMarkCircledIcon } from "views/components/icons";
import Tooltip from "views/components/Tooltip/Tooltip";

const defaultOnKeyDown = event => {
  /* We stop propagation to prevent elements to be deleted from canvas */
  if (keyCodes.isBlackListed(event.keyCode)) {
    event.stopPropagation();
  }
};

const QuickInput = ({
  className,
  errorColor = "#afb3b6",
  hasInputError,
  inputClassName,
  label,
  labelClassName,
  onBlur = noop,
  onFocus,
  onInputClick = noop,
  onKeyDown = defaultOnKeyDown,
  onKeyPress,
  ref,
  showTooltip,
  tipText,
  value,
  ...props
}) => (
  <React.Fragment>
    {label && <div className={`${style.label} ${labelClassName}`}>{label}</div>}
    <div
      className={`${style.div} ${className}`}
      data-error={!!hasInputError}
      data-disabled={props.disabled}
      data-testid="Quick Input"
    >
      <input
        className={inputClassName}
        value={value}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onInputClick}
        ref={ref}
        {...props}
      />
      {hasInputError && (
        <>
          <ExclaimationMarkCircledIcon
            color={errorColor}
            size={"14"}
            style={{ marginRight: "9px" }}
            data-tip={tipText}
            data-for="quick-input-alert-tip"
          />
          {showTooltip && (
            <Tooltip
              id="quick-input-alert-tip"
              className={style.alertToolTip}
              place="bottom"
              offset={{ left: 74 }}
            />
          )}
        </>
      )}
    </div>
  </React.Fragment>
);

QuickInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string
};

export default QuickInput;
