import { combineReducers } from "redux";

import { archivedApiReducers as archived } from "./archived";
import { sharedApiReducers as shared } from "./shared";
import { draftsApiReducers as drafts } from "./drafts";
import { queryApiReducers as query } from "./query";
import { foldersApiReducers as folders } from "./folders";
import { searchApiReducers as search } from "./search";
import { approvalsApiReducers as approvals } from "./approvals";

/* export default combineReducers({ archived, drafts, folders });*/
export default combineReducers({
  drafts,
  archived,
  query,
  folders,
  search,
  shared,
  approvals
});
