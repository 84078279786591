import React from "react";
import ReactDOMServer from "react-dom/server";
import { TableRowWithoutDropzones } from "../../elements/Table/TableRow";
import { Table } from "../../elements/Table/Table";
import tableStyle from "views/components/Editor/elements/Table/style.module.css";

export const tableHeightsUpdater = table => {
  const rowsWithAutoHeight = table.rows.map(setRowAutoHeight);

  const tableDiv = renderTableDiv({
    ...table,
    rows: rowsWithAutoHeight
  });

  const tableHtml = tableDiv.querySelector(`.${tableStyle.table}`);

  // need to access children of first child since rows are wrapped in a div so they can
  // have their position changed to absolute when original is being hidden
  const tableRowsHtml = tableHtml.firstChild.children;

  const rowsWithHeightAdjusted = table.rows.map((row, rowIndex) => {
    const rowHtml = tableRowsHtml[rowIndex];

    return adjustRowHeight({ row, rowHtml });
  });

  tableDiv.parentElement.removeChild(tableDiv);

  const tableDivWithRowsAdjusted = renderTableDiv({
    ...table,
    rows: rowsWithHeightAdjusted,
    height: "auto"
  });

  const newTableHeight = tableDivWithRowsAdjusted.scrollHeight;

  tableDivWithRowsAdjusted.parentElement.removeChild(tableDivWithRowsAdjusted);

  return {
    ...table,
    height: newTableHeight,
    rows: rowsWithHeightAdjusted
  };
};

export const renderTableDiv = table => {
  const simulationsDiv = document.getElementById("simulations");

  const tableMarkup = ReactDOMServer.renderToStaticMarkup(
    <Table
      TableRowComponent={TableRowWithoutDropzones}
      elementData={{
        ...table,
        opacity: 1
      }}
    />
  );

  const tableDiv = document.createElement("div");

  tableDiv.innerHTML = tableMarkup;
  simulationsDiv.appendChild(tableDiv);

  return tableDiv;
};

const adjustRowHeight = ({ row, rowHtml }) => {
  const textFields = row.cells[0].textFields;

  const maxTextFieldHeightMap = mapTextFieldHeights({ textFields, rowHtml });

  const cellsUpdated = row.cells.map(cell => {
    const textFieldsUpdated = cell.textFields.map(
      (textField, textFieldIndex) => {
        return {
          ...textField,
          height: maxTextFieldHeightMap[textFieldIndex]
        };
      }
    );

    return {
      ...cell,
      textFields: textFieldsUpdated
    };
  });

  return {
    ...row,
    cells: cellsUpdated
  };
};

const mapTextFieldHeights = ({ textFields, rowHtml }) => {
  const maxTextFieldHeightMap = textFields.map((textfield, textFieldIndex) => {
    const cellsTextFields = [...rowHtml.children].map(
      html => html.children[textFieldIndex].scrollHeight
    );

    return Math.max(...cellsTextFields, 10);
  });

  return maxTextFieldHeightMap;
};

const setRowAutoHeight = row => {
  const cellsWithAutoHeight = row.cells.map(setCellAutoHeight);

  return {
    ...row,
    cells: cellsWithAutoHeight
  };
};

const setCellAutoHeight = cell => {
  const textFieldsWithAutoHeight = cell.textFields.map(setTextFieldAutoHeight);

  return {
    ...cell,
    textFields: textFieldsWithAutoHeight
  };
};

const setTextFieldAutoHeight = textField => {
  return {
    ...textField,
    height: "auto"
  };
};
