import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ImagesIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 50 51"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M37 16.073V40a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h23.003"
      />
      <g fill="#333" transform="translate(31.3 .3)">
        <rect width="2" height="10" x="4" rx="1" />
        <rect width="10" height="2" y="4" rx="1" />
      </g>
      <path
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M43.602 16L49 17.142 43.602 50 20 46.156"
      />
      <path
        fill="#333"
        fillRule="nonzero"
        stroke="#333"
        strokeWidth=".5"
        d="M11.425 33.001h18.012l-4.11-12.266-5.656 8.943a.75.75 0 0 1-.955.277l-3.922-1.858L11.425 33zm13.477-14.402a.75.75 0 0 1 1.345.163l4.944 14.75a.75.75 0 0 1-.712.989H10a.75.75 0 0 1-.618-1.175l4.537-6.605a.75.75 0 0 1 .94-.253l3.9 1.847 6.143-9.716zM13 21.8a4.8 4.8 0 1 1 0-9.6 4.8 4.8 0 0 1 0 9.6zm0-1.6a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4z"
      />
    </g>
  </svg>
);
ImagesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ImagesIcon;
