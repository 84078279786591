import PATHS from "routes/paths";
import Subscriptions from "lib/subscriptions";

const BrandKitColorOps = {
  getColorArr(term, colors) {
    const colorArr = Object.values(colors || {});
    const sortFunc = (a, b) => {
      if (a.id === this.stickyColorGroupId) {
        return -1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    };

    if ((term || "").trim().length) {
      const regExp = new RegExp(term, "i");
      return colorArr.filter(e => regExp.test(e.name)).sort(sortFunc);
    } else {
      return colorArr.sort(sortFunc);
    }
  },

  async handleAddBrandColorGroup() {
    const { currentSubscriptionCode, currentTeam } = this.props;
    const subscription = Subscriptions.get(currentSubscriptionCode);

    if (subscription.getCanAccessBrandKit()) {
      const { id: teamId } = currentTeam;
      const apiResp = await this.props.addBrandKitColorGroup({ teamId });
      this.stickyColorGroupId = apiResp.ids;

      this.handleSearchInput(null);
    } else {
      this.props.showUpgradeModal();
    }
  },

  handleSearchInput(term) {
    const { history } = this.props;

    if ((term || "").trim().length) {
      const termEnc = encodeURIComponent(term);
      history.replace(`${PATHS.brandColors}?term=${termEnc}`);
    } else {
      history.replace(PATHS.brandColors);
    }
  }
};

export default BrandKitColorOps;
