import React from "react";
import style from "./style.module.css";
import { H5 } from "..";
import PrintItem from "./PrintItem/PrintItem";
import { calculateDisplayMeasurements } from "lib";

const OrderDesigns = ({ order, orderPrintOptions, className }) => {
  if (!order) return null;

  const getOrderDesign = design => {
    if (!design) return null;

    const designPrintItems = design.printItems || [];

    const getDisplayMeasurements = () => {
      const measurements = calculateDisplayMeasurements(design);
      return `${measurements.width}x${measurements.height}${measurements.unit}`;
    };

    const designSubtotal = designPrintItems
      .map(item => item.total)
      .reduce((a, b) => (a += b), 0);

    return (
      <div key={`orderDesignId-${design.designId}`} className={className}>
        <div className={style.orderDesign}>
          <div className={style.orderDesignItem}>
            <div className={style.productDescription}>
              <H5 className={style.designName}>{design.title}</H5>
              <div className={style.designDescription}>
                {design.templateSize} ({getDisplayMeasurements()})
              </div>
            </div>
            <H5 className={style.productPrice}>${designSubtotal.toFixed(2)}</H5>
          </div>
          {designPrintItems.length > 0 &&
            designPrintItems.map(item => (
              <PrintItem
                key={`printItem-${item.id}`}
                item={item}
                orderPrintOptions={orderPrintOptions[design.designId]}
              />
            ))}
        </div>
        <div className={style.divider} />
      </div>
    );
  };

  return <>{order.designs.map(design => getOrderDesign(design))}</>;
};

export default OrderDesigns;
