import React from "react";
import Dotdotdot from "react-clamp";

export const MultiLineEllipsis = ({ clamp = 3, children, className = "" }) => {
  return (
    <Dotdotdot clamp={clamp} className={className}>
      {children}
    </Dotdotdot>
  );
};

export default MultiLineEllipsis;
