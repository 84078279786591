import * as types from "./editorActionPropagationTypes";
import { pick } from "lib";
import { sortedJsonStringEqual } from "lib/equalityUtils";
import { immutableUpdate } from "lib/immutableUpdate";
import { LOCATION_CHANGE } from "react-router-redux";
import PATHS from "routes/paths";

export const initState = {
  actions: [],
  isProcessing: false,
  processingDesignIds: []
};

const editorActionPropagationReducers = (state = initState, action) => {
  switch (action.type) {
    case types.ADD_PROPAGATION_ACTION: {
      // add the action to the list for its uniqueId
      return immutableUpdate(state, {
        actions: {
          $autoArray: {
            $push: [action.actionData]
          }
        },
        lastActionAt: { $set: Date.now() }
      });
    }

    case types.REMOVE_PROPAGATION_ACTION: {
      return immutableUpdate(state, {
        actions: {
          $set: state.actions.filter(
            currentAction =>
              !sortedJsonStringEqual(
                pick(currentAction, Object.keys(action.match)),
                action.match
              )
          )
        }
      });
    }

    case types.SET_PROPAGATION_PROCESSING: {
      return immutableUpdate(state, {
        isProcessing: {
          $set: action.value
        }
      });
    }

    case types.SET_PROPAGATING_DESIGN_IDS: {
      return immutableUpdate(state, {
        processingDesignIds: {
          $set: action.designIds
        }
      });
    }

    case types.REMOVE_PROPAGATING_DESIGN_ID: {
      return immutableUpdate(state, {
        processingDesignIds: {
          $set: state.processingDesignIds.filter(
            designId => designId !== action.designId
          )
        }
      });
    }

    case LOCATION_CHANGE: {
      if (
        action.pathname &&
        // in the editor
        PATHS.isEditorPath(action.pathname) &&
        // the pathname changed (design changed or left the editor)
        action.pathname !== action.payload.pathname
      ) {
        // return to initial state so we clear the action list
        return initState;
      }
      return state;
    }

    default:
      return state;
  }
};

export default editorActionPropagationReducers;
