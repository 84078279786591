import React from "react";
import PropTypes from "prop-types";

import style from "./style.module.css";

const Testimonials = ({ testimonials }) => {
  return (
    <div className={style.testimonialsWrapper}>
      <div className={style.testimonialsHeader}>
        <header>What our users are loving about Easil</header>
      </div>
      <div className={style.testimonials}>
        {Object.keys(testimonials)
          .map(testimonial => testimonials[testimonial])
          .map((testimonial, index) => (
            <div key={`testimonial-${index}`} className={style.testimonial}>
              <div className={style.testimonialContent}>
                {testimonial.content}
              </div>
              <div className={style.testimonialCitation}>
                {testimonial.citation}
              </div>
              <div className={style.testimonialCompanyName}>
                {testimonial.companyName}
              </div>
              <div className={style.testimonialWebsite}>
                {testimonial.website}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

Testimonials.propTypes = {
  testimonials: PropTypes.objectOf(Object)
};

export default Testimonials;
