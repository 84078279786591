import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { noop } from "lib";

const H3 = ({ className, children, onClick = noop, styleInput }) => {
  return (
    <h3
      onClick={onClick}
      style={styleInput}
      className={`${style.h3} ${className}`}
    >
      {children}
    </h3>
  );
};
H3.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default H3;
