import React from "react";
/* import PropTypes from 'prop-types';*/

import CaretCircledIcon from "views/components/icons/CaretCircledIcon";
import CaretPopout from "./CaretPopout";
import style from "./style.module.css";

const CaretWithPopout = ({
  isPopoutOpen,
  wrapperElement,
  onClosePopout,
  openPopoutClick,
  className,
  caretArrowColor,
  caretFillColor,
  fullFill,
  width,
  height,
  popoutClassName,
  id = "",
  ...props
}) => {
  if (!isPopoutOpen) {
    return (
      <div
        className={fullFill ? style.caretOuterWrapFullFill : ""}
        id={`caret-with-popout-${id}`}
      >
        <div className={`${style.caretWrapper} ${className}`}>
          <CaretCircledIcon
            onClick={openPopoutClick}
            arrowColor={caretArrowColor}
            color={caretFillColor}
            fullFill={fullFill}
          />
        </div>
      </div>
    );
  }

  return (
    <CaretPopout
      className={className}
      wrapperElement={wrapperElement}
      onClose={onClosePopout}
      arrowColor={caretArrowColor}
      circleFillColor={caretFillColor}
      fullFill={fullFill}
      popoutClassName={popoutClassName}
    >
      {props.children}
    </CaretPopout>
  );
};

export default CaretWithPopout;
