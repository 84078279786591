import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

const MainLayout = ({ children, bgColor = "#fff", withGutters = true }) => (
  <div
    className={`${style.wrapper} translate`}
    style={{ backgroundColor: bgColor }}
    id="contentWrapper"
  >
    <div className={style.header}>
      <div className={style.gutters}>
        <div className={style.content}>{children[0]}</div>
      </div>
    </div>
    <div
      className={
        children[2].props.children ? style.subHeaderNoPadding : style.subHeader
      }
    >
      <div className={style.subHeaderGutters}>
        <div className={style.content}>{children[2]}</div>
      </div>
    </div>
    {!withGutters ? (
      children[1]
    ) : (
      <div className={style.gutters}>
        <div className={style.content}>{children[1]}</div>
      </div>
    )}
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.array
};

export default MainLayout;
