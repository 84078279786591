import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const AlignRectCenterIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  rotate,
  ...props
}) => {
  const finalStyle = {
    transform: `rotate(${rotate || 0}deg)`,
    ...style
  };
  return (
    <svg
      {...props}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 20}
      height={height || size || 20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <g fill={color || easilGrayXDark} transform="translate(3 2)">
          <rect width="10" height="5" x="2" y="2" rx="1" />
          <rect width="14" height="5" y="9" rx="1" />
          <rect width="2" height="16" x="6" rx=".5" />
        </g>
      </g>
    </svg>
  );
};
AlignRectCenterIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.string
};

export default AlignRectCenterIcon;
