import React from "react";
import style from "./style.module.css";
import SlidingToggleComponent from "views/components/SlidingToggle/SlidingToggle";
import CircledCheckIcon from "views/components/icons/CircledCheckIcon";
import CrossCircledIcon from "views/components/icons/CrossCircledIcon";

const SlidingToggle = ({ onToggle }) => {
  const getSlidingToggleOption = () => {
    const Label = ({ text, IconComponent, iconProps }) => {
      return (
        <span className={style.label}>
          <IconComponent {...iconProps} /> {text}
        </span>
      );
    };

    return [
      {
        label: () => (
          <Label
            text="Approve"
            IconComponent={CircledCheckIcon}
            iconProps={{
              className: style.icon,
              color: "#15c79d",
              size: "20px",
              noPadding: true
            }}
          />
        ),
        id: "ApprovalModa-SlidingToggle-left-id",
        onToggle: () => onToggle({ approve: true })
      },
      {
        label: () => (
          <Label
            text="Decline"
            IconComponent={CrossCircledIcon}
            iconProps={{
              version: 2,
              className: style.icon,
              color: "#e64826",
              size: "20px"
            }}
          />
        ),
        id: "ApprovalModa-SlidingToggle-right-id",
        onToggle: () => onToggle({ approve: false })
      }
    ];
  };

  return (
    <SlidingToggleComponent
      options={getSlidingToggleOption()}
      customStyles={{
        "background-color": "#eef0f1",
        "button-color": "#ffffff",
        "border-color": "#e2e5e8",
        width: "486px",
        "button-width": "235px",
        translation: "235px",
        "box-shadow": "0 2px 4px 0 rgba(15, 15, 16, 0.08)",
        height: "48px",
        "button-height": "40px",
        "button-border-radius": "4px"
      }}
    />
  );
};

SlidingToggle.displayName = "SlidingToggle";

SlidingToggle.propTypes = {};

export default SlidingToggle;
