import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const SplitArrowHeadIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  direction = "right",
  title
}) => {
  const directionToAngle = {
    right: 0,
    down: 90,
    left: 180,
    up: -90
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction]}deg)`,
    ...style
  };

  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 12H7M3 21l3.333-9L3 3l18 9z"
        />
      </g>
    </svg>
  );
};
SplitArrowHeadIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SplitArrowHeadIcon;
