export const FETCH_FOLDER_FOR_DESIGN_REQUEST =
  "API/ENTITIES/FOLDERS/FETCH_DESIGN_FOLDER_REQUEST";
export const FETCH_FOLDER_FOR_DESIGN_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/FETCH_DESIGN_FOLDER_REQUEST_SUCCESS";
export const FETCH_FOLDER_FOR_DESIGN_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/FETCH_DESIGN_FOLDER_REQUEST_FAILURE";

export const ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/ADD_CONTENT_TO_PERSONAL_REQUEST";
export const ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/ADD_CONTENT_TO_PERSONAL_REQUEST_SUCCESS";
export const ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/ADD_CONTENT_TO_PERSONAL_REQUEST_FAILURE";

export const REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/REMOVE_CONTENT_FROM_PERSONAL_REQUEST";
export const REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/REMOVE_CONTENT_FROM_PERSONAL_REQUEST_SUCCESS";
export const REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/REMOVE_CONTENT_FROM_PERSONAL_REQUEST_FAILURE";

export const ADD_CONTENT_TO_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/TEAM_FOLDERS/ADD_CONTENT_REQUEST";
export const ADD_CONTENT_TO_TEAM_FOLDER_SUCCESS =
  "API/ENTITIES/TEAM_FOLDERS/ADD_CONTENT_SUCCESS";
export const ADD_CONTENT_TO_TEAM_FOLDER_FAILURE =
  "API/ENTITIES/TEAM_FOLDERS/ADD_CONTENT_FAILURE";

export const REMOVE_CONTENT_FROM_TEAM_FOLDER_REQUEST =
  "API/ENTITIES/TEAM_FOLDERS/REMOVE_CONTENT_REQUEST";
export const REMOVE_CONTENT_FROM_TEAM_FOLDER_SUCCESS =
  "API/ENTITIES/TEAM_FOLDERS/REMOVE_CONTENT_SUCCESS";
export const REMOVE_CONTENT_FROM_TEAM_FOLDER_FAILURE =
  "API/ENTITIES/TEAM_FOLDERS/REMOVE_CONTENT_FAILURE";

export const MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST =
  "API/ENTITIES/FOLDERS/MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST";
export const MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/FOLDERS/MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_SUCCESS";
export const MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/FOLDERS/MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_FAILURE";
