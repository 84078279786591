import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import { findPage } from "state/api/helpers";
import { omit, isEmpty } from "lib";
import { removeItem } from "lib/array/array";
import PATHS from "routes/paths";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50
};

const declinedApprovalSharedApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case designsEntitiesTypes.DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST_SUCCESS: {
      const {
        response: { ids = [] } = {},
        request: { page }
      } = action;

      if (isEmpty(ids) && Number(page) !== 1) {
        return {
          ...state,
          pages: omit(state.pages, action.request.page)
        };
      }

      const updatedPage = {
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false
        }
      };

      return {
        ...state,
        pages: { ...state.pages, ...updatedPage }
      };
    }
    case designsEntitiesTypes.DESIGN_CANCEL_APPROVAL_REQUEST_SUCCESS:
    case designsEntitiesTypes.DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS: {
      if (action.pathname !== PATHS.workspaceShared) {
        return state;
      }

      const {
        request: {
          body: { designId }
        }
      } = action;

      const page = findPage(state.pages, designId);
      if (!page) return state;
      const imageIndex = state.pages[page].ids.indexOf(designId);

      const pageUpdated = {
        ...state.pages[page],
        ids: removeItem(state.pages[page].ids, imageIndex)
      };
      return {
        ...state,
        pages: {
          ...state.pages,
          [page]: pageUpdated
        }
      };
    }

    case designsEntitiesTypes.DESIGN_REVERT_APPROVAL_REQUEST_SUCCESS:
    case designsEntitiesTypes.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_SUCCESS: {
      const {
        response: { ids: designIds }
      } = action;

      let pagesClone = Object.assign({}, state.pages);

      designIds.forEach(designId => {
        const page = findPage(pagesClone, designId);
        if (!page) return state;
        const imageIndex = pagesClone[page].ids.indexOf(designId);

        const pageUpdated = {
          ...pagesClone[page],
          ids: removeItem(pagesClone[page].ids, imageIndex)
        };

        pagesClone = {
          ...pagesClone,
          [page]: pageUpdated
        };
      });

      return {
        ...state,
        pages: {
          ...state.pages,
          ...pagesClone
        }
      };
    }

    default:
      return state;
  }
};

export default declinedApprovalSharedApiReducers;
