import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PasteIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g transform="translate(2.5 1)">
      <path
        fill="none"
        d="M2 2h.5l.12-.319c.216-.577.36-.816.556-1.036A1.8 1.8 0 013.88.157C4.154.051 4.43 0 5.046 0h.908c.616 0 .892.051 1.166.157a1.8 1.8 0 01.704.488c.196.22.34.46.557 1.036L8.5 2H9M8 2h1a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2V4a2 2 0 012-2h1"
        stroke={color || easilGrayXDark}
        strokeWidth="2"
      />
      <g fill={color || easilGrayXDark}>
        <rect width="6" height="1.5" x="2.5" y="4.5" rx=".75" />
        <rect width="6" height="1.5" x="2.5" y="7.25" rx=".75" />
        <rect width="6" height="1.5" x="2.5" y="10" rx=".75" />
      </g>
    </g>
  </svg>
);

PasteIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PasteIcon;
