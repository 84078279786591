import { omit, isEmpty } from "lib";
import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import { getTermKey } from "../../helpers";

export const initState = {
  isSearching: false,
  pageSize: 50,
  results: {
    /* how this looks like when populated
     *
     * "searchTearm1": {
     *   pages: {}
     * },
     * "searchTearm2": {
     *   pages: {}
     * }*/
  }
};

const searchApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_SEARCH_REQUEST: {
      const { params, page, extra } = action.request;
      const { term, context, scope } = params;

      if (context !== "templates" && scope !== "team") {
        return state;
      }

      const termKey = getTermKey({
        term,
        size: params.size,
        searchType: extra.searchType
      });

      const getPreviousPages = () => {
        if (state.results[termKey]) {
          return state.results[termKey].pages;
        } else {
          return {};
        }
      };

      const getIds = () => {
        if (
          state.results[termKey] &&
          state.results[termKey].pages &&
          state.results[termKey].pages[page]
        ) {
          return state.results[termKey].pages[page].ids;
        } else {
          return null;
        }
      };

      return {
        ...state,
        isSearching: true,
        results: {
          ...state.results,
          [termKey]: {
            pages: {
              ...getPreviousPages(),
              [page]: {
                ids: getIds()
              }
            }
          }
        }
      };
    }

    case designsEntitiesTypes.DESIGNS_SEARCH_REQUEST_SUCCESS: {
      const { ids } = action.response;

      const { params, page, extra } = action.request;
      const { term, context, scope } = params;

      if (context !== "templates" && scope !== "team") {
        return state;
      }

      const termKey = getTermKey({
        term,
        size: params.size,
        searchType: extra.searchType
      });

      const getPreviousPages = () => {
        if (state.results[termKey]) {
          return state.results[termKey].pages;
        } else {
          return {};
        }
      };

      return {
        ...state,
        isSearching: false,
        results: {
          ...state.results,
          [termKey]: {
            pages: {
              ...getPreviousPages(),
              [page]: {
                ids: ids || []
              }
            }
          }
        }
      };
    }
    case designsEntitiesTypes.DESIGNS_BY_FOLDER_REQUEST_SUCCESS: {
      const {
        response: { ids = [] } = {},
        request: {
          page,
          params: { folderId }
        }
      } = action;

      if (isEmpty(ids) && Number(page) !== 1) {
        return {
          ...state,
          pages: omit(state.pages, action.request.page)
        };
      }

      const updatedPage = {
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false,
          folderId
        }
      };

      if (folderId !== state.folderId) {
        return {
          ...initState,
          pages: { ...state.pages, ...updatedPage }
        };
      }

      return {
        ...state,
        pages: { ...state.pages, ...updatedPage }
      };
    }

    default:
      return state;
  }
};

export default searchApiReducers;
