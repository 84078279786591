import React from "react";
import style from "./style.module.css";
import CheckIcon from "views/components/icons/CheckIcon";
import MinusIcon from "views/components/icons/MinusIcon";
import GuaranteeTickIcon from "views/components/icons/GuaranteeTickIcon";
import GuaranteeLabelIcon from "views/components/icons/GuaranteeLabelIcon";
import InfoIcon from "views/components/icons/InfoIcon";
import Tooltip from "views/components/Tooltip";

const getFeatureDisplay = (planFeatureDetails, isGuarantee) => {
  if (planFeatureDetails.label) {
    return planFeatureDetails.label;
  }
  if (planFeatureDetails.available) {
    if (isGuarantee) {
      return <GuaranteeTickIcon color="#3184fc" />;
    }
    return <CheckIcon color="#3184fc" size="19px" />;
  }
  return (
    <div className={style.planFeatureDetailsLabel}>
      <MinusIcon color="#ababab" size="14px" />
    </div>
  );
};

export const PlanComparisonFeatureSection = ({ featureSection, planCodes }) => (
  <>
    {featureSection.showHeader ? (
      <div className={style.planSectionHeader}>{featureSection.header}</div>
    ) : (
      <div className={style.planSectionEmptyHeader} />
    )}
    {featureSection.features.map(planFeature => (
      <div className={style.planFeatureRow}>
        <div className={style.planFeatureRowLeft}>
          {planFeature.isGuarantee ? (
            <GuaranteeLabelIcon color="#2C2E2F" />
          ) : (
            planFeature.label
          )}
          {Boolean(planFeature.infoToolTip) && (
            <>
              <InfoIcon
                color={planFeature.infoToolTipIconColor || "#d1d4d6"}
                tipText={planFeature.infoToolTip}
                isTipHtml={true}
                tipFor={`${planFeature.label}-tooltip`}
                size="12px"
                style={{
                  marginLeft: "8px",
                  marginBottom: "4px",
                  cursor: "help"
                }}
                href={planFeature.infoHref}
              />
              <Tooltip
                id={`${planFeature.label}-tooltip`}
                className={style.infoToolTip}
                place="right"
                multiline
              />
            </>
          )}
        </div>
        <div className={style.planFeatureRowRight}>
          {planCodes.map(planCode => (
            <div className={style.planFeatureDetailsLabel}>
              {getFeatureDisplay(
                planFeature[planCode],
                planFeature.isGuarantee
              )}
            </div>
          ))}
        </div>
      </div>
    ))}
  </>
);

export default PlanComparisonFeatureSection;
