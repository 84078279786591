import { getPath } from "lib/lodash";
import { isValidDateString } from "lib/temporal/dateUtils";

/**
 * @desc sorts an array by the createdAt value of its elements
 * @param {Array} array - the array to sort
 * @returns {Array} - the sorted array
 * @note Array.sort mutates the original array
 */
export const sortByCreatedAt = array => {
  const compare = (a, b) => {
    if (Date.parse(a.createdAt) < Date.parse(b.createdAt)) {
      return 1;
    }
    if (Date.parse(a.createdAt) > Date.parse(b.createdAt)) {
      return -1;
    }
    return 0;
  };

  return array.sort(compare);
};

/**
 * @desc sorts an array by the createdAt value of its elements for the given key
 * @param {Array} array - the array to sort
 * @param {string} key - the key to gather the createdAt value from in each element
 * @returns {Array} - the sorted array
 * @note Array.sort mutates the original array
 */
export const sortByCreatedAtForKey = (array, key) => {
  const compare = (a, b) => {
    if (!a[key] || !b[key]) {
      return 0;
    }
    if (Date.parse(a[key].createdAt) < Date.parse(b[key].createdAt)) {
      return 1;
    }
    if (Date.parse(a[key].createdAt) > Date.parse(b[key].createdAt)) {
      return -1;
    }
    return 0;
  };

  return array.sort(compare);
};

/**
 * @desc sorts an array by the given key defined as a path
 * @param {Array} array - the array to sort
 * @param {string} path - the key path to the sort value in each element
 * @param {String} [direction="DESC"] - the direction to sort
 * @returns {Array} - the sorted array
 * @note Array.sort mutates the original array
 */
export const sortByKeyWithPath = ({ array, path, direction = "DESC" }) => {
  const compare = (a, b) => {
    const aPathValue = getPath(a, path);
    const bPathValue = getPath(b, path);
    if (!aPathValue || !bPathValue) {
      return 0;
    }

    // handle date keys
    if (isValidDateString(aPathValue) && isValidDateString(bPathValue)) {
      if (Date.parse(aPathValue) < Date.parse(bPathValue)) {
        return direction === "DESC" ? 1 : -1;
      }
      if (Date.parse(aPathValue) > Date.parse(bPathValue)) {
        return direction === "DESC" ? -1 : 1;
      }
      return 0;
    }

    // handle string keys
    if (typeof aPathValue === "string" && typeof bPathValue === "string") {
      if (direction !== "DESC") {
        return aPathValue.localeCompare(bPathValue, "en", {
          sensitivity: "base"
        });
      } else {
        return bPathValue.localeCompare(aPathValue, "en", {
          sensitivity: "base"
        });
      }
    }

    return direction === "DESC"
      ? aPathValue - bPathValue
      : bPathValue - aPathValue;
  };

  return array.sort(compare);
};

/**
 * @desc sorts an array by the updatedAt value of its elements
 * @param {Array} array - the array to sort
 * @returns {Array} - the sorted array
 * @note Array.sort mutates the original array
 */
export const sortByUpdatedAt = array => {
  const compare = (a, b) => {
    if (Date.parse(a.updatedAt) < Date.parse(b.updatedAt)) {
      return 1;
    }
    if (Date.parse(a.updatedAt) > Date.parse(b.updatedAt)) {
      return -1;
    }
    return 0;
  };

  return array.sort(compare);
};
