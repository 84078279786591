import imageInstructionDefaults from "./imageInstructionDefaults";
import { isEmpty } from "lib/lodash";
import { getPaletteFromSrc } from "lib/Colors/colorUtils";
import gridFixtures from "views/components/Editor/sidebar/tabs/shapes/gridFixtures.json";
import { getMediaType } from "lib/mediaSourceHelpers";
import { getAnimationDataKey } from "lib/animatedElementUtils";
import { getVideoDurationFromSrc } from "lib/videoHelpers";

const gridProcess = (element, page) => {
  /* If the grid is missing its imageInstructions, we assign it
     the initial imageInstructions from the fixture
   */
  if (isEmpty(element.imageInstructions)) {
    const elementGridFixture = gridFixtures.find(
      fixture => fixture.id === element.id
    );
    element.imageInstructions = elementGridFixture["imageInstructions"];
  }

  return new Promise((resolve, _reject) => {
    const imageInstructionsPromises = processVectorImageInstructions(
      element,
      page
    );

    Promise.all(imageInstructionsPromises).then(values => {
      element.imageInstructions = values;
      resolve(element);
    });
  });
};

const processVectorImageInstruction = async (
  originalImageInstruction,
  element,
  page
) => {
  const imageInstruction = { ...originalImageInstruction };

  // the use of previewUrl on an image instruction is deprecated and should be replaced with previewSrc
  if (imageInstruction.previewUrl) {
    if (!imageInstruction.previewSrc) {
      imageInstruction.previewSrc = imageInstruction.previewUrl;
    }
    imageInstruction.previewUrl = undefined;
  }

  // in case the Image instruction doesn't have a palette we fetch it
  if (!imageInstruction.palette && imageInstruction.previewSrc) {
    imageInstruction.palette = await getPaletteFromSrc(
      imageInstruction.previewSrc
    );
  }

  if (!imageInstruction.top) {
    imageInstruction.top = 0;
  }

  if (!imageInstruction.left) {
    imageInstruction.left = 0;
  }

  if (!imageInstruction.angle) {
    imageInstruction.angle = 0;
  }

  // handle animated elements which are not already included in the page animatedElementsObject
  const animationDataKey = getAnimationDataKey({
    uniqueId: element.uniqueId,
    domId: imageInstruction.domId
  });
  if (
    imageInstruction.duration &&
    page.animatedElements &&
    !Object.keys(page.animatedElements).includes(animationDataKey)
  ) {
    window.easil.designAdapter.push({
      duration: imageInstruction.duration,
      uniqueId: element.uniqueId,
      animationDataKey,
      pageId: page.uniqueId,
      groupId: element.groupId
    });
  }

  // assign duration to video elements with no duration property
  if (imageInstruction.type === "video" && !imageInstruction.duration) {
    imageInstruction.duration = await getVideoDurationFromSrc(
      imageInstruction.src
    );
    // assign imageInstruction media object
    imageInstruction.media = {
      asset: imageInstruction.name.toLowerCase(),
      createdAt: Date.now(),
      height: imageInstruction.height,
      id: imageInstruction.id,
      name: imageInstruction.name.toLowerCase(),
      preview: imageInstruction.previewSrc,
      size: imageInstruction.size,
      status: "ACTIVE",
      thumbnail: imageInstruction.thumbSrc,
      type: getMediaType(imageInstruction),
      updatedAt: Date.now(),
      width: imageInstruction.width,
      duration: imageInstruction.duration
    };

    // push animated data to designAdapter for animatedElements assignment
    window.easil.designAdapter.push({
      duration: imageInstruction.duration,
      uniqueId: element.uniqueId,
      animationDataKey,
      pageId: page.uniqueId,
      groupId: element.groupId
    });
  }

  return {
    ...imageInstructionDefaults,
    ...imageInstruction
  };
};

const processVectorImageInstructions = (element, page) => {
  return element.imageInstructions.map(imageInstruction => {
    return processVectorImageInstruction(imageInstruction, element, page);
  });
};

export default gridProcess;
