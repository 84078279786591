import * as types from "./teamLogosTypes";

import { merge } from "lib";

export const initState = {};

const teamLogosReducers = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_LOGOS_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;
      return merge({}, state, entities.teamLogo);
    }

    default:
      return state;
  }
};

export default teamLogosReducers;
