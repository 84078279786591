import { createSelector } from "reselect";
import { isEmpty, some, isNil, getPath } from "lib/lodash";
import { apiDesignsResourceSelector } from "state/api/designs/apiDesignsSelectors";
import { pendingApprovalDesignsApiSelector } from "state/api/designs/personal/approvals/pendingApproval/pendingApprovalApiSelector";
import { pendingApprovalDesignsApiSelector as teamPendingApprovalDesignsApiSelector } from "state/api/designs/team/pendingApproval/pendingApprovalApiSelectors";
import { declinedApprovalDesignsApiSelector } from "state/api/designs/personal/approvals/declined/declinedApprovalApiSelector";
import { pendingApprovalSharedDesignsApiSelector } from "state/api/designs/personal/approvals/pendingApprovalShared/pendingApprovalSharedApiSelector";
import { declinedApprovalSharedDesignsApiSelector } from "state/api/designs/personal/approvals/declinedShared/declinedApprovalSharedApiSelector";
import { collectionApiSelector } from "state/api/collection/collectionApiSelectors";
import { teamSelector } from "state/entities/teams/teamsSelectors";
import { allDraftsInFolderIdsSelector } from "state/api/designs/apiDesignsSelectors";
import {
  catalogueTemplateSizesSelector,
  teamTemplateSizesSelector,
  templateSizesInFolderSelector,
  purchasedCatalogueSizesSelector,
  workspaceSizesWorkspaceEntitiesSelector
} from "state/entities/templateSizes/templateSizeSelectors";
import { sizesGroupedByCategoriesSelector } from "state/ui/uiSelectors";
import { userIsBrandManager } from "state/currentUser/currentUserSelectors";
import PATHS from "routes/paths";
import { getParameterByName } from "lib/queryStringUtils";

export const designEntitiesSelector = ({ state }) => state.entities.designs;

/**
 * @desc - using a design id and a redux state, retrieves the design from state that matches the design id
 * @param {object} state - a redux store state
 * @param {string} designId - the id for the design to retrieve from the redux state
 * @returns {object} - returns a design object matching the design id passed in
 */
export const designById = ({ state, designId }) =>
  state.entities.designs[designId];

// selector for all draft designs in current folder
// TODO: change draft folder structure to avoid constant folder id overwriting and refetching
export const draftsInFolderSelector = ({ state, folderId, size }) =>
  allDraftsInFolderIdsSelector({
    state,
    folderId,
    size
  }).map(designId =>
    designById({
      state,
      designId
    })
  );

export const designsForCollectionIdSelector = ({ state, collectionId }) => {
  const collection = collectionApiSelector(state)[collectionId];
  if (!collection) return [];

  // map through design ids and get entities for each
  const designs = collection.ids.map(designId =>
    designById({ state, designId })
  );

  return designs;
};

export const collectionIdsForDesignsSelector = ({ state, designIds }) => {
  if (!(designIds && Array.isArray(designIds))) return null;

  const designs = designIds.map(designId => designById({ state, designId }));

  const collectionIds = designs.map(design => design.collectionId);

  // remove duplicate collectionIds and return array
  return [...new Set(collectionIds)];
};

const getSizesForCurrentLocation = ({ state, isCatalogue, scope }) => {
  const isCatalogueFromPath = PATHS.isCatalogue(window.location.pathname);
  const isPurchasedCatalogueFromPath = PATHS.isPurchasedCatalogue(
    window.location.pathname
  );
  const folderId = getParameterByName("folderId", window.location.search);

  if (isCatalogue || isCatalogueFromPath) {
    if (folderId) {
      return templateSizesInFolderSelector({ state, folderId });
    }
    if (scope === "subscription") {
      return catalogueTemplateSizesSelector(state);
    } else {
      return teamTemplateSizesSelector(state);
    }
  }

  if (isPurchasedCatalogueFromPath) {
    return purchasedCatalogueSizesSelector(state);
  }

  return workspaceSizesWorkspaceEntitiesSelector(state);
};

// returns an array of categories with nested designs from a collection
export const designsForCollectionIdCategorisedSelector = ({
  state,
  collectionId,
  isCatalogue,
  scope
}) => {
  // get the region for the current team
  const currentTeamRegion = teamSelector({ state }).country;
  const sizes = getSizesForCurrentLocation({ state, isCatalogue, scope });
  const designs = designsForCollectionIdSelector({ state, collectionId });

  const sizesGroupedByCategory = sizesGroupedByCategoriesSelector(
    sizes,
    currentTeamRegion
  );

  const designsGroupedByCategory = sizesGroupedByCategory.map(category => {
    const designsForSize = category.sizes.map(size => {
      // get matching designs for this size
      return designs.filter(
        design => design.templateCode === size.templateCode
      );
    });
    return {
      ...category,
      designs: designsForSize.flat().filter(x => x) // NOTE: I don't think the filter is required
    };
  });

  return {
    categories: designsGroupedByCategory
      .filter(category => category.designs.length)
      .reverse(),
    sizeCount: designs.length
  };
};

const designByIdFromObject = ({ designs, designId }) => designs[designId];

const searchTermAndSizePassThrough = ({ searchTerm, size, folderId }) => ({
  searchTerm,
  size,
  folderId
});

export const userHasApprovedDrafts = state => {
  const approvedDesigns = approvedDesignsSelector(state);
  return !isEmpty(approvedDesigns);
};

export const userHasDraftsSharedWith = state => {
  return !isEmpty(sharedDesignsSelector(state));
};

const approvalsLocationSelector = (state, size, type) =>
  state.api.designs.personal.approvals[type];

const processApprovalsSelector = (stateLocation, size, designs) => {
  let pages = {};
  if (stateLocation.sizes) {
    pages = stateLocation.sizes[size].pages;
  } else {
    pages = stateLocation.pages;
  }

  if (isEmpty(pages)) {
    return null;
  }

  if (!pages[1].ids) {
    return null;
  }

  const designsIds = Object.keys(pages)
    .sort((a, b) => a - b)
    .reduce((acc, page) => acc.concat(pages[page].ids), []);

  return designsIds
    .map(designId => designByIdFromObject({ designs, designId }))
    .filter(design => !isNil(design));
};

export const approvalsSelector = createSelector(
  [
    approvalsLocationSelector,
    (state, size) => searchTermAndSizePassThrough({ size }),
    state => designEntitiesSelector({ state })
  ],
  processApprovalsSelector
);

export const sharedDesignsApprovedSelector = createSelector(
  [
    state => approvalsLocationSelector(state, "all", "approvedShared"),
    () => "all",
    state => designEntitiesSelector({ state })
  ],
  processApprovalsSelector
);

export const sharedDesignsPendingApprovalSelector = createSelector(
  [
    state => approvalsLocationSelector(state, "all", "pendingApprovalShared"),
    () => "all",
    state => designEntitiesSelector({ state })
  ],
  processApprovalsSelector
);

export const sharedDesignsDeclinedSelector = createSelector(
  [
    state => approvalsLocationSelector(state, "all", "declinedShared"),
    () => "all",
    state => designEntitiesSelector({ state })
  ],
  processApprovalsSelector
);

const processUserHasApprovalsSharedWith = (
  sharedDesignsPendingApproval,
  sharedDesignsDeclined
) => {
  return (
    !isEmpty(sharedDesignsPendingApproval) || !isEmpty(sharedDesignsDeclined)
  );
};

export const userHasApprovalsSharedWith = createSelector(
  [sharedDesignsPendingApprovalSelector, sharedDesignsDeclinedSelector],
  processUserHasApprovalsSharedWith
);

export const userHasDesignSharedWith = state => {
  return userHasDraftsSharedWith(state) || userHasApprovalsSharedWith(state);
};

export const teamHasApprovedDrafts = state => {
  const approvedDesigns = teamApprovedDesignsSelector(state);
  return !isEmpty(approvedDesigns);
};

const approvedDesignSelectorBySize = (state, size = "all") =>
  state.api.designs.personal.approvals.approved.sizes[size];

export const processApprovedDesignsSelector = (approvedDesigns, designs) => {
  const { pages = {} } = approvedDesigns;

  if (isEmpty(pages) || !pages[1].ids) {
    return null;
  }

  const designsIds = Object.keys(pages)
    .sort((a, b) => a - b)
    .reduce((acc, page) => acc.concat(pages[page].ids), []);

  return designsIds
    .map(designId => designByIdFromObject({ designs, designId }))
    .filter(design => !isNil(design));
};

export const approvedDesignsSelector = createSelector(
  [approvedDesignSelectorBySize, state => designEntitiesSelector({ state })],
  processApprovedDesignsSelector
);

const teamApprovedDesignsBySizeSelector = (state, size = "all") =>
  state.api.designs.team.approved.sizes[size];

export const processTeamApprovedDesignsSelector = (
  teamApprovedDesigns,
  designs
) => {
  const { pages = {} } = teamApprovedDesigns;

  if (isEmpty(pages) || !pages[1].ids) {
    return null;
  }

  const designsIds = Object.keys(pages)
    .sort((a, b) => a - b)
    .reduce((acc, page) => acc.concat(pages[page].ids), []);

  return designsIds
    .map(designId => designByIdFromObject({ designs, designId }))
    .filter(design => !isNil(design));
};

export const teamApprovedDesignsSelector = createSelector(
  [
    teamApprovedDesignsBySizeSelector,
    state => designEntitiesSelector({ state })
  ],
  processTeamApprovedDesignsSelector
);

const sharedDesignsBySizeSelector = (state, size = "all") =>
  state.api.designs.personal.shared.sizes[size];

const processSharedDesignsSelector = (sharedDesigns, designs) => {
  const { pages = {} } = sharedDesigns;

  if (isEmpty(pages)) {
    return null;
  }

  if (!pages[1].ids) {
    return null;
  }

  const designsIds = Object.keys(pages)
    .sort((a, b) => a - b)
    .reduce((acc, page) => acc.concat(pages[page].ids), []);

  return designsIds
    .map(designId => designByIdFromObject({ designs, designId }))
    .filter(design => !isNil(design));
};

export const sharedDesignsSelector = createSelector(
  [sharedDesignsBySizeSelector, state => designEntitiesSelector({ state })],
  processSharedDesignsSelector
);

const processDesignSelection = ({ pages }, designs) => {
  if (isEmpty(pages)) {
    return null;
  }

  if (!pages[1].ids) {
    return null;
  }

  const designsIds = Object.keys(pages)
    .sort((a, b) => a - b)
    .reduce((acc, page) => acc.concat(pages[page].ids), []);

  const designsWithoutRepeat = [...new Set(designsIds.reverse())];

  /* return the designs and the number of pages */
  return {
    designs: designsWithoutRepeat
      .reverse()
      .map(designId => designs[designId])
      .filter(design => design),
    pageCount: Object.keys(pages).length
  };
};

// collects the resource and designs from redux state
// when they have changed runs processing on them, otherwise returns the previously calculated response
export const designsSelector = createSelector(
  [apiDesignsResourceSelector, designEntitiesSelector],
  processDesignSelection
);

const cataloguePagesSelector = state => state.api.designs.team.query.pages;
const workspacePagesSelector = state => state.api.designs.personal.query.pages;
const catalogueEasilPagesSelector = state =>
  state.api.designs.team.layouts.easil.pages;
const catalogueTeamPagesSelector = state =>
  state.api.designs.team.layouts.team.pages;

export const processIsDraftOrCatalogueFetching = (
  cataloguePages,
  workspacePages,
  catalogueEasilPages,
  catalogueTeamPages
) => {
  const isCatalogueFetching = some(cataloguePages, {
    isFetching: true
  });
  const isWorkspaceFetching = some(workspacePages, {
    isFetching: true
  });
  const isCatalogueEasilFetching = some(catalogueEasilPages, {
    isFetching: true
  });
  const isCatalogueTeamFetching = some(catalogueTeamPages, {
    isFetching: true
  });
  return (
    isCatalogueFetching ||
    isWorkspaceFetching ||
    isCatalogueEasilFetching ||
    isCatalogueTeamFetching
  );
};

export const isDraftOrCatalogueFetching = createSelector(
  [
    cataloguePagesSelector,
    workspacePagesSelector,
    catalogueEasilPagesSelector,
    catalogueTeamPagesSelector
  ],
  processIsDraftOrCatalogueFetching
);

const designsSearchPagesSelector = ({ state, size, searchTerm }) => {
  if (isEmpty(searchTerm)) {
    return null;
  }

  const result =
    state.api.designs.personal.search.results[`${searchTerm}-${size}`];

  return (result || {}).pages;
};

const designsPagesSelector = ({ state, searchTerm }) =>
  isEmpty(searchTerm) ? state.api.designs.personal.query.pages : null;

export const processDraftDesignsByIdAndSize = (
  designsSearchPages,
  designsPages,
  designs,
  { searchTerm, size }
) => {
  const pagesMap = isEmpty(searchTerm) ? designsPages : designsSearchPages;
  const pages = Object.values(pagesMap || {});

  const filter = design =>
    design && design.status === "DRAFT" && design.templateCode === size;

  return {
    designs: pages
      .reduce((arr, { ids }) => [...arr, ...(ids || [])], [])
      .map(id => designs[id])
      .filter(filter),
    pageCount: pages.length
  };
};

export const draftDesignsByIdAndSize = createSelector(
  [
    designsSearchPagesSelector,
    designsPagesSelector,
    designEntitiesSelector,
    searchTermAndSizePassThrough
  ],
  processDraftDesignsByIdAndSize
);

const getQueryPages = ({ state, size, searchTerm, scope, folderId }) => {
  if (!isEmpty(searchTerm)) {
    return getPath(
      state,
      `api.designs.team.search.results.${searchTerm}-${size}.pages`,
      {}
    );
  }
  if (folderId) {
    return getPath(
      state,
      `api.designs.team.folders.${folderId}.sizes.${size}.pages`,
      {}
    );
  }
  if (scope) {
    return getPath(state, `api.designs.team.layouts.${scope}.pages`, {});
  }
  return getPath(state, `api.designs.team.query.pages`, {});
};

export const processMasterDesignsByIdAndSize = (
  queryPages,
  designs,
  { size }
) => {
  const pages = Object.values(queryPages || {});

  const filter = design =>
    design &&
    design.status === "MASTER" &&
    (!size || design.templateCode === size);

  return {
    designs: pages
      .reduce((arr, { ids }) => [...arr, ...(ids || [])], [])
      .map(id => designs[id])
      .filter(filter),
    pageCount: pages.length
  };
};

export const masterDesignsByIdAndSize = createSelector(
  [getQueryPages, designEntitiesSelector, searchTermAndSizePassThrough],
  processMasterDesignsByIdAndSize
);

export const processDesignsByCollectionId = (designs, collectionId) => {
  return Object.keys(designs)
    .map(id => designs[id])
    .filter(
      design =>
        design.collectionId === collectionId && design.archived === false
    );
};

const collectionIdPassThrough = ({ collectionId }) => collectionId;

export const designsByCollectionId = createSelector(
  [designEntitiesSelector, collectionIdPassThrough],
  processDesignsByCollectionId
);

export const processDesignsPendingApproval = (
  pendingApprovalApiState,
  designs
) => {
  const pendingApprovalIds = [].concat(
    ...Object.values(pendingApprovalApiState.pages).map(page => page.ids)
  );
  const designEntities = pendingApprovalIds.map(id =>
    designByIdFromObject({ designs, designId: id })
  );
  return designEntities;
};

export const designsPendingApproval = createSelector(
  [
    ({ state }) => pendingApprovalDesignsApiSelector(state),
    designEntitiesSelector
  ],
  processDesignsPendingApproval
);

const processTeamDesignsPendingApproval = (
  pendingApprovalApiState,
  designs
) => {
  const pendingApprovalIds = [].concat(
    ...Object.values(pendingApprovalApiState.pages).map(page => page.ids)
  );
  const designEntities = pendingApprovalIds.map(id =>
    designByIdFromObject({ designs, designId: id })
  );
  return designEntities;
};

export const teamDesignsPendingApproval = createSelector(
  [
    ({ state }) => teamPendingApprovalDesignsApiSelector(state),
    designEntitiesSelector
  ],
  processTeamDesignsPendingApproval
);

const processDesignsDeclined = (declinedApiState, designs) => {
  const declinedIds = [].concat(
    ...Object.values(declinedApiState.pages).map(page => page.ids)
  );
  const designEntities = declinedIds.map(id =>
    designByIdFromObject({ designs, designId: id })
  );
  return designEntities;
};

export const designsDeclined = createSelector(
  [
    ({ state }) => declinedApprovalDesignsApiSelector(state),
    designEntitiesSelector
  ],
  processDesignsDeclined
);

const processDesignsPendingApprovalShared = (
  pendingApprovalApiState,
  designs
) => {
  const pendingApprovalIds = [].concat(
    ...Object.values(pendingApprovalApiState.pages).map(page => page.ids)
  );
  const designEntities = pendingApprovalIds.map(id =>
    designByIdFromObject({ designs, designId: id })
  );
  return designEntities;
};

export const designsPendingApprovalShared = createSelector(
  [
    ({ state }) => pendingApprovalSharedDesignsApiSelector(state),
    designEntitiesSelector
  ],
  processDesignsPendingApprovalShared
);

const processDesignsDeclinedShared = (declinedApiState, designs) => {
  const declinedIds = [].concat(
    ...Object.values(declinedApiState.pages).map(page => page.ids)
  );
  const designEntities = declinedIds.map(id =>
    designByIdFromObject({ designs, designId: id })
  );
  return designEntities;
};

export const designsDeclinedShared = createSelector(
  [
    ({ state }) => declinedApprovalSharedDesignsApiSelector(state),
    designEntitiesSelector,
    state => state
  ],
  processDesignsDeclinedShared
);

export const areDesignsShared = ({ state, designIds }) => {
  if (!designIds || !Array.isArray(designIds) || !designIds.length)
    return false;

  const designsData = designIds.map(id => designById({ state, designId: id }));

  return designsData.some(design => design.isShared);
};

export const isUsersDesign = (state, designId) => {
  const design = designById({ state, designId });
  const currentUserId = state.currentUser.id;

  return design.userId === currentUserId;
};

export const isBMReviewingDesignSelector = (state, designId) => {
  const isBrandManager = userIsBrandManager(state);
  const design = designById({ state, designId });
  const isCurrentUsersDesign = isUsersDesign(state, designId);

  return (
    ["PENDING_APPROVAL", "APPROVED"].includes(design.status) &&
    isBrandManager &&
    !isCurrentUsersDesign
  );
};
