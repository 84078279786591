import React from "react";
import Element from "state/ui/editor/elements/Element";
import { getElementsBox } from "lib/elements";
import { uniq } from "lib/lodash";
import { uuid } from "lib/uuid";
import { insertItem, moveItem, removeItem } from "lib/array/array";

class Group extends Element {
  static RESTRICTIONS = [];

  hasAnyRestriction() {
    const restrictions = Object.keys(this.getRestrictionsMap() || {});

    return (
      restrictions.length !== 0 ||
      this.getElements().some(
        nestedElement => nestedElement.restrictions.length !== 0
      )
    );
  }

  hasAllRestrictions() {
    const restrictions = Object.keys(this.getRestrictionsMap() || {});

    return (
      restrictions.length !== 0 ||
      this.getElements().every(nestedElement =>
        nestedElement.hasAllRestrictions()
      )
    );
  }

  isResizable() {
    return this.getElements().every(nestedElement =>
      nestedElement.isResizable()
    );
  }

  isRemovable() {
    const restrictions = this.getRestrictionsMap();
    return !restrictions.removable;
  }

  render(props) {
    if (this.isHidden) {
      return;
    }

    const { elementPreviews, selectedItems, highlightedElementIds } = props;

    const groupSelected = selectedItems.find(i => i.itemId === this.uniqueId);

    return (
      <div
        id={`GROUP-${this.uniqueId}`}
        key={this.uniqueId}
        style={{
          zIndex: 2,
          position: "absolute",
          opacity: this.opacity,
          ...(elementPreviews || {})[this.uniqueId],
          ...(groupSelected || {}).preview
        }}
      >
        {this.elementsOrder.map(elementId => {
          const elementSelected = selectedItems.find(
            i => i.itemId === elementId
          );

          const isHighlighted =
            !highlightedElementIds || highlightedElementIds.includes(elementId);

          return this.getElement(elementId).render({
            groupId: this.uniqueId,
            ...props,
            ...(elementPreviews || {})[elementId],
            ...(elementSelected || {}).preview,
            isHighlighted
          });
        })}
      </div>
    );
  }

  clone(newElementId = uuid()) {
    const clonedNestedElements = this.elementsOrder.map(nestedElementId => {
      const clonedElement = this.getElement(nestedElementId).clone()[0];
      clonedElement.groupId = newElementId;
      return clonedElement;
    });

    const clonedElement = new this.constructor(this);

    clonedElement.uniqueId = newElementId;
    clonedElement.id = newElementId;
    clonedElement.elementsOrder = clonedNestedElements.map(
      nestedElement => nestedElement.uniqueId
    );

    return [clonedElement, ...clonedNestedElements];
  }

  getLayerTree() {
    const subElementsOrderReversed = this.elementsOrder.slice().reverse();

    const restrictions = this.getRestrictionsMap();

    return {
      type: this.type,
      name: this.name,
      uniqueId: this.uniqueId,
      isHidden: this.isHidden,
      canToggleVisibility: !restrictions.visibility,
      subElements: subElementsOrderReversed.map((nestedElementId, index) => {
        const nestedElement = this.getElement(nestedElementId);

        return nestedElement.getLayerTree({
          groupId: this.uniqueId
        });
      })
    };
  }

  getRestrictionsMap() {
    const restrictions = {};

    this.constructor.RESTRICTIONS.forEach(
      restriction => (restrictions[restriction] = false)
    );

    // form a unique array of child restrictions
    const childRestrictions = uniq(
      this.getElements()
        .map(nestedElement => nestedElement.restrictions)
        .reduce(
          (restrictionList, nestedRestrictions) => [
            ...restrictionList,
            ...nestedRestrictions
          ],
          []
        )
    );

    // add any child restrictions found to the list of restrictions for the group
    childRestrictions.forEach(restrictionKey => {
      restrictions[restrictionKey] = true;
    });

    return restrictions;
  }

  toggleRestriction({ attribute, value }) {
    let restrictionsSet;

    switch (true) {
      case attribute === "ALL" && value:
        restrictionsSet = this.constructor.RESTRICTIONS;
        break;

      case attribute === "ALL" && !value:
        restrictionsSet = [];
        break;

      case value:
        restrictionsSet = new Set(this.restrictions).add(attribute);
        break;

      default:
        restrictionsSet = new Set(this.restrictions);
        restrictionsSet.delete(attribute);
    }

    return new this.constructor({
      ...this,
      restrictions: Array.from(restrictionsSet)
    });
  }

  deleteItem(itemId, optionalOrder) {
    const elementsOrder = optionalOrder || this.elementsOrder;

    const elementIndex = elementsOrder.indexOf(itemId);
    const elementsUpdated = removeItem(elementsOrder, elementIndex);

    return new this.constructor({
      ...this,
      elementsOrder: elementsUpdated
    });
  }

  insertItem(itemId, index = 0, optionalOrder) {
    const elementsOrder = optionalOrder || this.elementsOrder;

    const groupElementsOrderUpdated = insertItem(
      elementsOrder.slice().reverse(),
      index,
      itemId
    ).reverse();

    return new this.constructor({
      ...this,
      elementsOrder: groupElementsOrderUpdated
    });
  }

  moveItem(fromIndex, toIndex, optionalOrder) {
    const elementsOrder = optionalOrder || this.elementsOrder;
    /* if the moved element is above the destiny position, the index has to be corrected
       to account for the element once it is remove from there */
    const toIndexCorrected = fromIndex < toIndex ? toIndex - 1 : toIndex;

    const groupElementsOrderUpdated = moveItem(
      elementsOrder.slice().reverse(),
      fromIndex,
      toIndexCorrected
    ).reverse();

    return new this.constructor({
      ...this,
      elementsOrder: groupElementsOrderUpdated
    });
  }

  getElements() {
    return this.elementsOrder.map(nestedElementId =>
      this.getElement(nestedElementId)
    );
  }

  getSizeAndPosition() {
    const nestedElements = this.elementsOrder
      .map(nestedElementId => this.getElement(nestedElementId))
      .filter(x => x); // in case an element isn't in the page (can happen with copying issues)

    return getElementsBox(nestedElements);
  }

  getSnapPoints() {
    const boundingBox = this.getSizeAndPosition(this);

    const elementSnapXCoodinates = {
      left: boundingBox.left, // left
      center: boundingBox.left + boundingBox.width / 2, // center
      right: boundingBox.left + boundingBox.width // right
    };

    const elementSnapYCoodinates = {
      top: boundingBox.top, // top
      center: boundingBox.top + boundingBox.height / 2, // center
      bottom: boundingBox.top + boundingBox.height // bottom
    };

    return {
      x: elementSnapXCoodinates,
      y: elementSnapYCoodinates
    };
  }

  getCenter() {
    const box = this.getSizeAndPosition();

    return {
      x: box.top - box.height / 2,
      y: box.left + box.width / 2
    };
  }

  /* rotate(angle){
   *
   *   const nestedElements = this.elementsOrder.map(
   *     nestedElementId => this.getElement(nestedElementId).clone()
   *   );

   *   const groupBox = getElementsBox(nestedElements);

   *   const clonedElement = new this.constructor(this);

   *   clonedElement.uniqueId = newElementId;
   *   clonedElement.elementsOrder = clonedNestedElements.map(
   *     nestedElement => nestedElement.uniqueId
   *   );

   *   return [clonedElement, ...clonedNestedElements];

   * }*/

  resize({ scale, anchorPoint, offset, zoom }) {
    return this.elementsOrder.map(elementId =>
      this.getElement(elementId).resize({ scale, anchorPoint, offset, zoom })
    );
  }
}

export default Group;
