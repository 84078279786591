import React from "react";
import NoComponent from "views/components/NoComponent";
import TableRow from "./TableRow";
import { Provider } from "react-redux";
import store from "state/store";

export const TableRowWithoutDropzones = props => {
  return (
    <Provider store={store}>
      <TableRow {...props} DropzoneElement={NoComponent} />
    </Provider>
  );
};

export default TableRowWithoutDropzones;
