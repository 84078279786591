import { noop } from "lib";
import * as types from "./creditsApiTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";

export const fetchCreditsApiCall = ({ onSuccess = noop } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = state.ui.currentTeam.id;

  dispatch({
    [CALL_API]: {
      method: "GET",
      types: [
        types.CREDITS_REQUEST,
        types.CREDITS_REQUEST_SUCCESS,
        types.CREDITS_REQUEST_FAILURE
      ],
      service: SERVICES.USER,
      endpoint: `/teams/${teamId}/users/${userId}/credits/current`,
      schema: schemas.CREDITS,
      onSuccess: onSuccess
    }
  });
};
