import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TripleSlidersIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color || easilGrayXDark}
        d="M6 6c0 .35.06.687.17 1H5a1 1 0 010-2l1.171-.002C6.061 5.311 6 5.648 6 6zm5 0c0 .364-.097.706-.268 1H7.268a1.993 1.993 0 010-2.001h3.464c.17.294.268.636.268 1.001zm8-1a1 1 0 010 2h-7.17a3.013 3.013 0 00-.001-2.002L19 5zm-7 7c0 .35.06.687.17 1H5a1 1 0 010-2l7.171-.002c-.11.313-.171.65-.171 1.002zm5 0c0 .364-.097.706-.268 1h-3.464a1.993 1.993 0 010-2.001h3.464c.17.294.268.636.268 1.001zm2-1a1 1 0 010 2h-1.17a3.013 3.013 0 00-.001-2.002L19 11z"
      />
      <circle cx="9" cy="6" r="2" fill={color || easilGrayXDark} />
      <circle cx="15" cy="12" r="2" fill={color || easilGrayXDark} />
      <path
        fill={color || easilGrayXDark}
        d="M9 18c0 .35.06.687.17 1H5a1 1 0 010-2l4.171-.002C9.061 17.311 9 17.648 9 18zm5 0c0 .364-.097.706-.268 1h-3.464a1.993 1.993 0 010-2.001h3.464c.17.294.268.636.268 1.001zm5-1a1 1 0 010 2h-4.17a3.013 3.013 0 00-.001-2.002L19 17z"
      />
      <circle cx="12" cy="18" r="2" fill={color || easilGrayXDark} />
    </g>
  </svg>
);
TripleSlidersIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TripleSlidersIcon;
