import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import SubPageLoading from "views/pages/SubPageLoading";
import SubscriptionsPageSubHeaderContainer from "views/containers/SubscriptionsPageSubHeaderContainer/SubscriptionsPageSubHeaderContainer";
const SubscriptionsPage = lazy(() => import("./SubscriptionsPage"));

export const SubscriptionsPageLazy = props => (
  <Suspense fallback={<SubPageLoading />}>
    <SubscriptionsPage {...props} />
  </Suspense>
);
SubscriptionsPageLazy.displayName = "SubscriptionsPage";

export default WithHeader(
  SubscriptionsPageLazy,
  { withGutters: false },
  SubscriptionsPageSubHeaderContainer
);
