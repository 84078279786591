import * as types from "./invoicesTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import { invoicesApiPageSelector } from "state/api/invoices/invoicesApiSelectors";
import { initState as initApiState } from "state/api/invoices/invoicesApiReducers";
import { downloadDesign as downloadFromPath } from "lib";

const shouldFetchInvoices = ({ state, page }) => {
  const invoicesApiAtPage = invoicesApiPageSelector({ state, page });

  if (!invoicesApiAtPage) return true;

  if (invoicesApiAtPage.isFetching) return false;

  return true;
};

export const fetchInvoicesIfNeeded = ({ page = 1 } = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchInvoices({ state: getState(), page })) {
    dispatch(fetchInvoices({ page }));
  }
};

export const fetchInvoices = ({ page = 1 } = {}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.SUBSCRIPTION,
      types: [
        types.FETCH_INVOICES_REQUEST,
        types.FETCH_INVOICES_REQUEST_SUCCESS,
        types.FETCH_INVOICES_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/invoices`,
      request: {
        page: page,
        pageSize: initApiState.pageSize
      },
      schema: schemas.INVOICES,
      onSuccess: response => {
        if (
          response.entities &&
          Object.keys(response.entities.invoices).length ===
            initApiState.pageSize
        ) {
          dispatch(fetchInvoices({ page: page + 1 }));
        }
      }
    }
  });
};

export const downloadAllInvoiceFiles = () => (dispatch, getState) =>
  new Promise(resolve => {
    const state = getState();
    const teamId = currentTeamIdSelector(state);
    const token = state.currentUser.token;

    fetch(`${SERVICES.SUBSCRIPTION}/teams/${teamId}/invoices/files`, {
      method: "GET",
      headers: new Headers({
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      })
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);

        downloadFromPath({
          path: url,
          isTargetBlank: true,
          downloadName: "easil-invoices.zip"
        });

        resolve();
      });
  });
