import { DragSource } from "react-dnd";
import MaskedVector from "./MaskedVector";

const MaskedVectorDraggableSource = {
  beginDrag(props, monitor, component) {
    props.startVectorCropMoving();

    return {};
  },
  endDrag(props, monitor) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

    props.finishVectorCropMoving({ differenceFromInitialOffset });
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(
  "VECTOR_CROP",
  MaskedVectorDraggableSource,
  collect
)(MaskedVector);
