import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const ImageInEditorContext = lazy(() => import("./ImageInEditorContext"));

export const ImageInEditorContextLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <ImageInEditorContext {...props} />
  </Suspense>
);
ImageInEditorContextLazy.displayName = "ImageInEditorContext";

export default ImageInEditorContextLazy;
