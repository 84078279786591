// handles retrying for an image src and updating relative elements when successful
export const listenForImageSrc = async (src, retries = 0, fallbackImageSrc) => {
  return new Promise((resolve, reject) => {
    if (window.easil.imgListeners && window.easil.imgListeners[src]) {
      return;
    } else {
      window.easil.imgListeners = {
        ...window.easil.imgListeners,
        [src]: true
      };
    }
    loadImageSrc(src, retries, fallbackImageSrc, resolve, reject);
  });
};

export const loadImageSrc = (
  src,
  retries = 0,
  fallbackImageSrc,
  resolve,
  reject
) => {
  // break when retries reaches 0
  if (retries === 0) {
    const matchingImgs = document.querySelectorAll(`img[src="${src}"]`);
    matchingImgs.forEach(element => {
      element.src = fallbackImageSrc;
    });
    window.easil.imgListeners[src] = false;
    reject(new Error("Could not fetch image"));
    return;
  }

  // create a new img element
  const img = document.createElement("img");

  // assign an onError to retry in 500ms
  img.onerror = () => {
    setTimeout(
      () => loadImageSrc(src, retries - 1, fallbackImageSrc, resolve, reject),
      500
    );
  };

  // assign onload to refresh all img elements with the given src
  img.onload = () => {
    const matchingImgs = document.querySelectorAll(`img[src="${src}"]`);
    matchingImgs.forEach(element => {
      element.src = src;
    });
    window.easil.imgListeners[src] = false;
    resolve();
  };

  img.src = src;
};
