import React from "react";
import { Modal, Loading } from "views/components";
import CaretSlimIcon from "views/components/icons/CaretSlimIcon";
import { isMediaVideoFormat } from "lib/mediaSourceHelpers";
import style from "./style.module.css";
import PropTypes from "prop-types";
import { isEmpty } from "lib";
import InfoPanelTop from "./InfoPanelTop";
import InfoPanelBottom from "./InfoPanelBottom";

const previewFrameHeight = 520;
const previewFrameWidth = 440;

const getAspectRatios = design => ({
  widthRatio: previewFrameWidth / design.width,
  heightRatio: previewFrameHeight / design.height
});

const getImagePreviewStyles = (design, isImageReady) => {
  if (!design) return {};
  const { widthRatio, heightRatio } = getAspectRatios(design);

  const isWidthDifferenceGreater = widthRatio > heightRatio;

  return {
    height: isWidthDifferenceGreater ? "520px" : "auto",
    width: isWidthDifferenceGreater ? "auto" : "440px",
    opacity: isImageReady ? 1 : 0
  };
};

const getImageErrorStyles = design => {
  if (!design) return {};
  const { widthRatio, heightRatio } = getAspectRatios(design);

  const isWidthDifferenceGreater = widthRatio > heightRatio;

  return {
    height: isWidthDifferenceGreater
      ? "520px"
      : `${design.height * widthRatio}px`,
    width: isWidthDifferenceGreater
      ? `${design.width * heightRatio}px`
      : "440px",
    backgroundColor: "white",
    position: "absolute"
  };
};

const defaultState = {
  previewDesign: null,
  currentHoverTarget: null,
  isImageReady: true
};

/**
 * @desc  This is an abstract component template.
 * NOTE: extension of this component is necessary and this component should NEVER be used without extension
 * NOTE: Do not use as a standard - React Composition should be preferred over Inheritance
 */
class CollectionPreviewModalTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.isHighlighted = this.isHighlighted.bind(this);
    this.handleChangePreview = this.handleChangePreview.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
    this.handleImageLoad = this.handleImageLoad.bind(this);

    // stub member functions to be implemented in extension components
    this.getButtons = this.getButtons.bind(this);
    this.ButtonBefore = this.ButtonBefore.bind(this);
    this.handleOpenDesign = this.handleOpenDesign.bind(this);
    this.handleOpenCollection = this.handleOpenCollection.bind(this);
    this.handleConvertCollectionToMaster = this.handleConvertCollectionToMaster.bind(
      this
    );
    this.handleConvertDesignToMaster = this.handleConvertDesignToMaster.bind(
      this
    );
    this.videoRef = React.createRef();
    this.handleStepPreview = this.handleStepPreview.bind(this);

    this.state = defaultState;
  }

  componentDidMount() {
    this.setState({
      previewDesign: this.props.selectedDesign
    });
  }

  componentDidUpdate(prevProps) {
    // handle update of empty preview design when prop selected design has been assigned
    if (
      isEmpty(this.state.previewDesign) &&
      isEmpty(prevProps.selectedDesign) &&
      !isEmpty(this.props.selectedDesign)
    ) {
      this.setState({
        previewDesign: this.props.selectedDesign
      });
    }
  }

  handleMouseEnter(hoverTarget) {
    this.setState({
      currentHoverTarget: hoverTarget
    });
  }

  handleMouseLeave() {
    this.setState({
      currentHoverTarget: null
    });
  }

  // returns a boolean describing the highlight status of the given design
  isHighlighted(design) {
    const { selectedDesign } = this.props;

    const { currentHoverTarget, previewDesign } = this.state;

    const designToDisplay = previewDesign || selectedDesign;

    // previewed design should always highlight
    if (
      !currentHoverTarget &&
      designToDisplay &&
      designToDisplay.id === design.id
    ) {
      return true;
    }

    // highlight designs based on button hover
    switch (currentHoverTarget) {
      case "collection": {
        return true;
      }
      case "design": {
        return design.id === designToDisplay.id;
      }
      default: {
        return false;
      }
    }
  }

  // sets the currently selected previewDesign, clears when attempting to select already selected preview
  handleChangePreview(design) {
    const { previewDesign } = this.state;

    let newPreviewDesign = design;

    if (previewDesign && previewDesign.id === design.id) {
      // unset preview if clicking again
      newPreviewDesign = null;
    }
    if (isMediaVideoFormat(design.thumbnailUrl)) {
      this.setState({
        previewDesign: newPreviewDesign
      });
      this.videoRef.current?.load();
    } else {
      this.setState({
        isImageReady: false,
        previewDesign: newPreviewDesign
      });
    }
  }

  handleStepPreview(indexAdjustment) {
    const { previewDesign: currentPreviewDesign = {} } = this.state;

    let newPreview;

    // return unique array of collection designs, removing duplicate sizes in
    // multiple categories
    const collectionDesigns = this.props.collectionDesignsCategorised.reduce(
      (designAccumulator, currentCategory) => {
        return [...new Set([...designAccumulator, ...currentCategory.designs])];
      },
      []
    );

    const currentPreviewIndex = collectionDesigns.findIndex(
      design => design.id === currentPreviewDesign.id
    );

    const nextPreviewIndex = currentPreviewIndex + indexAdjustment;

    if (
      currentPreviewIndex === -1 ||
      nextPreviewIndex === collectionDesigns.length
    ) {
      newPreview = collectionDesigns[0];
    } else if (nextPreviewIndex < 0) {
      newPreview = collectionDesigns[collectionDesigns.length - 1];
    } else {
      newPreview = collectionDesigns[nextPreviewIndex];
    }

    this.handleChangePreview(newPreview);
  }

  handleCloseModal() {
    // when we close the modal we want to clear the selections
    this.setState(defaultState);
    this.props.onCloseModal();
  }

  handleImageError() {
    this.setState({
      isImageReady: false
    });
  }

  handleImageLoad() {
    this.setState({
      isImageReady: true
    });
  }

  //ABSTRACT METHODS START
  handleOpenDesign() {
    // to be implemented by extending component
    throw new Error("Abstract method handleOpenDesign not implemented");
  }

  handleOpenCollection() {
    // to be implemented by extending component
    throw new Error("Abstract method handleOpenCollection not implemented");
  }

  handleConvertCollectionToMaster() {
    // to be implemented by extending component
    throw new Error(
      "Abstract method handleConvertCollectionToMaster not implemented"
    );
  }

  handleConvertDesignToMaster() {
    // to be implemented by extending component
    throw new Error(
      "Abstract method handleConvertDesignToMaster not implemented"
    );
  }

  getButtons() {
    // to be implemented by extending component
    throw new Error("Abstract method getButtons not implemented");
  }

  ButtonDivider() {
    // to be implemented by extending component
  }

  ButtonBefore() {
    // to be implemented by extending component
  }

  ButtonAfter() {
    // to be implemented by extending component
  }
  //ABSTRACT METHODS END

  render() {
    const {
      isOpen,
      collectionDesignsCategorised,
      selectedDesign,
      isLoading,
      sizeCount,
      isPurchaseCollectionModal
    } = this.props;

    const { previewDesign, isImageReady } = this.state;

    const designToDisplay = previewDesign || selectedDesign;

    const isCollection = sizeCount > 1;

    return (
      <Modal
        className={style.collectionPreviewModal}
        isOpen={isOpen}
        onRequestClose={this.handleCloseModal}
        hasHeader={false}
      >
        {isOpen && (
          <div className={style.collectionPreviewModalContent}>
            {isLoading ? (
              <div className={style.loadingWrapper}>
                <Loading />
              </div>
            ) : (
              <>
                {/* left preview section */}
                <div className={style.designPreviewWindow}>
                  {isMediaVideoFormat(designToDisplay.thumbnailUrl) ? (
                    <video
                      style={getImagePreviewStyles(
                        designToDisplay,
                        isImageReady
                      )}
                      muted
                      loop
                      autoPlay={true}
                      ref={this.videoRef}
                    >
                      <source
                        src={designToDisplay.thumbnailUrl}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <img
                      style={getImagePreviewStyles(
                        designToDisplay,
                        isImageReady
                      )}
                      src={designToDisplay.thumbnailUrl}
                      alt={`${designToDisplay.title} - ${designToDisplay.templateSize}`}
                      onError={this.handleImageError}
                      onLoad={this.handleImageLoad}
                    />
                  )}
                  {!isImageReady && (
                    <div style={getImageErrorStyles(designToDisplay)} />
                  )}
                  {isCollection && (
                    <div className={style.previewOverlay}>
                      <div className={style.relativeWrapper}>
                        <div
                          className={style.nextButton}
                          onClick={() => this.handleStepPreview(1)}
                        >
                          <CaretSlimIcon
                            color="#ffffff"
                            direction="right"
                            size="14"
                          />
                        </div>
                        <div
                          className={style.previousButton}
                          onClick={() => this.handleStepPreview(-1)}
                        >
                          <CaretSlimIcon
                            color="#ffffff"
                            direction="left"
                            size="14"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* Right information section */}
                <div className={style.infoPanel}>
                  <InfoPanelTop
                    selectedDesign={designToDisplay}
                    handleCloseModal={this.handleCloseModal}
                  />
                  <InfoPanelBottom
                    isCollection={isCollection}
                    collectionDesignsCategorised={collectionDesignsCategorised}
                    handleMouseEnter={this.handleMouseEnter}
                    handleMouseLeave={this.handleMouseLeave}
                    handleOpenDesign={this.handleOpenDesign}
                    handleOpenCollection={this.handleOpenCollection}
                    isHighlighted={this.isHighlighted}
                    handleChangePreview={this.handleChangePreview}
                    buttons={this.getButtons()}
                    isPurchaseCollectionModal={isPurchaseCollectionModal}
                    ButtonDivider={this.ButtonDivider}
                    ButtonBefore={this.ButtonBefore}
                    ButtonAfter={this.ButtonAfter}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
    );
  }
}

CollectionPreviewModalTemplate.displayName = "CollectionPreviewModalTemplate";

CollectionPreviewModalTemplate.propTypes = {
  isOpen: PropTypes.bool
};

export default CollectionPreviewModalTemplate;
