import React from "react";
import ImageJaggedMountainIcon from "views/components/icons/ImageJaggedMountainIcon";
import PadlockCurvedIcon from "views/components/icons/PadlockCurvedIcon";
import CrossedCircleIcon from "views/components/icons/CrossedCircleIcon";
import VideoIcon from "views/components/icons/VideoIcon";
import { noop } from "lib";
import { preventDefaultAndDoNotPropagate } from "lib/event/event";
import style from "./style.module.css";
import {
  DEFAULT_ACCEPTED_STATIC_FILE_TYPES,
  DEFAULT_ACCEPTED_ANIMATED_FILE_TYPES
} from "lib/constants";

const defaultAcceptedFileTypes = [
  ...DEFAULT_ACCEPTED_STATIC_FILE_TYPES,
  ...DEFAULT_ACCEPTED_ANIMATED_FILE_TYPES
];

export class FileDragAndDrop extends React.Component {
  constructor(props) {
    super(props);

    this.dropRef = React.createRef();
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.isValidFilesIncluded = this.isValidFilesIncluded.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.renderOverlayLabel = this.renderOverlayLabel.bind(this);
    this.getIncomingFileLabel = this.getIncomingFileLabel.bind(this);

    this.state = {
      dragging: false,
      isUploading: false,
      acceptedFileTypes: props.acceptedFileTypes || defaultAcceptedFileTypes,
      isPassThrough: false,
      isDroppable: false,
      lastBodyDragOver: null,
      isValidFile: true,
      incomingFileTypes: null
    };
  }

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
    document.body.addEventListener("dragover", this.handleDragOver);
    document.addEventListener("mouseout", this.handleMouseLeave);
  }

  componentDidUpdate(_prevProps, prevState) {
    const { handleIsDragging = noop } = this.props;
    if (prevState.dragging !== this.state.dragging) {
      handleIsDragging(this.state.dragging);
    }

    // remove video upload option for basic subscription
    if (
      this.props.isBasicSubscription &&
      this.state.acceptedFileTypes.includes("video/mp4")
    ) {
      this.setState({
        acceptedFileTypes: this.state.acceptedFileTypes.filter(
          type => type !== "video/mp4"
        )
      });
    }
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
    document.body.removeEventListener("dragover", this.handleDragOver);
    document.removeEventListener("mouseout", this.handleMouseLeave);
  }

  handleMouseLeave(event) {
    if (!this.state.dragging) return;

    const target = event.toElement || event.relatedTarget;
    if (!target || target.nodeName === "HTML") {
      // apply on next tick so we don't get drag events firing after this
      setTimeout(
        () =>
          this.setState({
            isDroppable: false,
            dragging: false
          }),
        0
      );
    }
  }

  isValidFilesIncluded(event) {
    const { acceptedFileTypes } = this.state;

    return (
      event.dataTransfer.items &&
      event.dataTransfer.items.length > 0 &&
      event.dataTransfer.types.includes("Files") &&
      Array.from(event.dataTransfer.items).filter(file =>
        acceptedFileTypes.includes(file.type)
      ).length > 0 &&
      // if there are files we want to ensure they are valid
      ((event.dataTransfer.files &&
        event.dataTransfer.files.length > 0 &&
        Array.from(event.dataTransfer.files).filter(file =>
          acceptedFileTypes.includes(file.type)
        ).length > 0) ||
        !event.dataTransfer.files ||
        !event.dataTransfer.files.length > 0)
    );
  }

  handleDragOver(event) {
    const { isUsingDirectParent } = this.props;

    const div = isUsingDirectParent
      ? this.dropRef.current.parentElement
      : this.dropRef.current.parentElement.parentElement;

    if (!event.dataTransfer.types.includes("Files")) {
      return;
    }

    if (this.isValidFilesIncluded(event)) {
      const incomingFileTypes = Array.from(event.dataTransfer.items).map(
        item => item.type
      );
      this.setState({ isValidFile: true, incomingFileTypes });
    } else {
      this.setState({ isValidFile: false });
    }

    if (div.contains(event.target) && !this.state.dragging) {
      this.setState({
        isDroppable: true,
        dragging: true
      });
    }

    if (!div.contains(event.target) && this.state.dragging) {
      this.setState({
        isDroppable: false,
        dragging: false
      });
    }
  }

  handleDrag(event) {
    this.renderOverlayLabel();
    preventDefaultAndDoNotPropagate(event);
  }

  async handleDrop(event) {
    const {
      handleDrop = () => Promise.resolve(),
      isDisabled,
      invalidFileToast = noop
    } = this.props;
    const { acceptedFileTypes } = this.state;

    if (isDisabled) {
      this.setState({ dragging: false, isDroppable: false });
      return;
    }
    if (!this.isValidFilesIncluded(event)) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ dragging: false, isDroppable: false });
      invalidFileToast({
        textComponent: "Unaccepted file type",
        type: "noButton",
        icon: "warning",
        duration: 3000
      });
      return;
    }

    // check that this is a drag we should intercept (file drag)
    if (this.isValidFilesIncluded(event)) {
      // filter out any invalid files
      const validFiles = Array.from(event.dataTransfer.files).filter(file =>
        acceptedFileTypes.includes(file.type)
      );

      // intercept the event by preventing it from triggering anything else
      preventDefaultAndDoNotPropagate(event);
      // flag dragging off
      this.setState({ dragging: false, isUploading: true, isDroppable: false });

      // handle the drop using passed file handling function
      await Promise.all(handleDrop(validFiles));

      this.setState({ isUploading: false });
    } else if (this.state.dragging) {
      // remove dragging regardless of valid files
      this.setState({
        dragging: false
      });
    }
  }

  getIncomingFileLabel() {
    const { incomingFileTypes } = this.state;
    const uniqueFileSet = [...new Set(incomingFileTypes)];
    let fileType;

    if (uniqueFileSet.every(file => file.includes("image"))) {
      fileType = "images";
    } else if (uniqueFileSet.every(file => file.includes("video"))) {
      fileType = "videos";
    } else {
      return "Upload files";
    }

    return `Drop your ${fileType} here`;
  }

  renderOverlayLabel() {
    switch (true) {
      case this.props.isDisabled: {
        return (
          <>
            <PadlockCurvedIcon
              size="24px"
              color="#303134"
              style={{ marginBottom: "2px" }}
            />
            Locked for this design
          </>
        );
      }
      case !this.state.isValidFile: {
        return (
          <>
            <CrossedCircleIcon
              size="24px"
              color="#303134"
              style={{ marginBottom: "2px" }}
            />
            Unaccepted file type
          </>
        );
      }
      case this.state.incomingFileTypes.includes("video/mp4"): {
        return (
          <>
            <VideoIcon isTabVersion={false} size="24px" color="#303134" />
            {this.props.label || this.getIncomingFileLabel()}
          </>
        );
      }
      default:
        return (
          <>
            <ImageJaggedMountainIcon
              isTabVersion={false}
              size="24px"
              color="#303134"
            />
            {this.props.label || this.getIncomingFileLabel()}
          </>
        );
    }
  }

  render() {
    const { isDroppable } = this.state;
    const {
      className,
      leftOffset,
      overlayTextStyle = {},
      isUsingDirectParent
    } = this.props;

    const div = isUsingDirectParent
      ? this.dropRef.current?.parentElement
      : this.dropRef.current?.parentElement.parentElement;
    return (
      <div
        className={[style.dropWrapper, className].filter(x => x).join(" ")}
        ref={this.dropRef}
        data-is-passthrough={!isDroppable}
      >
        {this.state.dragging && (
          <div
            className={`${
              this.props.isSidebarDnd
                ? style.draggingOverlayEditor
                : style.draggingOverlay
            }`}
            style={{
              width: `100%`,
              height: `calc(${div ? `${div.clientHeight}px` : "100%"})`,
              top: !this.props.isEditorDnd ? `-40px` : "0px",
              left: `-${leftOffset}px`
            }}
          >
            <div className={style.draggingOverlayText} style={overlayTextStyle}>
              {this.renderOverlayLabel()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FileDragAndDrop;
