import { combineReducers } from "redux";

import { personalApiReducers as personal } from "./personal";
import { teamApiReducers as team } from "./team";
import { purchasedApiReducers as purchased } from "./purchased";

import { CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS } from "state/currentUser/currentUserTypes";
const apiDesignsReducers = combineReducers({ personal, team, purchased });

const apiDesignsReducersWithReset = (state, action) => {
  if (action.type === CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS) {
    state = undefined;
  }

  return apiDesignsReducers(state, action);
};

export default apiDesignsReducersWithReset;
