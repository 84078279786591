import { pick } from "lib";
import { createSelector } from "reselect";

export const pendingUsersStoreSelector = state => state.entities.pendingUsers;

export const pendingUsersTotalCountSelector = state =>
  state.api.pendingUsers.totalCount;

const pendingUsersPageSelector = (state, pageNumber = 1) =>
  state.api.pendingUsers.pages[pageNumber];
const pendingUsersEntitiesSelector = state => state.entities.pendingUsers;

const processPendingUsersSelector = (page, pendingUsers) => {
  if (!page) return null;

  if (!page.ids) return null;

  if (page.didInvalidate) return null;

  return pick(pendingUsers, page.ids);
};

export const pendingUsersSelector = createSelector(
  [pendingUsersPageSelector, pendingUsersEntitiesSelector],
  processPendingUsersSelector
);
