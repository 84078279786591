export const UI_PURCHASE_COLLECTION_SET_CURRENT_ORDER_ID =
  "UI/PURCHASE_COLLECTION/SET_CURRENT_ORDER_ID";
export const UI_PURCHASE_COLLECTION_SET_LOADING =
  "UI/PURCHASE_COLLECTION/SET_LOADING";
export const UI_PURCHASE_COLLECTION_SET_COUPON_ERROR =
  "UI/PURCHASE_COLLECTION/SET_COUPON_ERROR";
export const UI_PURCHASE_COLLECTION_SET_TOKEN =
  "UI/PURCHASE_COLLECTION/SET_TOKEN";
export const UI_PURCHASE_COLLECTION_SET_PAYMENT_TYPE =
  "UI/PURCHASE_COLLECTION/SET_PAYMENT_TYPE";
export const UI_PURCHASE_COLLECTION_CLEAR = "UI/PURCHASE_COLLECTION/CLEAR";
export const UI_PURCHASE_COLLECTION_SET_POLLING_ERROR =
  "UI/PURCHASE_COLLECTION/SET_POLLING_ERROR";
export const UI_PURCHASE_COLLECTION_MODAL_OPEN =
  "UI/PURCHASE_COLLECTION/MODAL_OPEN";
export const UI_PURCHASE_COLLECTION_MODAL_CLOSE =
  "UI/PURCHASE_COLLECTION/MODAL_CLOSE";
export const UI_PURCHASE_COLLECTION_SET_COLLECTION_ID =
  "UI/PURCHASE_COLLECTION/SET_COLLECTION_ID";
export const UI_PURCHASE_COLLECTION_CLEAR_PAYMENT_ERROR =
  "UI/PURCHASE_COLLECTION/CLEAR_PAYMENT_ERROR";
