import { currentOrderIdSelector } from "state/ui/cart/cartSelectors";
export const shippingDetailsEntitySelector = state =>
  state.entities.shippingDetails;
export const shippingDetailsForOrderSelector = ({ state, orderId }) =>
  shippingDetailsEntitySelector(state)[orderId] || {};
export const shippingDetailsForCurrentOrderSelector = ({ state }) =>
  shippingDetailsForOrderSelector({
    state,
    orderId: currentOrderIdSelector(state)
  });
