import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CogIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  isSmall,
  ...props
}) => {
  if (isSmall)
    return (
      <svg
        {...props}
        data-no-pointer={noPointer}
        width={width || size || 20}
        height={height || size || 20}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h20v20H0z" />
          <path d="M0 0h20v20H0z" />
          <g
            transform="translate(1 1)"
            stroke={color || easilGrayXDark}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.8"
          >
            <circle cx="9" cy="9" r="2.455" />
            <path d="M15.055 11.455a1.35 1.35 0 0 0 .27 1.489l.049.049a1.636 1.636 0 1 1-2.316 2.315l-.049-.049a1.35 1.35 0 0 0-1.489-.27 1.35 1.35 0 0 0-.818 1.236v.139a1.636 1.636 0 0 1-3.273 0v-.074a1.35 1.35 0 0 0-.884-1.235 1.35 1.35 0 0 0-1.489.27l-.049.049a1.636 1.636 0 1 1-2.315-2.316l.049-.049a1.35 1.35 0 0 0 .27-1.489 1.35 1.35 0 0 0-1.236-.818h-.139a1.636 1.636 0 0 1 0-3.273h.074a1.35 1.35 0 0 0 1.235-.884 1.35 1.35 0 0 0-.27-1.489l-.049-.049a1.636 1.636 0 1 1 2.316-2.315l.049.049a1.35 1.35 0 0 0 1.489.27h.065a1.35 1.35 0 0 0 .819-1.236v-.139a1.636 1.636 0 0 1 3.272 0v.074a1.35 1.35 0 0 0 .819 1.235 1.35 1.35 0 0 0 1.489-.27l.049-.049a1.636 1.636 0 1 1 2.315 2.316l-.049.049a1.35 1.35 0 0 0-.27 1.489v.065a1.35 1.35 0 0 0 1.236.819h.139a1.636 1.636 0 0 1 0 3.272h-.074a1.35 1.35 0 0 0-1.235.819z" />
          </g>
        </g>
      </svg>
    );
  return (
    <svg
      {...props}
      data-no-pointer={noPointer}
      width={width || size || 30}
      height={height || size || 30}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g id="ROLES-+-Proto" stroke="none" fill="none" fillRule="evenodd">
        <path d="M-1-1h32v32H-1z" />
        <g
          stroke={color || easilGrayXDark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(1 1)"
        >
          <circle cx="14" cy="14" r="3.818" />
          <path d="M23.418 17.818a2.1 2.1 0 0 0 .42 2.317l.077.076a2.545 2.545 0 0 1-1.801 4.348 2.545 2.545 0 0 1-1.801-.746l-.077-.077a2.1 2.1 0 0 0-2.316-.42 2.1 2.1 0 0 0-1.273 1.922v.217a2.545 2.545 0 0 1-5.09 0v-.115a2.1 2.1 0 0 0-1.375-1.922 2.1 2.1 0 0 0-2.317.42l-.076.077a2.545 2.545 0 0 1-4.348-1.801c0-.676.268-1.324.746-1.801l.077-.077a2.1 2.1 0 0 0 .42-2.316 2.1 2.1 0 0 0-1.922-1.273h-.217a2.545 2.545 0 1 1 0-5.09h.115a2.1 2.1 0 0 0 1.922-1.375 2.1 2.1 0 0 0-.42-2.317l-.077-.076a2.545 2.545 0 0 1 1.801-4.348c.676 0 1.324.268 1.801.746l.077.077a2.1 2.1 0 0 0 2.316.42h.102a2.1 2.1 0 0 0 1.273-1.922v-.217a2.545 2.545 0 1 1 5.09 0v.115a2.1 2.1 0 0 0 1.273 1.922 2.1 2.1 0 0 0 2.317-.42l.076-.077a2.545 2.545 0 0 1 4.348 1.801c0 .676-.268 1.324-.746 1.801l-.077.077a2.1 2.1 0 0 0-.42 2.316v.102a2.1 2.1 0 0 0 1.922 1.273h.217a2.545 2.545 0 0 1 0 5.09h-.115a2.1 2.1 0 0 0-1.922 1.273z" />
        </g>
      </g>
    </svg>
  );
};
CogIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CogIcon;
