import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const DotDragIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 24}
    viewBox="0 0 20 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="20" height="24" />
      <g
        transform="translate(6.000000, 5.000000)"
        fill={color || easilGrayXDark}
      >
        <circle cx="1" cy="1" r="1" />
        <circle cx="7" cy="1" r="1" />
        <circle cx="1" cy="7" r="1" />
        <circle cx="7" cy="7" r="1" />
        <circle cx="1" cy="13" r="1" />
        <circle cx="7" cy="13" r="1" />
      </g>
    </g>
  </svg>
);
DotDragIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default DotDragIcon;
