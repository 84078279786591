import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const MagnifyingGlassPlusIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 12}
      height={height || size || 12}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <circle
          stroke={color || easilGrayXDark}
          strokeWidth="1.5"
          cx="5.5"
          cy="5.5"
          r="4.75"
        />
        <path
          d="m9 8 2.5 2.5a.707.707 0 1 1-1 1L8 9l1-1z"
          fill={color || easilGrayXDark}
        />
        <rect
          fill={color || easilGrayXDark}
          x="5"
          y="3"
          width="1"
          height="5"
          rx=".5"
        />
        <rect
          fill={color || easilGrayXDark}
          x="3"
          y="5"
          width="5"
          height="1"
          rx=".5"
        />
      </g>
    </svg>
  );
};
MagnifyingGlassPlusIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default MagnifyingGlassPlusIcon;
