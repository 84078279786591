import React, { Component } from "react";
import PropTypes from "prop-types";

/* components  */
import { ToggleCheckbox, Tooltip } from "views/components";
import PadlockSmallIcon from "views/components/icons/PadlockSmallIcon";
import EasilTemplatesSettingsLoading from "./EasilTemplatesSettingsLoading";
/* style */
import style from "./style.module.css";

class EasilTemplatesSettings extends Component {
  constructor(props) {
    super(props);

    this.getPadLockDetails = this.getPadLockDetails.bind(this);
    this.submitChange = this.submitChange.bind(this);
  }

  componentDidMount() {
    const { fetchTeamSettingsIfNeeded } = this.props;

    fetchTeamSettingsIfNeeded();
  }

  componentDidUpdate() {
    const {
      teamAllowEasilTemplatesSetting,
      fetchTeamSettingsIfNeeded
    } = this.props;

    if (teamAllowEasilTemplatesSetting === null) {
      fetchTeamSettingsIfNeeded();
    }
  }

  submitChange(value) {
    const { updateTeamSettings } = this.props;

    updateTeamSettings({ teamSettings: { allowEasilTemplates: !value } });
  }

  render() {
    const { teamAllowEasilTemplatesSetting } = this.props;

    if (teamAllowEasilTemplatesSetting === null) {
      return <EasilTemplatesSettingsLoading />;
    }

    const {
      padlockToolTipText,
      canChangeEasilTemplatesSetting
    } = this.getPadLockDetails();

    return (
      <div className={style.section} data-testid="EasilTemplatesSettings">
        <div className={style.toggleSection}>
          <div className={style.toggleLabel}>
            {!canChangeEasilTemplatesSetting && (
              <div
                data-for="easilTemplatesPadlock"
                data-tip={padlockToolTipText}
                className={style.padlock}
              >
                <PadlockSmallIcon size="24px" />
                <Tooltip
                  id="easilTemplatesPadlock"
                  place="bottom"
                  offset={{ top: 8 }}
                  multiline={true}
                />
              </div>
            )}
            <div>Hide Easil Templates</div>
          </div>
          <div
            className={style.toggle}
            data-disabled={!canChangeEasilTemplatesSetting}
          >
            <ToggleCheckbox
              width="49px"
              checked={!teamAllowEasilTemplatesSetting}
              onChange={this.submitChange}
            />
          </div>
        </div>
        <div className={style.description}>
          Only Designer roles will be able to view Easil Templates. This will
          apply to all sub-teams.{" "}
          <a
            href="http://support.easil.com/hiding-easil-templates-from-your-team/"
            rel="noopener noreferrer"
            target="_blank"
            className={style.learnMore}
          >
            Learn more.
          </a>
        </div>
      </div>
    );
  }

  getPadLockDetails() {
    const { isCurrentTeamOrg, isBrandManager } = this.props;

    /* if the current team is not an org, they can't change the settings */
    if (!isCurrentTeamOrg) {
      return {
        padlockToolTipText: "This setting is inherited \nfrom the parent team.",
        canChangeEasilTemplatesSetting: false
      };
    }

    /* if the current team is an org, user has to be brandManager to change the settings */
    if (!isBrandManager) {
      return {
        padlockToolTipText:
          "Only Brand Manager roles have the \nability to change this setting.",
        canChangeEasilTemplatesSetting: false
      };
    } else {
      return {
        padlockToolTipText: "",
        canChangeEasilTemplatesSetting: true
      };
    }
  }
}
EasilTemplatesSettings.displayName = "EasilTemplatesSettings";
EasilTemplatesSettings.propTypes = {
  isCurrentTeamOrg: PropTypes.bool.isRequired,
  isBrandManager: PropTypes.bool.isRequired,
  allowEasilTemplates: PropTypes.bool,
  fetchTeamSettingsIfNeeded: PropTypes.func,
  updateTeamSettings: PropTypes.func
};

export default EasilTemplatesSettings;
