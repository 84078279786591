import React from "react";

export default function EmptyLogosIcon(props) {
  return (
    <svg viewBox="0 0 56 44" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <rect
            width="50"
            height="38"
            x="6"
            y="6"
            fill="#DCDCE0"
            opacity=".85"
            rx="2"
          />
          <path
            fill="#2C2E2F"
            fillRule="nonzero"
            d="M52 4.103v31.794C52 38.163 50.21 40 48 40H4c-2.21 0-4-1.837-4-4.103V4.103C0 1.837 1.79 0 4 0h44c2.21 0 4 1.837 4 4.103zm-2 0c0-1.133-.895-2.053-2-2.053H4c-1.105 0-2 .919-2 2.052v31.794c0 1.133.895 2.052 2 2.052h44c1.105 0 2-.919 2-2.052V4.103z"
          />
        </g>
        <path d="M13 8h24v24H13z" />
        <g fill="#2C2E2F" fillRule="nonzero">
          <path d="M25.33 15.356a1 1 0 011.879.678l-.04.11-2.403 5.606h3.484a1 1 0 01.993.883l.007.117a1 1 0 01-.883.993l-.117.007h-5a1.001 1.001 0 01-.96-1.283l.04-.11 3-7z" />
          <path d="M24.5 9.404l-7 4a3 3 0 00-1.5 2.595V24a3.001 3.001 0 001.5 2.596l7.004 4.002a2.997 2.997 0 002.996-.002l6.996-3.998A3 3 0 0036 24.001V16a3.001 3.001 0 00-1.5-2.596l-7.004-4.002a2.997 2.997 0 00-2.996.002zm-6.099 5.797l.095-.063 7-4c.313-.18.695-.18 1.004-.002l7.004 4.002a.998.998 0 01.496.863V24c0 .356-.19.686-.5.864l-6.996 3.998c-.313.18-.695.18-1.004.002l-7.004-4.002a.998.998 0 01-.496-.863V16a1 1 0 01.401-.8z" />
        </g>
      </g>
    </svg>
  );
}
