import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const NinetyDegreeArrowIcon = ({
  className,
  width,
  size,
  height,
  style,
  color,
  direction = "right",
  isPointer,
  title,
  ...props
}) => {
  const directionToAngle = {
    up: -90,
    right: 0,
    down: 90,
    left: 180
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction]}deg)`,
    ...style
  };
  return (
    <svg
      className={className}
      style={finalStyle}
      data-is-pointer={isPointer}
      width={width || size || 14}
      height={height || size || 15}
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      data-testid="NinetyDegreeArrowIcon"
      {...props}
    >
      <title>{title}</title>
      <g stroke="none" fill="none" fillRule="evenodd">
        <path d="M-3-2h20v20H-3z" />
        <path
          d="M13.795 9.077l.026.053a.894.894 0 01-.026.793l-.012.021a.893.893 0 01-.147.192l.06-.066a.905.905 0 01-.038.044l-.022.022-4 4a.9.9 0 11-1.272-1.272l2.463-2.464H4C1.92 10.4.21 8.805.105 6.787L.1 6.591V1.5a.9.9 0 111.8 0v5.09c0 1.104.934 2.01 2.1 2.01h6.827L8.364 6.136a.9.9 0 01-.08-1.18l.08-.092a.9.9 0 011.272 0l4 4a.916.916 0 01.147.192l.012.02z"
          fill={color || easilGrayXDark}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
NinetyDegreeArrowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  direction: PropTypes.string
};

export default NinetyDegreeArrowIcon;
