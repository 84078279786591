import { debounce } from "lodash";

const userChat = {};

userChat.initialize = ({ userId, email }) => {
  window.civchat = {
    apiKey: process.env.REACT_APP_USER_ENGAGE_API_KEY,
    user_id: userId,
    email: email,
    onMessage: userChat.onMessage,
    onClose: userChat.hide,
    onOpen: userChat.repositionChatWindow,
    onLoad: () => {
      userChat.hide();
      userChat.checkForMessages();
    }
  };

  let scriptElement = document.createElement("script");
  scriptElement.type = "text/javascript";
  scriptElement.async = true;
  scriptElement.src = "https://easil.user.com/widget.js";

  let firstScriptTag = document.getElementsByTagName("script")[0];

  firstScriptTag.parentNode.insertBefore(scriptElement, firstScriptTag);
};

userChat.identify = ({ userId, email }) => {
  if (!window.UE || !window.UE.isReady) {
    setTimeout(() => userChat.identify({ userId, email }), 2000);

    return;
  }

  window.civchat.user_id = userId;
  window.civchat.email = email;

  window.UE.resetAuth(window.civchat);

  setTimeout(userChat.checkForMessages, 7000);
};

userChat.hideHtmlElement = htmlElement => {
  if (!htmlElement) return;
  htmlElement.style.visibility = "hidden";
};

userChat.isReady = () => window.UE && window.UE.isReady;

userChat.showHtmlElement = htmlElement => {
  if (!htmlElement) return;
  htmlElement.style.visibility = "visible";
};

userChat.counter = () => {
  return document.getElementById("usercom-launcher-counter");
};

userChat.checkForMessages = () => {
  if (!window.UE.isReady) {
    setTimeout(userChat.checkForMessages, 2000);
    return;
  }

  const counter = userChat.counter();

  if (counter) {
    userChat.hide();
    setTimeout(userChat.open, 300);
    return;
  }
};

userChat.onMessage = () => {
  /* counter means that the message is in another conversation, not the current one */
  if (!userChat.isOpen) {
    userChat.open();
  }
};

/* we debounce it to wait until userEngage has placed the counter */
userChat.onMessage = debounce(userChat.onMessage, 2000);

userChat.open = () => {
  if (!userChat.isOpen) {
    window.userengage("widget.show"); // Show the widget
    window.userengage("widget.open"); // Open the chat
    userChat.isOpen = true;
  }
};

userChat.hide = () => {
  window.userengage("widget.hide");
  userChat.isOpen = false;
};

userChat.hideWidgetIcon = () => {
  const widgetIcon = document.getElementById("usercom-launcher-dot-frame");
  userChat.hideHtmlElement(widgetIcon);
};

userChat.repositionChatWindow = () => {
  if (!userChat.isOpen) {
    userChat.debouncedRepositionChatWindow();
    return;
  }

  /* the messenger html element is not always in the html,
   * userEngage might take a while to place it in the html */
  const chatWindow = document.getElementById("usercom-board-frame");
  if (!chatWindow) {
    userChat.debouncedRepositionChatWindow();
    return;
  }
  // userChat.hideWidgetIcon();
  chatWindow.style.transform = `translateY(82px)`;
};

userChat.debouncedRepositionChatWindow = debounce(
  userChat.repositionChatWindow,
  100
);

userChat.toggle = () => {
  userChat.isOpen ? userChat.hide() : userChat.open();
};

window.easil.userChat = userChat;
export default userChat;
