import React from "react";
import { connect } from "react-redux";
import BillingSection from "./BillingSection";
import { createNewBillingDetailsForOrder } from "state/entities/billingDetails/billingDetailsActions";
import { billingDetailsForOrderSelector } from "state/entities/billingDetails/billingDetailsSelectors";
import { shippingDetailsForOrderSelector } from "state/entities/shippingDetails/shippingDetailsSelectors";
import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";
import {
  updateBillingDetails,
  updateShippingDetails,
  fetchBillingAndShippingDetails
} from "state/pageActions/cartPageActions";
import {
  currentOrderIdSelector,
  cartErrorsSelector,
  currentOrderLoadingStateSelector
} from "state/ui/cart/cartSelectors";
import {
  setCurrentBilling,
  setCurrentShipping,
  setShippingSameAsBilling,
  getShippingFromAccountInfo
} from "state/ui/cart/cartActions";

export const BillingSectionContainer = props => <BillingSection {...props} />;

function mapStateToProps(state) {
  const currentOrderId = currentOrderIdSelector(state);
  const subscription = currentSubscriptionSelector(state) || {};

  return {
    currentOrderId,
    billingDetails: billingDetailsForOrderSelector({
      state,
      orderId: currentOrderId
    }),
    shippingDetails: shippingDetailsForOrderSelector({
      state,
      orderId: currentOrderId
    }),
    errors: cartErrorsSelector(state),
    loading: currentOrderLoadingStateSelector(state),
    isAccount: subscription.isAccount
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchBillingAndShippingDetails: args =>
      dispatch(fetchBillingAndShippingDetails(args)),
    createNewBillingDetailsForOrder: args =>
      dispatch(createNewBillingDetailsForOrder(args)),
    updateBillingDetails: args => dispatch(updateBillingDetails(args)),
    setCurrentBilling: args => dispatch(setCurrentBilling(args)),
    setCurrentShipping: args => dispatch(setCurrentShipping(args)),
    updateShippingDetails: args => dispatch(updateShippingDetails(args)),
    setShippingSameAsBilling: args => dispatch(setShippingSameAsBilling(args)),
    getShippingFromAccountInfo: args =>
      dispatch(getShippingFromAccountInfo(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingSectionContainer);
