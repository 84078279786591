import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { noop } from "lib";
import { currentOrderIdSelector } from "state/ui/cart/cartSelectors";
import { asyncAction } from "lib/asyncHelpers";
import * as types from "./billingDetailsTypes";

const defaultFetchTypes = [
  types.FETCH_BILLING_DETAILS_FOR_ORDER_REQUEST,
  types.FETCH_BILLING_DETAILS_FOR_ORDER_REQUEST_SUCCESS,
  types.FETCH_BILLING_DETAILS_FOR_ORDER_REQUEST_FAILURE
];
const currentOrderFetchTypes = [
  types.FETCH_BILLING_DETAILS_FOR_CURRENT_ORDER_REQUEST,
  types.FETCH_BILLING_DETAILS_FOR_CURRENT_ORDER_REQUEST_SUCCESS,
  types.FETCH_BILLING_DETAILS_FOR_CURRENT_ORDER_REQUEST_FAILURE
];

export const fetchBillingDetailsForOrder = ({
  orderId,
  types,
  onSuccess = noop,
  resolve = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ORDER,
      types: types || defaultFetchTypes,
      endpoint: `/orders/${orderId}/billing-details`,
      schema: schemas.BILLING_DETAILS,
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      }
    }
  });
};

export const fetchBillingDetailsForCurrentOrder = ({
  onSuccess = noop,
  resolve = noop
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentOrderId = currentOrderIdSelector(state);

  return dispatch(
    fetchBillingDetailsForOrder({
      orderId: currentOrderId,
      onSuccess,
      resolve,
      types: currentOrderFetchTypes
    })
  );
};

export const asyncFetchBillingDetailsForCurrentOrder = asyncAction(
  fetchBillingDetailsForCurrentOrder
);

export const createNewBillingDetailsForOrder = ({
  orderId,
  billingDetails,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ORDER,
      types: [
        types.CREATE_BILLING_DETAILS_FOR_ORDER_REQUEST,
        types.CREATE_BILLING_DETAILS_FOR_ORDER_REQUEST_SUCCESS,
        types.CREATE_BILLING_DETAILS_FOR_ORDER_REQUEST_FAILURE
      ],
      endpoint: `/orders/${orderId}/billing-details`,
      schema: schemas.BILLING_DETAILS,
      request: {
        body: {
          ...billingDetails,
          orderId
        }
      },
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

export const createNewBillingDetailsForCurrentOrder = ({
  billingDetails,
  onSuccess = noop,
  resolve = noop,
  reject = noop
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentOrderId = currentOrderIdSelector(state);

  return dispatch(
    createNewBillingDetailsForOrder({
      orderId: currentOrderId,
      billingDetails,
      onSuccess,
      resolve,
      reject
    })
  );
};

export const updateBillingDetailsForOrder = ({
  orderId,
  billingDetails,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ORDER,
      types: [
        types.UPDATE_BILLING_DETAILS_FOR_ORDER_REQUEST,
        types.UPDATE_BILLING_DETAILS_FOR_ORDER_REQUEST_SUCCESS,
        types.UPDATE_BILLING_DETAILS_FOR_ORDER_REQUEST_FAILURE
      ],
      endpoint: `/orders/${orderId}/billing-details`,
      schema: schemas.BILLING_DETAILS,
      request: {
        body: {
          ...billingDetails,
          orderId
        }
      },
      onSuccess: response => {
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

export const updateBillingDetailsForCurrentOrder = ({
  billingDetails,
  onSuccess = noop,
  resolve = noop,
  reject
} = {}) => (dispatch, getState) => {
  const state = getState();
  const currentOrderId = currentOrderIdSelector(state);

  return dispatch(
    updateBillingDetailsForOrder({
      orderId: currentOrderId,
      billingDetails,
      onSuccess,
      resolve,
      reject
    })
  );
};
