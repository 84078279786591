import { createSelector } from "reselect";
import { isEmpty } from "lib";
import { formatDateString } from "lib/temporal/dateUtils";
import { dummyCredit } from "./creditsReducers";

const creditsSelector = state => state.entities.credits;

const processRemoveBackgroundSelector = credits => {
  if (isEmpty(credits)) {
    return null;
  }

  const { removeBackground } = credits;

  const creditsLeft = removeBackground.total - removeBackground.consumed;

  return {
    ...removeBackground,
    isDummy: removeBackground.id === dummyCredit.removeBackground.id,
    creditsLeft: creditsLeft,
    hasNoCredit: creditsLeft <= 0,
    renewDateFriendly: getRenewDateFriendly()
  };

  function getRenewDateFriendly() {
    const date = formatDateString(
      new Date(removeBackground.endAt),
      "MMM D, YYYY"
    );

    return date === "Invalid Date" ? "" : date;
  }
};

export const removeBackgroundSelector = createSelector(
  [creditsSelector],
  processRemoveBackgroundSelector
);
