import * as types from "./publicFontsTypes";

import { merge } from "lib";

export const initState = {};

const publicFontsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.PUBLIC_FONTS_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;
      return merge({}, state, entities.publicFont);
    }

    default:
      return state;
  }
};

export default publicFontsReducers;
