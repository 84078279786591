import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const UserMinusIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2 0h24v24H-2z" />
      <path d="M2 2h20v20H2z" />
      <path
        d="M12 14c2.185 0 4 1.614 4 3.667V20a1 1 0 01-2 0v-2.333c0-.893-.87-1.667-2-1.667H6c-1.13 0-2 .774-2 1.667V20a1 1 0 01-2 0v-2.333C2 15.614 3.815 14 6 14zm9-4a1 1 0 01.117 1.993L21 12h-6a1 1 0 01-.117-1.993L15 10h6zM9 3a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z"
        fill={color || easilGrayXDark}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
UserMinusIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default UserMinusIcon;
