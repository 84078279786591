import * as types from "./userTeamFontsTypes";

import { merge } from "lib";

export const initState = {};

const userTeamFontsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USER_TEAM_FONTS_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;
      return merge({}, state, entities.userTeamFont);
    }

    case types.USER_UPLOAD_FONT_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;
      return merge({}, state, entities.userFontUpload);
    }

    case types.USER_TEAM_FONT_REMOVE_REQUEST_SUCCESS: {
      if (!action.extra.fontId) return state;

      const fontId = action.extra.fontId;
      const newState = Object.assign({}, state);

      delete newState[fontId];

      return newState;
    }

    case types.USER_TEAM_FONTS_DELETE_REQUEST_SUCCESS: {
      const { fontIds } = action.extra;

      const fonts = Object.assign({}, state);

      // Filter out fonts to be removed
      const updatedFonts = Object.keys(fonts)
        .filter(id => !fontIds.includes(id))
        .reduce((_fonts, id) => {
          _fonts[id] = fonts[id];
          return _fonts;
        }, {});

      return updatedFonts;
    }

    default:
      return state;
  }
};

export default userTeamFontsReducers;
