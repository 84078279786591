import React, { Component } from "react";
import { connect } from "react-redux";
import { SmartImageModal } from "views/components/SmartImage/SmartImageModal";
import {
  createUserTeamSmartImage,
  updateUserTeamSmartImage
} from "state/entities/userTeamSmartImages/userTeamSmartImageActions";
import { userTeamSmartImagesEntitiesSelector } from "state/entities/userTeamSmartImages/userTeamSmartImagesSelectors";
import { userTeamSmartImagesApiSavingSelector } from "state/api/userTeamSmartImages/userTeamSmartImagesApiSelectors";
import { createTeamSmartImage } from "state/entities/teamSmartImages/teamSmartImagesActions";
import { updateTeamSmartImage } from "state/entities/teamSmartImages/teamSmartImagesActions";
import {
  teamSmartImagesApiSavingSelector,
  teamSmartImagesErrorsSelector
} from "state/api/teamSmartImages/teamSmartImagesApiSelectors";

export class SmartImageModalContainer extends Component {
  render() {
    return <SmartImageModal {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    smartImages: userTeamSmartImagesEntitiesSelector(state),
    isSaving: userTeamSmartImagesApiSavingSelector(state),
    isSavingTeam: teamSmartImagesApiSavingSelector(state),
    errors: teamSmartImagesErrorsSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createUserTeamSmartImage: args => dispatch(createUserTeamSmartImage(args)),
    createTeamSmartImage: args => dispatch(createTeamSmartImage(args)),
    updateUserTeamSmartImage: args => dispatch(updateUserTeamSmartImage(args)),
    updateTeamSmartImage: args => dispatch(updateTeamSmartImage(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartImageModalContainer);
