import { isEmpty, noop } from "lib";
import * as types from "state/entities/designs/designsTypes";

const imagePreloader = store => next => action => {
  switch (action.type) {
    case types.DESIGNS_DRAFTS_REQUEST_SUCCESS:
    case types.DESIGNS_BY_FOLDER_REQUEST_SUCCESS:
    case types.DESIGNS_BY_TEAM_FOLDER_REQUEST_SUCCESS:
    case types.TEAM_TEMPLATES_DESIGNS_REQUEST_SUCCESS:
    case types.EASIL_TEMPLATES_DESIGNS_REQUEST_SUCCESS:
    case types.EASIL_TUTORIAL_DESIGNS_REQUEST_SUCCESS:
    case types.TEAM_ARCHIVED_DESIGNS_REQUEST_SUCCESS:
    case types.TEAM_DESIGNS_REQUEST_SUCCESS:
    case types.DESIGNS_ARCHIVED_REQUEST_SUCCESS: {
      handleDesignRequestSuccess(action.response);
      break;
    }

    default:
      noop();
  }

  next(action);
};

const handleDesignRequestSuccess = response => {
  if (isEmpty(response)) {
    return;
  }

  Object.values(response.entities.designs).forEach(design => {
    const link = document.createElement("link");
    link.as = "image";
    link.rel = "preload";
    link.href = design.thumbnailUrl;

    const headScript = document.querySelector("script");
    headScript.parentNode.insertBefore(link, headScript);
  });
};

export default imagePreloader;
