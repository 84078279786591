import React from "react";
import style from "./style.module.css";
import { Button } from "views/components";

export default function BrandKitSearchTextButton({ Icon, title, ...props }) {
  return (
    <Button
      theme="blueSolidNoBackgroundNoBorder"
      className={style.searchTextButton}
      {...props}
    >
      <div className={style.iconBoundingBox}>
        <Icon />
      </div>
      {title}
    </Button>
  );
}
