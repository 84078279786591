import React from "react";
import style from "./style.module.css";
import { TEAM_ROLES } from "lib/constants";
import { Checkbox } from "views/components";

const TeamRolesPicker = ({ onClick, selectedRoles }) => {
  return (
    <div className={style.container}>
      <div className={style.teamRolesWrapper} data-testid="TeamRolesPicker">
        {TEAM_ROLES.map(role => (
          <div
            className={style.roleWrapper}
            key={role.key}
            onClick={event => onClick(event, role.key)}
          >
            <Checkbox
              checked={selectedRoles.includes(role.key)}
              onClick={event => onClick(event, role.key)}
            />
            <div className={style.roleLabel}>{role.label}</div>
            <div className={style.roleContent}>{role.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamRolesPicker;
