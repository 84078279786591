import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const UserPlusIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  rotate,
  title,
  ...props
}) => {
  return (
    <svg
      style={style}
      width={width || size || 24}
      height={height || size || 24}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      data-testid="UserPlusIcon"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          stroke={color || easilGrayXDark}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
          d="M3 20v-2a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v2h0"
        />
        <rect
          width="2"
          height="8"
          x="17"
          y="7"
          fill={color || easilGrayXDark}
          rx="1"
        />
        <rect
          width="2"
          height="8"
          x="17"
          y="7"
          fill={color || easilGrayXDark}
          rx="1"
          transform="rotate(90 18 11)"
        />
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M9 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
        />
      </g>
    </svg>
  );
};
UserPlusIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.string
};

export default UserPlusIcon;
