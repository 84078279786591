import React, { Component } from "react";
import style from "../style.module.css";
import CaretIcon from "views/components/icons/CaretIcon";
import { isNil, composeClassName } from "lib";

class DropdownWithTitle extends Component {
  constructor(props) {
    super(props);

    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.popoutRef = React.createRef();
    this.buttonRef = React.createRef();
    this.searchRef = React.createRef();
    this.state = {
      isOpen: false,
      options: this.props.options || [],
      selected: this.props.selected || null,
      searchText: this.props.searchText || ""
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevState) {
    if (this.state.isOpen && !prevState.isOpen) {
    }
    if (!this.state.isOpen && prevState.isOpen) {
      if (this.state.searchText) {
        this.setState({
          searchText: ""
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.options });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.isConnected
    ) {
      this.setState({ isOpen: false });
    }
  }

  onDropdownClick() {
    if (this.props.disabled) return;
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleChange(option) {
    this.setState({ isOpen: false });

    this.props.onChange(option.key);
  }

  handleSearchTextChange(event) {
    this.setState({
      searchText: event.target.value
    });
  }

  handleOnClick(event, option) {
    if (
      option.disabled &&
      event.target.tagName !== "A" &&
      event.target.tagName !== "a"
    ) {
      event.preventDefault();
    }
    if (option.disabled) {
      return;
    }
    this.handleChange(option);
  }

  render() {
    const {
      placeholder,
      error,
      showErrorMsg,
      label,
      selected,
      disabled,
      wrapperClassName,
      inputClassName,
      inputWrapperClassName,
      optionClassName,
      caretColor,
      caretSize = "8px",
      className,
      popoutClassName,
      inputTitle,
      inputTitleClassName,
      customWrapperStyle,
      inputTitleAndUnitClassName
    } = this.props;
    const { options, isOpen } = this.state;

    let finalOptions = options;

    return (
      <React.Fragment>
        {label && <div className={style.label}>{label}</div>}
        <div
          ref={this.setWrapperRef}
          style={customWrapperStyle}
          className={composeClassName(
            "dropdown",
            { dropdown: `${style.dropdown} ${wrapperClassName}` },
            className
          )}
        >
          <div
            aria-label="dropdown"
            onClick={this.onDropdownClick}
            className={`${style.inputDiv} ${inputWrapperClassName}`}
            data-error={!isNil(error)}
            data-disabled={disabled}
            ref={this.buttonRef}
            style={this.props.customOptionStyle}
          >
            <div className={inputTitleAndUnitClassName}>
              <div className={inputTitleClassName}>{inputTitle}</div>
              <input
                placeholder={placeholder}
                value={selected || ""}
                className={inputClassName}
                disabled
              />
            </div>
            <div>
              <CaretIcon
                size={caretSize}
                color={caretColor || style.easilGrayXDark}
                direction="down"
              />
            </div>
          </div>
          {showErrorMsg && error && (
            <span aria-label="dropdownError" className={style.error}>
              {error}
            </span>
          )}
          {isOpen && (
            <div
              className={composeClassName(
                "content",
                { content: `${style.content} ${popoutClassName}` },
                className
              )}
              ref={this.popoutRef}
            >
              {finalOptions.map(option => {
                if (Array.isArray(option)) {
                  return (
                    <div className={style.optionList}>
                      {option.map(subOption => (
                        <div
                          className={`${style.option} ${optionClassName}`}
                          data-is-selected={subOption.label === selected}
                          disabled={subOption.disabled}
                          key={subOption.key}
                          onClick={event => this.handleOnClick(event, option)}
                        >
                          {subOption.label}
                        </div>
                      ))}
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={composeClassName(
                        "option",
                        { option: `${style.option}` },
                        optionClassName
                      )}
                      data-is-selected={option.label === selected}
                      disabled={option.disabled}
                      key={option.key}
                      onClick={event => this.handleOnClick(event, option)}
                    >
                      {option.label}
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default DropdownWithTitle;
