export const contextSelector = state => state.ui.editorContext.context;

export const editorContextSelector = state => state.ui.editorContext;

export const designDataSelector = state => state.ui.editorContext.designData;

export const designSelector = state => state.ui.editorContext.design;

export const currentPageIdSelector = state =>
  state.ui.editorContext.currentPageId;

export const elementPreviewsSelector = state =>
  state.ui.editorContext.elementPreviews;

export const highlightedSectionSelector = state =>
  state.ui.editorContext.highlightedSection;

export const isEditTitleModalOpenSelector = state =>
  state.ui.editorContext.isEditTitleModalOpen;

export const isCroppingSelector = state =>
  editorContextSelector(state).isCropping;

export const isInCropModeSelector = state => {
  const context = contextSelector(state);
  if (!context) return false;
  return (
    context.isCroppingPhotoFrame ||
    context.isCroppingImage ||
    context.isCroppingVideo ||
    context.isCroppingVector ||
    context.isCroppingGridCell ||
    context.isCroppingTextMaskImage
  );
};

export const readySelector = state => state.ui.editorContext.ready;

export const selectedItemsSelector = state =>
  state.ui.editorContext.selectedItems;

export const showBleedSelector = state => state.ui.editorContext.showBleed;

export const snapToCornersSelector = state =>
  state.ui.editorContext.snapToCorners;

export const zoomSelector = state => state.ui.editorContext.zoom;

export const isResizingSelector = state => state.ui.editorContext.isResizing;

export const isPlayingSelector = state => state.ui.editorContext.isPlaying;

export const shouldUseDesignSmartFieldsSelector = state =>
  state.ui.editorContext.useDesignSmartFields;

export const isSharedDialogOpenSelector = state =>
  state.ui.editorContext.isSharedDialogOpen;

export const isCellLockingMode = state =>
  state.ui.editorContext.isCellLockingMode;
