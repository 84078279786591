import PATHS from "routes/paths";

export const tabs = () => ({
  Plans: {
    title: "Plans",
    url: PATHS.subscriptionPlans
  },
  Billing: {
    title: "Billing",
    url: PATHS.subscriptionBilling
  }
});
