import { memoize, uuid, getFileExtension } from "lib";
import gifFrames from "gif-frames";
import { VIDEO_MEDIA_TYPES } from "lib/constants";

const IMAGE_SOURCE_RETRY_TIME = 500;
const MAX_RETRY = 20;

const getSourceImage = async (gifSource, resolve, reject, attempt = 1) => {
  if (attempt < MAX_RETRY) {
    const sourceImage = await fetch(`${gifSource}?${uuid()}`);
    if (!sourceImage.ok) {
      setTimeout(() => {
        getSourceImage(gifSource, resolve, reject, attempt + 1);
      }, IMAGE_SOURCE_RETRY_TIME);
    } else {
      return resolve(sourceImage);
    }
  } else {
    return reject("");
  }
};

export const getTextMaskSource = ({
  color,
  source,
  filters,
  canvas,
  opacity
} = {}) =>
  new Promise(async resolve => {
    const ctx = canvas.getContext("2d");
    // remove any old content on the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const tempImg = document.createElement("img");
    document.body.appendChild(tempImg);
    tempImg.onload = () => {
      const tempImgRect = tempImg.getBoundingClientRect();
      const width = tempImgRect.width;
      const height = tempImgRect.height;

      // set the canvas to the full res image dimensions
      ctx.canvas.setAttribute("width", width);
      ctx.canvas.setAttribute("height", height);

      document.body.appendChild(canvas);

      // draw the background for the image
      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.fillStyle = color;
      ctx.rect(0, 0, width, height);
      ctx.stroke();
      ctx.fill();
      // apply filters only to the image
      if (filters && filters.length > 0) {
        ctx.filter = filters;
      }
      // apply opacity only to the image
      if (opacity) {
        ctx.globalAlpha = opacity;
      }
      // add the image as an overlay on the block color background
      ctx.drawImage(tempImg, 0, 0);

      // return our new data url and remove the temporary elements from the document
      resolve(canvas.toDataURL());
      document.body.removeChild(tempImg);
      document.body.removeChild(canvas);
    };
    tempImg.src = source;
  });

export const getSourceAsLocalUrl = async gifSource => {
  if (gifSource.startsWith("blob:")) {
    // when the url is already a local one we don't need to process it any more
    return gifSource;
  } else {
    let image;
    try {
      image = await new Promise((resolve, reject) =>
        getSourceImage(gifSource, resolve, reject)
      );
    } catch (error) {
      console.error(
        new Error(
          `source '${gifSource}' failed to be loaded after ${MAX_RETRY} attempts`
        )
      );
      return null;
    }

    const imageBlob = await image.blob();
    return window.URL.createObjectURL(imageBlob);
  }
};

export const getGifImageSource = async ({ isPlaying, gifSource }) => {
  if (!isPlaying) {
    const sourceAsLocalUrl = await getSourceAsLocalUrl(gifSource);

    if (!sourceAsLocalUrl) {
      return "";
    }

    const frameData = await gifFrames({
      url: sourceAsLocalUrl,
      frames: 0,
      outputType: "canvas"
    });
    return frameData[0].getImage().toDataURL();
  }
  return gifSource;
};

export const memoizedGetGifImageSource = memoize(
  getGifImageSource,
  ({ isPlaying, gifSource }) => `${Boolean(isPlaying)}-${gifSource}`
);

export const isMediaVideoFormat = mediaSource => {
  if (!mediaSource || !(typeof mediaSource === "string")) {
    return false;
  }
  const sourceSuffix = mediaSource.split(".").pop();
  if (!sourceSuffix) {
    return false;
  }

  return VIDEO_MEDIA_TYPES.includes(sourceSuffix.toLowerCase());
};

export const getMediaType = media => {
  const fileExtension = getFileExtension(media.name);

  if (fileExtension === "gif") {
    return "ANIMATION";
  } else if (fileExtension === "mp4") {
    return "VIDEO";
  } else {
    return "IMAGE";
  }
};
