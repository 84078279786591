import React from "react";
import PropTypes from "prop-types";
import BillingAddress from "./BillingAddress";
import BillingCards from "./BillingCards";
import BillingContact from "./BillingContact";
import style from "./style.module.css";
import { ToggleCheckbox } from "views/components";

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.fetchBilling = this.fetchBilling.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);
  }

  componentDidMount() {
    this.fetchBilling();
    this.props.fetchTeamSettingsIfNeeded();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentTeam.id !== this.props.currentTeam.id) {
      this.fetchBilling();
    }
  }

  fetchBilling() {
    this.props.fetchBilling({ teamId: this.props.currentTeam.id });
  }

  updateNotifications(receiveInvoiceEmails) {
    const { updateTeamSettings } = this.props;

    updateTeamSettings({ teamSettings: { receiveInvoiceEmails } });
  }

  render() {
    const { teamBilling = {}, teamSettings = {} } = this.props;

    return (
      <div className={style.billing}>
        <BillingCards
          addCard={this.props.addCard}
          creditCardModal={this.props.creditCardModal}
          currentCard={this.props.currentCard}
          currentSubscription={this.props.currentSubscription}
          currentSubscriptionPlan={this.props.currentSubscriptionPlan}
          currentTeam={this.props.currentTeam}
          fetchCards={this.props.fetchCards}
          removeCard={this.props.removeCard}
          toggleModal={this.props.toggleModal}
        />
        <BillingAddress
          currentTeam={this.props.currentTeam}
          teamBilling={teamBilling}
          teamBillingSettings={this.props.teamBillingSettings}
          updateBilling={this.props.updateBilling}
        />
        <BillingContact
          teamBilling={teamBilling}
          teamBillingSettings={this.props.teamBillingSettings}
          updateBilling={this.props.updateBilling}
          clearBillingErrors={this.props.clearBillingErrors}
        />

        <h3>Notifications</h3>
        <label className={style.notification}>
          <ToggleCheckbox
            checked={teamSettings.receiveInvoiceEmails}
            onChange={this.updateNotifications}
          />
          <span>Receive invoices by Email</span>
        </label>
      </div>
    );
  }
}

Billing.displayName = "Billing";

Billing.propTypes = {
  addCard: PropTypes.func.isRequired,
  creditCardModal: PropTypes.object.isRequired,
  currentCard: PropTypes.object,
  currentSubscription: PropTypes.object.isRequired,
  currentSubscriptionPlan: PropTypes.object.isRequired,
  currentTeam: PropTypes.object.isRequired,
  fetchBilling: PropTypes.func.isRequired,
  removeCard: PropTypes.func.isRequired,
  updateBilling: PropTypes.func.isRequired,
  teamBilling: PropTypes.object,
  teamBillingSettings: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default Billing;
