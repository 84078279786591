import dateFns from "date-fns";

export const differenceInDays = dateFns.differenceInDays;

export const isAfter = dateFns.isAfter;

/**
 * @desc apply a given format to a date string
 * @param {String} dateString - the date string to apply formatting to
 * @param {String} [format = MMM DD, YYYY] - the format to apply
 */
export const formatDateString = (dateString, format = "MMM DD, YYYY") => {
  const date = dateFns.format(dateString, format);
  if (date === "Invalid Date") return "-";
  return date;
};

/**
 * @desc checks if a given date string is valid
 * @param {String} dateString - the date string to check for validity
 * @returns {Boolean} whether or not the date string is valid
 */
export const isValidDateString = dateString => {
  const timestamp = Date.parse(dateString);

  return !isNaN(timestamp);
};
