import React from "react";
import PropTypes from "prop-types";
import { ToggleCheckbox } from "views/components";
import style from "./style.module.css";

const DefaultTeamToggle = ({
  onToggleMakeTeamDefault,
  className,
  textOverride
}) => {
  const textContent =
    textOverride || "Each time you log in, we’ll place you in this team.";
  return (
    <div
      className={`${style.defaultTeamToggle}${
        className ? ` ${className}` : ""
      }`}
    >
      <div>
        <div className={style.title}>Make this your default team</div>
        <div className={style.description}>{textContent}</div>
      </div>
      <div>
        <ToggleCheckbox width={48} onChange={onToggleMakeTeamDefault} />
      </div>
    </div>
  );
};
DefaultTeamToggle.propTypes = {
  onToggleMakeTeamDefault: PropTypes.func.isRequired
};

export default DefaultTeamToggle;
