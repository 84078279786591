import React from "react";
import { Modal } from "views/components";
import style from "./style.module.css";
import { merge } from "lib";
import composeClassName from "lib/composeClassName";
import PropTypes from "prop-types";

const ConfirmationModal = ({
  modalClassName,
  isOpen,
  onCloseModal,
  header,
  text,
  onOk,
  onCancel,
  buttonsDetails = {}
}) => {
  const onOkClick = () => {
    onOk();
    onCloseModal();
  };

  const onCancelClick = () => {
    onCancel();
    onCloseModal();
  };

  const defaultOkButton = {
    name: "Ok",
    theme: "blueSolid",
    onClick: onOkClick
  };

  const defaultCancelButton = {
    name: "Cancel",
    theme: "gray",
    onClick: onCancelClick
  };

  const buttonsList = [
    merge(defaultCancelButton, buttonsDetails.cancel),
    merge(defaultOkButton, buttonsDetails.ok)
  ];

  return (
    <Modal
      className={composeClassName("confirmationModal", style, modalClassName)}
      isOpen={isOpen}
      contentLabel="Upgrade to Plan"
      onRequestClose={onCloseModal}
      header={header}
      buttons={buttonsList}
      buttonsClassName={buttonsDetails.buttonsClassName}
    >
      <div className={composeClassName("confirmationModalContent", style)}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </Modal>
  );
};

ConfirmationModal.displayName = "ConfirmationModal";

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  header: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  modalClassName: PropTypes.string,
  buttonsDetails: PropTypes.object
};

export default ConfirmationModal;
