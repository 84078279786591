import { openConfirmationModal } from "state/ui/confirmationModal/confirmationModalActions";

import { noop } from "lib";

class ConfirmationModal {
  constructor(store) {
    this.store = store;

    this.startModal = this.startModal.bind(this);
  }

  config({ store }) {
    this.store = store;
  }

  startModal({
    header = "Confirm",
    text = "Are you sure?",
    onOk = noop,
    onCancel = noop,
    modalClassName,
    buttonsDetails
  }) {
    this.store.dispatch(
      openConfirmationModal({
        header,
        text,
        onOk,
        onCancel,
        modalClassName,
        buttonsDetails
      })
    );
  }
}

const instance = new ConfirmationModal();

export const confirm = instance.startModal;

export default instance;
