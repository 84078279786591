import React from "react";
import { connect } from "react-redux";
import ImagesList from "views/containers/ImageListContainer/ImagesList";
import { userIsDesigner } from "state/currentUser/currentUserSelectors";
import { teamSettingsSelector } from "state/entities/teamSettings/teamSettingsSelectors";
import PATHS from "routes/paths";
import { moveTo, modifyQuery } from "state/ui/navigation/navigationActions";
import { teamAnimationsPageSelector } from "state/entities/teamAnimations/teamAnimationsSelectors";
import { getTeamAnimationFoldersAll } from "state/entities/teamAnimationsFolders/teamAnimationsFoldersSelectors";
import {
  getAllTeamAnimationNextPageIfNeeded as getBrandAnimationNextPage,
  deleteTeamAnimation,
  updateTeamAnimation,
  getTeamAnimationInFolderNextPage,
  searchTeamAnimations,
  refetchTeamAnimations
} from "state/entities/teamAnimations/teamAnimationsActions";
import {
  teamAnimationsIsFetchingSelector,
  teamAnimationsAllFirstPageIsFetching,
  teamAnimationsErrorSelector
} from "state/api/teamAnimations/teamAnimationsApiSelectors";
import { showUpgradeModal } from "state/ui/upgradeModal/upgradeModalActions";
import Subscriptions from "lib/subscriptions";
import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import {
  fetchAllTeamAnimationFoldersIfNeeded,
  deleteTeamAnimationFolder,
  addTeamAnimationToFolder,
  updateTeamAnimationFolder,
  removeTeamAnimationFromFolder,
  deleteTeamAnimationFolderAndGoToBrandAnimations
} from "state/entities/teamAnimationsFolders/teamAnimationsFoldersActions";
import { teamAnimationFoldersIsFetchingSelector } from "state/api/teamAnimationsFolders/teamAnimationsFoldersApiSelectors.js";
import { isFetchingSearchAnimations } from "state/ui/editorAnimationSearch/editorAnimationSearchSelectors";
import { mediaInFolders } from "state/api/folders/media/apiMediaFoldersSelectors";
import { getFolderForMedia } from "state/entities/folders/foldersActions";

const BrandKitAnimationsListContainer = props => <ImagesList {...props} />;

const mapStateToProps = (state, { filters: { term, folderId } }) => {
  const currentSubscription = currentSubscriptionPlan(state);
  const currentSubscriptionCode = currentSubscription.code;
  const canAccessBrandKit = Subscriptions.get(
    currentSubscriptionCode
  ).getCanAccessBrandKit();

  return {
    isDesigner: userIsDesigner(state),
    teamSettings: teamSettingsSelector(state),
    imageFolders: getTeamAnimationFoldersAll({ state, term, folderId }),
    isFetchingImages: teamAnimationsIsFetchingSelector(state),
    isFetchingImageFolders: teamAnimationFoldersIsFetchingSelector(state),
    // imagesPageCount: 1,
    isImagesFirstPageFetching: teamAnimationsAllFirstPageIsFetching(state),
    userTeamImagesNotInFolders: teamAnimationsPageSelector({ state, term }),
    isSearching: isFetchingSearchAnimations(state),
    imageOriginPath: PATHS.brandAnimations,
    isBrandAnimations: true,
    canAccessBrandKit,
    errors: teamAnimationsErrorSelector(state),
    mediaInFolders: mediaInFolders(state)
  };
};

const mapDispatchToProps = (dispatch, { filters: { term, folderId } }) => {
  return {
    changePage: path => dispatch(moveTo(path)),
    changeQuery: args => dispatch(modifyQuery(args)),
    onSearchUsersImages: args =>
      dispatch(searchTeamAnimations({ term, folderId, ...args })),
    onFetchUsersImages: args => dispatch(getBrandAnimationNextPage(args)),
    onFetchUsersImagesInFolder: args =>
      dispatch(getTeamAnimationInFolderNextPage(args)),
    onFetchUsersImageFolders: args =>
      dispatch(fetchAllTeamAnimationFoldersIfNeeded(args)),
    onAddImageToFolder: args => dispatch(addTeamAnimationToFolder(args)),
    onDeleteUserTeamImage: args => dispatch(deleteTeamAnimation(args)),
    onDeleteImageFolder: args => dispatch(deleteTeamAnimationFolder(args)),
    onUpdateImageFolder: args => dispatch(updateTeamAnimationFolder(args)),
    removeImageFromFolder: args =>
      dispatch(removeTeamAnimationFromFolder(args)),
    onDeleteFolderAndGoToMyImages: args =>
      dispatch(deleteTeamAnimationFolderAndGoToBrandAnimations(args)),
    updateImage: args => dispatch(updateTeamAnimation(args)),
    showUpgradeModal: args => dispatch(showUpgradeModal(args)),
    getFolderForMedia: args => dispatch(getFolderForMedia(args)),
    refetchUserTeamImages: args => dispatch(refetchTeamAnimations(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandKitAnimationsListContainer);
