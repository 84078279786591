import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  moveToOrderDetails,
  moveTo
} from "state/ui/navigation/navigationActions";

import Orders from "./Orders";

import {
  currentTeamIdSelector,
  teamSelector
} from "../../../state/entities/teams/teamsSelectors";

import { getAllOrders } from "state/entities/orders/ordersActions";
import {
  ordersSelector,
  allOrdersSelector
} from "state/api/orders/ordersSelectors";

class OrdersContainer extends Component {
  render() {
    return <Orders {...this.props} />;
  }
}

function mapStateToProps(state) {
  const teamId = currentTeamIdSelector(state);
  const currentTeam = teamSelector({ state, teamId });
  const ordersApiState = ordersSelector({ state });
  const orders = allOrdersSelector({ state });

  return {
    user: state.currentUser,
    team: currentTeam,
    ordersApiState,
    orders
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getAllOrders: args => dispatch(getAllOrders(args)),
    moveToOrderDetails: args => dispatch(moveToOrderDetails(args)),
    changePage: path => dispatch(moveTo(path))
  };
};

OrdersContainer.propTypes = {
  user: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersContainer);
