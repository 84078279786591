import React, { Component } from "react";
import { Button, Loading, NoComponent } from "views/components";
import CaretSlimIcon from "views/components/icons/CaretSlimIcon";
import PadlockCurvedIcon from "views/components/icons/PadlockCurvedIcon";
import BillingSectionContainer from "./BillingSection/BillingSectionContainer";
import PATHS from "routes/paths";
import style from "./style.module.css";
import CartPreviewSectionContainer from "./CartPreviewSection/CartPreviewSectionContainer";
import PaymentSectionContainer from "./PaymentSection/PaymentSectionContainer";
import OrderSummaryContainer from "views/components/OrderSummary/OrderSummaryContainer";
import EmptyOrder from "./EmptyOrder";
import { noop } from "lib/lodash";
import { jsonStringEqual } from "lib/equalityUtils";
import SubPageLoading from "views/pages/SubPageLoading";

class Cart extends Component {
  static SECTIONS = ["Cart", "Billing", "Payment"];
  // account based subscriptions do not have a payment page
  static ACCOUNT_SECTIONS = ["Cart", "Billing"];
  constructor(props) {
    super(props);

    this.getCartSection = this.getCartSection.bind(this);
    this.setCurrentSection = this.setCurrentSection.bind(this);
    this.submitBillingAndShipping = this.submitBillingAndShipping.bind(this);
    this.getButtonDetails = this.getButtonDetails.bind(this);
    this.wrapperRef = React.createRef();

    // TODO: set this state to the original section, set to Billing here for development simplicity
    this.state = {
      currentSection: "Cart",
      CartFlowSectionComponent: CartPreviewSectionContainer,
      sections: props.isAccount ? Cart.ACCOUNT_SECTIONS : Cart.SECTIONS
    };
  }

  componentDidMount() {
    // collect the information for the current order
    this.props.fetchCartPageData();
  }

  componentDidUpdate(prevProps) {
    const {
      order,
      loading,
      currentBilling,
      currentShipping,
      isBillingAndShippingValid
    } = this.props;
    const {
      currentBilling: prevBilling,
      currentShipping: prevShipping
    } = prevProps;

    // Render empty state when no order or order designs after api call response
    if (
      !loading.order &&
      !loading.details &&
      (!order || !order.designs.length) &&
      this.state.currentSection !== "Empty"
    ) {
      this.setCurrentSection("Empty");
    }

    if (
      isBillingAndShippingValid &&
      (!jsonStringEqual(currentBilling, prevBilling) ||
        !jsonStringEqual(currentShipping, prevShipping))
    ) {
      this.props.updateShippingAndBillingData();
    }
  }

  /* Function to switch the cart flow section - returns a jsx component */
  getCartSection(currentSection) {
    switch (currentSection) {
      case "Cart": {
        return CartPreviewSectionContainer;
      }
      case "Billing": {
        return BillingSectionContainer;
      }
      case "Payment": {
        return PaymentSectionContainer;
      }
      case "Empty": {
        return EmptyOrder;
      }
      default: {
        return () => <div>NO SECTION COULD BE MATCHED</div>;
      }
    }
  }

  /**
   * @desc submit the billing and shipping details from redux state (requires no local data in component)
   */
  async submitBillingAndShipping() {
    // update the billing an shipping values
    Promise.all(this.props.updateShippingAndBillingData()).then(() => {
      // only move to next page if we know the update succeeded
      this.setCurrentSection("Payment");
    });
  }

  validateDesigns() {
    const { order } = this.props;

    if (!order || !order.designs || !(order.designs.length >= 1)) {
      return false;
    }

    return order.designs.reduce(
      (previousValid, design) =>
        previousValid &&
        design.printItems &&
        design.printItems.length >= 1 &&
        !design.isUpdatingDesignVersion &&
        !design.isUpdating &&
        !design.isDeleting,
      true
    );
  }

  /**
   * @desc get an object containing the information for the button at the bottom of the left section
   */
  getButtonDetails() {
    const { currentSection, sections } = this.state;
    const {
      isBillingAndShippingValid,
      token,
      isAccount,
      paymentType,
      order,
      changePage
    } = this.props;
    const currentSelectionIndex = sections.indexOf(currentSection);

    const buttonDetails = {
      onClick:
        currentSelectionIndex === sections.length - 1
          ? noop
          : () => this.setCurrentSection(sections[currentSelectionIndex + 1]),
      label:
        currentSelectionIndex === sections.length - 1
          ? "Complete Order"
          : `Continue to ${sections[currentSelectionIndex + 1]}`,
      icon:
        currentSelectionIndex === sections.length - 1
          ? props => <PadlockCurvedIcon {...props} size="16px" isFilled />
          : props => <CaretSlimIcon {...props} size={10} direction="right" />,
      theme:
        currentSelectionIndex === sections.length - 1
          ? "greenSolid"
          : "blueSolid",
      disabled: !this.validateDesigns()
    };

    switch (currentSection) {
      case "Billing": {
        if (!isAccount) {
          // for account based subs this will have to complete the order
          buttonDetails.onClick = isBillingAndShippingValid
            ? this.submitBillingAndShipping
            : noop;
          buttonDetails.disabled = !isBillingAndShippingValid;
        } else {
          // set up a different action for account subs
          buttonDetails.label = "Complete Order";
          buttonDetails.onClick = this.props.createOrderPayment;
          buttonDetails.disabled = !isBillingAndShippingValid;
        }
        break;
      }
      case "Payment": {
        const isValidCreditPayment = paymentType === "credit" && !!token;
        const isValidPaypalPayment = paymentType === "paypal";
        const isEntryValid = isValidCreditPayment || isValidPaypalPayment;
        const orderCompletionFunction =
          paymentType === "credit" ? this.props.createOrderPayment : noop;
        // when all details are valid we can submit the payment for the order
        buttonDetails.onClick = isEntryValid
          ? orderCompletionFunction /* submit the payment */
          : noop;
        buttonDetails.disabled = !isEntryValid || order.isCreatingPayment;
        break;
      }
      case "Empty": {
        buttonDetails.onClick = () => changePage(PATHS.catalogueEasil);
        buttonDetails.label = "View Templates";
        buttonDetails.disabled = false;
        buttonDetails.icon = NoComponent;
        break;
      }
      default: {
        break;
      }
    }

    return buttonDetails;
  }

  /**
   * @desc set the current section in state along with its render jsx component
   */
  setCurrentSection(section) {
    if (section === this.state.currentSection) return;
    this.setState(
      {
        currentSection: section,
        CartFlowSectionComponent: this.getCartSection(section)
      },
      () => {
        if (section !== "Empty") {
          this.wrapperRef.current.scrollIntoView();
        }
      }
    );
  }

  render() {
    const { loading, paymentType } = this.props;
    const { CartFlowSectionComponent, sections, currentSection } = this.state;

    if (loading.order) {
      return (
        <div className={style.wrapper}>
          <SubPageLoading />
        </div>
      );
    }

    const currentSectionIndex = sections.indexOf(currentSection);

    const buttonDetails = this.getButtonDetails();

    const isPaypal = paymentType === "paypal" && currentSection === "Payment";

    return (
      <div className={style.wrapper} ref={this.wrapperRef}>
        <div className={style.cartFlowWrapper}>
          <div className={style.cartFlowCrumbs}>
            {sections.map((section, index) => (
              <>
                <p
                  onClick={() => {
                    if (index < currentSectionIndex) {
                      this.setCurrentSection(section);
                    }
                  }}
                  data-is-selected={currentSection === section}
                  data-is-past-section={index <= currentSectionIndex}
                >
                  {section}
                </p>
                {index !== sections.length - 1 && (
                  <CaretSlimIcon
                    color="#7a7d81"
                    size="10px"
                    direction="right"
                  />
                )}
              </>
            ))}
          </div>

          {/* START CART FLOW SECTION */}
          <div className={style.cartFlowSectionWrapper}>
            {loading.order || loading.details ? (
              <div className={style.loadingSection}>
                <Loading />
              </div>
            ) : (
              <CartFlowSectionComponent isStillOnPage={PATHS.isCartPage} />
            )}
          </div>
          <div className={style.nextButtonWrapper}>
            <Button
              theme={"grayBackgroundXDarkGrayFont"}
              className={style.backButton}
              onClick={this.props.moveToWorkspace}
            >
              <CaretSlimIcon {...this.props} size={10} direction="left" />
              Back to Workspace
            </Button>
            <div className={style.buttonHoverWrapper} data-is-paypal={isPaypal}>
              <Button
                onClick={buttonDetails.onClick}
                theme={buttonDetails.theme}
                disabled={buttonDetails.disabled}
                className={style.continueButton}
                style={isPaypal ? { pointerEvents: "none" } : {}}
              >
                {this.props.order && this.props.order.isCreatingPayment ? (
                  <div className={style.buttonLoading}>
                    <Loading hexColor="#ffffffff" size="20px" />
                  </div>
                ) : (
                  <>
                    {buttonDetails.label}
                    <buttonDetails.icon color="#ffffff" />
                  </>
                )}
              </Button>
              <div className={style.buttonBacking} />
              <div
                id="paypal-button-container"
                className={style.paypalButton}
                style={isPaypal ? {} : { display: "none" }}
              />
            </div>
          </div>
          {/* END CART FLOW SECTION */}
        </div>
        <div className={style.orderSummaryWrapper}>
          <div className={style.orderSummaryRightGuttering}>
            {/* START ORDER SUMMARY */}
            <OrderSummaryContainer />
            {/* END ORDER SUMMARY */}
          </div>
        </div>
      </div>
    );
  }
}

export default Cart;
