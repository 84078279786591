import React, { Component } from "react";
import { connect } from "react-redux";
import { orderByIdWithFullDesignSelector } from "state/entities/orders/ordersSelectors";
import { printPricingGroupedByDesignIdWithDigitalSelector } from "state/entities/printPricing/printPricingSelectors";
import { currentOrderIdSelector } from "state/ui/cart/cartSelectors";
import OrderDesigns from "./OrderDesigns";

class OrderDesignsContainer extends Component {
  render() {
    return <OrderDesigns {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const orderId = ownProps.orderId || currentOrderIdSelector(state);
  const order = orderByIdWithFullDesignSelector({ state, orderId });
  const orderPrintOptions = printPricingGroupedByDesignIdWithDigitalSelector(
    state
  );

  return {
    order,
    orderPrintOptions
  };
};

export default connect(
  mapStateToProps,
  null
)(OrderDesignsContainer);
