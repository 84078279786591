import { createSelector } from "reselect";
import { getPath, getFilters } from "lib";
import { getParameterByName } from "lib/queryStringUtils";
import PATHS from "routes/paths";
import { getTermKey } from "./helpers";

export const resourcesStoreAddress = {
  [PATHS.workspaceDrafts]: `personal.drafts`,
  [PATHS.workspaceApproved]: `personal.approvals.approved.sizes`,
  [PATHS.workspaceArchived]: `personal.archived`,
  [PATHS.workspaceFolders]: `personal.folders`,
  [PATHS.workspaceDraftsSearch]: `personal.search`,
  [PATHS.workspaceShared]: `personal.shared.sizes`,
  [PATHS.catalogueAll]: `team.all`,
  [PATHS.catalogueTemplates]: `team.templates`,
  [PATHS.catalogueEasil]: `team.easil`,
  [PATHS.catalogueFolders]: `team.folders`,
  [PATHS.catalogueArchived]: `team.archived`,
  [PATHS.catalogueEasilSearch]: `team.search`,
  [PATHS.catalogueTemplatesSearch]: `team.search`,
  [PATHS.workspaceBrandManagerApproved]: `team.approved.sizes`,
  [PATHS.workspaceBrandManagerApprovedSearch]: `team.approved.search`,
  [PATHS.cataloguePurchased]: `purchased.templates`
};

const locationSelector = state => state.router.location;
const locationPathnameSelector = ({ state }) =>
  locationSelector(state).pathname;
const locationSearchSelector = ({ state }) => locationSelector(state).search;
const approvedSearchResultsSelector = ({ state }) =>
  getPath(state, "api.designs.team.approved.search.results");
const designsApiSelector = ({ state }) => state.api.designs;

export const allDraftsInFolderIdsSelector = ({
  state,
  folderId,
  size = "all"
}) =>
  Object.values(
    getPath(
      state,
      `api.designs.personal.folders.${folderId}.sizes.${size}.pages`,
      {}
    )
  ).reduce((ids, page) => [...ids, ...getPath(page, "ids", [])], []);

const processApiDesignsResourceSelector = (
  locationPathname,
  locationSearch,
  approvedSearchResults,
  designsApi,
  resourceOverride
) => {
  const resource = resourceOverride || locationPathname;

  /* These templates are nested one more level, to the specific size */
  if (
    resource === PATHS.catalogueEasil ||
    resource === PATHS.workspaceShared ||
    resource === PATHS.workspaceApproved ||
    resource === PATHS.workspaceBrandManagerApproved ||
    resource === PATHS.cataloguePurchased
  ) {
    const size = getParameterByName("size", locationSearch) || "all";

    const sizeStoreAddress = `${resourcesStoreAddress[resource]}.${size}`;

    return getPath(designsApi, sizeStoreAddress) || {};
  }

  // handles the workspace drafts redux structure with size breakdown (should become the new structure in the future)
  if (resource === PATHS.workspaceDrafts) {
    const size = getParameterByName("size", locationSearch) || "all";

    const sizeStoreAddress = `${resourcesStoreAddress[resource]}.sizes.${size}`;

    return getPath(designsApi, sizeStoreAddress) || {};
  }

  if (
    resource === PATHS.workspaceFolders ||
    resource === PATHS.catalogueFolders
  ) {
    const size = getParameterByName("size", locationSearch) || "all";
    const folderId = getParameterByName("folderId", locationSearch);

    return getPath(
      designsApi,
      `${resourcesStoreAddress[resource]}.${folderId}.sizes.${size}`,
      {}
    );
  }

  const isDesignSearch = PATHS.isDesignSearch(locationPathname);
  if (isDesignSearch) {
    return getDesignSearch(
      locationPathname,
      locationSearch,
      approvedSearchResults,
      designsApi
    );
  } else {
    return getPath(designsApi, resourcesStoreAddress[resource]) || {};
  }
};

export const apiDesignsResourceSelector = createSelector(
  [
    locationPathnameSelector,
    locationSearchSelector,
    approvedSearchResultsSelector,
    designsApiSelector,
    ({ resource }) => resource
  ],
  processApiDesignsResourceSelector
);

const getTeamApprovedSearch = (locationSearch, approvedSearchResults) => {
  const { size, term } = getFilters(locationSearch);

  const termKey = getTermKey({ term, size });

  return approvedSearchResults[termKey];
};

const getDesignSearch = (
  locationPathname,
  locationSearch,
  approvedSearchResults,
  designsApi
) => {
  const resource = locationPathname;

  if (resource === PATHS.workspaceBrandManagerApprovedSearch) {
    return getTeamApprovedSearch(locationSearch, approvedSearchResults) || {};
  }

  const urlParams = new URLSearchParams(locationSearch);
  const term = urlParams.get("term");
  const size = urlParams.get("size");
  let searchType = urlParams.get("searchType");
  const context = urlParams.get("context");

  if (resource === PATHS.catalogueTemplatesSearch && size) {
    // catalogue templates search has no all tab so don't even consider it
    searchType = "size";
  }

  if (!term || !context) {
    return {};
  }

  const contextToStoreMap = {
    workspace: "personal",
    templates: "team"
  };

  const storeAddress = contextToStoreMap[context];

  if (!storeAddress) {
    return {};
  }

  const store = designsApi[storeAddress].search;

  const termKey = getTermKey({
    term,
    size,
    searchType: !size ? "all" : searchType
  });

  return store.results[termKey] || {};
};

const pagePassThrough = ({ page }) => page;

const processApiDesignsPageSelector = (resourceState, page) => {
  if (!resourceState.pages) {
    return null;
  }
  return resourceState.pages[page];
};

export const apiDesignsPageSelector = createSelector(
  [apiDesignsResourceSelector, pagePassThrough],
  processApiDesignsPageSelector
);

const processApiDesignsIsLoadingSelector = resourceState => {
  if (!resourceState.pages) {
    return false;
  }

  return Object.keys(resourceState.pages).some(
    page => resourceState.pages[page].isFetching
  );
};

export const apiDesignsIsLoadingSelector = createSelector(
  [apiDesignsResourceSelector],
  processApiDesignsIsLoadingSelector
);

export const teamDesignApiPageSelector = (state, page) => {
  return state.api.designs.team.templates.pages[page];
};
