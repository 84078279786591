import React from "react";
import style from "./style.module.css";
import { Button } from "views/components";
import SadFileIcon from "views/components/icons/SadFileIcon";
import EasilIcon from "views/components/icons/EasilIcon";
import { userTracker } from "lib";

const ErrorBlock = ({ errorMessage, errorTitle, errorMessageClassName }) => (
  <React.Fragment>
    <h1 className={style.heading}>{errorTitle}</h1>
    <p className={errorMessageClassName}>
      {errorMessage}
      <br />
    </p>
  </React.Fragment>
);

class FullErrorPage extends React.Component {
  componentDidMount() {
    const {
      currentPlanCode,
      designId = window.location.pathname.split("/").pop(),
      errorType
    } = this.props;

    if (!errorType || errorType === "AuthorizationError") {
      return;
    }

    userTracker.track({
      event: userTracker.events.editorCrash,
      properties: {
        design_id: designId,
        plan: currentPlanCode
      }
    });
  }

  render() {
    const {
      onRedirectTo,
      buttonMessage = "Back to Easil",
      errorMessage = "The page you’re looking for can’t be found. Let’s get you back home.",
      errorTitle = "Oops. Looks like we've lost you.",
      redirectLocation = "/",
      errorMessageClassName,
      Icon = SadFileIcon
    } = this.props;

    return (
      <div className={style.center} data-testid="FullErrorPage">
        {/* the easil icon should always redirect to home */}
        <div onClick={() => onRedirectTo("/")}>
          <EasilIcon size="30.5" />
        </div>
        <Icon size="85" />
        <ErrorBlock
          errorMessage={errorMessage}
          errorTitle={errorTitle}
          errorMessageClassName={errorMessageClassName}
        />
        <Button
          onClick={() => onRedirectTo(redirectLocation)}
          theme="blueSolid"
        >
          {buttonMessage}
        </Button>
      </div>
    );
  }
}

export default FullErrorPage;
