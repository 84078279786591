class PubSub {
  constructor() {
    this.subscribers = new Map();
  }

  subscribe(name, fn, id) {
    if (typeof fn !== "function")
      throw new Error("Second parameter must be a function");

    if (typeof name !== "string")
      throw new Error("First parameter must be a string");

    if (!this.subscribers.has(name)) {
      this.subscribers.set(name, new Map());
    }

    if (fn.name === "") {
      throw new Error("Function cannot be annonymous");
    } else {
      this.subscribers.get(name).set(id, fn);
    }
  }

  unsubscribe(name, id) {
    if (this.subscribers.has(name)) {
      if (this.subscribers.get(name).get(id)) {
        this.subscribers.get(name).delete(id);

        const size = this.subscribers.get(name).size;

        if (size === 0) {
          this.subscribers.delete(name);
        }
      } else {
        throw new Error(`Subscriber "${id}" not found`);
      }
    } else {
      throw new Error(`Publisher "${name}" not found`);
    }
  }

  publish(name, data) {
    if (this.subscribers.has(name)) {
      this.subscribers.get(name).forEach(fn => fn(data));
    }
  }
}

export default PubSub;
