import { Logger } from "lib";
import {
  calculateScaledNewDimensions,
  calculateMovedNewDimensions,
  calculateCropMaskScale
} from "views/components/Editor/CropLayer/ImageCropping/ImageCroppingPreview/ImageCroppingPreviewCalculations";
import { getPageHtmlNode } from "views/components/Editor/utils";

const EditorVectorCropOps = {
  startCropMaskResize() {
    Logger.info("Editor.startCropMaskResize called");

    this.setState({
      isCropping: true
    });
  },

  finishCropMaskResize({
    differenceFromInitialOffset,
    dragItem,
    adjustmentOffset
  }) {
    Logger.info("Editor.finishVectorCropMoving called");

    const { zoom, selectedItems } = this.state;

    let vectorElement = this.getElementWithPreview(selectedItems[0].itemId);

    vectorElement = {
      ...vectorElement
      // angle: 0
    };

    const pageId = selectedItems[0].pageId;

    const pageDiv = getPageHtmlNode(pageId);
    const pageOffset = pageDiv.getBoundingClientRect();

    const { mask, height, width, top, left } = calculateCropMaskScale.calc({
      vectorElement,
      dragItem,
      differenceFromInitialOffset,
      pageOffset,
      zoom,
      adjustmentOffset
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          ...selectedItems[0].preview,
          mask,
          height,
          width,
          top,
          left
        }
      }
    ];

    this.setState({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  },

  startVectorCropMode() {
    Logger.info("Editor.startVectorCropMode called");

    const { context, actionbar } = this.state;

    this.setState({
      actionbar: {
        ...actionbar,
        buttonActive: "crop"
      },
      context: {
        ...context,
        isCroppingVector: true
      }
    });
  },

  cancelVectorCropMode() {
    Logger.info("Editor.cancelVectorCropMode called");

    const { context, actionbar, selectedItems } = this.state;

    const selectedItemsUpdated = selectedItems.map(item => ({
      ...item,
      preview: {}
    }));

    this.setState({
      actionbar: {
        ...actionbar,
        buttonActive: null
      },
      context: {
        ...context,
        isCroppingVector: false
      },
      selectedItems: selectedItemsUpdated
    });
  },

  startVectorCropMoving() {
    Logger.info("Editor.startVectorCropMoving called");

    this.setState({
      isCropping: true
    });
  },

  finishVectorCropMoving({ differenceFromInitialOffset }) {
    Logger.info("Editor.finishVectorCropMoving called");

    const { zoom, selectedItems } = this.state;

    let vectorElement = this.getElementWithPreview(selectedItems[0].itemId);

    const { mask } = calculateMovedNewDimensions({
      imageElement: vectorElement,
      differenceFromInitialOffset,
      zoom
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          ...selectedItems[0].preview,
          mask
        }
      }
    ];
    this.setState({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  },

  startVectorCropResizing({ pageId }) {
    Logger.info("Editor.startVectorCropResizing called");

    this.setState({
      isCropping: true
    });
  },

  finishVectorCropResizing({
    handlerInitialPosition,
    anchorPoint,
    differenceFromInitialOffset
  }) {
    Logger.info("Editor.finishVectorCropResizing called");

    const { zoom, selectedItems } = this.state;

    let vectorElement = this.getElementWithPreview(selectedItems[0].itemId);

    vectorElement = {
      ...vectorElement
      // angle: 0
    };

    const pageId = selectedItems[0].pageId;
    const pageDiv = getPageHtmlNode(pageId);

    const pageOffset = pageDiv.getBoundingClientRect();

    const {
      scale,
      mask,
      top,
      left,
      height,
      width
    } = calculateScaledNewDimensions({
      imageElement: vectorElement,
      differenceFromInitialOffset,
      zoom,
      dragItem: { anchorPoint, handlerInitialPosition },
      pageOffset
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          scale,
          mask,
          top,
          left,
          height,
          width
        }
      }
    ];

    this.setState({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  },

  applyCrop() {
    Logger.info("Editor.EditoVectorCropOps.applyCrop  called");

    const { actionbar, designData, selectedItems, context } = this.state;

    const selectedItem = selectedItems[0];

    if (!selectedItem.preview) {
      Logger.info(
        "Editor.EditorVectorCropOps - No changes to be applied, cancelling it"
      );
      this.state.cancelVectorCropMode();
      return;
    }

    const currentItem = designData.elements[selectedItem.itemId];
    const scale = currentItem.scale;
    this.updateStateAndSave({
      actionbar: {
        ...actionbar,
        buttonActive: null
      },
      designData: designData.updateElementsAttribute({
        elementsId: [selectedItem.itemId],
        attributes: { scale, ...selectedItems[0].preview } // always give a scale but prefer preview scale
      }),
      selectedItems: selectedItems.map(item => ({
        ...item,
        preview: {}
      })),
      context: {
        ...context,
        isCroppingVector: false
      }
    });
  }
};

export default EditorVectorCropOps;
