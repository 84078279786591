import { UPDATE_SIDE_PANEL } from "state/ui/sidePanel/sidePanelActionTypes";

export const initialState = {
  isExpanded: true,
  optionSelected: "resize",
  width: "384px"
};

const sidePanelReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SIDE_PANEL:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default sidePanelReducers;
