import React from "react";
import { connect } from "react-redux";
import ResetPasswordWithToken from "./ResetPasswordWithToken";
import {
  validateResetPasswordToken,
  updatePassword
} from "state/ui/resetPassword/resetPasswordActions";

export const ResetPasswordWithTokenContainer = ({
  onValidateResetPasswordToken,
  onUpdatePassword,
  isSubmitting,
  isSuccess,
  isFetching,
  isValidToken,
  errors,
  userId,
  token
}) => {
  return (
    <ResetPasswordWithToken
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      isFetching={isFetching}
      isValidToken={isValidToken}
      token={token}
      userId={userId}
      errors={errors}
      onValidateResetPasswordToken={onValidateResetPasswordToken}
      onUpdatePassword={onUpdatePassword}
    />
  );
};

const mapStateToProps = state => ({
  isSubmitting: state.ui.resetPassword.isSubmitting,
  isFetching: state.ui.resetPassword.isFetching,
  isSuccess: state.ui.resetPassword.isSuccess,
  isValidToken: state.ui.resetPassword.isValidToken,
  userId: state.ui.resetPassword.userId,
  errors: state.ui.resetPassword.errors
});

const mapDispatchToProps = dispatch => ({
  onValidateResetPasswordToken: args =>
    dispatch(validateResetPasswordToken(args)),
  onUpdatePassword: args => dispatch(updatePassword(args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordWithTokenContainer);
