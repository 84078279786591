import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ChangePasswordModal from "./ChangePasswordModal";
import { changeCurrentUserPasswordV2 } from "state/currentUser/currentUserActions";
import { updatePasswordSelector } from "state/api/updatePassword";

export class ChangePasswordModalContainer extends Component {
  render() {
    return <ChangePasswordModal {...this.props} />;
  }
}

const mapStateToProps = state => {
  const updatePasswordState = updatePasswordSelector(state);
  return {
    updatePasswordState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeCurrentUserPassword: args =>
      dispatch(changeCurrentUserPasswordV2(args))
  };
};

ChangePasswordModalContainer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeCurrentUserPassword: PropTypes.func
};

ChangePasswordModalContainer.displayName = "ChangePasswordModalContainer";
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModalContainer);
