import * as types from "./editorErrorModalTypes";
import { LOCATION_CHANGE } from "react-router-redux";
import PATHS from "routes/paths";

export const initState = {
  isVisible: false,
  errorType: null,
  userId: null,
  path: null
};

const editorErrorModalReducers = (state = initState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (
        action.pathname &&
        PATHS.isEditorPath(action.pathname) &&
        !PATHS.isEditorPath(action.payload.pathname)
      ) {
        return initState;
      }
      return state;
    }

    case types.SHOW_EDITOR_ERROR_MODAL: {
      // make the modal visible
      return {
        ...state,
        isVisible: true,
        errorType: action.errorType,
        user: action.user,
        path: action.path
      };
    }

    case types.HIDE_EDITOR_ERROR_MODAL: {
      // hide the modal
      return initState;
    }

    default:
      return state;
  }
};

export default editorErrorModalReducers;
