import { fetchFonts } from "lib/fonts";
import { isEmpty } from "lib";
import * as types from "./userTeamFontsTypes";
import { noop } from "lib";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { asyncAction } from "lib/asyncHelpers";

const setIsProcessing = ({ isProcessing }) => ({
  type: types.USER_FONTS_IS_PROCESSING,
  isProcessing
});

export const fetchUserTeamFonts = ({
  teamId,
  userId,
  page = 1,
  onSuccess = noop
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      types: [
        types.USER_TEAM_FONTS_REQUEST,
        types.USER_TEAM_FONTS_REQUEST_SUCCESS,
        types.USER_TEAM_FONTS_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/fonts`,
      request: {
        count: 100,
        page: page,
        pageSize: getState().api.userTeamFonts.pageSize
      },
      schema: schemas.USER_TEAM_FONTS_ARRAY,
      onSuccess: response => {
        if (response.entities) {
          const userTeamFontMap = response.entities.userTeamFont;
          const fontFamilies = [];
          const urls = [];
          Object.keys(userTeamFontMap).forEach(id => {
            let font = userTeamFontMap[id];
            fontFamilies.push(font.fontFamilyName);
            urls.push(font.stylesheetUrl);
          });
          fetchFonts(urls, fontFamilies);
          dispatch(
            fetchUserTeamFonts({ teamId, userId, page: page + 1, onSuccess })
          );
        } else {
          onSuccess();
        }
      }
    }
  });
};

export const fetchUserTeamFontsPage = ({ teamId, userId, page = 1 }) => (
  dispatch,
  getState
) => {
  dispatch({
    [CALL_API]: {
      types: [
        types.USER_TEAM_FONTS_REQUEST,
        types.USER_TEAM_FONTS_REQUEST_SUCCESS,
        types.USER_TEAM_FONTS_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/fonts`,
      request: {
        page: page,
        pageSize: getState().api.userTeamFonts.pageSize
      },
      schema: schemas.USER_TEAM_FONTS_ARRAY
    }
  });
};

export const shouldFetchUserTeamFonts = state => {
  return (
    !state.api.userTeamFonts.isProcessing &&
    isEmpty(state.api.userTeamFonts.pages)
  );
};

export const fetchUserTeamFontsIfNeeded = ({ teamId, userId, page }) => (
  dispatch,
  getState
) => {
  if (shouldFetchUserTeamFonts(getState())) {
    dispatch(setIsProcessing({ isProcessing: true }));
    dispatch(
      fetchUserTeamFonts({
        teamId,
        userId,
        page,
        onSuccess: () => dispatch(setIsProcessing({ isProcessing: false }))
      })
    );
  }
};

export const uploadFont = ({
  fontFile,
  userId,
  teamId,
  onSuccess = noop,
  resolve = noop,
  reject = noop
}) => (dispatch, getState) => {
  dispatch({
    [CALL_API]: {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/fonts`,
      request: {
        body: {
          userId,
          teamId,
          fontFile
        }
      },
      types: [
        types.USER_UPLOAD_FONT_REQUEST,
        types.USER_UPLOAD_FONT_REQUEST_SUCCESS,
        types.USER_UPLOAD_FONT_REQUEST_FAILURE
      ],
      schema: schemas.USER_FONT_UPLOAD,
      onSuccess: response => {
        const userTeamFontMap = response.entities.userFontUpload;
        const fontFamilies = [];
        const urls = [];
        Object.keys(userTeamFontMap).forEach(id => {
          let font = userTeamFontMap[id];
          fontFamilies.push(font.fontFamilyName);
          urls.push(font.stylesheetUrl);
        });
        fetchFonts(urls, fontFamilies);
        onSuccess(response);
        resolve(response);
      },
      onFailure: response => {
        reject(response);
      }
    }
  });
};

export const asyncUploadFont = asyncAction(uploadFont);

export const removeFont = ({
  teamId,
  userId,
  fontId,
  onSuccess = noop
}) => dispatch => {
  dispatch({
    [CALL_API]: {
      types: [
        types.USER_TEAM_FONT_REMOVE_REQUEST,
        types.USER_TEAM_FONT_REMOVE_REQUEST_SUCCESS,
        types.USER_TEAM_FONT_REMOVE_REQUEST_FAILURE
      ],
      method: "DELETE",
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/fonts/${fontId}`,
      schema: schemas.NONE,
      extra: {
        fontId
      },
      onSuccess
    }
  });
};

export const deleteUserTeamFonts = (response, onSuccess = noop) => (
  dispatch,
  getState
) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  const deletionId = response.id;
  const fontIds = response.fontIds;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      types: [
        types.USER_TEAM_FONTS_DELETE_REQUEST,
        types.USER_TEAM_FONTS_DELETE_REQUEST_SUCCESS,
        types.USER_TEAM_FONTS_DELETE_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/fonts/deletions/${deletionId}`,
      schema: schemas.NONE,
      extra: {
        fontIds
      },
      onSuccess: () => onSuccess()
    }
  });
};

export const createDeleteUserTeamFontsRequest = ({
  mediaIds,
  onSuccess = noop
}) => (dispatch, getState) => {
  const teamId = getState().ui.currentTeam.id;
  const userId = getState().currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      types: [
        types.USER_TEAM_FONTS_CREATE_DELETE_REQUEST,
        types.USER_TEAM_FONTS_CREATE_DELETE_REQUEST_SUCCESS,
        types.USER_TEAM_FONTS_CREATE_DELETE_REQUEST_FAILURE
      ],
      request: {
        body: {
          userId,
          teamId,
          fontIds: mediaIds
        }
      },
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/fonts/deletions`,
      schema: schemas.NONE,
      onSuccess: response => {
        dispatch(deleteUserTeamFonts(response, onSuccess));
      }
    }
  });
};
