import React from "react";
import { toast, Slide, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastWithButton, ToastWithoutButton } from "views/components/toast";

import style from "./libStyles.module.css";

/**
 * @desc adds a ToastContainer to the dom without requiring manual mounting
 */
const initialize = () => {
  toast.configure({
    autoClose: 8000,
    draggable: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    style: {
      width: "450px",
      bottom: "0.25em",
      right: "2em",
      animateDuration: "800ms"
    },
    closeButton: false,
    transition: Slide
  });
};

/**
 * @desc converts a toast type string to a toast content component
 * @param {string} type - a string reference to the type of toast component to be rendered
 * @param {object} props - the arguments to be passed into the toast component
 * @param {component} props.textComponent - the text component to be inserted into the toast component
 * @param {string} props.buttonLabel - the label to place on a button in the toast if one is present
 * @param {string} props.icon - a string reference for an icon to be displayed for the toast @example "success" will render a tick icon
 * @param {function} props.action - a function which will be used as the onClick for a button in the toast
 * @returns {component} - returns a toast content component
 */
const getToastComponent = ({ type, ...props }) => {
  switch (type) {
    case "noButton": {
      const { textComponent, icon } = props;
      return <ToastWithoutButton textComponent={textComponent} icon={icon} />;
    }
    case "button":
    default:
      const { buttonLabel, textComponent, icon, action } = props;
      return (
        <ToastWithButton
          textComponent={textComponent}
          buttonLabel={buttonLabel}
          icon={icon}
          action={action}
        />
      );
  }
};

/**
 * @desc maps a string reference to a transition animation
 * @param {string} type - a string reference to a transition animation type
 */
const getTransition = ({ type }) => {
  switch (type) {
    case "Flip":
      return Flip;
    case "Slide":
    default:
      return Slide;
  }
};

/**
 * @desc creates a new toast
 * @param {string} toastId - a unique identifier for the toast, used to reference the toast after creation
 * @param {component} textComponent - a jsx component to be rendered as the toasts text section
 * @param {string} buttonLabel - the label to be placed on the toasts button
 * @param {string} type - the type of toast, @example "button" will build a toast with a button
 * @param {string} icon - the type of icon to be used, @example "success" displays a tick icon
 * @param {function} onOpen - a function to run when the toast has completed opening
 * @param {function} onClose - a function to run when the toast is closed using its internal close, does not get triggered with removeToast or removeAllToast
 * @param {number} duration - the timer for the toast to auto-close, if not provided toast will not auto-close
 * @param {function} action - the action that will be triggered when clicking on the toasts button
 */
const makeToast = ({
  toastId,
  textComponent,
  buttonLabel = "Team Templates",
  type = "button",
  icon = "success",
  onOpen,
  onClose,
  duration,
  action,
  className
}) => {
  const toastComponent = getToastComponent({
    type,
    buttonLabel,
    textComponent,
    icon,
    action
  });
  toast(toastComponent, {
    onOpen,
    onClose,
    toastId,
    autoClose: duration || false,
    className: `${style.toasterToast} ${className}`
  });
};

/**
 * @desc updates a currently active toast
 * @param {string} toastId - the id for the toast that is to be updated
 * @param {component} textComponent - a jsx component to be rendered as the toasts text section
 * @param {string} buttonLabel - the label to be placed on the toasts button
 * @param {string} type - the type of toast, @example "button" will build a toast with a button
 * @param {string} icon - the type of icon to be used, @example "success" displays a tick icon
 * @param {function} onOpen - a function to run when the toast has completed opening
 * @param {function} onClose - a function to run when the toast is closed using its internal close, does not get triggered with removeToast or removeAllToast
 * @param {number} duration - the timer for the toast to auto-close, if not provided toast will not auto-close
 * @param {function} action - the action that will be triggered when clicking on the toasts button
 * @param {string} transitionType - a string reference to a transition animation to be used with the toast, @example "Slide" for the Slide transition animation
 */
const updateToast = ({
  toastId,
  textComponent,
  buttonLabel = "Team Templates",
  type = "button",
  icon = "success",
  onOpen,
  onClose,
  duration,
  action,
  transitionType
}) => {
  const toastComponent = getToastComponent({
    type,
    buttonLabel,
    textComponent,
    icon,
    action
  });
  const transition = getTransition({ type: transitionType });
  return toast.update(toastId, {
    render: toastComponent,
    onOpen,
    onClose,
    toastId,
    autoClose: duration || false,
    className: style.toasterToast,
    transition
  });
};

/**
 * @desc dismisses a single toast
 * @param {string} toastId - the id for the toast that is to be removed
 */
const removeToast = ({ toastId }) => {
  return toast.dismiss(toastId);
};

/**
 * @desc dismisses all currently active toasts
 */
const removeAllToast = () => {
  return toast.dismiss();
};

export const toaster = {
  initialize,
  makeToast,
  updateToast,
  removeToast,
  removeAllToast
};

export default toaster;
