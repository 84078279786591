import React, { Component } from "react";
import style from "./style.module.css";
import { InputTransparent } from "views/components";
import { inRange, clamp } from "lib";
import { throttle, keyCodes } from "lib";

class InputNumber extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = throttle(this.handleInputChange.bind(this), 200);
    this.updateField = this.updateField.bind(this);
    this.state = {};
  }

  componentDidMount() {
    const { isBlankAllowed = false, currentValue, min, max } = this.props;

    if (isBlankAllowed && !currentValue) {
      this.setState({
        fieldValue: ""
      });
    } else {
      this.setState({
        fieldValue: clamp(currentValue, min || -9999999, max || 9999999)
      });
    }
  }

  /**
   * @desc updates the fieldValue of the components state
   * @param {Number} value - the number that the state fieldValue should be set to
   */
  updateField(value) {
    this.setState(Object.assign({}, this.state, { fieldValue: value }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentValue !== this.props.currentValue) {
      this.updateField(
        clamp(
          this.props.currentValue,
          this.props.min || -9999999,
          this.props.max || 9999999
        )
      );
    }
  }

  /**
   * @desc Takes a string input from the text field and checks the validity of it
   * @param {Number} value - input to check the validity of
   * @returns {Object} result is an object with a boolean field 'valid' indicating the validity
   * of the input and an optional setValue field with a replacement value for the input
   */
  checkValidity(value) {
    const { min, max } = this.props;

    /* if not a number */
    if (isNaN(value)) return { valid: false };

    /* if outside of bounds */
    if (
      typeof min === "number" &&
      typeof max === "number" &&
      !inRange(value, min, max)
    )
      return {
        valid: false,
        setValue: clamp(value, min || -9999999, max || 9999999)
      };

    return { valid: true };
  }

  /**
   * @desc takes an input value and attempts to convert and validate it as a number before passing it to a handler function
   * @param {String} value - a string input from the user
   * @param {Function} handler - a function that the number formatted input will be passed to if deemed valid
   * @param {Boolean} forceUpdate - if the update should go ahead regardless of if the currentvalue equals the input value
   */
  handleInputChange(value, handler, forceUpdate) {
    const { currentValue } = this.props;

    const valueAsNum = value !== "" ? Number(value) : NaN;

    /* the values are the same, no need to do anything unless the update is forced */
    if (valueAsNum === currentValue && !forceUpdate) return;

    /* if ends with a dot do nothing */
    if (value.toString().match(/\d*\.$/)) return handler(valueAsNum);

    const validity = this.checkValidity(valueAsNum);

    /* if the value is not valid return to prop value */
    if (!validity.valid) {
      const setValue =
        validity.setValue || validity.setValue === 0
          ? validity.setValue
          : currentValue;
      this.updateField(setValue);
      return handler(setValue);
    }
    return handler(value);
  }

  onKeyDown(event) {
    /* We stop propagation to prevent elements to be deleted from canvas */
    if (keyCodes.isBlackListed(event.keyCode)) {
      event.stopPropagation();
    }

    /* Take up and down keystrokes for incrementing and decrementing the value */
    if (
      event.keyCode === keyCodes.upKey ||
      event.keyCode === keyCodes.downKey
    ) {
      event.preventDefault();
      let value;
      if (isNaN(Number(event.target.value))) {
        value = event.target.value;
      } else {
        value =
          event.keyCode === keyCodes.upKey
            ? Number(event.target.value) + this.props.step
            : Number(event.target.value) - this.props.step;
      }
      this.handleInputChange(value, this.props.onChange);
    }
  }

  render() {
    const { onBlur, onChange, seconds, className } = this.props;

    const { fieldValue } = this.state;

    return (
      <div
        className={seconds ? style.inputContainer : style.inputContainerSeconds}
      >
        <InputTransparent
          className={`${style.inputField} ${className}`}
          disabled={false}
          onKeyDown={this.onKeyDown.bind(this)}
          onBlur={e => {
            this.handleInputChange(e.target.value, onChange, true);
            this.handleInputChange(e.target.value, onBlur, true);
          }}
          onChange={e => {
            this.updateField(e.target.value);
          }}
          value={fieldValue === 0 ? "0" : fieldValue}
          onFocusIn={this.props.onFocusIn}
        />
      </div>
    );
  }
}

export default InputNumber;
