import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const RenderPage = lazy(() => import("./RenderPage"));

export const RenderPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <RenderPage {...props} />
  </Suspense>
);
RenderPageLazy.displayName = "SignupPage";

export default RenderPageLazy;
