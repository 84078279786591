export const USER_TEAM_IMAGES_REQUEST = "API/ENTITIES/USER_TEAM_IMAGES_REQUEST";
export const USER_TEAM_IMAGES_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_IMAGES_REQUEST_FAILURE";
export const USER_TEAM_IMAGES_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_IMAGES_REQUEST_SUCCESS";

export const USER_TEAM_IMAGES_IN_FOLDER_REQUEST =
  "API/ENTITIES/USER_TEAM_IMAGES_IN_FOLDER_REQUEST";
export const USER_TEAM_IMAGES_IN_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_IMAGES_IN_FOLDER_REQUEST_FAILURE";
export const USER_TEAM_IMAGES_IN_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_IMAGES_IN_FOLDER_REQUEST_SUCCESS";

export const USER_TEAM_IMAGES_SEARCH_REQUEST =
  "API/ENTITIES/USER_TEAM_IMAGES_SEARCH_REQUEST";
export const USER_TEAM_IMAGES_SEARCH_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_IMAGES_SEARCH_REQUEST_FAILURE";
export const USER_TEAM_IMAGES_SEARCH_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_IMAGES_SEARCH_REQUEST_SUCCESS";

export const USER_TEAM_IMAGE_DELETE_REQUEST =
  "API/ENTITIES/USER_TEAM_IMAGE_DELETE_REQUEST";
export const USER_TEAM_IMAGE_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_IMAGE_DELETE_REQUEST_FAILURE";
export const USER_TEAM_IMAGE_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_IMAGE_DELETE_REQUEST_SUCCESS";

export const USER_TEAM_IMAGE_UPDATE_REQUEST =
  "API/ENTITIES/USER_TEAM_IMAGE_UPDATE_REQUEST";
export const USER_TEAM_IMAGE_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_IMAGE_UPDATE_REQUEST_FAILURE";
export const USER_TEAM_IMAGE_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_IMAGE_UPDATE_REQUEST_SUCCESS";

export const USER_TEAM_IMAGE_UPLOAD_REQUEST =
  "API/ENTITIES/USER_TEAM_IMAGE_UPLOAD_REQUEST";
export const USER_TEAM_IMAGE_UPLOAD_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_IMAGE_UPLOAD_REQUEST_FAILURE";
export const USER_TEAM_IMAGE_UPLOAD_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_IMAGE_UPLOAD_REQUEST_SUCCESS";

export const USER_IMAGE_UPLOAD_URL_REQUEST =
  "API/ENTITIES/USER_IMAGE_UPLOAD_URL_REQUEST";
export const USER_IMAGE_UPLOAD_URL_REQUEST_FAILURE =
  "API/ENTITIES/USER_IMAGE_UPLOAD_URL_REQUEST_FAILURE";
export const USER_IMAGE_UPLOAD_URL_REQUEST_SUCCESS =
  "API/ENTITIES/USER_IMAGE_UPLOAD_URL_REQUEST_SUCCESS";

export const ADD_USER_TEAM_IMAGES_PLACEHOLDERS =
  "API/ENTITIES/ADD_USER_TEAM_IMAGES_PLACEHOLDERS";

export const USER_TEAM_IMAGES_CLEAR = "API/ENITITIES/USER_TEAM_IMAGES_CLEAR";

export const USER_TEAM_IMAGES_CREATE_DELETE_REQUEST =
  "API/ENTITIES/USER_TEAM_IMAGES_CREATE_DELETE_REQUEST";
export const USER_TEAM_IMAGES_CREATE_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_IMAGES_CREATE_DELETE_REQUEST_FAILURE";
export const USER_TEAM_IMAGES_CREATE_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_IMAGES_CREATE_DELETE_REQUEST_SUCCESS";

export const USER_TEAM_IMAGES_DELETE_REQUEST =
  "API/ENTITIES/USER_TEAM_IMAGES_DELETE_REQUEST";
export const USER_TEAM_IMAGES_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_IMAGES_DELETE_REQUEST_FAILURE";
export const USER_TEAM_IMAGES_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_IMAGES_DELETE_REQUEST_SUCCESS";
