import { flatten } from "lib/lodash";
import { uuid } from "lib/uuid";
import { insertItem } from "lib/array/array";
import { getAnimationData } from "lib/animatedElementUtils";
import { resizeElementProportionally } from "lib/scalingTools/scalingTools";
import { isTextElement } from "lib/elements";

const DUPLICATE_OFFSET = 50;

const DesignCopyOps = {
  copyElements({ elements }) {
    const elementsCopyArray = this.generateElementsCopyArray({ elements });

    // build page information for the elements to duplicate them on the same page
    const pageId = elements[0].pageId;
    const page = this.pages[pageId];

    return this.pasteElementsCopyArray({
      elementsCopyArray,
      pageId,
      page,
      elementOffsetX: 50,
      elementOffsetY: 50
    });
  },

  copyElement({ elementId, groupId, pageId, newElementId }) {
    if (groupId) {
      return this.copyElementInGroup({ elementId, groupId, newElementId });
    }

    const page = this.pages[pageId];
    const elementToBeCopiedIndex = page.elementsOrder.indexOf(elementId);

    const elementToBeCopied = this.getElement(elementId);

    const elementsCopy = elementToBeCopied.clone(newElementId);
    const elementCopy = elementsCopy[0];
    /* Move the copied element slightly left and down
       so it wond be place on top of the original element */

    elementCopy.top = elementToBeCopied.top + DUPLICATE_OFFSET;
    elementCopy.left = elementToBeCopied.left + DUPLICATE_OFFSET;

    const pagesUpdated = {
      ...this.pages,
      [pageId]: {
        ...page,
        elementsOrder: insertItem(
          page.elementsOrder,
          elementToBeCopiedIndex + 1,
          newElementId
        )
      }
    };

    const groupElements = {};

    // in the case that the root element is a group clone its children and add them to the groupElements array
    if (elementCopy.type === "group") {
      elementsCopy.forEach(element => {
        // apply offsets to child elements and add them
        const elementClone = Object.assign({}, element, {
          top: element.top + DUPLICATE_OFFSET,
          left: element.left + DUPLICATE_OFFSET
        });
        groupElements[element.uniqueId] = elementClone;
      });
    }

    const elementsUpdated = {
      ...this.elements,
      [newElementId]: elementCopy,
      ...groupElements
    };

    return new this.constructor({
      ...this,
      pages: pagesUpdated,
      elements: elementsUpdated,
      version: this.version + 1
    });
  },

  copyElementInGroup({ elementId, groupId, newElementId }) {
    const group = this.getElement(groupId);
    const elementToBeCopiedIndex = group.elementsOrder.indexOf(elementId);

    const elementToBeCopied = this.getElement(elementId);

    const elementCopy = elementToBeCopied.clone(newElementId)[0];

    elementCopy.top = elementToBeCopied.top + DUPLICATE_OFFSET;
    elementCopy.left = elementToBeCopied.left + DUPLICATE_OFFSET;

    const elementsUpdated = {
      ...this.elements,
      [groupId]: {
        ...group,
        elementsOrder: insertItem(
          group.elementsOrder,
          elementToBeCopiedIndex + 1,
          newElementId
        )
      },
      [newElementId]: elementCopy
    };

    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  },

  /**
   * @desc - takes an array of elements and new element Ids and builds a paste-able array of elements
   * @param {[Object]} elements - an array of element references to get an element array for
   */
  generateElementsCopyArray({ elements }) {
    return elements.map(element => {
      return this.getElement(element.itemId);
    });
  },

  adjustScaleOfElement({ element, scaleAdjustment, contextMenuPaste }) {
    return resizeElementProportionally({
      element,
      anchorPoint: {
        x: 0,
        y: 0
      },
      scale: scaleAdjustment,
      zoom: 1,
      offset: {
        x: 0,
        y: 0
      },
      contextMenuPaste
    });
  },

  /**
   * @desc - generates a designData object with the passed element array pasted into the current page
   * @param {number} elementOffsetX - X offset to apply to the newly pasted elements
   * @param {number} elementOffsetY - Y offset to apply to the newly pasted elements
   * @param {[Object]} elementsCopyArray  - an array of element objects to paste into the design
   * @param {string} pageId - the Id for the page to paste elements on
   * @param {number} scaleAdjustment - the scale to paste the new elements at initially
   * @param {object} anchor - the paste selection anchor to use as an origin for scaling actions
   * @param {object} isClearingRootGroupIds - defines whether the paste should clear groupIds from the root elements
   */
  pasteElementsCopyArray({
    elementOffsetX = 0,
    elementOffsetY = 0,
    elementsCopyArray,
    pageId,
    scaleAdjustment = 1,
    isClearingRootGroupIds = true, // currently we will always clear the group Ids in root
    contextMenuPaste
  }) {
    const groupElements = {};
    const newIds = [];
    const page = this.pages[pageId];

    const _elementsCopy = elementsCopyArray.reduce(
      (previousValue, elementToBeCopied) => {
        const newId = uuid();

        if (isClearingRootGroupIds || !elementToBeCopied.groupId) {
          newIds.push(newId);
        }

        // make a clone of the element
        const localElementsCopy = elementToBeCopied.clone(newId);

        // take the root element in all cases
        let rootElement = localElementsCopy[0];

        // adjust the initial position of the element based on provided offset
        rootElement.left = rootElement.left + elementOffsetX;
        rootElement.top = rootElement.top + elementOffsetY;

        const scaledElement = this.adjustScaleOfElement({
          element: rootElement,
          scaleAdjustment,
          contextMenuPaste
        });

        // in case that root element is a group gather all children and add them to the groupElements array
        if (scaledElement.type === "group") {
          localElementsCopy.forEach(childElement => {
            // apply offsets to child elements and add them
            const childElementClone = Object.assign({}, childElement, {
              left: childElement.left + elementOffsetX,
              top: childElement.top + elementOffsetY
            });

            const scaledChildElementClone = this.adjustScaleOfElement({
              element: childElementClone,
              scaleAdjustment
            });

            groupElements[childElement.uniqueId] = scaledChildElementClone;
          });
        }

        if (isClearingRootGroupIds) {
          scaledElement.groupId = null;
        }

        return {
          ...previousValue,
          [newId]: scaledElement
        };
      },
      {}
    );

    const updatedGroups = {};

    if (!isClearingRootGroupIds) {
      Object.values(_elementsCopy).forEach(rootElement => {
        if (rootElement.groupId) {
          // get the group element
          const updatedGroupElement =
            updatedGroups[rootElement.groupId] ||
            this.getElement(rootElement.groupId);
          if (
            !updatedGroupElement.elementsOrder.includes(rootElement.uniqueId)
          ) {
            updatedGroupElement.elementsOrder.push(rootElement.uniqueId);
          }
          updatedGroups[rootElement.groupId] = updatedGroupElement;
        }
      });
    }

    const newTextboxIds = [];
    // contains all elements, including group and individual elements
    const allNewElements = {
      ..._elementsCopy,
      ...groupElements
    };

    // iterate through unique ids to append textboxes to quickInputOrder
    new Set(Object.keys(allNewElements)).forEach(elementId => {
      if (isTextElement(allNewElements[elementId].type)) {
        newTextboxIds.push(allNewElements[elementId].uniqueId);
      }
    });

    const updatedQuickInputOrder = page.quickInputOrder.concat(newTextboxIds);

    const pagesUpdated = {
      ...this.pages,
      [pageId]: {
        ...page,
        elementsOrder: flatten(
          insertItem(page.elementsOrder, page.elementsOrder.length, newIds)
        ),
        quickInputOrder: updatedQuickInputOrder
      }
    };

    Object.values(_elementsCopy).forEach(element => {
      // this is an animated element
      const animationData = getAnimationData({ ...element, pageId });
      animationData.forEach(dataPoint => {
        pagesUpdated[pageId] = {
          ...pagesUpdated[pageId],
          animatedElements: {
            ...pagesUpdated[pageId].animatedElements,
            [dataPoint.animationDataKey]: dataPoint
          }
        };
      });
    });

    const elementsUpdated = {
      ...this.elements,
      ..._elementsCopy,
      ...groupElements,
      ...updatedGroups
    };

    const designUpdated = new this.constructor({
      ...this,
      pages: pagesUpdated,
      elements: elementsUpdated,
      version: this.version + 1
    });

    return {
      designUpdated,
      newIds
    };
  }
};

export default DesignCopyOps;
