import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PrintOptionsButton from "./PrintOptionsButton";
import { getPrintOptions } from "state/entities/printOptions/printOptionsActions";
import {
  templatePrintOptionsURLSelector,
  isFetchingPrintOptionsSelector
} from "state/entities/printOptions/printOptionsSelectors";

const PrintOptionsButtonContainer = ({
  selectedDesign,
  getPrintOptions,
  printOptionsURL,
  isFetchingPrintURL
}) => {
  return (
    <PrintOptionsButton
      selectedDesign={selectedDesign}
      getPrintOptions={getPrintOptions}
      printOptionsURL={printOptionsURL}
      isFetching={isFetchingPrintURL}
    />
  );
};

PrintOptionsButtonContainer.displayName = "PrintOptionsButtonContainer";
PrintOptionsButtonContainer.propTypes = {
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  printOptionsURL: templatePrintOptionsURLSelector(
    state,
    ownProps.selectedDesign.templateCode
  ),
  isFetchingPrintURL: isFetchingPrintOptionsSelector(state)
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPrintOptions: args => dispatch(getPrintOptions(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintOptionsButtonContainer);
