import React from "react";
import style from "./style.module.css";
import DesignSizeOption from "./DesignSizeOption";

export const DesignSizeOptions = ({
  category,
  handleChangePreview,
  isHighlighted
}) => {
  return (
    <div className={style.designSizeOptions} data-testid="DesignSizeOptions">
      {category.designs.map(design => (
        <DesignSizeOption
          design={design}
          isHighlighted={isHighlighted(design)}
          onClick={handleChangePreview}
          key={design.id}
        />
      ))}
    </div>
  );
};

DesignSizeOptions.displayName = "DesignSizeOptions";

export const DesignSizeCategories = ({
  collectionDesignsCategorised,
  handleChangePreview,
  isHighlighted
}) => {
  return (
    <div className={style.sizeCategories} data-testid="DesignSizeCategories">
      {collectionDesignsCategorised.map(category => (
        <div className={style.sizeCategory} key={category.name}>
          <div className={style.sizeCategoryTitle}>{category.name}</div>
          <DesignSizeOptions
            category={category}
            handleChangePreview={handleChangePreview}
            isHighlighted={isHighlighted}
          />
        </div>
      ))}
    </div>
  );
};

DesignSizeCategories.displayName = "DesignSizeCategories";

export default DesignSizeCategories;
