import * as types from "./teamSmartImagesTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import { noop } from "lib";

export const fetchTeamSmartImages = ({ params, onSuccess = noop } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  dispatch({
    [CALL_API]: {
      types: [
        types.TEAM_SMART_IMAGES_REQUEST,
        types.TEAM_SMART_IMAGES_REQUEST_SUCCESS,
        types.TEAM_SMART_IMAGES_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/smart-images`,
      schema: schemas.TEAM_SMART_IMAGES_ARRAY,
      request: {
        params
      },
      onSuccess: response => {}
    }
  });
};

/**
 * @desc Updates a team smart image
 * @param {Number} smartImage - Smart Image entity
 */
export const updateTeamSmartImage = ({ smartImage, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  dispatch({
    [CALL_API]: {
      method: "PUT",
      onSuccess,
      types: [
        types.TEAM_SMART_IMAGE_UPDATE_REQUEST,
        types.TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS,
        types.TEAM_SMART_IMAGE_UPDATE_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/smart-images/${smartImage.id}`,
      schema: schemas.USER_TEAM_SMART_IMAGE,
      request: {
        body: {
          id: smartImage.id,
          teamId,
          label: smartImage.label,
          mediaId: smartImage.mediaId,
          createdAt: smartImage.createdAt,
          updatedAt: smartImage.updatedAt
        }
      }
    }
  });
};

/**
 * @desc Creates a team smart image
 * @param {string} label - the label of the Smart Image entity
 * @param {Number} mediaId - The id of the media entity
 */
export const createTeamSmartImage = ({ label, mediaId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      onSuccess,
      types: [
        types.TEAM_SMART_IMAGE_UPLOAD_REQUEST,
        types.TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS,
        types.TEAM_SMART_IMAGE_UPLOAD_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/smart-images`,
      schema: schemas.TEAM_SMART_IMAGE,
      request: {
        body: {
          teamId,
          label,
          mediaId
        }
      }
    }
  });
};

/**
 * @desc Delete a team smart image
 * @param {Number} id - The id of the smart image entity
 * @param {Number} mediaId - The id of the media entity
 */
export const deleteTeamSmartImage = ({ id, mediaId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      onSuccess,
      types: [
        types.TEAM_SMART_IMAGE_DELETE_REQUEST,
        types.TEAM_SMART_IMAGE_DELETE_REQUEST_SUCCESS,
        types.TEAM_SMART_IMAGE_DELETE_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/smart-images/${id}`,
      schema: schemas.NONE,
      extra: {
        mediaId
      }
    }
  });
};
