import React from "react";
import style from "./style.module.css";
import { Loading } from "views/components";
import AnimationIcon from "views/components/icons/AnimationIcon";
import PlusIcon from "views/components/icons/PlusIcon";
import InfoAlert from "views/components/InfoAlert";
import { orderBy, omit, getPath } from "lib/lodash";
import { jsonStringEqual } from "lib/equalityUtils";
import { isAfter } from "lib/temporal/dateUtils";
import DesignUpdateNotice from "views/containers/cartContainer/DesignUpdateNotice";

import DesignItem from "./DesignItem";

class CartPreviewSection extends React.Component {
  static POLL_INTERVAL = 12000; // 12 seconds
  static POLL_MAX_RETRY = 25; // 12 * 25 = 5 minute timeout to stop polling
  constructor(props) {
    super(props);

    this.pollForDesignThumbnails = this.pollForDesignThumbnails.bind(this);
    this.getDesignsUpdatedSinceAdding = this.getDesignsUpdatedSinceAdding.bind(
      this
    );
    this.collectDesignDataIdsForUpdatedDesigns = this.collectDesignDataIdsForUpdatedDesigns.bind(
      this
    );
    this.updateOrderDesigns = this.updateOrderDesigns.bind(this);
    this.clickHandler = this.clickHandler.bind(this);

    this.state = {
      designsGeneratingThumbs: {},
      pollCount: 0,
      orderDesignUpdates: [],
      closeTab: true,
      activePrintItemId: null
    };
  }

  componentDidMount() {
    const { order: currentOrder = {} } = this.props;

    const currentDesigns = getPath(currentOrder, "designs", []);

    const designsGeneratingThumbs = currentDesigns.filter(
      design => design.thumbnailGenerating
    );
    this.setState({
      designsGeneratingThumbs: {
        ...this.state.designsGeneratingThumbs,
        ...designsGeneratingThumbs.reduce(
          (designs, design) => ({ ...designs, [design.designId]: design }),
          {}
        )
      }
    });

    this.collectDesignDataIdsForUpdatedDesigns();
  }

  componentDidUpdate(prevProps, prevState) {
    const { order: currentOrder = {} } = this.props;
    const { order: previousOrder = {} } = prevProps;

    const currentDesigns = currentOrder.designs || [];
    const previousDesigns = previousOrder.designs || [];

    if (!jsonStringEqual(currentDesigns, previousDesigns)) {
      const designsGeneratingThumbs = currentDesigns.filter(
        design => design.thumbnailGenerating
      );
      this.setState({
        designsGeneratingThumbs: {
          ...this.state.designsGeneratingThumbs,
          ...designsGeneratingThumbs.reduce(
            (designs, design) => ({ ...designs, [design.designId]: design }),
            {}
          )
        }
      });
    }

    // designs generating thumbs has changed so we should adjust the poll
    if (
      prevState.designsGeneratingThumbs !==
        this.state.designsGeneratingThumbs &&
      Object.keys(this.state.designsGeneratingThumbs).length
    ) {
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
      }
      this.pollForDesignThumbnails();
      this.pollInterval = setInterval(
        this.pollForDesignThumbnails,
        CartPreviewSection.POLL_INTERVAL
      );
    }

    if (
      Object.keys(prevState.designsGeneratingThumbs).length &&
      !Object.keys(this.state.designsGeneratingThumbs).length
    ) {
      if (this.pollInterval) {
        clearInterval(this.pollInterval);
      }
    }

    // past the poll maximum we should remove the interval (ideal)
    if (
      this.state.pollCount >= CartPreviewSection.POLL_MAX_RETRY &&
      this.pollInterval
    ) {
      clearInterval(this.pollInterval);
    }
  }

  pollForDesignThumbnails() {
    const { designsGeneratingThumbs, pollCount } = this.state;

    // make sure not to over poll here as a precautionary measure
    if (pollCount >= CartPreviewSection.POLL_MAX_RETRY) {
      // polled too many times already, no more polling
      return;
    } else {
      // increment the poll count
      this.setState({
        pollCount: pollCount + 1
      });
    }

    new Promise(resolve => {
      this.props.fetchCurrentOrder({
        onSuccess: response => {
          const newDesigns =
            response.entities.order[response.ids].designs || [];
          const currentDesigns = Object.values(designsGeneratingThumbs);
          const designIdsToOmit = [];

          currentDesigns.forEach(currentDesign => {
            const newDesign = newDesigns.find(
              newDesign => newDesign.designId === currentDesign.designId
            );
            if (!newDesign || !newDesign.thumbnailGenerating) {
              designIdsToOmit.push(currentDesign.designId);
            }
          });
          resolve(designIdsToOmit);
        }
      });
    }).then(designIdsToOmit => {
      if (!designIdsToOmit.length) return;
      this.setState({
        designsGeneratingThumbs: omit(designsGeneratingThumbs, designIdsToOmit)
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
  }

  getDesignsUpdatedSinceAdding() {
    const { order } = this.props;

    if (!order) return [];

    const { designs = [] } = order;

    return designs.filter(design =>
      isAfter(design.designUpdatedAt, design.updatedAt)
    );
  }

  async collectDesignDataIdsForUpdatedDesigns() {
    const { getUpdatedDesignDataIds } = this.props;
    const designsUpdatedSinceAdding = this.getDesignsUpdatedSinceAdding();

    const orderDesignUpdates = await getUpdatedDesignDataIds(
      designsUpdatedSinceAdding
    );

    this.setState({
      orderDesignUpdates
    });
  }

  updateOrderDesigns() {
    const { updateOrderDesigns } = this.props;
    const { orderDesignUpdates } = this.state;

    updateOrderDesigns(orderDesignUpdates);

    this.setState({
      orderDesignUpdates: []
    });
  }

  clickHandler() {
    this.setState({
      closeTab: false
    });
  }

  render() {
    const {
      order,
      onDesignPreview,
      onDeleteDesignFromOrder,
      printPricingGroupedByDesignId,
      handleUpdatePrintItem,
      handleCreatePrintItem,
      handleDeletePrintItem,
      loading,
      imageTemplateTypes
    } = this.props;

    const { orderDesignUpdates } = this.state;

    if (!order) return null;

    const { designs } = order;

    // show loading if no designs are present
    if (!designs) {
      return (
        <div className={style.loading}>
          <Loading />
        </div>
      );
    }

    const isAnyDesignAnimated = designs.some(design => design.isAnimated);

    return (
      <div className={style.wrapper}>
        <div className={style.warnings}>
          {isAnyDesignAnimated && this.state.closeTab && (
            <InfoAlert
              className={style.infoAlertBox}
              theme="black"
              icon={AnimationIcon}
            >
              <div className={style.alertAnimationMessage}>
                Your design contains Animated elements. When printed, only the
                first frame will be shown.
                <div
                  className={style.plusIconDisplay}
                  onClick={this.clickHandler}
                >
                  <PlusIcon rotate="45" size="18" />
                </div>
              </div>
            </InfoAlert>
          )}
          {orderDesignUpdates.length > 0 && (
            <DesignUpdateNotice
              className={style.designUpdateNotice}
              onUpdate={this.updateOrderDesigns}
            />
          )}
        </div>
        {orderBy(designs, ["createdAt"], ["desc"]).map((design, index) => (
          <DesignItem
            design={design}
            onDesignPreview={onDesignPreview}
            onDeleteDesignFromOrder={onDeleteDesignFromOrder}
            printPricingGroupedByDesignId={printPricingGroupedByDesignId}
            handleUpdatePrintItem={handleUpdatePrintItem}
            handleCreatePrintItem={handleCreatePrintItem}
            handleDeletePrintItem={handleDeletePrintItem}
            imageTemplateTypes={imageTemplateTypes}
            loading={loading}
            activePrintItemId={this.state.activePrintItemId}
            activeLabel={this.state.activeLabel}
          />
        ))}
      </div>
    );
  }
}

export default CartPreviewSection;
