import { isVideo } from "./isVideo";
import { isVector } from "./isSvg";

/**
 * @desc Checks the validity of an asset source type -> targets type match
 * @param {string} sourceAssetType - asset type to be checked for validity against targets
 * @param {[string]} targetElementTypes - element types to check as valid targets for source
 * @returns Boolean - whether or not the source is valid for all targets
 */
export const getIsSourceMatchingTargets = (
  sourceAssetType,
  targetElementTypes
) => {
  // ensure handling if targetElementTypes is provided as a single type string
  let _targetElementTypes = targetElementTypes;
  if (!Array.isArray(targetElementTypes)) {
    _targetElementTypes = [targetElementTypes];
  }

  if (!_targetElementTypes.length) {
    return false;
  }

  let isMatching = true;

  // map through target types checking for any elements that don't match the source type
  _targetElementTypes.forEach(targetType => {
    if (!isMatching) {
      return;
    }
    switch (targetType) {
      case "image":
      case "background":
      case "video":
      case "grid": {
        if (!["video", "image"].includes(sourceAssetType)) {
          isMatching = false;
        }
        break;
      }
      default: {
        // the target was not matching
        isMatching = false;
        break;
      }
    }
  });

  return isMatching;
};

/**
 * @desc Gets the source type for a given asset object
 * @param {Object} asset - the asset object to derive the source type from
 * @returns String - the source type for the asset
 */
export const getSourceTypeFromAsset = asset => {
  const source = asset.url || asset.src;

  if (!source || source.startsWith("blob:")) {
    if (asset.originalType) {
      switch (asset.originalType) {
        case "mp4": {
          return "video";
        }
        default: {
          return "invalid";
        }
      }
    }
  }

  if (isVideo(source)) {
    return "video";
  }

  // vector source and not a smart image
  if (isVector(source) && !asset.label) {
    return "vector";
  }

  return "image";
};
