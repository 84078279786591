import React from "react";

import style from "./orderFailedLoading.module.css";

const EmptyOrder = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.heading}>
        You don’t have any items in your cart.
      </div>
      <div className={style.content}>
        Start by choosing a pre-designed Template, or create your own Design.
      </div>
    </div>
  );
};

export default EmptyOrder;
