import {
  UI_OPEN_CONFIRMATION_MODAL,
  UI_CLOSE_CONFIRMATION_MODAL
} from "./confirmationModalTypes";

export const initState = {
  isOpen: false
};

const confirmationModal = (state = initState, action) => {
  switch (action.type) {
    case UI_OPEN_CONFIRMATION_MODAL: {
      return {
        isOpen: true,
        header: action.header,
        text: action.text,
        onOk: action.onOk,
        onCancel: action.onCancel,
        modalClassName: action.modalClassName,
        buttonsDetails: action.buttonsDetails
      };
    }

    case UI_CLOSE_CONFIRMATION_MODAL: {
      return initState;
    }

    default:
      return state;
  }
};

export default confirmationModal;
