import React from "react";
import ReactModal from "react-modal";
import style from "./style.module.css";
import { Button, Loading } from "..";
import PlusIcon from "views/components/icons/PlusIcon";

export const portalClassName = "ReactModalPortal";

const Modal = ({
  children,
  buttons,
  header,
  subHeader,
  onRequestClose,
  className,
  headerTitleClassName,
  hasHeader = true,
  crossColor,
  buttonsClassName,
  crossClassName,
  ...props
}) => {
  ReactModal.setAppElement("#root");

  return (
    <ReactModal
      portalClassName={portalClassName}
      className={`${style.content} ${className}`}
      overlayClassName={style.overlay}
      bodyOpenClassName={style.bodyOpen}
      onRequestClose={onRequestClose}
      {...props}
    >
      {hasHeader && (
        <div className={style.header} data-testid="modalHeaderWrapper">
          <div
            aria-label="Modal Header"
            className={`${style.headerTitle} ${headerTitleClassName}`}
          >
            {header}
            {subHeader && <div className={style.subHeader}>{subHeader}</div>}
          </div>
          <div
            onClick={onRequestClose}
            className={`${style.closeIcon} ${crossClassName}`}
          >
            <PlusIcon size="18px" rotate="45" title="Exit" color={crossColor} />
          </div>
        </div>
      )}
      <div aria-label="Modal Body" data-testid="modalBody">
        {children}
      </div>
      {buttons && (
        <div className={`${style.buttons} ${buttonsClassName}`}>
          {buttons.map(({ name, isLoading, ...buttonProps }) => (
            <Button isWide={true} key={name} {...buttonProps}>
              {isLoading ? <Loading hexColor="#ffffff" size="16px" /> : name}
            </Button>
          ))}
        </div>
      )}
    </ReactModal>
  );
};

export default Modal;
