import * as types from "./inviteToTeamFormTypes";
import { initState as initStatePristine } from "./inviteToTeamFormPristineReducers";
import {
  removeEntryFromForm,
  addEntryToForm,
  setLoadingStatus
} from "./inviteToTeamFormReducersCommonCases";
import { TEAMS_INVITE_USERS_REQUEST } from "state/entities/teams/teamsTypes";

const inviteToTeamFormValidReducers = (state = {}, action) => {
  switch (action.type) {
    case types.TEAM_INVITE_FORM_REMOVE_ENTRY: {
      return removeEntryFromForm({ state, action });
    }

    case types.TEAM_INVITE_FORM_ADD_NEW_ENTRY: {
      return addEntryToForm({ state, newEntry: action.newEntry });
    }

    case types.TEAM_INVITE_FORM_RESET: {
      return initStatePristine;
    }

    case TEAMS_INVITE_USERS_REQUEST: {
      return setLoadingStatus({ state });
    }

    default: {
      return state;
    }
  }
};

export default inviteToTeamFormValidReducers;
