import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import { api, currentUser, personalTeam, entities, ui } from ".";
import apiMiddleware from "./middleware/api";
import facebookPixel from "./middleware/facebookPixel";
import imagePreloader from "./middleware/imagePreloader";
import pathnameMiddleware from "./middleware/pathname";
import notificationMiddleware from "./middleware/notification";
import fonts from "./middleware/fonts";

const { REACT_APP_REDUX_DEV_TOOL: REDUX_DEV_TOOL } = process.env;

const configStore = history => {
  const middlewares = [
    thunk,
    pathnameMiddleware,
    notificationMiddleware,
    apiMiddleware,
    imagePreloader,
    facebookPixel,
    fonts,
    routerMiddleware(history)
  ];

  let composeEnhancers = compose;

  if (
    REDUX_DEV_TOOL === "true" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25
    });
  }

  const combined = combineReducers({
    api,
    ui,
    currentUser,
    personalTeam,
    entities,
    router: routerReducer
  });

  const store = createStore(
    combined,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
};

export default configStore;
