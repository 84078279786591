import { Component } from "react";
import WidthLimitedEllipsisSpan from "../WidthLimitedEllipsisSpan";
import style from "./style.module.css";
import commonStyle from "../style.module.css";
import { SECTION_TYPES } from "../teamCreateModalUtils";

const SELECTION_OPTIONS = [SECTION_TYPES.SUBTEAM, SECTION_TYPES.ORGANISATION];

const SELECTION_MAP = {
  [SECTION_TYPES.SUBTEAM]: {
    title: "Sub-Team",
    body: teamName => (
      <>
        {`Add a new team within
      your current team structure.
      Members of the `}
        <WidthLimitedEllipsisSpan
          style={{ fontWeight: 500 }}
          content={teamName}
          maxWidth="400px"
        />
        {` team can automatically access this new sub-team.`}
      </>
    )
  },
  [SECTION_TYPES.ORGANISATION]: {
    title: "Top-Level Team",
    body: teamName => (
      <>
        {`Create a Top-Level team separate from your current team structure.
      Members of the `}
        <WidthLimitedEllipsisSpan
          style={{ fontWeight: 500 }}
          content={teamName}
          maxWidth="400px"
        />
        {` team will not have access to the new top-level team.`}
      </>
    )
  }
};

export class SelectionSection extends Component {
  render() {
    const { selectedType, setSelectedType } = this.props;
    return (
      <div className={style.wrapper}>
        <div className={commonStyle.title}>Add a new team</div>
        <div className={commonStyle.textContent}>
          Start building your team structure by adding a new team.{" "}
          <a
            href="https://support.easil.com/creating-multiple-teams"
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        </div>
        <div className={style.selectionOptions}>
          {SELECTION_OPTIONS.map(selectionKey => (
            <div
              className={style.selectionOption}
              data-is-selected={selectedType === selectionKey}
              onClick={() => setSelectedType(selectionKey)}
              key={selectionKey}
            >
              <div className={style.selectionOptionTitle}>
                {SELECTION_MAP[selectionKey].title}
              </div>
              <div className={style.selectionOptionText}>
                {SELECTION_MAP[selectionKey].body(this.props.currentTeam.name)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default SelectionSection;
