import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const LoginPage = lazy(() => import("./LoginPage"));

export const LoginPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <LoginPage {...props} />
  </Suspense>
);
LoginPageLazy.displayName = "LoginPage";

export default LoginPageLazy;
