const user = "default1";

export const PostAffTracker = {
  getVisitorCookie() {
    if (!window.PostAffTracker) return;
    const token = window.PostAffTracker.getAffInfo().cookieManager.getVisitorCookie()
      .value;
    return token ? `${user}${token}` : undefined;
  },
  initialiseTracker() {
    if (!window.PostAffTracker) return;
    window.PostAffTracker.setAccountId(user);
    try {
      window.PostAffTracker.track();
    } catch (err) {}
  }
};
