import React from "react";
import style from "./style.module.css";
import Dropdown2 from "views/components/dropdown2/Dropdown2";
import CountryOptionsRenderer from "views/components/countrySelect/CountryOptionsRenderer";
import { getUserDefaultCountryWithFlag } from "lib/billingUtils";
import { separatedCountriesWithFlags } from "lib/CountryFlags";
import LogoImage from "views/containers/userSettingsContainer/LogoImage";

const CountryFlagsDropdown = ({ selectedCountry, onChange, disabled }) => {
  const selectedCountryData = getUserDefaultCountryWithFlag(selectedCountry);
  const getCountryDropdownLabel = () => {
    return (
      <div className={style.countryNameFlagWrapper}>
        <LogoImage
          src={selectedCountryData.flagSrc}
          width={"24px"}
          height={"24px"}
        />
        <div className={style.name}>{selectedCountryData.label}</div>
      </div>
    );
  };

  return (
    <Dropdown2
      selected={selectedCountryData.key}
      options={separatedCountriesWithFlags}
      optionsRenderer={CountryOptionsRenderer}
      onChange={onChange}
      isCustomValue
      value={getCountryDropdownLabel}
      className={style.countryFlagsDropdown}
      dropdownClassName={style.countryOptions}
      disabled={disabled}
    />
  );
};

export default CountryFlagsDropdown;
