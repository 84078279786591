import React, { Component } from "react";
import style from "./style.module.css";
import ScrollingOverflowText from "views/components/ScrollingOverflowText/ScrollingOverflowText";

export default class SmartImageLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label } = this.props;

    return (
      <ScrollingOverflowText className={style.smartImageLabel}>
        {label}
      </ScrollingOverflowText>
    );
  }
}
