import { secondsInMs, msInSeconds } from "lib/temporal/time";

export const getVideoSourceWithFallback = ({
  optimalSource,
  fallbackSource,
  callback
}) => {
  // try to get the optimal image
  cacheVideo(optimalSource).then(
    () => {
      // if the optimal image can be cached then use it
      return callback(optimalSource);
    },
    () => {
      // if the optimal image can not be cached then use the original source as a fallback
      return callback(fallbackSource);
    }
  );
};

export const getVideoDurationFromFile = async file => {
  const getVideoDuration = () =>
    new Promise(resolve => {
      let video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        // for the duration to prevent fractional values sent in request
        resolve(Math.floor(video.duration * 1000));
      };
      video.src = window.URL.createObjectURL(file);
    });

  const duration = await getVideoDuration();
  return duration;
};

export const loopTrimmedVideo = (event, elementData) => {
  const { startOffset = 0, trimDuration, duration } = elementData;
  let _trimDuration = trimDuration;
  if (!trimDuration) {
    _trimDuration = duration;
  }
  const endTime = startOffset + _trimDuration;
  const currentTimeInMs = secondsInMs(event.target.currentTime);
  if (currentTimeInMs >= endTime || currentTimeInMs < startOffset) {
    event.target.currentTime = msInSeconds(startOffset);
  }
};

export const getVideoSizeFromSrc = async src => {
  if (!src) {
    return {
      height: 0,
      width: 0
    };
  }
  const getVideoSize = src => {
    return new Promise(resolve => {
      let video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve({ height: video.videoHeight, width: video.videoWidth });
      };
      video.src = src;
    });
  };

  return await getVideoSize(src);
};

/**
 *
 * @param {Object} videoElement an object representing the video element to get dimensions for
 * @returns {Object} videoDimensions - contains width and height dimensions for an image
 */
export const getVideoDimensionsFromElement = async videoElement => {
  let videoDimensions;
  // check for dimensions in the media object first
  if (
    videoElement.media &&
    videoElement.media.width &&
    videoElement.media.height
  ) {
    videoDimensions = {
      width: videoElement.media.width,
      height: videoElement.media.height
    };
  } else {
    // fallback to using the main url, the original url and then finally the preview url
    try {
      videoDimensions = await getVideoSizeFromSrc(videoElement.url);
    } catch {
      try {
        videoDimensions = await getVideoSizeFromSrc(videoElement.originalUrl);
      } catch {
        videoDimensions = await getVideoSizeFromSrc(videoElement.previewSrc);
      }
    }
  }
  return videoDimensions;
};

export const getVideoDurationFromSrc = async src => {
  const getVideoDuration = src => {
    return new Promise(resolve => {
      let video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        // floor the duration to prevent fractional values sent in request
        resolve(Math.floor(video.duration * 1000));
      };
      video.src = src;
    });
  };

  return await getVideoDuration(src);
};

export const getVideoDetailsFromSrc = async src => {
  const getVideoDetails = src => {
    return new Promise(resolve => {
      let video = document.createElement("video");
      video.preload = "auto";
      video.onloadeddata = () => {
        window.URL.revokeObjectURL(video.src);
        // floor the duration to prevent fractional values sent in request
        resolve({
          height: video.videoHeight,
          width: video.videoWidth,
          duration: Math.floor(video.duration * 1000)
        });
      };
      video.src = src;
    });
  };

  return await getVideoDetails(src);
};

export const hasAudio = video => {
  return (
    video.mozHasAudio ||
    Boolean(video.webkitAudioDecodedByteCount) ||
    Boolean(video.audioTracks && video.audioTracks.length)
  );
};

export const getVideoHasAudioFromSrc = async src => {
  const getVideoHasAudio = src => {
    return new Promise(resolve => {
      let video = document.createElement("video");
      video.preload = "auto";
      video.onloadeddata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(hasAudio(video));
      };
      video.onerror = () => {
        resolve();
      };
      video.src = src;
    });
  };

  return await getVideoHasAudio(src);
};

export const cacheVideo = src => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");

    video.onloadeddata = () => {
      resolve(true);
    };

    video.onerror = () => {
      reject(false);
    };

    video.src = src;
  });
};
