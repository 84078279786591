import * as types from "state/entities/teams/teamsTypes";
import { SWITCH_TEAM } from "state/ui/uiTypes";
import { getPath } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
import { MIN_TEAM_LEVEL } from "lib/teamUtils";

export const initState = {
  pageSize: 100,
  detailedIds: {},
  parentIds: {
    all: {
      pages: {}
    }
  },
  totalCount: null
};

const api = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_REQUEST: {
      const teamId = action.request.teamId;

      return {
        ...state,
        detailedIds: {
          ...state.detailedIds,
          [teamId]: {
            ...state.detailedIds[teamId],
            isFetching: true
          }
        }
      };
    }
    case types.SUB_TEAMS_REQUEST:
    case types.TEAMS_REQUEST: {
      const parentId = getPath(action, "request.params.parentId") || "all";

      const page = {
        isFetching: true,
        lastFetched: Date.now()
      };

      return immutableUpdate(state, {
        parentIds: {
          [parentId]: {
            $auto: {
              pages: {
                $auto: {
                  [action.request.page]: {
                    $auto: {
                      $merge: {
                        ...page
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.SUB_TEAMS_REQUEST_SUCCESS:
    case types.TEAMS_REQUEST_SUCCESS: {
      const {
        request: { page },
        response: { ids, entities = {} }
      } = action;

      const teams = entities.teams || {};

      const parentId = getPath(action, "request.params.parentId") || "all";

      let tempIds = ids;

      if (parentId === "all") {
        tempIds = Object.values(teams)
          .filter(team => team.parentPathArr.length <= MIN_TEAM_LEVEL)
          .map(team => team.id);
      }

      const updatedPage = {
        isFetching: false,
        ids: tempIds,
        didInvalidate: false,
        lastFetched: Date.now()
      };

      return immutableUpdate(state, {
        parentIds: {
          [parentId]: {
            $auto: {
              pages: {
                $auto: {
                  [page]: {
                    $auto: {
                      $merge: {
                        ...updatedPage
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.TEAMS_SEARCH_REQUEST: {
      const parentId = getPath(action, "request.params.parentId") || "all";

      const updatedPage = {
        isFetching: true
      };

      return immutableUpdate(state, {
        parentIds: {
          [parentId]: {
            $auto: {
              pages: {
                $auto: {
                  [action.request.page]: {
                    $auto: {
                      $merge: {
                        ...updatedPage
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.TEAMS_SEARCH_REQUEST_SUCCESS: {
      if (!action.response || !action.response.ids) return state;
      const {
        request: { page },
        response: { ids }
      } = action;

      const parentId = getPath(action, "request.params.parentId") || "all";

      return immutableUpdate(state, {
        parentIds: {
          [parentId]: {
            $auto: {
              pages: {
                $auto: {
                  [page]: {
                    $auto: {
                      isFetching: { $set: false },
                      didInvalidate: { $set: false },
                      ids: {
                        $apply: currentIds => {
                          let updatedIds = currentIds;
                          if (!updatedIds) {
                            updatedIds = [];
                          }
                          updatedIds.push(ids);
                          return updatedIds;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.TEAM_REQUEST_SUCCESS: {
      const { teamId } = action.request;

      const detailedTeam = {
        ...state.detailedIds[teamId],
        isFetching: false,
        didInvalidate: false
      };

      return {
        ...state,
        detailedIds: {
          ...state.detailedIds,
          [teamId]: detailedTeam
        }
      };
    }

    case types.TEAMS_CREATE_REQUEST: {
      const pages = {};

      for (let page in state.parentIds.all.pages) {
        pages[page] = {
          ...state.parentIds.all.pages[page],
          didInvalidate: true
        };
      }

      return immutableUpdate(state, {
        parentIds: {
          all: {
            $auto: {
              pages: {
                $auto: {
                  $merge: {
                    ...pages
                  }
                }
              }
            }
          }
        },
        totalCount: {
          $set: state.totalCount + 1
        }
      });
    }

    case types.TEAMS_COUNT_REQUEST_SUCCESS: {
      return {
        ...state,
        totalCount: action.response.count
      };
    }

    case SWITCH_TEAM: {
      return initState;
    }

    default:
      return state;
  }
};

export default api;
