import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const XIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  title,
  ...props
}) => {
  return (
    <svg
      {...props}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 8 8"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <path
        fill={color || easilGrayXDark}
        fillRule="evenodd"
        d="M4 2.627L6.263.364a.9.9 0 0 1 1.273 1.273L5.273 3.9l2.263 2.263a.9.9 0 0 1-1.273 1.273L4 5.173 1.737 7.436A.9.9 0 0 1 .464 6.163L2.727 3.9.464 1.637A.9.9 0 1 1 1.737.364L4 2.627z"
      />
    </svg>
  );
};
XIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.number
};

export default XIcon;
