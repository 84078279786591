import React from "react";
import style from "./style.module.css";
import { NoComponent } from "views/components";
import { noop } from "lib";

import MinimalCardInput from "views/containers/cartContainer/PaymentSection/MinimalCardInput/MinimalCardInput";

const cardLogos = [
  { label: "Visa Logo", path: "/credit_card_logos/Visa-clear.png" },
  { label: "Mastercard Logo", path: "/credit_card_logos/Mastercard-clear.png" },
  { label: "Amex Logo", path: "/credit_card_logos/Amex-clear.png" }
];

export class PaymentOption extends React.Component {
  constructor(props) {
    super(props);

    this.getDisplayComponents = this.getDisplayComponents.bind(this);
    this.optionRef = React.createRef();
    this.handleCreateOrder = this.handleCreateOrder.bind(this);
    this.handleOnApprove = this.handleOnApprove.bind(this);

    // set default state components
    this.state = {
      headerComponents: [{ component: NoComponent, type: "" }],
      ContentComponent: NoComponent,
      orderId: null
    };
  }

  componentDidMount() {
    // set first mount state components
    const displayComponents = this.getDisplayComponents();
    this.setState({
      headerComponents: displayComponents.headers,
      ContentComponent: displayComponents.content
    });

    if (
      this.props.availableTypes.includes("paypal") &&
      process.env.REACT_APP_PAYPAL_CLIENT_ID
    ) {
      if (!window.paypal) {
        // add paypal scripts to the page
        /* eslint-disable */
        !(() => {
          var e = document.createElement("script"),
            t = document.getElementsByTagName("script")[0];
          (e.async = 0),
            (e.src = `https://www.paypal.com/sdk/js?client-id=${
              process.env.REACT_APP_PAYPAL_CLIENT_ID
            }&disable-funding=credit,card&currency=${this.props.currencyCode}`),
            (e.charset = "UTF-8"),
            t.parentNode.insertBefore(e, t);
        })();
        /* eslint-enable */
      }

      const waitForPaypal = () => {
        if (window.paypal) {
          window.paypal
            .Buttons({
              style: {
                layout: "vertical",
                color: "blue",
                shape: "rect",
                label: ""
              },
              createOrder: this.handleCreateOrder,
              onApprove: this.handleOnApprove
            })
            .render("#paypal-button-container");
        } else {
          setTimeout(() => {
            waitForPaypal();
          }, 100);
        }
      };

      // wait for scripts to load and then add the button to the page
      waitForPaypal();
    }
  }

  async handleCreateOrder() {
    // need to get the total price for the order and use paypal actions to create an order
    const { createPaypalOrder } = this.props;

    const [paypalOrderId, orderId] = await createPaypalOrder();

    this.setState({
      orderId
    });
    return paypalOrderId;
  }

  handleOnApprove(data) {
    const { orderId } = this.state;
    // get the details for the confirmed transaction
    this.props.createOrderPayment({ token: data.orderID, orderId });
  }

  componentDidUpdate(prevProps) {
    // update the content component if our type has changed
    if (prevProps.type !== this.props.type) {
      const displayComponents = this.getDisplayComponents();
      this.setState({
        ContentComponent: displayComponents.content
      });
    }
  }

  // get the header component we want to display based on the type we pass in
  getHeaderFromType(type) {
    switch (type) {
      case "paypal": {
        return {
          component: ({ isActive }) => (
            <>
              <div className={style.paymentOptionHeaderLeft}>
                <div
                  className={style.selectionCircle}
                  data-is-active={isActive}
                />
                {/* <div className={style.paymentOptionTitle}>Paypal</div> */}
                <img
                  className={style.paypalLogo}
                  alt={"paypal"}
                  src={"/credit_card_logos/paypalLogo.png"}
                />
              </div>
            </>
          ),
          type
        };
      }
      case "credit":
      default: {
        return {
          component: ({ isActive }) => (
            <>
              <div className={style.paymentOptionHeaderLeft}>
                <div
                  className={style.selectionCircle}
                  data-is-active={isActive}
                />
                <div className={style.paymentOptionTitle}>Credit card</div>
              </div>
              <div className={style.paymentOptionHeaderRight}>
                {cardLogos.map(logo => (
                  <img
                    className={style.logo}
                    alt={logo.label}
                    src={logo.path}
                  />
                ))}
              </div>
            </>
          ),
          type
        };
      }
    }
  }

  // get the display components for the modal (header and content components)
  getDisplayComponents() {
    const { type, availableTypes } = this.props;

    const components = {
      headers: availableTypes.map(availableType =>
        this.getHeaderFromType(availableType)
      )
    };

    switch (type) {
      case "credit": {
        components.content = MinimalCardInput;
        break;
      }
      case "paypal":
      default: {
        components.content = NoComponent;
        break;
      }
    }

    return components;
  }

  render() {
    const {
      type,
      handleSelection,
      setToken,
      token,
      clearPaymentError,
      paymentErrors
    } = this.props;

    const { headerComponents, ContentComponent } = this.state;

    const isContentToShow = ContentComponent !== NoComponent;

    // if there are payment errors then we want to assign the clearPaymentError action
    // else assign a noop to prevent multiple calls
    const clearError = Boolean(paymentErrors.length) ? clearPaymentError : noop;

    return (
      <>
        <div className={style.headerSection}>
          {headerComponents.map(
            ({ component: HeaderComponent, type: headerType }) => {
              const isActive = type === headerType;
              return (
                <div className={style.paymentOption} ref={this.optionRef}>
                  <div
                    className={style.paymentOptionHeader}
                    onClick={() => handleSelection(headerType)}
                  >
                    <HeaderComponent
                      isActive={isActive}
                      handleSelection={() => handleSelection(headerType)}
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
        <div
          className={style.contentWrapper}
          data-is-content-shown={isContentToShow}
        >
          <div className={style.paymentOptionContent}>
            <ContentComponent
              onTokenSuccess={setToken}
              token={token}
              onTokenClear={() => setToken()}
              clearError={clearError}
            />
          </div>
        </div>
      </>
    );
  }
}

export default PaymentOption;
