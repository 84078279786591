import React from "react";
import PropTypes from "prop-types";
import H3 from "views/components/h3/H3";
import SearchInput from "views/components/searchInput/SearchInput";
import Empty from "views/components/empty/Empty";
import Table from "views/components/table/Table";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import SplitArrowHeadIcon from "views/components/icons/SplitArrowHeadIcon";
import CrossedCircleIcon from "views/components/icons/CrossedCircleIcon";
import PopoutItemMenu from "views/components/popout/PopoutItemMenu/PopoutItemMenu";
import style from "./style.module.css";
import Pagination from "../pagination";
import {
  teamPageUsersPageChange,
  teamPagePendingUsersPageChange
} from "state/ui/uiActions";
import { usersApiPageSizeChange } from "state/api/users/usersApiActions";
import { pendingUsersApiPageSizeChange } from "state/api/pendingUsers/pendingUsersApiActions";
import { values, range, isEmpty } from "lib";
import MemberRow from "./memberRow";
import tableStyle from "./memberRow/style.module.css";
import MemberRolesFilterDropdown from "views/components/TeamRolesDropdown/MemberRolesFilterDropdown";
import {
  getTeamSearchResultsTitle,
  getEmptyTeamSearchResultsTitle
} from "lib/teamUtils";
import { easilBlack } from "views/style.module.css";

const baseEllipsisWidth = 24;

const commonStyles = {
  color: "#8f9297",
  fontSize: "14px",
  fontWeight: 500,
  width: "auto",
  position: "absolute"
};

const memberTableHeaders = [
  { name: "Name", style: { ...commonStyles } },
  {
    name: "Email",
    style: { ...commonStyles, left: tableStyle.headerCell1Left }
  },
  {
    name: "Role",
    style: { ...commonStyles, width: tableStyle.headerCell2Right, right: 0 }
  },
  { name: "" }
];

const dataSource = currentTable =>
  currentTable === "Team" ? "users" : "pendingUsers";
const pageChange = currentTable =>
  currentTable === "Team"
    ? teamPageUsersPageChange
    : teamPagePendingUsersPageChange;
const pageSizeChange = currentTable =>
  currentTable === "Team"
    ? usersApiPageSizeChange
    : pendingUsersApiPageSizeChange;

const TableLoading = ({ usersPagination, totalCount }) => {
  const { currentPage, pageSize } = usersPagination;
  const pages = range(1, Math.ceil(totalCount / pageSize) + 1);

  let numberOfRows = pageSize;
  if (currentPage === pages[pages.length - 1]) {
    numberOfRows = totalCount % pageSize;
  }

  return (
    <div className={style.tableSection} data-testid="TableLoading">
      <H3 className={style.tableTitle}>
        <Empty height="32px" width="148px" />
      </H3>
      <Empty height="21px" width="100%" />
      <Empty color="white" height="8px" width="100%" />
      {[...Array(numberOfRows || 10)].map((_, index) => (
        <div key={index}>
          <Empty color="white" height="20px" width="100%" />
          <Empty height="21px" width="100%" />
          <Empty color="white" height="20px" width="100%" />
        </div>
      ))}
      <Empty color="white" height="48px" width="100%" />
      <Empty color="white" height="40px" width="33%" inline />
      <Empty height="40px" width="34%" inline />
      <Empty color="white" height="40px" width="33%" inline />
    </div>
  );
};

const getHeaderStyle = ({ type, currentTable }) => {
  if (currentTable === type) {
    return {
      color: easilBlack,
      borderBottom: `2px solid ${easilBlack}`
    };
  }
  return {
    color: "#7a7d81"
  };
};

class TeamPageCol1 extends React.Component {
  constructor(props) {
    super(props);

    this.setSelectedMember = this.setSelectedMember.bind(this);
    this.openPopoutMenu = this.openPopoutMenu.bind(this);
    this.closePopoutMenu = this.closePopoutMenu.bind(this);
    this.getTableSectionWidth = this.getTableSectionWidth.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onEnterTriggered = this.onEnterTriggered.bind(this);
    this.getTeamSearchSection = this.getTeamSearchSection.bind(this);
    this.getTeamSearchTitle = this.getTeamSearchTitle.bind(this);
    this.getTableHeaders = this.getTableHeaders.bind(this);
    this.handleFilteredRoles = this.handleFilteredRoles.bind(this);
    this.handleMemberLinksClick = this.handleMemberLinksClick.bind(this);

    this.tableSectionRef = React.createRef();

    this.state = {
      selectedMemberId: null,
      popoutOpen: false,
      searchTerm: "",
      currentSearchTerm: "",
      isSearchFocussed: false,
      tableHeaders: [...memberTableHeaders],
      filteredRoles: [],
      includeParentTeams: true
    };
  }

  componentDidMount() {
    this.getTableHeaders();
    window.addEventListener("resize", this.getTableHeaders);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getTableHeaders);
  }

  setSelectedMember(memberId) {
    this.setState({
      selectedMemberId: memberId
    });
  }

  getMenuActionsDetails() {
    let actions = [
      {
        name: "Cancel all invitations",
        Icon: props => <CrossedCircleIcon {...props} />,
        onClick: () => {
          this.props.cancelAllTeamInvites();
          this.closePopoutMenu();
        }
      }
    ];

    if (this.props.pendingUsersTotalCount) {
      actions = [
        {
          name: "Resend all invitations",
          Icon: props => <SplitArrowHeadIcon {...props} />,
          onClick: () => {
            this.props.resendAllTeamInvites();
            this.closePopoutMenu();
          }
        }
      ].concat(actions);
    }

    return actions;
  }

  openPopoutMenu() {
    this.setState({
      popoutOpen: true
    });
  }

  closePopoutMenu() {
    this.setState({
      popoutOpen: false
    });
  }

  getTableSectionWidth() {
    const tableSection = this.tableSectionRef.current;
    if (!tableSection) return baseEllipsisWidth;
    return tableSection.offsetWidth;
  }

  onSearchChange(value) {
    const { includeParentTeams, filteredRoles, currentSearchTerm } = this.state;
    let updatedRoles = [...filteredRoles];
    let updatedCurrentSearchTerm = currentSearchTerm;
    /* blank searchTerm should remove filter */
    if (value === "") {
      updatedRoles = [];
      updatedCurrentSearchTerm = "";

      if (!includeParentTeams) {
        this.props.onSearchUsersByRoles({
          roles: updatedRoles,
          includeParentTeams
        });
      } else {
        this.props.changeTable("Team");
      }
    }
    this.setState({
      searchTerm: value,
      filteredRoles: updatedRoles,
      currentSearchTerm: updatedCurrentSearchTerm
    });
  }

  onEnterTriggered(event) {
    const value = event.target && event.target.value;

    /* on enter while focused */
    if (event.key !== "Enter") {
      return;
    }

    /* get the list of teams based on the search */
    if (value && value.trim()) {
      this.props.changeTable("Search");

      // apend search term to state
      // clear selected roles until action allows term and role filtering
      this.setState({
        currentSearchTerm: value,
        filteredRoles: []
      });

      // search action below
      this.props.onSearchUsersWithinTeam({
        term: this.state.searchTerm,
        includeParentTeams: this.state.includeParentTeams
      });
    }
  }

  handleFilteredRoles({ roles, includeParentTeams }) {
    // append roles to state
    // clear search term until action allows both term and role filter
    if (roles.length) {
      this.setState({
        searchTerm: "",
        currentSearchTerm: ""
      });
    }

    this.setState({
      filteredRoles: roles,
      includeParentTeams
    });

    this.props.onSearchUsersByRoles({ roles, includeParentTeams });
    this.props.changeTable("Search");
  }

  getTableHeaders() {
    const viewportWidth = window.innerWidth;

    if (viewportWidth < 875) {
      // remove Email from headers when viewport hits breakpoint
      // email field is removed at breakpoint in corresponding style module
      this.setState({
        tableHeaders: memberTableHeaders.filter(
          heading => heading.name !== "Email"
        )
      });
      return;
    }

    let updatedHeaders = [...memberTableHeaders];
    const emailStyleValue = viewportWidth < 1030 ? 0.4 : 0.5;

    // update the styling of the Email header to match the dynamic scaling of the
    // corresponding member row
    const emailIndex = updatedHeaders.findIndex(
      heading => heading.name === "Email"
    );
    updatedHeaders[emailIndex].style = {
      ...updatedHeaders[emailIndex].style,
      left: `calc((((100% - 64px) * 0.5) * 0${emailStyleValue}) + 64px)`
    };

    this.setState({
      tableHeaders: updatedHeaders
    });
  }

  getTeamSearchTitle() {
    const { currentSearchTerm, filteredRoles } = this.state;
    const { searchedTeamUsers } = this.props;

    if (searchedTeamUsers && !isEmpty(searchedTeamUsers)) {
      return getTeamSearchResultsTitle(currentSearchTerm, filteredRoles);
    }
    return getEmptyTeamSearchResultsTitle(currentSearchTerm, filteredRoles);
  }

  handleMemberLinksClick(table) {
    // change table to argument string
    // rest any applied filters
    this.props.changeTable(table);
    this.setState({
      includeParentTeams: true,
      filteredRoles: [],
      searchTerm: "",
      currentSearchTerm: ""
    });
  }

  getTeamSearchSection() {
    const {
      cancelTeamInvite,
      currentTeam,
      currentUser,
      isAdmin,
      isProcessing,
      isSearchingTeamUsers,
      onUserDelete,
      onUserRolesUpdate,
      resendTeamInvite,
      searchedTeamUsers = [],
      teamMemberCount,
      usersPagination
    } = this.props;
    const { selectedMemberId } = this.state;
    const searchResultsTitle = this.getTeamSearchTitle();

    if (isSearchingTeamUsers) {
      return (
        <TableLoading
          totalCount={teamMemberCount}
          usersPagination={usersPagination}
        />
      );
    }

    if (searchedTeamUsers && !isEmpty(searchedTeamUsers)) {
      return (
        <>
          <div className={style.searchTitleResults}>{searchResultsTitle}</div>
          <Table
            headers={this.state.tableHeaders}
            className={style.memberTable}
          >
            {Object.values(searchedTeamUsers).map(user => (
              <MemberRow
                isAdmin={isAdmin}
                key={user.id}
                isCurrentUser={user.id === currentUser.id}
                onUserRolesUpdate={onUserRolesUpdate}
                onUserDelete={() =>
                  onUserDelete({
                    user: user
                  })
                }
                member={user}
                currentTeam={currentTeam}
                popoutOpen={selectedMemberId === user.id}
                openPopoutMenu={this.openPopoutMenu}
                setSelectedMember={this.setSelectedMember}
                isProcessing={isProcessing}
                cancelTeamInvite={cancelTeamInvite}
                resendTeamInvite={resendTeamInvite}
              />
            ))}
          </Table>
        </>
      );
    } else {
      return (
        <div className={style.searchTitleResults}>{searchResultsTitle}</div>
      );
    }
  }

  render() {
    const {
      cancelTeamInvite,
      className,
      currentTable,
      currentTeam,
      currentUser,
      isAdmin,
      isCurrentTeamOrg,
      isPendingUsersProcessing,
      onUserDelete,
      onUserRolesUpdate,
      pendingUsers,
      pendingUsersPagination,
      pendingUsersTotalCount,
      resendTeamInvite,
      teamMemberCount,
      users,
      usersPagination
    } = this.props;

    const {
      selectedMemberId,
      popoutOpen,
      searchTerm,
      filteredRoles,
      includeParentTeams
    } = this.state;

    const teamPagination =
      currentTable === "Team" ? usersPagination : pendingUsersPagination;

    const isProcessing = isPendingUsersProcessing && currentTable !== "Team";

    return (
      <div
        className={[style.col1, className].filter(x => x).join(" ")}
        data-testid="TeamPageCol1"
      >
        <div className={style.tableSection} ref={this.tableSectionRef}>
          <div className={style.teamMembersTitle}>Team Members</div>
          {!users ? (
            <TableLoading
              totalCount={teamMemberCount}
              usersPagination={teamPagination}
            />
          ) : (
            <div className={style.table}>
              <div className={style.teamTitleSearchRow}>
                <div className={style.teamTitles} data-testid="teamTitles">
                  <div
                    className={style.tableTitle}
                    onClick={() => this.handleMemberLinksClick("Team")}
                    style={getHeaderStyle({ type: "Team", currentTable })}
                    aria-label="Team Member Count"
                  >
                    Active ({teamMemberCount})
                  </div>
                  <div
                    className={style.tableTitle}
                    onClick={() => this.handleMemberLinksClick("Pending")}
                    style={getHeaderStyle({
                      type: "Pending",
                      currentTable
                    })}
                  >
                    Pending ({pendingUsersTotalCount})
                  </div>
                </div>
                <div className={style.searchFilter}>
                  <SearchInput
                    className={style.teamSearchWrapper}
                    clearInput={() => this.onSearchChange("")}
                    placeholder={"Search for Team Members"}
                    onKeyPress={this.onEnterTriggered}
                    onChange={this.onSearchChange}
                    value={searchTerm}
                    onFocus={() => this.setState({ isSearchFocussed: true })}
                    onBlur={() => this.setState({ isSearchFocussed: false })}
                    isFocussed={this.state.isSearchFocussed}
                  />
                  <MemberRolesFilterDropdown
                    searchTerm={this.state.searchTerm}
                    onSearchUsers={args => this.handleFilteredRoles(args)}
                    onClear={() => this.handleMemberLinksClick("Team")}
                    currentFilteredRoles={filteredRoles}
                    includeParentTeams={includeParentTeams}
                    isCurrentTeamOrg={isCurrentTeamOrg}
                  />
                </div>
              </div>
              {currentTable === "Search" ? (
                this.getTeamSearchSection()
              ) : (
                <>
                  <Table
                    headers={this.state.tableHeaders}
                    className={style.memberTable}
                  >
                    <div className={style.tableDivider} />
                    {values(currentTable === "Team" ? users : pendingUsers).map(
                      member => (
                        <MemberRow
                          isAdmin={isAdmin}
                          key={member.id}
                          isCurrentUser={member.id === currentUser.id}
                          onUserRolesUpdate={onUserRolesUpdate}
                          onUserDelete={() =>
                            onUserDelete({
                              user: member,
                              isLastUserInPage: values(users).length === 1
                            })
                          }
                          member={member}
                          currentTeam={currentTeam}
                          isPending={currentTable === "Pending"}
                          popoutOpen={selectedMemberId === member.id}
                          openPopoutMenu={this.openPopoutMenu}
                          setSelectedMember={this.setSelectedMember}
                          isProcessing={isProcessing}
                          cancelTeamInvite={cancelTeamInvite}
                          resendTeamInvite={resendTeamInvite}
                        />
                      )
                    )}
                    {currentTable === "Pending" &&
                      pendingUsersTotalCount === 0 && (
                        <div className={style.pendingEmpty}>
                          {" "}
                          No Pending Invitations{" "}
                        </div>
                      )}
                    {currentTable === "Pending" && (
                      <div
                        className={style.menuWrapper}
                        style={{ width: `${this.getTableSectionWidth()}px` }}
                      >
                        <div
                          className={style.menu}
                          onClick={this.openPopoutMenu}
                          id={`team-page-pending-invite`}
                        >
                          <EllipsisIcon
                            color="#999c9e"
                            withBackground={true}
                            backgroundColor="transparent"
                          />
                          {popoutOpen && (
                            <PopoutItemMenu
                              onClose={this.closePopoutMenu}
                              buttonId={`team-page-pending-invite`}
                              className={tableStyle.popout}
                              nameSectionClassName={
                                tableStyle.popoutNameSection
                              }
                              itemName={"allMenu"}
                              actions={this.getMenuActionsDetails()}
                              menuClassName={tableStyle.popoutMenu}
                              isProcessing={isProcessing}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Table>
                  <Pagination
                    resource={`api.${dataSource(currentTable)}`}
                    currentPageAddress={`ui.teamPage.${dataSource(
                      currentTable
                    )}.currentPage`}
                    pageChangeAction={pageChange(currentTable)}
                    pageSizeChangeAction={pageSizeChange(currentTable)}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
TeamPageCol1.propTypes = {
  currentUser: PropTypes.object.isRequired,
  users: PropTypes.object,
  currentTeam: PropTypes.object.isRequired,
  onInviteToTeam: PropTypes.func.isRequired,
  inviteToTeamForm: PropTypes.object.isRequired,
  onUserRolesUpdate: PropTypes.func,
  onUserDelete: PropTypes.func,
  teamMemberCount: PropTypes.number,
  usersPagination: PropTypes.object
};

export default TeamPageCol1;
