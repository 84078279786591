import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const OutlineNoPaddingIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <g fill={color || easilGrayXDark} transform="translate(2 3.5)">
        <rect width="16" height="2" rx="1" />
        <rect width="16" height="3" y="4" rx="1" />
        <rect width="16" height="4" y="9" rx="1" />
      </g>
    </g>
  </svg>
);
OutlineNoPaddingIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default OutlineNoPaddingIcon;
