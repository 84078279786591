import { Logger } from "lib";
import {
  calculateScaledNewDimensions,
  calculateMovedNewDimensions,
  calculateCropMaskScale
} from "views/components/Editor/CropLayer/ImageCropping/ImageCroppingPreview/ImageCroppingPreviewCalculations";
import { getPageHtmlNode } from "views/components/Editor/utils";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { update as updateActionBarState } from "state/ui/actionBar/actionBarActions";
import { actionBarStateSelector } from "state/ui/actionBar/actionBarSelectors";
import {
  contextSelector,
  selectedItemsSelector
} from "state/ui/editorContext/editorContextSelectors";

const EditorImageCropOps = {
  startCropMaskResize() {
    Logger.info("Editor.startCropMaskResize called");

    this.setState({
      isCropping: true
    });
  },

  finishCropMaskResize({
    differenceFromInitialOffset,
    dragItem,
    adjustmentOffset
  }) {
    Logger.info("Editor.finishCropMaskResize called");

    const { zoom, selectedItems } = this.state;

    let imageElement = this.getElementWithPreview(selectedItems[0].itemId);

    imageElement = {
      ...imageElement
      // angle: 0
    };

    const pageId = selectedItems[0].pageId;

    const pageDiv = getPageHtmlNode(pageId);
    const pageOffset = pageDiv.getBoundingClientRect();

    const { mask, height, width, top, left } = calculateCropMaskScale.calc({
      imageElement,
      dragItem,
      differenceFromInitialOffset,
      pageOffset,
      zoom,
      adjustmentOffset
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          ...selectedItems[0].preview,
          mask,
          height,
          width,
          top,
          left
        }
      }
    ];

    this.setState({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  },

  startImageCropMode() {
    Logger.info("Editor.startImageCropMode called");

    const { actionbar, context, designData, selectedItems } = this.props;

    if (
      selectedItems.length === 1 &&
      designData.elements[selectedItems[0].itemId].type === "image" &&
      designData.elements[selectedItems[0].itemId].fitToFrame
    ) {
      return;
    }

    const updatedActionbarState = {
      ...actionbar,
      buttonActive: "crop"
    };
    const updatedContextState = {
      context: {
        ...context,
        isCroppingImage: true
      }
    };
    this.props.updateActionBarState(updatedActionbarState);
    this.props.updateContextState(updatedContextState);
  },

  cancelImageCropMode(dispatch, getState) {
    console.log("Editor.cancelImageCropMode called");
    const state = getState();

    const actionbar = actionBarStateSelector(state);
    const context = contextSelector(state);
    const selectedItems = selectedItemsSelector(state);

    const selectedItemsUpdated = selectedItems.map(item => ({
      ...item,
      preview: {}
    }));

    const actionbarUpdate = {
      ...actionbar,
      buttonActive: null
    };

    const contextUpdate = {
      context: {
        ...context,
        isCroppingImage: false
      },
      selectedItems: selectedItemsUpdated
    };

    dispatch(updateActionBarState(actionbarUpdate));
    dispatch(updateEditorContextState(contextUpdate));
  },

  startImageCropMoving() {
    Logger.info("Editor.startImageCropMoving called");

    this.setState({
      isCropping: true
    });
  },

  finishImageCropMoving({ differenceFromInitialOffset }) {
    Logger.info("Editor.finishImageCropMoving called");

    const { zoom, selectedItems } = this.state;

    let imageElement = this.getElementWithPreview(selectedItems[0].itemId);

    const { mask } = calculateMovedNewDimensions({
      imageElement,
      differenceFromInitialOffset,
      zoom
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          ...selectedItems[0].preview,
          mask
        }
      }
    ];
    this.setState({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  },

  startImageCropResizing({ pageId }) {
    Logger.info("Editor.startImageCropResizing called");

    this.setState({
      isCropping: true
    });
  },

  finishImageCropResizing({
    handlerInitialPosition,
    anchorPoint,
    differenceFromInitialOffset
  }) {
    Logger.info("Editor.finishImageCropResizing called");

    const { zoom, selectedItems } = this.state;

    let imageElement = this.getElementWithPreview(selectedItems[0].itemId);

    imageElement = {
      ...imageElement
      // angle: 0
    };

    const pageId = selectedItems[0].pageId;
    const pageDiv = getPageHtmlNode(pageId);

    const pageOffset = pageDiv.getBoundingClientRect();

    const {
      scale,
      mask,
      top,
      left,
      height,
      width
    } = calculateScaledNewDimensions({
      imageElement,
      differenceFromInitialOffset,
      zoom,
      dragItem: { anchorPoint, handlerInitialPosition },
      pageOffset
    });

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          scale,
          mask,
          top,
          left,
          height,
          width
        }
      }
    ];

    this.setState({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  },

  applyCrop(dispatch, getState) {
    Logger.info("Editor.EditoImageCropOps.applyCrop  called");
    const state = getState();

    const actionbar = actionBarStateSelector(state);
    const context = contextSelector(state);
    const selectedItems = selectedItemsSelector(state);
    const { designData } = context;

    const selectedItem = selectedItems[0];

    if (!selectedItem.preview) {
      Logger.info(
        "Editor.EditorImageCropOps - No changes to be applied, cancelling it"
      );
      dispatch(EditorImageCropOps.cancelImageCropMode);
      return;
    }

    const currentItem = designData.elements[selectedItem.itemId];
    const scale = currentItem.scale;

    const actionbarUpdate = {
      ...actionbar,
      buttonActive: null
    };

    const contextUpdate = {
      designData: designData.updateElementsAttribute({
        elementsId: [selectedItem.itemId],
        attributes: { scale, ...selectedItems[0].preview } // always give a scale but prefer preview scale
      }),
      selectedItems: selectedItems.map(item => ({
        ...item,
        preview: {}
      })),
      context: {
        ...context,
        isCroppingImage: false
      }
    };

    dispatch(updateActionBarState(actionbarUpdate));
    dispatch(updateEditorContextState(contextUpdate));
  }
};

export default EditorImageCropOps;
