import { confirm } from "lib/ConfirmationModal/ConfirmationModal";

import style from "./style.module.css";

const confirmDeleteSmartImage = (onClickDelete, onCancelDelete) => {
  let headerText;
  let bodyText;
  let buttonText;

  headerText = "Unassign Smart Image?";
  bodyText = "Are you sure you want to unassign this Smart Image?";
  buttonText = "Unassign";
  confirm({
    header: headerText,
    text: bodyText,
    onOk: onClickDelete,
    onCancel: onCancelDelete,
    buttonsDetails: {
      ok: {
        theme: "redSolid",
        className: style.button,
        name: buttonText
      },
      cancel: {
        theme: "gray",
        className: style.button,
        name: "Cancel"
      },
      buttonsClassName: style.deleteConfirmationModalButtons
    },
    modalClassName: style.deleteConfirmationModal
  });
};

export default confirmDeleteSmartImage;
