/**
 * @desc makes an async version of an action function
 * @param {function} action action function to be set async
 * @returns {function} Promise resolving with action response
 */
export const asyncAction = action => (args = {}) => dispatch =>
  new Promise((resolve, reject) => {
    args.resolve = resolve;
    args.reject = reject;
    dispatch(action(args));
  });
