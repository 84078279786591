import React, { Component } from "react";
import style from "./style.module.css";
import ROLES from "lib/roles";
// components
import Modal from "views/components/modal/Modal";
import Tooltip from "views/components/Tooltip/Tooltip";
import PopoutItemMenu from "views/components/popout/PopoutItemMenu/PopoutItemMenu";
import EditTeamRolesModal from "views/components/TeamRolesDropdown/EditTeamRolesModal";
import Avatar from "views/components/avatar/Avatar";
// icons
import CrossedCircleIcon from "views/components/icons/CrossedCircleIcon";
import TrashCanShortIcon from "views/components/icons/TrashCanShortIcon";
import PaperPlaneIcon from "views/components/icons/PaperPlaneIcon";
import SmoothPencilIcon from "views/components/icons/SmoothPencilIcon";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import InfoIcon from "views/components/icons/InfoIcon";

class MemberRow extends Component {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.getMenuActionsDetails = this.getMenuActionsDetails.bind(this);
    this.getMemberModal = this.getMemberModal.bind(this);
    this.getMemberIcon = this.getMemberIcon.bind(this);

    this.state = {
      showModal: false,
      modal: null
    };
  }

  handleOpenModal(action) {
    this.setState({
      showModal: true,
      modal: action
    });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  isMemberOfCurrentTeam(memberTeamId) {
    return memberTeamId !== this.props.currentTeam.id;
  }

  renderParentTeamIndicator(memberParentTeam) {
    if (this.isMemberOfCurrentTeam(memberParentTeam.id)) {
      return (
        // <ParentTeamIndicator team={memberParentTeam} />
        <div class={style.parentIndicator}>
          from <strong>{memberParentTeam.name}</strong>
        </div>
      );
    } else {
      return null;
    }
  }

  getMenuActionsDetails() {
    if (this.props.isPending) {
      return [
        {
          name: "Resend invitation",
          Icon: () => <PaperPlaneIcon size="20px" />,
          onClick: () =>
            this.props.resendTeamInvite({
              invitationId: this.props.member.id,
              onSuccess: () => this.props.setSelectedMember(null)
            })
        },
        {
          name: "Cancel invitation",
          Icon: () => <CrossedCircleIcon size="20px" />,
          onClick: () =>
            this.props.cancelTeamInvite({
              invitationId: this.props.member.id,
              onSuccess: () => this.props.setSelectedMember(null)
            })
        }
      ];
    }

    return [
      {
        name: "Edit Roles",
        Icon: () => <SmoothPencilIcon size="20px" />,
        onClick: () => {
          this.handleOpenModal("edit");
          this.props.setSelectedMember(null);
        }
      },
      {
        name: "Remove",
        Icon: () => <TrashCanShortIcon size="20px" />,
        onClick: () => {
          this.handleOpenModal("remove");
          this.props.setSelectedMember(null);
        }
      }
    ];
  }

  getMemberModal() {
    const { modal } = this.state;

    if (modal === "remove") {
      return (
        <Modal
          className={style.memberModal}
          isOpen={this.state.showModal}
          onRequestClose={this.handleCloseModal}
          header={`Remove ${this.props.member.name} from ${this.props.currentTeam.name}`}
          buttons={[
            {
              name: "Cancel",
              theme: "gray",
              onClick: this.handleCloseModal
            },
            {
              name: "Remove",
              theme: "redSolid",
              onClick: () => {
                this.props.onUserDelete();
                this.handleCloseModal();
              }
            }
          ]}
        >
          <div aria-label="remove confirmation" className={style.modal}>
            {`Are you sure you want to remove ${this.props.member.name} from ${this.props.currentTeam.name}?`}
          </div>
        </Modal>
      );
    }

    return (
      <EditTeamRolesModal
        isOpen={this.state.showModal}
        member={this.props.member}
        onSubmit={this.props.onUserRolesUpdate}
        onRequestClose={this.handleCloseModal}
      />
    );
  }

  getMemberIcon() {
    const { member, popoutOpen, isProcessing, isCurrentUser } = this.props;

    if (this.isMemberOfCurrentTeam(member.team.id) || isCurrentUser) {
      const tipText = isCurrentUser
        ? "Cannot edit your own roles"
        : "Cannot edit Parent member roles";
      return (
        <>
          <InfoIcon
            color={style.easilGrayXDark}
            size="18px"
            tipText={tipText}
            tipFor={"team-member-info"}
            style={{ marginRight: "3px" }}
          />
          <Tooltip id={"team-member-info"} place="left" />
        </>
      );
    }

    return (
      <>
        <EllipsisIcon
          color={style.easilGrayXDark}
          withBackground={true}
          backgroundColor="transparent"
        />
        {popoutOpen && (
          <PopoutItemMenu
            onClose={() => this.props.setSelectedMember(null)}
            className={style.popout}
            nameSectionClassName={style.popoutNameSection}
            itemName={member.email}
            actions={this.getMenuActionsDetails()}
            menuClassName={style.popoutMenu}
            isProcessing={isProcessing}
            buttonId={`team-member-${member.id}`}
            actionsClassName={style.popoutActions}
          />
        )}
      </>
    );
  }

  render() {
    const { member, isAdmin, popoutOpen } = this.props;

    const TeamMemberModal = this.getMemberModal();
    const TeamMemberIcon = this.getMemberIcon();

    return (
      <React.Fragment>
        <div className={style.row} data-testid="MemberRow">
          <div className={style.avatarCell}>
            <Avatar
              size="40px"
              theme="dark"
              src={member.avatarUrl}
              name={member.name}
              singleCharacterNameInitial={true}
              charsClassName={style.noAvatarChars}
            />
          </div>
          <div className={style.memberDetails}>
            <div className={style.cell}>
              <div className={style.nameCell} aria-label="member name">
                <span>{member.name}</span>
                {this.renderParentTeamIndicator(member.team)}
              </div>
              <div className={style.emailCell} aria-label="member email">
                <span>{member.email}</span>
              </div>
            </div>
            <div className={style.cell}>
              <div className={style.roleCell} aria-label="member roles">
                {ROLES.map(role => (
                  <div key={role.key}>
                    <div
                      className={style.roleIcon}
                      data-is-active={member.roles.includes(role.key)}
                      data-tip={role.description}
                      data-for="member-row-role-tip"
                    >
                      {role.label}
                    </div>
                    <Tooltip id="member-row-role-tip" place="bottom" />
                  </div>
                ))}
              </div>
              {isAdmin && (
                <div className={style.iconCell}>
                  <div
                    className={style.menu}
                    onClick={() =>
                      this.props.setSelectedMember(
                        popoutOpen ? null : member.id
                      )
                    }
                    id={`team-member-${member.id}`}
                  >
                    {TeamMemberIcon}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.showModal && TeamMemberModal}
      </React.Fragment>
    );
  }
}

export default MemberRow;
