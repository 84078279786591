import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import SubPageLoading from "views/pages/SubPageLoading";
const TeamSettingsPage = lazy(() => import("./TeamSettingsPage"));

export const TeamSettingsPageLazy = props => (
  <Suspense fallback={<SubPageLoading />}>
    <TeamSettingsPage {...props} />
  </Suspense>
);
TeamSettingsPageLazy.displayName = "TeamSettingsPage";

export default WithHeader(TeamSettingsPageLazy);
