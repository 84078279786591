import React from "react";

export default function EmptySearchIcon(props) {
  return (
    <svg viewBox="0 0 56 43" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          width="50"
          height="37"
          x="6"
          y="6"
          fill="#DCDCE0"
          opacity=".85"
          rx="2"
        />
        <path
          fill="#2C2E2F"
          fillRule="nonzero"
          d="M52 4.103v31.794C52 38.163 50.21 40 48 40H4c-2.21 0-4-1.837-4-4.103V4.103C0 1.837 1.79 0 4 0h44c2.21 0 4 1.837 4 4.103zm-2 0c0-1.133-.895-2.053-2-2.053H4c-1.105 0-2 .919-2 2.052v31.794c0 1.133.895 2.052 2 2.052h44c1.105 0 2-.919 2-2.052V4.103z"
        />
        <path
          fill="#2C2E2F"
          fillRule="nonzero"
          d="M24 10a9 9 0 017.032 14.617l3.675 3.676a1 1 0 01-1.414 1.414l-3.676-3.675A9 9 0 1124 10zm0 2a7 7 0 104.865 12.033l.078-.09a.96.96 0 01.089-.08A7 7 0 0024 12z"
        />
      </g>
    </svg>
  );
}
