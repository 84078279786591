import * as types from "./upgradeModalTypes";

export const initState = {
  subscriptionCode: null
};

const upgradeModalReducers = (state = initState, action) => {
  switch (action.type) {
    case types.SHOW_UPGRADE_MODAL: {
      return {
        ...state,
        subscriptionCode: action.subscriptionCode
      };
    }

    case types.HIDE_UPGRADE_MODAL: {
      return {
        ...state,
        subscriptionCode: null
      };
    }

    default:
      return state;
  }
};

export default upgradeModalReducers;
