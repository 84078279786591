import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ThreeUsersIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  rotate,
  title,
  ...props
}) => {
  return (
    <svg
      {...props}
      style={style}
      width={width || size || 24}
      height={height || size || 24}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      data-testid="ThreeUsersIcon"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          stroke={color || easilGrayXDark}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
          d="M7 18v-2a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v2h0M3 18v-1.919c0-.944.604-1.782 1.5-2.081h0m15 0a2.194 2.194 0 0 1 1.5 2.081V18h0"
        />
        <path
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          d="M12 5a2.496 2.496 0 0 1 2.5 2.5c0 .69-.28 1.315-.732 1.768A2.492 2.492 0 0 1 12 10c-.69 0-1.315-.28-1.768-.732A2.492 2.492 0 0 1 9.5 7.5c0-.69.28-1.315.732-1.768A2.492 2.492 0 0 1 12 5z"
        />
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M6 5.5c.288 0 .567.04.831.117a5.483 5.483 0 0 0-.328 1.7 1.286 1.286 0 1 0 .357 2.139c.206.547.5 1.052.861 1.5A3 3 0 1 1 6 5.5zm12 0a3 3 0 1 1-1.722 5.457c.363-.449.656-.954.864-1.501a1.286 1.286 0 1 0 .354-2.14l.004.184a5.49 5.49 0 0 0-.33-1.883c.263-.076.542-.117.83-.117z"
        />
      </g>
    </svg>
  );
};
ThreeUsersIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.string
};

export default ThreeUsersIcon;
