import * as types from "state/entities/userTeamSmartImages/userTeamSmartImagesTypes.js";

export const initState = {
  isSaving: false
};

const userTeamSmartImagesApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST:
    case types.USER_TEAM_SMART_IMAGE_UPDATE_REQUEST: {
      return {
        ...state,
        isSaving: true
      };
    }

    case types.USER_TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS:
    case types.USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS: {
      return {
        ...state,
        isSaving: false
      };
    }

    default:
      return state;
  }
};

export default userTeamSmartImagesApiReducers;
