import { Logger, userTracker, isEmpty } from "lib";
import { calculateTextMaskImageScaledNewDimensions } from "lib/croppingUtils";
import { EDITOR_ELEMENTS_MAP } from "lib/constants";
import { updateDurationForDesign } from "lib/designUtils";

const EditorTextMaskingOps = {
  onAddTextMask({ imageElement, containerProps }) {
    if (imageElement.type !== EDITOR_ELEMENTS_MAP.IMAGE) {
      return;
    }

    Logger.info("Editor.onAddTextMask called");
    const { designData, selectedItems } = containerProps;

    userTracker.track({
      event: userTracker.events.textMask,
      properties: {
        design_id: designData.id
      }
    });

    const updatedStateData = {
      designData: designData.addTextMask({
        elementId: selectedItems[0].itemId,
        imageElement
      })
    };

    const designWithUpdatedDuration = updateDurationForDesign(
      updatedStateData.designData,
      designData
    );

    containerProps.updateContextState({
      designData: designWithUpdatedDuration
    });
    containerProps.onSave(designWithUpdatedDuration, {});
  },

  onRemoveTextMask() {
    Logger.info("Editor.onRemoveTextMask called");
    const { designData, selectedItems } = this.state;

    this.updateStateAndSave({
      designData: designData.removeTextMask({
        elementId: selectedItems[0].itemId
      }),
      actionbar: {
        ...this.state.actionbar,
        buttonActive: null
      }
    });
  },

  onCancelTextMask() {
    Logger.info("Editor.onCancelTextMask called");
    const { actionbar } = this.state;

    this.setState({
      actionbar: {
        ...actionbar,
        buttonActive: null
      },
      context: {
        isCroppingTextMaskImage: false
      }
    });
  },

  startTextMaskCropMoving() {
    Logger.info("Editor.startCropMoving called");

    this.setState({
      isCropping: true
    });
  },

  cancelTextMaskCrop() {
    Logger.info("Editor.cancelTextMaskCrop called");
    const { selectedItems, actionbar } = this.state;

    this.setState({
      actionbar: {
        ...actionbar,
        buttonActive: null
      },
      selectedItems: selectedItems.map(item => ({
        ...item,
        preview: {}
      })),
      context: {
        isCroppingTextMaskImage: false
      }
    });
  },

  updateTextMask() {
    Logger.info("Editor.updateTextMask called");
    const { designData, selectedItems } = this.state;

    const selectedItem = selectedItems[0];

    if (isEmpty(selectedItem.preview)) {
      this.state.cancelTextMaskCrop();
      return;
    }

    const maskImage = selectedItem.preview.maskImage;

    this.updateStateAndSave({
      designData: designData.updateTextMask({
        elementId: selectedItem.itemId,
        maskImage
      }),
      selectedItems: selectedItems.map(item => ({
        ...item,
        preview: {}
      })),
      context: {
        isCroppingTextMaskImage: false
      }
    });
  },

  finishTextMaskCropMoving({ differenceFromInitialOffset }) {
    Logger.info("Editor.finishCropMoving called");
    const { zoom, selectedItems, designData } = this.state;

    let textElement = designData.getElement(selectedItems[0].itemId);

    textElement = {
      ...textElement,
      ...selectedItems[0].preview
    };

    const maskImage = {
      ...textElement.maskImage,
      left: textElement.maskImage.left + differenceFromInitialOffset.x / zoom,
      top: textElement.maskImage.top + differenceFromInitialOffset.y / zoom
    };

    if (maskImage.top > 0) {
      maskImage.top = 0;
    }

    const maskImageBottom = maskImage.top + maskImage.height;
    if (maskImageBottom < textElement.height) {
      maskImage.top += textElement.height - maskImageBottom;
    }

    if (maskImage.left > 0) {
      maskImage.left = 0;
    }

    const maskImageRight = maskImage.left + maskImage.width;
    if (maskImageRight < textElement.width) {
      maskImage.left += textElement.width - maskImageRight;
    }

    this.setState({
      selectedItems: selectedItems.map(selectedItem => ({
        ...selectedItem,
        preview: {
          maskImage
        }
      })),
      isCropping: false
    });
  },

  startTextMaskCropResizing({ pageId }) {
    Logger.info("Editor.startCropResizing called");

    this.setState({
      isCropping: true
    });
  },

  finishTextMaskCropResizing({ dragItem, differenceFromInitialOffset }) {
    Logger.info("Editor.finishCropResizing called");

    const { designData, selectedItems, zoom } = this.state;

    let textElement = designData.getElement(selectedItems[0].itemId);

    textElement = {
      ...textElement,
      ...selectedItems[0].preview
    };

    const imageNewDimensions = calculateTextMaskImageScaledNewDimensions({
      dragItem,
      textElement,
      zoom,
      differenceFromInitialOffset
    });

    const maskImage = {
      ...textElement.maskImage,
      height: imageNewDimensions.height / zoom,
      width: imageNewDimensions.width / zoom,
      left: textElement.maskImage.left + imageNewDimensions.leftDiff / zoom,
      top: textElement.maskImage.top + imageNewDimensions.topDiff / zoom,
      scale: imageNewDimensions.scale
    };

    const maskImageBottom = maskImage.top + maskImage.height;
    const maskImageRight = maskImage.left + maskImage.width;

    if (maskImage.top > 0) {
      maskImage.top = 0;
    }
    if (maskImageBottom < textElement.height) {
      maskImage.top += textElement.height - maskImageBottom;
    }
    if (maskImage.left > 0) {
      maskImage.left = 0;
    }
    if (maskImageRight < textElement.width) {
      maskImage.left += textElement.width - maskImageRight;
    }

    this.setState({
      selectedItems: selectedItems.map(selectedItem => ({
        ...selectedItem,
        preview: {
          maskImage
        }
      })),
      isCropping: false
    });
  },

  startTextImageCrop() {
    Logger.info("Editor.startTextImageCrop called");

    this.setState({
      context: {
        isCroppingTextMaskImage: true
      }
    });
  }
};

export default EditorTextMaskingOps;
