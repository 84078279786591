import { cloneDeep } from "lib";
import { jsonStringEqual } from "./equalityUtils";
import { getVideoHasAudioFromSrc } from "lib/videoHelpers";
import { ANIMATED_DESIGN_PAGE_DEFAULT_DURATION } from "lib/constants";
import { getImageName, getImageSize } from "lib/getImageDetails";
import { getAnimationData } from "lib/animatedElementUtils";
import { isTextElement } from "lib/elements";
import { hasSmartTextPlaceholders } from "lib/textUtils";

/**
 * @desc gets the object inside an animated elements data that contains the duration value
 * @param {Object} element - element data to be checked over
 * @returns {Object} animatedElementData - The object data or element data that contains the duration value
 * eg. Will return the maskImage object for a textbox || single imageInstruction object for a grid element
 */
export const getAnimatedElementData = element => {
  if (element.type === "image" && element.duration) {
    return element;
  }

  if (element.hasOwnProperty("duration") && !!element.duration) {
    return element;
  }

  if (element.hasOwnProperty("maskImage") && element.maskImage.duration) {
    return element.maskImage;
  }

  return null;
};

/**
 * @desc gets all animated elements on a single page in a design
 * @param {Object} designData - design data to be checked over
 * @param {String} pageId - pageId for page to be checked over
 * @returns {Object} animatedElementsOnDesignPage - an object containing animated elements on
 * the given page. Output in the form: {
 *  elementId(id inside the object containing the duration value, not the uniqueId):
 *  the object containing the animations duration value
 * }
 */
export const getAnimatedElementsOnPage = (designData, pageId) => {
  if (!pageId || !designData.pages || !designData.pages[pageId]) return null;

  const animatedElements = designData.pages[pageId].animatedElements;

  if (!animatedElements || Object.keys(animatedElements).length === 0) {
    return null;
  }

  return Object.values(animatedElements)
    .map(element => designData.getElement(element.uniqueId))
    .filter(x => x);
};

export const getElementsWithAudioOnPage = async (designData, pageId) => {
  const animatedElementsOnPage = getAnimatedElementsOnPage(designData, pageId);

  if (!animatedElementsOnPage) {
    return [];
  }

  const elementAudioChecks = await Promise.all(
    Object.values(animatedElementsOnPage).map(async element => {
      const elementTargets = cloneDeep(
        element.imageInstructions && element.imageInstructions.length > 0
          ? element.imageInstructions
          : [element]
      );
      const elementTargetPromises = elementTargets.map(
        async (elementTarget, targetIndex) => {
          let hasAudio = elementTarget.hasAudio;
          if (hasAudio === undefined) {
            const videoUtils = await import("lib/isVideo");
            if (videoUtils.isVideo(elementTarget.src)) {
              // no hasAudio set, need to get one
              hasAudio = await getVideoHasAudioFromSrc(
                elementTarget.previewSrc
              );
            }
          }

          elementTargets[targetIndex] = {
            ...elementTarget,
            hasAudio: !!hasAudio
          };
          return;
        }
      );
      await Promise.all(elementTargetPromises);
      return elementTargets.some(instruction => instruction.hasAudio);
    })
  );

  return Object.values(animatedElementsOnPage).filter(
    (elementOnPage, index) => elementAudioChecks[index]
  );
};

export const getAnimatedElementsForDesign = designData => {
  if (!designData) return {};
  return Object.keys(designData.pages).reduce(
    (accumulator, pageId) => ({
      ...accumulator,
      ...Object.values(designData.pages[pageId].animatedElements || {})
        .map(animatedElement => designData.getElement(animatedElement.uniqueId))
        .filter(x => x)
    }),
    {}
  );
};

export const isDesignAnimated = designData =>
  Object.values(designData.pages).some(page => page.duration);

export const updateDurationForDesign = (designData, previousDesignData) => {
  let updatedDesignData = cloneDeep(designData);
  const animatedElementPages = {};
  const animatedElementsInDesign = getAnimatedElementsForDesign(designData);
  let areAnimationsInDesign = Object.keys(animatedElementsInDesign).length > 0;

  if (
    jsonStringEqual(
      getAnimatedElementsForDesign(designData),
      getAnimatedElementsForDesign(previousDesignData)
    )
  ) {
    // return early if the animatedElements list has not updated at all
    return updatedDesignData;
  }

  // check through design page elements to find animated images (GIFs)
  Object.keys(designData.pages).forEach(pageId => {
    const page = designData.pages[pageId];
    const currentAnimatedElements = getAnimatedElementsOnPage(
      designData,
      pageId
    );
    const previousAnimatedElements = getAnimatedElementsOnPage(
      previousDesignData,
      pageId
    );
    const isDurationManual = page.isDurationManual;

    const isAnimatedElementsDifferent = !jsonStringEqual(
      currentAnimatedElements,
      previousAnimatedElements
    );

    if (isDurationManual || !isAnimatedElementsDifferent) {
      return;
    }

    const animatedElementsOnPage = getAnimatedElementsOnPage(
      designData,
      pageId
    );

    if (animatedElementsOnPage) {
      // set flag so when can exit early if design contains no animated elements
      animatedElementPages[pageId] = animatedElementsOnPage;
    }

    return;
  });

  if (areAnimationsInDesign) {
    Object.keys(animatedElementPages).forEach(pageId => {
      const animatedElements = animatedElementPages[pageId];
      const animatedElementData = animatedElements.map(element =>
        getAnimationData({ ...element, pageId })
      );
      // map through animated elements to return the longest duration for that page
      const longestAnimatedElement = animatedElementData.flat().reduce(
        (prevImage, currentImage) => {
          return (prevImage.duration || 0) > currentImage.duration
            ? prevImage
            : currentImage;
        },
        { duration: undefined }
      );

      // set each page duration to match the longest duration of all animated elements found on that page
      if (
        !designData.pages[pageId].duration ||
        !designData.pages[pageId].duration < longestAnimatedElement.duration
      ) {
        updatedDesignData.pages[pageId].duration =
          longestAnimatedElement.duration;
      }

      // determine remaining pages but filtering out pages that contain animated elements from the design data pages object
      const remainingDesignPages = updatedDesignData.pagesOrder.filter(
        id => !Object.keys(animatedElementPages).includes(id)
      );
      // set remaining pages to the default duration value
      if (areAnimationsInDesign && remainingDesignPages.length > 0) {
        remainingDesignPages.forEach(pageId => {
          if (!updatedDesignData.pages[pageId].isDurationManual) {
            updatedDesignData.pages[
              pageId
            ].duration = ANIMATED_DESIGN_PAGE_DEFAULT_DURATION;
          }
        });
      }
    });
  } else {
    Object.keys(updatedDesignData.pages).forEach(pageId => {
      updatedDesignData.pages[pageId].duration = undefined;
      updatedDesignData.pages[pageId].isDurationManual = false;
    });
  }
  return updatedDesignData;
};

export const formatDesignAsset = animation => ({
  thumbnailUrl: animation.thumbSrc || animation.previewSrc || animation.src,
  previewUrl: animation.previewSrc || animation.src,
  url: animation.previewSrc,
  mediaId: animation.id || animation.mediaId,
  price: animation.price,
  name: getImageName(animation),
  size: getImageSize(animation),
  originalUrl: animation.originalSrc
});

export const getSpreadElementsOrderForPage = ({
  designData,
  currentPageId
}) => {
  return designData.pages[currentPageId].elementsOrder.reduce(
    (previousValue, elementId) => {
      const element = designData.getElement(elementId);
      if (element.type === "group") {
        // element is a group, spread its element order here
        const newIds = [elementId].concat(element.elementsOrder);
        return previousValue.concat(newIds);
      }
      return previousValue.concat([elementId]);
    },
    []
  );
};

export const hasSmartFields = designData => {
  return Object.keys(designData.pages).some(pageId => {
    const pageElements = designData.pages[pageId].quickInputOrder;

    return pageElements.some(elmtId => {
      const elementData = designData.elements[elmtId];

      if (elementData?.type === "group") {
        elementData.elementsOrder.forEach(elementId => {
          const groupElementData = designData.elements[elementId];
          return (
            isTextElement(groupElementData.type) &&
            hasSmartTextPlaceholders(groupElementData.value)
          );
        });
      }

      return (
        isTextElement(elementData.type) &&
        hasSmartTextPlaceholders(elementData.value)
      );
    });
  });
};

export const isDesignOpenQuickInput = designData =>
  Object.values(designData.pages)[0].openQuickInput;
