import { createSelector } from "reselect";

const designDataSelector = ({ state }) => {
  if (state.entities) return state.entities.designsData;
};
const designIdsPassThrough = ({ designIds }) => designIds;
const designIdPassThrough = ({ designId }) => designId;

const processLatestDataForDesigns = (designsData, designIds) => {
  if (!designsData) return null;

  return Object.values(designsData).filter(data =>
    designIds.includes(data.designId)
  );
};

export const latestDataForDesigns = createSelector(
  [designDataSelector, designIdsPassThrough],
  processLatestDataForDesigns
);

const processLatestDesignDataForDesign = (designsData, designId) => {
  if (!designsData) return null;
  const designDataForDesign = Object.values(designsData).find(
    data => data.designId === designId
  );
  if (!designDataForDesign) return null;
  return designDataForDesign;
};

export const latestDesignDataForDesign = createSelector(
  [designDataSelector, designIdPassThrough],
  processLatestDesignDataForDesign
);
