import React from "react";
import { connect } from "react-redux";
import AddFolderModal from "./AddFolderModal";
import { removeAllToast } from "state/ui/toaster/ToasterActions";

const AddFolderModalContainer = props => {
  return <AddFolderModal {...props} />;
};

AddFolderModalContainer.displayName = "AddFolderModalContainer";

const mapDispatchToProps = dispatch => {
  return {
    removeAllToast: () => dispatch(removeAllToast())
  };
};

export default connect(null, mapDispatchToProps)(AddFolderModalContainer);
