import * as types from "./graphicsTypes";

export const initState = {};

const graphicsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_GRAPHICS_REQUEST: {
      return {
        ...state
      };
    }

    case types.FETCH_GRAPHICS_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;
      const {
        response: {
          entities: { graphic: graphics }
        }
      } = action;

      return {
        ...state,
        ...graphics
      };
    }

    default:
      return state;
  }
};

export default graphicsReducer;
