export const FETCH_CARDS = "APP/ENTITIES/CARDS/FETCH";
export const FETCH_CARDS_SUCCESS = "APP/ENTITIES/CARDS/FETCH_SUCCESS";
export const FETCH_CARDS_FAILURE = "APP/ENTITIES/CARDS/FETCH_FAILURE";

export const CREATE_CARD = "APP/ENTITIES/CARDS/CREATE";
export const CREATE_CARD_SUCCESS = "APP/ENTITIES/CARDS/CREATE_SUCCESS";
export const CREATE_CARD_FAILURE = "APP/ENTITIES/CARDS/CREATE_FAILURE";

export const REMOVE_CARD = "APP/ENTITIES/CARDS/REMOVE";
export const REMOVE_CARD_SUCCESS = "APP/ENTITIES/CARDS/REMOVE_SUCCESS";
export const REMOVE_CARD_FAILURE = "APP/ENTITIES/CARDS/REMOVE_FAILURE";
