import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const GridContainer = lazy(() => import("./GridContainer"));

export const GridContainerLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <GridContainer {...props} />
  </Suspense>
);
GridContainerLazy.displayName = "GridContainer";

export default GridContainerLazy;
