import React, { Component } from "react";
import PropType from "prop-types";
import style from "./style.module.css";
import { Avatar } from "views/components";
import PlusIcon from "views/components/icons/PlusIcon";
import { noop } from "lib";

class AvatarOption extends Component {
  constructor(props) {
    super(props);
    this.optionRef = React.createRef();
    this.getIsInScrollable = this.getIsInScrollable.bind(this);
  }

  getIsInScrollable() {
    if (!this.optionRef || !this.optionRef.current) return true;
    const parentElement = this.optionRef.current.parentElement;
    return parentElement.scrollHeight > parentElement.clientHeight;
  }

  render() {
    const {
      label,
      value,
      onClick,
      selected,
      className,
      isRemoveable,
      onRemove = noop,
      src
    } = this.props;

    return (
      <div
        className={`${style.avatarOption} ${className}`}
        data-is-selected={selected}
        key={`${this.props.key || ""}${value}`}
        onClick={() => onClick(value)}
        ref={this.optionRef}
        data-is-in-scrollable={this.getIsInScrollable()}
      >
        <Avatar
          src={src}
          name={label}
          size={"40px"}
          noBorder={true}
          className={style.avatar}
          charsClassName={style.avatarLabel}
          singleCharacterNameInitial={true}
        />
        <div className={style.avatarName}>{label}</div>
        {isRemoveable && (
          <div className={style.cross} onClick={onRemove}>
            <PlusIcon rotate="45" size="20px" color="#afb3b6" />
          </div>
        )}
      </div>
    );
  }
}

AvatarOption.propTypes = {
  label: PropType.string,
  value: PropType.string,
  onClick: PropType.func,
  selected: PropType.bool,
  className: PropType.string,
  iconColor: PropType.string
};

export default AvatarOption;
