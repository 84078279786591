import * as types from "./upgradeModalTypes";
import { push } from "react-router-redux";
import Subscriptions from "lib/subscriptions";

export const showUpgradeModal = ({
  subscriptionCode = Subscriptions.BASIC.code
} = {}) => {
  return {
    type: types.SHOW_UPGRADE_MODAL,
    subscriptionCode
  };
};

export const hideUpgradeModal = () => {
  return {
    type: types.HIDE_UPGRADE_MODAL
  };
};

export const redirectToSubscription = () => (dispatch, getState) => {
  const subscriptionCode = getState().ui.upgradeModal.subscriptionCode;

  let path = "/subscription";

  if (subscriptionCode !== Subscriptions.BASIC.code) {
    path += `?planCode=${subscriptionCode}`;
  }

  dispatch(push(path));
  dispatch(hideUpgradeModal());
};
