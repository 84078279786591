import { omitBy, isNil } from "lib/lodash";

/**
 * @desc takes an object and removes all keys that have nil value (null, undefined, "")
 * @param {object} object - the object to remove nil values from
 * @returns {object} the input object with nil keys removed
 */
export const removeNils = object => {
  return omitBy(
    object,
    attribute => isNil(attribute) || attribute === "null" || attribute === ""
  );
};

/**
 * @desc takes an object and removes all keys that have falsey values
 * @param {object} object - the object to remove falsey values from
 * @returns {object} the input object with falsey keys removed
 */
export const removeFalsey = object => {
  return omitBy(object, attribute => !attribute || attribute === "");
};

/**
 * @desc - takes an object and returns a new object with only the given keys
 * @param {object} object - the object to pick keys from
 * @param {string} keys - the keys to pick
 * @returns {object} - clone of input object with only requested keys
 */
export const pickKeys = (object, keys) => {
  const pickedObject = {};

  keys.forEach(key => {
    if (!isNil(object[key])) {
      pickedObject[key] = object[key];
    }
  });

  return pickedObject;
};

export const sortObjectKeysAlphabetically = object =>
  Object.keys(object)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: object[key]
      }),
      {}
    );
