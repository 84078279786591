import React from "react";
import { Modal, Button } from "views/components";
import InfoAlert from "views/components/InfoAlert";
import style from "./BuyCollectionStyle.module.css";
import CollectionDetails from "./CollectionDetails";
import PaymentDetailsContainer from "./PaymentDetails/PaymentDetailsContainer";
import PromoCode from "./PromoCode";

export class BuyCollectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.handlePayNowClick = this.handlePayNowClick.bind(this);
    this.handleOnCloseModal = this.handleOnCloseModal.bind(this);
  }

  componentDidMount() {
    if (!!this.props.couponErrors) return this.props.handleResetCouponError();
  }

  async handlePayNowClick() {
    if (this.props.loading.processing) return;

    await this.props.purchaseCollection({
      collectionId: this.props.collectionId
    });
  }

  handleOnCloseModal() {
    const { orderId } = this.props;
    // if order has been created and user attempts to close modal
    // we should cancel the order first
    if (orderId) {
      this.props.cancelPurchaseCollectionOrder({ orderId });
    }
    return this.props.onCloseModal();
  }

  render() {
    const {
      appliedCouponCode,
      collectionDesignsCategorised,
      collectionId,
      collectionPricing,
      couponErrors,
      createCouponCollectionOrder,
      currentOrder,
      currentTeamRegion,
      errors,
      handleResetCouponError,
      loading,
      pollingError,
      selectedDesign
    } = this.props;

    const hasError = !!pollingError || errors.length > 0;
    const getErrorMessage = () => {
      if (errors.length) {
        return errors.map(error => error.value);
      }
      if (pollingError) {
        return `We’ve encountered an issue processing your purchase. Contact support and quote ${pollingError.orderId}`;
      }
      return "We’ve encountered an issue processing your purchase. Please contact support";
    };

    const isPayButtonEnabled = () => {
      if (
        this.props.loading.processing ||
        this.props.loading.coupon ||
        this.props.pollingError
      )
        return false;
      switch (this.props.paymentType) {
        case "credit": {
          return !!this.props.token;
        }
        case "paypal": {
          return true;
        }
        default: {
          return false;
        }
      }
    };

    const isPaypal = this.props.paymentType === "paypal";

    return (
      <Modal
        className={style.buyCollectionModalContainer}
        isOpen={true}
        header="Buy Collection"
        onRequestClose={() => this.handleOnCloseModal()}
      >
        <div className={style.content}>
          {/* Collection Details Section - RED */}
          <CollectionDetails
            selectedDesign={selectedDesign}
            collectionDesignsCategorised={collectionDesignsCategorised}
            collectionPricing={collectionPricing}
            currentTeamRegion={currentTeamRegion}
            currentOrder={currentOrder}
          />
          {/* Notifications Section - YELLOW */}
          <div className={style.collectionNotification}>
            {/* InfoAlert here */}
            <InfoAlert className={style.notificationAlert}>
              <div className={style.notificationAlertContent}>
                After purchasing this collection, it will be available in the
                'Purchased' tab of the
                <br />
                Easil Templates page
                {this.props.currentPlan.code === "BASIC"
                  ? ", and you will receive 30 days access to Plus editing tools"
                  : ""}
                .
              </div>
            </InfoAlert>
          </div>
          {/* Payment Details Section - BLUE */}
          <PaymentDetailsContainer collectionId={this.props.collectionId} />
          {/* Promo Code Section - GREEN */}
          <PromoCode
            collectionId={collectionId}
            couponCode={appliedCouponCode}
            couponErrors={couponErrors}
            createCouponCollectionOrder={createCouponCollectionOrder}
            handleResetCouponError={handleResetCouponError}
            isLoading={loading.coupon}
          />
        </div>
        <div className={style.buttonHoverWrapper} data-is-paypal={isPaypal}>
          <Button
            className={style.payButton}
            theme="blueSolid"
            disabled={!isPayButtonEnabled()}
            isFullWidth={true}
            onClick={this.handlePayNowClick}
            isLoading={loading.processing}
            style={isPaypal ? { pointerEvents: "none" } : {}}
          >
            Pay Now
          </Button>
          <div className={style.buttonBacking} />
          <div
            id="paypal-button-container"
            className={style.paypalButton}
            style={isPaypal ? {} : { display: "none" }}
          />
        </div>
        <div className={style.pollingErrorAlert} data-has-error={hasError}>
          {getErrorMessage()}
        </div>
      </Modal>
    );
  }
}

BuyCollectionModal.displayName = "BuyCollectionModal";

export default BuyCollectionModal;
