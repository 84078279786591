import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const SizeIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <g fill={color || easilGrayXDark}>
        <path
          fillRule="nonzero"
          d="M3 1.9A1.1 1.1 0 0 0 1.9 3v10A1.1 1.1 0 0 0 3 14.1h10a1.1 1.1 0 0 0 1.1-1.1V3A1.1 1.1 0 0 0 13 1.9H3zM3 .1h10A2.9 2.9 0 0 1 15.9 3v10a2.9 2.9 0 0 1-2.9 2.9H3A2.9 2.9 0 0 1 .1 13V3A2.9 2.9 0 0 1 3 .1z"
        />
        <path d="M5.8 10h1.8a.9.9 0 1 1 0 1.8H4.9a.897.897 0 0 1-.9-.9V8.2a.9.9 0 0 1 1.8 0V10zM11.8 4.9v2.7a.9.9 0 1 1-1.8 0V5.8H8.2a.9.9 0 0 1 0-1.8h2.7a.9.9 0 0 1 .9.9z" />
      </g>
    </g>
  </svg>
);
SizeIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SizeIcon;
