import React, { Component } from "react";
import { default as AddFolderModal } from "views/components/DesignsPageSubHeader/AddFolderModalContainer";
import BrandKitAnimationsListContainer from "./BrandKitAnimationsListContainer";
import style from "./style.module.css";
import Subscriptions from "lib/subscriptions";
import { getParameterByName } from "lib/queryStringUtils";
import { isSvg } from "lib/isSvg";
import BrandKitAnimationOps from "./BrandKitAnimationOps";

export default class BrandKitAnimations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingFolder: false,
      isEditingFolder: false,
      isProcessing: false,
      menuFooterOpen: false,
      menuFooterOption: null,
      menuId: null,
      qsTerm: null,
      subscription: Subscriptions.get(props.currentSubscriptionCode),
      gifFreeze: null,
      showUploadModal: false,
      sortKey: "UPDATEDAT_DESC"
    };
    this.contentWrapper = document.getElementById("contentWrapper");
    this.getFilters = this.getFilters.bind(this);
    this.closeUploadModal = this.closeUploadModal.bind(this);
    this.handleOnChange = BrandKitAnimationOps.handleOnChange.bind(this);
    this.handleSortUpdate = BrandKitAnimationOps.handleSortUpdate.bind(this);
  }

  componentDidUpdate() {
    const urlSearchTerm = getParameterByName("term", window.location);

    if (urlSearchTerm && !this.state.qsTerm) {
      this.setState({ qsTerm: urlSearchTerm });
    }
  }

  determineImageSrc(logo) {
    return isSvg(logo.url) ? logo.url : logo.thumbnailUrl;
  }

  closeUploadModal() {
    this.setState({ showUploadModal: false });
  }

  getFilters(locationSearch) {
    return {
      folderId: getParameterByName("folderId", locationSearch),
      categoryId: getParameterByName("categoryId", locationSearch),
      size: getParameterByName("size", locationSearch),
      term: getParameterByName("term", locationSearch)
    };
  }

  render() {
    const {
      teamFolders,
      brandKitAnimations,
      isFetchingBrandAnimations
    } = this.props;
    const urlSearchTerm = getParameterByName("term", window.location);
    const showTitle =
      (isFetchingBrandAnimations || brandKitAnimations.length > 0) &&
      !urlSearchTerm;

    return (
      <>
        <div className={style.brandKitAnimationsWrapper}>
          {showTitle && <div className={style.contentTitle}>Brand Videos</div>}
          <BrandKitAnimationsListContainer
            filters={this.getFilters(this.props.location.search)}
            wrapperClassName={style.brandAnimationsList}
            imageRowClassName={style.brandAnimationRow}
            breadCrumbClassName={style.brandAnimationBreadCrumb}
            folderRowClassName={style.brandAnimationFolderRow}
            searchWrapperClassName={style.searchResultsTitle}
            sortKey={this.props.sortKey}
          />
        </div>
        {this.state.isAddingFolder && (
          <AddFolderModal
            errors={teamFolders.errors}
            isOpen
            submitting={teamFolders.isProcessing}
            onClose={() => this.setState({ isAddingFolder: false })}
            onCreate={({ name }) => this.handleAddTeamFolder(name)}
          />
        )}
      </>
    );
  }
}
