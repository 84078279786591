import React, { Component } from "react";
import { connect } from "react-redux";
import PriceSummary from "./PriceSummary";

import { orderByIdSelector } from "state/entities/orders/ordersSelectors";
import { currentOrderIdSelector } from "state/ui/cart/cartSelectors";
import { shippingDetailsForOrderSelector } from "state/entities/shippingDetails/shippingDetailsSelectors";

class PriceSummaryContainer extends Component {
  render() {
    return (
      <PriceSummary
        order={this.props.order}
        shippingDetails={this.props.shippingDetails}
        shippingCanBeHidden={this.props.shippingCanBeHidden}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // get orderId from props or use current order id
  const orderId = ownProps.orderId || currentOrderIdSelector(state);
  const order = orderByIdSelector({ state, orderId });
  const shippingDetails =
    ownProps.shipping || shippingDetailsForOrderSelector({ state, orderId });
  return {
    order,
    shippingDetails
  };
};

export default connect(
  mapStateToProps,
  null
)(PriceSummaryContainer);
