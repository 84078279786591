import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ArrowBentIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  flipHorizontally,
  title
}) => {
  const wrapperStyle = {
    width: width || size || 24,
    height: height || size || 24
  };

  const finalStyle = {
    transform: `scaleX(${flipHorizontally ? -1 : 1})`,
    ...style
  };

  return (
    <div style={wrapperStyle}>
      <svg
        className={className}
        style={finalStyle}
        data-no-pointer={noPointer}
        width={width || size || 24}
        height={height || size || 24}
        viewBox="0 0 16 15"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <title>{title}</title>
        <g fill="none" fillRule="evenodd">
          <path d="M-2-3h20v20H-2z" />
          <path d="M0-1h16v16H0z" />
          <path
            fill={color || easilGrayXDark}
            fillRule="nonzero"
            d="M12.65 6.079H8.181a6.15 6.15 0 0 0-2.472.51A6.388 6.388 0 0 0 3.68 7.965a6.485 6.485 0 0 0-1.357 2.059 6.377 6.377 0 0 0-.504 2.505v.921c0 .25-.09.465-.27.648a.868.868 0 0 1-.639.273.87.87 0 0 1-.643-.27A.894.894 0 0 1 0 13.45v-.921c0-1.128.217-2.203.65-3.225a8.404 8.404 0 0 1 1.743-2.645c.73-.741 1.6-1.33 2.61-1.767a7.932 7.932 0 0 1 3.179-.655h4.297l-2.53-2.529A1 1 0 0 1 11.365.293l4.243 4.243a1 1 0 0 1 0 1.414l-4.243 4.242A1 1 0 0 1 9.95 8.778l2.7-2.7z"
          />
        </g>
      </svg>
    </div>
  );
};

ArrowBentIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ArrowBentIcon;
