import { UPDATE_ACTION_BAR } from "state/ui/actionBar/actionBarActionTypes";

export const initialState = {
  buttonActive: null
};

const actionBarReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTION_BAR:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default actionBarReducers;
