import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { noop } from "lib";

const DesignConversion = ({
  message = "Your Design has been converted to a Template. View it in your",
  linkText = "Team Templates",
  onLinkClick = noop
}) => (
  <div>
    {message}{" "}
    <button className={style.linkButton} onClick={onLinkClick}>
      {linkText}
    </button>
    .
  </div>
);

DesignConversion.propTypes = {
  onLinkClick: PropTypes.func.isRequired
};

export default DesignConversion;
