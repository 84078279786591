import { ActionCreators } from "redux-undo";
import * as editorActionTypes from "./editorActionTypes";
import * as editorLayoutTypes from "../editorLayouts/editorLayoutsTypes";
import { getCurrentDesignData } from "state/ui/editor/editorSelectors";
import * as designTypes from "../../entities/designs/designsTypes";
import { isEmpty, debounce, noop, getPath } from "lib/lodash";
import Logger from "lib/logger";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import {
  fetchDesignData,
  updateDesignsStatus,
  fetchDesignDataAndRedirect,
  requestDesignApprovalApiCall
} from "state/entities/designs/designsActions";
import { designsByCollectionId } from "state/entities/designs/designsSelectors";
import {
  fetchUserTeamImages,
  deleteUserTeamImage
} from "state/entities/userTeamImages/userTeamImagesActions";
import {
  fetchStockAnimations,
  fetchStockVideos,
  getStockAnimationNextPageIfNeeded,
  getStockVideoNextPageIfNeeded
} from "state/entities/stockAnimations/stockAnimationsActions";
import {
  fetchUserTeamAnimations,
  fetchUserTeamVideos,
  deleteUserTeamAnimation,
  getUserAnimationNextPageIfNeeded,
  getUserVideoNextPageIfNeeded,
  getUserAnimationInFolderNextPage
} from "state/entities/userTeamAnimations/userTeamAnimationsActions";
import {
  fetchTeamAnimations,
  fetchTeamVideos,
  deleteTeamAnimation,
  getTeamAnimationNextPageIfNeeded,
  getTeamVideoNextPageIfNeeded,
  getTeamAnimationInFolderNextPage
} from "state/entities/teamAnimations/teamAnimationsActions";
import {
  fetchGraphicsIfNeeded,
  clearAndFetchGraphics as clearGraphicsAndFetch
} from "state/entities/graphics/graphicsActions";
import { IMAGE_ORIGINS } from "lib/constants";
import formatDataForV1 from "./formatDataForV1";
import { handleDesignRequestFailure } from "lib/handleDesignRequestFailure";
import { TABS as SIDEBAR_ANIMATIONS_TABS } from "views/components/Editor/sidebar/tabs/animations/animationsTabConstants";
import { fetchAllAnimationFoldersIfNeeded } from "state/entities/userTeamAnimationsFolders/userTeamAnimationsFoldersActions";
import { fetchAllTeamAnimationFoldersIfNeeded } from "state/entities/teamAnimationsFolders/teamAnimationsFoldersActions";
import {
  searchUserAnimations,
  searchStockAnimations,
  searchBrandAnimations,
  getBrandVideoSearchNextPage,
  getBrandAnimationSearchNextPage,
  getUserAnimationSearchNextPageIfNeeded,
  getUserVideoSearchNextPageIfNeeded,
  getStockVideoSearchNextPageIfNeeded,
  getStockAnimationSearchNextPageIfNeeded
} from "state/ui/editorAnimationSearch/editorAnimationSearchActions";
import { ANIMATION_ASSET_TYPE_MAP } from "lib/constants";
import { sendPortalMessage } from "lib/portalUtil";
import { userTeamSmartImagesEntitiesSelector } from "state/entities/userTeamSmartImages/userTeamSmartImagesSelectors";

/**
 * @desc  Steps backwards through redux history and collects the designData at that point
 *        before updating it with the API
 */
export const redo = () => (dispatch, getState) => {
  dispatch(ActionCreators.redo());
  const location = getState().router.location;

  const design = getCurrentDesignData(getState());

  persistDesignDataDebounced({
    design,
    onFailure: response =>
      handleDesignRequestFailure(response, dispatch, location),
    dispatch
  });
};

/**
 * @desc  Steps forwards through redux history and collects the designData at that point
 *        before updating it with the API
 */
export const undo = () => (dispatch, getState) => {
  dispatch(ActionCreators.undo());
  const location = getState().router.location;

  const design = getCurrentDesignData(getState());

  persistDesignDataDebounced({
    design,
    onFailure: response =>
      handleDesignRequestFailure(response, dispatch, location),
    dispatch
  });
};

/**
 * @desc  Updates the stored design data with the API (Does not trigger a redux update)
 * @param {object} design - the design to update
 * @param {function} onSuccess - a function to be run when the action completes successfully
 * @param {function} onFailure - a function to be run when the action fails to complete
 */
export const persistDesignData = ({
  design,
  onSuccess = noop,
  onFailure = noop,
  dispatch,
  isUpdatingDataVersion = true
}) => {
  const payload = formatDataForV1(design);

  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ASSET,
      types: [
        designTypes.SAVE_DESIGN_DATA_REQUEST,
        designTypes.SAVE_DESIGN_DATA_REQUEST_SUCCESS,
        designTypes.SAVE_DESIGN_DATA_REQUEST_FAILURE
      ],
      endpoint: `/designs/${payload.designId}/data`,
      request: {
        body: payload
      },
      extra: {
        isUpdatingDataVersion,
        designData: payload
      },
      schema: schemas.NONE,
      onSuccess: onSuccess,
      onFailure
    }
  });
};

export const persistDesignDataDebounced = debounce(persistDesignData, 500);

/**
 * @desc - takes a design and the current state of the editor and initiates an update for the design's designData
 * @param {object} design - the design that is to have its designData updated
 * @param {object} editorState - the current editor state in which to get the new designData from
 * @returns {Promise} - returns a promise which will resolve with the update request response data
 */
export const saveDesign = (design, editorState) => (dispatch, getState) =>
  new Promise(resolve => {
    const location = getState().router.location;

    dispatch({
      type: editorActionTypes.SAVE_DESIGN,
      data: design,
      editorState
    });

    persistDesignDataDebounced({
      design,
      onFailure: response =>
        handleDesignRequestFailure(response, dispatch, location),
      dispatch,
      onSuccess: resolve
    });
  });

export const loadDesign = data => {
  return {
    type: editorActionTypes.LOAD_DESIGN,
    data
  };
};

export const fetchDesignPageData = ({
  newDesign,
  onSuccess,
  pages = [1, 2]
}) => dispatch => {
  // Offset page by -1 as arrays start at 0. Eg page 1 is at index 0 in data array
  const pageNumbers = pages.map(pageNumber => pageNumber - 1);

  const onNewDesignSuccess = response => {
    const newDesignData = Object.keys(response.entities.designData).map(
      id => response.entities.designData[id]
    )[0].data;
    let data;
    if (pages.length === 0) {
      // Fetch all pages from design
      data = newDesignData
        .map((designData, index) => {
          return {
            pageId: pageNumbers[index],
            data: designData
          };
        })
        .filter(designData => designData !== null);
    } else {
      data = newDesignData
        .map((designData, index) => {
          if (!pageNumbers.includes(index)) return null;

          return {
            pageId: pageNumbers[index],
            data: designData
          };
        })
        .filter(designData => designData !== null);
    }
    dispatch({ type: editorLayoutTypes.EDITOR_LAYOUTS_FETCH_DATA_SUCCESS });
    onSuccess(data);
  };

  dispatch({ type: editorLayoutTypes.EDITOR_LAYOUTS_FETCH_DATA_REQUEST });
  dispatch(
    fetchDesignData({
      designId: newDesign.id,
      onSuccess: onNewDesignSuccess,
      checkSmartImages: true
    })
  );
};

export const archiveDesignsAndSwitchDesign = ({ designs, design }) => (
  dispatch,
  getState
) => {
  // TODO: This onSuccess function needs to be refactored
  const onSuccess = _response => {
    // Send design data to portal
    const designMessage = {
      event: "portal.design.loaded",
      design
    };
    sendPortalMessage(designMessage);

    const collectionId = (designs ? designs[0] : design).collectionId;
    const currentDesignId = (designs ? designs[0] : design).id;
    const designId = designsByCollectionId({
      state: getState(),
      collectionId
    }).filter(collectionDesign => collectionDesign.id !== currentDesignId)[0]
      .id;

    dispatch(fetchDesignDataAndRedirect({ designId }));
  };

  dispatch(updateDesignsStatus({ designs, design, onSuccess }));
};

const getNextPage = resourcePages => {
  if (isEmpty(resourcePages)) {
    return 1;
  }

  const lastPage = Math.max(...Object.keys(resourcePages));

  return resourcePages[lastPage].isFetching ? lastPage : lastPage + 1;
};

export const refetchSidebarImages = () => (dispatch, getState) => {
  const { userTeamImages } = getState().api;
  Object.keys(userTeamImages.pages).forEach(page =>
    dispatch(fetchUserTeamImages({ page }))
  );
};

export const refetchSidebarAnimations = () => (dispatch, getState) => {
  const { userTeamAnimations } = getState().api;

  const animationsPages = getPath(userTeamAnimations, "animations.pages");
  const videosPages = getPath(userTeamAnimations, "videos.pages");

  if (animationsPages) {
    Object.keys(animationsPages).forEach(page =>
      dispatch(fetchUserTeamAnimations({ page }))
    );
  }
  if (videosPages) {
    Object.keys(videosPages).forEach(page =>
      dispatch(fetchUserTeamVideos({ page }))
    );
  }
};

export const fetchSidebarImages = pageNumber => (dispatch, getState) => {
  const { userTeamImages } = getState().api;
  const userTeamImagePage = pageNumber || getNextPage(userTeamImages.pages);
  dispatch(fetchUserTeamImages({ userTeamImagePage }));
};

export const handleSidebarSearchAction = ({
  term,
  source,
  context
}) => dispatch => {
  switch (source) {
    case SIDEBAR_ANIMATIONS_TABS.TEAM_ANIMATIONS: {
      return dispatch(searchBrandAnimations({ term, searchType: context }));
    }
    case SIDEBAR_ANIMATIONS_TABS.STOCK_ANIMATIONS: {
      return dispatch(searchStockAnimations({ term, searchType: context }));
    }
    default: {
      return dispatch(searchUserAnimations({ term, searchType: context }));
    }
  }
};

/**
 * @desc dispatches an api request for fetching animations with the given from the given source
 * @param {number} pageNumber - the number of the page to fetch
 * @param {string} context - the animation asset context to collect (typically "animations" or "videos", defaults to "animations" if not provided)
 * @param {string} source - the source to get the assets from e.g. "MY_ANIMATIONS", "STOCK_ANIMATIONS" ,"BRAND_ANIMATIONS"
 * @param {string} term - the current search term if present
 * @returns void
 */
export const fetchSidebarAnimations = ({
  pageNumber,
  context = "ANIMATION",
  source = "MY_ANIMATIONS",
  term
}) => (dispatch, getState) => {
  if (term) {
    return handleSidebarSearchAction({ term, source, context });
  }

  switch (source) {
    case SIDEBAR_ANIMATIONS_TABS.TEAM_ANIMATIONS: {
      // fetch my animations with context
      const { teamAnimations } = getState().api;
      const teamAnimationPage =
        pageNumber || getNextPage(teamAnimations[context].pages);
      const searchType = context;

      if (context === ANIMATION_ASSET_TYPE_MAP.VIDEO) {
        return dispatch(fetchTeamVideos({ page: teamAnimationPage }));
      }

      return dispatch(
        fetchTeamAnimations({ page: teamAnimationPage, type: searchType })
      );
    }

    case SIDEBAR_ANIMATIONS_TABS.STOCK_ANIMATIONS: {
      // fetch stock animations with context
      const { stockAnimations } = getState().api;
      const stockAnimationPage =
        pageNumber || getNextPage(stockAnimations[context].pages);
      const searchType = context;

      if (context === ANIMATION_ASSET_TYPE_MAP.VIDEO) {
        return dispatch(fetchStockVideos({ page: stockAnimationPage }));
      }

      return dispatch(
        fetchStockAnimations({ page: stockAnimationPage, type: searchType })
      );
    }

    default: {
      // fetch my animations with context
      const { userTeamAnimations } = getState().api;
      const userTeamAnimationPage =
        pageNumber || getNextPage(userTeamAnimations[context].pages);

      if (context === ANIMATION_ASSET_TYPE_MAP.VIDEO) {
        return dispatch(fetchUserTeamVideos({ page: userTeamAnimationPage }));
      }

      return dispatch(
        fetchUserTeamAnimations({
          page: userTeamAnimationPage,
          params: { type: context }
        })
      );
    }
  }
};

export const fetchSidebarAnimationsNextPage = ({
  pageNumber,
  context = "animations",
  source = "MY_ANIMATIONS"
}) => (dispatch, getState) => {
  switch (source) {
    case "MY_ANIMATIONS": {
      // fetch my animations with context
      const state = getState();
      const { userTeamAnimations } = state.api;
      const {
        currentTerm,
        ANIMATION,
        VIDEO
      } = state.ui.editorAnimationSearch.personal;
      let userTeamAnimationPage =
        pageNumber || getNextPage(userTeamAnimations[context].pages);

      let fetchFunction = getUserAnimationNextPageIfNeeded;

      switch (context) {
        case "videos": {
          if (currentTerm) {
            fetchFunction = getUserVideoSearchNextPageIfNeeded;
            userTeamAnimationPage =
              Math.floor(
                VIDEO.terms[currentTerm]?.ids?.length /
                  state.ui.editorAnimationSearch.pageSize
              ) + 1;
          } else {
            fetchFunction = getUserVideoNextPageIfNeeded;
          }
          break;
        }
        case "animations":
        default: {
          if (currentTerm) {
            fetchFunction = getUserAnimationSearchNextPageIfNeeded;
            userTeamAnimationPage =
              Math.floor(
                ANIMATION.terms[currentTerm]?.ids?.length /
                  state.ui.editorAnimationSearch.pageSize
              ) + 1;
          }
          break;
        }
      }

      dispatch(fetchFunction({ page: userTeamAnimationPage }));
      break;
    }
    case "STOCK_ANIMATIONS": {
      // fetch stock animations with context
      const state = getState();
      const { stockAnimations } = getState().api;
      const { currentTerm } = state.ui.editorAnimationSearch.personal;
      let stockAnimationPage =
        pageNumber || getNextPage(stockAnimations[context].pages);

      let fetchFunction = getStockAnimationNextPageIfNeeded;

      switch (context) {
        case "videos": {
          if (currentTerm) {
            fetchFunction = getStockVideoSearchNextPageIfNeeded;
            stockAnimationPage =
              Math.floor(
                state.ui.editorAnimationSearch.stock.VIDEO.terms[currentTerm]
                  ?.ids?.length / state.ui.editorAnimationSearch.pageSize
              ) + 1;
          } else {
            fetchFunction = getStockVideoNextPageIfNeeded;
          }
          break;
        }
        case "animations":
        default: {
          if (currentTerm) {
            fetchFunction = getStockAnimationSearchNextPageIfNeeded;
            stockAnimationPage =
              Math.floor(
                state.ui.editorAnimationSearch.stock.ANIMATION.terms[
                  currentTerm
                ]?.ids?.length / state.ui.editorAnimationSearch.pageSize
              ) + 1;
          }
          break;
        }
      }

      dispatch(fetchFunction({ page: stockAnimationPage }));
      break;
    }
    case "TEAM_ANIMATIONS": {
      // fetch stock animations with context
      const state = getState();
      const { teamAnimations } = getState().api;
      const { currentTerm } = state.ui.editorAnimationSearch.personal;
      const { ANIMATION, VIDEO } = state.ui.editorAnimationSearch.brand;
      let teamAnimationPage =
        pageNumber || getNextPage(teamAnimations[context].pages);

      let fetchFunction = getTeamAnimationNextPageIfNeeded;

      switch (context) {
        case "videos": {
          if (currentTerm) {
            fetchFunction = getBrandVideoSearchNextPage;

            teamAnimationPage =
              Math.floor(
                VIDEO.terms[currentTerm]?.ids?.length /
                  state.ui.editorAnimationSearch.pageSize
              ) + 1;
          } else {
            fetchFunction = getTeamVideoNextPageIfNeeded;
          }
          break;
        }
        case "animations":
        default: {
          fetchFunction = getBrandAnimationSearchNextPage;
          if (currentTerm) {
            teamAnimationPage =
              Math.floor(
                ANIMATION.terms[currentTerm]?.ids?.length /
                  state.ui.editorAnimationSearch.pageSize
              ) + 1;
          }
          break;
        }
      }

      dispatch(fetchFunction({ page: teamAnimationPage }));
      break;
    }
    default: {
      break;
    }
  }
};

export const fetchSidebarMedia = ({ term, category, pageNumber, pageSize }) => (
  dispatch,
  getState
) => {
  const { graphics: graphicsApi } = getState().api;

  const graphicsPage = pageNumber || getNextPage(graphicsApi.pages);

  dispatch(
    fetchGraphicsIfNeeded({ term, category, page: graphicsPage, pageSize })
  );
};

export const clearAndFetchGraphics = ({
  term,
  category,
  context,
  pageSize
}) => dispatch =>
  dispatch(clearGraphicsAndFetch({ term, category, context, pageSize }));

export const deleteImage = ({ image, onSuccess }) => (dispatch, getState) => {
  switch (image.origin) {
    case IMAGE_ORIGINS.userTeamImage:
      const userTeamSmartImageState = userTeamSmartImagesEntitiesSelector(
        getState()
      );
      const isSmartImage = userTeamSmartImageState.hasOwnProperty(
        image.mediaId
      );
      dispatch(
        deleteUserTeamImage({ mediaId: image.mediaId, isSmartImage, onSuccess })
      );
      break;

    default:
      Logger.Error("Delete Image Failed. Image owner not identified.");
  }
};

export const deleteAnimation = ({ image: animation, onSuccess }) => (
  dispatch,
  _getState
) => {
  switch (animation.origin) {
    case IMAGE_ORIGINS.userTeamAnimation: {
      dispatch(
        deleteUserTeamAnimation({ mediaId: animation.mediaId, onSuccess })
      );
      break;
    }

    case IMAGE_ORIGINS.teamAnimation: {
      dispatch(deleteTeamAnimation({ mediaId: animation.mediaId, onSuccess }));
      break;
    }

    default:
      Logger.Error("Delete Animation Failed. Animation owner not identified.");
  }
};

export const requestDesignApproval = ({ designId, comment }) => dispatch =>
  dispatch(requestDesignApprovalApiCall({ designId, comment }));

export const setDesignSaveError = () => {
  return {
    type: designTypes.SET_DESIGN_SAVE_ERROR
  };
};

export const fetchAllAnimationFolders = ({ source }) => dispatch => {
  switch (source) {
    case SIDEBAR_ANIMATIONS_TABS.MY_ANIMATIONS: {
      return dispatch(fetchAllAnimationFoldersIfNeeded());
    }
    case SIDEBAR_ANIMATIONS_TABS.BRAND_ANIMATIONS: {
      return dispatch(fetchAllTeamAnimationFoldersIfNeeded());
    }
    default: {
      return dispatch(fetchAllAnimationFoldersIfNeeded());
    }
  }
};

export const getEditorAnimationsInFolders = ({
  source,
  ...args
}) => dispatch => {
  switch (source) {
    case SIDEBAR_ANIMATIONS_TABS.MY_ANIMATIONS: {
      return dispatch(getUserAnimationInFolderNextPage(args));
    }
    case SIDEBAR_ANIMATIONS_TABS.TEAM_ANIMATIONS: {
      return dispatch(getTeamAnimationInFolderNextPage(args));
    }
    default: {
      return dispatch(getUserAnimationInFolderNextPage(args));
    }
  }
};
