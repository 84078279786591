import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import { withRouter } from "react-router";
import SubPageLoading from "views/pages/SubPageLoading";
import DesignsPageSubHeaderContainer from "views/containers/DesignsPageSubHeaderContainer/DesignsPageSubHeaderContainer";
const DesignsPage = lazy(() => import("./DesignsPage"));

const options = { bgColor: "#F4F4F5", withGutters: false };

export const DesignsPageLazy = props => (
  <Suspense fallback={<SubPageLoading />}>
    <DesignsPage {...props} />
  </Suspense>
);
DesignsPageLazy.displayName = "DesignsPage";

export default WithHeader(
  withRouter(DesignsPageLazy),
  options,
  DesignsPageSubHeaderContainer
);
