import React from "react";

export default function EmptyFontsIcon(props) {
  const { color = "#DCDCE0", accent = "#2C2E2F" } = props;
  return (
    <svg viewBox="0 0 56 44" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <rect
            width="50"
            height="38"
            x="6"
            y="6"
            fill={color}
            opacity=".85"
            rx="2"
          />
          <path
            fill={accent}
            fillRule="nonzero"
            d="M52 4.103v31.794C52 38.163 50.21 40 48 40H4c-2.21 0-4-1.837-4-4.103V4.103C0 1.837 1.79 0 4 0h44c2.21 0 4 1.837 4 4.103zm-2 0c0-1.133-.895-2.053-2-2.053H4c-1.105 0-2 .919-2 2.052v31.794c0 1.133.895 2.052 2 2.052h44c1.105 0 2-.919 2-2.052V4.103z"
          />
        </g>
        <path d="M14 8h24v24H14z" />
        <path
          fill={accent}
          d="M23 30a1 1 0 010-2h2V12h-7v1a1 1 0 01-2 0v-2a1 1 0 011-1h18a1 1 0 011 1v2a1 1 0 01-2 0v-1h-7v16h2a1 1 0 010 2h-6z"
        />
      </g>
    </svg>
  );
}
