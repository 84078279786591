import React from "react";
import style from "./style.module.css";
import { hexToRgb } from "lib/Colors/colorUtils";

const Loading = ({
  size = "32px",
  hexColor = "#D1D4D6",
  className,
  borderWidth,
  largeEdgeHexOverride
}) => {
  const borderSize =
    borderWidth || Math.round(size.substring(0, size.length - 2) / 16) || 1;

  const dynamicStyle = {
    width: size || "32px",
    height: size || "32px",
    borderTop: `${borderSize || 2}px solid rgba(${hexToRgb(
      largeEdgeHexOverride || hexColor
    )}, ${largeEdgeHexOverride ? 1 : 0.5})`,
    borderRight: `${borderSize || 2}px solid rgba(${hexToRgb(
      largeEdgeHexOverride || hexColor
    )}, ${largeEdgeHexOverride ? 1 : 0.5})`,
    borderBottom: `${borderSize || 2}px solid rgba(${hexToRgb(
      largeEdgeHexOverride || hexColor
    )}, ${largeEdgeHexOverride ? 1 : 0.5}`,
    borderLeft: `${borderSize || 2}px solid ${hexColor}`
  };

  return (
    <div
      style={dynamicStyle}
      className={`${style.loader} ${className}`}
      data-testid="Loading"
    >
      loading
    </div>
  );
};

export default Loading;
