import { createSelector } from "reselect";

export const teamAnimationsFoldersApiSelector = state =>
  state.api.teamAnimationsFolders;
const processTeamAnimationsFoldersApiCurrentPageSelector = teamAnimationFolders => {
  if (!teamAnimationFolders || !teamAnimationFolders.pages) return 0;
  return Object.keys(teamAnimationFolders.pages).length;
};

export const TeamAnimationsFoldersApiCurrentPageSelector = createSelector(
  [teamAnimationsFoldersApiSelector],
  processTeamAnimationsFoldersApiCurrentPageSelector
);

const processTeamAnimationFoldersIsFetchingSelector = teamAnimationFolders => {
  if (!teamAnimationFolders || !teamAnimationFolders.pages) return true;
  const { pages } = teamAnimationFolders;
  return Object.values(pages).some(page => page.isFetching); // true if at least one page is fetching
};

export const teamAnimationFoldersIsFetchingSelector = createSelector(
  [teamAnimationsFoldersApiSelector],
  processTeamAnimationFoldersIsFetchingSelector
);
