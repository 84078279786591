import {
  topTeamsSelector,
  subTeamsSelector
} from "state/entities/teamHierarchy/teamHierarchySelectors";
import { teamSelector as entityTeamSelector } from "state/entities/teams/teamsSelectors";

export const isFetchingTeamsSelector = state => state.ui.teamMenu.isFetching;

// export const navigationLevelSelector = state => state.ui.header.teamMenu.navigationLevel;

export const selectedTeamSelector = state => state.ui.teamMenu.selectedTeam;

export const searchResultsSelector = state => {
  const teamIds = state.ui.teamMenu.teamIds;
  return teamIds.map(teamId => entityTeamSelector({ state, teamId }));
};

export const searchModeSelector = state => state.ui.teamMenu.isSearchMode;

/**
 * Return either the top tier teams for the user or the sub teams of a given team based on the
 * presence or absence of a selected team.
 */
export const teamsSelector = (state, selectedTeam) => {
  // Check if we are fetching search results.
  const searchTerm = searchModeSelector(state);
  if (searchTerm) return searchResultsSelector(state);

  // If not searching and no current team, return the users top level teams.
  if (!selectedTeam) return topTeamsSelector(state);

  // Otherwise, scope by selected team.
  return subTeamsSelector(state, selectedTeam.parentPathArr, selectedTeam.id);
};
