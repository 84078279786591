import * as types from "state/api/folders/apiFolderTypes";

export const initState = {};

const mediaFoldersApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_FOLDER_FOR_MEDIA_REQUEST: {
      const {
        request: { mediaId }
      } = action;

      return {
        ...state,
        [mediaId]: {
          isFetching: true
        }
      };
    }

    case types.FETCH_FOLDER_FOR_MEDIA_REQUEST_SUCCES: {
      const {
        response: { ids },
        request: { mediaId }
      } = action;
      return {
        ...state,
        [mediaId]: {
          isFetching: false,
          folderId: ids
        }
      };
    }
    default:
      return state;
  }
};

export default mediaFoldersApiReducers;
