import React from "react";
import style from "../style.module.css";

const CommentSection = ({ isApproving, onChange, comment }) => {
  return (
    <div>
      <div className={style.commentLabel}>Leave a comment (optional)</div>
      <textarea
        className={style.textarea}
        placeholder={`I'm ${
          isApproving ? "approving" : "declining"
        } this design because…`}
        onChange={e => onChange({ comment: e.target.value })}
        value={comment}
      />
    </div>
  );
};

CommentSection.displayName = "CommentSection";

CommentSection.propTypes = {};

export default CommentSection;
