import React, { Component } from "react";
import style from "./style.module.css";
import { Modal, Dropdown, Textarea } from "views/components";
import InfoAlert from "views/components/InfoAlert";
import { getPath } from "lib";

const options = [
  { key: "0", label: "It doesn't have the templates I need." },
  { key: "1", label: "I don't understand how to use it." },
  { key: "2", label: "It's too expensive." },
  { key: "3", label: "I don't need to use a template designing tool anymore." },
  { key: "4", label: "Other" }
];

class SubscriptionCancellationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      otherText: ""
    };
    this.handleSelectedOption = this.handleSelectedOption.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  //check the update state and reset prev state once is not using.
  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    if (prevProps.isOpen && !isOpen) {
      this.setState({
        selectedOption: null,
        otherText: ""
      });
    }
  }

  handleSelectedOption(option) {
    this.setState({ selectedOption: options[option] });
  }
  handleSubmit() {
    const { onCancel } = this.props;
    const { selectedOption, otherText } = this.state;

    onCancel({
      cancellationReason: selectedOption.label,
      cancellationNotes: otherText
    });
  }

  render() {
    const { selectedOption, otherText } = this.state;

    const buttonList = [
      {
        name: "No, take me back",
        theme: "gray",
        onClick: this.props.onCloseModal
      },
      {
        name: "Yes, I'm sure",
        theme: "redSolid",
        //disable logic for the submit button state
        disabled: !Boolean(selectedOption),
        onClick: this.handleSubmit
      }
    ];

    return (
      <>
        <Modal
          className={style.cancelModalWrapper}
          isOpen={this.props.isOpen}
          contentLabel="Action approval request"
          header={"Are you sure you want to cancel your plan?"}
          onRequestClose={this.props.onCloseModal}
          buttons={buttonList}
        >
          <InfoAlert theme="yellow">
            <div>
              Cancelling your plan will drop you down to the{" "}
              <strong>Easil Basic </strong> plan.
              <br />
              Your Credit Card details will be removed immediately.
            </div>
          </InfoAlert>
          <div className={style.questionFeedback}>
            Before you go, can you help us understand why?
          </div>
          <Dropdown
            className={style.dropdownSubscriptionCancellation}
            selected={selectedOption ? selectedOption.label : null}
            onChange={this.handleSelectedOption}
            options={options}
            placeholder={"Select Reason"}
          />
          {getPath(selectedOption, "key", "") === "4" && (
            <Textarea
              className={style.textArea}
              placeholder="Reason for cancelling (optional)"
              value={otherText}
              onChange={event => {
                this.setState({ otherText: event.target.value });
              }}
            />
          )}
        </Modal>
      </>
    );
  }
}

export default SubscriptionCancellationModal;
