import React from "react";
import PropTypes from "prop-types";
import { easilGrayXLight } from "./style.module.css";

const FontChevronIcon = ({ width, size, height, color, title, ...props }) => (
  <svg
    width={width || size || 6}
    height={height || size || 12}
    viewBox="0 0 6 12"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    {...props}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 7h6v6H0zM0-1h6v6H0z" />
      <path
        d="M3.036 9.586l1.247-1.283a1 1 0 1 1 1.434 1.394l-1.945 2a1 1 0 0 1-1.414.02l-2.055-2a1 1 0 1 1 1.394-1.434l1.339 1.303zM3.036 2.414l1.247 1.283a1 1 0 0 0 1.434-1.394l-1.945-2a1 1 0 0 0-1.414-.02l-2.055 2a1 1 0 0 0 1.394 1.434l1.339-1.303z"
        fill={color || easilGrayXLight}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
FontChevronIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  direction: PropTypes.string
};

export default FontChevronIcon;
