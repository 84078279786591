import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CaretIcon = ({
  className,
  width,
  size,
  height,
  style,
  color,
  direction = "right",
  isPointer,
  title,
  strokeWidth = "2",
  version = 1,
  ...props
}) => {
  switch (version) {
    case 2: {
      const directionToAngle = {
        up: 180,
        right: 90,
        down: 0,
        left: -90
      };

      const finalStyle = {
        transform: `rotate(${directionToAngle[direction]}deg)`,
        ...style
      };
      return (
        <svg
          className={className}
          style={finalStyle}
          data-is-pointer={isPointer}
          width={width || size || 10}
          height={height || size || 6}
          viewBox="0 0 10 6"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title"
          fill={color || easilGrayXDark}
          stroke={color || easilGrayXDark}
          {...props}
          data-testid="CaretIcon"
        >
          <title>{title}</title>
          <g fill="none" fillRule="evenodd">
            <path d="M-3-5h16v16H-3z" />
            <path
              stroke={color || easilGrayXDark}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1l4 4 4-4"
            />
          </g>
        </svg>
      );
    }
    case 1:
    default: {
      const directionToAngle = {
        up: -90,
        right: 0,
        down: 90,
        left: 180
      };

      const finalStyle = {
        transform: `rotate(${directionToAngle[direction]}deg)`,
        ...style
      };
      return (
        <svg
          className={className}
          style={finalStyle}
          data-is-pointer={isPointer}
          width={width || size || 12}
          height={height || size || 12}
          viewBox="0 0 6 8"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title"
          fill={color || easilGrayXDark}
          stroke={color || easilGrayXDark}
          {...props}
          data-testid="CaretIcon"
        >
          <title>{title}</title>
          <g fill="none">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={strokeWidth}
              d="M1.5 1l3 3.083L1.5 7"
            />
          </g>
        </svg>
      );
    }
  }
};
CaretIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  direction: PropTypes.string
};

export default CaretIcon;
