import React, { Component } from "react";
import { connect } from "react-redux";
import UpdateTeamSmartTextModal from "./UpdateTeamSmartTextModal";
import { updateTeamSmartText } from "state/entities/smartText/smartTextActions";
import {
  teamSmartTextOverideSelector,
  teamSmartTextSelector
} from "state/entities/smartText/smartTextSelectors";
import { moveToWorkspaceSmartText } from "state/ui/navigation/navigationActions";

export class UpdateTeamSmartTextModalContainer extends Component {
  render() {
    return <UpdateTeamSmartTextModal {...this.props} />;
  }
}

const mapStateToProps = state => {
  const standardSmartText = teamSmartTextSelector(state);
  const overrideSmartText = teamSmartTextOverideSelector(state);

  const smartText = [...standardSmartText, ...overrideSmartText];
  return {
    smartText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTeamSmartText: args => dispatch(updateTeamSmartText(args)),
    moveToWorkspaceSmartText: args => dispatch(moveToWorkspaceSmartText(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateTeamSmartTextModalContainer);
