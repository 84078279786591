export const FETCH_SHIPPING_DETAILS_FOR_ORDER_REQUEST =
  "API/ENTITIES/SHIPPING_DETAILS/FETCH_REQUEST";
export const FETCH_SHIPPING_DETAILS_FOR_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/SHIPPING_DETAILS/FETCH_REQUEST_SUCCESS";
export const FETCH_SHIPPING_DETAILS_FOR_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/SHIPPING_DETAILS/FETCH_REQUEST_FAILURE";

export const FETCH_SHIPPING_DETAILS_FOR_CURRENT_ORDER_REQUEST =
  "API/ENTITIES/SHIPPING_DETAILS/FETCH_CURRENT_REQUEST";
export const FETCH_SHIPPING_DETAILS_FOR_CURRENT_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/SHIPPING_DETAILS/FETCH_CURRENT_REQUEST_SUCCESS";
export const FETCH_SHIPPING_DETAILS_FOR_CURRENT_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/SHIPPING_DETAILS/FETCH_CURRENT_REQUEST_FAILURE";

export const CREATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST =
  "API/ENTITIES/SHIPPING_DETAILS/CREATE_REQUEST";
export const CREATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/SHIPPING_DETAILS/CREATE_REQUEST_SUCCESS";
export const CREATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/SHIPPING_DETAILS/CREATE_REQUEST_FAILURE";

export const UPDATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST =
  "API/ENTITIES/SHIPPING_DETAILS/UPDATE_REQUEST";
export const UPDATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_SUCCESS =
  "API/ENTITIES/SHIPPING_DETAILS/UPDATE_REQUEST_SUCCESS";
export const UPDATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_FAILURE =
  "API/ENTITIES/SHIPPING_DETAILS/UPDATE_REQUEST_FAILURE";
