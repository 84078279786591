import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark, easilGray } from "./style.module.css";

class CaretCircledIcon extends React.Component {
  static propTypes = {
    direction: PropTypes.string
  };

  shouldComponentUpdate() {
    return false;
  }
  render() {
    const {
      className,
      width,
      size,
      height,
      style,
      color,
      direction,
      noPointer,
      title,
      arrowColor = null,
      fullFill,
      ...props
    } = this.props;

    const directionToAngle = {
      up: -90,
      right: 0,
      down: 90,
      left: 180
    };

    const finalStyle = {
      transform: `rotate(${directionToAngle[direction] || 0}deg)`,
      ...style
    };

    const getFullFill = () => {
      if (fullFill) {
        return "";
      } else {
        return "M8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 Z";
      }
    };

    return (
      <svg
        {...props}
        className={className}
        style={finalStyle}
        data-no-pointer={noPointer}
        width={width || size || 16}
        height={height || size || 16}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
        stroke={color || easilGrayXDark}
        role="icon"
      >
        <title>{title}</title>
        <g
          id="DESIGNS-+-Proto"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Group-66"
            fill={
              this.props["data-light-disabled"]
                ? easilGray
                : color || easilGrayXDark
            }
            fillRule="nonzero"
          >
            <path
              d={`M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z ${getFullFill()}`}
              id="Oval-7-Copy"
            />
            <path
              d="M9.3059086,6.2801116 C9.70349181,5.89677643 10.3365512,5.90832678 10.7198863,6.30591002 C11.1032215,6.70349326 11.0916711,7.33655271 10.6940879,7.71988788 L8.61975121,9.71988736 C8.23238727,10.0933695 7.61893584,10.0933695 7.23157189,9.71988736 L5.30590999,7.86323468 C4.90832678,7.47989952 4.89677644,6.84684006 5.28011157,6.44925682 C5.66344671,6.05167358 6.2965061,6.04012323 6.69408931,6.4234584 L7.92566155,7.61089525 L9.3059086,6.2801116 Z"
              id="Path-3"
              fill={arrowColor}
            />
          </g>
        </g>
      </svg>
    );
  }
}

/* CaretCircledIcon.propTypes = {
 *   className: PropTypes.string,
 *   width: PropTypes.string,
 *   size: PropTypes.string,
 *   height: PropTypes.string,
 *   style: PropTypes.object,
 *   color: PropTypes.string,
 *   direction: PropTypes.string,
 * };
 * */

export default CaretCircledIcon;
