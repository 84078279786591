/* Adds the current location pathname and query params for all the redux actions */
export const pathname = store => next => action => {
  if (store.getState().router.location) {
    action.pathname = store.getState().router.location.pathname;
    action.queryParams = store.getState().router.location.search;
  }

  return next(action);
};

export default pathname;
