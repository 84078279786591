import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const BordersIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color = easilGrayXDark,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M22-2H-2v24h24z" />
      <path
        fill={color}
        stroke="none"
        d="M0 1v18a1 1 0 0 0 2 0V2h17a1 1 0 1 0 0-2H1a1 1 0 0 0-1 1z"
      />
      <path
        fill={color}
        stroke="none"
        d="M4 5v10a1 1 0 0 0 2 0V6h9a1 1 0 1 0 0-2H5a1 1 0 0 0-1 1z"
      />
    </g>
  </svg>
);
BordersIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default BordersIcon;
