import React from "react";
import style from "./style.module.css";
import layoutStyles from "views/layouts/mainLayout/style.module.css";

import HeaderBottomPanelLeft from "./HeaderBottomPanelLeft";
import HeaderBottomPanelRight from "./HeaderBottomPanelRight";

import { getHeaderLinks, cloneDeep } from "lib";
import { jsonStringEqual } from "lib/equalityUtils";

const linksOrder = [
  "designs",
  "templates",
  "brand",
  "team",
  "subscription",
  "orders"
];

const startingLinksWidth = 813;

export class HeaderBottomPanel extends React.Component {
  constructor(props) {
    super(props);

    this.computeLinksToPopout = this.computeLinksToPopout.bind(this);

    const {
      pathToCatalogue,
      isAdmin,
      isBrandManager,
      allowEasilTemplates,
      isTeamTemplates
    } = props;

    const headerLinks = getHeaderLinks({
      pathToCatalogue,
      isAdmin,
      isBrandManager,
      allowEasilTemplates,
      isTeamTemplates
    });

    this.state = {
      itemsToTrim: 0,
      headerLinks,
      activeHeaderLinks: headerLinks,
      popoutLinks: []
    };
  }

  componentDidMount() {
    this.computeLinksToPopout();
    window.addEventListener("resize", this.computeLinksToPopout);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.computeLinksToPopout);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const {
        pathToCatalogue,
        isAdmin,
        isBrandManager,
        allowEasilTemplates,
        isTeamTemplates
      } = this.props;

      const headerLinks = getHeaderLinks({
        pathToCatalogue,
        isAdmin,
        isBrandManager,
        allowEasilTemplates,
        isTeamTemplates
      });

      if (!jsonStringEqual(this.state.headerLinks, headerLinks)) {
        this.setState({
          headerLinks
        });
      }
      if (!jsonStringEqual(this.state.activeHeaderLinks, headerLinks)) {
        this.setState({
          activeHeaderLinks: headerLinks
        });
      }
    }
  }

  computeLinksToPopout() {
    const {
      headerLinks,
      activeHeaderLinks: currentActiveLinks,
      popoutLinks: currentPopoutLinks
    } = this.state;

    const activeHeaderLinks = cloneDeep(headerLinks);
    const popoutLinks = [];

    const spaceShort =
      startingLinksWidth -
      document.querySelector(`div[class="${layoutStyles.header}"]`)
        ?.clientWidth *
        0.73;

    if (spaceShort > 0) {
      let remainingSpace = spaceShort;

      // we need to cut back on links in the header
      for (const linkKey of [...linksOrder].reverse()) {
        const link = headerLinks[linkKey];
        if (link && (link.visible || link.isRightPanel) && !link.noPopout) {
          popoutLinks.unshift({ ...link, key: linkKey });
          activeHeaderLinks[linkKey].visible = false;
          remainingSpace -= (link.width || 0) - 20;
        }
        if (remainingSpace <= 0) {
          break;
        }
      }
    }

    if (
      !jsonStringEqual(currentActiveLinks, activeHeaderLinks) ||
      !jsonStringEqual(currentPopoutLinks, popoutLinks)
    ) {
      this.setState({
        activeHeaderLinks,
        popoutLinks
      });
    }
  }

  render() {
    const {
      onToggleHelpWindow,
      onCreateDesign,
      isOrganisation,
      isAdmin,
      isUserChatReady,
      currentOrder
    } = this.props;

    return (
      <div className={style.bottomPanel} data-testid="HeaderBottomPanel">
        <HeaderBottomPanelLeft
          links={this.state.activeHeaderLinks}
          onCreateDesign={onCreateDesign}
        />
        <HeaderBottomPanelRight
          links={this.state.activeHeaderLinks}
          onToggleHelpWindow={onToggleHelpWindow}
          isOrganisation={isOrganisation}
          isAdmin={isAdmin}
          isUserChatReady={isUserChatReady}
          popoutLinks={this.state.popoutLinks}
          currentOrder={currentOrder}
        />
      </div>
    );
  }
}

HeaderBottomPanel.displayName = "HeaderBottomPanel";

export default HeaderBottomPanel;
