import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const DiagonalArrow = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 12}
    height={height || size || 12}
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-6-6h24v24H-6z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M8.586 2H1a1 1 0 1 1 0-2h10a1 1 0 0 1 1 1v10a1 1 0 0 1-2 0V3.414l-8.293 8.293a1 1 0 1 1-1.414-1.414L8.586 2z"
      />
    </g>
  </svg>
);

DiagonalArrow.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default DiagonalArrow;
