import React from "react";
import style from "./style.module.css";
import { addAlphaToColorString, addAlphaIfNeeded } from "lib";

export class ScrollFold extends React.Component {
  constructor(props) {
    super(props);

    this.getCustomStyleFromParent = this.getCustomStyleFromParent.bind(this);
    this.getDisplayOptions = this.getDisplayOptions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.foldRef = React.createRef();

    this.state = {
      parentScrollTop: 0,
      parentScrollLeft: 0,
      listenerConnected: false
    };
  }

  componentDidUpdate() {
    const scrollFoldDiv = this.foldRef.current;

    if (!scrollFoldDiv) return;

    const parentElement = scrollFoldDiv.parentElement;

    if (!this.state.listenerConnected) {
      parentElement.addEventListener("scroll", this.handleScroll);
      this.setState({
        listenerConnected: true
      });
    }
  }

  componentWillUnmount() {
    if (this.state.listenerConnected) {
      const scrollFoldDiv = this.foldRef.current;

      if (!scrollFoldDiv) return;

      const parentElement = scrollFoldDiv.parentElement;
      parentElement.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("resize", this.handleResize);
    }
  }

  componentDidMount() {
    const scrollFoldDiv = this.foldRef.current;

    if (!scrollFoldDiv) return;

    const parentElement = scrollFoldDiv.parentElement;
    if (!this.state.listenerConnected) {
      parentElement.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.handleResize);
      this.setState({
        listenerConnected: true
      });
    }
  }

  handleResize() {
    this.handleScroll();
  }

  handleScroll() {
    const scrollFoldDiv = this.foldRef.current;

    if (!scrollFoldDiv) return;

    const parentElement = scrollFoldDiv.parentElement;

    this.setState({
      parentScrollTop: parentElement.scrollTop,
      parentScrollLeft: parentElement.scrollLeft
    });
  }

  getCustomStyleFromParent({ isStart, scrollFoldDiv }) {
    const {
      axis = "vertical",
      size = "64",
      color,
      borderRadius = 0
    } = this.props;

    if (!scrollFoldDiv) {
      return {};
    }

    const parentElement = scrollFoldDiv.parentElement;

    // need to use bounding client rect as parentElement.offsetHeight gets rounded which is unwanted
    const parentBoundingRect = parentElement.getBoundingClientRect();

    let customStyle = {};

    const parentStyles = window.getComputedStyle(parentElement);

    const isParentRelative = parentStyles.position === "relative";

    const backGroundColor = addAlphaIfNeeded(
      color || parentStyles.backgroundColor
    );

    const transparentBackgroundColor = addAlphaToColorString(
      backGroundColor,
      0
    );

    switch (axis) {
      case "horizontal": {
        customStyle = {
          left: isStart
            ? `${isParentRelative ? parentElement.scrollLeft : 0}px`
            : `${parentBoundingRect.width -
                size +
                (isParentRelative ? parentElement.scrollLeft : 0)}px`,
          top: `${
            parentElement.style.justifyContent === "center"
              ? `-${parentBoundingRect.height / 2}`
              : parentBoundingRect.top
          }px`,
          height: `${parentBoundingRect.height}px`,
          width: `${size}px`,
          backgroundImage: `linear-gradient(to right, ${
            isStart ? backGroundColor : transparentBackgroundColor
          }, ${isStart ? transparentBackgroundColor : backGroundColor})`,
          borderRadius: isStart
            ? `${borderRadius}px 0px ${borderRadius}px 0px`
            : `0px ${borderRadius}px 0px ${borderRadius}px`
        };
        break;
      }
      case "vertical":
      default: {
        customStyle = {
          top: isStart
            ? `${isParentRelative ? parentElement.scrollTop : 0}px`
            : `${parentBoundingRect.height -
                size +
                (isParentRelative ? parentElement.scrollTop : 0)}px`,
          left: `${
            parentElement.style.alignItems === "center"
              ? `-${parentBoundingRect.width / 2}`
              : 0
          }px`,
          width: `${parentBoundingRect.width}px`,
          height: `${size}px`,
          backgroundImage: `linear-gradient(to bottom, ${
            isStart ? backGroundColor : transparentBackgroundColor
          }, ${isStart ? transparentBackgroundColor : backGroundColor})`,
          borderRadius: isStart
            ? `${borderRadius}px ${borderRadius}px 0px 0px`
            : `0px 0px ${borderRadius}px ${borderRadius}px`
        };
      }
    }

    return customStyle;
  }

  getDisplayOptions({ scrollFoldDiv }) {
    let displayOptions = [];

    const { axis = "vertical" } = this.props;

    const { parentScrollTop, parentScrollLeft } = this.state;

    if (!scrollFoldDiv) {
      return displayOptions;
    }

    const parentElement = scrollFoldDiv.parentElement;

    if (axis === "vertical") {
      if (
        parentScrollTop <=
        parentElement.scrollHeight - parentElement.offsetHeight - 3
      ) {
        // the scroll is not touching the end of the element
        displayOptions.push({
          isStart: false
        });
      }
      if (parentScrollTop >= 1) {
        displayOptions.push({
          isStart: true
        });
      }
    } else {
      if (
        parentScrollLeft !==
        parentElement.scrollWidth - parentElement.offsetWidth
      ) {
        // the scroll is not touching the end of the element
        displayOptions.push({
          isStart: false
        });
      }
      if (parentScrollLeft !== 0) {
        displayOptions.push({
          isStart: true
        });
      }
    }

    return displayOptions;
  }

  render() {
    const scrollFoldDiv = this.foldRef.current;

    const displayOptions = this.getDisplayOptions({ scrollFoldDiv });

    return (
      <div
        className={`${style.scrollFold} ${this.props.className}`}
        ref={this.foldRef}
      >
        <div className={style.scrollFoldContent}>
          {displayOptions.map((option, index) => (
            <div
              key={`${option}-${index}`}
              style={this.getCustomStyleFromParent({
                isStart: option.isStart,
                scrollFoldDiv
              })}
              className={style.fold}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default ScrollFold;
