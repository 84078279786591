import * as types from "./resetPasswordTypes";
import { LOCATION_CHANGE } from "react-router-redux";
import PATHS from "routes/paths";
import { formatErrors } from "lib";

export const initState = {
  isSubmitting: false,
  isSuccess: false,
  isFetching: false,
  isValidToken: false,
  userId: null,
  errors: []
};

const errorsArray = error => formatErrors(error);

const resetPasswordReducers = (state = initState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (
        // came from the reset-password page and went somewhere else
        action.pathname === PATHS.resetPassword &&
        action.payload.pathname !== PATHS.resetPassword
      ) {
        return initState;
      }
      return state;
    }

    // Request Reset Password Token
    case types.RESET_PASSWORD_EMAIL_TOKEN_REQUEST: {
      return initState;
    }

    case types.RESET_PASSWORD_EMAIL_TOKEN_REQUEST_SUCCESS: {
      return {
        ...initState,
        isSuccess: true
      };
    }

    case types.RESET_PASSWORD_EMAIL_TOKEN_REQUEST_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...initState,
        errors
      };
    }

    // Validate Reset Password Token
    case types.VALIDATE_RESET_PASSWORD_EMAIL_TOKEN_REQUEST: {
      return {
        ...initState,
        isFetching: true
      };
    }

    case types.VALIDATE_RESET_PASSWORD_EMAIL_TOKEN_REQUEST_SUCCESS: {
      const {
        ids: id,
        entities: { resetPasswordToken }
      } = action.response;
      const userId = resetPasswordToken[id].userId;

      return {
        ...initState,
        userId,
        isValidToken: true
      };
    }

    case types.VALIDATE_RESET_PASSWORD_EMAIL_TOKEN_REQUEST_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...initState,
        errors
      };
    }

    // Reset Password
    case types.RESET_PASSWORD_REQUEST: {
      const { userId, isValidToken } = state;

      return {
        ...initState,
        userId,
        isValidToken,
        isSubmitting: true
      };
    }

    case types.RESET_PASSWORD_REQUEST_SUCCESS: {
      return {
        ...initState,
        isSuccess: true
      };
    }

    case types.RESET_PASSWORD_REQUEST_FAILURE: {
      const { userId, isValidToken } = state;
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...initState,
        userId,
        isValidToken,
        errors
      };
    }

    default:
      return state;
  }
};

export default resetPasswordReducers;
