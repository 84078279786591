export const CATALOGUE_SIZES_REQUEST = "API/ENTITIES/CATALOGUE_SIZES_REQUEST";
export const CATALOGUE_SIZES_REQUEST_SUCCESS =
  "API/ENTITIES/CATALOGUE_SIZES_REQUEST_SUCCESS";
export const CATALOGUE_SIZES_REQUEST_FAILURE =
  "API/ENTITIES/CATALOGUE_SIZES_REQUEST_FAILURE";
export const CATALOGUE_SIZES_FILTER_REQUEST =
  "API/ENTITIES/CATALOGUE_SIZES_FILTER_REQUEST";
export const CATALOGUE_SIZES_FILTER_REQUEST_SUCCESS =
  "API/ENTITIES/CATALOGUE_SIZES_FILTER_REQUEST_SUCCESS";
export const CATALOGUE_SIZES_FILTER_REQUEST_FAILURE =
  "API/ENTITIES/CATALOGUE_SIZES_FILTER_REQUEST_FAILURE";

export const TEAM_SIZES_REQUEST = "API/ENTITIES/TEAM_SIZES_REQUEST";
export const TEAM_SIZES_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SIZES_REQUEST_SUCCESS";
export const TEAM_SIZES_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SIZES_REQUEST_FAILURE";

export const TEAM_SIZES_FILTER_REQUEST =
  "API/ENTITIES/TEAM_SIZES_FILTER_REQUEST";
export const TEAM_SIZES_FILTER_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SIZES_FILTER_REQUEST_SUCCESS";
export const TEAM_SIZES_FILTER_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SIZES_FILTER_REQUEST_FAILURE";

export const TEAM_SIZES_FOLDER_REQUEST =
  "API/ENTITIES/TEAM_SIZES_FOLDERS_REQUEST";
export const TEAM_SIZES_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/TEAM_SIZES_FOLDERS_REQUEST_SUCCESS";
export const TEAM_SIZES_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/TEAM_SIZES_FOLDERS_REQUEST_FAILURE";

export const CATALOGUE_SIZES_FOLDER_REQUEST =
  "API/ENTITIES/CATALOGUE_SIZES_FOLDERS_REQUEST";
export const CATALOGUE_SIZES_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/CATALOGUE_SIZES_FOLDERS_REQUEST_SUCCESS";
export const CATALOGUE_SIZES_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/CATALOGUE_SIZES_FOLDERS_REQUEST_FAILURE";
