import React from "react";
import style from "./style.module.css";

import EmptyNotificationIcon from "views/components/icons/EmptyNotificationIcon";

const EmptyNotificationList = () => {
  return (
    <div className={style.wrapper}>
      <EmptyNotificationIcon size="44px" />
      <div className={style.text}>No new notifications</div>
    </div>
  );
};

EmptyNotificationList.displayName = "EmptyNotificationList";

EmptyNotificationList.propTypes = {};

export default EmptyNotificationList;
