import deepFreeze from "deep-freeze";
import Borders from "./Borders";
import { immutableUpdate } from "lib/immutableUpdate";

const table2Process = element => {
  element = deepFreeze(element);

  let table = { ...element };

  table = bordersProcess(table);

  if (!table.lockedCellIds) table.lockedCellIds = [];

  return table;
};

const bordersProcess = table => {
  let updatedTable = { ...table };

  Object.keys(updatedTable.cells).forEach(cellId => {
    const cell = updatedTable.cells[cellId];
    const newBorders = new Borders(cell.borders);
    updatedTable = immutableUpdate(updatedTable, {
      cells: {
        [cellId]: {
          $merge: { borders: newBorders }
        }
      }
    });
  });

  return updatedTable;
};

export default table2Process;
