import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TwoPeopleIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  isSmall,
  strokeWidth = 1.8,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 18}
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="TwoPeopleIcon"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0-1h20v20H0z" />
      <path d="M0-1h20v20H0z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M16 11c2.116 0 3.886 1.514 3.995 3.476l.005.19V17a1 1 0 01-1.993.117L18 17v-2.333c0-.893-.87-1.667-2-1.667a1 1 0 010-2zM4 11h6c2.116 0 3.886 1.514 3.995 3.476l.005.19V17a1 1 0 01-1.993.117L12 17v-2.333c0-.848-.786-1.59-1.833-1.661L10 13H4c-1.073 0-1.912.699-1.994 1.534L2 14.667V17a1 1 0 01-1.993.117L0 17v-2.333c0-1.99 1.704-3.566 3.796-3.662L4 11h6zm9-11a4 4 0 110 8 1 1 0 01-.117-1.993L13 6l.15-.005A2 2 0 0013 2a1 1 0 010-2zM7 0a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z"
      />
    </g>
  </svg>
);

TwoPeopleIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TwoPeopleIcon;
