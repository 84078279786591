import { Component } from "react";
import BreadCrumbList from "views/components/BreadCrumb/BreadCrumbList";
import { SECTION_TYPES } from "../teamCreateModalUtils";
import { InputTransparent } from "views/components";
import InfoAlert from "views/components/InfoAlert";
import DefaultTeamToggle from "views/containers/teamPageTopRow/CreateTeamModal/DefaultTeamToggle/DefaultTeamToggle";
import commonStyle from "../style.module.css";
import teamSectionStyle from "../teamSectionStyles.module.css";

export class OrgTeamSection extends Component {
  render() {
    const crumbPath = [
      {
        text: "Add New Team",
        onClick: () => {
          this.props.onSectionTransition(SECTION_TYPES.SELECTION);
        }
      },
      {
        text: "Top-Level Team"
      }
    ];
    return (
      <div className={teamSectionStyle.wrapper}>
        <div className={teamSectionStyle.breadCrumb}>
          <BreadCrumbList
            path={crumbPath}
            wrapperClassName={teamSectionStyle.breadCrumbList}
          />
        </div>
        <div className={commonStyle.title}>Add a new Top-Level Team</div>
        <div className={teamSectionStyle.nameSection}>
          <div className={teamSectionStyle.nameLabel}>Team Name</div>
          <div className={teamSectionStyle.inputWrapper}>
            <InputTransparent
              value={this.props.newTeamName}
              onChange={this.props.setNewTeamName}
              className={
                !!this.props.newTeamName
                  ? teamSectionStyle.input
                  : teamSectionStyle.inputPlaceholder
              }
              placeholder={"Example: 'Marketing' or 'Design'"}
            />
          </div>
        </div>
        <div className={teamSectionStyle.noticeText}>
          Your new team will be created seperate from{" "}
          <span>{this.props.currentTeam.name}</span>.
        </div>
        <div className={teamSectionStyle.divider} />
        <DefaultTeamToggle
          onToggleMakeTeamDefault={this.props.setMakeDefaultTeam}
          className={teamSectionStyle.teamToggle}
          textOverride={
            "Each time you log in to Easil, we'll place you in this team."
          }
        />
        <InfoAlert className={teamSectionStyle.info}>
          Your current subscription will not be carried over to your new team.
          <br />
          Try creating a Sub-Team, or learn about{" "}
          <a
            href="https://support.easil.com/creating-multiple-teams"
            target="_blank"
            rel="noreferrer"
          >
            Easil Teams.
          </a>
        </InfoAlert>
      </div>
    );
  }
}

export default OrgTeamSection;
