import React from "react";
import style from "./style.module.css";
import { Empty } from "../../components";

const TeamPageTopRowLoading = () => (
  <div className={style.topRowLoading} data-testid="TeamPageTopRowLoading">
    <div className={style.title}>
      <Empty height="31px" width="120px" />
    </div>
    <div className={style.topRowLeftItem}>
      <Empty height="33px" width="250px" />
    </div>
    <div className={style.topRowSubTitle}>
      <Empty height="24px" width="600px" />
    </div>
  </div>
);

export default TeamPageTopRowLoading;
