import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ShareDesignIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 18}
    height={height || size || 20}
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <path
      fill={color || easilGrayXDark}
      fillRule="nonzero"
      d="M11.172 0a3 3 0 0 1 2.12.879l3.83 3.828A3 3 0 0 1 18 6.828V17a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h8.172zm0 2H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6.828a1 1 0 0 0-.293-.707L11.88 2.293A1 1 0 0 0 11.172 2zm-.56 3.71l.095.083 3 3a1.006 1.006 0 0 1 .147.186l.021.037c.011.02.022.04.031.06l.023.053a.988.988 0 0 1 .06.223l.008.073.001.015.001.027L14 9.5a1.018 1.018 0 0 1-.071.371l-.023.052a1.018 1.018 0 0 1-.102.172l-.025.03c.004-.002.002-.001.001 0l-.013.015-.01.013-.05.054-3 3a1 1 0 0 1-1.497-1.32l.083-.094 1.291-1.293H9a3 3 0 0 0-2.995 2.824L6 13.5a1 1 0 1 1-2 0 5 5 0 0 1 4.783-4.995L9 8.5h1.586L9.293 7.207a1 1 0 0 1-.083-1.32l.083-.094a1 1 0 0 1 1.32-.083zm3.167 4.415l-.072.082.06-.067.012-.015z"
    />
  </svg>
);
ShareDesignIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ShareDesignIcon;
