import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TagWithDotIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path d="M1 2h20v20H1z" />
      <path
        d="M18 4a3 3 0 013 3v10a3 3 0 01-3 3H7.961a3 3 0 01-2.343-1.126l-4-5a3 3 0 010-3.748l4-5A3 3 0 017.961 4H18zm0 2H7.961a1 1 0 00-.78.375l-4 5a1 1 0 000 1.25l4 5a1 1 0 00.78.375H18a1 1 0 001-1V7a1 1 0 00-1-1zm-9.5 4.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
        fill={color || easilGrayXDark}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
TagWithDotIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TagWithDotIcon;
