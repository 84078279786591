import React from "react";
import { connect } from "react-redux";

import { redirectToTeamApproval } from "state/ui/notification/notificationActions";

import PendingApproval from "./PendingApproval";

const PendingApprovalContainer = props => {
  return <PendingApproval {...props} />;
};

PendingApprovalContainer.displayName = "PendingApprovalContainer";

PendingApprovalContainer.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.ui.notification.notifications.approval
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRedirectToTeamApproval: (...args) =>
      dispatch(redirectToTeamApproval(...args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingApprovalContainer);
