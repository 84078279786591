import React from "react";
import { SearchInput, DividerLabel, Loading } from "views/components";

import CheckIcon from "views/components/icons/CheckIcon";
import ArrowIcon from "views/components/icons/ArrowIcon";

import style from "./style.module.css";
import { easilBlue } from "views/style.module.css";
import DefaultTeamOption from "./DefaultTeamOption";

class TeamDefaultChooserPopout extends React.Component {
  constructor(props) {
    super(props);

    this.onTeamMenuNavigationDown = this.onTeamMenuNavigationDown.bind(this);
    this.onTeamMenuNavigationUp = this.onTeamMenuNavigationUp.bind(this);
    this.onFetchMoreTeams = this.onFetchMoreTeams.bind(this);
    this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);

    this.state = {
      teamsLoading: true,
      displayTeamDropdown: false,
      teams: [],
      nestedTeams: {},
      teamMenuLevel: 1,
      teamMenuTeamId: null,
      searchTerm: "",
      isSearchFocussed: false
    };
  }

  onSearchChange(value) {
    /* blank searchTerm should remove filter */
    if (value === "") {
      this.props.clearSearch();
    }
    this.setState({
      searchTerm: value
    });
  }

  // On down nav
  onTeamMenuNavigationDown(team) {
    this.props.navigateDown(team);
  }

  // On up nav
  onTeamMenuNavigationUp(team) {
    this.props.navigateUp(team);
  }

  componentDidMount() {
    /* get all the teams for the current user */
    this.props.fetchTeamsIfNeeded({
      onSuccess: () => {
        this.setState({ teamsLoading: false });
      }
    });
  }

  onFetchMoreTeams() {
    const {
      selectedTeam,
      fetchNextPageOfSubTeamsIfNeeded,
      fetchTeamsIfNeeded
    } = this.props;

    // when we have a selected team we want to fetch next subteam page
    if (selectedTeam) {
      fetchNextPageOfSubTeamsIfNeeded({ parentId: selectedTeam.id });
      return;
    }
    fetchTeamsIfNeeded();
  }

  onSearchKeyPress(event) {
    const { selectedTeam } = this.props;
    const value = event.target && event.target.value;

    /* on enter while focused */
    if (event.key !== "Enter") {
      return;
    }

    /* get the list of teams based on the search */
    if (value && value.trim()) {
      this.props.onSearchTeams({
        name: value,
        parentId: (selectedTeam || {}).id
      });
    }
  }

  render() {
    const { defaultTeam, teams, selectedTeam } = this.props;

    const { teamsLoading, searchTerm, isSearchFocussed } = this.state;

    const renderOptionList = list => {
      return list
        .filter(team => team.id !== defaultTeam.id)
        .map(team => (
          <DefaultTeamOption
            team={team}
            onFilterByOption={this.onTeamMenuNavigationDown}
            onOptionSelection={() => this.props.onOptionSelection(team.id)}
            key={team.id}
          />
        ));
    };

    const getOptions = () => {
      if (teamsLoading) {
        return <Loading />;
      }

      return renderOptionList(teams);
    };

    const hasSelectedTeam = selectedTeam && selectedTeam.name;

    return (
      <div
        className={style.dropdownPopout}
        data-testid="TeamDefaultChooserPopout"
      >
        <div className={style.dropdownPopoutTitle}>Default Team</div>
        <div className={style.searchWrapper}>
          <SearchInput
            placeholder="Search Teams"
            onKeyPress={this.onSearchKeyPress}
            clearInput={() => this.onSearchChange("")}
            onChange={this.onSearchChange}
            onClick={e => e.stopPropagation()}
            value={searchTerm}
            onFocus={() => this.setState({ isSearchFocussed: true })}
            onBlur={() => this.setState({ isSearchFocussed: false })}
            isFocussed={isSearchFocussed}
          />
        </div>
        {hasSelectedTeam ? (
          <div
            className={style.returnLink}
            onClick={() => this.onTeamMenuNavigationUp(selectedTeam)}
          >
            <ArrowIcon
              className={style.returnLinkIcon}
              size="24"
              direction="left"
            />
            <div>{selectedTeam.name}</div>
          </div>
        ) : (
          <div
            className={`${style.currentDefaultTeam} notranslate`}
            data-is-current-default="true"
          >
            {defaultTeam.name}
            <CheckIcon color={easilBlue} size="12px" />
          </div>
        )}
        <DividerLabel label="Teams" />
        {getOptions()}
      </div>
    );
  }
}

export default TeamDefaultChooserPopout;
