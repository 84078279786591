import { Logger } from "lib";

export const findPage = (pages, id) => {
  let pageNumberFound;

  for (let pageNum in pages) {
    const pageIds = pages[pageNum].ids;
    if (!pageIds) continue;

    if (pageIds.includes(id)) {
      pageNumberFound = pageNum;
      break;
    }
  }

  // This doesn't appear to be an error as everything is functioning. Changing to warning.
  // Possible that maybe the item has been removed and this is being called on redundant renders.
  if (!pageNumberFound) Logger.warn("Reducer page not found.");

  return pageNumberFound;
};
