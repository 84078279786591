import { currentOrderIdSelector } from "state/ui/cart/cartSelectors";
export const billingDetailsEntitySelector = state =>
  state.entities.billingDetails;
export const billingDetailsForOrderSelector = ({ state, orderId }) =>
  billingDetailsEntitySelector(state)[orderId] || {};
export const billingDetailsForCurrentOrderSelector = ({ state }) =>
  billingDetailsForOrderSelector({
    state,
    orderId: currentOrderIdSelector(state)
  });
