import * as types from "state/entities/teamUsersSearch/teamUsersSearchTypes";
import * as usersEntitiesTypes from "state/entities/users/usersTypes";
import { getPath, chunk } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";

export const initState = {
  pageSize: 50,
  currentSearchTerm: null,
  results: {
    /*
     * "searchTearm1": {
     *   pages: {}
     * },
     * "searchTearm2": {
     *   pages: {}
     * }*/
  }
};

const teamUsersSearchApiReducer = (state = initState, action) => {
  switch (action.type) {
    case types.SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST:
    case types.SEARCH_USERS_IN_TEAM_REQUEST: {
      const pageNumber = getPath(action.request, "page", 1);
      const searchTerm = getPath(action.request.params, "term", "ROLES");

      return immutableUpdate(state, {
        currentSearchTerm: {
          $set: searchTerm
        },
        results: {
          $auto: {
            [searchTerm]: {
              $auto: {
                pages: {
                  $auto: {
                    [pageNumber]: {
                      $set: {
                        isFetching: true
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.SEARCH_USERS_IN_TEAM_BY_ROLE_REQUEST_SUCCESS:
    case types.SEARCH_USERS_IN_TEAM_REQUEST_SUCCESS: {
      const pageNumber = getPath(action.request, "page", 1);
      const searchTerm = getPath(action.request.params, "term", "ROLES");
      return immutableUpdate(state, {
        results: {
          $auto: {
            [searchTerm]: {
              $auto: {
                pages: {
                  $auto: {
                    [pageNumber]: {
                      $set: {
                        isFetching: false,
                        ids: action.response.ids
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case usersEntitiesTypes.USER_DELETE_REQUEST: {
      const {
        user: { id: userId }
      } = action.request;
      const term = state.currentSearchTerm;

      // break early when not in a search
      if (!term) return state;

      const currentPages = getPath(state, `results[${term}].pages`, {});
      const allIds = Object.values(currentPages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        []
      );
      const updatedAllIds = allIds.filter(id => id !== userId);
      const idsSplitIntoPages = chunk(updatedAllIds, state.pageSize);

      let newPages = currentPages;
      const defaultPage = {
        isFetching: false,
        ids: [],
        lastFetched: Date.now()
      };

      // when all team members have been removed from search results
      // we need to return a single page with no ids
      if (!idsSplitIntoPages.length) {
        newPages = {
          1: defaultPage
        };
      } else {
        newPages = idsSplitIntoPages.reduce(
          (previousPages, ids, index) => ({
            ...previousPages,
            [index + 1]: {
              ...defaultPage,
              ids: ids
            }
          }),
          {}
        );
      }

      return immutableUpdate(state, {
        $auto: {
          results: {
            $auto: {
              [term]: {
                $set: {
                  pages: newPages
                }
              }
            }
          }
        }
      });
    }

    case types.CLEAR_TEAM_USERS_SEARCH: {
      return initState;
    }

    default:
      return state;
  }
};

export default teamUsersSearchApiReducer;
