import React, { Component } from "react";
import style from "./style.module.css";
import PropTypes from "prop-types";

import { Tooltip } from "views/components";
import InfoIcon from "views/components/icons/InfoIcon";
import ColoredLine from "views/components/coloredLine/ColoredLine";
import InfoAlert from "views/components/InfoAlert";
import SubscriptionCancellationModal from "views/components/SubscriptionCancellationModal/SubscriptionCancellationModal";
import { formatDateString } from "lib/temporal/dateUtils";
import { confirm } from "lib/ConfirmationModal/ConfirmationModal";

class CurrentSubscriptionDescription extends Component {
  constructor(props) {
    super(props);
    this.onCloseModal = this.onCloseModal.bind(this);

    this.state = {
      isCancelModalOpen: false
    };
  }
  static defaultProps = {
    confirmModal: confirm
  };

  getTiles() {
    /* to be implemented by subclass */
  }

  getInfoMessage() {
    /* to be implemented by subclass */
  }

  get planDisplayName() {
    return `Easil ${this.props.plan.name}`;
  }

  get planIntervalPeriod() {
    switch (this.props.plan.intervalPeriod) {
      case "MONTHLY":
        return "Monthly";
      case "YEARLY":
        return "Yearly";
      default: {
        /* ERROR */
        return "Error";
      }
    }
  }

  get renewDate() {
    return formatDateString(this.props.subscription.renewAt, "MMM D, YYYY");
  }

  updateCardComponent() {
    if (!this.props.isAdmin || this.props.subscription.isAccount) {
      return null;
    }

    return (
      <div
        onClick={this.onUpdateCardClick.bind(this)}
        className={style.updateCard}
      >
        Update card details
      </div>
    );
  }

  cancelPlanComponent() {
    if (!this.props.isAdmin || this.props.subscription.isAccount) {
      return null;
    }

    if (
      ["CANCELLING", "DOWNGRADING"].includes(this.props.subscription.status)
    ) {
      return null;
    }

    return (
      <div onClick={this.onCancelClick.bind(this)} className={style.cancelPlan}>
        Cancel plan
      </div>
    );
  }

  onUpdateCardClick() {
    this.props.showUpdateCardModal();
  }

  onCancelClick() {
    this.setState({ isCancelModalOpen: true });
  }

  onCloseModal() {
    this.setState({ isCancelModalOpen: false });
  }
  render() {
    return (
      <div className={style.wrapper}>
        <SubscriptionCancellationModal
          isOpen={this.state.isCancelModalOpen}
          onCloseModal={this.onCloseModal}
          onCancel={this.props.onCancel}
        />
        <div className={style.header}>Your Plan</div>
        <ColoredLine color="#e6e9eb" margin="12px 0px 24px" />
        <div className={style.tilesSection}>
          {this.getTiles().map(tileData => (
            <div key={tileData.title} className={tileData.className}>
              <div className={style.tileTitle}>{tileData.title}</div>
              {tileData.tooltip && (
                <div
                  className={style.tileTooltip}
                  data-tip={tileData.tooltip}
                  data-for="tileDetail"
                >
                  <InfoIcon size="12px" color="#d1d4d6" />
                </div>
              )}
              <div className={style.tileBody}>{tileData.body}</div>
            </div>
          ))}
          <Tooltip id="tileDetail" place="top" />
        </div>
        {this.updateCardComponent()}
        {this.cancelPlanComponent()}
        <div>
          <InfoAlert className={style.info}>{this.getInfoMessage()}</InfoAlert>
        </div>
      </div>
    );
  }
}

CurrentSubscriptionDescription.displayName = "CurrentSubscriptionDescription";

CurrentSubscriptionDescription.propTypes = {
  confirmModal: PropTypes.func,
  plan: PropTypes.object,
  subscription: PropTypes.object,
  card: PropTypes.object,
  isAdmin: PropTypes.bool,
  showUpdateCardModal: PropTypes.func,
  onCancel: PropTypes.func
};

export default CurrentSubscriptionDescription;
