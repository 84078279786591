import { removeItem } from "lib/array/array";
import {
  updateEntries,
  isFormValid,
  isEntryValid,
  checkForDuplicatedEmails
} from "./helpers";

export const removeEntryFromForm = ({ action, state }) => {
  const { entryIndex } = action.payload;
  const { entries } = state;

  let entriesUpdated = removeItem(entries, entryIndex);

  entriesUpdated = checkForDuplicatedEmails(entriesUpdated);

  if (entriesUpdated.length === 0) {
    return {
      entries: [],
      status: "EMPTY"
    };
  }

  return {
    ...state,
    entries: entriesUpdated,
    status: isFormValid(entriesUpdated) ? "VALID" : "INVALID"
  };
};

export const addEntryToForm = ({ state, newEntry }) => {
  const entriesUpdated = [...state.entries, newEntry];

  // when the form update is invalid we don't want it to add
  if (!isFormValid(entriesUpdated) || !isEntryValid(newEntry)) return state;

  return {
    ...state,
    entries: entriesUpdated,
    status: "VALID"
  };
};

export const handleFormChange = ({ action, state }) => {
  const { attribute, value, entryIndex } = action.payload;
  const { entries } = state;

  const entriesUpdated = updateEntries({
    attribute,
    value,
    entryIndex,
    entries
  });

  const status = isFormValid(entriesUpdated) ? "VALID" : "INVALID";

  return {
    ...state,
    entries: entriesUpdated,
    status
  };
};

export const setLoadingStatus = ({ state }) => {
  return {
    ...state,
    status: "LOADING"
  };
};
