import * as types from "./userTeamSmartImagesTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import { noop } from "lib";

export const fetchUserSmartTeamImages = ({ params, onSuccess = noop } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      types: [
        types.USER_TEAM_SMART_IMAGES_REQUEST,
        types.USER_TEAM_SMART_IMAGES_REQUEST_SUCCESS,
        types.USER_TEAM_SMART_IMAGES_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/smart-images`,
      schema: schemas.USER_TEAM_SMART_IMAGES_ARRAY,
      request: {
        params
      },
      onSuccess: response => {}
    }
  });
};

/**
 * @desc Creates a user team smart image
 * @param {string} label - the label of the Smart Image entity
 * @param {Number} mediaId - The id of the media entity
 */
export const createUserTeamSmartImage = ({
  label,
  mediaId,
  onSuccess = noop
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      onSuccess,
      types: [
        types.USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST,
        types.USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS,
        types.USER_TEAM_SMART_IMAGE_UPLOAD_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/smart-images`,
      schema: schemas.USER_TEAM_SMART_IMAGE,
      request: {
        body: {
          userId,
          teamId,
          label,
          mediaId
        }
      }
    }
  });
};

/**
 * @desc Updates a user team smart image
 * @param {Number} smartImage - Smart Image entity
 */
export const updateUserTeamSmartImage = ({ smartImage, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "PUT",
      onSuccess,
      types: [
        types.USER_TEAM_SMART_IMAGE_UPDATE_REQUEST,
        types.USER_TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS,
        types.USER_TEAM_SMART_IMAGE_UPDATE_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/smart-images/${smartImage.id}`,
      schema: schemas.USER_TEAM_SMART_IMAGE,
      request: {
        body: {
          id: smartImage.id,
          userId,
          teamId,
          label: smartImage.label,
          mediaId: smartImage.mediaId,
          createdAt: smartImage.createdAt,
          updatedAt: smartImage.updatedAt
        }
      }
    }
  });
};

/**
 * @desc Delete a user team smart image
 * @param {Number} id - The id of the smart image entity
 * @param {Number} mediaId - The id of the media entity
 */
export const deleteUserTeamSmartImage = ({ id, mediaId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);
  const userId = currentUserSelector(state).id;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      onSuccess,
      types: [
        types.USER_TEAM_SMART_IMAGE_DELETE_REQUEST,
        types.USER_TEAM_SMART_IMAGE_DELETE_REQUEST_SUCCESS,
        types.USER_TEAM_SMART_IMAGE_DELETE_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/smart-images/${id}`,
      schema: schemas.NONE,
      extra: {
        mediaId
      }
    }
  });
};
