import { isEmpty, getPath, pick } from "lib/lodash";
import { uuid } from "lib/uuid";
import { sanitizeHTMLForTextUsingDOMNodes } from "lib/textUtils";
import { rotatePoint } from "lib/geometry/rotation";
import { sortedJsonStringEqual } from "./equalityUtils";
import { EDITOR_ELEMENTS_MAP } from "lib/constants";

export const getElementsBox = _elements => {
  const elements = _elements instanceof Array ? _elements : [_elements];

  if (isEmpty(elements)) {
    return {
      top: 0,
      left: 0,
      width: 0,
      height: 0
    };
  }

  const xCoordinates = [];
  const yCoordinates = [];

  elements.forEach(element => {
    const elementOrigin = {
      x: element.left + element.width / 2,
      y: element.top + element.height / 2
    };

    const rotateElementAroundOriginFn = (x, y) =>
      rotatePoint(x, y, elementOrigin.x, elementOrigin.y, element.angle || 0);

    const cornersCoordinates = {
      NW: rotateElementAroundOriginFn(element.left, element.top),
      NE: rotateElementAroundOriginFn(
        element.left + element.width,
        element.top
      ),
      SW: rotateElementAroundOriginFn(
        element.left,
        element.top + element.height
      ),
      SE: rotateElementAroundOriginFn(
        element.left + element.width,
        element.top + element.height
      )
    };

    for (let coordinate in cornersCoordinates) {
      const { x, y } = cornersCoordinates[coordinate];

      xCoordinates.push(x);
      yCoordinates.push(y);
    }
  });

  return {
    top: Math.min(...yCoordinates),
    left: Math.min(...xCoordinates),
    width: Math.max(...xCoordinates) - Math.min(...xCoordinates),
    height: Math.max(...yCoordinates) - Math.min(...yCoordinates)
  };
};

// check an array of elements against an array of restrictions and determine if there are any elements that violate the restriction list
export const checkForRestrictedElements = ({
  elements,
  restrictions,
  brandColors
}) => {
  let elementCopies = [];
  elements.forEach(element => {
    const newId = uuid();
    // make a clone of the element
    const localElementsCopy = element.clone(newId);
    // add all elements to the copies array
    elementCopies = elementCopies.concat(localElementsCopy);
  });

  const elementTypes = [];
  elementCopies.forEach(element => {
    if (!elementTypes.includes(element.type)) {
      elementTypes.push(element.type);
    }
  });

  // check for elements that are not allowed
  if (
    restrictions.includes("addTextElement") &&
    elementTypes.includes("textbox")
  ) {
    return true;
  }

  //check for vector elements when graphics not allowed
  if (
    restrictions.includes("addGraphicElement") &&
    elementTypes.includes("vector")
  ) {
    return true;
  }

  // check for image elements when not allowed
  if (restrictions.includes("addPhotoElement")) {
    if (elementTypes.includes("image")) {
      return true;
    }

    // check for image masks in textboxes
    const textBoxElements = elementCopies.filter(
      element => element.type === "textbox"
    );
    textBoxElements.forEach(textElement => {
      if (!isEmpty(textElement.maskImage)) {
        return true;
      }
    });
  }

  // check for colors used that are not allowed
  if (restrictions.includes("brandColorsOnly")) {
    const brandColorCodes = brandColors.reduce((previousValue, colorSet) => {
      if (!colorSet || isEmpty(colorSet.colors)) return previousValue;
      const colors = Object.values(colorSet.colors).map(
        colorObject => colorObject.color
      );
      return previousValue.concat(colors);
    }, []);

    const isIncludingNonBrandColor = elementCopies.reduce(
      (previousValue, element) => {
        let newValue = false;
        switch (element.type) {
          case "textbox": {
            newValue = !brandColorCodes.includes(element.color);
            break;
          }
          case "vector": {
            const elementFillColors = element.fillColors.map(
              fillColor => fillColor.color
            );
            elementFillColors.forEach(fillColor => {
              newValue = newValue || !brandColorCodes.includes(fillColor.color);
            });
            break;
          }
          default: {
            break;
          }
        }
        return newValue || previousValue;
      },
      false
    );

    if (isIncludingNonBrandColor) {
      return true;
    }
  }

  // if nothing was triggered we know there are no restricted elements in the list
  return false;
};

export const convertTextBoxToVectorText = (element, value) => {
  const isElementEmptyString = element.value.trim() === "";

  // when string is empty we do not want to create a vectorText
  if (isElementEmptyString) return;

  const sanitizedValue = sanitizeHTMLForTextUsingDOMNodes(element.value)
    .replace("<br>", " ")
    .replace(/&amp;/g, "&");

  const sanitizedDisplayValue = sanitizeHTMLForTextUsingDOMNodes(
    element.displayValue
  )
    .replace("<br>", " ")
    .replace(/&amp;/g, "&");

  // take the textbox and adjust the type to vectortext
  return {
    type: EDITOR_ELEMENTS_MAP.VECTOR_TEXT,
    fillColors: getPath(element, "fillColors") || [
      { color: element.color, domId: "change_1" }
    ],
    color: undefined, // vectorText can not have color
    srcHeight: element.srcHeight || 1000,
    srcWidth: element.srcWidth || 1000,
    imageInstructions: element.imageInstructions || [],
    height:
      element.width > element.height * 2 ? element.width / 2 : element.height,
    shadow: Boolean(element.shadow)
      ? { ...element.shadow, enabled: false }
      : undefined,
    svgInstructions: {
      pathType: "Circle",
      type: "curvedText",
      ...(element.svgInstructions || {}),
      degrees: value
    },
    value: sanitizedValue,
    displayValue: sanitizedDisplayValue
  };
};

export const convertVectorTextToTextBox = (
  element,
  value,
  textValueOverride
) => {
  const textValue =
    textValueOverride !== undefined && typeof textValueOverride === "string"
      ? textValueOverride
      : element.value;

  //take the vectortext and adjust the type to textbox
  const changes = {
    type: "textbox",
    fillColors: undefined, // textbox can not have fillColors
    color: getPath(element, "fillColors[0].color") || element.color,
    scale: 1,
    shadow: Boolean(element.shadow)
      ? { ...element.shadow, enabled: true }
      : undefined,
    svgInstructions: {
      ...(element.svgInstructions || {}),
      degrees: value
    },
    value: textValue
  };

  return changes;
};

export const isImportantPropsChanged = (
  oldProps,
  newProps,
  importantPropKeys = []
) => {
  const importantOldProps = pick(oldProps, importantPropKeys);
  const importantNewProps = pick(newProps, importantPropKeys);

  return !sortedJsonStringEqual(importantOldProps, importantNewProps);
};

export const isElementInTargetVisibleArea = (element, target) => {
  if (!element || !target) return false;
  const elementBounds = element.getBoundingClientRect();
  const targetBounds = target.getBoundingClientRect();

  return (
    elementBounds.top >= targetBounds.top &&
    elementBounds.left >= targetBounds.left &&
    elementBounds.right <= targetBounds.right &&
    elementBounds.bottom <= targetBounds.bottom
  );
};

//https://stackoverflow.com/questions/35939886/find-first-scrollable-parent#42543908
export const getScrollParent = (element, includeHidden) => {
  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === "absolute";
  const overflowRegex = includeHidden ? /(scroll|hidden)/ : /(scroll)/;

  if (style.position === "fixed") return document.body;
  for (let parent = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === "static") {
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX))
      return parent;
  }

  return document.body;
};

export const getIsImageReplaceAvailableForElement = element => {
  if (element.restrictions.includes("imageUpload")) {
    return false;
  }
  switch (element.type) {
    case "video":
    case "image": {
      return true;
    }
    case "grid":
    case "vector": {
      if (element.imageInstructions && element.imageInstructions.length >= 1) {
        return true;
      }
      return false;
    }
    default: {
      return false;
    }
  }
};

/**
 * Check if the element type is one that we consider as part of the broader "text element" type.
 * @param elementType The type of element
 * @returns {boolean} true if the element is one that we want to display in this tab.
 */
export const isTextElement = elementType =>
  EDITOR_ELEMENTS_MAP.TEXTBOX === elementType ||
  EDITOR_ELEMENTS_MAP.VECTOR_TEXT === elementType;
