import React from "react";
import { noop } from "lib/lodash";

export const EditorContext = React.createContext({
  canRedo: false,
  canUndo: false,
  isPlaying: {},
  onUndo: noop,
  onRedo: noop
});

export default EditorContext;
