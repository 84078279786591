import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PlusIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  rotate,
  title,
  ...props
}) => {
  const finalStyle = {
    transform: `rotate(${rotate || 0}deg)`,
    ...style
  };

  return (
    <svg
      {...props}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
      stroke={color || easilGrayXDark}
      data-testid="PlusIcon"
    >
      <title>{title}</title>
      <g stroke="none" fillRule="evenodd">
        <rect width="2" height="20" x="9" rx="1" />
        <rect width="20" height="2" y="9" rx="1" />
      </g>
    </svg>
  );
};
PlusIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.string
};

export default PlusIcon;
