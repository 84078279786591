import React, { Component } from "react";
import style from "./styleResizeBox.module.css";
import ResizeHandler from "./resizeHandler/ResizeHandler";
import ElementContextMenu from "./ElementContextMenu";
import styleElementContextMenu from "./styleElementContextMenu.module.css";
import getElementHighestSide from "lib/getElementHighestSide";

class ResizeBox extends Component {
  constructor(props) {
    super(props);
    this.resizeBoxRef = React.createRef();
    this.getTopSide = this.getTopSide.bind(this);
    this.shouldShowContextMenu = this.shouldShowContextMenu.bind(this);
    this.handleContextMenuStyling = this.handleContextMenuStyling.bind(this);
    this.hasSizeAndPositionRestriction = this.hasSizeAndPositionRestriction.bind(
      this
    );
    this.state = {
      highestSide: null
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isInTextEditMode !== nextProps.isInTextEditMode) {
      return true;
    }
    if (this.props.angle !== nextProps.angle) {
      return true;
    }
    if (this.props.isResizing !== nextProps.isResizing) {
      return true;
    }
    if (this.state.highestSide !== nextState.highestSide) {
      return true;
    }
    if (this.props.isLocked !== nextProps.isLocked) {
      return true;
    }
    if (this.props.element !== nextProps.element) {
      return true;
    }
    if (this.props.elements !== nextProps.elements) {
      return true;
    }
    return this.props.showHandlers !== nextProps.showHandlers;
  }

  componentDidUpdate(prevProps) {
    if (this.props.angle !== prevProps.angle) {
      this.getTopSide();
    }
  }

  componentDidMount() {
    this.getTopSide();
  }

  getTopSide() {
    if (!this.props.element) {
      return;
    }

    const { highestSide, sideArray } = getElementHighestSide(
      this.props.element
    );

    switch (highestSide) {
      case sideArray[0]:
        this.setState({ highestSide: "top" });
        break;
      case sideArray[1]:
        this.setState({ highestSide: "right" });
        break;
      case sideArray[2]:
        this.setState({ highestSide: "bottom" });
        break;
      case sideArray[3]:
        this.setState({ highestSide: "left" });
        break;
      default:
        this.setState({ highestSide: "top" });
    }
  }

  hasSizeAndPositionRestriction() {
    if (!this.props.element) {
      return;
    }
    return this.props.element.restrictions.some(
      restriction => restriction === "sizeAndPosition"
    );
  }

  handleContextMenuStyling() {
    if (this.props.element && this.props.element.type === "table") {
      switch (this.state.highestSide) {
        case "top":
          return this.hasSizeAndPositionRestriction()
            ? `${styleElementContextMenu.menuWrapper}`
            : `${styleElementContextMenu.tableMenuWrapper}`;
        case "right":
          return `${styleElementContextMenu.tableMenuWrapperRight}`;
        case "bottom":
          return `${styleElementContextMenu.tableMenuWrapperBottom}`;
        case "left":
          return `${styleElementContextMenu.menuWrapperLeft}`;
        default:
          return `${styleElementContextMenu.tableMenuWrapper}`;
      }
    } else if (this.props.element && this.props.element.type === "table2") {
      switch (this.state.highestSide) {
        case "top":
          return this.hasSizeAndPositionRestriction()
            ? `${styleElementContextMenu.menuWrapper}`
            : `${styleElementContextMenu.table2MenuWrapper}`;
        case "right":
          return `${styleElementContextMenu.table2MenuWrapperRight}`;
        case "bottom":
          return `${styleElementContextMenu.table2MenuWrapperBottom}`;
        case "left":
          return `${styleElementContextMenu.table2MenuWrapperLeft}`;
        default:
          return `${styleElementContextMenu.table2MenuWrapper}`;
      }
    } else {
      switch (this.state.highestSide) {
        case "top":
          return `${styleElementContextMenu.menuWrapper}`;
        case "right":
          return `${styleElementContextMenu.menuWrapperRight}`;
        case "bottom":
          return `${styleElementContextMenu.menuWrapperBottom}`;
        case "left":
          return `${styleElementContextMenu.menuWrapperLeft}`;
        default:
          return `${styleElementContextMenu.menuWrapper}`;
      }
    }
  }

  shouldShowContextMenu() {
    const {
      isElementSelection,
      isResizing,
      isMultipleElementsSelection,
      isCropping
    } = this.props;
    if (isCropping) {
      return false;
    }
    return (
      (!isElementSelection && isMultipleElementsSelection) ||
      (!isResizing && !isElementSelection)
    );
  }

  render() {
    const {
      isResizing,
      isLocked,
      isSemiLocked,
      left,
      pageId,
      showHandlers = true,
      top,
      withXResize = false,
      withYResize = false,
      handlersCoordinates = ["NW", "NE", "SW", "SE"],
      Handler = ResizeHandler,
      className,
      element,
      isPreview,
      isCropping,
      isInTextEditMode,
      elements
    } = this.props;

    const allCoordinates = [...handlersCoordinates];

    if (withXResize) {
      allCoordinates.push(...["E", "W"]);
    }

    if (withYResize) {
      allCoordinates.push(...["N", "S"]);
    }

    let opacity = 1;
    if (isResizing && !isPreview) {
      opacity = 0;
    }
    if (isInTextEditMode) {
      opacity = 0.3;
    }

    return (
      <div
        className={`${style.resizeBox} ${className}`}
        style={{
          left: left || 0,
          opacity,
          top: top || 0
        }}
        ref={this.resizeBoxRef}
      >
        {this.shouldShowContextMenu() && (
          <ElementContextMenu
            isLocked={isLocked}
            isSemiLocked={isSemiLocked}
            element={element}
            elements={elements}
            angle={this.props.angle}
            onContextMenuClick={this.props.onContextMenuClick}
            contextMenuEnable={this.props.contextMenuEnable}
            closeContextMenu={this.props.closeContextMenu}
            onContextMenu={this.props.onContextMenu}
            menuStyling={this.handleContextMenuStyling}
            modifyQuery={this.props.modifyQuery}
            contextMenu={this.props.contextMenu}
            highestSide={this.state.highestSide}
          />
        )}
        {showHandlers &&
          allCoordinates.map(position => (
            <Handler
              pageId={pageId}
              position={position}
              key={`${position}-${Handler.displayName}`}
              element={element}
              hasXHandles={withXResize}
              hasYHandles={withYResize}
              isCropping={isCropping}
            />
          ))}
      </div>
    );
  }
}

ResizeBox.displayName = "ResizeBox";

export default ResizeBox;
