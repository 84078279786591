import React, { Component } from "react";
import { connect } from "react-redux";
import ImagesUploadModal from "./ImagesUploadModal";
import {
  uploadTeamAnimation,
  clearTeamAnimationsUploadErrors,
  addUploadingTeamAnimationsPlaceholders
} from "state/entities/teamAnimations/teamAnimationsActions";
import { getParameterByName } from "lib/queryStringUtils";
import { getAllTeamAnimationsFolders } from "state/entities/teamAnimationsFolders/teamAnimationsFoldersSelectors";
import {
  fetchAllTeamAnimationFoldersIfNeeded,
  uploadTeamAnimationToFolder
} from "state/entities/teamAnimationsFolders/teamAnimationsFoldersActions";
import {
  ANIMATED_ASSET_FILE_TYPES,
  BASIC_SUBSCRIPTION_ANIMATED_ASSET_FILE_TYPES
} from "lib/constants";
import { teamAnimationsErrorSelector } from "state/api/teamAnimations/teamAnimationsApiSelectors.js";
import { getUserSubscriptionPlan } from "state/entities/subscriptionPlans/subscriptionPlansSelectors";
import {
  modifyQuery,
  navigateToAssetPrioritySubtab
} from "state/ui/navigation/navigationActions";

export class TeamAnimationsUploadModalContainer extends Component {
  render() {
    return <ImagesUploadModal {...this.props} destinationName="Brand Videos" />;
  }
}

const mapStateToProps = state => {
  const userSubscriptionPlan = getUserSubscriptionPlan(state);
  const acceptedFileTypes = userSubscriptionPlan.isBasicPlan
    ? BASIC_SUBSCRIPTION_ANIMATED_ASSET_FILE_TYPES
    : ANIMATED_ASSET_FILE_TYPES;

  return {
    acceptedFileTypes,
    imageFolders: getAllTeamAnimationsFolders(state),
    folderId: getParameterByName("folderId", window.location.search),
    uploadOrigin: "teamAnimation",
    uploadErrors: teamAnimationsErrorSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUsersImageFolders: args =>
      dispatch(fetchAllTeamAnimationFoldersIfNeeded(args)),
    onUploadUserTeamImage: args => dispatch(uploadTeamAnimation(args)),
    onUploadImageToFolder: args => dispatch(uploadTeamAnimationToFolder(args)),
    clearUploadErrors: () => dispatch(clearTeamAnimationsUploadErrors()),
    addUploadingAnimationsPlaceholders: args =>
      dispatch(addUploadingTeamAnimationsPlaceholders(args)),
    modifyQuery: args => dispatch(modifyQuery(args)),
    handleUploadTabChange: args => dispatch(navigateToAssetPrioritySubtab(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamAnimationsUploadModalContainer);
