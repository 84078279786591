import regionsUS from "./regions/regionsUS";
import regionsAU from "./regions/regionsAU";

/**
 * @desc builds an object containing all regions from US and AU in the regions folder
 */
export const regions = Object.assign(
  {},
  ...Object.keys(regionsUS).map(key => ({
    [`US-${key}`]: {
      key,
      label: regionsUS[key],
      country: "US"
    }
  })),
  ...Object.keys(regionsAU).map(key => ({
    [`AU-${key}`]: {
      key,
      label: regionsAU[key],
      country: "AU"
    }
  }))
);

/**
 * @desc takes a countryCode and returns an array of regions in that country
 * @param {string} countryCode - the country code to get regions for
 */
export const getRegionsForCountry = ({ countryCode }) => {
  const regionList = Object.values(regions).filter(
    region => region.country === countryCode
  );

  return regionList;
};
