import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const EditPadlockIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="EditPadlockIcon"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <g fill={color || easilGrayXDark} fillRule="nonzero">
        <path d="M9.878 8 8.44 9.439a4.5 4.5 0 0 0-.476.56L6 10a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-3.88l1.608-1.605c.25.438.392.945.392 1.485v4a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3h3.878z" />
        <path d="M10 2a4 4 0 0 1 3.608 2.271l-1.613 1.612v-.032A2 2 0 0 0 8 6v3H6V6a4 4 0 0 1 4-4z" />
      </g>
      <path
        stroke={color || easilGrayXDark}
        strokeWidth="2"
        d="m14 7.414.586.586-3.792 3.792a2 2 0 0 1-.782.483c-.178-.6-.016-.856.196-1.069L14 7.414zm3-2.828a.413.413 0 0 1 .293.707L17 5.586 16.414 5c.374-.374.48-.414.586-.414z"
      />
    </g>
  </svg>
);

EditPadlockIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default EditPadlockIcon;
