export const getImageName = image => {
  if (!image) {
    throw new Error("Image required.");
  }

  if (image.media && image.media.name) {
    return image.media.name;
  }

  if (image.name) {
    return image.name;
  }

  const baseUrl =
    image.originalUrl || image.url || image.previewUrl || image.thumbnailUrl;

  if (baseUrl) {
    return baseUrl.split("/").pop();
  }

  return null;
};

export const getSmartImageLabel = image => {
  if (!image) {
    throw new Error("Image required.");
  }

  if (image.label) {
    return image.label;
  }

  return null;
};

export const getImageSize = image => {
  if (!image) {
    throw new Error("Image required.");
  }

  if (image.media && image.media.size) {
    return image.media.size;
  }

  if (image.size) {
    return image.size;
  }

  return null;
};

export const getImageMediaId = image => {
  if (!image) {
    throw new Error("Image required.");
  }

  if (image.media && image.media.id) {
    return image.media.id;
  }

  if (image.mediaId) {
    return image.mediaId;
  }

  if (image.id) {
    return image.id;
  }

  return null;
};
