import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import TeamOption from "./TeamOption";
import { Loading } from "views/components";

export class TeamList extends React.Component {
  constructor(props) {
    super(props);

    this.onScroll = this.onScroll.bind(this);
    this.wrapperRef = React.createRef();
  }

  onScroll() {
    if (!this.wrapperRef.current) return;

    const wrapper = this.wrapperRef.current;
    const { onFetchMoreTeams } = this.props;

    if (
      wrapper.scrollTop + wrapper.offsetHeight >
      wrapper.scrollHeight * 0.75
    ) {
      // scrolled past 75% of the content into view, fetch more content
      onFetchMoreTeams();
    }
  }

  render() {
    const {
      teams,
      teamsLoading,
      handleProcessDesignRemix,
      selectTeam
    } = this.props;

    return (
      <div
        className={style.wrapper}
        ref={this.wrapperRef}
        onScroll={this.onScroll}
        data-testid="TeamList"
      >
        {!teamsLoading ? (
          Object.values(teams).map(team => (
            <TeamOption
              key={team.id}
              team={team}
              handleProcessDesignRemix={handleProcessDesignRemix}
              selectTeam={() => selectTeam(team)}
            />
          ))
        ) : (
          <Loading size="24px" />
        )}
      </div>
    );
  }
}

TeamList.propTypes = {
  teams: PropTypes.object
};

TeamList.displayName = "TeamList";
export default TeamList;
