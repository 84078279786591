import React from "react";
import { connect } from "react-redux";
import TeamSignupDetails from "./TeamSignupDetails";
import { onCreateOrganisationAndInviteToTeam } from "state/ui/teamSignup/TeamSignupActions";
import { inviteToTeamAndRedirect } from "state/entities/teams/teamsActions";
import { switchToken } from "state/currentUser/currentUserActions";
import { Redirect } from "react-router-dom";

export const TeamSignupDetailsContainer = ({
  onCreateOrganisation,
  inviteToTeamForm,
  isOrganisationCreated,
  inviteToTeamAndRedirect,
  user,
  isCreatingOrganisation,
  isSwitchingToken
}) => {
  if (!user) {
    return <Redirect to="/signup/team" />;
  }

  return (
    <TeamSignupDetails
      isCreatingOrganisation={isCreatingOrganisation}
      inviteToTeamAndRedirect={inviteToTeamAndRedirect}
      inviteToTeamForm={inviteToTeamForm}
      isOrganisationCreated={isOrganisationCreated}
      onCreateOrganisation={onCreateOrganisation}
      isSwitchingToken={isSwitchingToken}
    />
  );
};

const mapStateToProps = state => ({
  inviteToTeamForm: state.ui.inviteToTeamForm,
  isOrganisationCreated: state.ui.teamSignup.isOrganisationSucess,
  isCreatingOrganisation: state.ui.teamSignup.isProcessing,
  user: state.ui.teamSignup.user,
  isSwitchingToken: state.currentUser.isSwitchingToken
});

const mapDispatchToProps = dispatch => ({
  onCreateOrganisation: args =>
    dispatch(onCreateOrganisationAndInviteToTeam(args)),
  inviteToTeamAndRedirect: args => dispatch(inviteToTeamAndRedirect(args)),
  switchToken: args => dispatch(switchToken(args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamSignupDetailsContainer);
