export const UI_CART_SET_CURRENT_ORDER_ID = "UI/CART/SET_CURRENT_ORDER_ID";
export const UI_CART_SET_LOADING = "UI/CART/SET_LOADING";
export const UI_CART_SET_COUPON_ERROR = "UI/CART/SET_COUPON_ERROR";
export const UI_CART_SET_BILLING = "UI/CART/SET_BILLING";
export const UI_CART_SET_SHIPPING = "UI/CART/SET_SHIPPING";
export const UI_CART_SHIPPING_SAME_AS_BILLING =
  "UI/CART/SHIPPING_SAME_AS_BILLING";
export const UI_CART_SET_TOKEN = "UI/CART/SET_TOKEN";
export const UI_CART_SET_PAYMENT_TYPE = "UI/CART/SET_PAYMENT_TYPE";
export const UI_CART_SET_SHIPPING_DEFAULTS = "UI/CART/SET_SHIPPING_DEFAULTS";
export const UI_CART_CLEAR = "UI/CART/CLEAR";
