import * as types from "./takeoverSharedDesignModalTypes";
import { fetchAllUsersWithParentTeams } from "state/entities/users/usersActions";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";

// update redux takeover shared design modal state to show the modal
export const initTakeoverModal = ({ designId }) => (dispatch, getState) => {
  const dispatchToShowModal = () => dispatch(showModal(designId));

  const currentTeamId = currentTeamIdSelector(getState());

  dispatch(
    fetchAllUsersWithParentTeams({
      teamId: currentTeamId,
      onSuccess: dispatchToShowModal
    })
  );
};

export const showModal = designId => {
  return {
    type: types.SHOW_TAKEOVER_MODAL,
    designId
  };
};

// update redux takeover shared design modal state to hide the modal
export const hideTakeoverSharedDesignModal = () => {
  return {
    type: types.HIDE_TAKEOVER_MODAL
  };
};
