import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const AnimationIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <rect
        stroke={color || easilGrayXDark}
        strokeWidth="2"
        x="1"
        y="1"
        width="18"
        height="18"
        rx="3"
      />
      <path fill={color || easilGrayXDark} d="M4 1h2v18H4zM14 1h2v18h-2z" />
      <path
        fill={color || easilGrayXDark}
        d="M1 11V9h18v2zM1 6.5v-2h4v2zM1 15.5v-2h4v2zM15 6.5v-2h4v2zM15 15.5v-2h4v2z"
      />
    </g>
  </svg>
);
AnimationIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default AnimationIcon;
