import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import CaretCircledIcon from "views/components/icons/CaretCircledIcon";

export class CaretPopout extends Component {
  static propTypes = {
    resizeEventsCount: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.setPopoutRef = this.setPopoutRef.bind(this);
    this.setCaretRef = this.setCaretRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.popoutRef = null;
    this.caretRef = null;

    this.state = {
      dynamicStyle: null
    };
  }

  componentDidMount() {
    this.ensureInsideWrapper();
    document
      .getElementById("root")
      .addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document
      .getElementById("root")
      .removeEventListener("click", this.handleClickOutside);
  }

  getBottomValue() {
    const newPopoutBottom =
      this.popoutRef.offsetTop -
      this.caretRef.offsetTop +
      (this.popoutRef.offsetHeight + 5);

    return newPopoutBottom;
  }

  ensureInsideWrapper() {
    let { wrapperElement } = this.props;

    if (!wrapperElement) wrapperElement = document.body;
    // handle the event where a react ref is passed in (extracting current here allows the bounding to update)
    if (wrapperElement.current) wrapperElement = wrapperElement.current;

    const wrapperRightPosition = wrapperElement.getBoundingClientRect().right;

    /* get the current offsetLeft of the popout to add to offset calculation */
    const popoutOriginalOffsetLeft = this.popoutRef.offsetLeft;
    const popoutRightPosition = this.popoutRef.getBoundingClientRect().right;

    const offset = wrapperRightPosition - popoutRightPosition;

    // get the position for the bottom dynamically to suit all popouts
    const bottomValue = this.getBottomValue();
    let leftValue;

    // don't adjust the left if the element is not overlapping the right border
    if (offset < 0) {
      leftValue = popoutOriginalOffsetLeft + offset - 5;
    }

    this.setState({
      dynamicStyle: {
        left: leftValue,
        bottom: bottomValue
      }
    });
  }

  setPopoutRef(node) {
    this.popoutRef = node;
  }

  setCaretRef(node) {
    this.caretRef = node;
  }

  handleClickOutside(event) {
    const { popoutRef, caretRef } = this;
    const closestPopoutFromTarget = event.target.closest(
      `div[id^="caret-with-popout-"]`
    );
    if (
      popoutRef &&
      !popoutRef.contains(event.target) &&
      caretRef &&
      !caretRef.contains(event.target) &&
      // ignore targets that are not in the dom at the time of trigger
      (event.target.isConnected || closestPopoutFromTarget)
    ) {
      this.props.onClose();
    }
    return event;
  }

  render() {
    const { dynamicStyle } = this.state;
    const {
      onClose,
      className,
      arrowColor,
      circleFillColor,
      fullFill,
      popoutClassName
    } = this.props;

    return (
      <div className={fullFill ? style.caretOuterWrapFullFill : ""}>
        <div
          className={`${style.caretWrapper} ${className}`}
          ref={this.setCaretRef}
        >
          <CaretCircledIcon
            onClick={onClose}
            arrowColor={arrowColor}
            color={circleFillColor}
            fullFill={fullFill}
          />
        </div>
        <div
          className={`${style.popout} ${popoutClassName}`}
          style={dynamicStyle}
          ref={this.setPopoutRef}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default CaretPopout;
