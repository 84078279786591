import React from "react";

export class WidthLimitedEllipsisSpan extends React.Component {
  constructor(props) {
    super(props);

    this.computeVisibleCharsForWidth = this.computeVisibleCharsForWidth.bind(
      this
    );

    this.state = {
      characterLimit: undefined
    };
  }

  computeVisibleCharsForWidth() {
    const { maxWidth = "100%", content, style = {} } = this.props;

    const testSpan = document.createElement("SPAN");
    const testStyles = `display: flex; white-space: nowrap; max-width: ${maxWidth}; overflow: hidden; ${
      style.fontWeight ? `font-weight: ${style.fontWeight}` : ""
    }`;
    testSpan.setAttribute("style", testStyles);
    document.body.appendChild(testSpan);

    let charLimit = 0;
    // run through the characters in the content and add them until we exceed the boundary
    for (let i = 0; i < content.length; i++) {
      let newNode = document.createElement("span");
      let char = content.charAt(i);
      if (char === " ") {
        char = "o";
      }
      newNode.appendChild(document.createTextNode(char));
      testSpan.appendChild(newNode);

      if (newNode.offsetLeft < testSpan.offsetWidth) {
        charLimit++;
      }
    }
    document.body.removeChild(testSpan);

    this.setState({
      characterLimit: charLimit
    });
  }

  componentDidMount() {
    this.computeVisibleCharsForWidth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.computeVisibleCharsForWidth();
    }
  }

  render() {
    const { content, className, style } = this.props;

    const { characterLimit = content.length } = this.state;

    const displayContent = `${content.substring(0, characterLimit).trim()}${
      characterLimit < content.length ? "..." : ""
    }`;

    return (
      <span className={className} style={style}>
        {displayContent}
      </span>
    );
  }
}

export default WidthLimitedEllipsisSpan;
