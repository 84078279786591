export const RESET_PASSWORD_EMAIL_TOKEN_REQUEST =
  "API/UI/RESET_PASSWORD/EMAIL_TOKEN_REQUEST";
export const RESET_PASSWORD_EMAIL_TOKEN_REQUEST_SUCCESS =
  "API/UI/RESET_PASSWORD/EMAIL_TOKEN_REQUEST_SUCCESS";
export const RESET_PASSWORD_EMAIL_TOKEN_REQUEST_FAILURE =
  "API/UI/RESET_PASSWORD/EMAIL_TOKEN_REQUEST_FAILURE";

export const VALIDATE_RESET_PASSWORD_EMAIL_TOKEN_REQUEST =
  "API/UI/RESET_PASSWORD/VALIDATE_EMAIL_TOKEN_REQUEST";
export const VALIDATE_RESET_PASSWORD_EMAIL_TOKEN_REQUEST_SUCCESS =
  "API/UI/RESET_PASSWORD/VALIDATE_EMAIL_TOKEN_REQUEST_SUCCESS";
export const VALIDATE_RESET_PASSWORD_EMAIL_TOKEN_REQUEST_FAILURE =
  "API/UI/RESET_PASSWORD/VALIDATE_EMAIL_TOKEN_REQUEST_FAILURE";

export const RESET_PASSWORD_REQUEST =
  "API/UI/RESET_PASSWORD/RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS =
  "API/UI/RESET_PASSWORD/RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_FAILURE =
  "API/UI/RESET_PASSWORD/RESET_PASSWORD_REQUEST_FAILURE";
