import React from "react";
import { connect } from "react-redux";

import { getApprovalNotifications } from "state/ui/notification/notificationSelectors";
import { userIsBrandManager } from "state/currentUser/currentUserSelectors";

import Notification from "./Notification";

const NotificationContainer = props => {
  return <Notification {...props} />;
};

NotificationContainer.displayName = "NotificationContainer";

NotificationContainer.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: getApprovalNotifications(state),
    isBrandManager: userIsBrandManager(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer);
