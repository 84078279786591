import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const SparklesIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <path d="M0 0h16v16H0z" />
      <path
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 8.004C5.836 7.168 6.503 6.167 7 5c.5 1.166 1.167 2.167 2 3.004.833.837 1.833 1.502 3 1.996-1.168.501-2.168 1.168-3 2-.832.832-1.499 1.832-2 3-.5-1.171-1.167-2.171-2-3-.833-.829-1.833-1.495-3-2 1.164-.495 2.164-1.16 3-1.996z"
      />
      <path
        fill="#56585C"
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.25 3.25c.334-.334.584-.751.75-1.25.167.499.417.915.75 1.25.333.335.75.585 1.25.75-.5.167-.917.417-1.25.75-.333.333-.583.75-.75 1.25a3.118 3.118 0 0 0-.75-1.25A3.177 3.177 0 0 0 12 4a3.129 3.129 0 0 0 1.25-.75z"
      />
      <path
        fill="#56585C"
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.833 1.833C2.168 1.5 2.39 1.055 2.5.5c.111.554.334.999.667 1.333.333.335.777.557 1.333.667-.556.112-1 .334-1.333.667-.333.332-.555.777-.667 1.333-.111-.557-.334-1.002-.667-1.333C1.5 2.835 1.056 2.613.5 2.5c.555-.11.999-.332 1.333-.667z"
      />
    </g>
  </svg>
);
SparklesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SparklesIcon;
