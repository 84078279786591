import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const EditorContainer = lazy(() => import("./EditorContainer"));

export const EditorContainerLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <EditorContainer {...props} />
  </Suspense>
);
EditorContainerLazy.displayName = "EditorContainer";

export default EditorContainerLazy;
