import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import * as types from "./teamSignupTypes";
import { noop, Referrer } from "lib";
import { getParameterByName } from "lib/queryStringUtils";
import { inviteToTeamAndRedirect } from "state/entities/teams/teamsActions";
import {
  fetchUserToken,
  switchToken
} from "state/currentUser/currentUserActions";
import { moveTo } from "state/ui/navigation/navigationActions";
import PATHS from "routes/paths";
import { reportSignupEvent } from "lib/conversionTracker";

const isSignUpUrl = pathname =>
  pathname === PATHS.signup ||
  pathname === PATHS.signupTeam ||
  PATHS.signupTeams;

export const getCampaignReference = router => {
  if (!isSignUpUrl(router.location.pathname)) {
    return null;
  }

  return getParameterByName("cid", router.location.search);
};

const getCoupon = router => {
  if (!isSignUpUrl(router.location.pathname)) {
    return null;
  }

  return getParameterByName("coupon", router.location.search);
};

export const onCreatePersonalOrganisation = ({
  organisation,
  onSuccess = noop
}) => (dispatch, getState) => {
  const state = getState();

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.USER,
      endpoint: "/organisations/personal",
      types: [
        types.PERSONAL_ORGANISATION_CREATE_REQUEST,
        types.PERSONAL_ORGANISATION_CREATE_REQUEST_SUCCESS,
        types.PERSONAL_ORGANISATION_CREATE_REQUEST_FAILURE
      ],
      schema: schemas.PERSONAL_ORG_CREATION,
      request: {
        body: {
          ...organisation,
          couponCode: getCoupon(state.router),
          campaignReference: getCampaignReference(state.router)
        }
      },
      onSuccess: response => {
        dispatch(
          fetchUserToken({
            ...organisation,
            onSuccess: () => {
              reportSignupEvent();
              onSuccess();
            }
          })
        );
      }
    }
  });
};

export const onCreateOrganisation = ({ organisation, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const {
    ui: {
      teamSignup: {
        personalOrganisation: { country },
        user
      }
    },
    router
  } = getState();

  dispatch({
    [CALL_API]: {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      service: SERVICES.USER,
      endpoint: "/organisations",
      types: [
        types.ORGANISATION_CREATE_REQUEST,
        types.ORGANISATION_CREATE_REQUEST_SUCCESS,
        types.ORGANISATION_CREATE_REQUEST_FAILURE
      ],
      schema: schemas.ORGANISATION,
      request: {
        body: {
          country: country,
          userId: user.id,
          ...organisation,
          couponCode: getCoupon(router),
          campaignReference: getCampaignReference(router)
        }
      },
      onSuccess: response => {
        dispatch(switchToken({ onSuccess: () => onSuccess(response.ids) }));
      }
    }
  });
};

export const onCreateOrganisationAndInviteToTeam = ({ organisation }) => (
  dispatch,
  getState
) => {
  Referrer.set(PATHS.catalogueAll);

  dispatch(
    onCreateOrganisation({
      organisation,
      onSuccess: () =>
        dispatch(inviteToTeamAndRedirect({ redirectTo: PATHS.catalogueAll }))
    })
  );
};

export const onCreatePersonalOrganisationAndInviteToTeam = ({
  organisation
}) => (dispatch, getState) => {
  dispatch(
    onCreatePersonalOrganisation({
      organisation,
      onSuccess: () =>
        dispatch(inviteToTeamAndRedirect({ redirectTo: "/designs" }))
    })
  );
};

export const onCreatePersonalOrganisationWithToken = ({
  organisation,
  redirectTo,
  token
}) => (dispatch, getState) => {
  const { router } = getState();
  const cid = getCampaignReference(router);
  if (token) {
    dispatch(
      onCreatePersonalOrganisation({
        organisation,
        onSuccess: () =>
          dispatch(moveTo(`/invitations/${token}${cid ? `?cid=${cid}` : ""}`))
      })
    );
  } else {
    const onSuccess = redirectTo
      ? () => dispatch(moveTo(`${redirectTo}${cid ? `?cid=${cid}` : ""}`))
      : noop;

    dispatch(
      onCreatePersonalOrganisation({
        organisation,
        onSuccess
      })
    );
  }
};
