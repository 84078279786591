import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CheckeredBackgroundIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color = easilGrayXDark,
  color2 = easilGrayXDark,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <defs>
      <rect id="a" width="18" height="18" rx="2" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0 0h24v24H0z" />
      <g transform="translate(3 3)">
        <mask id="b" fill="#fff">
          <use href="#a" />
        </mask>
        <use fill="#FFF" stroke={color} strokeWidth="2" href="#a" />
        <path
          fill="#FFF"
          stroke={color2 || color}
          d="M-3-3h24v24H-3z"
          mask="url(#b)"
        />
        <path
          fill={color}
          fillRule="nonzero"
          d="M2-1h14a3 3 0 013 3v14a3 3 0 01-3 3H2a3 3 0 01-3-3V2A3 3 0 011.824-.995L2-1zm0 2a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1V2a1 1 0 00-1-1H2z"
          mask="url(#b)"
        />
      </g>
      <path
        d="M16 17.5v2h-2v-2h2zm-6 0v2H8v-2h2zm3-3.3v2h-2v-2h2zm6.5 0v2h-2v-2h2zM16.2 11v2h-2v-2h2zm-6.4 0v2h-2v-2h2zm9.7-3.2v2h-2v-2h2zm-13 0v2h-2v-2h2zm6.5 0v2h-2v-2h2zm3-3.3v2h-2v-2h2zm-6 0v2H8v-2h2zm-5.5 9.7h2v2h-2z"
        fill={color}
        stroke={color}
      />
    </g>
  </svg>
);
CheckeredBackgroundIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CheckeredBackgroundIcon;
