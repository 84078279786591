import { combineReducers } from "redux";

import { approvedApiReducers as approved } from "./approved";
import { approvedSharedApiReducers as approvedShared } from "./approvedShared";
import { pendingApprovalApiReducers as pendingApproval } from "./pendingApproval";
import { declinedApprovalApiReducers as declined } from "./declined";
import { pendingApprovalSharedApiReducers as pendingApprovalShared } from "./pendingApprovalShared";
import { declinedApprovalSharedApiReducers as declinedShared } from "./declinedShared";

const approvalsApiReducers = combineReducers({
  approved,
  approvedShared,
  pendingApproval,
  pendingApprovalShared,
  declined,
  declinedShared
});

export default approvalsApiReducers;
