import { getPath } from "lib";

export const templatesApiSelector = state =>
  getPath(state, "api.designs.purchased.templates.all", {});

/* checks for purchased templates but assumes there are none until data is fetched */
export const isAnyPurchasedTemplates = state => {
  const templatePages = templatesApiSelector(state).pages;
  // will assume false until ids are loaded
  return (
    templatePages &&
    Object.values(templatePages).length > 0 &&
    templatePages[1].ids &&
    templatePages[1].ids.length > 0
  );
};

/* checks for purchased templates but assumes there are some until data is fetched */
export const isAnyPurchasedTemplatesAfterFetch = state => {
  const templatePages = templatesApiSelector(state).pages;

  if (
    !templatePages ||
    Object.values(templatePages).length <= 0 ||
    !templatePages[1].ids
  ) {
    // assume true if we don't yet have the data to know for sure
    return true;
  }
  return templatePages[1].ids.length > 0;
};
