import React from "react";
import style from "./style.module.css";
import { Modal, AddImageButton, Button, Input, Avatar } from "views/components";
import { isEmpty } from "lib/lodash";
import { jsonStringEqual } from "lib/equalityUtils";
import CountryFlagsDropdown from "views/components/CountryFlagsDropdown/CountryFlagsDropdown";

class TeamSettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleTeamUpdate = this.handleTeamUpdate.bind(this);
    this.handleAddTeamImage = this.handleAddTeamImage.bind(this);
    this.handleRemoveTeamImage = this.handleRemoveTeamImage.bind(this);
    this.getModalButtons = this.getModalButtons.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      team: this.props.team
    };
  }

  componentDidUpdate(prevProps) {
    if (
      isEmpty(this.state.team) ||
      !jsonStringEqual(this.props.team, prevProps.team)
    ) {
      this.setState({ team: this.props.team });
    }
  }

  handleTeamUpdate(attribute, value) {
    this.setState({
      team: {
        ...this.state.team,
        [attribute]: value
      }
    });
  }

  handleAddTeamImage(fileObject) {
    const { team } = this.state;
    // generate blob to render as placeholder for team logo
    const fileURL = URL.createObjectURL(fileObject);
    const teamObject = Object.assign({}, team, {
      logo: fileObject,
      logoUrl: fileURL
    });

    this.setState({
      team: teamObject
    });
  }

  handleRemoveTeamImage() {
    const { team } = this.state;
    const teamObject = Object.assign({}, team, { logo: null, logoUrl: null });

    this.setState({ team: teamObject });
  }

  async handleTeamSave() {
    const { team } = this.state;
    const teamObject = Object.assign({}, team);

    if (!team.logo) teamObject.logo = "NULL";
    delete teamObject.logoUrl;

    this.props.updateTeam({ teamId: team.id, team: teamObject });
  }

  getModalButtons() {
    const { team } = this.state;

    return [
      {
        name: "Cancel",
        theme: "gray",
        onClick: this.handleClose
      },
      {
        name: "Save Changes",
        theme: "blueSolid",
        onClick: () => {
          this.handleTeamSave({ teamId: team.id, team });
          this.props.onCloseModal();
        }
      }
    ];
  }

  handleClose() {
    this.setState({
      team: null
    });
    this.props.onCloseModal();
  }

  render() {
    const { team } = this.state;
    const buttons = this.getModalButtons();

    if (isEmpty(team)) return null;

    return (
      <Modal
        className={style.teamSettingsModal}
        headerTitleClassName={style.teamSettingsTitle}
        isOpen={this.props.isOpen}
        onRequestClose={this.handleClose}
        buttons={buttons}
        header={"Team Settings"}
      >
        <div className={style.teamSettingsModalWrapper}>
          <div className={style.teamLogoWrapper}>
            <div className={style.sectionTitle}>Team Logo</div>
            <div className={style.logoWrapper}>
              <div className={style.teamLogo}>
                <Avatar
                  size="92px"
                  theme="dark"
                  src={team.logoUrl}
                  name={team.name}
                  singleCharacterNameInitial={true}
                  charsClassName={style.noAvatarChars}
                />
              </div>
              <div className={style.logoButtons}>
                <AddImageButton
                  acceptedFileTypes={["png", "jpg", "jpeg"]}
                  onChange={({ file }) => this.handleAddTeamImage(file)}
                  image={
                    <Button theme="grayBackgroundXDarkGrayFont" isFullWidth>
                      Browse
                    </Button>
                  }
                />
                <Button
                  onClick={this.handleRemoveTeamImage}
                  theme="gray"
                  disabled={!team.logoUrl}
                  isFullWidth
                >
                  Remove
                </Button>
              </div>
            </div>
          </div>
          <div className={style.teamDropdownsWrapper}>
            <div className={style.teamName}>
              <div className={style.sectionTitle}>Team Name</div>
              <Input
                value={team.name}
                onChange={e => this.handleTeamUpdate("name", e.target.value)}
                className={style.input}
                placeholder={team.name}
              />
            </div>
            <div className={style.teamCountry}>
              <div className={style.sectionTitle}>Country</div>
              <CountryFlagsDropdown
                selectedCountry={team.country}
                onChange={selectedOption =>
                  this.handleTeamUpdate("country", selectedOption)
                }
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default TeamSettingsModal;
