import React from "react";
import { easilGrayXDark } from "./style.module.css";

const PencilDottedStyleIcon = ({
  noPointer,
  className,
  color,
  width,
  height,
  size,
  title,
  style
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="M16 15a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2"
          stroke={color || easilGrayXDark}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-dasharray="3"
        />
        <path
          d="M14.197 3.019c.39-.39.903-.586 1.415-.586.511 0 1.023.195 1.414.586.38.38.571.88.571 1.38 0 .496-.189.993-.566 1.374l-6.034 5.838a2 2 0 0 1-1.097.541l-1.93.286-.096-2.577a2 2 0 0 1 .557-1.076zM13 4l3 3M8 4h1M16 12v-1"
          stroke="#AFB3B6"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default PencilDottedStyleIcon;
