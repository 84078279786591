import React, { Component } from "react";
import SubHeader from "./SubHeader";
import { getPathToCatalogueBasedOnTeamSettings } from "lib/pathing";
import { currentTeamSelector } from "state/entities/teams/teamsSelectors";

import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class SubHeaderContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { children } = this.props;
    const currentUrl = window.location.pathname;

    return (
      <SubHeader {...this.props} currentUrl={currentUrl}>
        {children}
      </SubHeader>
    );
  }
}

const mapStateToProps = state => {
  const pathToCatalogue = getPathToCatalogueBasedOnTeamSettings(state);
  const team = currentTeamSelector(state);

  return {
    pathToCatalogue,
    team
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps)
);

SubHeaderContainer.displayName = "SubHeaderContainer";

export default enhance(SubHeaderContainer);
