import React from "react";

import style from "./style.module.css";

import { SubscriptionsPageSubHeaderTabs } from "./SubscriptionsPageSubHeaderTabs/SubscriptionsPageSubHeaderTabs";

export const SubscriptionsPageSubHeader = () => (
  <div className={style.subHeaderWrapper}>
    <SubscriptionsPageSubHeaderTabs />
  </div>
);

SubscriptionsPageSubHeader.displayName = "SubscriptionsPageSubHeader";

export default SubscriptionsPageSubHeader;
