export const inviteToTeamFormErrors = {
  email: {
    notFound: "already is part of this team."
  }
};

export const errors = {
  presence: "Must be present.",
  invalidEmail: "Please enter a valid email address.",
  invalidPasswordLength: "Minimum 8 characters.",
  invalidPasswordCombination: "Must be stronger.",
  invalidCountry: "It is not a valid country.",
  selectAtLeastOne: "Select at least one.",
  fortyPlusChars: "Maximum of 40 chars.",
  inviteToTeamForm: inviteToTeamFormErrors
};

const messages = {
  errors
};

export default messages;
