import React from "react";
import BrandKitColorOps from "views/components/BrandKit/BrandKitColors/BrandKitColorOps";
import BrandKitSearchInput from "views/components/BrandKit/BrandKitSearch/BrandKitSearchInput";
import BrandKitSearchTextButton from "views/components/BrandKit/BrandKitSearch/BrandKitSearchTextButton";
import PlusIcon from "views/components/icons/PlusIcon";

export class BrandKitColorSearch extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddBrandColorGroup = BrandKitColorOps.handleAddBrandColorGroup.bind(
      this
    );
    this.handleSearchInput = BrandKitColorOps.handleSearchInput.bind(this);
  }

  render() {
    const { isBrandManager, colors, location } = this.props;

    const urlParams = new URLSearchParams(location.search);
    const urlTerm = urlParams.get("term");
    const colorArr = BrandKitColorOps.getColorArr(urlTerm, colors);

    return (
      <>
        <BrandKitSearchInput
          onChange={value => this.handleSearchInput(value)}
          onClear={() => this.handleSearchInput(null)}
          placeholder="Search for Brand Colors by name"
          value={urlTerm || ""}
        />

        {isBrandManager && (
          <BrandKitSearchTextButton
            Icon={() => <PlusIcon color="#3184fc" size="18px" />}
            onClick={() => this.handleAddBrandColorGroup()}
            title={
              urlTerm === null && colorArr.length === 0
                ? "Add Palette"
                : "New Palette"
            }
          />
        )}
      </>
    );
  }
}

export default BrandKitColorSearch;
