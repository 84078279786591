import * as types from "./creditsApiTypes";
import { CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS } from "state/currentUser/currentUserTypes";

export const initState = {
  isFetching: false
};

const creditsApiReducers = (state = initState, action) => {
  switch (action.type) {
    // Team Switch Action
    case CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS: {
      // Clear the state to init when changing teams
      return { ...initState };
    }

    // Remove Background Credit Action
    case types.CREDITS_REQUEST: {
      // indicate that fetching is happening
      return {
        ...state,
        isFetching: true,
        lastFetched: Date.now()
      };
    }
    case types.CREDITS_REQUEST_FAILURE:
    case types.CREDITS_REQUEST_SUCCESS: {
      // indicate that fetching is no longer occuring
      return {
        ...state,
        isFetching: false,
        lastFetched: Date.now()
      };
    }

    default:
      return state;
  }
};

export default creditsApiReducers;
