import React, { Component } from "react";
import TableCell from "./TableCell/TableCell";
import style from "./style.module.css";
import { getEmptyImage } from "react-dnd-html5-backend";
import { identity, noop } from "lib/lodash";
import TableRowDropzones from "./TableRowDropZones";

class TableRow extends Component {
  static defaultProps = {
    connectDropTarget: identity,
    connectDragSource: identity,
    connectDragPreview: noop,
    DropzoneElement: TableRowDropzones
  };

  componentDidMount() {
    const { connectDragPreview } = this.props;

    connectDragPreview(getEmptyImage(), {
      captureDraggingState: true
    });
  }

  render() {
    const {
      row,
      index: rowIndex,
      rowMetadata,
      connectDragSource,
      isOnlyElementSelected,
      onRowMove,
      onTableOver,
      onPersistRowMove,
      DropzoneElement,
      isDragging,
      isDroppable = true,
      isHidden,
      tableId
    } = this.props;

    const { cells } = row;

    const getColumnMetadata = index => rowMetadata.cellsMetadata[index];

    const totalWidthUnits = rowMetadata.cellsMetadata.reduce(
      (totalWidth, column) => totalWidth + column.width,
      0
    );

    const getWidthInPercentage = index =>
      (getColumnMetadata(index).width / totalWidthUnits) * 100;

    // If the table is the only element selected we want to make the rows draggable,
    // otherwise not.
    const wrapper = isOnlyElementSelected ? connectDragSource : identity;
    return wrapper(
      <div
        className={style.tableRow}
        style={{
          pointerEvents: isOnlyElementSelected ? "auto" : "none",
          opacity: isHidden ? "0" : "1"
        }}
      >
        {// only put the dropzone if this row is not currently dragging or it will cancel onDrag events
        isDroppable && (
          <DropzoneElement
            rowIndex={rowIndex}
            onRowMove={onRowMove}
            onTableOver={onTableOver}
            onPersistRowMove={onPersistRowMove}
            isDragging={isDragging}
          />
        )}
        {cells.map((cell, index) => (
          <TableCell
            key={cell.id}
            textFields={cell.textFields}
            rowIndex={rowIndex}
            index={index}
            id={cell.id}
            columnMetadata={getColumnMetadata(index)}
            widthInPercentage={getWidthInPercentage(index)}
            tableId={tableId}
          />
        ))}
      </div>
    );
  }
}

export default TableRow;
