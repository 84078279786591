import { createSelector } from "reselect";

export const userTeamImagesApiSelector = state => state.api.userTeamImages;

export const processUserTeamImagesIsFetchingSelector = userTeamImagesApi => {
  if (!userTeamImagesApi || !userTeamImagesApi.pages) return true;
  const { pages } = userTeamImagesApi;
  return Object.values(pages).some(page => page.isFetching); // true if at least one page is fetching
};

export const userTeamImagesIsFetchingSelector = createSelector(
  [userTeamImagesApiSelector],
  processUserTeamImagesIsFetchingSelector
);

const processUserTeamImagesPageCount = userTeamImagesApi => {
  if (!userTeamImagesApi || !userTeamImagesApi.pages) return 0;
  return Object.keys(userTeamImagesApi.pages).length;
};

export const userTeamImagesPageCount = createSelector(
  [userTeamImagesApiSelector],
  processUserTeamImagesPageCount
);

export const userTeamImagesFirstPageIsFetching = state => {
  if (
    !state.api.userTeamImages ||
    !state.api.userTeamImages.pages ||
    !state.api.userTeamImages.pages[1]
  )
    return true;
  return state.api.userTeamImages.pages[1].isFetching;
};
