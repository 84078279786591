import Subscriptions from "lib/subscriptions";
import { MEDIA_SORT_OPTIONS } from "lib/constants";
import getFileExtension from "lib/getFileExtension";
import { clickClosestAddFileInput } from "lib/input/mouse";
import { getGifUploadData } from "lib/imageHelpers";

const TEAM_FOLDER_TYPE = "BRAND_IMAGE";

const BrandKitImageOps = {
  setQueryString(params) {
    const { modifyQuery } = this.props;
    const encodedFolderId = encodeURIComponent((params || {}).folderId || "");
    const encodedTerm = encodeURIComponent((params || {}).term || "");

    if (encodedFolderId) {
      modifyQuery({ appendTerms: { folderId: encodedFolderId } });
    } else if (encodedTerm) {
      modifyQuery({ appendTerms: { term: encodedTerm } });
    } else {
      modifyQuery({ dropTerms: ["term"] });
    }
  },

  handleSortUpdate(value) {
    this.props.setBrandKitState(
      {
        sortKey: value
      },
      "images"
    );
  },

  async handleAddImage(fileObject, onSuccess) {
    const { qsFolderId, qsTerm, sortKey } = this.props;
    let mediaResp = {};
    const sortOption = MEDIA_SORT_OPTIONS[sortKey];

    const fileExtension = getFileExtension(fileObject.name);

    if (fileExtension === "gif") {
      const gifData = await getGifUploadData(fileObject);
      mediaResp = await this.props.addBrandKitImage({
        teamId: this.props.currentTeam.id,
        file: fileObject,
        onSuccess,
        duration: gifData.duration,
        placeholderURL: window.URL.createObjectURL(fileObject)
      });
    } else {
      mediaResp = await this.props.addBrandKitImage({
        teamId: this.props.currentTeam.id,
        file: fileObject,
        onSuccess,
        placeholderURL: window.URL.createObjectURL(fileObject)
      });
    }

    if (qsTerm) {
      this.setQueryString(null);
    } else if ((mediaResp || {}).mediaId && qsFolderId) {
      await this.setState({ isProcessing: true });

      await this.props.addContentToTeamFolder({
        contentId: mediaResp.mediaId,
        folderId: qsFolderId,
        teamId: this.props.currentTeam.id,
        type: TEAM_FOLDER_TYPE,
        isFolderUpload: true
      });

      await this.props.searchBrandKitImages({
        folderId: qsFolderId,
        term: qsTerm,
        sortBy: sortOption.sortKey,
        sortOrder: sortOption.direction
      });

      await this.setState({ isProcessing: false });
    }
  },

  onAddImageButtonClick(event) {
    const subscription = Subscriptions.get(this.props.currentSubscriptionCode);
    event.target.value = null;
    event.stopPropagation();
    if (!subscription.getCanAccessBrandKit()) {
      event.preventDefault();

      this.props.showUpgradeModal();
    } else {
      clickClosestAddFileInput(event.target);
    }
  }
};

export default BrandKitImageOps;
