import React from "react";
import { Button } from "views/components";
import ChangePasswordModalContainer from "../";

class ChangePasswordButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.state = {
      isModalOpen: false
    };
  }

  handleOpenModal() {
    const { isModalOpen } = this.state;
    if (!isModalOpen) {
      this.setState({
        isModalOpen: true
      });
    }
  }

  handleCloseModal() {
    const { isModalOpen } = this.state;
    if (isModalOpen) {
      this.setState({
        isModalOpen: false
      });
    }
  }

  render() {
    const { className } = this.props;
    const { isModalOpen } = this.state;
    return (
      <div>
        <Button
          className={className}
          theme="blueSolid"
          onClick={this.handleOpenModal}
        >
          Change Password
        </Button>
        <ChangePasswordModalContainer
          isOpen={isModalOpen}
          onClose={this.handleCloseModal}
        />
      </div>
    );
  }
}
export default ChangePasswordButton;
