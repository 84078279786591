import React from "react";
import { connect } from "react-redux";
import BuyCollectionModal from "./BuyCollectionModal";
import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import {
  purchaseCollectionTokenSelector,
  purchaseCollectionPaymentTypeSelector,
  purchaseCollectionLoadingSelector,
  purchaseCollectionPollingErrorSelector,
  purchaseCollectionErrorsSelector,
  currentOrderIdSelector
} from "state/ui/purchaseCollectionModal/purchaseCollectionModalSelectors";
import {
  purchaseCollection,
  createCouponCollectionOrder
} from "state/ui/purchaseCollectionModal/purchaseCollectionModalActions";
import { cancelOrder } from "state/entities/orders/ordersActions";
import {
  getCouponByOrderIdSelector,
  orderByIdSelector
} from "state/entities/orders/ordersSelectors";
import { setCouponError } from "state/ui/cart/cartActions";

export class BuyCollectionModalContainer extends React.Component {
  render() {
    return <BuyCollectionModal {...this.props} />;
  }
}

BuyCollectionModalContainer.displayName = "BuyCollectionModalContainer";

const mapStateToProps = (state, ownProps) => {
  const currentPlan = currentSubscriptionPlan(state);
  const token = purchaseCollectionTokenSelector(state);
  const paymentType = purchaseCollectionPaymentTypeSelector(state);
  const loading = purchaseCollectionLoadingSelector(state);
  const pollingError = purchaseCollectionPollingErrorSelector(state);
  const errors = purchaseCollectionErrorsSelector(state);
  const couponErrors = state.ui.cart.couponErrors;
  const orderId = currentOrderIdSelector(state);
  const appliedCouponCode = getCouponByOrderIdSelector({
    state,
    orderId
  });
  const currentOrder = orderByIdSelector({ state, orderId });

  return {
    appliedCouponCode,
    couponErrors,
    currentOrder,
    currentPlan,
    errors,
    loading,
    orderId,
    paymentType,
    pollingError,
    token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    purchaseCollection: args => dispatch(purchaseCollection(args)),
    cancelPurchaseCollectionOrder: args => dispatch(cancelOrder(args)),
    createCouponCollectionOrder: args =>
      dispatch(createCouponCollectionOrder(args)),
    handleResetCouponError: () => dispatch(setCouponError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyCollectionModalContainer);
