import { createSelector } from "reselect";

// retrieves a page from the default "all" parentId
export const teamsApiPageSelector = (state, page) => {
  return state.api.teams.parentIds.all.pages[page];
};

// retrieves a page for the given parentId
export const teamsApiPageForParentIdSelector = ({
  state,
  page = 1,
  parentId
}) => {
  const parentIdState = state.api.teams.parentIds[parentId];
  if (!parentIdState) return {};
  return parentIdState.pages[page];
};

export const teamsApiSelector = state => state.api.teams;

export const teamsApiDetailedIdSelector = (state, id) => {
  return state.api.teams.detailedIds[id];
};

const teamsApiParentIdsSelector = ({ state }) => state.api.teams.parentIds;

const processAllTeamsIdsSelector = teamsApiParentIds => {
  const allTeamsIds = Object.values(teamsApiParentIds).reduce(
    (ids, teamApiForParentId) => {
      const newPages = Object.values(teamApiForParentId.pages);
      const newIds = newPages.reduce(
        (idsForParentId, page) => idsForParentId.concat(page.ids || []),
        []
      );
      return ids.concat(newIds);
    },
    []
  );
  return allTeamsIds;
};

// retrieves every team id from every parentId in state.api.teams
export const allTeamsIdsSelector = createSelector(
  [teamsApiParentIdsSelector],
  processAllTeamsIdsSelector
);
