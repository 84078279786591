import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FontFamilyIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 18 12"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-4h20v20H-1z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M2.87 9l-.91 2.282a1 1 0 1 1-1.85-.74L4.05.69a.993.993 0 0 1 .047-.12L4.12.51l.006.003a1 1 0 0 1 1.851.279l3.886 9.704a.994.994 0 0 1 .11.273l.002.006a1 1 0 0 1-1.932.518L7.129 9H2.87zm.799-2H6.33L5 3.664 3.668 7z"
      />
      <path
        fill={color || easilGrayXDark}
        d="M11.5 4.02c.206-.44.511-.902.814-1.145.513-.413 1.25-.62 2.21-.62.856 0 1.51.12 1.96.36.452.24.774.567.967.98.194.413.29 1.165.29 2.255l-.017 2.883c0 .762.155 1.613.227 2.04A1.003 1.003 0 0 1 16.977 12a1 1 0 0 1-.991-.867c-.02-.082-.044-.2-.073-.353-.346.41-.716.718-1.112.923a2.702 2.702 0 0 1-1.261.308c-.744 0-1.353-.25-1.828-.752-.475-.5-.712-1.167-.712-2 0-.526.097-.986.29-1.379a2.36 2.36 0 0 1 .817-.94c.352-.235.914-.443 1.688-.624.937-.217 1.59-.42 1.96-.607 0-.515-.037-.854-.11-1.015a.854.854 0 0 0-.404-.395c-.197-.103-.473-.154-.83-.154a1.25 1.25 0 0 0-.835.298c-.098.082-.194.3-.276.447a1 1 0 1 1-1.8-.87zm4.255 3.298c-.258.105-.66.228-1.204.369-.627.164-1.037.345-1.23.545-.194.199-.29.457-.29.773 0 .357.106.658.32.9.214.244.482.366.804.366.287 0 .572-.098.853-.295.281-.196.476-.429.584-.698.109-.27.163-.76.163-1.468v-.492z"
      />
    </g>
  </svg>
);
FontFamilyIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FontFamilyIcon;
