import { Component } from "react";
import UserPlusIcon from "views/components/icons/UserPlusIcon";
import InviteToTeamModal from "views/components/inviteToTeamModal";
import style from "./style.module.css";

export class TeamCreateButton extends Component {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.state = {
      isModalOpen: false
    };
  }

  handleOpenModal() {
    this.setState({
      isModalOpen: true
    });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false
    });
  }

  render() {
    const {
      currentTeam,
      currentPlan,
      currentTeamSubscription,
      inviteToTeamFormState,
      onInviteToTeam,
      usersTotalCount
    } = this.props;

    return (
      <div data-testid="AddTeamMembersButton">
        <div
          className={style.addTeamMembersButtonWrapper}
          onClick={this.handleOpenModal}
        >
          <UserPlusIcon size="24px" color={style.easilBlue} />
          <div className={style.addTeamMembersButtonTitle}>
            Add Team Members
          </div>
        </div>
        {this.state.isModalOpen && (
          <InviteToTeamModal
            currentTeamName={currentTeam.name}
            currentTeamSubscription={currentTeamSubscription}
            isModalOpen={this.state.isModalOpen}
            onCloseModal={this.handleCloseModal}
            inviteToTeamFormState={inviteToTeamFormState}
            onInviteToTeam={() => {
              onInviteToTeam();
              this.handleCloseModal();
            }}
            usersTotalCount={usersTotalCount}
            currentPlan={currentPlan}
            displayCostInformation={true}
          />
        )}
      </div>
    );
  }
}

export default TeamCreateButton;
