import React from "react";

export default function ColorIcon({ color = "#2C2E2F", ...props }) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <mask id="b" fill="#fff">
          <circle cx="12" cy="12" r="10" />
        </mask>
        <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
        <path
          fill="#2C2E2F"
          fillOpacity=".25"
          d="M12 2h10v10H12z"
          mask="url(#b)"
        />
        <path fill="#2C2E2F" d="M12 12h10v10H12z" mask="url(#b)" opacity=".5" />
        <path
          fill="#2C2E2F"
          fillOpacity=".7"
          d="M2 12h10v10H2z"
          mask="url(#b)"
        />
      </g>
    </svg>
  );
}
