import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const LineHeightIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <g transform="translate(0 -4)">
        <path d="M0 4h16v16H0z" />
        <rect
          width="16"
          height="1.8"
          y="11"
          fill={color || easilGrayXDark}
          rx=".9"
        />
        <path
          fill={color || easilGrayXDark}
          d="M8.048 6.038l1.701-1.702a.9.9 0 0 1 1.273 1.273L8.759 7.872a.903.903 0 0 1-.156.125.9.9 0 0 1-1.204-.062L5.136 5.672A.9.9 0 1 1 6.41 4.399l1.639 1.639zM8.104 17.977L9.8 19.672a.9.9 0 1 0 1.273-1.273l-2.263-2.263a.898.898 0 0 0-.705-.26.898.898 0 0 0-.705.26L5.136 18.4a.9.9 0 1 0 1.273 1.273l1.695-1.695z"
        />
      </g>
    </g>
  </svg>
);
LineHeightIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default LineHeightIcon;
