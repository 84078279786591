import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";

const sizeInitState = {
  pages: {}
};

export const initState = {
  sizes: {
    all: sizeInitState
    /* other sizes will populate this list, on demand as we don't know
     *  what sizes the user has */
  },
  pageSize: 50
};

const approvedSharedApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_APPROVED_SHARED_REQUEST: {
      const size = action.request.params.size || "all";

      const sizeState = state.sizes[size] || sizeInitState;

      const newSizeState = handleDesignRequest({ state: sizeState, action });

      return {
        ...state,
        sizes: {
          ...state.sizes,
          [size]: newSizeState
        }
      };
    }

    case designsEntitiesTypes.DESIGNS_APPROVED_SHARED_REQUEST_SUCCESS: {
      const size = action.request.params.size || "all";

      const sizeState = state.sizes[size] || sizeInitState;

      const newSizeState = handleDesignRequestSuccess({
        state: sizeState,
        action
      });

      return {
        ...state,
        sizes: {
          ...state.sizes,
          [size]: newSizeState
        }
      };
    }

    default:
      return state;
  }
};

export default approvedSharedApiReducers;
