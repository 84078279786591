import { Component } from "react";
import BreadCrumbList from "views/components/BreadCrumb/BreadCrumbList";
import { SECTION_TYPES } from "../teamCreateModalUtils";
import { InputTransparent } from "views/components";
import DefaultTeamToggle from "views/containers/teamPageTopRow/CreateTeamModal/DefaultTeamToggle/DefaultTeamToggle";
import commonStyle from "../style.module.css";
import teamSectionStyle from "../teamSectionStyles.module.css";

export class SubTeamSection extends Component {
  render() {
    const crumbPath = [
      {
        text: "Add New Team",
        onClick: () => {
          this.props.onSectionTransition(SECTION_TYPES.SELECTION);
        }
      },
      {
        text: "Sub-Team"
      }
    ];
    return (
      <div className={teamSectionStyle.wrapper}>
        <div className={teamSectionStyle.breadCrumb}>
          <BreadCrumbList
            path={crumbPath}
            wrapperClassName={teamSectionStyle.breadCrumbList}
          />
        </div>
        <div className={commonStyle.title}>Add a new Sub-Team</div>
        <div className={teamSectionStyle.nameSection}>
          <div className={teamSectionStyle.nameLabel}>Team Name</div>
          <div className={teamSectionStyle.inputWrapper}>
            <InputTransparent
              value={this.props.newTeamName}
              onChange={this.props.setNewTeamName}
              className={
                !!this.props.newTeamName
                  ? teamSectionStyle.input
                  : teamSectionStyle.inputPlaceholder
              }
              placeholder={"Example: 'Marketing' or 'Design'"}
            />
          </div>
        </div>
        <div className={teamSectionStyle.noticeText}>
          Your new team will be created underneath{" "}
          <span>{this.props.currentTeam.name}</span>.
        </div>
        <div className={teamSectionStyle.divider} />
        <DefaultTeamToggle
          onToggleMakeTeamDefault={this.props.setMakeDefaultTeam}
          className={teamSectionStyle.teamToggle}
          textOverride={
            "Each time you log in to Easil, we'll place you in this team."
          }
        />
      </div>
    );
  }
}

export default SubTeamSection;
