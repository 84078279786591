export const ALL_USER_TEAM_ANIMATIONS_REQUEST =
  "API/ENTITIES/ALL_USER_TEAM_ANIMATIONS_REQUEST";
export const ALL_USER_TEAM_ANIMATIONS_REQUEST_FAILURE =
  "API/ENTITIES/ALL_USER_TEAM_ANIMATIONS_REQUEST_FAILURE";
export const ALL_USER_TEAM_ANIMATIONS_REQUEST_SUCCESS =
  "API/ENTITIES/ALL_USER_TEAM_ANIMATIONS_REQUEST_SUCCESS";

export const USER_TEAM_ANIMATIONS_REQUEST =
  "API/ENTITIES/USER_TEAM_ANIMATIONS_REQUEST";
export const USER_TEAM_ANIMATIONS_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_ANIMATIONS_REQUEST_FAILURE";
export const USER_TEAM_ANIMATIONS_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_ANIMATIONS_REQUEST_SUCCESS";

export const USER_TEAM_VIDEOS_REQUEST = "API/ENTITIES/USER_TEAM_VIDEOS_REQUEST";
export const USER_TEAM_VIDEOS_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_VIDEOS_REQUEST_FAILURE";
export const USER_TEAM_VIDEOS_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_VIDEOS_REQUEST_SUCCESS";

export const USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST =
  "API/ENTITIES/USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST";
export const USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST_FAILURE";
export const USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_ANIMATIONS_IN_FOLDER_REQUEST_SUCCESS";

export const USER_TEAM_ANIMATIONS_SEARCH_REQUEST =
  "API/ENTITIES/USER_TEAM_ANIMATIONS_SEARCH_REQUEST";
export const USER_TEAM_ANIMATIONS_SEARCH_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_ANIMATIONS_SEARCH_REQUEST_FAILURE";
export const USER_TEAM_ANIMATIONS_SEARCH_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_ANIMATIONS_SEARCH_REQUEST_SUCCESS";

export const USER_TEAM_ANIMATION_DELETE_REQUEST =
  "API/ENTITIES/USER_TEAM_ANIMATION_DELETE_REQUEST";
export const USER_TEAM_ANIMATION_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_ANIMATION_DELETE_REQUEST_FAILURE";
export const USER_TEAM_ANIMATION_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_ANIMATION_DELETE_REQUEST_SUCCESS";

export const USER_TEAM_ANIMATION_UPDATE_REQUEST =
  "API/ENTITIES/USER_TEAM_ANIMATION_UPDATE_REQUEST";
export const USER_TEAM_ANIMATION_UPDATE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_ANIMATION_UPDATE_REQUEST_FAILURE";
export const USER_TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_ANIMATION_UPDATE_REQUEST_SUCCESS";

export const USER_TEAM_ANIMATION_UPLOAD_REQUEST =
  "API/ENTITIES/USER_TEAM_ANIMATION_UPLOAD_REQUEST";
export const USER_TEAM_ANIMATION_UPLOAD_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_ANIMATION_UPLOAD_REQUEST_FAILURE";
export const USER_TEAM_ANIMATION_UPLOAD_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_ANIMATION_UPLOAD_REQUEST_SUCCESS";

export const USER_ANIMATION_UPLOAD_URL_REQUEST =
  "API/ENTITIES/USER_ANIMATION_UPLOAD_URL_REQUEST";
export const USER_ANIMATION_UPLOAD_URL_REQUEST_FAILURE =
  "API/ENTITIES/USER_ANIMATION_UPLOAD_URL_REQUEST_FAILURE";
export const USER_ANIMATION_UPLOAD_URL_REQUEST_SUCCESS =
  "API/ENTITIES/USER_ANIMATION_UPLOAD_URL_REQUEST_SUCCESS";

export const ADD_USER_TEAM_ANIMATIONS_PLACEHOLDERS =
  "API/ENTITIES/ADD_USER_TEAM_ANIMATIONS_PLACEHOLDERS";

export const CLEAR_USER_TEAM_ANIMATION_UPLOAD_ERRORS =
  "API/ENTITIES/CLEAR_USER_TEAM_ANIMATION_UPLOAD_ERRORS";

export const USER_TEAM_ANIMATIONS_CLEAR =
  "API/ENITITIES/USER_TEAM_ANIMATIONS_CLEAR";

export const USER_TEAM_VIDEOS_DELETE_REQUEST =
  "API/ENTITIES/USER_TEAM_VIDEOS_DELETE_REQUEST";
export const USER_TEAM_VIDEOS_DELETE_REQUEST_FAILURE =
  "API/ENTITIES/USER_TEAM_VIDEOS_DELETE_REQUEST_FAILURE";
export const USER_TEAM_VIDEOS_DELETE_REQUEST_SUCCESS =
  "API/ENTITIES/USER_TEAM_VIDEOS_DELETE_REQUEST_SUCCESS";
