// import Element from "./Element";
// import TextBoxElement from "./TextBoxElement";
// import TableElement from "./TableElement";
// import GridElement from "./GridElement";
// import VectorElement from "./VectorElement";
// import VectorTextElement from "./VectorTextElement";
// import ImageElement from "./ImageElement";
// import BackgroundElement from "./BackgroundElement";
// import Group from "./Group";
import { DEFAULT_VECTOR_MASK, EDITOR_ELEMENTS_MAP } from "lib/constants";
import { getFontByName } from "lib/fonts";

const BACKGROUND_DEFAULT_PROPS = {};

const ELEMENT_DEFAULT_PROPS = {
  restrictions: [],
  resizableX: false,
  resizableY: false,
  isHidden: false,
  name: "Element",
  angle: 0,
  scaleX: 1,
  scaleY: 1,
  scale: 1,
  opacity: 1
};

const GRID_DEFAULT_PROPS = {
  fonts: [],
  imageInstructions: [], // TODO
  palette: [],
  source: "easil",
  textInstructions: [], // TODO
  type: "grid",
  name: "Grid"
};

const GROUP_DEFAULT_PROPS = {
  type: "group",
  name: "Group",
  elementsOrder: [],
  angle: 0
};

const IMAGE_DEFAULT_PROPS = {
  type: "image",
  source: "easil",
  filters: "64646464646464064",
  name: "Image",
  scale: 1,
  price: 0,
  mask: {
    top: 0,
    left: 0
  }
};

const VIDEO_DEFAULT_PROPS = {
  type: "video",
  source: "easil",
  filters: "64646464646464064",
  name: "Video",
  scale: 1,
  price: 0,
  mask: {
    top: 0,
    left: 0
  }
};

const TABLE_DEFAULT_PROPS = {
  fonts: [],
  type: "table",
  name: "Table",
  resizableX: true
};

const TABLE2_DEFAULT_PROPS = {
  fonts: [],
  type: "table2",
  name: "Table2",
  resizableX: true
};

const robotoRegularFont = getFontByName("Roboto-Regular");
const robotoRegularFontId = robotoRegularFont ? robotoRegularFont.fontId : null;

const TEXTBOX_DEFAULT_PROPS = {
  type: "textbox",
  fontSize: 25,
  lineHeight: 1.4,
  letterSpacing: 0,
  textAlign: "center",
  color: "#000000",
  fontFamily: "Roboto-Regular",
  fontId: robotoRegularFontId,
  value: "Enter Text Here",
  name: "Enter Text Here",
  textDecoration: "none",
  bold: false,
  width: 500,
  height: 25 * 1.4,
  resizableX: true,
  resizableY: true,
  source: "textbox"
};

const VECTOR_DEFAULT_PROPS = {
  imageInstructions: [],
  mask: DEFAULT_VECTOR_MASK
};

const VECTOR_TEXT_DEFAULT_PROPS = {
  imageInstructions: []
};

const QR_CODE_DEFAULT_PROPS = {
  value: "about.easil.com"
};

class ElementDefaultsFactory {
  static getDefaults(type) {
    switch (type) {
      case "background":
        return JSON.parse(JSON.stringify(BACKGROUND_DEFAULT_PROPS));
      case "grid":
        return JSON.parse(JSON.stringify(GRID_DEFAULT_PROPS));
      case "group":
        return JSON.parse(JSON.stringify(GROUP_DEFAULT_PROPS));
      case "image":
        return JSON.parse(JSON.stringify(IMAGE_DEFAULT_PROPS));
      case "video":
        return JSON.parse(JSON.stringify(VIDEO_DEFAULT_PROPS));
      case "table":
        return JSON.parse(JSON.stringify(TABLE_DEFAULT_PROPS));
      case "table2":
        return JSON.parse(JSON.stringify(TABLE2_DEFAULT_PROPS));
      case "textbox":
        return JSON.parse(JSON.stringify(TEXTBOX_DEFAULT_PROPS));
      case "vector":
        return JSON.parse(JSON.stringify(VECTOR_DEFAULT_PROPS));
      case EDITOR_ELEMENTS_MAP.VECTOR_TEXT:
        return JSON.parse(JSON.stringify(VECTOR_TEXT_DEFAULT_PROPS));
      case "qrcode":
        return JSON.parse(JSON.stringify(QR_CODE_DEFAULT_PROPS));
      default:
        return JSON.parse(JSON.stringify(ELEMENT_DEFAULT_PROPS));
    }
  }
}

export default ElementDefaultsFactory;
