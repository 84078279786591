import { createSelector } from "reselect";
import { isNil, uniq } from "lib";

const graphicsApiSelector = ({ state }) => state.api.graphics;
const pagePassThrough = ({ page }) => page;

const processIsFetchingPage = (graphics, page) => {
  /* if the page doesn't exist, we have not started fetching it */
  if (isNil(graphics.pages[page])) {
    return false;
  }

  return graphics.pages[page].isFetching;
};

export const isFetchingPage = createSelector(
  [graphicsApiSelector, pagePassThrough],
  processIsFetchingPage
);

const graphicsEntitiesSelector = ({ state }) => state.entities.graphics;
const graphicsApiPagesSelector = ({ state }) =>
  graphicsApiSelector({ state }).pages;

const processAvailableGraphics = (graphics, graphicsPages) => {
  const availableIds = Object.values(graphicsPages)
    .map(pages => pages.ids)
    .reduce((previous, current) => previous.concat(current), [])
    .filter(x => x);

  // ensure that the ids are all unique
  const uniqueIds = uniq(availableIds);
  // we need to map the graphics entities to the availableIds, filtering will cause order errors as js object key ordering is not to be trusted
  return uniqueIds.map(id => graphics[id]);
};

export const availableGraphics = createSelector(
  [graphicsEntitiesSelector, graphicsApiPagesSelector],
  processAvailableGraphics
);

const processGraphicsFetching = pages => {
  let fetching = false;

  Object.keys(pages)
    .map(id => pages[id])
    .forEach(page => {
      if (page.isFetching) fetching = true;
    });

  return fetching;
};

export const graphicsFetching = createSelector(
  [graphicsApiPagesSelector],
  processGraphicsFetching
);
