import React, { Component } from "react";
import PropTypes from "prop-types";
import { DividerLabel } from "..";
import style from "./style.module.css";
import SearchInput from "../searchInput";
import CountryOption from "../countryOption/CountryOption";

/**
 * Renders options of structure:
 * {
 *   countries: {},
 * }
 */
class CountryOptionsRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.setTextInputRef = element => {
      this.textInput = element;
    };

    this.state = {
      searchFilter: null,
      countries: props.options.countries,
      commonCountries: props.options.commonCountries
    };
  }

  componentDidUpdate() {
    // focus on the search input when opening
    this.textInput.querySelector("input").focus();
  }

  handleSearchChange(filter) {
    const { countries, commonCountries } = this.props.options;

    const filteredCountriesList = this.filterCountryListBySearchTerm(
      countries,
      "label",
      filter
    );

    const filteredCommonCountriesList = this.filterCountryListBySearchTerm(
      commonCountries,
      "label",
      filter
    );

    this.setState({
      searchFilter: filter,
      countries: filteredCountriesList,
      commonCountries: filteredCommonCountriesList
    });
  }

  filterCountryListBySearchTerm(list, field, term) {
    if (!list) return null;
    return list.filter(country =>
      term ? country[field].match(new RegExp(term, "i")) : country
    );
  }

  render() {
    const { onChange, selected } = this.props;
    const { countries, commonCountries, searchFilter } = this.state;

    return (
      <React.Fragment>
        <SearchInput
          className={style.search}
          placeholder="Search Countries"
          onChange={value => this.handleSearchChange(value)}
          setInputRef={this.setTextInputRef}
          clearInput={() => this.handleSearchChange("")}
          value={searchFilter}
        />
        {/*Common Countries*/}
        {commonCountries && commonCountries.length > 0 && (
          <React.Fragment>
            <DividerLabel label="" className={style.countryDivider} />
            {commonCountries.map(commonCountry => (
              <CountryOption
                key={`commonCountry-${commonCountry.label}`}
                country={commonCountry}
                onClick={onChange}
                selected={selected === commonCountry.key}
              />
            ))}
          </React.Fragment>
        )}
        {/*Countries*/}
        {countries && countries.length > 0 && (
          <React.Fragment>
            <DividerLabel label="" className={style.countryDivider} />
            {countries.map(country => (
              <CountryOption
                key={`country-${country.label}`}
                country={country}
                onClick={onChange}
                selected={selected === country.key}
              />
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

CountryOptionsRenderer.propTypes = {
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CountryOptionsRenderer;
