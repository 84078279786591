import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import { getHtmlNodeCenter } from "lib/htmlElements/dimensions";
import { rotatePoint } from "lib/geometry/rotation";
import { EditorContext } from "views/components/Editor/EditorContext";
import style from "../styleSelectionBox.module.css";
import canvasStyle from "../canvas/style.module.css";
import { SnapCoordinatesSystem } from "lib/SnapSystems";
import { getHandlerContactPoint } from "./utils";

const ResizeHandlerSource = {
  beginDrag(props, monitor, component) {
    const { element, hasXHandles, hasYHandles, selectedItems, zoom } = props;
    props.startResizing({ pageId: props.pageId });

    const componentNode = findDOMNode(component);
    const selectionBoxNode = componentNode.parentElement;

    const selectionBoxNodeCenter = getHtmlNodeCenter(selectionBoxNode);
    let handlerContactPoint = getHandlerContactPoint(componentNode);

    // update handle contact points when a single element is below minimum dimensions
    if (element && selectedItems.length === 1) {
      const minElementDimensions = hasXHandles || hasYHandles ? 20 : 16;
      const elBelowMinHeight = element.height * zoom < minElementDimensions;
      const elBelowMinWidth = element.width * zoom < minElementDimensions;
      let yAdjustment = 0,
        xAdjustment = 0;

      if (elBelowMinHeight) {
        // min height means adjustment to the y scale
        yAdjustment = hasXHandles ? 14 : 4;

        if (componentNode.dataset.position.includes("S")) {
          yAdjustment = yAdjustment * -1;
        }
      }

      if (elBelowMinWidth) {
        // min width means adjustment to the x scale
        xAdjustment = hasYHandles ? 14 : 4;

        if (componentNode.dataset.position.includes("E")) {
          xAdjustment = xAdjustment * -1;
        }
      }

      const rotatedPointOffsets = rotatePoint(
        xAdjustment,
        yAdjustment,
        0,
        0,
        Number(element.angle || 0)
      );

      handlerContactPoint.x += rotatedPointOffsets.x;
      handlerContactPoint.y += rotatedPointOffsets.y;
    }

    const anchorPoint = rotatePoint(
      handlerContactPoint.x,
      handlerContactPoint.y,
      selectionBoxNodeCenter.x,
      selectionBoxNodeCenter.y,
      180
    );

    const snapCoordinatesSystem = SnapCoordinatesSystem.Builder({
      pageId: props.pageId,
      excludeIds: props.selectedItems.map(item => item.itemId)
    });

    return {
      origin: selectionBoxNodeCenter,
      snapSystem: snapCoordinatesSystem,
      handlerInitialPosition: handlerContactPoint,
      handlerCoordinateName: props.position,
      anchorPoint: anchorPoint,
      canvasPositionAndDimensions: selectionBoxNode
        .closest(`.${canvasStyle.canvas}`)
        .getBoundingClientRect(),
      element: props.element,
      hasXHandles: props.hasXHandles,
      hasYHandles: props.hasYHandles
    };
  },

  endDrag(props, monitor, component) {
    const { zoom } = props;
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();
    const { anchorPoint, canvasPositionAndDimensions } = monitor.getItem();

    const dragItem = monitor.getItem();
    const scale = dragItem.resizeScale;

    dragItem.snapSystem.removeScrollEventListener();

    props.finishResizing({
      dragItem,
      zoom,
      scale,
      anchorPoint,
      offset: {
        x: canvasPositionAndDimensions.left,
        y: canvasPositionAndDimensions.top
      },
      handlerCoordinateName: props.position,
      differenceFromInitialOffset
    });
  }
};

function collect(connect, monitor) {
  const collectedProps = {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset()
  };

  // persist necessary props thorugh collect while element is being dragged
  if (monitor.getItem()?.element) {
    collectedProps.element = monitor.getItem()?.element;
  }
  if (monitor.getItem()?.hasXHandles) {
    collectedProps.hasXHandles = monitor.getItem()?.hasXHandles;
  }
  if (monitor.getItem()?.hasYHandles) {
    collectedProps.hasYHandles = monitor.getItem()?.hasYHandles;
  }

  return collectedProps;
}

export class ResizeHandler extends Component {
  constructor(props) {
    super(props);

    this.handleElementMinimumDimensions = this.handleElementMinimumDimensions.bind(
      this
    );

    this.state = {
      elBelowMinHeight: false,
      elBelowMinWidth: false
    };
  }

  static POSITIONS = {
    N: "N",
    NW: "NW",
    W: "W",
    SW: "SW",
    S: "S",
    SE: "SE",
    E: "E",
    NE: "NE"
  };

  componentDidMount() {
    const { connectDragPreview } = this.props;

    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  componentDidUpdate() {
    if (this.props.element) {
      this.handleElementMinimumDimensions();
    }
  }

  handleElementMinimumDimensions() {
    const { element, zoom, hasXHandles, hasYHandles } = this.props;

    const minElementDimensions = hasXHandles || hasYHandles ? 20 : 16;
    const elementHeight = element.height * zoom;
    const elementWidth = element.width * zoom;
    const elBelowMinHeight = elementHeight < minElementDimensions;
    const elBelowMinWidth = elementWidth < minElementDimensions;

    if (this.state.elBelowMinHeight !== elBelowMinHeight) {
      this.setState({
        elBelowMinHeight: !this.state.elBelowMinHeight
      });
    }

    if (this.state.elBelowMinWidth !== elBelowMinWidth) {
      this.setState({
        elBelowMinWidth: !this.state.elBelowMinWidth
      });
    }
  }

  render() {
    const {
      connectDragSource,
      isInTextEditMode,
      position,
      hasXHandles,
      hasYHandles
    } = this.props;

    return connectDragSource(
      <div
        className={style.dotWrapper}
        data-position={position}
        data-min-height={this.state.elBelowMinHeight}
        data-min-width={this.state.elBelowMinWidth}
        data-x-handles={!!hasXHandles}
        data-y-handles={!!hasYHandles}
        data-is-editing={isInTextEditMode}
      >
        <div
          className={style.dot}
          data-position={position}
          data-is-editing={isInTextEditMode}
        />
      </div>
    );
  }
}

const ResizeHandlerDraggable = DragSource(
  "RESIZE_HANDLER",
  ResizeHandlerSource,
  collect
)(ResizeHandler);

const ResizeHandlerDefault = props => (
  <EditorContext.Consumer>
    {({
      startResizing,
      finishResizing,
      selectedItems,
      zoom,
      designData,
      isInTextEditMode
    }) => {
      return (
        <ResizeHandlerDraggable
          {...props}
          startResizing={startResizing}
          finishResizing={finishResizing}
          selectedItems={selectedItems}
          getElement={designData.getElement}
          zoom={zoom}
          isInTextEditMode={isInTextEditMode}
        />
      );
    }}
  </EditorContext.Consumer>
);

export default ResizeHandlerDefault;
