import {
  uploadUserTeamAnimation,
  addUploadingAnimationsPlaceholders as addUserTeamAnimationPlaceholders
} from "state/entities/userTeamAnimations/userTeamAnimationsActions";
import {
  uploadTeamAnimation,
  addUploadingTeamAnimationsPlaceholders as addTeamAnimationPlaceholders
} from "state/entities/teamAnimations/teamAnimationsActions";
import { uploadAnimationToFolder as uploadUserTeamAnimationToFolder } from "state/entities/userTeamAnimationsFolders/userTeamAnimationsFoldersActions";
import { uploadTeamAnimationToFolder } from "state/entities/teamAnimationsFolders/teamAnimationsFoldersActions";

export const handleUploadAnimation = ({
  isBrandAnimations,
  ...args
}) => dispatch => {
  if (isBrandAnimations) {
    return dispatch(uploadTeamAnimation(args));
  }
  return dispatch(uploadUserTeamAnimation(args));
};

export const handleUploadAnimationToFolder = ({
  isBrandAnimations,
  ...args
}) => dispatch => {
  if (isBrandAnimations) {
    return dispatch(uploadTeamAnimationToFolder(args));
  }
  return dispatch(uploadUserTeamAnimationToFolder(args));
};

export const handleUploadingAnimationsPlaceholders = ({
  isBrandAnimations,
  ...args
}) => dispatch => {
  if (isBrandAnimations) {
    return dispatch(addTeamAnimationPlaceholders(args));
  }
  return dispatch(addUserTeamAnimationPlaceholders(args));
};
