import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TriangleIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 19}
    height={height || size || 16}
    viewBox="0 0 19 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <path d="M1 0h16v16H1z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M2.755 14h12.851L9.282 2.985 2.755 14zM10.163.502l8.038 14A1 1 0 0 1 17.333 16H1a1 1 0 0 1-.86-1.51l8.295-14a1 1 0 0 1 1.728.012z"
      />
    </g>
  </svg>
);
TriangleIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TriangleIcon;
