import * as types from "./usersApiTypes";
import { fetchUsersIfNeeded } from "state/entities/users/usersActions";

export const usersApiPageSizeChange = pageSize => (dispatch, getState) => {
  dispatch({
    type: types.USERS_API_PAGE_SIZE_CHANGE,
    payload: { pageSize }
  });

  dispatch(fetchUsersIfNeeded({ page: 1 }));
};
