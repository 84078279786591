import React, { Component } from "react";
import roleFilterStyle from "./roleFilterStyle.module.css";
import { TEAM_ROLES } from "lib/constants";
import DropdownCustom from "views/components/DropdownCustom/DropdownCustom";
import Checkbox from "views/components/checkbox/Checkbox";
import Button from "views/components/button";
import ToggleCheckbox from "views/components/toggleCheckBox/ToogleCheckbox";

export default class MemberRolesFilterDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getPopoutContent = this.getPopoutContent.bind(this);
    this.handleParentTeamToggle = this.handleParentTeamToggle.bind(this);

    this.state = {
      filteredRoles: [],
      showParentTeamMembers: true,
      hasUpdates: false,
      isOpen: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // reselect checkboxes and toggles that were deselected before popout was closed and
    // no search or clear action was applied
    if (!prevState.isOpen && this.state.isOpen) {
      if (this.state.filteredRoles !== this.props.currentFilteredRoles) {
        this.setState({ filteredRoles: this.props.currentFilteredRoles });
      }

      if (this.state.showParentTeamMembers !== this.props.includeParentTeams) {
        this.setState({ showParentTeamMembers: this.props.includeParentTeams });
      }
    }

    if (prevState.showParentTeamMembers !== this.state.showParentTeamMembers) {
      this.setState({ hasUpdates: true });
    }

    if (prevState.isOpen && !this.state.isOpen) {
      this.setState({ hasUpdates: false });
    }
  }

  handleOnClick(event, key) {
    event.preventDefault();
    event.stopPropagation();
    const { filteredRoles } = this.state;
    let updatedRoles = [...filteredRoles];

    // if role is already included in array, toggle and remove
    if (filteredRoles.includes(key)) {
      updatedRoles = updatedRoles.filter(role => role !== key);

      this.setState({
        filteredRoles: updatedRoles,
        hasUpdates: true
      });
      return;
    }

    updatedRoles.push(key);

    this.setState({
      filteredRoles: updatedRoles,
      hasUpdates: true
    });
  }

  handleSubmit() {
    const { filteredRoles, showParentTeamMembers } = this.state;
    this.props.onSearchUsers({
      roles: filteredRoles,
      includeParentTeams: showParentTeamMembers
    });
    this.setState({ isOpen: false, hasUpdates: false });
  }

  handleParentTeamToggle() {
    this.setState({
      showParentTeamMembers: !this.state.showParentTeamMembers
    });
  }

  getPopoutContent() {
    return (
      <div
        className={roleFilterStyle.roleFilterWrapper}
        data-testid="MemberRolesDropdown"
      >
        <div className={roleFilterStyle.roleSelect}>
          <div className={roleFilterStyle.titles}>Filter by Roles</div>
          <div className={roleFilterStyle.rolePicker}>
            {TEAM_ROLES.map(role => (
              <div
                className={roleFilterStyle.teamRole}
                onClick={event => this.handleOnClick(event, role.key)}
                key={role.key}
              >
                <div className={roleFilterStyle.roleIcon}>{role.label}</div>
                <Checkbox
                  onClick={event => this.handleOnClick(event, role.key)}
                  checked={this.state.filteredRoles.includes(role.key)}
                  checkboxClassName={roleFilterStyle.roleCheckbox}
                />
              </div>
            ))}
          </div>
        </div>
        {!this.props.isCurrentTeamOrg && (
          <div className={roleFilterStyle.parentTeamToggleWrapper}>
            <div className={roleFilterStyle.titles}>Filter by Team</div>
            <div className={roleFilterStyle.parentTeamToggle}>
              <div>Show Parent Team Members</div>
              <ToggleCheckbox
                checked={this.state.showParentTeamMembers}
                onChange={this.handleParentTeamToggle}
                className={roleFilterStyle.parentTeamToggle}
                width="40px"
                height="20px"
              />
            </div>
          </div>
        )}
        <div className={roleFilterStyle.buttons}>
          <Button
            theme="gray"
            onClick={() => {
              this.props.onClear();
              this.setState({ filteredRoles: [], isOpen: false });
            }}
          >
            Clear
          </Button>
          <Button
            theme="grayBackgroundXDarkGrayFont"
            onClick={this.handleSubmit}
            disabled={!this.state.hasUpdates}
            disabledTheme="grayBackgroundXDarkGrayFontDisabled"
          >
            Apply
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const popoutContent = this.getPopoutContent();

    return (
      <DropdownCustom
        value="Filter"
        popoutContent={popoutContent}
        className={roleFilterStyle.memberFilterRoles}
        inputClassName={roleFilterStyle.roleFilterInput}
        isOpen={this.state.isOpen}
        setIsOpen={args => this.setState({ isOpen: args })}
      />
    );
  }
}
