import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const BackgroundIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g id="Editor" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Colors" transform="translate(-1102.000000, -330.000000)">
        <g id="Group-4" transform="translate(1101.000000, 328.000000)">
          <rect id="Rectangle-205" x="0" y="0" width="20" height="20" />
          <path
            d="M17,13 C17,12.4477153 17.4477153,12 18,12 C18.5522847,12 19,12.4477153 19,13 L19,15 C19,16.6568542 17.6568542,18 16,18 L14,18 C13.4477153,18 13,17.5522847 13,17 C13,16.4477153 13.4477153,16 14,16 L16,16 C16.5522847,16 17,15.5522847 17,15 L17,13 Z"
            id="Rectangle"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
          />
          <path
            d="M5,13 C5,12.4477153 5.44771525,12 6,12 C6.55228475,12 7,12.4477153 7,13 L7,15 C7,16.6568542 5.65685425,18 4,18 L2,18 C1.44771525,18 1,17.5522847 1,17 C1,16.4477153 1.44771525,16 2,16 L4,16 C4.55228475,16 5,15.5522847 5,15 L5,13 Z"
            id="Rectangle"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
            transform="translate(4.000000, 15.000000) scale(-1, 1) translate(-4.000000, -15.000000) "
          />
          <path
            d="M17,3 C17,2.44771525 17.4477153,2 18,2 C18.5522847,2 19,2.44771525 19,3 L19,5 C19,6.65685425 17.6568542,8 16,8 L14,8 C13.4477153,8 13,7.55228475 13,7 C13,6.44771525 13.4477153,6 14,6 L16,6 C16.5522847,6 17,5.55228475 17,5 L17,3 Z"
            id="Rectangle"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
            transform="translate(16.000000, 5.000000) scale(1, -1) translate(-16.000000, -5.000000) "
          />
          <path
            d="M5,3 C5,2.44771525 5.44771525,2 6,2 C6.55228475,2 7,2.44771525 7,3 L7,5 C7,6.65685425 5.65685425,8 4,8 L2,8 C1.44771525,8 1,7.55228475 1,7 C1,6.44771525 1.44771525,6 2,6 L4,6 C4.55228475,6 5,5.55228475 5,5 L5,3 Z"
            id="Rectangle"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
            transform="translate(4.000000, 5.000000) scale(-1, -1) translate(-4.000000, -5.000000) "
          />
          <g id="Group-8" transform="translate(7.000000, 8.000000)">
            <g id="Group-5" />
          </g>
          <path
            d="M14.2,9.69261308 L14.2,7 C14.2,6.88954305 14.1104569,6.8 14,6.8 L6,6.8 C5.88954305,6.8 5.8,6.88954305 5.8,7 L5.8,13 C5.8,13.1104569 5.88954305,13.2 6,13.2 L7.43648532,13.2 L10.2136894,10.0423193 C11.1948418,8.84704327 12.9591855,8.67346175 14.1544615,9.65461408 C14.1674516,9.66527705 14.1826244,9.67793774 14.2,9.69261308 Z M14.2,11.8255977 C14.1948371,11.8212172 14.1897145,11.81676 14.1846338,11.8122261 C13.6018529,11.292169 13.2464207,10.9792532 13.1392996,10.8913219 C12.6270384,10.470828 11.8708911,10.5452201 11.4328117,11.078178 L9.56701529,13.2 L14,13.2 C14.1104569,13.2 14.2,13.1104569 14.2,13 L14.2,11.8255977 Z M6,5 L14,5 C15.1045695,5 16,5.8954305 16,7 L16,13 C16,14.1045695 15.1045695,15 14,15 L6,15 C4.8954305,15 4,14.1045695 4,13 L4,7 C4,5.8954305 4.8954305,5 6,5 Z M8.2,10 C7.64771525,10 7.2,9.55228475 7.2,9 C7.2,8.44771525 7.64771525,8 8.2,8 C8.75228475,8 9.2,8.44771525 9.2,9 C9.2,9.55228475 8.75228475,10 8.2,10 Z"
            id="Combined-Shape"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);
BackgroundIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default BackgroundIcon;
