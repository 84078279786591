import { isEmpty } from "lib";

export const publicFontsSelector = state => state.entities.publicFonts;

export const publicFontsIsLoadingSelector = state => {
  const publicFontsApiState = state.api.publicFonts;
  const publicFontPages = publicFontsApiState.pages;

  return (
    !publicFontPages ||
    isEmpty(publicFontPages) ||
    publicFontPages[1].isFetching
  );
};
