const getParameterByName = (paramName, path) => {
  // Disabling linting of this regex as the regex will need to be rewritten
  // eslint-disable-next-line
  const name = paramName.replace(/[\[\]]/g, "\\$&");

  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(path);

  if (!results) return null;
  if (!results[2]) return "";

  return decodeURIComponent(results[2]);
};

export { getParameterByName };
