/* 
    takes an array of promise container functions and runs them sequentially until all
    promises are complete, then resolves with the results of all of the promises
*/
export const chainPromises = promiseArray =>
  new Promise(resolve =>
    promiseArray.reduce(
      (promiseChain, currentPromise) =>
        promiseChain.then((chainResults = []) => {
          // take the next promise in chain and run it
          // NOTE: never return here as it will escape the chain and break this function
          currentPromise().then(currentResult => {
            // append the results to the chain results
            return [...chainResults, currentResult];
          });
        }),
      Promise.resolve([])
        .then(results => resolve(results))
        .catch(error => resolve(error))
    )
  );

export const chainPromisesWithResults = promiseArray =>
  new Promise(resolve => {
    const results = [];

    promiseArray.reduce(
      (previousPromise, nextPromise, currentIndex) =>
        previousPromise.then(result => {
          results.push(result);
          if (currentIndex === promiseArray.length - 1) {
            return nextPromise().then(result => resolve([...results, result]));
          }
          return nextPromise();
        }),
      Promise.resolve()
    );
  });
