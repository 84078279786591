const DEBUG_LEVELS = {
  info: 3,
  warn: 2,
  error: 1,
  none: 0
};

class Logger {
  constructor() {
    const { REACT_APP_LOGGER_LEVEL: LOGGER_LEVEL } = process.env;

    if (LOGGER_LEVEL & !Object.keys(DEBUG_LEVELS).includes(LOGGER_LEVEL)) {
      console.error("Invalid Log Level");
    }

    this._level = DEBUG_LEVELS[LOGGER_LEVEL] ? LOGGER_LEVEL : "none";
  }

  info() {
    if (DEBUG_LEVELS[this._level] >= DEBUG_LEVELS.info)
      console.info("Easil: ", ...arguments);
  }

  warn(msg) {
    if (DEBUG_LEVELS[this._level] >= DEBUG_LEVELS.warn)
      console.warn("Easil: ", msg);
  }

  error() {
    if (DEBUG_LEVELS[this._level] >= DEBUG_LEVELS.error)
      console.error("Easil: ", ...arguments);
  }

  set level(value) {
    const debugLevelValues = Object.keys(DEBUG_LEVELS);

    if (!debugLevelValues.includes(value)) {
      const levels = debugLevelValues.join('", ');

      console.warn(`### Invalid level. Log Levels are: "${levels}" ###`);
      return;
    }

    console.warn(`### Log Level configured to: ${value} ###`);

    this._level = value;
  }
}

const instance = new Logger();
window.easil.Logger = instance;

export default instance;
