import React from "react";

import * as NotificationTypes from "./notificationTypes";

const NotificationEvent = event => {
  switch (event.type) {
    case "PENDING_APPROVAL": {
      return (
        <NotificationTypes.PendingApprovalContainer
          event={event}
          key={event.id}
        />
      );
    }

    default: {
      return null;
    }
  }
};

NotificationEvent.displayName = "NotificationEvent";

NotificationEvent.propTypes = {};

export default NotificationEvent;
