import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TInBoxIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 16}
    height={height || size || 16}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill={color || easilGrayXDark}>
      <path d="M9 9.958V6.033h.909v.791a1 1 0 102 0V5.033a1 1 0 00-1-1h-5.85a1 1 0 00-1 1v1.791a1 1 0 002 0v-.791H7v3.924a.992.992 0 00-.925.985 1 1 0 001 1h1.85a1 1 0 001-1A.99.99 0 009 9.958zM1 6.084a1 1 0 001-1V3a1 1 0 011-1h2.013a1 1 0 000-2H3a3 3 0 00-3 3v2.084a1 1 0 001 1zM15 9.916a1 1 0 00-1 1V13a1 1 0 01-1 1h-2.013a1 1 0 000 2H13a3 3 0 003-3v-2.084a1 1 0 00-1-1zM13 0h-2.084a1 1 0 000 2H13a1 1 0 011 1v2.013a1 1 0 002 0V3a3 3 0 00-3-3zM5.084 14H3a1 1 0 01-1-1v-2.013a1 1 0 00-2 0V13a3 3 0 003 3h2.084a1 1 0 000-2z" />
    </g>
  </svg>
);
TInBoxIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TInBoxIcon;
