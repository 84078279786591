import { noop } from "lib";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import PATHS from "routes/paths";
import * as types from "./notificationTypes";

import { moveTo } from "state/ui/navigation/navigationActions";

import { switchToken } from "state/currentUser/currentUserActions";

import { currentUserSelector } from "state/currentUser/currentUserSelectors";

export const fetchNotifications = ({
  userId,
  onSuccess = noop,
  onFailure
}) => ({
  [CALL_API]: {
    types: [
      types.FETCH_APPROVAL_NOTIFICATIONS_REQUEST,
      types.FETCH_APPROVAL_NOTIFICATIONS_SUCCESS,
      types.FETCH_APPROVAL_NOTIFICATIONS_FAILURE
    ],
    service: SERVICES.ASSET,
    endpoint: `/users/${userId}/approval-notifications`,
    schema: schemas.APPROVAL_NOTIFICATIONS_ARRAY,
    request: {},
    onSuccess
  }
});

export const fetchCurrentUserNotifications = () => (dispatch, getState) => {
  const userId = currentUserSelector(getState()).id;

  dispatch(
    fetchNotifications({
      userId
    })
  );
};

export const redirectToTeamApproval = ({ teamId }) => (dispatch, getState) => {
  dispatch(
    switchToken({
      teamId,
      onSuccess: () => {
        dispatch(moveTo(PATHS.workspaceDrafts));
      }
    })
  );
};
