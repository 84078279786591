import React, { Component } from "react";
import style from "./style.module.css";
import FolderWideIcon from "views/components/icons/FolderWideIcon";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import TrashIcon from "views/components/icons/TrashIcon";
import PopoutItemMenu from "views/components/popout/PopoutItemMenu/PopoutItemMenu";
import confirmDeleteFolder from "./confirmDeleteFolder";

export class ImageListFolderRow extends Component {
  constructor(props) {
    super(props);

    this.getMenuActionsDetails = this.getMenuActionsDetails.bind(this);
    this.openPopout = this.openPopout.bind(this);
    this.closePopout = this.closePopout.bind(this);
    this.togglePopout = this.togglePopout.bind(this);
    this.handleSubmitNameChange = this.handleSubmitNameChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.popoutRef = React.createRef();

    this.state = {
      popoutOpen: false
    };
  }

  componentDidMount() {
    // add listening so clicking outside closes the popout
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // remove listening so actions are not performed on unmounted component
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.popoutRef.current &&
      !this.popoutRef.current.contains(event.target) &&
      event.target.isConnected
    ) {
      this.closePopout(event);
    }
  }

  getMenuActionsDetails() {
    return [
      {
        name: "Delete",
        Icon: props => <TrashIcon version="2" {...props} />,
        onClick: confirmDeleteFolder({
          onOk: () => {
            this.props.onDeleteImageFolder({ folderId: this.props.folder.id });
          }
        })
      }
    ];
  }

  handleSubmitNameChange(newName) {
    const { onUpdateImageFolder, folder } = this.props;

    onUpdateImageFolder({
      folder: Object.assign({}, folder, { name: newName })
    });
    this.closePopout();
  }

  openPopout(e) {
    e.stopPropagation();
    if (this.state.popoutOpen === false) {
      this.setState({
        popoutOpen: true
      });
    }
  }

  closePopout(e) {
    if (e) {
      e.stopPropagation();
    }
    if (this.state.popoutOpen === true) {
      this.setState({
        popoutOpen: false
      });
    }
  }

  togglePopout(e) {
    if (this.state.popoutOpen === true) {
      return this.closePopout(e);
    }
    this.openPopout(e);
  }

  render() {
    const {
      folder,
      isLast,
      changePage,
      hasImages,
      imageOriginPath,
      className
    } = this.props;
    const { popoutOpen } = this.state;

    return (
      <div
        className={`${style.folderRow} ${className}`}
        onClick={() => changePage(`${imageOriginPath}?folderId=${folder.id}`)}
      >
        <div className={style.folderTile}>
          <div className={style.imageWrapper}>
            <FolderWideIcon
              strokeWidth="1.5"
              color={"#9a9b9d"}
              width="22px"
              height="18px"
            />
          </div>
        </div>
        <div
          className={style.imageRowRight}
          data-is-last={isLast && !hasImages}
        >
          <div className={style.folderTitle}>{folder.name}</div>
          <div className={style.menu}>
            <div
              className={style.imageRowPopoutCaretWrapper}
              onClick={this.togglePopout}
              id={`image-list-folder-button-${this.props.folder.id}`}
            >
              <EllipsisIcon
                color="#999c9e"
                withBackground={true}
                backgroundColor="transparent"
              />
            </div>
          </div>
          {popoutOpen && (
            <div
              style={{
                position: "relative"
              }}
            >
              <PopoutItemMenu
                onClose={this.closePopout}
                onRename={this.handleSubmitNameChange}
                className={style.folderPopout}
                itemName={folder.name}
                actions={this.getMenuActionsDetails()}
                buttonId={`image-list-folder-button-${this.props.folder.id}`}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ImageListFolderRow.displayName = "ImageListFolderRow";

export default ImageListFolderRow;
