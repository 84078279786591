import React, { Component } from "react";
import style from "./style.module.css";
import CheckIcon from "views/components/icons/CheckIcon";

class FontOption extends Component {
  render() {
    const { fontId, fontName, fontFamily, onClick, selected } = this.props;
    return (
      <div
        className={style.option}
        style={{
          fontFamily: `"${fontFamily}"`
        }}
        key={fontId}
        onClick={() => onClick(fontId)}
      >
        <div className={style.name}>{fontName}</div>
        {selected && (
          <div className={style.selected}>
            <CheckIcon width={"11"} height={"11"} />
          </div>
        )}
      </div>
    );
  }
}

export default FontOption;
