import * as types from "./teamAnimationsFoldersTypes";

import { omit } from "lib";

export const initState = null;

const teamAnimationFoldersReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_TEAM_ANIMATION_FOLDERS_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return action.request.page === 1 ? {} : state;
      }

      const {
        response: {
          entities: { folder }
        }
      } = action;

      const folders = Object.assign({}, state, folder);

      return {
        ...folders
      };
    }

    case types.CREATE_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS: {
      const {
        response: {
          entities: { folder }
        }
      } = action;

      return {
        ...state,
        ...folder
      };
    }

    case types.DELETE_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS: {
      return omit(state, action.extra.folderId);
    }

    case types.UPDATE_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS: {
      const {
        request: {
          body: { id, name }
        }
      } = action;
      const updatedFolder = Object.assign({}, state[id], { name: name });

      return { ...state, [id]: updatedFolder };
    }

    default:
      return state;
  }
};

export default teamAnimationFoldersReducer;
