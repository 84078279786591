import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const BannerIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color = easilGrayXDark,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke="transparent"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0-4h24v24H0z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M20 14v-2.535a1 1 0 0 1 .5-.866A2.998 2.998 0 0 0 22 8a2.998 2.998 0 0 0-1.5-2.6 1 1 0 0 1-.5-.865V2H4v2.535a1 1 0 0 1-.5.866A2.998 2.998 0 0 0 2 8c0 1.084.579 2.066 1.5 2.6a1 1 0 0 1 .5.865V14h16zm2 1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-3c-1.241-.93-2-2.4-2-4s.759-3.07 2-4V1a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v3c1.241.93 2 2.4 2 4s-.759 3.07-2 4v3z"
      />
    </g>
  </svg>
);
BannerIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default BannerIcon;
