const Referrer = {};

Referrer.DEFAULT_LIFE_SPAN_IN_MS = 3600000; // 1 hour (60minutes * 60seconds * 1000miliseconds)

Referrer.set = path => {
  if (!path) {
    return;
  }

  localStorage.setItem("referrer", path);
  localStorage.setItem("referrerCreatedAt", Date.now());
};

Referrer.get = ({ lifeSpanInMs = Referrer.DEFAULT_LIFE_SPAN_IN_MS } = {}) => {
  const referrer = localStorage.getItem("referrer");
  const referrerCreatedAt = localStorage.getItem("referrerCreatedAt");

  if (!referrer || !referrerCreatedAt) {
    return null;
  }

  if (Date.now() - referrerCreatedAt <= lifeSpanInMs) {
    return referrer;
  } else {
    return null;
  }
};

Referrer.remove = () => {
  localStorage.removeItem("referrer");
  localStorage.removeItem("referrerCreatedAt");
};

Referrer.pop = () => {
  const referrer = Referrer.get();
  Referrer.remove();

  return referrer;
};

export default Referrer;
