export const TABS = {
  STOCK_IMAGES: "STOCK_IMAGES",
  THIS_DESIGN: "THIS_DESIGN",
  MY_IMAGES: "MY_IMAGES",
  BRAND_IMAGES: "BRAND_IMAGES",
  BRAND_LOGOS: "BRAND_LOGOS",
  SMART_IMAGES: "SMART_IMAGES",
  SEARCH: "SEARCH"
};

export const SUBSCRIPTION_ONLY_TABS = [TABS.BRAND_LOGOS, TABS.BRAND_IMAGES];

export const TABS_ORDER = [
  TABS.STOCK_IMAGES,
  TABS.MY_IMAGES,
  TABS.THIS_DESIGN,
  TABS.BRAND_IMAGES,
  TABS.BRAND_LOGOS,
  TABS.SMART_IMAGES
];

export const TABS_DETAILS = {
  [TABS.STOCK_IMAGES]: {
    key: TABS.STOCK_IMAGES,
    label: "Stock Images"
  },
  [TABS.THIS_DESIGN]: {
    key: TABS.THIS_DESIGN,
    label: "This Design"
  },
  [TABS.MY_IMAGES]: {
    key: TABS.MY_IMAGES.toString(),
    label: "My Images"
  },
  [TABS.BRAND_IMAGES]: {
    key: TABS.BRAND_IMAGES.toString(),
    label: "Brand Images"
  },
  [TABS.BRAND_LOGOS]: {
    key: TABS.BRAND_LOGOS.toString(),
    label: "Brand Logos"
  },
  [TABS.SMART_IMAGES]: {
    key: TABS.SMART_IMAGES.toString(),
    label: "Smart Images"
  }
};
