import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const LightBulbPencilAndPaperIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 210}
    height={height || size || 66}
    viewBox="0 0 210 66"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none">
      <path
        fill="#FCE068"
        d="M23.986 61.683h.85a5.298 5.298 0 0 0 5.186 4.248 5.298 5.298 0 0 0 5.186-4.248h.85a2.615 2.615 0 0 0 2.612-2.612v-3.086h.493a1.04 1.04 0 0 0 1.041-1.041v-.357c0-1.298.108-2.565.306-3.799a1.06 1.06 0 0 0 .028-.151c.551-3.297 1.787-6.334 3.677-8.922a17.635 17.635 0 0 0 3.327-11.65c-.626-8.722-7.683-15.705-16.414-16.241-4.9-.302-9.574 1.38-13.14 4.734a17.64 17.64 0 0 0-5.535 12.8c0 3.599 1.08 7.06 3.125 10.008 2.787 4.019 4.26 8.59 4.26 13.22v.358a1.04 1.04 0 0 0 1.041 1.041h.493v3.086a2.618 2.618 0 0 0 2.614 2.612z"
      />
      <path
        d="M21.488 57.491h.85a5.298 5.298 0 0 0 5.186 4.248 5.298 5.298 0 0 0 5.186-4.248h.85a2.615 2.615 0 0 0 2.612-2.612v-3.086h.493a1.04 1.04 0 0 0 1.041-1.041v-.357c0-1.298.108-2.565.306-3.799a1.06 1.06 0 0 0 .028-.151c.551-3.297 1.787-6.334 3.677-8.922a17.635 17.635 0 0 0 3.327-11.65c-.626-8.722-7.683-15.705-16.414-16.241-4.9-.302-9.574 1.38-13.14 4.734a17.64 17.64 0 0 0-5.535 12.8c0 3.599 1.08 7.06 3.125 10.008 2.787 4.019 4.26 8.59 4.26 13.22v.358a1.04 1.04 0 0 0 1.041 1.041h.493v3.086a2.618 2.618 0 0 0 2.614 2.612zm1.481-22.518h-2.212v-.889c0-.969.788-1.757 1.757-1.757h.456v2.646h-.001zm2.083 2.082h4.945v8.228h-4.945v-8.228zm-1.042 10.31h11.8a26.204 26.204 0 0 0-.177 2.345H19.415a24.2 24.2 0 0 0-.196-2.345h4.791zm3.514 12.292a3.202 3.202 0 0 1-3.019-2.166h6.037a3.2 3.2 0 0 1-3.018 2.166zm6.567-4.778a.53.53 0 0 1-.53.53H21.489a.53.53 0 0 1-.53-.53v-3.086h13.132v3.086zM12.039 27.166c0-4.317 1.733-8.324 4.879-11.283 3.144-2.956 7.262-4.439 11.586-4.173 7.694.473 13.913 6.626 14.464 14.312a15.548 15.548 0 0 1-2.933 10.273c-1.924 2.636-3.228 5.688-3.894 8.988h-4.063v-8.228h3.254a1.04 1.04 0 0 0 1.041-1.041v-1.93a3.843 3.843 0 0 0-3.839-3.839h-1.497a1.04 1.04 0 0 0-1.041 1.041v3.687h-4.945v-3.687a1.04 1.04 0 0 0-1.041-1.041h-1.497a3.843 3.843 0 0 0-3.839 3.839v1.93a1.04 1.04 0 0 0 1.041 1.041h3.254v8.228h-4.111c-.706-3.262-2.067-6.414-4.065-9.296a15.411 15.411 0 0 1-2.754-8.821zm20.04 7.807v-2.646h.456c.969 0 1.757.788 1.757 1.757v.889h-2.213zM28.045 4.148V1.892a1.04 1.04 0 1 0-2.082 0v2.256a1.04 1.04 0 1 0 2.082 0zM10.49 10.701L8.895 9.105a1.04 1.04 0 1 0-1.472 1.472l1.595 1.596a1.039 1.039 0 0 0 1.472 0 1.042 1.042 0 0 0 0-1.472zM1.843 29.83h2.256a1.04 1.04 0 1 0 0-2.082H1.843a1.04 1.04 0 1 0 0 2.082zm48.413-2.43a1.04 1.04 0 1 0 0 2.082h2.256a1.04 1.04 0 1 0 0-2.082h-2.256zm-3.569-17.068a1.04 1.04 0 1 0-1.472-1.472l-1.595 1.595a1.04 1.04 0 1 0 1.472 1.472l1.595-1.595z"
        fill={color || easilGrayXDark}
      />
      <path
        fill="#FCE068"
        d="M95.96 47.582l30.672-30.672 7.892 7.893-30.671 30.672z"
      />
      <path
        fill={color || easilGrayXDark}
        d="M87.822 57.286h.163l12.955-2.281c.081 0 .081 0 .163-.081h.081c.081-.081.244-.163.326-.244l27.214-27.214 4.4-4.4c.407-.407.407-1.141 0-1.548L122.45 10.762c-.407-.407-1.141-.407-1.548 0l-4.4 4.4-27.214 27.214c-.081.081-.163.244-.244.326v.081c0 .081 0 .081-.081.163l-2.281 12.955c-.081.326.081.733.326.978.243.244.488.407.814.407zm2.933-11.814l7.741 7.741-9.37 1.63 1.629-9.371zm26.562-27.948l3.83 3.83L95.399 47.02l-3.83-3.83 25.748-25.666zm-16.54 34.792l-3.83-3.748 25.666-25.666 3.83 3.83-25.666 25.584zm20.859-39.191l9.207 9.207-2.852 2.852-9.126-9.207 2.771-2.852z"
      />
      <path
        fill="#FCE068"
        d="M208.92 63.523h-35.079a1.03 1.03 0 0 1-1.031-1.031V15.984c0-.57.462-1.031 1.031-1.031h35.079c.57 0 1.031.462 1.031 1.031v46.508c0 .569-.462 1.031-1.031 1.031z"
      />
      <path
        fill={color || easilGrayXDark}
        stroke="#000"
        strokeWidth=".268"
        d="M171.56 9.775a3.941 3.941 0 0 0-3.928 3.928v43.213a3.94 3.94 0 0 0 3.928 3.928h31.427a3.941 3.941 0 0 0 3.928-3.928V20.548c0-.023-.002-.046-.004-.07a.98.98 0 0 0-.387-.697l-9.595-9.591a.978.978 0 0 0-.817-.414H171.56v-.001zm0 1.964h23.57v5.893a3.941 3.941 0 0 0 3.928 3.928h5.893v35.356a1.939 1.939 0 0 1-1.964 1.964H171.56a1.939 1.939 0 0 1-1.964-1.964V13.703c0-1.105.859-1.964 1.964-1.964zm25.535 1.389l6.468 6.468h-4.504a1.939 1.939 0 0 1-1.964-1.964v-4.504zm-14.732 12.36c-3.785 0-6.875 3.09-6.875 6.875s3.09 6.875 6.875 6.875 6.875-3.09 6.875-6.875-3.09-6.875-6.875-6.875zm0 1.965a4.896 4.896 0 0 1 4.911 4.91 4.896 4.896 0 0 1-4.911 4.912 4.896 4.896 0 0 1-4.911-4.911 4.897 4.897 0 0 1 4.911-4.911zm9.622 5.892a.983.983 0 0 0-.963.786 8.834 8.834 0 0 1-6.89 6.886.981.981 0 0 0-.786.959v6.1c0 .542.44.982.982.982h13.749c.542 0 .982-.44.982-.982v-13.75a.982.982 0 0 0-.982-.982h-6.092zm.541 1.964h4.569v11.785H185.31v-4.577c3.509-1.034 6.178-3.698 7.216-7.208z"
      />
    </g>
  </svg>
);
LightBulbPencilAndPaperIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default LightBulbPencilAndPaperIcon;
