import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ScalingIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <g fill={color || easilGrayXDark}>
        <path
          fillRule="nonzero"
          d="M1.9 6A.9.9 0 0 1 .1 6V3A2.9 2.9 0 0 1 3 .1h10A2.9 2.9 0 0 1 15.9 3v10a2.9 2.9 0 0 1-2.9 2.9h-3a.9.9 0 1 1 0-1.8h3a1.1 1.1 0 0 0 1.1-1.1V3A1.1 1.1 0 0 0 13 1.9H3A1.1 1.1 0 0 0 1.9 3v3z"
        />
        <path d="M6.9 10.395V15a.9.9 0 0 1-.9.9H1a.9.9 0 0 1-.9-.9v-5a.9.9 0 0 1 .9-.9h4.65l2.296-2.3H6.4a.9.9 0 0 1 0-1.8h3.7a.9.9 0 0 1 .9.9v3.7a.9.9 0 1 1-1.8 0V8.092l-2.3 2.303zm-5 .505v3.2h3.2v-3.2H1.9z" />
      </g>
    </g>
  </svg>
);
ScalingIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ScalingIcon;
