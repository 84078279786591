import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const GuaranteeTickIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  title,
  planName,
  color,
  ...props
}) => {
  return (
    <svg
      {...props}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 40}
      height={height || size || 42}
      viewBox="0 0 40 42"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g fill={color || easilGrayXDark} fillRule="nonzero">
        <path d="M19.995 2.162c.364 0 .718.115 1.011.33l2.964 2.162a4.068 4.068 0 0 0 2.417.785l3.672-.007a1.71 1.71 0 0 1 1.633 1.189l1.127 3.49a4.062 4.062 0 0 0 1.491 2.057l2.972 2.15a1.71 1.71 0 0 1 .625 1.924l-1.14 3.487a4.062 4.062 0 0 0 0 2.54l1.14 3.488a1.71 1.71 0 0 1-.625 1.923l-2.972 2.15a4.062 4.062 0 0 0-1.494 2.057l-1.124 3.489a1.712 1.712 0 0 1-1.636 1.189l-3.676-.007a4.062 4.062 0 0 0-2.409.785l-2.96 2.164c-.603.44-1.42.44-2.023 0l-2.968-2.162a4.069 4.069 0 0 0-2.417-.785l-3.672.007c-.745 0-1.405-.481-1.632-1.191l-1.127-3.49a4.062 4.062 0 0 0-1.494-2.055L2.71 27.676a1.71 1.71 0 0 1-.625-1.923l1.14-3.487a4.062 4.062 0 0 0 0-2.541l-1.14-3.487a1.71 1.71 0 0 1 .625-1.92l2.972-2.151a4.062 4.062 0 0 0 1.49-2.055L8.3 6.62c.228-.71.89-1.192 1.636-1.19l3.676.008a4.062 4.062 0 0 0 2.41-.785l2.963-2.162c.294-.215.648-.33 1.011-.33m0-1.186c-.614 0-1.213.195-1.71.557l-2.964 2.162a2.9 2.9 0 0 1-1.71.557l-3.674-.006h-.005a2.9 2.9 0 0 0-2.762 2.01l-1.127 3.49a2.9 2.9 0 0 1-1.06 1.46L2.01 13.358A2.9 2.9 0 0 0 .954 16.61l1.14 3.487a2.9 2.9 0 0 1 0 1.8l-1.14 3.49a2.9 2.9 0 0 0 1.056 3.254l2.972 2.15c.5.362.871.873 1.06 1.46l1.128 3.49a2.9 2.9 0 0 0 2.761 2.01h.006l3.673-.005a2.9 2.9 0 0 1 1.71.557l2.964 2.162a2.9 2.9 0 0 0 3.42 0l2.964-2.162a2.9 2.9 0 0 1 1.71-.557h.006l3.67.007h.005a2.9 2.9 0 0 0 2.761-2.01l1.127-3.491a2.9 2.9 0 0 1 1.06-1.46l2.972-2.15a2.9 2.9 0 0 0 1.057-3.253l-1.14-3.487a2.9 2.9 0 0 1 0-1.8l1.14-3.487a2.9 2.9 0 0 0-1.057-3.253l-2.969-2.155a2.9 2.9 0 0 1-1.06-1.46l-1.129-3.491a2.9 2.9 0 0 0-2.76-2.01h-.007l-3.669.007h-.005a2.9 2.9 0 0 1-1.71-.557l-2.96-2.163a2.9 2.9 0 0 0-1.715-.557" />
        <path d="M19.995 10.32A10.678 10.678 0 1 1 9.317 21c.007-5.895 4.784-10.672 10.678-10.678m0-1.186c-6.552 0-11.863 5.312-11.863 11.864 0 6.553 5.313 11.864 11.865 11.864 6.552 0 11.863-5.312 11.863-11.864A11.864 11.864 0 0 0 19.995 9.135" />
        <path d="M17.478 25.78a.661.661 0 0 1-.469-.193l-3.2-3.2a.664.664 0 1 1 .94-.94l2.73 2.732 7.761-7.767a.664.664 0 0 1 .94.939l-8.236 8.235a.661.661 0 0 1-.469.194" />
      </g>
    </svg>
  );
};
GuaranteeTickIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.string,
  planName: PropTypes.string
};

export default GuaranteeTickIcon;
