import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TextAlignLeftIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 10"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <path
      fill={color || easilGrayXDark}
      fillRule="evenodd"
      d="M1 8h14a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2zm0-4h6a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2zm0-4h14a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2z"
    />
  </svg>
);
TextAlignLeftIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TextAlignLeftIcon;
