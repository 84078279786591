import { createSelector } from "reselect";
import { flatten, isNil, isNotNil } from "lib";

const designActionsApiSelector = state => state.api.designActions;
const designActionsEntitiesSelector = state => state.entities.designActions;
const designIdPassThrough = (state, designId) => designId;

const processDesignActionsforDesignId = (
  designActionsApi,
  designActionsEntities,
  designId
) => {
  if (!designActionsApi[designId]) {
    return null;
  }

  const pages = designActionsApi[designId].pages;

  const pagesIds = Object.keys(pages)
    .sort(function(a, b) {
      return a - b;
    })
    .map(pageNumber => pages[pageNumber].ids);

  if (pagesIds.every(isNil)) {
    return null;
  }

  return flatten(pagesIds)
    .map(id => designActionsEntities[id])
    .filter(isNotNil);
};

export const designActionsforDesignId = createSelector(
  [
    designActionsApiSelector,
    designActionsEntitiesSelector,
    designIdPassThrough
  ],
  processDesignActionsforDesignId
);

export const getLatestDesignAction = ({ state, designId }) => {
  const designActions = designActionsforDesignId(state, designId);

  if (!designActions) {
    return null;
  } else {
    return designActions[0];
  }
};
