import AddTeamSmartTextModalContainer from "./modals/AddTeamSmartTextModalContainer";
import BrandKitSearchInput from "views/components/BrandKit/BrandKitSearch/BrandKitSearchInput";
import BrandKitSearchTextButton from "views/components/BrandKit/BrandKitSearch/BrandKitSearchTextButton";
import PATHS from "routes/paths";
import PlusIcon from "views/components/icons/PlusIcon";
import React from "react";

export class BrandKitSmartTextSearch extends React.Component {
  constructor(props) {
    super(props);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);

    this.state = {
      isModalOpen: false
    };
  }

  handleSearchInput(term) {
    const { history } = this.props;

    if ((term || "").trim().length) {
      const termEnc = encodeURIComponent(term);
      history.replace(`${PATHS.brandSmartText}?term=${termEnc}`);
    } else {
      history.replace(PATHS.brandSmartText);
    }
  }

  handleOpenModal() {
    this.setState({
      isModalOpen: true
    });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false
    });
  }

  render() {
    const { isBrandManager, location } = this.props;
    const { isModalOpen } = this.state;

    const urlParams = new URLSearchParams(location.search);
    const urlTerm = urlParams.get("term");

    return (
      <>
        <BrandKitSearchInput
          onChange={value => this.handleSearchInput(value)}
          onClear={() => this.handleSearchInput(null)}
          placeholder="Search for Smart Text"
          value={urlTerm || ""}
        />

        {isBrandManager && (
          <BrandKitSearchTextButton
            Icon={() => <PlusIcon color="#3184fc" size="18px" />}
            onClick={() => this.handleOpenModal()}
            title="Add Smart Text"
          />
        )}
        {isModalOpen && (
          <AddTeamSmartTextModalContainer
            isOpen={isModalOpen}
            onCloseModal={this.handleCloseModal}
          />
        )}
      </>
    );
  }
}

export default BrandKitSmartTextSearch;
