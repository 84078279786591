import PATHS from "routes/paths";
import { isNil } from "lib";

class Copymaker {
  static startModeIfNeeded() {
    if (isNil(Copymaker.getCmQueryParam())) {
      Copymaker.checkIfEditorIsLoadingCmDesign();
    } else {
      Copymaker.init();
    }
  }

  static exit() {
    // Note: lastCmDesignId is kept to enable the user to use the 'back' button from CM
    // and return to Easil in CM mode.
    localStorage.removeItem("isCmMode");
  }

  static init(designId) {
    Copymaker.setCmMode(true);
    Copymaker.setLastCmDesignId();
    Copymaker.setAuthToken();
  }

  static isCmModeOn() {
    return Boolean(localStorage.getItem("isCmMode"));
  }

  static getCmQueryParam() {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get("cm");
  }

  static checkIfEditorIsLoadingCmDesign() {
    if (!PATHS.isEditorPath(window.location.pathname)) return;

    const lastCmDesignId = Copymaker.getLastCmDesignId();
    const designId = Copymaker.getEditorDesignId();

    if (lastCmDesignId === designId) {
      Copymaker.setCmMode(true);
    }
  }
  static getEditorDesignId() {
    const [, , designId] = window.location.pathname.split("/");

    return designId;
  }

  static setCmMode(value) {
    localStorage.setItem("isCmMode", value);
  }

  static getLastCmDesignId() {
    return localStorage.getItem("lastCmDesignId");
  }

  static setLastCmDesignId(designId) {
    const _designId = designId || Copymaker.getEditorDesignId();

    return localStorage.setItem("lastCmDesignId", _designId);
  }

  static setAuthToken() {
    localStorage.setItem("auth_token", Copymaker.getCmQueryParam());
  }
}

export default Copymaker;
