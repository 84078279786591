import React from "react";

export default function EmptyColorsIcon(props) {
  return (
    <svg viewBox="0 0 56 44" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <rect
            width="50"
            height="38"
            x="6"
            y="6"
            fill="#DCDCE0"
            opacity=".85"
            rx="2"
          />
          <path
            fill="#2C2E2F"
            fillRule="nonzero"
            d="M52 4.103v31.794C52 38.163 50.21 40 48 40H4c-2.21 0-4-1.837-4-4.103V4.103C0 1.837 1.79 0 4 0h44c2.21 0 4 1.837 4 4.103zm-2 0c0-1.133-.895-2.053-2-2.053H4c-1.105 0-2 .919-2 2.052v31.794c0 1.133.895 2.052 2 2.052h44c1.105 0 2-.919 2-2.052V4.103z"
          />
        </g>
        <path d="M15 8h24v24H15z" />
        <g transform="translate(14 8)">
          <path d="M0 0h24v24H0z" />
          <mask id="b" fill="#fff">
            <circle cx="12" cy="12" r="10" />
          </mask>
          <circle cx="12" cy="12" r="9" stroke="#2C2E2F" strokeWidth="2" />
          <path
            fill="#2C2E2F"
            fillOpacity=".25"
            d="M12 2h10v10H12z"
            mask="url(#b)"
          />
          <path
            fill="#2C2E2F"
            d="M12 12h10v10H12z"
            mask="url(#b)"
            opacity=".5"
          />
          <path
            fill="#2C2E2F"
            fillOpacity=".7"
            d="M2 12h10v10H2z"
            mask="url(#b)"
          />
        </g>
      </g>
    </svg>
  );
}
