import * as types from "./purchaseCollectionModalTypes";
import {
  asyncCreateOrderV2 as createCollectionOrder,
  asyncCreateCollectionOrderPaymentFromState as createCollectionOrderPayment,
  asyncAddNewCollectionToOrder as addNewCollectionToOrder,
  asyncGetAllOrdersV2 as fetchOrders,
  asyncPollOrderStatus as pollOrderStatus,
  asyncCreatePaypalOrder as createPaypalOrder,
  asyncCancelOrder as cancelCollectionOrder,
  handleAddCouponToOrder
} from "state/entities/orders/ordersActions";
import { moveToPurchased } from "state/ui/navigation/navigationActions";
import { asyncFetchPurchasedDesignsForCurrentUserTeam } from "state/entities/designs/designsActions";
import { fetchSubscriptionsForCurrentTeam } from "state/entities/subscriptions/subscriptionsActions";
import {
  purchaseCollectionRetryPaymentSelector,
  purchaseCollectionCouponAddedSelector,
  currentOrderIdSelector
} from "state/ui/purchaseCollectionModal/purchaseCollectionModalSelectors";

export const setCurrentOrderId = ({ orderId }) => {
  return {
    type: types.UI_PURCHASE_COLLECTION_SET_CURRENT_ORDER_ID,
    orderId
  };
};

export const setLoading = loading => {
  return {
    type: types.UI_PURCHASE_COLLECTION_SET_LOADING,
    loading
  };
};

export const setCouponError = () => {
  return {
    type: types.UI_PURCHASE_COLLECTION_SET_COUPON_ERROR
  };
};

export const setToken = token => {
  return {
    type: types.UI_PURCHASE_COLLECTION_SET_TOKEN,
    token: token ? token.id : ""
  };
};

export const setPaymentType = paymentType => {
  return {
    type: types.UI_PURCHASE_COLLECTION_SET_PAYMENT_TYPE,
    paymentType
  };
};

export const clearPurchaseCollectionModalState = () => {
  return {
    type: types.UI_PURCHASE_COLLECTION_CLEAR
  };
};

export const setPollingError = pollingError => {
  return {
    type: types.UI_PURCHASE_COLLECTION_SET_POLLING_ERROR,
    pollingError
  };
};

export const showPurchaseCollectionModal = ({ collectionId }) => {
  return {
    type: types.UI_PURCHASE_COLLECTION_MODAL_OPEN,
    collectionId
  };
};

export const closePurchaseCollectionModal = () => {
  return {
    type: types.UI_PURCHASE_COLLECTION_MODAL_CLOSE
  };
};

export const clearPurchaseCollectionPaymentError = () => {
  return {
    type: types.UI_PURCHASE_COLLECTION_CLEAR_PAYMENT_ERROR
  };
};

export const createPurchaseCollectionOrder = ({ collectionId }) => async (
  dispatch,
  getState
) => {
  const state = getState();
  // first check if there has been a order created already
  // after a coupon has been added
  let orderId = currentOrderIdSelector(state);
  if (orderId) return orderId;

  // check if there is an in progress COLLECTION order
  const orders = await dispatch(
    fetchOrders({ status: "IN_PROGRESS", type: "COLLECTION" })
  );
  const retryPayment = purchaseCollectionRetryPaymentSelector(state);
  const couponAdded = purchaseCollectionCouponAddedSelector(state);

  if (orders.length) {
    const orderId = orders[0].id;
    const couponCode = orders[0].couponCode;
    // if we have retryPayment flag or a couponCode for the current order
    // return orderId for payment attempt
    // else cancel the IN_PROGRESS order
    if (retryPayment || (couponCode && couponAdded)) {
      return orderId;
    } else {
      await dispatch(cancelCollectionOrder({ orderId }));
    }
  }

  let orderEntity = {};
  // make the order
  let {
    entities: { order: orderEntities }
  } = await dispatch(createCollectionOrder());
  // assign order here if we create one successfully!
  orderEntity.order = Object.values(orderEntities)[0];

  orderId = orderEntity.order.id;

  await dispatch(addNewCollectionToOrder({ collectionId, orderId }));

  return orderId;
};

export const addPaymentForCollectionOrder = ({
  orderId,
  token
}) => async dispatch => {
  dispatch(setLoading({ processing: true }));

  // apply payment for the order
  await dispatch(createCollectionOrderPayment({ orderId, token }));

  // poll until the order status is changed to "COMPLETED"
  dispatch(pollOrderStatus({ orderId }))
    .then(async () => {
      // async fetch purchased designs call
      await dispatch(asyncFetchPurchasedDesignsForCurrentUserTeam());
      // refetch subscription
      dispatch(fetchSubscriptionsForCurrentTeam());
      dispatch(setLoading({ processing: false }));
      // redirect
      dispatch(moveToPurchased());
      // close modal
      dispatch(closePurchaseCollectionModal());
    })
    .catch(response => {
      dispatch(setLoading({ processing: false }));
      dispatch(setPollingError(response));
    });
};

export const createPaypalCollectionOrder = ({
  collectionId
}) => async dispatch => {
  // create collection order
  const orderId = await dispatch(
    createPurchaseCollectionOrder({ collectionId })
  );

  // create paypal order for collection
  const { paypalOrderId } = await dispatch(createPaypalOrder({ orderId }));

  dispatch(setCurrentOrderId({ orderId }));

  // return paypal order id
  return [paypalOrderId, orderId];
};

export const purchaseCollection = ({ collectionId }) => async dispatch => {
  dispatch(setLoading({ processing: true }));

  const orderId = await dispatch(
    createPurchaseCollectionOrder({ collectionId })
  );

  dispatch(setCurrentOrderId({ orderId }));
  dispatch(addPaymentForCollectionOrder({ orderId }));
};

export const createCouponCollectionOrder = ({
  collectionId,
  coupon
}) => async dispatch => {
  dispatch(setLoading({ coupon: true }));

  const orderId = await dispatch(
    createPurchaseCollectionOrder({ collectionId })
  );

  dispatch(setCurrentOrderId({ orderId }));
  dispatch(handleAddCouponToOrder({ orderId, coupon }));
  dispatch(setLoading({ coupon: false }));

  return orderId;
};
