import React, { Component } from "react";
import PropTypes from "prop-types";
import TeamCreateButton from "views/components/TeamCreateButton";
import AddTeamMembersButton from "views/components/AddTeamMembersButton/AddTeamMembersButton";
import TeamSettingsButton from "views/components/TeamSettingsButton/TeamSettingsButton";
import { isEmpty, Logger } from "lib";
import Avatar from "views/components/avatar/Avatar";
import UpdateTeamLogoButton from "views/components/UpdateTeamLogoButton/UpdateTeamLogoButton";
import style from "./style.module.css";

class TeamPageTopRow extends Component {
  static propTypes = {
    inviteToTeamForm: PropTypes.object,
    currentTeam: PropTypes.object,
    teamsTotalCount: PropTypes.number,
    usersTotalCount: PropTypes.number,
    users: PropTypes.object.isRequired,
    teams: PropTypes.object.isRequired,
    onSubmitNewTeam: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.closeCreateTeamModal = this.closeCreateTeamModal.bind(this);
    this.changeCreateTeamModalType = this.changeCreateTeamModalType.bind(this);
    this.handleTeamSettingsModal = this.handleTeamSettingsModal.bind(this);

    this.state = {
      createTeamModalType: null,
      inviteToTeamModal: false,
      teamSettingsModal: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      inviteToTeamForm: { entries, isSubmitted }
    } = nextProps;

    if (isEmpty(entries) && isSubmitted) {
      this.handleCloseModal("inviteToTeamModal");
    }
  }

  handleOpenModal(modalType) {
    const { showUpgradeModal, currentSubscriptionFeatures } = this.props;

    switch (modalType) {
      case "createTeamModal": {
        this.setState({ createTeamModalType: "orgTeam" });
        break;
      }

      case "createSubTeamModal": {
        this.setState({ createTeamModalType: "subTeam" });
        break;
      }
      case "inviteToTeamModal": {
        if (currentSubscriptionFeatures.canInviteMembers) {
          this.setState({ inviteToTeamModal: true });
          return;
        }

        showUpgradeModal();
        break;
      }

      default:
        Logger.error("Invalid CreateTeamModal Type");
    }
  }

  handleSubmit(teamDetails) {
    this.props.onSubmitNewTeam(teamDetails);

    this.closeCreateTeamModal();
  }

  closeCreateTeamModal() {
    this.setState({ createTeamModalType: null });
  }

  handleCloseModal(modalName) {
    this.setState({ [modalName]: false });
  }

  changeCreateTeamModalType(type) {
    this.setState({
      createTeamModalType: type
    });
  }

  handleTeamSettingsModal() {
    this.setState({
      teamSettingsModal: !this.state.teamSettingsModal
    });
  }

  render() {
    const {
      currentTeam,
      currentTeamSubscription,
      isAdmin,
      usersTotalCount,
      inviteToTeamForm,
      onInviteToTeam,
      currentPlan,
      updateTeam
    } = this.props;

    return (
      <div className="notranslate" data-testid="TeamPageTopRow">
        <div className={`${style.topRowPrimary} translate`}>
          <div className={`${style.title} notranslate`}>
            <div className={style.teamLogo}>
              <Avatar
                size="80px"
                theme="dark"
                src={currentTeam.logoUrl}
                name={currentTeam.name}
                singleCharacterNameInitial={true}
                charsClassName={style.noAvatarChars}
                doubleBorder
                doubleBorderColor="black"
              />
              <div className={style.teamLogoButton}>
                <UpdateTeamLogoButton
                  hasLogo={!!currentTeam.logoUrl}
                  team={currentTeam}
                  updateTeam={updateTeam}
                />
              </div>
            </div>
            <div className={style.teamName}>{currentTeam.name}</div>
          </div>
          <div className={style.teamButtonsWrapper}>
            {isAdmin && (
              <>
                <TeamSettingsButton showDivider="true" />
                <TeamCreateButton showDivider="true" />
                <AddTeamMembersButton
                  currentTeam={currentTeam}
                  currentTeamSubscription={currentTeamSubscription}
                  inviteToTeamFormState={inviteToTeamForm}
                  onInviteToTeam={onInviteToTeam}
                  usersTotalCount={usersTotalCount}
                  currentPlan={currentPlan}
                  displayCostInformation={true}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
TeamPageTopRow.displayName = "TeamPageTopRow";
export default TeamPageTopRow;
