import * as types from "state/entities/catalogueSizes/catalogueSizesTypes";
import { getPath } from "lib";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 100
};

const api = (state = initState, action) => {
  switch (action.type) {
    case types.CATALOGUE_SIZES_REQUEST: {
      const currentPages = getPath(state, "pages", {});

      const page = {
        [action.request.page]: {
          ...currentPages[action.request.page],
          isFetching: true,
          lastFetched: Date.now()
        }
      };

      return {
        ...state,
        pages: { ...currentPages, ...page }
      };
    }

    case types.CATALOGUE_SIZES_REQUEST_SUCCESS: {
      const currentPages = getPath(state, "pages", {});

      const {
        request: { page },
        response: { ids }
      } = action;
      const pages = {
        ...currentPages,
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false,
          lastFetched: Date.now()
        }
      };

      return {
        ...state,
        pages: pages
      };
    }

    case types.CATALOGUE_SIZES_FILTER_REQUEST: {
      const currentPages = getPath(state, "pages", {});

      const page = {
        [action.request.page]: {
          ...currentPages[action.request.page],
          isFetching: true
        }
      };

      return {
        ...state,
        pages: { ...currentPages, ...page }
      };
    }

    case types.CATALOGUE_SIZES_FILTER_REQUEST_SUCCESS: {
      const currentPages = getPath(state, "pages", {});

      const {
        request: { page },
        response: { ids }
      } = action;
      const pages = {
        ...currentPages,
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false
        }
      };

      return {
        ...state,
        pages: pages
      };
    }

    default:
      return state;
  }
};

export default api;
