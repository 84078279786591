import { isNil } from "lib/lodash";
import { getFontByName } from "lib/fonts";
import { stripHTML } from "lib/textUtils";

const textboxProcess = (element, page) => {
  // Default the name if required.
  element.name = element.name || "Text Field";

  // default letterSpacing
  element.letterSpacing =
    element.letterSpacing === "normal" ? 0 : Number(element.letterSpacing);

  element.textCase = element.textCase || "none";

  // make it resizableX
  element.resizableX = true;
  element.resizableY = true;

  if (element.maskImage) {
    if (isNil(element.maskImage.originalSrc)) {
      element.originalSrc = element.src;
    }
    // the use of previewUrl is deprecated and should be replaced with previewSrc
    if (element.maskImage.previewUrl) {
      if (!element.maskImage.previewSrc) {
        element.maskImage.previewSrc = element.maskImage.previewUrl;
      }
      element.maskImage.previewUrl = undefined;
    }

    // handle animated elements which are not already included in the page animatedElementsObject
    if (
      element.maskImage.duration &&
      page.animatedElements &&
      !Object.keys(page.animatedElements).includes(element.uniqueId)
    ) {
      window.easil.designAdapter.push({
        duration: element.maskImage.duration,
        uniqueId: element.uniqueId,
        animationDataKey: element.uniqueId,
        pageId: page.uniqueId,
        groupId: element.groupId
      });
    }

    // This is fairly gross, however, we seem to have designs that have no previewUrl or previewSrc
    if (!element.maskImage.previewSrc) {
      const elementSrc = element.maskImage.originalSrc || element.maskImage.src;

      const nameStartIdx = elementSrc.lastIndexOf("/") + 1;
      const path = elementSrc.slice(0, nameStartIdx);
      const filename = elementSrc.slice(nameStartIdx);
      let previewFile = `${path}${filename}`;
      if (!filename.startsWith("preview_")) {
        previewFile = `${path}preview_${filename}`;
      }
      element.maskImage.previewSrc = previewFile;
      element.maskImage.src = previewFile;
    }
    element.maskImage.src = element.maskImage.previewSrc;
  }

  // assign displayValue to textbox value if not present
  if (!element.displayValue) {
    element.displayValue = element.value;
  }

  if (!Boolean(element.version)) {
    element.version = 1;
  }

  if (!element.restrictions) {
    element.restrictions = [];
  }

  if (element.version < 1.1) {
    if (element.restrictions.includes("textDecoration")) {
      element.restrictions.push("textCurve");
    }
    element.version = 1.1;
  }

  if (!element.fontId) {
    const font = getFontByName(element.fontFamily);
    if (font) {
      element.fontId = font.fontId;
    }
  }

  // update textbox name property when it has previously been assigned the value
  if (element.name === stripHTML(element.value)) {
    element.name = "Text Field";
  }

  return element;
};

export default textboxProcess;
