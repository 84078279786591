import React from "react";

export const SliderSwatch = ({
  hsl,
  offset,
  onClick = () => {},
  borderRadius,
  active,
  first,
  last
}) => {
  const styles = {
    background: `hsl(${hsl.h}, 50%, ${offset * 100}%)`,
    height: "41px",
    borderRadius: borderRadius || null
  };

  const handleClick = e =>
    onClick(
      {
        h: hsl.h,
        s: 0.5,
        l: offset,
        source: "hsl"
      },
      e
    );

  return <div style={styles} onClick={handleClick} />;
};

export default SliderSwatch;
