import React from "react";
import style from "./style.module.css";

const HoverOverlay = ({ height, width, zoom, position }) => {
  const borderWidth = 1 / zoom;
  const dynamicStyle = {
    height,
    width,
    borderWidth: `${borderWidth}px`,
    position
  };

  return <div className={style.hover} style={dynamicStyle} />;
};

export default HoverOverlay;
