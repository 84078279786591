import React from "react";
import { easilGrayXDark } from "./style.module.css";

const TickCircledIcon = ({ size = 24, color = easilGrayXDark }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm-1.688-10.393l-2.116-2.052a1 1 0 0 0-1.392 1.436l2.812 2.727a1 1 0 0 0 1.393 0l6.187-6a1 1 0 1 0-1.392-1.436l-5.492 5.325z"
        fillRule="oddeven"
      />
    </svg>
  );
};

export default TickCircledIcon;
