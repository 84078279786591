import { getPath } from "lib";
import * as types from "./notificationTypes";

export const initState = {
  pageSize: 100,
  notifications: {
    approval: null
  }
};

const notificationReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_APPROVAL_NOTIFICATIONS_SUCCESS: {
      const entities = getPath(
        action,
        "response.entities.approvalNotification",
        {}
      );

      return {
        ...state,
        notifications: {
          ...state.notifications,
          approval: Object.values(entities)
        }
      };
    }

    default:
      return state;
  }
};

export default notificationReducers;
