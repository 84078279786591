import * as types from "./workspaceSizesTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { getParameterByName } from "lib/queryStringUtils";
import { getCurrentTeamId } from "state/ui/currentTeam/currentTeamSelectors";
import { currentUserIdSelector } from "state/currentUser/currentUserSelectors";
import { pageSizeSelector } from "state/api/workspaceSizes/workspaceSizesApiSelectors";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import PATHS from "routes/paths";
import { noop } from "lib";
import { asyncAction } from "lib/asyncHelpers";

const fetchApprovedSizes = ({ page = 1, params }) => (dispatch, getState) => {
  const state = getState();
  const userId = currentUserIdSelector(state);
  const teamId = getCurrentTeamId(state);

  const approvedParams = {
    ...params,
    status: "APPROVED"
  };

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.WORKSPACE_APPROVED_SIZES_FILTER_REQUEST,
        types.WORKSPACE_APPROVED_SIZES_FILTER_REQUEST_SUCCESS,
        types.WORKSPACE_APPROVED_SIZES_FILTER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/drafts/sizes`,
      request: {
        page: page,
        pageSize: pageSizeSelector(state),
        params: approvedParams
      },
      schema: schemas.WORKSPACE_SIZES
    }
  });
};

const fetchBrandManagerApprovedSizes = ({ page = 1, params }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = getCurrentTeamId(state);

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_FILTER_REQUEST,
        types.WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_FILTER_REQUEST_SUCCESS,
        types.WORKSPACE_BRAND_MANAGER_APPROVED_SIZES_FILTER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/drafts/approved/sizes`,
      request: {
        page: page,
        pageSize: pageSizeSelector(state)
      },
      schema: schemas.WORKSPACE_SIZES
    }
  });
};

const fetchSharedSizes = ({ page = 1 }) => (dispatch, getState) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = state.ui.currentTeam.id;

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.WORKSPACE_SHARED_SIZES_FILTER_REQUEST,
        types.WORKSPACE_SHARED_SIZES_FILTER_REQUEST_SUCCESS,
        types.WORKSPACE_SHARED_SIZES_FILTER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/drafts/shared/sizes`,
      request: {
        page: page,
        pageSize: pageSizeSelector(state)
      },
      schema: schemas.WORKSPACE_SIZES
    }
  });
};

export const fetchAllSizesForCurrentUserTeam = ({
  page = 1,
  isFilter,
  resolve,
  reject
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;

  return dispatch(
    fetchAllSizes({ teamId, userId, page, isFilter, resolve, reject })
  );
};

export const asyncFetchAllSizesForCurrentTeam = asyncAction(
  fetchAllSizesForCurrentUserTeam
);

export const fetchAllSizes = ({
  teamId,
  userId,
  page = 1,
  isFilter,
  resolve = noop,
  reject = noop
}) => (dispatch, getState) => {
  const state = getState();
  const categoryId = getParameterByName(
    "categoryId",
    state.router.location.search
  );
  const term = getParameterByName("term", state.router.location.search);
  const params = {};

  if (categoryId) params.categoryId = categoryId;
  if (term) params.term = term;

  if (PATHS.isWorkspaceShared(state.router.location.pathname)) {
    dispatch(fetchSharedSizes({ page }));
    return;
  }

  if (PATHS.isWorkspaceApproved(state.router.location.pathname)) {
    dispatch(fetchApprovedSizes({ page, params }));
    return;
  }

  if (PATHS.isWorkspaceBrandManagerApproved(state.router.location.pathname)) {
    dispatch(fetchBrandManagerApprovedSizes({ page, params }));
    return;
  }

  /* a bit hacky bit will have to stick with it now as I'm running out of time
     ideally the this would there would be a dedicated action to fetch the workspace
     archived sizes, and it would not overwrite the workspace sizes. */
  if (state.router.location.pathname === PATHS.workspaceArchived) {
    params.archived = true;
  }

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: isFilter
        ? [
            types.WORKSPACE_SIZES_FILTER_REQUEST,
            types.WORKSPACE_SIZES_FILTER_REQUEST_SUCCESS,
            types.WORKSPACE_SIZES_FILTER_REQUEST_FAILURE
          ]
        : [
            types.WORKSPACE_SIZES_REQUEST,
            types.WORKSPACE_SIZES_REQUEST_SUCCESS,
            types.WORKSPACE_SIZES_REQUEST_FAILURE
          ],
      endpoint: `/teams/${teamId}/users/${userId}/drafts/sizes`,
      request: {
        page: page,
        pageSize: pageSizeSelector(state),
        params
      },
      schema: schemas.WORKSPACE_SIZES,
      onSuccess: response => {
        if (
          response &&
          response.ids &&
          response.ids.length === pageSizeSelector(state)
        ) {
          dispatch(fetchAllSizes({ teamId, page: page + 1 }));
        } else {
          resolve(response);
        }
      },
      onFailure: response => reject(response)
    }
  });
};

export const fetchAllWorkspaceSizesForFolder = ({ folderId, page = 1 }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = currentUserIdSelector(state);
  const pageSize = pageSizeSelector(state);
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.WORKSPACE_SIZES_FOLDER_REQUEST,
        types.WORKSPACE_SIZES_FOLDER_REQUEST_SUCCESS,
        types.WORKSPACE_SIZES_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/sizes`,
      request: {
        page,
        pageSize
      },
      extra: {
        folderId
      },
      schema: schemas.WORKSPACE_SIZES,
      onSuccess: response => {
        if (response && response.ids && response.ids.length === pageSize) {
          dispatch(fetchAllSizes({ folderId, page: page + 1 }));
        }
      }
    }
  });
};
