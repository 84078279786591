import Design from "../Design";
import elementProcess from "./elementProcess";
import { omit, camelCase, isEmpty } from "lib/lodash";
import { getAnimationData } from "lib/animatedElementUtils";
import { EDITOR_ELEMENTS_MAP } from "lib/constants";

import DesignHelper from "./DesignHelper";

function Store() {
  const items = {};

  this.add = (id, item) => {
    items[id] = item;
  };

  this.remove = id => {
    delete items[id];
  };

  this.getItems = () => {
    return items;
  };
}

const designAdapter = async design => {
  window.easil.designAdapter = [];
  const elementProcesses = [];
  const elementsStore = new Store();
  const animatedElements = [];

  const processElement = (originalElement, pageGroupIds, page) => {
    const element = { ...originalElement };
    return elementProcess(element, pageGroupIds, page);
  };

  const processPageElements = page => {
    const pageGroupIds = page.groups.map(group => group.id);
    page.elements.forEach(element =>
      elementProcesses.push(processElement(element, pageGroupIds, page))
    );
  };

  const processPageGroups = (groups, elements) => {
    groups.forEach(group => {
      const adjustedGroup = DesignHelper.findElementsMissingFromGroup(
        group,
        elements
      );

      const groupElement = {
        ...omit(adjustedGroup, "elements"),
        type: "group",
        uniqueId: group.id,
        isHidden: !group.visible,
        restrictions: [],
        elementsOrder: adjustedGroup.elements
      };

      elementsStore.add(adjustedGroup.id, groupElement);
    });
  };

  /**
   * Check if the element type is one that we want to display in this tab.
   * @param elementType The type of element
   * @returns {boolean} true if the element is one that we want to display in this tab.
   */
  const isTextElement = elementType =>
    EDITOR_ELEMENTS_MAP.TEXTBOX === elementType ||
    EDITOR_ELEMENTS_MAP.VECTOR_TEXT === elementType;

  const processPage = page => {
    processPageGroups(page.groups, page.elements);
    processPageElements(page);

    const elementsIds = new Set();
    const quickInputElementIds = [];

    page.elements.forEach(processPageElement);

    // Pick out the text elements in each page that we want to display.
    if (!page.quickInputOrder) {
      page.elements.forEach(element => {
        if (isTextElement(element?.type)) {
          quickInputElementIds.push(element.uniqueId);
        }
      });
    }

    const existingQuickInputOrder = Array.from(new Set(page.quickInputOrder));
    const newQuickInputOrder = Array.from(new Set(quickInputElementIds));

    return {
      backgroundColor: page.backgroundColor,
      guides: page.guides,
      elementsOrder: [...elementsIds],
      quickInputOrder: existingQuickInputOrder.length
        ? existingQuickInputOrder
        : newQuickInputOrder,
      duration: page.duration,
      isDurationManual: page.isDurationManual,
      animatedElements: page.animatedElements || {},
      openQuickInput: page.openQuickInput || false
    };

    function processPageElement(element) {
      if (element.groupId && pageGroupExists(element.groupId)) {
        elementsIds.add(element.groupId);
      } else {
        elementsIds.add(element.uniqueId);
      }
    }

    function pageGroupExists(groupId) {
      const group = page.groups.find(group => group.id === groupId);

      return Boolean(group);
    }
  };

  const processPages = pages => {
    return pages.reduce((acc, page) => {
      acc[page.uniqueId] = processPage(page);

      return acc;
    }, {});
  };

  const pagesProcessed = processPages(design.pages);

  /* we have to wait for all the elements to be processed,
   * some elements are processed  async, like  an imageInstruction
   * when it is missing the image color palette */
  await Promise.all(elementProcesses).then(elementsProcessed => {
    elementsProcessed.forEach(elementProcessed => {
      elementsStore.add(elementProcessed.uniqueId, elementProcessed);
    });
  });

  const getRestrictions = () => {
    return design.restrictions.map(restriction => camelCase(restriction));
  };

  // update designData for elements with newly assigned duration properties
  // not already included in the animatedElements object
  const windowDesignAdapter = window.easil.designAdapter;
  if (windowDesignAdapter && !isEmpty(windowDesignAdapter)) {
    windowDesignAdapter.forEach(element => {
      const pageAnimatedElements =
        pagesProcessed[element.pageId].animatedElements;

      // catch when no animatedElements
      // will be assigned in the next if statement
      if (!pageAnimatedElements) return;

      pagesProcessed[element.pageId].animatedElements = {
        ...pageAnimatedElements,
        [element.animationDataKey]: element
      };
    });
  }

  const isAnimatedElementsProcessed = Object.values(pagesProcessed).every(
    page => !!page.animatedElements
  );

  if (!isAnimatedElementsProcessed) {
    // get the adapted elements for this page
    const designElements = elementsStore.getItems();
    Object.values(designElements).forEach(designElement => {
      const page = design.pages.find(
        page =>
          !!page.elements.find(
            element => element.uniqueId === designElement.uniqueId
          )
      );
      if (!page) {
        return;
      }
      //check through each of them if they are animated
      const animationData = getAnimationData({
        ...designElement,
        pageId: page.uniqueId
      });
      if (animationData) {
        animationData.forEach(element => {
          animatedElements.push({
            uniqueId: element.uniqueId,
            animationDataKey: element.animationDataKey,
            duration: element.duration,
            pageId: element.pageId,
            groupId: element.groupId
          });
        });
      }
    });

    // apply the animatedElements to each page
    Object.keys(pagesProcessed).forEach(pageId => {
      const animatedElementsForPage = {};
      animatedElements.forEach(animatedElement => {
        if (animatedElement.pageId === pageId) {
          animatedElementsForPage[
            animatedElement.animationDataKey
          ] = animatedElement;
        }
      });
      pagesProcessed[pageId].animatedElements = animatedElementsForPage;
    });
  } else {
    // ensure we remove any animatedElements from the list that are no longer present
    Object.keys(pagesProcessed).forEach(pageId => {
      const page = pagesProcessed[pageId];
      Object.keys(page.animatedElements).forEach(animatedElementId => {
        const animatedElement = page.animatedElements[animatedElementId];
        if (
          !(
            page.elementsOrder.includes(animatedElement.uniqueId) ||
            page.elementsOrder.includes(animatedElement.groupId)
          )
        ) {
          delete pagesProcessed[pageId].animatedElements[animatedElementId];
        }
      });
    });
  }

  let designData = {
    id: design.id,
    designId: design.designId,
    designDataId: design.designDataId,
    createdAt: design.createdAt,
    updatedAt: design.updatedAt,
    templateCode: design.template.code,
    pagesOrder: design.pages.map(page => page.uniqueId),
    bleed: design.template.bleed,
    width: design.width,
    height: design.height,
    pages: pagesProcessed,
    ordered: design.ordered,
    elements: elementsStore.getItems(),
    fonts: design.fonts,
    version: design.version,
    restrictions: getRestrictions()
  };

  designData = DesignHelper.fixTable2Cells(designData);
  designData = DesignHelper.removeTablesWithNoRows(designData);
  designData = DesignHelper.removeMissingElementsFromGroups(designData);
  designData = DesignHelper.replaceDuplicateElements(designData);
  designData = DesignHelper.replaceHtmlEntitiesInTableCells(designData);
  designData = DesignHelper.removeBrokenTextMask(designData);
  designData = DesignHelper.fixTextMaskWithNoOpacity(designData);

  return new Design(designData);
};

export default designAdapter;
