import { merge } from "lib";
import { getSize as getSvgSize } from "../vector/vectorUtils";

export function leftSizeSizeSum(beginElement, data) {
  const result = [...data].reverse().reduce(
    (acc, element) => {
      if (element === beginElement) {
        return { count: true, size: 0 };
      }
      if (acc.count) {
        return { size: acc.size + (element.size || 1), count: true };
      }
      return acc;
    },
    { count: false, size: 0 }
  );
  return result.size;
}

export function scaleToCover(placeholder = {}, image = {}) {
  const imageSize = {
    width: image.width || image.srcWidth,
    height: image.height || image.srcHeight
  };
  return Math.max(
    placeholder.width / imageSize.width,
    placeholder.height / imageSize.height
  );
}

export function getDefaultImage() {
  return {
    src:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNg+M9QDwADgQF/e5IkGQAAAABJRU5ErkJggg==",
    srcWidth: 1,
    srcHeight: 1
  };
}

export function getDefaultImageInstructionSize(imageInstruction, element) {
  const placeholderSize = getPlaceholderSize(imageInstruction, element);
  const defaultImage = getDefaultImage();
  const originalImageSize = {
    width: parseInt(imageInstruction.srcWidth || defaultImage.srcWidth, 10),
    height: parseInt(imageInstruction.srcHeight || defaultImage.srcHeight, 10)
  };
  const defaultScale = scaleToCover(placeholderSize, originalImageSize);
  const width = originalImageSize.width * defaultScale;
  const height = originalImageSize.height * defaultScale;
  const widthDiff = width - placeholderSize.width;
  const heightDiff = height - placeholderSize.height;

  // I parseFloat here in order to get rid -0 result value
  const top = parseFloat(-heightDiff / 2);
  const left = parseFloat(-widthDiff / 2);

  return { top, left, width, height, scale: defaultScale };
}

function getVectorPlaceholderSize(imageInstruction, element) {
  return getSvgSize(
    element.svg.querySelector(
      `#${imageInstruction.domId} img, #${imageInstruction.domId} image, #${imageInstruction.domId} foreignObject`
    )
  );
}

export function getPlaceholderSize(imageInstruction, element) {
  switch (element.type) {
    case "grid":
      return getGridPlaceholderSize(imageInstruction, element);
    case "vector":
      return getVectorPlaceholderSize(imageInstruction, element);
    default:
      throw new Error(
        `Element with type "${element.type}" does not support image instructions`
      );
  }
}

export function getGridPlaceholderSize(imageInstruction, element) {
  const { width, height } = element;
  return traverseGridSource(imageInstruction.domId, element.source, {
    width,
    height
  });
}

export function traverseGridSource(domId, rootSource, rootDimensionRaw) {
  const rootDimension = merge({ top: 0, left: 0 }, rootDimensionRaw || {});

  if (rootSource.type === "image") {
    return domId === rootSource.domId ? rootDimension : null;
  }

  const { children } = rootSource;
  const totalSizeUnits = children.reduce(
    (acc, childSource) => acc + (childSource.size || 1),
    0
  );

  return children.reduce((acc, childSourceRaw) => {
    const childSource = merge({ size: 1 }, childSourceRaw);
    const leftSideSizeUnits = leftSizeSizeSum(childSourceRaw, children);

    let childDimension = {};

    if (rootSource.type === "row") {
      childDimension = {
        height: rootDimension.height,
        width: rootDimension.width * (childSource.size / totalSizeUnits),
        left:
          rootDimension.left +
          rootDimension.width * (leftSideSizeUnits / totalSizeUnits),
        top: rootDimension.top
      };
    } else {
      childDimension = {
        height: rootDimension.height * (childSource.size / totalSizeUnits),
        width: rootDimension.width,
        left: rootDimension.left,
        top:
          rootDimension.top +
          rootDimension.height * (leftSideSizeUnits / totalSizeUnits)
      };
    }
    return acc || traverseGridSource(domId, childSource, childDimension);
  }, null);
}

export function enhanceVectorImageInstructionWithDefaults(
  imageInstruction,
  element
) {
  return {
    ...getImageInstructionSize(imageInstruction, element),
    src: imageInstruction.src || getDefaultImage().src
  };
}

export function getImageInstructionSize(imageInstruction, element) {
  return merge(
    getDefaultImageInstructionSize(imageInstruction, element),
    imageInstruction
  );
}

export const getDefaultImageInstructionTarget = element => {
  const imageInstructions = element.imageInstructions;

  if (!imageInstructions) {
    return;
  }

  const firstEmptyInstructionIndex = imageInstructions.findIndex(
    instruction => !instruction.src
  );

  if (firstEmptyInstructionIndex === -1) {
    // when none are empty return the first instruction
    return imageInstructions[0];
  }

  return imageInstructions[firstEmptyInstructionIndex];
};
