import * as types from "./portalTypes";

export const showDownloadProof = allowDownloadProof => (dispatch, getState) => {
  dispatch({
    type: types.SHOW_DOWNLOAD_PROOF,
    payload: { allowDownloadProof }
  });
};

export const hidePDFOption = preventPDFDownload => (dispatch, getState) => {
  dispatch({
    type: types.HIDE_PDF_DOWNLOAD,
    payload: { preventPDFDownload }
  });
};
