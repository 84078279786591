import React from "react";
import style from "./style.module.css";
import { isEmpty } from "lib";
import { Input, ToggleCheckbox } from "views/components";

const FontModalDescriptionSection = ({
  teamFont,
  handleChangeTeamFont,
  useFontName,
  handleToggleUseFontName
}) => {
  const handleInputChange = event => {
    handleChangeTeamFont("teamFontName", event.target.value);
  };

  const getError = () => {
    if (useFontName) {
      return null;
    }

    if (!isEmpty(teamFont.teamFontName)) {
      return null;
    }

    return "It can't be blank";
  };

  return (
    <div className={style.fontDescritpionSection}>
      <label className={style.heading}>
        Describe where the Font will be used (optional)
      </label>
      <div className={style.inputAndToggle}>
        <div className={style.inputField} data-disabled={useFontName}>
          <Input
            value={teamFont.teamFontName}
            onChange={handleInputChange}
            error={getError()}
          />
        </div>
        <div className={style.toggleSection}>
          <span>Use Font name</span>
          <ToggleCheckbox
            className={style.toggleCheckBox}
            checked={useFontName}
            height="30px"
            onChange={handleToggleUseFontName}
          />
        </div>
      </div>
    </div>
  );
};

export default FontModalDescriptionSection;
