export const TABS = {
  // STOCK_ANIMATIONS: "STOCK_ANIMATIONS",
  MY_ANIMATIONS: "MY_ANIMATIONS",
  TEAM_ANIMATIONS: "TEAM_ANIMATIONS",
  THIS_DESIGN: "THIS_DESIGN",
  SEARCH: "SEARCH"
};

export const TABS_ORDER = [
  // TABS.STOCK_ANIMATIONS,
  TABS.THIS_DESIGN,
  TABS.MY_ANIMATIONS,
  TABS.TEAM_ANIMATIONS
];

export const TABS_DETAILS = {
  // [TABS.STOCK_ANIMATIONS]: {
  //   key: TABS.STOCK_ANIMATIONS,
  //   label: "Stock Videos"
  // },
  [TABS.THIS_DESIGN]: {
    key: TABS.THIS_DESIGN,
    label: "This Design"
  },
  [TABS.MY_ANIMATIONS]: {
    key: TABS.MY_ANIMATIONS.toString(),
    label: "My Videos"
  },
  [TABS.TEAM_ANIMATIONS]: {
    key: TABS.TEAM_ANIMATIONS.toString(),
    label: "Brand Videos"
  }
};

export const SUBTAB_TYPES = {
  ANIMATIONS: "stickers",
  VIDEOS: "videos",
  ALL: "all"
};

export const SUBTAB_TYPES_MAP = {
  stickers: "animations",
  videos: "videos",
  all: "all"
};

export const ANIMATIONS_SIDEBAR_SUBTABS = {
  videos: {
    label: "Videos",
    key: SUBTAB_TYPES.VIDEOS,
    assetType: "videos",
    contentType: "VIDEO"
  },
  stickers: {
    label: "Stickers",
    key: SUBTAB_TYPES.ANIMATIONS,
    assetType: "animations",
    contentType: "ANIMATION"
  }
};

export const EDITOR_ANIMATION_SUBTABS_OPTIONS = [
  SUBTAB_TYPES_MAP.videos,
  SUBTAB_TYPES_MAP.stickers
];
