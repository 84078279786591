const searchParams = params =>
  Object.keys(params)
    .map(key => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");

export const httpBodyEncoder = ({ body, headers }) => {
  switch (headers["Content-Type"]) {
    case "application/x-www-form-urlencoded":
      return searchParams(body);

    case "multipart/form-data":
      const form = new FormData();

      Object.keys(body).forEach(field =>
        form.append(field.toString(), body[field])
      );

      delete headers["Content-Type"];

      return form;

    default:
      return JSON.stringify(body);
  }
};
