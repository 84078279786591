import React from "react";
import style from "./style.module.css";
import { calculateDisplayMeasurements } from "lib";
import PlusIcon from "views/components/icons/PlusIcon";
import MultiLineEllipsis from "views/components/MultiLineEllipsis/MultiLineEllipsis";
import PrintOptionsButtonContainer from "views/containers/PrintOptionsButtonContainer/PrintOptionsButtonContainer";

const getDisplayMeasurements = design => {
  if (!design) return "";
  const measurements = calculateDisplayMeasurements(design);
  return `${measurements.width} x ${
    measurements.height
  }${measurements.unit.toLowerCase()}`;
};

export const InfoPanelTop = ({ selectedDesign, handleCloseModal }) => {
  const displayMeasurements = getDisplayMeasurements(selectedDesign);

  return (
    <div className={style.infoPanelTop}>
      <div className={style.closeModalButton}>
        <PlusIcon
          className={style.closeButton}
          rotate="45"
          size="18px"
          onClick={handleCloseModal}
        />
      </div>
      <MultiLineEllipsis clamp={4} className={style.selectedDesignTitle}>
        {selectedDesign.title}
      </MultiLineEllipsis>
      <div className={style.selectedDesignSize}>
        {selectedDesign.templateSize} ({displayMeasurements})
      </div>
      <PrintOptionsButtonContainer selectedDesign={selectedDesign} />
    </div>
  );
};

InfoPanelTop.displayName = "InfoPanelTop";

export default InfoPanelTop;
