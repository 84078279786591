import React from "react";
import style from "./style.module.css";

import { Loading } from "views/components";

const LoadingNotificationList = () => {
  return (
    <div className={style.wrapper}>
      <Loading size="24px" />
    </div>
  );
};

LoadingNotificationList.displayName = "LoadingNotificationList";

LoadingNotificationList.propTypes = {};

export default LoadingNotificationList;
