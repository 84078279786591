import React from "react";

const PlanConfig = {
  EDGE: {
    header: "Upgrade to Easil Edge",
    imageUrl: "/images/upgrade_modal/edge.jpg",
    intro: (
      <>
        Unlock <b>premium features</b>, including new high quality
        <br />
        templates daily, discounts on print, and much more!
      </>
    ),
    points: [
      "Access over 20,000 all inclusive Templates",
      "Save 15% on all your print purchases",
      "Priority customer service",
      "Save your brand colors, fonts, logos & images in Brand Kit",
      "Access 1 Million+ free stock images",
      "50 Background Remove credits per month",
      "Share Designs & apply Design Approval Workflow",
      "Set Document & Design Restrictions"
    ]
  },
  PLUS: {
    header: "Upgrade to Easil Plus",
    imageUrl: "/images/upgrade_modal/plus.jpg",
    intro: (
      <>
        Unlock <b>premium features</b>, including new high quality
        <br />
        templates daily, Brand Kit, Design Locking,
        <br />
        Approval Workflow, and much more!
      </>
    ),
    points: [
      "Access over 10,000 all inclusive Templates",
      "Save your brand colors, fonts, logos & images in Brand Kit",
      "Access 1 Million+ free stock images",
      "10 Background Remove credits per month",
      "Create Animated GIFs & Short Videos",
      "Download Transparent PNG & MP4",
      "Share Designs & apply Design Approval Workflow",
      "Set Document & Design Restrictions"
    ]
  }
};

export default PlanConfig;
