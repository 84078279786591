export const FETCH_COUPON = "APP/ENTITIES/SUBSCRIPTIONS/FETCH_COUPON";
export const FETCH_COUPON_SUCCESS =
  "APP/ENTITIES/SUBSCRIPTIONS/FETCH_COUPON_SUCCESS";
export const FETCH_COUPON_FAILURE =
  "APP/ENTITIES/SUBSCRIPTIONS/FETCH_COUPON_FAILURE";

export const FETCH_SUBSCRIPTIONS = "APP/ENTITIES/SUBSCRIPTIONS/FETCH";
export const FETCH_SUBSCRIPTIONS_SUCCESS =
  "APP/ENTITIES/SUBSCRIPTIONS/FETCH_SUCCESS";
export const FETCH_SUBSCRIPTIONS_FAILURE =
  "APP/ENTITIES/SUBSCRIPTIONS/FETCH_FAILURE";

export const UPDATE_SUBSCRIPTION = "APP/ENTITIES/SUBSCRIPTIONS/UPDATE";
export const UPDATE_SUBSCRIPTION_SUCCESS =
  "APP/ENTITIES/SUBSCRIPTIONS/UPDATE_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAILURE =
  "APP/ENTITIES/SUBSCRIPTIONS/UPDATE_FAILURE";
