import React from "react";
import Popout from "../";
import PropTypes from "prop-types";
import Menu, { MenuActionType } from "../Menu";
import { Loading } from "views/components";
import style from "./style.module.css";

const PopoutItemMenu = ({
  actions,
  actionClassName,
  actionsClassName,
  actionNameClassName,
  className,
  footerOpen,
  footerInfo,
  isProcessing,
  itemName,
  menuClassName,
  nameSectionClassName,
  nameBoxClassName,
  onClose,
  onRename,
  buttonId
}) => {
  const composeClassName = () => {
    const classes = [style.popoutItemMenu];

    if (className) {
      classes.push(className);
    }

    return classes.join(" ");
  };

  let buttonRef;

  if (buttonId) {
    const buttonQueryResult = document.querySelector(`#${buttonId}`);
    if (buttonQueryResult) {
      buttonRef = buttonQueryResult;
    }
  }

  return (
    <div
      onClick={e => e.stopPropagation()}
      className={style.guardWrapper}
      data-testid="PopoutItemMenu"
    >
      <Popout
        onClose={onClose}
        className={composeClassName()}
        buttonRef={buttonRef}
      >
        {!isProcessing ? (
          <Menu
            actionClassName={actionClassName}
            actionNameClassName={actionNameClassName}
            actions={actions}
            actionsClassName={actionsClassName}
            footerInfo={footerInfo}
            footerOpen={footerOpen}
            isNameEditable={onRename !== undefined}
            itemName={itemName}
            menuClassName={menuClassName}
            nameBoxClassName={nameBoxClassName}
            nameSectionClassName={nameSectionClassName}
            submitNameChange={onRename}
          />
        ) : (
          <div className={style.loadingContent}>
            <Loading />
          </div>
        )}
      </Popout>
    </div>
  );
};

PopoutItemMenu.displayName = "PopoutItemMenu";

PopoutItemMenu.propTypes = {
  actions: PropTypes.arrayOf(MenuActionType),
  className: PropTypes.string,
  itemName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onRename: PropTypes.func
};

export default PopoutItemMenu;
