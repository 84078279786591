import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TextEditIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 10 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-5-2h20v20H-5z" />
      <g transform="translate(-3)">
        <path d="M0 0h16v16H0z" />
        <path fill={color || easilGrayXDark} d="M7.1 4h1.8v7H7.1z" />
        <rect width="6" height="1.8" x="5" y="7.1" fill="#BDC0C2" rx=".9" />
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M7.1 10a.9.9 0 0 1 1.8 0v1A4.9 4.9 0 0 1 4 15.9a.9.9 0 1 1 0-1.8A3.1 3.1 0 0 0 7.1 11v-1z"
        />
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M8.9 10a.9.9 0 1 0-1.8 0v1a4.9 4.9 0 0 0 4.9 4.9.9.9 0 1 0 0-1.8A3.1 3.1 0 0 1 8.9 11v-1zM7.1 6a.9.9 0 0 0 1.8 0V5A4.9 4.9 0 0 0 4 .1a.9.9 0 0 0 0 1.8A3.1 3.1 0 0 1 7.1 5v1z"
        />
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M8.9 6a.9.9 0 1 1-1.8 0V5A4.9 4.9 0 0 1 12 .1a.9.9 0 0 1 0 1.8A3.1 3.1 0 0 0 8.9 5v1z"
        />
      </g>
    </g>
  </svg>
);
TextEditIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TextEditIcon;
