export const printOptionsStateSelector = state => state.entities.printOptions;
export const isFetchingPrintOptionsSelector = state =>
  printOptionsStateSelector(state).isFetching;

export const templatePrintOptionsSelector = (state, templateCode) =>
  printOptionsStateSelector(state)[templateCode];

export const templatePrintOptionsURLSelector = (state, templateCode) => {
  const printOptionsState = printOptionsStateSelector(state);
  const templatePrintOptions = templatePrintOptionsSelector(
    state,
    templateCode
  );

  if (
    printOptionsState.isFetching ||
    !(templatePrintOptions && templatePrintOptions.templateCode)
  )
    return null;

  return templatePrintOptions.descriptionUrl;
};
