import * as types from "./toasterTypes";

export const initState = {
  toastIds: []
};

const ToasterReducers = (state = initState, action) => {
  switch (action.type) {
    case types.MAKE_TOAST: {
      /* append the toast to the current toastIds in the state */
      const toastIds = state.toastIds.concat([action.toastId]);

      return {
        ...state,
        toastIds
      };
    }

    case types.REMOVE_TOAST: {
      /* remove the provided toastId from the toastIds in state */
      const toastIds = state.toastIds.filter(
        toastId => toastId !== action.toastId
      );
      return {
        ...state,
        toastIds
      };
    }

    case types.REMOVE_ALL_TOAST: {
      /* replace the toastIds in state with an empty array */
      return {
        ...state,
        toastIds: []
      };
    }

    default:
      return state;
  }
};

export default ToasterReducers;
