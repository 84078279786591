import PATHS from "routes/paths";
import Subscriptions from "lib/subscriptions";

const BrandKitFontOps = {
  getFontArr(term) {
    const {
      fonts: { entries }
    } = this.props;
    const fontArr = Object.values(entries || {});

    if ((term || "").trim().length) {
      const regExp = new RegExp(term, "i");
      return fontArr.filter(
        f =>
          regExp.test(f.fontFamilyName) ||
          regExp.test(f.fontName) ||
          regExp.test(f.teamFontName)
      );
    } else {
      return fontArr;
    }
  },

  handleSearchInput(term) {
    const { history } = this.props;

    if ((term || "").trim().length) {
      const termEnc = encodeURIComponent(term);
      history.replace(`${PATHS.brandFonts}?term=${termEnc}`);
    } else {
      history.replace(PATHS.brandFonts);
    }
  },

  handleOpenEditFontModal(index) {
    const {
      fonts,
      showUpgradeModal,
      currentSubscriptionCode,
      setBrandKitState
    } = this.props;
    const subscription = Subscriptions.get(currentSubscriptionCode);

    if (!subscription.getCanAccessBrandKit()) {
      showUpgradeModal();
      return;
    }

    setBrandKitState({
      fontModal: {
        visible: true,
        font: index ? JSON.parse(JSON.stringify(fonts.entries[index])) : null
      }
    });
  }
};

export default BrandKitFontOps;
