import React from "react";
import PhotoFrameDraggableImage from "./PhotoFrameDraggableImage";
import style from "./style.module.css";

const PhotoFrameCropping = ({
  canvasBackground,
  pageOffset,
  vectorElement,
  photoFrame,
  zoom,
  isCropping,
  startPhotoFrameCropMoving,
  finishPhotoFrameCropMoving,
  placeholderDimensions
}) => {
  const cancelClick = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div
      onClick={cancelClick}
      style={{
        height: canvasBackground.offsetHeight,
        width: canvasBackground.offsetWidth,
        overflow: "hidden",
        position: "absolute",
        top: 0,
        left: 0
      }}
    >
      <div
        className={style.photoFrameMasking}
        style={{
          height: canvasBackground.offsetHeight,
          width: canvasBackground.offsetWidth,
          overflow: "hidden"
        }}
      />
      <PhotoFrameDraggableImage
        isCropping={isCropping}
        pageOffset={pageOffset}
        photoFrame={photoFrame}
        vectorElement={vectorElement}
        placeholderDimensions={placeholderDimensions}
        zoom={zoom}
        startPhotoFrameCropMoving={startPhotoFrameCropMoving}
        finishPhotoFrameCropMoving={finishPhotoFrameCropMoving}
      />
    </div>
  );
};

export default PhotoFrameCropping;
