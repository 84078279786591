import React from "react";
import PropTypes from "prop-types";
import _style2 from "./style2.module.css";
import { noop, isEmpty } from "lib";
import { Input } from "../";
import DiagonalArrow from "views/components/icons/DiagonalArrow";
import MagnifierRightIcon from "views/components/icons/MagnifierRightIcon";

const SearchInput = ({
  className,
  placeholder,
  onChange = noop,
  onKeyDown,
  onKeyPress,
  inputClassName,
  searchSuggestions = [],
  handleChangeAndSearch,
  onFocus = noop,
  onBlur = noop,
  clearInput,
  isFocussed,
  suggestionIndexHighlighted = null,
  onMouseEnterSuggestion = noop,
  setInputRef = noop,
  onInputClick = noop,
  value,
  ...props
}) => {
  const style = _style2;
  const isEmptySearch = isEmpty(value);

  return (
    <div
      data-is-focused={isFocussed}
      className={`${style.wrapper} ${className}`}
      ref={setInputRef}
      data-testid="SearchInput"
    >
      <MagnifierRightIcon
        className={style.icon}
        height="20"
        width="20"
        color="#afb3b6"
      />
      <Input
        placeholder={placeholder}
        className={inputClassName || style.input}
        {...props}
        onChange={e => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
        clearInput={clearInput}
        isEmptySearch={isEmptySearch}
        onInputClick={onInputClick}
        value={value}
      />
      {!!searchSuggestions.length && isFocussed && (
        <div className={style.searchSuggestions}>
          <div className={style.searchSuggestionsTitle}>
            Suggestions
            <div className={style.searchSuggestionsBorderCenter} />
          </div>
          {searchSuggestions.map((suggestion, index) => (
            <div
              key={suggestion.searchTerm}
              className={style.suggestion}
              data-is-highlighted={suggestionIndexHighlighted === index}
              onMouseEnter={() => onMouseEnterSuggestion(index)}
              onClick={() => handleChangeAndSearch(suggestion)}
            >
              {suggestion.displayName}
              <DiagonalArrow color="#e2e5e8" className={style.suggestionIcon} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
SearchInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlue: PropTypes.func,
  suggestionIndexHighlighted: PropTypes.number,
  onMouseEnterSuggestion: PropTypes.func
};

export default SearchInput;
