import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TeamPageCol1 from "./TeamPageCol1";

import { pendingUsersApiSelector } from "state/api/pendingUsers/pendingUsersApiSelector";
import {
  cancelTeamInvite,
  resendTeamInvite
} from "state/api/pendingUsers/pendingUsersApiActions";

import {
  usersSelector,
  usersTotalCountSelector,
  teamSelector,
  pendingUsersSelector
} from "state/entities/entitiesSelectors";
import {
  fetchUsersWithParentTeamsIfNeeded,
  fetchPendingUsersIfNeeded,
  fetchPendingUsers,
  fetchTeamIfNeeded,
  onDeleteUser,
  updateUserRoles,
  inviteToTeam
} from "state/entities/entitiesActions";
import {
  teamPageUsersPageChange,
  teamPageUsersTableChange
} from "state/ui/uiActions";
import { userIsAdmin } from "state/currentUser/currentUserSelectors";
import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";
import Subscriptions from "lib/subscriptions";
import { showUpgradeModal } from "state/ui/upgradeModal/upgradeModalActions";
import {
  resendAllTeamInvites,
  cancelAllTeamInvites
} from "state/api/pendingUsers/pendingUsersApiActions";
import {
  searchUsersInTeam,
  searchTeamUsersByRoles
} from "state/entities/users/usersActions";
import {
  allSearchedTeamUsers,
  isSearchingTeamMembers
} from "state/entities/teamUsersSearch/teamUsersSearchSelectors";
import { getIsCurrentTeamOrg } from "state/entities/teams/teamsSelectors";

import style from "./style.module.css";
export class TeamPageCol1Container extends Component {
  static propTypes = {
    usersPaginationCurrentPage: PropTypes.number,
    usersPaginationPageSize: PropTypes.number,
    users: PropTypes.object,
    teamMemberCount: PropTypes.number,
    currentTeam: PropTypes.object,
    onUserUpdate: PropTypes.func,
    onUserInvite: PropTypes.func,
    onUserDelete: PropTypes.func,
    fetchUsersIfNeeded: PropTypes.func,
    fetchTeamIfNeeded: PropTypes.func,
    isAdmin: PropTypes.bool,
    fetchPendingUsersIfNeeded: PropTypes.func
  };

  componentDidMount() {
    const {
      usersPaginationCurrentPage,
      fetchPendingUsersIfNeeded,
      fetchUsersWithParentTeamsIfNeeded,
      currentTeam
    } = this.props;

    fetchUsersWithParentTeamsIfNeeded({
      page: usersPaginationCurrentPage,
      teamId: currentTeam.id
    });
    fetchPendingUsersIfNeeded({ page: usersPaginationCurrentPage });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      usersPaginationCurrentPage,
      pendingUsersPaginationCurrentPage,
      fetchUsersWithParentTeamsIfNeeded,
      fetchPendingUsersIfNeeded,
      users,
      pendingUsers
    } = nextProps;

    if (!users)
      fetchUsersWithParentTeamsIfNeeded({ page: usersPaginationCurrentPage });
    if (!pendingUsers)
      fetchPendingUsersIfNeeded({ page: pendingUsersPaginationCurrentPage });
  }

  render() {
    const {
      cancelAllTeamInvites,
      cancelTeamInvite,
      changeTable,
      currentSubscriptionFeatures,
      currentTable,
      currentTeam,
      currentTeamSubscription,
      currentUser,
      fetchPendingUsers,
      inviteToTeamForm,
      isAdmin,
      isCurrentTeamOrg,
      isPendingUsersProcessing,
      isSearchingTeamUsers,
      onInviteToTeam,
      onSearchUsersWithinTeam,
      onSearchUsersByRoles,
      pendingUsers,
      pendingUsersPaginationCurrentPage,
      pendingUsersPaginationPageSize,
      pendingUsersTotalCount,
      resendAllTeamInvites,
      resendTeamInvite,
      searchedTeamUsers,
      showUpgradeModal,
      teamMemberCount,
      users,
      usersPaginationCurrentPage,
      usersPaginationPageSize
    } = this.props;

    return (
      <TeamPageCol1
        className={style.col1Wrapper}
        isAdmin={isAdmin}
        currentUser={currentUser}
        users={users}
        currentTeam={currentTeam}
        inviteToTeamForm={inviteToTeamForm}
        currentTeamSubscription={currentTeamSubscription}
        onInviteToTeam={() =>
          onInviteToTeam({
            onSuccess: () => fetchPendingUsers({ teamId: currentTeam.id })
          })
        }
        onUserRolesUpdate={this.props.onUserRolesUpdate}
        onUserDelete={this.props.onUserDelete}
        usersPagination={{
          currentPage: usersPaginationCurrentPage,
          pageSize: usersPaginationPageSize
        }}
        pendingUsersPagination={{
          currentPage: pendingUsersPaginationCurrentPage,
          pageSize: pendingUsersPaginationPageSize
        }}
        teamMemberCount={teamMemberCount}
        pendingUsersTotalCount={pendingUsersTotalCount}
        pendingUsers={pendingUsers}
        currentTable={currentTable}
        changeTable={changeTable}
        currentSubscriptionFeatures={currentSubscriptionFeatures}
        showUpgradeModal={showUpgradeModal}
        isPendingUsersProcessing={isPendingUsersProcessing}
        cancelTeamInvite={cancelTeamInvite}
        resendTeamInvite={resendTeamInvite}
        cancelAllTeamInvites={cancelAllTeamInvites}
        resendAllTeamInvites={resendAllTeamInvites}
        onSearchUsersWithinTeam={onSearchUsersWithinTeam}
        onSearchUsersByRoles={onSearchUsersByRoles}
        searchedTeamUsers={searchedTeamUsers}
        isSearchingTeamUsers={isSearchingTeamUsers}
        isCurrentTeamOrg={isCurrentTeamOrg}
      />
    );
  }
}

const mapStateToProps = state => {
  const usersPage = state.ui.teamPage.users.currentPage;
  const pendingUsersPage = state.ui.teamPage.pendingUsers.currentPage;
  const currentTeamSubscription = currentSubscriptionSelector(state);
  const currentTable = state.ui.teamPage.users.currentTable;
  const currentSubscriptionFeatures = Subscriptions.get(
    currentTeamSubscription.code
  );
  const isPendingUsersProcessing = pendingUsersApiSelector({ state })
    .isProcessing;
  const pendingUsersTotalCount = state.api.pendingUsers.totalCount;

  return {
    isAdmin: userIsAdmin(state),
    currentUser: state.currentUser,
    usersPaginationCurrentPage: usersPage,
    usersPaginationPageSize: state.api.users.pageSize,
    pendingUsersPaginationCurrentPage: pendingUsersPage,
    pendingUsersPaginationPageSize: state.api.pendingUsers.pageSize,
    users: usersSelector(state, usersPage),
    teamMemberCount: usersTotalCountSelector(state),
    currentTeam: teamSelector({ state }),
    inviteToTeamForm: state.ui.inviteToTeamForm,
    currentTeamSubscription,
    currentTable: currentTable,
    pendingUsers: pendingUsersSelector(state, pendingUsersPage),
    currentSubscriptionFeatures,
    isPendingUsersProcessing,
    pendingUsersTotalCount,
    searchedTeamUsers: allSearchedTeamUsers(state),
    isSearchingTeamUsers: isSearchingTeamMembers(state),
    isCurrentTeamOrg: getIsCurrentTeamOrg(state)
  };
};

const mapDispatchToProps = dispatch => {
  const onUserDeletion = ({ user, isLastUserInPage }) => {
    dispatch(onDeleteUser({ user }));
    if (isLastUserInPage) dispatch(teamPageUsersPageChange(-1));
  };

  return {
    onUserRolesUpdate: user => dispatch(updateUserRoles(user)),
    onInviteToTeam: args => dispatch(inviteToTeam(args)),
    onUserDelete: args => onUserDeletion(args),
    fetchUsersWithParentTeamsIfNeeded: args =>
      dispatch(fetchUsersWithParentTeamsIfNeeded(args)),
    fetchPendingUsersIfNeeded: args =>
      dispatch(fetchPendingUsersIfNeeded(args)),
    fetchPendingUsers: args => dispatch(fetchPendingUsers(args)),
    fetchTeamIfNeeded: () => dispatch(fetchTeamIfNeeded()),
    changeTable: type => dispatch(teamPageUsersTableChange(type)),
    showUpgradeModal: args => dispatch(showUpgradeModal(args)),
    cancelTeamInvite: args => dispatch(cancelTeamInvite(args)),
    resendTeamInvite: args => dispatch(resendTeamInvite(args)),
    cancelAllTeamInvites: args => dispatch(cancelAllTeamInvites(args)),
    resendAllTeamInvites: args => dispatch(resendAllTeamInvites(args)),
    onSearchUsersWithinTeam: args => dispatch(searchUsersInTeam(args)),
    onSearchUsersByRoles: args => dispatch(searchTeamUsersByRoles(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamPageCol1Container);
