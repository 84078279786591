import PersonIcon from "views/components/icons/PersonIcon";
import CogIcon from "views/components/icons/CogIcon";
import StarIcon from "views/components/icons/StarIcon";
import CubeIcon from "views/components/icons/CubeIcon";

export const ROLES_KEYS = {
  ADMIN: "ADMIN",
  BRAND_MANAGER: "BRAND_MANAGER",
  DESIGNER: "DESIGNER",
  MEMBER: "MEMBER"
};

const ROLES = [
  {
    key: ROLES_KEYS.MEMBER,
    label: "Member",
    icon: PersonIcon,
    description: "Amend editable areas in design templates."
  },
  {
    key: ROLES_KEYS.DESIGNER,
    label: "Designer",
    icon: CubeIcon,
    description: "Create and edit design templates."
  },
  {
    key: ROLES_KEYS.BRAND_MANAGER,
    label: "Brand Manager",
    icon: StarIcon,
    description: "Approve designs, import and manage brand assets."
  },
  {
    key: ROLES_KEYS.ADMIN,
    label: "Admin",
    icon: CogIcon,
    description: "Add, remove and manage team members."
  }
];

export default ROLES;
