import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EditableTitle, AddImageButton, Loading } from "views/components";
import CrossCircledIcon from "views/components/icons/CrossCircledIcon";
import TickCircledIcon from "views/components/icons/TickCircledIcon";
import style from "./style.module.css";
import { getCurrentTeamsSearch } from "state/api/teamsSearch/teamsSearchApiSelector";
import { ChangePasswordButton } from "views/containers/ChangePasswordModalContainer";
import UpdateUserDetailsButton from "views/containers/UpdateUserDetailsModalContainer/UpdateUserDetailsButton/UpdateUserDetailsButton";
import {
  isFetchingUserPreferences as isFetchingUserPreferencesSelector,
  usersPreferencesUpdateErrors as usersPreferencesUpdateErrorsSelector
} from "state/ui/userPreferences/userPreferencesSelectors";
import TeamDefaultChooser from "./TeamDefaultChooser";
import {
  updateUserV2,
  fetchCurrentUserPreferencesIfNeeded,
  updateUserDefaultTeam
} from "state/currentUser/currentUserActions";
import { searchTeams } from "state/entities/teams/teamsActions";
import LogoImage, { LogoImageLoading } from "./LogoImage";

class UserSettingsContainer extends Component {
  static LOGO_UPLOAD_INPUT_ID = "avatarLogoUploadButton";

  constructor(props) {
    super(props);

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleAddImage = this.handleAddImage.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getLogoImage = this.getLogoImage.bind(this);
    this.handleSetDefaultTeam = this.handleSetDefaultTeam.bind(this);
    this.getUpdateStatusIcon = this.getUpdateStatusIcon.bind(this);

    this.state = {
      user: props.user,
      hasUserUpdatedDefaultTeam: false
    };
  }

  componentDidMount() {
    this.props.fetchCurrentUserPreferencesIfNeeded();
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;

    const { user } = this.props;

    this.setState({ user });
  }

  handleUpdate(attribute, value) {
    const { user } = this.state;
    user[attribute] = value;

    this.setState({ user });
    this.handleSave();
  }

  handleAddImage(fileObject) {
    const { user } = this.state;
    const userObject = Object.assign({}, user, { avatar: fileObject });
    this.props.updateUser({ user: userObject });
  }

  handleRemoveImage(event) {
    event.stopPropagation();
    const { user } = this.state;
    const userObject = { ...user, avatar: null, avatarUrl: null };
    this.props.updateUser({ user: userObject });
    this.setState({ user: userObject });
  }

  handleSave() {
    const { user } = this.state;
    user.avatar = user.avatarUrl;

    if (user.avatarUrl) {
      const filename = user.avatarUrl.split("/").pop();
      user.avatar = filename;
    } else {
      user.avatar = "NULL";
    }

    delete user.avatarUrl;
    this.props.updateUser({ user });
  }

  getLogoImage() {
    const {
      settings: { isProcessing }
    } = this.props;

    if (isProcessing) {
      return <LogoImageLoading width="104px" height="104px" />;
    }

    const { user } = this.state;

    if (user.avatarUrl) {
      return <LogoImage src={user.avatarUrl} width="104px" height="104px" />;
    } else {
      return null;
    }
  }

  handleSetDefaultTeam(defaultTeamId) {
    this.setState({
      hasUserUpdatedDefaultTeam: true
    });

    this.props.onSetDefaultTeam(defaultTeamId);
  }

  getUpdateStatusIcon() {
    const {
      isFetchingUserPreferences,
      usersPreferencesUpdateErrors
    } = this.props;

    if (isFetchingUserPreferences) {
      return <Loading size="20px" />;
    }

    if (
      usersPreferencesUpdateErrors &&
      usersPreferencesUpdateErrors.length > 0
    ) {
      return <CrossCircledIcon color="#e64826" />;
    }
    return <TickCircledIcon color="#00bd62" />;
  }

  render() {
    const { user, hasUserUpdatedDefaultTeam } = this.state;

    const {
      settings: { errors }
    } = this.props;

    const avatarUploadStyles = {
      backgroundColor: "#efeff1",
      noBorder: true,
      width: "104px",
      height: "104px"
    };

    return (
      <div className={style.wrapper}>
        <h1 className={style.pageHeader}>{user.name}</h1>
        <div className={style.Tab}>
          <div className={style.TabCol}>
            <div className={style.sectionHeading}>
              <h2>Personal</h2>
            </div>
            <div className={style.formGroup}>
              <div className={style.formGroupLabel}>Full Name</div>
              <EditableTitle
                title={user.name}
                onTitleUpdate={value => this.handleUpdate("name", value)}
                allowEdit={false}
                displayTrashIcon={false}
                className={style.editableInput}
              />
            </div>
            <div className={style.formGroup}>
              <div className={style.formGroupLabel}>Email</div>
              <EditableTitle
                title={user.email}
                onTitleUpdate={value => this.handleUpdate("email", value)}
                allowEdit={false}
                displayTrashIcon={false}
                className={style.editableInput}
              />
            </div>
            <div className={style.formGroup}>
              <UpdateUserDetailsButton />
            </div>
            <div className={style.formGroup}>
              <div className={style.formGroupLabel}>Password</div>
              <ChangePasswordButton className={style.changePasswordButton} />
            </div>
            <div className={style.formGroup}>
              <div className={style.formGroupDefaultTeamHeader}>
                Default Team
              </div>
              <div className={style.formGroupLabel}>Set your default team</div>
              <TeamDefaultChooser
                defaultTeamId={this.props.defaultTeamId}
                teams={this.props.teams}
                teamsSearch={this.props.teamsSearch}
                onSearchTeams={this.props.onSearchTeams}
                onSetDefaultTeam={teamId => this.handleSetDefaultTeam(teamId)}
                className={style.teamChooser}
              />
              <div className={style.defaultTeamContentWrapper}>
                <div className={style.formGroupDefaultTeamSubText}>
                  Your selection will automatically save.
                </div>
                {hasUserUpdatedDefaultTeam && (
                  <div className={style.teamUpdateStatus}>
                    {this.getUpdateStatusIcon()}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={style.TabCol}>
            <div className={style.sectionHeading}>
              <h2>Profile Picture</h2>
            </div>
            <div className={style.LogoUpload}>
              {!user.avatarUrl && (
                <AddImageButton
                  acceptedFileTypes={["png", "jpg", "jpeg"]}
                  className={style.addImageButton}
                  buttonStyles={avatarUploadStyles}
                  buttonBackgroundColor="#efeff1"
                  buttonNoBorder={true}
                  buttonWidth="104px"
                  buttonHeight="104px"
                  inputId={UserSettingsContainer.LOGO_UPLOAD_INPUT_ID}
                  onChange={({ file }) => this.handleAddImage(file)}
                  image={this.getLogoImage()}
                  isUserAvatar={true}
                />
              )}
              {user.avatarUrl && (
                <AddImageButton
                  acceptedFileTypes={["png", "jpg", "jpeg"]}
                  className={style.addImageButton}
                  buttonStyles={avatarUploadStyles}
                  buttonBackgroundColor="#efeff1"
                  buttonNoBorder={true}
                  buttonWidth="104px"
                  buttonHeight="104px"
                  onChange={({ file }) => this.handleAddImage(file)}
                  inputId={UserSettingsContainer.LOGO_UPLOAD_INPUT_ID}
                  image={this.getLogoImage()}
                  isUserAvatar={true}
                />
              )}
              <div>
                {!user.avatarUrl ? (
                  <React.Fragment>
                    <strong>Add a profile image</strong>
                    <br />
                    Must be at least 64 x 64px.
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <label
                      htmlFor={UserSettingsContainer.LOGO_UPLOAD_INPUT_ID}
                      className={style.removePictureButton}
                    >
                      <span onClick={event => this.handleRemoveImage(event)}>
                        Remove
                      </span>
                      {" profile picture"}
                    </label>
                  </React.Fragment>
                )}
              </div>
            </div>
            {errors.avatar && (
              <div className={style.fieldError}>{errors.avatar}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.currentUser,
    settings: state.ui.userSettings,
    teams: state.entities.teams,
    teamsSearch: getCurrentTeamsSearch(state),
    isFetchingUserPreferences: isFetchingUserPreferencesSelector(state),
    usersPreferencesUpdateErrors: usersPreferencesUpdateErrorsSelector(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateUser: args => dispatch(updateUserV2(args)),
    fetchCurrentUserPreferencesIfNeeded: (...args) =>
      dispatch(fetchCurrentUserPreferencesIfNeeded(...args)),
    onSearchTeams: (...args) => dispatch(searchTeams(...args)),
    onSetDefaultTeam: (...args) => dispatch(updateUserDefaultTeam(...args))
  };
};

UserSettingsContainer.propTypes = {
  user: PropTypes.object,
  settings: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettingsContainer);
