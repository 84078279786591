import { DragSource } from "react-dnd";
import TableRow from "./TableRow";

const TableRowSource = {
  beginDrag(props) {
    return {
      id: props.row.id,
      index: props.index,
      row: props.row,
      rowMetadata: props.rowMetadata
    };
  },
  endDrag(props, monitor) {
    if (monitor.getDropResult()) {
      props.onPersistRowMove();
    }
  }
};

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

export default DragSource("TABLE_ROW", TableRowSource, collect)(TableRow);
