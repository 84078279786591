import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import {
  TeamPage,
  TeamSignupPage,
  SignupPage,
  SignupTeamDetailsPage,
  BrandPage,
  UserSettingsPage,
  OrdersPage,
  OrderPage,
  ResetPasswordPage,
  ResetPasswordWithTokenPage,
  CartPage,
  CartCompletedPage,
  DesignRemixPage,
  CollectionRemixPage,
  LoginPage,
  InvitationAcceptancePage,
  PurchaseCollectionPage
} from "views/pages";
import EditorContainer from "views/containers/editor";
import SubscriptionsPageContainer from "views/containers/SubscriptionsPageContainer";
import DesignsPage from "views/pages/designsPage";
import UpgradeSuccessPage from "views/pages/upgradeSuccessPage";
import withUser from "views/enhancers/withUser";
import PATHS from "./paths";

import RenderPage from "views/pages/RenderPage/RenderPageLazy";

const UserRoutes = withUser(() => (
  <Switch>
    <Route exact path={PATHS.team} component={TeamPage} />
    <Route path={PATHS.designs} component={DesignsPage} />
    <Route path={PATHS.brand} component={BrandPage} />
    <Route path={PATHS.subscription} component={SubscriptionsPageContainer} />
    <Route path={PATHS.settings} component={UserSettingsPage} />
    <Route path={PATHS.editorDesign} component={EditorContainer} />
    <Redirect from={PATHS.editor} to={PATHS.designs} />
    <Route exact path={PATHS.ordersId} component={OrderPage} />
    <Route path={PATHS.orders} component={OrdersPage} />
    <Route exact path={PATHS.cartComplete} component={CartCompletedPage} />
    <Route path={PATHS.cart} component={CartPage} />
    <Route exact path={PATHS.remix} component={DesignRemixPage} />
    <Route exact path={PATHS.remixCollection} component={CollectionRemixPage} />
    <Route
      path={PATHS.purchaseCollectionWithCollectionId}
      component={PurchaseCollectionPage}
    />
    <Redirect from="*" to={PATHS.designs} />
  </Switch>
));

const Routes = () => (
  <Switch>
    <Route exact path={PATHS.signup} component={SignupPage} />
    <Route exact path={PATHS.signupTeam} component={TeamSignupPage} />
    <Route exact path={PATHS.signupTeams} component={TeamSignupPage} />
    <Route path={PATHS.signupTeamDetails} component={SignupTeamDetailsPage} />
    <Route exact path={PATHS.signupWithToken} component={SignupPage} />
    <Route path={PATHS.login} component={LoginPage} />
    <Route
      path={PATHS.invitationsWithToken}
      component={InvitationAcceptancePage}
    />
    <Route
      exact
      path={PATHS.resetPasswordWithToken}
      component={ResetPasswordWithTokenPage}
    />
    <Route path={PATHS.resetPassword} component={ResetPasswordPage} />
    <Route path={PATHS.subscriptionSuccess} component={UpgradeSuccessPage} />
    <Route path={PATHS.renderer} component={RenderPage} />
    <UserRoutes />
  </Switch>
);

export default Routes;
