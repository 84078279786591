import { getPath } from "lib";

export const teamSizesApiIdsSelector = state => {
  const teamPagesForScope = getPath(state, `api.teamSizes.pages`, {});
  return Object.values(teamPagesForScope).reduce(
    (ids, page) => [...ids, ...getPath(page, "ids", [])],
    []
  );
};

export const teamSizesArchivedApiIdsSelector = state => {
  const teamPagesForScope = getPath(state, "api.teamSizes.archived.pages", {});
  return Object.values(teamPagesForScope).reduce(
    (ids, page) => [...ids, ...getPath(page, "ids", [])],
    []
  );
};

export const teamSizesInFolderApiIdsSelector = ({ state, folderId }) => {
  const teamPagesForScope = getPath(
    state,
    `api.teamSizes.folders.${folderId}.pages`,
    {}
  );
  return Object.values(teamPagesForScope).reduce(
    (ids, page) => [...ids, ...getPath(page, "ids", [])],
    []
  );
};
