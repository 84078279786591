import { createSelectorCreator, defaultMemoize } from "reselect";
import { castArray, pick, isEmpty, omit, isEqual } from "lib";
import { TEAM_DEPTH_INDEX_LIMIT, ORG_DEPTH_INDEX } from "lib/constants";
import { allTeamsIdsSelector } from "state/api/teams/teamsApiSelector";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const currentTeamIdSelector = state => state.ui.currentTeam.id;

export const currentTeamSelector = state =>
  teamSelector({ state, teamId: state.ui.currentTeam.id });

export const getIsCurrentTeamOrg = state =>
  currentTeamSelector(state).parentPathArr.length === ORG_DEPTH_INDEX;

export const getIsCurrentTeamAtDepthLimit = state =>
  currentTeamSelector(state).parentPathArr.length === TEAM_DEPTH_INDEX_LIMIT;

export const isInCurrentSubteam = state =>
  currentTeamSelector(state).parentPathArr.length > 1;

export const teamSelector = ({ state, teamId }) => {
  if (!teamId) return state.entities.teams[state.ui.currentTeam.id];

  return state.entities.teams[teamId];
};

export const teamsStoreSelector = state => state.entities.teams;

export const teamsTotalCountSelector = state => state.api.teams.totalCount;

const teamsPagesSelector = ({ state }) => state.api.teams.parentIds.all.pages;
const teamsEntitiesSelector = ({ state }) => state.entities.teams;

const pageNumberPassThrough = ({ pageNumber = 1 }) => pageNumber;
const excludePassThrough = ({ exclude = [] }) => exclude;

const processTeamsSelector = (
  teamPages,
  teamsEntities,
  pageNumber,
  exclude
) => {
  const page = teamPages[pageNumber];

  if (!page) return null;

  if (!page.ids) return null;

  let teams = pick(teamsEntities, page.ids);

  if (!isEmpty(exclude)) {
    teams = omit(teams, castArray(exclude));
  }

  return teams;
};

export const teamsSelector = createDeepEqualSelector(
  [
    teamsPagesSelector,
    teamsEntitiesSelector,
    pageNumberPassThrough,
    excludePassThrough
  ],
  processTeamsSelector
);

/**
 * @desc - processing function that takes in entities and an array of teamIds and returns the entities from the id array
 * @param {Object} teamsEntities - an object containing key-value pairs of format { teamId: teamEntity }
 * @param {string[]} exclude - an array of team ids indicating teams to be filtered out of the output object
 * @param {string[]} allTeamsIds - an array of ids for all all teams currently in the api state
 * @returns {Object[]} an object array containing entities for all currently stored api teamIds
 */
const processTeamsSelectorAll = (teamsEntities, exclude = [], allTeamsIds) => {
  const teamIds = allTeamsIds.filter(id => !exclude.includes(id));
  const teams = teamIds.reduce(
    (teamObject, id) =>
      Object.assign({}, teamObject, { [id]: teamsEntities[id] }),
    {}
  );
  return teams;
};

export const teamsSelectorAll = createDeepEqualSelector(
  [teamsEntitiesSelector, excludePassThrough, allTeamsIdsSelector],
  processTeamsSelectorAll
);
