import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const Table2Container = lazy(() => import("./Table2Container"));

export const Table2Lazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <Table2Container {...props} />
  </Suspense>
);
Table2Lazy.displayName = "Table2";

export default Table2Lazy;
