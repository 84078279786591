import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BrandKit from "views/components/BrandKit/BrandKit";

/* Actions */
import { showUpgradeModal } from "state/ui/upgradeModal/upgradeModalActions";
import {
  fetchBrandKitColorGroups,
  addBrandKitColorGroup,
  removeBrandKitColorGroup,
  updateBrandKitColorGroup,
  searchBrandKitImages,
  searchBrandKitLogos,
  updateBrandKitImage,
  removeBrandKitImage,
  addBrandKitImage,
  setSelectedColor,
  addBrandKitLogo,
  updateBrandKitLogo,
  removeBrandKitLogo,
  fetchBrandKitFontsIfNeeded,
  addBrandKitFont,
  removeBrandKitFont,
  asyncPartialUpdateBrandKitFont as partialUpdateBrandKitFont,
  refetchBrandKitImages,
  refetchBrandKitLogos
} from "state/ui/brandKit/BrandKitActions";
import {
  createTeamFolder,
  deleteTeamFolder,
  fetchAllTeamFolders,
  updateTeamFolder
} from "state/entities/folders/foldersActions";
import {
  addContentToTeamFolder,
  removeContentFromTeamFolder
} from "state/entities/designFolders/designFoldersActions";
import { modifyQuery } from "state/ui/navigation/navigationActions";

/* Selectors */
import { userIsBrandManager } from "state/currentUser/currentUserSelectors";
import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import { foldersEntitiesSelector } from "state/entities/folders/foldersSelectors";
import { getTeamAnimationsNotInFolders } from "state/entities/teamAnimations/teamAnimationsSelectors";
import { teamAnimationsAllFirstPageIsFetching } from "state/api/teamAnimations/teamAnimationsApiSelectors";
import { getFolderForMedia } from "state/entities/folders/foldersActions";
import { mediaInFolders } from "state/api/folders/media/apiMediaFoldersSelectors";

import Subscriptions from "lib/subscriptions";
import {
  deleteTeamSmartImage,
  fetchTeamSmartImages
} from "state/entities/teamSmartImages/teamSmartImagesActions";
import { teamSmartImagesEntitiesSelector } from "state/entities/teamSmartImages/teamSmartImagesSelectors";

export const BrandKitContainer = props => <BrandKit {...props} />;

BrandKitContainer.propTypes = {
  addContentToTeamFolder: PropTypes.func.isRequired,
  brandKitImages: PropTypes.object,
  brandKitLogos: PropTypes.object,
  removeContentFromTeamFolder: PropTypes.func.isRequired,
  currentSubscriptionCode: PropTypes.string,
  colors: PropTypes.object,
  selectedColor: PropTypes.number,
  currentTeam: PropTypes.object,
  isBrandManager: PropTypes.bool,
  fetchBrandKitColorGroups: PropTypes.func,
  createTeamFolder: PropTypes.func.isRequired,
  deleteTeamFolder: PropTypes.func.isRequired,
  fetchAllTeamFolders: PropTypes.func.isRequired,
  updateTeamFolder: PropTypes.func.isRequired,
  addBrandKitColorGroup: PropTypes.func,
  removeBrandKitColorGroup: PropTypes.func,
  updateBrandKitColorGroup: PropTypes.func,
  searchBrandKitImages: PropTypes.func.isRequired,
  searchBrandKitLogos: PropTypes.func.isRequired,
  updateBrandKitImage: PropTypes.func,
  removeBrandKitImage: PropTypes.func,
  addBrandKitImage: PropTypes.func,
  setSelectedBrandColor: PropTypes.func,
  showUpgradeModal: PropTypes.func,
  fonts: PropTypes.object,
  userFonts: PropTypes.object,
  addBrandKitLogo: PropTypes.func,
  updateBrandKitLogo: PropTypes.func,
  removeBrandKitLogo: PropTypes.func,
  fetchBrandKitFontsIfNeeded: PropTypes.func,
  fetchTeamSmartImages: PropTypes.func,
  partialUpdateBrandKitFont: PropTypes.func,
  removeBrandKitFont: PropTypes.func,
  addBrandKitFont: PropTypes.func
};

const mapStateToProps = state => {
  const currentSubscription = currentSubscriptionPlan(state);
  const canAccessBrandKit = Subscriptions.get(
    currentSubscription.code
  ).getCanAccessBrandKit();

  return {
    brandKitImages: state.ui.brandKit.images,
    brandKitLogos: state.ui.brandKit.logos,
    canAccessBrandKit,
    currentSubscriptionCode: currentSubscription.code,
    currentTeam: state.ui.currentTeam,
    isBrandManager: userIsBrandManager(state),
    colors: state.ui.brandKit.colors,
    selectedColor: state.ui.brandKit.colors.selected,
    teamFolders: {
      entries: foldersEntitiesSelector(state),
      ...state.ui.teamFolders
    },
    fonts: state.ui.brandKit.fonts, // Move to selector
    userFonts: state.entities.userTeamFonts,
    brandKitAnimations: getTeamAnimationsNotInFolders(state),
    smartImages: teamSmartImagesEntitiesSelector(state),
    isFetchingBrandAnimations: teamAnimationsAllFirstPageIsFetching(state),
    mediaInFolders: mediaInFolders(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createTeamFolder: (...args) => dispatch(createTeamFolder(...args)),
    deleteTeamFolder: (...args) => dispatch(deleteTeamFolder(...args)),
    fetchAllTeamFolders: (...args) => dispatch(fetchAllTeamFolders(...args)),
    updateTeamFolder: (...args) => dispatch(updateTeamFolder(...args)),
    fetchBrandKitColorGroups: teamId =>
      dispatch(fetchBrandKitColorGroups({ teamId })),
    addBrandKitColorGroup: (...args) =>
      dispatch(addBrandKitColorGroup(...args)),
    removeBrandKitColorGroup: ({ teamId, groupId }) =>
      dispatch(removeBrandKitColorGroup({ teamId, groupId })),
    updateBrandKitColorGroup: ({ teamId, groupId, colors, name }) =>
      dispatch(updateBrandKitColorGroup({ teamId, groupId, colors, name })),
    addContentToTeamFolder: (...args) =>
      dispatch(addContentToTeamFolder(...args)),
    removeContentFromTeamFolder: (...args) =>
      dispatch(removeContentFromTeamFolder(...args)),
    searchBrandKitImages: (...args) => dispatch(searchBrandKitImages(...args)),
    searchBrandKitLogos: (...args) => dispatch(searchBrandKitLogos(...args)),
    updateBrandKitImage: (...args) => dispatch(updateBrandKitImage(...args)),
    removeBrandKitImage: (...args) => dispatch(removeBrandKitImage(...args)),
    addBrandKitImage: args => dispatch(addBrandKitImage(args)),
    setSelectedBrandColor: args => dispatch(setSelectedColor(args)),
    showUpgradeModal: args => dispatch(showUpgradeModal(args)),
    addBrandKitLogo: ({ teamId, file, onSuccess }) =>
      dispatch(addBrandKitLogo({ teamId, file, onSuccess })),
    updateBrandKitLogo: (...args) => dispatch(updateBrandKitLogo(...args)),
    removeBrandKitLogo: (...args) => dispatch(removeBrandKitLogo(...args)),
    fetchBrandKitFontsIfNeeded: teamId =>
      dispatch(fetchBrandKitFontsIfNeeded({ teamId })),
    partialUpdateBrandKitFont: ({ teamId, id, params }) =>
      dispatch(partialUpdateBrandKitFont({ teamId, id, params })),
    removeBrandKitFont: ({ teamId, id }) =>
      dispatch(removeBrandKitFont({ teamId, id })),
    addBrandKitFont: ({ teamId, params }) =>
      dispatch(addBrandKitFont({ teamId, params })),
    modifyQuery: args => dispatch(modifyQuery(args)),
    refetchBrandKitImages: args => dispatch(refetchBrandKitImages(args)),
    refetchBrandKitLogos: args => dispatch(refetchBrandKitLogos(args)),
    getFolderForMedia: args => dispatch(getFolderForMedia(args)),
    fetchTeamSmartImages: args => dispatch(fetchTeamSmartImages(args)),
    onDeleteTeamSmartImage: args => dispatch(deleteTeamSmartImage(args))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandKitContainer);
