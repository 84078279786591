import { cloneDeep, Logger, omit, pick } from "lib";
import { TEXTBOX_COLLECTION_PROPAGATION_ACTIONS } from "lib/constants";
import { flipSelectionOnAxis } from "views/components/Editor/utils";
import { onColorChange } from "views/components/Editor/editorOps/Editor.onColorChange";
import ActionBarOps from "views/components/Editor/actionbar/ActionBarOps";
import { removeItem } from "lib/array/array";
import { EditorTableOps } from "views/components/Editor/editorOps/index";

const EditorElementsOps = {
  /**
   * Align the selected items according to the alignment parameter provided.
   * @param alignment - Alignment request for the selected elements.
   * @param {object} containerProps - Props of the calling container.
   */
  alignElements(alignment, containerProps) {
    Logger.info("EditorElementsOps.alignElements called");
    const { designData, selectedItems } = containerProps;

    const updatedDesignData = designData.alignElements(
      selectedItems,
      alignment
    );

    containerProps.onSave(updatedDesignData, {});
    containerProps.updateContextState({
      designData: updatedDesignData
    });
  },

  /**
   * Copies the selected items and returns and object with the newly updated design data and the
   * copied elements as the newly selectedItems.
   *
   * @param {Array} selectedItems - Current selected items.
   * @param {object} designData - Design data to operate on.
   * @return {object} containing the updated design data and the copied elements as the selectedItems
   */
  copyElements(selectedItems, designData) {
    // guard case for if copy is initiated with no selection
    if (!selectedItems.length) return;

    const { designUpdated, newIds } = designData.copyElements({
      elements: selectedItems
    });

    const newlySelectedItems = newIds.map(newElementId => ({
      itemId: newElementId,
      groupId: null,
      pageId: selectedItems[0].pageId,
      preview: {}
    }));

    return {
      designData: designUpdated,
      selectedItems: newlySelectedItems
    };
  },

  /**
   * Handle the color attribute change and save the design.
   *
   * @param {object} attributes - Color attributes to change.
   * @param {object} containerProps - Props of the calling container.
   */
  handleColorChange(attributes, containerProps) {
    const {
      context,
      currentPageId,
      designData,
      selectedItems,
      elementPreviews: propsElementPreviews,
      smartTextState
    } = containerProps;
    const elementPreviews = onColorChange({
      ...attributes,
      context,
      currentPageId,
      designData,
      selectedItems,
      stateElementPreviews: propsElementPreviews,
      smartTextState
    });
    containerProps.updateContextState({ elementPreviews });
  },

  /**
   * Copy the selected elements, update the design data and select the new elements.
   * @param {object} containerProps - Props of the calling container.
   */
  onElementsCopy(containerProps) {
    Logger.info("EditorElementOps.onElementsCopy called");
    const { designData, selectedItems } = containerProps;
    const {
      designData: updatedDesignData,
      selectedItems: updatedSelection
    } = EditorElementsOps.copyElements(selectedItems, designData);

    containerProps.onSave(updatedDesignData, {});
    containerProps.updateContextState({
      designData: updatedDesignData,
      selectedItems: updatedSelection
    });
  },

  /**
   * Update the selected items with the provided previews.
   * @param {object} containerProps - Props of the calling container
   * @param {object} previewUpdates - An object containing all the previews to base the
   * selectedItem updates on.
   */
  onElementsPreview(previewUpdates, containerProps) {
    const { designData, selectedItems } = containerProps;
    const updatedSelectedItems = EditorElementsOps.updateSelectedItemsFromPreviews(
      designData,
      selectedItems,
      previewUpdates
    );

    const updatedSelection = updatedSelectedItems.filter(x => x);
    containerProps.updateContextState({ selectedItems: updatedSelection });
  },

  /**
   * Persist the updates in the element previews to the design data.
   * @param {object} containerProps - Props of the calling container
   */
  onElementPreviewPersist(containerProps) {
    const { designData, elementPreviews } = containerProps;
    const updatedDesignData = EditorElementsOps.updateElements(
      designData,
      elementPreviews
    );

    containerProps.updateContextState({
      designData: updatedDesignData,
      elementPreviews: {}
    });
    containerProps.onSave(updatedDesignData, {});
  },

  /**
   * @desc flips an image instruction (grid cell, photo frame cell) based on axis
   * @param {object} designData - DesignData for the design containing the image instruction.
   * @param {Array<object>} selectedItems - Array of selected item objects.
   * @param {string} axis - x or y. Passed in from corresponding flipping button
   * @param {string} domId - the id representing the selected grid cell
   * ^ can also be located in context
   * @returns {object} - updated copy of the designData object
   */
  flipImageInstruction(designData, selectedItems, axis, domId) {
    // only one selected element when single cell has been selected
    const selectedElementData = cloneDeep(
      designData.getElement(selectedItems[0].itemId)
    );
    const selectedImageInstruction = selectedElementData.imageInstructions.find(
      instruction => instruction.domId === domId
    );

    if (!selectedImageInstruction) return;

    // get updated top/left values based on axis flipping
    const elementUpdates = flipSelectionOnAxis(axis, [
      selectedImageInstruction
    ]);
    // updated values returned with imageInstruction uniqueId as key
    const updatedValues = elementUpdates[selectedImageInstruction.uniqueId];

    // spread new values with existing image instruction
    const updatedImageInstruction = {
      ...selectedImageInstruction,
      ...updatedValues
    };

    // find index for original image instruction and replace with updated instruction
    const selectedImageInstructionIndex = selectedElementData.imageInstructions.findIndex(
      instruction => instruction.domId === domId
    );
    selectedElementData.imageInstructions.splice(
      selectedImageInstructionIndex,
      1,
      updatedImageInstruction
    );

    return designData.updateImageInstructions({
      elementId: selectedElementData.uniqueId,
      imageInstructions: selectedElementData.imageInstructions
    });
  },

  // allow updating for multiple elements multiple attributes
  onElementsAttributesChange(elementUpdates, callback) {
    Logger.info("Editor.onElementsAttributesChange called");

    const { designData } = this.props;

    this.updateStateAndSave(
      {
        designData: designData.updateElementsAttributes({
          elementUpdates
        })
      },
      callback
    );
  },

  onSelectedElementsAttributesChange(
    attributes,
    selectedItems = this.props.selectedItems,
    callback
  ) {
    Logger.info("Editor.onElementAttributeChange called");

    const { designData } = this.props;
    const selectedElementsData = selectedItems.map(({ itemId, pageId }) => {
      return {
        ...designData.elements[itemId],
        pageId
      };
    });

    // we need to ensure that preview values are only being cleared when we are applying them or intentionally clearing them
    // get the previews for the currently selected items
    const selectedItemsWithPreviews = pick(
      this.props.elementPreviews,
      selectedItems.map(item => item.itemId)
    );
    const updatedSelectedPreviews = Object.keys(
      selectedItemsWithPreviews
    ).reduce((updatedPreviews, previewKey) => {
      const currentPreview = selectedItemsWithPreviews[previewKey];
      // remove the new saving attributes from the preview so we don't get overlaps of preview data
      return {
        ...updatedPreviews,
        [previewKey]: omit(currentPreview, Object.keys(attributes))
      };
    }, {});

    const attributeAction = Object.keys(attributes)[0];
    const isAttributeActionPermitted = TEXTBOX_COLLECTION_PROPAGATION_ACTIONS.includes(
      attributeAction
    );
    // if design is part of a collection and permitted textbox attributes were updated
    // add action to propagation queue
    if (
      this.props.collectionDesigns.length > 1 &&
      selectedElementsData.every(element => element.type === "textbox") &&
      isAttributeActionPermitted
    ) {
      this.props.addActionToPropagationQueue({
        selectedElementsData,
        actionType: "textboxAttributeChange",
        attributes
      });
    }

    // persist the current elementPreviews as well as updated
    const updatedPreviews = {
      ...this.state.elementPreviews,
      ...updatedSelectedPreviews
    };

    this.updateStateAndSave({
      designData: designData.updateElementsAttribute(
        {
          elementsId: selectedItems.map(item => item.itemId),
          attributes
        },
        callback
      ),
      lastAttributeChanges: selectedItems.map(({ itemId, pageId }) => ({
        attributes,
        uniqueId: itemId,
        pageId,
        originalElement: designData.elements[itemId]
      })),
      elementPreviews: updatedPreviews
    });
  },

  /**
   * Flip a selected item along the provided axis.
   * @param {string} axis - 'x' or 'y' access
   * @param {object} containerProps - Calling container props.
   */
  onFlipSelection(axis, containerProps) {
    Logger.info("EditorElementsOps.onFlipSelection called");
    const { selectedItems, designData } = containerProps;

    const selectedElements = [];
    selectedItems.forEach(selectedItem => {
      const dataElement = designData.getElement(selectedItem.itemId);
      if (dataElement.type === "group") {
        dataElement.elementsOrder.forEach(elementId => {
          selectedElements.push(designData.getElement(elementId));
        });
      } else {
        selectedElements.push(dataElement);
      }
    });

    const elementUpdates = flipSelectionOnAxis(axis, selectedElements);
    ActionBarOps.onElementsAttributesChange(elementUpdates, containerProps);
  },

  isAnySelectedElementRestrictedForPosition() {
    return this.getSelectedAsElements().some(element => !element.isResizable());
  },

  /**
   * Take the selected items and select grouped elements instead of groups where applicable
   */
  flattenSelection(selectedItems, designData) {
    const selectedElements = selectedItems.map(selectedItem =>
      designData.getElement(selectedItem.itemId)
    );

    const newSelection = [];
    selectedElements.forEach((selectedElement, elementIndex) => {
      if (selectedElement.type === "group") {
        selectedElement.elementsOrder.forEach(groupElementId => {
          const groupElement = designData.getElement(groupElementId);
          newSelection.push({
            groupId: groupElement.groupId,
            itemId: groupElement.uniqueId,
            pageId: selectedItems[elementIndex].pageId
          });
        });
      } else {
        newSelection.push(selectedItems[elementIndex]);
      }
    });
    return newSelection;
  },

  getSelectedAsElements() {
    const { selectedItems, designData } = this.props;

    return selectedItems.map(selectedItem =>
      designData.getElement(selectedItem.itemId)
    );
  },

  /**
   * Get the restrictions for each selected item and return an array of selected items with their
   * restrictions map populated.
   * @param {object} designData - The design data to retrieve the elements from.
   * @param {Array<object>} selectedItems - Array of selected items
   * @returns {Array<object>} array of selected items with
   */
  getSelectedElementsWithRestrictions(designData, selectedItems) {
    return selectedItems.map(item => {
      return designData.getElementWithRestrictions(
        Object.assign({}, item, { uniqueId: item.itemId })
      );
    });
  },

  /**
   * @desc - takes any combination of x and y axis offsets and applies
   * these offsets to the positions of all selected elements,
   * designed to only make a single call to updateStateAndSave in order
   * to avoid having too many page refreshes
   * @param {Number} offsetX - the amount to offset the x axis by
   * @param {Number} offsetY - the amount to offset the y axis by
   */
  onSelectedElementsChangePosition({ offsetX, offsetY }) {
    const { selectedItems, designData } = this.props;

    const performMovement = (item, index) => {
      const { top, left } = item;
      const attributes = {};
      if (offsetY) attributes.top = top + offsetY;
      if (offsetX) attributes.left = left + offsetX;

      /* get the updated designData object */
      const data = designData.updateElementsAttribute({
        elementsId: [item.uniqueId],
        attributes
      });

      /* if not the first element then just add the data for the element */
      if (index > 0)
        data.elements = { [item.uniqueId]: data.elements[item.uniqueId] };
      return data;
    };

    /* map through the selectedItems and apply the x or y offset */
    const updateData = selectedItems
      .map((element, index) => {
        const item = designData.getElement(element.itemId);
        if (item.type === "group") {
          /* perform the movement action on all nested elements */
          return item
            .getElements()
            .map((groupNestedElement, i) =>
              performMovement(groupNestedElement, index + i)
            );
        }
        /* if not a group, move it */
        return performMovement(item, index);
      })
      /* merge all group elements where needed */
      .reduce((p, c) => p.concat(Array.isArray(c) ? c : [c]), []);

    const update = updateData[0];
    /* join all of the updated designData objects elements together */
    update.elements = Object.assign(
      {},
      ...updateData.map(data => data.elements)
    );
    this.updateStateAndSave({
      designData: update
    });
  },

  onElementsRestrictionChange(
    { attribute, elementsId, value },
    containerProps
  ) {
    Logger.info("EditorElementsOps.onElementsRestrictionChange called");

    const { designData, selectedItems } = containerProps;

    const updatedDesignData = designData.toggleElementsRestriction({
      elementsId: elementsId || selectedItems.map(item => item.itemId),
      attribute,
      value
    });

    containerProps.updateContextState({ designData: updatedDesignData });
    containerProps.onSave(updatedDesignData, {});
  },

  /**
   * Update the position of the selected item within the designData and return the updated design
   * data.
   *
   * @param {object} moveAction - action to perform on the object
   * @param {object} containerProps - Container properties for the calling container
   */
  onElementPositionChange(moveAction, containerProps) {
    Logger.info("EditorElementOps.onElementPositionChange called");

    const { designData, selectedItems } = containerProps;
    const updatedDesignData = designData.changeElementPosition({
      elementId: selectedItems[0].itemId,
      groupId: selectedItems[0].groupId,
      pageId: selectedItems[0].pageId,
      moveAction: moveAction
    });
    containerProps.updateContextState(updatedDesignData);
    containerProps.onSave(updatedDesignData, {});
  },

  deleteItem({ id, pageId, groupId }) {
    Logger.info("Editor.deleteItem called");
    const { designData } = this.props;

    this.updateStateAndSave({
      designData: designData.deleteElement({ elementId: id, groupId, pageId }),
      selectedItems: [],
      isDragging: false
    });
  },

  /**
   * Select a single items.
   * @param {string} itemId - Item to be selected.
   * @param {string} pageId - ID of the page the selected item is on
   * @param {string} groupId - ID of the group the item belongs to.
   * @param {number} pageIndex - index of the page.
   * @param {boolean} append - whether to append the item to the selection
   * @param {TextField} textField - A TextField that should be selected.
   * @param {object} containerProps - Calling container properties
   */
  onSelectItem(
    { itemId = null, pageId = null, groupId, pageIndex, append, textField },
    containerProps
  ) {
    Logger.info("EditorElementOps.onSelectItem called");
    const { actionbar, designData, selectedItems } = containerProps;

    if (textField) {
      setTimeout(() => {
        EditorTableOps.selectTableTextField(textField);
      }, 0);
    }

    /* When a group is selected and shift click through to unselect individual elements */
    if (selectedItems.length === 1 && selectedItems[0].itemId === groupId) {
      const groupElements = designData.elements[groupId].elementsOrder;
      const remainingGroupElements = removeItem(
        groupElements,
        groupElements.indexOf(itemId)
      );

      EditorElementsOps.onSelectItems(
        {
          itemsList: remainingGroupElements.map(id => ({
            elementdata: { uniqueId: id, groupId }
          }))
        },
        containerProps
      );
      return;
    }

    const itemIndex = selectedItems.findIndex(item => item.itemId === itemId);

    const isItemAlreadySelected = itemIndex !== -1;

    /* cancel it to avoid re-render if item is already selected */
    if (selectedItems.length === 1 && isItemAlreadySelected) {
      return;
    }

    if (isItemAlreadySelected && append) {
      const actionBarState = {
        ...actionbar,
        buttonActive: null
      };
      const baseEditorState = {
        selectedItems: removeItem(selectedItems, itemIndex)
      };
      containerProps.updateContextState({
        ...baseEditorState,
        context: {}
      });
      containerProps.updateActionBarState(actionBarState);
      return;
    }

    if (!append) {
      const _pageId =
        pageId ||
        designData.pagesOrder[pageIndex] ||
        designData.getElementPageId(itemId);

      const actionBarState = {
        ...containerProps.actionbar,
        buttonActive: null
      };
      const baseEditorState = {
        selectedItems: [
          {
            itemId,
            groupId,
            pageId: _pageId
          }
        ]
      };

      // open up QR Code popout when element is selected
      if (baseEditorState.selectedItems.length === 1) {
        const elementData = designData.getElement(itemId);
        actionBarState.buttonActive =
          elementData.type === "qrcode" ? "qrvalue" : null;
      }

      containerProps.updateContextState({
        ...baseEditorState,
        context: {}
      });
      containerProps.updateActionBarState(actionBarState);
      return;
    }

    const actionBarState = {
      ...actionbar,
      buttonActive: null
    };
    const baseEditorState = {
      selectedItems: [
        ...containerProps.selectedItems,
        {
          itemId,
          groupId,
          pageId: pageId || designData.pagesOrder[pageIndex]
        }
      ]
    };

    containerProps.updateContextState({
      ...baseEditorState,
      context: {}
    });
    containerProps.updateActionBarState(actionBarState);
  },

  /**
   * Select multiple items.
   * @param {Array<object>} itemsList - Items to be selected.
   * @param {object} containerProps - Calling container properties
   */
  onSelectItems({ itemsList }, containerProps) {
    Logger.info("EditorElementOps.onSelectItems called");
    const actionBarState = {
      ...containerProps.actionbar,
      buttonActive: null
    };
    const baseEditorState = {
      selectedItems: itemsList.map(item => {
        return {
          itemId: item.elementdata.uniqueId,
          groupId: item.elementdata.groupId,
          pageId: containerProps.currentPageId
        };
      })
    };
    containerProps.updateContextState({
      ...baseEditorState,
      context: {}
    });
    containerProps.updateActionBarState(actionBarState);
  },

  /**
   * Toggle the visibility of the provided element.
   * @param {string} elementId - ID of the element to toggle.
   * @param {boolean} isGroupHidden - Is the containing group hidde?
   * @param {object} containerProps - Calling container props.
   */
  toggleHideElement({ elementId, isGroupHidden }, containerProps) {
    Logger.info("EditorElementsOps.toggleHideElement called");
    const { designData } = containerProps;

    const updatedDesignData = designData.toggleHideElement({
      elementId,
      isGroupHidden
    });
    containerProps.updateContextState(updatedDesignData);

    // Reset previews for selected items
    const updatedSelectedItems = containerProps.selectedItems.map(item => ({
      ...item,
      preview: {}
    }));
    const updatedContextState = {
      selectedItems: updatedSelectedItems
    };
    containerProps.onSave(updatedDesignData, updatedContextState);
  },

  /**
   * Update the design data elements based on the previews provided.
   *
   * @param {object} designData - Design data to update.
   * @param {object} elementPreviews - Element previews to base the design updates on.
   * @returns {object} updated designData.
   */
  updateElements(designData, elementPreviews) {
    if (!Object.keys(elementPreviews).length) {
      return designData;
    }

    // make a local clone to avoid mutation
    let _elementPreviews = cloneDeep(elementPreviews);

    // map through and check if any elements are pages and update them separately while removing
    // them from out update object
    Object.entries(_elementPreviews).forEach(([elementId]) => {
      if (designData.pages[elementId]) {
        designData = designData.updatePageAttribute({
          pageId: elementId,
          attributes: _elementPreviews[elementId]
        });
        delete _elementPreviews[elementId];
      }
    });

    if (Object.keys(_elementPreviews).length) {
      // now that we just have element previews we can just bulk update them
      designData = designData.updateElementsAttributes({
        elementUpdates: _elementPreviews
      });
    }

    return designData;
  },

  /**
   * Update the selectedItems with the data provided in the elementPreviews.
   *
   * @param {object} designData - DesignData to pull additional element information from.
   * @param {Array} selectedItems - Array of selectedItem objects to update.
   * @param {object} elementPreviews - An object containing all the previews to base the
   * selectedItem updates on.
   * @return {Array} of updated selectedItem objects.
   */
  updateSelectedItemsFromPreviews(designData, selectedItems, elementPreviews) {
    return Object.keys(elementPreviews).map(elementId => {
      let selectedItem = selectedItems.find(item => item.itemId === elementId);
      if (!selectedItem) {
        // attempt to get the item from designData
        const dataElement = designData.getElement(elementId);
        // when still empty just return undefined
        if (!dataElement) return undefined;

        const dataElementPageId = designData.getElementPageId(elementId);
        selectedItem = {
          groupId: dataElement.groupId,
          itemId: dataElement.uniqueId,
          pageId: dataElementPageId
        };
      }

      const attributes = elementPreviews[elementId];
      selectedItem = {
        ...selectedItem,
        preview: {
          ...attributes
        }
      };

      const element = designData.getElement(elementId);

      // when the preview is image instructions we want to replace without removing unchanged ones
      if (
        Object.keys(attributes).includes("imageInstructions") &&
        attributes.imageInstructions.length
      ) {
        const previewInstructions = attributes.imageInstructions;
        const elementInstructions = element.imageInstructions;

        const originalInstructionsByDomId = elementInstructions.reduce(
          (combinedInstructions, currentInstruction) => ({
            ...combinedInstructions,
            [currentInstruction.domId]: currentInstruction
          }),
          {}
        );

        previewInstructions.forEach(previewInstruction => {
          originalInstructionsByDomId[
            previewInstruction.domId
          ] = previewInstruction;
        });

        selectedItem.preview.imageInstructions = Object.values(
          originalInstructionsByDomId
        );
      }

      return selectedItem;
    });
  },

  /**
   * Update the previews of the selectedItems
   * @param {Array} selectedItems - Selected items to update the previews in.
   * @param {object} elementPreviews - containing the previews to update the selected items
   * @param {object} attributes - The element attributes to be updated
   * @return {{}}
   */
  updatePreviews(selectedItems, elementPreviews, attributes) {
    // we need to ensure that preview values are only being cleared when we are applying them or
    // intentionally clearing them get the previews for the currently selected items
    const selectedItemsWithPreviews = pick(
      elementPreviews,
      selectedItems.map(item => item.itemId)
    );
    return Object.keys(selectedItemsWithPreviews).reduce(
      (updatedPreviews, previewKey) => {
        const currentPreview = selectedItemsWithPreviews[previewKey];
        // remove the new saving attributes from the preview, so we don't get overlaps of preview data
        return {
          ...updatedPreviews,
          [previewKey]: omit(currentPreview, Object.keys(attributes))
        };
      },
      {}
    );
  },

  /**
   * Update the attributes on the selected items and return the updated items.
   *
   * @param {object} designData - Design data to pull the elements from.
   * @param {Array} selectedItems - Selected items.
   * @param {object} context - the editors current context.
   * @param {object} attributes - The attributes and the new values they should be set to.
   * @return {Array} of updated selection items.
   */
  updateSelectedItemAttributes(designData, selectedItems, context, attributes) {
    return selectedItems
      .map(selectedItem => ({
        ...selectedItem,
        preview: {
          ...attributes
        }
      }))
      .map(selectedItem => {
        const element = designData.getElement(selectedItem.itemId);
        // when the preview is image instructions we want to replace without removing unchanged ones
        if (
          Object.keys(attributes).includes("imageInstructions") &&
          attributes.imageInstructions.length
        ) {
          const previewInstructions = attributes.imageInstructions;
          const elementInstructions = element.imageInstructions;

          const originalInstructionsByDomId = elementInstructions.reduce(
            (combinedInstructions, currentInstruction) => ({
              ...combinedInstructions,
              [currentInstruction.domId]: currentInstruction
            }),
            {}
          );

          previewInstructions.forEach(previewInstruction => {
            originalInstructionsByDomId[
              previewInstruction.domId
            ] = previewInstruction;
          });

          selectedItem.preview.imageInstructions = Object.values(
            originalInstructionsByDomId
          );
        }

        // handle updating height when preview changes for a textbox
        if (
          element.type === "textbox" &&
          !element.restrictions.includes("textBoundary") &&
          !context.isCroppingTextMaskImage &&
          !selectedItem.preview.height
        ) {
          selectedItem.preview.height = element.getHeight({
            ...selectedItem.preview
          });
        }

        return selectedItem;
      });
  }
};

export default EditorElementsOps;
