import React from "react";
import style from "./style.module.css";

export const DesignSizeOption = ({ design, isHighlighted, onClick }) => {
  let label = design.templateSize;
  let subtitle = "";
  if (design.subtitle !== design.templateSize) {
    subtitle = " - " + design.subtitle;
  }
  if (design.templateSize === "Custom") {
    label = design.subtitle;
    subtitle = "";
  }
  return (
    <div
      className={style.sizeOption}
      data-is-highlighted={isHighlighted}
      onClick={() => onClick(design)}
      data-testid="DesignSizeOption"
    >
      {label}
      {subtitle && <span>{subtitle}</span>}
    </div>
  );
};

DesignSizeOption.displayName = "DesignSizeOption";

export default DesignSizeOption;
