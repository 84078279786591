import { createSelector } from "reselect";
import { getPath, uniq } from "lib";
import { catalogueSizesApiIdsSelector } from "state/api/catalogueSizes/catalogueSizesApiSelectors";
import {
  teamSizesApiIdsSelector,
  teamSizesArchivedApiIdsSelector,
  teamSizesInFolderApiIdsSelector
} from "state/api/teamSizes/teamSizesApiSelectors";
import PATHS from "routes/paths";
import { getParameterByName } from "lib/queryStringUtils";

export const catalogueSizeEntitiesSelector = state =>
  getPath(state, "entities.catalogueSizes", {});

export const catalogueSizesSelector = ({ state, pathnameOverride }) => {
  const pathname = pathnameOverride || state.router.location.pathname;
  const sizeEntities = catalogueSizeEntitiesSelector(state);
  const catalogueSizeIds = catalogueSizesApiIdsSelector(state);
  const teamSizeIds = teamSizesApiIdsSelector(state);
  const teamSizesArchivedIds = teamSizesArchivedApiIdsSelector(state);
  let scopedIds = [];
  if (PATHS.isCatalogueEasilPath(pathname)) {
    scopedIds = catalogueSizeIds;
  } else if (PATHS.isCatalogueTemplates(pathname)) {
    scopedIds = teamSizeIds;
  } else if (PATHS.isCatalogueArchived(pathname)) {
    scopedIds = teamSizesArchivedIds;
  } else {
    // return both if not an expected scoped page
    scopedIds = uniq([
      ...catalogueSizeIds,
      ...teamSizeIds,
      ...teamSizesArchivedIds
    ]);
  }
  return scopedIds
    .map(sizeId => sizeEntities[sizeId])
    .filter(x => x)
    .reduce(
      (sizes, size) => ({
        ...sizes,
        [size.templateCode]: size
      }),
      {}
    );
};

export const catalogueSizesForFolderSelector = ({
  state,
  folderId,
  pathOverride
}) => {
  let _folderId = folderId;
  if (!_folderId) {
    const pathname = pathOverride || state.router.location.search;
    _folderId = getParameterByName("folderId", pathname);
  }
  const sizeEntities = catalogueSizeEntitiesSelector(state);
  const sizeIdsForFolder = teamSizesInFolderApiIdsSelector({
    state,
    folderId: _folderId
  });
  return sizeIdsForFolder
    .map(sizeId => sizeEntities[sizeId])
    .filter(x => x)
    .reduce(
      (sizes, size) => ({
        ...sizes,
        [size.templateCode]: size
      }),
      {}
    );
};

export const catalogueSizesFoldersSelector = ({ state }) =>
  getPath(state, "entities.catalogueSizes.folders");

const typePassThrough = ({ type }) => type;

const processCatalogueSizesByType = (catalogueSizes, type) => {
  if (!catalogueSizes) return [];

  const sizes = Object.keys(catalogueSizes).map(id => catalogueSizes[id]);

  return sizes.filter(size => size.templateType === type);
};

export const catalogueSizesByType = createSelector(
  [catalogueSizesSelector, typePassThrough],
  processCatalogueSizesByType
);
