import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import {
  composeClassName,
  calculateTemplateSizeDisplayMeasurements,
  noop
} from "lib";
import { isMediaVideoFormat } from "lib/mediaSourceHelpers";

class DesignSizeCard extends Component {
  static defaultProps = {
    onClick: noop
  };

  constructor(props) {
    super(props);

    this.state = {
      isImageLoaded: false
    };

    this.onImageLoaded = this.onImageLoaded.bind(this);
  }

  onImageLoaded() {
    this.setState({ isImageLoaded: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.designSize.isSelected !== nextProps.designSize.isSelected) {
      return true;
    }

    if (this.state.isImageLoaded !== nextState.isImageLoaded) {
      return true;
    }

    return false;
  }

  render() {
    const { designSize, wrapperClassName, onClick } = this.props;

    const wrapperClass = composeClassName(
      "designSizeCardWrapper",
      style,
      wrapperClassName
    );

    const getCategorySizeDimensionsLabel = designSize => {
      const measurements = calculateTemplateSizeDisplayMeasurements(designSize);
      if (designSize.sizeOverride) {
        return designSize.sizeOverride;
      }
      // This is not a bug, as a requirement, some Template sizes purposely have no display
      // measurements.
      if (!measurements) return null;

      return `${measurements.width} x ${measurements.height}${measurements.unit}`;
    };

    return (
      <div className={wrapperClass} onClick={onClick}>
        <div
          className={style.thumbCard}
          data-is-selected={this.state.isImageLoaded && designSize.isSelected}
        >
          {isMediaVideoFormat(designSize.thumbnailUrl) ? (
            <video
              className={style.thumbnail}
              muted
              loop
              autoPlay={true}
              onLoadedData={this.onImageLoaded}
            >
              <source src={designSize.thumbnailUrl} type="video/mp4" />
            </video>
          ) : (
            <img
              className={style.thumbnail}
              src={designSize.thumbnailUrl}
              alt="design thumbnail"
              onLoad={this.onImageLoaded}
              data-is-selected={designSize.isSelected}
            />
          )}
        </div>
        <div className={style.cardInfo}>
          <div className={style.cardInfoLabel}>{designSize.name}</div>
          <div className={style.cardInfoText}>
            {getCategorySizeDimensionsLabel(designSize)}
          </div>
        </div>
      </div>
    );
  }
}
DesignSizeCard.propTypes = {
  designSize: PropTypes.object.isRequired,
  wrapperClassName: PropTypes.string,
  onClick: PropTypes.func
};

export default DesignSizeCard;
