import { createSelector } from "reselect";
import { omit } from "lib";
import { catalogueSizesApiIdsSelector } from "state/api/catalogueSizes/catalogueSizesApiSelectors";
import {
  teamSizesApiIdsSelector,
  teamSizesInFolderApiIdsSelector
} from "state/api/teamSizes/teamSizesApiSelectors";

const catalogueSizesSelector = state => state.entities.catalogueSizes;
const workspaceSizesSelector = state => state.entities.workspaceSizes;
const purchasedCatalogueSizesEntitySelector = state =>
  state.entities.purchasedCatalogueSizes;
export const workspaceSizesWorkspaceEntitiesSelector = state =>
  workspaceSizesSelector(state).workspace;
export const workspaceSizesSharedEntitiesSelector = state =>
  workspaceSizesSelector(state).shared;
export const workspaceSizesApprovedEntitiesSelector = state =>
  workspaceSizesSelector(state).approved;
export const workspaceSizesFoldersEntitiesSelector = state =>
  workspaceSizesSelector(state).folders;
const templateCodePassThrough = (state, templateCode) => templateCode;

const processTemplateSizesSelector = (sizes, sizeIds) => {
  // just return sizes matching the ids from api scoped state
  return sizeIds
    .map(id => sizes[id])
    .reduce(
      (sizes, currentSize) => ({
        ...sizes,
        [currentSize.templateCode]: currentSize
      }),
      {}
    );
};

export const catalogueTemplateSizesSelector = createSelector(
  [catalogueSizesSelector, catalogueSizesApiIdsSelector],
  processTemplateSizesSelector
);

export const teamTemplateSizesSelector = createSelector(
  [catalogueSizesSelector, teamSizesApiIdsSelector],
  processTemplateSizesSelector
);

const processTemplateSizesInFolderSelector = (
  sizeEntities = {},
  sizeIds = []
) => {
  const sizes = sizeIds.map(sizeId => sizeEntities[sizeId]);
  return Object.values(sizes).reduce(
    (sizes, currentSize) => ({
      ...sizes,
      [currentSize.templateCode]: currentSize
    }),
    {}
  );
};

export const templateSizesInFolderSelector = createSelector(
  [
    ({ state }) => catalogueSizesSelector(state),
    teamSizesInFolderApiIdsSelector
  ],
  processTemplateSizesInFolderSelector
);

const processPurchasedCatalogueSizesSelector = catalogueSizes => {
  return omit(catalogueSizes, "folders");
};

export const purchasedCatalogueSizesSelector = createSelector(
  [purchasedCatalogueSizesEntitySelector],
  processPurchasedCatalogueSizesSelector
);
/**
 * Find a template size in either the catalogue sizes or workspace size.
 * If a size is not in the catalogue sizes, then it's been inactivated and the user has a draft
 * in their workspace, which means we need to look in workspace sizes.
 */
const processTemplateSizeSelector = (
  catalogueSizes = [],
  workspaceSizesApproved = [],
  workspaceSizes = [],
  workspaceSizesShared = [],
  templateCode
) => {
  const designTemplateSizes = Object.assign(
    {},
    workspaceSizes,
    catalogueSizes,
    workspaceSizesApproved,
    workspaceSizesShared
  );
  const size = designTemplateSizes[templateCode];
  if (!size) return null;

  return size;
};
//(state, templateCode)
export const templateSizeSelector = createSelector(
  [
    catalogueSizesSelector,
    workspaceSizesApprovedEntitiesSelector,
    workspaceSizesSharedEntitiesSelector,
    workspaceSizesWorkspaceEntitiesSelector,
    templateCodePassThrough
  ],
  processTemplateSizeSelector
);
