import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

import { clamp, Logger, noop, debounce, cloneDeep } from "lib";

import { Input } from "..";

class CreditCardForm extends Component {
  static MAX_CARD_LENGTH = 19;
  static MAX_CVV_LENGTH = 4;
  static MIN_EXP_MONTH_VALUE = 1;
  static MAX_EXP_MONTH_VALUE = 12;
  static MAX_EXP_YEAR_LENGTH = 4;
  static CARD_NUMBER_FIELD_VALIDATION_DELAY = 2000;
  static CARD_FIELDS_VALIDATION_DELAY = 500;

  constructor(props) {
    super(props);

    this.formChange = this.formChange.bind(this);
    this.responseHandler = this.responseHandler.bind(this);
    this.formatExpiryMonthForDisplay = this.formatExpiryMonthForDisplay.bind(
      this
    );
    this.setEditing = this.setEditing.bind(this);
    this.cardNumberValidation = debounce(
      this.validateFields.bind(this),
      CreditCardForm.CARD_NUMBER_FIELD_VALIDATION_DELAY
    );
    this.otherValidations = debounce(
      this.validateFields.bind(this),
      CreditCardForm.CARD_FIELDS_VALIDATION_DELAY
    );
    this.handleFieldBlur = this.handleFieldBlur.bind(this);
    this.wrapperRef = React.createRef();

    this.state = {
      isFetching: false,
      hasBeenValidated: false,
      card: {
        number: {
          value: null,
          error: null
        },
        expMonth: {
          value: null,
          error: null
        },
        expYear: {
          value: null,
          error: null
        },
        cvc: {
          value: null,
          error: null
        }
      },
      editing: {
        expMonth: false
      }
    };
  }

  componentDidMount() {
    if (typeof Stripe !== "function") {
      const script = document.createElement("script");

      script.async = true;
      script.src = "https://js.stripe.com/v2";
      document.body.appendChild(script);
    }
  }

  cardFilled(card) {
    return (
      card.number.value &&
      card.expMonth.value &&
      card.expYear.value &&
      card.cvc.value
    );
  }

  cardValid(card) {
    if (
      card.number.value &&
      card.number.error === null &&
      card.expMonth.value &&
      card.expMonth.error === null &&
      card.expYear.value &&
      card.expYear.error === null &&
      card.cvc.value &&
      card.cvc.error === null
    )
      return true;

    return false;
  }

  validateFields() {
    const { card: stateCard, isFetching } = this.state;
    const { onTokenFailure = noop } = this.props;
    let fetchingToken = isFetching;
    // copy card for immutability
    const card = cloneDeep(stateCard);
    /* cancel any  debounced validations */
    this.cardNumberValidation.cancel();
    this.otherValidations.cancel();

    if (!this.cardFilled(card)) {
      this.setState({ card });
      return;
    }

    for (let cardField in card) {
      const value = card[cardField].value;

      switch (cardField) {
        case "number":
          if (window.Stripe.card.validateCardNumber(value)) {
            card.number.error = null;
          } else {
            card.number.error = "Card number is invalid";
          }
          break;
        case "expMonth":
          if (window.Stripe.card.validateExpiry(value, card.expYear.value)) {
            card.expMonth.error = null;
          } else {
            card.expMonth.error = "Expiry month is invalid";
          }
          break;
        case "expYear":
          if (
            value >= new Date().getFullYear() &&
            window.Stripe.card.validateExpiry(card.expMonth.value, value)
          ) {
            card.expYear.error = null;
          } else {
            card.expYear.error = "Expiry year is invalid";
          }
          break;
        case "cvc":
          if (window.Stripe.card.validateCVC(value)) {
            card.cvc.error = null;
          } else {
            card.cvc.error = "CVC number is invalid";
          }
          break;
        default:
          break;
      }
    }

    if (this.cardValid(card)) {
      fetchingToken = true;
      this.createToken(card);
    } else {
      onTokenFailure();
    }
    this.setState({ card, isFetching: fetchingToken, hasBeenValidated: true });
  }

  responseHandler(_status, response) {
    const { card: stateCard } = this.state;
    // copy card for immutability
    const card = cloneDeep(stateCard);
    this.setState({ isFetching: false });
    if (!response.error) return this.props.onTokenSuccess(response);
    card.number.error = response.error.message;
    this.setState({ card });
  }

  createToken(card) {
    window.Stripe.setPublishableKey(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    );

    const tokenData = {
      number: card.number.value,
      cvc: card.cvc.value,
      exp_month: card.expMonth.value,
      exp_year: card.expYear.value
    };

    window.Stripe.card.createToken(tokenData, this.responseHandler);
  }

  formatInput(name, value) {
    value = value.replace(/\D/g, ""); // strip non-numeric
    switch (name) {
      case "number": {
        value = value.substring(
          0,
          Math.min(CreditCardForm.MAX_CARD_LENGTH, value.length)
        ); // restrict to max length
        break;
      }
      case "expMonth": {
        if (
          (value === "" || (value[0] === "0" && value.length <= 2)) &&
          value !== "00"
        ) {
          break;
        }
        value = clamp(
          value,
          CreditCardForm.MIN_EXP_MONTH_VALUE,
          CreditCardForm.MAX_EXP_MONTH_VALUE
        ); // clamp between 1 and 12 (Jan <= value <= Dec)
        break;
      }
      case "expYear": {
        value = value.substring(
          0,
          Math.min(CreditCardForm.MAX_EXP_YEAR_LENGTH, value.length)
        ); // restrict to 4 digit year format
        break;
      }
      case "cvc": {
        value = value.substring(
          0,
          Math.min(CreditCardForm.MAX_CVV_LENGTH, value.length)
        ); // restrict to 4 digit cvv value
        break;
      }
      default: {
        Logger.error(
          `Invalid Credit Card input name: ${name}, value: ${value}`
        );
        break;
      }
    }
    return value;
  }

  formChange(event) {
    const {
      target: { name, value: inputValue }
    } = event;

    const { onTokenFailure = noop, clearError = noop } = this.props;

    // clear errors in state when form is altered
    clearError();

    // copy card for immutability
    const card = cloneDeep(this.state.card);

    onTokenFailure();

    /* label as being edited in state */
    this.setEditing(name, true);

    /* pass value through formatting */
    const value = this.formatInput(name, inputValue);

    card[name].value = value;

    this.setState({ card, hasBeenValidated: false }, () => {
      if (name === "number") {
        this.cardNumberValidation();
      } else {
        this.otherValidations();
      }
    });
  }

  formatCardNumberForDisplay(cardNumber) {
    if (!cardNumber) return ""; // break early for null input
    return cardNumber
      .replace(/\D/g, "") // strip non-numeric
      .match(/.{1,4}/g) // split every 4 characters
      .join(" "); // join with a space
  }

  formatExpiryMonthForDisplay(expMonth) {
    /* check the state for if the field is still being edited */
    const {
      editing: { expMonth: isEditing }
    } = this.state;

    /* return early on empty or 0 value */
    if (!expMonth || expMonth === "" || expMonth[0] === "0") return expMonth;
    if (expMonth < 10) {
      /* if the month field is no longer being edited append the 0 */
      return isEditing ? expMonth : `0${expMonth}`;
    }
    return expMonth;
  }

  setEditing(field, isEditing) {
    /* equivalent of on field blur */

    this.setState({ editing: { [field]: isEditing } });
  }

  handleFieldBlur(field, isEditing, event) {
    this.setEditing(field, isEditing);

    if (!this.state.hasBeenValidated) {
      this.validateFields();
    }
  }

  render() {
    const { disabled, placeholder, hideLabels = false } = this.props;

    const {
      isFetching: isFetchingToken,
      card: { number, expMonth, expYear, cvc }
    } = this.state;

    return (
      <React.Fragment>
        <div className={style.form}>
          <div className={style.group}>
            {!hideLabels && (
              <div className={`${style.label} ${this.props.labelStyles}`}>
                Card Number
              </div>
            )}
            <Input
              placeholder={placeholder ? placeholder : "****************"}
              name="number"
              type="string"
              data-private="true"
              disabled={isFetchingToken || disabled}
              value={this.formatCardNumberForDisplay(number.value) || ""}
              onChange={this.formChange}
              onBlur={() => this.handleFieldBlur("number", false)}
            />
            {number.error && <div className={style.error}>{number.error}</div>}
          </div>
        </div>
        <div className={style.form}>
          <div className={style.group}>
            {!hideLabels && (
              <div className={`${style.label} ${this.props.labelStyles}`}>
                Expiry (Month)
              </div>
            )}
            <Input
              placeholder="MM"
              name="expMonth"
              data-private="true"
              type="string"
              min="1"
              max="12"
              minLength="2"
              maxLength="2"
              disabled={isFetchingToken || disabled}
              value={this.formatExpiryMonthForDisplay(expMonth.value) || ""}
              onChange={this.formChange}
              onBlur={() => this.handleFieldBlur("expMonth", false)}
            />
            {expMonth.error && (
              <div className={style.error}>{expMonth.error}</div>
            )}
          </div>
          <div className={style.group}>
            {!hideLabels && (
              <div className={`${style.label} ${this.props.labelStyles}`}>
                Expiry (Year)
              </div>
            )}
            <Input
              placeholder="YYYY"
              name="expYear"
              data-private="true"
              type="string"
              min="2017"
              minLength="4"
              maxLength="4"
              disabled={isFetchingToken || disabled}
              value={expYear.value || ""}
              onChange={this.formChange}
              onBlur={() => this.handleFieldBlur("expYear", false)}
            />
            {expYear.error && (
              <div className={style.error}>{expYear.error}</div>
            )}
          </div>
          <div className={style.group}>
            {!hideLabels && (
              <div className={`${style.label} ${this.props.labelStyles}`}>
                CVC
              </div>
            )}
            <Input
              placeholder="CVC"
              name="cvc"
              data-private="true"
              type="string"
              disabled={isFetchingToken || disabled}
              value={cvc.value || ""}
              onChange={this.formChange}
              onBlur={() => this.handleFieldBlur("cvc", false)}
            />
            {cvc.error && <div className={style.error}>{cvc.error}</div>}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CreditCardForm.propTypes = {
  onTokenSuccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default CreditCardForm;
