import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import SubPageLoading from "views/pages/SubPageLoading";
const OrdersPage = lazy(() => import("./OrdersPage"));

export const OrdersPageLazy = props => (
  <Suspense fallback={<SubPageLoading />}>
    <OrdersPage {...props} />
  </Suspense>
);
OrdersPageLazy.displayName = "OrdersPage";

export default WithHeader(OrdersPageLazy);
