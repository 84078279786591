import { Component } from "react";
import ThreeUsersIcon from "views/components/icons/ThreeUsersIcon";
import TeamCreateModal from "views/containers/TeamCreateModalContainer";
import style from "./style.module.css";

export class TeamCreateButton extends Component {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.state = {
      isModalOpen: false
    };
  }

  handleOpenModal() {
    this.setState({
      isModalOpen: true
    });
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false
    });
  }

  render() {
    return (
      <div data-testid="TeamCreateButton">
        <div
          className={style.teamCreateButtonWrapper}
          onClick={this.handleOpenModal}
          data-is-divider={this.props.showDivider}
        >
          <ThreeUsersIcon size="24px" color={style.easilBlue} />
          <div className={style.teamCreateTitle}>Add New Team</div>
        </div>
        <TeamCreateModal
          isOpen={this.state.isModalOpen}
          onClose={this.handleCloseModal}
        />
      </div>
    );
  }
}

export default TeamCreateButton;
