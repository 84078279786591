import * as foldersEntitiesTypes from "state/entities/folders/foldersTypes";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50
};

const foldersApiReducers = (state = initState, action) => {
  switch (action.type) {
    case foldersEntitiesTypes.FETCH_PERSONAL_FOLDERS_REQUEST: {
      return handleDesignRequest({ state, action });
    }

    case foldersEntitiesTypes.FETCH_PERSONAL_FOLDERS_REQUEST_SUCCESS: {
      return handleDesignRequestSuccess({ state, action });
    }

    case foldersEntitiesTypes.CREATE_PERSONAL_FOLDER_REQUEST: {
      const pageNumber = Object.keys(state.pages).map(id => state.pages[id])
        .length;
      const updatedPages = Object.assign({}, state.pages);
      const updatedPage = updatedPages[pageNumber];

      updatedPage.isFetching = true;

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageNumber]: {
            ...updatedPage
          }
        }
      };
    }

    case foldersEntitiesTypes.CREATE_PERSONAL_FOLDER_REQUEST_SUCCESS: {
      const { ids: newId } = action.response;
      const pageNumber = Object.keys(state.pages).map(id => state.pages[id])
        .length;
      const updatedPages = Object.assign({}, state.pages);
      const updatedPage = updatedPages[pageNumber];

      updatedPage.ids.push(newId);
      updatedPage.isFetching = false;

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageNumber]: {
            ...updatedPage
          }
        }
      };
    }

    default:
      return state;
  }
};

export default foldersApiReducers;
