import React, { Component } from "react";
import common from "../common.module.css";
import BrandKitEmpty from "../BrandKitEmpty/BrandKitEmpty";
import EmptyFontsIcon from "views/components/icons/EmptyFontsIcon";
import EmptySearchIcon from "views/components/icons/EmptySearchIcon";
import FontModal from "./FontModal";
import Loading from "views/components/loading";
import PopoutItemMenu from "views/components/popout/PopoutItemMenu";
import Subscriptions from "lib/subscriptions";
import style from "./style.module.css";
import { noop } from "lib";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import SmoothPencilIcon from "views/components/icons/SmoothPencilIcon";
import TrashCanIcon from "views/components/icons/TrashCanIcon";
import ScrollingOverflowText from "views/components/ScrollingOverflowText/ScrollingOverflowText";
import BrandKitFontOps from "./BrandKitFontOps";

export class BrandKitFonts extends Component {
  constructor(props) {
    super(props);

    this.handleEditFont = this.handleEditFont.bind(this);
    this.handleRemoveFont = this.handleRemoveFont.bind(this);
    this.handleCloseFontModal = this.handleCloseFontModal.bind(this);
    this.onAddImageButtonClick = this.onAddImageButtonClick.bind(this);
    this.handleOpenEditFontModal = BrandKitFontOps.handleOpenEditFontModal.bind(
      this
    );
    this.getFontArr = BrandKitFontOps.getFontArr.bind(this);
    this.handleSearchInput = BrandKitFontOps.handleSearchInput.bind(this);

    this.state = {
      subscription: Subscriptions.get(props.currentSubscriptionCode)
    };
  }

  componentDidMount() {
    if (this.props.currentTeam.id) {
      const teamId = this.props.currentTeam.id;
      this.props.fetchBrandKitFontsIfNeeded(teamId);
    }
  }

  handleEditFont(id) {
    const font = this.props.brandKit.fonts[id];
    const fontId = id;
    const visible = true;
    this.props.setBrandKitState({ fontModal: { fontId, font, visible } });
  }

  handleRemoveFont(id) {
    const teamId = this.props.currentTeam.id;
    this.props.removeBrandKitFont({ teamId, id });
  }

  handleCloseFontModal() {
    this.props.setBrandKitState({
      fontModal: {
        visible: false,
        font: null
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.fonts.isSuccess === false &&
      this.props.fonts.isSuccess === true
    ) {
      this.handleCloseFontModal();
    }
  }

  onAddImageButtonClick(event) {
    if (!this.state.subscription.getCanAccessBrandKit()) {
      event.stopPropagation();
      event.preventDefault();

      this.props.showUpgradeModal();
    } else {
      noop();
    }
  }

  render() {
    const {
      fonts: { entries, isProcessing, isUpdating },
      isBrandManager,
      location,
      userFonts,
      popoutFontId
    } = this.props;

    const urlParams = new URLSearchParams(location.search);
    const urlTerm = urlParams.get("term");
    const fontArr = this.getFontArr(urlTerm);

    return (
      <>
        {(isProcessing || fontArr.length > 0) &&
          (urlTerm === null ? (
            <div className={common.contentTitle}>Brand Fonts</div>
          ) : (
            <div className={common.contentTitleForSearch}>
              Showing all results for <b>{urlTerm}</b>
            </div>
          ))}

        {fontArr.map(font => (
          <div className={`${common.whiteRow} notranslate`} key={font.id}>
            <div className={common.whiteRowTop}>
              <div>
                <ScrollingOverflowText
                  className={style.teamFontName}
                  customStyle={{ fontFamily: `"${font.fontFamilyName}"` }}
                >
                  {font.teamFontName}
                </ScrollingOverflowText>

                <ScrollingOverflowText className={style.fontFamily}>
                  {font.fontName}
                </ScrollingOverflowText>
              </div>

              {isBrandManager && (
                <div className={common.whiteRowButtons}>
                  <div
                    className={common.whiteRowButton}
                    onClick={() => this.handleOpenEditFontModal(font.id)}
                  >
                    <SmoothPencilIcon size="23px" />
                  </div>

                  <div
                    className={common.whiteRowButton}
                    onClick={event => {
                      this.props.setBrandKitState({
                        popoutFontId: popoutFontId === font.id ? null : font.id
                      });
                    }}
                    id={`brand-font-popout-button-${font.id}`}
                  >
                    <EllipsisIcon size="24px" />
                  </div>

                  {popoutFontId === font.id && (
                    <PopoutItemMenu
                      actions={[
                        {
                          Icon: () => <TrashCanIcon size="22px" />,
                          onClick: () => this.handleRemoveFont(font.id),
                          name: "Delete"
                        }
                      ]}
                      onClose={() =>
                        this.props.setBrandKitState({ popoutFontId: null })
                      }
                      buttonId={`brand-font-popout-button-${font.id}`}
                      className={common.popoutItemMenu}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}

        {(isProcessing || isUpdating) && <Loading />}

        {!isProcessing &&
          fontArr.length === 0 &&
          (urlTerm === null ? (
            <BrandKitEmpty
              button={
                isBrandManager && {
                  children: <>Add Font</>,
                  onClick: () => this.handleOpenEditFontModal()
                }
              }
              icon={<EmptyFontsIcon height="44px" width="56px" />}
              subtitle={
                isBrandManager &&
                `Start by clicking 'Add Font' and then either upload your own fonts, or choose from ours.\nYou and your team members will then be able to easily use these within any design!`
              }
              title="Let's upload your Brand Fonts!"
            />
          ) : (
            <BrandKitEmpty
              icon={<EmptySearchIcon height="43px" width="56px" />}
              subtitle="Try searching for another Font, or clearing your search."
              title={
                <>
                  No Fonts could be found for '<b>{urlTerm}</b>'.
                </>
              }
            />
          ))}
        <FontModal
          isOpen={this.props.fontModal.visible}
          teamFont={this.props.fontModal.font || undefined}
          isUpdate={Boolean(this.props.fontModal.font)}
          teamFonts={entries}
          userTeamFonts={userFonts}
          isProcessing={isUpdating}
          handleCloseFontModal={this.handleCloseFontModal}
        />
      </>
    );
  }
}

export default BrandKitFonts;
