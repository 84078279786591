import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ImageIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill={color || easilGrayXDark} fillRule="evenodd">
      <circle cx="6.5" cy="6.5" r="1.5" />
      <path
        fillRule="nonzero"
        d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
      />
      <path
        fillRule="nonzero"
        d="M14.184 11.336l-.203-.125.109.076.094.05zM18 14.038v.474h.674l.175.124.087-.124H20V13l-4.868-3.425c-2.177-1.172-4-.314-5.115 1.367L4 20l3.11-1.066 4.572-6.885c.669-1.006 1.402-1.265 2.408-.762L18 14.038zm.674.474h.262L20 13h-2v1.038l.674.474zm0 0L18 14.038V13h2l-1.064 1.512h-.262zm.175.124l.087-.124H20V13l-4.868-3.425c-2.177-1.172-4-.314-5.115 1.367L4 20l3.11-1.066 4.572-6.885c.669-1.006 1.402-1.265 2.408-.762L18 14.038v.474h.674l.175.124zm-4.665-3.3l-.203-.125.109.076.094.05z"
      />
    </g>
  </svg>
);
ImageIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ImageIcon;
