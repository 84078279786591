import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FatArrowIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color = easilGrayXDark,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color}
    stroke={color}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-4h24v24H-3z" />
      <path
        stroke="none"
        fill={color}
        fillRule="nonzero"
        d="M6.876 5.878a1 1 0 0 1 .082 1.32l-3.866 4.97a1 1 0 0 0 .083 1.322l.331.332a1 1 0 0 0 1.321.082l4.97-3.866a1 1 0 0 1 1.322.082l2.084 2.085c.388-3.196.68-5.674.876-7.434.095-.862.168-1.55.217-2.064-.24.023-.518.051-.831.084-1.075.113-2.536.278-4.288.483l-.112.013c-1.362.159-2.817.332-4.272.507l2.083 2.084zm7.495-4.144v-.007.007zM4.838 6.668L1.926 3.756a1 1 0 0 1 .586-1.7l.542-.066a1340.796 1340.796 0 0 1 5.779-.69l.112-.013c1.759-.205 3.227-.37 4.31-.485a71.233 71.233 0 0 1 1.452-.141c.19-.016.34-.026.452-.03.072-.004.132-.005.189-.003.366.012.445.025.72.3.483.483.577-.36-1.128 13.556a1 1 0 0 1-1.7.586l-2.911-2.911-4.274 3.323a3 3 0 0 1-3.963-.246l-.332-.332a3 3 0 0 1-.246-3.963l3.324-4.273z"
      />
    </g>
  </svg>
);
FatArrowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FatArrowIcon;
