import React from "react";
import style from "./style.module.css";
import ScrollingOverflowText from "views/components/ScrollingOverflowText/ScrollingOverflowText";

const FontModalHeader = ({ teamFont, useFontName }) => {
  const title = useFontName ? teamFont.fontName : teamFont.teamFontName;
  const subtitle = useFontName ? "Brand Font" : teamFont.fontName;

  return (
    <div
      className={style.preview}
      style={{ fontFamily: teamFont.fontFamilyName }}
    >
      <ScrollingOverflowText className={style.title}>
        {title}
      </ScrollingOverflowText>
      <ScrollingOverflowText className={style.headerSubtitle}>
        {subtitle}
      </ScrollingOverflowText>
    </div>
  );
};

export default FontModalHeader;
