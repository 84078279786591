import React, { Component } from "react";
import PropTypes from "prop-types";
import Caret2Icon from "views/components/icons/Caret2Icon";
import style from "./style.module.css";
import { isNil, composeClassName } from "lib";

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isOpen: false,
      options: this.props.options || [],
      selected: this.props.selected || null
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.options });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.isConnected
    ) {
      this.setState({ isOpen: false });
    }
  }

  onDropdownClick() {
    if (this.props.disabled) return;
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleChange(option) {
    this.setState({ isOpen: false });

    this.props.onChange(option.key);
  }

  render() {
    const {
      placeholder,
      error,
      showErrorMsg,
      label,
      selected,
      disabled,
      wrapperClassName,
      inputClassName,
      inputWrapperClassName,
      optionClassName,
      caretColor,
      optionsZIndex = 3,
      optionsBorderColor,
      emptyText,
      className
    } = this.props;
    const { options, isOpen } = this.state;

    const optionsDynamicStyle = {
      zIndex: optionsZIndex,
      borderColor: optionsBorderColor ? optionsBorderColor : null
    };

    return (
      <React.Fragment>
        {label && <div className={style.label}>{label}</div>}
        <div
          ref={this.setWrapperRef}
          className={composeClassName(
            "dropdown",
            { dropdown: `${style.dropdown} ${wrapperClassName}` },
            className
          )}
        >
          <div
            aria-label="dropdown"
            onClick={this.onDropdownClick}
            className={`${style.inputDiv} ${inputWrapperClassName}`}
            data-error={!isNil(error)}
            data-disabled={disabled}
            data-testid="dropdownButton"
          >
            <div className={`${style.selected} ${inputClassName}`}>
              {selected || placeholder || ""}
            </div>
            <Caret2Icon
              size="16px"
              color={caretColor || "#afb3b6"}
              direction="down"
            />
          </div>
          {showErrorMsg && error && (
            <span
              aria-label="dropdownError"
              data-testid="dropdownError"
              className={style.error}
            >
              {error}
            </span>
          )}
          {isOpen && (
            <div
              className={composeClassName("content", style, className)}
              style={optionsDynamicStyle}
              data-testid="dropdownContent"
            >
              {options.length === 0 && emptyText && <span>{emptyText}</span>}
              {options.map((option, index) => (
                <div
                  className={`${style.option} ${optionClassName}`}
                  key={option.key}
                  id={option.key}
                  onClick={() => this.handleChange(option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  showErrorMsg: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  inputWrapperClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  caretColor: PropTypes.string,
  optionsZIndex: PropTypes.number,
  optionsBorderColor: PropTypes.string,
  emptyText: PropTypes.string,
  className: PropTypes.string
};

Dropdown.displayName = "Dropdown3";

export default Dropdown;
