import React from "react";
import PropTypes from "prop-types";
import BillingAddressModal from "./BillingAddressModal";
import style from "./style.module.css";
import SmoothPencilIcon from "views/components/icons/SmoothPencilIcon";
import PlusIcon from "views/components/icons/PlusIcon";
import { allCountries } from "lib/countriesList";

class BillingAddress extends React.Component {
  state = {
    isModalVisible: false
  };

  hideModal = () => {
    this.setState({ isModalVisible: false });
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  render = () => (
    <>
      <h2>Billing</h2>

      <h3>Address</h3>

      <p>Your billing address will appear on all invoices.</p>

      {this.props.teamBilling && this.props.teamBilling.street ? (
        <>
          <div className={style.billingAddress}>
            <b>{this.props.currentTeam.name}</b>
            <br />
            {[
              this.props.teamBilling.street,
              this.props.teamBilling.suburb,
              this.props.teamBilling.state,
              this.props.teamBilling.postcode,
              (
                allCountries.find(
                  c => c.key === this.props.teamBilling.country
                ) || {}
              ).label
            ]
              .filter(a => a)
              .join(", ")}
          </div>
          <button className={style.addButton} onClick={this.showModal}>
            <SmoothPencilIcon color="#2d70cf" size="18" />
            Edit billing address
          </button>
        </>
      ) : (
        <button className={style.addButton} onClick={this.showModal}>
          <PlusIcon size="16" />
          Add billing address
        </button>
      )}

      {this.state.isModalVisible && (
        <BillingAddressModal
          currentTeam={this.props.currentTeam}
          teamBilling={this.props.teamBilling}
          teamBillingSettings={this.props.teamBillingSettings}
          updateBilling={this.props.updateBilling}
          onClose={this.hideModal}
        />
      )}
    </>
  );
}

BillingAddress.displayName = "BillingAddress";

BillingAddress.propTypes = {
  currentTeam: PropTypes.object.isRequired,
  updateBilling: PropTypes.func.isRequired,
  teamBilling: PropTypes.object,
  teamBillingSettings: PropTypes.object.isRequired
};

export default BillingAddress;
