import React, { Component } from "react";
import { H2, H3, Loading } from "views/components";
import CaretSlimIcon from "views/components/icons/CaretSlimIcon";
import style from "./style.module.css";
import { formatOrderStatus, formatOrderDate } from "./ordersHelpers";
import OrderDesignsContainer from "views/components/OrderDesign/OrderDesignsContainer";
import PriceSummaryContainer from "views/components/PriceSummary/PriceSummaryContainer";
class Order extends Component {
  componentDidMount() {}

  render() {
    const { order } = this.props;

    if (!order) {
      return (
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loading />
        </div>
      );
    }

    return (
      <div className={style.wrapper}>
        <div className={style.breadCrumb}>
          <div onClick={this.props.moveToOrdersPage}>Orders</div>
          <CaretSlimIcon size={10} direction="right" color="#afb3b6" />
          <div>Order No.{order.id}</div>
        </div>
        <H2 className={style.title}>Order No.{order.id}</H2>
        <div className={style.orderStatusBox}>
          <div>
            <strong>Status:</strong> {formatOrderStatus(order.status)}
          </div>
          <div>
            <strong>Paid:</strong> {formatOrderDate(order.paidAt)}
          </div>
        </div>
        {order.shipping && order.billing && (
          <div className={style.billingAndPaymentSection}>
            <div>
              <H3 className={style.orderHeading}>Billing</H3>
              {order.shipping.attention && (
                <div className={style.billingRow}>
                  <div className={style.billingRowLeft}>Attention:</div>
                  <div className={style.billingRowRight}>
                    {order.shipping.attention}
                  </div>
                </div>
              )}
              <div className={style.billingRow}>
                <div className={style.billingRowLeft}>Company Name:</div>
                <div className={style.billingRowRight}>
                  {order.shipping.name}
                </div>
              </div>
              <div className={style.billingRow}>
                <div className={style.billingRowLeft}>Billing Address:</div>
                <div className={style.billingRowRight}>
                  {order.billing.street}, {order.billing.suburb}{" "}
                  {order.billing.state} {order.billing.postcode},{" "}
                  {order.billing.country}
                </div>
              </div>
              <div className={style.billingRow}>
                <div className={style.billingRowLeft}>Shipping Address:</div>
                <div className={style.billingRowRight}>
                  {order.shipping.street}, {order.shipping.suburb}{" "}
                  {order.shipping.state} {order.shipping.postcode},{" "}
                  {order.shipping.country}
                </div>
              </div>
              <div className={style.billingRow}>
                <div className={style.billingRowLeft}>Delivery Type:</div>
                <div className={style.billingRowRight}>
                  {order.shipping.addressType}
                </div>
              </div>
              {order.shipping.notes && (
                <div className={style.billingRow}>
                  <div className={style.billingRowLeft}>Delivery Note:</div>
                  <div className={style.billingRowRight}>
                    {order.shipping.notes}
                  </div>
                </div>
              )}
            </div>
            {/* NO PAYMENT DETAILS AVAILABLE YET
            <div>
              <H3 className={style.orderHeading}>Payment</H3>
              <div>

              </div>
            </div>
            */}
          </div>
        )}
        <div className={style.orderSummarySection}>
          <H3 className={style.orderHeading}>Summary</H3>
          <OrderDesignsContainer
            orderId={order.id}
            className={style.orderDesigns}
          />
        </div>
        <div className={style.priceSummarySection}>
          <PriceSummaryContainer
            orderId={order.id}
            shipping={order.shipping}
            shippingCanBeHidden
          />
        </div>
      </div>
    );
  }
}

export default Order;
