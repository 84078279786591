import React from "react";
import { connect } from "react-redux";
import UpdateUserDetailsModal from "./UpdateUserDetailsModal";
import { updateUserV2 } from "state/currentUser/currentUserActions";
import { currentUserSelector } from "state/currentUser/currentUserSelectors";

export const UpdateUserDetailsModalContainer = props => {
  return <UpdateUserDetailsModal {...props} />;
};

const mapStateToProps = state => {
  const currentUserState = currentUserSelector(state);
  return {
    currentUserState,
    settings: state.ui.userSettings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUserDetails: args => dispatch(updateUserV2(args))
  };
};

UpdateUserDetailsModalContainer.displayName = "UpdateUserDetailsModalContainer";
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateUserDetailsModalContainer);
