import { merge, values, isEmpty } from "lib";

/* Sad truth, we need to relay on the fontName :(, not id */
const FontToolBox = {
  boldItalicFontFamilyName: "boldItalicFontFamilyName",
  boldFontFamilyName: "boldFontFamilyName",
  italicFontFamilyName: "italicFontFamilyName",
  fonts: {},
  variantToSourceFontMap: {},

  addFonts(fonts) {
    this.mapFonts(fonts);
    return this;
  },

  getFonts() {
    return this.fonts;
  },

  mapFonts(fonts) {
    values(fonts).forEach(font => {
      this.fonts[font.fontFamilyName] = font;
      this.mapFontVariants(font);
    });
  },

  addToVariantToSourceFontMap(fontMap) {
    merge(this.variantToSourceFontMap, fontMap);
  },

  mapFontVariants(font) {
    if (font.boldFontFamilyName && font.boldFontFamilyName !== null) {
      this.addToVariantToSourceFontMap({
        [font.boldFontFamilyName]: font.fontFamilyName
      });
    }

    if (font.italicFontFamilyName && font.italicFontFamilyName !== null) {
      this.addToVariantToSourceFontMap({
        [font.italicFontFamilyName]: font.fontFamilyName
      });
    }

    if (
      font.boldItalicFontFamilyName &&
      font.boldItalicFontFamilyName !== null
    ) {
      this.addToVariantToSourceFontMap({
        [font.boldItalicFontFamilyName]: font.fontFamilyName
      });
    }
  },

  checkIfFontHasBoldOption({ fontName, isItalicVersion = false }) {
    const fontData = this.getFontData(fontName);

    if (!fontData || isEmpty(fontData)) {
      return false;
    }

    const familyName = isItalicVersion
      ? this.boldItalicFontFamilyName
      : this.boldFontFamilyName;

    return fontData.hasOwnProperty(familyName);
  },

  checkIfFontHasItalicOption({ fontName, isBoldVersion = false }) {
    const fontData = this.getFontData(fontName);

    if (!fontData || isEmpty(fontData)) {
      return false;
    }

    const familyName = isBoldVersion
      ? this.boldItalicFontFamilyName
      : this.italicFontFamilyName;

    return fontData.hasOwnProperty(familyName);
  },

  getFontData(fontName) {
    let fontData;

    if (this.fonts.hasOwnProperty(fontName)) {
      fontData = this.fonts[fontName];
    } else {
      const sourceFontName = this.variantToSourceFontMap[fontName];
      fontData = this.fonts[sourceFontName];
    }

    if (!fontData || !fontData.fontFamilyName) {
      return {};
    }

    return fontData;
  },

  getFontFamilyFor({
    fontName,
    isItalicVersion = false,
    isBoldVersion = false
  }) {
    const fontData = this.getFontData(fontName);

    if (isBoldVersion && isItalicVersion) {
      return fontData[this.boldItalicFontFamilyName];
    }

    if (isBoldVersion && !isItalicVersion) {
      return fontData[this.boldFontFamilyName];
    }

    if (!isBoldVersion && isItalicVersion) {
      return fontData[this.italicFontFamilyName];
    }

    return fontData.fontFamilyName;
  }
};

export default FontToolBox;
