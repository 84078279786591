import { cloneDeep } from "lib";
import {
  getVideoDimensionsFromElement,
  getVideoDurationFromSrc
} from "lib/videoHelpers";
import { DEFAULT_MASK } from "lib/constants";

export const processVideo = async (element, page) => {
  const adaptedElement = cloneDeep(element);

  if (!adaptedElement.srcHeight || !adaptedElement.srcWidth) {
    // image and background elements should always have a srcWidth and srcHeight, this missing can cause resize and crop issues
    const videoSize = await getVideoDimensionsFromElement(element);

    adaptedElement.srcHeight = videoSize.height;
    adaptedElement.srcWidth = videoSize.width;
  }

  // the use of previewUrl on a video element is deprecated and should be replaced with previewSrc
  if (adaptedElement.previewUrl) {
    if (!adaptedElement.previewSrc) {
      adaptedElement.previewSrc = adaptedElement.previewUrl;
    }
    adaptedElement.previewUrl = undefined;
  }

  // check for invalid scale
  if (!adaptedElement.scale || adaptedElement.scale <= 0) {
    // check that there is height and width to work from
    if (adaptedElement.height <= 0 || adaptedElement.width <= 0) {
      if (adaptedElement.height <= 0 && !(adaptedElement.width <= 0)) {
        // the height is missing but we have a width
        adaptedElement.height =
          adaptedElement.srcHeight *
          (adaptedElement.width / adaptedElement.srcWidth);
      } else if (!(adaptedElement.height <= 0) && adaptedElement.width <= 0) {
        // the width is missing but we have a height
        adaptedElement.width =
          adaptedElement.srcWidth *
          (adaptedElement.height / adaptedElement.srcHeight);
      } else {
        // both height and width missing
        adaptedElement.height = adaptedElement.srcHeight;
        adaptedElement.width = adaptedElement.srcWidth;
      }
    }

    const scaleToFit = Math.min(
      adaptedElement.height / adaptedElement.srcHeight,
      adaptedElement.width / adaptedElement.srcWidth
    );

    adaptedElement.scale = scaleToFit;
    adaptedElement.height = adaptedElement.srcHeight * scaleToFit;
    adaptedElement.width = adaptedElement.srcWidth * scaleToFit;
  }

  if (adaptedElement.height <= 0) {
    adaptedElement.height = adaptedElement.srcHeight * adaptedElement.scale;
  }

  if (adaptedElement.width <= 0) {
    adaptedElement.width = adaptedElement.srcWidth * adaptedElement.scale;
  }

  // handle animated elements which are not already included in the page animatedElementsObject
  if (
    adaptedElement.duration &&
    page.animatedElements &&
    !Object.keys(page.animatedElements).includes(element.uniqueId)
  ) {
    window.easil.designAdapter.push({
      duration: adaptedElement.duration,
      uniqueId: element.uniqueId,
      animationDataKey: element.uniqueId,
      pageId: page.uniqueId,
      groupId: element.groupId
    });
  }

  // assign duration to video elements with no duration property
  if (adaptedElement.type === "video" && !adaptedElement.duration) {
    adaptedElement.duration = await getVideoDurationFromSrc(adaptedElement.src);

    // push animated data to designAdapter for animatedElements assignment
    window.easil.designAdapter.push({
      duration: adaptedElement.duration,
      uniqueId: element.uniqueId,
      animationDataKey: element.uniqueId,
      pageId: page.uniqueId,
      groupId: element.groupId
    });
  }

  // videos should be adapted in the same way adding originalSrc and a mask if none is present
  return {
    ...adaptedElement,
    originalSrc: element.originalSrc || element.src,
    mask: element.mask || DEFAULT_MASK,
    src: element.previewSrc || element.src
  };
};

export default processVideo;
