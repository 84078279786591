import * as types from "./editorErrorModalTypes";

// update redux editor error modal state to show the modal
export const showEditorErrorModal = ({ user, errorType, path }) => {
  return {
    type: types.SHOW_EDITOR_ERROR_MODAL,
    user,
    errorType,
    path
  };
};
// update redux editor error modal state to hide the modal
export const hideEditorErrorModal = () => {
  return {
    type: types.HIDE_EDITOR_ERROR_MODAL
  };
};
