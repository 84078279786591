import * as teamMenuTypes from "state/ui/header/TeamMenu/TeamMenuTypes";
import { switchToken } from "state/currentUser/currentUserActions";
import { moveToWorkspace } from "state/ui/navigation/navigationActions";
import { noop } from "lib";
import {
  fetchSubTeamsIfNeeded,
  fetchNextPageOfSubTeamsIfNeeded
} from "state/entities/teams/teamsActions";
import { parentTeamSelector } from "state/entities/teamHierarchy/teamHierarchySelectors";
import { searchTeams as apiSearchTeams } from "state/entities/teams/teamsActions";

export const clearAll = () => dispatch => {
  dispatch({
    type: teamMenuTypes.CLEAR_ALL
  });
};

export const clearSearch = () => dispatch => {
  dispatch({
    type: teamMenuTypes.CLEAR_SEARCH
  });
};

/**
 * Fetch the sub teams of the team with the given parentId.
 * @param {Object} selectedTeam - the team object of the selected team.
 * @param {number} page = 1 - the page to fetch, defaulted to 1 if not provided
 */
export const navigateDown = (selectedTeam, page = 1) => dispatch => {
  dispatch({
    type: teamMenuTypes.NAVIGATE_DOWN,
    payload: {
      selectedTeam
    }
  });
  dispatch(
    fetchNextPageOfSubTeamsIfNeeded({ noop, parentId: selectedTeam.id })
  );
};

/**
 * Fetch the sub teams of the parent of the currently selected team to navigate back up the team
 * hierarchy
 * @param {Object} selectedTeam - the team object of the selected team.
 * @param {number} page = 1 - the page to fetch, defaulted to 1 if not provided
 */
export const navigateUp = (selectedTeam, page = 1) => (dispatch, getState) => {
  const parentTeam = parentTeamSelector(getState(), selectedTeam.parentPathArr);
  dispatch({
    type: teamMenuTypes.NAVIGATE_UP,
    payload: {
      selectedTeam: parentTeam
    }
  });
  if (parentTeam) {
    dispatch(fetchSubTeamsIfNeeded({ page, noop, parentId: parentTeam.id }));
  }
};

export const searchTeams = searchObj => dispatch => {
  dispatch({
    type: teamMenuTypes.SEARCH_TEAMS,
    payload: {
      term: searchObj.name
    }
  });
  dispatch(apiSearchTeams(searchObj));
};

export const switchTeams = ({ teamId }) => dispatch => {
  dispatch({
    type: teamMenuTypes.SWITCH_TEAMS
  });

  dispatch(
    switchToken({
      teamId,
      onSuccess: dispatch(moveToWorkspace())
    })
  );
};
