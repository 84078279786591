import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const JaggedMountainOutOfFrameIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 56}
    height={height || size || 44}
    viewBox="0 0 56 44"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <rect
        fill={color || easilGrayXDark}
        x="6"
        y="6"
        width="50"
        height="38"
        rx="2"
      />
      <path
        d="M52 4.103v31.794C52 38.163 50.21 40 48 40H4c-2.21 0-4-1.837-4-4.103V4.103C0 1.837 1.79 0 4 0h44c2.21 0 4 1.837 4 4.103zm-2 0c0-1.133-.895-2.052-2-2.052H4c-1.105 0-2 .919-2 2.052v31.794c0 1.133.895 2.052 2 2.052h44c1.105 0 2-.919 2-2.052V4.103z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path d="M14 8h24v24H14z" />
      <path
        d="M30.134 16.509a1.368 1.368 0 0 1 2.175.04l3.42 4.642a1.387 1.387 0 0 1-.284 1.932c-.61.455-1.47.326-1.921-.287l-2.36-3.201-4.994 6.274a1.368 1.368 0 0 1-2.026.13l-2.092-2.032-3.612 4.482a1.368 1.368 0 0 1-1.932.203 1.387 1.387 0 0 1-.201-1.943l4.555-5.653a1.368 1.368 0 0 1 2.02-.123l2.086 2.026zM20.133 12a2.295 2.295 0 0 1 2.288 2.302 2.295 2.295 0 0 1-2.288 2.302 2.295 2.295 0 0 1-2.289-2.302A2.295 2.295 0 0 1 20.133 12z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

JaggedMountainOutOfFrameIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default JaggedMountainOutOfFrameIcon;
