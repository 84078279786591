import React from "react";
import ImageInstructionDropZone from "views/components/Editor/elements/grid/ImageInstructionDropZone";
import ImageInstructionContent from "./ImageInstructionContent";

export const ImageInstruction = props => {
  return (
    <>
      {!props.isDropzoneHidden && <ImageInstructionDropZone {...props} />}
      {!props.isContentHidden && (
        <ImageInstructionContent
          imageInstruction={props.imageInstruction}
          zoom={props.zoom}
          scale={props.scale}
          instructionScale={props.instructionScale}
          element={props.element}
          isPlaying={props.isPlaying}
          autoPlay={props.autoPlay}
          height={props.height}
          width={props.width}
          customStyles={props.customStyles}
          isForcedFullOpacity={props.isForcedFullOpacity}
          isMuted={props.isMuted}
          leftMaskOffset={props.leftMaskOffset}
          topMaskOffset={props.topMaskOffset}
          renderVersion={props.renderVersion}
        />
      )}
    </>
  );
};

export default ImageInstruction;
