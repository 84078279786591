import {
  getCanvasbackgroundContentWrapper,
  getPageHtmlNode
} from "views/components/Editor/utils";
import { debounce, getPath } from "lib";

class SnapCoordinatesSystem {
  static buildDummyInstance() {
    let dummyDiv = document.createElement("div");
    let dummyBackgroundElement = dummyDiv;

    if (window.STORYBOOK_ENV) {
      dummyDiv = document.getElementById("PAGE-storybookMockCanvasWrapper");
      dummyBackgroundElement = dummyDiv.parentElement;
    }

    const dummyInstance = new SnapCoordinatesSystem({
      snapPoints: { x: [], y: [] },
      canvasHtmlElement: dummyDiv,
      canvasBackgroundHtmlElement: dummyBackgroundElement,
      zoom: 1
    });

    dummyInstance.addScrollEventListener();
    dummyInstance.updateSnapPointsCorrected();

    return dummyInstance;
  }
  static Builder({ pageId, excludeIds }) {
    if (!getPath(window, "easil.editor.state.snapToCorners")) {
      return SnapCoordinatesSystem.buildDummyInstance();
    }

    const { designData } = window.easil.editor;
    const { zoom } = window.easil.editor.state;

    const instance = new SnapCoordinatesSystem({
      snapPoints: designData.getSnapPoints({ pageId, excludeIds }),
      canvasHtmlElement: getPageHtmlNode(pageId),
      canvasBackgroundHtmlElement: getCanvasbackgroundContentWrapper(),
      zoom
    });

    instance.addScrollEventListener();
    instance.updateSnapPointsCorrected();

    return instance;
  }

  static SNAP_TRHESHOLD = 5; //pixels

  constructor({
    snapPoints,
    canvasHtmlElement,
    canvasBackgroundHtmlElement,
    zoom
  } = {}) {
    this.zoom = zoom;
    // { x: [], y: [] }
    this.snapPoints = snapPoints;
    // { x: [], y: [] } corrected for zoom and page offset
    this.snapPointsCorrected = null;
    // htmlElement
    this.canvasHtmlElement = canvasHtmlElement;
    // htmlElement
    this.canvasBackgroundHtmlElement = canvasBackgroundHtmlElement;

    this.updateSnapPointsCorrectedDebounced = debounce(
      this.updateSnapPointsCorrected.bind(this),
      300
    );
  }

  getSnapPointsCorrected() {
    return this.snapPointsCorrected;
  }

  addScrollEventListener() {
    this.canvasBackgroundHtmlElement.addEventListener(
      "scroll",
      this.updateSnapPointsCorrectedDebounced
    );
  }

  removeScrollEventListener() {
    this.canvasBackgroundHtmlElement.removeEventListener(
      "scroll",
      this.updateSnapPointsCorrectedDebounced
    );
  }

  updateSnapPointsCorrected() {
    const canvasBoundingRect = this.canvasHtmlElement.getBoundingClientRect();

    const snapPointsXCorrected = this.snapPoints.x
      .map(value => value * this.zoom + canvasBoundingRect.x)
      .sort(function(a, b) {
        return a - b;
      });

    const snapPointsYCorrected = this.snapPoints.y
      .map(value => value * this.zoom + canvasBoundingRect.y)
      .sort(function(a, b) {
        return a - b;
      });

    this.snapPointsCorrected = {
      x: snapPointsXCorrected,
      y: snapPointsYCorrected
    };
  }
}

export default SnapCoordinatesSystem;
