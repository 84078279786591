import * as foldersTypes from "state/entities/designFolders/designFoldersTypes";
import * as types from "./BrandKitTypes";
import { CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS } from "state/currentUser/currentUserTypes";
import { cloneDeep } from "lib";
import { getParameterByName } from "lib/queryStringUtils";

export const initState = {
  colors: {
    entries: null,
    errors: {},
    isProcessing: false,
    isSuccess: false,
    selected: null
  },
  fonts: {
    entries: null,
    errors: {},
    isProcessing: false,
    isSuccess: false
  },
  images: {
    entries: undefined,
    entriesFolderId: undefined,
    entriesTerm: undefined,
    error: undefined,
    isMoreAvailable: undefined,
    isProcessing: undefined,
    page: undefined
  },
  logos: {
    entries: undefined,
    entriesFolderId: undefined,
    entriesTerm: undefined,
    error: undefined,
    isMoreAvailable: undefined,
    isProcessing: undefined,
    page: undefined
  }
};

const BrandKitReducers = (state = initState, action) => {
  switch (action.type) {
    // Colors

    case types.BRAND_KIT_UPDATE_SELECTED_COLOR: {
      const { colorId } = action;

      const colors = Object.assign({}, state.colors, {
        selected: colorId
      });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_REQUEST_COLOR_GROUP: {
      const colors = Object.assign({}, state.colors, { isProcessing: true });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_REQUEST_COLOR_GROUP_SUCCESS: {
      let colorGroups = null;

      if (action.response.entities) {
        const {
          entities: { colorGroup }
        } = action.response;
        colorGroups = colorGroup;
      }

      const colors = Object.assign({}, state.colors, {
        entries: colorGroups,
        isProcessing: false,
        isSuccess: true
      });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_REQUEST_COLOR_GROUP_FAILURE: {
      const { error } = action;
      const errors = error.errors.map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });
      const colors = Object.assign({}, state.colors, { errors });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_CREATE_COLOR_GROUP_REQUEST: {
      const colors = Object.assign({}, state.colors, {
        isProcessing: true,
        isSuccess: false
      });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_CREATE_COLOR_GROUP_REQUEST_SUCCESS: {
      const {
        ids: id,
        entities: { colorGroup }
      } = action.response;

      const updatedColors = Object.assign({}, state.colors.entries, colorGroup);
      const selectedColor = parseInt(Object.keys(colorGroup[id].colors)[0], 10);
      const colors = Object.assign({}, state.colors, {
        entries: updatedColors,
        isProcessing: false,
        isSuccess: true,
        selected: selectedColor
      });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_CREATE_COLOR_GROUP_REQUEST_FAILURE: {
      const { error } = action;
      const errors = error.errors.map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });
      const colors = Object.assign({}, state.colors, { errors });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_UPDATE_COLOR_GROUP_REQUEST: {
      const colors = Object.assign({}, state.colors, {
        isProcessing: true,
        isSuccess: false
      });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_UPDATE_COLOR_GROUP_REQUEST_SUCCESS: {
      let selectedColor = state.colors.selected;
      const {
        entities: { colorGroup }
      } = action.response;
      const group = Object.values(colorGroup)[0];
      const colorGroups = Object.assign({}, state.colors.entries);
      const originalColorGroup = colorGroups[group.id];
      colorGroups[group.id] = group;
      const groupColors = colorGroups[group.id].colors;
      if (
        Object.keys(originalColorGroup.colors).length <
        Object.keys(groupColors).length
      ) {
        selectedColor = parseInt(
          Object.keys(groupColors)[Object.keys(groupColors).length - 1],
          10
        );
      }
      const colors = Object.assign({}, state.colors, {
        entries: colorGroups,
        isProcessing: false,
        isSuccess: true,
        selected: selectedColor
      });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_UPDATE_COLOR_GROUP_REQUEST_FAILURE: {
      const { error } = action;
      const errors = error.errors.map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });
      const colors = Object.assign({}, state.colors, { errors });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST: {
      const colors = Object.assign({}, state.colors, {
        isProcessing: true,
        isSuccess: false
      });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST_SUCCESS: {
      const { id } = action.response;

      if (!id) return state;

      const groupId = action.response.id;
      const newColorGroups = state.colors.entries;
      delete newColorGroups[groupId];
      const colors = Object.assign({}, state.colors, {
        entries: newColorGroups,
        isProcessing: false,
        isSuccess: true
      });

      return {
        ...state,
        colors
      };
    }

    case types.BRAND_KIT_REMOVE_COLOR_GROUP_REQUEST_FAILURE: {
      const { error } = action;
      const errors = error.errors.map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });
      const colors = Object.assign({}, state.colors, { errors });

      return {
        ...state,
        colors
      };
    }

    // Images:

    case types.BRAND_KIT_IMAGES_CLEAR: {
      return {
        ...state,
        images: initState.images
      };
    }
    case types.BRAND_KIT_LOGOS_CLEAR: {
      return {
        ...state,
        logos: initState.logos
      };
    }

    case types.CREATE_IMAGE_FAILURE:
    case types.CREATE_IMAGE_REQUEST:
    case types.CREATE_IMAGE_SUCCESS:
    case types.REMOVE_IMAGE_FAILURE:
    case types.REMOVE_IMAGE_REQUEST:
    case types.REMOVE_IMAGE_SUCCESS:
    case types.SEARCH_IMAGES_REQUEST:
    case types.SEARCH_IMAGES_FAILURE:
    case types.SEARCH_IMAGES_SUCCESS:
    case types.UPDATE_IMAGE_FAILURE:
    case types.UPDATE_IMAGE_REQUEST:
    case types.UPDATE_IMAGE_SUCCESS: {
      let entries = cloneDeep([...(state.images.entries || [])]);
      let entriesFolderId = state.images.entriesFolderId;
      let entriesTerm = state.images.entriesTerm;
      let isMoreAvailable = state.images.isMoreAvailable;
      let page = state.images.page;

      const isProcessing =
        action.type === types.CREATE_IMAGE_REQUEST ||
        action.type === types.REMOVE_IMAGE_REQUEST ||
        action.type === types.SEARCH_IMAGES_REQUEST ||
        action.type === types.UPDATE_IMAGE_REQUEST;

      if (action.type === types.SEARCH_IMAGES_SUCCESS) {
        let arr = Array.isArray(action.response) ? action.response : [];

        entriesFolderId = action.extra.folderId;
        entriesTerm = action.extra.term;
        isMoreAvailable = arr.length === action.request.pageSize;
        page = action.request.page;

        arr = arr.map(entry => {
          const originalEntry = entries.find(
            image => image.mediaId === entry.mediaId
          );
          if (!originalEntry) {
            return entry;
          }
          if (originalEntry.thumbnailUrl.startsWith("blob:")) {
            entry.preservedThumbUrl = entry.thumbnailUrl;
            entry.thumbnailUrl = originalEntry.thumbnailUrl;
          }
          return entry;
        });

        entries = page > 1 ? [...entries, ...arr] : arr;
      }

      if (action.type === types.CREATE_IMAGE_SUCCESS && !entriesTerm) {
        const brandKitImage = action.response;

        brandKitImage.preservedThumbUrl = brandKitImage.thumbnailUrl;
        brandKitImage.thumbnailUrl = action.extra.placeholderURL;

        if (
          brandKitImage &&
          brandKitImage.media &&
          !brandKitImage.media.priority
        ) {
          brandKitImage.media.priority = null;
        }

        entries = [brandKitImage, ...entries];
      }

      if (action.type === types.UPDATE_IMAGE_SUCCESS) {
        const updatedImage = action.response;

        entries = entries.map(entry =>
          entry.mediaId === updatedImage.mediaId ||
          entry.id === updatedImage.mediaId
            ? updatedImage
            : entry
        );
      }

      if (action.type === types.REMOVE_IMAGE_SUCCESS) {
        entries = entries.filter(
          ({ id, mediaId }) => (id || mediaId) !== action.extra.imageId
        );
      }

      return {
        ...state,
        images: {
          entries: entries.map(entry => ({ ...entry, origin: "teamImage" })),
          entriesFolderId,
          entriesTerm,
          error: action.error,
          isMoreAvailable,
          isProcessing,
          page
        }
      };
    }

    // Logos:

    case types.CREATE_LOGO_FAILURE:
    case types.CREATE_LOGO_REQUEST:
    case types.CREATE_LOGO_SUCCESS:
    case types.REMOVE_LOGO_FAILURE:
    case types.REMOVE_LOGO_REQUEST:
    case types.REMOVE_LOGO_SUCCESS:
    case types.SEARCH_LOGOS_REQUEST:
    case types.SEARCH_LOGOS_FAILURE:
    case types.SEARCH_LOGOS_SUCCESS:
    case types.UPDATE_LOGO_FAILURE:
    case types.UPDATE_LOGO_REQUEST:
    case types.UPDATE_LOGO_SUCCESS: {
      let entries = [...(state.logos.entries || [])];
      let entriesFolderId = state.logos.entriesFolderId;
      let entriesTerm = state.logos.entriesTerm;
      let isMoreAvailable = state.logos.isMoreAvailable;
      let page = state.logos.page;

      const isProcessing =
        action.type === types.CREATE_LOGO_REQUEST ||
        action.type === types.REMOVE_LOGO_REQUEST ||
        action.type === types.SEARCH_LOGOS_REQUEST ||
        action.type === types.UPDATE_LOGO_REQUEST;

      if (action.type === types.SEARCH_LOGOS_SUCCESS) {
        const arr = Array.isArray(action.response) ? action.response : [];

        entriesFolderId = action.extra.folderId;
        entriesTerm = action.extra.term;
        isMoreAvailable = arr.length === action.request.pageSize;
        page = action.request.page;
        entries = page > 1 ? [...entries, ...arr] : arr;
      }

      if (
        action.type === types.CREATE_LOGO_SUCCESS &&
        !entriesFolderId &&
        !entriesTerm
      ) {
        entries = [action.response, ...entries];
      }

      if (action.type === types.UPDATE_LOGO_SUCCESS) {
        entries = entries.map(entry =>
          entry.mediaId === action.response.mediaId ? action.response : entry
        );
      }

      if (action.type === types.REMOVE_LOGO_SUCCESS) {
        entries = entries.filter(
          ({ id, mediaId }) => (id || mediaId) !== action.extra.logoId
        );
      }

      return {
        ...state,
        logos: {
          entries: entries.map(entry => ({ ...entry, origin: "teamLogo" })),
          entriesFolderId,
          entriesTerm,
          error: action.error,
          isMoreAvailable,
          isProcessing,
          page
        }
      };
    }

    // Images & Logos:

    case foldersTypes.ADD_CONTENT_TO_TEAM_FOLDER_SUCCESS:
    case foldersTypes.REMOVE_CONTENT_FROM_TEAM_FOLDER_SUCCESS: {
      let removeId;
      const isInfolder = getParameterByName("folderId", action.queryParams);
      const isSearching = getParameterByName("term", action.queryParams);

      if (isSearching && !isInfolder) {
        return state;
      }
      if (action.type === foldersTypes.ADD_CONTENT_TO_TEAM_FOLDER_SUCCESS) {
        removeId = action.request.body.contentId;
      } else if (
        action.type === foldersTypes.REMOVE_CONTENT_FROM_TEAM_FOLDER_SUCCESS
      ) {
        removeId = action.extra.contentId;
      }

      const removeEntryById = (stateEntries, _removeId) => {
        const entries = cloneDeep(stateEntries || []);

        const removeEntryIndex = entries.findIndex(
          ({ id, mediaId }) => (id || mediaId) === _removeId
        );
        if (
          removeEntryIndex !== -1 &&
          (!entries[removeEntryIndex].thumbnailUrl.startsWith("blob:") ||
            !action.extra.isFolderUpload)
        ) {
          // when the entry exists and it is not a blob entry or it was not a folder upload we remove it
          entries.splice(removeEntryIndex, 1);
        }

        return entries;
      };

      return {
        ...state,
        images: {
          ...state.images,
          entries: removeEntryById(state.images.entries, removeId)
        },
        logos: {
          ...state.logos,
          entries: removeEntryById(state.logos.entries, removeId)
        }
      };
    }

    // Fonts

    case types.BRAND_KIT_REQUEST_FONTS: {
      const fonts = Object.assign({}, state.fonts, {
        isProcessing: true,
        isUpdating: false,
        isSuccess: false
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_REQUEST_FONTS_SUCCESS: {
      let fontsList = null;
      if (action.response.entities) {
        const {
          response: {
            entities: { brandKitFont }
          }
        } = action;

        if (
          state.fonts.entries &&
          Object.keys(state.fonts.entries).length > 0
        ) {
          fontsList = Object.assign({}, state.fonts.entries, brandKitFont);
        } else {
          fontsList = brandKitFont;
        }
      }

      const fonts = Object.assign({}, state.fonts, {
        entries: fontsList || [],
        isProcessing: false,
        isUpdating: false,
        isSuccess: true
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_REQUEST_FONTS_FAILURE: {
      const { error } = action;
      const errors = (error.errors || []).map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });
      const fonts = Object.assign({}, state.fonts, { errors });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_UPLOAD_FONT_REQUEST: {
      const fonts = Object.assign({}, state.fonts, {
        entries: state.fonts.entries,
        isProcessing: false,
        isUpdating: true,
        isSuccess: false
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_UPLOAD_FONT_REQUEST_SUCCESS: {
      const fonts = Object.assign({}, state.fonts, {
        entries: state.fonts.entries,
        isProcessing: false,
        isUpdating: false,
        isSuccess: true
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_UPLOAD_FONT_REQUEST_FAILURE: {
      const fonts = Object.assign({}, state.fonts, {
        entries: state.fonts.entries,
        isProcessing: false,
        isUpdating: false,
        isSuccess: false
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_CREATE_FONT_REQUEST: {
      const fonts = Object.assign({}, state.fonts, {
        isProcessing: false,
        isUpdating: true,
        isSuccess: false
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_CREATE_FONT_REQUEST_SUCCESS: {
      const {
        response: {
          entities: { brandKitFont: newFont }
        }
      } = action;

      const updatedFontsList = Object.assign({}, state.fonts.entries, newFont);
      const fonts = Object.assign({}, state.logos, {
        entries: updatedFontsList,
        isProcessing: false,
        isUpdating: false,
        isSuccess: true
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_CREATE_FONT_REQUEST_FAILURE: {
      const { error } = action;
      const errors = error.errors.map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });
      const fonts = Object.assign({}, state.fonts, { errors });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_UPDATE_FONT_REQUEST: {
      const fonts = Object.assign({}, state.fonts, {
        isProcessing: false,
        isUpdating: true,
        isSuccess: false
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_UPDATE_FONT_REQUEST_SUCCESS: {
      const {
        response: {
          ids: fontId,
          entities: { brandKitFont: updatedFonts }
        }
      } = action;

      const updatedFont = updatedFonts[fontId];
      const fontsList = Object.assign({}, state.fonts.entries);
      fontsList[fontId] = updatedFont;

      const fonts = Object.assign({}, state.fonts, {
        entries: fontsList,
        isProcessing: false,
        isUpdating: false,
        isSuccess: true
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_UPDATE_FONT_REQUEST_FAILURE: {
      const { error } = action;
      const errors = error.errors.map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });
      const fonts = Object.assign({}, state.fonts, { errors });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_PARTIAL_UPDATE_FONT_REQUEST: {
      const fonts = Object.assign({}, state.fonts, {
        isProcessing: false,
        isUpdating: true,
        isSuccess: false
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_PARTIAL_UPDATE_FONT_REQUEST_SUCCESS: {
      const {
        response: {
          ids: fontId,
          entities: { brandKitFont: updatedFonts }
        }
      } = action;

      const updatedFont = updatedFonts[fontId];
      const fontsList = Object.assign({}, state.fonts.entries);
      fontsList[fontId] = updatedFont;

      const fonts = Object.assign({}, state.fonts, {
        entries: fontsList,
        isProcessing: false,
        isUpdating: false,
        isSuccess: true
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_PARTIAL_UPDATE_FONT_REQUEST_FAILURE: {
      const { error } = action;
      const errors = error.errors.map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });
      const fonts = Object.assign({}, state.fonts, { errors });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_REMOVE_FONT_REQUEST: {
      const fonts = Object.assign({}, state.fonts, {
        entries: state.fonts.entries,
        isProcessing: false,
        isUpdating: true,
        isSuccess: false
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_REMOVE_FONT_REQUEST_SUCCESS: {
      const { id } = action.response;

      if (!id) return state;

      const fontsList = Object.assign({}, state.fonts.entries);
      delete fontsList[id];
      const fonts = Object.assign({}, state.fonts, {
        entries: fontsList,
        isProcessing: false,
        isUpdating: false,
        isSuccess: true
      });

      return {
        ...state,
        fonts
      };
    }

    case types.BRAND_KIT_REMOVE_FONT_REQUEST_FAILURE: {
      const { error } = action;
      const errors = error.errors.map(item => {
        return {
          error: item.path || "Error",
          value: item.message || "There was an issue processing your request"
        };
      });
      const fonts = Object.assign({}, state.fonts, { errors });

      return {
        ...state,
        fonts
      };
    }

    case CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS: {
      return initState;
    }

    default:
      return state;
  }
};

export default BrandKitReducers;
