import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PersonIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  isSmall,
  strokeWidth = 1.8,
  ...props
}) => {
  if (isSmall) {
    return (
      <svg
        {...props}
        data-no-pointer={noPointer}
        width={width || size || 20}
        height={height || size || 20}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h20v20H0z" />
          <path d="M0 0h20v20H0z" />
          <g
            transform="translate(2 1)"
            stroke={color || easilGrayXDark}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={strokeWidth}
          >
            <path d="M16 18v-2a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v2" />
            <circle cx="8" cy="4" r="4" />
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg
      {...props}
      data-no-pointer={noPointer}
      width={width || size || 26}
      height={height || size || 30}
      viewBox="0 0 26 30"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g id="ROLES-+-Proto" stroke="none" fill="none" fillRule="evenodd">
        <path d="M-3-1h32v32H-3z" />
        <g
          stroke={color || easilGrayXDark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
          transform="translate(1 1)"
        >
          <path d="M24 28v-3.111c0-3.437-2.686-6.222-6-6.222H6c-3.314 0-6 2.785-6 6.222V28" />
          <ellipse cx="12" cy="6.222" rx="6" ry="6.222" />
        </g>
      </g>
    </svg>
  );
};
PersonIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PersonIcon;
