import { castArray } from "lodash";

export const formatErrors = (error, isCustomerFriendly) => {
  if (error.errors) {
    return castArray(error.errors).map(item => {
      return {
        error: !isCustomerFriendly && item.path ? item.path : "Error",
        value:
          !isCustomerFriendly && item.message
            ? item.message
            : "There was an issue processing your request"
      };
    });
  }

  return [
    {
      error: error.name,
      value: "There was an issue processing your request"
    }
  ];
};

const extractPortalPasswordResetSubdomain = url => {
  const regex = /^https?:\/\/login\.([^.]+)\.com/;
  const match = url.match(regex);
  let text = "Portal";
  switch (match[1]) {
    case "easil": {
      text = "Easil " + text;
      break;
    }
    case "inkco": {
      text = "Inkco " + text;
      break;
    }
    case "copirite": {
      text = "Copirite " + text;
      break;
    }
    default: {
      return text;
    }
  }
  return text;
};

export const passwordErrorParse = (errorMessage, error) => {
  if (error && error.path === "portalUser") {
    return (
      <span>
        Please use the{" "}
        <a href={errorMessage} style={{ textDecoration: "underline" }}>
          {extractPortalPasswordResetSubdomain(errorMessage)}
        </a>{" "}
        to reset your password
      </span>
    );
  }
  if (!errorMessage) return;
  switch (errorMessage) {
    case "password is an invalid length":
    case "password must be equal to the defined value": {
      return "Provided current password is incorrect";
    }
    case "newPassword is an invalid length": {
      return "Provided new password is an invalid length";
    }
    case "newPassword must not be in the defined set": {
      return "Please use a stronger password";
    }
    default: {
      return errorMessage.replace("newPassword", "New password");
    }
  }
};

export const emailErrorParse = error => {
  if (!error) return;
  switch (error) {
    case "email is invalid":
    default: {
      return "Please enter a valid email address";
    }
  }
};
