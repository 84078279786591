import * as types from "./clipBoardTypes";
import { makeToast } from "state/ui/toaster/ToasterActions";
import { ClipboardFailToastContent } from "views/components/ClipboardFailToastContent/ClipboardFailToastContent";
import { clipBoardSelectors } from "./clipBoardSelectors";
import style from "views/components/ClipboardFailToastContent/style.module.css";

export const copyToClipBoard = ({ elementsCopyArray, sourceDesignSpecs }) => {
  return {
    type: types.CLIP_BOARD_COPY,
    elementsCopyArray,
    sourceDesignSpecs
  };
};

/**
 * @desc clears the element clipboard state
 */
export const clearClipBoard = () => {
  return { type: types.CLIP_BOARD_CLEAR };
};

/**
 * @desc shows the clip board paste failure toast
 */
export const showToastForClipboardPasteFailure = () => dispatch => {
  dispatch(
    makeToast({
      textComponent: ClipboardFailToastContent,
      type: "noButton",
      icon: "warning",
      className: style.pasteFailToast
    })
  );
};

/**
 * @desc clears the element clipboard if it has content and notifies the user
 */
export const clearClipboardAndTriggerToast = () => (dispatch, getState) => {
  const state = getState();
  const clipBoardState = clipBoardSelectors({ state });
  if (!clipBoardState || !clipBoardState.elementsCopyArray) {
    return;
  }
  dispatch(clearClipBoard());
  dispatch(showToastForClipboardPasteFailure());
};
