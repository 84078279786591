import React, { Component } from "react";
import style from "./style.module.css";
import { getEmptyImage } from "react-dnd-html5-backend";
import { identity } from "lib";
import VideoCroppingResizeHandler from "../VideoCroppingResizeHandler";
import ResizeBox from "views/components/Editor/ResizeBox";
import PATHS from "routes/paths";

class MaskedVideo extends Component {
  static defaultProps = {
    connectDragSource: identity
  };

  constructor(props) {
    super(props);

    this.getVideoSource = this.getVideoSource.bind(this);

    this.state = {
      isRenderer: PATHS.isRenderer(window.location.pathname)
    };
  }

  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  getVideoMaskStyle() {
    const { videoElement, isCropping, zoom } = this.props;

    const { mask, scale, srcWidth, srcHeight } = videoElement;

    if (!mask) {
      return {};
    }

    const translateX = -mask.left * scale * zoom;
    const translateY = -mask.top * scale * zoom;

    const height = srcHeight * scale * zoom;
    const width = srcWidth * scale * zoom;

    return {
      opacity: isCropping ? 0 : 1,
      transformOrigin: "top left",
      transform: `translate(${translateX}px, ${translateY}px)`,
      filter: "brightness(0.5)",
      height,
      width
    };
  }

  getWrapperStyle({ videoElement, zoom }) {
    return {
      width: videoElement.width * zoom,
      height: videoElement.height * zoom,
      position: "absolute",
      transform: `translate(${videoElement.left * zoom}px, ${videoElement.top *
        zoom}px)`,
      transformOrigin: "0 0"
    };
  }

  getElementStyle({ videoElement, zoom }) {
    const styleProps = {
      transform: [
        `rotate(${videoElement.angle}deg)`,
        `scaleX(${videoElement.scaleX})`,
        `scaleY(${videoElement.scaleY})`
      ].join(" ")
    };

    const { height, opacity, width } = videoElement;

    const finalStyle = {
      ...styleProps,
      height: height * zoom,
      opacity: opacity,
      position: "relative",
      overflow: "show",
      width: width * zoom
    };

    return finalStyle;
  }

  getVideoSource(videoElement) {
    if (this.state.isRenderer) {
      return videoElement.originalSrc;
    }
    return videoElement.previewSrc || videoElement.originalSrc;
  }

  render() {
    const { connectDragSource, videoElement, zoom, maskStyle } = this.props;

    return connectDragSource(
      <div style={this.getWrapperStyle({ videoElement, zoom })}>
        <div style={this.getElementStyle({ videoElement, zoom })}>
          <div style={{ ...this.getVideoMaskStyle(), ...maskStyle }}>
            {/* <img
              alt=""
              className={style.image}
              src={this.getVideoSource(videoElement)}
            /> */}
            <video className={style.video} muted loop autoPlay alt="">
              <source
                src={this.getVideoSource(videoElement)}
                type="video/mp4"
              />
            </video>
            <ResizeBox
              isResizing={false}
              Handler={VideoCroppingResizeHandler}
              isCropping={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MaskedVideo;
