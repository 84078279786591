import { mergeWith, isArray, uniqBy, pick, isNil, omitBy, xor } from "lodash";
import { jsonStringEqual } from "./equalityUtils";

const uniqFilter = (value, filter) => {
  const comparitor = pick(value, ...filter); // get only filter key values
  return JSON.stringify(comparitor); // stringify them
};

const customizer = (objValue, srcValue, filterBy = []) => {
  if (isArray(objValue)) {
    const result = objValue.concat(srcValue); // join the arrays together
    return uniqBy(result, value => uniqFilter(value, filterBy)); // filter them by filters if applicable
  }
};

export const uniqByKeyValue = (arr, keys) =>
  uniqBy(arr, value => uniqFilter(value, Array.isArray(keys) ? keys : [keys]));

export const mergeWithArrayConcat = (object, other, filterBy) =>
  mergeWith(object, other, (objValue, srcValue) =>
    customizer(objValue, srcValue, filterBy)
  );

export const isNotNil = value => {
  return !isNil(value);
};

export const omitUnchanged = (obj1, obj2) => {
  const filteredOBJ = omitBy(obj1, (value, key) => {
    return jsonStringEqual(value, obj2[key]);
  });
  return filteredOBJ;
};

export const omitUnchangedComparison = (obj1, obj2) => {
  const filteredOBJ = omitUnchanged(obj1, obj2);
  return Object.keys(filteredOBJ).reduce(
    (comparisonValues, currentKey) => ({
      ...comparisonValues,
      [currentKey]: [obj1[currentKey], obj2[currentKey]]
    }),
    {}
  );
};

/**
 * @desc takes any number of booleans and returns an true if only one of them is true, otherwise returns false
 * @param  {...Boolean} booleans - the booleans to perform the xor on
 */
export const boolXor = (...booleans) => {
  // filter out any duplicate booleans
  const remaining = xor(...booleans.map(boolean => [boolean]));

  // if true is returned then there is only one true
  if (remaining.length === 2 || remaining.includes(true)) {
    return true;
  }
  // true was not returned, either all false or more than one true
  return false;
};
