import PATHS from "routes/paths";
import { push } from "react-router-redux";
import {
  appendObjectToURLParams,
  deleteObjectFromURLParams,
  objectToQueryString,
  queryStringToObject
} from "lib/query/query";
import { removeNils } from "lib/object/object";
import { isVideo } from "lib/isVideo";
import { isGif } from "lib/isGif";
import { EDITOR_SIDEBAR_TABS } from "lib/constants";
import Subscriptions from "lib/subscriptions";
import { SUBTAB_TYPES } from "views/components/Editor/sidebar/tabs/animations/animationsTabConstants.js";
import { TABS } from "views/components/Editor/sidebar/tabs/images/imageTabConstants";

// move to the team page
export const moveToTeamPage = () => dispatch => {
  dispatch(moveTo(PATHS.team));
};

// move to the cart complete page
export const moveToCartComplete = orderId => dispatch => {
  dispatch(moveTo(PATHS.cartComplete.replace(":orderId", orderId)));
};

// move to order details page
export const moveToOrderDetails = orderId => dispatch => {
  dispatch(moveTo(PATHS.ordersId.replace(":orderId", orderId)));
};

export const moveToBrandKit = () => dispatch => {
  dispatch(moveTo(PATHS.brand));
};

export const moveToBrandKitFonts = () => dispatch => {
  dispatch(moveTo(PATHS.brandFonts));
};

export const changeEditorTabToImages = tab => (dispatch, getState) => {
  dispatch(changeEditorTab("images"));
};
export const changeEditorTab = tab => (dispatch, getState) => {
  const tabsNames = EDITOR_SIDEBAR_TABS.map(option => option.name);

  if (!tabsNames.includes(tab)) {
    return;
  }

  dispatch(
    modifyQuery({
      appendTerms: { tab },
      dropTerms: ["subtab", "folderId"],
      clearCurrentQuery: true
    })
  );
};

export const moveToEditorDesignId = ({ designId }) => (dispatch, getState) => {
  dispatch(moveTo(PATHS.buildEditorDesignPath(designId)));
};

/**
 * @desc - moves to the purchased collections page
 */
export const moveToPurchased = () => dispatch => {
  dispatch(moveTo(PATHS.cataloguePurchased));
};

/**
 * @desc - moves to the workspace page
 */
export const moveToWorkspace = () => (dispatch, getState) => {
  dispatch(moveTo(PATHS.workspace));
};

/**
 * @desc - moves to the workspace smart text page
 */
export const moveToWorkspaceSmartText = () => (dispatch, getState) => {
  dispatch(moveTo(PATHS.workspaceUploadsSmartText));
};

/**
 * @desc - moves to the subscription page
 */
export const moveToSubscription = () => (dispatch, getState) => {
  dispatch(moveTo(PATHS.subscription));
};

/**
 * @desc - moves to the subscription page with only basic and subscription code provided
 * @param {string} subscriptionCode - code of subscription to be displayed
 */
export const moveToSubscriptionCode = subscriptionCode => (
  dispatch,
  getState
) => {
  let path = "/subscription/plans";

  if (subscriptionCode !== Subscriptions.BASIC.code) {
    path += `?planCode=${subscriptionCode}`;
  }

  dispatch(moveTo(path));
};

/**
 * @desc - moves to the catalogue page
 */
export const moveToCatalogue = () => (dispatch, getState) => {
  dispatch(moveTo(PATHS.catalogue));
};

/**
 * @desc - moves to the catalogue page with easil templates toggled on and the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToEasilCatalogueFiltered = filters => (dispatch, getState) => {
  const filterCleared = removeNils(filters);

  const url = PATHS.catalogueEasil + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the catalogue page with easil templates toggled on and the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToPurchasedCatalogueFiltered = filters => (
  dispatch,
  getState
) => {
  const filterCleared = removeNils(filters);

  const url = PATHS.cataloguePurchased + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the catalogue page with team templates toggled on and the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToTeamCatalogueFiltered = filters => (dispatch, getState) => {
  const filterCleared = removeNils({ ...filters, searchType: "size" });

  const url = PATHS.catalogueTemplates + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the catalogue archived page
 */
export const moveToCatalogueArchived = () => (dispatch, getState) => {
  const url = PATHS.catalogueArchived;

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the catalogue archived page with the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToCatalogueArchivedFiltered = filters => (
  dispatch,
  getState
) => {
  const filterCleared = removeNils(filters);

  const url = PATHS.catalogueArchived + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the catalogue folders page with the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToCatalogueFoldersFiltered = filters => (
  dispatch,
  getState
) => {
  const filterCleared = removeNils(filters);

  const url = PATHS.catalogueFolders + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the workspace drafts page with the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToWorkspaceDraftsFiltered = filters => (
  dispatch,
  getState
) => {
  const filterCleared = removeNils(filters);

  const url = PATHS.workspaceDrafts + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the workspace Images page
 */
export const moveToWorkspaceImages = () => (dispatch, getState) => {
  dispatch(moveTo(PATHS.workspaceUploadsImages));
};

/**
 * @desc - moves to the workspace archived page with the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToWorkspaceArchivedFiltered = filters => (
  dispatch,
  getState
) => {
  const filterCleared = removeNils(filters);

  const url = PATHS.workspaceArchived + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the workspace My Approved Designs tab with the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToWorkspaceApprovedFiltered = filters => (
  dispatch,
  getState
) => {
  const filterCleared = removeNils(filters);

  const url = PATHS.workspaceApproved + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

export const moveToWorkspaceBrandManagerApprovedFiltered = filters => (
  dispatch,
  getState
) => {
  const filterCleared = removeNils(filters);

  const url =
    PATHS.workspaceBrandManagerApproved + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the workspace archived page with the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToWorkspaceSharedFiltered = filters => (
  dispatch,
  getState
) => {
  const filterCleared = removeNils(filters);

  const url = PATHS.workspaceShared + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the workspace folders page with the provided filters added to query
 * @param {object} filter - key value pair of the filters to add to query
 */
export const moveToWorkspaceFoldersFiltered = filters => (
  dispatch,
  getState
) => {
  const filterCleared = removeNils(filters);

  const url = PATHS.workspaceFolders + objectToQueryString(filterCleared);

  dispatch(moveTo(url));
};

/**
 * @desc - moves to the catalogue with team templates toggled on
 */
export const moveToCatalogueTemplates = () => (dispatch, getState) => {
  dispatch(moveTo(PATHS.catalogueTemplates));
};

/**
 * @desc - takes an object of terms to add and an object of terms to drop and builds a query string by appending and deleting from url querystring <Additive>
 * @param {object={}} appendTerms - an object of key value pairs describing query parameters to append to the url @example appendTerms = {bar: 3}, "?foo=1&foo2=6" => "?foo=1&foo2=6&bar=3"
 * @param {[string]=[]} dropTerms - an array of keys to drop from the query string entirely @example dropTerms = ["bar"], "?foo=1&bar=3&foo2=6" => "?foo=1&foo2=6"
 */
export const modifyQuery = ({
  appendTerms = {},
  dropTerms = [],
  pathname = window.location.pathname,
  clearCurrentQuery
}) => (dispatch, getState) => {
  let searchParams =
    window.location.search && !clearCurrentQuery
      ? queryStringToObject(window.location.search)
      : {};

  // add new terms to search
  searchParams = appendObjectToURLParams(searchParams, appendTerms);

  // remove terms to be dropped
  searchParams = deleteObjectFromURLParams(searchParams, dropTerms);

  const url = `${pathname}${objectToQueryString(searchParams)}`;

  dispatch(moveTo(url));
};

/**
 * @desc - takes an object of search terms and replaces query params with these new params <Destructive>
 * @param {object} searchTerms - an object of key value pairs describing query parameters to use in url @example searchTerms = {bar: 3}, "?foo=1" => "?bar=3"
 */
export const search = searchTerms => (dispatch, getState) => {
  const location = objectToQueryString(searchTerms);

  dispatch(moveTo(location));
};

/**
 * @desc - moves to the catalogue with easil templates toggled on
 */
export const moveToCatalogueEasil = () => (dispatch, getState) => {
  dispatch(moveTo(PATHS.catalogueEasil));
};

export const moveToCart = () => (dispatch, getState) => {
  dispatch(moveTo(PATHS.cart));
};

/**
 * @desc - moves to the provided location url
 * @param {string} location - the url to move the user to
 */
export const moveTo = location => (dispatch, getState) => {
  dispatch(push(location));
};

/**
 * @desc - navigate to subtab after asset upload in the editor
 * performs a check on the incoming files
 * navigation priority is Video > Gif (Stickers) > Image
 * @param {array} files - the selected upload files array
 * @param {string} folderId - the destination folderId if selected
 * @param {string} subTab - the destination subTab (eg. My Videos, Brand Videos) if selected
 */
export const navigateToAssetPrioritySubtab = ({
  files,
  folderId,
  subTab
}) => dispatch => {
  const _files = Array.from(files);
  if (!_files || _files.length === 0) return;

  const hasVideo = _files.some(file => isVideo(file.name));
  const hasGif = _files.some(file => isGif(file.name));
  const tab = hasVideo || hasGif ? "animations" : "images";

  const dispatchSubtabNavigation = assetType => {
    const termsObject = {
      tab,
      assetType
    };

    if (folderId) {
      termsObject.folderId = folderId;
    }
    if (subTab) {
      termsObject.subtab = subTab;
    }

    return dispatch(
      modifyQuery({
        appendTerms: termsObject,
        clearCurrentQuery: true
      })
    );
  };

  if (hasVideo) {
    return dispatchSubtabNavigation(SUBTAB_TYPES.VIDEOS);
  } else if (!hasVideo && hasGif) {
    return dispatchSubtabNavigation(SUBTAB_TYPES.ANIMATIONS);
  } else {
    return dispatchSubtabNavigation(TABS.MY_IMAGES);
  }
};
