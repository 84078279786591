import { connect } from "react-redux";
import Billing from "views/components/Billing";
import { toggleModal } from "state/ui/subscription/subscriptionActions";
import { currentCardSelector } from "state/entities/cards/cardsSelectors";
import { teamBillingSelector } from "state/entities/teamBilling/teamBillingSelectors";
import { clearTeamSettingsBillingErrors as clearBillingErrors } from "state/ui/teamSettings/teamSettingsActions";
import {
  fetchTeamSettingsIfNeeded,
  updateTeamSettings
} from "state/entities/teamSettings/teamSettingsActions";

import {
  addCard,
  fetchCards,
  removeCard
} from "state/entities/cards/cardsActions";

import {
  fetchBilling,
  updateBilling
} from "state/entities/teamBilling/teamBillingActions";

import {
  currentSubscriptionSelector,
  currentSubscriptionPlan
} from "state/entities/subscriptions/subscriptionsSelectors";

import { teamSettingsSelector } from "state/entities/teamSettings/teamSettingsSelectors";

const mapStateToProps = state => {
  const teamId = (state.ui.currentTeam || {}).id;
  const teamSettings = teamSettingsSelector(state);

  return {
    creditCardModal: state.ui.subscription.modals.creditCard,
    currentCard: currentCardSelector(state),
    currentSubscription: currentSubscriptionSelector(state),
    currentSubscriptionPlan: currentSubscriptionPlan(state),
    currentTeam: state.entities.teams[teamId],
    teamBilling: teamBillingSelector({ state, teamId }),
    teamBillingSettings: state.ui.teamSettings.billing,
    teamSettings
  };
};

const mapDispatchToProps = dispatch => ({
  addCard: (...args) => dispatch(addCard(...args)),
  fetchBilling: (...args) => dispatch(fetchBilling(...args)),
  fetchCards: (...args) => dispatch(fetchCards(...args)),
  removeCard: (...args) => dispatch(removeCard(...args)),
  toggleModal: (...args) => dispatch(toggleModal(...args)),
  updateBilling: (...args) => dispatch(updateBilling(...args)),
  clearBillingErrors: (...args) => dispatch(clearBillingErrors(...args)),
  fetchTeamSettingsIfNeeded: (...args) =>
    dispatch(fetchTeamSettingsIfNeeded(...args)),
  updateTeamSettings: (...args) => dispatch(updateTeamSettings(...args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing);
