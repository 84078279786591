import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const TeamMenu = lazy(() => import("./TeamMenu"));

export const TeamMenuLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <TeamMenu {...props} />
  </Suspense>
);
TeamMenuLazy.displayName = "TeamMenu";

export default TeamMenuLazy;
