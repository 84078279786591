import * as types from "./shippingRatesTypes";
import { getRegionsForCountry, regions } from "lib/regionsList";

export const initState = {};

// takes an object of rates and replaces all default rates with rates for the regions within the default country
const replaceDefaultRates = ({ rates, newRates, regions }) => {
  // check for all region rates
  const defaultRates = Object.values(newRates).filter(
    rate => rate.region === "DEFAULT"
  );

  /* 
    if any of the new rates are already present in the list remove them
    otherwise add their name to the rate item
  */
  Object.keys(newRates).forEach(newRateKey => {
    Object.keys(rates).forEach(rateKey => {
      // remove rates that have new values coming in
      if (
        rates[rateKey].country === newRates[newRateKey].country &&
        rates[rateKey].region === newRates[newRateKey].region
      ) {
        return delete rates[rateKey];
      }
    });
    // apply names for the rates
    if (["ALL", "DEFAULT"].includes(newRates[newRateKey].region)) return;
    if (
      !regions[`${newRates[newRateKey].country}-${newRates[newRateKey].region}`]
    )
      return;
    newRates[newRateKey] = {
      ...newRates[newRateKey],
      ...{
        name:
          regions[
            `${newRates[newRateKey].country}-${newRates[newRateKey].region}`
          ].label
      }
    };
  });

  const newRatesList = Object.assign({}, rates, newRates);
  if (defaultRates) {
    // when we find default rates we want to duplicate it for the regions in the country
    defaultRates.forEach(defaultRate => {
      // get the regions for the country the default rate is for
      const regionsForCountry = getRegionsForCountry({
        countryCode: defaultRate.country
      });

      // loop through the regions and where there is not already a rate for the given region make one using the default rate
      regionsForCountry.forEach(region => {
        if (
          !Object.values(newRatesList).find(
            rate =>
              rate.region === region.key && rate.country === defaultRate.country
          )
        ) {
          newRatesList[`${defaultRate.id}-${region.key}`] = Object.assign(
            {},
            defaultRate,
            {
              region: region.key,
              name: region.label
            }
          );
        }
      });
    });
  }

  return newRatesList;
};

const shippingRatesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_SHIPPING_RATES_REQUEST: {
      return state;
    }

    case types.FETCH_SHIPPING_RATES_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;

      const {
        response: {
          entities: { shippingRate }
        }
      } = action;

      const rates = Object.assign({}, state);

      const updatedRates = replaceDefaultRates({
        rates,
        newRates: shippingRate,
        regions
      });

      return {
        ...updatedRates
      };
    }

    default:
      return state;
  }
};

export default shippingRatesReducer;
