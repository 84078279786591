// perform a string comparison on 2 strings without concern for case sensitivity
export const caseInsensitiveStringCompare = (string1, string2) =>
  (string1 || "").toUpperCase() === (string2 || "").toUpperCase();

// perform an string includes comparison on string 1 with argument string 2
export const caseInsensitiveStringIncludes = (string1, string2) =>
  (string1 || "").toUpperCase().includes((string2 || "").toUpperCase());

/**
 * @desc Retrieves font family names from a html string
 * @param {string} string - the string to collect font family names from
 * @returns {string[]} - found font names
 */
export const getFontFamiliesFromRichText = string => {
  const _string = string.replace(/&quot;/g, "");
  const regex = /(font-family: ).+?(?=;)/g;
  const matches = (_string.match(regex) || []).map(match =>
    match.replace(/(font-family: )/g, "")
  );
  return matches || [];
};

export const getFontFamilyMatchesInRichText = (string, fontFamily) => {
  const _string = string.replace(/&quot;/g, "");
  const regex = /(font-family: ).+?(?=;)/g;
  const matches = (_string.match(regex) || []).map(match =>
    match.replace(/(font-family: )/g, "")
  );
  return (matches || []).filter(match => match === fontFamily);
};
