import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";

import { fixPagesIds } from "state/api/designs/helpers";
import { flatten } from "lib";

export const initState = {
  pages: {},
  totalCount: null,
  pageSize: 50
};

const archivedApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.TEAM_ARCHIVED_DESIGNS_REQUEST: {
      return handleDesignRequest({ state, action });
    }

    case designsEntitiesTypes.TEAM_ARCHIVED_DESIGNS_REQUEST_SUCCESS: {
      return handleDesignRequestSuccess({ state, action });
    }

    case designsEntitiesTypes.TEAM_TEMPLATES_STATUS_UPDATE_REQUEST: {
      const { body: archivedChanged } = action.request;
      const allArchivedIds = flatten(
        Object.keys(state.pages)
          .sort()
          .map(i => state.pages[i].ids)
      );

      const archivedChangedIds = archivedChanged.map(archived => archived.id);

      const allarchivedIdsWithoutUpdateds = allArchivedIds.filter(
        archivedId => !archivedChangedIds.includes(archivedId)
      );

      return {
        ...state,
        pages: fixPagesIds({ ids: allarchivedIdsWithoutUpdateds, state })
      };
    }

    default:
      return state;
  }
};

export default archivedApiReducers;
