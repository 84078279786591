import React from "react";
import style from "./style.module.css";
import Form from "views/components/Form";
import TrashCanIcon from "views/components/icons/TrashCanIcon";
import { getPath, isEmpty } from "lib";
import {
  getSizeOptions,
  getStockOptions,
  getFinishingOptions,
  getClosestMatchingPrintPricingOption
} from "views/containers/cartContainer/CartPreviewSection/printItemUtils";

const printItemFields = {
  sizeDescription: {
    label: "Size",
    key: "sizeDescription",
    type: "dropdown",
    default: "",
    width: "22%"
  },
  stock: {
    label: "Material",
    key: "stock",
    type: "dropdown",
    default: "",
    width: "70%"
  },
  quantity: {
    label: "Quantity",
    key: "quantity",
    type: "number",
    default: 1,
    value: 1,
    width: "22%"
  },
  finishing: {
    label: "Finish",
    key: "finishing",
    type: "dropdown",
    default: "Not Available",
    width: "70%"
  }
};

const mapPrintRows = fields => [
  [fields.sizeDescription, fields.stock],
  [fields.quantity, fields.finishing]
];

export class PrintItem extends React.Component {
  constructor(props) {
    super(props);

    this.getSizeOptions = this.getSizeOptions.bind(this);
    this.getStockOptions = this.getStockOptions.bind(this);
    this.getFinishingOptions = this.getFinishingOptions.bind(this);
    this.getFields = this.getFields.bind(this);
    this.getPrintRows = this.getPrintRows.bind(this);
    this.handleUpdatePrintItem = this.handleUpdatePrintItem.bind(this);
  }

  componentDidUpdate(prevProps) {
    const currentFinishingOptions = this.getFinishingOptions();

    if (
      prevProps.selectedPrintOption.stock !==
        this.props.selectedPrintOption.stock &&
      currentFinishingOptions.length > 0
    ) {
      this.props.selectActiveLabel("Finish");
    }
    if (
      this.props.activeLabel === "Finish" &&
      currentFinishingOptions.length === 0
    ) {
      this.props.selectActiveLabel(null);
    }
  }

  getSizeOptions() {
    const { printPricing } = this.props;

    // get a unique list of size options available for this design
    return getSizeOptions({ printPricing });
  }

  getStockOptions() {
    const { printPricing, selectedPrintOption } = this.props;

    return getStockOptions({
      printPricing,
      sizeDescription: selectedPrintOption.sizeDescription
    });
  }

  getFinishingOptions({
    printPricing = this.props.printPricing,
    selectedPrintOption = this.props.selectedPrintOption
  } = {}) {
    return getFinishingOptions({
      printPricing,
      sizeDescription: selectedPrintOption.sizeDescription,
      stock: selectedPrintOption.stock
    });
  }

  getFields() {
    const { selectedPrintOption, printItem } = this.props;
    const options = {
      sizeDescription: this.getSizeOptions(),
      stock: this.getStockOptions(),
      finishing: this.getFinishingOptions()
    };

    return Object.keys(printItemFields).reduce((combinedFields, key) => {
      const field = {
        ...printItemFields[key],
        value:
          selectedPrintOption[key] || getPath(options, [key, 0, "key"]) || "",
        options: options[key],
        onBlur: this.handleUpdatePrintItem
      };

      if (key === "quantity") field.value = printItem.quantity;

      return {
        ...combinedFields,
        [key]: field
      };
    }, {});
  }

  /**
   * @desc build an array of print option field rows for setting the current printItem's properties
   * @returns [[],[]] - Array of arrays describing the row structure of the printItem input form
   */
  getPrintRows() {
    const fields = this.getFields();
    return mapPrintRows(fields);
  }

  handleUpdatePrintItem(update) {
    const { printItem, printPricing, handleUpdatePrintItem } = this.props;
    const fields = this.getFields();

    const updatedFields = {
      ...fields,
      [update.key]: { ...fields[update.key], value: update.value }
    };

    // ensure that the quantity value is always a number when being sent to the action
    if (typeof updatedFields.quantity.value === "string")
      updatedFields.quantity.value = Number(updatedFields.quantity.value);

    const printPricingOption = getClosestMatchingPrintPricingOption({
      printPricing,
      fields: updatedFields
    });

    // no update if the optionIds and quantity are the same
    if (
      printPricingOption.id === printItem.printProviderPrintOptionPricingId &&
      updatedFields.quantity.value === printItem.quantity
    ) {
      return;
    }

    handleUpdatePrintItem({
      printItem: {
        ...printItem,
        price: printItem.price || printItem.itemPrice,
        printProviderPrintOptionPricingId: printPricingOption.id,
        quantity: updatedFields.quantity.value
      },
      printItemId: printItem.id
    });
  }

  render() {
    if (
      !this.props.selectedPrintOption ||
      isEmpty(this.props.selectedPrintOption)
    ) {
      return null;
    }

    const rows = this.getPrintRows();

    const activeLabel = this.props.isActive && this.props.activeLabel;

    return (
      <div
        className={style.printItemWrapper}
        onClick={this.props.onClick}
        data-is-active={this.props.isActive}
      >
        <Form
          rows={rows}
          rowSpacingX="12px"
          rowSpacingY="12px"
          activeLabel={activeLabel}
          selectActiveLabel={this.props.selectActiveLabel}
        />
        <div className={style.trashCan}>
          <TrashCanIcon
            size="20px"
            color="#57595da3"
            onClick={this.props.handleDeletePrintItem}
          />
        </div>
      </div>
    );
  }
}

export default PrintItem;
