import React from "react";
import CaretSlimIcon from "views/components/icons/CaretSlimIcon";
import InvoiceMonthList from "./InvoiceMonthList";
import { isEmpty } from "lib";
import style from "./style.module.css";

const mapIsSelectedToInvoiceMonths = ({ invoiceMonths, selectedMonth }) => {
  const mappedMonths = Object.values(invoiceMonths).map(invoiceMonth => {
    const isSelected = invoiceMonth.label === selectedMonth;
    return {
      ...invoiceMonth,
      isSelected
    };
  });

  return Object.values(mappedMonths).sort(
    (monthA, monthB) => monthB.key - monthA.key
  );
};

export class InvoiceMonths extends React.Component {
  componentDidUpdate(prevProps) {
    const isMonthsFirstLoad =
      isEmpty(prevProps.invoiceMonths) && !isEmpty(this.props.invoiceMonths);
    const isSelectedYearChanged =
      prevProps.selectedYear !== this.props.selectedYear;
    if (isMonthsFirstLoad || isSelectedYearChanged) {
      const months = mapIsSelectedToInvoiceMonths({
        invoiceMonths: this.props.invoiceMonths,
        selectedMonth: this.props.selectedMonth
      });
      this.props.setSelectedMonth(months[0].label);
    }
  }

  render() {
    const {
      invoiceMonths,
      selectedMonth,
      selectedYear,
      setSelectedMonth
    } = this.props;

    if (!invoiceMonths) return null;
    const months = mapIsSelectedToInvoiceMonths({
      invoiceMonths,
      selectedMonth
    });

    return (
      <div className={style.invoiceMonths}>
        {months.map(invoiceMonth => {
          return (
            <div
              className={style.invoiceMonth}
              key={`${invoiceMonth.label}-${selectedYear}`}
            >
              <div
                className={style.invoiceMonthLabel}
                onClick={() => setSelectedMonth(invoiceMonth.label)}
              >
                {/* invoice month button */}
                {invoiceMonth.label}
                <CaretSlimIcon
                  direction={invoiceMonth.isSelected ? "up" : "down"}
                  size="12"
                />
              </div>
              <InvoiceMonthList invoiceMonth={invoiceMonth} />
            </div>
          );
        })}
      </div>
    );
  }
}

InvoiceMonths.displayName = "InvoiceMonths";

export default InvoiceMonths;
