import React from "react";
import PropTypes from "prop-types";

const DuplicatePageIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    xmlns="http://www.w3.org/2000/svg"
    data-testid="DuplicatePageIcon"
  >
    <g fill="none" fillRule="evenodd">
      <rect width={width} height={height} rx="4" />
      <path d="M3 3h14v14H3z" />
      <g
        transform="translate(4 4)"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <rect y="3.5" width="8.5" height="8.5" rx="2" />
        <path d="M4 0h4a4 4 0 0 1 4 4v4" />
      </g>
    </g>
  </svg>
);

DuplicatePageIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default DuplicatePageIcon;
