import React from "react";
import { easilGrayXDark } from "./style.module.css";
import PropTypes from "prop-types";

const FolderSquareIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M8.41 3a3 3 0 012.094.852l.135.141L11.545 5H19.2c1.49 0 2.71 1.352 2.795 2.835L22 8v1.947l-.002.046a2.989 2.989 0 01.752 2.335l-.66 6A3 3 0 0119.108 21H4.892a3 3 0 01-2.982-2.672l-.66-6c-.097-.883.2-1.72.75-2.332V5.778a2.788 2.788 0 012.63-2.773L4.8 3h3.61zm11.358 8l-15.59.002-.055.004a1 1 0 00-.885 1.103l.66 6a1 1 0 00.994.891h14.216a1 1 0 00.994-.89l.664-6.055.002-.055a1 1 0 00-1-1zM8.409 5H4.8a.793.793 0 00-.793.673L4 5.778v3.231A3 3 0 014.232 9h15.536c.078 0 .156.003.233.009L20 8c0-.466-.371-.929-.72-.993L19.2 7h-7.655a2 2 0 01-1.354-.528l-.132-.134-.906-1.007a1 1 0 00-.623-.324L8.41 5z"
        fill={color || easilGrayXDark}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
FolderSquareIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FolderSquareIcon;
