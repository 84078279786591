import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import { boolXor } from "lib";
import { getHtmlNodeCenter } from "lib/htmlElements/dimensions";
import { rotatePoint } from "lib/geometry/rotation";
import style from "views/components/Editor/styleSelectionBox.module.css";
import { getPageHtmlNode } from "views/components/Editor/utils";
/**
 * Implements the drag source contract.
 */
const GridCellCropResizeHandlerSource = {
  beginDrag(props, monitor, component) {
    const {
      startCropResizing,
      selectedItems,
      gridCell,
      position,
      gridElement
    } = props;

    startCropResizing({ pageId: selectedItems[0].pageId });

    const componentNode = findDOMNode(component);
    const selectionBoxNode = componentNode.parentElement;

    const componentNodeCenter = getHtmlNodeCenter(componentNode);
    const selectionBoxNodeCenter = getHtmlNodeCenter(selectionBoxNode);

    const pageHtmlElement = getPageHtmlNode(selectedItems[0].pageId);

    const scaleAnchorPoint = rotatePoint(
      componentNodeCenter.x,
      componentNodeCenter.y,
      selectionBoxNodeCenter.x,
      selectionBoxNodeCenter.y,
      180
    );

    // here we only want to flip if there is a single scaleX applied
    // when both are applied we are back at no flip
    const isFlipX = boolXor(gridCell.scaleX === -1, gridElement.scaleX === -1);
    const isFlipY = boolXor(gridCell.scaleY === -1, gridElement.scaleY === -1);

    const anchorPoint = { ...scaleAnchorPoint };

    if (isFlipX) anchorPoint.x = componentNodeCenter.x;
    if (isFlipY) anchorPoint.y = componentNodeCenter.y;

    return {
      handlerInitialPosition: componentNodeCenter,
      anchorPoint,
      pageHtmlElement,
      scaleAnchorPoint,
      selectionBoxNodeCenter,
      position
    };
  },

  endDrag(props, monitor, component) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();
    const {
      handlerInitialPosition,
      anchorPoint,
      pageHtmlElement,
      scaleAnchorPoint,
      position,
      selectionBoxNodeCenter
    } = monitor.getItem();

    props.finishCropResizing({
      handlerInitialPosition,
      anchorPoint,
      differenceFromInitialOffset,
      pageHtmlElement,
      scaleAnchorPoint,
      position,
      selectionBoxNodeCenter
    });
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset()
  };
}

class GridCellCropResizeHandler extends Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;

    if (connectDragPreview) {
      // Use empty image as a drag preview so browsers don't draw it
      // and we can draw whatever we want on the custom drag layer instead.
      connectDragPreview(getEmptyImage(), {
        // IE fallback: specify that we'd rather screenshot the node
        // when it already knows it's being dragged so we can hide it with CSS.
        captureDraggingState: true
      });
    }
  }

  render() {
    const { connectDragSource, position } = this.props;

    return connectDragSource(
      <div className={style.dotWrapper} data-position={position}>
        <div className={style.dot} />
      </div>
    );
  }
}

const GridCellCropResizeHandlerDraggable = DragSource(
  "GRID_CELL_CROP_RESIZE_HANDLER",
  GridCellCropResizeHandlerSource,
  collect
)(GridCellCropResizeHandler);

const GridCellCropResizeHandlerDefault = props => {
  return (
    <GridCellCropResizeHandlerDraggable
      {...props}
      selectedGridCellId={props.selectedGridCellId}
      startCropResizing={props.startCropResizing}
      finishCropResizing={props.finishCropResizing}
      selectedItems={props.selectedItems}
      getElement={props.getElement}
      zoom={props.zoom}
    />
  );
};

export default GridCellCropResizeHandlerDefault;
