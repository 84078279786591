import * as types from "state/currentUser/currentUserTypes";

export const initState = {
  isProcessing: false,
  error: null
};

const updatePasswordReducers = (state = initState, action) => {
  switch (action.type) {
    case types.CHANGE_CURRENT_USER_PASSWORD_REQUEST: {
      return Object.assign({}, state, { isProcessing: true, error: null });
    }

    case types.CHANGE_CURRENT_USER_PASSWORD_REQUEST_SUCCESS: {
      return Object.assign({}, state, { isProcessing: false, error: null });
    }

    case types.CHANGE_CURRENT_USER_PASSWORD_REQUEST_FAILURE: {
      return Object.assign({}, state, {
        isProcessing: false,
        error: action.error.errors.pop()
      });
    }

    default:
      return state;
  }
};

export default updatePasswordReducers;
