import React from "react";

export default function EmptyImagesIcon(props) {
  return (
    <svg viewBox="0 0 56 44" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <rect
            width="50"
            height="38"
            x="6"
            y="6"
            fill="#DCDCE0"
            opacity=".85"
            rx="2"
          />
          <path
            fill="#2C2E2F"
            fillRule="nonzero"
            d="M52 4.103v31.794C52 38.163 50.21 40 48 40H4c-2.21 0-4-1.837-4-4.103V4.103C0 1.837 1.79 0 4 0h44c2.21 0 4 1.837 4 4.103zm-2 0c0-1.133-.895-2.053-2-2.053H4c-1.105 0-2 .919-2 2.052v31.794c0 1.133.895 2.052 2 2.052h44c1.105 0 2-.919 2-2.052V4.103z"
          />
        </g>
        <path d="M14 8h24v24H14z" />
        <path
          fill="#2C2E2F"
          fillRule="nonzero"
          stroke="#DFDFE2"
          strokeWidth=".6"
          d="M30.134 16.509a1.368 1.368 0 012.175.04h0l3.42 4.642a1.386 1.386 0 01-.284 1.932c-.61.455-1.47.326-1.921-.287h0l-2.36-3.201-4.994 6.274a1.367 1.367 0 01-2.026.13h0l-2.092-2.032-3.612 4.482a1.37 1.37 0 01-1.932.203 1.387 1.387 0 01-.201-1.943h0l4.555-5.653a1.367 1.367 0 012.02-.123h0l2.086 2.026zM20.133 12c1.264 0 2.288 1.03 2.288 2.302s-1.024 2.302-2.288 2.302a2.295 2.295 0 01-2.289-2.302A2.296 2.296 0 0120.133 12z"
        />
      </g>
    </svg>
  );
}
