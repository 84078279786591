import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ChainLinksIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 16}
    height={height || size || 16}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <path d="M0 0h16v16H0z" />
      <path
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.6 8.7a3.5 3.5 0 005.278.378l2.1-2.1a3.5 3.5 0 00-4.949-4.949L7.825 3.226"
      />
      <path
        stroke={color || easilGrayXDark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.4 7.3a3.5 3.5 0 00-5.278-.378l-2.1 2.1a3.5 3.5 0 004.949 4.949l1.197-1.197"
      />
    </g>
  </svg>
);
ChainLinksIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ChainLinksIcon;
