import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import UserMenu from "./UserMenu";
import {
  switchToken,
  switchToPersonalTeam,
  logout
} from "state/currentUser/currentUserActions";
import { logoutLocationSelector } from "state/currentUser/currentUserSelectors";
import { teamSelector } from "state/entities/entitiesSelectors";
import { onPersonalTeam } from "state/currentUser/currentUserSelectors";

class UserMenuContainer extends Component {
  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);

    this.state = {
      displayDropdown: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.logoutLocation && this.props.logoutLocation) {
      window.location = this.props.logoutLocation;
    }
  }

  toggleMenu() {
    const { displayDropdown } = this.state;

    this.setState({
      displayDropdown: !displayDropdown
    });
  }

  render() {
    const {
      avatarClassName,
      currentTeam,
      hasCaret,
      logout,
      user,
      onPersonalTeam,
      onSwitchToPersonalTeam
    } = this.props;

    const { displayDropdown } = this.state;

    return (
      <UserMenu
        toggleMenu={this.toggleMenu}
        onSwitchToPersonalTeam={onSwitchToPersonalTeam}
        displayDropdown={displayDropdown}
        onPersonalTeam={onPersonalTeam}
        user={user}
        logout={logout}
        currentTeam={currentTeam}
        hasCaret={hasCaret}
        avatarClassName={avatarClassName}
      />
    );
  }
}

UserMenuContainer.propTypes = {
  user: PropTypes.object.isRequired,
  currentTeam: PropTypes.object.isRequired,
  logout: PropTypes.func,
  onSwitchTeam: PropTypes.func,
  onPersonalTeam: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const currentTeam = teamSelector({ state });
  const logoutLocation = logoutLocationSelector(state);

  return {
    currentTeam,
    logoutLocation,
    onPersonalTeam: onPersonalTeam(state),
    user: state.currentUser
  };
};
const mapDispatchToProps = dispatch => ({
  logout: args => dispatch(logout(args)),
  onSwitchTeam: args => dispatch(switchToken(args)),
  onSwitchToPersonalTeam: args => dispatch(switchToPersonalTeam(args))
});
const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(UserMenuContainer);
