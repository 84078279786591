import * as types from "./shippingDetailsTypes";

export const initState = {};

const shippingDetailsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_SHIPPING_DETAILS_FOR_ORDER_REQUEST_SUCCESS:
    case types.FETCH_SHIPPING_DETAILS_FOR_CURRENT_ORDER_REQUEST_SUCCESS: {
      const { entities } = action.response;

      if (!entities) return state;

      return {
        ...state,
        ...entities.shippingDetails
      };
    }

    case types.UPDATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_SUCCESS: {
      const orderId = action.request.body.orderId;
      const {
        entities: {
          shippingDetails: { [orderId]: shippingDetails }
        }
      } = action.response;

      return {
        ...state,
        [orderId]: shippingDetails
      };
    }

    case types.CREATE_SHIPPING_DETAILS_FOR_ORDER_REQUEST_SUCCESS: {
      const orderId = action.request.body.orderId;
      const {
        entities: {
          shippingDetails: { [orderId]: shippingDetails }
        }
      } = action.response;

      return {
        ...state,
        [orderId]: shippingDetails
      };
    }

    default:
      return state;
  }
};

export default shippingDetailsReducer;
