import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "views/components";
import style from "./style.module.css";
import InfoAlert from "views/components/InfoAlert";

class RequestApprovalModal extends Component {
  static defaultState = {
    comment: ""
  };
  constructor(props) {
    super(props);

    this.state = RequestApprovalModal.defaultState;

    this.returnToDefaultState = this.returnToDefaultState.bind(this);
    this.handleApprovalRequestClick = this.handleApprovalRequestClick.bind(
      this
    );
  }

  returnToDefaultState() {
    this.setState(RequestApprovalModal.defaultState);
  }

  handleApprovalRequestClick() {
    this.props.onRequestDesignApproval({
      designId: this.props.designId,
      comment: this.state.comment
    });
    this.props.onCloseModal();
    this.returnToDefaultState();
  }

  render() {
    const buttonsList = [
      {
        name: "Cancel",
        theme: "gray",
        onClick: this.props.onCloseModal
      },
      {
        name: "Request",
        theme: "blueSolid",
        onClick: this.handleApprovalRequestClick
      }
    ];
    return (
      <Modal
        className={style.wrapper}
        isOpen={this.props.isOpen}
        contentLabel="Request Approval"
        onRequestClose={this.props.onCloseModal}
        header={"Request Approval"}
        buttons={buttonsList}
      >
        <div>
          <div className={style.description}>
            This design requires approval from a Brand Manager in your team
            before it can be downloaded or purchased.
          </div>
          <InfoAlert className={style.alert}>
            All resizes of this design will require separate approval.
          </InfoAlert>
          <div className={style.textareaLabel}>Write a comment (optional)</div>
          <textarea
            className={style.textarea}
            placeholder="E.g. I'm requesting approval for this design because…"
            value={this.state.comment}
            onChange={e => this.setState({ comment: e.target.value })}
          />
        </div>
      </Modal>
    );
  }
}

RequestApprovalModal.displayName = "RequestApprovalModal";

RequestApprovalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onRequestDesignApproval: PropTypes.func.isRequired
};

export default RequestApprovalModal;
