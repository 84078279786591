import React, { Component } from "react";
import { Checkbox, Button, Scrollable } from "views/components";
import CaretIcon from "views/components/icons/CaretIcon";
import style from "./style.module.css";
import { isNil, composeClassName, noop } from "lib";

class DropdownWithCheckBoxAndCustomRender extends Component {
  constructor(props) {
    super(props);

    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.popoutRef = React.createRef();
    this.buttonRef = React.createRef();
    this.state = {
      open: false,
      options: this.props.options || []
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { onToggle = noop } = this.props;
    if (this.state.open && !prevState.open) {
      onToggle({
        open: this.state.open,
        popoutHeight:
          this.popoutRef && this.popoutRef.current
            ? this.popoutRef.current.offsetHeight
            : 0,
        buttonBottom:
          window.innerHeight -
          (this.buttonRef && this.buttonRef.current
            ? this.buttonRef.current.getBoundingClientRect().bottom
            : 0)
      });
    }
    if (!this.state.open && prevState.open) {
      onToggle({ open: this.state.open, popoutHeight: 0, buttonBottom: 0 });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.options });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.isConnected
    ) {
      this.setState({ open: false, options: this.props.options });
    }
  }

  onDropdownClick() {
    this.setState({ open: !this.state.open });
  }

  onSubmit() {
    this.props.onSubmit(this.state.options);
    this.setState({ open: false });
  }

  handleChange(option, optionIndex, checked) {
    const { handleChange = options => options } = this.props;

    const { options } = this.state;

    const optionChanged = { ...options[optionIndex], selected: checked };

    let newOptions = options.map((item, index) => {
      return index !== optionIndex ? item : optionChanged;
    });

    newOptions = handleChange(newOptions, optionIndex);

    this.setState({
      options: newOptions
    });
  }

  render() {
    const {
      placeholder,
      error,
      showErrorMsg,
      label,
      stickyButton,
      contentStyle,
      dropdownStyle,
      flipCheckBoxes,
      caretColor,
      currentValue,
      inputTitle,
      inputClassName,
      maxHeight,
      inputDivStyle
    } = this.props;
    const { options, open } = this.state;

    const customContentStyle = {
      maxHeight: stickyButton ? "84%" : "100%"
    };

    const customButtonStyle =
      maxHeight !== undefined
        ? {
            maxHeight,
            overflow: maxHeight > 0 ? "unset" : "hidden"
          }
        : {};

    const inputValue =
      currentValue ||
      options
        .filter(option => option.selected)
        .map(option =>
          typeof option.label === "string" ? option.label : option.value
        )
        .join(", ");

    return (
      <div
        className={inputDivStyle}
        style={customButtonStyle}
        data-testid="DropdownWithCheckBoxAndCustomRender"
      >
        {label && <div className={style.label}>{label}</div>}
        <div
          ref={this.setWrapperRef}
          className={composeClassName("dropdown", style, dropdownStyle)}
        >
          <div
            aria-label="dropdown"
            onClick={this.onDropdownClick}
            className={composeClassName("inputDiv", style, inputDivStyle)}
            data-error={!isNil(error)}
            ref={this.buttonRef}
            style={customButtonStyle}
            data-testid="dropdownButton"
          >
            {inputTitle && <div className={style.inputTitle}>{inputTitle}</div>}
            <input
              className={`${inputClassName}`}
              placeholder={placeholder}
              value={inputValue}
              disabled
            />
            <CaretIcon
              size="8px"
              color={caretColor || style.easilGrayXDark}
              direction="down"
            />
          </div>
          {showErrorMsg && error && (
            <span aria-label="dropdownError" className={style.error}>
              {error}
            </span>
          )}
          {open && (
            <div
              className={composeClassName(
                "contentWrapper",
                style,
                contentStyle
              )}
              ref={this.popoutRef}
              data-testid="dropdownContent"
            >
              <Scrollable
                scrollYClassName={style.scrollbar}
                scrollContentClassName={style.scrollContentClassName}
                className={style.dropdownScrollable}
              >
                <div className={style.content} style={customContentStyle}>
                  {options.map((option, index) => (
                    <Checkbox
                      key={option.key}
                      checked={option.selected}
                      label={option.label}
                      onClick={e =>
                        this.handleChange(option, index, e.target.checked)
                      }
                      isBoxRight={flipCheckBoxes}
                    />
                  ))}
                  {!stickyButton && (
                    <div className={style.doneButtonContainer}>
                      <Button
                        onClick={this.onSubmit}
                        className={style.doneButton}
                        theme={"grayBackgroundXDarkGrayFont"}
                        isExtraWide
                      >
                        Done
                      </Button>
                    </div>
                  )}
                </div>
              </Scrollable>
              {stickyButton && (
                <div className={style.doneButtonContainer}>
                  <Button
                    onClick={this.onSubmit}
                    className={style.doneButton}
                    theme="grayBackgroundXDarkGrayFont"
                    isExtraWide
                  >
                    Done
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

DropdownWithCheckBoxAndCustomRender.displayName =
  "DropdownWithCheckBoxAndCustomRender";

export default DropdownWithCheckBoxAndCustomRender;
