import * as types from "./personalTeamTypes";

export const initState = {};

const personalTeam = (state = initState, action) => {
  switch (action.type) {
    case types.PERSONAL_TEAM_REQUEST: {
      // when starting to fetch set the lastFetched date early to ensure repeat requests are not made
      return {
        ...state,
        lastFetched: Date.now()
      };
    }

    case types.PERSONAL_TEAM_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return state;
      }
      const {
        response: {
          ids: teamId,
          entities: { teams }
        }
      } = action;
      const team = teams[teamId];

      return { ...team, lastFetched: Date.now() };
    }

    default:
      return state;
  }
};

export default personalTeam;
