import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const OpacityIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <g transform="translate(0 1)">
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M8 1a6 6 0 1 0 0 12A6 6 0 0 0 8 1zm0-2a8 8 0 1 1 0 16A8 8 0 0 1 8-1z"
        />
        <rect
          width="2"
          height="2"
          x="1"
          y="4"
          fill={color || easilGrayXDark}
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="1"
          y="8"
          fill={color || easilGrayXDark}
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="3"
          y="2"
          fill={color || easilGrayXDark}
          opacity=".8"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="3"
          y="6"
          fill={color || easilGrayXDark}
          opacity=".8"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="3"
          y="10"
          fill={color || easilGrayXDark}
          opacity=".8"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="7"
          y="2"
          fill={color || easilGrayXDark}
          opacity=".4"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="7"
          y="6"
          fill={color || easilGrayXDark}
          opacity=".4"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="7"
          y="10"
          fill={color || easilGrayXDark}
          opacity=".4"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="11"
          y="2"
          fill={color || easilGrayXDark}
          fillOpacity=".2"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="11"
          y="6"
          fill={color || easilGrayXDark}
          fillOpacity=".2"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="11"
          y="10"
          fill={color || easilGrayXDark}
          fillOpacity=".2"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="5"
          fill={color || easilGrayXDark}
          opacity=".6"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="5"
          y="4"
          fill={color || easilGrayXDark}
          opacity=".6"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="5"
          y="8"
          fill={color || easilGrayXDark}
          opacity=".6"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="5"
          y="12"
          fill={color || easilGrayXDark}
          opacity=".6"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="9"
          fill={color || easilGrayXDark}
          fillOpacity=".3"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="9"
          y="4"
          fill={color || easilGrayXDark}
          fillOpacity=".3"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="9"
          y="8"
          fill={color || easilGrayXDark}
          fillOpacity=".3"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="9"
          y="12"
          fill={color || easilGrayXDark}
          fillOpacity=".3"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="13"
          y="4"
          fill={color || easilGrayXDark}
          fillOpacity=".1"
          rx="1"
        />
        <rect
          width="2"
          height="2"
          x="13"
          y="8"
          fill={color || easilGrayXDark}
          fillOpacity=".1"
          rx="1"
        />
      </g>
    </g>
  </svg>
);
OpacityIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default OpacityIcon;
