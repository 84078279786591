import React from "react";
import CollectionPreviewModalTemplate from "views/components/CollectionPreviewModal/CollectionPreviewModalTemplate";
import style from "./style.module.css";

export class PurchaseCollectionModal extends CollectionPreviewModalTemplate {
  // returns an array of buttons for the modal
  getButtons() {
    const {
      collectionPricing,
      currentTeamRegion,
      currentTeam,
      isAdmin
    } = this.props;
    const isOrganisation =
      currentTeam &&
      currentTeam.parentPathArr &&
      currentTeam.parentPathArr.length <= 1;
    let price, currency;

    if (collectionPricing) {
      if (currentTeamRegion === "AU") {
        price = collectionPricing.priceAud;
        currency = "AUD";
      } else {
        price = collectionPricing.priceUsd;
        currency = "USD";
      }
    }

    return [
      {
        label: "Upgrade Now",
        action: () => this.props.onUpgrade("EDGE"),
        visible: true,
        id: "purchase1",
        iconBoxSize: "0",
        // You’re not in the parent team, and if you dont have permission to upgrade your team
        isDisabled: !(isOrganisation && isAdmin),
        titleClassName: style.titleClassName
      },
      {
        label: (
          <div className={style.buyCollectionButton}>
            Buy Collection <span>- {currency}</span>${price}
          </div>
        ),
        action: () => this.props.handleOpenBuyCollectionModal(),
        visible: true,
        id: "purchase2",
        iconBoxSize: "0",
        titleClassName: style.titleClassName
      }
    ];
  }

  ButtonDivider() {
    return (
      <div className={style.divider}>
        <div className={style.line} />
        <div className={style.text}>OR</div>
        <div className={style.line} />
      </div>
    );
  }

  ButtonBefore() {
    return (
      <div className={style.freeEdgeNotice}>
        FREE on the{" "}
        <span
          onClick={() => this.props.onUpgrade("EDGE")}
          className={style.easilEdgeLink}
        >
          Easil Edge
        </span>{" "}
        plan
      </div>
    );
  }

  ButtonAfter() {
    return (
      <div className={style.disclaimer}>
        By purchasing this collection, you agree to our{" "}
        <a
          href="https://about.easil.com/terms-of-use/"
          target="_blank"
          rel="noopener noreferrer"
          className={style.disclaimerLink}
        >
          User Licence and Terms.
        </a>
      </div>
    );
  }
}

PurchaseCollectionModal.displayName = "PurchaseCollectionModal";

export default PurchaseCollectionModal;
