import { BROWSER_NAMES } from "lib/constants";

export const getBrowserClientName = () => {
  if (
    (navigator.userAgent.indexOf(BROWSER_NAMES.OPERA) ||
      navigator.userAgent.indexOf(BROWSER_NAMES.OPERA2)) !== -1
  ) {
    return BROWSER_NAMES.OPERA;
  } else if (navigator.userAgent.indexOf(BROWSER_NAMES.CHROME) !== -1) {
    return BROWSER_NAMES.CHROME;
  } else if (navigator.userAgent.indexOf(BROWSER_NAMES.SAFARI) !== -1) {
    return BROWSER_NAMES.SAFARI;
  } else if (navigator.userAgent.indexOf(BROWSER_NAMES.FIREFOX) !== -1) {
    return BROWSER_NAMES.FIREFOX;
  } else if (
    navigator.userAgent.indexOf(BROWSER_NAMES.IE) !== -1 ||
    !!document.documentMode === true
  ) {
    //IF IE > 10
    return BROWSER_NAMES.IE_LABEL;
  } else {
    return "unknown";
  }
};
