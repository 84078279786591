import Subscriptions from "lib/subscriptions";
import { fileDownloadFormats } from "lib/constants";
import { cloneDeep } from "lib";

export const applyDisabledValues = (
  fileTypes,
  downloadOptions,
  subscription,
  exportToMyImages,
  hidePDFDownload
) => {
  if (typeof fileTypes !== "object") return [];

  const updatedFileTypes = cloneDeep(fileTypes);
  if (hidePDFDownload) delete updatedFileTypes.PDF;

  return Object.values(updatedFileTypes).map(option => {
    const isDownloadableOnPlan = subscription.canDownloadType(option.value);
    const isDisabled =
      !downloadOptions.includes(option.value) ||
      !isDownloadableOnPlan ||
      (exportToMyImages && option.value === "PDF");
    return Object.assign({}, option, { isDownloadableOnPlan, isDisabled });
  });
};

export const allowedFormats = ({
  design,
  designTemplate,
  numberOfPages,
  isDesignAnimated
}) => {
  const allowedFormats = ["JPG", "PNG"];
  const isImageType = designTemplate && designTemplate.templateType === "image";
  const isEBookCover =
    isDesignAnimated && isDesignAnimated.templateCode === "ebooks_covers";
  const isCustomPxDesign =
    design &&
    design.templateCode === "custom" &&
    design.measurementUnits === "px";
  const isLessThanMaxPixels =
    design && design.height * design.width <= 1920 * 1080;

  if (!isImageType || isEBookCover) {
    allowedFormats.push("PDF");
  }

  if (isImageType || (isCustomPxDesign && isLessThanMaxPixels)) {
    if (numberOfPages > 1) {
      allowedFormats.push("GIF");
    }
    if ((!isDesignAnimated && numberOfPages > 1) || isDesignAnimated) {
      allowedFormats.push("MP4");
    }
  }

  return allowedFormats;
};

export const GIF_SPEEDS = [
  { key: "slow", label: "Slow" },
  { key: "medium", label: "Medium" },
  { key: "fast", label: "Fast" }
];

export const PDF_QUALITIES = [
  { key: "print", label: "Print" },
  { key: "web", label: "Web" }
];

export const formatDropdownProperties = {
  PDF: {
    title: "Select quality",
    options: PDF_QUALITIES
  },
  GIF: {
    title: "Select speed",
    options: GIF_SPEEDS
  },
  MP4: {
    title: "Select speed",
    options: GIF_SPEEDS
  }
};

export const isDesignTemplatePrintable = designTemplate =>
  designTemplate &&
  (designTemplate.templateType !== "image" ||
    designTemplate.templateCode === "ebooks_covers");

export const getDefaultDownloadFormat = ({
  planCode,
  downloadOptions,
  design,
  designTemplate
}) => {
  // BASIC PLAN
  if (planCode === Subscriptions.BASIC.code) {
    // BASIC should only have JPG option, if that isn't available return null
    if (downloadOptions.includes(fileDownloadFormats.JPG)) {
      return fileDownloadFormats.JPG;
    }
    return null;
  }

  // PLUS AND EDGE PLAN
  if (isDesignTemplatePrintable(designTemplate)) {
    // printable designs should default to PDF
    if (downloadOptions.includes(fileDownloadFormats.PDF)) {
      return fileDownloadFormats.PDF;
    }
    return null;
  }

  // not a printable design
  if (design.isAnimated) {
    // animated designs should default to mp4
    if (downloadOptions.includes(fileDownloadFormats.MP4)) {
      return fileDownloadFormats.MP4;
    }
    return null;
  }

  // anything else should be defaulted to PNG
  if (downloadOptions.includes(fileDownloadFormats.PNG)) {
    return fileDownloadFormats.PNG;
  }
  return null;
};
