import { createSelector } from "reselect";
import { isNil, getPath } from "lib";

export const userTeamAnimationsEntitiesSelector = state =>
  state.entities.userTeamAnimations;
const userTeamAnimationsApiSelector = state => state.api.userTeamAnimations;

export const processGetUserTeamAnimationsNotInFolders = (
  userTeamAnimationsApi,
  userTeamAnimationsEntities
) => {
  let userAnimations = [];
  if (userTeamAnimationsApi.pages) {
    const pagesOrder = Object.keys(userTeamAnimationsApi.pages).sort();

    pagesOrder.forEach(page => {
      const ids = userTeamAnimationsApi.pages[page].ids || [];

      ids.forEach(userTeamAnimationId => {
        const entity = userTeamAnimationsEntities[userTeamAnimationId];

        userAnimations.push(entity);
      });
    });
  }
  return userAnimations;
};

export const getUserTeamAnimationsNotInFolders = createSelector(
  [userTeamAnimationsApiSelector, userTeamAnimationsEntitiesSelector],
  processGetUserTeamAnimationsNotInFolders
);

const processUserTeamAnimationsSelector = (
  userTeamAnimationsApi,
  userTeamAnimationsEntities
) => {
  if (isNil(userTeamAnimationsApi.all.pages)) {
    return null;
  }

  let hasBeenFetched = false;
  let userAnimationsIds = [];

  const pagesOrder = Object.keys(userTeamAnimationsApi.all.pages).sort();

  pagesOrder.forEach(page => {
    const ids = getPath(userTeamAnimationsApi.all, `pages[${page}].ids`);
    const uniqueIds = [...new Set(ids)];

    if (!isNil(uniqueIds)) {
      hasBeenFetched = true;
      userAnimationsIds = userAnimationsIds.concat(uniqueIds);
    }
  });

  if (hasBeenFetched) {
    return userAnimationsIds.map(
      animationId => userTeamAnimationsEntities[animationId]
    );
  } else {
    return null;
  }
};

export const userTeamAnimationsSelector = createSelector(
  [userTeamAnimationsApiSelector, userTeamAnimationsEntitiesSelector],
  processUserTeamAnimationsSelector
);

export const userTeamAnimationSelector = ({ state, animationId }) =>
  state.entities.userTeamAnimations[animationId];
