export const PENDING_USERS_API_PAGE_SIZE_CHANGE =
  "APP/API/PENDING_USERS/PAGE_SIZE_CHANGE";

export const PENDING_USERS_RESEND_REQUEST =
  "APP/API/PENDING_USERS/RESEND_REQUEST";
export const PENDING_USERS_RESEND_REQUEST_SUCCESS =
  "APP/API/PENDING_USERS/RESEND_REQUEST_SUCCESS";
export const PENDING_USERS_RESEND_REQUEST_FAILURE =
  "APP/API/PENDING_USERS/RESEND_REQUEST_FAILURE";

export const PENDING_USERS_RESEND_ALL_REQUEST =
  "APP/API/PENDING_USERS/RESEND_ALL_REQUEST";
export const PENDING_USERS_RESEND_ALL_REQUEST_SUCCESS =
  "APP/API/PENDING_USERS/RESEND_ALL_REQUEST_SUCCESS";
export const PENDING_USERS_RESEND_ALL_REQUEST_FAILURE =
  "APP/API/PENDING_USERS/RESEND_ALL_REQUEST_FAILURE";

export const PENDING_USERS_CANCEL_REQUEST =
  "APP/API/PENDING_USERS/CANCEL_REQUEST";
export const PENDING_USERS_CANCEL_REQUEST_SUCCESS =
  "APP/API/PENDING_USERS/CANCEL_REQUEST_SUCCESS";
export const PENDING_USERS_CANCEL_REQUEST_FAILURE =
  "APP/API/PENDING_USERS/CANCEL_REQUEST_FAILURE";

export const PENDING_USERS_CANCEL_ALL_REQUEST =
  "APP/API/PENDING_USERS/CANCEL_ALL_REQUEST";
export const PENDING_USERS_CANCEL_ALL_REQUEST_SUCCESS =
  "APP/API/PENDING_USERS/CANCEL_ALL_REQUEST_SUCCESS";
export const PENDING_USERS_CANCEL_ALL_REQUEST_FAILURE =
  "APP/API/PENDING_USERS/CANCEL_ALL_REQUEST_FAILURE";
