import { noop } from "lib";
import { getParameterByName } from "lib/queryStringUtils";
import {
  removeBackgroundApiCall,
  removeBackgroundWithinFolderApiCall
} from "state/api/removeBackground/removeBackgroundApiActions";
import {
  refetchUserTeamImagesPages,
  refetchUserTeamImagesPagesInFolder
} from "state/entities/userTeamImages/userTeamImagesActions";
import { fetchCreditsApiCall } from "state/api/credits/creditsApiActions";
import { APP_UI_WORKSPACE_REMOVE_BG_RESET_MODAL } from "./removeBgTypes";
import { editorImageSearchPersonalSelector } from "state/ui/editorImageSearch/editorImageSearchSelectors";
import { searchUserImages } from "state/ui/editorImageSearch/editorImageSearchActions";

export const resetModal = () => {
  return {
    type: APP_UI_WORKSPACE_REMOVE_BG_RESET_MODAL
  };
};

export const removeBg = ({ mediaId, onSuccess = noop, onFailure = noop }) => (
  dispatch,
  getState
) => {
  const imageFolderId = getParameterByName("folderId", window.location.search);

  if (imageFolderId) {
    dispatch(
      removeBgWithinFolder({
        mediaId,
        folderId: imageFolderId,
        onSuccess,
        onFailure
      })
    );
  } else {
    dispatch(
      removeBgOnRoot({
        mediaId,
        onSuccess,
        onFailure
      })
    );
  }
};

export const removeBgWithinFolder = ({
  mediaId,
  folderId,
  onSuccess = noop,
  onFailure = noop
}) => (dispatch, getState) => {
  dispatch(
    removeBackgroundWithinFolderApiCall({
      mediaId,
      folderId,
      onSuccess: response => {
        dispatch(refetchUserTeamImagesPagesInFolder(folderId));
        dispatch(fetchCreditsApiCall());
        onSuccess(response);
      },
      onFailure: response => {
        onFailure(response);
      }
    })
  );
};

export const removeBgOnRoot = ({
  mediaId,
  onSuccess = noop,
  onFailure = noop
}) => (dispatch, getState) => {
  dispatch(
    removeBackgroundApiCall({
      mediaId,
      onSuccess: response => {
        const editorPersonalSearchState = editorImageSearchPersonalSelector(
          getState()
        );
        const isSearchActive = editorPersonalSearchState.currentTerm !== null;
        if (isSearchActive) {
          dispatch(
            searchUserImages({ term: editorPersonalSearchState.currentTerm })
          );
        }

        dispatch(refetchUserTeamImagesPages());
        dispatch(fetchCreditsApiCall());
        onSuccess(response);
      },
      onFailure: response => {
        onFailure(response);
      }
    })
  );
};
