export const reportSignupEvent = () => {
  window.gtag("event", "signup");
};

export const reportUpgradeEvent = ({
  currency,
  interval,
  callback,
  plan,
  value
}) => {
  window.gtag("event", `${plan.toLowerCase()}_upgrade`, {
    currency: currency,
    interval: interval,
    plan: plan,
    value: value,
    event_callback: callback
  });
};
