export const SECTION_TYPES = {
  SELECTION: "SELECTION",
  SUBTEAM: "SUBTEAM",
  ORGANISATION: "ORGANISATION"
};

export const TEAM_TYPE_MAP = {
  SUBTEAM: "subTeam",
  ORGANISATION: "orgTeam"
};
