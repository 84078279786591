import { sortObjectKeysAlphabetically } from "./object/object";
import { cloneDeep, omit } from "lib/lodash";

/**
 * Compares element preview objects and disregards the 'value' property.
 *
 * In some use cases (shouldComponentUpdate for certain action bars) we want to explicitly ignore the value as the
 * change in value is inconsequential (and potentially detrimental) , however, the change in other preview properties
 * is important.
 *
 * @param {object} preview1 - first preview item to compare
 * @param {object} preview2 - second preview item to compare
 * @returns {boolean} if both previews match apart from their value property.
 */
export const isPreviewEqualIgnoringValue = (preview1, preview2) => {
  const currentPreview = cloneDeep(preview1);
  if (!!currentPreview && !!currentPreview.value) delete currentPreview.value;

  const nextPreview = cloneDeep(preview2);
  if (!!nextPreview && !!nextPreview.value) delete nextPreview.value;

  return jsonStringEqual(currentPreview, nextPreview);
};

export const jsonStringEqual = (input1, input2) =>
  JSON.stringify(input1) === JSON.stringify(input2);

/* perform jsonStringEqual while omiting circular key 'children' */
export const jsonStringEqualForProps = (input1, input2) =>
  jsonStringEqual(omit(input1, "children"), omit(input2, "children"));

// perform a jsonString equality check after sorting input object keys alphabetically so both are in same order
export const sortedJsonStringEqual = (input1, input2) =>
  jsonStringEqual(
    sortObjectKeysAlphabetically(input1),
    sortObjectKeysAlphabetically(input2)
  );
