import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

const OneCardLayout = ({ children, background, className }) => {
  const dynamicStyle = {
    backgroundImage: `url("${background}")`
  };

  return (
    <div
      className={`${style.background} translate`}
      style={dynamicStyle}
      data-testid="OneCardLayout"
    >
      <div className={`${style.card} ${className}`}>{children}</div>
    </div>
  );
};
OneCardLayout.propTypes = {
  children: PropTypes.node
};

export default OneCardLayout;
