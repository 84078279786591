import React from "react";
import { withRouter } from "react-router";
import BrandKitNav from "../BrandKitNav/BrandKitNav";
import style from "./style.module.css";

function BrandKitLayout({ children, location }) {
  const [child0, ...childN] = children;

  return (
    <div className={style.container}>
      <div className={style.top}>{child0}</div>

      <div className={style.bottom}>
        <div className={style.left}>
          <BrandKitNav location={location} />
        </div>

        <div className={style.right}>{childN}</div>
      </div>
    </div>
  );
}

export default withRouter(BrandKitLayout);
