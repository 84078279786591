import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import {
  handleDesignRequest,
  handleDesignRequestSuccess
} from "state/api/designs/apiDesignsReducersCommonCases";

import { findPage } from "state/api/helpers";
import { fixPagesIds } from "state/api/designs/helpers";
import { flatten } from "lib";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50
};

const archivedApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_ARCHIVED_REQUEST: {
      return handleDesignRequest({ state, action });
    }

    case designsEntitiesTypes.DESIGNS_ARCHIVED_REQUEST_SUCCESS: {
      return handleDesignRequestSuccess({ state, action });
    }

    case designsEntitiesTypes.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST: {
      const { body: archivedChanged } = action.request;
      const allArchivedIds = flatten(
        Object.keys(state.pages)
          .sort()
          .map(i => state.pages[i].ids)
      );

      const archivedChangedIds = archivedChanged.map(archived => archived.id);

      const allArchivedIdsWithoutUpdatedIds = allArchivedIds.filter(
        archivedId => !archivedChangedIds.includes(archivedId)
      );

      return {
        ...state,
        pages: fixPagesIds({ ids: allArchivedIdsWithoutUpdatedIds, state })
      };
    }

    case designsEntitiesTypes.DESIGNS_DELETE_REQUEST_SUCCESS: {
      const { designId } = action.extra;
      const pageNumber = findPage(state.pages, designId);
      const page = state.pages[pageNumber];
      const pageIds = page.ids;

      function removeIdFromPage(array, id) {
        const index = array.indexOf(id);
        if (index !== -1) {
          array.splice(index, 1);
        }
        return array;
      }

      const updatedPage = {
        ...page,
        ids: removeIdFromPage(pageIds, designId)
      };

      return {
        ...state,
        pages: {
          ...state.pages,
          [pageNumber]: updatedPage
        }
      };
    }

    default:
      return state;
  }
};

export default archivedApiReducers;
