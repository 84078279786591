const downloadDesign = ({ path, isTargetBlank, downloadName }) => {
  if (["MY_IMAGES", "MY_ANIMATIONS"].includes(path)) return;

  const element = document.createElement("a");

  element.setAttribute("href", path);
  element.setAttribute("download", downloadName || path);

  if (isTargetBlank) {
    element.setAttribute("target", "_blank");
  }

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export default downloadDesign;
