import * as types from "./editorActionPropagationTypes";

/**
 * @desc adds an action to the propagation queue in redux
 * @param {Object} actionData - a set of data which describes the action and its inputs
 * @see removeActionsMatching for removal of action from propagation queue
 */
export const addAction = actionData => {
  return {
    type: types.ADD_PROPAGATION_ACTION,
    actionData
  };
};

/**
 * @desc remove one or more actions from the propagation queue in redux
 * @param {Object} match - an object of key-value pairs for actions matching to be removed
 * @see addAction for adding of action to propagation queue
 */
export const removeActionsMatching = match => {
  return {
    type: types.REMOVE_PROPAGATION_ACTION,
    match
  };
};

export const setIsProcessing = (value = false) => {
  return {
    type: types.SET_PROPAGATION_PROCESSING,
    value
  };
};

export const setPropagatingDesignIds = designIds => {
  return {
    type: types.SET_PROPAGATING_DESIGN_IDS,
    designIds
  };
};

export const removePropagatingDesignId = designId => {
  return {
    type: types.REMOVE_PROPAGATING_DESIGN_ID,
    designId
  };
};
