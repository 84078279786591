import * as types from "./portalTypes";

export const initState = {
  showDownloadProof: true,
  disablePDFDownload: false
};

const portalReducers = (state = initState, action) => {
  switch (action.type) {
    case types.SHOW_DOWNLOAD_PROOF: {
      return {
        ...state,
        showDownloadProof: action.payload.allowDownloadProof
      };
    }
    case types.HIDE_PDF_DOWNLOAD: {
      return {
        ...state,
        disablePDFDownload: action.payload.preventPDFDownload
      };
    }
    default:
      return state;
  }
};

export default portalReducers;
