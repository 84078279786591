/**
 * @desc performs the stopPropagation function for the given html event
 * @param {HTMLEvent} event - the html event to stop propagation on
 */
export const doNotPropagate = event => {
  event.stopPropagation();
};

/**
 * @desc performs stopPropagation and preventDefault for the given html event
 * @param {HTMLEvent} event - the html event to halt
 */
export const preventDefaultAndDoNotPropagate = event => {
  event.preventDefault();
  event.stopPropagation();
};
