import * as types from "./catalogueSizesTypes";

export const initState = {
  folders: {}
};

const catalogueSizesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CATALOGUE_SIZES_FOLDER_REQUEST_SUCCESS:
    case types.TEAM_SIZES_FOLDER_REQUEST_SUCCESS:
    case types.TEAM_SIZES_REQUEST_SUCCESS:
    case types.CATALOGUE_SIZES_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return state;
      }

      return {
        ...state,
        ...action.response.entities.catalogueSize
      };
    }

    case types.TEAM_SIZES_FILTER_REQUEST_SUCCESS:
    case types.CATALOGUE_SIZES_FILTER_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return state;
      }

      const {
        response: {
          entities: { catalogueSize }
        }
      } = action;

      return {
        ...catalogueSize
      };
    }

    default:
      return state;
  }
};

export default catalogueSizesReducer;
