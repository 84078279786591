import React, { Component } from "react";

import { DropTarget } from "react-dnd";
import HoverOverlay from "../HoverOverlay";
import TableRow from "./TableRow";
import style from "./style.module.css";
import { identity, getPath, isEmpty } from "lib/lodash";
import { insertItem, moveItem } from "lib/array/array";
import { tableHeightsUpdater } from "views/components/Editor/editorOps/EditorTableOps/tableSimulator";

export class Table extends Component {
  static defaultProps = {
    connectDragSource: identity,
    connectDropTarget: identity,
    TableRowComponent: TableRow
  };

  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
    this.getTableStyle = this.getTableStyle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.showHoverOverlay = this.showHoverOverlay.bind(this);
    this.handleRowMove = this.handleRowMove.bind(this);
    this.handleTableOver = this.handleTableOver.bind(this);
    this.persistRowMove = this.persistRowMove.bind(this);
    this.finishHandleTableOver = this.finishHandleTableOver.bind(this);

    this.state = {
      isHovered: false,
      rowsPreview: null,
      columnsMetadataPreview: null
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOver && !this.props.isOver) {
      this.setState({ rowsPreview: null });
    }
  }

  onSelect(e) {
    e.stopPropagation();
    e.preventDefault();

    /* if user pressed shift or meta/command(mac), we append the to the delection*/
    const append = e.shiftKey || e.metaKey;

    this.props.onSelectItem({ append });
  }

  getTableStyle() {
    const { height, width, opacity } = this.props.elementData;

    return {
      ...this.props.style,
      opacity,
      height,
      width
    };
  }

  onMouseEnter() {
    this.setState({ isHovered: true });
  }

  onMouseLeave() {
    this.setState({ isHovered: false });
  }

  showHoverOverlay() {
    const {
      isOnlyElementSelected,
      isSelected,
      isAllRestrictionsLocked
    } = this.props;
    const { isHovered } = this.state;

    if (isOnlyElementSelected || isSelected || isAllRestrictionsLocked) {
      return false;
    }

    return isHovered;
  }

  finishHandleTableOver() {
    this.setState({
      rowsPreview: null,
      columnsMetadataPreview: null
    });
  }

  /**
   * @desc Handles setting the preview when a table from the sidebar is dragged and hovered over our selected table
   * @param {object} args - the argument object
   * @param {number} args.hoverIndex - the index of the currently hovered table position
   * @param {object} args.table - the table object for the table we are hovering with
   */
  handleTableOver({ hoverIndex, table }) {
    // break early if we have no table or the table has no rows
    if (!table || isEmpty(table) || !table.rows || !table.rows[0]) {
      return;
    }

    const { rows, columnsMetadata } = this.props.elementData;
    const { rowsPreview } = this.state;
    const currentVisibleRows = rowsPreview || rows;

    // collect the id for our hovered row
    const hoveredRowId = getPath(currentVisibleRows, [hoverIndex, "id"], "");
    const firstTableRowId = getPath(table, ["rows", 0, "id"]);

    // check if the hovered row already has our dragged table in this position
    const isAlreadyPreviewingInThatPosition = hoveredRowId === firstTableRowId;

    // exit early if the dragged table is already being previewed in this position
    if (isAlreadyPreviewingInThatPosition) {
      return;
    }

    const nextRowsPreview = insertItem(rows, hoverIndex, table.rows[0]);

    const columnsMetadataUpdated = {
      ...table.columnsMetadata,
      ...columnsMetadata
    };

    this.setState({
      rowsPreview: nextRowsPreview,
      columnsMetadataPreview: columnsMetadataUpdated
    });
  }

  handleRowMove({ dragIndex, hoverIndex }) {
    const { rows } = this.props.elementData;

    const currentVisibleRows = this.state.rowsPreview || rows;

    const nextRowsPreview = moveItem(currentVisibleRows, dragIndex, hoverIndex);

    this.setState({ rowsPreview: nextRowsPreview });
  }

  persistRowMove() {
    const { onElementAttributeChange } = this.props;
    const { rowsPreview } = this.state;

    if (!rowsPreview) return;

    onElementAttributeChange({ rows: rowsPreview });
    this.setState({ rowsPreview: null });
  }

  render() {
    const {
      deleteTableRow,
      isOnlyElementSelected,
      elementData,
      connectDragSource,
      connectDropTarget,
      TableRowComponent,
      zoom
    } = this.props;

    const { rowsPreview, columnsMetadataPreview } = this.state;

    const rows = rowsPreview ? rowsPreview : elementData.rows;
    const columnsMetadata = columnsMetadataPreview
      ? columnsMetadataPreview
      : elementData.columnsMetadata;

    const tableStyle = this.getTableStyle();

    const isPreviewAvailable = Boolean(rowsPreview) && rowsPreview.length > 0;
    // when there is a preview we hide the original but still use its drop zones
    // to avoid drop zone locations changing dramatically

    return connectDropTarget(
      connectDragSource(
        <div
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onClick={this.onSelect}
          style={tableStyle}
          className={style.table}
        >
          <div
            style={{
              position: isPreviewAvailable ? "absolute" : "relative",
              width: "100%",
              zIndex: 5
            }}
          >
            {elementData.rows.map((row, index) => (
              <TableRowComponent
                isOnlyElementSelected={isOnlyElementSelected}
                key={row.id}
                index={index}
                isFirstRow={index === 0}
                isLastRow={index === rows.length - 1}
                row={row}
                onTableOver={this.handleTableOver}
                finishHandleTableOver={this.finishHandleTableOver}
                rowMetadata={columnsMetadata[row.rowTypeCode]}
                deleteTableRow={deleteTableRow}
                onRowMove={this.handleRowMove}
                onPersistRowMove={this.persistRowMove}
                isReordering={this.state.isReordering}
                isDroppable={true}
                isHidden={isPreviewAvailable}
                tableId={elementData.uniqueId}
              />
            ))}
          </div>
          {Boolean(rowsPreview) &&
            rowsPreview.length > 0 &&
            rowsPreview.map((row, index) => (
              <TableRowComponent
                isOnlyElementSelected={isOnlyElementSelected}
                key={row.id}
                index={index}
                isFirstRow={index === 0}
                isLastRow={index === rows.length - 1}
                row={row}
                onTableOver={this.handleTableOver}
                finishHandleTableOver={this.finishHandleTableOver}
                rowMetadata={columnsMetadata[row.rowTypeCode]}
                deleteTableRow={deleteTableRow}
                onRowMove={this.handleRowMove}
                onPersistRowMove={this.persistRowMove}
                isReordering={this.state.isReordering}
                isDroppable={false}
              />
            ))}
          {this.showHoverOverlay() && (
            <HoverOverlay
              height={tableStyle.height}
              width={tableStyle.width}
              zoom={zoom}
            />
          )}
        </div>
      )
    );
  }
}

const LayerTarget = {
  drop(props, monitor, component) {
    const dragItem = monitor.getItem();
    if (!monitor.getDropResult()) {
      return;
    }

    const { hoverIndex } = monitor.getDropResult();
    const table = dragItem.tableElement;

    const rowsUpdated = insertItem(
      props.elementData.rows,
      hoverIndex,
      table.rows[0]
    );

    const columnsMetadata = {
      ...table.columnsMetadata,
      ...props.elementData.columnsMetadata
    };

    const tableUpdated = tableHeightsUpdater({
      ...props.elementData,
      rows: rowsUpdated,
      columnsMetadata: columnsMetadata
    });

    props.onElementAttributeChange({ ...tableUpdated });

    component.finishHandleTableOver();

    return {
      wasSideBarTableDropHandled: true
    };
  }
};

const collectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

export default DropTarget(["SIDEBAR_TABLE"], LayerTarget, collectTarget)(Table);
/* export default Table;*/
