export const addHyphensToUUID = uuid => {
  const cleanUUID = uuid.replace(/-/g, "");
  return [
    cleanUUID.slice(0, 8),
    cleanUUID.slice(8, 12),
    cleanUUID.slice(12, 16),
    cleanUUID.slice(16, 20),
    cleanUUID.slice(20)
  ]
    .join("-")
    .toLowerCase();
};
