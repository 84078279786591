import * as types from "./pendingUsersApiTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { fetchPendingUsersIfNeeded } from "state/entities/pendingUsers/pendingUsersActions";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import { findPage } from "state/api/designs/helpers";

export const pendingUsersApiPageSizeChange = pageSize => dispatch => {
  dispatch({
    type: types.PENDING_USERS_API_PAGE_SIZE_CHANGE,
    payload: { pageSize }
  });

  dispatch(fetchPendingUsersIfNeeded({ page: 1 }));
};

export const cancelTeamInvite = ({ invitationId, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const page = findPage(state.api.pendingUsers.pages, invitationId);

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.USER,
      types: [
        types.PENDING_USERS_CANCEL_REQUEST,
        types.PENDING_USERS_CANCEL_REQUEST_SUCCESS,
        types.PENDING_USERS_CANCEL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/invitations/${invitationId}`,
      schema: schemas.NONE,
      extra: {
        invitationId
      },
      onSuccess: () => {
        dispatch(fetchPendingUsersIfNeeded({ page }));
        onSuccess();
      }
    }
  });
};

export const resendTeamInvite = ({ invitationId, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.USER,
      types: [
        types.PENDING_USERS_RESEND_REQUEST,
        types.PENDING_USERS_RESEND_REQUEST_SUCCESS,
        types.PENDING_USERS_RESEND_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/invitations/${invitationId}/token`,
      request: {
        body: {
          userId,
          teamId,
          inviteId: invitationId
        }
      },
      schema: schemas.NONE,
      onSuccess
    }
  });
};

export const resendAllTeamInvites = ({ onSuccess } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.USER,
      types: [
        types.PENDING_USERS_RESEND_ALL_REQUEST,
        types.PENDING_USERS_RESEND_ALL_REQUEST_SUCCESS,
        types.PENDING_USERS_RESEND_ALL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/invitations/pending`,
      request: {
        body: {
          teamId
        }
      },
      schema: schemas.NONE,
      onSuccess
    }
  });
};

export const cancelAllTeamInvites = ({ onSuccess } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.USER,
      types: [
        types.PENDING_USERS_CANCEL_ALL_REQUEST,
        types.PENDING_USERS_CANCEL_ALL_REQUEST_SUCCESS,
        types.PENDING_USERS_CANCEL_ALL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/invitations`,
      schema: schemas.NONE,
      onSuccess
    }
  });
};
