/**
 * @desc appends all key value pairs from given appendTerms to the given queryParams object
 * @param {object} queryParams - an object of key value pairs representing the query params
 * @param {object} appendTerms - an object of key value pairs describing the params to add to the URL search
 * @returns {object} returns a URLParams instance with the given terms appended
 */
export const appendObjectToURLParams = (queryParams, appendTerms) => {
  const qParams = Object.assign({}, queryParams);
  Object.keys(appendTerms).forEach(key => {
    qParams[key] = appendTerms[key];
  });
  return qParams;
};

/**
 * @desc removes all given terms from to the given queryParams object
 * @param {object} queryParams - an object of key value pairs representing the query params
 * @param {[string]} appendTerms - an array of keys to delete from the URL search
 * @returns {object} returns a URLParams instance with the given terms deleted
 */
export const deleteObjectFromURLParams = (queryParams, deleteTerms) => {
  const qParams = Object.assign({}, queryParams);
  deleteTerms.forEach(term => {
    delete qParams[term];
  });
  return qParams;
};

export const objectToQueryString = object => {
  var searchTermsEncoded = Object.keys(object)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(object[key]))
    .join("&");

  const queryString = "?" + searchTermsEncoded;

  return queryString;
};

/**
 * @desc takes a url query string and converts it into an object
 * @param {string} queryString - a query string to convert to an object
 * @returns {object} an object of key value pairs based on the query string passed in
 */
export const queryStringToObject = queryString => {
  const object = queryString
    .replace("?", "")
    .split("&")
    .reduce((previous, current, index, array) => {
      const param = current.split("=");
      previous[decodeURIComponent(param.shift())] = decodeURIComponent(
        param.pop()
      );
      return previous;
    }, {});

  return object;
};
