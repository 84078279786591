const keyCodes = {
  backspaceKey: 8,
  tabKey: 9,
  enterKey: 13,
  escapeKey: 27,
  leftKey: 37,
  upKey: 38,
  arrowUp: 38,
  rightKey: 39,
  downKey: 40,
  arrowDown: 40,
  deleteKey: 46,
  plusKeyFirefox: 61,
  aKey: 65,
  bKey: 66,
  cKey: 67,
  iKey: 73,
  kKey: 75,
  lKey: 76,
  uKey: 85,
  vKey: 86,
  yKey: 89,
  zKey: 90,
  plusKeyNumPad: 107,
  minusKeyNumPad: 109,
  minusKeyFirefox: 173,
  plusKeyStandard: 187,
  minusKeyStandard: 189,
  isBlackListed: keyCode => blackList.includes(keyCode)
};

const blackList = [
  keyCodes.deleteKey,
  keyCodes.backspaceKey,
  keyCodes.escapeKey,
  keyCodes.leftKey,
  keyCodes.upKey,
  keyCodes.rightKey,
  keyCodes.downKey
];

export default keyCodes;
