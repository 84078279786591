import * as types from "../../currentUser/currentUserTypes";

export const initState = {
  isProcessing: false,
  isSuccess: false,
  errors: {}
};

const userSettingsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_UPDATE_REQUEST: {
      const errors = {};

      return {
        ...state,
        errors,
        isProcessing: true,
        isSuccess: false
      };
    }

    case types.CURRENT_USER_UPDATE_REQUEST_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isSuccess: true
      };
    }

    case types.CURRENT_USER_UPDATE_REQUEST_FAILURE: {
      const { error } = action;
      let errors = {};
      error.errors.forEach(item => {
        errors[item.path] = item.message;
      });

      return {
        ...state,
        errors,
        isProcessing: false,
        isSuccess: false
      };
    }

    default:
      return state;
  }
};

export default userSettingsReducer;
