import React from "react";
import PropTypes from "prop-types";

const SmoothHashIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color = "#8F9297"
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="SmoothHashIcon"
  >
    <g fill="none" fillRule="evenodd">
      <rect width={width} height={height} rx="4" />
      <g transform="translate(3 3)" fill={color}>
        <rect x="3" width="2" height="14" rx="1" />
        <rect y="3" width="14" height="2" rx="1" />
        <rect y="9" width="14" height="2" rx="1" />
        <rect x="9" width="2" height="14" rx="1" />
      </g>
    </g>
  </svg>
);
SmoothHashIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SmoothHashIcon;
