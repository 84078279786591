import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ShadowIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h20v20H-1z" />
      <g fill={color || easilGrayXDark}>
        <path d="M10.316 7.691h4.12l-2.06-4.417-2.06 4.417zm-.933 2l-1.055 2.262a1 1 0 0 1-1.812-.845l4.9-10.51a.999.999 0 0 1 .75-.566 1 1 0 0 1 1.159.546l4.902 10.513a1 1 0 1 1-1.812.845l-1.047-2.245H9.383z" />
        <path
          fillRule="nonzero"
          d="M11.033 14.1a1 1 0 0 1 0-2h2.669l1.157 2.482a1 1 0 0 1-1.813.845l-.619-1.327h-1.394zM5.649 2.582a1 1 0 0 1 1.815.84L1.908 15.42a1 1 0 1 1-1.815-.84L5.65 2.582z"
        />
      </g>
    </g>
  </svg>
);
ShadowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ShadowIcon;
