import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CurvedTextIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  color,
  title
}) => {
  return (
    <svg
      className={className}
      data-no-pointer={noPointer}
      height={height || size || 24}
      width={width || size || 24}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                d="M0 0H20V20H0z"
                transform="translate(-471 -74) translate(465 68) translate(6 6)"
              />
              <g>
                <path
                  stroke={color || easilGrayXDark}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 15c-3-3.5-7-3.5-8-3.5s-5 0-8 3.5"
                  transform="translate(-471 -74) translate(465 68) translate(6 6) translate(2 2)"
                />
                <g
                  fill={color || easilGrayXDark}
                  transform="translate(-471 -74) translate(465 68) translate(6 6) translate(2 2) translate(4)"
                >
                  <rect width="8" height="2" rx="1" />
                  <rect width="2" height="8" x="3" y="1" rx="1" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CurvedTextIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CurvedTextIcon;
