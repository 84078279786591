import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ArrowIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  direction = "right",
  title
}) => {
  const directionToAngle = {
    right: 0,
    down: 90,
    left: 180,
    up: -90
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction]}deg)`,
    ...style
  };

  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 17 14"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M-3-5h24v24H-3z" />
        <path
          fill={color || easilGrayXDark}
          d="M13.064 7.706l-3.99 3.991a.989.989 0 0 0 1.397 1.398l5.624-5.624a.989.989 0 0 0-.145-1.518L10.493.496A.993.993 0 1 0 9.09 1.901l3.828 3.828H1.783a.989.989 0 0 0 0 1.977h11.281z"
        />
      </g>
    </svg>
  );
};
ArrowIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ArrowIcon;
