import React from "react";
import { connect } from "react-redux";
import DesignPromotionModal from "views/components/CollectionPreviewModal/DesignPromotionModal";
import CreateDraftModal from "views/components/CollectionPreviewModal/CreateDraftModal";
import { closeCollectionPreviewModal } from "state/ui/collectionPreviewModal/collectionPreviewModalActions";
import { fetchCollectionDesigns } from "state/entities/designs/designsActions";
import {
  designById,
  designsForCollectionIdCategorisedSelector
} from "state/entities/designs/designsSelectors";
import { collectionApiSelector } from "state/api/collection/collectionApiSelectors";
import { teamSelector } from "state/entities/teams/teamsSelectors";
import PropTypes from "prop-types";
import PATHS from "routes/paths";

export class CollectionPreviewModalContainer extends React.Component {
  componentDidUpdate(prevProps) {
    const { isOpen, selectedDesign } = this.props;
    const { isOpen: wasOpen } = prevProps;

    if (isOpen && !wasOpen) {
      // just opened the modal so we should fetch collection designs
      this.props.fetchCollectionDesigns(selectedDesign.collectionId);
    }
  }

  render() {
    if (!this.props.selectedDesign) return null;

    switch (this.props.selectedDesign.status) {
      case "APPROVED":
      case "DRAFT": {
        return <DesignPromotionModal {...this.props} />;
      }
      case "MASTER":
      default: {
        return <CreateDraftModal {...this.props} />;
      }
    }
  }
}

CollectionPreviewModalContainer.displayName = "CollectionPreviewModalContainer";

CollectionPreviewModalContainer.propTypes = {
  isOpen: PropTypes.bool
};

const mapStateToProps = state => {
  const isOpen = state.ui.collectionPreviewModal.isOpen;

  if (!isOpen) {
    return {
      isOpen
    };
  }

  const selectedDesign = designById({
    state,
    designId: state.ui.collectionPreviewModal.selectedDesignId
  });

  const designsCategorisedInput = {
    state,
    collectionId: selectedDesign.collectionId,
    scope: "subscription"
  };

  if (PATHS.isTeamTemplates(window.location.pathname)) {
    designsCategorisedInput.scope = "team";
  }
  if (PATHS.isCatalogue(window.location.pathname)) {
    designsCategorisedInput.isCatalogue = true;
  }

  const {
    categories: collectionDesignsCategorised,
    sizeCount
  } = designsForCollectionIdCategorisedSelector(designsCategorisedInput);

  const currentTeamRegion = teamSelector({ state }).country;

  const collectionApiState = collectionApiSelector(state);

  return {
    isOpen,
    selectedDesign,
    collectionDesignsCategorised,
    currentTeamRegion,
    sizeCount,
    isLoading: collectionApiState.isFetching
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseModal: () => dispatch(closeCollectionPreviewModal()),
    fetchCollectionDesigns: args => dispatch(fetchCollectionDesigns(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionPreviewModalContainer);
