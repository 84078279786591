import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

const Empty = ({
  height,
  width,
  className,
  inline,
  color,
  children,
  ratio
}) => {
  const dynamicStyle = {
    display: inline && "inline-block",
    background: color,
    height: height,
    width: width,
    paddingBottom: ratio
  };

  return (
    <div className={`${style.empty} ${className}`} style={dynamicStyle}>
      {children}
    </div>
  );
};

Empty.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string
};
export default Empty;
