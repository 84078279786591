import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PaperPlaneIcon = ({
  className,
  width,
  size,
  height,
  style,
  color,
  direction = "right",
  title,
  viewBox = "0 0 20 20"
}) => {
  const directionToAngle = {
    right: 0,
    down: 90,
    left: 180,
    up: -90
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction]}deg)`,
    ...style
  };

  return (
    <svg
      className={className}
      style={finalStyle}
      xmlns="http://www.w3.org/2000/svg"
      width={width || size || 24}
      height={height || size || 24}
      viewBox={viewBox}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          fillRule="nonzero"
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.236 3.236 16.764 10 3.236 16.764 6.618 10 3.236 3.236zM7 10h9"
        />
      </g>
    </svg>
  );
};
PaperPlaneIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PaperPlaneIcon;
