import elementsRestrictionsMap from "views/components/Editor/sidebar/tabs/restrictions/elementsRestrictionsMap";

const ALL_DOCUMENT_RESTRICTIONS = [
  "brandColorsOnly",
  "brandFontsOnly",
  "backgroundColor",
  "addTextElement",
  "addImageElement",
  "arrange",
  "addPhotoElement",
  "addGraphicElement",
  "editCollectionItems",
  "customColor",
  "showTemplatePanel",
  "showFavoritesPanel",
  "controlFavoritesAssets",
  "layoutsPanel",
  "addNewPage",
  "addLink"
];

const DesignRestrictionsOps = {
  toggleElementsRestriction({ elementsId, attribute, value }) {
    const elementsSelectedUpdated = {};

    const restrictionDetails = elementsRestrictionsMap[attribute] || {};

    const sideEffects = restrictionDetails.sideEffects || [];

    const attributes = [attribute].concat(sideEffects);

    // loop through attributes and perform the toggle
    attributes.forEach(_attribute => {
      const toggle = elementId => {
        // check if the element has already been modified and use the modified version if it has
        const element =
          elementsSelectedUpdated[elementId] || this.getElement(elementId);

        if (element.type === "textbox" && _attribute === "sizeAndPosition") {
          _attribute = "position";
        }

        elementsSelectedUpdated[elementId] = element.toggleRestriction({
          attribute: _attribute,
          value
        });
      };
      if (elementsId === "ALL") {
        Object.keys(this.elements).forEach(toggle);
      } else {
        elementsId.forEach(toggle);
      }
    });

    const elementsUpdated = {
      ...this.elements,
      ...elementsSelectedUpdated
    };

    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  },

  toggleDocumentRestriction({ attribute, value }) {
    let restrictionsSet;

    switch (true) {
      case attribute === "ALL" && value:
        restrictionsSet = ALL_DOCUMENT_RESTRICTIONS;
        break;

      case attribute === "ALL" && !value:
        restrictionsSet = [];
        break;

      case value:
        restrictionsSet = new Set(this.restrictions).add(attribute);
        break;

      default:
        restrictionsSet = new Set(this.restrictions);
        restrictionsSet.delete(attribute);
    }

    return new this.constructor({
      ...this,
      restrictions: Array.from(restrictionsSet),
      version: this.version + 1
    });
  },

  getRestrictionsMap() {
    const restrictionsMap = {};

    ALL_DOCUMENT_RESTRICTIONS.forEach(restriction => {
      restrictionsMap[restriction] = this.restrictions.includes(restriction);
    });

    return restrictionsMap;
  }
};

export default DesignRestrictionsOps;
