import * as teamApiTypes from "state/entities/teams/teamsTypes";
import * as teamMenuTypes from "state/ui/header/TeamMenu/TeamMenuTypes";
import { LOCATION_CHANGE } from "react-router-redux";

const initState = {
  isFetching: false,
  isSearchMode: false,
  selectedTeam: null,
  teamIds: []
};

const TeamMenuReducers = (state = initState, action) => {
  switch (action.type) {
    case teamMenuTypes.CLEAR_ALL: {
      return initState;
    }
    case teamMenuTypes.CLEAR_SEARCH: {
      return {
        ...state,
        isSearchMode: false,
        teamIds: []
      };
    }
    case teamApiTypes.FETCH_TEAMS:
      return {
        ...state,
        isFetching: true,
        selectedTeam: null
      };
    case teamApiTypes.SUB_TEAMS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case teamMenuTypes.NAVIGATE_DOWN:
    case teamMenuTypes.NAVIGATE_UP: {
      return {
        ...state,
        isSearchMode: false,
        selectedTeam: action.payload.selectedTeam
      };
    }
    case teamApiTypes.FETCH_TEAMS_FAILURE:
    case teamApiTypes.SUB_TEAMS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case teamApiTypes.FETCH_TEAMS_SUCCESS:
    case teamApiTypes.SUB_TEAMS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        teamIds: action.response.ids
      };
    case teamMenuTypes.SEARCH_TEAMS: {
      return {
        ...state,
        isFetching: true,
        isSearchMode: true
      };
    }
    case teamApiTypes.TEAMS_SEARCH_REQUEST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        teamIds: []
      };
    }
    case teamApiTypes.TEAMS_SEARCH_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        teamIds: action.response.ids || []
      };
    }
    // we want to reset the menu when the page changes or the team changes
    case LOCATION_CHANGE:
    case teamMenuTypes.SWITCH_TEAMS: {
      return initState;
    }
    default:
      return state;
  }
};

export default TeamMenuReducers;
