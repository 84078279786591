import React from "react";

import style from "./style.module.css";

import Subscriptions from "lib/subscriptions";

import UserSubscriptionPlan from "views/containers/plansContainer/PricingGrid/UserSubscriptionPlan";

import PlanComparisonFeatureGrid from "./PlanComparisonFeatureGrid";

import { noop } from "lib";

import { Button } from "views/components";
import PlanIcon from "views/components/icons/PlanIcon";
import EdgeDesignGuaranteeIcon from "views/components/icons/EdgeDesignGuaranteeIcon";

const formatPriceForDisplay = price => {
  const whole = Math.floor(price);
  const decimal = price - whole;

  let priceValue = whole;

  if (decimal > 0) {
    priceValue = price.toFixed(2);
  }

  return priceValue;
};

const getButtonContent = (
  isUpgradableToThisPlan,
  isCurrentPlan,
  plan,
  handleUpgradeClick,
  subscribeButtonLabel
) => {
  if (isUpgradableToThisPlan) {
    return (
      <Button
        isFullWidth={true}
        theme="blueSolid"
        className={style.upgradeButton}
        onClick={() => handleUpgradeClick(plan.id)}
      >
        {`${subscribeButtonLabel()} to ${plan.name}`}
      </Button>
    );
  }
  if (isCurrentPlan) {
    return (
      <Button
        isFullWidth={true}
        theme="blueSolid"
        className={style.upgradeButton}
        onClick={noop}
        disabled={true}
      >
        Your Current Plan
      </Button>
    );
  }
  return null;
};

const PlanCardHeader = ({
  plan,
  handleUpgradeClick,
  isUpgradableToThisPlan,
  userSubscriptionPlan,
  isCurrentPlan
}) => {
  const isEdge = (plan.name || "").toUpperCase() === "EDGE";
  const pricePointMultiplier = plan.intervalPeriod === "YEARLY" ? 12 : 1;
  const pricePoint = (plan.amount || 0) / pricePointMultiplier;
  const displayPrice = formatPriceForDisplay(pricePoint);
  return (
    <div className={style.planCardHeader} data-plan-type={plan.code}>
      <div className={style.planTitleBox}>
        {/* <div className={style.planTitleCompanyName}>Easil</div>
        <div className={style.planTitle}>{plan.name}</div> */}
        <PlanIcon planName={plan.name} color={"#2C2E2F"} />
        {isEdge && <EdgeDesignGuaranteeIcon color={"#2C2E2F"} />}
      </div>
      <div className={style.planDescription}>
        {Subscriptions[plan.code].description}
      </div>
      <div className={style.largePricePoint}>
        <div className={style.largePricePointTop}>
          {plan.code !== "BASIC" ? (
            <>
              <div className={style.largePricePointCurrency}>
                {plan.currency || "AUD"}
              </div>
              <div className={style.largePricePointCurrencySymbol}>$</div>
              <div className={style.largePricePointValue}>{displayPrice}</div>
            </>
          ) : (
            <div className={style.largePricePointCurrency}>Free</div>
          )}
        </div>
        {plan.code !== "BASIC" && (
          <div className={style.billingInfo}>
            {`Per user / month\n`}
            {plan.intervalPeriod === "YEARLY" &&
              `Billed as $${formatPriceForDisplay(plan.amount || 0)} ${
                plan.currency
              } yearly `}
            {plan.intervalPeriod === "YEARLY" && (
              <span className={style.savingText} data-plan-type={plan.code}>
                Save ${formatPriceForDisplay(plan.save || 0)}
              </span>
            )}
          </div>
        )}
      </div>
      <div className={style.upgradeButtonWrapper}>
        {getButtonContent(
          isUpgradableToThisPlan,
          isCurrentPlan,
          plan,
          handleUpgradeClick,
          subscribeButtonLabel
        )}
      </div>
    </div>
  );

  function subscribeButtonLabel() {
    if (
      userSubscriptionPlan.isPlanId(plan.id) &&
      userSubscriptionPlan.subscription.status === "CANCELLING"
    ) {
      return "Resubscribe";
    } else {
      return "Upgrade";
    }
  }
};

const canShowUpgradeButton = ({ cardPlan, userSubscriptionPlan, isAdmin }) => {
  if (!isAdmin) {
    return false;
  }

  /* we never show the upgrade button to basic plan */
  if (cardPlan.code === "BASIC") {
    return false;
  }

  /* if user is cancelling/trialling we want to show the upgrade button to any plan */
  if (userSubscriptionPlan.hasEndDate()) {
    return true;
  }

  /* if the plan is the the user's next plan, we don't want to show the upgrade button to this plan */
  if (userSubscriptionPlan.subscription.nextSubscriptionId === cardPlan.id) {
    return false;
  }

  const isCurrentPlan = userSubscriptionPlan.isPlanId(cardPlan.id);
  /* if it is a card for the user's current plan, don't show the upgrade button */
  if (isCurrentPlan) {
    return false;
  }

  return userSubscriptionPlan.isUpgrade(cardPlan);
};

const PlanCard = ({
  plan,
  handleUpgradeClick,
  userSubscriptionPlan,
  isAdmin
}) => {
  const isUpgradableToThisPlan = canShowUpgradeButton({
    cardPlan: plan,
    userSubscriptionPlan,
    isAdmin
  });
  const isCurrentPlan = userSubscriptionPlan.isPlanId(plan.id);

  return (
    <div className={style.planCardWrapper}>
      <PlanCardHeader
        plan={plan}
        handleUpgradeClick={handleUpgradeClick}
        isUpgradableToThisPlan={isUpgradableToThisPlan}
        userSubscriptionPlan={userSubscriptionPlan}
        isCurrentPlan={isCurrentPlan}
      />
    </div>
  );
};

export const PlanComparisonBottom = ({
  plans,
  handleUpgradeClick,
  currentPlan,
  currentSubscription,
  isAdmin
}) => {
  const userSubscriptionPlan = new UserSubscriptionPlan({
    subscription: currentSubscription,
    plan: currentPlan
  });

  return (
    <div className={style.wrapperBottom}>
      <div className={style.wrapperBottomCards}>
        {plans.map(plan => (
          <PlanCard
            key={plan.id}
            plan={plan}
            handleUpgradeClick={handleUpgradeClick}
            userSubscriptionPlan={userSubscriptionPlan}
            isAdmin={isAdmin}
          />
        ))}
      </div>
      <PlanComparisonFeatureGrid plans={plans} />
    </div>
  );
};

export default PlanComparisonBottom;
