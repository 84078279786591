import { omit, isEmpty, chunk } from "lib";
import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import { getTermKey } from "../../helpers";

export const initState = {
  isSearching: false,
  pageSize: 50,
  results: {
    /* how this looks like when populated
     *
     * "searchTearm1": {
     *   pages: {}
     * },
     * "searchTearm2": {
     *   pages: {}
     * }*/
  }
};

const searchApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_SEARCH_REQUEST: {
      const { params, page, extra } = action.request;
      const { term, context } = params;

      if (context !== "workspace") {
        return state;
      }

      const termKey = getTermKey({
        term,
        size: params.size,
        searchType: !params.size ? "all" : extra.searchType
      });

      const getPreviousPages = () => {
        if (state.results[termKey]) {
          return state.results[termKey].pages;
        } else {
          return {};
        }
      };

      return {
        ...state,
        isSearching: true,
        results: {
          ...state.results,
          [termKey]: {
            pages: {
              ...getPreviousPages(),
              [page]: {
                ids: null
              }
            }
          }
        }
      };
    }

    case designsEntitiesTypes.DESIGNS_SEARCH_REQUEST_SUCCESS: {
      const { ids } = action.response;

      const { params, page, extra } = action.request;
      const { term, context } = params;

      if (context !== "workspace") {
        return state;
      }

      const termKey = getTermKey({
        term,
        size: params.size,
        searchType: !params.size ? "all" : extra.searchType
      });

      const getPreviousPages = () => {
        if (state.results[termKey]) {
          return state.results[termKey].pages;
        } else {
          return {};
        }
      };

      return {
        ...state,
        isSearching: false,
        results: {
          ...state.results,
          [termKey]: {
            pages: {
              ...getPreviousPages(),
              [page]: {
                ids: ids || []
              }
            }
          }
        }
      };
    }
    case designsEntitiesTypes.DESIGNS_BY_FOLDER_REQUEST_SUCCESS: {
      const {
        response: { ids = [] } = {},
        request: {
          page,
          params: { folderId }
        }
      } = action;

      if (isEmpty(ids) && Number(page) !== 1) {
        return {
          ...state,
          pages: omit(state.pages, action.request.page)
        };
      }

      const updatedPage = {
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false,
          folderId
        }
      };

      if (folderId !== state.folderId) {
        return {
          ...initState,
          pages: { ...state.pages, ...updatedPage }
        };
      }

      return {
        ...state,
        pages: { ...state.pages, ...updatedPage }
      };
    }

    case designsEntitiesTypes.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_SUCCESS: {
      const {
        response: { ids },
        request: { body }
      } = action;

      // when this is a request to unArchive something we don't need to update search state
      // since search is not available to archived designs at the moment
      if (!body[0].archived) {
        return state;
      }

      const newResults = {};

      Object.keys(state.results).forEach(searchTerm => {
        const termData = state.results[searchTerm];

        const allTermIds = Object.values(termData.pages).reduce(
          (previousIds, currentPage) => [...previousIds, ...currentPage.ids],
          []
        );

        const filteredIds = allTermIds.filter(pageId => !ids.includes(pageId));

        // chunk the ids into pageSize and reduce them to a pages object
        const newPages = chunk(filteredIds, state.pageSize).reduce(
          (pages, pageIds, currentIndex) => ({
            ...pages,
            [currentIndex + 1]: { ids: pageIds }
          }),
          {}
        );

        // ensure we preserve previous results data and add to results
        newResults[searchTerm] = {
          ...termData,
          pages: newPages
        };
      });

      return {
        ...state,
        results: newResults
      };
    }

    default:
      return state;
  }
};

export default searchApiReducers;
