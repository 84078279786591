import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CheckIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 12"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
    data-testid="CheckIcon"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-4-6h24v24H-4z" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 1L5.345 11 1 7"
      />
    </g>
  </svg>
);
CheckIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CheckIcon;
