import * as types from "./printPricingTypes";
import { mergeWithArrayConcat } from "lib";

export const initState = {};

const ordersReducer = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_PRINT_PRICING_FOR_DESIGN_REQUEST_SUCCESS: {
      let { entities } = action.response;

      const { designId } = action.extra;

      const filterBy = ["id", "label", "designId"];

      Object.keys(entities.printPricing).forEach(key => {
        if (!entities.printPricing[key].options) return;
        entities.printPricing[key].options = entities.printPricing[
          key
        ].options.map(option => Object.assign({}, option, { designId }));
      });

      /* use the mergeWithArrayConcat function to avoid overwriting arrays */
      return {
        ...mergeWithArrayConcat(state, entities.printPricing, filterBy)
      };
    }

    default:
      return state;
  }
};

export default ordersReducer;
