import React from "react";
import common from "../common.module.css";
import BrandKitColorGroupColor from "./BrandKitColorGroupColor";
import PopoutItemMenu from "views/components/popout/PopoutItemMenu";
import style from "./style.module.css";
import Subscriptions from "lib/subscriptions";

import TrashCanIcon from "views/components/icons/TrashCanIcon";
import SmoothPencilIcon from "views/components/icons/SmoothPencilIcon";
import PlusIcon from "views/components/icons/PlusIcon";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import CrossThinIcon from "views/components/icons/CrossThinIcon";
import CheckIcon from "views/components/icons/CheckIcon";

export default class BrandKitColorGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorGroups: Object.values(props.colors),
      isMenuOpen: false,
      isRenaming: false,
      name: this.props.name
    };
  }

  componentDidUpdate(prevProps) {
    const { colors: oldColors } = prevProps;
    const { colors: newColors } = this.props;

    if (
      Object.keys(oldColors).length !== Object.keys(newColors).length ||
      Object.values(oldColors).some(
        c => c.color !== (newColors[c.id] || {}).color
      )
    ) {
      const { colors } = this.props;
      this.setState({ colorGroups: Object.values(colors) });
    }
  }

  handleColorAdd() {
    const { colorGroups } = this.state;
    const { currentSubscriptionCode } = this.props;
    const colorArr = colorGroups.map(c => c.color);
    const subscription = Subscriptions.get(currentSubscriptionCode);

    if (!subscription.getCanAccessBrandKit()) {
      this.props.showUpgradeModal();
      return;
    }

    this.props.updateBrandKitColorGroup({
      colors: [...colorArr, "#2170BF"],
      groupId: this.props.id,
      name: this.props.name,
      teamId: this.props.currentTeam.id
    });
  }

  handleColorChange(colorId, hex) {
    const { colorGroups } = this.state;

    this.setState({
      colorGroups: colorGroups.map(colorGroup =>
        colorGroup.id === colorId ? { ...colorGroup, color: hex } : colorGroup
      )
    });
  }

  async handleColorClick(colorId) {
    const { isBrandManager } = this.props;

    if (isBrandManager) {
      await this.handleColorPersist();
      await this.props.setSelectedBrandColor({ colorId });
    }
  }

  async handleColorPersist() {
    const { colorGroups } = this.state;
    const { colors } = this.props;

    if (
      colorGroups.length !== Object.keys(colors).length ||
      colorGroups.some(c => c.color !== (colors[c.id] || {}).color)
    ) {
      await this.props.updateBrandKitColorGroup({
        colors: colorGroups.map(c => c.color),
        groupId: this.props.id,
        name: this.props.name,
        teamId: this.props.currentTeam.id
      });
    }
  }

  handleColorRemove(colorId) {
    this.props.updateBrandKitColorGroup({
      colors: this.state.colorGroups
        .filter(c => c.id !== colorId)
        .map(c => c.color),
      groupId: this.props.id,
      name: this.state.name,
      teamId: this.props.currentTeam.id
    });
  }

  handleRemove() {
    const groupId = this.props.id;
    const teamId = this.props.currentTeam.id;
    this.props.removeBrandKitColorGroup({ teamId, groupId });
  }

  handleRename() {
    this.props.updateBrandKitColorGroup({
      colors: this.state.colorGroups.map(c => c.color),
      groupId: this.props.id,
      name: this.state.name,
      teamId: this.props.currentTeam.id
    });
    this.setState({ isRenaming: false });
  }

  handleRenameCancel() {
    this.setState({
      isRenaming: false,
      name: this.props.name
    });
  }

  render() {
    const { colorGroups } = this.state;
    const { isBrandManager, selectedColor } = this.props;

    return (
      <div className={common.whiteRow}>
        <div className={common.whiteRowTop}>
          {this.state.isRenaming ? (
            <div className={style.colorsGroupRename}>
              <input
                autoFocus
                onChange={e => this.setState({ name: e.target.value })}
                onKeyDown={e => {
                  if (e.key === "Escape") {
                    this.handleRenameCancel();
                  } else if (e.key === "Enter") {
                    this.handleRename();
                  }
                }}
                type="text"
                value={this.state.name}
              />
              <div
                className={style.colorsGroupRenameButton}
                onClick={() => this.handleRenameCancel()}
              >
                <CrossThinIcon size="16px" />
              </div>
              <div
                className={style.colorsGroupRenameButton}
                onClick={() => this.handleRename()}
              >
                <CheckIcon size="18px" />
              </div>
            </div>
          ) : (
            <div
              className={style.colorsGroupName}
              onClick={() => {
                if (isBrandManager) {
                  this.setState({ isRenaming: true });
                }
              }}
            >
              <span title={this.state.name}>{this.state.name}</span>

              {isBrandManager && <SmoothPencilIcon size="23px" />}
            </div>
          )}

          {isBrandManager && (
            <div className={common.whiteRowButtons}>
              <div
                className={common.whiteRowButton}
                onClick={event => {
                  this.setState(s => ({
                    isMenuOpen: !s.isMenuOpen
                  }));
                }}
                id={`color-palette-popout-button-${this.props.id}`}
              >
                <EllipsisIcon size="24px" />
              </div>

              {this.state.isMenuOpen && (
                <PopoutItemMenu
                  actions={[
                    {
                      Icon: () => <TrashCanIcon size="22px" />,
                      onClick: () => this.handleRemove(),
                      name: "Delete"
                    }
                  ]}
                  onClose={() => this.setState({ isMenuOpen: false })}
                  className={style.popoutMenu}
                  buttonId={`color-palette-popout-button-${this.props.id}`}
                />
              )}
            </div>
          )}
        </div>

        <div className={style.colorsGroupColors}>
          {colorGroups.map(({ color, id }) => (
            <BrandKitColorGroupColor
              color={color}
              id={id}
              isRemovable={isBrandManager && colorGroups.length > 1}
              isSelected={selectedColor === id}
              key={id}
              onClick={() => this.handleColorClick(id)}
              onPickerChange={c => this.handleColorChange(id, c.hex)}
              onPickerClose={async () => {
                await this.handleColorPersist();
                await this.props.setSelectedBrandColor({ colorId: null });
              }}
              onRemove={() => this.handleColorRemove(id)}
            />
          ))}

          {isBrandManager && (
            <div className={style.addButtonContainer}>
              <div
                className={style.addButton}
                onClick={() => this.handleColorAdd()}
              >
                <div className={style.addButtonPlus}>
                  <PlusIcon size="18px" />
                </div>

                <div className={style.addButtonText}>New Color</div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
