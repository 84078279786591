import React from "react";
import style from "./style.module.css";
import { H3 } from "views/components";
import ImagesIcon from "views/components/icons/ImagesIcon";
import { Logger } from "lib";

class ImageDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputId: `file${Date.now() + Math.random()}`,
      isDraggingOver: false,
      file: null,
      imageError: null
    };
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.processImage = this.processImage.bind(this);
    this.stopBrowserDefaultBehaviour = this.stopBrowserDefaultBehaviour.bind(
      this
    );
  }

  handleFileSelect({ event, origin }) {
    this.stopBrowserDefaultBehaviour(event);
    if (this.props.disabled) return;

    let file;

    switch (origin) {
      case "dragAndDrop":
        file = event.dataTransfer.files[0];
        break;
      case "manualInput":
        file = event.target.files[0];
        break;
      default:
        Logger.warn({ error: "Invalid Event Origin" });
        this.setState({ imageError: "Something went wrong" });
        return;
    }

    this.setState({
      file: file,
      isDraggingOver: false,
      imageError: null
    });

    this.processImage(file).then(
      result => {
        this.setState(
          {
            fileSrc: result
          },
          () => {
            this.props.onChange({ file });
          }
        );
      },
      ({ error }) => {
        this.setState({ imageError: error });
      }
    );
  }

  processImage(file) {
    const imageFilesTypesAllowed = ["png", "jpg", "jpeg", "gif"];
    const fileExtension = file.name
      .split(".")
      .pop()
      .toLowerCase();

    if (!imageFilesTypesAllowed.includes(fileExtension))
      return Promise.reject({ error: "Images must be in PNG or JPG format." });

    const promise = new Promise((resolve, reject) => {
      const errorCallback = msg => () => {
        Logger.warn(msg);
        return reject({ error: "We were not able to process your image." });
      };

      const reader = new FileReader();
      reader.onerror = errorCallback("File Reader onload error");

      reader.onload = ({ target }) => resolve(target.result);

      reader.readAsDataURL(file);
    });

    return promise;
  }

  stopBrowserDefaultBehaviour(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  handleDragEnter(e) {
    this.stopBrowserDefaultBehaviour(e);

    if (this.props.disabled) return;

    this.setState({ isDraggingOver: true });
  }

  handleDragLeave(e) {
    this.stopBrowserDefaultBehaviour(e);

    if (this.props.disabled) return;

    this.setState({ isDraggingOver: false });
  }

  handleDragOver(e) {
    this.stopBrowserDefaultBehaviour(e);

    if (this.props.disabled) return;

    e.dataTransfer.dropEffect = "copy";
  }

  render() {
    return (
      <div className={style.component}>
        <div
          className={style.dropzone}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          onDragOver={this.handleDragOver}
          onDrop={e => {
            this.handleFileSelect({ event: e, origin: "dragAndDrop" });
          }}
          data-is-dragover={this.state.isDraggingOver}
          data-testid="ImageDropZone"
        >
          {this.state.file && this.state.fileSrc ? (
            <img
              src={this.state.fileSrc}
              file={this.state.file}
              alt="team logo"
            />
          ) : (
            <ImagesIcon size="50px" noPointer className={style.icon} />
          )}
        </div>
        <H3 className={style.description}>{this.props.description}</H3>
        <div>
          <input
            disabled={this.props.disabled}
            type="file"
            id={this.state.inputId}
            accept=".png, .jpg, .jpeg"
            className={style.inputfile}
            onChange={e => {
              this.handleFileSelect({ event: e, origin: "manualInput" });
            }}
            data-testid="image-input"
          />
          <label
            data-not-allowed={this.props.disabled}
            htmlFor={this.state.inputId}
          >
            Upload an image
          </label>
          <span> or drag & drop above.</span>
          {this.state.imageError && (
            <div className={style.error}>{this.state.imageError}</div>
          )}
        </div>
      </div>
    );
  }
}
export default ImageDropZone;
