import * as types from "./userTeamAnimationsFoldersTypes";
import { isEmpty, noop } from "lib/lodash";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import {
  fetchUserTeamAnimationsInFolder,
  uploadAnimationToPreSignedUrl
} from "state/entities/userTeamAnimations/userTeamAnimationsActions";
import { getParameterByName } from "lib/queryStringUtils";
import { ANIMATION_ASSET_TYPE_MAP } from "lib/constants";
import { SUBTAB_TYPES } from "views/components/Editor/sidebar/tabs/animations/animationsTabConstants";

const shouldFetchAllAnimationFolders = state => {
  return isEmpty(state.api.userTeamAnimationsFolders.pages);
};

export const fetchAllAnimationFoldersIfNeeded = (...args) => (
  dispatch,
  getState
) => {
  if (shouldFetchAllAnimationFolders(getState())) {
    dispatch(fetchAllAnimationFolders(...args));
  }
};

export const fetchAllAnimationFolders = ({ page = 1 } = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.FETCH_USER_TEAM_ANIMATION_FOLDERS_REQUEST,
        types.FETCH_USER_TEAM_ANIMATION_FOLDERS_REQUEST_SUCCESS,
        types.FETCH_USER_TEAM_ANIMATION_FOLDERS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders`,
      request: {
        page: page,
        params: {
          type: "ANIMATION"
        }
      },
      schema: schemas.FOLDERS,
      onSuccess: response => {
        if (response.entities) {
          dispatch(
            fetchAllAnimationFolders({ teamId, userId, page: page + 1 })
          );
        }
      }
    }
  });
};

export const createUserTeamAnimationsFolder = ({ name, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST,
        types.CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS,
        types.CREATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders`,
      request: {
        body: {
          teamId,
          userId,
          name,
          type: "ANIMATION"
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const addAnimationToFolder = ({ folderId, contentId, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = currentTeamIdSelector(state);
  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      types: [
        types.ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST,
        types.ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS,
        types.ADD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content`,
      request: {
        body: {
          userId,
          teamId,
          contentId,
          folderId,
          type: "ANIMATION"
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const addBulkVideosToFolder = ({
  mediaIds,
  folderId,
  onSuccess,
  onFailure,
  term
}) => (dispatch, getState) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = currentTeamIdSelector(state);
  const term = getParameterByName("term", window.location.search);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST,
        types.BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS,
        types.BULK_MOVE_VIDEO_TO_USER_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/videos`,
      request: {
        body: {
          userId,
          teamId,
          mediaIds,
          folderId
        },
        extra: {
          term
        }
      },
      schema: schemas.FOLDER,
      onSuccess,
      onFailure
    }
  });
};

export const deleteAnimationFolder = ({ folderId, onSuccess = noop }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST,
        types.DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS,
        types.DELETE_USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}`,
      schema: schemas.FOLDER,
      extra: {
        folderId
      },
      onSuccess
    }
  });
};

export const updateAnimationFolder = ({ folder, onSuccess }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "PUT",
      service: SERVICES.ASSET,
      types: [
        types.UPDATE_USER_TEAM_ANIMATION_FOLDER_REQUEST,
        types.UPDATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_SUCCESS,
        types.UPDATE_USER_TEAM_ANIMATION_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folder.id}`,
      schema: schemas.FOLDER,
      request: {
        body: folder,
        type: "ANIMATION"
      },
      onSuccess
    }
  });
};

export const removeAnimationFromFolder = ({
  folderId,
  contentId,
  term,
  onSuccess
}) => (dispatch, getState) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = currentTeamIdSelector(state);
  dispatch({
    [CALL_API]: {
      method: "DELETE",
      service: SERVICES.ASSET,
      types: [
        types.REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST,
        types.REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST_SUCCESS,
        types.REMOVE_ANIMATION_FROM_USER_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content/${contentId}`,
      request: {
        extra: {
          contentId,
          folderId,
          type: "ANIMATION",
          term
        }
      },
      schema: schemas.FOLDER,
      onSuccess
    }
  });
};

export const uploadAnimationToFolder = ({
  folderId,
  imageFile: file,
  onSuccess,
  duration,
  onPresignedUrlCreated = noop,
  noRefetch,
  placeholderURL,
  sortOptions
}) => async (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const assetType = getParameterByName("assetType", window.location);
  const getAnimationFetchType = () => {
    if (assetType) {
      return assetType === SUBTAB_TYPES.ANIMATIONS
        ? ANIMATION_ASSET_TYPE_MAP.ANIMATION
        : ANIMATION_ASSET_TYPE_MAP.VIDEO;
    }
  };

  // synchronously generate a pre signed url for the direct upload
  const preSignedUrl = await generatePreSignedUrlPromise({ file, teamId });
  onPresignedUrlCreated(preSignedUrl.id);

  // synchronously upload the image file to the pre signed url
  await uploadAnimationToPreSignedUrlPromise({
    file,
    preSignedUrl: preSignedUrl.url
  });

  // make update request to the api
  dispatch(
    uploadAnimationToFolderApiCall({
      folderId,
      fileId: preSignedUrl.id,
      filename: preSignedUrl.filename,
      onSuccess,
      duration,
      noRefetch,
      placeholderURL,
      type: getAnimationFetchType(),
      sortOptions
    })
  );

  /**
   * @desc taking a file object and a teamId makes a request for a pre signed image upload url and returns it on resolution
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.teamId - the id string for the current team to upload the image for
   * @returns {Promise} results in a Promise that resolves when the pre signed url is retrieved containing a 'url' key
   */
  function generatePreSignedUrlPromise({ file, teamId }) {
    return new Promise((resolve, reject) => {
      dispatch(
        createUserAnimationInFolderUploadUrl({
          folderId,
          filename: file.name,
          responseContentType: file.type,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }

  /**
   * @desc taking a file and a pre signed url processes the upload of the file to the passed url
   * @param {Object} args
   * @param {Object} args.file - an object that describes the file selected by the user in file picker
   * @param {string} args.preSignedUrl - a pre signed image upload url to use for directly uploading the passed file
   * @returns {Promise} results in a Promise that resolves when the image has been successfully uploaded
   */
  function uploadAnimationToPreSignedUrlPromise({ file, preSignedUrl }) {
    return new Promise((resolve, reject) => {
      dispatch(
        uploadAnimationToPreSignedUrl({
          imageFile: file,
          preSignedUrl: preSignedUrl,
          onSuccess: resolve,
          onFailure: reject
        })
      );
    });
  }
};

/**
 * @desc makes the api request for a pre signed url
 * @param {Object} args
 * @param {string} folderId - the id for the folder to upload the file into
 * @param {string} filename - the name of the file that will be uploaded
 * @param {string} responseContentType - the type extension for the file to be uploaded
 * @param {function} onSuccess - a function that runs when the request succeeds passed the result of the request
 * @param {function} onFailure - a function that runs when the request fails passed the results of the request
 */
export const createUserAnimationInFolderUploadUrl = ({
  folderId,
  filename,
  responseContentType,
  onSuccess,
  onFailure
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);
  const userId = state.currentUser.id;

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET,
      types: [
        types.USER_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST,
        types.USER_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST_SUCCESS,
        types.USER_ANIMATION_IN_FOLDER_UPLOAD_URL_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content/url`,
      request: {
        body: {
          userId,
          teamId,
          folderId,
          filename,
          responseContentType
        }
      },
      onSuccess: onSuccess,
      schema: schemas.NONE
    }
  });
};

/**
 * @desc adds the details for an uploaded image to the api
 * @param {Object} args
 * @param {string} args.folderId - the id for the folder that the image is to be contained in
 * @param {Number} args.duration - the duration of a given GIF
 * @param {function} args.onSuccess - a function which is run when the request succeeds and is passed the results of the request
 */
export const uploadAnimationToFolderApiCall = ({
  folderId,
  duration,
  filename,
  fileId,
  onSuccess = noop,
  noRefetch,
  placeholderURL,
  type,
  sortOptions = {
    sortBy: "updatedAt",
    sortOrder: "desc"
  }
}) => (dispatch, getState) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = currentTeamIdSelector(state);

  dispatch({
    [CALL_API]: {
      method: "POST",
      service: SERVICES.ASSET_V2,
      types: [
        types.UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST,
        types.UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS,
        types.UPLOAD_ANIMATION_TO_USER_TEAM_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content`,
      request: {
        body: {
          userId,
          teamId,
          filename,
          folderId,
          duration,
          id: fileId
        }
      },
      extra: {
        placeholderURL
      },
      schema: schemas.FOLDER,
      onSuccess: response => {
        if (!noRefetch) {
          dispatch(
            fetchUserTeamAnimationsInFolder({
              page: 1,
              folderId,
              type,
              sortBy: sortOptions.sortBy,
              sortOrder: sortOptions.sortOrder
            })
          );
        }
        onSuccess(response);
      }
    }
  });
};
