const creditCardFields = {
  number: {
    label: "Card Number",
    key: "number",
    type: "string"
  },
  expMonth: {
    label: "MM",
    key: "expMonth",
    type: "string"
  },
  expYear: {
    label: "YYYY",
    key: "expYear",
    type: "string"
  },
  cvc: {
    label: "CVC",
    key: "cvc",
    type: "string"
  }
};

const creditCardRows = fields => [
  [fields.number],
  [fields.expMonth, fields.expYear, fields.cvc]
];

export const getMappedShippingRows = ({
  cardDetails,
  onChange,
  onBlur,
  getDisplayFormatFunction
}) => {
  const mappedCreditCardFields = {};
  Object.keys(creditCardFields).forEach(key => {
    mappedCreditCardFields[key] = {
      ...creditCardFields[key],
      value: getDisplayFormatFunction(key)(cardDetails[key].value),
      onChange: ({ key: inputKey, value }) => {
        onChange({ target: { name: inputKey, value } });
      },
      onBlur: () => onBlur(key, false),
      error: !!cardDetails[key].error && {
        description: cardDetails[key].error
      },
      dataPrivate: true
    };
  });
  return creditCardRows(mappedCreditCardFields);
};
