import React from "react";
import BrandKitAnimationOps from "./BrandKitAnimationOps";
import BrandKitSearchInput from "views/components/BrandKit/BrandKitSearch/BrandKitSearchInput";
import BrandKitSearchSpacer from "../BrandKitSearch/BrandKitSearchSpacer";
import ImagesUploadButton from "views/containers/DesignsPageContainer/Workspace/ImagesButtons/ImagesUploadButton";
import style from "./style.module.css";
import TeamAnimationsNewFolderButtonContainer from "views/containers/DesignsPageContainer/Workspace/ImagesButtons/TeamAnimationsNewFolderButtonContainer";
import BrandKitSortButton from "../BrandKitSortButton";

export class BrandKitAnimationsSearch extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnChange = BrandKitAnimationOps.handleOnChange.bind(this);
    this.setQueryString = BrandKitAnimationOps.setQueryString.bind(this);
    this.handleSortUpdate = BrandKitAnimationOps.handleSortUpdate.bind(this);
  }

  render() {
    const {
      isBrandManager,
      qsTerm,
      showUpgradeModal,
      canAccessBrandKit
    } = this.props;

    return (
      <>
        <BrandKitSearchInput
          onChange={value => this.handleOnChange(value)}
          onKeyDown={e => this.setQueryString(e)}
          onClear={e => this.setQueryString(e)}
          placeholder="Search for Brand Videos by name"
          value={qsTerm || ""}
        />

        {isBrandManager && (
          <>
            <BrandKitSortButton
              sortKey={this.props.sortKey}
              onSortUpdate={this.handleSortUpdate}
            />
            <div className={style.addFolderButtonWrapper}>
              <TeamAnimationsNewFolderButtonContainer />
            </div>
            <BrandKitSearchSpacer />
            <div className={style.animationsUploadButtonWrapper}>
              <ImagesUploadButton
                isTeamAnimations
                className={style.brandAnimationsUploadButton}
                showUpgradeModal={showUpgradeModal}
                canAccessBrandKit={canAccessBrandKit}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default BrandKitAnimationsSearch;
