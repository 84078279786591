import React from "react";
import PropTypes from "prop-types";
import { ButtonDropDown } from "views/components";
import DropDownOptions from "./DropDownOptions";

export const TeamCreateDropDown = ({
  direction,
  handleOpenModal,
  isCurrentTeamAtDepthLimit
}) => {
  return (
    <ButtonDropDown label="Team" direction={direction}>
      <DropDownOptions
        handleOpenModal={handleOpenModal}
        isCurrentTeamAtDepthLimit={isCurrentTeamAtDepthLimit}
      />
    </ButtonDropDown>
  );
};

TeamCreateDropDown.displayName = "TeamCreateDropDown";
TeamCreateDropDown.propTypes = {
  handleOpenModal: PropTypes.func,
  direction: PropTypes.string
};

export default TeamCreateDropDown;
