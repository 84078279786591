import React from "react";
import { isValidHex } from "react-color/lib/helpers/color";
import style from "./style.module.css";

import { keyCodes } from "lib";

import { EditableInput } from "react-color/lib/components/common";
import ColorEyeDropperButton from "views/components/Editor/actionbar/buttons/colorPicker/ColorPickerPopoutContent/sections/ColorEyeDropperButton/ColorEyeDropperButton";

export const Fields = ({ onChange, rgb, hsl, hex, isRichText }) => {
  const handleChange = (data, e) => {
    if (data.hex) {
      isValidHex(data.hex) &&
        onChange(
          {
            hex: data.hex,
            source: "hex"
          },
          e
        );
    } else if (data.r || data.g || data.b) {
      onChange(
        {
          r: data.r || rgb.r,
          g: data.g || rgb.g,
          b: data.b || rgb.b,
          a: rgb.a,
          source: "rgb"
        },
        e
      );
    } else if (data.a) {
      if (data.a < 0) {
        data.a = 0;
      } else if (data.a > 100) {
        data.a = 100;
      }

      data.a /= 100;
      onChange(
        {
          h: hsl.h,
          s: hsl.s,
          l: hsl.l,
          a: data.a,
          source: "rgb"
        },
        e
      );
    }
  };

  const handleKeyDown = event => {
    /* We stop propagation to prevent elements to be deleted from canvas */
    if (keyCodes.isBlackListed(event.keyCode)) {
      event.stopPropagation();
    }
  };
  const isEyeDropperAvailable = window.EyeDropper;

  return (
    <div
      onKeyDown={handleKeyDown}
      className={style.fieldsWrapper}
      data-is-disabled={isRichText}
    >
      <div className={style.fieldDouble}>
        <EditableInput
          label="hex"
          value={hex.replace("#", "")}
          onChange={handleChange}
        />
      </div>
      <div className={style.fieldSingle}>
        <EditableInput
          label="r"
          value={rgb.r}
          onChange={handleChange}
          dragLabel="true"
          dragMax="255"
        />
      </div>
      <div className={style.fieldSingle}>
        <EditableInput
          label="g"
          value={rgb.g}
          onChange={handleChange}
          dragLabel="true"
          dragMax="255"
        />
      </div>
      <div className={style.fieldSingle}>
        <EditableInput
          label="b"
          value={rgb.b}
          onChange={handleChange}
          dragLabel="true"
          dragMax="255"
        />
      </div>
      {isEyeDropperAvailable && (
        <div className={style.colorEyeDropperField}>
          <ColorEyeDropperButton
            onChange={onChange}
            className={style.eyeDropperButtonHex}
          />
        </div>
      )}
    </div>
  );
};

export default Fields;
