import { Logger } from "lib";
import { domParser } from "lib/defaultDomParser";
import { getCSSComputedBoundingClientRect } from "lib/htmlElements/dimensions";
import { getSize } from "views/components/Editor/elements/vector/vectorUtils";
import {
  calculateScaledNewDimensions,
  calculateMovedNewDimensions,
  calculateUnrotatedFrame
} from "../CropLayer/photoFrameCropping/PhotoFrameCroppingPreviewCalculations";
import { calculateZoom } from "views/components/Editor/utils";

const EditorPhotoFrameOps = {
  startPhotoFrameCropMode({ selectedItems, context, designData }) {
    Logger.info("Editor.startPhotoInFrameCropMode called");
    const zoom = calculateZoom(designData);
    /* We need to find the gridElement Html */
    const { itemId } = selectedItems[0];

    const vectorHtmlElement = document.getElementById(itemId);

    const photoFrameHtmlElement = vectorHtmlElement.querySelector(
      `#${context.selectedPhotoInFrameId}`
    );

    const parentElement = photoFrameHtmlElement.parentElement;

    const getDimensionSource = () => {
      const clipPath = parentElement.querySelector("clipPath");
      const rect = clipPath.querySelector("rect");
      const circle = clipPath.querySelector("circle");
      const path = clipPath.querySelector("path");
      const ellipse = clipPath.querySelector("ellipse");
      const polygon = clipPath.querySelector("polygon");
      return rect || circle || path || ellipse || polygon;
    };

    const dimensionSource = getDimensionSource();

    const vectorElement = designData.getElement(itemId);

    // ratio is scale factor from original size to current size
    const vectorRatio = vectorElement.width / vectorElement.srcWidth; //<design space>
    const vectorRatioZoomed = vectorRatio * zoom; //<window space>

    const dimensionSourceClientRect = getCSSComputedBoundingClientRect({
      element: dimensionSource,
      cssValueMultiplier: vectorRatioZoomed
    });

    const vectorElementClientRect = vectorHtmlElement.getBoundingClientRect();
    const dimensionSourceOffsetTop =
      dimensionSourceClientRect.top - vectorElementClientRect.top;
    const dimensionSourceOffsetLeft =
      dimensionSourceClientRect.left - vectorElementClientRect.left;

    const photoFrameOffsetRect = {
      width: dimensionSourceClientRect.width,
      height: dimensionSourceClientRect.height,
      top: dimensionSourceOffsetTop,
      left: dimensionSourceOffsetLeft
    };

    const getPlaceholderDimensions = () => {
      const svgDom = domParser.parseFromString(
        window.easil.svgs[vectorElement.src].data,
        "image/svg+xml"
      );

      const image = svgDom.querySelector(
        `#${context.selectedPhotoInFrameId} > image`
      );

      return getSize(image);
    };

    return {
      context: {
        ...context,
        isCroppingPhotoFrame: true,
        vectorHtmlElement,
        photoFrameOffsetRect,
        placeholderDimensions: getPlaceholderDimensions()
      }
    };
  },

  finishPhotoFrameCropMoving(props) {
    const {
      zoom,
      selectedItems,
      designData,
      domId,
      differenceFromInitialOffset
    } = props;

    const vectorElement = designData.elements[selectedItems[0].itemId];

    const vectorElementWithPreview = {
      ...vectorElement,
      ...selectedItems[0].preview
    };

    const imageInstructions = vectorElementWithPreview.imageInstructions.filter(
      imageInstruction => imageInstruction.domId === domId
    );
    const photoFrame = imageInstructions[0];

    const {
      frameOffsetTop,
      frameOffsetLeft,
      frameWindowHeight,
      frameWindowWidth
    } = calculateUnrotatedFrame({ vectorElement, zoom, photoFrame });

    const newPhotoInFrameDimensions = calculateMovedNewDimensions({
      vectorElement,
      photoFrame,
      differenceFromInitialOffset,
      zoom,
      frameOffsetTop,
      frameOffsetLeft,
      frameWindowHeight,
      frameWindowWidth
    });

    const photoFrameUpdated = {
      ...photoFrame,
      ...photoFrame,
      top: newPhotoInFrameDimensions.top, // / (vectorRatio * zoom),
      left: newPhotoInFrameDimensions.left, // / (vectorRatio * zoom),
      height: newPhotoInFrameDimensions.height, // / (vectorRatio * zoom),
      width: newPhotoInFrameDimensions.width, // / (vectorRatio * zoom),
      scale: 1
    };

    // replace without removing unchanged ones
    const updatedImageInstructions = vectorElement.imageInstructions.map(
      currentInstruction => {
        if (currentInstruction.domId === photoFrameUpdated.domId) {
          return photoFrameUpdated;
        }
        return currentInstruction;
      }
    );

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          imageInstructions: updatedImageInstructions
        }
      }
    ];

    return {
      isCropping: false,
      selectedItems: selectedItemUpdated
    };
  },

  startPhotoFrameCropResizing(args) {
    const { selectedItems, context, zoom, designData, canvasBackground } = args;
    /* Get the photo frame offset rect when resizing starts */
    /* we disable the scrolling while resizing */
    canvasBackground.style.overflowX = "hidden";
    /* We need to find the gridElement Html */
    const { itemId } = selectedItems[0];

    const vectorHtmlElement = document.getElementById(itemId);

    const photoFrameHtmlElement = vectorHtmlElement.querySelector(
      `#${context.selectedPhotoInFrameId}`
    );

    const parentElement = photoFrameHtmlElement.parentElement;

    const getDimensionSource = () => {
      const clipPath = parentElement.querySelector("clipPath");
      const rect = clipPath.querySelector("rect");
      const circle = clipPath.querySelector("circle");
      const path = clipPath.querySelector("path");
      const ellipse = clipPath.querySelector("ellipse");
      const polygon = clipPath.querySelector("polygon");
      return rect || circle || path || ellipse || polygon;
    };

    const dimensionSource = getDimensionSource();

    const vectorElement = designData.getElement(itemId);

    // ratio is scale factor from original size to current size
    const vectorRatio = vectorElement.width / vectorElement.srcWidth; //<design space>
    const vectorRatioZoomed = vectorRatio * zoom; //<window space>

    const dimensionSourceClientRect = getCSSComputedBoundingClientRect({
      element: dimensionSource,
      cssValueMultiplier: vectorRatioZoomed
    });

    const vectorElementClientRect = vectorHtmlElement.getBoundingClientRect();
    const dimensionSourceOffsetTop =
      dimensionSourceClientRect.top - vectorElementClientRect.top;
    const dimensionSourceOffsetLeft =
      dimensionSourceClientRect.left - vectorElementClientRect.left;

    const photoFrameOffsetRect = {
      width: dimensionSourceClientRect.width,
      height: dimensionSourceClientRect.height,
      top: dimensionSourceOffsetTop,
      left: dimensionSourceOffsetLeft
    };

    /* add frame offset rect to state */
    return {
      isCropping: true,
      photoFrameOffsetRect
    };
  },

  finishPhotoFrameCropResizing({ containerProps, newImageArguments }) {
    const { zoom, selectedItems, vectorElement, photoFrame } = containerProps;
    const {
      pageOffset,
      handlerInitialPosition,
      anchorPoint,
      differenceFromInitialOffset,
      originPointUnrotated,
      anchorPointUnrotated,
      scaleAnchorPoint,
      position,
      selectionBoxNodeCenter
    } = newImageArguments;

    const {
      frameOffsetTop,
      frameOffsetLeft,
      frameWindowHeight,
      frameWindowWidth
    } = calculateUnrotatedFrame({ vectorElement, zoom, photoFrame });

    const newPhotoInFrameDimensions = calculateScaledNewDimensions({
      vectorElement,
      photoFrame,
      differenceFromInitialOffset,
      zoom,
      dragItem: {
        anchorPoint,
        handlerInitialPosition,
        scaleAnchorPoint,
        position,
        selectionBoxNodeCenter
      },
      pageOffset,
      frameOffsetTop,
      frameOffsetLeft,
      frameWindowHeight,
      frameWindowWidth,
      originPointUnrotated,
      anchorPointUnrotated
    });

    const vectorRatio = vectorElement.width / vectorElement.srcWidth;

    const photoFrameUpdated = {
      ...photoFrame,
      top: newPhotoInFrameDimensions.top, //  / (vectorRatio * zoom),
      left: newPhotoInFrameDimensions.left, // / (vectorRatio * zoom),
      height: newPhotoInFrameDimensions.height, // / (vectorRatio * zoom),
      width: newPhotoInFrameDimensions.width // / (vectorRatio * zoom)
    };

    // replace without removing unchanged ones
    const updatedImageInstructions = vectorElement.imageInstructions.map(
      currentInstruction => {
        if (currentInstruction.domId === photoFrameUpdated.domId) {
          return photoFrameUpdated;
        }
        return currentInstruction;
      }
    );

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        scale: vectorRatio,
        preview: {
          imageInstructions: updatedImageInstructions
        }
      }
    ];

    return {
      isCropping: false,
      selectedItems: selectedItemUpdated
    };
  },

  getVectorElementWithPreview({
    designData,
    selectedItems,
    selectedPhotoInFrameId
  }) {
    const selectedItem = selectedItems[0];
    const photoFrameId = selectedItem.itemId;
    let vectorElement = designData.elements[photoFrameId];

    vectorElement = {
      ...vectorElement,
      ...selectedItems[0].preview
    };

    const imageInstructions = vectorElement.imageInstructions.filter(
      imageInstruction => imageInstruction.domId === selectedPhotoInFrameId
    );
    const photoFrame = imageInstructions[0];

    return {
      vectorElement,
      photoFrame
    };
  }
};

export default EditorPhotoFrameOps;
