/**
 * @desc takes an object of css attributes and produces a css style string
 * @param {object} cssAttributes - css attributes object to convert to a styling string
 * @returns {string} a css string
 */
export const cssAttributesToCssString = cssAttributes => {
  let cssString = "";

  for (var attribute in cssAttributes) {
    cssString += `${attribute}: ${cssAttributes[attribute]}; `;
  }

  return cssString.trim();
};
