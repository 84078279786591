import { Logger, isEmpty } from "lib";
import { SIDEBAR_TABS_MAP, EDITOR_SIDEBAR_TABS } from "lib/constants";

const EditorMiscellaneousOps = {
  isSelectionDeletable() {
    Logger.info("EditorMiscellaneousOps.isSelectionDeletable called");

    const { designData, selectedItems } = this.props;

    if (isEmpty(selectedItems)) {
      return false;
    }

    return selectedItems.every(selectedItem => {
      const element = designData.elements[selectedItem.itemId];

      return !(element && element.restrictions.includes("removable"));
    });
  },

  getSelectedTab(props) {
    let {
      sidebarLocation: { tab }
    } = props;
    const DEFAULT_TAB = SIDEBAR_TABS_MAP.RESIZE;
    const tabKeys = [
      ...Object.values(EDITOR_SIDEBAR_TABS).map(option => option.name),
      SIDEBAR_TABS_MAP.DOWNLOAD,
      SIDEBAR_TABS_MAP.ORDER_CHECK
    ];
    if (!tab || !tabKeys.includes(tab)) {
      tab = DEFAULT_TAB;
    }

    if (
      props.areFunctionalitiesLocked &&
      ![SIDEBAR_TABS_MAP.DOWNLOAD, SIDEBAR_TABS_MAP.ORDER_CHECK].includes(tab)
    ) {
      tab = DEFAULT_TAB;
    }

    if (tab === SIDEBAR_TABS_MAP.DOWNLOAD && props.areHeadersButtonsLocked) {
      tab = DEFAULT_TAB;
    }

    return tab;
  }
};

export default EditorMiscellaneousOps;
