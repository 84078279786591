import * as types from "./purchasedCatalogueSizesTypes";

export const initState = {
  folders: {}
};

const purchasedCatalogueSizesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.PURCHASED_CATALOGUE_SIZES_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return state;
      }

      return {
        ...state,
        ...action.response.entities.catalogueSize
      };
    }

    case types.PURCHASED_CATALOGUE_SIZES_FILTER_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return state;
      }

      const {
        response: {
          entities: { catalogueSize }
        }
      } = action;

      return {
        ...catalogueSize
      };
    }

    default:
      return state;
  }
};

export default purchasedCatalogueSizesReducer;
