export const ADD_TEAMS_FAILURE = "API/COLLECTION_ALLOCATIONS/ADD_TEAMS_FAILURE";
export const ADD_TEAMS_REQUEST = "API/COLLECTION_ALLOCATIONS/ADD_TEAMS_REQUEST";
export const ADD_TEAMS_SUCCESS = "API/COLLECTION_ALLOCATIONS/ADD_TEAMS_SUCCESS";

export const LIST_TEAMS_FAILURE =
  "API/COLLECTION_ALLOCATIONS/LIST_TEAMS_FAILURE";
export const LIST_TEAMS_REQUEST =
  "API/COLLECTION_ALLOCATIONS/LIST_TEAMS_REQUEST";
export const LIST_TEAMS_SUCCESS =
  "API/COLLECTION_ALLOCATIONS/LIST_TEAMS_SUCCESS";

export const REMOVE_TEAM_FAILURE =
  "API/COLLECTION_ALLOCATIONS/REMOVE_TEAM_FAILURE";
export const REMOVE_TEAM_REQUEST =
  "API/COLLECTION_ALLOCATIONS/REMOVE_TEAM_REQUEST";
export const REMOVE_TEAM_SUCCESS =
  "API/COLLECTION_ALLOCATIONS/REMOVE_TEAM_SUCCESS";
