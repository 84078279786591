import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ResizeIconDetailed = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 18}
    height={height || size || 18}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-3h24v24H-3z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M3 18a3 3 0 01-3-3V3a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-2.824 2.995L15 18H3zM15 2H3a1 1 0 00-1 1v12a1 1 0 001 1h5v-1.5a1 1 0 011.993-.117L10 14.5V16h5a1 1 0 00.993-.883L16 15v-5h-1.5a1 1 0 01-.993-.883L13.5 9a1 1 0 01.883-.993L14.5 8H16V3a1 1 0 00-1-1zm-3.5 6a1 1 0 010 2l-.404-.005c-.52.052-1.014.497-1.087.922l-.01.098.001.485a1 1 0 01-1.993.117L8 11.492v-.507c.022-1.464 1.272-2.727 2.722-2.957L10.947 8h.553z"
      />
    </g>
  </svg>
);
ResizeIconDetailed.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ResizeIconDetailed;
