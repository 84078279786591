import React from "react";
import PropTypes from "prop-types";
import { Popout, Button } from "../../components";
import PlusIcon from "views/components/icons/PlusIcon";
import tabStyle from "views/components/DesignsPageSubHeader/style.module.css";
import subheaderStyle from "views/containers/subHeader/style.module.css";

import style from "./style.module.css";

class FolderPopout extends React.Component {
  constructor(props) {
    super(props);

    this.popoutRef = React.createRef();

    this.state = {
      popoutCoords: {
        top: 0,
        left: 0
      }
    };

    this.positionPopout = this.positionPopout.bind(this);
  }

  componentDidMount() {
    this.positionPopout();
    window.addEventListener("resize", this.positionPopout);
    window.addEventListener("scroll", this.positionPopout, true);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.positionPopout);
    window.removeEventListener("scroll", this.positionPopout, true);
  }

  positionPopout() {
    const tabElement = document.getElementsByClassName(
      tabStyle.foldersTabTitle
    )[0];

    if (!tabElement) {
      return;
    }

    const tabElementBoundingRect = tabElement.getBoundingClientRect();

    const subHeaderWrapper = document.getElementsByClassName(
      subheaderStyle.subHeaderWrapper
    )[0];
    const subHeaderWrapperBoundingRect = subHeaderWrapper.getBoundingClientRect();

    const popoutElement = this.popoutRef.current.parentElement;
    const popoutElementBoundingRect = popoutElement.getBoundingClientRect();

    const top = tabElementBoundingRect.y + 40;
    let left = tabElementBoundingRect.x + 25;

    const popoutWidth = popoutElementBoundingRect.width;

    /* avoid it going out of the wrapper bounderies on the right side */
    if (left + popoutWidth > subHeaderWrapperBoundingRect.right) {
      left += subHeaderWrapperBoundingRect.right - (left + popoutWidth);
    }
    /* avoid it going out of the wrapper bounderies on the left side */
    if (left < subHeaderWrapperBoundingRect.left) {
      left += subHeaderWrapperBoundingRect.left - left;
    }

    this.setState({
      popoutCoords: {
        top: top,
        left: left
      }
    });
  }

  render() {
    const {
      folders,
      onClose,
      onCreateFolderClick,
      onFolderOptionClick,
      title = "My Folders",
      canCreateNewFolder
    } = this.props;

    let options;
    if (folders && folders.length) {
      options = folders.map(folder => ({
        key: folder.id,
        label: folder.name
      }));
    } else {
      options = [
        {
          key: null,
          label: "No Folders",
          isNotClickable: true
        }
      ];
    }
    return (
      <Popout
        className={style.popout}
        onClose={onClose}
        maxHeight="500px"
        width={400}
        top={this.state.popoutCoords.top}
        left={this.state.popoutCoords.left}
        offsetDirection="left"
      >
        <div
          className={style.headerWrapper}
          id="folder-popout-header"
          data-testid="folderPopoutHeader"
          ref={this.popoutRef}
        >
          <span className={style.headerTitle} id="folder-popout-title">
            {title}
          </span>
          {canCreateNewFolder && (
            <Button
              onClick={onCreateFolderClick}
              theme="NoBackgroundNoBorder"
              className={style.headerButton}
              id="folder-create-button"
            >
              <div className={style.iconBoundingBox}>
                <PlusIcon color="#2C6FCF" size="14" title="Add Folder" />
              </div>
              <span>New Folder</span>
            </Button>
          )}
        </div>
        <div
          className={style.selectionItemWrapper}
          id="folder-list"
          data-testid="folderList"
        >
          {options &&
            options.map(option => (
              <div
                className={style.selectionItem}
                data-no-click={option.isNotClickable}
                key={option.key}
                onClick={() => onFolderOptionClick(option.key)}
                id="folder-option"
                data-testid="folderOption"
              >
                {option.label}
              </div>
            ))}
        </div>
      </Popout>
    );
  }
}

FolderPopout.propTypes = {
  folders: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
};

export default FolderPopout;
