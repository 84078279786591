import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FileArrowLeftIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 18}
    height={height || size || 20}
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-2h24v24H-3z" />
      <g fill={color || easilGrayXDark} fillRule="nonzero">
        <path d="M3 0a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V8.437a3 3 0 0 0-.947-2.187L11.262.813A3 3 0 0 0 9.208 0H3zm6.893 2.27l5.791 5.438a1 1 0 0 1 .316.73V17a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h6.208a1 1 0 0 1 .685.27z" />
        <path d="M7.414 9h5.336c.69 0 1.25.448 1.25 1s-.56 1-1.25 1H7.414l1.293 1.293a1 1 0 0 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 1.414L7.414 9z" />
      </g>
    </g>
  </svg>
);
FileArrowLeftIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default FileArrowLeftIcon;
