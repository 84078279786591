import React from "react";

export default function LogosAltIcon({ color = "#2C2E2F", ...props }) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill={color} fillRule="nonzero">
          <path d="M11.33 7.356a1 1 0 011.879.678l-.04.11-2.403 5.606h3.484a1 1 0 01.993.883l.007.117a1 1 0 01-.883.993l-.117.007h-5a1.001 1.001 0 01-.96-1.283l.04-.11 3-7z" />
          <path d="M10.5 1.404l-7 4A3 3 0 002 7.999V16a3.001 3.001 0 001.5 2.596l7.004 4.002a2.997 2.997 0 002.996-.002l6.996-3.998A3 3 0 0022 16.001V8a3.001 3.001 0 00-1.5-2.596l-7.004-4.002a2.997 2.997 0 00-2.996.002zM4.401 7.201l.095-.063 7-4c.313-.18.695-.18 1.004-.002l7.004 4.002a.998.998 0 01.496.863V16c0 .356-.19.686-.5.864l-6.996 3.998c-.313.18-.695.18-1.004.002l-7.004-4.002A.998.998 0 014 15.999V8a1 1 0 01.401-.8z" />
        </g>
      </g>
    </svg>
  );
}
