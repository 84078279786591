import React, { Component } from "react";
import ReactDOM from "react-dom";
import { noop, keyCodes, isNil } from "lib";
import { getCurrentRangeValues, setRangeForTarget } from "lib/selectionUtils";
import {
  sanitizeHTMLForTextUsingDOMNodes,
  DOM_NODE_TYPES
} from "lib/textUtils";
import {
  getNearestContentEditableParentFromSelection,
  toggleBoldOnSelection,
  indentSelection,
  toggleItalicOnSelection,
  toggleUnderlineOnSelection,
  splitListItem,
  getRange
} from "lib/DOMNodeUtils";
import { nestListInPreviousSibling } from "lib/richText/IndentationUtils";
import style from "./style.module.css";
import PATHS from "routes/paths";
import { handleKeydownForParagraphSpacing } from "./contentEditableUtils";
import { isRichTextColorChange } from "lib/htmlStrings";
import { saveCurrentRange, applySavedSelection } from "lib/DOMNodeUtils";

class UncontrolledContentEditable extends Component {
  static defaultProps = {
    onMouseEnter: noop,
    onMouseLeave: noop,
    onClick: noop,
    onDoubleClick: noop,
    onKeyDown: noop
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.forceUpdateHTML = this.forceUpdateHTML.bind(this);
    this.replaceEnterWithLineBreak = this.replaceEnterWithLineBreak.bind(this);
    this.handleObserverTrigger = this.handleObserverTrigger.bind(this);

    this.ref = React.createRef();

    this.state = {
      initialText: this.props.text
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.editable && !this.props.editable) {
      this.setState({
        initialText: nextProps.text
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!nextProps.editable && this.props.editable) {
      ReactDOM.findDOMNode(this).innerHTML = this.state.initialText;
    }
  }

  replaceEnterWithLineBreak(event) {
    if (event.key === "Enter" && !this.props.isAutoCompleteOpen) {
      // conditionally add new list item when in a li
      const range = getRange();

      const anchorParent = range.startContainer.parentNode;
      if (
        !event.shiftKey &&
        range.startOffset === range.endOffset &&
        range.startContainer.nodeName === "#text" &&
        anchorParent.nodeName === "LI"
      ) {
        // range is closed and we are in a text node and the direct parent is a list item
        splitListItem(range.startContainer, anchorParent, range.startOffset);
      } else {
        document.execCommand("insertLineBreak");
      }

      event.stopPropagation();
      event.preventDefault();
      return;
    }

    handleKeydownForParagraphSpacing({
      event,
      updateParagraphSpacing: this.props.updateParagraphSpacing
    });
  }

  handleObserverTrigger(e) {
    if (
      e[0].type === "childList" &&
      e[0].addedNodes &&
      e[0].removedNodes &&
      e[0].addedNodes.length &&
      e[0].removedNodes.length &&
      e[0].addedNodes.length === e[0].removedNodes.length &&
      [...e[0].addedNodes].every((node, index) =>
        node.isEqualNode(e[0].removedNodes[index])
      )
    ) {
      // if the change is a childList change where removed nodes were also added then ignore it
      return;
    }
    this.handleChange({
      target: this.ref.current,
      stopPropagation: noop,
      preventDefault: noop
    });
  }

  componentDidMount() {
    this.ref.current.addEventListener(
      "keydown",
      this.replaceEnterWithLineBreak
    );

    if (this.props.isObservable) {
      const config = {
        attributes: true,
        attributeOldValue: true,
        childList: true,
        subtree: true,
        characterData: true
      };

      // Create an observer instance
      this.observer = new MutationObserver(this.handleObserverTrigger);

      // Start observation
      this.observer.observe(this.ref.current, config);
    }
  }

  componentWillUnmount() {
    this.ref.current.removeEventListener(
      "keydown",
      this.replaceEnterWithLineBreak
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.editable && this.props.editable) {
      this.ref.current.focus();
      if (this.props.selectAll) {
        const selection = window.getSelection();
        const range = document.createRange();

        range.selectNodeContents(this.ref.current);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }

    if (this.observer && !this.props.isObservable && prevProps.isObservable) {
      // isObservable is no longer true so we can remove the observer safely
      this.observer.disconnect();
      delete this.observer;
    }

    if (!this.observer && this.props.isObservable) {
      const config = {
        attributes: true,
        attributeOldValue: true,
        childList: true,
        subtree: true
      };

      // Create an observer instance
      this.observer = new MutationObserver(this.handleObserverTrigger);

      // Start observation
      this.observer.observe(this.ref.current, config);
    }

    if (
      prevProps.text !== this.props.text &&
      this.props.isObservable &&
      this.props.text !== this.ref.current.innerHTML
    ) {
      if (isRichTextColorChange(prevProps.text, this.props.text)) {
        // force the update since it is a color change
        saveCurrentRange();
        this.ref.current.innerHTML = this.props.text;
        applySavedSelection();
      }
    }
  }

  onKeyUp(event) {
    /* We stop propagation to prevent elements to be deleted from canvas */
    if (keyCodes.isBlackListed(event.keyCode)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  handlePaste(event) {
    event.preventDefault();
    event.stopPropagation();

    const data = event.nativeEvent.clipboardData.getData("text/plain");

    // get current selection range
    const { rangeStart, rangeEnd, rangeTargetIndex } = getCurrentRangeValues(
      this.ref.current
    );

    this.insertTextAtCursor(data);

    this.props.onChange(this.ref.current.innerHTML, event, value =>
      this.forceUpdateHTML(value, rangeStart, rangeEnd, rangeTargetIndex)
    );
  }

  insertTextAtCursor(text) {
    // convert the text to a html suitable format
    const insertableText = text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    // clear any current data in the selection (overwrite)
    range.deleteContents();
    // build a node to insert into the textbox
    const node = document.createElement("span");
    node.innerHTML = sanitizeHTMLForTextUsingDOMNodes(insertableText);
    // insert the text
    range.insertNode(node);
    // set the cursor to the end of the inserted text
    selection.collapseToEnd();
  }

  handleTextTab(node) {
    // check if the node is a list item with a previous sibling being a list item
    const range = getRange();

    if (
      range.startOffset === range.endOffset &&
      range.startContainer.nodeName === "#text"
    ) {
      // range is closed and we are in a text node
      const anchorParent = range.startContainer.parentNode;
      if (anchorParent.nodeName === "LI") {
        if (
          anchorParent.previousSibling &&
          anchorParent.previousSibling.nodeName === "LI"
        ) {
          // the direct parent is a list item with a list item as its previous sibling
          // splitListItem(range.startContainer, anchorParent, range.startOffset);
          return nestListInPreviousSibling(range.startContainer, anchorParent);
        } else {
          // we don't want to do anything if it is a list item already but we have no previous list items
          return;
        }
      }
    }
    return indentSelection(node);
  }

  render() {
    let html = this.props.editable ? this.state.initialText : this.props.text;

    const isRenderer = PATHS.isRenderer(window.location.pathname);

    return (
      <div
        style={this.props.style}
        className={`${style.contentEditable} ${this.props.className} ${
          isRenderer ? "" : style.textCursorAnchor
        }`}
        id={`UCE-${this.props.elementId}`}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onMouseUp={this.props.onMouseUp || noop}
        onClick={this.props.onClick}
        onKeyUp={this.onKeyUp}
        onDoubleClick={this.props.onDoubleClick}
        ref={this.ref}
        onInput={this.handleChange}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        contentEditable={this.props.editable}
        draggable={!this.props.editable}
        onPaste={this.handlePaste}
        dangerouslySetInnerHTML={{ __html: html }}
        data-testid="UncontrolledContentEditable"
      />
    );
  }

  /**
   * @desc takes a keydown event and applies the standard text manipulation hotkeys
   * @param {object} event - a keydown event object
   */
  applyStyleChanges(event) {
    const { disableInlineStyling, richTextRestrictions = {} } = this.props;

    const isMetaOrCtrl = event.ctrlKey || event.metaKey;
    // const isShift = event.shiftKey;

    switch (event.keyCode) {
      case keyCodes.uKey: {
        if (isMetaOrCtrl) {
          //ctrl/cmd-u
          event.preventDefault();
          if (disableInlineStyling || richTextRestrictions.underline) return;
          const node = getNearestContentEditableParentFromSelection();

          toggleUnderlineOnSelection(node);
        }
        break;
      }
      case keyCodes.iKey: {
        if (isMetaOrCtrl) {
          //ctrl/cmd-i
          event.preventDefault();
          if (disableInlineStyling || richTextRestrictions.italic) return;
          const node = getNearestContentEditableParentFromSelection();

          toggleItalicOnSelection(node);
        }
        break;
      }
      case keyCodes.bKey: {
        if (isMetaOrCtrl) {
          //ctrl/cmd-b
          event.preventDefault();
          if (disableInlineStyling || richTextRestrictions.bold) return;
          const node = getNearestContentEditableParentFromSelection();

          toggleBoldOnSelection(node);
        }
        break;
      }
      // case keyCodes.tabKey: {
      //   if (isShift) {
      //     //ctrl/cmd-tab
      //     event.preventDefault();
      //     if (disableInlineStyling || richTextRestrictions.bold) return;
      //     const node = getNearestContentEditableParentFromSelection();

      //     unIndentSelection(node);
      //     break;
      //   }
      //   //tab
      //   event.preventDefault();
      //   if (disableInlineStyling || richTextRestrictions.bold) return;
      //   const node = getNearestContentEditableParentFromSelection();

      //   this.handleTextTab(node);
      //   break;
      // }
      // case keyCodes.lKey: {
      //   if (isMetaOrCtrl) {
      //     //ctrl/cmd-l
      //     event.preventDefault();
      //     if (disableInlineStyling) return;
      //     const node = getNearestContentEditableParentFromSelection();

      //     // toggleColorOnSelection(node, color);
      //     if (window.easil.testSaveRange) {
      //       setCurrentSelectionPosition(window.easil.testSaveRange, node);
      //     }
      //   }
      //   break;
      // }
      // case keyCodes.kKey: {
      //   if (isMetaOrCtrl) {
      //     //ctrl/cmd-l
      //     event.preventDefault();
      //     if (disableInlineStyling) return;
      //     const node = getNearestContentEditableParentFromSelection();
      //   }
      //   break;
      // }
      default:
        break;
    }
  }

  handleKeyDown(event) {
    event.stopPropagation();

    /* handle any default text style modifications */
    this.applyStyleChanges(event);

    this.props.onKeyDown(event);
  }

  handleBlur(event) {
    if (this.props.shouldNotBlur) return;
    this.props.onBlur(event.target.innerHTML, event);
  }

  forceUpdateHTML(value, rangeStart, rangeEnd, rangeTargetIndex = 0) {
    let _rangeStart = rangeStart;
    let _rangeEnd = rangeEnd;
    let _rangeTargetIndex = rangeTargetIndex;
    const target = this.ref.current;

    if (isNil(_rangeStart) || isNil(!_rangeEnd)) {
      // get current selection range
      const currentRangeValues = getCurrentRangeValues(target);
      _rangeTargetIndex = currentRangeValues.rangeTargetIndex;
      _rangeStart = currentRangeValues.rangeStart;
      _rangeEnd = currentRangeValues.rangeEnd;
    }

    // update innerHTML
    target.innerHTML = value;

    const targetChildNodes = Array.from(target.childNodes);
    let rangeTargetNode = targetChildNodes[_rangeTargetIndex];

    // compensate for if a range target is in a new node that has been stripped
    if (targetChildNodes.length - 1 < _rangeTargetIndex) {
      const filteredTargetChildNodes = targetChildNodes.filter(
        node => node.nodeName === DOM_NODE_TYPES.text
      );
      rangeTargetNode =
        filteredTargetChildNodes[filteredTargetChildNodes.length - 1];
      if (isNil(rangeTargetNode)) {
        // in the case the selection is then empty we want to escape
        return;
      }
      _rangeStart = rangeTargetNode.data.length;
      _rangeEnd = rangeTargetNode.data.length;
    }

    // set the new range on the rangeTarget child which should be a text node
    setRangeForTarget({
      target: rangeTargetNode,
      startOffset: _rangeStart,
      endOffset: _rangeEnd
    });
  }

  handleChange(event) {
    if (!event.target) {
      return;
    }
    if (!event.target?.textContent.trim().length) {
      event.target.innerHTML = "";
    }

    const { rangeStart, rangeEnd, rangeTargetIndex } = getCurrentRangeValues(
      this.ref.current
    );

    this.props.onChange(event.target.innerHTML, event, value =>
      this.forceUpdateHTML(
        value,
        rangeStart - 1,
        rangeEnd - 1,
        rangeTargetIndex
      )
    );
  }
}

export default UncontrolledContentEditable;
