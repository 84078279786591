import * as types from "state/entities/publicFonts/publicFontsTypes";
import { omit, isEmpty } from "lib";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 100,
  isCssProcessing: false
};

export const handlePublicFontRequest = ({ action, state }) => {
  const { page } = action.request;
  return {
    ...state,
    pages: {
      ...state.pages,
      [page]: {
        ...state.pages[page],
        isFetching: true
      }
    }
  };
};

export const handlePublicFontRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      pages: omit(state.pages, action.request.page)
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    pages: { ...state.pages, ...updatedPage }
  };
};

const publicFontsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.PUBLIC_FONTS_REQUEST: {
      return handlePublicFontRequest({ state, action });
    }

    case types.PUBLIC_FONTS_REQUEST_SUCCESS: {
      return handlePublicFontRequestSuccess({ state, action });
    }

    case types.PUBLIC_FONTS_CSS_PROCESSING: {
      return {
        ...state,
        isCssProcessing: action.isProcessing
      };
    }

    default:
      return state;
  }
};

export default publicFontsApiReducers;
