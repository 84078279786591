import * as types from "./teamSmartImagesTypes";
import { merge, omit } from "lib";

export const initState = {};

const teamSmartImagesReducers = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_SMART_IMAGES_REQUEST_SUCCESS: {
      const { entities = {} } = action.response;
      return merge({}, state, entities.teamSmartImage);
    }

    case types.TEAM_SMART_IMAGE_UPDATE_REQUEST_SUCCESS: {
      return {
        ...state,
        [action.request.body.mediaId]:
          action.response.entities.userTeamSmartImage[
            action.request.body.mediaId
          ]
      };
    }

    case types.TEAM_SMART_IMAGE_UPLOAD_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.response.entities.teamSmartImage
      };
    }

    case types.TEAM_SMART_IMAGE_DELETE_REQUEST_SUCCESS: {
      return omit(state, action.extra.mediaId);
    }

    default:
      return state;
  }
};

export default teamSmartImagesReducers;
