import React from "react";
import style from "./style.module.css";
import { pick } from "lib/lodash";
import { MaskedImageDraggable } from "./MaskedImage";
import ImageCroppingHandler from "./ImageCroppingHandler";
import { getElementComponent } from "views/components/Editor/elements/elementsComponentsMap";
import ResizeBox from "views/components/Editor/ResizeBox";

const ImageCropping = ({
  canvasBackground,
  finishImageCropMoving,
  imageElement,
  isCropping,
  pageId,
  pageOffset,
  startImageCropMoving,
  zoom,
  startCropMaskResize,
  finishCropMaskResize
}) => {
  const cancelClick = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const getWrapperStyle = () => {
    return {
      height: canvasBackground.offsetHeight,
      width: canvasBackground.offsetWidth,
      overflow: "hidden",
      position: "absolute",
      top: 0,
      left: 0
    };
  };

  const getGrayOverlayStyle = () => {
    return {
      height: canvasBackground.offsetHeight,
      width: canvasBackground.offsetWidth,
      overflow: "hidden",
      opacity: isCropping ? 0 : 0.5
    };
  };

  const getPageStyle = () => {
    return {
      position: "absolute",
      top: pageOffset.top,
      left: pageOffset.left,
      zIndex: 4
      // opacity: isCropping ? 0 : 1
    };
  };

  const getElementStyle = () => {
    const translateX = imageElement.left * zoom;
    const translateY = imageElement.top * zoom;

    const transform = [
      `translate(${translateX}px, ${translateY}px)`,
      `scale(${zoom})`
    ].join(" ");

    return {
      pointerEvents: "none",
      position: "absolute",
      width: 0,
      height: 0,
      zIndex: 4,
      opacity: isCropping ? 0 : 1,
      transform: transform,
      transformOrigin: "0 0"
    };
  };

  const getRotationStyle = () => {
    return {
      transform: [
        `rotate(${imageElement.angle}deg)`,
        `scaleX(${imageElement.scaleX})`,
        `scaleY(${imageElement.scaleY})`
      ].join(" ")
    };
  };

  const { ElementComponent, componentProps } = getElementComponent("image");

  const elementData = pick(imageElement, componentProps);

  const getElementCropStyle = () => {
    const translateX = imageElement.left * zoom;
    const translateY = imageElement.top * zoom;

    const transform = `translate(${translateX}px, ${translateY}px) rotate(${imageElement.angle}deg)`;

    return {
      position: "absolute",
      width: `${imageElement.width * zoom}px`,
      height: `${imageElement.height * zoom}px`,
      transform,
      pointerEvents: "none",
      zIndex: 4,
      opacity: isCropping ? 0 : 1
    };
  };

  const Handler = props => {
    return (
      <ImageCroppingHandler
        imageElement={imageElement}
        startCropMaskResize={startCropMaskResize}
        finishCropMaskResize={finishCropMaskResize}
        pageId={pageId}
        {...props}
      />
    );
  };

  return (
    <div onClick={cancelClick} id="ImageCroppingDiv" style={getWrapperStyle()}>
      <div className={style.imageCropping} style={getGrayOverlayStyle()} />
      <div style={getPageStyle()}>
        <MaskedImageDraggable
          finishImageCropMoving={finishImageCropMoving}
          imageElement={imageElement}
          isCropping={isCropping}
          pageOffset={pageOffset}
          startImageCropMoving={startImageCropMoving}
          zoom={zoom}
        />
        <div style={getElementStyle()} id="ImageCroppingElementDiv">
          <div>
            <ElementComponent
              zoom={zoom}
              style={getRotationStyle()}
              key={imageElement.uniqueId}
              elementId={imageElement.uniqueId}
              elementData={elementData}
            />
          </div>
        </div>
        <div style={getElementCropStyle()} id="ImageCroppingMaskResizeBox">
          <ResizeBox Handler={Handler} pageId={pageId} isCropping={true} />
        </div>
      </div>
    </div>
  );
};

export default ImageCropping;
