import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";
export const cartSelector = state => state.ui.cart;
export const currentOrderIdSelector = state => cartSelector(state).orderId;
export const currentOrderLoadingStateSelector = state =>
  cartSelector(state).loading;
export const currentBillingSelector = state => cartSelector(state).billing;
export const currentShippingSelector = state => cartSelector(state).shipping;
export const cartErrorsSelector = state => cartSelector(state).errors;
export const isBillingAndShippingValid = state => {
  const subscription = currentSubscriptionSelector(state) || {};
  const billing = currentBillingSelector(state);
  const shipping = currentShippingSelector(state);

  return (
    // account orders wont need billing details
    (subscription.isAccount ||
      // when not account then we need billing
      (billing && billing.isValid)) &&
    shipping &&
    shipping.isValid
  );
};
export const cartTokenSelector = state => cartSelector(state).token;
export const cartPaymentTypeSelector = state => cartSelector(state).paymentType;
export const paypalOrderIdSelector = state => cartSelector(state).paypalOrderId;
