export const EDITOR_ANIMATION_SEARCH_REQUEST =
  "API/MEDIA/ANIMATION_SEARCH_REQUEST";
export const EDITOR_ANIMATION_SEARCH_SUCCESS =
  "API/MEDIA/ANIMATION_SEARCH_SUCCESS";
export const EDITOR_ANIMATION_SEARCH_FAILURE =
  "API/MEDIA/ANIMATION_SEARCH_FAILURE";

export const EDITOR_ANIMATION_SEARCH_CLEAR_CURRENT_TERM =
  "API/MEDIA/ANIMATION_SEARCH_CLEAR_CURRENT_TERM";

export const EDITOR_STOCK_ANIMATION_SEARCH_REQUEST =
  "API/MEDIA/STOCK_ANIMATION_SEARCH_REQUEST";
export const EDITOR_STOCK_ANIMATION_SEARCH_SUCCESS =
  "API/MEDIA/STOCK_ANIMATION_SEARCH_SUCCESS";
export const EDITOR_STOCK_ANIMATION_SEARCH_FAILURE =
  "API/MEDIA/STOCK_ANIMATION_SEARCH_FAILURE";

export const EDITOR_BRAND_ANIMATION_SEARCH_REQUEST =
  "API/MEDIA/BRAND_ANIMATION_SEARCH_REQUEST";
export const EDITOR_BRAND_ANIMATION_SEARCH_SUCCESS =
  "API/MEDIA/BRAND_ANIMATION_SEARCH_SUCCESS";
export const EDITOR_BRAND_ANIMATION_SEARCH_FAILURE =
  "API/MEDIA/BRAND_ANIMATION_SEARCH_FAILURE";
