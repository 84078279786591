import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ClockArrowAntiClockwiseIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => {
  return (
    <svg
      className={className}
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 22}
      height={height || size || 22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      ariaLabelledby="title"
      fill={color || easilGrayXDark}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M2 1h20v20H2z" />
        <g
          stroke={color || easilGrayXDark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M4.773 5.818A9 9 0 1 1 3 11.182" />
          <path d="M4.44 2v4.243h4.242" />
        </g>
        <path
          stroke={color || easilGrayXDark}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 7v5l3 2"
        />
      </g>
    </svg>
  );
};
ClockArrowAntiClockwiseIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ClockArrowAntiClockwiseIcon;
