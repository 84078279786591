import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PadlockIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  noKeyhole
}) => (
  <svg
    aria-labelledby="title"
    className={className}
    data-no-pointer={noPointer}
    data-testid="PadlockIcon"
    height={height || size || 24}
    style={style}
    viewBox="0 0 14 16"
    width={width || size || 24}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Locking-Panel---Document---Lockinng"
        transform="translate(-1325.000000, -415.000000)"
      >
        <g id="Group-34-Copy-3" transform="translate(1322.000000, 413.000000)">
          <rect id="Rectangle-22" x="0" y="0" width="20" height="20" />
          <g id="Group-131" transform="translate(2.000000, 2.000000)">
            <rect id="Rectangle-214" x="0" y="0" width="16" height="16" />
            <g id="Group-130" transform="translate(1.000000, 4.000000)">
              <rect id="Rectangle-84" x="1" y="0" width="12" height="12" />
              <path
                d="M3,3.9 C2.39248678,3.9 1.9,4.39248678 1.9,5 L1.9,9 C1.9,9.60751322 2.39248678,10.1 3,10.1 L11,10.1 C11.6075132,10.1 12.1,9.60751322 12.1,9 L12.1,5 C12.1,4.39248678 11.6075132,3.9 11,3.9 L3,3.9 Z M3,2.1 L11,2.1 C12.6016258,2.1 13.9,3.39837423 13.9,5 L13.9,9 C13.9,10.6016258 12.6016258,11.9 11,11.9 L3,11.9 C1.39837423,11.9 0.1,10.6016258 0.1,9 L0.1,5 C0.1,3.39837423 1.39837423,2.1 3,2.1 Z"
                id="Rectangle-212"
                fill={color || easilGrayXDark}
                fillRule="nonzero"
              />
            </g>
            <path
              d="M10.1,4 C10.1,2.84020203 9.15979797,1.9 8,1.9 C6.84020203,1.9 5.9,2.84020203 5.9,4 L5.9,6.1 L10.1,6.1 L10.1,4 Z M8,0.1 C10.1539105,0.1 11.9,1.84608948 11.9,4 L11.9,7.9 L4.1,7.9 L4.1,4 C4.1,1.84608948 5.84608948,0.1 8,0.1 Z"
              id="Rectangle-215"
              fill={color || easilGrayXDark}
              fillRule="nonzero"
            />
            {!noKeyhole ? (
              <circle
                id="Oval-6"
                fill={color || easilGrayXDark}
                cx="8"
                cy="11"
                r="1"
              />
            ) : (
              ""
            )}
          </g>
        </g>
      </g>
    </g>
  </svg>
);
PadlockIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PadlockIcon;
