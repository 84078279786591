export const entityBlackList = [
  {
    regEx: /&amp;/g,
    value: "&"
  },
  {
    regEx: /&nbsp;/g,
    value: " "
  },
  {
    regEx: /&#64;/g,
    value: "@"
  },
  {
    regEx: /&apos;/g,
    value: "'"
  },
  {
    regEx: /&#47;/g,
    value: "/"
  },
  {
    regEx: /<(?!br)(?!span)(?!div)(?!b>)(?!i>)(?!u>)(?!\/span)(?!\/div)(?!\/b>)(?!\/i>)(?!\/u>)/g,
    value: "&lt;"
  },
  {
    regEx: />(?!rb)(?!naps)(?!vid)(?!b)(?!i)(?!u)(?!".*"=elyts rb)(?!".*"=elyts naps)(?!".*"=elyts vid)(?!".*"=elyts b)(?!".*"=elyts i)(?!".*"=elyts u)(?!naps\/)(?!\/vid)(?!\/b)(?!\/i)(?!\/u)/g,
    value: ";tg&",
    useReverse: true
  }
];

export const entityRenderEncodeList = [
  {
    regEx: /&lt;/g,
    value: "<"
  },
  {
    regEx: /&gt;/g,
    value: ">"
  }
];
