import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import SubPageLoading from "views/pages/SubPageLoading";
const OrderPage = lazy(() => import("./OrderPage"));

export const OrderPageLazy = props => (
  <Suspense fallback={<SubPageLoading />}>
    <OrderPage {...props} />
  </Suspense>
);
OrderPageLazy.displayName = "OrderPage";

export default WithHeader(OrderPageLazy);
