import React from "react";
import PropTypes from "prop-types";

const ResizeIconSmall = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  tipText,
  tipFor
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    xmlns="http://www.w3.org/2000/svg"
    width={width || size || 24}
    height={height || size || 24}
    data-tip={tipText}
    data-for={tipFor}
  >
    <g fill="none" fillRule="evenodd">
      <rect width={width || size || 24} height={height || size || 24} rx="4" />
      <g
        stroke="#57595D"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      >
        <path d="M4.633 15.124A8.002 8.002 0 0 0 20 12a8 8 0 0 0-8-8c-3.717 0-7.101 2.565-8 6" />
        <path d="M8.5 8.5 4 10 3 5.5" />
      </g>
    </g>
  </svg>
);
ResizeIconSmall.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ResizeIconSmall;
