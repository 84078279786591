import React from "react";
import TextFieldContainer from "views/components/Editor/elements/Table/TableRow/TableCell/TextField/TextFieldContainer";
import style from "./style.module.css";

const TableCell = ({
  textFields,
  id,
  index: cellIndex,
  columnMetadata,
  widthInPercentage,
  rowIndex,
  tableId
}) => {
  const getTextFieldMetadata = index => columnMetadata.textFields[index];

  const dynamicStyle = {
    width: `${widthInPercentage}%`
  };

  return (
    <div className={style.tableCell} style={dynamicStyle}>
      {textFields.map((textField, index) => (
        <TextFieldContainer
          key={textField.id}
          rowIndex={rowIndex}
          cellIndex={cellIndex}
          height={textField.height}
          index={index}
          id={textField.id}
          value={textField.value}
          metadata={getTextFieldMetadata(index)}
          tableId={tableId}
        />
      ))}
    </div>
  );
};

export default TableCell;
