import { priorityCountries, secondaryCountries } from "lib/countriesList";
import US from "./us.jpg";
import AU from "./au.jpg";
import GB from "./gb.jpg";
import CA from "./ca.jpg";
import AD from "./ad.jpg";
import AE from "./ae.jpg";
import AF from "./af.jpg";
import AG from "./ag.jpg";
import AI from "./ai.jpg";
import AL from "./al.jpg";
import AM from "./am.jpg";
import AO from "./ao.jpg";
import AQ from "./aq.jpg";
import AR from "./ar.jpg";
import AS from "./as.jpg";
import AT from "./at.jpg";
import AW from "./aw.jpg";
import AX from "./ax.jpg";
import AZ from "./az.jpg";
import BA from "./ba.jpg";
import BB from "./bb.jpg";
import BD from "./bd.jpg";
import BE from "./be.jpg";
import BF from "./bf.jpg";
import BG from "./bg.jpg";
import BH from "./bh.jpg";
import BI from "./bi.jpg";
import BJ from "./bj.jpg";
import BL from "./bl.jpg";
import BM from "./bm.jpg";
import BN from "./bn.jpg";
import BO from "./bo.jpg";
import BQ from "./bq.jpg";
import BR from "./br.jpg";
import BS from "./bs.jpg";
import BT from "./bt.jpg";
import BV from "./bv.jpg";
import BW from "./bw.jpg";
import BY from "./by.jpg";
import BZ from "./bz.jpg";
import CC from "./cc.jpg";
import CD from "./cd.jpg";
import CF from "./cf.jpg";
import CG from "./cg.jpg";
import CH from "./ch.jpg";
import CI from "./ci.jpg";
import CK from "./ck.jpg";
import CL from "./cl.jpg";
import CM from "./cm.jpg";
import CN from "./cn.jpg";
import CO from "./co.jpg";
import CR from "./cr.jpg";
import CU from "./cu.jpg";
import CV from "./cv.jpg";
import CW from "./cw.jpg";
import CX from "./cx.jpg";
import CY from "./cy.jpg";
import CZ from "./cz.jpg";
import DE from "./de.jpg";
import DJ from "./dj.jpg";
import DK from "./dk.jpg";
import DM from "./dm.jpg";
import DO from "./do.jpg";
import DZ from "./dz.jpg";
import EC from "./ec.jpg";
import EE from "./ee.jpg";
import EG from "./eg.jpg";
import EH from "./eh.jpg";
import ER from "./er.jpg";
import ES from "./es.jpg";
import ET from "./et.jpg";
import FI from "./fi.jpg";
import FJ from "./fj.jpg";
import FK from "./fk.jpg";
import FM from "./fm.jpg";
import FO from "./fo.jpg";
import FR from "./fr.jpg";
import GA from "./ga.jpg";
import GD from "./gd.jpg";
import GE from "./ge.jpg";
import GF from "./gf.jpg";
import GG from "./gg.jpg";
import GH from "./gh.jpg";
import GI from "./gi.jpg";
import GL from "./gl.jpg";
import GM from "./gm.jpg";
import GN from "./gn.jpg";
import GP from "./gp.jpg";
import GQ from "./gq.jpg";
import GR from "./gr.jpg";
import GS from "./gs.jpg";
import GT from "./gt.jpg";
import GU from "./gu.jpg";
import GW from "./gw.jpg";
import GY from "./gy.jpg";
import HK from "./hk.jpg";
import HM from "./hm.jpg";
import HN from "./hn.jpg";
import HR from "./hr.jpg";
import HT from "./ht.jpg";
import HU from "./hu.jpg";
import ID from "./id.jpg";
import IE from "./ie.jpg";
import IL from "./il.jpg";
import IM from "./im.jpg";
import IN from "./in.jpg";
import IO from "./io.jpg";
import IQ from "./iq.jpg";
import IR from "./ir.jpg";
import IS from "./is.jpg";
import IT from "./it.jpg";
import JE from "./je.jpg";
import JM from "./jm.jpg";
import JO from "./jo.jpg";
import JP from "./jp.jpg";
import KE from "./ke.jpg";
import KG from "./kg.jpg";
import KH from "./kh.jpg";
import KI from "./ki.jpg";
import KM from "./km.jpg";
import KN from "./kn.jpg";
import KP from "./kp.jpg";
import KR from "./kr.jpg";
import KW from "./kw.jpg";
import KY from "./ky.jpg";
import KZ from "./kz.jpg";
import LA from "./la.jpg";
import LB from "./lb.jpg";
import LC from "./lc.jpg";
import LI from "./li.jpg";
import LK from "./lk.jpg";
import LR from "./lr.jpg";
import LS from "./ls.jpg";
import LT from "./lt.jpg";
import LU from "./lu.jpg";
import LV from "./lv.jpg";
import LY from "./ly.jpg";
import MA from "./ma.jpg";
import MC from "./mc.jpg";
import MD from "./md.jpg";
import ME from "./me.jpg";
import MF from "./mf.jpg";
import MG from "./mg.jpg";
import MH from "./mh.jpg";
import MK from "./mk.jpg";
import ML from "./ml.jpg";
import MM from "./mm.jpg";
import MN from "./mn.jpg";
import MO from "./mo.jpg";
import MP from "./mp.jpg";
import MQ from "./mq.jpg";
import MR from "./mr.jpg";
import MS from "./ms.jpg";
import MT from "./mt.jpg";
import MU from "./mu.jpg";
import MV from "./mv.jpg";
import MW from "./mw.jpg";
import MX from "./mx.jpg";
import MY from "./my.jpg";
import MZ from "./mz.jpg";
import NA from "./na.jpg";
import NC from "./nc.jpg";
import NE from "./ne.jpg";
import NF from "./nf.jpg";
import NG from "./ng.jpg";
import NI from "./ni.jpg";
import NL from "./nl.jpg";
import NO from "./no.jpg";
import NP from "./np.jpg";
import NR from "./nr.jpg";
import NU from "./nu.jpg";
import NZ from "./nz.jpg";
import OM from "./om.jpg";
import PA from "./pa.jpg";
import PE from "./pe.jpg";
import PF from "./pf.jpg";
import PG from "./pg.jpg";
import PH from "./ph.jpg";
import PK from "./pk.jpg";
import PL from "./pl.jpg";
import PM from "./pm.jpg";
import PN from "./pn.jpg";
import PR from "./pr.jpg";
import PS from "./ps.jpg";
import PT from "./pt.jpg";
import PW from "./pw.jpg";
import PY from "./py.jpg";
import QA from "./qa.jpg";
import RE from "./re.jpg";
import RO from "./ro.jpg";
import RS from "./rs.jpg";
import RU from "./ru.jpg";
import RW from "./rw.jpg";
import SA from "./sa.jpg";
import SB from "./sb.jpg";
import SC from "./sc.jpg";
import SD from "./sd.jpg";
import SE from "./se.jpg";
import SG from "./sg.jpg";
import SH from "./sh.jpg";
import SI from "./si.jpg";
import SJ from "./sj.jpg";
import SK from "./sk.jpg";
import SL from "./sl.jpg";
import SM from "./sm.jpg";
import SN from "./sn.jpg";
import SO from "./so.jpg";
import SR from "./sr.jpg";
import SS from "./ss.jpg";
import ST from "./st.jpg";
import SV from "./sv.jpg";
import SX from "./sx.jpg";
import SY from "./sy.jpg";
import SZ from "./sz.jpg";
import TC from "./tc.jpg";
import TD from "./td.jpg";
import TF from "./tf.jpg";
import TG from "./tg.jpg";
import TH from "./th.jpg";
import TJ from "./tj.jpg";
import TK from "./tk.jpg";
import TL from "./tl.jpg";
import TM from "./tm.jpg";
import TN from "./tn.jpg";
import TO from "./to.jpg";
import TR from "./tr.jpg";
import TT from "./tt.jpg";
import TV from "./tv.jpg";
import TW from "./tw.jpg";
import TZ from "./tz.jpg";
import UA from "./ua.jpg";
import UG from "./ug.jpg";
import UM from "./um.jpg";
import UY from "./uy.jpg";
import UZ from "./uz.jpg";
import VA from "./va.jpg";
import VC from "./vc.jpg";
import VE from "./ve.jpg";
import VG from "./vg.jpg";
import VI from "./vi.jpg";
import VN from "./vn.jpg";
import VU from "./vu.jpg";
import WF from "./wf.jpg";
import WS from "./ws.jpg";
import YE from "./ye.jpg";
import YT from "./yt.jpg";
import ZA from "./za.jpg";
import ZM from "./zm.jpg";
import ZW from "./zw.jpg";

const countryFlags = {
  US: US,
  AU: AU,
  GB: GB,
  CA: CA,
  AD: AD,
  AE: AE,
  AF: AF,
  AG: AG,
  AI: AI,
  AL: AL,
  AM: AM,
  AO: AO,
  AQ: AQ,
  AR: AR,
  AS: AS,
  AT: AT,
  AW: AW,
  AX: AX,
  AZ: AZ,
  BA: BA,
  BB: BB,
  BD: BD,
  BE: BE,
  BF: BF,
  BG: BG,
  BH: BH,
  BI: BI,
  BJ: BJ,
  BL: BL,
  BM: BM,
  BN: BN,
  BO: BO,
  BQ: BQ,
  BR: BR,
  BS: BS,
  BT: BT,
  BV: BV,
  BW: BW,
  BY: BY,
  BZ: BZ,
  CC: CC,
  CD: CD,
  CF: CF,
  CG: CG,
  CH: CH,
  CI: CI,
  CK: CK,
  CL: CL,
  CM: CM,
  CN: CN,
  CO: CO,
  CR: CR,
  CU: CU,
  CV: CV,
  CW: CW,
  CX: CX,
  CY: CY,
  CZ: CZ,
  DE: DE,
  DJ: DJ,
  DK: DK,
  DM: DM,
  DO: DO,
  DZ: DZ,
  EC: EC,
  EE: EE,
  EG: EG,
  EH: EH,
  ER: ER,
  ES: ES,
  ET: ET,
  FI: FI,
  FJ: FJ,
  FK: FK,
  FM: FM,
  FO: FO,
  FR: FR,
  GA: GA,
  GD: GD,
  GE: GE,
  GF: GF,
  GG: GG,
  GH: GH,
  GI: GI,
  GL: GL,
  GM: GM,
  GN: GN,
  GP: GP,
  GQ: GQ,
  GR: GR,
  GS: GS,
  GT: GT,
  GU: GU,
  GW: GW,
  GY: GY,
  HK: HK,
  HM: HM,
  HN: HN,
  HR: HR,
  HT: HT,
  HU: HU,
  ID: ID,
  IE: IE,
  IL: IL,
  IM: IM,
  IN: IN,
  IO: IO,
  IQ: IQ,
  IR: IR,
  IS: IS,
  IT: IT,
  JE: JE,
  JM: JM,
  JO: JO,
  JP: JP,
  KE: KE,
  KG: KG,
  KH: KH,
  KI: KI,
  KM: KM,
  KN: KN,
  KP: KP,
  KR: KR,
  KW: KW,
  KY: KY,
  KZ: KZ,
  LA: LA,
  LB: LB,
  LC: LC,
  LI: LI,
  LK: LK,
  LR: LR,
  LS: LS,
  LT: LT,
  LU: LU,
  LV: LV,
  LY: LY,
  MA: MA,
  MC: MC,
  MD: MD,
  ME: ME,
  MF: MF,
  MG: MG,
  MH: MH,
  MK: MK,
  ML: ML,
  MM: MM,
  MN: MN,
  MO: MO,
  MP: MP,
  MQ: MQ,
  MR: MR,
  MS: MS,
  MT: MT,
  MU: MU,
  MV: MV,
  MW: MW,
  MX: MX,
  MY: MY,
  MZ: MZ,
  NA: NA,
  NC: NC,
  NE: NE,
  NF: NF,
  NG: NG,
  NI: NI,
  NL: NL,
  NO: NO,
  NP: NP,
  NR: NR,
  NU: NU,
  NZ: NZ,
  OM: OM,
  PA: PA,
  PE: PE,
  PF: PF,
  PG: PG,
  PH: PH,
  PK: PK,
  PL: PL,
  PM: PM,
  PN: PN,
  PR: PR,
  PS: PS,
  PT: PT,
  PW: PW,
  PY: PY,
  QA: QA,
  RE: RE,
  RO: RO,
  RS: RS,
  RU: RU,
  RW: RW,
  SA: SA,
  SB: SB,
  SC: SC,
  SD: SD,
  SE: SE,
  SG: SG,
  SH: SH,
  SI: SI,
  SJ: SJ,
  SK: SK,
  SL: SL,
  SM: SM,
  SN: SN,
  SO: SO,
  SR: SR,
  SS: SS,
  ST: ST,
  SV: SV,
  SX: SX,
  SY: SY,
  SZ: SZ,
  TC: TC,
  TD: TD,
  TF: TF,
  TG: TG,
  TH: TH,
  TJ: TJ,
  TK: TK,
  TL: TL,
  TM: TM,
  TN: TN,
  TO: TO,
  TR: TR,
  TT: TT,
  TV: TV,
  TW: TW,
  TZ: TZ,
  UA: UA,
  UG: UG,
  UM: UM,
  UY: UY,
  UZ: UZ,
  VA: VA,
  VC: VC,
  VE: VE,
  VG: VG,
  VI: VI,
  VN: VN,
  VU: VU,
  WF: WF,
  WS: WS,
  YE: YE,
  YT: YT,
  ZA: ZA,
  ZM: ZM,
  ZW: ZW
};

export const separatedCountriesWithFlags = {
  countries: secondaryCountries.map(country => {
    return {
      ...country,
      flagSrc: countryFlags[country.key]
    };
  }),
  commonCountries: priorityCountries.map(country => {
    return {
      ...country,
      flagSrc: countryFlags[country.key]
    };
  })
};

export const allCountriesWithFlags = Object.values(
  separatedCountriesWithFlags
).flat();
