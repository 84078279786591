import React from "react";
import PropTypes from "prop-types";

const TablesIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h24v24H-2z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M3 2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm0-2h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
      />
      <path d="M0 0h20v4H0z" />
      <path
        stroke={color}
        d="M.5 12.5v1h6v4h1v-4h5v4h1v-4h6v-1h-6v-5h6v-1h-6v-4h-1v4h-5v-4h-1v4h-6v1h6v5h-6zm7 0v-5h5v5h-5z"
      />
    </g>
  </svg>
);
TablesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TablesIcon;
