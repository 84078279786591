import * as types from "./invoicesTypes";

export const initState = null;

const invoicesReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_INVOICES_REQUEST_SUCCESS: {
      if (!action.response.entities) {
        return action.request.page === 1 ? {} : state;
      }

      const {
        response: {
          entities: { invoices }
        }
      } = action;

      return {
        ...state,
        ...invoices
      };
    }

    default:
      return state;
  }
};

export default invoicesReducers;
