import Element from "state/ui/editor/elements/Element";

class VectorTextElement extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "textEdit",
    "sizeAndPosition",
    "color",
    "textDecoration",
    "textCurve",
    "letterSpacing",
    "lineHeight"
  ];

  static DEFAULT_PROPS = {
    imageInstructions: []
  };

  changeFillColor({ domId, color }) {
    const fillColorsUpdated = [];

    this.fillColors.forEach(fillColor => {
      if (!fillColor.domId.includes(domId)) {
        fillColorsUpdated.push(fillColor);
      } else {
        fillColorsUpdated.push({
          ...fillColor,
          color
        });
      }
    });

    return new this.constructor({
      ...this,
      fillColors: fillColorsUpdated
    });
  }

  get minWidth() {
    return 1;
  }

  get minHeight() {
    return 1;
  }

  updateAttributes(attributes) {
    const _attributes = super.ensureMinimalValues(attributes);

    const sideEffects = {};

    if (!this.resizableX && _attributes.hasOwnProperty("width")) {
      const scaleFactor = _attributes.width / this.width;

      sideEffects.height = this.height * scaleFactor;
      sideEffects.scale = this.scale * scaleFactor;
    }

    if (!this.resizableY && _attributes.hasOwnProperty("height")) {
      const scaleFactor = _attributes.height / this.height;

      sideEffects.width = this.width * scaleFactor;
      sideEffects.scale = this.scale * scaleFactor;
    }

    if (_attributes.svgInstructions) {
      _attributes.svgInstructions = {
        ...(this.svgInstructions || {}),
        ...attributes.svgInstructions
      };
    }

    return new this.constructor({
      ...this,
      ..._attributes,
      ...sideEffects
    });
  }

  updateImageInstructions({ imageInstructions }) {
    return new this.constructor({
      ...this,
      imageInstructions
    });
  }

  getImageColors() {
    const colors = {};

    this.imageInstructions.forEach(imageInstruction => {
      this.getImageInstructionColors(imageInstruction, colors);
    });

    return colors;
  }

  getImageInstructionColors(imageInstruction, colors = {}) {
    if (!imageInstruction.id) {
      return;
    }

    return (colors[imageInstruction.id] = {
      previewUrl: imageInstruction.previewSrc || this.src,
      colors: imageInstruction.palette
    });
  }
}

export default VectorTextElement;
