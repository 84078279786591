import React, { Component } from "react";
import style from "./style.module.css";
import CheckIcon from "views/components/icons/CheckIcon";
import LogoImage from "views/containers/userSettingsContainer/LogoImage";

class CountryOption extends Component {
  render() {
    const { country, onClick, selected } = this.props;
    return (
      <div
        className={style.option}
        key={country.label}
        onClick={() => onClick(country.key)}
        data-testid="CountryOption"
      >
        <div className={style.countryNameFlagWrapper}>
          <LogoImage src={country.flagSrc} width={"24px"} height={"24px"} />
          <div className={style.name}>{country.label}</div>
        </div>
        {selected && (
          <div className={style.selected}>
            <CheckIcon width={"11"} height={"11"} />
          </div>
        )}
      </div>
    );
  }
}

export default CountryOption;
