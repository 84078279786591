import {
  UI_OPEN_REQUEST_APPROVAL_MODAL,
  UI_CLOSE_REQUEST_APPROVAL_MODAL,
  UI_OPEN_CANCEL_APPROVAL_MODAL,
  UI_CLOSE_CANCEL_APPROVAL_MODAL
} from "./requestApprovalModalTypes";

export const openRequestApprovalModal = ({ designId }) => {
  return {
    type: UI_OPEN_REQUEST_APPROVAL_MODAL,
    designId
  };
};

export const closeRequestApprovalModal = () => {
  return {
    type: UI_CLOSE_REQUEST_APPROVAL_MODAL
  };
};

export const openCancelApprovalModal = () => {
  return {
    type: UI_OPEN_CANCEL_APPROVAL_MODAL
  };
};

export const closeCancelApprovalModal = () => {
  return {
    type: UI_CLOSE_CANCEL_APPROVAL_MODAL
  };
};
