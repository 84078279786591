import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import { getTermKey } from "../../helpers";

export const initState = {
  isSearching: false,
  pageSize: 50,
  results: {
    /* how this looks like when populated
     *
     * "searchTerm1": {
     *   pages: {}
     * },
     * "searchTerm2": {
     *   pages: {}
     * }*/
  }
};

const searchApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_SEARCH_REQUEST: {
      const { params, page, extra } = action.request;
      const { term, context, scope } = params;

      if (context !== "templates" && scope !== "purchased") {
        return state;
      }

      const termKey = getTermKey({
        term,
        size: params.size,
        searchType: extra.searchType
      });

      const getPreviousPages = () => {
        if (state.results[termKey]) {
          return state.results[termKey].pages;
        } else {
          return {};
        }
      };

      const getIds = () => {
        if (
          state.results[termKey] &&
          state.results[termKey].pages &&
          state.results[termKey].pages[page]
        ) {
          return state.results[termKey].pages[page].ids;
        } else {
          return null;
        }
      };

      return {
        ...state,
        isSearching: true,
        results: {
          ...state.results,
          [termKey]: {
            pages: {
              ...getPreviousPages(),
              [page]: {
                ids: getIds()
              }
            }
          }
        }
      };
    }

    case designsEntitiesTypes.DESIGNS_SEARCH_REQUEST_SUCCESS: {
      const { ids } = action.response;

      const { params, page, extra } = action.request;
      const { term, context, scope } = params;

      if (context !== "templates" && scope !== "purchased") {
        return state;
      }

      const termKey = getTermKey({
        term,
        size: params.size,
        searchType: extra.searchType
      });

      const getPreviousPages = () => {
        if (state.results[termKey]) {
          return state.results[termKey].pages;
        } else {
          return {};
        }
      };

      return {
        ...state,
        isSearching: false,
        results: {
          ...state.results,
          [termKey]: {
            pages: {
              ...getPreviousPages(),
              [page]: {
                ids: ids || []
              }
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default searchApiReducers;
