import * as types from "./designActionsTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";

export const getDesignActions = ({ designId, action, page = 1 }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  const params = {};

  if (action) {
    params.action = action;
  }

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.DESIGN_APPROVAL_ACTIONS_REQUEST,
        types.DESIGN_APPROVAL_ACTIONS_REQUEST_SUCCESS,
        types.DESIGN_APPROVAL_ACTIONS_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/drafts/${designId}/approval-action`,
      request: {
        page,
        params: params
      },
      extra: {
        designId
      },
      schema: schemas.DESIGN_ACTIONS
    }
  });
};
