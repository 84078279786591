import PATHS from "routes/paths";
import tabsStyle from "./tabsStyle.module.css";

export const tabs = () => ({
  Designs: {
    title: "My Designs",
    url: PATHS.workspaceDrafts,
    altUrls: [PATHS.workspaceFolders, PATHS.purchaseCollection]
  },
  ApprovedDesigns: {
    title: "My Approved Designs",
    url: PATHS.workspaceApproved
  },
  MyUploads: {
    title: "My Uploads",
    url: PATHS.workspaceUploads
  },
  SharedWithMe: {
    title: "Shared with me",
    url: PATHS.workspaceShared
  },
  Archived: {
    title: "Archived",
    url: PATHS.workspaceArchived
  },
  BrandManagerApprovedDesigns: {
    title: "Approved Designs",
    className: tabsStyle.brandManagerApprovedDesignsTab,
    url: PATHS.workspaceBrandManagerApproved,
    hasNoBorder: true
  }
});
