import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const CollectionRemixPage = lazy(() => import("./CollectionRemixPage"));

export const CollectionRemixPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <CollectionRemixPage {...props} />
  </Suspense>
);
CollectionRemixPageLazy.displayName = "CollectionRemixPage";

export default CollectionRemixPageLazy;
