import React from "react";
import style from "./style.module.css";
import PropTypes from "prop-types";
import { Button, NoComponent } from "views/components";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import { Logger, noop, composeClassName } from "lib";

const IconButton = ({
  iconColor,
  Icon = NoComponent,
  title,
  className,
  iconSize = "20",
  iconBoxSize,
  onClick,
  MenuComponent,
  showMenu,
  setMenuState,
  titleClassName,
  isDisabled,
  wrapperClassName,
  theme,
  iconClassName
}) => {
  if (!iconBoxSize) iconBoxSize = iconSize;
  if (!Icon) {
    Logger.error(
      "No Icon Provided. Please provide an Icon or use Button Component"
    );
    return null;
  }

  const getButtonClassName = () => {
    const classes = [style.button];

    if (className) {
      classes.push(className);
    }

    return classes.join(" ");
  };

  const onToggleMenuClick = event => {
    event.preventDefault();
    event.stopPropagation();

    setMenuState(!showMenu);
  };

  return (
    <div
      className={[style.wrapper, wrapperClassName].filter(x => x).join(" ")}
      data-testid="IconButton"
    >
      <Button
        className={getButtonClassName()}
        onClick={onClick}
        disabled={isDisabled}
        theme={theme}
      >
        <div
          className={`${style.icon} ${iconClassName}`}
          style={{ width: `${iconBoxSize}px`, height: `${iconBoxSize}px` }}
        >
          <Icon color={iconColor} size={iconSize} />
        </div>
        {title && (
          <div
            className={composeClassName("title", style, titleClassName)}
            data-testid="iconButtonTitle"
          >
            {title}
          </div>
        )}
        {MenuComponent && (
          <div
            className={style.menu}
            data-is-active={showMenu}
            onClick={onToggleMenuClick}
          >
            <EllipsisIcon
              color="#999c9e"
              withBackground={true}
              backgroundColor="transparent"
            />
          </div>
        )}
      </Button>
      {showMenu && MenuComponent}
    </div>
  );
};

IconButton.displayName = "IconButton";

IconButton.defaultProps = {
  iconColor: "#9a9b9d",
  onClick: noop
};

IconButton.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  MenuComponent: PropTypes.element,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
  setMenuState: PropTypes.func,
  showMenu: PropTypes.bool,
  title: PropTypes.string
};

export default IconButton;
