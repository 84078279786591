import { getIsCurrentTeamOrg } from "state/entities/teams/teamsSelectors";
import { userIsAdmin } from "state/currentUser/currentUserSelectors";
export * from "./teamCatalogue/teamCatalogueSelectors";

export const teamPageUsersPagination = state => {
  return state.ui.teamPage.users.currentPage;
};

export const canUpgradeSubscription = state => {
  const isAdmin = userIsAdmin(state);
  const isCurrentTeamOrg = getIsCurrentTeamOrg(state);

  return isAdmin && isCurrentTeamOrg;
};
