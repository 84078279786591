import { values } from "lib/lodash";

const DesignColorsOps = {
  getImagesColors() {
    const colors = {};

    const elementsIds = Object.keys(this.elements);

    elementsIds.forEach(elementId => {
      const elementImagesColors = this.getElementImage(elementId, colors);

      Object.assign(colors, elementImagesColors);
    });

    return values(colors);
  },

  getElementImage(elementId) {
    let element = this.getElement(elementId);

    return element.getImageColors();
  }
};

export default DesignColorsOps;
