import CollectionPreviewModalTemplate from "../CollectionPreviewModalTemplate";
import FileIcon from "views/components/icons/FileIcon";
import SizesIcon from "views/components/icons/SizesIcon";

export class CreateDraftModal extends CollectionPreviewModalTemplate {
  // returns an array of buttons for the modal
  getButtons() {
    const { sizeCount } = this.props;
    const isCollection = sizeCount > 1;

    return [
      {
        label: "Edit Design",
        action: this.handleOpenDesign,
        visible: true,
        id: "design",
        icon: FileIcon
      },
      {
        label: "Edit Collection",
        action: this.handleOpenCollection,
        visible: isCollection,
        id: "collection",
        icon: SizesIcon
      }
    ];
  }

  // open the selected design in editor and make drafts of all designs in the collection
  handleOpenCollection() {
    const { copyCollectionToDrafts, selectedDesign: propsDesign } = this.props;
    const { previewDesign: stateDesign } = this.state;

    // prefer taking from state selection
    const { collectionId, templateCode } = stateDesign || propsDesign;

    // collectionId for collection to copy, templateCode for the template to open in editor after
    copyCollectionToDrafts({ collectionId, templateCode });
    this.handleCloseModal();
    // we need to close the modal here
  }

  // create a draft of the selected design and open in editor
  handleOpenDesign() {
    const { selectedDesign: propsDesign, createDraftFromTemplate } = this.props;
    const { previewDesign: stateDesign } = this.state;

    // always use selected design from state if it exists
    const design = stateDesign || propsDesign;

    // create a draft and then close the modal
    createDraftFromTemplate({ design });
    this.handleCloseModal();
  }
}

CreateDraftModal.displayName = "CreateDraftModal";

export default CreateDraftModal;
