import parse from "parse-svg-path";
import abs from "abs-svg-path";
import normalize from "normalize-svg-path";
import { pickKeys } from "lib/object/object";

// takes a svg path definition and calculates the bounding dimensions returning its height and width
export const getPathBounds = element => {
  const originalBBox = element.getBBox();

  if (originalBBox && originalBBox.height && originalBBox.width) {
    // preference bbox dimensions where available (FF does not work for this)
    return originalBBox;
  }

  let _path = "" + element.getAttribute("d");

  // ES6 string tpl call
  if (
    Array.isArray(_path) &&
    _path.length === 1 &&
    typeof _path[0] === "string"
  )
    _path = _path[0];

  // svg path string
  if (typeof _path === "string") {
    _path = parse(_path);
  }

  _path = abs(_path);
  _path = normalize(_path);

  if (!_path.length) return [0, 0, 0, 0];

  const bounds = [Infinity, Infinity, -Infinity, -Infinity];

  for (let i = 0, l = _path.length; i < l; i++) {
    const points = _path[i].slice(1);

    for (let j = 0; j < points.length; j += 2) {
      if (points[j + 0] < bounds[0]) bounds[0] = points[j + 0];
      if (points[j + 1] < bounds[1]) bounds[1] = points[j + 1];
      if (points[j + 0] > bounds[2]) bounds[2] = points[j + 0];
      if (points[j + 1] > bounds[3]) bounds[3] = points[j + 1];
    }
  }

  const [left, top, right, bottom] = bounds;

  return {
    width: right - left,
    height: bottom - top,
    x: left,
    y: top
  };
};

/*
  Code from here down is based on https://github.com/kfitfk/svg-boundings with modifications to suit our needs
  TODO: expand the use of functions from this source for calculating some of our svg bounds
*/
const elementAttributeNames = {
  ellipse: ["cx", "cy", "rx", "ry", "transform", "stroke-width"],
  polygon: ["points", "stroke-width"],
  circle: ["cx", "cy", "r"],
  rect: ["x", "y", "width", "height"]
};

export const boundingRectOfRect = rect => {
  rect = attributesFromElement(rect, elementAttributeNames.rect);

  return {
    x: rect.x,
    y: rect.y,
    width: rect.width,
    height: rect.height
  };
};

export const boundingRectOfPolygon = polygon => {
  polygon = attributesFromElement(polygon, elementAttributeNames.polygon);

  var points = polygon.points
    .trim()
    .replace(/\r\n|\n|\r/gm, ",")
    .replace(/\s+/g, ",")
    .split(",")
    .map(parseFloat);

  var l = Number.POSITIVE_INFINITY;
  var r = Number.NEGATIVE_INFINITY;
  var t = Number.POSITIVE_INFINITY;
  var b = Number.NEGATIVE_INFINITY;

  for (var i = 0; i < points.length; i += 2) {
    if (l > points[i]) l = points[i];
    if (r < points[i]) r = points[i];
    if (t > points[i + 1]) t = points[i + 1];
    if (b < points[i + 1]) b = points[i + 1];
  }

  return {
    left: l,
    top: t,
    right: r,
    bottom: b,
    width: r - l,
    height: b - t,
    x: l,
    y: t
  };
};

export const boundingRectOfCircle = circle => {
  circle = attributesFromElement(circle, elementAttributeNames.circle);

  var cx = circle.cx || 0;
  var cy = circle.cy || 0;
  var r = circle.r;

  return {
    left: cx - r,
    top: cy - r,
    right: cx + r,
    bottom: cy + r,
    width: 2 * r,
    height: 2 * r,
    x: cx - r,
    y: cy - r
  };
};

export const boundingRectOfEllipse = ellipse => {
  ellipse = attributesFromElement(ellipse, elementAttributeNames.ellipse);

  var cx = ellipse.cx || 0;
  var cy = ellipse.cy || 0;
  var rx = ellipse.rx;
  var ry = ellipse.ry;
  var l = cx - rx;
  var t = cy - ry;
  var r = l + 2 * rx;
  var b = t + 2 * ry;

  var transform = ellipse.transform;
  var matrix;
  if (transform) {
    matrix = matrixStrToObj(transform);

    var ma = matrix.a;
    var mb = matrix.b;
    var mc = matrix.c;
    var md = matrix.d;
    var me = matrix.e;
    var mf = matrix.f;
    var denominator = ma * md - mb * mc;
    var A = ry * ry * md * md + rx * rx * mb * mb;
    var B = -2 * (mc * md * ry * ry + ma * mb * rx * rx);
    var C = ry * ry * mc * mc + rx * rx * ma * ma;
    var D =
      2 * ry * ry * (mc * md * mf - md * md * me) +
      2 * rx * rx * (ma * mb * mf - mb * mb * me) -
      2 * (cx * ry * ry * md - cy * rx * rx * mb) * denominator;
    var E =
      2 * ry * ry * (mc * md * me - mc * mc * mf) +
      2 * rx * rx * (ma * mb * me - ma * ma * mf) +
      2 * (cx * ry * ry * mc - cy * rx * rx * ma) * denominator;
    var F =
      ry *
        ry *
        (mc * mc * mf * mf - 2 * mc * md * me * mf + md * md * me * me) +
      rx *
        rx *
        (ma * ma * mf * mf - 2 * ma * mb * me * mf + mb * mb * me * me) +
      (2 * cx * ry * ry * (md * me - mc * mf) +
        2 * cy * rx * rx * (ma * mf - mb * me)) *
        denominator +
      (ry * ry * cx * cx + rx * rx * cy * cy - rx * rx * ry * ry) *
        Math.pow(denominator, 2);
    var a = 4 * A * C - B * B;
    var b1 = 4 * A * E - 2 * B * D;
    var c1 = 4 * A * F - D * D;
    var d1 = b1 * b1 - 4 * a * c1;
    var b2 = 4 * C * D - 2 * B * E;
    var c2 = 4 * C * F - E * E;
    var d2 = b2 * b2 - 4 * a * c2;
    var tb1 = (0 - b1 + Math.sqrt(d1)) / (2 * a);
    var tb2 = (0 - b1 - Math.sqrt(d1)) / (2 * a);
    var lr1 = (0 - b2 + Math.sqrt(d2)) / (2 * a);
    var lr2 = (0 - b2 - Math.sqrt(d2)) / (2 * a);
    return {
      left: Math.min(lr1, lr2),
      x: Math.min(lr1, lr2),
      top: Math.min(tb1, tb2),
      y: Math.min(tb1, tb2),
      right: Math.max(lr1, lr2),
      bottom: Math.max(tb1, tb2),
      _wh: function() {
        delete this._wh;
        this.width = this.right - this.left;
        this.height = this.bottom - this.top;
        return this;
      }
    }._wh();
  }

  return {
    left: l,
    top: t,
    right: r,
    bottom: b,
    width: 2 * rx,
    height: 2 * ry,
    x: l,
    y: t
  };
};

const matrixStrToObj = str => {
  var m = [];
  // eslint-disable-next-line
  var rdigit = /[\d\.\-Ee]+/g;
  var n;
  // eslint-disable-next-line
  while ((n = rdigit.exec(str))) {
    m.push(+n);
  }

  return {
    a: m[0],
    b: m[1],
    c: m[2],
    d: m[3],
    e: m[4],
    f: m[5]
  };
};

// eslint-disable-next-line
const RE_NUMBER_ATTRIBUTES = /^(?:[cr]?x\d?|[cr]?y\d?|width|height|r|letter\-spacing)$/i;

const attributesFromElement = (element, attributeNames) => {
  var attributes = {};
  attributeNames.forEach(function(name) {
    var value = element.getAttribute(name);
    if (RE_NUMBER_ATTRIBUTES.test(name)) value = parseFloat(value) || 0;
    if (value != null) attributes[name] = value;
  });
  attributes.type = element.nodeName;
  return attributes;
};

export const getBBoxAsObject = svgElement =>
  pickKeys(svgElement.getBBox(), ["x", "y", "width", "height"]);
