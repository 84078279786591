import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const MailIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    aria-labelledby="title"
    className={className}
    fill={color || easilGrayXDark}
    data-no-pointer={noPointer}
    style={style}
    width={width || size || "24px"}
    height={height || size || "24px"}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path d="M19.2 4C20.736 4 22 5.229 22 6.75v10.5c0 1.521-1.264 2.75-2.8 2.75H4.8C3.264 20 2 18.771 2 17.25V6.75C2 5.229 3.264 4 4.8 4zm.8 4.783l-7.479 4.57a1 1 0 01-.933.058l-.11-.058L4 8.783v8.467c0 .37.3.692.691.743L4.8 18h14.4c.444 0 .8-.346.8-.75V8.783zM19.2 6H4.8c-.338 0-.625.2-.743.474L12 11.327l7.943-4.853A.806.806 0 0019.2 6z" />
  </svg>
);

MailIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default MailIcon;
