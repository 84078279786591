import * as types from "./graphicsTypes";
import { isFetchingPage } from "./graphicsSelectors";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";

export const fetchGraphicsIfNeeded = ({ category, term, pageSize, page }) => (
  dispatch,
  getState
) => {
  if (!isFetchingPage({ state: getState(), page })) {
    dispatch(
      fetchGraphics({
        category,
        term,
        pageSize,
        page
      })
    );
  }
};

export const fetchGraphics = ({
  category,
  context = "stock",
  term,
  pageSize = 10,
  page = 1
}) => dispatch => {
  const params = {
    context,
    type: "vector"
  };

  if (category) {
    params.category = category;

    // remove vector type in search query when searching for animations
    if (category === "animations") {
      delete params.type;
    }
  }
  if (term) params.term = term;

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.FETCH_GRAPHICS_REQUEST,
        types.FETCH_GRAPHICS_REQUEST_SUCCESS,
        types.FETCH_GRAPHICS_REQUEST_FAILURE
      ],
      endpoint: "/media",
      request: {
        page: page,
        pageSize,
        params
      },
      schema: schemas.GRAPHICS
    }
  });
};

export const clearAndFetchGraphics = ({
  category,
  context,
  term,
  pageSize = 10,
  page = 1
}) => dispatch => {
  dispatch({ type: types.CLEAR_GRAPHICS_REQUEST });
  dispatch(fetchGraphics({ category, context, term, page, pageSize }));
};
