/**
 * @desc takes an array of numbers and generates a string representing the set of pages
 * e.g. [1,2,3,7,8,9] => '1-3, 7-9'
 * @param {[number]} numberArray - an array of page numbers
 * @returns {string} - returns a string representation of the page set provided in the form of '1-5, 7, 10-20'
 */
export const hyphenateNumberArray = numberArray => {
  return numberArray
    .reduce((previous, current, index) => {
      if (index === 0) return [current.toString()];
      const lastItem = previous.pop();
      if (lastItem.endsWith(current - 1)) {
        const trimLength = (current - 1).toString().length;
        if (lastItem.length <= trimLength) {
          /* not yet hyphenated */
          previous.push(`${lastItem}-${current}`);
          return previous;
        }
        /* hyphenated already, replace the last number */
        const trimmedString = lastItem.substring(
          0,
          lastItem.length - trimLength
        );
        previous.push(`${trimmedString}${current}`);
        return previous;
      }
      /* not sequential, return current page number */
      previous.push(lastItem);
      previous.push(current.toString());
      return previous;
    }, [])
    .join(",");
};

export default hyphenateNumberArray;
