import * as types from "./editorImageSearchTypes";
import * as userTeamImagesTypes from "state/entities/userTeamImages/userTeamImagesTypes";
import * as userTeamImageFolderTypes from "state/entities/imageFolders/imageFoldersTypes";
import { getPath, isEmpty } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
import { getParameterByName } from "lib/queryStringUtils";

export const initState = {
  personal: {
    currentTerm: null,
    terms: {}
  },
  stock: {
    terms: {}
  },
  brand: {
    image: {
      terms: {}
    },
    logo: {
      terms: {}
    }
  }
};

const editorImageSearchReducers = (state = initState, action) => {
  switch (action.type) {
    case userTeamImagesTypes.USER_TEAM_IMAGE_DELETE_REQUEST: {
      if (isEmpty(state.personal.terms[state.personal.currentTerm]))
        return state;
      const idsForTerm = state.personal.terms[state.personal.currentTerm];
      const idIndex = (idsForTerm.ids || []).indexOf(action.request.mediaId);
      return immutableUpdate(state, {
        personal: {
          terms: {
            [state.personal.currentTerm]: {
              images: {
                $unset: [action.request.mediaId]
              },
              ids: {
                $splice: [[idIndex, 1]]
              }
            }
          }
        }
      });
    }

    case types.EDITOR_IMAGE_SEARCH_REQUEST: {
      if (action.request.params.folderId) {
        // handle assigning to a folder state
        return immutableUpdate(state, {
          personal: {
            currentTerm: {
              $set: action.request.params.term
            },
            folders: {
              $auto: {
                [action.request.params.folderId]: {
                  $auto: {
                    [action.request.params.term]: {
                      $auto: {
                        $merge: {
                          isFetching: true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }
      return immutableUpdate(state, {
        personal: {
          currentTerm: {
            $set: action.request.params.term
          },
          terms: {
            [action.request.params.term]: {
              $auto: {
                $merge: {
                  isFetching: true
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_IMAGE_SEARCH_CLEAR_CURRENT_TERM: {
      return immutableUpdate(state, {
        personal: {
          currentTerm: {
            $set: null
          }
        }
      });
    }

    case types.EDITOR_IMAGE_SEARCH_SUCCESS: {
      const ids = new Set([
        // ...(state.personal.terms[action.request.params.term].ids || []),
        ...(action.response.ids || [])
      ]);

      const newEntities = getPath(
        action,
        "response.entities.userTeamSearchedImage",
        {}
      );

      if (action.request.params.folderId) {
        return immutableUpdate(state, {
          personal: {
            folders: {
              $auto: {
                [action.request.params.folderId]: {
                  $auto: {
                    [action.request.params.term]: {
                      isFetching: { $set: false },
                      didInvalidate: { $set: false },
                      ids: { $set: [...ids] },
                      images: {
                        $auto: {
                          $set: newEntities
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      }

      return immutableUpdate(state, {
        personal: {
          terms: {
            [action.request.params.term]: {
              isFetching: { $set: false },
              didInvalidate: { $set: false },
              ids: { $set: [...ids] },
              images: {
                $auto: {
                  $set: newEntities
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_BRAND_IMAGE_SEARCH_REQUEST: {
      return immutableUpdate(state, {
        brand: {
          image: {
            terms: {
              [action.request.params.term]: {
                $auto: {
                  $merge: {
                    isFetching: true
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_BRAND_IMAGE_SEARCH_SUCCESS: {
      const ids = new Set([
        ...(state.brand.image.terms[action.request.params.term].ids || []),
        ...(action.response.ids || [])
      ]);

      const newEntities = getPath(
        action,
        "response.entities.brandSearchedImage",
        {}
      );

      return immutableUpdate(state, {
        brand: {
          image: {
            terms: {
              [action.request.params.term]: {
                isFetching: { $set: false },
                didInvalidate: { $set: false },
                ids: { $set: [...ids] },
                images: {
                  $auto: {
                    $merge: newEntities
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_BRAND_LOGO_SEARCH_REQUEST: {
      return immutableUpdate(state, {
        brand: {
          logo: {
            terms: {
              [action.request.params.term]: {
                $auto: {
                  $merge: {
                    isFetching: true
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_BRAND_LOGO_SEARCH_SUCCESS: {
      const ids = new Set([
        ...(state.brand.logo.terms[action.request.params.term].ids || []),
        ...(action.response.ids || [])
      ]);

      const newEntities = getPath(
        action,
        "response.entities.brandSearchedLogo",
        {}
      );

      return immutableUpdate(state, {
        brand: {
          logo: {
            terms: {
              [action.request.params.term]: {
                isFetching: { $set: false },
                didInvalidate: { $set: false },
                ids: { $set: [...ids] },
                images: {
                  $auto: {
                    $merge: newEntities
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_STOCK_IMAGE_SEARCH_REQUEST: {
      return immutableUpdate(state, {
        stock: {
          terms: {
            [action.request.params.term]: {
              $auto: {
                $merge: {
                  isFetching: true
                }
              }
            }
          }
        }
      });
    }

    case types.EDITOR_STOCK_IMAGE_SEARCH_SUCCESS: {
      const ids = new Set([
        ...(state.stock.terms[action.request.params.term].ids || []),
        ...(action.response.ids || [])
      ]);

      let newEntities = getPath(
        action,
        "response.entities.stockSearchedImage",
        {}
      );

      const nextPage = ids.size ? action.request.page + 1 : action.request.page;

      return immutableUpdate(state, {
        stock: {
          terms: {
            [action.request.params.term]: {
              isFetching: { $set: false },
              didInvalidate: { $set: false },
              nextPage: { $set: nextPage },
              ids: { $set: [...ids] },
              images: {
                $auto: {
                  $merge: newEntities
                }
              }
            }
          }
        }
      });
    }

    case userTeamImageFolderTypes.REMOVE_IMAGE_FROM_IMAGE_FOLDER_REQUEST_SUCCESS: {
      if (!state.personal.folders) {
        return state;
      }
      if (action.request.extra.term) {
        const ids =
          state.personal.folders[action.request.extra.folderId][
            action.request.extra.term
          ].ids;
        const idToRemove = action.request.extra.contentId;
        const newIds = ids.filter(id => id !== idToRemove);
        return immutableUpdate(state, {
          personal: {
            folders: {
              $auto: {
                [action.request.extra.folderId]: {
                  $auto: {
                    [action.request.extra.term]: {
                      isFetching: { $set: false },
                      didInvalidate: { $set: false },
                      ids: { $set: [...newIds] }
                      // images: {
                      //   $auto: {
                      //     $set: newEntities
                      //   }
                      // }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return state;
      }
    }

    case userTeamImageFolderTypes.BULK_MOVE_IMAGES_TO_USER_TEAM_FOLDER_REQUEST_SUCCESS: {
      if (!state.personal.folders) {
        return state;
      }

      const searchTerm = getPath(action.request, "extra.term");
      const currentFolderId = getParameterByName(
        "folderId",
        action.queryParams
      );

      if (searchTerm && currentFolderId) {
        const stateIds =
          state.personal.folders[currentFolderId][searchTerm].ids;
        const idsToRemove = action.request.body.mediaIds;
        const newIds = stateIds.filter(id => !idsToRemove.includes(id));
        return immutableUpdate(state, {
          personal: {
            folders: {
              $auto: {
                [currentFolderId]: {
                  $auto: {
                    [searchTerm]: {
                      isFetching: { $set: false },
                      didInvalidate: { $set: false },
                      ids: { $set: [...newIds] }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};

export default editorImageSearchReducers;
