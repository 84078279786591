import React, { Component } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import { canvasMargin } from "views/components/Editor/canvas/canvasConstants";
import { pick, noop, isNil, some } from "lib/lodash";
import { jsonStringEqual } from "lib/equalityUtils";
import { getElementComponent } from "views/components/Editor/elements/elementsComponentsMap";
import { SnapCoordinatesSystem } from "lib/SnapSystems";
import { parseLinkUrl } from "lib/parseLinkUrl";
import PATHS from "routes/paths";

/**
 * Implements the drag source contract.
 */
const DraggableElementSource = {
  canDrag(props) {
    const { restrictions } = props.element;
    const { areFunctionalitiesLocked, isTable2MovementRestricted } = props;

    return !(
      restrictions.includes("sizeAndPosition") ||
      restrictions.includes("position") ||
      areFunctionalitiesLocked ||
      isTable2MovementRestricted
    );
  },

  beginDrag(props, monitor, component) {
    const { element, toggleDragging } = props;

    const snapCoordinatesSystem = SnapCoordinatesSystem.Builder({
      pageId: props.pageId,
      excludeIds: [element.uniqueId]
    });

    toggleDragging(true);
    const rotatedElementBounding = component.element.firstElementChild.getBoundingClientRect();

    const topOffset =
      (rotatedElementBounding.height - props.element.height * props.zoom) / 2;

    const leftOffset =
      (rotatedElementBounding.width - props.element.width * props.zoom) / 2;

    return {
      id: props.element.uniqueId,
      left: props.element.left,
      top: props.element.top,
      element: props.element,
      topOffset: topOffset,
      leftOffset: leftOffset,
      pageId: props.pageId,
      fromPageIndex: props.pageNumber,
      groupId: props.groupId,
      initScrollVertical: props.containerHtmlElement.scrollTop,
      initScrollHorizontal: props.containerHtmlElement.scrollLeft,
      snapSystem: snapCoordinatesSystem
    };
  },
  endDrag(props, monitor) {
    /* handle when user press ESC while dragging*/
    if (!monitor.getDropResult()) {
      props.toggleDragging(false);
      return;
    }

    const { backgroundItemDrop } = monitor.getDropResult();

    let {
      delta,
      pageId: toPageId,
      pageNumber: toPageIndex,
      canvasHeight,
      verticalScrollDiff,
      horizontalScrollDiff
    } = monitor.getDropResult();

    const item = monitor.getItem();

    if (!isNil(backgroundItemDrop)) {
      const pageIndexOfDrop = backgroundItemDrop.getPageIndexOfDrop();
      if (!pageIndexOfDrop) {
        if (item.element.restrictions.includes("removable")) return;

        props.onDelete({
          id: item.id,
          pageId: item.pageId,
          groupId: item.groupId
        });
        return;
      } else {
        toPageId = window.easil.editor.designData.pagesOrder[pageIndexOfDrop];
        toPageIndex = pageIndexOfDrop + 1;
        canvasHeight = window.easil.editor.designData.height * props.zoom;
        delta = backgroundItemDrop.getMovementDelta();
        ({
          x: horizontalScrollDiff,
          y: verticalScrollDiff
        } = backgroundItemDrop.getScrollDiff());
      }
    }

    const left = item.left * props.zoom + delta.x;
    const top = item.top * props.zoom + delta.y;

    if (toPageId !== item.pageId) {
      const gutterZoomed = canvasMargin * 2 * props.zoom;
      const pageAndGutterZoomedSize = canvasHeight + gutterZoomed;

      const numberOfPagesMoved = Math.abs(item.fromPageIndex - toPageIndex);

      const pagesMovedOffset = pageAndGutterZoomedSize * numberOfPagesMoved;

      const directionFactor =
        item.top * props.zoom + verticalScrollDiff + delta.y < 0 ? 1 : -1;

      const newTop =
        top + verticalScrollDiff + pagesMovedOffset * directionFactor;

      return props.onMoveToPage({
        toPage: toPageId,
        id: item.id,
        left: left + horizontalScrollDiff,
        top: newTop,
        fromPage: item.pageId
      });
    }

    item.snapSystem.removeScrollEventListener();

    props.onMove({
      id: item.id,
      left: left + horizontalScrollDiff,
      top: top + verticalScrollDiff
    });

    // apply selection to element when drag has completed
    if (!props.isSelected) {
      props.onSelectItem({
        itemId: props.element.uniqueId,
        groupId: props.groupId,
        pageId: props.pageId
      });
    }
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

class DraggableElement extends Component {
  constructor(props) {
    super(props);

    this.setElementRef = element => {
      this.element = element;
    };

    const { ElementComponent, componentProps } = getElementComponent(
      props.element.type
    );

    this.onSelect = this.onSelect.bind(this);
    this.state = {
      ElementComponent,
      componentProps
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const elementPropsThatMatters = [
      ...this.state.componentProps,
      "type",
      "left",
      "top",
      "scaleX",
      "scaleY",
      "angle",
      "value",
      "displayValue",
      "restrictions",
      "svgInstructions"
    ];
    const elementData = pick(this.props.element, elementPropsThatMatters);
    const nextElementData = pick(nextProps.element, elementPropsThatMatters);

    if (!jsonStringEqual(elementData, nextElementData)) {
      return true;
    }

    const propsThatMatter = [
      "isDragging",
      "zoom",
      "isSelected",
      "isMoveCursorHidden",
      "isHighlighted"
    ];
    const currentPropsThatMatter = pick(this.props, propsThatMatter);
    const nextPropsThatMatter = pick(nextProps, propsThatMatter);

    return !jsonStringEqual(currentPropsThatMatter, nextPropsThatMatter);
  }

  componentDidUpdate(prevProps) {
    if (this.props.element.type !== prevProps.element.type) {
      const { ElementComponent, componentProps } = getElementComponent(
        this.props.element.type
      );

      this.setState({
        ElementComponent,
        componentProps
      });
      this.forceUpdate();
    }
  }

  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }
  }

  onSelect({ append }) {
    this.props.onSelectItem({
      itemId: this.props.element.uniqueId,
      groupId: this.props.groupId,
      pageId: this.props.pageId,
      append
    });
  }

  isAllRestrictionsLocked() {
    const { designData, element: elementData } = this.props;
    if (!designData || !elementData) return false;

    const element = designData.getElement(elementData.uniqueId);

    const restrictions = element.getRestrictionsMap();

    // if any restrictions are not locked then this is false
    return !some(
      Object.values(restrictions),
      restriction => restriction === false
    );
  }

  render() {
    const { left, top, scaleX, scaleY, angle } = this.props.element;
    const {
      connectDragSource,
      isSelected,
      isMoveCursorHidden,
      isDesignPartOfACollection,
      isHighlighted
    } = this.props;
    const { ElementComponent, componentProps } = this.state;

    const style = {
      transform: `rotate(${angle}deg) scaleX(${scaleX}) scaleY(${scaleY})`
    };

    const elementData = { ...pick(this.props.element, componentProps) };

    const isDraggingOrResizing = this.props.isDragging || this.props.isResizing;

    let opacity = "1";

    if (isDraggingOrResizing) {
      // elements should be transparent when drag or resize is happening
      opacity = "0";
    }

    if (!isHighlighted) {
      // not highlighted elements should be semi-transparent
      opacity = "0.2";
    }

    if (PATHS.isRenderer(window.location.pathname)) {
      // always have full opacity when rendering
      opacity = "1";
    }

    let styleWrapper = {
      // width: 0,
      // height: 0,
      cursor: isMoveCursorHidden ? "auto" : "move",
      position: "absolute",
      zIndex: "2",
      transform: `translate3d(${left * this.props.zoom}px, ${top *
        this.props.zoom}px, 0) scale(${this.props.zoom})`,
      transformOrigin: "0 0",
      opacity,
      visibility: this.props.hide ? "hidden" : "visible"
    };

    const startImageCropMode = this.props.element.restrictions.includes(
      "cropping"
    )
      ? noop
      : this.props.startImageCropMode;

    const startVideoCropMode = this.props.element.restrictions.includes(
      "cropping"
    )
      ? noop
      : this.props.startVideoCropMode;

    const startVectorCropMode = this.props.element.restrictions.includes(
      "cropping"
    )
      ? noop
      : this.props.startVectorCropMode;

    const isAllRestrictionsLocked = this.isAllRestrictionsLocked();

    if (PATHS.isRenderer(window.location.pathname) && elementData.link) {
      // ensure link is valid and add protocol if it is missing
      const parsedLink = parseLinkUrl(elementData.link);
      return (
        <a
          ref={this.setElementRef}
          style={{
            ...styleWrapper,
            width: "auto",
            height: "auto",
            display: "block"
          }}
          id={this.props.element.uniqueId}
          href={parsedLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ElementComponent
            zoom={this.props.zoom}
            isPreview={this.props.isPreview}
            style={style}
            key={this.props.element.uniqueId}
            elementId={this.props.element.uniqueId}
            elementData={elementData}
            connectDragSource={connectDragSource}
            onSelectItem={this.onSelect}
            onElementAttributeChange={this.props.onElementAttributeChange}
            onElementPreview={this.props.onElementPreview}
            startImageCropMode={startImageCropMode}
            startVideoCropMode={startVideoCropMode}
            startVectorCropMode={startVectorCropMode}
            isSelected={isSelected}
            restrictions={this.props.element.restrictions}
            pageId={this.props.pageId}
            groupId={this.props.groupId}
            isDesignPartOfACollection={isDesignPartOfACollection}
            isAllRestrictionsLocked={isAllRestrictionsLocked}
          />
        </a>
      );
    }

    return (
      <div
        ref={this.setElementRef}
        style={styleWrapper}
        id={this.props.element.uniqueId}
      >
        <ElementComponent
          zoom={this.props.zoom}
          isPreview={this.props.isPreview}
          style={style}
          key={this.props.element.uniqueId}
          elementId={this.props.element.uniqueId}
          elementData={elementData}
          connectDragSource={connectDragSource}
          onSelectItem={this.onSelect}
          onElementAttributeChange={this.props.onElementAttributeChange}
          onElementPreview={this.props.onElementPreview}
          startImageCropMode={startImageCropMode}
          startVideoCropMode={startVideoCropMode}
          startVectorCropMode={startVectorCropMode}
          isSelected={isSelected}
          restrictions={this.props.element.restrictions}
          pageId={this.props.pageId}
          groupId={this.props.groupId}
          isDesignPartOfACollection={isDesignPartOfACollection}
          isAllRestrictionsLocked={isAllRestrictionsLocked}
          isResizing={this.props.isResizing}
        />
      </div>
    );
  }
}

// Export the wrapped component:
export default DragSource(
  "ITEM",
  DraggableElementSource,
  collect
)(DraggableElement);
