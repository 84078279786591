import React from "react";
import style from "./style.module.css";
import { NavLink } from "react-router-dom";
import {} from "../../components";
import CircledQuestionMarkIcon from "views/components/icons/CircledQuestionMarkIcon";
import EllipsisIcon from "views/components/icons/EllipsisIcon";
import SimpleCartIcon from "views/components/icons/SimpleCartIcon";

const leftLinks = ["designs", "templates", "brand", "team"];

export class HeaderBottomPanelRight extends React.Component {
  constructor(props) {
    super(props);

    this.handleSetLinkPopout = this.handleSetLinkPopout.bind(this);
    this.ellipsisRef = React.createRef();
    this.handlePopoutClickOutside = this.handlePopoutClickOutside.bind(this);

    this.state = {
      linkPopoutOpen: false
    };
  }

  handleSetLinkPopout(linkPopoutOpen) {
    this.setState({
      linkPopoutOpen
    });
  }

  componentDidMount() {
    document
      .getElementById("root")
      .addEventListener("click", this.handlePopoutClickOutside);
  }

  componentWillUnmount() {
    document
      .getElementById("root")
      .removeEventListener("click", this.handlePopoutClickOutside);
  }

  handlePopoutClickOutside(event) {
    const { ellipsisRef } = this;

    if (
      ellipsisRef.current &&
      !ellipsisRef.current.contains(event.target) &&
      // ignore targets that are not in the dom at the time of trigger
      event.target.isConnected
    ) {
      this.handleSetLinkPopout(false);
    }
    return event;
  }

  render() {
    const {
      onToggleHelpWindow,
      links,
      isOrganisation,
      isAdmin,
      isUserChatReady,
      popoutLinks,
      currentOrder
    } = this.props;

    const isOrdersVisible = !popoutLinks.find(
      link => link.title === "My Orders"
    );
    const isSubscriptionVisible = !popoutLinks.find(
      link => link.title === "Subscription"
    );

    const leftPopoutLinks = [];
    const rightPopoutLinks = [];
    popoutLinks.forEach(popoutLink => {
      if (leftLinks.includes(popoutLink.key)) {
        leftPopoutLinks.push(popoutLink);
      } else {
        rightPopoutLinks.push(popoutLink);
      }
    });

    return (
      <div data-testid="HeaderBottomPanelRight">
        {isOrganisation && isAdmin && isSubscriptionVisible && (
          <NavLink
            key={links.subscription.title}
            className={style.navlink}
            activeClassName={style.navlinkActive}
            to={links.subscription.url || "/"}
          >
            {links.subscription.title}
          </NavLink>
        )}
        {isOrdersVisible && (
          <NavLink
            key={links.orders.title}
            className={`${style.navlink}  ${style.marginRight}`}
            activeClassName={style.navlinkActive}
            to={links.orders.url || "/"}
          >
            {links.orders.title}
          </NavLink>
        )}
        {popoutLinks.length > 0 && (
          <div
            className={style.linkEllipsisWrapper}
            onClick={() => {
              this.handleSetLinkPopout(!this.state.linkPopoutOpen);
            }}
            ref={this.ellipsisRef}
          >
            <EllipsisIcon
              color="white"
              className={style.bottomPanelIcon}
              size="24"
            />
            {this.state.linkPopoutOpen && (
              <div className={style.linkPopout}>
                {leftPopoutLinks.length > 0 && (
                  <>
                    {leftPopoutLinks.map(link => (
                      <div className={style.popoutNavlinkWrapper}>
                        <NavLink
                          key={link.title}
                          className={`${style.popoutNavlink}`}
                          activeClassName={style.navlinkActive}
                          to={link.url || "/"}
                        >
                          {link.title}
                        </NavLink>
                      </div>
                    ))}
                    <div className={style.popoutLinksSeperator} />
                  </>
                )}
                {rightPopoutLinks.length > 0 &&
                  rightPopoutLinks.map(link => (
                    <div className={style.popoutNavlinkWrapper}>
                      <NavLink
                        key={link.title}
                        className={`${style.popoutNavlink}`}
                        activeClassName={style.navlinkActive}
                        to={link.url || "/"}
                      >
                        {link.title}
                      </NavLink>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
        <div
          className={style.navIcon}
          onClick={onToggleHelpWindow}
          data-disabled={!isUserChatReady}
          data-testid={"SupportButton"}
        >
          <CircledQuestionMarkIcon
            color="white"
            className={style.bottomPanelIcon}
            onClick={onToggleHelpWindow}
            size="24"
          />
        </div>
        <NavLink
          className={style.navIcon}
          activeClassName={style.navlinkActive}
          to={links.cart.url || "/"}
        >
          <SimpleCartIcon
            color="white"
            className={style.bottomPanelIcon}
            size="24"
          />
          {currentOrder &&
            currentOrder.designs &&
            currentOrder.designs.length > 0 && (
              <div className={style.cartContentIndicator}>
                {currentOrder.designs.length}
              </div>
            )}
        </NavLink>
      </div>
    );
  }
}

HeaderBottomPanelRight.displayName = "HeaderBottomPanelRight";

export default HeaderBottomPanelRight;
