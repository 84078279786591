import React, { Component } from "react";
import style from "./style.module.css";
import { Modal, Input, Tooltip } from "views/components";
import InfoAlert from "views/components/InfoAlert/InfoAlert";
import InfoIcon from "views/components/icons/InfoIcon";
import InfoHollowIcon from "views/components/icons/InfoHollowIcon";
import { stripHTML } from "lib/textUtils";
import QuickInput from "views/components/QuickInput/QuickInput";

class UpdateTeamSmartTextModal extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.canSave = this.canSave.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.generateInfoAlert = this.generateInfoAlert.bind(this);

    this.state = {
      newName: this.props.selectedRow.name,
      newValue: this.props.selectedRow.value,
      error: null
    };
  }

  onChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  canSave() {
    const { newName, newValue, error } = this.state;

    if (newName === null || newValue === null) {
      return false;
    }

    if (
      newName !== null &&
      newName === "" &&
      newValue !== null &&
      newValue === ""
    ) {
      return false;
    }

    if (error && error !== null) {
      return false;
    }

    return true;
  }

  handleSave() {
    const { newName, newValue } = this.state;

    const cleanValue = stripHTML(newValue);

    const smartText = {
      [newName]: { value: cleanValue, type: "string" }
    };

    this.props.updateTeamSmartText({
      smartText,
      smartTextKey: this.props.selectedRow.name,
      onSuccess: () => this.props.onCloseModal()
    });
  }

  handleBlur() {
    const { newName } = this.state;
    const { smartText, selectedRow } = this.props;

    const isSmartTextInUse = () => {
      // remove current smart field name
      const filteredSmartText = smartText.filter(
        text => text.name !== selectedRow.name
      );
      return filteredSmartText.some(text => text.name === newName);
    };

    let error;

    switch (true) {
      case !newName:
        error = <span>Enter a smart text ID.</span>;
        this.setState({ error: error });
        break;
      case isSmartTextInUse():
        error = <span>Already in use.</span>;
        this.setState({ error: error });
        break;
      case newName && newName.length <= 1:
        error = <span>Must contain at least 2 characters.</span>;
        this.setState({ error: error });
        break;
      case newName && !newName.match(/^[-a-zA-Z0-9._]+$/):
        error = <span>Cannot contain invalid characters.</span>;
        this.setState({ error: error });
        break;
      case newName &&
        !newName.match(/^(?!.*[._-]{2})[a-zA-Z0-9]+[._-]?[a-zA-Z0-9]+$/):
        error = <span>Cannot contain more than one separator.</span>;
        this.setState({ error: error });
        break;
      case newName && newName.startsWith("user."):
        error = (
          <span>
            User Smart Text can be added in{" "}
            <span
              onClick={() => this.props.moveToWorkspaceSmartText()}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              My Uploads.
            </span>
          </span>
        );
        this.setState({ error: error });
        break;
      default:
        this.setState({ error: null });
        return;
    }
  }

  handleKeyDown(event) {
    if (event.code === "Space") event.preventDefault();
  }

  generateInfoAlert() {
    const { selectedRow } = this.props;
    const selectedRowName = selectedRow.name.split(".")[1];
    if (selectedRow.hasOwnProperty("overridden") && !selectedRow.overridden) {
      return (
        <InfoAlert
          className={style.info}
          theme={"blue"}
          hasIcon={false}
          spanClass={style.infoText}
          iconSize={"32px"}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <InfoHollowIcon
              size={"20px"}
              className={style.infoIcon}
              color={style.easilBlue}
            />{" "}
            {`Overriding the `}
            <span
              style={{ fontWeight: 500, marginLeft: "3px", marginRight: "3px" }}
            >
              Team {selectedRowName}{" "}
            </span>
            {` field will not update the`}
          </div>
          <div style={{ marginLeft: "33px" }}>
            {`${selectedRowName} linked to your Easil account.`}
          </div>
        </InfoAlert>
      );
    }
    return (
      <InfoAlert
        className={style.info}
        theme={"yellow"}
        hasIcon={false}
        spanClass={style.infoText}
        iconSize={"32px"}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <InfoHollowIcon
            size={"20px"}
            className={style.infoIcon}
            color="#ffc100"
          />{" "}
          Changing the Smart Text ID will unlink any existing
        </div>
        <div style={{ marginLeft: "33px" }}>designs using the current ID.</div>
      </InfoAlert>
    );
  }

  render() {
    const { isOpen, onCloseModal, selectedRow } = this.props;
    const { newName, newValue, error } = this.state;

    const getInputFieldError = () => error;

    const labelErrorColor = {
      color: "#c01818",
      marginBottom: "8px"
    };

    const labelColor = {
      color: "#7a7d81",
      marginBottom: "8px"
    };

    const isOverridden =
      !selectedRow.hasOwnProperty("overridden") ||
      (selectedRow.hasOwnProperty("overridden") && selectedRow.overridden);

    return (
      <Modal
        className={style.updateSmartTextModal}
        isOpen={isOpen}
        contentLabel="Update Smart Text"
        onRequestClose={onCloseModal}
        header="Update Smart Text"
        buttonsClassName={style.modalButtons}
        buttons={[
          {
            name: "Cancel",
            theme: "gray",
            onClick: onCloseModal
          },
          {
            name: "Save",
            theme: "blueSolid",
            onClick: this.handleSave,
            disabled: !this.canSave()
          }
        ]}
      >
        <div className={style.modalFields}>
          <div className={style.modalInputRow}>
            <div
              style={error ? labelErrorColor : labelColor}
              className={!isOverridden ? style.smartTextIdLabel : ""}
            >
              <span
                style={
                  error
                    ? { fontWeight: 500, paddingRight: "4px" }
                    : { fontWeight: "normal", paddingRight: "4px" }
                }
              >
                Smart Text ID
              </span>
              {getInputFieldError()}
              {!isOverridden && (
                <div
                  data-tip={`Cannot edit ID of default Smart Text item`}
                  data-for="smartTextId"
                >
                  <InfoIcon size="12px" color="#7a7d81" />
                  <Tooltip
                    id="smartTextId"
                    place="bottom"
                    className={style.tooltip}
                    multiline={true}
                  />
                </div>
              )}
            </div>
            <QuickInput
              name="newName"
              onChange={this.onChange}
              value={newName}
              placeholder="Enter Smart Text ID"
              className={style.modalInputSplit}
              inputClassName={style.modalInput}
              onBlur={this.handleBlur}
              hasInputError={getInputFieldError()}
              errorColor={"#c01818"}
              onKeyDown={this.handleKeyDown}
              disabled={!isOverridden}
            />
          </div>
          {this.generateInfoAlert()}
          <div className={style.modalInputRow}>
            <div style={labelColor}>Smart Text Content</div>
            <Input
              name="newValue"
              onChange={this.onChange}
              value={newValue}
              placeholder="Type your Smart Text content here"
              className={style.modalInputWrapper}
              inputClassName={style.modalInput}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default UpdateTeamSmartTextModal;
