import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const FillFrameIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  viewbox = ""
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      data-no-pointer={noPointer}
      width={width || size || 20}
      height={height || size || 20}
      aria-labelledby="title"
      fill={color || easilGrayXDark}
      viewBox={viewbox}
      data-testid="FillFrameIcon"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M20 0v20H0V0z" />
        <path
          stroke={color || easilGrayXDark}
          stroke-width="2"
          d="m12 12 4 4m1-3v4h-4M8 8 4 4M3 7V3h4M8 12l-4 4m3 1H3v-4M12 8l4-4m-3-1h4v4"
        />
      </g>
    </svg>
  );
};
FillFrameIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  rotate: PropTypes.string
};

export default FillFrameIcon;
