import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const VideoInEditorContext = lazy(() => import("./VideoInEditorContext"));

export const VideoInEditorContextLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <VideoInEditorContext {...props} />
  </Suspense>
);
VideoInEditorContextLazy.displayName = "VideoInEditorContext";

export default VideoInEditorContextLazy;
