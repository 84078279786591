import React from "react";
import { Button, Loading } from "views/components";
import buttonStyle from "./style.module.css";

const getButtonLabel = (isLoading, type) => {
  if (isLoading) {
    return <Loading size="24px" />;
  }
  return <div>Load More {`${type}`}</div>;
};

export const MoreButton = ({ loadMore, isLoading, type, style = {} }) => (
  <div className={buttonStyle.buttonWrapper}>
    <Button
      isFullWidth={true}
      theme="loadMoreButton"
      onClick={loadMore}
      style={style}
    >
      {getButtonLabel(isLoading, type)}
    </Button>
  </div>
);

export default MoreButton;
