import React from "react";
import PropTypes from "prop-types";
import { keyCodes } from "lib";
class keyboardShortcuts extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeydown = this.handleKeydown.bind(this);
  }

  handleKeydown(event) {
    if (!document.body.contains(event.target)) {
      return;
    }

    const editorSidebarDom = document.querySelector("#editor-sidebar");
    const editorActionbarDom = document.querySelector("#editor-actionbar");
    const editorHeaderDom = document.querySelector("#editor-header");
    const editorDom = document.querySelector("#editor-core");

    // prevent keyboard shortcuts from interrupting default shortcuts for sidebar, actionbar and header
    if (
      // the current target is in the editor sidebar, actionbar, or header
      (editorSidebarDom && editorSidebarDom.contains(event.target)) ||
      (editorActionbarDom && editorActionbarDom.contains(event.target)) ||
      (editorHeaderDom && editorHeaderDom.contains(event.target)) ||
      // or the current target is not inside the editor context
      (editorDom &&
        !editorDom.contains(event.target) &&
        !event.target.isEqualNode(document.body))
    ) {
      // we should pass on control
      return;
    }

    this.stopDefaultBackspaceBehaviour(event);

    // SHORTCUTS NOT BLOCKED BY FUNCTIONALITY LOCK START
    switch (event.keyCode) {
      // ZOOM IN FUNCTION
      case keyCodes.plusKeyStandard:
      case keyCodes.plusKeyFirefox:
      case keyCodes.plusKeyNumPad:
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          if (this.props.isInCroppingMode) {
            this.props.cancelCurrentCropMode();
            return;
          }
          this.props.bumpZoomUp();
        }
        break;
      //ZOOM OUT FUNCTION
      case keyCodes.minusKeyStandard:
      case keyCodes.minusKeyFirefox:
      case keyCodes.minusKeyNumPad:
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          if (this.props.isInCroppingMode) {
            this.props.cancelCurrentCropMode();
            return;
          }
          this.props.bumpZoomDown();
        }
        break;

      default:
        break;
    }
    // SHORTCUTS NOT BLOCKED BY FUNCTIONALITY LOCK END

    // we always want this to check before any of the functionality that are affected by locking
    // if editor functionalities are locked then so should keyboard shortcuts
    if (this.props.areFunctionalitiesLocked) return;

    // SHORTCUTS BLOCKED BY FUNCTIONALITY LOCK START
    switch (event.keyCode) {
      //UNDO FUNCTION
      /* eslint-disable-next-line */
      case keyCodes.zKey: {
        if (!event.ctrlKey && !event.metaKey) return;

        if (event.shiftKey) {
          if (this.props.isInCroppingMode) {
            this.props.cancelCurrentCropMode();
            return;
          }
          this.props.onRedo();
        } else {
          if (this.props.isInCroppingMode) {
            this.props.cancelCurrentCropMode();
            return;
          }
          this.props.onUndo();
        }
        break;
      }

      case keyCodes.yKey: {
        if (!event.ctrlKey && !event.metaKey) return;
        if (this.props.isInCroppingMode) {
          this.props.cancelCurrentCropMode();
          return;
        }
        this.props.onRedo();
        break;
      }

      //DELETE ELEMENT FUNCTION
      case keyCodes.backspaceKey:
      case keyCodes.deleteKey: {
        if (this.props.isInCroppingMode) {
          this.props.cancelCurrentCropMode();
          return;
        }
        this.props.onDelete();
        break;
      }

      //BUMP ELEMENT FUNCTION
      case keyCodes.upKey:
      case keyCodes.downKey:
      case keyCodes.leftKey:
      case keyCodes.rightKey: {
        if (this.props.isInCroppingMode) {
          this.props.cancelCurrentCropMode();
          return;
        }
        this.props.onBump(event);
        break;
      }

      // SELECT ALL ELEMENTS ON PAGE FUNCTION
      case keyCodes.aKey:
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          if (this.props.isInCroppingMode) {
            this.props.cancelCurrentCropMode();
            return;
          }
          /* ctrl-a or cmd-a should select all elements on a page */
          this.props.onSelectAll();
        }
        break;

      // COPY SELECTION TO CLIPBOARD
      case keyCodes.cKey:
        if (event.ctrlKey || event.metaKey) {
          if (this.props.isInCroppingMode) {
            this.props.cancelCurrentCropMode();
            return;
          }
          /* ctrl-c or cmd-c should copy all selected elements to clipBoard */
          event.preventDefault();
          this.props.copyElementsToClipBoard();
        }
        break;

      // PASTE SELECTION FROM CLIPBOARD
      case keyCodes.vKey:
        if (event.ctrlKey || event.metaKey) {
          if (this.props.isInCroppingMode) {
            this.props.cancelCurrentCropMode();
            return;
          }
          /* ctrl-v or cmd-v should paste all elements from clipBoard */
          event.preventDefault();
          this.props.pasteElementsFromClipBoard();
        }
        break;

      default:
        break;
    }
    // SHORTCUTS BLOCKED BY FUNCTIONALITY LOCK END
  }

  handleKeyPress(event) {
    this.stopDefaultBackspaceBehaviour(event);
  }

  stopDefaultBackspaceBehaviour(event) {
    event = event || window.event;
    if (event.keyCode === keyCodes.backspaceKey) {
      var elements = "HTML, BODY, TABLE, TBODY, TR, TD, DIV";
      var source = event.srcElement || event.target;
      var regex = new RegExp(source.tagName.toUpperCase());
      if (source.contentEditable !== "true") {
        //it's not REALLY true, checking the boolean value (!== true) always passes, so we can use != 'true' rather than !== true/
        if (regex.test(elements)) {
          event.preventDefault
            ? event.preventDefault()
            : (event.returnValue = false);
        }
      }
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeydown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  }

  render() {
    return null;
  }
}

keyboardShortcuts.propTypes = {
  onUndo: PropTypes.func,
  onRedo: PropTypes.func
};

export default keyboardShortcuts;
