import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import * as designFoldersTypes from "state/entities/designFolders/designFoldersTypes";
import { findPage, fixPagesIds } from "state/api/designs/helpers";
import { flatten, getPath, chunk } from "lib";
import { insertItem, replaceItem, removeItem } from "lib/array/array";
import { immutableUpdate } from "lib/immutableUpdate";
import PATHS from "routes/paths";
import { draftDesignsApiLocalSelector } from "./draftsApiSelector";
import { getParameterByName } from "lib/queryStringUtils";

export const initState = {
  detailedIds: {},
  totalCount: null,
  pageSize: 50,
  sizes: {
    all: {
      pages: {}
    }
  }
};

const draftsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_DRAFTS_REQUEST: {
      const size = getPath(action, "request.params.size") || "all";

      const page = {
        isFetching: true,
        lastFetched: Date.now()
      };

      return immutableUpdate(state, {
        sizes: {
          [size]: {
            $auto: {
              pages: {
                $auto: {
                  [action.request.page]: {
                    $auto: {
                      $merge: {
                        ...page
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case designsEntitiesTypes.DESIGNS_DRAFTS_REQUEST_SUCCESS: {
      const {
        request: { page },
        response: { ids }
      } = action;

      const size = getPath(action, "request.params.size") || "all";

      const updatedPage = {
        isFetching: false,
        ids,
        didInvalidate: false,
        lastFetched: Date.now()
      };

      return immutableUpdate(state, {
        sizes: {
          [size]: {
            $auto: {
              pages: {
                $auto: {
                  [page]: {
                    $auto: {
                      $merge: {
                        ...updatedPage
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case designsEntitiesTypes.DESIGN_CANCEL_APPROVAL_REQUEST_SUCCESS: {
      const {
        request: {
          body: { designId }
        },
        extra: { size = "all" } = {}
      } = action;

      const pages = draftDesignsApiLocalSelector(state, size);

      const newIds = [designId].concat(pages[1] ? pages[1].ids : []);

      const updatedPage = {
        1: {
          isFetching: false,
          ids: newIds,
          didInvalidate: false
        }
      };

      return {
        ...state,
        sizes: {
          [size]: {
            pages: { ...pages, ...updatedPage }
          }
        }
      };
    }

    case designsEntitiesTypes.DESIGN_REVERT_APPROVAL_REQUEST_SUCCESS: {
      const {
        response: { ids: designIds },
        extra: { size = "all" } = {}
      } = action;

      const pages = draftDesignsApiLocalSelector(state, size);

      const newIds = designIds.concat(pages[1].ids);

      const updatedPage = {
        1: {
          isFetching: false,
          ids: newIds,
          didInvalidate: false
        }
      };

      return {
        ...state,
        sizes: {
          [size]: {
            pages: { ...pages, ...updatedPage }
          }
        }
      };
    }

    case designsEntitiesTypes.DESIGNS_DRAFTS_COPY_REQUEST:
    case designsEntitiesTypes.DESIGNS_DRAFTS_CREATE_REQUEST:
    case designsEntitiesTypes.DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST: {
      const {
        request: {
          body: { parentId }
        },
        extra: { provisionalId, size = "all", folderId }
      } = action;

      if (folderId) return state;

      const pages = draftDesignsApiLocalSelector(state, size);

      const allDraftIds = flatten(
        Object.keys(pages)
          .sort()
          .map(i => pages[i].ids)
      );

      const copiedDraftPosition = allDraftIds.indexOf(parentId);

      const allDraftIdsWithCopy = insertItem(
        allDraftIds,
        copiedDraftPosition,
        provisionalId
      );

      return {
        ...state,
        sizes: {
          [size]: {
            pages: fixPagesIds({
              ids: allDraftIdsWithCopy,
              state: { ...state, pages: state.sizes[size].pages }
            })
          }
        }
      };
    }

    case designsEntitiesTypes.DESIGNS_DRAFTS_COPY_REQUEST_SUCCESS:
    case designsEntitiesTypes.DESIGNS_DRAFTS_CREATE_REQUEST_SUCCESS:
    case designsEntitiesTypes.DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST_SUCCESS: {
      const {
        extra: { provisionalId, size = "all", folderId }
      } = action;

      if (folderId) return state;

      const pages = draftDesignsApiLocalSelector(state, size);

      const page = findPage(pages, provisionalId);
      const ids = pages[page].ids;

      const newIds = replaceItem(
        ids,
        ids.indexOf(provisionalId),
        action.response.ids
      );
      const pageUpdated = {
        ...pages[page],
        ids: newIds
      };

      return {
        ...state,
        sizes: {
          [size]: {
            pages: {
              ...pages,
              [page]: {
                ...pageUpdated
              }
            }
          }
        }
      };
    }

    case designsEntitiesTypes.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST: {
      // return early if in a folder
      const folderId = getParameterByName("folderId", action.queryParams);
      if (folderId) return state;

      const { body: draftsChanged } = action.request;
      const size = getParameterByName("size", action.queryParams) || "all";

      const pages = draftDesignsApiLocalSelector(state, size);

      const allDraftIds = flatten(
        Object.keys(pages)
          .sort()
          .map(i => pages[i].ids)
      );

      const draftsChangedIds = draftsChanged.map(draft => draft.id);

      const allDraftsIdsWithoutUpdateds = allDraftIds.filter(
        draftId => !draftsChangedIds.includes(draftId)
      );

      return {
        ...state,
        pages: fixPagesIds({
          ids: allDraftsIdsWithoutUpdateds,
          state: { ...state, pages: state.sizes[size].pages }
        })
      };
    }

    case designFoldersTypes.ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST_SUCCESS: {
      const designs = action.response;
      const designIdMap = {};
      const sizes = {};

      designs.forEach(({ designId }) => {
        designIdMap[designId] = true;
      });

      Object.entries(state.sizes).forEach(([size, { pages, ...sizeOther }]) => {
        sizes[size] = { pages: {}, ...sizeOther };

        Object.entries(pages).forEach(([pageNumber, { ids, ...pageOther }]) => {
          sizes[size].pages[pageNumber] = {
            ids: ids.filter(id => !designIdMap[id]),
            ...pageOther
          };
        });
      });

      return { ...state, sizes };
    }

    case designFoldersTypes.MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST_SUCCESS: {
      const designIdsToBeRemoved = action.response.map(
        design => design.body.designId
      );
      const size = getPath(action, "request.body.size", "all");
      const currentPages = getPath(state, `sizes.${size}.pages`, {});

      const allIds = Object.values(currentPages).reduce(
        (previousValues, currentPage) => previousValues.concat(currentPage.ids),
        []
      );

      // remove designIds from response
      const updatedAllIds = allIds.filter(
        id => !designIdsToBeRemoved.includes(id)
      );

      // split ids into chunks of page size
      const idsSplitIntoPages = chunk(updatedAllIds, state.pageSize);

      // build the pages
      const newPages = idsSplitIntoPages.reduce(
        (previousPages, ids, index) => ({
          ...previousPages,
          [index + 1]: {
            isFetching: false,
            ids,
            didInvalidate: false,
            lastFetched: Date.now()
          }
        }),
        {}
      );

      return immutableUpdate(state, {
        sizes: {
          $auto: {
            [size]: {
              $auto: {
                $set: {
                  pages: newPages
                }
              }
            }
          }
        }
      });
    }

    case designsEntitiesTypes.DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS: {
      if (action.pathname !== PATHS.workspaceDrafts) {
        return state;
      }

      const {
        request: {
          body: { designId }
        },
        extra: { size = "all" } = {}
      } = action;

      const pages = draftDesignsApiLocalSelector(state, size);

      const page = findPage(pages, designId);
      if (!page) return state;
      const imageIndex = pages[page].ids.indexOf(designId);

      const pageUpdated = {
        ...pages[page],
        ids: removeItem(pages[page].ids, imageIndex)
      };
      return {
        ...state,
        sizes: {
          [size]: {
            pages: {
              ...pages,
              [page]: pageUpdated
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default draftsApiReducers;
