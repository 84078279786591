import React from "react";

const ResizeIcon = ({ color = "#fff" }) => (
  <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke={color}>
      <path
        d="M6 8V5.25A2.28 2.28 0 018.3 3h10.4C19.96 3 21 4 21 5.25v13.5c0 1.24-.72 2.25-2 2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M4 12h10a1 1 0 011 1v7a1 1 0 01-1 1H4a1 1 0 01-1-1v-7a1 1 0 011-1z"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default ResizeIcon;
