import * as types from "./purchaseCollectionModalTypes";
import * as ordersTypes from "state/entities/orders/ordersTypes";
import { CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS } from "state/currentUser/currentUserTypes";
import { formatErrors, omit } from "lib";

const errorsArray = error => formatErrors(error);

export const initState = {
  loading: {
    processing: false
  },
  orderId: null,
  token: null,
  paymentType: "",
  errors: [
    // VV of style VV
    // {
    //   type: "billing",
    //   description: "field must be filled",
    //   fields: ["name"]
    // }
  ],
  isOpen: false,
  collectionId: null
};

const purchaseCollectionModalReducers = (state = initState, action) => {
  switch (action.type) {
    // Team Switch Action
    case CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS: {
      // Clear the state to init when changing teams
      return { ...initState };
    }

    case types.UI_PURCHASE_COLLECTION_SET_CURRENT_ORDER_ID: {
      return {
        ...state,
        orderId: action.orderId
      };
    }

    case types.UI_PURCHASE_COLLECTION_SET_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.loading
        }
      };
    }

    case types.UI_PURCHASE_COLLECTION_SET_TOKEN: {
      const { token } = action;

      return {
        ...state,
        token
      };
    }

    case types.UI_PURCHASE_COLLECTION_SET_PAYMENT_TYPE: {
      const { paymentType } = action;
      // set the payment type and clear any errors
      return {
        ...state,
        paymentType,
        errors: []
      };
    }

    case types.UI_PURCHASE_COLLECTION_SET_COUPON_ERROR: {
      const { couponErrors } = state;

      if (!couponErrors) return state;

      return omit(state, "couponErrors");
    }

    case types.UI_PURCHASE_COLLECTION_SET_POLLING_ERROR: {
      const { pollingError } = action;

      return {
        ...state,
        pollingError
      };
    }

    case types.UI_PURCHASE_COLLECTION_MODAL_OPEN: {
      const { collectionId } = action;

      return {
        ...state,
        isOpen: true,
        collectionId
      };
    }

    case types.UI_PURCHASE_COLLECTION_MODAL_CLOSE: {
      return initState;
    }

    case ordersTypes.CREATE_ORDER_REQUEST_FAILURE:
    case ordersTypes.CREATE_ORDER_PAYMENT_REQUEST_FAILURE: {
      const { error } = action;
      const errors = errorsArray(error);

      return {
        ...state,
        errors,
        loading: {
          processing: false,
          coupon: false
        },
        retryPayment: true
      };
    }

    case ordersTypes.ADD_COUPON_TO_ORDER_REQUEST_SUCCESS: {
      // set a coupon flag on initial coupon success application
      return {
        ...state,
        couponAdded: true
      };
    }

    case types.UI_PURCHASE_COLLECTION_CLEAR_PAYMENT_ERROR: {
      const { errors } = state;

      if (!errors) return state;

      return {
        ...state,
        errors: []
      };
    }

    case types.UI_PURCHASE_COLLECTION_CLEAR: {
      return initState;
    }

    default:
      return state;
  }
};

export default purchaseCollectionModalReducers;
