import { sanitizeHTMLForV1 } from "lib/textUtils";
import { sixDigitHexColor } from "lib/Colors/colorUtils";
import { DEFAULT_SHADOW } from "lib/constants";
import { getFontByName } from "lib/fonts";

/*
 * Determines if the current shadow object matches the default 'no shadow' settings.
 */
function isShadowEnabled(shadowObj) {
  if (!shadowObj) return false;

  const isDefaultAngle = Number(shadowObj.angle) === DEFAULT_SHADOW.angle;
  const isDefaultBlur = Number(shadowObj.blur) === DEFAULT_SHADOW.blur;
  const isDefaultDistance =
    Number(shadowObj.distance) === DEFAULT_SHADOW.distance;
  const isDefaultColor =
    sixDigitHexColor(shadowObj.color) === DEFAULT_SHADOW.color;

  return !(
    isDefaultAngle &&
    isDefaultBlur &&
    isDefaultDistance &&
    isDefaultColor
  );
}

function formatShadow(textboxFormatted) {
  textboxFormatted.shadow = {
    ...textboxFormatted.shadow,
    enabled: isShadowEnabled(textboxFormatted.shadow)
  };
}

const formatTextboxDataForV1 = textboxData => {
  /* sanitize the textbox value with renderer sanitizing */
  const value = sanitizeHTMLForV1(textboxData.value);

  const textboxFormatted = {
    source: "textbox",
    ...textboxData,
    value
  };

  if (textboxFormatted.hasOwnProperty("shadow")) {
    formatShadow(textboxFormatted);
  }

  if (!textboxFormatted.fontId) {
    const font = getFontByName(textboxFormatted.fontFamily);
    if (font) {
      textboxFormatted.fontId = font.fontId;
    }
  }

  return textboxFormatted;
};

export default formatTextboxDataForV1;
