import React from "react";
import TextMaskResizeHandler from "./TextMaskResizeHandler";
import { connect } from "react-redux";
import {
  contextSelector,
  selectedItemsSelector,
  zoomSelector,
  designDataSelector,
  elementPreviewsSelector
} from "state/ui/editorContext/editorContextSelectors";
import { getPath } from "lib/lodash";
import { getPageOffsetInCanvasbackground } from "views/components/Editor/utils";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { getCanvasbackground } from "views/components/Editor/utils";
import { calculateTextMaskImageScaledNewDimensions } from "lib/croppingUtils";
import { Logger } from "lib";

class TextMaskResizeHandlerContainer extends React.Component {
  constructor(props) {
    super(props);

    this.getElementWithPreview = this.getElementWithPreview.bind(this);
    this.getPageOffsetInCanvasbackground = this.getPageOffsetInCanvasbackground.bind(
      this
    );
    this.getElementWithPreview = this.getElementWithPreview.bind(this);
    this.getElementPreview = this.getElementPreview.bind(this);
    this.startTextMaskCropResizing = this.startTextMaskCropResizing.bind(this);
    this.finishTextMaskCropResizing = this.finishTextMaskCropResizing.bind(
      this
    );
  }

  getElementPreview(elementId) {
    if (!elementId) return {};
    const { elementPreviews, selectedItems } = this.props;

    const elementSelection =
      selectedItems.find(item => item.itemId === elementId) || {};

    return {
      ...elementPreviews[elementId],
      ...elementSelection.preview
    };
  }

  getElementWithPreview(elementId) {
    if (!elementId) return {};
    const { designData } = this.props;

    const element = designData.getElement(elementId);

    const elementPreview = this.getElementPreview(elementId);

    // when the preview is image instructions we want to replace without removing unchanged ones
    if (Object.keys(elementPreview).includes("imageInstructions")) {
      const previewInstructions = elementPreview.imageInstructions;
      const elementInstructions = element.imageInstructions;

      const originalInstructionsByDomId = elementInstructions.reduce(
        (combinedInstructions, currentInstruction) => ({
          ...combinedInstructions,
          [currentInstruction.domId]: currentInstruction
        }),
        {}
      );

      previewInstructions.forEach(previewInstruction => {
        originalInstructionsByDomId[
          previewInstruction.domId
        ] = previewInstruction;
      });

      elementPreview.imageInstructions = Object.values(
        originalInstructionsByDomId
      );
    }

    return {
      ...element,
      ...elementPreview
    };
  }

  getPageOffsetInCanvasbackground(pageId) {
    if (!pageId) return 0;
    return getPageOffsetInCanvasbackground(pageId);
  }

  startTextMaskCropResizing() {
    Logger.info("TextMaskResizeHandlerContainer.startImageCropResizing called");
    this.props.updateContext({
      isCropping: true
    });
  }

  finishTextMaskCropResizing({ dragItem, differenceFromInitialOffset }) {
    Logger.info(
      "TextMaskResizeHandlerContainer.finishImageCropResizing called"
    );
    const { designData, zoom, selectedItems } = this.props;

    let textElement = designData.getElement(selectedItems[0].itemId);

    textElement = {
      ...textElement,
      ...selectedItems[0].preview
    };

    const imageNewDimensions = calculateTextMaskImageScaledNewDimensions({
      dragItem,
      textElement,
      zoom,
      differenceFromInitialOffset
    });

    const maskImage = {
      ...textElement.maskImage,
      height: imageNewDimensions.height / zoom,
      width: imageNewDimensions.width / zoom,
      left: textElement.maskImage.left + imageNewDimensions.leftDiff / zoom,
      top: textElement.maskImage.top + imageNewDimensions.topDiff / zoom,
      scale: imageNewDimensions.scale
    };

    const maskImageBottom = maskImage.top + maskImage.height;
    const maskImageRight = maskImage.left + maskImage.width;

    if (maskImage.top > 0) {
      maskImage.top = 0;
    }
    if (maskImageBottom < textElement.height) {
      maskImage.top += textElement.height - maskImageBottom;
    }
    if (maskImage.left > 0) {
      maskImage.left = 0;
    }
    if (maskImageRight < textElement.width) {
      maskImage.left += textElement.width - maskImageRight;
    }

    const selectedItemUpdated = selectedItems.map(selectedItem => ({
      ...selectedItem,
      preview: {
        maskImage
      }
    }));

    this.props.updateContext({
      isCropping: false,
      selectedItems: selectedItemUpdated
    });
  }

  render() {
    if (!this.props.context || !this.props.context.isCroppingTextMaskImage) {
      return null;
    }

    const selectedImage = getPath(this.props, "selectedItems.0");

    const pageId = selectedImage?.pageId || "";
    const pageOffset = this.getPageOffsetInCanvasbackground(pageId);

    const canvasBackground = getCanvasbackground();
    Logger.info(this.props.designData);
    return (
      <TextMaskResizeHandler
        {...this.props}
        zoom={this.props.zoom}
        designData={this.props.designData}
        selectedItems={this.props.selectedItems}
        pageId={pageId}
        pageOffset={pageOffset}
        startTextMaskCropResizing={this.startTextMaskCropResizing}
        finishTextMaskCropResizing={this.finishTextMaskCropResizing}
        canvasBackground={canvasBackground}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    context: contextSelector(state),
    selectedItems: selectedItemsSelector(state),
    zoom: zoomSelector(state),
    designData: designDataSelector(state),
    elementPreviews: elementPreviewsSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContext: args => dispatch(updateEditorContextState(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TextMaskResizeHandlerContainer);
