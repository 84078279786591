import React, { Component } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { DragSource } from "react-dnd";
import PhotoFrameCropResizeHandlerContainer from "./photoFrameCropResizeHandler/PhotoFrameCropResizeHandlerContainer";
import { Image, Video } from "views/components";
import ResizeBox from "views/components/Editor/ResizeBox";
import { Vector } from "views/components/Editor/elements/vector/Vector";
import style from "./style.module.css";
import {
  calculatePhotoFrameDisplayDimensions,
  getImageDimensions
} from "views/components/Editor/CropLayer/photoFrameCropping/PhotoFrameCroppingPreviewCalculations";
import { isVideo } from "lib/isVideo";
import { waitForElement } from "lib/DOMNodeUtils";
import { rotatePoint } from "lib/geometry/rotation";

const PhotoFrameMaskingDraggableImageSource = {
  beginDrag(props, monitor, component) {
    props.startPhotoFrameCropMoving();
    return {
      domId: props.photoFrame.domId
    };
  },
  endDrag(props, monitor) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

    const rotatedOffset = rotatePoint(
      differenceFromInitialOffset.x,
      differenceFromInitialOffset.y,
      0,
      0,
      -props.vectorElement.angle
    );

    props.finishPhotoFrameCropMoving({
      domId: monitor.getItem().domId,
      differenceFromInitialOffset: {
        ...differenceFromInitialOffset,
        ...rotatedOffset
      }
    });
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

class PhotoFrameMaskingDraggableImage extends Component {
  constructor(props) {
    super(props);

    this.calculatePhotoFrameDimension = this.calculatePhotoFrameDimension.bind(
      this
    );
    this.getImageWrapStyle = this.getImageWrapStyle.bind(this);
    this.getImageDimensions = this.getImageDimensions.bind(this);
    this.wrapperRef = React.createRef();

    this.state = {
      syncInterval: null
    };
  }

  componentDidMount() {
    const { connectDragPreview } = this.props;
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      });
    }

    waitForElement("video", this.wrapperRef.current).then(sourceVideo => {
      this.setState({
        sourceVideo
      });
    });
  }

  componentWillUnmount() {
    if (this.state.syncInterval) {
      clearInterval(this.state.syncInterval);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.state.sourceVideo && !prevState.sourceVideo) ||
      (this.state.sourceVideo &&
        !this.state.sourceVideo.isSameNode(prevState.sourceVideo))
    ) {
      // our sourceVideo just appeared or changed so we should add a listener to it for time updates
      const { sourceVideo } = this.state;
      sourceVideo.addEventListener("timeupdate", () => {
        const videos = [
          ...(this.wrapperRef.current?.querySelectorAll("video") || [])
        ];
        const sourceVideoSrc = sourceVideo.firstChild?.src || sourceVideo.src;
        videos.forEach(video => {
          const videoSrc = video.firstChild?.src || video.src;
          if (
            !video.isSameNode(sourceVideo) &&
            videoSrc === sourceVideoSrc &&
            video.currentTime !== sourceVideo.currentTime
          ) {
            video.currentTime = sourceVideo.currentTime;
          }
        });
      });
    }

    if (!this.state.sourceVideo?.isConnected) {
      // our source video was removed, look for a new one?
      waitForElement("video", this.wrapperRef.current).then(sourceVideo => {
        this.setState({
          sourceVideo
        });
      });
    }
  }

  cancelClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  calculatePhotoFrameDimension() {
    const {
      vectorElement,
      photoFrame,
      zoom,
      placeholderDimensions
    } = this.props;

    const unmaskedWidth = vectorElement.srcWidth * vectorElement.scale;
    const unmaskedHeight = vectorElement.srcHeight * vectorElement.scale;
    const maskedRightDiff = unmaskedWidth - vectorElement.width;
    const maskedBottomDiff = unmaskedHeight - vectorElement.height;

    const unmaskedVector = {
      ...vectorElement,
      width: unmaskedWidth,
      height: unmaskedHeight,
      maskedWidth: vectorElement.width,
      maskedHeight: vectorElement.height,
      maskedRightDiff,
      maskedBottomDiff
    };

    return calculatePhotoFrameDisplayDimensions({
      photoFrame,
      vectorElement: unmaskedVector,
      zoom,
      placeholderDimensions
    });
  }

  getImageDimensions() {
    const {
      zoom,
      photoFrame,
      vectorElement,
      placeholderDimensions
    } = this.props;

    return getImageDimensions({
      zoom,
      photoFrame,
      vectorElement,
      placeholderDimensions
    });
  }

  getImageWrapStyle() {
    const { isCropping, photoFrame } = this.props;

    const photoFrameDimensions = this.calculatePhotoFrameDimension();

    return {
      position: "absolute",
      top: photoFrameDimensions.top,
      left: photoFrameDimensions.left,
      height: photoFrameDimensions.height,
      width: photoFrameDimensions.width,
      zIndex: 4,
      opacity: isCropping ? 0 : 1,
      transformOrigin: "top left",
      transform: `
        scaleX(${photoFrame.scaleX})
        scaleY(${photoFrame.scaleY})
      `
    };
  }

  getWrapperStyle() {
    const { pageOffset, vectorElement, isCropping, zoom } = this.props;

    return {
      position: "absolute",
      top: pageOffset.top + vectorElement.top * zoom,
      left: pageOffset.left + vectorElement.left * zoom,
      height: vectorElement.height * zoom,
      width: vectorElement.width * zoom,
      zIndex: 5,
      opacity: isCropping ? 0 : 1,
      transform: [
        `rotate(${vectorElement.angle}deg)`,
        `scaleX(${vectorElement.scaleX})`,
        `scaleY(${vectorElement.scaleY})`
      ].join(" ")
    };
  }

  render() {
    const { connectDragSource, vectorElement, photoFrame, zoom } = this.props;

    const photoFrameDimensions = this.calculatePhotoFrameDimension();

    const Handle = ({ ...props }) => {
      return (
        <PhotoFrameCropResizeHandlerContainer
          vectorElement={vectorElement}
          {...props}
          photoFrame={photoFrame}
        />
      );
    };

    const imageDimensions = this.getImageDimensions();

    const imageWrapStyle = this.getImageWrapStyle();

    // when the preview is image instructions we want to replace without removing unchanged ones
    const elementInstructions = [...vectorElement.imageInstructions];

    const updatedImageInstructions = elementInstructions.map(
      currentInstruction => {
        if (currentInstruction.domId === photoFrame.domId) {
          return photoFrame;
        }
        return currentInstruction;
      },
      {}
    );

    const offsetLeft = imageDimensions.left;
    const offsetTop = imageDimensions.top;

    return connectDragSource(
      <div
        style={this.getWrapperStyle()}
        ref={this.wrapperRef}
        id={`${vectorElement.id}-draggable-image`}
      >
        {/* Full Image For Cropping */}
        <div onClick={this.cancelClick} style={imageWrapStyle}>
          {isVideo(photoFrame.previewSrc) ? (
            <Video
              filter="brightness(0.5)"
              src={photoFrame.previewSrc}
              height={photoFrameDimensions.height}
              width={photoFrameDimensions.width}
              left={offsetLeft}
              top={offsetTop}
              isLandscape={true}
              className={style.image}
              autoPlay
              noBorders
            />
          ) : (
            <Image
              filter="brightness(0.5)"
              height={photoFrameDimensions.height}
              src={photoFrame.previewSrc}
              left={offsetLeft}
              top={offsetTop}
              width={photoFrameDimensions.width}
              isLandscape={true}
              className={style.image}
              noBorders
            />
          )}
        </div>
        {/* Vector Frame - contains current cropped image */}
        <div
          style={{
            position: "absolute",
            transform: `scale(${zoom}) `,
            transformOrigin: "0 0",
            zIndex: 11
          }}
        >
          <Vector
            elementData={{
              ...vectorElement,
              imageInstructions: updatedImageInstructions
            }}
            zoom={zoom}
            autoPlay
          />
        </div>
        {/* Resize Box */}
        <div
          style={{
            ...imageWrapStyle,
            top: imageWrapStyle.top,
            left: imageWrapStyle.left,
            zIndex: 12
          }}
        >
          <div
            style={{
              top: offsetTop,
              left: offsetLeft,
              width: "100%",
              height: "100%",
              position: "absolute"
            }}
          >
            <ResizeBox
              Handler={Handle}
              left={0}
              top={0}
              isResizing={false}
              fixedZIndex={12}
              isCropping={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

// Export the wrapped component:
export default DragSource(
  "PHOTO_FRAME_IMAGE",
  PhotoFrameMaskingDraggableImageSource,
  collect
)(PhotoFrameMaskingDraggableImage);
