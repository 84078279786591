import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "views/components";
import CaretIcon from "views/components/icons/CaretIcon";
import style from "./style.module.css";

const avatarSize = "32";
const caretSize = "10";

export const TeamOption = ({ team, handleProcessDesignRemix, selectTeam }) => {
  const teamCount = team.subTeamCount;
  return (
    <div className={style.teamOption} data-testid="TeamOption">
      <div className={style.teamAvatar}>
        <Avatar name={team.name} size={avatarSize} singleCharacterNameInitial />
      </div>
      <div className={style.teamName}>
        <p>{team.name}</p>
        {teamCount > 0 && (
          <div
            className={style.meta}
            onClick={selectTeam}
            data-testid="metaSection"
          >
            {/* allow team drill down here */}
            <small>
              <strong>{teamCount}</strong> {teamCount === 1 ? "Team" : "Teams"}
            </small>
            <CaretIcon color="#d1d4d6" size="10" />
          </div>
        )}
      </div>
      <div
        className={style.teamOptionAction}
        onClick={() => handleProcessDesignRemix(team.id)}
        data-testid="addToTeamButton"
      >
        <p>Add to team</p>
        <CaretIcon size={caretSize} direction="right" color="#bdc0c2" />
      </div>
    </div>
  );
};

TeamOption.propTypes = {
  team: PropTypes.object,
  handleProcessDesignRemix: PropTypes.func
};

TeamOption.displayName = "TeamOption";
export default TeamOption;
