import {
  UI_OPEN_APPROVAL_MODAL,
  UI_CLOSE_APPROVAL_MODAL
} from "./approvalModalTypes";

import * as entitiesTypes from "state/entities/designs/designsTypes";

export const initState = {
  isOpen: false,
  isLoading: false,
  designId: ""
};

const approvalModal = (state = initState, action) => {
  switch (action.type) {
    case UI_OPEN_APPROVAL_MODAL: {
      return {
        ...state,
        isOpen: true,
        designId: action.designId
      };
    }

    case UI_CLOSE_APPROVAL_MODAL: {
      return initState;
    }

    case entitiesTypes.DESIGN_DECLINE_REQUEST:
    case entitiesTypes.DESIGN_UNAPPROVE_REQUEST:
    case entitiesTypes.DESIGN_APPROVE_REQUEST: {
      return {
        ...state,
        ...{ isLoading: true }
      };
    }

    case entitiesTypes.DESIGN_DECLINE_REQUEST_SUCCESS:
    case entitiesTypes.DESIGN_UNAPPROVE_REQUEST_SUCCESS:
    case entitiesTypes.DESIGN_APPROVE_REQUEST_SUCCESS: {
      return {
        ...state,
        ...{ isLoading: false }
      };
    }

    default:
      return state;
  }
};

export default approvalModal;
