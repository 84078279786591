import React, { Component } from "react";
import style from "./style.module.css";
import TeamRolesPicker from "./TeamRolesPicker";
import { TEAM_ROLES } from "lib/constants";
import TeamRoleInfoMessage from "./TeamRoleInfoMessage";
import DropdownCustom from "views/components/DropdownCustom/DropdownCustom";

export default class TeamRolesDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.getPopoutContent = this.getPopoutContent.bind(this);

    this.state = {
      selectedRoles: props.selectedRoles || [],
      isOpen: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedRoles !== prevProps.selectedRoles) {
      this.setState({ selectedRoles: this.props.selectedRoles });
    }

    // onBlur handling to apply selected roles to new invite form
    if (!this.state.isOpen && prevState.isOpen) {
      this.props.onSubmit(
        TEAM_ROLES.map(role => ({
          ...role,
          selected: this.state.selectedRoles.includes(role.key)
        }))
      );
    }
  }

  handleOnChange(event, key) {
    event.preventDefault();
    event.stopPropagation();
    const { selectedRoles } = this.state;
    let updatedRoles = selectedRoles;

    // if role is already included in array, toggle and remove
    if (selectedRoles.includes(key)) {
      updatedRoles = selectedRoles.filter(role => role !== key);

      this.setState({
        selectedRoles: updatedRoles
      });
      return;
    }

    updatedRoles.push(key);

    this.setState({
      selectedRoles: updatedRoles
    });
  }

  getRoles() {
    const { selectedRoles } = this.state;

    return TEAM_ROLES.filter(role => selectedRoles.includes(role.key))
      .map(option => option.label)
      .join(", ");
  }

  getPopoutContent() {
    return (
      <div
        className={style.rolesDropdownWrapper}
        data-testid="TeamRolesDropdown"
      >
        <TeamRolesPicker
          onClick={this.handleOnChange}
          selectedRoles={this.state.selectedRoles}
        />
        <TeamRoleInfoMessage className={style.inviteRoleMessage} />
      </div>
    );
  }

  render() {
    const { error, showErrorMsg, label } = this.props;
    const popoutContent = this.getPopoutContent();

    return (
      <DropdownCustom
        className={style.rolesDropdown}
        error={error}
        showErrorMsg={showErrorMsg}
        label={label}
        placeholder="Roles"
        value={this.getRoles() || "Roles"}
        popoutContent={popoutContent}
        isOpen={this.state.isOpen}
        setIsOpen={args => this.setState({ isOpen: args })}
      />
    );
  }
}
