import React, { Component } from "react";
import { connect } from "react-redux";
import DesignsPageSubHeader from "views/components/DesignsPageSubHeader";

import {
  fetchAllPersonalFolders,
  fetchAllTeamFoldersIfNeeded,
  createWorkspaceFolder,
  createTeamFolder as createCatalogueFolder
} from "state/entities/folders/foldersActions";

import {
  workspaceFoldersSelector,
  catalogueFoldersSelector
} from "state/entities/folders/foldersSelectors";

import { teamSelector } from "state/entities/entitiesSelectors";

import { fetchUsersIfNeeded } from "state/entities/entitiesActions";

import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import { showUpgradeModal } from "state/ui/upgradeModal/upgradeModalActions";
import {
  userIsDesigner,
  userIsBrandManager
} from "state/currentUser/currentUserSelectors";
import { getParameterByName } from "lib/queryStringUtils";
import {
  userHasApprovedDrafts,
  userHasDesignSharedWith,
  teamHasApprovedDrafts
} from "state/entities/designs/designsSelectors";
import {
  fetchDesignsApproved,
  fetchDesignsShared,
  fetchDesignsSharedApproved,
  fetchDesignsSharedPendingApproval,
  fetchTeamDesignsApproved
} from "state/entities/designs/designsActions";
import { isAnyPurchasedTemplates } from "state/api/designs/purchased/templates/templatesApiSelectors";
import { isAnyArchivedTemplates } from "state/api/designs/team/archived/archivedApiSelectors";

export class DesignsPageSubHeaderContainer extends Component {
  render() {
    return <DesignsPageSubHeader {...this.props} />;
  }
}

const mapStateToProps = state => {
  const isDesigner = userIsDesigner(state);
  const currentSubscription = currentSubscriptionPlan(state);
  const currentTeam = teamSelector({ state });
  const filters = {
    folderId: getParameterByName("folderId", window.location.search),
    categoryId: getParameterByName("categoryId", window.location.search),
    size: getParameterByName("size", window.location.search),
    term: getParameterByName("term", window.location.search)
  };
  const isBrandManager = userIsBrandManager(state);
  // show the approved designs for team tab if a brand manager and approved designs are present
  const canSeeBrandManagerApprovedDesigns =
    isBrandManager && teamHasApprovedDrafts(state);
  const isPurchasedTemplates = isAnyPurchasedTemplates(state);
  const isArchivedTemplates = isAnyArchivedTemplates(state);

  return {
    currentSubscriptionCode: currentSubscription.code,
    createFolderState: state.ui.teamFolders,
    workspaceFolders: workspaceFoldersSelector(state),
    catalogueFolders: catalogueFoldersSelector(state),
    currentUser: state.currentUser,
    currentTeam: currentTeam,
    userHasApprovedDrafts: userHasApprovedDrafts(state),
    userHasDesignSharedWith: userHasDesignSharedWith(state),
    canSeeBrandManagerApprovedDesigns,
    filters,
    isDesigner,
    userIsBrandManager: isBrandManager,
    isPurchasedTemplates,
    isArchivedTemplates
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchDesignsApproved: (...args) =>
      dispatch(fetchDesignsApproved(...args)),
    onFetchTeamDesignsApproved: (...args) =>
      dispatch(fetchTeamDesignsApproved(...args)),
    onFetchDesignsShared: (...args) => dispatch(fetchDesignsShared(...args)),
    onFetchDesignsSharedApproved: args =>
      dispatch(fetchDesignsSharedApproved(args)),
    onFetchDesignsSharedPendingApproval: args =>
      dispatch(fetchDesignsSharedPendingApproval(args)),
    showUpgradeModal: args => dispatch(showUpgradeModal(args)),
    createWorkspaceFolder: args => dispatch(createWorkspaceFolder(args)),
    createCatalogueFolder: args => dispatch(createCatalogueFolder(args)),
    fetchAllPersonalFolders: args => dispatch(fetchAllPersonalFolders(args)),
    fetchAllTeamFolders: args => dispatch(fetchAllTeamFoldersIfNeeded(args)),
    fetchUsersIfNeeded: args => dispatch(fetchUsersIfNeeded(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignsPageSubHeaderContainer);
