import { DragSource } from "react-dnd";
import MaskedVideo from "./MaskedVideo";

const MaskedVideoDraggableSource = {
  beginDrag(props, monitor, component) {
    props.startVideoCropMoving();

    return {};
  },
  endDrag(props, monitor) {
    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();

    props.finishVideoCropMoving({ differenceFromInitialOffset });
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(
  "VIDEO_CROP",
  MaskedVideoDraggableSource,
  collect
)(MaskedVideo);
