import React from "react";

import style from "./style.module.css";

import { SlidingToggle } from "views/components";

import { noop } from "lib";

const getOptions = (setPeriod = noop) => [
  {
    label: "Monthly",
    id: "MONTHLY",
    onToggle: () => setPeriod("MONTHLY")
  },
  {
    label: "Yearly",
    id: "YEARLY",
    onToggle: () => setPeriod("YEARLY")
  }
];

const customStyles = {
  "background-color": "#ffffff",
  "button-color": "#2c2e2f",
  "button-width": "105px",
  "button-height": "44px",
  width: "225px",
  height: "52px",
  "border-radius": "12px",
  "border-color": "rgba(44, 46, 47, 0.16)"
};

export const PlanComparisonTop = ({ setPeriod, currentPeriod, isFiltered }) => {
  return (
    <div className={style.wrapperTop}>
      <SlidingToggle
        options={getOptions(setPeriod)}
        customStyles={customStyles}
        selectedOption={currentPeriod}
      />
    </div>
  );
};

export default PlanComparisonTop;
