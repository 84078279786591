import { createSelector } from "reselect";

export const imageFoldersApiSelector = state => state.api.imageFolders;
const processImageFoldersApiCurrentPageSelector = imageFolders => {
  if (!imageFolders || !imageFolders.pages) return 0;
  return Object.keys(imageFolders.pages).length;
};

export const imageFoldersApiCurrentPageSelector = createSelector(
  [imageFoldersApiSelector],
  processImageFoldersApiCurrentPageSelector
);

const processImageFoldersIsFetchingSelector = imageFolders => {
  if (!imageFolders || !imageFolders.pages) return true;
  const { pages } = imageFolders;
  return Object.values(pages).some(page => page.isFetching); // true if at least one page is fetching
};

export const imageFoldersIsFetchingSelector = createSelector(
  [imageFoldersApiSelector],
  processImageFoldersIsFetchingSelector
);
