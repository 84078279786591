import React, { Component } from "react";
import { H2, H3, Loading, Button } from "views/components";
import CheckIcon from "views/components/icons/CheckIcon";
import style from "./style.module.css";
import { formatOrderStatus } from "views/containers/orderContainer/ordersHelpers";
import OrderDesignsContainer from "views/components/OrderDesign/OrderDesignsContainer";
import PriceSummaryContainer from "views/components/PriceSummary/PriceSummaryContainer";
import PATHS from "routes/paths";
class Cart extends Component {
  render() {
    const { order, changePage } = this.props;

    if (!order) {
      return (
        <div
          style={{
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loading />
        </div>
      );
    }

    return (
      <div className={style.wrapper}>
        <div className={style.orderSuccessTop}>
          <div className={style.circlecheck}>
            <CheckIcon size="14px" color="#2c2e2f" />
            <div />
            <div />
          </div>
          <H2 className={style.heading}>Order Successful</H2>
          <div className={style.successMessage}>
            <strong>Congratulations!</strong> Your order was successfully
            received. Please check your email for your receipt.
            <br /> For any questions, please use the Help button at the top of
            the screen, or email{" "}
            <a href="mailto:support@easil.com" className={style.supportEmail}>
              support@easil.com
            </a>
            .
          </div>
        </div>
        <div className={style.orderStatusBox}>
          <div>
            <strong>Reference ID:</strong> {order.id}
          </div>
          <div>
            <strong>Status:</strong> {formatOrderStatus(order.status)}
          </div>
        </div>
        <div className={style.orderSummarySection}>
          <H3 className={style.orderHeading}>Summary</H3>
          <OrderDesignsContainer
            orderId={order.id}
            className={style.orderDesigns}
          />
        </div>
        <div className={style.priceSummarySection}>
          <PriceSummaryContainer
            orderId={order.id}
            shipping={order.shipping}
            shippingCanBeHidden
          />
        </div>
        <div className={style.buttonSection}>
          <Button
            onClick={() => changePage(PATHS.workspace)}
            theme="blueSolid"
            className={style.workspaceButton}
          >
            Return to My Workspace
          </Button>
        </div>
      </div>
    );
  }
}

export default Cart;
