import { removeItem } from "lib/array/array";
import { tableHeightsUpdater } from "views/components/Editor/editorOps/EditorTableOps/tableSimulator";

const DesignTableOps = {
  deleteTableRow({ rowIndex, rowHeight, elementId }) {
    const tableElement = this.getElement(elementId);

    const tableUpdated = {
      ...tableElement,
      rows: removeItem(tableElement.rows, rowIndex),
      height: tableElement.height - rowHeight
    };

    const tableWithHeightUpdated = tableHeightsUpdater(tableUpdated);

    const elementsUpdated = {
      ...this.elements,
      [elementId]: tableWithHeightUpdated
    };

    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  },

  duplicateLastTableRow({ tableId }) {
    const tableElement = this.getElement(tableId);

    const tableUpdated = tableElement.duplicateLastRow();

    const elementsUpdated = {
      ...this.elements,
      [tableId]: tableUpdated
    };

    return new this.constructor({
      ...this,
      elements: elementsUpdated,
      version: this.version + 1
    });
  }
};

export default DesignTableOps;
