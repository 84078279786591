import * as types from "./designsTypes";
import * as collectionTypes from "../../api/collection/collectionTypes";
import { merge, isEmpty, omit } from "lib";

export const initState = {};

const designsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.DESIGN_REQUEST_SUCCESS: {
      const { entities } = action.response;
      return merge({}, state, entities.designs);
    }
    case types.TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS:
    case types.SEARCH_TEAM_DESIGNS_APPROVED_REQUEST_SUCCESS:
    case types.DESIGNS_APPROVED_REQUEST_SUCCESS:
    case types.DESIGNS_SHARED_REQUEST_SUCCESS:
    case types.DESIGNS_BY_FOLDER_REQUEST_SUCCESS:
    case types.DESIGNS_BY_TEAM_FOLDER_REQUEST_SUCCESS:
    case types.TEAM_TEMPLATES_DESIGNS_REQUEST_SUCCESS:
    case types.EASIL_TEMPLATES_DESIGNS_REQUEST_SUCCESS:
    case types.EASIL_TUTORIAL_DESIGNS_REQUEST_SUCCESS:
    case types.TEAM_ARCHIVED_DESIGNS_REQUEST_SUCCESS:
    case types.TEAM_DESIGNS_REQUEST_SUCCESS:
    case types.TEAM_DESIGNS_EASIL_REQUEST_SUCCESS:
    case types.TEAM_DESIGNS_TEAM_REQUEST_SUCCESS:
    case types.DESIGNS_ARCHIVED_REQUEST_SUCCESS:
    case types.DESIGNS_SEARCH_REQUEST_SUCCESS:
    case types.DESIGNS_PENDING_APPROVAL_REQUEST_SUCCESS:
    case types.DESIGNS_PENDING_APPROVAL_TEAM_REQUEST_SUCCESS:
    case types.DESIGNS_DECLINED_APPROVAL_REQUEST_SUCCESS:
    case types.DESIGNS_PENDING_APPROVAL_SHARED_REQUEST_SUCCESS:
    case types.DESIGNS_DECLINED_APPROVAL_SHARED_REQUEST_SUCCESS:
    case types.PURCHASED_TEMPLATES_DESIGNS_REQUEST_SUCCESS:
    case types.DESIGNS_DRAFTS_REQUEST_SUCCESS: {
      if (isEmpty(action.response)) {
        return state;
      }

      const { entities } = action.response;

      return merge({}, state, entities.designs);
    }

    case types.DESIGN_DRAFT_UPDATE_REQUEST: {
      const { body: design } = action.request;

      return {
        ...state,
        [design.id]: {
          ...state[design.id],
          ...design
        }
      };
    }

    case types.DESIGNS_TEMPLATES_REQUIRES_APPROVAL_UPDATE_REQUEST_SUCCESS: {
      const {
        request: {
          body: { designId, requiresApproval }
        }
      } = action;

      return {
        ...state,
        [designId]: {
          ...state[designId],
          requiresApproval
        }
      };
    }

    case collectionTypes.REQUIRES_APPROVAL_UPDATE_REQUEST_SUCCESS: {
      const {
        request: {
          body: { requiresApproval }
        }
      } = action;

      const designIds = action.response.map(design => design.designId);

      const stateUpdate = designIds.reduce((previousState, designId) => {
        previousState[designId] = {
          ...state[designId],
          requiresApproval
        };

        return previousState;
      }, {});

      return {
        ...state,
        ...stateUpdate
      };
    }

    case types.DESIGNS_DRAFTS_COPY_REQUEST:
    case types.DESIGNS_DRAFTS_CREATE_REQUEST:
    case types.DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST: {
      const {
        request: {
          body: { parentId }
        },
        extra: { provisionalId }
      } = action;

      return {
        ...state,
        [provisionalId]: {
          ...state[parentId],
          provisionalId: provisionalId,
          id: provisionalId
        }
      };
    }

    case types.DESIGNS_DRAFTS_COPY_REQUEST_SUCCESS:
    case types.DESIGNS_DRAFTS_CREATE_REQUEST_SUCCESS:
    case types.DESIGNS_DRAFTS_CUSTOM_CREATE_REQUEST_SUCCESS: {
      const {
        response: { entities, ids: id },
        extra: { provisionalId }
      } = action;

      const newDesign = {
        [id]: {
          ...state[provisionalId],
          ...entities.designs[id]
        }
      };
      return {
        ...omit(state, provisionalId),
        ...newDesign
      };
    }

    case types.DESIGN_RESIZE_REQUEST_SUCCESS: {
      const {
        response: { entities, ids: id }
      } = action;

      const newDesign = {
        [id]: {
          ...entities.designs[id]
        }
      };
      return {
        ...state,
        ...newDesign
      };
    }

    case types.COLLECTION_DESIGNS_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;
      const {
        response: {
          entities: { collectionDesign: designs }
        }
      } = action;

      return merge({}, state, designs);
    }

    case types.COLLECTION_PRICE_REQUEST_SUCCESS: {
      if (!action.response.entities) return state;
      const {
        response: {
          entities: { collection }
        },
        extra: { collectionId }
      } = action;
      const { designs, ids } = collection[collectionId];
      const updatedDesigns = {};

      ids.forEach(designId => {
        Object.values(designs)
          .filter(design => design.id === designId)
          .forEach(design => {
            Object.assign(updatedDesigns, {
              [design.id]: {
                ...design
              }
            });
          });
      });

      return {
        ...state,
        ...updatedDesigns
      };
    }

    case types.TEAM_TEMPLATES_STATUS_UPDATE_REQUEST_SUCCESS:
    case types.DESIGNS_DRAFTS_STATUS_UPDATE_REQUEST_SUCCESS: {
      const { entities } = action.response;
      const designs = entities.designStatusUpdate;

      return {
        ...state,
        ...designs
      };
    }

    case types.DESIGN_SHARE_REQUEST_SUCCESS: {
      const collectionId = action.response[0].body.collectionId;
      const designs = state;
      const updatedDesigns = {};

      Object.values(designs)
        .filter(design => design.collectionId === collectionId)
        .forEach(design => {
          Object.assign(updatedDesigns, {
            [design.id]: {
              ...design,
              isShared: true,
              isLocked: false
            }
          });
        });

      return {
        ...state,
        ...updatedDesigns
      };
    }

    case types.DESIGNS_SHARE_REQUEST_SUCCESS: {
      const collectionIds = action.request.body.map(collection => {
        return collection.collectionId;
      });
      const designs = state;
      const updatedDesigns = {};

      Object.values(designs)
        .filter(design => collectionIds.includes(design.collectionId))
        .forEach(design => {
          Object.assign(updatedDesigns, {
            [design.id]: {
              ...design,
              isShared: true,
              isLocked: false
            }
          });
        });

      return {
        ...state,
        ...updatedDesigns
      };
    }

    case types.DESIGN_NO_LONGER_SHARED: {
      const collectionId = action.collectionId;
      const designs = state;
      const updatedDesigns = {};

      Object.values(designs)
        .filter(design => design.collectionId === collectionId)
        .forEach(design => {
          Object.assign(updatedDesigns, {
            [design.id]: {
              ...design,
              isShared: false,
              isLocked: false
            }
          });
        });

      return {
        ...state,
        ...updatedDesigns
      };
    }

    case types.DESIGN_CANCEL_APPROVAL_REQUEST_SUCCESS: {
      const {
        request: {
          body: { designId }
        }
      } = action;

      const updatedDesign = Object.assign({}, state[designId], {
        status: "DRAFT"
      });
      return {
        ...state,
        [designId]: updatedDesign
      };
    }

    case types.DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS: {
      const {
        request: {
          body: { designId }
        }
      } = action;

      const updatedDesign = Object.assign({}, state[designId], {
        status: "PENDING_APPROVAL"
      });

      return {
        ...state,
        [designId]: updatedDesign
      };
    }

    case types.DESIGN_REVERT_APPROVAL_REQUEST_SUCCESS: {
      const {
        response: { ids: designIds }
      } = action;

      const updatedDesigns = {};

      designIds.forEach(designId => {
        const updatedDesign = Object.assign({}, state[designId], {
          status: "DRAFT"
        });
        updatedDesigns[designId] = updatedDesign;
      });

      return {
        ...state,
        ...updatedDesigns
      };
    }

    case collectionTypes.UPDATE_REQUEST_SUCCESS: {
      const {
        response: { designs }
      } = action;
      const updatedDesigns = {};

      if (!designs) {
        throw new Error("Invalid response: designs expected.");
      }

      designs.forEach(design => {
        updatedDesigns[design.id] = {
          ...state[design.id],
          ...design
        };
      });

      return {
        ...state,
        ...updatedDesigns
      };
    }

    case types.DESIGN_APPROVE_REQUEST_SUCCESS: {
      const {
        request: {
          body: { designId }
        }
      } = action;

      const updatedDesign = Object.assign({}, state[designId], {
        status: "APPROVED"
      });

      return {
        ...state,
        [designId]: updatedDesign
      };
    }

    case types.DESIGN_DECLINE_REQUEST_SUCCESS: {
      const {
        request: {
          body: { designId }
        }
      } = action;

      const updatedDesign = Object.assign({}, state[designId], {
        status: "DECLINED"
      });

      return {
        ...state,
        [designId]: updatedDesign
      };
    }

    case types.DESIGNS_DELETE_REQUEST_SUCCESS: {
      const {
        extra: { designId }
      } = action;

      // remove design from entities
      return omit({ ...state }, designId);
    }

    default:
      return state;
  }
};

export default designsReducer;
