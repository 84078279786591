import {
  UI_OPEN_APPROVAL_MODAL,
  UI_CLOSE_APPROVAL_MODAL
} from "./approvalModalTypes";

export const openApprovalModal = ({ designId }) => {
  return {
    type: UI_OPEN_APPROVAL_MODAL,
    designId
  };
};

export const closeApprovalModal = () => {
  return {
    type: UI_CLOSE_APPROVAL_MODAL
  };
};
