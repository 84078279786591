import React from "react";
import { connect } from "react-redux";

import { createDraftFromTemplate } from "state/entities/entitiesActions";
import { copyCollectionToDraftsAndOpenSelectedInEditor } from "state/ui/collectionPreviewModal/collectionPreviewModalActions";

import CreateDraftModal from "./CreateDraftModal";

export const CreateDraftModalContainer = props => {
  return <CreateDraftModal {...props} />;
};

CreateDraftModalContainer.displayName = "CreateDraftModalContainer";

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => {
  return {
    createDraftFromTemplate: args => dispatch(createDraftFromTemplate(args)),
    copyCollectionToDrafts: args =>
      dispatch(copyCollectionToDraftsAndOpenSelectedInEditor(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDraftModalContainer);
