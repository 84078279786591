import * as types from "state/entities/teamSettings/teamSettingsTypes";

export const initState = {
  isFetching: false
};

const api = (state = initState, action) => {
  switch (action.type) {
    case types.TEAM_SETTINGS_REQUEST: {
      return {
        ...state,
        isFetching: true,
        lastFetched: Date.now()
      };
    }

    case types.TEAM_SETTINGS_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.response.teamSettings,
        isFetching: false,
        lastFetched: Date.now()
      };
    }

    case types.TEAM_SETTINGS_REQUEST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        lastFetched: null
      };
    }

    default:
      return state;
  }
};

export default api;
