import React from "react";
import style from "./style.module.css";
import { Logger } from "lib";
import { preventDefaultAndDoNotPropagate } from "lib/event/event";
import getFileExtension from "lib/getFileExtension";
import PATHS from "routes/paths";
import { Loading } from "views/components";
import TrashIcon from "views/components/icons/TrashIcon";
import TickCircledIcon from "views/components/icons/TickCircledIcon";
import ArrowOutOfBoxIcon from "../icons/ArrowOutOfBoxIcon";

class FontDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputId: `file${Date.now() + Math.random()}`,
      isDraggingOver: false,
      files: props.files,
      fileSrcs: props.fileSrcs,
      fontError: null
    };
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.processFont = this.processFont.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.stopBrowserDefaultBehaviour = this.stopBrowserDefaultBehaviour.bind(
      this
    );
    this.inputReference = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
  }

  showFileUpload() {
    if (!this.inputReference || !this.inputReference.current) return;
    this.inputReference.current.click();
  }

  handleFileSelect({ event, origin }) {
    this.stopBrowserDefaultBehaviour(event);
    if (this.props.disabled) return;

    let files;

    switch (origin) {
      case "dragAndDrop":
        files = Array.from(event.dataTransfer.files);
        break;
      case "manualInput":
        files = Array.from(event.target.files);
        break;
      default:
        Logger.warn({ error: "Invalid Event Origin" });
        this.setState({ fontError: "Something went wrong" });
        return;
    }

    this.setState({
      files,
      isDraggingOver: false,
      fontError: null
    });

    this.processFont(files).then(
      result => {
        this.setState({ fileSrcs: result }, () => this.props.onChange(files));
      },
      ({ error }) => {
        this.setState({ fontError: error });
      }
    );
  }

  processFont(files) {
    const fileSourcePromises = files.map(file => {
      const fontFilesTypesAllowed = ["ttf", "otf"];
      const fileExtension = getFileExtension(file.name);

      if (!fontFilesTypesAllowed.includes(fileExtension))
        return Promise.reject({ error: "Fonts must be in TTF or OTF format." });

      return new Promise((resolve, reject) => {
        const errorCallback = msg => () => {
          Logger.warn(msg);
          return reject({ error: "We were not able to process your font." });
        };

        const validateFileAsFont = fileSrc => {
          resolve(fileSrc);
        };

        const reader = new FileReader();
        reader.onerror = errorCallback("File Reader onload error");

        reader.onload = ({ target }) => validateFileAsFont(target.result);
        reader.readAsDataURL(file);
      });
    });

    return Promise.all(fileSourcePromises);
  }

  stopBrowserDefaultBehaviour(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  handleDragEnter(e) {
    this.stopBrowserDefaultBehaviour(e);

    if (this.props.disabled) return;

    this.setState({ isDraggingOver: true });
  }

  handleDragLeave(e) {
    this.stopBrowserDefaultBehaviour(e);

    if (this.props.disabled) return;

    this.setState({ isDraggingOver: false });
  }

  handleDragOver(e) {
    this.stopBrowserDefaultBehaviour(e);

    if (this.props.disabled) return;

    e.dataTransfer.dropEffect = "copy";
  }

  handleRemove(event, fileIndex) {
    preventDefaultAndDoNotPropagate(event);

    let updatedFiles = [...this.state.files];
    updatedFiles.splice(fileIndex, 1);
    let updatedSrcs = [...this.state.fileSrcs];
    updatedSrcs.splice(fileIndex, 1);

    if (!updatedFiles.length) {
      updatedFiles = null;
      updatedSrcs = null;
    }

    this.setState({
      files: updatedFiles,
      fileSrcs: updatedSrcs,
      fontError: null
    });

    this.props.onChange(updatedFiles);
  }

  render() {
    const { className, trashIconColor, uploadingFonts } = this.props;
    const { isDraggingOver, files, fileSrcs, inputId, fontError } = this.state;
    const isEditorPath = PATHS.isEditorPath(window.location.pathname);
    const customStyle = {
      color: isEditorPath ? "#FFFFFF" : ""
    };

    return (
      <div className={`${style.wrapper} ${className}`}>
        <div
          className={style.fontDropZone}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          onDragOver={this.handleDragOver}
          onDrop={e => {
            this.handleFileSelect({ event: e, origin: "dragAndDrop" });
          }}
          data-is-dragover={isDraggingOver}
          data-has-files={files && fileSrcs}
          onClick={this.showFileUpload}
          data-testid={"FontDropZone"}
        >
          {files && fileSrcs ? (
            files.map((file, fileIndex) => (
              <div
                className={style.uploadedFontMeta}
                src={fileSrcs[fileIndex]}
                file={file}
                style={customStyle}
                key={`${file.name}-${fileIndex}`}
              >
                <span className={style.fontName}>{file.name}</span>
                {uploadingFonts ? (
                  <>
                    {!uploadingFonts[fileIndex] ? (
                      <Loading size="14px" />
                    ) : (
                      <TickCircledIcon size="14px" color="#D1D4D6" />
                    )}
                  </>
                ) : (
                  <TrashIcon
                    color={trashIconColor}
                    className={style.remove}
                    size="20px"
                    onClick={event => {
                      this.handleRemove(event, fileIndex);
                    }}
                  />
                )}
              </div>
            ))
          ) : (
            <div>
              <input
                disabled={this.props.disabled}
                type="file"
                id={inputId}
                accept=".ttf, .otf"
                className={style.inputfile}
                onChange={e => {
                  this.handleFileSelect({ event: e, origin: "manualInput" });
                }}
                ref={this.inputReference}
                multiple
                data-testid="font-input"
              />
              <ArrowOutOfBoxIcon style={{ marginBottom: "3px" }} />
              <div className={style.fontUploadContent} style={customStyle}>
                <label data-not-allowed={this.props.disabled}>
                  Click to upload
                </label>
                <span className={style.fontUploadContent}> or drag & drop</span>
              </div>
              <div className={style.fontUploadSubText}>
                TTF & OTF files supported
              </div>
              {fontError && <div className={style.error}>{fontError}</div>}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default FontDropZone;
