import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import CaretIcon from "views/components/icons/CaretIcon";

const DropDownLink = ({ className, children, onClick, hasCaret = true }) => {
  return (
    <div
      className={`${style.wrapper} ${className}`}
      onClick={onClick}
      data-testid="DropDownLink"
    >
      <div>{children}</div>
      {hasCaret && <CaretIcon className={style.icon} />}
    </div>
  );
};

DropDownLink.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

DropDownLink.displayName = "DropDownLink";

export default DropDownLink;
