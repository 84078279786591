import * as designsEntitiesTypes from "state/entities/designs/designsTypes";
import { isEmpty, omit } from "lib";

export const initState = {
  pages: {},
  pageSize: 50
};

const pendingApprovalApiReducers = (state = initState, action) => {
  switch (action.type) {
    case designsEntitiesTypes.DESIGNS_PENDING_APPROVAL_TEAM_REQUEST_SUCCESS: {
      const {
        response: { ids = [] } = {},
        request: { page }
      } = action;

      if (isEmpty(ids) && Number(page) !== 1) {
        return {
          ...state,
          pages: omit(state.pages, action.request.page)
        };
      }

      const updatedPage = {
        [page]: {
          isFetching: false,
          ids: ids,
          didInvalidate: false
        }
      };

      return {
        ...state,
        pages: { ...state.pages, ...updatedPage }
      };
    }

    default:
      return state;
  }
};

export default pendingApprovalApiReducers;
