import React from "react";
import style from "./style.module.css";
import { NoComponent } from "views/components";

import MinimalCardInput from "./MinimalCardInput/MinimalCardInput";

const cardLogos = [
  { label: "Visa Logo", path: "/credit_card_logos/Visa-clear.png" },
  { label: "Mastercard Logo", path: "/credit_card_logos/Mastercard-clear.png" },
  { label: "Amex Logo", path: "/credit_card_logos/Amex-clear.png" }
];

export class PaymentOption extends React.Component {
  constructor(props) {
    super(props);

    this.getDisplayComponents = this.getDisplayComponents.bind(this);
    this.optionRef = React.createRef();
    this.handleCreateOrder = this.handleCreateOrder.bind(this);
    this.handleOnApprove = this.handleOnApprove.bind(this);

    this.state = {
      HeaderComponent: NoComponent,
      ContentComponent: NoComponent
    };
  }

  componentDidMount() {
    const displayComponents = this.getDisplayComponents();
    this.setState({
      HeaderComponent: displayComponents.header,
      ContentComponent: displayComponents.content
    });

    if (
      this.props.type === "paypal" &&
      process.env.REACT_APP_PAYPAL_CLIENT_ID
    ) {
      if (!window.paypal) {
        // add paypal scripts to the page
        /* eslint-disable */
        !(() => {
          var e = document.createElement("script"),
            t = document.getElementsByTagName("script")[0];
          (e.async = 0),
            (e.src = `https://www.paypal.com/sdk/js?client-id=${
              process.env.REACT_APP_PAYPAL_CLIENT_ID
            }&disable-funding=credit,card&currency=${this.props.currencyCode}`),
            (e.charset = "UTF-8"),
            t.parentNode.insertBefore(e, t);
        })();
        /* eslint-enable */
      }

      const waitForPaypal = () => {
        if (window.paypal) {
          window.paypal
            .Buttons({
              style: {
                layout: "vertical",
                color: "blue",
                shape: "rect",
                label: ""
              },
              createOrder: this.handleCreateOrder,
              onApprove: this.handleOnApprove
            })
            .render("#paypal-button-container");
        } else {
          setTimeout(() => {
            waitForPaypal();
          }, 100);
        }
      };

      // wait for scripts to load and then add the button to the page
      waitForPaypal();
    }
  }

  async handleCreateOrder() {
    // need to get the total price for the order and use paypal actions to create an order
    const { orderId, createPaypalOrder } = this.props;
    const { paypalOrderId } = await createPaypalOrder({ orderId });
    return paypalOrderId;
  }

  handleOnApprove(data) {
    // get the details for the confirmed transaction
    this.props.createOrderPayment({ token: data.orderID });
  }

  getDisplayComponents() {
    const { type } = this.props;
    switch (type) {
      case "paypal": {
        return {
          content: () => (
            <div className={style.paypalContent}>
              After clicking 'Complete Order', you will be redirected to PayPal
              <br />
              to complete your purchase securely.
            </div>
          ),
          header: ({ isActive }) => (
            <>
              <div className={style.paymentOptionHeaderLeft}>
                <div
                  className={style.selectionCircle}
                  data-is-active={isActive}
                />
                <div className={style.paymentOptionTitle}>Paypal</div>
              </div>
            </>
          )
        };
      }
      case "credit":
      default: {
        return {
          content: MinimalCardInput,
          header: ({ isActive }) => (
            <>
              <div className={style.paymentOptionHeaderLeft}>
                <div
                  className={style.selectionCircle}
                  data-is-active={isActive}
                />
                <div className={style.paymentOptionTitle}>Credit card</div>
              </div>
              <div className={style.paymentOptionHeaderRight}>
                {cardLogos.map(logo => (
                  <img
                    className={style.logo}
                    alt={logo.label}
                    src={logo.path}
                  />
                ))}
              </div>
            </>
          )
        };
      }
    }
  }

  render() {
    const { isActive, handleSelection, setToken, token } = this.props;

    const { HeaderComponent, ContentComponent } = this.state;

    return (
      <div
        className={style.paymentOption}
        data-is-active={isActive}
        ref={this.optionRef}
      >
        <div className={style.paymentOptionHeader} onClick={handleSelection}>
          <HeaderComponent
            isActive={isActive}
            handleSelection={handleSelection}
          />
        </div>
        <div className={style.paymentOptionContent}>
          <ContentComponent
            onTokenSuccess={setToken}
            token={token}
            onTokenClear={() => setToken()}
          />
        </div>
      </div>
    );
  }
}

export default PaymentOption;
