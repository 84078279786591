import { combineReducers } from "redux";
import { teamsApiReducers as teams } from "./teams";
import { usersApiReducers as users } from "./users";
import { pendingUsersApiReducers as pendingUsers } from "./pendingUsers";
import { apiDesignsReducers as designs } from "./designs";
import { apiFoldersReducers as folders } from "./folders";
import { apiTagsReducers as tags } from "./tags";
import { publicFontsApiReducers as publicFonts } from "./publicFonts";
import { userTeamFontsApiReducers as userTeamFonts } from "./userTeamFonts";
import { userTeamImagesApiReducers as userTeamImages } from "./userTeamImages";
import { teamImagesApiReducers as teamImages } from "./teamImages";
import { teamLogosApiReducers as teamLogos } from "./teamLogos";
import { subscriptionsApiReducers as subscriptions } from "./subscriptions";
import { subscriptionPlansApiReducers as subscriptionPlans } from "./subscriptionPlans";
import { cardsApiReducers as cards } from "./cards";
import { purchasedCatalogueSizesApiReducers as purchasedCatalogueSizes } from "./purchasedCatalogueSizes";
import { catalogueSizesApiReducers as catalogueSizes } from "./catalogueSizes";
import { workspaceSizesApiReducers as workspaceSizes } from "./workspaceSizes";
import { userTeamFavoritesApiReducers as userTeamFavorites } from "./userTeamFavorites";
import { graphicsApiReducers as graphics } from "./graphics";
import { userPreferencesApiReducers as userPreferences } from "./userPreferences";
import { teamsSearchApiReducers as teamsSearch } from "./teamsSearch";
import { teamUsersSearchApiReducer as teamUsersSearch } from "./teamUsersSearch";
import { teamSettingsApiReducers as teamSettings } from "./teamSettings";
import { searchSuggestionsApiReducers as searchSuggestions } from "./searchSuggestions";
import { apiImageFoldersReducers as imageFolders } from "./imageFolders";
import { updatePasswordReducers as updatePassword } from "./updatePassword";
import { creditsApiReducers as credits } from "./credits";
import { CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS } from "state/currentUser/currentUserTypes";
import { collectionAllocationsApiReducers as collectionAllocations } from "./collectionAllocations";
import { folderAllocationsApiReducers as folderAllocations } from "./folderAllocations";
import { designActionsApiReducers as designActions } from "./designActions";
import { invoicesApiReducers as invoices } from "./invoices";
import { default as collections } from "./collection/collectionApiReducers";
import { ordersReducers as orders } from "./orders/ordersReducers";
import { userTeamAnimationsApiReducers as userTeamAnimations } from "./userTeamAnimations";
import { userTeamSmartImagesApiReducers as userTeamSmartImages } from "./userTeamSmartImages";
import { teamSmartImagesApiReducers as teamSmartImages } from "./teamSmartImages";
import { teamAnimationsApiReducers as teamAnimations } from "./teamAnimations";
import { stockAnimationsApiReducers as stockAnimations } from "./stockAnimations";
import { userTeamAnimationsFoldersApiReducers as userTeamAnimationsFolders } from "./userTeamAnimationFolders";
import { teamAnimationsFoldersApiReducers as teamAnimationsFolders } from "./teamAnimationsFolders";
import { teamSizesApiReducers as teamSizes } from "./teamSizes";

const combinedReducers = combineReducers({
  cards,
  catalogueSizes,
  collectionAllocations,
  collections,
  credits,
  designActions,
  designs,
  folderAllocations,
  folders,
  graphics,
  imageFolders,
  invoices,
  orders,
  pendingUsers,
  publicFonts,
  purchasedCatalogueSizes,
  searchSuggestions,
  stockAnimations,
  subscriptionPlans,
  subscriptions,
  tags,
  teamAnimations,
  teamAnimationsFolders,
  teamImages,
  teamSmartImages,
  teamLogos,
  teams,
  teamSettings,
  teamSizes,
  teamsSearch,
  teamUsersSearch,
  updatePassword,
  userPreferences,
  users,
  userTeamAnimations,
  userTeamAnimationsFolders,
  userTeamFavorites,
  userTeamFonts,
  userTeamImages,
  userTeamSmartImages,
  workspaceSizes
});

const combinedWithReset = (state, action) => {
  if (action.type === CURRENT_USER_SWITCH_TOKEN_REQUEST_SUCCESS) {
    state = undefined;
  }

  return combinedReducers(state, action);
};

export default combinedWithReset;
