import { values } from "lib";
import * as types from "./teamSignupTypes";

export const initState = {
  isProcessing: false,
  isSuccess: false,
  isOrganisationSucess: false,
  errors: {},
  personalOrganisation: null,
  user: null
};

const TeamSignupReducers = (state = initState, action) => {
  switch (action.type) {
    case types.PERSONAL_ORGANISATION_CREATE_REQUEST: {
      return {
        ...state,
        errors: {},
        isProcessing: true
      };
    }

    case types.PERSONAL_ORGANISATION_CREATE_REQUEST_SUCCESS: {
      const { users, organisations } = action.response.entities;

      return {
        ...state,
        isProcessing: false,
        isSuccess: true,
        personalOrganisation: values(organisations)[0],
        user: values(users)[0]
      };
    }

    case types.PERSONAL_ORGANISATION_CREATE_REQUEST_FAILURE: {
      const {
        error: { errors }
      } = action;

      const errorsMap = errors.reduce(
        (acc, error) => ({
          ...acc,
          [error.path]: error.message
        }),
        {}
      );

      return {
        ...state,
        isProcessing: false,
        errors: errorsMap
      };
    }

    case types.ORGANISATION_CREATE_REQUEST_SUCCESS: {
      return {
        ...state,
        isOrganisationSucess: true,
        isProcessing: false
      };
    }

    case types.ORGANISATION_CREATE_REQUEST_FAILURE: {
      return {
        ...state,
        isOrganisationSucess: false,
        isProcessing: false
      };
    }

    case types.ORGANISATION_CREATE_REQUEST: {
      return {
        ...state,
        isProcessing: true
      };
    }
    default:
      return state;
  }
};

export default TeamSignupReducers;
