import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CrossedCircleIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color = easilGrayXDark,
  title,
  viewBox = "0 0 20 20"
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="CrossedCircleIcon"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <rect
        width="14"
        height="14"
        x="3"
        y="3"
        stroke={color || easilGrayXDark}
        strokeWidth="2"
        rx="7"
      />
      <path stroke={color || easilGrayXDark} strokeWidth="2" d="M5 15 15 5" />
    </g>
  </svg>
);
CrossedCircleIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CrossedCircleIcon;
