import { noop, keyCodes } from "lib";
import { getRange, getNextSiblings } from "lib/DOMNodeUtils";

export const handleKeydownForParagraphSpacing = ({
  event,
  updateParagraphSpacing = noop
}) => {
  const isMetaOrCtrl = event.ctrlKey || event.metaKey;
  const range = getRange();
  const isClosedSelection = range.startOffset === range.endOffset;

  if (isClosedSelection && range.startContainer.nodeName === "DIV") {
    const target = range.startContainer.childNodes[range.startOffset];

    if (target && target.nodeName === "BR") {
      // we have a br selected
      if (
        [keyCodes.deleteKey].includes(event.keyCode) ||
        [keyCodes.backspaceKey].includes(event.keyCode)
      ) {
        // clicking backspace or delete keys while in a br, remove the break
        target.remove();
        event.preventDefault();
        event.stopPropagation();
        updateParagraphSpacing();
        return;
      }

      const input = String.fromCharCode(event.keyCode);

      if (!isMetaOrCtrl && /[a-zA-Z0-9-_ ]/.test(input)) {
        // handle other alpha-numeric input inside the break
        event.preventDefault();
        event.stopPropagation();

        const nextSiblings = getNextSiblings(target);
        const isEndOfInput =
          nextSiblings.length === 0 ||
          nextSiblings.every(sibling => sibling.nodeName === "BR");

        if (!isEndOfInput) {
          document.execCommand("insertLineBreak");
        }

        // get the character that we are going to be inserting first
        let character = String.fromCharCode(
          96 <= event.keyCode && event.keyCode <= 105
            ? event.keyCode - 48
            : event.keyCode
        );
        if (!event.shiftKey) {
          character = character.toLowerCase();
        }

        // create our new text node to contain the input character
        const textNode = document.createTextNode(character);
        range.startContainer.insertBefore(textNode, target);
        target.remove();

        // update the selection to be after the character we inserted so that the user can continue to type
        const selection = window.getSelection();
        const updatedRange = new Range();
        updatedRange.setStart(textNode, 1);
        selection.removeAllRanges();
        selection.addRange(updatedRange);

        // update the paragraph spacing styling
        updateParagraphSpacing();
      }
    }
  } else if (
    [keyCodes.backspaceKey].includes(event.keyCode) &&
    isClosedSelection &&
    range.startContainer.nodeName === "#text" &&
    range.startOffset === 0 &&
    range.startContainer.previousSibling &&
    range.startContainer.previousSibling.nodeName === "BR"
  ) {
    // we are at the start of the text node with a break coming before it
    range.startContainer.previousSibling.remove();
    event.preventDefault();
    event.stopPropagation();
    updateParagraphSpacing();
  } else if (
    ([keyCodes.deleteKey].includes(event.keyCode) ||
      (event.keyCode === keyCodes.backspaceKey && event.ctrlKey)) &&
    isClosedSelection &&
    range.startContainer.nodeName === "#text" &&
    range.startContainer.textContent.length === range.startOffset &&
    range.startContainer.nextSibling &&
    range.startContainer.nextSibling.nodeName === "BR"
  ) {
    // we are at the end of the text node with a break coming after it
    range.startContainer.nextSibling.remove();
    event.preventDefault();
    event.stopPropagation();
    updateParagraphSpacing();
  }
};
