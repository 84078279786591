import { priorityCountries, secondaryCountries } from "lib/countriesList";
import { getRegionsForCountry } from "lib/regionsList";
import { SHIPPING_COUNTRY_CODES } from "lib/constants";

export const getDefaultStateForCountry = countryCode => {
  switch (countryCode) {
    case "US": {
      return "AL";
    }
    case "AU":
    default: {
      return "QLD";
    }
  }
};

export const addressTypes = [
  {
    key: "BUSINESS",
    label: "Business"
  },
  {
    key: "RESIDENTIAL",
    label: "Residential"
  }
];

export const parseBillingUpdate = ({ key, value }) => {
  let updateKey = key;
  let updateValue = value;

  return {
    updateKey,
    updateValue
  };
};

const validateField = ({ value }) => Boolean(value);

export const getMappedBillingRows = ({
  billingDetails,
  onChange,
  onBlur,
  errors
}) => {
  const mappedBillingFields = {};
  Object.keys(billingFields).forEach(key => {
    const fieldError = errors.find(error => error.fields.includes(key));
    const copiedFieldError = fieldError ? { ...fieldError } : undefined;
    if (copiedFieldError) {
      copiedFieldError.description = `${key} ${copiedFieldError.description}`;
    }
    mappedBillingFields[key] = {
      ...billingFields[key],
      value: billingDetails[key],
      onChange,
      onBlur,
      validate: validateField,
      error: copiedFieldError
    };
    if (key === "state") {
      // state options are based on current country
      const options = getRegionsForCountry({
        countryCode: billingDetails.country || billingFields.country.default
      });

      if (options.length) {
        mappedBillingFields[key].options = options;
        const currentValueExists = options
          .map(option => option.key)
          .includes(billingDetails[key]);
        const defaultValueExists = options
          .map(option => option.key)
          .includes(billingDetails[key]);
        if (currentValueExists) {
          mappedBillingFields[key].value = billingDetails[key];
        } else if (defaultValueExists) {
          mappedBillingFields[key].value = billingFields[key].default;
        } else {
          mappedBillingFields[key].value = options[0].key;
        }
      } else {
        mappedBillingFields[key].type = "text";
      }
    }
    if (key === "postcode" && billingDetails.country !== "AU") {
      mappedBillingFields[key].label = "Zip Code";
    }
  });
  return billingRows(mappedBillingFields);
};

const billingFields = {
  name: {
    label: "Name",
    key: "name",
    type: "text",
    default: ""
  },
  street: {
    label: "Address",
    key: "street",
    type: "text",
    default: ""
  },
  suburb: {
    label: "Suburb or City",
    key: "suburb",
    type: "text",
    default: ""
  },
  country: {
    label: "Country/Region",
    key: "country",
    type: "dropdown",
    default: "AU",
    options: [priorityCountries, secondaryCountries],
    isSearchable: true,
    searchPlaceholder: "Search Countries"
  },
  state: {
    label: "State/Territory",
    key: "state",
    type: "dropdown",
    default: "QLD",
    isSearchable: true,
    searchPlaceholder: "Search States"
  },
  postcode: {
    label: "Postcode",
    key: "postcode",
    type: "text",
    default: ""
  }
};

const billingRows = fields => [
  [fields.name],
  [fields.street],
  [fields.suburb],
  [fields.country, fields.state, fields.postcode]
];

export const getMappedShippingRows = ({
  shippingDetails,
  onChange,
  onBlur,
  isShippingSameAsBilling,
  errors
}) => {
  const mappedShippingFields = {};
  Object.keys(shippingFields).forEach(key => {
    const fieldError = errors.find(error => error.fields.includes(key));
    const copiedFieldError = fieldError ? { ...fieldError } : undefined;
    if (copiedFieldError) {
      copiedFieldError.description = `${key} ${copiedFieldError.description}`;
    }
    mappedShippingFields[key] = {
      ...shippingFields[key],
      value: shippingDetails[key],
      onChange,
      onBlur,
      validate: validateField,
      error: copiedFieldError
    };
    if (key === "state") {
      // state options are based on current country
      const options = getRegionsForCountry({
        countryCode: shippingDetails.country || shippingFields.country.default
      });
      if (options.length) {
        mappedShippingFields[key].options = options;
        const currentValueExists = options
          .map(option => option.key)
          .includes(shippingDetails[key]);
        const defaultValueExists = options
          .map(option => option.key)
          .includes(shippingDetails[key]);
        if (currentValueExists) {
          mappedShippingFields[key].value = shippingDetails[key];
        } else if (defaultValueExists) {
          mappedShippingFields[key].value = billingFields[key].default;
        } else {
          mappedShippingFields[key].value = options[0].key;
        }
      } else {
        mappedShippingFields[key].type = "text";
      }
    }
    if (key === "postcode" && shippingDetails.country !== "AU") {
      mappedShippingFields[key].label = "Zip Code";
    }
    if (key === "name" && shippingDetails.addressType === "RESIDENTIAL") {
      mappedShippingFields[key].label = "Name";
    }
  });
  return shippingRows(mappedShippingFields, isShippingSameAsBilling);
};

export const shippingFields = {
  attention: {
    label: "Attention",
    key: "attention",
    type: "text",
    optional: true,
    default: ""
  },
  name: {
    label: "Company Name",
    key: "name",
    type: "text",
    default: ""
  },
  street: {
    label: "Address",
    key: "street",
    type: "text",
    default: ""
  },
  suburb: {
    label: "Suburb or City",
    key: "suburb",
    type: "text",
    default: ""
  },
  country: {
    label: "Country/Region",
    key: "country",
    type: "dropdown",
    default: "AU",
    options: priorityCountries.filter(country =>
      SHIPPING_COUNTRY_CODES.includes(country.key)
    ),
    isSearchable: true,
    searchPlaceholder: "Search Countries"
  },
  state: {
    label: "State/Territory",
    key: "state",
    type: "dropdown",
    default: "QLD",
    isSearchable: true,
    searchPlaceholder: "Search States"
  },
  postcode: {
    label: "Postcode",
    key: "postcode",
    type: "text",
    default: ""
  },
  addressType: {
    label: "Delivery Type",
    key: "addressType",
    type: "dropdown",
    optional: true,
    options: addressTypes,
    default: "BUSINESS"
  },
  notes: {
    label: "Delivery Note",
    key: "notes",
    type: "textarea",
    optional: true,
    default: "",
    height: "88px"
  }
};

export const shippingRows = (fields, isShippingSameAsBilling) =>
  isShippingSameAsBilling
    ? [
        [fields.attention],
        [fields.name],
        [{ ...fields.street, height: "0px" }],
        [{ ...fields.suburb, height: "0px" }],
        [
          { ...fields.country, height: "0px" },
          { ...fields.state, height: "0px" },
          { ...fields.postcode, height: "0px" }
        ],
        [fields.addressType],
        [fields.notes]
      ]
    : [
        [fields.attention],
        [fields.name],
        [fields.street],
        [fields.suburb],
        [fields.country, fields.state, fields.postcode],
        [fields.addressType],
        [fields.notes]
      ];

export const parseShippingUpdate = ({ key, value }) => {
  let updateKey = key;
  let updateValue = value;

  return {
    updateKey,
    updateValue
  };
};
