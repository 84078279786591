import React from "react";

export const Fade = ({ isDisabled, fade }) => {
  if (isDisabled || !fade) return null;

  return (
    <>
      <feGaussianBlur stdDeviation={fade} result="coloredBlur" />
    </>
  );
};

export default Fade;
