import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PencilIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  isPointer,
  title,
  version = 1,
  ...props
}) => {
  switch (String(version)) {
    case "1": {
      return (
        <svg
          {...props}
          data-is-pointer={isPointer}
          width={width || size || 24}
          height={height || size || 24}
          viewBox="0 0 16 15"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title"
          fill={color || easilGrayXDark}
          stroke={color || easilGrayXDark}
          data-testid="PencilIcon"
        >
          <title>{title}</title>
          <g fill="none" fillRule="evenodd" strokeWidth="2">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.536 14.589l9.346-9.346a1 1 0 0 0 0-1.415l-2.122-2.12a1 1 0 0 0-1.414 0L2 11.052M1.97 11.099v3.499h3.44"
            />
            <path d="M12.575 6.575L9 3" />
          </g>
        </svg>
      );
    }
    case "2": {
      return (
        <svg
          {...props}
          data-is-pointer={isPointer}
          width={width || size || 24}
          height={height || size || 24}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title"
          data-testid="PencilIcon"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              fill={color || easilGrayXDark}
              d="M18.546 7.64l.847-.847a1.546 1.546 0 1 0-2.186-2.186l-.846.847 2.185 2.185zm-1.414 1.414l-2.186-2.186-9.701 9.701-.82 3.006 3.006-.82 9.701-9.701zm2.086-6.779a3.546 3.546 0 0 1 1.59 5.932l-12.15 12.15a1 1 0 0 1-.445.258l-4.95 1.35a1 1 0 0 1-1.228-1.228l1.35-4.95a1 1 0 0 1 .258-.444l12.15-12.15a3.546 3.546 0 0 1 3.425-.918z"
              fillRule="nonzero"
            />
          </g>
        </svg>
      );
    }
    default:
      return <PencilIcon {...this.arguments} version="1" />;
  }
};
PencilIcon.propTypes = {
  version: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PencilIcon;
