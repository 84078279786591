import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const PadlockSmallIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="PadlockSmallIcon"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color || easilGrayXDark}
        fillRule="nonzero"
        d="M10 10h4V8.333C14 7.023 13.08 6 12 6s-2 1.023-2 2.333V10zm6 0a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3V8.333C8 5.963 9.766 4 12 4s4 1.962 4 4.333V10zm-8 2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H8z"
      />
    </g>
  </svg>
);
PadlockSmallIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default PadlockSmallIcon;
