import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const ScissorsIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <path
      d="m5.596 8.432 1.847 3.922-.637 1.368c-.019.04-.048.07-.071.108A3.498 3.498 0 0 1 0 12.5a3.489 3.489 0 0 1 3.262-3.476A.662.662 0 0 1 3.448 9c.662 0 1.466-.208 2.148-.568zM4.133.07a1 1 0 0 1 1.241.444l2.502 4.95L8.89 7.469c.149.267.297.536.466.78.477.692 1.121 1.178 2.438.822.011-.003.019-.003.03-.005 0 0 .005 0 .005.002a3.455 3.455 0 0 1 2.03.206A3.501 3.501 0 0 1 12.5 16c-.8 0-1.529-.277-2.115-.731a3.546 3.546 0 0 1-.999-1.202c-.018-.03-.039-.046-.057-.083l-1.331-2.82-1.57-3.324-2.112-4.471a10.475 10.475 0 0 1-.786-2.127A.998.998 0 0 1 4.133.07zM3.5 11c-.827 0-1.5.673-1.5 1.5S2.673 14 3.5 14 5 13.327 5 12.5 4.327 11 3.5 11zm9 0c-.827 0-1.5.673-1.5 1.5 0 .65.419 1.2.999 1.407.157.057.324.093.501.093.827 0 1.5-.673 1.5-1.5 0-.46-.212-.867-.539-1.142A1.487 1.487 0 0 0 12.5 11zM10.626.514a1 1 0 0 1 1.844.728c-.184.734-.449 1.45-.787 2.127l-.812 1.628-.849 1.823-1.45-2.609z"
      fill={color || easilGrayXDark}
      fillRule="evenodd"
    />
  </svg>
);
ScissorsIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default ScissorsIcon;
