import React, { Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FullLoadingPage, FullErrorPage } from "views/pages";
import { processInvitation } from "state/ui/invitation/invitationActions";
import style from "./style.module.css";
import IOMessageIcon from "views/components/icons/IOMessageIcon";
import auth from "lib/auth";

export class InvitationAcceptanceContainer extends Component {
  static propTypes = {
    token: PropTypes.string,
    invitation: PropTypes.object,
    fetchInvitation: PropTypes.func
  };

  componentDidMount() {
    const { token, processInvitation } = this.props;

    processInvitation(token);
  }

  render() {
    const { invitation, onRedirectTo, token } = this.props;

    if (this.props.queryParams === "?wrongUser=true") {
      return (
        <FullErrorPage
          Icon={IOMessageIcon}
          buttonMessage="Logout"
          errorTitle="This invitation is for a different account"
          errorMessage={
            "It looks like this Team invitation is intended for a different account. If you have another Easil account, please log out and use it to log in."
          }
          errorMessageClassName={style.errorMessage}
          onRedirectTo={() => {
            auth.purgeLocalStorage();
            window.location = `/invitations/${token}`;
          }}
        />
      );
    }

    // NOTE: this error message page could be broken down further, but as it currently stands this is considered acceptable for known error cases
    if (invitation.errors.length > 0) {
      return (
        <FullErrorPage
          Icon={IOMessageIcon}
          buttonMessage="Back to Easil"
          errorTitle="Oh no! Your invitation to join this team has expired."
          errorMessage={
            "Please contact your team Admin to resend the invite to you."
          }
          errorMessageClassName={style.errorMessage}
          onRedirectTo={onRedirectTo}
        />
      );
    }

    return <FullLoadingPage />;
  }
}

const mapStateToProps = state => {
  return {
    invitation: state.ui.invitation,
    queryParams: state.router.location.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    processInvitation: token => dispatch(processInvitation({ token })),
    onRedirectTo: path => dispatch(push(path))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationAcceptanceContainer);
