import CollectionPreviewModalTemplate from "../CollectionPreviewModalTemplate";
import FileIcon from "views/components/icons/FileIcon";
import SizesIcon from "views/components/icons/SizesIcon";

export class DesignPromotionModal extends CollectionPreviewModalTemplate {
  // returns an array of buttons for the modal
  getButtons() {
    const { sizeCount } = this.props;
    const isCollection = sizeCount > 1;

    return [
      {
        label: "Convert Design",
        action: this.handleConvertDesignToMaster,
        visible: true,
        id: "design",
        icon: FileIcon
      },
      {
        label: "Convert Collection",
        action: this.handleConvertCollectionToMaster,
        visible: isCollection,
        id: "collection",
        icon: SizesIcon
      }
    ];
  }

  // convert collection to templates
  handleConvertCollectionToMaster() {
    const {
      selectedDesign: propsDesign,
      convertCollectionToMaster
    } = this.props;
    const { previewDesign: stateDesign } = this.state;

    // prefer taking from state selection
    const { collectionId } = stateDesign || propsDesign;

    // convert collection to masters and then close the modal
    convertCollectionToMaster({ collectionId });
    this.handleCloseModal();
  }

  // convert design to template
  handleConvertDesignToMaster() {
    const { selectedDesign: propsDesign, convertDesignToMaster } = this.props;
    const { previewDesign: stateDesign } = this.state;

    // always use selected design from state if it exists
    const design = stateDesign || propsDesign;

    // convert design to master and then close the modal
    convertDesignToMaster({ designId: design.id });
    this.handleCloseModal();
  }
}

DesignPromotionModal.displayName = "DesignPromotionModal";

export default DesignPromotionModal;
