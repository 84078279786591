import PATHS from "routes/paths";
import { getParameterByName } from "lib/queryStringUtils";
import InputMode from "lib/InputMode";

/**
 * @desc using window.location, check if the current path places us in a folder
 * @param {string} currentLocation - the location to compare against as a key from PATHS
 * @returns {boolean} returns true if in a folder and false if not in a folder
 */
export const isInFolder = (currentLocation = "images") => {
  const pathname = window.location.pathname;
  const splitPath = pathname.split(PATHS[currentLocation]);

  // if there is no path past images then we are not in a folder
  if (splitPath.length === 1) {
    return false;
  }

  // if the path is not a folder then we are not in a folder
  if (!splitPath[1].startsWith(PATHS.folder)) {
    return false;
  }

  // if all cases pass then we are in a folder
  return true;
};

export const getFilters = locationSearch => {
  return {
    folderId: getParameterByName("folderId", locationSearch),
    size: getParameterByName("size", locationSearch),
    term: getParameterByName("term", locationSearch),
    categoryId: getParameterByName("categoryId", locationSearch)
  };
};

/**
 * Derive the sideLocation object from the window.location.search string.
 * @param {string} searchParams - window.location.search string to parse.
 * @return {object} sideLocation object.
 */
export const getSidebarLocation = searchParams => {
  const sidebarTab = getParameterByName("tab", searchParams);
  const sidebarSubTab = getParameterByName("subtab", searchParams);
  const sidebarAssetType = getParameterByName("assetType", searchParams);
  const sidebarCategoryLabel = getParameterByName(
    "categoryLabel",
    searchParams
  );
  const isImMode = InputMode.isImModeOn();

  return {
    tab: sidebarTab || (isImMode ? "layers" : "resize"),
    subtab: sidebarSubTab || null,
    assetType: sidebarAssetType,
    categoryLabel: sidebarCategoryLabel
  };
};
