import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const GarbageCanIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 20}
    height={height || size || 20}
    xmlns="http://www.w3.org/2000/svg"
    data-testid="GarbageCanIcon"
  >
    <g fill="none" fillRule="evenodd">
      <rect width={width} height={height} rx="4" />
      <path d="M3 3h14v14H3z" />
      <g stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <path
          strokeWidth="2"
          d="M4 7h12m-1.5 0v7a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2V7h9zM7 7V6c0-1.105.784-2 1.75-2h2.5c.966 0 1.75.895 1.75 2v1"
        />
        <path
          strokeWidth="1.5"
          d="M8.5 9.75v3.5m3-3.5v3.5"
          fill={color || easilGrayXDark}
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

GarbageCanIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default GarbageCanIcon;
