import {
  UI_OPEN_COLLECTION_PREVIEW_MODAL,
  UI_CLOSE_COLLECTION_PREVIEW_MODAL
} from "./collectionPreviewModalTypes";
import { copyCollectionToDrafts } from "state/api/collection/collectionActions";
import { moveToEditorDesignId } from "state/ui/navigation/navigationActions";

export const openCollectionPreviewModal = ({ selectedDesignId }) => {
  return {
    type: UI_OPEN_COLLECTION_PREVIEW_MODAL,
    selectedDesignId
  };
};

export const closeCollectionPreviewModal = () => {
  return {
    type: UI_CLOSE_COLLECTION_PREVIEW_MODAL
  };
};

export const copyCollectionToDraftsAndOpenSelectedInEditor = ({
  collectionId,
  templateCode
}) => dispatch => {
  const moveToParentDraftInEditor = response => {
    const draftMatchingTemplateCode = response.designs.find(
      design => design.templateCode === templateCode
    );

    // get the id for the draft matching the templateCode, or use the first one
    const designId = draftMatchingTemplateCode
      ? draftMatchingTemplateCode.id
      : response.designs[0].id;
    // open draft matching templateCode in editor
    dispatch(moveToEditorDesignId({ designId }));
  };

  // we need to get the collection copied to drafts
  dispatch(
    copyCollectionToDrafts({
      collectionId,
      onSuccess: moveToParentDraftInEditor
    })
  );
};
