import * as types from "../../entities/designTags/designTagsTypes";

import { formatErrors } from "lib";

export const initState = {
  isAdding: false,
  isRemoving: false,
  isSuccess: false,
  errors: []
};

const errorsArray = error => formatErrors(error);

const addTagToDesignReducers = (state = initState, action) => {
  switch (action.type) {
    case types.ADD_TAG_TO_DESIGN_REQUEST: {
      return {
        ...initState,
        isAdding: true
      };
    }

    case types.ADD_TAG_TO_DESIGN_REQUEST_SUCCESS: {
      return {
        ...initState,
        isSuccess: true
      };
    }

    case types.ADD_TAG_TO_DESIGN_REQUEST_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...initState,
        errors
      };
    }

    default:
      return state;
  }
};

export default addTagToDesignReducers;
