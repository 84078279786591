import { cloneDeep } from "lib";
import { getImageDimensionsFromElement } from "lib/imageHelpers";
import { DEFAULT_MASK } from "lib/constants";

export const processImage = async (element, page) => {
  const adaptedElement = cloneDeep(element);

  if (!adaptedElement.srcHeight || !adaptedElement.srcWidth) {
    // image and background elements should always have a srcWidth and srcHeight, this missing can cause resize and crop issues
    const imageSize = await getImageDimensionsFromElement(element);

    adaptedElement.srcHeight = imageSize.height;
    adaptedElement.srcWidth = imageSize.width;
  }

  // the use of previewUrl on an image element is deprecated and should be replaced with previewSrc
  if (adaptedElement.previewUrl) {
    if (!adaptedElement.previewSrc) {
      adaptedElement.previewSrc = adaptedElement.previewUrl;
    }
    adaptedElement.previewUrl = undefined;
  }

  // check for invalid scale
  if (!adaptedElement.scale || adaptedElement.scale <= 0) {
    // check that there is height and width to work from
    if (adaptedElement.height <= 0 || adaptedElement.width <= 0) {
      if (adaptedElement.height <= 0 && !(adaptedElement.width <= 0)) {
        // the height is missing but we have a width
        adaptedElement.height =
          adaptedElement.srcHeight *
          (adaptedElement.width / adaptedElement.srcWidth);
      } else if (!(adaptedElement.height <= 0) && adaptedElement.width <= 0) {
        // the width is missing but we have a height
        adaptedElement.width =
          adaptedElement.srcWidth *
          (adaptedElement.height / adaptedElement.srcHeight);
      } else {
        // both height and width missing
        adaptedElement.height = adaptedElement.srcHeight;
        adaptedElement.width = adaptedElement.srcWidth;
      }
    }

    const scaleToFit = Math.min(
      adaptedElement.height / adaptedElement.srcHeight,
      adaptedElement.width / adaptedElement.srcWidth
    );

    adaptedElement.scale = scaleToFit;
    adaptedElement.height = adaptedElement.srcHeight * scaleToFit;
    adaptedElement.width = adaptedElement.srcWidth * scaleToFit;
  }

  if (adaptedElement.height <= 0) {
    adaptedElement.height = adaptedElement.srcHeight * adaptedElement.scale;
  }

  if (adaptedElement.width <= 0) {
    adaptedElement.width = adaptedElement.srcWidth * adaptedElement.scale;
  }

  // handle animated elements which are not already included in the page animatedElementsObject
  if (
    adaptedElement.duration &&
    page.animatedElements &&
    !Object.keys(page.animatedElements).includes(element.uniqueId)
  ) {
    window.easil.designAdapter.push({
      duration: adaptedElement.duration,
      uniqueId: element.uniqueId,
      animationDataKey: element.uniqueId,
      pageId: page.uniqueId,
      groupId: element.groupId
    });
  }

  // images and backgrounds should be adapted in the same way adding originalSrc and a mask if none is present
  return {
    ...adaptedElement,
    originalSrc: element.src,
    mask: element.mask || DEFAULT_MASK,
    src: adaptedElement.previewSrc || element.src
  };
};

export default processImage;
