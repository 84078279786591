import { createSelector } from "reselect";
import { currentSubscriptionSelector } from "state/entities/subscriptions/subscriptionsSelectors";
import UserSubscriptionPlan from "views/containers/plansContainer/PricingGrid/UserSubscriptionPlan/UserSubscriptionPlan";

export const currentSubscriptionPlanSelector = (state, planId) =>
  state.entities.subscriptionPlans[planId];

const subscriptionPlansSelector = state => state.entities.subscriptionPlans;

const processGetUserSubscriptionPlan = ({ subscription, plan }) =>
  new UserSubscriptionPlan({ subscription, plan });

const reselectCurrentSubscriptionPlanSelector = createSelector(
  [currentSubscriptionSelector, subscriptionPlansSelector],
  (subscription, subscriptionPlans) => {
    const plan = subscriptionPlans[subscription.planId];
    return {
      subscription,
      plan
    };
  }
);

export const getUserSubscriptionPlan = createSelector(
  [reselectCurrentSubscriptionPlanSelector],
  processGetUserSubscriptionPlan
);
