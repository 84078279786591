import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const CaretSlimIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  direction = "up"
}) => {
  const directionToAngle = {
    up: 0,
    right: 90,
    down: 180,
    left: -90
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction]}deg)`,
    ...style
  };

  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 14 8"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
      data-testid="CaretSlimIcon"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M-5 16h24V-8H-5z" />
        <path
          fill={color || easilGrayXDark}
          fillRule="nonzero"
          d="M12.293 7.707a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 1.414 1.414L7 2.414l5.293 5.293z"
        />
      </g>
    </svg>
  );
};

CaretSlimIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.number,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default CaretSlimIcon;
