import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const DesignRemixPage = lazy(() => import("./DesignRemixPage"));

export const DesignRemixPageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <DesignRemixPage {...props} />
  </Suspense>
);
DesignRemixPageLazy.displayName = "DesignRemixPage";

export default DesignRemixPageLazy;
