import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const SmoothPencilIcon = ({
  width,
  size,
  height,
  style,
  color,
  noPointer,
  title,
  tipText,
  tipFor,
  ...props
}) => (
  <svg
    {...props}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    data-testid="SmoothPencilIcon"
    data-tip={tipText}
    data-for={tipFor}
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        d="M19.218 2.275a3.546 3.546 0 011.59 5.932l-12.15 12.15a1 1 0 01-.445.258l-4.95 1.35a1 1 0 01-1.228-1.228l1.35-4.95a1 1 0 01.258-.444l12.15-12.15a3.546 3.546 0 013.425-.918zm-4.272 4.592L5.245 16.57l-.82 3.006 3.006-.82 9.7-9.702-2.185-2.186zm2.261-2.26l-.847.845 2.186 2.186.847-.845a1.546 1.546 0 10-2.186-2.186z"
        fill={color || easilGrayXDark}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
SmoothPencilIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default SmoothPencilIcon;
