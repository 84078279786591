import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const GridIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke={color || easilGrayXDark}
  >
    <title>{title}</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-2" transform="translate(-2.000000, -2.000000)">
        <rect id="Rectangle-8" x="0" y="0" width="24" height="24" />
        <g>
          <rect id="Rectangle-8" x="0" y="0" width="24" height="24" />
          <g id="Group-43" transform="translate(11.000000, 2.000000)" />
          <rect
            id="Rectangle-29"
            fill={color || easilGrayXDark}
            x="11"
            y="4"
            width="2"
            height="16"
          />
          <rect
            id="Rectangle-30"
            fill={color || easilGrayXDark}
            x="4"
            y="11"
            width="16"
            height="2"
          />
          <path
            d="M4,4 L4,20 L20,20 L20,4 L4,4 Z M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z"
            id="Rectangle-13"
            fill={color || easilGrayXDark}
            fillRule="nonzero"
          />
        </g>
        <g id="Group-43" transform="translate(11.000000, 2.000000)" />
      </g>
    </g>
  </svg>
);
GridIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default GridIcon;
