import React from "react";
import DesignSizeOption from "views/components/CollectionPreviewModal/DesignSizeOption";
import style from "./CollectionDetailsStyle.module.css";
import { MultiLineEllipsis } from "views/components/MultiLineEllipsis/MultiLineEllipsis";
import { isMediaVideoFormat } from "lib/mediaSourceHelpers";
import { Scrollable } from "views/components";

const defaultState = {
  previewDesign: null,
  currentHoverTarget: null,
  isImageReady: true
};

class CollectionDetails extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangePreview = this.handleChangePreview.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
    this.handleImageLoad = this.handleImageLoad.bind(this);
    this.isHighlighted = this.isHighlighted.bind(this);
    this.getCollectionPrice = this.getCollectionPrice.bind(this);

    this.videoRef = React.createRef();

    this.state = defaultState;
  }

  // sets the currently selected previewDesign, clears when attempting to select already selected preview
  handleChangePreview(design) {
    const { previewDesign } = this.state;
    let newPreviewDesign = design;

    if (previewDesign && previewDesign.id === design.id) {
      // unset preview if clicking again
      newPreviewDesign = null;
    }

    if (isMediaVideoFormat(design.thumbnailUrl)) {
      this.setState({
        previewDesign: newPreviewDesign
      });
      this.videoRef.current?.load();
    } else {
      this.setState({
        isImageReady: false,
        previewDesign: newPreviewDesign
      });
    }
  }

  handleImageError() {
    this.setState({
      isImageReady: false
    });
  }

  handleImageLoad() {
    this.setState({
      isImageReady: true
    });
  }

  // returns a boolean describing the highlight status of the given design
  isHighlighted(design) {
    const { selectedDesign } = this.props;
    const { previewDesign } = this.state;
    const designToDisplay = previewDesign || selectedDesign;

    // previewed design should always highlight
    if (designToDisplay && designToDisplay.id === design.id) {
      return true;
    }
  }

  getCollectionPrice() {
    const { collectionPricing, currentTeamRegion, currentOrder } = this.props;

    let price, currency;

    // if we have a current order in progress
    // with updated pricing (coupon discount applied)
    // we should update the displayed collection price
    if (currentOrder && currentOrder.currency && currentOrder.amount) {
      return (
        <div className={style.collectionPrice}>
          <span>{currentOrder.currency}</span> ${currentOrder.amount}
        </div>
      );
    }

    if (collectionPricing) {
      if (currentTeamRegion === "AU") {
        price = collectionPricing.priceAud;
        currency = "AUD";
      } else {
        price = collectionPricing.priceUsd;
        currency = "USD";
      }
    }

    return (
      <div className={style.collectionPrice}>
        <span>{currency}</span> ${price}
      </div>
    );
  }

  render() {
    const { selectedDesign, collectionDesignsCategorised } = this.props;
    const { previewDesign } = this.state;
    const designSizes = collectionDesignsCategorised
      .map(category => category.designs)
      .flat(1);
    const designToDisplay = previewDesign || selectedDesign;

    return (
      <div className={style.collectionDetails}>
        <div className={style.designPreviewWindow}>
          {isMediaVideoFormat(designToDisplay.thumbnailUrl) ? (
            <video
              ref={this.videoRef}
              className={style.designThumbnail}
              muted
              loop
              autoPlay={true}
            >
              <source src={designToDisplay.thumbnailUrl} type="video/mp4" />
            </video>
          ) : (
            <img
              className={style.designThumbnail}
              src={designToDisplay.thumbnailUrl}
              alt={`${designToDisplay.title} - ${designToDisplay.templateSize}`}
              onError={this.handleImageError}
              onLoad={this.handleImageLoad}
            />
          )}
        </div>
        <div className={style.collectionInformation}>
          <div className={style.collectionHeaderPrice}>
            <MultiLineEllipsis clamp={3} className={style.designTitle}>
              {designToDisplay.title}
            </MultiLineEllipsis>
            {this.getCollectionPrice()}
          </div>
          <div className={style.collectionIncludes}>
            This collection includes:
          </div>
          <Scrollable
            scrollYClassName={style.scrollbar}
            scrollContentClassName={style.scrollContentClassName}
          >
            <div className={style.designSizeOptions}>
              {designSizes.map(design => (
                <DesignSizeOption
                  design={design}
                  isHighlighted={this.isHighlighted(design)}
                  onClick={this.handleChangePreview}
                  key={design.id}
                />
              ))}
            </div>
          </Scrollable>
        </div>
      </div>
    );
  }
}

CollectionDetails.displayName = "CollectionDetails";

export default CollectionDetails;
