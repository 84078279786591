export const rotateRect = (x, y, width, height, angle) => {
  const angleInRadians = (angle * Math.PI) / 180.0;
  return [
    {
      // top-left
      x:
        x -
        (width / 2) * Math.cos(angleInRadians) +
        (height / 2) * Math.sin(angleInRadians),
      y:
        y -
        (width / 2) * Math.sin(angleInRadians) -
        (height / 2) * Math.cos(angleInRadians)
    },
    {
      // top-right
      x:
        x +
        (width / 2) * Math.cos(angleInRadians) +
        (height / 2) * Math.sin(angleInRadians),
      y:
        y +
        (width / 2) * Math.sin(angleInRadians) -
        (height / 2) * Math.cos(angleInRadians)
    },
    {
      // bottom-right
      x:
        x +
        (width / 2) * Math.cos(angleInRadians) -
        (height / 2) * Math.sin(angleInRadians),
      y:
        y +
        (width / 2) * Math.sin(angleInRadians) +
        (height / 2) * Math.cos(angleInRadians)
    },
    {
      // bottom-left
      x:
        x -
        (width / 2) * Math.cos(angleInRadians) -
        (height / 2) * Math.sin(angleInRadians),
      y:
        y -
        (width / 2) * Math.sin(angleInRadians) +
        (height / 2) * Math.cos(angleInRadians)
    }
  ];
};
