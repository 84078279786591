import * as invoicesEntitiesTypes from "state/entities/invoices/invoicesTypes";
import { immutableUpdate } from "lib/immutableUpdate";

export const initState = {
  pages: {},
  detailedIds: {},
  totalCount: null,
  pageSize: 50
};

const invoicesApiReducers = (state = initState, action) => {
  switch (action.type) {
    case invoicesEntitiesTypes.FETCH_INVOICES_REQUEST: {
      const { page } = action.request;

      return immutableUpdate(state, {
        pages: {
          $auto: {
            [page]: {
              $auto: {
                $merge: {
                  isFetching: true,
                  ids: []
                }
              }
            }
          }
        }
      });
    }

    case invoicesEntitiesTypes.FETCH_INVOICES_REQUEST_SUCCESS: {
      const {
        response: { ids = [] },
        request: { page }
      } = action;
      return immutableUpdate(state, {
        pages: {
          $auto: {
            [page]: {
              $auto: {
                $merge: {
                  isFetching: false,
                  ids
                }
              }
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default invoicesApiReducers;
