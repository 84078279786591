export const FETCH_PERSONAL_TAGS_REQUEST =
  "API/ENTITIES/TAGS/FETCH_PERSONAL_TAG_REQUEST";
export const FETCH_PERSONAL_TAGS_REQUEST_SUCCESS =
  "API/ENTITIES/TAGS/FETCH_PERSONAL_TAG_REQUEST_SUCCESS";
export const FETCH_PERSONAL_TAGS_REQUEST_FAILURE =
  "API/ENTITIES/TAGS/FETCH_PERSONAL_TAG_REQUEST_FAILURE";

export const FETCH_TEAM_TAGS_REQUEST =
  "API/ENTITIES/TAGS/FETCH_TEAM_TAG_REQUEST";
export const FETCH_TEAM_TAGS_REQUEST_SUCCESS =
  "API/ENTITIES/TAGS/FETCH_TEAM_TAG_REQUEST_SUCCESS";
export const FETCH_TEAM_TAGS_REQUEST_FAILURE =
  "API/ENTITIES/TAGS/FETCH_TEAM_TAG_REQUEST_FAILURE";
