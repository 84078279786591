const GIF_FILE_EXTENSIONS = ["gif"];

export const isGif = url => {
  if (!url) return false;
  const fileExtension = url.split(".").pop();
  if (
    GIF_FILE_EXTENSIONS.some(
      extension => fileExtension && fileExtension.toLowerCase() === extension
    )
  ) {
    return true;
  }
  return false;
};
