import React, { Component } from "react";
import PropTypes from "prop-types";
import Option from "views/components/option/Option";
import AvatarOption from "views/components/option/AvatarOption";

class DropdownOptionsRenderer extends Component {
  handleSelected(selected, multiSelect, option) {
    if (multiSelect)
      return selected.filter(item => item.id === option.id).length > 0;
    return selected;
  }

  render() {
    const {
      onChange,
      selected,
      options,
      multiSelect,
      isAvatarOption
    } = this.props;

    const OptionRenderer = isAvatarOption ? AvatarOption : Option;

    return (
      <React.Fragment>
        {options && (
          <React.Fragment>
            {options.map(option => (
              <OptionRenderer
                key={`dropdownOption-${option.id}`}
                value={option.id}
                label={option.label}
                onClick={() => onChange(option.id)}
                selected={this.handleSelected(selected, multiSelect, option)}
                src={option.src}
              />
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

DropdownOptionsRenderer.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DropdownOptionsRenderer;
