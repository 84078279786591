import * as types from "../../entities/folders/foldersTypes";
import { parseClientErrorMessages } from "lib";

export const initState = {
  errors: [],
  isProcessing: false,
  isSuccess: false
};

const teamFoldersReducers = (state = initState, action) => {
  switch (action.type) {
    case types.CREATE_TEAM_FOLDER_REQUEST:
    case types.CREATE_PERSONAL_FOLDER_REQUEST:
    case types.DELETE_TEAM_FOLDER_REQUEST:
    case types.FETCH_TEAM_FOLDERS_REQUEST:
    case types.UPDATE_TEAM_FOLDER_REQUEST: {
      return {
        ...state,
        errors: [],
        isSuccess: false,
        isProcessing: true
      };
    }

    case types.CREATE_TEAM_FOLDER_REQUEST_SUCCESS:
    case types.CREATE_PERSONAL_FOLDER_REQUEST_SUCCESS:
    case types.DELETE_TEAM_FOLDER_REQUEST_SUCCESS:
    case types.FETCH_TEAM_FOLDERS_REQUEST_SUCCESS:
    case types.UPDATE_TEAM_FOLDER_REQUEST_SUCCESS: {
      return {
        ...state,
        errors: [],
        isSuccess: true,
        isProcessing: false
      };
    }

    case types.CREATE_TEAM_FOLDER_REQUEST_FAILURE:
    case types.CREATE_PERSONAL_FOLDER_REQUEST_FAILURE:
    case types.DELETE_TEAM_FOLDER_REQUEST_FAILURE:
    case types.FETCH_TEAM_FOLDERS_REQUEST_FAILURE:
    case types.UPDATE_TEAM_FOLDER_REQUEST_FAILURE: {
      return {
        ...state,
        errors: parseClientErrorMessages(action.error),
        isProcessing: false,
        isSuccess: false
      };
    }

    default:
      return state;
  }
};

export default teamFoldersReducers;
