import {
  addHyphensToUUID,
  decodeHtmlTable,
  isEmpty,
  isNil,
  keyBy,
  Logger,
  omit,
  uniq
} from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
import Design from "state/ui/editor/Design";

const privateMethods = {
  findDuplicatesInPage({ page, duplicatedElements, uniqueElements, design }) {
    page.elementsOrder.map((itemId, elementIndex) =>
      privateMethods.checkIfElementIsDuplicated(
        itemId,
        elementIndex,
        page,
        duplicatedElements,
        uniqueElements,
        design
      )
    );
  },

  findDuplicates(design) {
    const { pagesOrder } = design;

    const uniqueElements = new Set();
    const duplicatedElements = [];

    pagesOrder
      .map(pageId => ({ id: pageId, ...design.pages[pageId] }))
      .forEach(page =>
        privateMethods.findDuplicatesInPage({
          page,
          duplicatedElements,
          uniqueElements,
          design
        })
      );

    return duplicatedElements;
  },

  checkIfElementIsDuplicated(
    itemId,
    elementIndex,
    page,
    duplicatedElements,
    uniqueElements,
    design
  ) {
    const element = design.getElement(itemId);

    if (element.type === "group") {
      privateMethods.lookForDuplicatedGroup({
        group: element,
        page,
        elementIndex,
        duplicatedElements,
        uniqueElements
      });
    } else {
      privateMethods.lookForDuplicatedElement({
        element,
        page,
        elementIndex,
        duplicatedElements,
        uniqueElements
      });
    }
  },

  lookForDuplicatedElement({
    element,
    page,
    elementIndex,
    duplicatedElements,
    uniqueElements
  }) {
    if (uniqueElements.has(element.uniqueId)) {
      duplicatedElements.push({
        in: "page",
        pageId: page.id,
        elementId: element.uniqueId,
        elementIndex: elementIndex
      });
    } else {
      uniqueElements.add(element.uniqueId);
    }
  },

  lookForDuplicatedGroup({
    group,
    page,
    elementIndex,
    duplicatedElements,
    uniqueElements
  }) {
    privateMethods.lookForDuplicatedElement({
      element: group,
      page,
      elementIndex,
      uniqueElements,
      duplicatedElements
    });

    /* map group nested elements */
    group.elementsOrder.forEach((nestedElementId, nestedIndex) => {
      if (uniqueElements.has(nestedElementId)) {
        duplicatedElements.push({
          in: "group",
          elementId: nestedElementId,
          elementIndex: nestedIndex,
          groupId: group.uniqueId
        });
      } else {
        uniqueElements.add(nestedElementId);
      }
    });
  },

  updateGroup(elementToBeUpdated, design) {
    const element = design.getElement(elementToBeUpdated.elementId);

    const [elementWithNewId] = element.clone();

    const newId = elementWithNewId.uniqueId;

    const group = design.elements[elementToBeUpdated.groupId];

    /* update group */
    const groupUpdated = immutableUpdate(group, {
      elementsOrder: {
        [elementToBeUpdated.elementIndex]: {
          $set: newId
        }
      }
    });

    /* merge new Elements */
    const designUpdated = new Design(
      immutableUpdate(design, {
        elements: {
          $merge: {
            [newId]: elementWithNewId,
            [groupUpdated.uniqueId]: groupUpdated
          }
        }
      })
    );

    return designUpdated;
  },

  updatePage(elementToBeUpdated, design) {
    const element = design.getElement(elementToBeUpdated.elementId);

    const [elementWithNewId] = element.clone();

    if (elementWithNewId.type === "group") {
      elementWithNewId.elementsOrder = element.elementsOrder.slice();
    }

    const newId = elementWithNewId.uniqueId;

    const designUpdated = new Design(
      immutableUpdate(design, {
        pages: {
          [elementToBeUpdated.pageId]: {
            elementsOrder: {
              [elementToBeUpdated.elementIndex]: { $set: newId }
            }
          }
        },
        elements: {
          $merge: { [newId]: elementWithNewId }
        }
      })
    );

    return designUpdated;
  },

  replaceDuplicates(duplicatedElements, design) {
    let designUpdated = design;

    duplicatedElements.forEach(elementToBeUpdated => {
      switch (elementToBeUpdated.in) {
        case "page": {
          designUpdated = privateMethods.updatePage(
            elementToBeUpdated,
            designUpdated
          );
          break;
        }
        case "group": {
          designUpdated = privateMethods.updateGroup(
            elementToBeUpdated,
            designUpdated
          );
          break;
        }

        default: {
          /* ERROR */
          Logger.error(
            "Invalid condition: duplicated element has to be in page or group"
          );
        }
      }
    });

    return designUpdated;
  },

  findGroupsWithMissingElements(elements) {
    const elementsArray = Object.values(elements);

    const groupsWithMissingElements = elementsArray.filter(
      isGroupWithMissingElement
    );

    return groupsWithMissingElements;

    function isGroupWithMissingElement(element) {
      if (element.type !== "group") {
        return false;
      }

      const allGroupElementsExists = element.elementsOrder.some(
        nestedElement => !Boolean(elements[nestedElement])
      );

      return Boolean(allGroupElementsExists);
    }
  },

  removeMissingElementsFromGroupElementsOrder({ groups, elements }) {
    return groups.map(groupWithoutMissingElements);

    function groupWithoutMissingElements(group) {
      return {
        ...group,
        elementsOrder: group.elementsOrder.filter(nestedElemenet =>
          Boolean(elements[nestedElemenet])
        )
      };
    }
  }
};

const DesignHelper = {
  replaceDuplicateElements(designData) {
    const designInstance = new Design(designData);

    const duplicatedElements = privateMethods.findDuplicates(designInstance);
    const designUpdated = privateMethods.replaceDuplicates(
      duplicatedElements,
      designInstance
    );

    return designUpdated;
  },
  removeMissingElementsFromGroups(designData) {
    const designInstance = new Design(designData);

    const elements = designInstance.elements;

    const groupsWithMissingElements = privateMethods.findGroupsWithMissingElements(
      elements
    );

    const groupsFixed = privateMethods.removeMissingElementsFromGroupElementsOrder(
      {
        groups: groupsWithMissingElements,
        elements
      }
    );

    const designUpdated = {
      ...designInstance,
      elements: {
        ...elements,
        ...keyBy(groupsFixed, "uniqueId")
      }
    };

    return designUpdated;
  },
  removeTablesWithNoRows(designData) {
    const designInstance = new Design(designData);

    const elements = designInstance.elements;

    const tablesWithoutRow = Object.values(elements).filter(element => {
      return element.type === "table" && isEmpty(element.rows);
    });

    const tablesWithoutRowIds = tablesWithoutRow.map(table => table.uniqueId);

    const designUpdated = {
      ...designInstance,
      elements: omit(elements, tablesWithoutRowIds)
    };

    return designUpdated;
  },
  replaceHtmlEntitiesInTableCells(designData) {
    /* iterate through the design and replace &nbsp with space characters where found */
    const designInstance = new Design(designData);

    const elements = designInstance.elements;

    let tableElements = Object.values(elements).filter(element => {
      return element.type === "table";
    });

    const tablesElementsIds = tableElements.map(table => table.uniqueId);

    /* take the tableElements and for every textField in them run a string replace for space characters */
    tableElements = tableElements.map(tableElement => {
      return decodeHtmlTable(tableElement);
    });

    tableElements = Object.assign({}, ...tableElements);

    /* update the designData */
    const designUpdated = {
      ...designInstance,
      elements: {
        ...omit(elements, tablesElementsIds),
        ...tableElements
      }
    };
    return designUpdated;
  },

  /* Unfortunately at some point the editor would let the user insert a
   * textbox in the maskImage, leading to a broken design, this method
   * fix those broken designs */
  removeBrokenTextMask(designData) {
    Object.values(designData.elements)
      .filter(isTextBoxWithBrokenMask)
      .forEach(fixTextBoxWithBrokenMask);

    return designData;

    function isTextBoxWithBrokenMask(element) {
      return (
        element.type === "textbox" &&
        element.maskImage &&
        isNil(element.maskImage.src)
      );
    }

    function fixTextBoxWithBrokenMask(element) {
      designData.elements[element.uniqueId] = omit(element, "maskImage");
    }
  },

  /* Some designs have been discovered where text masks were set up with no opacity value
  this method fills in the opacity for these designs */
  fixTextMaskWithNoOpacity(designData) {
    Object.values(designData.elements)
      .filter(isTextBoxWithMissingMaskOpacity)
      .forEach(fixTextBoxWithMissingMaskOpacity);

    return designData;

    // determine if an element is a textbox with a mask which has no opacity property
    function isTextBoxWithMissingMaskOpacity(element) {
      return (
        element.type === "textbox" &&
        element.maskImage &&
        isNil(element.maskImage.opacity)
      );
    }

    // to fix missing opacity set opacity to 1
    function fixTextBoxWithMissingMaskOpacity(element) {
      designData.elements[element.uniqueId].maskImage.opacity = 1;
    }
  },

  /* finds designs that reference the group provided and appends them to the element list if they are not already present */
  findElementsMissingFromGroup(group, elements) {
    const elementsReferencingGroup = elements
      .filter(element => element.groupId && element.groupId === group.id)
      .map(element => element.uniqueId);

    const elementsForGroup = uniq(
      group.elements.concat(elementsReferencingGroup)
    );

    return Object.assign(group, { elements: elementsForGroup });
  },

  fixTable2Cells(designData) {
    // uuid is stripped of its hyphens by the api middleware
    // calling camelizeKeys
    Object.values(designData.elements)
      .filter(element => element.type === "table2")
      .forEach(element => {
        designData.elements[element.uniqueId].cells = Object.keys(
          element.cells
        ).reduce((obj, cellId) => {
          obj[addHyphensToUUID(cellId)] = element.cells[cellId];
          return obj;
        }, {});
      });

    return designData;
  }
};

export default DesignHelper;
