import React from "react";
import style from "./style.module.css";

const FontModalDivider = ({ text = "OR" }) => {
  return (
    <div className={style.fontModalDivider}>
      <div className={style.fontModalDividerLine} />
      <div className={style.fontModalDividerText}>{text}</div>
    </div>
  );
};

export default FontModalDivider;
