import React from "react";
import BrandKitSearchInput from "views/components/BrandKit/BrandKitSearch/BrandKitSearchInput";
import BrandKitSearchTextButton from "views/components/BrandKit/BrandKitSearch/BrandKitSearchTextButton";
import BrandKitFontOps from "./BrandKitFontOps";
import PlusIcon from "views/components/icons/PlusIcon";

export class BrandKitFontSearch extends React.Component {
  constructor(props) {
    super(props);

    this.getFontArr = BrandKitFontOps.getFontArr.bind(this);
    this.handleSearchInput = BrandKitFontOps.handleSearchInput.bind(this);
    this.handleOpenEditFontModal = BrandKitFontOps.handleOpenEditFontModal.bind(
      this
    );
  }

  render() {
    const { isBrandManager, location } = this.props;

    const urlParams = new URLSearchParams(location.search);
    const urlTerm = urlParams.get("term");

    return (
      <>
        <BrandKitSearchInput
          onChange={value => this.handleSearchInput(value)}
          onClear={() => this.handleSearchInput(null)}
          placeholder="Search for Brand Fonts by name"
          value={urlTerm || ""}
        />

        {isBrandManager && (
          <BrandKitSearchTextButton
            Icon={() => <PlusIcon color="#3184fc" size="18px" />}
            onClick={() => {
              this.handleSearchInput(null);
              this.handleOpenEditFontModal(null);
            }}
            title="Add Font"
          />
        )}
      </>
    );
  }
}

export default BrandKitFontSearch;
