import React, { Component } from "react";
import FileDragAndDrop from "views/components/FileDragAndDrop";
import { getFileExtension, Logger, omit } from "lib";
import { getGifUploadData } from "lib/imageHelpers";
import dateFns from "date-fns";
import {
  ANIMATION_VIDEO_MAX_DURATION,
  STATIC_ASSET_FILE_TYPES,
  ANIMATED_ASSET_FILE_TYPES,
  DEFAULT_ACCEPTED_STATIC_FILE_TYPES,
  DEFAULT_ACCEPTED_ANIMATED_FILE_TYPES
} from "lib/constants";
import style from "./style.module.css";

const calculateReadableSize = size => {
  if (size < 1000) return `${size}B`;
  if (size > 1000) return `${(size / 1000).toFixed(0)}KB`;
  if (size > Math.pow(1000, 2))
    return `${(size / Math.pow(1000, 2)).toFixed(2)}MB`;
  if (size > Math.pow(1000, 3))
    return `${(size / Math.pow(1000, 3)).toFixed(2)}GB`;
};

const acceptedFileTypes = [
  ...STATIC_ASSET_FILE_TYPES,
  ...ANIMATED_ASSET_FILE_TYPES
];

const initState = {
  uploadingImages: {},
  isCancelling: false,
  isModalOpen: false,
  shouldUpdate: false
};

export class ImagesListDragAndDropUpload extends Component {
  constructor(props) {
    super(props);

    this.handleFlagFileAsFinishedUploading = this.handleFlagFileAsFinishedUploading.bind(
      this
    );
    this.handleUploadImages = this.handleUploadImages.bind(this);
    this.onMediaUpload = this.onMediaUpload.bind(this);
    this.getOverlayTextStyle = this.getOverlayTextStyle.bind(this);
    this.setShouldUpdate = this.setShouldUpdate.bind(this);
    this.wrapperRef = React.createRef();

    this.state = initState;
  }

  componentDidMount() {
    document
      .querySelector("#contentWrapper")
      .addEventListener("scroll", this.setShouldUpdate);
    window.addEventListener("resize", this.setShouldUpdate);
    this.setState({
      shouldUpdate: true
    });
  }

  componentWillUnmount() {
    document
      .querySelector("#contentWrapper")
      .removeEventListener("scroll", this.setShouldUpdate);
    window.removeEventListener("resize", this.setShouldUpdate);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.isModalOpen && !prevState.isModalOpen) {
      this.setState({
        isCancelling: false
      });
    }
  }

  setShouldUpdate() {
    if (this.state.shouldUpdate) return;

    const wrapper = document.querySelector(
      `div[class*="${style.fileDropWrapper}"]`
    );

    if (!wrapper) {
      return;
    }

    const wrapperParentBounds = wrapper.parentElement.getBoundingClientRect();
    if (
      wrapperParentBounds.top < 0 &&
      wrapperParentBounds.top >
        (wrapperParentBounds.height - window.innerHeight) * -1
    ) {
      // don't trigger update if full page height
      return;
    }

    this.setState({
      shouldUpdate: true
    });
  }

  handleFlagFileAsFinishedUploading(fileName, resolve) {
    const { uploadingImages } = this.state;

    if (
      uploadingImages[fileName] &&
      uploadingImages[fileName].isFinished === false
    ) {
      this.setState({
        uploadingImages: Object.assign({}, uploadingImages, {
          [fileName]: Object.assign({}, uploadingImages[fileName], {
            isFinished: true
          })
        })
      });
      setTimeout(
        () =>
          this.setState({
            uploadingImages: omit(this.state.uploadingImages, fileName)
          }),
        5000
      );
      resolve();
    }
  }

  handleFlagFileAsCancelledUploading(fileName, resolve) {
    const { uploadingImages } = this.state;

    if (
      uploadingImages[fileName] &&
      uploadingImages[fileName].isFinished === false
    ) {
      this.setState({
        uploadingImages: Object.assign({}, uploadingImages, {
          [fileName]: Object.assign({}, uploadingImages[fileName], {
            isFinished: true,
            isCancelled: true
          })
        })
      });
      resolve();
    }
  }

  handleUploadImages(files) {
    const uploadingImages = Array.from(files).reduce(
      (previous, current, index) => {
        return Object.assign({}, previous, {
          [current.name]: {
            size: calculateReadableSize(files[index].size),
            isFinished: false
          }
        });
      },
      {}
    );

    this.setState({
      file: files[0],
      uploadingImages,
      imageError: null,
      isModalOpen: true
    });

    /* define function for creating promise containers for all uploads */
    const createImageProcessPromise = file =>
      new Promise(resolve => {
        if (this.state.isCancelling) {
          return this.handleFlagFileAsCancelledUploading(file.name, resolve);
        }
        this.processImage(file).then(
          result => {
            this.setState({ fileSrc: result });
            this.onMediaUpload(file, () =>
              this.handleFlagFileAsFinishedUploading(file.name, resolve)
            );
          },
          ({ error }) => {
            this.setState({ imageError: error });
          }
        );
      });

    /* set up the promise containers for the upload */
    const uploadPromises = Object.values(files).map(file =>
      createImageProcessPromise(file)
    );

    /* run the promises in chain */
    return uploadPromises;
  }

  async onMediaUpload(file, onSuccess) {
    const {
      onUploadUserTeamImage,
      onUploadAnimation,
      onUploadImageToFolder,
      onUploadAnimationToFolder,
      folderId
    } = this.props;
    const fileExtension = getFileExtension(file.name);
    const isAnimation = fileExtension === "gif" || fileExtension === "mp4";
    const getUploadMediaAction = () => {
      if (folderId && isAnimation) {
        return onUploadAnimationToFolder;
      }

      if (isAnimation) {
        return onUploadAnimation;
      }

      if (folderId && !isAnimation) {
        return onUploadImageToFolder;
      }

      return onUploadUserTeamImage;
    };
    const uploadMedia = getUploadMediaAction();

    let uploadStart = Date.now();
    let previewCreated;
    let uploadCompleted;

    this.setState({
      uploadingFileName: file.name,
      uploadingError: false
    });

    const processSuccess = async image => {
      uploadCompleted = Date.now();
      const uploadTook = dateFns.differenceInSeconds(
        uploadCompleted,
        uploadStart
      );
      const previewTook = dateFns.differenceInSeconds(
        previewCreated,
        uploadStart
      );
      Logger.info(
        `Preview Element for image ${file.name} shown ${uploadTook -
          previewTook} seconds faster`
      );

      onSuccess(image);
    };

    // define what we should do when the presignedUrl is created
    const onPresignedUrlCreated = async id => {
      const fileURL = window.URL.createObjectURL(file);
      const getMediaType = () => {
        if (fileExtension === "gif") {
          return "ANIMATION";
        } else if (fileExtension === "mp4") {
          return "VIDEO";
        } else {
          return "IMAGE";
        }
      };

      // build a placeholder media entry
      const media = {
        asset: file.name.toLowerCase(),
        createdAt: Date.now(),
        height: null,
        id,
        name: file.name.toLowerCase(),
        preview: "preview_placeholder.png",
        size: 75780,
        status: "ACTIVE",
        thumbnail: "thumb_placeholder.png",
        type: getMediaType(),
        updatedAt: Date.now(),
        width: null,
        duration: null
      };

      // build placeholder media entity
      const mediaEntity = {
        createdAt: Date.now(),
        media,
        mediaId: id,
        previewUrl: fileURL,
        thumbnailUrl: fileURL,
        updatedAt: Date.now(),
        url: fileURL,
        origin: isAnimation ? "userTeamAnimation" : "userTeamImage"
      };

      // add placeholder media entity to the store
      isAnimation
        ? this.props.addUploadingAnimationsPlaceholders({
            mediaPlaceholders: [mediaEntity],
            folderId
          })
        : this.props.addUploadingImagesPlaceholders({
            mediaPlaceholders: [mediaEntity],
            folderId
          });

      previewCreated = Date.now();
    };

    if (fileExtension === "gif") {
      const gifData = await getGifUploadData(file);
      uploadMedia({
        imageFile: file,
        folderId,
        onSuccess: processSuccess,
        onPresignedUrlCreated,
        noRefetch: folderId ? false : true,
        duration: gifData.duration,
        sortOptions: this.props.sortOptions
      });
      return;
    }

    if (fileExtension === "mp4") {
      const getVideoDuration = () =>
        new Promise(resolve => {
          let video = document.createElement("video");
          video.preload = "metadata";
          video.onloadedmetadata = () => {
            window.URL.revokeObjectURL(video.src);
            // foor the duration to prevent fractional values sent in request
            resolve(Math.floor(video.duration * 1000));
          };
          video.src = window.URL.createObjectURL(file);
        });
      const duration = await getVideoDuration();

      if (duration > ANIMATION_VIDEO_MAX_DURATION) {
        this.setState({
          uploadingImages: Object.assign({}, this.state.uploadingImages, {
            [file.name]: Object.assign(
              {},
              this.state.uploadingImages[file.name],
              {
                isFinished: true,
                isError: true
              }
            )
          }),
          errors: {
            [file.name]: {
              value: "Videos must be less than 60 seconds in duration"
            }
          }
        });
        // return early to prevent animation upload
        return;
      }

      uploadMedia({
        imageFile: file,
        folderId,
        onSuccess: processSuccess,
        onPresignedUrlCreated,
        noRefetch: true,
        duration,
        placeholderURL: window.URL.createObjectURL(file),
        sortOptions: this.props.sortOptions
      });
      return;
    }

    uploadMedia({
      imageFile: file,
      folderId,
      onSuccess: processSuccess,
      onPresignedUrlCreated,
      noRefetch: folderId ? false : true,
      sortOptions: this.props.sortOptions
    });
  }

  processImage(file) {
    const fileExtension = file.name
      .split(".")
      .pop()
      .toLowerCase();

    if (!acceptedFileTypes.includes(fileExtension))
      return Promise.reject({ error: "Images must be in PNG or JPG format." });

    const promise = new Promise((resolve, reject) => {
      const errorCallback = msg => () => {
        Logger.warn(msg);
        return reject({ error: "We were not able to process your image." });
      };

      const validateFileAsImage = fileSrc => {
        const image = new Image();
        image.onerror = errorCallback("Image onload error");
        image.onload = () => {
          Logger.info({ app: "Image processed successfully" });
          /* validate image size here*/
          resolve(fileSrc);
        };
        image.src = fileSrc;
      };

      const validateFileAsVideo = fileSrc => {
        let video = document.createElement("video");
        video.onerror = errorCallback("Video onload error");
        video.onloadeddata = () => {
          Logger.info({ app: "Video processed successfully" });
          /* validate video size here*/
          resolve(fileSrc);
        };
        video.src = fileSrc;
      };

      const reader = new FileReader();
      reader.onerror = errorCallback("File Reader onload error");

      fileExtension === "mp4"
        ? (reader.onload = ({ target }) => validateFileAsVideo(target.result))
        : (reader.onload = ({ target }) => validateFileAsImage(target.result));
      reader.readAsDataURL(file);
    });

    return promise;
  }

  getOverlayTextStyle() {
    const wrapper = document.querySelector(
      `div[class*="${style.fileDropWrapper}"]`
    );

    if (!wrapper) {
      return {
        position: "sticky",
        top: "250px",
        marginTop: "250px"
      };
    }

    const visibleHeight =
      window.innerHeight -
      Math.max(wrapper.parentElement.getBoundingClientRect().top, 0);

    const stickyOffset = visibleHeight / 2;

    if (this.state.shouldUpdate) {
      this.setState({
        shouldUpdate: false
      });
    }

    // keep the text in center of visible overlay
    return {
      position: "sticky",
      top: `${stickyOffset}px`,
      marginBottom: `${stickyOffset}px`,
      marginTop: `${stickyOffset}px`
    };
  }

  getLeftOffset() {
    const dropWrapper = document.querySelector(
      `div[class*="${style.fileDropWrapper}"]`
    );

    if (!dropWrapper) {
      return;
    }
    return dropWrapper.getBoundingClientRect().left;
  }

  render() {
    const isAnimationsSection =
      this.props.isUserTeamAnimations || this.props.isBrandAnimations;
    const overlayTextStyle = this.getOverlayTextStyle();
    const leftOffset = this.getLeftOffset();
    const label = `Drop your ${isAnimationsSection ? "videos" : "images"} here`;
    const acceptedFileTypesForSection = isAnimationsSection
      ? DEFAULT_ACCEPTED_ANIMATED_FILE_TYPES
      : DEFAULT_ACCEPTED_STATIC_FILE_TYPES;

    // exit safely and prevent drag and drop upload
    // when user is not a brand manager
    if (this.props.isBrandAnimations && !this.props.canAccessBrandKit)
      return null;

    return (
      <FileDragAndDrop
        handleDrop={this.handleUploadImages}
        label={label}
        invalidFileToast={this.props.invalidFileToast}
        overlayTextStyle={overlayTextStyle}
        className={style.fileDropWrapper}
        isUsingDirectParent={true}
        leftOffset={leftOffset}
        acceptedFileTypes={acceptedFileTypesForSection}
        isBasicSubscription={this.props.isBasicSubscription}
      />
    );
  }
}

export default ImagesListDragAndDropUpload;
