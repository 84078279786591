import { debounce } from "lodash";
import { Logger, getPath } from "lib";
import PATHS from "routes/paths";

const userTracker = {};

const safelyCall = function(f) {
  return function() {
    try {
      f.apply(this, arguments);
    } catch (error) {
      /* in case 3rd party throws something, we log and keep going */
      Logger.error(error);
      Logger.warn(
        "3rd Party `Segment` operation Failed, Logging and Continuing"
      );
    }
  };
};

/* eslint-disable */
// prettier-ignore
function initialize () {
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
    analytics.load(process.env.REACT_APP_SEGMENT_APP_KEY);
    analytics.page();
  }}();
}
/* eslint-enable */
userTracker.initialize = safelyCall(initialize);

function identify({
  id,
  email,
  name,
  country,
  roles,
  createdAt: created_at,
  teamPlan: subscription_plan,
  teamName: team_name,
  teamId: team_id
}) {
  window.analytics.identify(id, {
    email,
    roles,
    name,
    country,
    created_at,
    subscription_plan,
    team_id,
    team_name
  });
}
userTracker.identify = safelyCall(identify);

function page({ path, referrer, search, title, url }) {
  if (!window.analytics) {
    return;
  }

  // check over paths to return a readable page name to pass on to analytics
  const getPageName = path => {
    if (PATHS.isEditorPath(path)) {
      return "Editor";
    }
    if (PATHS.isOrderPath(path)) {
      return "Orders";
    }
    if (PATHS.isPurchaseCollection(path)) {
      return "Purchase Collection";
    }
    if (PATHS.isInvitationPath(path)) {
      return "Invitation";
    }
    return path;
  };
  const pageName = getPageName(path);

  window.analytics.page(pageName, { path, referrer, search, title, url });
}
/* the debounce here avoid tracking redirects */
userTracker.page = debounce(safelyCall(page), 300);

function track({ event, properties, options, callback }) {
  if (!window.analytics) {
    return;
  }

  // ensure analytics isn't getting blocked
  if (!getPath(window, "analytics.user")) {
    Logger.warn(
      "3rd Party `analytics.user` could not be loaded, aborted tracking call"
    );
    return;
  }

  const propertiesWithTeamId = {
    ...properties,
    team_id: window.analytics.user().traits().team_id
  };

  window.analytics.track(event, propertiesWithTeamId, options, callback);
}
userTracker.track = safelyCall(track);

userTracker.start = (user, overwrite = false) => {
  userTracker.initialize();
  userTracker.identify(user);
};

userTracker.events = {
  addGroup: "add_group",
  addPage: "add_page",
  categoryView: "category_view",
  collectionProductAdded: "collection_product_added",
  copyDesign: "copy_design",
  designCreated: "design_created",
  designMerge: "design_merge",
  designRender: "design_render",
  editorCrash: "editor_crash",
  mediaFavourited: "media_favourited",
  mediaUpload: "media_upload",
  purchaseCollectionView: "purchase_collection_view",
  resizeDesign: "resize_design",
  teamMediaUpload: "team_media_upload",
  textMask: "text_mask",
  userMediaUpload: "user_media_upload"
};

export default userTracker;
