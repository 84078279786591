import Element from "state/ui/editor/elements/Element";
import { getSourceTypeFromAsset } from "lib/elementTypeUtils";

class BackgroundElement extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "sizeAndPosition",
    "filters",
    "flipping",
    "cropping",
    "setAsBackground"
  ];

  getImageColors() {
    /* We use the id instead of UUID because we want to reference to the asset not the element */
    return {
      [this.id]: {
        previewUrl: this.previewSrc || this.src,
        colors: this.palette
      }
    };
  }

  canBeSetAsBackground() {
    return (
      !this.restrictions.includes("setAsBackground") &&
      !this.restrictions.includes("sizeAndPosition")
    );
  }

  convertToAssetTypeAndCenter({ pageWidth, pageHeight }) {
    const newTop = (pageHeight - this.srcHeight) / 2;
    const newLeft = (pageWidth - this.srcWidth) / 2;

    const assetType = getSourceTypeFromAsset({ url: this.src });

    return new this.constructor({
      ...this,
      type: assetType,
      height: this.srcHeight,
      width: this.srcWidth,
      left: Math.max(newLeft, 0),
      top: Math.max(newTop, 0),
      scale: 1
    });
  }
}

export default BackgroundElement;
