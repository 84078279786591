import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import "lib/polyfills.js";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import history from "./state/history";
import store from "./state/store";
import Routes from "./routes";
import { default as UpgradeModal } from "views/containers/UpgradeModalContainer";
import { default as ConfirmationModal } from "views/containers/ConfirmationModalContainer";
import { default as CollectionPreviewModal } from "views/containers/CollectionPreviewModalContainer";
import { default as RequestApprovalModal } from "views/containers/RequestApprovalModalContainer";
import { default as ApprovalModal } from "views/components/ApprovalModal/ApprovalModalContainer";
import { default as PurchaseCollectionModal } from "views/components/PurchaseCollection/PurchaseCollectionModalContainerGlobal.js";
import { sessionRecorder, toaster } from "lib";
import Copymaker from "lib/Copymaker";
import InputMode from "lib/InputMode";
import Render from "lib/Render";
import SSO from "lib/SSO";

import { default as ConfirmationModalLib } from "lib/ConfirmationModal/ConfirmationModal";

if (!Render.isRenderMode()) {
  SSO.init();

  ConfirmationModalLib.config({ store });

  sessionRecorder.init();
  toaster.initialize();

  Copymaker.startModeIfNeeded();
  InputMode.startModeIfNeeded();
}

const Modals = () => {
  return (
    <React.Fragment>
      <UpgradeModal />
      <ConfirmationModal />
      <CollectionPreviewModal />
      <RequestApprovalModal />
      <ApprovalModal />
      <PurchaseCollectionModal />
    </React.Fragment>
  );
};

const Root = () => {
  return (
    <span className="notranslate">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <React.Fragment>
            <Routes />
            <Modals />
          </React.Fragment>
        </ConnectedRouter>
      </Provider>
    </span>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

registerServiceWorker();

// Add event listeners on location change. This ensures that SPA navigation triggers a service
// worker update check but like a browser navigation event would.
history.listen((location, action) => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready.then(registration => {
      console.info("Checking server for updated service worker");
      registration.update();
    });

    // This event listener listens for the service worker to be swapped to the new version.
    // Once the user gives approval via the toast, the new worker is installed triggering this
    // event.
    navigator.serviceWorker.oncontrollerchange = () => {
      console.info(
        "Service worker controller has changed. Reloading application"
      );
      window.location.reload();
    };
  }

  // update inline manual when the page is changed
  if (window.inline_manual_player) {
    window.inline_manual_player.update();
  }
});
