import * as types from "state/entities/stockAnimations/stockAnimationsTypes";
import { findPage } from "../helpers";
import { omit, isEmpty, chunk, getPath } from "lib";
import { removeItem } from "lib/array/array";
import { ANIMATION_ASSET_TYPE_MAP } from "lib/constants";

export const initState = {
  animations: {
    pages: {
      /* isFetching: true|false
       * ids:[]
       * didInvalidate: true|false */
    },
    detailedIds: {},
    totalCount: null
  },
  videos: {
    pages: {
      /* isFetching: true|false
       * ids:[]
       * didInvalidate: true|false */
    },
    detailedIds: {},
    totalCount: null
  },
  all: {
    pages: {
      /* isFetching: true|false
       * ids:[]
       * didInvalidate: true|false */
    },
    detailedIds: {},
    totalCount: null
  },
  search: {
    /*
    [term]: {
      pages: {
        isFetching: true|false
        ids:[]
        didInvalidate: true|false
      },
      detailedIds: {},
      totalCount: null
    }
    */
  },
  pageSize: 50
};

export const handleAllStockAnimationRequest = ({ action, state }) => {
  const { page } = action.request;

  return {
    ...state,
    all: {
      ...state.all,
      pages: {
        ...state.all.pages,
        [page]: {
          ...state.all.pages[page],
          isFetching: true
        }
      }
    }
  };
};

export const handleStockAnimationRequest = ({ action, state }) => {
  const { page } = action.request;

  return {
    ...state,
    animations: {
      ...state.animations,
      pages: {
        ...state.animations.pages,
        [page]: {
          ...state.animations.pages[page],
          isFetching: true
        }
      }
    }
  };
};

export const handleStockVideoRequest = ({ action, state }) => {
  const { page } = action.request;

  return {
    ...state,
    videos: {
      ...state.videos,
      pages: {
        ...state.videos.pages,
        [page]: {
          ...state.videos.pages[page],
          isFetching: true
        }
      }
    }
  };
};

export const handleAllStockAnimationRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      all: {
        pages: omit(state.all.pages, action.request.page)
      }
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    all: {
      pages: { ...state.all.pages, ...updatedPage }
    }
  };
};

export const handleStockAnimationRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      animations: {
        pages: omit(state.animations.pages, action.request.page)
      }
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    animations: {
      pages: { ...state.animations.pages, ...updatedPage }
    }
  };
};

export const handleStockVideoRequestSuccess = ({ state, action }) => {
  const {
    response: { ids = [] } = {},
    request: { page }
  } = action;

  if (isEmpty(ids) && Number(page) !== 1) {
    return {
      ...state,
      videos: {
        pages: omit(state.videos.pages, action.request.page)
      }
    };
  }

  const updatedPage = {
    [page]: {
      isFetching: false,
      ids: ids,
      didInvalidate: false
    }
  };

  return {
    ...state,
    videos: {
      pages: { ...state.videos.pages, ...updatedPage }
    }
  };
};

const stockAnimationsApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.ALL_STOCK_ANIMATIONS_REQUEST: {
      return handleAllStockAnimationRequest({ state, action });
    }

    case types.STOCK_ANIMATIONS_REQUEST: {
      return handleStockAnimationRequest({ state, action });
    }

    case types.STOCK_VIDEOS_REQUEST: {
      return handleStockVideoRequest({ state, action });
    }

    case types.ALL_STOCK_ANIMATIONS_REQUEST_SUCCESS: {
      return handleAllStockAnimationRequestSuccess({ state, action });
    }

    case types.STOCK_ANIMATIONS_REQUEST_SUCCESS: {
      return handleStockAnimationRequestSuccess({ state, action });
    }

    case types.STOCK_VIDEOS_REQUEST_SUCCESS: {
      return handleStockVideoRequestSuccess({ state, action });
    }

    case types.ADD_STOCK_ANIMATIONS_PLACEHOLDERS: {
      const { mediaPlaceholders, folderId } = action;

      const animationPlaceholders = mediaPlaceholders.filter(
        placeholder => placeholder.type === ANIMATION_ASSET_TYPE_MAP.ANIMATION
      );
      const videoPlaceholders = mediaPlaceholders.filter(
        placeholder => placeholder.type === ANIMATION_ASSET_TYPE_MAP.VIDEO
      );

      const mediaIds = mediaPlaceholders.map(media => media.mediaId);
      const animationMediaIds = animationPlaceholders.map(
        media => media.mediaId
      );
      const videoMediaIds = videoPlaceholders.map(media => media.mediaId);

      if (folderId) {
        const allFolderAnimationIds = Object.values(
          getPath(state, `folders.${folderId}.pages`)
        ).reduce(
          (previousValues, currentPage) =>
            previousValues.concat(currentPage.ids),
          mediaIds
        );

        // split ids into chunks of page size
        const folderAnimationIdsSplitIntoPages = chunk(
          allFolderAnimationIds,
          state.pageSize
        );

        const newPages = folderAnimationIdsSplitIntoPages.reduce(
          (previousPages, ids, index) => ({
            ...previousPages,
            [index + 1]: {
              isFetching: false,
              ids,
              didInvalidate: true
            }
          }),
          {}
        );

        return {
          ...state,
          folders: {
            ...state.folders,
            [folderId]: {
              ...state.folders[folderId],
              pages: newPages
            }
          }
        };
      }

      let animationPages = state.animations.pages;
      let videoPages = state.videos.pages;

      if (animationPlaceholders.length) {
        const allAnimationIds = Object.values(state.animations.pages).reduce(
          (previousValues, currentPage) =>
            previousValues.concat(currentPage.ids),
          animationMediaIds
        );

        // split ids into chunks of page size
        const idsSplitIntoPages = chunk(
          allAnimationIds,
          state.animations.pageSize
        );

        animationPages = idsSplitIntoPages.reduce(
          (previousPages, ids, index) => ({
            ...previousPages,
            [index + 1]: {
              isFetching: false,
              ids,
              didInvalidate: true
            }
          }),
          {}
        );
      }

      if (videoPlaceholders.length) {
        const allAnimationIds = Object.values(state.videos.pages).reduce(
          (previousValues, currentPage) =>
            previousValues.concat(currentPage.ids),
          videoMediaIds
        );

        // split ids into chunks of page size
        const idsSplitIntoPages = chunk(allAnimationIds, state.videos.pageSize);

        videoPages = idsSplitIntoPages.reduce(
          (previousPages, ids, index) => ({
            ...previousPages,
            [index + 1]: {
              isFetching: false,
              ids,
              didInvalidate: true
            }
          }),
          {}
        );
      }

      return {
        ...state,
        animations: {
          ...state.animations,
          pages: animationPages
        },
        videos: {
          ...state.videos,
          pages: videoPages
        }
      };
    }

    case types.STOCK_ANIMATION_DELETE_REQUEST: {
      const { mediaId } = action.request;

      const animationsPage = findPage(
        state.animations.pages,
        action.request.mediaId
      );
      const videosPage = findPage(state.videos.pages, action.request.mediaId);

      if (!animationsPage && !videosPage) return state;

      if (animationsPage) {
        const animationIndex = state.animations.pages[
          animationsPage
        ].ids.indexOf(mediaId);

        const pageUpdated = {
          ...state.animations.pages[animationsPage],
          ids: removeItem(
            state.animations.pages[animationsPage].ids,
            animationIndex
          )
        };
        return {
          ...state,
          animations: {
            ...state.animations,
            pages: {
              ...state.animations.pages,
              [animationsPage]: pageUpdated
            }
          }
        };
      } else if (videosPage) {
        const animationIndex = state.videos.pages[videosPage].ids.indexOf(
          mediaId
        );

        const pageUpdated = {
          ...state.videos.pages[videosPage],
          ids: removeItem(state.videos.pages[videosPage].ids, animationIndex)
        };
        return {
          ...state,
          videos: {
            ...state.videos,
            pages: {
              ...state.videos.pages,
              [videosPage]: pageUpdated
            }
          }
        };
      }
      break;
    }
    default:
      return state;
  }
};

export default stockAnimationsApiReducers;
