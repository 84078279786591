import * as types from "./catalogueSizesTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";
import { getParameterByName } from "lib/queryStringUtils";
import { initState } from "state/api/catalogueSizes/catalogueSizesApiReducers";
import { currentTeamIdSelector } from "state/entities/teams/teamsSelectors";
import { asyncAction } from "lib/asyncHelpers";
import { noop } from "lib";
import PATHS from "routes/paths";

export const fetchAllSizesForCurrentTeam = ({
  page = 1,
  isFilter,
  resolve,
  reject
}) => (dispatch, getState) => {
  const state = getState();
  const teamId = currentTeamIdSelector(state);

  return dispatch(fetchAllSizes({ teamId, page, isFilter, resolve, reject }));
};

export const asyncFetchAllSizesForCurrentTeam = asyncAction(
  fetchAllSizesForCurrentTeam
);

export const fetchTeamSizes = ({
  teamId,
  page = 1,
  isFilter,
  resolve = noop,
  reject = noop,
  isArchived
}) => dispatch => {
  const params = {
    archived: false
  };

  if (isArchived) {
    params.archived = true;
  }

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET_V2,
      types: isFilter
        ? [
            types.TEAM_SIZES_FILTER_REQUEST,
            types.TEAM_SIZES_FILTER_REQUEST_SUCCESS,
            types.TEAM_SIZES_FILTER_REQUEST_FAILURE
          ]
        : [
            types.TEAM_SIZES_REQUEST,
            types.TEAM_SIZES_REQUEST_SUCCESS,
            types.TEAM_SIZES_REQUEST_FAILURE
          ],
      endpoint: `/teams/${teamId}/catalogue/sizes`,
      request: {
        page: page,
        pageSize: initState.pageSize,
        params
      },
      schema: schemas.CATALOGUE_SIZES,
      onSuccess: response => {
        if (
          response &&
          response.ids &&
          response.ids.length === initState.pageSize
        ) {
          dispatch(fetchTeamSizes({ teamId, page: page + 1 }));
        } else {
          resolve(response);
        }
      },
      onFailure: response => reject(response)
    }
  });
};

export const fetchTeamSizesForFolder = ({
  teamId,
  page = 1,
  resolve = noop,
  reject = noop,
  folderId
}) => dispatch => {
  if (!folderId) return;
  const params = {
    archived: false,
    folderId
  };

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET_V2,
      types: [
        types.TEAM_SIZES_FOLDER_REQUEST,
        types.TEAM_SIZES_FOLDER_REQUEST_SUCCESS,
        types.TEAM_SIZES_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/catalogue/sizes`,
      request: {
        page: page,
        pageSize: initState.pageSize,
        params
      },
      schema: schemas.CATALOGUE_SIZES,
      onSuccess: response => {
        if (
          response &&
          response.ids &&
          response.ids.length === initState.pageSize
        ) {
          dispatch(fetchTeamSizesForFolder({ teamId, page: page + 1 }));
        } else {
          resolve(response);
        }
      },
      onFailure: response => reject(response)
    }
  });
};

export const fetchAllSizes = ({
  teamId,
  page = 1,
  isFilter,
  resolve = noop,
  reject = noop
}) => (dispatch, getState) => {
  const params = {};
  const state = getState();
  const categoryId = getParameterByName(
    "categoryId",
    state.router.location.search
  );

  if (categoryId) {
    params.categoryId = categoryId;
  }

  if (
    state.router.location.pathname === PATHS.catalogueTemplates ||
    state.router.location.pathname === PATHS.catalogueTemplatesSearch ||
    state.router.location.pathname === PATHS.catalogueArchived
  ) {
    params.scope = "team";
  } else {
    params.scope = "subscription";
  }

  if (state.router.location.pathname === PATHS.catalogueArchived) {
    params.archived = true;
  }

  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: isFilter
        ? [
            types.CATALOGUE_SIZES_FILTER_REQUEST,
            types.CATALOGUE_SIZES_FILTER_REQUEST_SUCCESS,
            types.CATALOGUE_SIZES_FILTER_REQUEST_FAILURE
          ]
        : [
            types.CATALOGUE_SIZES_REQUEST,
            types.CATALOGUE_SIZES_REQUEST_SUCCESS,
            types.CATALOGUE_SIZES_REQUEST_FAILURE
          ],
      endpoint: `/teams/${teamId}/catalogue/subscription/sizes`,
      request: {
        page: page,
        pageSize: initState.pageSize,
        params
      },
      schema: schemas.CATALOGUE_SIZES,
      onSuccess: response => {
        if (
          response &&
          response.ids &&
          response.ids.length === initState.pageSize
        ) {
          dispatch(fetchAllSizes({ teamId, page: page + 1 }));
        } else {
          resolve(response);
        }
      },
      onFailure: response => reject(response)
    }
  });
};

export const fetchAllCatalogueSizesForFolder = ({ folderId, page = 1 }) => (
  dispatch,
  getState
) => {
  const teamId = currentTeamIdSelector(getState());
  dispatch({
    [CALL_API]: {
      method: "GET",
      service: SERVICES.ASSET,
      types: [
        types.CATALOGUE_SIZES_FOLDER_REQUEST,
        types.CATALOGUE_SIZES_FOLDER_REQUEST_SUCCESS,
        types.CATALOGUE_SIZES_FOLDER_REQUEST_FAILURE
      ],
      endpoint: `/teams/${teamId}/folders/${folderId}/sizes`,
      request: {
        page: page,
        pageSize: initState.pageSize
      },
      extra: {
        folderId
      },
      schema: schemas.CATALOGUE_SIZES,
      onSuccess: response => {
        if (
          response &&
          response.ids &&
          response.ids.length === initState.pageSize
        ) {
          dispatch(fetchAllSizes({ folderId, page: page + 1 }));
        }
      }
    }
  });
};
