import * as types from "../../entities/designFolders/designFoldersTypes";

import { formatErrors } from "lib";

export const initState = {
  isAdding: false,
  isRemoving: false,
  isSuccess: false,
  errors: []
};

const errorsArray = error => formatErrors(error);

const addContentToFolderReducers = (state = initState, action) => {
  switch (action.type) {
    case types.MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST:
    case types.ADD_CONTENT_TO_TEAM_FOLDER_REQUEST:
    case types.ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST: {
      return {
        ...initState,
        isAdding: true
      };
    }

    case types.MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST_SUCCESS:
    case types.ADD_CONTENT_TO_TEAM_FOLDER_SUCCESS:
    case types.ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST_SUCCESS: {
      return {
        ...initState,
        isSuccess: true
      };
    }

    case types.REMOVE_CONTENT_FROM_TEAM_FOLDER_REQUEST:
    case types.REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST: {
      return {
        ...initState,
        isRemoving: true
      };
    }

    case types.REMOVE_CONTENT_FROM_TEAM_FOLDER_SUCCESS:
    case types.REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST_SUCCESS: {
      return {
        ...initState,
        isSuccess: true
      };
    }

    case types.MOVE_BULK_COLLECTIONS_TO_PERSONAL_FOLDER_REQUEST_FAILURE:
    case types.REMOVE_CONTENT_FROM_TEAM_FOLDER_FAILURE:
    case types.REMOVE_CONTENT_FROM_PERSONAL_FOLDER_REQUEST_FAILURE:
    case types.ADD_CONTENT_TO_TEAM_FOLDER_FAILURE:
    case types.ADD_CONTENT_TO_PERSONAL_FOLDER_REQUEST_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...initState,
        errors
      };
    }

    default:
      return state;
  }
};

export default addContentToFolderReducers;
