import { removeItem } from "lib/array/array";
import { findPage } from "state/api/helpers";

export const handleUnapproveSearch = ({ state, action, filters }) => {
  const termKey = `${filters.term}-${filters.size || "all"}`;
  const termKeyState = state.search.results[termKey];

  const page = findPage(termKeyState.pages, action.request.body.designId);

  if (!page) return state;

  const designIndex = termKeyState.pages[page].ids.indexOf(
    action.request.body.designId
  );

  const pageUpdated = {
    ...termKeyState.pages[page],
    ids: removeItem(termKeyState.pages[page].ids, designIndex)
  };

  return {
    ...state,
    search: {
      ...state.search,
      results: {
        [termKey]: {
          ...termKeyState,
          pages: {
            ...termKeyState.pages,
            [page]: pageUpdated
          }
        }
      }
    }
  };
};

export const handleUnapprove = ({ state, action, filters }) => {
  const size = filters.size || "all";

  const sizeState = state.sizes[size];

  const page = findPage(sizeState.pages, action.request.body.designId);

  if (!page) return state;

  const designIndex = sizeState.pages[page].ids.indexOf(
    action.request.body.designId
  );

  const pageUpdated = {
    ...sizeState.pages[page],
    ids: removeItem(sizeState.pages[page].ids, designIndex)
  };

  return {
    ...state,
    sizes: {
      ...state.sizes,
      [size]: {
        ...sizeState,
        pages: {
          ...sizeState.pages,
          [page]: pageUpdated
        }
      }
    }
  };
};
