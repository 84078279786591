import * as types from "../../entities/teams/teamsTypes";
import * as billingTypes from "../../entities/teamBilling/teamBillingTypes";
import * as teamSettingsUITypes from "./teamSettingsTypes";

export const initState = {
  team: {
    isProcessing: false,
    isSuccess: false,
    errors: {}
  },
  billing: {
    isProcessing: false,
    isSuccess: false,
    errors: {}
  }
};

const teamSettingsReducer = (state = initState, action) => {
  switch (action.type) {
    case billingTypes.TEAM_BILLING_UPDATE_REQUEST: {
      const errors = {};
      const billing = Object.assign({}, state.billing, {
        errors,
        isProcessing: true,
        isSuccess: false
      });

      return {
        ...state,
        billing
      };
    }
    case types.TEAMS_UPDATE_REQUEST: {
      const errors = {};
      const team = Object.assign({}, state.team, {
        errors,
        isProcessing: true,
        isSuccess: false
      });
      return {
        ...state,
        team
      };
    }

    case types.TEAMS_UPDATE_REQUEST_SUCCESS: {
      const team = Object.assign({}, state.team, {
        isProcessing: false,
        isSuccess: true
      });

      return {
        ...state,
        team
      };
    }

    case billingTypes.TEAM_BILLING_UPDATE_REQUEST_SUCCESS: {
      const billing = Object.assign({}, state.billing, {
        isProcessing: false,
        isSuccess: true
      });

      return {
        ...state,
        billing
      };
    }

    case types.TEAMS_UPDATE_REQUEST_FAILURE: {
      const { error } = action;
      let errors = {};
      error.errors.forEach(item => {
        errors[item.path] = item.message;
      });

      const team = Object.assign({}, state.team, {
        errors,
        isProcessing: false,
        isSuccess: false
      });

      return {
        ...state,
        team
      };
    }

    case billingTypes.TEAM_BILLING_UPDATE_REQUEST_FAILURE: {
      const { error } = action;
      let errors = {};
      error.errors.forEach(item => {
        errors[item.path] = item.message;
      });

      const billing = Object.assign({}, state.billing, {
        errors,
        isProcessing: false,
        isSuccess: false
      });

      return {
        ...state,
        billing
      };
    }

    case teamSettingsUITypes.UI_CLEAR_TEAM_SETTINGS_BILLING_ERRORS: {
      const billing = Object.assign({}, state.billing, {
        ...state.billing,
        errors: {}
      });

      return {
        ...state,
        billing
      };
    }

    default:
      return state;
  }
};

export default teamSettingsReducer;
