import React from "react";
import PhotoFrameCropping from "./PhotoFrameCropping";
import { connect } from "react-redux";
import {
  isCroppingSelector,
  contextSelector,
  selectedItemsSelector,
  zoomSelector,
  designDataSelector,
  elementPreviewsSelector
} from "state/ui/editorContext/editorContextSelectors";
import { getPageOffsetInCanvasbackground } from "views/components/Editor/utils";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { Logger } from "lib";
import EditorPhotoFrameOps from "views/components/Editor/editorOps/EditorPhotoFrameOps";

class PhotoFrameCroppingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.startPhotoFrameCropMoving = this.startPhotoFrameCropMoving.bind(this);
    this.finishPhotoFrameCropMoving = this.finishPhotoFrameCropMoving.bind(
      this
    );
  }

  startPhotoFrameCropMoving() {
    Logger.info(
      "PhotoFrameCroppingContainer.startPhotoInFrameCropMoving called"
    );

    this.props.updateContext({
      isCropping: true
    });
  }

  finishPhotoFrameCropMoving({ domId, differenceFromInitialOffset }) {
    Logger.info(
      "PhotoFrameCroppingContainer.finishPhotoInFrameCropMoving called"
    );
    const { zoom, selectedItems, designData, updateContext } = this.props;

    const updatedContext = EditorPhotoFrameOps.finishPhotoFrameCropMoving({
      zoom,
      selectedItems,
      designData,
      domId,
      differenceFromInitialOffset
    });

    updateContext(updatedContext);
  }

  render() {
    const {
      canvasBackground,
      context: {
        isCroppingPhotoFrame,
        selectedPhotoInFrameId,
        vectorHtmlElement,
        photoFrameOffsetRect,
        placeholderDimensions
      },
      designData,
      selectedItems,
      zoom,
      isCropping
    } = this.props;

    if (!canvasBackground || !isCroppingPhotoFrame) {
      return null;
    }

    const {
      vectorElement,
      photoFrame
    } = EditorPhotoFrameOps.getVectorElementWithPreview({
      selectedItems,
      designData,
      selectedPhotoInFrameId
    });
    const pageId = selectedItems[0].pageId;
    const pageOffset = getPageOffsetInCanvasbackground(pageId);

    return (
      <PhotoFrameCropping
        {...this.props}
        vectorHtmlElement={vectorHtmlElement}
        photoFrameOffsetRect={photoFrameOffsetRect}
        placeholderDimensions={placeholderDimensions}
        startPhotoFrameCropMoving={this.startPhotoFrameCropMoving}
        finishPhotoFrameCropMoving={this.finishPhotoFrameCropMoving}
        photoFrame={photoFrame}
        vectorElement={vectorElement}
        pageOffset={pageOffset}
        pageId={pageId}
        zoom={zoom}
        canvasBackground={canvasBackground}
        isCropping={isCropping}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isCropping: isCroppingSelector(state),
    context: contextSelector(state),
    selectedItems: selectedItemsSelector(state),
    zoom: zoomSelector(state),
    elementPreviews: elementPreviewsSelector(state),
    designData: designDataSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContext: args => dispatch(updateEditorContextState(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoFrameCroppingContainer);
