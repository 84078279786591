import React, { lazy, Suspense } from "react";
import { WithHeader } from "../../enhancers";
import SubPageLoading from "views/pages/SubPageLoading";
const CartCompletedPage = lazy(() => import("./CartCompletedPage"));

export const CartCompletedPageLazy = props => (
  <Suspense fallback={<SubPageLoading />}>
    <CartCompletedPage {...props} />
  </Suspense>
);
CartCompletedPageLazy.displayName = "CartCompletedPage";

export default WithHeader(CartCompletedPageLazy);
