import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TextSpaceIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 18 8"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-6h20v20H-1z" />
      <path
        fill={color || easilGrayXDark}
        d="M4.328 3h9.415l-.415-.414a1 1 0 1 1 1.415-1.414l2.121 2.12a1 1 0 0 1 0 1.415l-2.121 2.121a1 1 0 0 1-1.415-1.414L13.743 5H4.328l.415.414a1 1 0 1 1-1.415 1.414l-2.12-2.12a1 1 0 0 1 0-1.415l2.12-2.121a1 1 0 1 1 1.415 1.414L4.328 3z"
      />
    </g>
  </svg>
);
TextSpaceIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TextSpaceIcon;
