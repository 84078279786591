import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const DropzonesIcon = ({
  noPointer,
  width,
  size,
  height,
  style,
  color,
  title,
  ...props
}) => (
  <svg
    {...props}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    fill={color || easilGrayXDark}
    stroke="transparent"
  >
    <title>{title}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={color}
        fillRule="nonzero"
        d="M15.456 17.968c.342-.525.753-.796 1.274-.614a1 1 0 0 1 .614 1.274c-.249.713-.427.882-.842 1.377-.348.416-.927.584-1.387.277-.46-.306-.554-.985-.277-1.387.277-.401.277-.401.618-.927zm-3.216 3.26A1 1 0 0 1 13.165 23a8.955 8.955 0 0 1-2.112.782 1 1 0 1 1-.453-1.948 6.955 6.955 0 0 0 1.64-.607zm-5.223.5a1 1 0 1 1-.567 1.918 8.945 8.945 0 0 1-2.062-.905 1 1 0 1 1 1.028-1.716c.501.3 1.038.537 1.601.704zM2.943 18.51a1 1 0 1 1-1.732 1 8.967 8.967 0 0 1-.872-2.075 1 1 0 0 1 1.926-.537c.158.564.386 1.106.678 1.612zm-.754-5.072a1 1 0 1 1-1.95-.448 8.94 8.94 0 0 1 .777-2.114 1 1 0 1 1 1.776.92 6.94 6.94 0 0 0-.603 1.642zm2.953-4.257a1 1 0 0 1-1.386-.28c-.306-.46-.133-1.027.28-1.386.403-.35.53-.568 1.242-.816a1 1 0 0 1 1.273.615c.182.522-.149.91-.615 1.274-.467.363-.306.27-.794.593z"
      />
      <g fill={color} transform="translate(8)">
        <path
          fillRule="nonzero"
          stroke="none"
          d="M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm0-2h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
        />
        <circle stroke="none" cx="5.5" cy="5.5" r="1.5" />
        <path
          fillRule="nonzero"
          stroke="none"
          d="M13.017 10.108a3 3 0 0 0-3.916.54L6.19 14H13a1 1 0 0 0 1-1v-2.245l-.983-.647zm1.1-1.67l1.433.943a1 1 0 0 1 .45.835V13a3 3 0 0 1-3 3H4a1 1 0 0 1-.755-1.655L7.59 9.336a5 5 0 0 1 6.527-.899z"
        />
      </g>
    </g>
  </svg>
);
DropzonesIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default DropzonesIcon;
