import React from "react";
import style from "./style.module.css";
import { Tooltip } from "views/components";
import InfoIcon from "views/components/icons/InfoIcon";

export const CreditSection = ({
  currentCredits,
  removeBackgroundCreditsPerMonth,
  nextCreditsTipText,
  renewDate,
  userSubscriptionPlan
}) => (
  <div className={style.creditBreakdownBoxWrapper}>
    <div className={style.detailSection}>
      <div className={style.creditBreakdownDetail}>
        <div className={style.creditBreakdownDetailTitle}>
          Credits required{" "}
        </div>
        <div className={style.creditBreakdownDetailValue}>1</div>
      </div>
    </div>
    <div className={style.verticalDivider} />
    <div className={style.detailSection}>
      <div className={style.creditBreakdownDetail}>
        <div className={style.creditBreakdownDetailTitle}>
          Your available credits{" "}
        </div>
        <div className={style.creditBreakdownDetailValue}>{currentCredits}</div>
      </div>
    </div>
    {userSubscriptionPlan.isRemoveBackgroundAvailable && (
      <>
        <div className={style.verticalDivider} />
        <div className={style.detailSection}>
          <div className={style.creditBreakdownDetail}>
            <div className={style.creditBreakdownDetailTitle}>
              Next {removeBackgroundCreditsPerMonth} credits{" "}
              <div data-tip={nextCreditsTipText} data-for="nextCreditsTip">
                <InfoIcon width="12px" height="12px" color="#d1d4d6" />
              </div>
            </div>
            <div className={style.creditBreakdownDetailValue}>{renewDate}</div>
            <Tooltip id="nextCreditsTip" place="top" />
          </div>
        </div>
      </>
    )}
  </div>
);

export default CreditSection;
