import * as types from "state/entities/teams/teamsTypes";
import { SWITCH_TEAM } from "state/ui/uiTypes";

export const initState = {
  pageSize: 50,
  currentSearchTerm: null,
  results: {
    /* how this looks like when populated
     *
     * "searchTearm1": {
     *   pages: {}
     * },
     * "searchTearm2": {
     *   pages: {}
     * }*/
  }
};

const api = (state = initState, action) => {
  switch (action.type) {
    case types.TEAMS_SEARCH_REQUEST: {
      const { page: pageNumber, params } = action.request;
      const searchTerm = params.name;

      const getCachedResultsForTerm = () => {
        const previousResultsForTerm = state.results[searchTerm];

        if (
          previousResultsForTerm &&
          previousResultsForTerm.pages[pageNumber]
        ) {
          return previousResultsForTerm.pages[pageNumber];
        } else {
          return {};
        }
      };

      return {
        ...state,
        currentSearchTerm: searchTerm,
        results: {
          ...state.results,
          [searchTerm]: {
            pages: {
              [pageNumber]: {
                ...getCachedResultsForTerm(),
                isFetching: true
              }
            }
          }
        }
      };
    }

    case types.TEAMS_SEARCH_REQUEST_SUCCESS: {
      const { page: pageNumber, params } = action.request;
      const searchTerm = params.name;

      return {
        ...state,
        results: {
          ...state.results,
          [searchTerm]: {
            pages: {
              [pageNumber]: {
                isFetching: false,
                ids: action.response.ids || []
              }
            }
          }
        }
      };
    }

    case SWITCH_TEAM: {
      return initState;
    }

    default:
      return state;
  }
};

export default api;
