import React from "react";
import DraggableElement from "views/components/Editor/elements/DraggableElement";
import { getElementsBox } from "lib/elements";
import { cloneDeep, merge } from "lib/lodash";
import { uuid } from "lib/uuid";
import Logger from "lib/logger";
import { rotatePoint } from "lib/geometry/rotation";
import {
  resizeElementProportionally,
  resizeElementHorizontally,
  resizeElementVertically
} from "lib/scalingTools/scalingTools";
import ElementDefaultsFactory from "./ElementDefaultsFactory";
import { table2CellDimensionUpdater } from "views/components/Editor/sidebar/tabs/shapes/Tables2Tab/helper";

const render = ({
  areFunctionalitiesLocked,
  containerHtmlElement,
  designData,
  element,
  groupId,
  hide,
  isTable2MovementRestricted,
  onDelete,
  onElementAttributeChange,
  onElementPreview,
  onElementsPreview,
  onMove,
  onMoveToPage,
  onSelectItem,
  pageId,
  pageNumber,
  registerHtmlElement,
  selectedItems,
  startImageCropMode,
  startVideoCropMode,
  startVectorCropMode,
  toggleDragging,
  zoom,
  isResizing,
  isDesignPartOfACollection,
  isHighlighted
}) => {
  const isSelected = Boolean(
    selectedItems.find(item => item.itemId === element.uniqueId)
  );

  return (
    <DraggableElement
      onElementPreview={onElementPreview}
      onElementsPreview={onElementsPreview}
      onElementAttributeChange={onElementAttributeChange}
      onMove={onMove}
      onDelete={onDelete}
      onMoveToPage={onMoveToPage}
      registerHtmlElement={registerHtmlElement}
      containerHtmlElement={containerHtmlElement}
      toggleDragging={toggleDragging}
      hide={hide}
      key={element.uniqueId}
      zoom={zoom}
      pageId={pageId}
      id={element.uniqueId}
      onSelectItem={onSelectItem}
      groupId={groupId}
      element={element}
      pageNumber={pageNumber}
      designData={designData}
      startImageCropMode={startImageCropMode}
      startVideoCropMode={startVideoCropMode}
      startVectorCropMode={startVectorCropMode}
      isSelected={isSelected}
      areFunctionalitiesLocked={areFunctionalitiesLocked}
      isResizing={isResizing}
      isDesignPartOfACollection={isDesignPartOfACollection}
      isHighlighted={isHighlighted}
      isTable2MovementRestricted={isTable2MovementRestricted}
    />
  );
};

class Element {
  static RESTRICTIONS = [];

  constructor(props) {
    Object.assign(
      this,
      ElementDefaultsFactory.getDefaults("element"),
      ElementDefaultsFactory.getDefaults(props.type),
      props
    );

    if (!props.uniqueId) {
      this.uniqueId = uuid();
    }
  }

  get minWidth() {
    return 1;
  }

  get minHeight() {
    return 1;
  }

  get canChangeHeight() {
    return true;
  }

  render(props) {
    if (this.isHidden) {
      return;
    }

    const { elementPreviews, selectedItems } = props;

    const selectedItem = selectedItems.find(i => i.itemId === this.uniqueId);

    return render({
      element: merge(
        {},
        this,
        (selectedItem || {}).preview,
        (elementPreviews || {})[this.uniqueId]
      ),
      ...props
    });
  }

  isRemovable() {
    return !this.restrictions.includes("removable");
  }

  isResizable() {
    return (
      !this.restrictions.includes("sizeAndPosition") &&
      !this.restrictions.includes("position") &&
      !this.isHidden
    );
  }

  canBeSetAsBackground() {
    return false;
  }

  clone(newElementId = uuid()) {
    const clonedElement = cloneDeep(this);

    clonedElement.uniqueId = newElementId;

    return [clonedElement];
  }

  getLayerTree({ groupId } = {}) {
    return {
      type: this.type,
      name: this.name,
      uniqueId: this.uniqueId,
      isHidden: this.isHidden,
      canToggleVisibility: !this.restrictions.includes("visibility"),
      groupId
    };
  }

  getRestrictionsMap() {
    const restrictions = {};

    this.constructor.RESTRICTIONS.forEach(
      restriction => (restrictions[restriction] = false)
    );

    this.restrictions.forEach(restriction => {
      if (!restrictions.hasOwnProperty(restriction)) {
        Logger.error("UNMAPED RESTRICTION:", restriction);
      }
      restrictions[restriction] = true;
    });

    return restrictions;
  }

  mutateAttributes(attributes) {
    Object.keys(attributes).forEach(attributeKey => {
      this[attributeKey] = attributes[attributeKey];
    });
  }

  ensureMinimalValues(attributes) {
    const _attributes = Object.assign({}, attributes);

    if (
      _attributes.hasOwnProperty("width") &&
      _attributes.width < this.minWidth
    ) {
      _attributes.width = this.minWidth;
    }

    if (
      _attributes.hasOwnProperty("height") &&
      _attributes.height < this.minHeight
    ) {
      _attributes.height = this.minHeight;
    }

    return _attributes;
  }

  updateAttributes(attributes) {
    const sideEffects = {};

    const _attributes = this.ensureMinimalValues(attributes);

    if (
      _attributes.hasOwnProperty("width") &&
      !_attributes.hasOwnProperty("scale")
    ) {
      const scaleFactor = _attributes.width / this.width;

      sideEffects.height = this.height * scaleFactor;
      sideEffects.scale = this.scale * scaleFactor;
    }

    if (
      _attributes.hasOwnProperty("height") &&
      !_attributes.hasOwnProperty("scale")
    ) {
      const scaleFactor = _attributes.height / this.height;

      sideEffects.width = this.width * scaleFactor;
      sideEffects.scale = this.scale * scaleFactor;
    }

    return new this.constructor({
      ...this,
      ..._attributes,
      ...sideEffects
    });
  }

  toggleRestriction({ attribute, value }) {
    const shouldAddAllRestrictions = () =>
      attribute === "ALL" && value === true;

    const shouldRemoveAllRestrictions = () =>
      attribute === "ALL" && value === false;

    const shouldAddAttribute = () => value === true;

    const shouldRemoveAttribute = () => value === false;

    let restrictionsSet;

    switch (true) {
      case shouldAddAllRestrictions():
        restrictionsSet = this.constructor.RESTRICTIONS;
        break;

      case shouldRemoveAllRestrictions():
        restrictionsSet = [];
        break;

      case shouldAddAttribute():
        restrictionsSet = new Set(this.restrictions).add(attribute);
        break;

      case shouldRemoveAttribute():
        restrictionsSet = new Set(this.restrictions);
        restrictionsSet.delete(attribute);
        break;

      default:
        Logger.error("Unable to toggle Element Restriction");
    }

    return new this.constructor({
      ...this,
      restrictions: Array.from(restrictionsSet)
    });
  }

  move({ top, left }) {
    return new this.constructor({
      ...this,
      top: this.top + top,
      left: this.left + left
    });
  }

  resizeHorizontally({ scale, anchorPoint, offset, zoom, scaleDirection }) {
    const scaledDimensions = resizeElementHorizontally({
      element: this,
      scale,
      anchorPoint,
      offset,
      zoom,
      handlerCoordinateName: scaleDirection
    });

    const elementResized = {
      ...this,
      top: this.top + scaledDimensions.topIncrement,
      left: this.left + scaledDimensions.leftIncrement,
      width: this.width + scaledDimensions.widthIncrement
    };

    if (this.type === "table2") {
      const table2DomPreview = document.getElementById(
        `preview-${this.uniqueId}`
      );
      if (
        table2DomPreview &&
        table2DomPreview.scrollHeight !== elementResized.height
      ) {
        elementResized.height = table2DomPreview.scrollHeight;
      }
      const { cells } = table2CellDimensionUpdater(elementResized);
      elementResized.cells = cells;
    }

    this.applyResizeSideEffects(elementResized);

    return new this.constructor(elementResized);
  }

  resizeVertically({ scale, anchorPoint, offset, zoom, scaleDirection }) {
    const scaledDimensions = resizeElementVertically({
      element: this,
      scale,
      anchorPoint,
      offset,
      zoom,
      handlerCoordinateName: scaleDirection
    });

    const elementResized = {
      ...this,
      top: this.top + scaledDimensions.topIncrement,
      left: this.left + scaledDimensions.leftIncrement,
      height: this.height + scaledDimensions.heightIncrement
    };

    if (this.type === "table2") {
      elementResized.cells = scaledDimensions.cells;
      elementResized.height = scaledDimensions.height / zoom;
    }
    this.applyResizeSideEffects(elementResized, scale);

    return new this.constructor(elementResized);
  }

  stretch() {
    return this;
  }

  resize({
    scale,
    anchorPoint,
    offset,
    zoom,
    scaleDirection,
    differenceFromInitialOffset,
    dragItem
  }) {
    switch (scaleDirection) {
      case "W":
      case "E":
        return this.resizeHorizontally({
          scale,
          anchorPoint,
          offset,
          zoom,
          scaleDirection
        });
      case "N":
      case "S":
        return this.resizeVertically({
          scale,
          anchorPoint,
          offset,
          zoom,
          scaleDirection
        });
      default:
      /* noop; */
    }

    const scaledDimensions = resizeElementProportionally({
      element: this,
      offset,
      zoom,
      anchorPoint,
      dragItem,
      differenceFromInitialOffset,
      scale
    });

    const elementUpdated = {
      ...this,
      top: this.top + scaledDimensions.topIncrement,
      left: this.left + scaledDimensions.leftIncrement,
      width: this.width * scale,
      height: this.height * scale,
      fontSize: this.fontSize * scale,
      scale: this.scale * scale,
      rows: scaledDimensions.rows,
      columnsMetadata: scaledDimensions.columnsMetadata,
      value: scaledDimensions.value,
      displayValue: scaledDimensions.displayValue
    };

    if (scaledDimensions.imageInstructions) {
      elementUpdated.imageInstructions = scaledDimensions.imageInstructions;
    }

    if (this.type === "table2") {
      // remove unneeded table2 properties
      [
        "fontSize",
        "rows",
        "value",
        "displayValue",
        "columnsMetadata",
        "filter"
      ].forEach(prop => delete elementUpdated[prop]);

      const { cells } = table2CellDimensionUpdater({
        ...elementUpdated,
        cells: scaledDimensions.cells
      });
      elementUpdated.cells = cells;
    }

    this.applyResizeSideEffects(elementUpdated, scale);

    return new this.constructor(elementUpdated);
  }

  applyResizeSideEffects(elementResized) {
    /* This method is implemented per subClasses base */
    return elementResized;
  }

  getCornersPosition(simulatedAttributes) {
    const { left, top, width, height, angle } = Object.assign(
      {},
      this,
      simulatedAttributes
    );

    const round = value => Math.round(value * 1000) / 1000;

    let points = [
      [left, top],
      [left + width, top],
      [left + width, top + height],
      [left, top + height]
    ];

    const centerX = left + width / 2;
    const centerY = top + height / 2;

    const rotatedPoints = points.map(point => {
      const { x, y } = rotatePoint(point[0], point[1], centerX, centerY, angle);

      return [round(x), round(y)];
    });

    return {
      topLeft: { x: rotatedPoints[0][0], y: rotatedPoints[0][1] },
      topRight: { x: rotatedPoints[1][0], y: rotatedPoints[1][1] },
      bottomRight: { x: rotatedPoints[2][0], y: rotatedPoints[2][1] },
      bottomLeft: { x: rotatedPoints[3][0], y: rotatedPoints[3][1] }
    };
  }

  getSnapPoints() {
    const boundingBox = !this.angle ? this : getElementsBox(this);

    const elementSnapXCoordinates = {
      left: boundingBox.left, // left
      center: boundingBox.left + boundingBox.width / 2, // center
      right: boundingBox.left + boundingBox.width // right
    };

    const elementSnapYCoordinates = {
      top: boundingBox.top, // top
      center: boundingBox.top + boundingBox.height / 2, // center
      bottom: boundingBox.top + boundingBox.height // bottom
    };

    return {
      x: elementSnapXCoordinates,
      y: elementSnapYCoordinates
    };
  }

  getSnapPointsWithOffsets() {
    const elementSnapPoints = this.getSnapPoints();

    const horizontalOffsets = {
      left: this.left - elementSnapPoints.x.left,
      center: this.left - elementSnapPoints.x.center,
      right: this.left - elementSnapPoints.x.right
    };

    /* vertical */
    const verticalOffsets = {
      top: this.top - elementSnapPoints.y.top,
      center: this.top - elementSnapPoints.y.center,
      bottom: this.top - elementSnapPoints.y.bottom
    };

    return {
      ...elementSnapPoints,
      offsets: {
        x: horizontalOffsets,
        y: verticalOffsets
      }
    };
  }

  getImageColors() {
    return {};
  }

  convertToBackgroundType({ pageWidth, pageHeight }) {
    Logger.error(
      `${this.constructor.name} doesn't implement 'convertToBackgroundType'`
    );

    return this;
  }

  isAttributeRestricted(attribute) {
    return this.restrictions.includes(attribute);
  }

  hasAnyRestriction() {
    return this.restrictions.length !== 0;
  }

  hasAllRestrictions() {
    return this.constructor.RESTRICTIONS.every(restrictionKey =>
      this.restrictions.includes(restrictionKey)
    );
  }

  convertToAssetTypeAndCenter({ pageWidth, pageHeight }) {
    Logger.Error(
      `${this.constructor.name} doesn't implement 'convertToAssetTypeAndCenter'`
    );

    return this;
  }

  removeTextMask(args) {
    Logger.Error(`${this.constructor.name} doesn't implement 'removeTextMask'`);

    return this;
  }

  addTextMask(args) {
    Logger.Error(`${this.constructor.name} doesn't implement 'removeTextMask'`);

    return this;
  }

  updateTextMask(args) {
    Logger.Error(`${this.constructor.name} doesn't implement 'updateTextMask'`);

    return this;
  }

  updateImageInstructions(args) {
    Logger.Error(
      `${this.constructor.name} doesn't implement 'updateImageInstructions'`
    );

    return this;
  }
}

export default Element;
