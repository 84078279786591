import { connect } from "react-redux";

import { updateTeam } from "../../../state/entities/teams/teamsActions";

import Team from "./Team";

function mapStateToProps(state) {
  const currentTeamId = state.ui.currentTeam.id;
  const teamSettings = state.ui.teamSettings;

  return {
    team: state.entities.teams[currentTeamId],
    roles: state.currentUser.roles,
    teamSettings: teamSettings.team
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateTeam: args => dispatch(updateTeam(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Team);
