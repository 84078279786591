import React from "react";

import style from "./style.module.css";

import UpgradeModal from "./UpgradeModal";
import UpdateDetailsModal from "./UpdateDetailsModal";

import Modal from "../../components/modal";
import Loading from "../../components/loading";
import CurrentSubscriptionDescriptionSelector from "views/components/CurrentSubscriptionDescription/CurrentSubscriptionDescriptionSelector";
import PlanComparison from "views/components/PlanComparison/PlanComparison";
import UserSubscriptionPlan from "views/containers/plansContainer/PricingGrid/UserSubscriptionPlan";

export class Plans extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseUpgradeModal = this.handleCloseUpgradeModal.bind(this);
    this.handleUpgradeClick = this.handleUpgradeClick.bind(this);
    this.handleResubscribeClick = this.handleResubscribeClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleUpgradeFromTrialClick = this.handleUpgradeFromTrialClick.bind(
      this
    );
    this.handleOpenUpdateDetailsModal = this.handleOpenUpdateDetailsModal.bind(
      this
    );
    this.handleCloseUpdateModal = this.handleCloseUpdateModal.bind(this);
    this.handleUpdateDetails = this.handleUpdateDetails.bind(this);

    this.state = {
      updateDetailsModal: false,
      selectedPlan: {
        code: null,
        name: null,
        amount: null
      }
    };
  }

  componentDidMount() {
    if (this.props.currentTeam.id) {
      const teamId = this.props.currentTeam.id;
      this.props.fetchSubscriptionsIfNeeded(teamId);
      this.props.fetchCards(teamId);
    }
  }

  handleCloseUpgradeModal() {
    this.props.onToggleModal({ name: "upgrade", visible: false });
  }

  handleSubscribe({ token, couponCode, planId, onSuccess, onFailure }) {
    const {
      currentTeam: { id: teamId },
      currentSubscription: { id: subscriptionId }
    } = this.props;

    this.props.onUpdateSubscription({
      teamId,
      subscriptionId,
      planId,
      couponCode,
      token,
      onSuccess,
      onFailure
    });
  }

  handleResubscribeClick() {
    const {
      currentPlan: { code: subscriptionCode },
      currentTeam: { id: teamId },
      currentSubscription: { id: subscriptionId }
    } = this.props;

    this.props.onUpdateSubscription({
      teamId,
      subscriptionId,
      subscriptionCode
    });
  }

  handleCancelClick(args = {}) {
    const {
      currentTeam: { id: teamId },
      currentSubscription: { id: subscriptionId }
    } = this.props;

    this.props.onUpdateSubscription({
      teamId,
      subscriptionId,
      subscriptionCode: "BASIC",
      ...args
    });
  }

  handleUpgradeClick(planId) {
    const { plans } = this.props;
    const plan = plans.find(plan => plan.id === planId);
    this.setState({ selectedPlan: plan });
    this.props.onToggleModal({ name: "upgrade", visible: true });
  }

  handleUpgradeFromTrialClick() {
    const { currentPlan, plans } = this.props;
    const plan = plans.find(plan => plan.id === currentPlan.id);
    this.setState({ selectedPlan: plan });
    this.props.onToggleModal({ name: "upgrade", visible: true });
  }

  handleCloseUpdateModal() {
    this.props.onToggleModal({ name: "creditCard", visible: false });
  }

  handleUpdateDetails({ token }) {
    const {
      currentTeam: { id: teamId }
    } = this.props;
    this.props.onAddCard({ teamId, token });
  }

  handleOpenUpdateDetailsModal() {
    this.props.onToggleModal({ name: "creditCard", visible: true });
  }

  isPlanUpgrade() {
    if (this.state.selectedPlan.code === null) {
      return false;
    }

    const userSubscriptionPlan = new UserSubscriptionPlan({
      subscription: this.props.currentSubscription,
      plan: this.props.currentPlan
    });

    return userSubscriptionPlan.isUpgrade(this.state.selectedPlan);
  }

  render() {
    const {
      coupon,
      currentSubscription,
      currentCard,
      currentPlan,
      fetchCoupon,
      plans,
      upgradeModalVisible,
      subscriptionChangeSubmitting,
      creditCardModalVisible,
      creditCardChangeSubmitting,
      creditCardErrors,
      subscriptionErrors,
      isAdmin,
      showOnlyPlanCode
    } = this.props;

    if (!currentSubscription) return <Loading />;

    const { selectedPlan } = this.state;

    return (
      <div>
        <div className={style.subscriptionDescriptionSection}>
          <CurrentSubscriptionDescriptionSelector
            isAdmin={isAdmin}
            showUpdateCardModal={this.handleOpenUpdateDetailsModal}
            plan={currentPlan}
            subscription={currentSubscription}
            card={currentCard}
            onCancel={this.handleCancelClick}
          />
        </div>
        <PlanComparison
          isAdmin={isAdmin}
          plans={plans}
          currentSubscription={currentSubscription}
          currentPlan={currentPlan}
          openUpgradeModal={this.handleUpgradeClick}
          filterPlanCode={showOnlyPlanCode}
        />
        <Modal
          className={style.upgradeModalContainer}
          isOpen={creditCardModalVisible}
          contentLabel="Update credit card details"
          onRequestClose={this.handleCloseUpdateModal}
          header="Update credit card details"
        >
          <UpdateDetailsModal
            onCancel={this.handleCloseUpdateModal}
            onUpdate={this.handleUpdateDetails}
            isUpdating={creditCardChangeSubmitting}
            card={currentCard}
            errors={creditCardErrors}
          />
        </Modal>
        <Modal
          className={style.upgradeModalContainer}
          isOpen={upgradeModalVisible}
          contentLabel={`Upgrade to the Easil ${selectedPlan.name} plan`}
          onRequestClose={() => this.handleCloseUpgradeModal()}
          header={`Upgrade to the Easil ${selectedPlan.name} plan`}
        >
          <UpgradeModal
            coupon={coupon}
            fetchCoupon={fetchCoupon}
            onCancel={() => this.handleCloseUpgradeModal()}
            onSubscribe={args => this.handleSubscribe(args)}
            isSubscribing={subscriptionChangeSubmitting}
            currentPlan={currentPlan}
            currentSubscription={currentSubscription}
            newPlan={selectedPlan}
            card={currentCard}
            errors={[...subscriptionErrors, ...creditCardErrors]}
            upgrading={this.isPlanUpgrade()}
          />
        </Modal>
      </div>
    );
  }
}

Plans.displayName = "Plans";

export default Plans;
