import * as types from "./subscriptionsTypes";
import { merge, omit } from "lib";
import * as cardsTypes from "state/entities/cards/cardsTypes";

export const initState = {};

// paths that can be used to identify a coupon error
const couponErrorPaths = [
  "eligibleIntervals",
  "eligiblePlans",
  "coupon",
  "code"
];

// filters an array of errors so that only coupon errors remain
const filterErrors = errors => {
  let couponErrors = errors;
  // parse through the errors
  if (!Array.isArray(couponErrors)) {
    // not an array handle single error
    couponErrors = [couponErrors];
  }

  // filter out any errors not for coupons
  return couponErrors.filter(error => couponErrorPaths.includes(error.path));
};

const subscriptionsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_COUPON: {
      return merge({}, state, { coupon: null });
    }

    case cardsTypes.CREATE_CARD_FAILURE:
    case types.UPDATE_SUBSCRIPTION_FAILURE: {
      // filter out anything not subscription coupon related
      const applicableErrors = filterErrors(action.error.errors);

      // new errors, update the state with them
      if (applicableErrors && applicableErrors.length) {
        return {
          ...state,
          coupon: {
            errors: applicableErrors,
            status: action.error.status,
            name: action.error.name
          }
        };
      }

      if (state.coupon && (state.coupon.errors || !state.coupon.isValid)) {
        // nothing new but we have an old coupon error, clear it
        return { ...omit(state, "coupon") };
      }

      // nothing to change here, return the state
      return { ...state };
    }

    case types.FETCH_COUPON_FAILURE: {
      return { ...state, coupon: action.error };
    }

    case types.FETCH_COUPON_SUCCESS: {
      return merge({}, state, { coupon: action.response });
    }

    case types.FETCH_SUBSCRIPTIONS_SUCCESS: {
      const { entities } = action.response;

      return merge({}, state, entities.subscription);
    }

    case types.UPDATE_SUBSCRIPTION_SUCCESS: {
      const { entities } = action.response;

      return merge({}, initState, entities.subscription);
    }

    default:
      return state;
  }
};

export default subscriptionsReducers;
