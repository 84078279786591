import React, { Component } from "react";
import style from "./style.module.css";
import HoverOverlay from "views/components/Editor/elements/HoverOverlay/HoverOverlay";
import { jsonStringEqual } from "lib/equalityUtils";
import Table2Row from "./Table2Row/Table2Row";
import { identity } from "lib";

class Table2 extends Component {
  static defaultProps = {
    connectDragSource: identity
  };

  constructor(props) {
    super(props);
    this.getTableStyle = this.getTableStyle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.tableRef = React.createRef();

    this.state = {
      lockedCellIds: this.props.elementData.lockedCellIds,
      isHovered: false
    };
  }

  shouldComponentUpdate(nextProps, prevState) {
    if (!jsonStringEqual(this.props.layout, nextProps.layout)) {
      return true;
    } else if (!jsonStringEqual(this.props.cells, nextProps.cells)) {
      return true;
    } else if (
      !jsonStringEqual(this.props.elementData, nextProps.elementData)
    ) {
      return true;
    } else if (this.state.isHovered !== prevState.isHovered) {
      return true;
    }
    return false;
  }

  getTableStyle() {
    const { width, height, opacity } = this.props.elementData;

    return {
      ...this.props.style,
      opacity,
      // allow table to grow in height when editing
      height: this.props.isEditingCells ? "100%" : height,
      width
    };
  }

  onSelect(e) {
    e.stopPropagation();
    e.preventDefault();

    /* if user pressed shift or meta/command(mac), we append the to the delection*/
    const append = e.shiftKey || e.metaKey;

    this.props.onSelectItem({ append });
  }

  onMouseEnter() {
    this.setState({ isHovered: true });
  }

  onMouseLeave() {
    this.setState({ isHovered: false });
  }

  showHoverOverlay() {
    const {
      isOnlyElementSelected,
      isSelected,
      isAllRestrictionsLocked
    } = this.props;
    const { isHovered } = this.state;

    if (isOnlyElementSelected || isSelected || isAllRestrictionsLocked) {
      return false;
    }

    return isHovered;
  }

  render() {
    const tableStyle = this.getTableStyle();
    return this.props.connectDragSource(
      <div
        className={style.tableWrapper}
        style={tableStyle}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onSelect}
        data-testid="Table2Element"
        id={`${this.props.isPreview ? "preview" : "table2-element"}-${
          this.props.elementData.uniqueId
        }`}
        draggable={true}
        ref={this.tableRef}
      >
        <table>
          <colgroup>
            {this.props.elementData.layout[0].map((col, index) => (
              <col
                id={`colgroup-${this.props.elementData.uniqueId}-${index}`}
                key={index}
              />
            ))}
          </colgroup>
          <tbody>
            {this.props.elementData.layout.map((row, rowIndex) => (
              <Table2Row
                key={rowIndex}
                isPreview={this.props.isPreview}
                row={row}
                rowIndex={rowIndex}
                elementData={this.props.elementData}
                zoom={this.props.zoom}
                lockedRowResizeCellIds={this.props.lockedRowResizeCellIds}
                lockedColumnResizeCellIds={this.props.lockedColumnResizeCellIds}
              />
            ))}
          </tbody>
        </table>
        {this.showHoverOverlay() && (
          <HoverOverlay
            height={this.props.elementData.height}
            width={this.props.elementData.width}
            zoom={this.props.zoom}
          />
        )}
      </div>
    );
  }
}

export default Table2;
