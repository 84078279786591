import * as types from "../../entities/downloads/downloadsTypes";

import { formatErrors } from "lib";

const errorsArray = error => formatErrors(error);

export const initState = {
  isProcessing: false,
  isSuccess: false,
  format: null,
  errors: []
};

const downloadsReducers = (state = initState, action) => {
  switch (action.type) {
    case types.DOWNLOAD_DESIGN_REQUEST: {
      const {
        request: {
          body: { format }
        }
      } = action;

      return {
        ...state,
        errors: [],
        format: format.toUpperCase(),
        isSuccess: false,
        isProcessing: true
      };
    }

    case types.DOWNLOAD_DESIGN_REQUEST_SUCCESS: {
      const {
        response: {
          ids: id,
          entities: { download: downloads }
        }
      } = action;
      const download = downloads[id];

      if (!download.status) return state;
      if (download.status === "IN PROGRESS") return state;

      return {
        ...initState,
        isSuccess: true
      };
    }

    case types.DOWNLOAD_DESIGN_REQUEST_FAILURE: {
      const { error } = action;

      const errors = errorsArray(error);

      return {
        ...state,
        errors,
        format: null,
        isProcessing: false,
        isSuccess: false
      };
    }

    default:
      return state;
  }
};

export default downloadsReducers;
