import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { keyCodes } from "lib";

import { Input } from "../../components";
import PlusIcon from "views/components/icons/PlusIcon";
import TrashIcon from "views/components/icons/TrashIcon";
import PencilIcon from "views/components/icons/PencilIcon";
import CheckIcon from "views/components/icons/CheckIcon";

class EditableTitle extends Component {
  constructor(props) {
    super(props);

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);

    this.state = {
      title: props.title,
      isEditing: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) return;

    this.setState({ title: this.props.title });
  }

  handleEditClick() {
    if (this.props.onEditClick) {
      this.props.onEditClick();
    } else {
      this.setState({ isEditing: true });
    }
  }

  handleCancelClick() {
    this.setState({ isEditing: false, title: this.props.title });
  }

  handleConfirmClick() {
    this.setState({ isEditing: false });
    this.props.onTitleUpdate(this.state.title);
  }

  onKeyDown(event) {
    /* on escape key press while on page and editing */
    if (event.keyCode === keyCodes.escapeKey && this.state.isEditing) {
      event.stopPropagation();
      event.preventDefault();
      this.handleCancelClick();
    }

    /* We stop propagation to prevent elements to be deleted from canvas */
    if (keyCodes.isBlackListed(event.keyCode)) {
      event.stopPropagation();
    }
  }

  onKeyPress(event) {
    /* on enter while focussed */
    if (event.key === "Enter") {
      this.handleConfirmClick();
    }
  }

  handleRemoveClick() {
    this.props.onRemoveClick();
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  render() {
    const {
      className,
      displayTrashIcon = true,
      allowEdit,
      style: propsStyle = {}
    } = this.props;
    const { title, isEditing } = this.state;

    return (
      <div className={`${style.wrapper} ${className}`} style={propsStyle}>
        <div className={`${style.label} notranslate`}>
          {isEditing && (
            <Input
              defaultValue={title}
              onChange={this.handleTitleChange}
              aria-label="title"
              className={style.input}
              onKeyPress={this.onKeyPress}
              onKeyDown={this.onKeyDown}
            />
          )}
          {!isEditing && title}
        </div>
        <div className={style.actions}>
          {isEditing && (
            <Fragment>
              <PlusIcon
                size="18px"
                rotate="45"
                onClick={this.handleCancelClick}
              />
              <CheckIcon size="18px" onClick={this.handleConfirmClick} />
            </Fragment>
          )}
          {!isEditing && allowEdit && (
            <Fragment>
              <PencilIcon size="18px" onClick={this.handleEditClick} />
              {displayTrashIcon && (
                <TrashIcon size="24px" onClick={this.handleRemoveClick} />
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

EditableTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onRemoveClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onTitleUpdate: PropTypes.func,
  displayTrashIcon: PropTypes.bool,
  allowEdit: PropTypes.bool
};

export default EditableTitle;
