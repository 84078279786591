import * as imageColorExtractor from "node-vibrant";
import { DEFAULT_SHADOW } from "lib/constants";

export const getPaletteFromSrc = async (src, isRetry) => {
  let palette = null;

  if (!src) return [];

  try {
    palette = await imageColorExtractor.from(src).getPalette();
  } catch (err) {
    const uniqueSrc = `${src}?q=${Date.now()}`; // black magic happens here

    if (isRetry) {
      palette = {};
    } else {
      return await getPaletteFromSrc(uniqueSrc, true);
    }
  }

  const paletteArr = [];

  [
    "Vibrant",
    "LightVibrant",
    "DarkVibrant",
    "Muted",
    "LightMuted",
    "DarkMuted"
  ].forEach(paletteType => {
    if (palette[paletteType]) {
      paletteArr.push(
        imageColorExtractor.Util.rgbToHex(
          palette[paletteType]._rgb[0],
          palette[paletteType]._rgb[1],
          palette[paletteType]._rgb[2]
        )
      );
    }
  });

  return paletteArr;
};

/**
 * @desc - takes a hexadecimal color code as an argument and returns a 6 digit hexadecimal color code.
 * @param {string} hex - hexadecimal color code, either 3 or 6 digits
 * @returns A 6 digit hexadecimal color code
 */
export const sixDigitHexColor = hex => {
  if (!hex) return DEFAULT_SHADOW.color;

  if (/^transparent$/i.test(hex)) {
    return "transparent";
  }

  if (hex.length === 7) {
    return hex.toUpperCase();
  }

  return ["#", hex[1], hex[1], hex[2], hex[2], hex[3], hex[3]]
    .join("")
    .toUpperCase();
};

/**
 * @desc gets the fill colors from the given elements
 * @param {[object]} elements - an array of elements to get fill colors for
 * @returns {[object]}
 */
export const getFillColorsForElements = elements => {
  const colors = new Set();

  elements.forEach(element => {
    let elementColors = {};

    if (element.fillColors) {
      element.fillColors.forEach(fillColor => {
        const fillColorPrefixArray = fillColor.domId?.match(/change_?\d+/);
        let fillColorPrefix;
        if (!!fillColorPrefixArray) {
          fillColorPrefix = fillColorPrefixArray[0];
          elementColors[fillColorPrefix] = fillColor.color;
        } else {
          return [];
        }
      });

      Object.keys(elementColors).forEach(elementColorId =>
        colors.add({
          elementId: element.uniqueId || element.itemId,
          id: elementColorId,
          color: sixDigitHexColor(elementColors[elementColorId])
        })
      );
    }
  });

  return Array.from(colors);
};

/**
 * @desc convert Hex color to RGB
 * @param {string} hex - the hex color to convert to RGB
 * @returns {string} - a string representing the hex color as an rgb value
 */
export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) return;

  const { r, g, b } = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };

  return `${r}, ${g}, ${b}`;
};

/**
 * @desc converts a given decimal number to hexadecimal
 * @param {Number} int
 * @returns {string} hex string representing the number in base 16
 */
export const toHex = int => {
  const hex = int.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

/**
 * @desc parses an rgb color string into a hex value
 * @param {string} color - an rgb color string
 * @returns {{ hex: string, opacity: number }} - an object representing the rgb color in hex with opacity
 */
export const parseColor = color => {
  const arr = [];
  color.replace(/[\d+.]+/g, function(v) {
    arr.push(parseFloat(v));
  });
  return {
    hex:
      "#" +
      arr
        .slice(0, 3)
        .map(toHex)
        .join(""),
    opacity: arr.length === 4 ? arr[3] : 1
  };
};

/**
 * @desc parses an rgb color string into a hex string
 * @param {string} rgb - an rgb color string
 * @returns {string} - a hex string
 */
export const rgbToHex = rgb => {
  const parsedColor = parseColor(rgb);
  return parsedColor.hex;
};
