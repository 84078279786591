import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";
import InfoAlert from "views/components/InfoAlert";
import {
  getCouponErrorMessage,
  getCouponMeta,
  PERCENT_SYMBOL
} from "./upgradeUtils";
import Subscriptions from "lib/subscriptions";
import { reportUpgradeEvent } from "lib/conversionTracker";

import {
  Button,
  CreditCardForm,
  Input,
  Loading,
  Tooltip
} from "../../components";
import CheckIcon from "views/components/icons/CheckIcon";
import InfoIcon from "views/components/icons/InfoIcon";

import { handleError, isEmpty, noop, pick } from "lib";

class UpgradeModal extends Component {
  static ACTIVE_MEMBERS_TIP_TEXT =
    "An active member is someone who has \naccepted their Easil invitation";
  static INTERVAL_CHARGE_TIP_TEXT = interval =>
    `The total ${interval.toLowerCase()} charge for all members on your plan`;
  static INTERVALS_USER_FRIENDLY = {
    YEARLY: "Yearly",
    MONTHLY: "Monthly"
  };

  constructor(props) {
    super(props);

    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.handlePromoFormChange = this.handlePromoFormChange.bind(this);
    this.handleToken = this.handleToken.bind(this);
    this.handleTokenFailure = this.handleTokenFailure.bind(this);
    this.handleTogglePaymentForm = this.handleTogglePaymentForm.bind(this);
    this.setProcessingFalse = this.setProcessingFalse.bind(this);
    this.setProcessingFalseAndSetError = this.setProcessingFalseAndSetError.bind(
      this
    );
    this.getPromoCodeSection = this.getPromoCodeSection.bind(this);

    this.state = {
      promoCode: null,
      token: null,
      card: null,
      displayCardForm: false,
      processing: false,
      errors: null
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    // Reset the token if card has updated successfully.
    // We don't want to attempt creating the card twice for the team as it will error.
    if (prevProps.card !== this.props.card) {
      this.setState({ token: null });
    }
  }

  isCouponForTotalAmount = coupon =>
    coupon && coupon.value === 100 && coupon.valueType === PERCENT_SYMBOL;

  componentWillUnmount() {
    /* If the modal has been unmounted the following callback functions need to be
     * set to noop,otherwise it will try to setState on a unmounted component, if the
     * callback happen after modal/component has been unmounted*/
    this.setProcessingFalseAndSetError = noop;
    this.setProcessingFalse = noop;
  }

  setProcessingFalse() {
    this.setState({ processing: false, errors: null });
  }

  setProcessingFalseAndSetError(error) {
    const errors = handleError({ error });
    const updatedState = {
      processing: false,
      errors: [{ value: "There was a problem processing your card" }]
    };

    if (errors && errors.message) {
      updatedState.errors = [
        {
          value: errors.message
        }
      ];
    }

    this.setState({
      ...updatedState
    });
  }

  handlePromoFormChange(event) {
    const {
      target: { value: promoCode }
    } = event;

    if (promoCode === "") {
      this.setState({ promoCode: null });
    } else {
      this.setState({ promoCode });
    }
  }

  handleToken(token) {
    const tokenObject = pick(token, ["id", "object", "type"]);

    this.setState({
      token: tokenObject,
      card: { last4: token.card.last4, brand: token.card.brand }
    });
  }

  handleTokenFailure() {
    this.setState({
      token: null,
      card: null
    });
  }

  handleSubscribe() {
    const {
      newPlan,
      card,
      onSubscribe,
      isSubscribing,
      currentSubscription
    } = this.props;
    const { token, promoCode, processing } = this.state;
    const couponMeta = getCouponMeta(this.props);
    const isCouponForTotalAmount = this.isCouponForTotalAmount(couponMeta);
    if (isSubscribing || processing) return;

    this.setState({ processing: true, errors: null });

    if (!token && isEmpty(card) && !isCouponForTotalAmount) {
      return this.setProcessingFalseAndSetError();
    }

    let handleSuccess = this.setProcessingFalse;

    if (
      [Subscriptions.PLUS.code, Subscriptions.EDGE.code].includes(newPlan.code)
    ) {
      const couponMeta = getCouponMeta(this.props);
      const discountPerMember = (couponMeta || {}).discountPerMember || 0;
      const pricePerMember = newPlan.amount - discountPerMember;
      const total = pricePerMember * currentSubscription.quantity;

      handleSuccess = reportUpgradeEvent({
        currency: newPlan.currency,
        interval: newPlan.intervalPeriod,
        plan: newPlan.code,
        value: total,
        callback: this.setProcessingFalse
      });
    }

    onSubscribe({
      planId: newPlan.id,
      token,
      couponCode: (couponMeta || {}).code || promoCode,
      onSuccess: handleSuccess,
      onFailure: this.setProcessingFalseAndSetError
    });
  }

  subscribeButtonLabel() {
    const { isSubscribing, newPlan, currentSubscription } = this.props;
    const { processing } = this.state;

    if (isSubscribing || processing) return <Loading />;

    const isCancelling =
      currentSubscription.planId === newPlan.id &&
      currentSubscription.status === "CANCELLING";

    const label = isCancelling ? "Resubscribe" : "Upgrade";

    return `${label} to ${newPlan.name}`;
  }

  handleCouponApply() {
    this.props.fetchCoupon({ promoCode: this.state.promoCode });
    this.setState({ promoCode: null });
  }

  handleTogglePaymentForm() {
    this.setState({ displayCardForm: !this.state.displayCardForm });
  }

  getPromoCodeSection() {
    const {
      isSubscribing,
      newPlan: { intervalPeriod, name: newPlanName }
    } = this.props;
    const { promoCode } = this.state;
    const couponMeta = getCouponMeta(this.props);

    // coupon is an error or current interval is not eligible
    const isCouponError = couponMeta && couponMeta instanceof Error;

    const couponErrorMessage = getCouponErrorMessage(
      couponMeta,
      intervalPeriod,
      newPlanName
    );

    return (
      <div
        className={style.upgradeModalPromoCode}
        data-testid="promoCodeSection"
      >
        <div className={style.upgradeModalFormLabel}>Promo Code</div>
        <div className={style.upgradeModalFormInline}>
          <Input
            disabled={isSubscribing}
            value={promoCode || ""}
            onChange={this.handlePromoFormChange}
            onKeyDown={e => {
              if (e.key === "Enter") {
                this.handleCouponApply(e);
              }
            }}
            placeholder="Enter Promo Code"
          />
          <Button
            theme="blueSolid"
            disabled={isSubscribing || !promoCode}
            onClick={e => this.handleCouponApply(e)}
          >
            Apply
          </Button>
        </div>
        {couponMeta &&
          (isCouponError ? ( // is an Error
            <InfoAlert className={style.upgradeModalPromoCodeAlert} theme="red">
              {couponErrorMessage}
            </InfoAlert>
          ) : (
            <InfoAlert
              className={style.upgradeModalPromoCodeAlert}
              icon={CheckIcon}
              iconSize="16px"
              theme="green"
            >
              The promo code <strong>{couponMeta.code}</strong> has been
              successfully applied.
            </InfoAlert>
          ))}
      </div>
    );
  }

  render() {
    const {
      isSubscribing,
      currentPlan,
      currentSubscription,
      newPlan,
      card,
      upgrading,
      errors: propsErrors
    } = this.props;

    const { errors: stateErrors, token } = this.state;
    const errors = stateErrors || propsErrors;
    const { displayCardForm } = this.state;
    const isShowingCardInput = !card || displayCardForm;
    const couponMeta = getCouponMeta(this.props);
    const discountPerMember = (couponMeta || {}).discountPerMember || 0;
    const pricePerMember = newPlan.amount - discountPerMember;
    const total = pricePerMember * currentSubscription.quantity;
    const userFriendlyInterval =
      UpgradeModal.INTERVALS_USER_FRIENDLY[newPlan.intervalPeriod];
    const isCouponForTotalAmount = this.isCouponForTotalAmount(couponMeta);
    const shouldDisableUpgradeButton = Boolean(
      !token && isShowingCardInput && !isCouponForTotalAmount
    );

    // coupon is an error or current interval is not eligible
    const isCouponError = couponMeta && couponMeta instanceof Error;

    return (
      <div className={style.upgradeModalWrapper}>
        <div className={style.upgradeDetails}>
          <div className={style.upgradeDetail}>
            <div className={style.upgradeDetailTitle}>
              Active Members{" "}
              <div
                data-tip={UpgradeModal.ACTIVE_MEMBERS_TIP_TEXT}
                data-for="activeMembersTip"
              >
                <InfoIcon width="12px" height="12px" color="#d1d4d6" />
              </div>
            </div>
            <div className={style.upgradeDetailValue}>
              {currentSubscription.quantity}
            </div>
            <Tooltip id="activeMembersTip" place="top" multiline />
          </div>
          <div className={style.upgradeDetail}>
            <div className={style.upgradeDetailTitle}>
              {userFriendlyInterval} plan price per member
            </div>
            <div
              className={style.upgradeDetailValue}
              style={discountPerMember ? { color: "#3ab38d" } : {}}
            >
              ${pricePerMember.toFixed(2)} {newPlan.currency}
            </div>
          </div>
          <div className={style.upgradeDetail}>
            <div className={style.upgradeDetailTitle}>
              {userFriendlyInterval} charge{" "}
              <div
                data-tip={UpgradeModal.INTERVAL_CHARGE_TIP_TEXT(
                  newPlan.intervalPeriod
                )}
                data-for="intervalChargeTip"
              >
                <InfoIcon width="12px" height="12px" color="#d1d4d6" />
              </div>
            </div>
            <div className={style.upgradeDetailValue}>
              ${total.toFixed(2)} {newPlan.currency}
            </div>
            <Tooltip id="intervalChargeTip" place="top" />
          </div>
        </div>
        {upgrading && !isShowingCardInput && this.getPromoCodeSection()}
        {isShowingCardInput && (
          <div className={style.upgradeModalPaymentDetails}>
            <h2>Payment Details</h2>
            {upgrading && this.getPromoCodeSection()}
            <CreditCardForm
              onTokenSuccess={token => this.handleToken(token)}
              onTokenFailure={this.handleTokenFailure}
              disabled={isSubscribing}
              labelStyles={style.cardLabelStyle}
            />
          </div>
        )}
        {card && !displayCardForm && (
          <InfoAlert className={style.infoAlert}>
            <div>
              You're upgrading{" "}
              {currentSubscription.status !== "CANCELLING" && (
                <span>
                  from <strong>{currentPlan.name}</strong>{" "}
                </span>
              )}
              to <strong>{newPlan.name}</strong>. Your total will be charged to
              <br />
              <strong>
                {card.brand} ****{card.last4}.
              </strong>{" "}
              <strong
                className={style.upgradeModalShowPaymentFormLink}
                onClick={() => this.handleTogglePaymentForm()}
              >
                Change card
              </strong>
            </div>
          </InfoAlert>
        )}
        {errors && !isCouponError && (
          <div className={style.errors} data-testid="errorSection">
            {errors.map((error, index) => (
              <div key={`credit-card-error-${index}`} className={style.error}>
                {error.value}
              </div>
            ))}
          </div>
        )}
        <div className={style.upgradeModalFooter}>
          <Button
            theme="blueSolid"
            isFullWidth={true}
            onClick={() => this.handleSubscribe()}
            disabled={shouldDisableUpgradeButton}
          >
            {this.subscribeButtonLabel()}
          </Button>
        </div>
      </div>
    );
  }
}

UpgradeModal.propTypes = {
  fetchCoupon: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func,
  isSubscribing: PropTypes.bool,
  upgrading: PropTypes.bool,
  errors: PropTypes.array
};

export default UpgradeModal;
