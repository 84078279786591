import { createSelector } from "reselect";
import { getPath } from "lib/lodash";

export const stockAnimationsApiSelector = state => state.api.stockAnimations;
export const processStockAnimationsIsFetchingSelector = stockAnimationsApi => {
  if (!stockAnimationsApi || !stockAnimationsApi.pages) return true;
  const { pages } = stockAnimationsApi;
  return Object.values(pages).some(page => page.isFetching); // true if at least one page is fetching
};

export const stockAnimationsIsFetchingSelector = createSelector(
  [stockAnimationsApiSelector],
  processStockAnimationsIsFetchingSelector
);

const processStockAnimationsPageCount = stockAnimationsApi => {
  if (!stockAnimationsApi || !stockAnimationsApi.pages) return 0;
  return Object.keys(stockAnimationsApi.all.pages).length;
};

export const stockAnimationsPageCount = createSelector(
  [stockAnimationsApiSelector],
  processStockAnimationsPageCount
);

export const stockAnimationsFirstPageIsFetching = state => {
  const isAnimationsMissing = !getPath(
    state,
    "api.stockAnimations.animations.pages[1]"
  );
  const isVideosMissing = !getPath(
    state,
    "api.stockAnimations.videos.pages[1]"
  );

  // both states are missing
  if (isAnimationsMissing && isVideosMissing) {
    return true;
  }

  const isAnimationsFirstPageFetching = getPath(
    state,
    "api.stockAnimations.animations.pages[1].isFetching"
  );
  const isVideosFirstPageFetching = getPath(
    state,
    "api.stockAnimations.videos.pages[1].isFetching"
  );

  return (
    (!isAnimationsMissing && isAnimationsFirstPageFetching) ||
    (!isVideosMissing && isVideosFirstPageFetching)
  );
};
