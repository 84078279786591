import React from "react";
import GridCellCropping from "./GridCellCropping";
import { connect } from "react-redux";
import {
  isCroppingSelector,
  contextSelector,
  selectedItemsSelector,
  zoomSelector,
  designDataSelector,
  elementPreviewsSelector
} from "state/ui/editorContext/editorContextSelectors";
import { getPageOffsetInCanvasbackground } from "views/components/Editor/utils";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import EditorGridOps from "views/components/Editor/editorOps/EditorGridOps";
import { Logger } from "lib";

class GridCellCroppingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.startGridCellCropMoving = this.startGridCellCropMoving.bind(this);
    this.finishGridCellCropMoving = this.finishGridCellCropMoving.bind(this);
  }

  startGridCellCropMoving() {
    Logger.info("GridCellCroppingContainer.startGridCellCropMoving called");

    this.props.updateContext({
      isCropping: true
    });
  }

  finishGridCellCropMoving({ differenceFromInitialOffset }) {
    Logger.info("GridCellCroppingContainer.finishGridCellCropMoving called");
    const {
      zoom,
      selectedItems,
      designData,
      context: { selectedGridCellId, gridCellHtmlElement },
      updateContext
    } = this.props;

    const { gridElement, gridCell } = EditorGridOps.getGridElementWithPreview({
      selectedItems,
      designData,
      selectedGridCellId
    });

    const gridCellIndex = gridElement.imageInstructions.findIndex(
      imageInstruction => imageInstruction.domId === selectedGridCellId
    );

    const updatedContext = EditorGridOps.finishGridCellCropMoving({
      differenceFromInitialOffset,
      zoom,
      selectedItems,
      gridElement,
      gridCell,
      gridCellHtmlElement,
      gridCellIndex
    });

    updateContext(updatedContext);
  }

  render() {
    const {
      context: {
        isCroppingGridCell,
        selectedGridCellId,
        gridHtmlElement,
        gridCellHtmlElement
      },
      designData,
      selectedItems,
      zoom,
      canvasBackground,
      isCropping
    } = this.props;

    if (!canvasBackground || !isCroppingGridCell) {
      return null;
    }

    const { gridElement, gridCell } = EditorGridOps.getGridElementWithPreview({
      selectedGridCellId,
      selectedItems,
      designData
    });

    const pageId = selectedItems[0].pageId;
    const pageOffset = getPageOffsetInCanvasbackground(pageId);

    return (
      <GridCellCropping
        {...this.props}
        gridHtmlElement={gridHtmlElement}
        gridCellHtmlElement={gridCellHtmlElement}
        startGridCellCropMoving={this.startGridCellCropMoving}
        finishGridCellCropMoving={this.finishGridCellCropMoving}
        gridCell={gridCell}
        gridElement={gridElement}
        pageOffset={pageOffset}
        zoom={zoom}
        canvasBackground={canvasBackground}
        isCropping={isCropping}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isCropping: isCroppingSelector(state),
    context: contextSelector(state),
    selectedItems: selectedItemsSelector(state),
    zoom: zoomSelector(state),
    elementPreviews: elementPreviewsSelector(state),
    designData: designDataSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContext: args => dispatch(updateEditorContextState(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GridCellCroppingContainer);
