import React, { Component } from "react";
import CaretIcon from "views/components/icons/CaretIcon";
import Caret2Icon from "views/components/icons/Caret2Icon";
import style from "./style.module.css";
import { isNil, composeClassName, noop } from "lib";
import { preventDefaultAndDoNotPropagate } from "lib/event/event";

class Dropdown2 extends Component {
  static defaultProps = {
    onToggle: noop
  };

  constructor(props) {
    super(props);

    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      optionsOpened: false,
      options: this.props.options || [],
      selected: this.props.selected || null
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.options });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.isConnected
    ) {
      this.setState({ optionsOpened: false });
    }
  }

  onDropdownClick(event) {
    preventDefaultAndDoNotPropagate(event);

    if (this.props.disabled) return;
    this.props.onToggle({ isOpen: !this.state.optionsOpened });
    this.setState({
      optionsOpened: !this.state.optionsOpened
    });
  }

  handleChange(optionKey) {
    this.props.onToggle({ isOpen: !this.state.optionsOpened });
    this.setState({
      optionsOpened: !this.state.optionsOpened
    });
    this.props.onChange(optionKey);
  }

  render() {
    const {
      optionsRenderer,
      placeholder,
      error,
      showErrorMsg,
      label,
      value,
      selected,
      disabled,
      options,
      dropdownHeight,
      className,
      isCustomValue,
      caretColor = style.easilGrayXDark,
      caretVersion,
      caretSize = "8px",
      dropdownClassName,
      wrapperClassName,
      isNewCaret
    } = this.props;
    const { optionsOpened } = this.state;
    // TODO: Make a default KeyValueOptionsRenderer to use if no custom options renderer is provided
    const OptionsRenderer = optionsRenderer;

    return (
      <React.Fragment>
        {label && <div className={style.label}>{label}</div>}
        <div
          ref={this.setWrapperRef}
          className={[style.dropdown, wrapperClassName]
            .filter(x => x)
            .join(" ")}
        >
          <div
            aria-label="dropdown"
            onClick={this.onDropdownClick}
            className={composeClassName("inputDiv", style, className)}
            data-error={!isNil(error)}
            data-disabled={disabled}
            data-testid="Dropdown2"
          >
            {isCustomValue ? (
              value()
            ) : (
              <input placeholder={placeholder} value={value} disabled />
            )}
            {isNewCaret ? (
              <Caret2Icon
                size={"16px"}
                color={caretColor || "#afb3b6"}
                direction="down"
              />
            ) : (
              <CaretIcon
                size={caretSize}
                color={caretColor}
                direction="down"
                version={caretVersion}
              />
            )}
          </div>
          {showErrorMsg && error && (
            <span aria-label="dropdownError" className={style.error}>
              {error}
            </span>
          )}
          <div
            className={`${style.options} ${
              optionsOpened ? style.optionsOpened : null
            } ${dropdownClassName}`}
            data-is-opened={optionsOpened}
            style={{ height: dropdownHeight }}
          >
            <OptionsRenderer
              options={options}
              selected={selected}
              onChange={this.handleChange}
              togglePopout={this.onDropdownClick}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dropdown2;
