import React, { Component } from "react";
import CaretIcon from "views/components/icons/CaretIcon";
import style from "./style.module.css";
import InputTransparent from "views/components/inputTransparent/InputTransparent";
import MagnifierIcon from "views/components/icons/MagnifierIcon";
import { isNil, composeClassName, noop } from "lib";
import { isElementInTargetVisibleArea, getScrollParent } from "lib/elements";
import { Tooltip } from "views/components";
import InfoIcon from "views/components/icons/InfoIcon";
import { TwoPeopleIcon } from "../icons";

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.onDropdownClick = this.onDropdownClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.showSelectedIcon = this.showSelectedIcon.bind(this);
    this.popoutRef = React.createRef();
    this.buttonRef = React.createRef();
    this.searchRef = React.createRef();
    this.state = {
      isOpen: false,
      options: this.props.options || [],
      selected: this.props.selected || null,
      searchText: this.props.searchText || ""
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      onToggle = noop,
      isScrollOnOpen,
      isJustScrollIntoView
    } = this.props;
    if (this.state.isOpen && !prevState.isOpen) {
      onToggle({
        open: this.state.isOpen,
        popoutHeight:
          this.popoutRef && this.popoutRef.current
            ? this.popoutRef.current.offsetHeight
            : 0,
        buttonBottom:
          window.innerHeight -
          (this.buttonRef && this.buttonRef.current
            ? this.buttonRef.current.getBoundingClientRect().bottom
            : 0)
      });
      if (this.props.isSearchable && this.searchRef) {
        this.searchRef.current.querySelector("input").focus();
      }
      // scroll dropdown menu into view when opened
      if (isScrollOnOpen) {
        const dropdownContent = this.wrapperRef.querySelector(
          'div[class^="style_content"]'
        );
        const lastDropdownOption = dropdownContent.querySelector(
          "div:last-of-type"
        );

        let scrollTarget = lastDropdownOption;
        let comparisonTarget = dropdownContent;

        if (isJustScrollIntoView) {
          scrollTarget = dropdownContent;
          comparisonTarget = document.body;
        } else {
          comparisonTarget = getScrollParent(lastDropdownOption);
        }

        if (scrollTarget) {
          const isNeedingScrolling = !isElementInTargetVisibleArea(
            scrollTarget,
            comparisonTarget
          );
          if (isNeedingScrolling) {
            scrollTarget.scrollIntoView({
              block: "end",
              behavior: "smooth"
            });
          }
        }
      }
    }
    if (!this.state.isOpen && prevState.isOpen) {
      onToggle({ open: this.state.isOpen, popoutHeight: 0, buttonBottom: 0 });
      if (this.state.searchText) {
        this.setState({
          searchText: ""
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ options: nextProps.options });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.isConnected
    ) {
      this.setState({ isOpen: false });
    }
  }

  onDropdownClick(event) {
    if (this.props.disabled) return;
    this.setState({ isOpen: !this.state.isOpen });
    const { onClick = noop } = this.props;
    event.preventDefault();
    event.stopPropagation();
    onClick();
  }

  handleChange(option) {
    this.setState({ isOpen: false });

    this.props.onChange(option.key);
  }

  handleSearchTextChange(event) {
    this.setState({
      searchText: event.target.value
    });
  }

  showSelectedIcon() {
    const { selectedFolder, options } = this.props;
    if (!selectedFolder) return false;

    const selectedFolderOption = options.find(folder => {
      return folder.key === selectedFolder.id;
    });

    return selectedFolderOption.isShared;
  }

  render() {
    const {
      icon,
      placeholder,
      error,
      showErrorMsg,
      label,
      selected,
      disabled,
      wrapperClassName,
      inputClassName,
      inputWrapperClassName,
      optionClassName,
      caretColor,
      caretSize = "8px",
      optionsZIndex = 4,
      optionsBorderColor,
      emptyText,
      className,
      popoutClassName,
      inputTitle,
      inputTitleClassName,
      customWrapperStyle,
      isSearchable,
      searchPlaceholder,
      isActive,
      prependOptions,
      prependOptionStyle
    } = this.props;
    const { options, isOpen } = this.state;
    const optionsDynamicStyle = {
      zIndex: optionsZIndex,
      borderColor: optionsBorderColor ? optionsBorderColor : null
    };
    const getSharedOptionTooltip = option => {
      if (option.disabled) {
        return "Cannot move designs into a folder shared from parent team";
      }
      return "Templates added to this folder will be shared with selected Sub-Teams";
    };

    let finalOptions = options;

    if (isSearchable && this.state.searchText) {
      finalOptions = finalOptions
        .map(option => {
          if (Array.isArray(option) && option.length) {
            return option.filter(
              subOption =>
                subOption.key.match(
                  new RegExp(`.*${this.state.searchText}.*`, "i")
                ) ||
                subOption.label.match(
                  new RegExp(`.*${this.state.searchText}.*`, "i")
                )
            );
          } else {
            return option;
          }
        })
        .filter(
          option =>
            Array.isArray(option) ||
            option.key.match(new RegExp(`.*${this.state.searchText}.*`, "i")) ||
            option.label.match(new RegExp(`.*${this.state.searchText}.*`, "i"))
        )
        .filter(option => {
          return !(Array.isArray(option) && !option.length);
        });
    }

    return (
      <React.Fragment>
        {label && <div className={style.label}>{label}</div>}
        <div
          ref={this.setWrapperRef}
          style={customWrapperStyle}
          className={composeClassName(
            "dropdown",
            { dropdown: `${style.dropdown} ${wrapperClassName}` },
            className
          )}
          data-testid="Dropdown"
        >
          <div
            aria-label="dropdown"
            onClick={this.onDropdownClick}
            className={`${style.inputDiv} ${inputWrapperClassName}`}
            data-error={!isNil(error)}
            data-disabled={disabled}
            ref={this.buttonRef}
            style={this.props.customOptionStyle}
            data-is-active={isOpen || isActive}
          >
            {inputTitle && (
              <div className={inputTitleClassName}>{inputTitle}</div>
            )}
            {this.showSelectedIcon() && (
              <div className={style.icon}>
                <TwoPeopleIcon />
              </div>
            )}
            <input
              placeholder={placeholder}
              value={selected || ""}
              className={inputClassName}
              disabled
            />
            <CaretIcon
              size={caretSize}
              color={caretColor || style.easilGrayXDark}
              direction="down"
            />
          </div>
          {showErrorMsg && error && (
            <span
              aria-label="dropdownError"
              data-testid="dropdownError"
              className={style.error}
            >
              {error}
            </span>
          )}
          {isOpen && (
            <div
              className={composeClassName(
                "content",
                { content: `${style.content} ${popoutClassName}` },
                className
              )}
              style={optionsDynamicStyle}
              ref={this.popoutRef}
              data-testid="dropdownContent"
            >
              {isSearchable ? (
                <div className={style.optionList}>
                  <div className={style.searchWrapper} ref={this.searchRef}>
                    <MagnifierIcon color="#d1d4d6" />
                    <InputTransparent
                      value={this.state.searchText}
                      onChange={this.handleSearchTextChange}
                      className={style.input}
                      placeholder={searchPlaceholder || ""}
                    />
                  </div>
                </div>
              ) : null}
              {finalOptions.length === 0 && emptyText && (
                <span>{emptyText}</span>
              )}
              {prependOptions && prependOptions.length > 0 && (
                <>
                  {prependOptions.map(prependOption => {
                    return (
                      <div
                        className={`${style.prependOption} ${prependOptionStyle}`}
                        data-is-selected={prependOption.label === selected}
                        disabled={prependOption.disabled}
                        key={prependOption.key}
                        onClick={event => {
                          if (
                            prependOption.disabled &&
                            event.target.tagName !== "A" &&
                            event.target.tagName !== "a"
                          ) {
                            event.preventDefault();
                          }
                          if (prependOption.disabled) {
                            return;
                          }
                          this.handleChange(prependOption);
                        }}
                      >
                        {prependOption.label}
                      </div>
                    );
                  })}
                  <div className={style.prependDivider}></div>
                </>
              )}
              {finalOptions.map(option => {
                if (Array.isArray(option)) {
                  return (
                    <div className={style.optionList}>
                      {option.map(subOption => (
                        <div
                          className={`${style.option} ${optionClassName}`}
                          data-is-selected={subOption.label === selected}
                          disabled={subOption.disabled}
                          key={subOption.key}
                          onClick={event => {
                            if (
                              subOption.disabled &&
                              event.target.tagName !== "A" &&
                              event.target.tagName !== "a"
                            ) {
                              event.preventDefault();
                            }
                            if (subOption.disabled) {
                              return;
                            }
                            this.handleChange(subOption);
                          }}
                        >
                          {subOption.icon && (
                            <div className={style.icon}>{icon}</div>
                          )}
                          {subOption.label}
                        </div>
                      ))}
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={composeClassName(
                        "option",
                        { option: `${style.option}` },
                        optionClassName
                      )}
                      data-is-selected={option.label === selected}
                      disabled={option.disabled}
                      key={option.key}
                      onClick={event => {
                        if (
                          option.disabled &&
                          event.target.tagName !== "A" &&
                          event.target.tagName !== "a"
                        ) {
                          event.preventDefault();
                        }
                        if (option.disabled) {
                          return;
                        }
                        this.handleChange(option);
                      }}
                    >
                      {option.icon ? (
                        <>
                          <div className={style.optionWrapper}>
                            <div className={style.icon}>{option.icon}</div>
                            {option.label}
                          </div>
                          {option.isShared && (
                            <div
                              data-tip={getSharedOptionTooltip(option)}
                              data-for="infoTip"
                              className={style.infoIcon}
                            >
                              <InfoIcon size="12px" />
                              <Tooltip
                                id="infoTip"
                                place="bottom"
                                multiline
                                className={style.shareToolTip}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <>{option.label}</>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Dropdown;
