import PATHS from "routes/paths";

import { userIsDesigner } from "state/currentUser/currentUserSelectors";

import { teamAllowEasilTemplatesSettings } from "state/entities/teamSettings/teamSettingsSelectors";

export const getPathToCatalogueBasedOnTeamSettings = state => {
  let moveToPath = PATHS.catalogueTemplates;

  if (!state) return moveToPath;

  const isDesigner = userIsDesigner(state);

  const allowEasilTemplates = teamAllowEasilTemplatesSettings(state);

  if (allowEasilTemplates || isDesigner) {
    moveToPath = PATHS.catalogueEasil;
  }
  return moveToPath;
};
