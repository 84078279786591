import { createSelector } from "reselect";
import { userTeamImagesApiSelector } from "state/api/userTeamImages/userTeamImagesApiSelector";
import { userTeamImagesEntitiesSelector } from "state/entities/userTeamImages/userTeamImagesSelectors";
import { userTeamAnimationsApiSelector } from "state/api/userTeamAnimations/userTeamAnimationsApiSelector";
import { userTeamAnimationsEntitiesSelector } from "state/entities/userTeamAnimations/userTeamAnimationsSelectors";
import { teamAnimationsApiSelector } from "state/api/teamAnimations/teamAnimationsApiSelectors";
import { teamAnimationsEntitiesSelector } from "state/entities/teamAnimations/teamAnimationsSelectors";
import { stockAnimationsApiSelector } from "state/api/stockAnimations/stockAnimationsApiSelector";
import { stockAnimationsEntitiesSelector } from "state/entities/stockAnimations/stockAnimationsSelectors";
import { teamImagesSelector as teamImagesEntitiesSelector } from "state/entities/teamImages/teamImagesSelectors";
import { userIsBrandManager } from "state/currentUser/currentUserSelectors";
import { getPath } from "lib/lodash";

export const getEditorState = state => state.ui.editor.present.editorState;

export const getCurrentDesignData = state => {
  return state.ui.editor.present.designData;
};

const teamImagesApiSelector = state => state.api.teamImages;

const teamLogosApiSelector = state => state.api.teamLogos;

export const userTeamFavoritesEntitiesSelector = state =>
  state.entities.userTeamFavorites;

const teamFavoritesEntitiesSelector = state => state.entities.teamFavorites;

const teamLogosEntitiesSelector = state => state.entities.teamLogos;

/**
 * Retrieve each entity for each id in the provided API state pages.
 * @param {object} apiState - Api state to process
 * @param {object} entityState - Entity state containing the full object for each id.
 * @param {*[]} favoritesIds - Array of media ids the user has favourited.
 * @param {boolean} canBeDeleted - whether the user can delete this type of entity.
 * @returns {*[]} - An array of entity objects.
 */
const processApiState = (apiState, entityState, favoritesIds, canBeDeleted) => {
  let images = [];

  if (apiState && apiState.pages) {
    const pagesOrder = Object.keys(apiState.pages).sort();
    pagesOrder.forEach(page => {
      const ids = apiState.pages[page].ids || [];

      ids.forEach(imageId => {
        const entity = entityState[imageId];

        if (!entity) return;

        const entityWithFavorite = {
          ...entity,
          isFavorited: favoritesIds.includes(entity.id || entity.mediaId),
          canBeDeleted
        };

        images.push(entityWithFavorite);
      });
    });
  }
  return images;
};

/* TODO: REFACTOR */
const processGetSidebarImages = (
  userTeamImagesApi,
  teamImagesApi,
  teamLogosApi,
  userTeamFavorites = [],
  userTeamImagesEntities,
  teamImagesEntities,
  isUserBrandManager,
  teamLogosEntities
) => {
  const favoritesIds = Object.keys(userTeamFavorites);

  const userImages = processApiState(
    userTeamImagesApi,
    userTeamImagesEntities,
    favoritesIds,
    true
  );
  const teamImages = processApiState(
    teamImagesApi,
    teamImagesEntities,
    favoritesIds,
    isUserBrandManager
  );
  const teamLogos = processApiState(
    teamLogosApi,
    teamLogosEntities,
    favoritesIds,
    isUserBrandManager
  );

  return {
    userTeamImages: userImages,
    teamImages: teamImages,
    teamLogos: teamLogos
  };
};

export const getSidebarImages = createSelector(
  [
    userTeamImagesApiSelector,
    teamImagesApiSelector,
    teamLogosApiSelector,
    userTeamFavoritesEntitiesSelector,
    userTeamImagesEntitiesSelector,
    teamImagesEntitiesSelector,
    userIsBrandManager,
    teamLogosEntitiesSelector
  ],
  processGetSidebarImages
);

export const processGetSidebarAnimations = (
  userTeamAnimationsApi,
  userTeamAnimationsEntities,
  userTeamFavorites
) => {
  const favoritesIds = Object.keys(userTeamFavorites);

  const userAnimations = [];
  const userVideos = [];

  if (userTeamAnimationsApi.animations.pages) {
    const pagesOrder = Object.keys(
      userTeamAnimationsApi.animations.pages
    ).sort();

    pagesOrder.forEach(page => {
      const ids =
        [...new Set(userTeamAnimationsApi.animations.pages[page].ids)] || [];

      ids.forEach(userTeamAnimationId => {
        const entity = userTeamAnimationsEntities[userTeamAnimationId];

        if (!entity) return;

        const entityWithFavorite = {
          ...entity,
          isFavorited: favoritesIds.includes(entity.id || entity.mediaId),
          canBeDeleted: true
        };

        userAnimations.push(entityWithFavorite);
      });
    });
  }

  if (userTeamAnimationsApi.videos.pages) {
    const pagesOrder = Object.keys(userTeamAnimationsApi.videos.pages).sort();

    pagesOrder.forEach(page => {
      const ids =
        [...new Set(userTeamAnimationsApi.videos.pages[page].ids)] || [];

      ids.forEach(userTeamAnimationId => {
        const entity = userTeamAnimationsEntities[userTeamAnimationId];

        if (!entity) return;

        const entityWithFavorite = {
          ...entity,
          isFavorited: favoritesIds.includes(entity.id || entity.mediaId),
          canBeDeleted: true
        };

        userVideos.push(entityWithFavorite);
      });
    });
  }

  return {
    userTeamAnimations: {
      animations: userAnimations,
      videos: userVideos
    }
  };
};

export const getSidebarAnimations = createSelector(
  [
    userTeamAnimationsApiSelector,
    userTeamAnimationsEntitiesSelector,
    userTeamFavoritesEntitiesSelector
  ],
  processGetSidebarAnimations
);

export const processGetSidebarTeamAnimations = (
  teamAnimationsApi,
  teamAnimationsEntities,
  teamFavorites
) => {
  const favoritesIds = teamFavorites ? Object.keys(teamFavorites) : [];

  const userAnimations = [];
  const userVideos = [];

  if (teamAnimationsApi.animations.pages) {
    const pagesOrder = Object.keys(teamAnimationsApi.animations.pages).sort();

    pagesOrder.forEach(page => {
      const ids = teamAnimationsApi.animations.pages[page].ids || [];
      const uniqueIds = [...new Set(ids)];

      uniqueIds.forEach(userTeamAnimationId => {
        const entity = teamAnimationsEntities[userTeamAnimationId];

        if (!entity) return;

        const entityWithFavorite = {
          ...entity,
          isFavorited: favoritesIds.includes(entity.id || entity.mediaId),
          canBeDeleted: true
        };

        userAnimations.push(entityWithFavorite);
      });
    });
  }

  if (teamAnimationsApi.videos.pages) {
    const pagesOrder = Object.keys(teamAnimationsApi.videos.pages).sort();

    pagesOrder.forEach(page => {
      const ids = teamAnimationsApi.videos.pages[page].ids || [];
      const uniqueIds = [...new Set(ids)];

      uniqueIds.forEach(userTeamAnimationId => {
        const entity = teamAnimationsEntities[userTeamAnimationId];

        if (!entity) return;

        const entityWithFavorite = {
          ...entity,
          isFavorited: favoritesIds.includes(entity.id || entity.mediaId),
          canBeDeleted: true
        };

        userVideos.push(entityWithFavorite);
      });
    });
  }

  return {
    animations: userAnimations,
    videos: userVideos
  };
};

export const getSidebarTeamAnimations = createSelector(
  [
    teamAnimationsApiSelector,
    teamAnimationsEntitiesSelector,
    teamFavoritesEntitiesSelector
  ],
  processGetSidebarTeamAnimations
);

export const processGetSidebarStockAnimations = (
  stockAnimationsApi,
  stockAnimationsEntities
) => {
  const stockAnimations = [];
  const stockVideos = [];
  const stockAnimationsSearch = {};
  const stockAnimationsAll = [];

  const stockAnimationsTerms = Object.keys(stockAnimationsApi.search || {});

  if (stockAnimationsTerms && stockAnimationsTerms.length) {
    stockAnimationsTerms.forEach(term => {
      stockAnimations[term] = [];

      const stockAnimationsAnimationsApiForTerm =
        stockAnimationsApi.search[term];

      const pagesOrder = Object.keys(
        stockAnimationsAnimationsApiForTerm.pages
      ).sort();

      pagesOrder.forEach(page => {
        const ids = stockAnimationsAnimationsApiForTerm.pages[page].ids || [];

        ids.forEach(stockAnimationId => {
          const entity = stockAnimationsEntities[stockAnimationId];

          if (!entity) return;

          const formattedEntity = {
            ...entity,
            canBeDeleted: true
          };

          stockAnimationsSearch[term].push(formattedEntity);
        });
      });
    });
  }

  if (stockAnimationsApi.animations.pages) {
    const pagesOrder = Object.keys(stockAnimationsApi.animations.pages).sort();

    pagesOrder.forEach(page => {
      const ids = stockAnimationsApi.animations.pages[page].ids || [];

      ids.forEach(stockAnimationId => {
        const entity = stockAnimationsEntities[stockAnimationId];

        if (!entity) return;

        const formattedEntity = {
          ...entity,
          canBeDeleted: true
        };

        stockAnimations.push(formattedEntity);
      });
    });
  }

  if (stockAnimationsApi.videos.pages) {
    const pagesOrder = Object.keys(stockAnimationsApi.videos.pages).sort();

    pagesOrder.forEach(page => {
      const ids = stockAnimationsApi.videos.pages[page].ids || [];

      ids.forEach(stockAnimationId => {
        const entity = stockAnimationsEntities[stockAnimationId];

        if (!entity) return;

        const formattedEntity = {
          ...entity,
          canBeDeleted: true
        };

        stockVideos.push(formattedEntity);
      });
    });
  }

  if (stockAnimationsApi.all.pages) {
    const pagesOrder = Object.keys(stockAnimationsApi.all.pages).sort();

    pagesOrder.forEach(page => {
      const ids = stockAnimationsApi.all.pages[page].ids || [];

      ids.forEach(stockAnimationId => {
        const entity = stockAnimationsEntities[stockAnimationId];

        if (!entity) return;

        const formattedEntity = {
          ...entity,
          canBeDeleted: true
        };

        stockAnimationsAll.push(formattedEntity);
      });
    });
  }

  return {
    animations: stockAnimations,
    videos: stockVideos,
    search: stockAnimationsSearch,
    all: stockAnimationsAll
  };
};

export const getSidebarStockAnimations = createSelector(
  [stockAnimationsApiSelector, stockAnimationsEntitiesSelector],
  processGetSidebarStockAnimations
);

/* Sidebar images are considered in fetching state if any user or brand images are still fetching */
export const isFetchingInitialSidebarImages = state => {
  const {
    userTeamImages: userTeamImagesApi,
    teamImages: teamImagesApi,
    teamLogos: teamLogosApi
  } = state.api;

  const isFetchingTeamImages =
    teamImagesApi.pages &&
    teamImagesApi.pages[1] &&
    teamImagesApi.pages[1].isFetching &&
    !teamImagesApi.pages[1].ids;
  const isFetchingUserImages =
    userTeamImagesApi.pages &&
    userTeamImagesApi.pages[1] &&
    userTeamImagesApi.pages[1].isFetching &&
    !userTeamImagesApi.pages[1].ids;
  const isFetchingTeamLogos =
    teamLogosApi.pages &&
    teamLogosApi.pages[1] &&
    teamLogosApi.pages[1].isFetching &&
    !teamLogosApi.pages[1].ids;

  return isFetchingTeamImages || isFetchingUserImages || isFetchingTeamLogos;
};

export const getIsFetchingForAnimationState = (
  animationState,
  assetTypes = ["animations", "videos"]
) =>
  assetTypes.reduce(
    (isPreviousAssetTypeFetching, currentAssetType) =>
      isPreviousAssetTypeFetching ||
      (getPath(
        animationState,
        `${currentAssetType}.pages.1.isFetching`,
        false
      ) &&
        !getPath(animationState, `${currentAssetType}.pages.1.ids`, false)),
    false
  );

/* Sidebar animations are considered in fetching state if any user or brand animations are still fetching */
export const isFetchingInitialSidebarAnimations = state => {
  const {
    userTeamAnimations: userTeamAnimationsApi,
    teamAnimations: teamAnimationsApi,
    stockAnimations: stockAnimationsApi
  } = state.api;

  const isFetchingTeamAnimations = getIsFetchingForAnimationState(
    userTeamAnimationsApi
  );

  const isFetchingUserAnimations = getIsFetchingForAnimationState(
    teamAnimationsApi
  );

  const isFetchingStockAnimations = getIsFetchingForAnimationState(
    stockAnimationsApi
  );

  return (
    isFetchingTeamAnimations ||
    isFetchingUserAnimations ||
    isFetchingStockAnimations
  );
};
