class Borders {
  static BORDER_DEFAULT_COLOR = "#E5E8EB";
  static DEFAULT_PROPS = {
    borderBottom: { color: Borders.BORDER_DEFAULT_COLOR },
    borderTop: { color: Borders.BORDER_DEFAULT_COLOR },
    borderRight: { color: Borders.BORDER_DEFAULT_COLOR },
    borderLeft: { color: Borders.BORDER_DEFAULT_COLOR }
  };

  static BORDER_SIZE = "2px";
  static BORDER_POSITIONS = [
    "borderBottom",
    "borderTop",
    "borderRight",
    "borderLeft"
  ];

  constructor(
    { borderBottom, borderTop, borderRight, borderLeft } = Borders.DEFAULT_PROPS
  ) {
    this.borderBottom = borderBottom;
    this.borderTop = borderTop;
    this.borderRight = borderRight;
    this.borderLeft = borderLeft;
  }

  removeAllBorders() {
    return new this.constructor({
      borderBottom: { color: null },
      borderTop: { color: null },
      borderRight: { color: null },
      borderLeft: { color: null }
    });
  }

  toggleBorder(borderPosition) {
    const hasBorderPosition = this.hasBorderPosition(borderPosition);

    const newColor = hasBorderPosition ? null : this.getBordersColor();

    const borderPositionUpdated = {
      color: newColor
    };

    return new this.constructor({
      ...this,
      [borderPosition]: borderPositionUpdated
    });
  }

  hasBorderPosition(position) {
    return Boolean(this[position].color);
  }

  hasNoBorder() {
    const hasNoBorder = Borders.BORDER_POSITIONS.every(
      borderPosition => !this.hasBorderPosition(borderPosition)
    );

    return hasNoBorder;
  }

  changeBordersColor(color) {
    const bordersPositionsColorUpdated = {};

    if (this.hasNoBorder()) {
      return new this.constructor(
        Borders.BORDER_POSITIONS.reduce(
          (map, borderPosition) => ({ ...map, [borderPosition]: { color } }),
          {}
        )
      );
    }

    Borders.BORDER_POSITIONS.forEach(borderPosition => {
      const hasBorderPosition = this.hasBorderPosition(borderPosition);

      const newColor = hasBorderPosition ? color : null;

      bordersPositionsColorUpdated[borderPosition] = {
        ...this[borderPosition],
        color: newColor
      };
    });

    return new this.constructor({
      ...bordersPositionsColorUpdated
    });
  }

  getBordersColor() {
    if (this.hasNoBorder()) {
      return Borders.BORDER_DEFAULT_COLOR;
    }

    const firstBorderWithColor = Borders.BORDER_POSITIONS.find(
      borderPosition => this[borderPosition].color !== null
    );

    return this[firstBorderWithColor].color;
  }

  getAsCss(element, backgroundColor, isSelected) {
    const { borderBottom, borderTop, borderLeft, borderRight } = this;

    const getBorderPositionCss = borderPosition => {
      if (element && element.type === "table2") {
        if (!borderPosition.color && !isSelected)
          return `${Borders.BORDER_SIZE} solid ${backgroundColor}`;

        if (isSelected && !borderPosition.color) {
          return `${Borders.BORDER_SIZE} solid ${Borders.BORDER_DEFAULT_COLOR}`;
        }

        return `${Borders.BORDER_SIZE} solid ${borderPosition.color}`;
      }

      if (!borderPosition.color) return "unset";

      return `${Borders.BORDER_SIZE} solid ${borderPosition.color}`;
    };

    return {
      borderBottom: getBorderPositionCss(borderBottom),
      borderTop: getBorderPositionCss(borderTop),
      borderLeft: getBorderPositionCss(borderLeft),
      borderRight: getBorderPositionCss(borderRight)
    };
  }
}

export default Borders;
