import * as types from "./subscriptionPlansTypes";
import { merge } from "lib";

export const initState = {};

const subscriptionPlansReducers = (state = initState, action) => {
  switch (action.type) {
    case types.FETCH_SUBSCRIPTION_PLANS_SUCCESS: {
      const {
        entities: { subscriptionPlan: subscriptionPlans }
      } = action.response;

      return merge({}, state, subscriptionPlans);
    }

    default:
      return state;
  }
};

export default subscriptionPlansReducers;
