import ResizeIcon from "views/components/icons/ResizeIcon";
import LayoutsIcon from "views/components/icons/LayoutsIcon";
import TIcon from "views/components/icons/TIcon";
import ShapesSquareIcon from "views/components/icons/ShapesSquareIcon";
import FavoriteFolderIcon from "views/components/icons/FavoriteFolderIcon";
import LayersIcon from "views/components/icons/LayersIcon";
import PadlockCurvedIcon from "views/components/icons/PadlockCurvedIcon";
import ImageJaggedMountainIcon from "views/components/icons/ImageJaggedMountainIcon";
import VideoIcon from "views/components/icons/VideoIcon";

export const DEFAULT_SHADOW = {
  color: "#000000",
  blur: 10,
  distance: 10,
  angle: 135
};

export const DEFAULT_OUTLINE = {
  color: "#000000",
  width: 10,
  knockout: false,
  fill: true,
  fade: 0,
  smoothness: 0,
  offset: 0,
  angle: 0
};

export const DESIGNS_SIZES_NAMES = {
  popularSizes: "Popular Sizes",
  socialMedia: "Social Media",
  largeFormat: "Large Format",
  digitalScreens: "Digital Screens",
  Adverts: "Adverts",
  documents: "Documents",
  custom: "Custom",
  tutorials: "Tutorials"
};

export const DOUBLE_SIDED_TEMPLATE_CODES = [
  "businesscardl",
  "businesscardp",
  "businesscard192336",
  "businesscard336192",
  "postcard_landscape",
  "postcard_portrait",
  "a6_greeting_card_landscape",
  "a6_greeting_card_portrait",
  "greeting_card_portrait",
  "greeting_card_landscape"
];

export const TEMPLATE_SIZES_ORDER = {
  "Social Media": [
    "instagram_stories",
    "instagram1080",
    "instagram_portrait",
    "facebook_post",
    "facebook_advert",
    "facebook_cover_2021",
    "facebook_cover",
    "facebook_event_cover",
    "pinterest_short_2",
    "pinterest_short",
    "linkedin_cover_company",
    "youtube_thumbnail",
    "youtube_channel_art",
    "twitter_image",
    "twitter_header",
    "pinterest_tall"
  ],
  "Large Format": [
    "poster457610",
    "poster",
    "banner2438914",
    "banner3001250_2"
  ],
  "Digital Screens": ["landscape_tv_full_hd", "landscape_nightlife_hd"],
  Adverts: [
    "large_rectangle_336",
    "leaderboard_728",
    "medium_rectangle_300",
    "wide_skyscraper",
    "instagram_stories",
    "instagram1080"
  ],
  Documents: [
    "us_letter",
    "businesscard336192",
    "businesscard192336",
    "a4_portrait",
    "presentation_16_9",
    "businesscardl",
    "businesscardp",
    "postcard_landscape",
    "postcard_portrait",
    "us_letter_landscape",
    "a3_landscape",
    "a4_landscape",
    "a5_landscape",
    "dl_landscape_flyer",
    "dl_portrait_flyer",
    "a6_postcard_landscape",
    "a6_postcard_portrait",
    "rack_card",
    "us_half_letter",
    "a5_portrait",
    "ebooks_covers",
    "poster457610",
    "poster",
    "a6_greeting_card_landscape",
    "a6_greeting_card_portrait",
    "greeting_card_portrait",
    "greeting_card_landscape"
  ],
  Custom: ["custom"]
};

export const RESIZE_CATEGORIES_ORDER = [
  "Documents",
  "Large Format",
  "Social Media",
  "Adverts",
  "Digital Screens",
  "Custom"
];

export const IMAGE_ORIGINS = {
  userTeamImage: "userTeamImage",
  userTeamAnimation: "userTeamAnimation",
  stockAnimation: "stockAnimation",
  teamImage: "teamImage",
  teamAnimation: "teamAnimation",
  teamLogo: "teamLogo",
  stock: "stock"
};

export const ORG_DEPTH_INDEX = 1;
export const TEAM_DEPTH_INDEX_LIMIT = 3;

export const ONE_DAY_IN_MINUTES = 1440;

export const MANDATORY_BILLING_FIELDS = [
  "name",
  "street",
  "suburb",
  "country",
  "state",
  "postcode"
];

export const MANDATORY_SHIPPING_FIELDS = [
  "name",
  "street",
  "suburb",
  "country",
  "state",
  "postcode"
];

export const ADDRESS_FIELDS = [
  "street",
  "suburb",
  "country",
  "state",
  "postcode"
];

export const SHIPPING_COUNTRY_CODES = ["AU", "US"];

export const TEXTBOX_COLLECTION_PROPAGATION_ACTIONS = [
  "fontFamily",
  "textDecoration"
];

export const VIDEO_COLLECTION_PROPAGATION_ACTIONS = [];

export const IMAGE_COLLECTION_PROPAGATION_ACTIONS = [];

export const ANIMATED_DESIGN_PAGE_DEFAULT_DURATION = 5000;

export const BROWSER_NAMES = {
  OPERA: "Opera",
  OPERA2: "OPR",
  CHROME: "Chrome",
  SAFARI: "Safari",
  FIREFOX: "Firefox",
  IE: "MSIE",
  IE_LABEL: "IE" // IE label
};

export const FONT_SIZE_OPTIONS = [
  6,
  8,
  10,
  12,
  14,
  16,
  18,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  110,
  120,
  130,
  140,
  150,
  160,
  170,
  180,
  190,
  200,
  210,
  220,
  230,
  240,
  250,
  260,
  270,
  280,
  290,
  300
];

// the media source types supported by html5 video tag
export const VIDEO_MEDIA_TYPES = ["mp4", "webm", "ogg"];

export const FILL_VALUE_REGEX = "fill:(.*?)(?=;|z)";

export const SVG_FILL_SELECTOR = '*[fill], *[style*="fill:"]';

export const DESIGN_STATUS_TYPES = {
  MASTER: "MASTER",
  DRAFT: "DRAFT"
};

export const DESIGN_TYPES = {
  DRAFT: "draft",
  TEMPLATE: "template"
};

export const fileDownloadFormats = {
  JPG: "JPG",
  GIF: "GIF",
  PDF: "PDF",
  PNG: "PNG",
  MP4: "MP4"
};

export const exportableFileFormats = [
  fileDownloadFormats.JPG,
  fileDownloadFormats.PNG,
  fileDownloadFormats.GIF,
  fileDownloadFormats.MP4
];

export const elementAlignmentValues = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
  TOP: "top",
  MIDDLE: "middle",
  BOTTOM: "bottom"
};

export const ACTION_BAR_BUTTON_NAMES_MAP = {
  GRID_CELL: "gridCell",
  PHOTO_IN_FRAME: "photoInFrame",
  TABLE_TEXT_FIELD_CELL: "tableTextFieldCell",
  TEXT_MASK: "textMask",
  TABLE2_CELL: "table2Cell",
  TABLE2_TEXTFIELD: "table2Textfield"
};

export const ANIMATION_ASSET_TYPE_MAP = {
  ANIMATION: "ANIMATION",
  VIDEO: "VIDEO"
};

export const EDITOR_ELEMENTS_MAP = {
  GRID: "grid",
  IMAGE: "image",
  VIDEO: "video",
  VECTOR: "vector",
  TEXTBOX: "textbox",
  TABLE: "table",
  TABLE2: "table2",
  VECTOR_TEXT: "vectortext",
  QR_CODE: "qrcode",
  BACKGROUND: "background",
  GROUP: "group"
};

export const EDITOR_ELEMENT_TYPES = Object.values(EDITOR_ELEMENTS_MAP);

export const EDITOR_IMAGE_ELEMENT_TYPES = [
  EDITOR_ELEMENTS_MAP.GRID,
  EDITOR_ELEMENTS_MAP.IMAGE,
  EDITOR_ELEMENTS_MAP.VIDEO,
  EDITOR_ELEMENTS_MAP.VECTOR,
  EDITOR_ELEMENTS_MAP.TEXTBOX,
  EDITOR_ELEMENTS_MAP.BACKGROUND
];

export const LINKABLE_EDITOR_ELEMENT_TYPES = [
  EDITOR_ELEMENTS_MAP.IMAGE,
  EDITOR_ELEMENTS_MAP.VECTOR,
  EDITOR_ELEMENTS_MAP.VIDEO,
  EDITOR_ELEMENTS_MAP.BACKGROUND,
  EDITOR_ELEMENTS_MAP.TEXTBOX
];

export const DEFAULT_MASK = {
  top: 0,
  left: 0
};

export const ANIMATION_VIDEO_MAX_DURATION = 60000; // 60 seconds

export const IMAGE_LIST_FOLDER_TYPES = {
  BRAND_ANIMATION: "BRAND_ANIMATION",
  ANIMATION: "ANIMATION",
  IMAGE: "IMAGE"
};

export const MEDIA_SORT_OPTIONS = {
  NAME_ASC: {
    label: "Name (A-Z)",
    key: "NAME_ASC",
    sortKey: "name",
    direction: "ASC"
  },
  NAME_DESC: {
    label: "Name (Z-A)",
    key: "NAME_DESC",
    sortKey: "name",
    direction: "DESC"
  },
  UPDATEDAT_DESC: {
    label: "Newest",
    key: "UPDATEDAT_DESC",
    sortKey: "updatedAt",
    direction: "DESC"
  },
  UPDATEDAT_ASC: {
    label: "Oldest",
    key: "UPDATEDAT_ASC",
    sortKey: "updatedAt",
    direction: "ASC"
  }
};

export const STATIC_ASSET_FILE_TYPES = ["png", "jpg", "jpeg", "svg"];
export const ANIMATED_ASSET_FILE_TYPES = ["mp4", "gif"];
export const BASIC_SUBSCRIPTION_ANIMATED_ASSET_FILE_TYPES = ["gif"];
export const DEFAULT_ACCEPTED_STATIC_FILE_TYPES = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/svg",
  "image/svg+xml"
];
export const DEFAULT_ACCEPTED_ANIMATED_FILE_TYPES = ["image/gif", "video/mp4"];

export const DEFAULT_MAX_DURATION = 60000;

export const SIDEBAR_IMAGE_ASSET_TYPES = [
  "SIDEBAR_IMAGE",
  "SIDEBAR_ASSET_IMAGE",
  "SIDEBAR_VIDEO"
];

export const SIDEBAR_TABS_MAP = {
  ANIMATIONS: "animations",
  ASSETS: "assets",
  DOWNLOAD: "download",
  IMAGES: "images",
  LAYERS: "layers",
  LAYOUTS: "layouts",
  ORDER_CHECK: "orderCheck",
  RESIZE: "resize",
  RESTRICTIONS: "restrictions",
  SHAPES: "shapes",
  TEXT: "text"
};

export const CHANGE_PASSWORD_INPUT_FIELDS = {
  NEW_PASSWORD: "newPasswordInput",
  CONFIRM_NEW_PASSWORD: "confirmNewPasswordInput",
  CURRENT_PASSWORD: "currentPasswordInput"
};

export const ANIMATED_EXPORT_TYPES = ["MP4", "GIF"];

export const DEFAULT_VECTOR_MASK = {
  top: 0,
  left: 0
};

export const TEAM_ROLES = [
  {
    key: "ADMIN",
    label: "Admin",
    content: "Add, remove and manage team members"
  },
  {
    key: "BRAND_MANAGER",
    label: "Brand Manager",
    content: "Approve designs, import and manage brand assets"
  },
  {
    key: "DESIGNER",
    label: "Designer",
    content: "Create, edit and promote design templates"
  },
  {
    key: "MEMBER",
    label: "Member",
    content: "Amend editable areas in design templates"
  }
];

export const DEFAULT_RULER_UNIT_BREAKDOWN = 10;

export const ELEMENT_DPI_MINIMUM = 150;

export const TEXT_PT_MINIMUM = 8;

// list of properties that should be persisted when an image instruction is updated
export const INHERITED_IMAGE_INSTRUCTION_PROPERTIES = [
  "filter",
  "filters",
  "opacity",
  "scaleX",
  "scaleY"
];

export const DEFAULT_PARAGRAPH_SPACING = 100;
export const DEFAULT_PARAGRAPH_SPACING_DISPLAY = 1.4;

export const MINIMUM_CROP_RESIZE_HANDLE_PROXIMITY = 8;
export const MINIMUM_CROP_EUCLIDEAN_DISTANCE = 11;

export const PORTAL_EVENTS = {
  ORDER_CHECK: "navigator.editor.orderCheck",
  EDIT_DESIGN_DESCRIPTION: "portal.design.editDescription",
  REQUEST_APPROVAL: "navigator.editor.requestApproval",
  ACTION_APPROVAL: "navigator.editor.actionApproval",
  CANCEL_APPROVAL: "navigator.editor.cancelApprovalRequest",
  OPEN_DOWNLOAD: "navigator.downloadShow",
  CLOSE_DOWNLOAD: "navigator.downloadHide",
  PREVENT_PDF_DOWNLOAD: "preventPDFDownload",
  CLIPBOARD_CONTENTS: "portal.design.clipboardContent"
};

export const DEFAULT_FILTER_VALUE = "64646464646464064";

export const EDITOR_SIDEBAR_TABS = [
  {
    name: SIDEBAR_TABS_MAP.RESIZE,
    icon: ResizeIcon,
    title: "Sizes"
  },
  {
    name: SIDEBAR_TABS_MAP.LAYOUTS,
    icon: LayoutsIcon,
    title: "Layouts",
    shouldDisabled: (documentRestrictions, areFunctionalitiesLocked) =>
      areFunctionalitiesLocked || documentRestrictions["layoutsPanel"]
  },
  {
    name: SIDEBAR_TABS_MAP.TEXT,
    icon: TIcon,
    title: "Text",
    shouldDisabled: (documentRestrictions, areFunctionalitiesLocked) =>
      areFunctionalitiesLocked || documentRestrictions["addTextElement"]
  },
  {
    name: SIDEBAR_TABS_MAP.IMAGES,
    icon: ImageJaggedMountainIcon,
    title: "Images",
    shouldDisabled: (documentRestrictions, areFunctionalitiesLocked) =>
      areFunctionalitiesLocked
  },
  {
    name: SIDEBAR_TABS_MAP.ANIMATIONS,
    icon: VideoIcon,
    title: "Video",
    shouldDisabled: (documentRestrictions, areFunctionalitiesLocked) =>
      areFunctionalitiesLocked
  },
  {
    name: SIDEBAR_TABS_MAP.SHAPES,
    icon: ShapesSquareIcon,
    title: "Elements",
    shouldDisabled: (documentRestrictions, areFunctionalitiesLocked) =>
      areFunctionalitiesLocked || documentRestrictions["addGraphicElement"]
  },
  {
    name: SIDEBAR_TABS_MAP.ASSETS,
    icon: FavoriteFolderIcon,
    title: "Assets",

    shouldDisabled: (documentRestrictions, areFunctionalitiesLocked) => {
      return (
        areFunctionalitiesLocked ||
        ["showTemplatePanel", "showFavoritesPanel"].every(
          restriction => documentRestrictions[restriction]
        )
      );
    }
  },
  {
    name: SIDEBAR_TABS_MAP.RESTRICTIONS,
    icon: PadlockCurvedIcon,
    title: "Locking",
    shouldDisabled: (_, areFunctionalitiesLocked) => areFunctionalitiesLocked
  },
  {
    name: SIDEBAR_TABS_MAP.LAYERS,
    icon: LayersIcon,
    title: "Layers",
    shouldDisabled: (_, areFunctionalitiesLocked) => areFunctionalitiesLocked
  }
];

export const SMART_INPUT_FIELDS = {
  USER_NAME: "user.name",
  USER_EMAIL: "user.email",
  TEAM_NAME: "team.name"
};

export const SMART_TEXT_AUTOCOMPLETE_REGEX = /({[(a-zA-Z0-9_.)]+)(?![^{}]*})/;

export const SMART_FIELD_REGEX = /\{([^}]+)\}/g;

export const TABLE_2_ACTIONS = {
  ADD_COLUMN: "addColumn",
  // ADD_COLUMNS: "addColumns",
  DUPLICATE_COLUMNS: "duplicateColumns",
  DELETE_COLUMNS: "deleteColumns",
  MOVE_COLUMNS_LEFT: "moveColumnsLeft",
  MOVE_COLUMNS_RIGHT: "moveColumnsRight",
  ADD_ROW: "addRow",
  // ADD_ROWS: "addRows",
  DUPLICATE_ROWS: "duplicateRows",
  DELETE_ROWS: "deleteRows",
  MOVE_ROWS_UP: "moveRowsUp",
  MOVE_ROWS_DOWN: "moveRowsDown",
  MERGE_CELLS: "mergeCells",
  UNMERGE_CELLS: "unmergeCells"
};
