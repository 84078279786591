import * as types from "state/entities/smartText/smartTextTypes";

export const initialState = {};

const smartTextAutoCompleteReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUTO_COMPLETE_DROPDOWN:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default smartTextAutoCompleteReducers;
