/**
 * @desc - takes a HTMLNode and finds the closest add file input before performing a click action
 * @param {object} target - a HTMLDom object to be used as the target to find the closest addFile input from
 */
export const clickClosestAddFileInput = target => {
  const closestImageButton =
    target.closest("#add-image-button") ||
    target.querySelector("#add-image-button");
  const input = closestImageButton
    ? closestImageButton.querySelector("input")
    : null;
  if (input) {
    input.click();
  }
};
