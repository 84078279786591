import { createSelector } from "reselect";
import { isNil, getPath, isEmpty } from "lib";
import { formatSearchResultList } from "state/ui/userTeamImagesPage/userTeamImagesPageSelectors";

export const teamAnimationsEntitiesSelector = state =>
  state.entities.teamAnimations;
const teamAnimationsApiSelector = state => state.api.teamAnimations;
const allTeamAnimationsApiSelector = state => state.api.teamAnimations?.all;
const teamAnimationSearchSelector = state =>
  state.ui.editorAnimationSearch.brand;

export const processGetTeamAnimationsNotInFolders = (
  teamAnimationsApi,
  teamAnimationsEntities
) => {
  let teamAnimations = [];
  if (teamAnimationsApi.pages) {
    const pagesOrder = Object.keys(teamAnimationsApi.pages).sort();

    pagesOrder.forEach(page => {
      const ids = teamAnimationsApi.pages[page].ids || [];
      const uniqueIds = [...new Set(ids)];

      uniqueIds.forEach(teamAnimationId => {
        const entity = teamAnimationsEntities[teamAnimationId];

        teamAnimations.push(entity);
      });
    });
  }
  return teamAnimations;
};

export const getTeamAnimationsNotInFolders = createSelector(
  [allTeamAnimationsApiSelector, teamAnimationsEntitiesSelector],
  processGetTeamAnimationsNotInFolders
);

export const teamAnimationsPageSelector = ({ state, term }) => {
  if (term && term !== "") {
    const searchState = teamAnimationSearchSelector(state);
    if (
      searchState &&
      !isEmpty(searchState.ALL) &&
      !isEmpty(searchState.ALL.terms)
    ) {
      const termSearchState = getPath(searchState.ALL.terms, term);

      if (!termSearchState || termSearchState.isFetching) return [];

      const ids = termSearchState.ids || [];
      const uniqueIds = [...new Set(ids)];
      const animationEntities = uniqueIds.map(
        teamAnimationId => termSearchState.animations[teamAnimationId]
      );

      return formatSearchResultList(animationEntities);
    }
    return [];
  }
  return getTeamAnimationsNotInFolders(state);
};

const processTeamAnimationsSelector = (
  teamAnimationsApi,
  teamAnimationsEntities
) => {
  if (isNil(teamAnimationsApi.all.pages)) {
    return null;
  }

  let hasBeenFetched = false;
  let teamAnimationsIds = [];

  const pagesOrder = Object.keys(teamAnimationsApi.all.pages).sort();

  pagesOrder.forEach(page => {
    const ids = getPath(teamAnimationsApi.all, `pages[${page}].ids`);

    if (!isNil(ids)) {
      hasBeenFetched = true;
      teamAnimationsIds = teamAnimationsIds.concat(ids);
    }
  });

  if (hasBeenFetched) {
    return teamAnimationsIds.map(
      animationId => teamAnimationsEntities[animationId]
    );
  } else {
    return null;
  }
};

export const teamAnimationsSelector = createSelector(
  [teamAnimationsApiSelector, teamAnimationsEntitiesSelector],
  processTeamAnimationsSelector
);

export const teamAnimationSelector = ({ state, animationId }) =>
  state.entities.teamAnimations[animationId];
