import { entityBlackList, entityRenderEncodeList } from "./htmlEntityLists";
import { reverseReplace } from "lib";

export const decodeHtml = html => {
  entityBlackList.forEach(entity => {
    if (entity.useReverse) {
      /* flip the string, apply forward variable lookup and then flip back */
      html = reverseReplace(html, entity.regEx, entity.value);
    } else {
      html = html.replace(entity.regEx, entity.value);
    }
  });
  return html;
};

export const decodeHtmlTable = tableElement => {
  tableElement.rows = tableElement.rows.map(row => {
    row.cells = row.cells.map(cell => {
      cell.textFields = cell.textFields.map(textField => {
        textField.value = decodeHtml(textField.value);
        return textField;
      });
      return cell;
    });
    return row;
  });
  return { [tableElement.uniqueId]: tableElement };
};

export const encodeHtmlForRender = html => {
  entityRenderEncodeList.forEach(entity => {
    html = html.replace(entity.regEx, entity.value);
  });
  return html;
};

export const encodeHtmlTableForRender = tableElement => {
  tableElement.rows = tableElement.rows.map(row => {
    row.cells = row.cells.map(cell => {
      cell.textFields = cell.textFields.map(textField => {
        textField.value = encodeHtmlForRender(textField.value);
        return textField;
      });
      return cell;
    });
    return row;
  });
  return { [tableElement.uniqueId]: tableElement };
};

/**
 * @desc takes 2 html strings and determines if the color values have changed
 * @param {string} from - the html string we had previously
 * @param {string} to - the new html string we want to check against
 * @returns {boolean} - whether or not the color values have changed
 */
export const isRichTextColorChange = (from, to) => {
  const regex = /color: rgb\(\d+,\s\d+,\s\d+\);/g;
  const fromMatch = from.match(regex);
  const toMatch = to.match(regex);

  if (fromMatch && toMatch && fromMatch.length === toMatch.length) {
    let isColorChange = false;
    for (let i = 0; i < fromMatch.length; i++) {
      if (fromMatch[i] !== toMatch[i]) {
        // one of the color values did not match
        isColorChange = true;
        break;
      }
    }
    return isColorChange;
  } else {
    // strings do not have the same number of color params so must be a color change
    return true;
  }
};
