import React from "react";
import PrintItems from "./PrintItems";
import { calculateDisplayMeasurements } from "lib";
import { isMediaVideoFormat } from "lib/mediaSourceHelpers";
import { Button, H2, Image } from "views/components";
import { playVideo } from "lib/videoControlUtils";

import style from "./style.module.css";

class DesignItem extends React.Component {
  constructor(props) {
    super(props);
    this.imageWrapperRef = React.createRef();
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this);

    this.state = {
      isHovered: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isHovered !== this.state.isHovered) {
      const videoElement = this.imageWrapperRef.current.querySelector("video");
      if (videoElement) {
        if (this.state.isHovered) {
          // start playing the video thumbnail on hover
          playVideo(videoElement);
        } else {
          // pause the video thumbnail and scrub back to 0:00
          videoElement.pause();
          videoElement.currentTime = 0;
        }
      }
    }
  }

  handleOnMouseOver() {
    if (this.state.isHovered) {
      return;
    }
    this.setState({ isHovered: true });
  }

  render() {
    const {
      design,
      onDesignPreview,
      onDeleteDesignFromOrder,
      printPricingGroupedByDesignId,
      handleUpdatePrintItem,
      handleCreatePrintItem,
      handleDeletePrintItem,
      imageTemplateTypes,
      loading,
      activePrintItemId,
      activeLabel
    } = this.props;
    const {
      id,
      title,
      templateSize,
      templateCode,
      designId,
      orderId,
      thumbnailUrl,
      isDeleting,
      printItems,
      isUpdating,
      isUpdatingDesignVersion
    } = design;

    const getDisplayMeasurements = () => {
      const measurements = calculateDisplayMeasurements(design);
      return `${measurements.width}x${measurements.height}${measurements.unit}`;
    };

    return (
      <div key={id} className={style.cartDesignWrapper}>
        <div className={style.CartOptionSection}>
          <div className={style.thumbPreviewSection}>
            <div
              className={style.thumbnail}
              onMouseEnter={() => this.setState({ isHovered: true })}
              onMouseLeave={() => this.setState({ isHovered: false })}
              onMouseOver={this.handleOnMouseOver}
              ref={this.imageWrapperRef}
            >
              {isMediaVideoFormat(thumbnailUrl) ? (
                <video className={style.previewVideo} muted loop>
                  <source src={thumbnailUrl} type="video/mp4" />
                </video>
              ) : (
                <Image
                  src={thumbnailUrl}
                  alt={title}
                  width="auto"
                  height="auto"
                />
              )}
            </div>
            <div className={style.previewRemoveButtons}>
              <Button
                onClick={() => onDesignPreview({ designId })}
                theme="grayBackgroundXDarkGrayFont"
                isFullWidth={true}
                className={style.previewButton}
              >
                Preview
              </Button>
              <Button
                onClick={() =>
                  onDeleteDesignFromOrder({ orderId, orderDesignId: id })
                }
                theme="xLightGrayBorderXDarkGrayFont"
                isFullWidth={true}
                className={style.removeButton}
              >
                Remove
              </Button>
            </div>
          </div>
          <div className={style.printOptionSelections}>
            <div className={style.designTitle}>
              <H2>{title}</H2>
            </div>
            <div className={style.designSubtitle}>
              {templateSize} ({getDisplayMeasurements()})
            </div>
            <PrintItems
              printItems={printItems}
              printPricing={printPricingGroupedByDesignId[designId]}
              handleUpdatePrintItem={args =>
                handleUpdatePrintItem({ ...args, orderId, orderDesignId: id })
              }
              handleCreatePrintItem={args =>
                handleCreatePrintItem({ ...args, orderId, orderDesignId: id })
              }
              handleDeletePrintItem={args =>
                handleDeletePrintItem({ ...args, orderId, orderDesignId: id })
              }
              isUpdating={isUpdating}
              isPrintableOption={
                !imageTemplateTypes
                  .map(templateType => templateType.templateCode)
                  .includes(templateCode)
              }
              handleRemoveDesignFromOrder={() =>
                onDeleteDesignFromOrder({ orderId, orderDesignId: id })
              }
              isLoading={loading.pricing || loading.sizes}
              selectPrintItem={printItemId => {
                this.setState({
                  activePrintItemId: printItemId,
                  activeLabel:
                    activePrintItemId === printItemId ? activeLabel : null
                });
              }}
              selectActiveLabel={({ optionLabel, printItemId }) => {
                this.setState({
                  activePrintItemId: printItemId,
                  activeLabel: optionLabel
                });
              }}
              activePrintItemId={activePrintItemId}
              activeLabel={activeLabel}
            />
          </div>
        </div>
        {(isDeleting || isUpdatingDesignVersion) && (
          <div className={style.deleteOverlay} />
        )}
      </div>
    );
  }
}

export default DesignItem;
