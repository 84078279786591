import React from "react";
import style from "./style.module.css";
import BrandKitColorSearch from "views/components/BrandKit/BrandKitColors/BrandKitColorSearch";
import BrandKitFontSearch from "views/components/BrandKit/BrandKitFonts/BrandKitFontSearch";
import BrandKitLogosSearch from "views/components/BrandKit/BrandKitLogos/BrandKitLogosSearch";
import BrandKitAnimationsSearch from "views/components/BrandKit/BrandKitAnimations/BrandKitAnimationsSearch";
import BrandKitSmartTextSearch from "views/components/BrandKit/BrandKitSmartText/BrandKitSmartTextSearch";
import PATHS from "routes/paths";
import { Route, Switch } from "react-router-dom";
import BrandKitImagesSearch from "../BrandKitImages/BrandKitImagesSearch";

export default function BrandKitSearch(props) {
  return (
    <div className={style.searchContainer}>
      <Switch>
        <Route
          path={PATHS.brandColors}
          render={routerProps => (
            <BrandKitColorSearch {...props} {...routerProps} />
          )}
        />
        <Route
          path={PATHS.brandFonts}
          render={routerProps => (
            <BrandKitFontSearch {...props} {...routerProps} />
          )}
        />
        <Route
          path={PATHS.brandLogos}
          render={routerProps => (
            <BrandKitLogosSearch
              {...props}
              {...routerProps}
              {...props.logosState}
            />
          )}
        />
        <Route
          path={PATHS.brandImages}
          render={routerProps => (
            <BrandKitImagesSearch
              {...props}
              {...routerProps}
              {...props.imagesState}
            />
          )}
        />
        <Route
          path={PATHS.brandAnimations}
          render={routerProps => (
            <BrandKitAnimationsSearch
              {...props}
              {...routerProps}
              {...props.animationsState}
            />
          )}
        />
        <Route
          path={PATHS.brandSmartText}
          render={routerProps => (
            <BrandKitSmartTextSearch {...props} {...routerProps} />
          )}
        />
      </Switch>
    </div>
  );
}
