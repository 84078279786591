import { Logger, boolXor } from "lib";
import { getCSSComputedBoundingClientRect } from "lib/htmlElements/dimensions";
import { getHtmlNodeCenter } from "lib/htmlElements/dimensions";
import { rotatePoint } from "lib/geometry/rotation";
import { calculateScalingFactor } from "lib/scalingTools/scalingTools";

export const calculateNewDimensions = ({
  dragItemType,
  vectorElement,
  photoFrame,
  differenceFromInitialOffset,
  photoFrameDivHeight,
  photoFrameDivWidth,
  zoom,
  dragItem,
  pageOffset,
  frameOffsetTop,
  frameOffsetLeft,
  frameWindowHeight,
  frameWindowWidth,
  originPointUnrotated,
  anchorPointUnrotated,
  scaleAnchorPoint,
  position
}) => {
  switch (dragItemType) {
    case "PHOTO_FRAME_IMAGE":
      return calculateMovedNewDimensions({
        vectorElement,
        photoFrame,
        differenceFromInitialOffset,
        photoFrameDivHeight,
        photoFrameDivWidth,
        frameOffsetTop,
        frameOffsetLeft,
        frameWindowHeight,
        frameWindowWidth,
        zoom
      });

    case "PHOTO_FRAME_CROP_RESIZE_HANDLER":
      return calculateScaledNewDimensions({
        vectorElement,
        photoFrame,
        differenceFromInitialOffset,
        zoom,
        dragItem,
        pageOffset,
        frameOffsetTop,
        frameOffsetLeft,
        frameWindowHeight,
        frameWindowWidth,
        originPointUnrotated,
        anchorPointUnrotated,
        scaleAnchorPoint,
        position
      });

    default:
      Logger.error("GridCell Cropping Preview DragType Handler");
  }
};

export const calculateMovedNewDimensions = ({
  vectorElement,
  photoFrame,
  differenceFromInitialOffset,
  frameWindowHeight,
  frameWindowWidth,
  zoom
}) => {
  const diffRotated = differenceFromInitialOffset;

  const vectorRatio = vectorElement.width / vectorElement.srcWidth;
  const vectorRatioZoomed = vectorRatio * zoom;

  const photoFrameHeight = photoFrame.height;
  const photoFrameWidth = photoFrame.width;

  const photoFrameLeft = photoFrame.left;
  const photoFrameTop = photoFrame.top;

  const newPhotoFrameLeft =
    photoFrameLeft +
    ((diffRotated.x / zoom) * photoFrame.scaleX * vectorElement.scaleX) /
      vectorElement.scale;
  const newPhotoFrameTop =
    photoFrameTop +
    ((diffRotated.y / zoom) * photoFrame.scaleY * vectorElement.scaleY) /
      vectorElement.scale;

  // define the unbound frame dimensions
  const photoFrameNewDimensions = {
    height: photoFrameHeight,
    width: photoFrameWidth,
    left: newPhotoFrameLeft,
    top: newPhotoFrameTop,
    right: newPhotoFrameLeft + photoFrameWidth,
    bottom: newPhotoFrameTop + photoFrameHeight,
    leftDiff: newPhotoFrameLeft - photoFrameLeft,
    topDiff: newPhotoFrameTop - photoFrameTop,
    scale: 1
  };

  // calculate the dimension limits
  const frameWindowTop = 0;
  const frameWindowBottom =
    frameWindowTop + frameWindowHeight / vectorRatioZoomed;
  const frameWindowLeft = 0;
  const frameWindowRight =
    frameWindowLeft + frameWindowWidth / vectorRatioZoomed;

  // prevent dimensions from exceeding the bounding of the photo frame
  if (photoFrameNewDimensions.top > frameWindowTop) {
    photoFrameNewDimensions.top = frameWindowTop;
  }

  if (photoFrameNewDimensions.bottom < frameWindowBottom) {
    photoFrameNewDimensions.top =
      frameWindowHeight / vectorRatioZoomed - photoFrameHeight;
  }

  if (photoFrameNewDimensions.left > frameWindowLeft) {
    photoFrameNewDimensions.left = frameWindowLeft;
  }

  if (photoFrameNewDimensions.right < frameWindowRight) {
    photoFrameNewDimensions.left =
      frameWindowWidth / vectorRatioZoomed - photoFrameWidth;
  }

  return photoFrameNewDimensions;
};

export const calculateUnrotatedFrame = ({
  vectorElement,
  zoom,
  photoFrame
}) => {
  // ratio is scale factor from original size to current size
  const vectorRatio = vectorElement.width / vectorElement.srcWidth; //<design space>
  const vectorRatioZoomed = vectorRatio * zoom; //<window space>

  // get the DOM node for the element
  const DOMElement = document.querySelector(`[id="${vectorElement.uniqueId}"]`);

  // get all g tags from the svg in element
  const gTags = Array.from(DOMElement.querySelectorAll("svg > g"));
  const elementDomElement = DOMElement.firstChild;

  const clipPath = DOMElement.querySelector(
    `[id="${photoFrame.domId}"`
  ).parentElement.querySelector("clipPath");

  // get tag for frame
  const frameDomElement = gTags.filter(gTag => gTag.contains(clipPath))[0];

  const getDimensionSource = () => {
    const rect = clipPath.querySelector("rect");
    const circle = frameDomElement.querySelector("circle");
    const path = frameDomElement.querySelector("path");
    const ellipse = clipPath.querySelector("ellipse");
    const polygon = clipPath.querySelector("polygon");
    return rect || circle || path || ellipse || polygon;
  };

  // define the object to get dimensions for dropzone from
  const dimensionSource = getDimensionSource();

  const dimensionSourceClientRect = getCSSComputedBoundingClientRect({
    element: dimensionSource,
    cssValueMultiplier: vectorRatioZoomed
  });

  const frameWindowHeight = dimensionSourceClientRect.height;
  const frameWindowWidth = dimensionSourceClientRect.width;

  // get center of the frame node
  const frameRectCenter = getHtmlNodeCenter(gTags.pop());

  // get center of the element node
  const elementRectCenter = getHtmlNodeCenter(elementDomElement);

  // rotate the frame center backwards from element center
  const frameRectCenterUnrotated = rotatePoint(
    frameRectCenter.x,
    frameRectCenter.y,
    elementRectCenter.x,
    elementRectCenter.y,
    -vectorElement.angle
  );

  // calculate the top for frame as center minus half height
  const frameTopUnrotated = frameRectCenterUnrotated.y - frameWindowHeight / 2;

  // calculate the left for frame as center minus half width
  const frameLeftUnrotated = frameRectCenterUnrotated.x - frameWindowWidth / 2;

  const elementTopUnrotated =
    elementRectCenter.y - (vectorElement.height * zoom) / 2;
  const elementLeftUnrotated =
    elementRectCenter.x - (vectorElement.width * zoom) / 2;

  // calculate the difference between the frame and element positions
  const frameOffsetTop = frameTopUnrotated - elementTopUnrotated;
  const frameOffsetLeft = frameLeftUnrotated - elementLeftUnrotated;

  return {
    frameOffsetTop,
    frameOffsetLeft,
    frameWindowHeight,
    frameWindowWidth
  };
};

export const getDesignRelativeDimensions = ({
  vectorElement,
  pageOffset,
  image,
  frameOffsetTop,
  frameOffsetLeft,
  zoom,
  dragItem,
  frameWindowHeight, // window
  frameWindowWidth // window
}) => {
  const vectorRatio = vectorElement.width / vectorElement.srcWidth; //design
  const vectorRatioZoomed = vectorRatio * zoom; // window

  const elementLeftZoomed = vectorElement.left * zoom; // window
  const elementTopZoomed = vectorElement.top * zoom; // window
  const elementWidthZoomed = vectorElement.width * zoom;
  const elementHeightZoomed = vectorElement.height * zoom;

  // frame bounding rect in window coordinates
  const frameBoundingClientRect = {
    x: pageOffset.left + elementLeftZoomed + frameOffsetLeft,
    y: pageOffset.top + elementTopZoomed + frameOffsetTop
  };

  const frameWidth = frameWindowWidth / vectorRatioZoomed;
  const frameHeight = frameWindowHeight / vectorRatioZoomed;

  // image dimensions
  const imageHeight = image.height; // design
  const imageWidth = image.width; // design
  const imageLeft = image.left;
  const imageTop = image.top;

  // define the center of the element in screen coordinates for initial anchor rotation
  const elementCenter = {
    x: pageOffset.left + elementLeftZoomed + elementWidthZoomed / 2,
    y: pageOffset.top + elementTopZoomed + elementHeightZoomed / 2
  };

  // unrotate the anchor around the element
  const unRotatedAnchorPosition = rotatePoint(
    dragItem.anchorPoint.x,
    dragItem.anchorPoint.y,
    elementCenter.x,
    elementCenter.y,
    -parseInt(vectorElement.angle)
  );

  // offset the anchor so that it is in design coordinates
  const anchorPoint = {
    x:
      (unRotatedAnchorPosition.x - frameBoundingClientRect.x) /
      vectorRatioZoomed,
    y:
      (unRotatedAnchorPosition.y - frameBoundingClientRect.y) /
      vectorRatioZoomed
  };

  return {
    imageHeight,
    imageWidth,
    imageLeft,
    imageTop,
    anchorPoint,
    frameWidth,
    frameHeight
  };
};

/* 
  calculate the new dimensions for a photoFrame
  keeping in mind that the photo frame in this case
  is storing dimensions for the image inside it
  and not the frame itself.

  Naming had been converted inside the function but input is
  as 'photoFrame' instead of 'image'
*/
export const calculateScaledNewDimensions = ({
  vectorElement, // design dimensions
  photoFrame: image, // design dimensions
  differenceFromInitialOffset,
  zoom,
  pageOffset, // window dimension
  frameOffsetTop, // window
  frameOffsetLeft, // window
  frameWindowHeight, // window
  frameWindowWidth, // window
  dragItem
}) => {
  const {
    scaleAnchorPoint,
    handlerInitialPosition,
    selectionBoxNodeCenter
  } = dragItem;

  const {
    imageHeight,
    imageWidth,
    imageLeft,
    imageTop,
    anchorPoint,
    frameHeight,
    frameWidth
  } = getDesignRelativeDimensions({
    vectorElement,
    pageOffset,
    image,
    dragItem,
    frameOffsetLeft,
    frameOffsetTop,
    zoom,
    selectionBoxNodeCenter,
    differenceFromInitialOffset,
    frameWindowHeight, // window
    frameWindowWidth // window
  });

  const rotatedDifference = rotatePoint(
    differenceFromInitialOffset.x,
    differenceFromInitialOffset.y,
    0,
    0,
    vectorElement.angle
  ); // window

  const scale = calculateScale({
    // using scale anchor here as it will not be flipped (smooth scaling)
    anchorPoint: scaleAnchorPoint,
    differenceFromInitialOffset: rotatedDifference,
    handlerInitialPosition,
    vectorElement,
    image,
    imageDivWidth: frameWindowWidth / zoom,
    imageDivHeight: frameWindowHeight / zoom
  });

  const newImageHeight = imageHeight * scale; // element
  const newImageWidth = imageWidth * scale; // element

  const newImageLeft = anchorPoint.x + (imageLeft - anchorPoint.x) * scale;
  const newImageTop = anchorPoint.y + (imageTop - anchorPoint.y) * scale;

  // should all be converted back to design space
  const imageNewDimensions = {
    height: newImageHeight,
    width: newImageWidth,
    left: newImageLeft,
    top: newImageTop,
    right: newImageLeft + newImageWidth,
    bottom: newImageTop + newImageHeight
  };

  if (imageNewDimensions.top > 0) {
    imageNewDimensions.top = 0;
  }
  if (imageNewDimensions.bottom < frameHeight) {
    imageNewDimensions.top += frameHeight - imageNewDimensions.bottom;
  }
  if (imageNewDimensions.left > 0) {
    imageNewDimensions.left = 0;
  }
  if (imageNewDimensions.right < frameWidth) {
    imageNewDimensions.left += frameWidth - imageNewDimensions.right;
  }

  return imageNewDimensions;
};

export const unRotateKeyPoints = (
  differenceFromInitialOffset,
  initialOffset,
  vectorElement,
  anchorPoint
) => {
  // get the DOM node for the element
  const DOMElement = document.querySelector(`[id="${vectorElement.uniqueId}"]`);

  const elementDomElement = DOMElement.firstChild;

  // get center of the element node
  const elementRectCenter = getHtmlNodeCenter(elementDomElement);

  const offsetPoint = {
    x: initialOffset.x + differenceFromInitialOffset.x,
    y: initialOffset.y + differenceFromInitialOffset.y
  };

  const offsetPointUnrotated = rotatePoint(
    offsetPoint.x,
    offsetPoint.y,
    elementRectCenter.x,
    elementRectCenter.y,
    -vectorElement.angle
  );

  const originPointUnrotated = rotatePoint(
    initialOffset.x,
    initialOffset.y,
    elementRectCenter.x,
    elementRectCenter.y,
    -vectorElement.angle
  );

  const getAnchorPoint = () => {
    // when no anchor is present just return default position object
    if (anchorPoint) {
      return rotatePoint(
        anchorPoint.x,
        anchorPoint.y,
        elementRectCenter.x,
        elementRectCenter.y,
        -vectorElement.angle
      );
    }
    return {
      x: 0,
      y: 0
    };
  };

  const anchorPointUnrotated = getAnchorPoint();

  // unrotated offset difference
  const differenceFromInitialOffsetUnrotated = {
    x: offsetPointUnrotated.x - originPointUnrotated.x,
    y: offsetPointUnrotated.y - originPointUnrotated.y
  };

  return {
    differenceFromInitialOffsetUnrotated,
    originPointUnrotated,
    anchorPointUnrotated
  };
};

export const calculatePhotoFrameDisplayDimensions = ({
  photoFrame,
  vectorElement,
  zoom,
  placeholderDimensions: placeholder
}) => {
  const vectorRatio = vectorElement.width / vectorElement.srcWidth;
  const vectorRatioZoomed = vectorRatio * zoom;

  // get the DOM node for the element
  const DOMElement = document.querySelector(`[id="${vectorElement.uniqueId}"]`);

  // get all g tags from the svg in element
  const gTags = Array.from(DOMElement.querySelectorAll("svg > g"));
  const elementDomElement = DOMElement.firstChild;

  const clipPath = DOMElement.querySelector(
    `[id="${photoFrame.domId}"`
  ).parentElement.querySelector("clipPath");

  // get tag for frame
  const frameDomElement = gTags.filter(gTag => gTag.contains(clipPath))[0];

  const getDimensionSource = () => {
    const rect = clipPath.querySelector("rect");
    const circle = frameDomElement.querySelector("circle");
    const path = clipPath.querySelector("path");
    const ellipse = clipPath.querySelector("ellipse");
    const polygon = clipPath.querySelector("polygon");
    return rect || circle || path || ellipse || polygon;
  };

  // define the object to get dimensions for dropzone from
  const dimensionSource = getDimensionSource();

  const dimensionSourceClientRect = getCSSComputedBoundingClientRect({
    element: dimensionSource,
    cssValueMultiplier: vectorRatioZoomed
  });

  const frameWindowHeight = dimensionSourceClientRect.height;
  const frameWindowWidth = dimensionSourceClientRect.width;

  // get center of the frame node
  const frameRectCenter = getHtmlNodeCenter(gTags.pop());

  const elementWidth = vectorElement.srcWidth * vectorElement.scale * zoom;
  const elementHeight = vectorElement.srcHeight * vectorElement.scale * zoom;

  const xOffset =
    (vectorElement.scaleX !== 1 ? vectorElement.maskedRightDiff || 0 : 0) *
    zoom;
  const yOffset =
    (vectorElement.scaleY !== 1 ? vectorElement.maskedBottomDiff || 0 : 0) *
    zoom;

  // get center of the element node
  const elementRectCenter = getHtmlNodeCenter(elementDomElement);

  const DOMPage = DOMElement.closest('div[id^="PAGE_CANVAS"]');
  const pageBoundingRect = DOMPage.getBoundingClientRect();

  const elementRectCenterUnMasked = {
    x:
      pageBoundingRect.left +
      vectorElement.left * zoom -
      xOffset +
      elementWidth / 2,
    y:
      pageBoundingRect.top +
      vectorElement.top * zoom -
      yOffset +
      elementHeight / 2
  };

  // rotate the frame center backwards from element center
  const frameRectCenterUnrotated = rotatePoint(
    frameRectCenter.x,
    frameRectCenter.y,
    elementRectCenter.x,
    elementRectCenter.y,
    -vectorElement.angle
  );

  // calculate the top for frame as center minus half height
  const frameTopUnrotated = frameRectCenterUnrotated.y - frameWindowHeight / 2;
  const frameBottomUnrotated =
    frameRectCenterUnrotated.y + frameWindowHeight / 2;

  // calculate the left for frame as center minus half width
  const frameLeftUnrotated = frameRectCenterUnrotated.x - frameWindowWidth / 2;
  const frameRightUnrotated = frameRectCenterUnrotated.x + frameWindowWidth / 2;

  const elementTopUnrotated =
    elementRectCenterUnMasked.y - (vectorElement.height * zoom) / 2;
  const elementLeftUnrotated =
    elementRectCenterUnMasked.x - (vectorElement.width * zoom) / 2;
  const elementBottomUnrotated =
    elementRectCenterUnMasked.y + (vectorElement.height * zoom) / 2;
  const elementRightUnrotated =
    elementRectCenterUnMasked.x + (vectorElement.width * zoom) / 2;

  // calculate the difference between the frame and element positions
  const frameOffsetTop = frameTopUnrotated - elementTopUnrotated;
  const frameOffsetLeft = frameLeftUnrotated - elementLeftUnrotated;
  const frameOffsetBottom = frameBottomUnrotated - elementBottomUnrotated;
  const frameOffsetRight = frameRightUnrotated - elementRightUnrotated;

  const getCalculatedLeft = () => {
    const isOneXFlipped = boolXor(
      photoFrame.scaleX === -1,
      vectorElement.scaleX === -1
    );

    if (!(photoFrame.scaleX === -1 || vectorElement.scaleX === -1)) {
      // no flips, return normally
      return frameOffsetLeft;
    }

    if (isOneXFlipped) {
      if (photoFrame.scaleX === -1) {
        // when just the photo frame flipped use the left offset
        return frameOffsetLeft;
      }
    }

    // if both or just vector scaleX we use negative right offset
    return -frameOffsetRight;
  };

  const getCalculatedTop = () => {
    const isOneYFlipped = boolXor(
      photoFrame.scaleY === -1,
      vectorElement.scaleY === -1
    );

    if (!(photoFrame.scaleY === -1 || vectorElement.scaleY === -1)) {
      // no flips, return normally
      return frameOffsetTop;
    }

    if (isOneYFlipped) {
      if (photoFrame.scaleY === -1) {
        // when just the photo frame flipped use the top offset
        return frameOffsetTop;
      }
    }

    // if both or just vector scaleX we use negative bottom offset
    return -frameOffsetBottom;
  };

  const calculatedLeft = getCalculatedLeft();

  const calculatedTop = getCalculatedTop();

  return {
    top: calculatedTop,
    left: calculatedLeft,
    height: photoFrame.height * zoom * vectorRatio,
    width: photoFrame.width * zoom * vectorRatio,
    frameWindowHeight,
    frameWindowWidth
  };
};

export const getImageDimensions = ({
  zoom,
  photoFrame,
  vectorElement,
  placeholderDimensions
}) => {
  const { scale } = vectorElement;

  const {
    frameWindowWidth,
    frameWindowHeight,
    width,
    height
  } = calculatePhotoFrameDisplayDimensions({
    photoFrame,
    vectorElement,
    zoom,
    placeholderDimensions
  });

  const photoFrameLeft =
    photoFrame.left * zoom * scale +
    (photoFrame.scaleX === -1 ? width - frameWindowWidth : 0);
  const photoFrameTop =
    photoFrame.top * zoom * scale +
    (photoFrame.scaleY === -1 ? height - frameWindowHeight : 0);

  return {
    top: photoFrameTop,
    left: photoFrameLeft
  };
};

export const calculateScale = ({
  anchorPoint,
  differenceFromInitialOffset,
  handlerInitialPosition,
  image,
  vectorElement,
  imageDivWidth,
  imageDivHeight
}) => {
  const scaleFactorFromMouseMovement = calculateScalingFactor({
    anchorPoint,
    handlerInitialPosition,
    differenceFromInitialPosition: differenceFromInitialOffset
  });

  // ensure that we scale proportionately
  const scale = Math.max(
    imageDivWidth /
      (image.width * (vectorElement.width / vectorElement.srcWidth)),
    imageDivHeight /
      (image.height * (vectorElement.height / vectorElement.srcHeight)),
    scaleFactorFromMouseMovement
  );

  return scale;
};
