import { noop } from "lib";
import * as types from "./removeBackgroundApiTypes";
import { schemas, CALL_API, SERVICES } from "state/middleware/api";

export const removeBackgroundApiCall = ({
  onSuccess = noop,
  onFailure = noop,
  mediaId
}) => (dispatch, getState) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = state.ui.currentTeam.id;

  dispatch({
    [CALL_API]: {
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      method: "POST",
      types: [
        types.REMOVE_BACKGROUND_REQUEST,
        types.REMOVE_BACKGROUND_REQUEST_SUCCESS,
        types.REMOVE_BACKGROUND_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/images`,
      request: {
        body: {
          userId,
          teamId,
          mediaId
        }
      },
      schema: schemas.CREDITS,
      onFailure: onFailure,
      onSuccess: onSuccess
    }
  });
};

export const removeBackgroundWithinFolderApiCall = ({
  onSuccess = noop,
  onFailure = noop,
  mediaId,
  folderId
}) => (dispatch, getState) => {
  const state = getState();
  const userId = state.currentUser.id;
  const teamId = state.ui.currentTeam.id;

  dispatch({
    [CALL_API]: {
      headers: {
        "Content-Type": "multipart/form-data",
        "mime-type": "multipart/form-data",
        Authorization: "Bearer " + getState().currentUser.token
      },
      method: "POST",
      types: [
        types.REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST,
        types.REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST_SUCCESS,
        types.REMOVE_BACKGROUND_WITHIN_FOLDER_REQUEST_FAILURE
      ],
      service: SERVICES.ASSET,
      endpoint: `/teams/${teamId}/users/${userId}/folders/${folderId}/content`,
      request: {
        body: {
          userId,
          teamId,
          mediaId,
          folderId,
          type: "IMAGE"
        }
      },
      schema: schemas.CREDITS,
      onFailure: onFailure,
      onSuccess: onSuccess
    }
  });
};
