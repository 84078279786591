import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const InvitationAcceptancePage = lazy(() =>
  import("./InvitationAcceptancePage")
);

export const InvitationAcceptancePageLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <InvitationAcceptancePage {...props} />
  </Suspense>
);
InvitationAcceptancePageLazy.displayName = "InvitationAcceptancePage";

export default InvitationAcceptancePageLazy;
