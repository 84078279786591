import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TableIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
  >
    <title>{title}</title>

    <g fill="none" fillRule="evenodd">
      <path d="M-2-2h20v20H-2z" />
      <g>
        <rect
          width="14"
          height="14"
          x="1"
          y="1"
          stroke={color || easilGrayXDark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          rx="2"
        />
        <g fill={color || easilGrayXDark}>
          <path
            fillRule="nonzero"
            d="M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm0-2h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"
          />
          <path d="M5 2h2v12H5z" />
          <path d="M1 5h15v2H1z" />
        </g>
      </g>
    </g>
  </svg>
);
TableIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TableIcon;
