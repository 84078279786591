import { rotatePoint } from "lib/geometry/rotation";

const calculateMovedNewDimensions = ({
  imageElement,
  differenceFromInitialOffset,
  zoom
}) => {
  const diffRotated = rotatePoint(
    differenceFromInitialOffset.x,
    differenceFromInitialOffset.y,
    0,
    0,
    -imageElement.angle
  );

  const imageMaskTop = calculateImageMaskTop({
    verticalDiff: diffRotated.y,
    imageElement,
    zoom
  });

  const imageMaskLeft = calculateImageMaskLeft({
    horizontalDiff: diffRotated.x,
    imageElement,
    zoom
  });

  const imageMaskBottom = calculateImageMaskBottom({
    top: imageMaskTop,
    imageElement
  });

  const imageMaskRight = calculateImageMaskRight({
    left: imageMaskLeft,
    imageElement
  });

  const imageElementWithMaskUpdated = {
    ...imageElement,
    mask: {
      top: imageMaskTop,
      left: imageMaskLeft,
      bottom: imageMaskBottom,
      right: imageMaskRight
    }
  };

  return imageElementWithMaskUpdated;
};

const calculateImageMaskTop = ({ verticalDiff, imageElement, zoom }) => {
  const verticalMovement =
    -verticalDiff / (imageElement.scale * zoom * imageElement.scaleY);

  let imageMaskTop = imageElement.mask.top + verticalMovement;

  if (imageMaskTop < 0) {
    imageMaskTop = 0;
  }

  const imageHeight = imageElement.srcHeight * imageElement.scale;
  const elementHeight = imageElement.height;

  const imageMaskTopMaxValue =
    (imageHeight - elementHeight) / imageElement.scale;

  if (imageMaskTop > imageMaskTopMaxValue) {
    imageMaskTop = imageMaskTopMaxValue;
  }

  return imageMaskTop;
};

const calculateImageMaskLeft = ({ horizontalDiff, imageElement, zoom }) => {
  const horizontalMovement =
    -horizontalDiff / (imageElement.scale * zoom * imageElement.scaleX);

  let imageMaskLeft = imageElement.mask.left + horizontalMovement;

  if (imageMaskLeft < 0) {
    imageMaskLeft = 0;
  }

  const imageWidth = imageElement.srcWidth * imageElement.scale;

  const elementWidth = imageElement.width;

  const imageMaskLeftMaxValue =
    (imageWidth - elementWidth) / imageElement.scale;

  if (imageMaskLeft > imageMaskLeftMaxValue) {
    imageMaskLeft = imageMaskLeftMaxValue;
  }

  return imageMaskLeft;
};

const calculateImageMaskBottom = ({ top, imageElement }) => {
  const imageHeight = imageElement.srcHeight;
  const elementHeight = imageElement.height / imageElement.scale;

  return imageHeight - (top + elementHeight);
};

const calculateImageMaskRight = ({ left, imageElement }) => {
  const imageWidth = imageElement.srcWidth;
  const elementWidth = imageElement.width / imageElement.scale;

  return imageWidth - (left + elementWidth);
};

export default calculateMovedNewDimensions;
