import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchAllDataForOrder } from "state/pageActions/orderPageActions";
import { orderByIdWithFullDesignSelector } from "state/entities/orders/ordersSelectors";
import { moveTo } from "state/ui/navigation/navigationActions";
import PATHS from "routes/paths";

import Order from "./Order";

class OrderContainer extends Component {
  componentDidMount() {
    this.props.fetchAllDataForOrder({ orderId: this.props.orderId });
  }

  render() {
    return <Order {...this.props} />;
  }
}

function mapStateToProps(state, ownProps) {
  const order = orderByIdWithFullDesignSelector({
    state,
    orderId: ownProps.orderId
  });
  return {
    order
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchAllDataForOrder: args => dispatch(fetchAllDataForOrder(args)),
    moveToOrdersPage: () => dispatch(moveTo(PATHS.orders))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderContainer);
