import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

const Checkbox = ({
  className,
  checkboxClassName,
  label = "",
  disabled = false,
  isBoxRight = false,
  isHovered,
  isMinus = false,
  isDoubleScale = false,
  checked,
  ...props
}) => {
  const getLabelClassName = () => {
    const labelClasses = [style.container];

    if (className) {
      labelClasses.push(className);
    }

    return labelClasses.join(" ");
  };

  const getCheckmarkClassName = () => {
    const checkmarkClasses = [style.checkmark];

    if (checkboxClassName) {
      checkmarkClasses.push(checkboxClassName);
    }

    return checkmarkClasses.join(" ");
  };

  const customLabelStyle = {};
  const customCheckBoxStyle = {};

  if (isBoxRight) {
    customLabelStyle.paddingLeft = "0px";
    customCheckBoxStyle.left = "auto";
    customCheckBoxStyle.right = 0;
  }

  return (
    <label
      className={getLabelClassName()}
      data-disabled={disabled}
      style={customLabelStyle}
      data-testid="Checkbox"
    >
      {label}
      <input
        {...props}
        type="checkbox"
        name={label}
        readOnly
        data-testid="checkbox"
        checked={checked}
      />
      <span
        className={getCheckmarkClassName()}
        data-is-hovered={isHovered}
        data-is-minus={isMinus}
        data-double-scale={isDoubleScale}
        style={customCheckBoxStyle}
      />
    </label>
  );
};
Checkbox.propTypes = {
  className: PropTypes.string,
  checkboxClassName: PropTypes.string
};

export default Checkbox;
