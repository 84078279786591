import { Logger } from "lib";
import { replaceItem } from "lib/array/array";
import {
  calculateMovedNewDimensions,
  calculateScaledNewDimensions,
  calculateUnRotatedCell
} from "../CropLayer/gridCellCropping/GridCellCroppingPreviewCalculations";

const EditorGridOps = {
  /**
   * Set up the context for grid cell cropping.
   * @param {object} context - Current context that contains the selectedGridCellId
   * @param {Array<object>} selectedItems - Array of selected items
   * @return updated context
   */
  startGridCellCropMode(context, selectedItems) {
    Logger.info("Editor.startGridCellCropMode called");

    // We need to find the gridElement Html
    const { itemId } = selectedItems[0];
    const gridHtmlElement = document.getElementById(itemId);
    const gridCellHtmlElement = gridHtmlElement.querySelector(
      `#${context.selectedGridCellId}`
    );

    if (!gridCellHtmlElement || !gridHtmlElement) {
      // don't go into grid crop mode without the right properties
      return;
    }

    return {
      context: {
        ...context,
        isCroppingGridCell: true,
        gridHtmlElement,
        gridCellHtmlElement
      }
    };
  },

  finishGridCellCropMoving({
    differenceFromInitialOffset,
    zoom,
    selectedItems,
    gridElement,
    gridCell,
    gridCellIndex,
    gridCellHtmlElement
  }) {
    const {
      clientWidth: gridCellDivWidth,
      clientHeight: gridCellDivHeight
    } = gridCellHtmlElement;

    const newGridCellDimensions = calculateMovedNewDimensions({
      gridElement,
      gridCell,
      differenceFromInitialOffset,
      gridCellDivHeight,
      gridCellDivWidth,
      zoom
    });

    const gridCellUpdated = {
      ...gridCell,
      top: newGridCellDimensions.top,
      left: newGridCellDimensions.left
    };

    const imageInstructionsUpdated = replaceItem(
      gridElement.imageInstructions,
      gridCellIndex,
      gridCellUpdated
    );

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          imageInstructions: imageInstructionsUpdated
        }
      }
    ];

    return {
      isCropping: false,
      selectedItems: selectedItemUpdated
    };
  },

  finishGridCellCropResizing(args) {
    const {
      handlerInitialPosition,
      anchorPoint,
      differenceFromInitialOffset,
      pageHtmlElement,
      scaleAnchorPoint,
      position,
      selectionBoxNodeCenter,
      zoom,
      selectedItems,
      designData,
      context
    } = args;

    const gridElement = designData.elements[selectedItems[0].itemId];

    const gridElementWithPreview = {
      ...gridElement,
      ...selectedItems[0].preview
    };

    const gridCellIndex = gridElementWithPreview.imageInstructions.findIndex(
      imageInstruction => imageInstruction.domId === context.selectedGridCellId
    );

    const gridCell = gridElementWithPreview.imageInstructions[gridCellIndex];

    const {
      clientWidth: gridCellDivWidth,
      clientHeight: gridCellDivHeight
    } = context.gridCellHtmlElement;

    const {
      gridCellOffsetTop: gridCellComputedOffsetTop,
      gridCellOffsetLeft: gridCellComputedOffsetLeft
    } = calculateUnRotatedCell({
      gridCellHtmlElement: context.gridCellHtmlElement,
      gridHtmlElement: context.gridHtmlElement,
      gridElement,
      zoom
    });

    const pageOffset = pageHtmlElement.getBoundingClientRect();

    const newGridCellDimensions = calculateScaledNewDimensions({
      gridElement,
      gridCell,
      differenceFromInitialOffset,
      gridCellDivHeight,
      gridCellDivWidth,
      zoom,
      dragItem: {
        anchorPoint,
        handlerInitialPosition,
        scaleAnchorPoint,
        position,
        selectionBoxNodeCenter
      },
      pageOffset,
      gridCellOffsetTop: gridCellComputedOffsetTop,
      gridCellOffsetLeft: gridCellComputedOffsetLeft
    });

    const gridCellUpdated = {
      ...gridCell,
      top: newGridCellDimensions.top,
      left: newGridCellDimensions.left,
      height: newGridCellDimensions.height,
      width: newGridCellDimensions.width
    };

    const imageInstructionsUpdated = replaceItem(
      gridElement.imageInstructions,
      gridCellIndex,
      gridCellUpdated
    );

    const selectedItemUpdated = [
      {
        ...selectedItems[0],
        preview: {
          imageInstructions: imageInstructionsUpdated
        }
      }
    ];

    return {
      isCropping: false,
      selectedItems: selectedItemUpdated
    };
  },

  updateGridImageInstructions({ elementId, imageInstructions } = {}) {
    Logger.info("Editor.updateGridImageInstructions called");
    const { designData } = this.state;

    const updatedDesignData = designData.updateImageInstructions({
      elementId: elementId,
      imageInstructions
    });
    this.updateStateAndSave({
      designData: updatedDesignData
    });
  },

  getGridElementWithPreview({ designData, selectedItems, selectedGridCellId }) {
    const selectedItem = selectedItems[0];
    const gridElementId = selectedItem.itemId;
    let gridElement = designData.elements[gridElementId];

    gridElement = {
      ...gridElement,
      ...selectedItems[0].preview
    };

    const imageInstructions = gridElement.imageInstructions.filter(
      imageInstruction => imageInstruction.domId === selectedGridCellId
    );
    const gridCell = imageInstructions[0];

    return {
      gridElement,
      gridCell
    };
  }
};

export default EditorGridOps;
