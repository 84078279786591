import { rotateRect } from "lib/transform";
import { cloneDeep } from "lib";
import { rotatePoint } from "lib/geometry/rotation";
import { elementAlignmentValues } from "lib/constants";

/**
 * @desc - takes an element, the axis to check, and boolean to determine which extreme to check
 * @param {object} element - the selected element as an object
 * @param {string} axis - a string (either "x" or "y") to filter from the rectangle corner points
 * @param {boolean} isLowPosition - a boolean, whether we are checking for lowest or highest value
 * @returns {string} returns a string representing the extreme value for elements corner point
 */
export const getRotatedRectExtremePosition = (
  element,
  axis,
  isLowPosition = true
) => {
  if (!axis || !element) return;

  const mathExtremeFunction = isLowPosition ? Math.min : Math.max;

  const elementDimensions = rotateRect(
    element.left,
    element.top,
    element.width,
    element.height,
    element.angle
  );

  const extremePointValue = mathExtremeFunction(
    ...elementDimensions.map(curr => {
      return curr[axis];
    })
  );

  return extremePointValue;
};

/**
 * @desc - uses conditionals to determine the axis of the alignment, and returns an object containing
 * relevant properties for the alignment functionality
 * @param {string} alignment - alignment type "left", "center", "right", "top", "middle" or "bottom"
 * @returns {object} returns an object with the following properties:
 * isXAxisAlignment: (boolean)
 * axis: string ("x" or "y")
 * alignmentOrigin: string (eg. "left" or "top" based on isXAxisAlignment)
 * elementLengthProperty: string (eg. "width" or "height" based on isXAxisAlignment)
 */
export const determineElementPropertiesFromAlignment = alignment => {
  if (!alignment) return;
  const { LEFT, CENTER, RIGHT, TOP } = elementAlignmentValues;

  const isXAxisAlignment = [LEFT, CENTER, RIGHT].includes(alignment);
  let axis = "x",
    alignmentOrigin = LEFT,
    elementLengthProperty = "width";

  if (!isXAxisAlignment) {
    axis = "y";
    alignmentOrigin = TOP;
    elementLengthProperty = "height";
  }

  return {
    isXAxisAlignment,
    axis,
    alignmentOrigin,
    elementLengthProperty
  };
};

/**
 * @desc - takes an array of elements to return all of the 'left' values of those elements
 * @param {[object]} selectedElements - an array of selected element objects
 * @returns {[object]} returns an array of strings representing the 'left' values
 */
export const getElementsLeftValues = selectedElements => {
  if (!selectedElements) return;

  return selectedElements.map(element => {
    // return the left position of a rotated element
    if (Number(element.angle)) {
      const leftRotatedValue = getRotatedRectExtremePosition(
        element,
        "x",
        true
      );
      return leftRotatedValue + element.width / 2;
    }
    return element.left;
  });
};

/**
 * @desc - takes an array of elements to return all of the horizontal (x axis) center values of those elements
 * * by taking into account the left and width properties, as well as any rotated element values
 * @param {[object]} selectedElements - an array of selected element objects
 * @returns {[object]} returns an array of strings representing the center values
 */
export const getElementsHorizontalCenterValues = selectedElements => {
  if (!selectedElements) return;

  return selectedElements.map(element => {
    const rightValue = element.left + element.width;
    return (rightValue - element.left) / 2 + element.left;
  });
};

/**
 * @desc - takes an array of elements to return all of the 'right' values of those elements
 * @param {[object]} selectedElements - an array of selected element objects
 * @returns {[object]} returns an array of strings representing the 'right' values
 */
export const getElementsRightValues = selectedElements => {
  if (!selectedElements) return;

  return selectedElements.map(element => {
    // return the right position of a rotated element
    if (Number(element.angle)) {
      const rightRotatedValue = getRotatedRectExtremePosition(
        element,
        "x",
        false
      );
      return rightRotatedValue + element.width / 2;
    }
    return element.left + element.width;
  });
};

/**
 * @desc - takes an array of elements to return all of the 'top' values of those elements
 * @param {[object]} selectedElements - an array of selected element objects
 * @returns {[object]} returns an array of strings representing the 'top' values
 */
export const getElementsTopValues = selectedElements => {
  if (!selectedElements) return;

  return selectedElements.map(element => {
    // return the left position of a rotated element
    if (Number(element.angle)) {
      const topRotatedValue = getRotatedRectExtremePosition(element, "y", true);
      return topRotatedValue + element.height / 2;
    }
    return element.top;
  });
};

/**
 * @desc - takes an array of elements to return all of the vertical (y axis) middle values of those elements
 * by taking into account the top and length properties, as well as any rotated element values
 * @param {[object]} selectedElements - an array of selected element objects
 * @returns {[object]} returns an array of strings representing the middle values
 */
export const getElementsVerticalMiddleValues = selectedElements => {
  if (!selectedElements) return;

  return selectedElements.map(element => {
    const bottomValue = element.top + element.height;
    return (bottomValue - element.top) / 2 + element.top;
  });
};

/**
 * @desc - takes an array of elements to return all of the 'bottom' values of those elements
 * @param {[object]} selectedElements - an array of selected element objects
 * @returns {[object]} returns an array of strings representing the 'bottom' values
 */
export const getElementsBottomValues = selectedElements => {
  if (!selectedElements) return;

  return selectedElements.map(element => {
    // return the right position of a rotated element
    if (Number(element.angle)) {
      const bottomRotatedValue = getRotatedRectExtremePosition(
        element,
        "y",
        false
      );
      return bottomRotatedValue + element.height / 2;
    }
    return element.top + element.height;
  });
};

/**
 * @desc - performs the incoming alignment on the selected element
 * @param {object} design - this design data
 * @param {string} elementId - elementId of selected element to align
 * @param {string} minimumValue - lowest value for selected alignment property
 * @param {string} alignment - alignment type : "left" (horizontal) or "top" (vertical)
 * @returns {object} returns an object for the updated element after the alignment has been applied
 * returned object takes the form: {
 *  [elementId]: updatedElementObject
 * }
 */
export const alignElementsAtMinimumOrigin = (
  design,
  elementId,
  minimumValue,
  alignment
) => {
  const designData = cloneDeep(design);
  const elementToAlign = designData.elements[elementId];
  const {
    axis,
    alignmentOrigin,
    elementLengthProperty
  } = determineElementPropertiesFromAlignment(alignment);

  if (elementToAlign.type === "group") {
    // if element is a group, all individual group elements need to be updated
    const groupedElements = elementToAlign.elementsOrder.map(elementId =>
      designData.getElement(elementId)
    );
    const groupLowestValue = elementToAlign[alignmentOrigin];
    // find the difference between the current group lowest value and total selected elements lowest value
    const groupLowestAdjustment = minimumValue - groupLowestValue;
    // exit loop if group is already aligned to the lowest value
    if (groupLowestAdjustment === 0) return;

    const movedGroupElements = designData.moveGroup(
      groupedElements,
      groupLowestAdjustment,
      alignmentOrigin
    );

    return movedGroupElements;
  }

  if (Number(elementToAlign.angle)) {
    // if element is rotated, we need to determine the difference between the lowestValue
    // and the lowest value for the corresponding rotated edge
    // then apply that difference to the alignmentOrigin
    const lowestValuePoint = getRotatedRectExtremePosition(
      elementToAlign,
      axis,
      true
    );

    // return early if rotated element is already the lowest point
    if (lowestValuePoint === minimumValue) return;

    const lowestValueDifference =
      lowestValuePoint -
      minimumValue +
      elementToAlign[elementLengthProperty] / 2;

    elementToAlign[alignmentOrigin] =
      elementToAlign[alignmentOrigin] - lowestValueDifference;

    return { [elementToAlign.uniqueId]: elementToAlign };
  }
  // assign the lowest left value to the selected element
  elementToAlign[alignmentOrigin] = minimumValue;

  return { [elementToAlign.uniqueId]: elementToAlign };
};

/**
 * @desc - performs the incoming alignment on the selected element
 * @param {object} design - this design data
 * @param {string} elementId - elementId of selected element to align
 * @param {string} selectedElementsValues - lowest and highest positional values for selected alignment property
 * @param {string} alignment - alignment type : "center" (horizontal) or "middle" (vertical)
 * @returns {object} returns an object for the updated element after the alignment has been applied
 * returned object takes the form: {
 *  [elementId]: updatedElementObject
 * }
 */
export const alignElementsAtCenterOrigin = (
  design,
  elementId,
  selectedElementsValues,
  alignment
) => {
  const designData = cloneDeep(design);
  const elementToAlign = designData.elements[elementId];
  const {
    alignmentOrigin,
    elementLengthProperty
  } = determineElementPropertiesFromAlignment(alignment);
  const { highestValue, lowestValue } = selectedElementsValues;
  const selectedItemsCenterpoint =
    (highestValue - lowestValue) / 2 + lowestValue;

  if (elementToAlign.type === "group") {
    // if element is a group, all individual group elements need to be updated
    const groupedElements = elementToAlign.elementsOrder.map(elementId =>
      designData.getElement(elementId)
    );
    const groupLowestValue = elementToAlign[alignmentOrigin];
    const groupLowestAdjustment =
      selectedItemsCenterpoint -
      elementToAlign[elementLengthProperty] / 2 -
      groupLowestValue;
    // exit loop if no alignment is needed
    if (groupLowestAdjustment === 0) return;

    const movedGroupElements = designData.moveGroup(
      groupedElements,
      groupLowestAdjustment,
      alignmentOrigin
    );

    return movedGroupElements;
  }

  // assign the left positions to the centerpoint minus half the width of the element
  elementToAlign[alignmentOrigin] =
    selectedItemsCenterpoint - elementToAlign[elementLengthProperty] / 2;
  return { [elementToAlign.uniqueId]: elementToAlign };
};

/**
 * @desc - performs the incoming alignment on the selected element
 * @param {object} design - this design data
 * @param {string} elementId - elementId of selected element to align
 * @param {string} selectedElementsValues - lowest and highest positional values for selected alignment property
 * @param {string} alignment - alignment type : "right" (horizontal) or "bottom" (vertical)
 * @returns {object} returns an object for the updated element after the alignment has been applied
 * returned object takes the form: {
 *  [elementId]: updatedElementObject
 * }
 */
export const alignElementsAtMaximumOrigin = (
  design,
  elementId,
  selectedElementsValues,
  alignment
) => {
  const designData = cloneDeep(design);
  const elementToAlign = designData.elements[elementId];
  const {
    axis,
    alignmentOrigin,
    elementLengthProperty
  } = determineElementPropertiesFromAlignment(alignment);
  const { highestValue } = selectedElementsValues;

  if (elementToAlign.type === "group") {
    const groupedElements = elementToAlign.elementsOrder.map(elementId =>
      designData.getElement(elementId)
    );
    const groupHighestValue =
      elementToAlign[alignmentOrigin] + elementToAlign[elementLengthProperty];
    const groupLowestAdjustment = highestValue - groupHighestValue;
    // exit loop if no alignment is needed
    if (groupLowestAdjustment === 0) return;

    const movedGroupElements = designData.moveGroup(
      groupedElements,
      groupLowestAdjustment,
      alignmentOrigin
    );

    return movedGroupElements;
  }

  if (Number(elementToAlign.angle)) {
    // if element is rotated, we need to determine the difference between the highestValue
    // and the highest value for the corresponding rotated edge
    // then apply that difference to the alignmentOrigin
    const highestValuePoint = getRotatedRectExtremePosition(
      elementToAlign,
      axis,
      false
    );

    if (highestValuePoint === highestValue) return;

    const highestValueDifference =
      highestValue -
      highestValuePoint -
      elementToAlign[elementLengthProperty] / 2;
    elementToAlign[alignmentOrigin] =
      elementToAlign[alignmentOrigin] + highestValueDifference;

    return { [elementToAlign.uniqueId]: elementToAlign };
  }

  // assign the left to the highest right value minus the element width
  elementToAlign[alignmentOrigin] =
    highestValue - elementToAlign[elementLengthProperty];
  return { [elementToAlign.uniqueId]: elementToAlign };
};

export const rotateElementsAroundCenter = ({ elements, angle }) => {
  let updatedElements = {};

  let xSum = 0;
  let ySum = 0;

  const elementsWithCenter = elements.map(element => {
    const elementCenter = {
      x: element.left + element.width / 2,
      y: element.top + element.height / 2
    };

    xSum += elementCenter.x;
    ySum += elementCenter.y;

    return {
      ...element,
      center: elementCenter
    };
  });

  const selectionCenter = {
    x: xSum / elements.length,
    y: ySum / elements.length
  };

  elementsWithCenter.forEach(element => {
    const rotatedElementCenter = rotatePoint(
      element.center.x,
      element.center.y,
      selectionCenter.x,
      selectionCenter.y,
      angle || 0
    );

    const newLeft = rotatedElementCenter.x - element.width / 2;
    const newTop = rotatedElementCenter.y - element.height / 2;
    const newAngle = Number(element.angle) + angle;

    updatedElements[element.uniqueId] = {
      left: newLeft,
      top: newTop,
      angle: newAngle
    };
  });

  return updatedElements;
};
