import React, { Component } from "react";
import { connect } from "react-redux";
import Table2Cell from "./Table2Cell";
import { getCurrentDesignData } from "state/ui/editor/editorSelectors";
import { actionBarStateSelector } from "state/ui/actionBar/actionBarSelectors";
import {
  contextSelector,
  elementPreviewsSelector,
  selectedItemsSelector,
  shouldUseDesignSmartFieldsSelector,
  isResizingSelector,
  zoomSelector,
  isCellLockingMode
} from "state/ui/editorContext/editorContextSelectors";
import { update as updateEditorContextState } from "state/ui/editorContext/editorContextActions";
import { update as updateActionBarState } from "state/ui/actionBar/actionBarActions";
import { saveDesign } from "state/ui/editor/editorActions";
import { ACTION_BAR_BUTTON_NAMES_MAP } from "lib/constants";
import { EditorElementsOps } from "views/components/Editor/editorOps";
import ActionBarOps from "views/components/Editor/actionbar/ActionBarOps";
import { Logger, isEmpty } from "lib";
import { designById } from "state/entities/designs/designsSelectors";
import { table2CellDimensionUpdater } from "views/components/Editor/sidebar/tabs/shapes/Tables2Tab/helper";
import { jsonStringEqual } from "lib/equalityUtils";
import {
  smartTextSelector,
  designSmartTextSelector
} from "state/entities/smartText/smartTextSelectors";
import { isSmartTextAutoCompleteOpen } from "state/ui/smartTextAutoComplete/smartTextAutoCompleteSelectors";
import { activeButtonSelector } from "state/ui/actionBar/actionBarSelectors";

export class Table2CellContainer extends Component {
  constructor(props) {
    super(props);
    this.detectShiftDown = this.detectShiftDown.bind(this);
    this.detectShiftUp = this.detectShiftUp.bind(this);
    this.handleSelectedCells = this.handleSelectedCells.bind(this);
    this.handleCellEditMode = this.handleCellEditMode.bind(this);
    this.isCellSelected = this.isCellSelected.bind(this);
    this.onTable2TextFieldPreview = this.onTable2TextFieldPreview.bind(this);
    this.onElementPreview = this.onElementPreview.bind(this);
    this.onElementPreviewPersist = this.onElementPreviewPersist.bind(this);
    this.onTable2TextFieldChange = this.onTable2TextFieldChange.bind(this);

    this.state = {
      isCellSelected: this.isCellSelected(),
      shiftHeld: false,
      isCellEditable: false
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.detectShiftDown);
    document.addEventListener("keyup", this.detectShiftUp);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.detectShiftDown);
    document.removeEventListener("keyup", this.detectShiftUp);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !jsonStringEqual(
        this.props.context?.selectedTable2CellIds,
        prevProps.context.selectedTable2CellIds
      ) ||
      !jsonStringEqual(
        this.props.elementData.layout,
        prevProps.elementData.layout
      )
    ) {
      this.setState({ isCellSelected: this.isCellSelected() });
    }

    if (
      prevState.isCellSelected &&
      !this.state.isCellSelected &&
      this.state.isCellEditable
    ) {
      this.setState({ isCellEditable: false });
    }

    if (
      this.props.context.selectedTable2CellIds &&
      this.props.context.selectedTable2CellIds.includes(this.props.cellId) &&
      !this.state.isCellSelected
    ) {
      this.setState({
        isCellSelected: true
      });
    }
  }

  detectShiftDown(event) {
    if (this.props.isSelected && this.props.isOnlyElementSelected) {
      if ((event.shiftKey || event.metaKey) && !this.state.shiftHeld) {
        this.setState({
          shiftHeld: true
        });
      }
    }
  }

  detectShiftUp(event) {
    if (!event.shiftKey && !event.metaKey && this.state.shiftHeld) {
      this.setState({
        shiftHeld: false
      });
    }
  }

  isCellSelected() {
    if (!this.props.context.selectedTable2CellIds) return false;
    return this.props.context.selectedTable2CellIds.some(
      cellId => cellId === this.props.cellId
    );
  }

  handleSelectedCells(cellId) {
    const { selectedTable2CellIds = [] } = this.props.context;
    const { isCellSelected } = this.state;

    if (this.state.shiftHeld) {
      const updatedSelectedTable2CellIds = isCellSelected
        ? selectedTable2CellIds.filter(selectedId => selectedId !== cellId)
        : [...selectedTable2CellIds, cellId];

      this.props.updateContextState({
        context: { selectedTable2CellIds: updatedSelectedTable2CellIds }
      });
      if (updatedSelectedTable2CellIds.length) {
        this.handleCellEditMode(ACTION_BAR_BUTTON_NAMES_MAP.TABLE2_CELL);
      } else {
        this.handleCellEditMode(null);
      }
      return;
    }

    // enter text edit mode unless element is restricted
    if (selectedTable2CellIds.length === 1 && isCellSelected) {
      const isCellLocked = this.props.designData
        .getElement(this.props.elementData.uniqueId)
        .isCellLocked(this.props.cellId);
      if (
        this.props.elementData.restrictions.includes("textEdit") ||
        isCellLocked
      ) {
        return;
      } else {
        this.setState({ isCellEditable: true });
        this.handleCellEditMode(ACTION_BAR_BUTTON_NAMES_MAP.TABLE2_TEXTFIELD);
        return;
      }
    }

    this.handleCellEditMode(ACTION_BAR_BUTTON_NAMES_MAP.TABLE2_CELL);
    this.props.updateContextState({
      context: { selectedTable2CellIds: [cellId] }
    });
  }

  handleCellEditMode(buttonName) {
    const actionBarState = {
      ...this.props.actionbar,
      buttonActive: buttonName
    };
    this.props.updateActionBarState(actionBarState);
  }

  onTable2TextFieldPreview({ value, displayValue }) {
    Logger.info("Table2CellContainer.onTableTextFieldPreview called");
    const table2Element = this.props.designData.getElement(
      this.props.elementData.uniqueId
    );
    const updatedTable2Element = table2Element.updateTextFieldValue({
      cellId: this.props.cellId,
      value,
      displayValue
    });
    const table2Dom = document.getElementById(
      `table2-element-${table2Element.uniqueId}`
    );

    this.onElementPreview({
      cells: updatedTable2Element.cells,
      height: table2Dom.scrollHeight
    });
  }

  /**
   * Handle the update of attributes on the selected item.
   * @param {object} attributes - The attributes and the new values they should be set to.
   */
  onElementPreview(attributes) {
    Logger.info("Table2CellContainer.onElementPreview called");

    const updatedSelectedItems = EditorElementsOps.updateSelectedItemAttributes(
      this.props.designData,
      this.props.selectedItems,
      this.props.context,
      attributes
    );

    this.props.updateContextState({ selectedItems: updatedSelectedItems });
  }

  /**
   * Persist the updates in the element previews to the design data.
   */
  onElementPreviewPersist() {
    const {
      designData,
      selectedItems,
      elementPreviews,
      elementData
    } = this.props;
    let previews = {};
    if (Object.keys(elementPreviews).length === 0) {
      selectedItems.forEach(item => {
        previews[item.itemId] = item.preview || {};
      });
    } else {
      previews = elementPreviews;
    }

    if (previews[elementData.uniqueId]?.cells) {
      const { cells } = table2CellDimensionUpdater({
        ...elementData,
        cells: previews[elementData.uniqueId].cells
      });
      previews[elementData.uniqueId].cells = cells;
    }

    const updatedDesignData = EditorElementsOps.updateElements(
      designData,
      previews
    );
    const updatedContextState = {
      designData: updatedDesignData,
      elementPreviews: {}
    };
    this.props.updateContextState({
      designData: updatedDesignData,
      elementPreviews: {}
    });
    this.props.onSave(updatedDesignData, updatedContextState);
  }

  onTable2TextFieldChange() {
    Logger.info("Table2CellContainer.onTableTextFieldChange called");
    const { selectedItems } = this.props;

    /* if there is no preview then nothing is changed, escape */
    if (
      !selectedItems ||
      !selectedItems[0] ||
      !selectedItems[0].preview ||
      isEmpty(selectedItems[0].preview)
    ) {
      return;
    }

    const tablePreview = selectedItems[0].preview;

    /* if the preview contains no changes escape */
    if (isEmpty(tablePreview)) {
      return;
    }

    ActionBarOps.onSelectedElementsAttributesChange(
      tablePreview,
      this.props.selectedItems,
      this.props
    );
  }

  render() {
    const { isCellSelected } = this.state;
    const isEditingCell =
      this.props.activeButton ===
        ACTION_BAR_BUTTON_NAMES_MAP.TABLE2_TEXTFIELD && isCellSelected;
    const _isCellLockingMode =
      this.props.isCellLockingMode && this.props.isOnlyElementSelected;
    const selectedItem = this.props.selectedItems.find(
      i => i.itemId === this.props.elementData.uniqueId
    );
    const elementDataWithPreview = {
      ...this.props.elementData,
      ...selectedItem?.preview
    };
    const isCellLocked = elementDataWithPreview.lockedCellIds.includes(
      this.props.cellId
    );

    return (
      <Table2Cell
        {...this.props}
        cellId={this.props.cellId}
        colspan={this.props.colspan}
        rowspan={this.props.rowspan}
        isSelected={this.props.isSelected}
        handleSelectedCells={this.handleSelectedCells}
        handleCellEditMode={this.handleCellEditMode}
        isAutoCompleteOpen={this.props.isAutoCompleteOpen}
        isOnlyElementSelected={this.props.isOnlyElementSelected}
        isCellSelected={isCellSelected}
        isCellEditable={this.state.isCellEditable}
        handleTable2TextFieldPreview={this.onTable2TextFieldPreview}
        onTable2TextFieldChange={this.onTable2TextFieldChange}
        onElementPreview={this.onElementPreview}
        onElementPreviewPersist={this.onElementPreviewPersist}
        isEditingCell={isEditingCell}
        isCellLockingMode={_isCellLockingMode}
        elementData={elementDataWithPreview}
        isCellLocked={isCellLocked}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentDesignData = getCurrentDesignData(state);
  const selectedItems = selectedItemsSelector(state);
  const isSelected = Boolean(
    selectedItems.find(item => item.itemId === ownProps.elementData.uniqueId)
  );
  const isOnlyElementSelected = () => {
    if (selectedItems.length > 1) return false;
    return selectedItems.some(
      item => item.itemId === ownProps.elementData.uniqueId
    );
  };

  let design = {};
  if (currentDesignData) {
    design = designById({ state, designId: currentDesignData.id });
  }

  const shouldUseDesignSmartFields = shouldUseDesignSmartFieldsSelector(state);
  const smartTextState = shouldUseDesignSmartFields
    ? designSmartTextSelector(state)
    : smartTextSelector(state);

  return {
    actionbar: actionBarStateSelector(state),
    activeButton: activeButtonSelector(state),
    context: contextSelector(state),
    design,
    designData: currentDesignData,
    elementPreviews: elementPreviewsSelector(state),
    isAutoCompleteOpen: isSmartTextAutoCompleteOpen(state),
    isCellLockingMode: isCellLockingMode(state),
    isOnlyElementSelected: isOnlyElementSelected(),
    isResizing: isResizingSelector(state),
    isSelected,
    selectedItems,
    smartTextState,
    zoom: zoomSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateContextState: args => dispatch(updateEditorContextState(args)),
    updateActionBarState: args => dispatch(updateActionBarState(args)),
    onSave: (...args) => dispatch(saveDesign(...args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table2CellContainer);
