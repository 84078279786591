import * as types from "state/currentUser/currentUserTypes";

export const initState = {};

const userPreferencesReducers = (state = initState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_PREFERENCES_REQUEST_SUCCESS: {
      const { ids: userId, entities } = action.response;

      return entities.userPreferences[userId];
    }

    case types.CURRENT_USER_UPDATE_PREFERENCES_REQUEST: {
      return {
        ...action.request.body,
        isFetching: true
      };
    }

    case types.CURRENT_USER_UPDATE_PREFERENCES_REQUEST_SUCCESS: {
      const { ids: userId, entities } = action.response;

      return {
        ...entities.userPreferences[userId],
        isFetching: false
      };
    }

    case types.CURRENT_USER_UPDATE_PREFERENCES_REQUEST_FAILURE: {
      const {
        error: { errors }
      } = action;

      return {
        ...state,
        errors,
        isFetching: false
      };
    }

    default:
      return state;
  }
};

export default userPreferencesReducers;
