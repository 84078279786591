import {
  DESIGN_REQUEST_APPROVAL_REQUEST,
  DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS
} from "state/entities/designs/designsTypes";
import {
  UI_OPEN_REQUEST_APPROVAL_MODAL,
  UI_CLOSE_REQUEST_APPROVAL_MODAL,
  UI_OPEN_CANCEL_APPROVAL_MODAL,
  UI_CLOSE_CANCEL_APPROVAL_MODAL
} from "./requestApprovalModalTypes";

export const initState = {
  isOpen: false,
  isProcessing: false,
  isCancelApprovalModalOpen: false
};

const requestApprovalModal = (state = initState, action) => {
  switch (action.type) {
    case DESIGN_REQUEST_APPROVAL_REQUEST: {
      return {
        ...state,
        isProcessing: true
      };
    }
    case DESIGN_REQUEST_APPROVAL_REQUEST_SUCCESS: {
      return {
        ...state,
        isProcessing: false
      };
    }

    case UI_OPEN_REQUEST_APPROVAL_MODAL: {
      return {
        isOpen: true,
        designId: action.designId
      };
    }

    case UI_CLOSE_REQUEST_APPROVAL_MODAL: {
      return initState;
    }

    case UI_OPEN_CANCEL_APPROVAL_MODAL: {
      return {
        ...state,
        isCancelApprovalModalOpen: true
      };
    }
    case UI_CLOSE_CANCEL_APPROVAL_MODAL: {
      return {
        ...state,
        isCancelApprovalModalOpen: false
      };
    }

    default:
      return state;
  }
};

export default requestApprovalModal;
