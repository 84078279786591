import React from "react";
import { connect } from "react-redux";

import { closeApprovalModal } from "state/ui/approvalModal/approvalModalActions";
import { approvalAction } from "state/ui/designsPage/designsPageActions";
import { getDesignActions } from "state/entities/designActions/designActionsActions";
import { getLatestDesignAction } from "state/entities/designActions/designActionsSelectors";
import { approvalModalSelector } from "state/ui/approvalModal/approvalModalSelectors";
import { designById } from "state/entities/designs/designsSelectors";
import { fetchAllUsersWithParentTeams } from "state/entities/users/usersActions";
import { userById } from "state/entities/users/usersSelectors";
import { refetchAllPendingApprovalDesignsPages } from "state/entities/designs/designsActions";

import ApprovalModal from "./ApprovalModal";

class ApprovalModalContainer extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.props.getDesignActions({ designId: this.props.designId });
      this.props.fetchAllUsersWithParentTeams();
    }
  }

  render() {
    return <ApprovalModal {...this.props} />;
  }
}

ApprovalModalContainer.propTypes = {};

ApprovalModalContainer.displayName = "ApprovalModalContainer";

const mapStateToProps = (state, ownProps) => {
  const { isOpen, isLoading, designId } = approvalModalSelector(state);
  const designAction = getLatestDesignAction({ state, designId });
  const design = designById({ state, designId });
  let requester;
  if (designAction && designAction.actionedById) {
    requester = userById(state, designAction.actionedById);
  }
  return {
    isOpen,
    isLoading,
    designId,
    designAction,
    design,
    requester
  };
};

const mapDispatchToProps = dispatch => {
  return {
    approveAction: args => dispatch(approvalAction(args)),
    onCloseModal: () => dispatch(closeApprovalModal()),
    getDesignActions: args => dispatch(getDesignActions(args)),
    fetchAllUsersWithParentTeams: () =>
      dispatch(fetchAllUsersWithParentTeams({})),
    refetchAllPendingApprovalDesignsPages: (...args) =>
      dispatch(refetchAllPendingApprovalDesignsPages(...args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalModalContainer);
