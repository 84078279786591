import React, { Component } from "react";
import { debounce, noop } from "lib";
import style from "./style.module.css";
import { ScrollFold } from "views/components";

class Scrollable extends Component {
  static PADDING_IN_PX = 8;
  constructor(props) {
    super(props);

    /* observer */
    this.observer = null;

    /* Y axis data */
    this.contentPositionY = 0;
    this.scrollerBeingDraggedY = false;
    this.scrollerY = null;
    this.topPosition = null;
    this.normalizedPositionY = null;
    this.scrollerHeight = null;

    /* X axis data */
    this.contentPositionX = 0;
    this.scrollerBeingDraggedX = false;
    this.scrollerX = null;
    this.leftPosition = null;
    this.normalizedPositionX = null;
    this.scrollerWidth = null;

    /* Refs */
    this.scrollContainer = React.createRef();
    this.scrollContentWrapper = React.createRef();

    /* Y functions */
    this.calculateScrollerYHeight = this.calculateScrollerYHeight.bind(this);
    this.moveScrollerY = this.moveScrollerY.bind(this);
    this.startDragY = this.startDragY.bind(this);
    this.stopDragY = this.stopDragY.bind(this);
    this.scrollBarScrollY = this.scrollBarScrollY.bind(this);
    this.createScrollerY = this.createScrollerY.bind(this);
    this.resetScrollerY = debounce(this.resetScrollerY.bind(this), 300);

    /* X functions */
    this.calculateScrollerXWidth = this.calculateScrollerXWidth.bind(this);
    this.moveScrollerX = this.moveScrollerX.bind(this);
    this.startDragX = this.startDragX.bind(this);
    this.stopDragX = this.stopDragX.bind(this);
    this.scrollBarScrollX = this.scrollBarScrollX.bind(this);
    this.createScrollerX = this.createScrollerX.bind(this);
    this.resetScrollerX = debounce(this.resetScrollerX.bind(this), 300);

    this.resetScrollers = this.resetScrollers.bind(this);
  }

  resetScrollerY() {
    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;

    if (!scrollContainer) return;

    if (this.scrollerY) {
      this.scrollerY = null;

      const scrollerYHtmlElement = scrollContainer.querySelector(
        `.${style.scrollerY}`
      );

      scrollContainer.removeChild(scrollerYHtmlElement);
    }

    this.createScrollerY();

    scrollContentWrapper.addEventListener("scroll", this.moveScrollerY);
  }

  resetScrollerX() {
    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;

    if (!scrollContentWrapper) return;

    if (this.scrollerX) {
      this.scrollerX = null;

      const scrollerXHtmlElement = scrollContainer.querySelector(
        `.${style.scrollerX}`
      );

      scrollContainer.removeChild(scrollerXHtmlElement);
    }

    this.createScrollerX();

    scrollContentWrapper.addEventListener("scroll", this.moveScrollerX);
  }

  resetScrollers() {
    this.resetScrollerY();
    this.resetScrollerX();
  }

  calculateScrollerXWidth() {
    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;

    if (!scrollContainer) return 0;

    // *Calculation of how wide scroller should be
    var visibleRatio =
      scrollContainer.offsetWidth / scrollContentWrapper.scrollWidth;
    return visibleRatio * scrollContainer.offsetWidth;
  }

  calculateScrollerYHeight() {
    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;

    if (!scrollContainer) return 0;

    // *Calculation of how tall scroller should be
    let visibleRatio =
      scrollContainer.offsetHeight / scrollContentWrapper.scrollHeight;
    return visibleRatio * scrollContainer.offsetHeight;
  }

  moveScrollerY(evt) {
    if (!this.scrollerY) {
      return;
    }

    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;
    // Move Scroll bar to top offset
    let scrollPercentage =
      evt.target.scrollTop / scrollContentWrapper.scrollHeight;
    this.topPosition = scrollPercentage * (scrollContainer.offsetHeight - 5); // 5px arbitrary offset so scroll bar doesn't move too far beyond content wrapper bounding box
    this.scrollerY.style.top =
      this.topPosition + Scrollable.PADDING_IN_PX + "px";

    const { onScroll = noop } = this.props;
    onScroll(evt);
  }

  moveScrollerX(evt) {
    if (!this.scrollerX) {
      return;
    }

    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;
    // Move Scroll bar to top offset
    let scrollPercentage =
      evt.target.scrollLeft / scrollContentWrapper.scrollWidth;
    this.leftPosition = scrollPercentage * (scrollContainer.offsetWidth - 5); // 5px arbitrary offset so scroll bar doesn't move too far beyond content wrapper bounding box
    this.scrollerX.style.left =
      this.leftPosition + Scrollable.PADDING_IN_PX + "px";
  }

  startDragY(evt) {
    const scrollContentWrapper = this.scrollContentWrapper.current;

    evt.preventDefault();
    evt.stopPropagation();

    this.normalizedPositionY = evt.pageY;
    this.contentPositionY = scrollContentWrapper.scrollTop;
    this.scrollerBeingDraggedY = true;
  }

  startDragX(evt) {
    const scrollContentWrapper = this.scrollContentWrapper.current;

    evt.preventDefault();
    evt.stopPropagation();

    this.normalizedPositionX = evt.pageX;
    this.contentPositionX = scrollContentWrapper.scrollLeft;
    this.scrollerBeingDraggedX = true;
  }

  stopDragY(evt) {
    this.scrollerBeingDraggedY = false;
  }

  stopDragX(evt) {
    this.scrollerBeingDraggedX = false;
  }

  scrollBarScrollY(evt) {
    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;

    if (this.scrollerBeingDraggedY === true) {
      let mouseDifferential = evt.pageY - this.normalizedPositionY;
      let scrollEquivalent =
        mouseDifferential *
        (scrollContentWrapper.scrollHeight / scrollContainer.offsetHeight);
      scrollContentWrapper.scrollTop = this.contentPositionY + scrollEquivalent;
    }
  }

  scrollBarScrollX(evt) {
    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;

    if (this.scrollerBeingDraggedX === true) {
      let mouseDifferential = evt.pageX - this.normalizedPositionX;
      let scrollEquivalent =
        mouseDifferential *
        (scrollContentWrapper.scrollWidth / scrollContainer.offsetWidth);
      scrollContentWrapper.scrollLeft =
        this.contentPositionX + scrollEquivalent;
    }
  }

  createScrollerY() {
    const { scrollYClassName } = this.props;
    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;

    // determine how big scroller should be based on content
    this.scrollerHeight = this.calculateScrollerYHeight();

    if (
      scrollContainer &&
      this.scrollerHeight / scrollContainer.offsetHeight < 1
    ) {
      // *Creates scroller element and appends to '.scrollable' div
      // create scroller element
      this.scrollerY = document.createElement("div");
      this.scrollerY.className = `${style.scrollerY} ${scrollYClassName}`;

      // *If there is a need to have scroll bar based on content size
      /* total padding is the top + bottom padding, so 2 * padding */
      const totalPadding = 2 * Scrollable.PADDING_IN_PX;
      this.scrollerY.style.height = this.scrollerHeight - totalPadding + "px";

      // keep the same position
      let scrollPercentage =
        scrollContentWrapper.scrollTop / scrollContentWrapper.scrollHeight;
      this.topPosition = scrollPercentage * (scrollContainer.offsetHeight - 5); // 5px arbitrary offset so scroll bar doesn't move too far beyond content wrapper bounding box
      this.scrollerY.style.top =
        this.topPosition + Scrollable.PADDING_IN_PX + "px";

      // append scroller to scrollContainer div
      scrollContainer.appendChild(this.scrollerY);

      // show scroll path divot
      /*       scrollContainer.className += ' showScroll'; */

      // attach related draggable listeners
      this.scrollerY.addEventListener("mousedown", this.startDragY);
      window.addEventListener("mouseup", this.stopDragY);
      window.addEventListener("mousemove", this.scrollBarScrollY);
    }
  }

  createScrollerX() {
    const scrollContainer = this.scrollContainer.current;
    const scrollContentWrapper = this.scrollContentWrapper.current;

    // determine how big scroller should be based on content
    this.scrollerWidth = this.calculateScrollerXWidth();

    if (
      scrollContainer &&
      this.scrollerWidth / scrollContainer.offsetWidth < 1
    ) {
      // *Creates scroller element and appends to '.scrollable' div
      // create scroller element
      this.scrollerX = document.createElement("div");
      this.scrollerX.className = style.scrollerX;

      // *If there is a need to have scroll bar based on content size
      /* total padding is the top + bottom padding, so 2 * padding */
      const totalPadding = 2 * Scrollable.PADDING_IN_PX;
      this.scrollerX.style.width = this.scrollerWidth - totalPadding + "px";

      // keep the same position
      let scrollPercentage =
        scrollContentWrapper.scrollLeft / scrollContentWrapper.scrollWidth;
      this.leftPosition = scrollPercentage * (scrollContainer.offsetWidth - 5); // 5px arbitrary offset so scroll bar doesn't move too far beyond content wrapper bounding box

      this.scrollerX.style.left =
        this.leftPosition + Scrollable.PADDING_IN_PX + "px";

      // append scroller to scrollContainer div
      scrollContainer.appendChild(this.scrollerX);

      // show scroll path divot
      /*       scrollContainer.className += ' showScroll'; */

      // attach related draggable listeners
      this.scrollerX.addEventListener("mousedown", this.startDragX);
      window.addEventListener("mouseup", this.stopDragX);
      window.addEventListener("mousemove", this.scrollBarScrollX);
    }
  }

  componentDidMount() {
    this.resetScrollers();

    let targetNode = this.scrollContentWrapper.current;

    // Options for the observer (which mutations to observe)
    let config = { attributes: true, childList: true, subtree: true };

    // Create an observer instance linked to the callback function
    this.observer = new MutationObserver(this.resetScrollers);

    // Start observing the target node for configured mutations
    this.observer.observe(targetNode, config);

    // In case window got resized we need to adjust the scrollers to new size
    window.addEventListener("resize", this.resetScrollers);
  }

  componentWillUnmount() {
    this.observer.disconnect();
    window.removeEventListener("resize", this.resetScrollers);
    window.removeEventListener("scroll", this.moveScrollerX);
    window.removeEventListener("scroll", this.moveScrollerY);
    window.removeEventListener("mousedown", this.startDragX);
    window.removeEventListener("mouseup", this.stopDragX);
    window.removeEventListener("mousemove", this.scrollBarScrollX);
    window.removeEventListener("mousedown", this.startDragY);
    window.removeEventListener("mouseup", this.stopDragY);
    window.removeEventListener("mousemove", this.scrollBarScrollY);
  }

  render() {
    return (
      <div
        className={`${style.scrollable} ${this.props.className}`}
        ref={this.scrollContainer}
        style={this.props.customStyles || {}}
        data-testid="Scrollable"
      >
        <div
          className={style.contentWrapper}
          ref={this.scrollContentWrapper}
          id={this.props.contentWrapperId}
          onScroll={this.props.onScroll}
        >
          <div
            className={`${style.content} ${this.props.scrollContentClassName}`}
          >
            {this.props.children}
          </div>
          {this.props.hasFold && (
            <ScrollFold
              color={this.props.foldColor}
              className={`${style.scrollFold} ${this.props.foldClassName ||
                ""}`}
              size={this.props.foldSize || 20}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Scrollable;
