import Logger from "lib/logger";

const EditorMoveOps = {
  moveSelection({
    selectedElements,
    left,
    top,
    fromPageId,
    toPageId,
    ...rest
  }) {
    Logger.info("Editor.moveSelection called");
    const { designData, zoom } = this.state;

    /* First we need to correct the coordinates from the zoom */
    const topUnzoomed = top / zoom;
    const leftUnzoomed = left / zoom;

    this.updateStateAndSave({
      designData: designData.moveSelection({
        selectedElements,
        left: leftUnzoomed,
        top: topUnzoomed,
        fromPageId,
        toPageId,
        ...rest
      }),
      isDragging: false,
      selectedItems: selectedElements.map(selectedItem => ({
        itemId: selectedItem.uniqueId,
        groupId: toPageId === fromPageId ? selectedItem.groupId : null,
        pageId: toPageId,
        preview: {}
      }))
    });
  },

  moveToPage({ id, left, top, fromPage, toPage }) {
    Logger.info("Editor.moveToPage called");
    const { designData, zoom } = this.state;

    /* First we need to correct the coordinates from the zoom */
    const topUnzoomed = top / zoom;
    const leftUnzoomed = left / zoom;

    this.updateStateAndSave({
      designData: designData.moveElementToPage({
        elementId: id,
        left: leftUnzoomed,
        top: topUnzoomed,
        fromPage,
        toPage
      }),
      isDragging: false,
      selectedItems: [
        {
          ...this.state.selectedItem,
          itemId: id,
          pageId: toPage,
          groupId: null
        }
      ]
    });
  }
};

export default EditorMoveOps;
