import * as types from "./ordersTypes";
import { merge, getPath, omit, cloneDeep } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";

export const initState = {};

const formatOrder = ({ entity }) => {
  return {
    ...entity.order,
    designs: entity.designs,
    shipping: entity.shippingDetail,
    billing: entity.billingDetail,
    fetchedAt: Date.now()
  };
};

const ordersReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CREATE_ORDER_PAYMENT_REQUEST_SUCCESS: {
      const { entities, ids } = action.response;
      const orderId = ids;
      const newState = { ...state };
      const entity = entities.orders[orderId];

      newState[orderId] = {
        ...state[orderId],
        ...entity
      };

      return merge({}, newState);
    }

    case types.CREATE_ORDER_REQUEST_SUCCESS: {
      const { entities, ids } = action.response;
      const orderId = ids;
      const newState = { ...state };
      const entity = entities.order[orderId];
      const order = formatOrder({ entity });

      newState[orderId] = order;

      return merge({}, newState);
    }

    case types.FETCH_ORDERS_REQUEST_SUCCESS: {
      const { entities, ids } = action.response;

      if (!entities) return state;
      const newState = cloneDeep(state);

      // if ids is an array
      // map through and append order to each id
      if (Array.isArray(ids)) {
        ids.forEach(id => {
          const entity = entities.orders[id];
          newState[id] = entity;
        });
        return merge({}, newState);
      }

      const orderId = ids;
      const entity = entities.orders[orderId];
      const order = formatOrder({ entity });

      newState[orderId] = order;

      return merge({}, newState);
    }

    case types.ADD_COUPON_TO_ORDER_REQUEST_SUCCESS: {
      const { entities, ids } = action.response;
      const newState = {
        ...state,
        [ids]: {
          ...state[ids],
          ...entities.order[ids]
        }
      };
      return newState;
    }

    case types.FETCH_ORDER_REQUEST_SUCCESS:
    case types.UPDATE_ORDER_REQUEST_SUCCESS:
    case types.UPDATE_ORDER_COUPON_CODE_REQUEST_SUCCESS:
    case types.COMPLETE_ORDER_REQUEST_SUCCESS: {
      const { entities, ids } = action.response;
      const orderId = ids;
      const newState = { ...state };
      const entity = entities.order[orderId];
      const order = formatOrder({ entity });

      newState[orderId] = order;

      return merge({}, newState);
    }

    case types.ADD_DESIGN_TO_ORDER_REQUEST_SUCCESS: {
      const {
        entities: { orderDesign: orderDesigns }
      } = action.response;

      const orderDesign = Object.values(orderDesigns)[0];

      const currentDesigns = getPath(
        state,
        `${[orderDesign.orderId]}.designs`,
        []
      );

      return immutableUpdate(state, {
        [orderDesign.orderId]: {
          $merge: {
            designs: [...currentDesigns, orderDesign]
          }
        }
      });
    }

    case types.UPDATE_DESIGN_IN_ORDER_REQUEST_SUCCESS: {
      const {
        entities: { orderDesign: orderDesigns }
      } = action.response;

      const orderDesign = Object.values(orderDesigns)[0];

      const currentDesigns = getPath(
        state,
        `${[orderDesign.orderId]}.designs`,
        []
      );

      const designIndex = currentDesigns
        .map(design => design.id)
        .indexOf(orderDesign.id);

      const newDesigns = [...currentDesigns];
      newDesigns.splice(designIndex, 1, {
        ...currentDesigns[designIndex],
        ...orderDesign,
        isUpdatingDesignVersion: false
      });

      return immutableUpdate(state, {
        [orderDesign.orderId]: {
          $merge: {
            designs: newDesigns
          }
        }
      });
    }

    case types.DESIGN_ORDER_DELETE_REQUEST: {
      const {
        extra: { orderDesignId, orderId }
      } = action;

      const orderDesigns = [...state[orderId].designs];
      const orderDesignIndex = orderDesigns
        .map(design => design.id)
        .indexOf(orderDesignId);
      orderDesigns[orderDesignIndex].isDeleting = true;

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            $merge: {
              designs: orderDesigns
            }
          }
        }
      });
    }

    case types.DESIGN_ORDER_DELETE_REQUEST_SUCCESS: {
      const {
        extra: { orderDesignId, orderId }
      } = action;

      const orderDesigns = state[orderId].designs;
      const updatedOrderDesigns = orderDesigns.filter(
        design => design.id !== orderDesignId
      );

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            $merge: {
              designs: updatedOrderDesigns
            }
          }
        }
      });
    }

    case types.DESIGN_ORDER_DELETE_REQUEST_FAILURE: {
      const {
        extra: { orderDesignId, orderId }
      } = action;

      const orderDesigns = [...state[orderId].designs];
      const orderDesignIndex = orderDesigns
        .map(design => design.id)
        .indexOf(orderDesignId);
      orderDesigns[orderDesignIndex].isDeleting = undefined;

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            $merge: {
              designs: orderDesigns
            }
          }
        }
      });
    }

    case types.FLAG_ORDER_DESIGNS_UPDATING_VERSION: {
      const { orderDesignIds, orderId } = action;

      const updatedOrderDesigns = cloneDeep(state[orderId].designs).map(
        orderDesign => {
          if (orderDesignIds.includes(orderDesign.id)) {
            return {
              ...orderDesign,
              isUpdatingDesignVersion: true
            };
          }
          return orderDesign;
        }
      );

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            $merge: {
              designs: updatedOrderDesigns
            }
          }
        }
      });
    }

    case types.UPDATE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST: {
      const {
        body: { id: printOptionId, orderId, orderDesignId }
      } = action.request;

      const orderDesignPrintItem = omit(action.request.body, [
        "userId",
        "teamId"
      ]);

      const currentOrderDesignIndex = state[orderId].designs.findIndex(
        design => design.id === orderDesignId
      );
      const orderDesign =
        currentOrderDesignIndex || currentOrderDesignIndex === 0
          ? { ...state[orderId].designs[currentOrderDesignIndex] }
          : {};

      const currentOptionIndex = orderDesign.printItems.findIndex(
        printItem => printItem.id === printOptionId
      );

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            designs: {
              $autoArray: {
                [currentOrderDesignIndex]: {
                  $auto: {
                    printItems: {
                      $autoArray: {
                        $splice: [[currentOptionIndex, 1, orderDesignPrintItem]]
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.UPDATE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST_SUCCESS: {
      const {
        body: { id: printOptionId, orderId, orderDesignId }
      } = action.request;

      const {
        response: {
          entities: {
            orderDesignPrintItem: { [printOptionId]: orderDesignPrintItem }
          }
        }
      } = action;

      const mergedOrderDesignPrintItem = {
        ...omit(action.request.body, ["userId", "teamId"]),
        ...orderDesignPrintItem
      };

      const currentOrderDesignIndex = state[orderId].designs.findIndex(
        design => design.id === orderDesignId
      );
      const orderDesign =
        currentOrderDesignIndex || currentOrderDesignIndex === 0
          ? { ...state[orderId].designs[currentOrderDesignIndex] }
          : {};

      const currentOptionIndex = orderDesign.printItems.findIndex(
        printItem => printItem.id === printOptionId
      );

      if (
        orderDesign.printItems[currentOptionIndex]
          .printProviderPrintOptionPricingId !==
        orderDesignPrintItem.printProviderPrintOptionPricingId
      ) {
        // when requests are made in quick succession we need to prevent the update or we can get jittering values in the input
        return state;
      }

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            designs: {
              $autoArray: {
                [currentOrderDesignIndex]: {
                  $auto: {
                    printItems: {
                      $autoArray: {
                        $splice: [
                          [currentOptionIndex, 1, mergedOrderDesignPrintItem]
                        ]
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.ADD_PRINT_ITEM_TO_ORDER_DESIGN_REQUEST: {
      const {
        body: { orderId, orderDesignId }
      } = action.request;

      const currentOrderDesignIndex = state[orderId].designs.findIndex(
        design => design.id === orderDesignId
      );

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            designs: {
              $autoArray: {
                [currentOrderDesignIndex]: {
                  $auto: {
                    isUpdating: {
                      $set: true
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.ADD_PRINT_ITEM_TO_ORDER_DESIGN_REQUEST_SUCCESS: {
      const {
        body: { orderId, orderDesignId }
      } = action.request;

      const {
        response: {
          entities: {
            orderDesignPrintItem: {
              [action.response.ids]: orderDesignPrintItem
            }
          }
        }
      } = action;

      const currentOrderDesignIndex = state[orderId].designs.findIndex(
        design => design.id === orderDesignId
      );

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            designs: {
              $autoArray: {
                [currentOrderDesignIndex]: {
                  $auto: {
                    printItems: {
                      $autoArray: {
                        $push: [orderDesignPrintItem]
                      }
                    },
                    isUpdating: {
                      $set: false
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.ADD_PRINT_ITEM_TO_ORDER_DESIGN_REQUEST_FAILURE: {
      const {
        body: { orderId, orderDesignId }
      } = action.request;

      const currentOrderDesignIndex = state[orderId].designs.findIndex(
        design => design.id === orderDesignId
      );

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            designs: {
              $autoArray: {
                [currentOrderDesignIndex]: {
                  $auto: {
                    isUpdating: {
                      $set: false
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.DELETE_PRINT_ITEM_IN_ORDER_DESIGN_REQUEST: {
      const { orderId, orderDesignId, printItemId } = action.extra;

      const currentOrderDesignIndex = state[orderId].designs.findIndex(
        design => design.id === orderDesignId
      );
      const currentPrintItemIndex = state[orderId].designs[
        currentOrderDesignIndex
      ].printItems.findIndex(printItem => printItem.id === printItemId);

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            designs: {
              $autoArray: {
                [currentOrderDesignIndex]: {
                  $auto: {
                    printItems: {
                      $autoArray: {
                        $splice: [[currentPrintItemIndex, 1]]
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }

    case types.CREATE_ORDER_PAYMENT_REQUEST: {
      const { orderId } = action.request.body;

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            isCreatingPayment: {
              $set: true
            }
          }
        }
      });
    }
    case types.CREATE_ORDER_PAYMENT_REQUEST_FAILURE: {
      const { orderId } = action.request.body;

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            isCreatingPayment: {
              $set: false
            }
          }
        }
      });
    }

    case types.CANCEL_ORDER_REQUEST_SUCCESS: {
      const { orderId, status } = action.response;

      return immutableUpdate(state, {
        [orderId]: {
          $auto: {
            status: {
              $set: status
            }
          }
        }
      });
    }

    default:
      return state;
  }
};

export default ordersReducer;
