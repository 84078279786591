import { isEmpty } from "lib";
import { teamSelector } from "state/entities/teams/teamsSelectors";

/**
 * Recursively traverse the team hierarchy to find the ids of the children for a given parent.
 * @param state Team Hierarchy state scoped by the parent of the initial or previous call.
 * @param traversalPath Array of parentIds to be navigated.
 * @param parentId UUID of the parent that we need to find children for.
 */
const findSubTeams = (state, traversalPath, parentId) => {
  // Loading any teams is either still pending (empty state) or
  // the parent has no children as null is used to indicate 'end of the hierarchy'
  if (isEmpty(state) || (isEmpty(state[parentId]) && !traversalPath.length)) {
    return [];
  }
  // We've reached the parents level and the parent has children to return
  if (state[parentId]) {
    return Object.keys(state[parentId]);
  }
  // This first id in the traversal path indicates our current level. Grab it's state and drop it from the traversal
  // path for the next recursive call.
  const currentId = traversalPath[0];
  traversalPath.shift();
  return findSubTeams(state[currentId], traversalPath, parentId);
};

/**
 * Attempt to find a teams parent in the team hierarchy. If no match is found in the hierarchy then
 * it's likely the user does not have access to the parent and null is returned.
 * @param state Redux state to query.
 * @param parentPathArr Parent path array of the team we want to find the parent for.
 */
export const parentTeamSelector = (state, parentPathArr) => {
  const teamHierarchyState = state.entities.teamHierarchy;

  // Either a programmer error or Easil team.
  if (!parentPathArr || !parentPathArr.length) return null;

  // Look for the direct parent in the hierarchy.
  let idx = parentPathArr.length - 1;
  let parentId = teamHierarchyState[parentPathArr[idx]]
    ? parentPathArr[idx]
    : null;

  // Nothing found so user does not have access to the parent.
  // We have to do this as teamSelector defaults to currentTeam
  if (!parentId) return null;

  // They have a parent in the hierarchy so fetch the details and return
  return teamSelector({ state, teamId: parentId });
};

/**
 * Select the top tier teams the user has access too. These are the highest level access
 * a user has within each org they have access too. They are not always tier 1 teams (orgs).
 * @param state Redux state to query.
 * @returns {string[]|*[]} Array of Team objects.
 */
export const topTeamsSelector = state => {
  const teamHierarchyState = state.entities.teamHierarchy;
  if (isEmpty(teamHierarchyState)) {
    // Teams haven't been fetched from API yet.
    return [];
  }
  const topLevelTeamIds = Object.keys(teamHierarchyState);
  return topLevelTeamIds.map(teamId => teamSelector({ state, teamId }));
};

/**
 * Find the sub teams of a given parent by traversing the team hierarchy state using the parents parent path.
 * @param state Team Hierarchy redux state.
 * @param parentsParentPath Array representing the parents path in the hierarchy.
 * @param parentId UUID of the parent whose direct children we want to find.
 * @returns Array of team entities.
 */
export const subTeamsSelector = (state, parentsParentPath, parentId) => {
  const teamHierarchyState = state.entities.teamHierarchy;
  if (isEmpty(teamHierarchyState)) {
    // Teams haven't been fetched from API yet.
    return [];
  }

  const hasNoParentPath = !parentsParentPath || !parentsParentPath.length;
  if (hasNoParentPath) {
    if (parentId) {
      // Attempt to find parent at the top level (Most likely Easil team)
      const subTeamIds = Object.keys(teamHierarchyState[parentId]);
      return subTeamIds.map(teamId => teamSelector({ state, teamId }));
    }
    // Return top level teams
    return Object.keys(teamHierarchyState);
  }
  // Find the root team in the parent teams parent path that the user has access to so we can traverse the
  // tree and find the required children.
  let accessiblePath = parentsParentPath;
  let accessibleRootTeamId;
  let rootTeamIdx = 0;
  while (!accessibleRootTeamId && rootTeamIdx < parentsParentPath.length) {
    if (teamHierarchyState[parentsParentPath[rootTeamIdx]]) {
      accessibleRootTeamId = parentsParentPath[rootTeamIdx];
      accessiblePath = parentsParentPath.slice(rootTeamIdx);
    }
    rootTeamIdx++;
  }
  const subTeamIds = findSubTeams(teamHierarchyState, accessiblePath, parentId);

  // Map to entities.
  return subTeamIds.map(teamId => teamSelector({ state, teamId }));
};
