import React, { Component } from "react";
import PropTypes from "prop-types";
import style from "./style.module.css";

import { Button, CreditCardForm, Loading } from "../../components";

class UpdateDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleToken = this.handleToken.bind(this);
    this.handleTokenFailure = this.handleTokenFailure.bind(this);

    this.state = {
      token: null,
      card: null
    };
  }

  handleToken(token) {
    const tokenObject = {
      id: token.id,
      object: token.object,
      type: token.type
    };

    this.setState({
      token: tokenObject,
      card: { last4: token.last4, brand: token.brand }
    });
  }

  handleTokenFailure() {
    this.setState({
      token: null,
      card: null
    });
  }

  handleUpdate() {
    const { token, promoCode } = this.state;

    this.props.onUpdate({ token, promoCode });
  }

  updateButtonLabel() {
    if (this.props.isUpdating) return <Loading />;
    return "Update Card";
  }

  handleTogglePaymentForm() {
    this.setState({ displayCardForm: !this.state.displayCardForm });
  }

  render() {
    const { onCancel, isUpdating, errors } = this.props;
    const { token } = this.state;

    return (
      <div className={style.upgradeModalWrapper}>
        <div className={style.upgradeModalPaymentDetails}>
          <h2>Payment Details</h2>
          <CreditCardForm
            onTokenSuccess={token => this.handleToken(token)}
            onTokenFailure={this.handleTokenFailure}
            disabled={isUpdating}
          />
        </div>
        {errors && (
          <div className={style.errors}>
            {errors.map((error, index) => (
              <div key={`credit-card-error-${index}`} className={style.error}>
                {error.error}: {error.value}
              </div>
            ))}
          </div>
        )}
        <div className={style.upgradeModalFooter}>
          <Button
            className={style.upgradeModalCancelButton}
            theme="gray"
            onClick={onCancel}
            disabled={isUpdating}
          >
            Cancel
          </Button>
          <Button
            className={style.upgradeModalUpdateButton}
            theme="blueSolid"
            onClick={() => this.handleUpdate()}
            disabled={!token}
          >
            {this.updateButtonLabel()}
          </Button>
        </div>
      </div>
    );
  }
}

UpdateDetailsModal.propTypes = {
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
  isUpdating: PropTypes.bool,
  errors: PropTypes.array
};

export default UpdateDetailsModal;
