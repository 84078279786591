import React, { lazy, Suspense } from "react";
import SubPageLoading from "views/pages/SubPageLoading";
const TextboxContainer = lazy(() => import("./TextboxContainer"));

export const TextboxContainerLazy = props => (
  <Suspense fallback={<SubPageLoading height={"100vh"} />}>
    <TextboxContainer {...props} />
  </Suspense>
);
TextboxContainerLazy.displayName = "TextboxContainer";

export default TextboxContainerLazy;
