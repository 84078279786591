import * as types from "state/entities/subscriptions/subscriptionsTypes";
import {
  currentSubscriptionPlan,
  currentSubscriptionSelector,
  getSubscriptionPlan
} from "state/entities/subscriptions/subscriptionsSelectors";
import { trackSubscriptionFB } from "lib/fbPixel";

const facebookPixel = store => next => action => {
  switch (action.type) {
    case types.UPDATE_SUBSCRIPTION_SUCCESS: {
      handleUpdateSubscriptionSuccess(action.request, store);
      break;
    }

    default:
    /* noop */
  }
  next(action);
};

const handleUpdateSubscriptionSuccess = (request, store) => {
  const state = store.getState();
  const currentPlan = currentSubscriptionPlan(state);
  const currentSubscription = currentSubscriptionSelector(state);

  const wasOnFreePlan =
    currentPlan.code === "BASIC" || currentSubscription.status === "TRIALLING";

  if (wasOnFreePlan) {
    const newPlan = getSubscriptionPlan(request.body.planId, state);
    // new subscriptions should have their data tracked with fb
    trackSubscriptionFB({
      planName: newPlan.name.toLowerCase(),
      value: newPlan.amount,
      intervalPeriod: newPlan.intervalPeriod.toLowerCase(),
      currency: newPlan.currency
    });
  }
};

export default facebookPixel;
