import React from "react";
import { connect } from "react-redux";
import PurchaseCollectionModal from "./PurchaseCollectionModal";
import { designsForCollectionIdCategorisedSelector } from "state/entities/designs/designsSelectors";
import {
  collectionApiSelector,
  collectionSubscriptionCodeSelector,
  collectionPricingSelector
} from "state/api/collection/collectionApiSelectors";
import { currentTeamSelector } from "state/entities/teams/teamsSelectors";
import { fetchCollectionDesignsWithPrice } from "state/entities/designs/designsActions";
import {
  moveToWorkspace,
  moveToSubscriptionCode
} from "state/ui/navigation/navigationActions";
import { currentSubscriptionPlan } from "state/entities/subscriptions/subscriptionsSelectors";
import { userIsAdmin } from "state/currentUser/currentUserSelectors";
import { createDraftFromTemplate } from "state/entities/entitiesActions";
import { copyCollectionToDraftsAndOpenSelectedInEditor } from "state/ui/collectionPreviewModal/collectionPreviewModalActions";
import CreateDraftModalContainer from "views/components/CollectionPreviewModal/CreateDraftModal/CreateDraftModalContainer";
import BuyCollectionModalContainer from "views/components/PurchaseCollection/BuyCollectionModalContainer";
import PATHS from "routes/paths";
import Subscriptions from "lib/subscriptions";
import { fetchAllSizes as fetchAllCatalogueSizes } from "state/entities/catalogueSizes/catalogueSizesActions";
import { userTracker } from "lib";

export class PurchaseCollectionModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpenBuyCollectionModal = this.handleOpenBuyCollectionModal.bind(
      this
    );

    this.state = {
      selectedDesign: {},
      modalComponentOpen: PurchaseCollectionModal
    };
  }

  componentDidMount() {
    // we should fetch collection price
    this.props.fetchCollectionDesignsWithPrice(this.props.collectionId);
    this.props.fetchCatalogueSizes({
      teamId: this.props.currentTeam.id,
      isFilter: false
    });
  }

  componentDidUpdate(prevProps) {
    // return to workspace if error
    const {
      collectionId,
      collectionPricing,
      currentUserSubscriptionCode,
      collectionDesignsCategorised,
      canAccessCollection,
      collectionError,
      onCloseModal
    } = this.props;

    if (collectionError) {
      onCloseModal();
    }
    if (
      (!prevProps.collectionDesignsCategorised ||
        !prevProps.collectionDesignsCategorised.length) &&
      collectionDesignsCategorised &&
      collectionDesignsCategorised.length
    ) {
      this.setState({
        selectedDesign: collectionDesignsCategorised[0].designs[0]
      });

      // Tracks when user opens Purchase Collection Modal
      userTracker.track({
        event: userTracker.events.purchaseCollectionView,
        properties: {
          collection_id: collectionId,
          price: collectionPricing,
          plan: currentUserSubscriptionCode
        }
      });
    }

    // set the modal component based on user v collection subscription
    if (
      canAccessCollection &&
      this.state.modalComponentOpen !== CreateDraftModalContainer
    ) {
      this.setState({
        modalComponentOpen: CreateDraftModalContainer
      });
    }
  }

  handleOpenBuyCollectionModal() {
    this.setState({
      modalComponentOpen: BuyCollectionModalContainer
    });

    // tracks when user clicks the 'Buy Collection' button
    userTracker.track({
      event: userTracker.events.collectionProductAdded,
      properties: {
        collection_id: this.props.collectionId,
        price: this.props.collectionPricing,
        plan: this.props.currentUserSubscriptionCode
      }
    });
  }

  render() {
    const Modal = this.state.modalComponentOpen;
    return (
      <Modal
        {...this.props}
        selectedDesign={this.state.selectedDesign}
        handleOpenBuyCollectionModal={this.handleOpenBuyCollectionModal}
      />
    );
  }
}

PurchaseCollectionModalContainer.displayName =
  "PurchaseCollectionModalContainer";

const mapStateToProps = (state, ownProps) => {
  const {
    categories: collectionDesignsCategorised,
    sizeCount
  } = designsForCollectionIdCategorisedSelector({
    state,
    collectionId: ownProps.collectionId,
    isCatalogue: true,
    scope: "subscription"
  });
  const isAdmin = userIsAdmin(state);
  const currentTeam = currentTeamSelector(state);
  const currentTeamRegion = currentTeam.country;
  const collectionApiState = collectionApiSelector(state);
  const currentUserSubscriptionCode = currentSubscriptionPlan(state).code;
  const collectionSubscriptionCode = collectionSubscriptionCodeSelector(
    state,
    ownProps.collectionId
  );
  const subscription = Subscriptions.get(currentUserSubscriptionCode);
  const canAccessCollection = subscription.canAccess(
    collectionSubscriptionCode
  );
  const isPurchaseCollectionModal =
    (ownProps.isPurchaseCollectionModal !== undefined &&
      ownProps.isPurchaseCollectionModal) ||
    (PATHS.isPurchaseCollection(window.location.pathname) &&
      !canAccessCollection);
  const collectionPricing = collectionPricingSelector(
    state,
    ownProps.collectionId
  );

  return {
    canAccessCollection,
    collectionDesignsCategorised,
    collectionError: collectionApiState.error,
    collectionPricing,
    collectionSubscriptionCode,
    currentTeam,
    currentTeamRegion,
    currentUserSubscriptionCode,
    isAdmin,
    isLoading: collectionApiState.isFetching,
    isPurchaseCollectionModal,
    sizeCount
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const onCloseModal =
    ownProps.onCloseModal || (() => dispatch(moveToWorkspace()));
  return {
    onCloseModal,
    fetchCollectionDesignsWithPrice: args =>
      dispatch(fetchCollectionDesignsWithPrice(args)),
    onUpgrade: args => {
      dispatch(onCloseModal);
      dispatch(moveToSubscriptionCode(args));
    },
    createDraftFromTemplate: args => dispatch(createDraftFromTemplate(args)),
    copyCollectionToDrafts: args =>
      dispatch(copyCollectionToDraftsAndOpenSelectedInEditor(args)),
    fetchCatalogueSizes: args => dispatch(fetchAllCatalogueSizes(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseCollectionModalContainer);
