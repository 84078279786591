import React from "react";
import PropTypes from "prop-types";
import { easilGrayXDark } from "./style.module.css";

const TextCaretIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color
}) => (
  <svg
    className={className}
    style={style}
    data-no-pointer={noPointer}
    width={width || size || 24}
    height={height || size || 24}
    viewBox="2 2 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        stroke={color || easilGrayXDark}
        stroke-width="2"
        d="M12 4v16M9 3h1a2 2 0 0 1 2 2 2 2 0 0 1 2-2h1m0 18h-1a2 2 0 0 1-2-2 2 2 0 0 1-2 2H9M12 8h6a3 3 0 0 1 3 3v2a3 3 0 0 1-3 3h-6m-3 0H6a3 3 0 0 1-3-3v-2a3 3 0 0 1 3-3h3"
      />
    </g>
  </svg>
);
TextCaretIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TextCaretIcon;
