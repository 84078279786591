import { createSelector } from "reselect";
import { invoicesApiSelector } from "state/api/invoices/invoicesApiSelectors";
import { isEmpty } from "lib";
import { immutableUpdate } from "lib/immutableUpdate";
import { getMonthName } from "lib/timeHelpers";
import dateFns from "date-fns";

const categoriseInvoicesByDate = ({ invoices }) => {
  let categorisedInvoices = {};

  invoices.forEach(invoice => {
    const date = dateFns.parse(invoice.createdAt);
    const year = dateFns.format(date, "YYYY");
    const month = dateFns.format(date, "MM");
    categorisedInvoices = immutableUpdate(categorisedInvoices, {
      $auto: {
        [year]: {
          $auto: {
            [month]: {
              $auto: {
                label: { $set: getMonthName(parseInt(month)) },
                key: { $set: parseInt(month) },
                invoices: {
                  $autoArray: {
                    $push: [invoice]
                  }
                }
              }
            }
          }
        }
      }
    });
  });

  return categorisedInvoices;
};

export const invoicesSelector = ({ state }) => state.entities.invoices;

export const processInvoicesAllSelector = (invoicesApi, invoicesEntities) => {
  if (!invoicesApi.pages || isEmpty(invoicesApi.pages)) return [];
  const allIds = Object.values(invoicesApi.pages).reduce(
    (joinedIds, { ids }) => {
      return joinedIds.concat(ids);
    },
    []
  );
  const matchingEntities = allIds.map(id => invoicesEntities[id]);

  const categorisedInvoices = categoriseInvoicesByDate({
    invoices: matchingEntities
  });

  return categorisedInvoices;
};

export const invoicesAllSelector = createSelector(
  [invoicesApiSelector, invoicesSelector],
  processInvoicesAllSelector
);
