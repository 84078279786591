import React from "react";
import { connect } from "react-redux";
import Invoices from "views/components/Invoices";
import {
  fetchInvoicesIfNeeded,
  downloadAllInvoiceFiles
} from "state/entities/invoices/invoicesActions";
import { invoicesAllSelector } from "state/entities/invoices/invoicesSelectors";
import { isAnyInvoicesFetching } from "state/api/invoices/invoicesApiSelectors";

export const InvoicesContainer = props => <Invoices {...props} />;

const mapStateToProps = state => {
  return {
    invoices: invoicesAllSelector({ state }),
    isLoading: isAnyInvoicesFetching({ state })
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchInvoicesIfNeeded: args => dispatch(fetchInvoicesIfNeeded(args)),
    downloadAllInvoiceFiles: args => dispatch(downloadAllInvoiceFiles(args))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesContainer);
