import { createSelector } from "reselect";
import Subscriptions from "lib/subscriptions";
import { currentTeamSelector } from "state/entities/teams/teamsSelectors";
import { isNil, getPath } from "lib";

const subscriptionsSelector = state => state.entities.subscriptions;
const subscriptionPlansSelector = state => state.entities.subscriptionPlans;
const subscriptionIdApiSelector = state =>
  getPath(state, "api.subscriptions.pages.1.ids");

const processCurrentSubscriptionSelector = (
  subscriptions,
  subscriptionPlans,
  subscriptionId
) => {
  const subscription = subscriptions[subscriptionId];

  const nextSubscriptionId = getPath(subscription, "nextSubscriptionId");

  if (!isNil(nextSubscriptionId)) {
    subscription.nextSubscription = processGetSubscriptionPlan(
      nextSubscriptionId,
      subscriptionPlans
    );
  }

  return subscription;
};

export const currentSubscriptionSelector = createSelector(
  [subscriptionsSelector, subscriptionPlansSelector, subscriptionIdApiSelector],
  processCurrentSubscriptionSelector
);

const processCurrentSubscriptionPlan = (
  currentSubscription,
  subscriptionPlans
) => {
  if (!currentSubscription) return null;

  return subscriptionPlans[currentSubscription.planId];
};

export const currentSubscriptionPlan = createSelector(
  [currentSubscriptionSelector, subscriptionPlansSelector],
  processCurrentSubscriptionPlan
);

const processGetEasilCatalogueDefaultSize = (plan, currentTeam) => {
  const subscription = Subscriptions.get(plan.code);

  return subscription.getEasilCatalogueDefaultSize({
    region: currentTeam.country
  });
};

export const getEasilCatalogueDefaultSize = createSelector(
  [currentSubscriptionPlan, currentTeamSelector],
  processGetEasilCatalogueDefaultSize
);

const planIdPassThrough = (planId, state) => planId;

const processGetSubscriptionPlan = (planId, subscriptionPlans) =>
  subscriptionPlans[planId];

export const getSubscriptionPlan = createSelector(
  [planIdPassThrough, (planId, state) => subscriptionPlansSelector(state)],
  processGetSubscriptionPlan
);

const processSubscriptionCodesToShow = plan => {
  if (plan) {
    const subscription = Subscriptions.get(plan.code);

    return subscription.getListOfSubscriptionsAbove();
  } else {
    return Subscriptions.SUBSCRIPTIONS_LIST;
  }
};

export const subscriptionCodesToShow = createSelector(
  [currentSubscriptionPlan],
  processSubscriptionCodesToShow
);
