import Element from "state/ui/editor/elements/Element";
import { omit, pick } from "lib/lodash";
import { domParser as defaultDomParser } from "lib/defaultDomParser";
import { cssAttributesToCssString } from "lib/styling/css";
import {
  getNodesInRange,
  getAllLinkedContent,
  removeStyleFromSelection,
  getAllFontSizeContent,
  removeFontSizeFromSelection,
  toggleMarkOnSelection
} from "lib/DOMNodeUtils";
import { createDOMElementFromHTMLString } from "lib/tests/DOMNodeUtils/testSetupFunctions";
import {
  createStyleElement,
  applyParagraphStyling
} from "views/components/Editor/elements/textboxStylingUtils";
import {
  getFontFamilyUpdateSideEffects,
  getValueUpdateSideEffects
} from "./textboxUtils";

const DEFAULT_PX = 16;

const getEmValueFromElement = element => {
  if (element.parentNode) {
    const parentFontSize = parseFloat(
      window.getComputedStyle(element.parentNode).fontSize
    );
    const elementFontSize = parseFloat(
      window.getComputedStyle(element).fontSize
    );
    return (elementFontSize / parentFontSize) * elementFontSize;
  }
  return DEFAULT_PX;
};

class TextBoxElement extends Element {
  static RESTRICTIONS = [
    "removable",
    "visibility",
    "opacity",
    "angle",
    "duplicate",
    "textEdit",
    "position",
    "color",
    "fontFamily",
    "fontSize",
    "textDecoration",
    "textShadow",
    "textCurve",
    "textMask",
    "letterSpacing",
    "lineHeight",
    "textAlign",
    "textBoundary"
  ];

  getDomValuesFromSimulatedAttributes(simulatedAttributes) {
    const element = Object.assign({}, this, simulatedAttributes);

    const {
      lineHeight,
      letterSpacing,
      paragraphSpacing,
      width,
      height,
      textAlign,
      color,
      fontSize,
      fontFamily,
      bold,
      opacity,
      textDecoration,
      textCase
    } = element;
    const tempId = `simulated-${this.uniqueId}`;

    const cssAttributes = {
      position: "absolute",
      top: 0,
      left: 0,
      "line-height": `${lineHeight}em`,
      "letter-spacing": `${letterSpacing / 1000}em`,
      width: width === "default" ? width : `${width}px`,
      height: height === "default" ? height : `${height}px`,
      "text-align": textAlign,
      color: color,
      "font-size": `${Math.floor(fontSize)}px`,
      "font-family": fontFamily,
      "font-weight": bold,
      opacity: opacity,
      "text-decoration": textDecoration,
      "word-break": "break-word",
      visibility: "hidden",
      "--paragraph-spacing": paragraphSpacing,
      "--line-height": lineHeight,
      "--font-size": fontSize,
      "text-transform": textCase
    };

    const cssString = cssAttributesToCssString(cssAttributes);

    const div = document.createElement("div");
    div.setAttribute("id", tempId);

    const text = document.createElement("span");

    text.innerHTML = element.value;

    div.appendChild(text);

    div.style.cssText = cssString;
    document.body.appendChild(div);

    createStyleElement(tempId);

    applyParagraphStyling(div, tempId);

    const textValues = pick(text, [
      "offsetHeight",
      "offsetWidth",
      "scrollHeight",
      "scrollWidth"
    ]);
    const divValues = pick(div, [
      "offsetHeight",
      "offsetWidth",
      "scrollHeight",
      "scrollWidth"
    ]);

    const textLineHeight = parseFloat(getComputedStyle(text).lineHeight);
    const textLineCount = Math.floor(text.offsetHeight / textLineHeight);
    const textCharacterCount = text.textContent.length;
    const textLetterSpacing =
      parseFloat(getComputedStyle(text).letterSpacing) || 0;
    const emRatio = getEmValueFromElement(text);

    const domValues = {
      text: {
        ...textValues,
        lineCount: textLineCount,
        lineHeight: textLineHeight,
        characterLength: textCharacterCount,
        letterSpacing: textLetterSpacing,
        emRatio
      },
      div: divValues
    };

    document.body.removeChild(div);

    return domValues;
  }

  getHeight(simulatedAttributes) {
    const element = Object.assign({}, this, simulatedAttributes);

    const {
      lineHeight,
      letterSpacing,
      paragraphSpacing,
      width,
      textAlign,
      color,
      fontSize,
      fontFamily,
      bold,
      opacity,
      textDecoration,
      textCase
    } = element;
    const tempId = `simulated-${element.uniqueId}`;

    const cssAttributes = {
      position: "absolute",
      top: 0,
      left: 0,
      "line-height": `${lineHeight}em`,
      "letter-spacing": `${letterSpacing / 1000}em`,
      width: `${width}px`,
      "text-align": textAlign,
      color: color,
      "font-size": `${fontSize}px`,
      "font-family": fontFamily,
      "font-weight": bold,
      opacity: opacity,
      "text-decoration": textDecoration,
      "word-break": "break-word",
      "--paragraph-spacing": paragraphSpacing,
      "--line-height": lineHeight,
      "--font-size": fontSize,
      "text-transform": textCase
    };

    const cssString = cssAttributesToCssString(cssAttributes);

    const div = document.createElement("div");
    div.setAttribute("id", tempId);

    div.innerHTML = element.value;
    div.style.cssText = cssString;
    document.body.appendChild(div);

    createStyleElement(tempId);

    applyParagraphStyling(div, tempId);

    const height = div.clientHeight;

    document.body.removeChild(div);

    return height;
  }

  get canChangeHeight() {
    return true;
  }

  updateAttributes(attributes, domParser = defaultDomParser) {
    let sideEffects = {};

    const isBoundaryLocked = this.restrictions.includes("textBoundary");

    if (attributes.lineHeight) {
      if (!isBoundaryLocked) {
        sideEffects.height = this.getHeight({
          lineHeight: attributes.lineHeight
        });
      }
    }

    if (attributes.fontSize) {
      if (this.value.includes("font-size:")) {
        // contains rich text font-size changes
        const textboxElement = createDOMElementFromHTMLString(this.value);

        textboxElement.style["position"] = "absolute";
        textboxElement.contentEditable = "true";

        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(textboxElement);

        selection.removeAllRanges();
        selection.addRange(range);

        const nodesInRange = getNodesInRange(textboxElement);

        const fontSizeNodes = getAllFontSizeContent(nodesInRange.allNodes);

        // only strip color styling if fontSizeNodes are present
        if (fontSizeNodes.length) {
          removeFontSizeFromSelection({
            textboxElement,
            styledNodes: fontSizeNodes,
            nodesInRange
          });
          // reassign the new stripped innerHTML value to the element preview
          sideEffects.value = textboxElement.innerHTML;
          sideEffects.displayValue = textboxElement.innerHTML;
        }

        textboxElement.remove();
      }

      if (!isBoundaryLocked) {
        /* we can allow the height to increase if boundary is not locked */
        sideEffects.height = this.getHeight({ fontSize: attributes.fontSize });
      } else {
        // this is the values for the text without the fontSize change
        const domValuesWithOriginalFontSize = this.getDomValuesFromSimulatedAttributes(
          {
            height: "auto",
            value: sideEffects.value
          }
        );

        // the values with the new font but width constrained to get the height
        const domValuesNewFontSizeWidthConstrained = this.getDomValuesFromSimulatedAttributes(
          {
            height: "auto",
            fontSize: attributes.fontSize,
            value: sideEffects.value
          }
        );

        //
        const heightBoundingAllowanceScale = 1.05;

        // check if we are adding a new line
        const isOriginalMultiLine =
          domValuesWithOriginalFontSize.text.lineCount >= 2; // anything under 2 is a single line
        const isExceedingHeightBounds =
          this.height * heightBoundingAllowanceScale <
          domValuesNewFontSizeWidthConstrained.text.offsetHeight;
        const numberOfLinesFittingNewFontSize = Math.floor(
          this.height / domValuesNewFontSizeWidthConstrained.text.lineHeight
        );

        // if multiple lines and would create a new line
        if (isExceedingHeightBounds) {
          // prevent the fontSize from being changed since finding max with height values would require brute force
          sideEffects.fontSize = this.fontSize;
        }

        // is single line and can not add any more lines
        if (
          !isOriginalMultiLine &&
          numberOfLinesFittingNewFontSize <
            domValuesNewFontSizeWidthConstrained.text.lineCount
        ) {
          // only single line so check width changes
          const domValuesNewFontSize = this.getDomValuesFromSimulatedAttributes(
            {
              width: "auto",
              height: "auto",
              fontSize: attributes.fontSize
            }
          );

          const isExceedingBounds =
            domValuesNewFontSize.text.offsetWidth > this.width;

          if (isExceedingBounds) {
            // scale the fontSize to the maximum that will fit in bounds
            sideEffects.fontSize = Math.floor(
              (this.fontSize / domValuesWithOriginalFontSize.text.offsetWidth) *
                this.width
            );
          }
        }
      }
    }

    if (attributes.value) {
      if (!attributes.type === "vectortext") {
        sideEffects.height = this.getHeight({ value: attributes.displayValue });
      }

      // always strip mark tags out before saving
      if (attributes.value.includes("<mark")) {
        // contains mark tags, remove them
        const textboxElement = createDOMElementFromHTMLString(attributes.value);

        textboxElement.style["position"] = "absolute";
        textboxElement.contentEditable = "true";

        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(textboxElement);

        selection.removeAllRanges();
        selection.addRange(range);

        toggleMarkOnSelection(textboxElement);

        // reassign the new stripped innerHTML value to the element preview
        sideEffects.value = textboxElement.innerHTML;

        textboxElement.remove();
      }

      // check if the textbox is all the same font family
      const value = sideEffects.value || attributes.value;
      if (value && value.includes("font-family:")) {
        sideEffects = getValueUpdateSideEffects({ sideEffects, value });
      }
    }

    /* Adjust the element height if the width has been changed */
    if (attributes.hasOwnProperty("width")) {
      sideEffects.height = this.getHeight({ width: attributes.width });
    }

    if (attributes.hasOwnProperty("fontFamily") && isBoundaryLocked) {
      if (isBoundaryLocked) {
        const newValues = this.getDomValuesFromSimulatedAttributes({
          width: this.width,
          height: this.height,
          fontFamily: attributes.fontFamily
        });

        const widthScaleFactor = this.width / newValues.text.offsetWidth;
        const heightScaleFactor = this.height / newValues.text.offsetHeight;

        // the scale should be the biggest reduction to get both height and width to fit
        const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);

        // the fontSize should be adjusted by the scale factor
        sideEffects.fontSize =
          Math.floor(this.fontSize * scaleFactor * 10) / 10;
      } else {
        sideEffects = getFontFamilyUpdateSideEffects({
          sideEffects,
          value: this.value,
          attributes: {
            ...this,
            ...attributes
          },
          getHeight: this.getHeight
        });
      }
    }

    if (attributes.hasOwnProperty("letterSpacing")) {
      if (isBoundaryLocked) {
        // this is the values for the text without the letterSpacing change
        const domValuesWithOriginalLetterSpacing = this.getDomValuesFromSimulatedAttributes(
          {
            height: "auto",
            width: this.width,
            letterSpacing: this.letterSpacing
          }
        );

        // the values with the new letter spacing but width constrained to get the height
        const domValuesNewLetterSpacingWidthConstrained = this.getDomValuesFromSimulatedAttributes(
          {
            height: "auto",
            width: this.width,
            letterSpacing: attributes.letterSpacing
          }
        );

        const heightBoundingAllowanceScale = 1.05;

        // check if we are adding a new line
        const isCreatingNewLine =
          domValuesWithOriginalLetterSpacing.text.lineCount <
          domValuesNewLetterSpacingWidthConstrained.text.lineCount;
        const isExceedingHeightBounds =
          this.height * heightBoundingAllowanceScale <
          domValuesNewLetterSpacingWidthConstrained.text.offsetHeight;
        const numberOfLinesFittingNewFontSize = Math.floor(
          this.height /
            domValuesNewLetterSpacingWidthConstrained.text.lineHeight
        );

        // if multiple lines and would create a new line
        if (
          (isExceedingHeightBounds || // height can not be extended any further
            isCreatingNewLine) &&
          numberOfLinesFittingNewFontSize <
            domValuesNewLetterSpacingWidthConstrained.text.lineCount // can not fit new line count
        ) {
          const domValuesWithNoLetterSpacing = this.getDomValuesFromSimulatedAttributes(
            {
              height: "auto",
              width: this.width,
              letterSpacing: 0
            }
          );

          const whiteSpace =
            this.width - domValuesWithNoLetterSpacing.text.offsetWidth;

          const pxPerEm = domValuesWithNoLetterSpacing.text.emRatio / 1000;

          const maxLetterSpacing =
            whiteSpace /
              domValuesWithNoLetterSpacing.text.characterLength /
              pxPerEm -
            1;

          const newLetterSpacing =
            Math.floor(maxLetterSpacing || 0) >= this.letterSpacing
              ? maxLetterSpacing
              : this.letterSpacing;

          sideEffects.letterSpacing = Math.floor(newLetterSpacing || 0);
        }
      } else {
        // manage the height adjustment needed after letter spacing has changed
        sideEffects.height = this.getHeight({ ...attributes, ...sideEffects });
      }
    }
    if (attributes.hasOwnProperty("paragraphSpacing")) {
      if (isBoundaryLocked) {
        // this is the values for the text without the paragraphSpacing change
        const domValuesWithOriginalLetterSpacing = this.getDomValuesFromSimulatedAttributes(
          {
            height: "auto",
            width: this.width,
            paragraphSpacing: this.paragraphSpacing
          }
        );

        // the values with the new letter spacing but width constrained to get the height
        const domValuesNewLetterSpacingWidthConstrained = this.getDomValuesFromSimulatedAttributes(
          {
            height: "auto",
            width: this.width,
            paragraphSpacing: attributes.paragraphSpacing
          }
        );

        const heightBoundingAllowanceScale = 1.05;

        // check if we are adding a new line
        const isCreatingNewLine =
          domValuesWithOriginalLetterSpacing.text.lineCount <
          domValuesNewLetterSpacingWidthConstrained.text.lineCount;
        const isExceedingHeightBounds =
          this.height * heightBoundingAllowanceScale <
          domValuesNewLetterSpacingWidthConstrained.text.offsetHeight;
        const numberOfLinesFittingNewFontSize = Math.floor(
          this.height /
            domValuesNewLetterSpacingWidthConstrained.text.lineHeight
        );

        // if multiple lines and would create a new line
        if (
          (isExceedingHeightBounds || // height can not be extended any further
            isCreatingNewLine) &&
          numberOfLinesFittingNewFontSize <
            domValuesNewLetterSpacingWidthConstrained.text.lineCount // can not fit new line count
        ) {
          const domValuesWithNoParagraphSpacing = this.getDomValuesFromSimulatedAttributes(
            {
              height: "auto",
              width: this.width,
              paragraphSpacing: 0
            }
          );

          const whiteSpace =
            this.width - domValuesWithNoParagraphSpacing.text.offsetWidth;

          const pxPerEm = domValuesWithNoParagraphSpacing.text.emRatio / 1000;

          const maxParagraphSpacing =
            whiteSpace /
              domValuesWithNoParagraphSpacing.text.characterLength /
              pxPerEm -
            1;

          const newParagraphSpacing =
            Math.floor(maxParagraphSpacing || 0) >= this.paragraphSpacing
              ? maxParagraphSpacing
              : this.paragraphSpacing;

          sideEffects.paragraphSpacing = Math.floor(newParagraphSpacing || 0);
        }
      } else {
        // manage the height adjustment needed after paragraph spacing has changed
        sideEffects.height = this.getHeight({ ...attributes, ...sideEffects });
      }
    }

    if (this.maskImage) {
      if (attributes.height && attributes.height > this.maskImage.height) {
        const scale = attributes.height / this.maskImage.height;
        sideEffects.maskImage = {
          ...this.maskImage,
          top: 0,
          height: this.maskImage.height * scale,
          width: this.maskImage.width * scale
        };
      }

      if (attributes.width && attributes.width > this.maskImage.width) {
        const scale = attributes.width / this.maskImage.width;
        sideEffects.maskImage = {
          ...this.maskImage,
          left: 0,
          height: this.maskImage.height * scale,
          width: this.maskImage.width * scale
        };
      }
    }

    if (attributes.hasOwnProperty("link")) {
      const textboxElement = createDOMElementFromHTMLString(this.value);

      textboxElement.style["position"] = "absolute";
      textboxElement.contentEditable = "true";

      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(textboxElement);

      selection.removeAllRanges();
      selection.addRange(range);

      const nodesInRange = getNodesInRange(textboxElement);
      const linkedNodes = getAllLinkedContent(nodesInRange.allNodes);

      // only strip inline links if present
      if (linkedNodes.length) {
        removeStyleFromSelection({
          textboxElement,
          styledNodes: linkedNodes,
          nodesInRange,
          styleName: "anchor"
        });
        // reassign the new stripped innerHTML value to the element preview
        sideEffects.value = textboxElement.innerHTML;
      }

      textboxElement.remove();
    }

    return new this.constructor({
      ...this,
      ...attributes,
      ...sideEffects
    });
  }

  applyResizeSideEffects(elementResized, scale) {
    /* Adjust the text mask image if text grow bigger than image */
    if (this.maskImage) {
      if (elementResized.height > elementResized.maskImage.height) {
        const scale = elementResized.height / elementResized.maskImage.height;
        elementResized.maskImage = {
          ...elementResized.maskImage,
          top: 0,
          height: elementResized.maskImage.height * scale,
          width: elementResized.maskImage.width * scale
        };
      }

      if (elementResized.width > elementResized.maskImage.width) {
        const scale = elementResized.width / elementResized.maskImage.width;
        elementResized.maskImage = {
          ...elementResized.maskImage,
          left: 0,
          height: elementResized.maskImage.height * scale,
          width: elementResized.maskImage.width * scale
        };
      }
    }

    return elementResized;
  }

  removeTextMask() {
    return new this.constructor({
      ...omit(this, "maskImage")
    });
  }

  addTextMask(imageElement) {
    /* If textBox is bigger than image, we scale the image to fit, but never shrinks it(the number 1 param below) */
    const scaleFactor = Math.max(
      this.height / imageElement.srcHeight,
      this.width / imageElement.srcWidth
    );

    const imageHeight = imageElement.srcHeight * scaleFactor;
    const imageWidth = imageElement.srcWidth * scaleFactor;

    const newTop = (this.height - imageHeight) / 2;
    const newLeft = (this.width - imageWidth) / 2;

    const maskImage = {
      id: imageElement.id || imageElement.mediaId,
      name: imageElement.name,
      price: imageElement.price,
      type: "image",
      teamImage: true,
      folderId: null,
      folderName: null,
      source: "easil",
      originalSrc: imageElement.originalSrc,
      src: imageElement.previewSrc,
      srcWidth: imageElement.srcWidth,
      srcHeight: imageElement.srcHeight,
      thumbSrc: imageElement.thumbSrc,
      previewSrc: imageElement.previewSrc,
      referenceId: null,
      priority: 99999,
      tagList: [],
      category: "image",
      createdBy: "Stacie Ferguson",
      createdAt: "2018-04-23T15:22:28.209+10:00",
      approvedAt: null,
      approvedBy: null,
      archivedAt: null,
      teams: [],
      palette: imageElement.palette,
      favorite: false,
      top: newTop,
      height: imageHeight,
      width: imageWidth,
      left: newLeft,
      scale: 1,
      filters: "64646464646464064",
      opacity: 1,
      duration: imageElement.duration
    };

    // include smart image label if present
    if (imageElement.label) {
      maskImage.label = imageElement.label;
    }

    return new this.constructor({
      ...this,
      maskImage: maskImage
    });
  }

  /* Textbox elements only, part of the refactory */
  updateTextMask({ maskImage }) {
    return new this.constructor({
      ...this,
      maskImage: maskImage
    });
  }

  updateImageInstructions({ imageInstructions }) {
    return new this.constructor({
      ...this,
      imageInstructions
    });
  }
}

export default TextBoxElement;
