import React from "react";
import PropTypes from "prop-types";

const TableBorderSideIcon = ({
  noPointer,
  className,
  width,
  size,
  height,
  style,
  color,
  title,
  direction = "top"
}) => {
  const directionToAngle = {
    left: -90,
    top: 0,
    right: 90,
    bottom: 180
  };

  const finalStyle = {
    transform: `rotate(${directionToAngle[direction] || 0}deg)`,
    ...style
  };
  return (
    <svg
      className={className}
      style={finalStyle}
      data-no-pointer={noPointer}
      width={width || size || 24}
      height={height || size || 24}
      viewBox="0 0 14 12"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="title"
    >
      <title>{title}</title>
      <g fill="none" stroke="none" fillRule="evenodd">
        <g fill={color || "#CCC"}>
          <path d="M3 10h2v2H3zM6 10h2v2H6zM9 10h2v2H9zM12 10h2v2h-2zM0 10h2v2H0z" />
        </g>
        <path fill={color || "#333"} d="M0 0h14v2H0z" />
      </g>
    </svg>
  );
};
TableBorderSideIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};

export default TableBorderSideIcon;
