import { getTemplateThumbnail } from "lib";
import { TEMPLATE_SIZES_ORDER } from "lib/constants";

const catalogueSizesArray = sizes => Object.keys(sizes).map(id => sizes[id]);

const sizeCategoryGroups = sizes => {
  const categoryList = catalogueSizesArray(sizes)
    .map(size => size.category)
    .map(category => category);
  return Array.from(new Set(categoryList)).sort();
};

const categoryAdditionalSizes = {
  Adverts: ["instagram_stories", "instagram1080"],
  Documents: ["poster457610", "poster"]
};

export const sizeCategoryObject = (size, region) => {
  const {
    name,
    width,
    height,
    widthInMm,
    heightInMm,
    templateType,
    widthInInches,
    heightInInches,
    templateCode
  } = size;
  return {
    id: size.templateCode,
    templateCode,
    thumbnailUrl: getTemplateThumbnail(templateCode, region),
    templateType,
    name,
    width,
    height,
    widthInMm,
    heightInMm,
    widthInInches,
    heightInInches
  };
};

const categoriesByCategoryGroup = (sizes, category, region) => {
  const categories = catalogueSizesArray(sizes).filter(
    size => size.category === category
  );

  if (Object.keys(categoryAdditionalSizes).includes(category)) {
    categoryAdditionalSizes[category].map(
      designSize => sizes[designSize] && categories.push(sizes[designSize])
    );
  }

  const updatedCategories = categories.filter(size =>
    TEMPLATE_SIZES_ORDER[category].includes(size.templateCode)
  );

  // sort the categories using the TEMPLATE SIZES ORDER
  updatedCategories.sort(
    (a, b) =>
      TEMPLATE_SIZES_ORDER[category].indexOf(a.templateCode) -
      TEMPLATE_SIZES_ORDER[category].indexOf(b.templateCode)
  );

  return updatedCategories.map(size => sizeCategoryObject(size, region));
};

export const sizesGroupedByCategoriesSelector = (sizes, region) => {
  if (!sizes) return [];

  const groups = sizeCategoryGroups(sizes);
  const categories = groups.map(category => ({
    name: category,
    sizes: categoriesByCategoryGroup(sizes, category, region)
  }));
  const [custom] = categories.splice(
    categories.findIndex(category => category.name === "Custom"),
    1
  );
  if (custom) categories.push(custom);
  return categories;
};

export const sizeFilterLabelSelector = (sizes, templateCode) => {
  const size = catalogueSizesArray(sizes).find(
    size => size.templateCode === templateCode
  );
  return size ? size.name : null;
};

export const categoryFilterLabelSelector = (categories, categoryId) => {
  const category = catalogueSizesArray(categories).find(
    category => category.id === categoryId
  );
  return category ? category.name : null;
};
