import React, { Component } from "react";
import { H1, Input, Button, ImageDropZone } from "views/components";
import CheckIcon from "views/components/icons/CheckIcon";
import style from "./style.module.css";
import InviteToTeamFormContainer from "views/components/inviteToTeamForm/InviteToTeamFormContainer";

class SignupTeamDetails extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTeamNameChange = this.handleTeamNameChange.bind(this);

    this.state = {
      teamName: null,
      file: null,
      teamNameError: null
    };
  }

  handleSubmit() {
    const { teamName, file } = this.state;
    const {
      isOrganisationCreated,
      onCreateOrganisation,
      inviteToTeamAndRedirect
    } = this.props;

    if (isOrganisationCreated) {
      inviteToTeamAndRedirect({ redirectTo: "/team" });
      return;
    }

    const organisation = {
      name: teamName,
      setAsDefault: true
    };

    if (file) {
      organisation.logo = file;
    }

    onCreateOrganisation({ organisation });
  }

  handleTeamNameChange(event) {
    const { value } = event.target;

    this.setState({
      teamName: value,
      teamNameError: value.length > 50 ? "Max of 50 characters." : null
    });
  }

  canSubmitForm() {
    const {
      inviteToTeamForm,
      isCreatingOrganisation,
      isSwitchingToken
    } = this.props;
    const { teamName, teamNameError } = this.state;

    if (!teamName) {
      return false;
    }

    if (teamNameError) {
      return false;
    }

    if (isCreatingOrganisation) {
      return false;
    }

    if (isSwitchingToken) {
      return false;
    }

    return !["INVALID", "LOADING"].includes(inviteToTeamForm.status);
  }

  render() {
    const { isOrganisationCreated } = this.props;

    return (
      <section className={style.content}>
        <div className={style.col1}>
          <H1 className={style.title}>Team Details</H1>
          <Input
            disabled={this.props.isOrganisationCreated}
            className={style.teamName}
            label="Team Name"
            onChange={this.handleTeamNameChange}
            error={this.state.teamNameError}
            showErrorMsg
          />
          <CheckIcon
            color="#1DCDA3"
            size="15px"
            data-hidden={!isOrganisationCreated}
          />
          <div className={style.tableSection}>
            <div className={style.tableName}>
              Invite team members (optional)
            </div>
            <InviteToTeamFormContainer allowZeroEntries maxNumberOfRows={25} />
          </div>
          <Button
            theme="blueSolid"
            disabled={!this.canSubmitForm()}
            onClick={this.handleSubmit}
          >
            Get Started
          </Button>
        </div>
        <div className={style.col2}>
          <ImageDropZone
            disabled={this.props.isOrganisationCreated}
            onChange={({ file }) => this.setState({ file })}
            description="Add Team Logo"
          />
        </div>
      </section>
    );
  }
}
SignupTeamDetails.propTypes = {};

export default SignupTeamDetails;
